import * as React from "react";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { Loader, TransactionDialogTable } from "../../../shared";

export const TransactionDialog = ({
  state,
  data,
  item,
  handleChecked,
  handleCheckindeterminate,
  handleSelectedIds,
  handleAllChecked,
  handleAllSelectedIds,
  toggleViewModal,
}) => {
  const columns = React.useMemo(
    () => [
      {
        id: "action",
        accessor: "permission",
        padding: "checkbox",
        disableSortBy: true,
        Header: (row) => {
          return (
            <Stack
              sx={{
                flexDirection: "row ",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={handleAllChecked()}
                    indeterminate={handleCheckindeterminate()}
                    color="primary"
                    onChange={(e) => {
                      handleAllSelectedIds(e, row);
                    }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 24,
                        height: "24px",
                        width: "24px",
                      },
                    }}
                  />
                }
              />
            </Stack>
          );
        },
        Cell: ({ row, value }) => {
          return (
            <Stack
              sx={{
                flexDirection: "row ",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={handleChecked(row.original.id)}
                    color="primary"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                    onChange={(e) => {
                      handleSelectedIds(e, row);
                    }}
                  />
                }
              />
            </Stack>
          );
        },
      },
      {
        Header: "Transaction ID",
        accessor: "transaction_id",
      },
      {
        Header: "Transaction Date",
        accessor: "created_at",
      },
      {
        Header: "Order Amount",
        accessor: "order_amount",
        Cell: ({ value }) => {
          return <Typography fontSize="14px">{value?.toFixed(2)}</Typography>;
        },
      },
      {
        Header: "Amount Paid",
        accessor: "amount_paid",
        Cell: ({ value }) => {
          return <Typography fontSize="14px">{value?.toFixed(2)}</Typography>;
        },
      },
      {
        Header: "Balance",
        accessor: "balance",
        Cell: ({ value }) => {
          return <Typography fontSize="14px">{value?.toFixed(2)}</Typography>;
        },
      },
    ],
    [state]
  );
  return (
    <Stack
      mt={3}
      spacing={2}
      sx={{
        m: "16px -12px -12px",
        height: "94%",
      }}
    >
      {false ? (
        <Loader />
      ) : (
        <TransactionDialogTable
          defaultSearch="false"
          data={data}
          columns={columns}
          item={item}
          toggleViewModal={toggleViewModal}
        />
      )}
    </Stack>
  );
};
