import * as React from "react";
import { Stack, Typography, Button, } from "@mui/material";

export function ConfirmOrderDialog(props) {
  const { handleClose, isButton, onSubmit } = props;

  return (
    <div style={{ padding: "20px" }}>
      <Stack gap="12px">
        <Typography>Are you sure you want to confirm this order?</Typography>
      </Stack>

      <Stack direction="row" gap="12px" marginTop={3} justifyContent="flex-end">
        <Button onClick={handleClose}>No</Button>
        <Button disabled={isButton} onClick={onSubmit} variant="contained">
          Yes
        </Button>
      </Stack>
    </div>
  );
}
