import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, Popover } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";

export const MorePopOver = (props) => {
  const { SendInvoice, DownloadInvoice } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popId = open ? "simple-popover" : undefined;
  return (
    <div>
      {" "}
      <Button
        type="button"
        size="medium"
        variant="text"
        aria-describedby={popId}
        endIcon={<ArrowDropDownIcon width={12} height={6} />}
        onClick={handleClick}
      >
        More
      </Button>{" "}
      <Popover
        id={popId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            width: "200px",
            height: "112px",
            borderRadius: "8px",
            padding: "8px 0px",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "48px",
              padding: "0px 16px",
              display: "flex",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.06)",
              },
            }}
            onClick={SendInvoice}
          >
            <Box
              marginRight="8px"
              component="img"
              src="/icons/icons8_mail.svg"
            />
            Send Invoice
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "48px",
              padding: "0px 16px",
              display: "flex",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.06)",
              },
            }}
            onClick={() => DownloadInvoice("download")}
          >
            <Box marginRight="8px" component="img" src="/icons/ic_Import.png" />
            Download pdf
          </Box>
        </Box>
      </Popover>
    </div>
  );
};
