import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  ClientAccountDetails,
  ClientAccountTransactions,
  ClientaccountCustomerEdit,
  CreateOrEditCustomers,
} from "..";
import { ProtectedRoute } from "../../shared";
import {
  ClientAccountsList,
  ClientAccountSettlement,
  CreateClientAccount,
  ClientAccountDetailsList,
} from "./containers";

export const ClientAccounts = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ClientAccountsList />} />
        <Route
          element={<ProtectedRoute allowedRoute={["client-account-modify"]} />}
        >
          <Route path="/edit/:cid" element={<ClientAccountDetails />} />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["client-account-create"]} />}
        >
          <Route
            path="/details/:id/settlement"
            element={<ClientAccountSettlement />}
          />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["client-account-create"]} />}
        >
          <Route path="/create" element={<CreateClientAccount />} />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["client-account-modify"]} />}
        >
          <Route path="/details/:id" element={<ClientAccountDetailsList />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["customer-create"]} />}>
          <Route path="/customer/create" element={<CreateOrEditCustomers />} />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["client-account-modify"]} />}
        >
          <Route
            path="/details/:id/transactions/:t_id"
            element={<ClientAccountTransactions />}
          />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["customer-modify"]} />}>
          <Route
            path="/details/:customerId/customers/:id"
            element={<ClientaccountCustomerEdit />}
          />
        </Route>
      </Routes>
    </>
  );
};
