import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { useRef, useState } from "react";

export const Categories = ({ data, selectedCategory, changeCategory }) => {
  const categoryRef = useRef(null);
  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  return (
    <Stack>
      <Box
        sx={{
          height: "56px",
          borderBottom: "1px solid #E6E6E6",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          onClick={() =>
            handleHorizantalScroll(categoryRef.current, 50, 100, -10)
          }
          sx={{
            width: "56px",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRight: "1px solid #E6E6E6",
          }}
        >
          <img src="/icons/ic_left.svg" />
        </Box>
        <Box
          ref={categoryRef}
          sx={{
            display: "flex",
            gap: "4px",
            width: "100%",
            padding: "10px",
            overflow: "hidden",
          }}
        >
          {data.map((item, i) => (
            <Box
              key={i}
              sx={{
                minWidth: "auto",
                maxHeight: "50px",
                borderRadius: "30px",
                padding: "13px 12px",
                border: "1px solid #E6E6E6",
                fontSize: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: i === selectedCategory ? "#1560D4" : "",
                color: i === selectedCategory ? "#fff" : "",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() => changeCategory(i)}
            >
              {item.name}
            </Box>
          ))}
        </Box>
        <Box
          onClick={() =>
            handleHorizantalScroll(categoryRef.current, 50, 100, 10)
          }
          sx={{
            width: "56px",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderLeft: "1px solid var(--gray-gray-90, #E6E6E6)",
          }}
        >
          <img src="/icons/ic_right.svg" />
        </Box>
      </Box>
    </Stack>
  );
};
