import {
  ContentLayout,
  CustomTable,
  Loader,
  DeleteModal,
  useAbly,
  CustomeDialog,
} from "../../../shared";
import React from "react";
import { Button, Chip, Box, Typography, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { sortItems } from "../../../utils";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../store/AppScopeProvider";
import { useBumpScreen } from "../hooks";

export const BumpScreenList = () => {
  const {
    state,
    toggleModal,
    handleDeleteBumpScreen,
    onStorePairingStatus,
    onChangeFilter,
    resetFilters,
    isCreateVisible,
    isDeletable,
    isEditable,
    toggleUnpair,
    onConfirmUnpair,
  } = useBumpScreen({ isBumpscreen: true });
  const { appState } = React.useContext(AppContext);
  const { globalData } = appState;
  const navigate = useNavigate();
  const { presenceDataKds } = useAbly();
  const ActionButtons = ({ row, deleteAction }) => {
    return (
      <div className="action-buttons">
        <Typography
          variant="subtitle2"
          color={row.pairing_status === 0 ? "#A3A3A3" : "#1560D4"}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            row.pairing_status !== 0 && toggleUnpair(row.id);
          }}
        >
          Unpair
        </Typography>
        {isEditable && (
          <Link to={`edit/${row.id}`}>
            <img alt="edit" src="/icons/ic_edit.svg" />
          </Link>
        )}
        {isDeletable && (
          <img alt="delete" src="/icons/ic_delete.svg" onClick={deleteAction} />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Pairing Code",
        accessor: "pairing_code",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },

      {
        Header: "Status",
        accessor: "online_status",
        Cell: ({ row }) => {
          const isOnline = presenceDataKds.find(
            (item) => +item.clientId === row.original.id
          );
          return (
            <Box>
              {isOnline ? (
                <img alt="online" src="/icons/ic_online.svg" />
              ) : (
                <img alt="offline" src="/icons/ic_offline.svg" />
              )}
            </Box>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        accessor: (row, index) => {
          return (
            <ActionButtons
              row={row}
              deleteAction={() => {
                toggleModal(row.id);
              }}
            />
          );
        },
      },
    ],
    [presenceDataKds, state.ablyPairedIds]
  );
  return (
    <>
      <ContentLayout title="Bump Screens">
        <CustomTable
          columns={columns}
          data={state?.bumpScreenList}
          tableTitle="Bump Screens"
          defaultSearch={false}
          isLoading={state.isBusy}
          headerRightContent={
            <>
              {isCreateVisible && (
                <Button
                  type="button"
                  size="medium"
                  variant="text"
                  startIcon={<AddIcon />}
                  onClick={() => navigate("create")}
                >
                  Add Bump Screen
                </Button>
              )}
            </>
          }
          filterContent={
            <>
              <InputControl
                type="table-search"
                name="searchText"
                placeholder="Search"
                size="small"
                value={state.filters.searchText}
                onChange={(e) => onChangeFilter(e)}
              />

              <InputControl
                type="select"
                name="pairing_status"
                label="Type"
                optionValue={globalData?.bump_screens?.pairing_status ?? []}
                width="250px"
                onChange={(e) => onChangeFilter(e)}
                value={state.filters.pairing_status}
              />
              <Button
                type="button"
                size="medium"
                variant="text"
                color="gray"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={(e) => resetFilters(e)}
              >
                <CloseIcon />
              </Button>
            </>
          }
        />
      </ContentLayout>
      <DeleteModal
        open={state.isOpen}
        handleClose={() => toggleModal()}
        onConfirm={() => {
          handleDeleteBumpScreen();
        }}
      />
      <CustomeDialog
        open={state.isUnpairOpen}
        handleClose={toggleUnpair}
        PaperProps={{
          sx: { p: "24px 24px 16px 24px", minWidth: "432px" },
        }}
        title="Unpair"
        content={
          <Stack sx={{ gap: "16px", paddingTop: "16px" }}>
            <Stack sx={{ padding: "16px 0 10px 0" }}>
              <Typography>Are you sure you want to unpair?</Typography>
            </Stack>

            <Stack direction="row" gap="12px" justifyContent="flex-end">
              <Button onClick={toggleUnpair}>No</Button>
              <Button
                onClick={onConfirmUnpair}
                variant="contained"
                disabled={state.isUnpairBusy}
              >
                Yes
              </Button>
            </Stack>
          </Stack>
        }
      />
    </>
  );
};
