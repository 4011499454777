import { Box, Stack, Typography } from "@mui/material";
import * as React from "react";
import Chart from "react-apexcharts";
export const DashboardChart = (props) => {
    const { title, data, barColor, horizontal = false, index } = props

    const state = {
        options: {
            chart: {
                id: "basic-bar",
                toolbar: {
                    show: false,
                },
            },
            xaxis: {
                categories: data["x-axis"],
                labels: {
                    maxWidth: 50
                }
            },
            yaxis: {
                show: horizontal,
            },
            grid: {
                show: false
            },
            plotOptions: {
                bar: {
                    borderRadius: 8,
                    borderRadiusApplication: 'end',
                    horizontal: horizontal,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -28,
                offsetX: 0,
                style: {
                    fontSize: '14px',
                    colors: [barColor]
                },
                formatter: function (value) {
                    return "$" + value?.toFixed(2);
                }

            },
            colors: [barColor],
            tooltip: {
                marker: {
                    show: false,
                },
            }
        },
        series: [
            {
                name: "Orders",
                data: data["y-axis"]
            }
        ]
    };
    return (
        <Stack sx={{
            boxShadow: "0px 2px 8px 1px #0000000F",
            border: "1px solid #0000001A",
            borderRadius: "8px"
        }} gap="16px" mb="24px">
            <Typography variant="subtitle2" fontWeight={600} p="24px">{title}</Typography>
            <Box
                sx={{
                    width: "100%",
                    overflowY: "hidden",
                    overflowX: !horizontal ? "auto" : "hidden",
                }}
            >
                <Chart
                    key={index}
                    options={state.options}
                    series={state.series}
                    type="bar"
                    width={!horizontal ? (data["x-axis"].length * 60) + 100 : "95%"}
                    height={horizontal ? (data["x-axis"].length * 40) + ((data["x-axis"].length - 1) * 18) + 90 : "361px"}
                />
            </Box>
        </Stack>
    );
};
