import { useContext } from "react";
import { useImmer } from "use-immer";
import { AppContext } from "../../../store/AppScopeProvider";
import {
  DownloadInvoiceeById,
  SendInvoiceById,
  getOrderDetailsById,
  confirmOrder,
  rejectOrder,
  refundOrder,
} from "../api";
import { errorMessage, successMessage } from "../../../utils";
import { useEffect } from "react";
var fileDownload = require("js-file-download");

export const useOrderDetails = ({ orderId }) => {
  const { appState } = useContext(AppContext);
  const { globalData } = appState;
  const [state, setState] = useImmer({
    isDetailsFetching: true,
    order_number: "",
    isOpenReject: false,
    isButtonReject: false,
    isOpenConfirm: false,
    rejectText: "Reject",
    isButtonConfirm: false,
    isOpenRefund: false,
    isButtonRefund: false,
    data: {
      id: "",
      order_number: "",
      finalised_by_user: "",
      order_date: "",
      channel_id: "",
      channel_name: "",
      is_guest: "",
      customer_id: "",
      customer_first_name: "",
      customer_last_name: "",
      customer_company_name: "",
      customer_address: "",
      total_product_count: "",
      total_quantity_ordered: "",
      subtotal_exc_tax: "",
      subtotal_inc_tax: "",
      discount_percentage: "",
      discount_amount: "",
      delivery_provider: "",
      delivery_fee: "",
      tax_amount: "",
      tax_percentage: "",
      grand_total: "",
      grand_total_inc_tax: "",
      grand_total_exc_tax: "",
      refunded_total_amount: "",
      refunded_total_tax_amount: "",
      refunded_total_discount_amount: "",
      sales_type: "",
      sales_type_id: "",
      status: "",
      status_id: "",
      payment_type: "",
      payment_status: "",
      store_id: "",
      store_name: "",
      customer_ip: "",
      terminal_id: "",
      user_id: "",
      order_notes: "",
      table_number: "",
      table_cover: "",
      finalised_by: "",
      products: [],
      payments: [],
      created_at: "",
      updated_at: "",
      deleted_at: "",
      round_off: "",
    },
    refundInputs: {
      reason: "",
      deduct_fee: 0,
      deduction_amount: null,
    },
    rejectInput: {
      reason: "",
    },
    httpStatusCodes: {
      details: "",
    },
  });

  const getOrderDetails = async (orderId) => {
    try {
      setState((draft) => {
        draft.isDetailsFetching = true;
      });
      const res = await getOrderDetailsById(orderId);
      if (res.success) {
        setState((draft) => {
          draft.data = res.data;
          draft.order_number = res.data.order_number;
          draft.isDetailsFetching = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isDetailsFetching = false;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };

  useEffect(() => {
    if (orderId) {
      getOrderDetails(orderId);
    }
  }, [orderId]);

  const handleSendInvoice = async () => {
    try {
      const res = await SendInvoiceById(orderId);
      if (res.success) {
        successMessage(res.message);
      }else{
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const printFile = (blob) => {
    let blobURL = URL.createObjectURL(blob);
    let iframe = document.createElement("iframe"); //load content in an iframe to print later
    document.body.appendChild(iframe);
    iframe.style.display = "none";
    iframe.src = blobURL;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  };
  const handleDownloadInvoice = async (type) => {
    try {
      setState((draft) => {
        draft.invoiceLoading = true;
      });
      const res = await DownloadInvoiceeById(orderId);
      const content = res.headers["content-type"];
      if (type === "print") {
        printFile(res.data);
      } else {
        fileDownload(res.data, `invoice${state.data?.order_number}`, content);
      }
      setState((draft) => {
        draft.invoiceLoading = false;
      });
    } catch (err) {
      setState((draft) => {
        draft.invoiceLoading = false;
      });
      errorMessage(err.response.data.message);
    }
  };
  const handleCloseRefund = () => {
    setState((draft) => {
      draft.isOpenRefund = false;
    });
  };
  const handleCloseReject = () => {
    setState((draft) => {
      draft.isOpenReject = false;
    });
  };

  const handleModal = (type, data, text = "Reject") => {
    switch (type) {
      case "reject":
        setState((draft) => {
          draft.isOpenReject = data;
          draft.rejectInput.reason = "";
          draft.rejectText = text;
        });
        break;
      case "confirm":
        setState((draft) => {
          draft.isOpenConfirm = data;
        });
        break;
      case "refund":
        setState((draft) => {
          draft.isOpenRefund = data;
        });
        break;
      default:
    }
  };

  const handleRefundInputs = (e) => {
    const { value, name } = e?.target;
    setState((draft) => {
      draft.refundInputs[name] = value;
    });
  };

  const handleRejectInputs = (e) => {
    const { value, name } = e?.target;
    setState((draft) => {
      draft.rejectInput[name] = value;
    });
  };

  const handleOrderConfirm = async () => {
    setState((draft) => {
      draft.isButtonConfirm = true;
    });
    try {
      const res = await confirmOrder(orderId);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isOpenConfirm = false;
        });
        getOrderDetails(orderId);
      } else {
        errorMessage(res.message);
      }
      setState((draft) => {
        draft.isButtonConfirm = false;
      });
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isButtonConfirm = false;
      });
    }
  };

  const handleOrderReject = async () => {
    setState((draft) => {
      draft.isButtonReject = true;
    });
    try {
      const res = await rejectOrder(orderId, state.rejectInput);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isOpenReject = false;
        });
        getOrderDetails(orderId);
      } else {
        errorMessage(res.message);
      }
      setState((draft) => {
        draft.isButtonReject = false;
      });
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isButtonReject = false;
      });
    }
  };

  const handleOrderRefund = async () => {
    setState((draft) => {
      draft.isButtonRefund = true;
    });
    let data = {
      ...state.refundInputs,
      deduction_amount: state.refundInputs.deduct_fee ? state.refundInputs.deduction_amount : null
    }

    try {
      const res = await refundOrder(orderId, data);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isOpenRefund = false;
        });
        getOrderDetails(orderId);
      } else {
        errorMessage(res.message);
      }
      setState((draft) => {
        draft.isButtonRefund = false;
      });
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isButtonRefund = false;
      });
    }
  };

  return {
    state,
    globalData,
    handleSendInvoice,
    handleDownloadInvoice,
    setState,
    handleCloseRefund,
    handleCloseReject,
    handleRefundInputs,
    handleModal,
    handleOrderConfirm,
    handleRejectInputs,
    handleOrderReject,
    handleOrderRefund,
  };
};
