import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import { useDebounce } from "use-debounce";
import { useDropdownApis, usePermission, useTabs } from "../../../shared";

import {
  assignStoreToCoupons,
  createCoupons,
  deleteStoreFromCoupons,
  downloadCoupons,
  editCoupons,
  getBarcodeView,
  getCondition,
  getCouponsDetailsById,
  getUsageHistory,
  playpauseCoupons,
  statuschangeCoupons,
} from "../api/CouponsApi";
import qs from "qs";
import moment from "moment";
var fileDownload = require("js-file-download");

export const useCoupons = ({
  isCoupons,
  isConditions,
  couponsById,
  isBarcode,
}) => {
  const { appState } = useContext(AppContext);
  const {
    globalData,
    currentTabs: { CouponseDetails: currentTab },
  } = appState;
  const navigate = useNavigate();
  const { setCurentTab } = useTabs();
  const isEditable = usePermission("coupon-modify");
  const [state, setState] = useImmer({
    isBusy: false,
    barcodeViewList: [],
    initialLoad: false,
    isSaveButtonBusy: false,
    isOpen: false,
    id: null,
    checked: {
      inStore: false,
      onlineOrdering: false,
      member: false,
      guest: false,
      normal: false,
      couponCode: false,
      percentage: false,
      fixed: false,
    },
    usageHistoryfilters: { isSearching: false, searchText: "" },
    usageHistory: [],
    store: [],
    name: "",
    isFocus: false,
    storeDetails: {
      isSaveButton: false,
      isLoading: true,
      isOpen: false,
      isDelete: false,
      id: null,
      storeList: [],
      allStores: [],
      filteredStores: [],
      selectedStores: [],
    },
    conditions: [],
    mobile_image: "",
    desktop_image: "",
    data: {
      active: 1,
      name: "",
      label: "",
      description: "",
      coupon_type: 1,
      mobile_image: "",
      desktop_image: "",
      start_from: "",
      end_till: "",
      start_time: null,
      end_time: null,
      days_of_week: [],
      use_per_customer: "",
      use_per_day: "",
      time_lock_period: "",
      max_redeem_count: "",
      discount_amount: "",
      min_cart_value: "",
      max_discount_amount: "",
      discount_type: 2,
      condition_type: 1,
      conditions: [],
      coupon_code: "",
      location_type: "",
      show_in_list: 1,
      coupon_usage_type: "",
      customer_type: "",
    },
  });
  const { storeList } = useDropdownApis({
    isStoreList: currentTab === "2" ? true : false,
  });
  const onFocusTab = () => {
    setState((draft) => {
      draft.isFocus = true;
    });
  };
  const onBlurTab = () => {
    setState((draft) => {
      draft.isFocus = false;
    });
  };
  useEffect(() => {
    window.addEventListener("focus", onFocusTab);
    return () => {
      window.removeEventListener("focus", onFocusTab);
    };
  }, []);

  const handleCouponsDetailsList = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getCouponsDetailsById(couponsById);
      if (res.data) {
        setState((draft) => {
          draft.data = res.data;
          draft.name = res.data.name;
          draft.storeDetails.storeList = res.data.stores;
          draft.data.discount_type = Number(res.data.discount_type);
          draft.checked.inStore =
            res.data.coupon_usage_type === 1 || res.data.coupon_usage_type === 3
              ? true
              : false;
          draft.checked.onlineOrdering =
            res.data.coupon_usage_type === 2 || res.data.coupon_usage_type === 3
              ? true
              : false;
          draft.checked.member =
            res.data.customer_type === 1 || res.data.customer_type === 3
              ? true
              : false;
          draft.checked.guest =
            res.data.customer_type === 2 || res.data.customer_type === 3
              ? true
              : false;
          draft.isBusy = false;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (couponsById) {
      handleCouponsDetailsList();
    }
  }, [couponsById]);
  useEffect(() => {
    if (isConditions || state?.isFocus) {
      getConditions();
    }
  }, [isConditions, state.isFocus]);

  const getConditions = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });
      const res = await getCondition();
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.conditions = res.data;
          draft.initialLoad = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
      });
    }
  };
  const [searchText] = useDebounce(state.usageHistoryfilters.searchText, 1000);

  const HandleFilterStores = (type, text) => {
    const alreadyStores = state.storeDetails.storeList.map((val) => {
      return val.id;
    });

    const filteredStoreList = storeList?.filter(
      (val) => !alreadyStores.includes(val.id)
    );
    if (type === "initial") {
      setState((draft) => {
        draft.storeDetails.filteredStores = filteredStoreList;

        return draft;
      });
    } else {
      text = text.toLowerCase();
      setState((draft) => {
        draft.storeDetails.filteredStores = filteredStoreList.filter((val) => {
          return val.name.toString().toLowerCase().indexOf(text) > -1;
        });
        return draft;
      });
    }
  };
  useEffect(() => {
    HandleFilterStores("initial");
  }, [storeList, state.storeDetails.storeList]);
  const handleStoreSelect = (id) => {
    const isSelcted = state.storeDetails.selectedStores.includes(id);

    if (isSelcted) {
      setState((draft) => {
        draft.storeDetails.selectedStores =
          draft.storeDetails.selectedStores.filter((val) => val !== id);
      });
    } else {
      setState((draft) => {
        draft.storeDetails.selectedStores = [
          ...draft.storeDetails.selectedStores,
          id,
        ];
        return draft;
      });
    }
  };
  const handleStoreAllSelect = (id) => {
    const isAllSelcted = state.storeDetails.selectedStores.includes(id);
  };
  const onSelectItems = (id) => {
    const isSelcted = state.storeDetails.selectedStores.includes(id);
    if (isSelcted) {
      setState((draft) => {
        draft.storeDetails.selectedStores =
          draft.storeDetails.selectedStores.filter((val) => val !== id);
      });
    } else {
      setState((draft) => {
        draft.storeDetails.selectedStores = [
          ...draft.storeDetails.selectedStores,
          id,
        ];
        return draft;
      });
    }
  };
  const handleAssignStoretoCoupons = async () => {
    const data = {
      store_id: state.storeDetails.selectedStores,
    };
    setState((draft) => {
      draft.storeDetails.isSaveButton = true;
    });
    try {
      const res = await assignStoreToCoupons(data, couponsById);
      if (res.success === true) {
        successMessage(res.message);
        setState((draft) => {
          draft.storeDetails.storeList = [
            draft.store,
            ...draft.storeDetails.allStores.filter((val) =>
              draft.storeDetails.selectedStores.includes(val.id)
            ),
          ];
          draft.storeDetails.isLoading = false;
          draft.storeDetails.selectedStores = [];
          draft.storeDetails.isSaveButton = false;
          return draft;
        });
        handleModal(false);
        handleCouponsDetailsList();
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.storeDetails.isSaveButton = false;
      });
    }
  };
  const handleModal = (e) => {
    setState((draft) => {
      draft.storeDetails.isOpen = e;
      draft.storeDetails.selectedStores = [];
      return draft;
    });
    HandleFilterStores("initial");
  };

  const deleteModal = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isOpen = !draft.isOpen;
    });
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };
  const onRadioButtonChange = (e) => {
    const name = e?.target.name;
    const value = e?.target.value;
    if (name === "discount_type") {
      setState((draft) => {
        draft.data.discount_type = Number(value);
      });
    } else if (name === "coupon_type") {
      setState((draft) => {
        draft.data.coupon_type = value;
      });
    }
  };
  const handleConditionType = (e) => {
    setState((draft) => {
      draft.data.condition_type = +e.target.value;
    });
  };
  const handleDropzone = (e, name) => {
    setState((draft) => {
      draft.data[name] = e;
    });
  };

  const onStartDateChange = (value, name) => {
    setState((draft) => {
      draft.data[name] = value || null;
    });
  };

  const onAddConditions = (e) => {
    setState((draft) => {
      draft.data.conditions.push({ id: "", name: "" });
    });
  };

  const handleOnChange = (e, index) => {
    let id = e.value;
    setState((draft) => {
      draft.data.conditions = [
        ...draft.data.conditions.map((data, i) =>
          i === index ? { ...data, id } : data
        ),
      ];
    });
  };

  useEffect(() => {
    if (state.checked.guest) {
      let cusType = state.conditions
        .filter((v) => v.scope == 2)
        .map((v) => v.id);
      setState((draft) => {
        draft.data.conditions = state.data.conditions.filter(
          (v) => !cusType.includes(v.id)
        );
        draft.data.use_per_customer = "";
        draft.data.use_per_day = "";
        draft.data.time_lock_period = "";
      });
    }
  }, [state.checked.guest]);

  const onSaveCoupons = async () => {
    const { member, guest, onlineOrdering, inStore } = state.checked;
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      let formData = new FormData();
      Object.keys(state.data).map((key) => {
        if (
          key !== "conditions" &&
          key !== "days_of_week" &&
          key !== "start_time" &&
          key !== "end_time" &&
          key !== "mobile_image" &&
          key !== "desktop_image" &&
          key !== "use_per_customer" &&
          key !== "max_redeem_count" &&
          key !== "time_lock_period" &&
          key !== "use_per_day" &&
          key !== "start_from" &&
          key !== "end_till" &&
          key !== "coupon_usage_type" &&
          key !== "customer_type"
        ) {
          formData.append(key, state.data[key]);
        }
      });
      if (member && guest) {
        formData.append("customer_type", 3);
      } else if (guest) {
        formData.append("customer_type", 2);
      } else if (member) {
        formData.append("customer_type", 1);
      }
      if (onlineOrdering && inStore) {
        formData.append("coupon_usage_type", 3);
      } else if (onlineOrdering) {
        formData.append("coupon_usage_type", 2);
      } else if (inStore) {
        formData.append("coupon_usage_type", 1);
      }

      state.data.start_from &&
        formData.append(
          "start_from",
          moment(state.data.start_from).format("YYYY-MM-DD")
        );
      state.data.end_till &&
        formData.append(
          "end_till",
          moment(state.data.end_till).format("YYYY-MM-DD")
        );
      state.data.use_per_customer &&
        formData.append("use_per_customer", state.data.use_per_customer);
      state.data.max_redeem_count &&
        formData.append("max_redeem_count", state.data.max_redeem_count);
      state.data.time_lock_period &&
        formData.append("time_lock_period", state.data.time_lock_period);
      state.data.use_per_day &&
        formData.append("use_per_day", state.data.use_per_day);
      state.mobile_image && formData.append("mobile_image", state.mobile_image);
      state.desktop_image &&
        formData.append("desktop_image", state.desktop_image);
      state.data.start_time &&
        formData.append(
          "start_time",
          moment(state.data.start_time).format("HH:mm")
        );
      state.data.end_time &&
        formData.append(
          "end_time",
          moment(state.data.end_time).format("HH:mm")
        );
      state.data.conditions.some((item) => item.id) &&
        state.data.conditions.map((i) =>
          formData.append("conditions[]", JSON.stringify(i.id))
        );

      state.data.days_of_week &&
        state.data.days_of_week.map((i) =>
          formData.append("days_of_week[]", i)
        );

      const res = await createCoupons(formData);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate(`/coupons/details/${res.data.id}`);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const DeleteStoreModal = (state, data) => {
    setState((draft) => {
      draft.storeDetails.isDelete = state;
      draft.storeDetails.id = data;
    });
  };
  const handleDeleteStores = async (id) => {
    try {
      const res = await deleteStoreFromCoupons(
        couponsById,
        state.storeDetails.id
      );
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.storeDetails.storeList = draft.storeDetails.storeList.filter(
            (item) => item.id !== state.storeDetails.id
          );
        });
        DeleteStoreModal(false, null);

        handleCouponsDetailsList();
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handlePlayPauseStatus = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });

      const res = await playpauseCoupons(id);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.data = draft.data.active === 2 ? 3 : 2;
          handleCouponsDetailsList();
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const handleStatusChange = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await statuschangeCoupons(id);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
          draft.data = draft.data.active === 1 ? 2 : 1;
          handleCouponsDetailsList();
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onUpdateCoupons = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const { member, guest, onlineOrdering, inStore } = state.checked;
      let formData = new FormData();
      Object.keys(state.data).map((key) => {
        if (
          key !== "conditions" &&
          key !== "days_of_week" &&
          key !== "start_time" &&
          key !== "end_time" &&
          key !== "mobile_image" &&
          key !== "desktop_image" &&
          key !== "use_per_customer" &&
          key !== "max_redeem_count" &&
          key !== "time_lock_period" &&
          key !== "use_per_day" &&
          key !== "max_discount_amount" &&
          key !== "start_from" &&
          key !== "end_till" &&
          key !== "customer_type" &&
          key !== "coupon_usage_type"
        ) {
          formData.append(key, state.data[key]);
        }
      });
      if (member && guest) {
        formData.append("customer_type", 3);
      } else if (guest) {
        formData.append("customer_type", 2);
      } else if (member) {
        formData.append("customer_type", 1);
      }
      if (onlineOrdering && inStore) {
        formData.append("coupon_usage_type", 3);
      } else if (onlineOrdering) {
        formData.append("coupon_usage_type", 2);
      } else if (inStore) {
        formData.append("coupon_usage_type", 1);
      }

      state.data.start_from &&
        formData.append(
          "start_from",
          moment(state.data.start_from).format("YYYY-MM-DD")
        );
      state.data.end_till &&
        formData.append(
          "end_till",
          moment(state.data.end_till).format("YYYY-MM-DD")
        );
      formData.append(
        "max_discount_amount",
        state.data.max_discount_amount === null
          ? ""
          : state.data.max_discount_amount
      );
      formData.append(
        "use_per_customer",
        state.data.use_per_customer === null ? "" : state.data.use_per_customer
      );
      formData.append(
        "max_redeem_count",
        state.data.max_redeem_count === null ? "" : state.data.max_redeem_count
      );
      formData.append(
        "time_lock_period",
        state.data.time_lock_period === null ? "" : state.data.time_lock_period
      );
      formData.append(
        "use_per_day",
        state.data.use_per_day === null ? "" : state.data.use_per_day
      );
      formData.append("_method", "PUT");
      state.mobile_image && formData.append("mobile_image", state.mobile_image);
      state.desktop_image &&
        formData.append("desktop_image", state.desktop_image);

      state.data.start_time
        ? formData.append(
            "start_time",
            moment(state.data.start_time, "HH:mm:ss").format("HH:mm")
          )
        : formData.append("start_time", "");
      state.data.end_time
        ? formData.append(
            "end_time",
            moment(state.data.end_time, "HH:mm:ss").format("HH:mm")
          )
        : formData.append("end_time", "");
      state.data.conditions.some((item) => item.id) &&
        state.data.conditions.map((i) =>
          formData.append("conditions[]", JSON.stringify(i.id))
        );

      state.data.days_of_week &&
        state.data.days_of_week.map((i) =>
          formData.append("days_of_week[]", i)
        );

      const res = await editCoupons(couponsById, formData);
      if (res.success) {
        successMessage("Coupon updated successfully.");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate(`/coupons/details/${couponsById}`);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const handleInputChange = (event, v) => {
    const name = event?.target.name;
    const value = event?.target.value;
    if (name === "mobile_image") {
      setState((draft) => {
        draft.mobile_image = value[0];
        draft.data.mobile_image = URL.createObjectURL(value[0]);
      });
    } else if (name === "desktop_image") {
      setState((draft) => {
        draft.desktop_image = value[0];
        draft.data.desktop_image = URL.createObjectURL(value[0]);
      });
    }
  };
  const onDayPicker = (value) => {
    if (state.data.days_of_week.includes(value)) {
      setState((draft) => {
        draft.data.days_of_week = draft.data.days_of_week.filter(
          (c) => c !== value
        );
      });
    } else {
      setState((draft) => {
        draft.data.days_of_week = [...draft.data.days_of_week, value];
      });
    }
  };
  const handleDelete = (type, index) => {
    setState((draft) => {
      draft.data.conditions = [
        ...draft.data.conditions.filter((data, i) => i !== index),
      ];
    });
  };
  const handleAllSelect = (checked) => {
    if (checked) {
      setState((draft) => {
        draft.storeDetails.selectedStores = [
          ...state.storeDetails.filteredStores.map((item) => item.id),
        ];
      });
    } else {
      setState((draft) => {
        draft.storeDetails.selectedStores = [];
      });
    }
  };
  const handleAssignStore = async (type) => {
    const data = {
      location_type: type,
      store_id: type === 1 ? state.storeDetails.selectedStores : [],
    };
    try {
      const res = await assignStoreToCoupons(data, couponsById);
      if (res.success === true) {
        setState((draft) => {
          draft.storeDetails.storeList = [
            draft.store,
            ...draft.storeDetails.allStores.filter((val) =>
              draft.storeDetails.selectedStores.includes(val.id)
            ),
          ];
          draft.storeDetails.isLoading = false;
          draft.storeDetails.selectedStores = [];
          return draft;
        });
        handleModal(false);
        handleCouponsDetailsList();
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };

  const onChangeTitle = (e) => {
    handleAssignStore(e);
    setState((draft) => {
      draft.data.location_type = e;
    });
  };
  const handleSwitchChange = (event) => {
    const value = event.target.checked === true ? 1 : 0;
    setState((draft) => {
      draft.data.show_in_list = value;
    });
  };

  const handleCheckBoxChange = (e, type) => {
    const { checked } = e.target;
    if (type === "inStore") {
      setState((draft) => {
        draft.checked.inStore = checked ? true : false;
      });
    } else if (type === "onlineOrdering") {
      setState((draft) => {
        draft.checked.onlineOrdering = checked ? true : false;
      });
    } else if (type === "member") {
      setState((draft) => {
        draft.checked.member = checked ? true : false;
      });
    } else if (type === "guest") {
      setState((draft) => {
        draft.checked.guest = checked ? true : false;
        draft.data.coupon_type = "1";
      });
    }
  };
  const getUsageHistoryList = async ({ searchText = null }) => {
    let params = {};
    if (searchText) {
      params = { ...params, search: searchText };
    }

    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });
      const res = await getUsageHistory(query, couponsById);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.initialLoad = false;
          draft.usageHistory = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.initialLoad = false;
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (
      !state.initialLoad &&
      !state.usageHistoryfilters.isSearching &&
      isCoupons === false &&
      searchText
    ) {
      getUsageHistoryList({ searchText });
    }
  }, [searchText, isCoupons]);
  useEffect(() => {
    if (couponsById && currentTab === "3") {
      getUsageHistoryList({});
    }
  }, [couponsById, currentTab]);
  const onChangeusageHistoryFilter = (e) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.usageHistoryfilters[name] = value;
      draft.usageHistoryfilters.isSearching = false;
    });
  };

  const resetusageHistoryFilters = () => {
    if (state.usageHistoryfilters.searchText) {
      getUsageHistoryList({});
      setState((draft) => {
        draft.usageHistoryfilters.searchText = "";
        draft.usageHistoryfilters.isSearching = true;
      });
    }
  };
  const downloadCouponsList = async (CouponId) => {
    try {
      const res = await downloadCoupons(CouponId);
      const content = res.headers["content-type"];
      fileDownload(res.data, `CouponList.xlsx`, content);
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };
  const getBarcodeViewList = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getBarcodeView(couponsById);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.barcodeViewList = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (couponsById && isBarcode) {
      getBarcodeViewList();
    }
  }, [couponsById, isBarcode]);
  const validateDate = (reason, name, type) => {
    if (reason === "disablePast") {
      setState((draft) => {
        draft.validation[name] = "Please select a future date";
      });
    } else if (reason === "invalidDate") {
      setState((draft) => {
        draft.validation[name] =
          type === "date"
            ? "Selected Date is not a valid date"
            : "Selected Time is not valid";
      });
    } else {
      setState((draft) => {
        draft.validation[name] = null;
      });
    }
  };
  return {
    HandleFilterStores,
    handleStoreSelect,
    handleAssignStoretoCoupons,
    handleModal,
    state,
    currentTab,
    setCurentTab,
    onSelectItems,
    globalData,
    deleteModal,
    onChange,
    handleDropzone,
    onStartDateChange,
    globalData,
    onAddConditions,
    handleOnChange,
    onSaveCoupons,
    handleStoreAllSelect,
    DeleteStoreModal,
    handleDeleteStores,
    handleStatusChange,
    handlePlayPauseStatus,
    onUpdateCoupons,
    onDayPicker,
    handleInputChange,
    handleDelete,
    handleAllSelect,
    onChangeTitle,
    handleConditionType,
    coupons: state.coupons,
    handleSwitchChange,
    handleCheckBoxChange,
    onChangeusageHistoryFilter,
    resetusageHistoryFilters,
    onRadioButtonChange,
    downloadCouponsList,
    validateDate,
    isEditable,
    onBlurTab,
  };
};
