import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import { BumpScreenList, CreateOrEditBumpScreen } from "./containers";

export const BumpScreen = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<BumpScreenList />} />
                <Route
                    element={<ProtectedRoute allowedRoute={["bump-screen-modify"]} />}
                >
                    <Route path="/edit/:id" element={<CreateOrEditBumpScreen />} />
                </Route>
                <Route
                    element={<ProtectedRoute allowedRoute={["bump-screen-create"]} />}
                >
                    <Route path="/create" element={<CreateOrEditBumpScreen />} />
                </Route>
            </Routes>
        </>
    );
};
