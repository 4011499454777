import {
  CustomTable,
  CustomeDialog,
  DeleteModal,
  usePermission,
  CheckBoxListDialog,
} from "../../../shared";
import * as React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { arraySort, sortItems } from "../../../utils";

export const StoresList = (props) => {
  const navigate = useNavigate();
  const isEditable = usePermission("user-modify");
  const isDeletable = usePermission("user-delete");

  const {
    storesDetails,
    handleModal,
    HandleFilterStores,
    handleStorsSelect,
    handleAssignStorestoUser,
    handleDeleteStore,
    handleSelectAllStores,
  } = props;
  const ActionButtons = ({ deleteAction }) => {
    return (
      <div className="action-buttons"
        onClick={(e) => e.preventDefault()}
      >
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Store Name",
        id: "name",
        accessor: 'name',
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              deleteAction={() => handleModal("store-delete", true, row.id)}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <CustomTable
        columns={columns}
        data={arraySort(storesDetails.storesList, "name")}
        searchBar={false}
        tableTitle="Stores"
        rowsSelection={false}
        headerRightContent={
          <>
            {isEditable && (
              <Button
                type="button"
                size="medium"
                variant="text"
                className="delte"
                startIcon={<AddIcon />}
                onClick={() => handleModal("store-add", true)}
              >
                Add Store
              </Button>
            )}
          </>
        }
        onRowLink={(e) => {
          return `/stores/details/${e.id}`;
        }}
      />
      <CustomeDialog
        open={storesDetails.isOpen}
        handleClose={() => handleModal("store-add", false)}
        PaperProps={{
          sx: { width: "423px", height: "535px", p: "16px" },
        }}
        title="Stores"
        content={
          <CheckBoxListDialog
            Items={storesDetails.filteredStore}
            onSearch={HandleFilterStores}
            loading={storesDetails.isLoading}
            onSelect={handleStorsSelect}
            onSubmit={handleAssignStorestoUser}
            disabled={storesDetails.selectedStores.length === 0}
            isSelected={(id) => storesDetails.selectedStores.includes(id)}
            onClearSearch={() => HandleFilterStores("search", "")}
            value={storesDetails.searchText}
            hasSelectAll={true}
            isSelectAllChecked={storesDetails.filteredStore.every((item) =>
              storesDetails.selectedStores.includes(item.id)
            )}
            onAllSelect={handleSelectAllStores}
          />
        }
      />

      <DeleteModal
        open={storesDetails.isDelete}
        handleClose={() => handleModal("store-delete", false, null)}
        onConfirm={handleDeleteStore}
      />
    </>
  );
};
