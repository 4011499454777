import {
    ContentLayout,
    DeleteModal,
    Loader,
    CustomDetailsList,
    CustomTable,
    CustomeDialog,
    DraggableReactTable,
    usePermission,
    HeaderBreadcrumbs,
} from "../../../shared";
import * as React from "react";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Typography
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useAdminUsers } from "../..";
import { GroupNameDialog } from "./GroupNameDialog";
import { ProductInfiniteScrollDialog } from "./ProductInfiniteScrollDialog";

export const UserFavouriteDetails = () => {
    const params = useParams();
    const { state, handleModal, handleProductSelect, handleAssignProducts, onDeleteUserFavGroupProducts, onChangeUserFavGroup, onUpdateUserFavGroup, onProductsOrderChange, isSelected } = useAdminUsers({
        isFavDetails: params
    })
    const { isloading, details,
        isProductModal,
        isDelete,
        isDeleteFev,
        isOpen
    } = state.favoritesList
    const isEditable = usePermission("user-modify");
    const isDeletable = usePermission("user-delete");
    const isCreate = usePermission("user-create");

    const data =
        (details.products?.length > 0 &&
            details.products.map((item) => ({
                id: item.product_id,
                name: item.name,
            }))) ||
        [];

    const columns = [

        {
            title: "Group Name",
            accessor: (val) => {
                return (
                    <>
                        <Typography variant="subtitle2" fontWeight={400} color="#636363">
                            {val.name}
                            {isEditable && (
                                <LoadingButton
                                    color="info"
                                    sx={{ ml: "10px" }}
                                    onClick={() => { handleModal("fav-edit", true) }}
                                >
                                    Edit
                                </LoadingButton>
                            )}
                        </Typography>
                    </>
                );
            },
        },
        {
            title: "Created By",
            accessor: "user_name",
        },

    ];



    const columns2 = React.useMemo(
        () => [
            {
                Header: "Product",
                accessor: "name"

            },
            {
                Header: "Action",
                disableGlobalFilter: true,
                id: "action",
                align: "right",
                disableSortBy: true,
                accessor: (row, index) => {
                    return (
                        <ActionButtons
                            deleteAction={() => handleModal('fav-pro-list-delete', true, row.id)}
                            row={row} />
                    );
                },
            },
        ],
        [data]
    );
    const ActionButtons = ({ deleteAction }) => {
        return (
            <div className="action-buttons">
                {isDeletable && (
                    <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
                )}
            </div>
        );
    };


    return (
        <>
            <ContentLayout
                title={details?.name}
                breadcrumbs={
                    <>
                        <HeaderBreadcrumbs
                            links={[
                                { name: "Dashboard", href: "/store-admin" },
                                {
                                    name: "Admin User",
                                    href: "/store-admin/admin-users"
                                },
                                {
                                    name: 'Favourites',
                                    href: `/store-admin/admin-users/details/${params.id}`
                                },
                                { name: details.name || "" },
                            ]}
                        />
                    </>
                }
                rightContent={
                    <>
                        <Button
                            type="button"
                            size="medium"
                            variant="text"
                            onClick={() => handleModal("fav-delete", true)}
                            sx={{
                                minWidth: "40px",
                                p: "8px",
                            }}
                        >
                            <img
                                src="/icons/ic_delete_blue.svg"
                            />
                        </Button>
                    </>
                }
            >
                {isloading ? (
                    <Loader />
                ) : (
                    <>
                        <CustomDetailsList
                            columns={columns}
                            data={details}
                            title="Details"
                        />
                        <DraggableReactTable
                            columns={columns2}
                            data={data}
                            tableTitle="Products"
                            searchBar={false}
                            onRowOrderChange={onProductsOrderChange}
                            headerRightContent={
                                <>
                                    {isCreate && (
                                        <Button
                                            type="button"
                                            size="medium"
                                            variant="text"
                                            startIcon={<AddIcon />}
                                            onClick={() => handleModal('fav-pro-list', true)}
                                        >
                                            Add Product
                                        </Button>
                                    )}
                                </>
                            }
                        />
                        <CustomeDialog
                            open={isProductModal}
                            handleClose={() => handleModal('fav-pro-list', false)}
                            PaperProps={{
                                sx: { width: "423px", height: "491px", p: "16px" },
                            }}
                            title="Products"
                            content={
                                <ProductInfiniteScrollDialog
                                    handleSelect={handleProductSelect}
                                    isSelected={isSelected}
                                    handleChange={handleAssignProducts}
                                    disabled={state.favoritesList.selectedProducts.length === 0}
                                    alreadyList={state.favoritesList.alreadyList}
                                />

                            }
                        />
                    </>
                )}
            </ContentLayout>
            <DeleteModal
                open={isDelete}
                handleClose={() => handleModal('fav-pro-list-delete', false, null)}
                onConfirm={() => {
                    onDeleteUserFavGroupProducts('list')
                }}
            />
            <DeleteModal
                open={isDeleteFev}
                handleClose={() => handleModal("fav-delete", false)}
                onConfirm={() => {
                    onDeleteUserFavGroupProducts('fev')
                }}
            />
            <CustomeDialog
                open={isOpen}
                handleClose={() => handleModal("fav-edit", false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: { width: "423px", height: "235px", p: "16px" },
                }}
                title="Group"
                content={
                    <GroupNameDialog
                        onChangeFavGroup={onChangeUserFavGroup}
                        state={state.favoritesList}
                        onSaveFavGroup={() => { }}
                        onUpdateFavGroup={onUpdateUserFavGroup}
                        isEdit={true}
                    />
                }
            />
        </>
    );
};
