import React from "react";

import {
  Stack,
  Card,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

export const CustomDetailsList = (props) => {
  const {
    columns,
    data,
    title,
    headerRightContent = null,
    marginBottom = "24px",
    style = {},
  } = props;

  const prepareData = columns?.map((val) => {
    if (typeof val.accessor === "string") {
      return {
        ...val,
        accessor: data[val.accessor],
        hidden: val.hidden ? val.hidden(data) : false,
      };
    } else {
      return {
        ...val,
        accessor: val.accessor(data),
        hidden: val.hidden ? val.hidden(data) : false,
      };
    }
  });

  return (
    <Card sx={{ mb: marginBottom, ...style }}>
      {title && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="83px"
          p="32px 24px"
        >
          <Typography
            variant="subtitle1"
            fontWeight={600}
            lineHeight="19px"
            minWidth="160px"
          >
            {title}
          </Typography>
          {headerRightContent}
        </Stack>
      )}
      <Divider />
      <List disablePadding>
        {prepareData?.map((data, index) => {
          return (
            <React.Fragment key={index}>
              {data.header ? data.header(data) : null}
              <ListItem
                divider={true}
                disablePadding
                key={index}
                sx={{
                  display: data.hidden ? "none" : "flex",
                  backgroundColor: data.backgroundColor,
                }}
              >
                <ListItemText
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    my: 0,
                    pl: 2,
                  }}
                  disableTypography={true}
                >
                  <Stack minHeight="48px" justifyContent="center" p="10px">
                    <Typography
                      variant="subtitle2"
                      fontWeight={400}
                      minWidth="160px"
                    >
                      {data.title}
                    </Typography>
                  </Stack>
                  <Stack
                    minHeight="48px"
                    justifyContent="center"
                    p="10px"
                    width="100%"
                  >
                    <Typography
                      variant="subtitle2"
                      fontWeight={400}
                      color="#636363"
                    >
                      {data.accessor}
                    </Typography>
                  </Stack>
                </ListItemText>
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    </Card>
  );
};
