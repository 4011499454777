import { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { getRolesList, deleteRoleById } from "..";
import { useTabs } from "../../../shared";
import { AppContext } from "../../../store/AppScopeProvider";
import { successMessage, errorMessage } from "../../../utils";
import { useLocation } from "react-router-dom";

export const useRolesList = () => {
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const { appState } = useContext(AppContext);
  const {
    currentTabs: { rolesAndPermissionList: currentTab },
  } = appState;
  const { setCurentTab } = useTabs();
  const [state, setState] = useImmer({
    isLoading: true,
    rolesList: [],
    deleteModalOpen: false,
    selectedRole: null,
  });

  const breadcrumbsLinks = [
    { name: "Dashboard", href: "/" },
    {
      name: "Roles & Permissions",
      href: "/roles-and-permissions",
    },
  ];

  const HandleRolesList = async (currentTab = "1") => {
    const type = currentTab === "1" ? "super_admin" : "store_admin";
    try {
      const res = await getRolesList(type);
      if (res.success) {
        setState((draft) => {
          draft.rolesList = res.data;
          draft.isLoading = false;
        });
      }
    } catch (error) {
      setState((draft) => {
        draft.isLoading = false;
      });
      errorMessage(error.response.data.message);
    }
  };
  useEffect(() => {
    if (isStoreAdmin) {
      HandleRolesList("2");
    }
  }, [isStoreAdmin]);
  useEffect(() => {
    if (!isStoreAdmin && currentTab !== "3") {
      HandleRolesList(currentTab);
    }
  }, [currentTab, isStoreAdmin]);
  const handleModal = (data, id) => {
    setState((draft) => {
      draft.deleteModalOpen = data;
      draft.selectedRole = id;
    });
  };

  const handleDeleteRole = async () => {
    try {
      const res = await deleteRoleById(state.selectedRole);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.rolesList = draft.rolesList.filter(
            (item) => item.id !== state.selectedRole
          );
        });
        handleModal(false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      console.log(err);
    }
  };

  return {
    state,
    breadcrumbsLinks,
    handleModal,
    handleDeleteRole,
    currentTab,
    setCurentTab,
  };
};
