import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import {
  getFranchisees,
  deleteFranchiseeById,
  createFranchisee,
  getFranchiseeDetails,
} from "../api/FranchiseeApi";
import { editFranchisee } from "../api/FranchiseeApi";
import { usePermission } from "../../../shared";

export const useFranchisee = ({
  isFranchisee,
  franchiseeId,
  franchiseelistId,
}) => {
  const navigate = useNavigate();
  const [state, setState] = useImmer({
    roleDeleteModal: false,
    id: "",
    httpStatusCodes: {
      details: "",
    },
    franchiseelistDetails: {
      isBusy: false,
      open: false,
      selectedMember: null,
      memberDeleteModal: false,
      selectedStore: [],
      filteredStores: [],
      data: {
        id: "",
        name: "",
        status: "",
        stores: [],
      },
    },
    franchiseeDetails: {
      loading: true,
      isBusy: false,
      isOpen: false,
      id: null,
      isSaveButtonBusy: false,
      franchiseeDetailsList: [],
      franchisee: [{ id: "", name: "", status: "" }],
      data: {
        name: "",
        status: false,
      },
    },
  });

  const isDeletable = usePermission("franchisee-delete");
  const isCreateVisible = usePermission("franchisee-create");
  const isEditable = usePermission("franchisee-modify");
  const HandleFranchiseeDetailsList = async () => {
    try {
      setState((draft) => {
        draft.franchiseeDetails.isBusy = true;
      });
      const res = await getFranchiseeDetails(franchiseeId);
      if (res.data) {
        setState((draft) => {
          draft.franchiseeDetails.data = res.data;
          draft.franchiseeDetails.isBusy = false;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.httpStatusCodes.details = err.response.status;
        draft.franchiseeDetails.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (franchiseeId) {
      HandleFranchiseeDetailsList();
    }
  }, [franchiseeId]);

  const onUpdateFranchisee = async (franchiseeId) => {
    try {
      setState((draft) => {
        draft.franchiseeDetails.isSaveButtonBusy = true;
      });
      const res = await editFranchisee(
        franchiseeId,
        state.franchiseeDetails.data
      );
      if (res.success) {
        successMessage("Updated Successfully");
        navigate(-1);
        setState((draft) => {
          draft.franchiseeDetails.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.franchiseeDetails.isSaveButtonBusy = false;
      });
    }
  };

  useEffect(() => {
    if (isFranchisee) {
      getFranchisee();
    }
  }, [isFranchisee]);

  const getFranchisee = async () => {
    try {
      setState((draft) => {
        draft.franchiseeDetails.isBusy = true;
      });
      const res = await getFranchisees();
      if (res.success) {
        setState((draft) => {
          draft.franchiseeDetails.isBusy = false;
          draft.franchiseeDetails.franchisee = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.franchiseeDetails.isBusy = false;
      });
    }
  };

  const onSaveState = async () => {
    try {
      setState((draft) => {
        draft.franchiseeDetails.isSaveButtonBusy = true;
      });
      const res = await createFranchisee(state.franchiseeDetails.data);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.franchiseeDetails.isSaveButtonBusy = false;
        });
        navigate(-1);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.franchiseeDetails.isSaveButtonBusy = false;
      });
    }
  };
  const onChange = (e, type) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.franchiseeDetails.data.name = value;
    });
  };
  const onSwitchChange = (e) => {
    setState((draft) => {
      draft.franchiseeDetails.data.status = e.target.checked
        ? e.target.checked
        : false;
    });
  };
  const handleModal = (type, id = null) => {
    if (type === "franchisee") {
      setState((draft) => {
        draft.franchiseeDetails.id = id;
        draft.franchiseeDetails.isOpen = !draft.franchiseeDetails.isOpen;
      });
    }
  };
  const handleDeleteFranchisee = async () => {
    try {
      const res = await deleteFranchiseeById(state.franchiseeDetails.id);
      if (res.success) {
        successMessage("Deleted Successfully");

        setState((draft) => {
          draft.franchiseeDetails.franchisee =
            draft.franchiseeDetails.franchisee.filter(
              (item) => item.id !== state.franchiseeDetails.id
            );
        });
        handleModal("franchisee");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleEdit = () => {
    navigate(`/franchisee/details/edit/${franchiseelistId}`);
  };
  const handleModals = (data, id) => {
    setState((draft) => {
      draft.id = id;
      draft.roleDeleteModal = data;
    });
  };
  const handleDeleteFranchisees = async () => {
    try {
      const res = await deleteFranchiseeById(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        navigate("/franchisee");
        handleModals(true);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const HandleFranchiseeDetailsLists = async () => {
    try {
      setState((draft) => {
        draft.franchiseelistDetails.isBusy = true;
      });
      const res = await getFranchiseeDetails(franchiseelistId);
      if (res.data) {
        setState((draft) => {
          draft.franchiseelistDetails.data = res.data;
          draft.franchiseelistDetails.isBusy = false;
          draft.isLoading = false;
        });
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.franchiseelistDetails.isBusy = false;
        draft.httpStatusCodes.details = error.response.status;
      });
    }
  };
  useEffect(() => {
    if (franchiseelistId) {
      HandleFranchiseeDetailsLists();
    }
  }, [franchiseelistId]);

  const handleUsersSelect = (id) => {
    const isSelcted = state.franchiseelistDetails?.selectedStore.includes(id);
    if (isSelcted) {
      setState((draft) => {
        draft.franchiseelistDetails.selectedStore =
          draft.franchiseelistDetails?.selectedStore.filter(
            (val) => val !== id
          );
      });
    } else {
      setState((draft) => {
        draft.franchiseelistDetails.selectedStore = [
          ...draft.franchiseelistDetails?.selectedStore,
          id,
        ];
        return draft;
      });
    }
  };

  const handleMemberModal = (data, id) => {
    setState((draft) => {
      draft.franchiseelistDetails.memberDeleteModal = data;
      draft.franchiseelistDetails.selectedMember = id;
    });
  };
  const selectedStore = (id) => {
    return state.franchiseelistDetails.selectedStore.includes(id);
  };
  const handleClickOpen = () => {
    setState((draft) => {
      draft.franchiseelistDetails.open = true;
      return draft;
    });
  };

  return {
    state,
    handleModal,
    handleDeleteFranchisee,
    onSaveState,
    onChange,
    onSwitchChange,
    HandleFranchiseeDetailsList,
    onUpdateFranchisee,
    handleEdit,
    handleModals,
    handleDeleteFranchisees,
    HandleFranchiseeDetailsLists,
    handleUsersSelect,
    handleMemberModal,
    selectedStore,
    handleClickOpen,
    isDeletable,
    isEditable,
    isCreateVisible,
  };
};
