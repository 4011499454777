import { ContentLayout, CustomTable, DeleteModal } from "../../../shared";
import * as React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import InputControl from "../../../shared/components/InputControl";
import { sortItems } from "../../../utils";
import { useOptionalGroup } from "../hooks/useOptionalGroup";

export const OptionalGroupList = () => {
  const navigate = useNavigate();
  const {
    state,
    onChangeFilter,
    resetFilters,
    handleDeleteOptionalGroup,
    toggleModal,
    isEditable,
    isDeletable,
    isCreateVisible,
    globalData,
  } = useOptionalGroup({
    isOptionalGroup: true,
    isProduct: false,
  });
  const ActionButtons = ({ id, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}

        {isEditable && (
          <RouterLink to={`/optionalGroup/details/${id}`}>
            <img alt="details" src="/icons/arrow_forward.svg" />
          </RouterLink>
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => {
                toggleModal(row.id);
              }}
            />
          );
        },
      },
    ],
    [globalData]
  );
  return (
    <>
      <ContentLayout
        title="Modifier Groups"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("/optionalGroup/create")}
              >
                New Group
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={state.optionalGroup}
          isLoading={state?.isBusy}
          tableId="optional-group_list"
          onRowLink={(e) => {
            return `/optionalGroup/details/${e.id}`;
          }}
        />
      </ContentLayout>
      <DeleteModal
        open={state.isOpen}
        handleClose={() => toggleModal()}
        onConfirm={() => handleDeleteOptionalGroup()}
      />
    </>
  );
};
