import * as React from "react";
import { Box, Stack, Card, Typography, Divider, Collapse, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Button } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";

export const LoyaltyCardsProductsTable = (props) => {
  const {
    IsEdit,
    data,
    searchText,
    handleMoveIncludeOrExclude,
    handleProductsSearch,
    onProductsSeachClear
  } = props
  const [open, setOpen] = React.useState(null);
  const handleClick = (id) => {
    setOpen(id === open ? null : id);
  };

  return (
    <>
      <Card sx={{ mb: "24px" }}>
        <Stack p="20px 16px 16px">
          <InputControl
            type="table-search"
            placeholder="Search"
            value={searchText}
            onChange={(e) => handleProductsSearch(e.target.value)}
            onClear={onProductsSeachClear}
          />
        </Stack>
        <Stack direction="row">
          <Box width="100%">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              height="83px"
              p="32px 24px"
              bgcolor="rgba(0, 0, 0, 0.06)"
            >
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="160px"
              >
                Included Products ({data.included_products_count})
              </Typography>
            </Stack>
            <Divider />
            <Stack sx={{
              flexDirection: "row",
              // gap: "20px",
            }}>
              <List
                sx={{ width: '100%', bgcolor: 'background.paper', p: 0 }}
              >
                {data.included_products.map((data, index) => (
                  <>
                    <ListItemButton
                      key={index}
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                      }}
                      onClick={() => handleClick(data.id)}>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="subtitle2"
                            color="#171717"
                          >
                            {`${data.name} (${data.products.length})`}

                          </Typography>
                        }
                      />
                      {
                        data.products.length > 0 &&
                        <>
                          <img src="/icons/ic_dropdown.svg" style={{
                            transform: open === data.id ? "" : "rotate(180deg)"
                          }} />
                        </>
                      }
                    </ListItemButton>
                    <Collapse in={open === data.id ? true : false} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {data.products.map((data, i) => (
                          <ListItemButton key={i} sx={{ pl: 4, minHeight: "48.8px", py: "6px", borderBottom: "1px solid #E6E6E6", }} className="hover-btn">
                            <ListItemText primary={
                              <Typography
                                sx={{ display: "inline", fontWeight: 400 }}
                                component="span"
                                variant="subtitle2"
                                color="#171717"
                              >
                                {data.name}
                              </Typography>
                            } />
                            {IsEdit && (
                              <Button
                                className="btn"
                                size="small"
                                onClick={() => handleMoveIncludeOrExclude("exclude", data, index)}
                                sx={{
                                  display: "none",
                                  border: "none !important",
                                  minWidth:"120px !important",
                                  bgcolor: "rgba(0, 0, 0, 0.03)",
                                  "&:hover": {
                                    bgcolor: "rgba(0, 0, 0, 0.06)",
                                  },
                                }}
                                color="info"
                              >Move to Exclude</Button>
                            )}
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </>
                ))}
              </List>

            </Stack>
          </Box>
          <Box width="100%" borderLeft="1px solid #E6E6E6">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              height="83px"
              p="32px 24px"
              bgcolor="rgba(0, 0, 0, 0.06)"
            >
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="160px"
              >
                Excluded Products ({data.excluded_products_count})
              </Typography>
            </Stack>
            <Divider />
            <Stack sx={{
              flexDirection: "row",
              // gap: "20px",
            }}>
              <List
                sx={{ width: '100%', bgcolor: 'background.paper', p: 0 }}
              >
                {data.excluded_products.map((data, index) => (
                  <>
                    <ListItemButton
                      key={index}
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                      }}
                      onClick={() => handleClick(data.id)}>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="subtitle2"
                            color="#636363"
                          >
                            {`${data.name} (${data.products.length})`}

                          </Typography>
                        }
                      />
                      {data.products.length > 0 &&
                        <>
                          <img src="/icons/ic_dropdown.svg" style={{
                            transform: open === data.id ? "" : "rotate(180deg)"
                          }} />
                        </>
                      }
                    </ListItemButton>
                    <Collapse in={open === data.id ? true : false} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {data.products.map((data, i) => (
                          <ListItemButton key={i} sx={{ pl: 4, minHeight: "48.8px", py: "6px", borderBottom: "1px solid #E6E6E6", }} className="hover-btn">
                            <ListItemText primary={
                              <Typography
                                sx={{ display: "inline", fontWeight: 400 }}
                                component="span"
                                variant="subtitle2"
                                color="#636363"
                              >
                                {data.name}
                              </Typography>
                            }
                            />
                            {IsEdit && (
                              <Button
                                className="btn"
                                size="small"
                                onClick={() => handleMoveIncludeOrExclude("include", data, index)}
                                sx={{
                                  display: "none",
                                  border: "none !important",
                                  minWidth:"120px !important",
                                  bgcolor: "rgba(0, 0, 0, 0.03)",
                                  "&:hover": {
                                    bgcolor: "rgba(0, 0, 0, 0.06)",
                                  },
                                }}
                                color="info"
                              >Move to Include</Button>
                            )}
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </>
                ))}
              </List>

            </Stack>
          </Box>
        </Stack>
      </Card>
    </>
  );
};
