import React from "react";
import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import {
  Stack,
  FormControlLabel,
  Checkbox,
  Typography,
  Card,
  Grid,
} from "@mui/material";
import { PosPermissionSection, useCreateOrEditPosRoles } from "..";
import { sortItems } from "../../../utils";
import InputControl from "../../../shared/components/InputControl";
import { LoadingButton } from "@mui/lab";
const checkboxStyle = { "& .MuiSvgIcon-root": { fontSize: 24 } };

export const AddOrEditPosRoles = () => {
  const {
    state,
    isPermissionChecked,
    updateMode,
    formValidator,
    breadcrumbsLinks,
    isIndeterminate,
    handleFormSubmit,
    handleFormChange,
    handleCheckPermission,
  } = useCreateOrEditPosRoles();

  const columns = React.useMemo(
    () => [
      {
        id: "expander",
        Header: "",
        Cell: ({ row }) =>
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `0px 10px`,
                },
              })}
              style={{ padding: `0px 10px`, marginLeft: "-20px" }}
            >
              <img
                alt="forward"
                className={`${row.isExpanded && "expanded-icon"}`}
                src="/icons/ic_forward.svg"
              />
            </span>
          ) : null,
        padding: "checkbox",
      },
      {
        Header: () => {
          return <span style={{ marginLeft: `-15px` }}>Permission Name</span>;
        },
        accessor: "name",
        Cell: ({ row, value }) => {
          return (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                marginLeft: "-15px",
              }}
            >
              {row.depth ? (
                <img alt="permission" src="/icons/permission.svg" />
              ) : (
                <img alt="folder" src="/icons/Folder.svg" />
              )}
              <span>{row.original.display_name || value}</span>
            </Stack>
          );
        },
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        id: "permission",
        accessor: "permission",
        padding: "checkbox",
        disableSortBy: true,
        Header: (row) => {
          const checked = isPermissionChecked({ type: "ALL_PERMISSION" });
          const indeterminate = isIndeterminate({
            type: "HEAD",
            row,
            allChecked: checked,
          });
          return (
            <Stack
              sx={{
                flexDirection: "row ",
              }}
            >
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={checked}
                    indeterminate={indeterminate}
                    color="primary"
                    onChange={(event) =>
                      handleCheckPermission({ event, row, type: "HEAD" })
                    }
                    sx={checkboxStyle}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 600,
                      lineHeight: "15.73px",
                    }}
                  >
                    Permission
                  </Typography>
                }
              />
            </Stack>
          );
        },
        Cell: ({ row }) => {
          const type =
            row.depth === 0 ? "MODULE_PERMISSION" : "UNIQUE_PERMISSION";
          const checked = isPermissionChecked({
            type,
            row,
          });
          const indeterminate = isIndeterminate({
            type: "PERMISSION_CELL",
            row,
            allChecked: checked,
          });
          return (
            <Stack
              sx={{
                flexDirection: "row ",
              }}
              justifyContent="end"
            >
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={checked}
                    indeterminate={indeterminate}
                    color="primary"
                    sx={checkboxStyle}
                    onChange={(event) => {
                      handleCheckPermission({
                        event,
                        row,
                        type: "CELL",
                      });
                    }}
                  />
                }
              />
            </Stack>
          );
        },
      },
      {
        id: "manager-passcode",
        accessor: "permission",
        padding: "checkbox",
        disableSortBy: true,
        Header: (row) => {
          const isAllPermissionsChecked = isPermissionChecked({
            type: "ALL_PERMISSION",
          });
          const isAllManagerPasscodeChecked = isPermissionChecked({
            type: "ALL_MANAGER_PASSCODE",
          });
          const indeterminate = isIndeterminate({
            type: "MANAGER_PASSCODE_HEAD",
            row,
            allChecked: isAllManagerPasscodeChecked,
          });
          return (
            <Stack
              sx={{
                flexDirection: "row",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isAllPermissionsChecked}
                    checked={isAllManagerPasscodeChecked}
                    indeterminate={indeterminate}
                    color="primary"
                    onChange={(event) =>
                      handleCheckPermission({
                        event,
                        row,
                        type: "MANAGER_PASSCODE_HEAD",
                      })
                    }
                    sx={checkboxStyle}
                  />
                }
                labelPlacement="start"
                label={
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 600,
                      lineHeight: "15.73px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Manager Passcode
                  </Typography>
                }
              />
            </Stack>
          );
        },
        Cell: ({ row }) => {
          const permissionCellTpe =
            row.depth === 0 ? "MODULE_PERMISSION" : "UNIQUE_PERMISSION";
          const isPermitted = isPermissionChecked({
            type: permissionCellTpe,
            row,
          });
          const type =
            row.depth === 0
              ? "MANAGER_PASSCODE_MODULE_PERMISSION"
              : "MANAGER_PASSCODE_UNIQUE_PERMISSION";
          const checked = isPermissionChecked({
            type,
            row,
          });
          const indeterminate = isIndeterminate({
            type: "MANAGER_PASSCODE_CELL",
            row,
            allChecked: checked,
          });
          return (
            <Stack justifyContent="end">
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    disabled={!isPermitted}
                    checked={checked}
                    indeterminate={indeterminate}
                    color="primary"
                    sx={checkboxStyle}
                    onChange={(event) => {
                      handleCheckPermission({
                        event,
                        row,
                        type: "MANAGER_PASSCODE_CELL",
                      });
                    }}
                  />
                }
              />
            </Stack>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  const helperText = formValidator.current.message(
    "Role Name",
    state.formData.name,
    "required|min:3"
  );

  return updateMode && state.permissions.isLoading ? (
    <Loader />
  ) : (
    <ContentLayout
      title={updateMode ? state.roleDetails?.display_name : "New POS Role"}
      rightContent={
        <LoadingButton
          type="button"
          size="medium"
          variant="contained"
          onClick={handleFormSubmit}
          loading={state.isSubmitButtonLoading}
        >
          {updateMode ? "Update" : "Save"}
        </LoadingButton>
      }
      breadcrumbs={<HeaderBreadcrumbs links={breadcrumbsLinks} />}
    >
      <React.Fragment>
        <Card sx={{ p: "32px 24px", mb: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Basic Details
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack width={"100%"} gap="20px">
                <InputControl
                  inputLabel="Role Name *"
                  label="Role Name *"
                  name="name"
                  type="outlinedInput"
                  placeholder="Role Name"
                  onChange={(event) => handleFormChange(event)}
                  value={state.formData.name}
                  error={helperText ? Boolean(helperText) : undefined}
                  helperText={helperText}
                />
              </Stack>
            </Grid>
          </Grid>
        </Card>
        <PosPermissionSection
          permissionList={state?.permissions.allList}
          columns={columns}
          loading={state.permissions.isLoading}
        />
      </React.Fragment>
    </ContentLayout>
  );
};
