import { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import {
  getPermissionList,
  createRoleItem,
  getRoleDetailsList,
  editRoleItem,
} from "..";
import { useParams } from "react-router-dom";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";

export const useCreateOrEditRoles = (props) => {
  const { appState } = useContext(AppContext);
  const {
    globalData,
    currentTabs: { rolesAndPermissionList: currentTab },
  } = appState;
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useImmer({
    isLoading: true,
    allPermissionsList: [],
    data: {
      name: "",
      permission_id: [],
      type: null,
    },
    details: {},
  });

  useEffect(() => {
    if (currentTab) {
      setState((draft) => {
        draft.data.type = currentTab === "1" ? "super_admin" : "store_admin";
        return draft;
      });
    }
  }, [currentTab]);
  const HandleInitialPermission = async () => {
    try {
      const res = await getPermissionList();
      if (res.data) {
        setState((draft) => {
          draft.allPermissionsList = res.data;
          draft.isLoading = false;
          return draft;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const HandleRolesDetailsList = async () => {
    try {
      const res = await getRoleDetailsList(id);
      if (res.data) {
        let Result = res.data.permissions.map((val) => val.id);
        setState((draft) => {
          draft.details = res.data;
          draft.data.name = res.data.display_name;
          draft.data.permission_id = res.data.permissions.map((val) => val.id);
          draft.data.type = res.data.type;
        });
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };
  useEffect(() => {
    HandleInitialPermission();
    if (id) {
      HandleRolesDetailsList(id);
    }
  }, [id]);

  const handleAddRole = async () => {
    try {
      const res = await createRoleItem(state.data);
      if (res.success === true) {
        successMessage(res.message);
        navigate(-1);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };

  const handleEditRole = async () => {
    try {
      const res = await editRoleItem(id, { ...state.data, role_id: id });

      if (res.success === true) {
        successMessage(res.message);
        navigate(-1);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };

  const handleChecked = (depth, id, subRows) => {
    if (depth === 1) {
      const check = state.data.permission_id.some((e) => e === id);
      return check;
    } else if (depth === 2) {
      const permission_id_arry = state.allPermissionsList.flatMap((e, i) => {
        return e.permissions.map((e) => {
          return e.id;
        });
      });
      return state.data.permission_id.length === permission_id_arry.length;
    } else {
      const subArray = subRows?.map((e, i) => {
        return e.id;
      });
      return subArray.every((val) => state.data.permission_id.includes(val));
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setState((draft) => {
      draft.data[name] = value;
    });
  };

  const handleCheckPermission = (e, row, type) => {
    if (type === "head") {
      if (e.target.checked) {
        const permission_id_arry = state.allPermissionsList.flatMap((e, i) => {
          return e.permissions.map((e) => {
            return e.id;
          });
        });
        setState((draft) => {
          draft.data.permission_id = permission_id_arry;
        });
      } else {
        setState((draft) => {
          draft.data.permission_id = [];
        });
      }
    } else {
      if (row.depth) {
        if (e.target.checked) {
          setState((draft) => {
            draft.data.permission_id = [
              ...draft.data.permission_id,
              row.original.id,
            ];
          });
        } else {
          setState((draft) => {
            draft.data.permission_id = draft.data.permission_id.filter(
              (val) => val !== row.original.id
            );
          });
        }
      } else {
        const subRowsID = row.originalSubRows?.map((e, i) => {
          return e.id;
        });
        if (e.target.checked) {
          setState((draft) => {
            draft.data.permission_id = [
              ...new Set([...state.data.permission_id, ...subRowsID]),
            ];
          });
        } else {
          setState((draft) => {
            draft.data.permission_id = draft.data.permission_id.filter(
              (val) => !subRowsID.includes(val)
            );
          });
        }
        setState((draft) => {
          draft.data.permission_id = draft.data.permission_id.filter(
            (val) => val !== row.original.id
          );
        });
      }
    }
  };

  const handleCheckindeterminate = (type, row) => {
    if (type === "head") {
      const permission_id_arry = state.allPermissionsList.flatMap((e, i) => {
        return e.permissions.map((e) => {
          return e.id;
        });
      });

      return (
        permission_id_arry.length !== state.data.permission_id.length &&
        state.data.permission_id.length > 0
      );
    } else if (row.depth === 0) {
      const subArray = row.originalSubRows.map((e, i) => {
        return e.id;
      });

      const commonIDs = state.data.permission_id.filter((val) =>
        subArray.includes(val)
      );

      const notAllIDs = subArray.every((val) =>
        state.data.permission_id.includes(val)
      );

      return commonIDs.length > 0 && !notAllIDs;
    }
  };

  return {
    state,
    handleCheckPermission,
    handleChecked,
    handleOnChange,
    handleAddRole,
    handleEditRole,
    handleCheckindeterminate,
    globalData,
  };
};
