import { ContentLayout, HeaderBreadcrumbs, Loader, PageNotFound } from "../../../shared";
import React from "react";
import { Button, Stack, Card, Typography, Grid } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";

import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useNavigate } from "react-router-dom";
import { useBundles } from "../hooks";

export const CreateOrEditBundles = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    state,
    globalData,
    onChangeInput,
    onSaveBundleGroup,
    onUpdateBundleGroup,
  } = useBundles({ isDetailsId: id, isAdd: true, });
  const { updateData, isSaveButtonBusy, BundleGroupDetails } = state;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        maxChoice: {
          message: "The Maximum Choice should greater than Minimum Choice",
          rule: (value) => {
            const { max_choice, min_choice } = value;
            return parseInt(max_choice) >= parseInt(min_choice)
          },
        },
      },
    })
  );

  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdateBundleGroup(id) : onSaveBundleGroup();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }
  return (
    <>
      <ContentLayout
        title={id ? BundleGroupDetails.dataList?.name : "New Bundles"}
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              disabled={isSaveButtonBusy}
              onClick={() => onSubmit()}
            >
              {!id ? "Save" : "Update"}
            </Button>
          </>
        }
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Bundle Groups", href: "/bundle" },
              id ? {
                name: BundleGroupDetails.dataList?.name,
                href: `/bundle/details/${id}`,
              } : null,
              { name: id ? "Edit" : "Create" },
            ].filter(Boolean)}
          />
        }
      >
        {id && BundleGroupDetails.isLoading ? (
          <Loader />
        ) : (
          <Card sx={{ p: "32px 24px", mb: "24px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  sx={{ width: "150px" }}
                >
                  Details
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <Stack width={"100%"} gap="20px">
                  <InputControl
                    name="name"
                    label="Bundle Name"
                    onChange={onChangeInput}
                    value={updateData.name}
                    required
                    error={validator.current.message(
                      "Bundle Name",
                      updateData.name,
                      "required|min:3"
                    )}
                    helperText={validator.current.message(
                      "Bundle Name",
                      updateData.name,
                      "required|min:3"
                    )}
                  />
                  <InputControl
                    name="alias"
                    label="Alias"
                    autoComplete="off"
                    onChange={onChangeInput}
                    value={updateData.alias}
                    required
                    error={validator.current.message(
                      "Alias",
                      updateData.alias,
                      "required"
                    )}
                    helperText={validator.current.message(
                      "Alias",
                      updateData.alias,
                      "required"
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Card>
        )}
      </ContentLayout>
    </>
  );
};
