import { useImmer } from "use-immer";
import {
  downloadReport,
  getAccountList,
  getListById,
  updateExcel,
  uploadExcel,
} from "../api/ListApis";
import { errorMessage, successMessage } from "../../../utils";
import React, { useEffect } from "react";
import { useDebounce } from "use-debounce";
import qs from "qs";
import { useDropdownApis, usePermission } from "../../../shared";
import { AppContext } from "../../../store";
import { useNavigate } from "react-router-dom";
var fileDownload = require("js-file-download");

export const useList = ({ isList, detailsId, isStore }) => {
  const navigate = useNavigate();
  const { appState } = React.useContext(AppContext);
  const { globalData } = appState;
  const [state, setState] = useImmer({
    isBusy: false,
    isSearching: false,
    accountList: [],
    searchText: "",
    data: {
      store_id: "",
      month: "",
      year: "",
      excel_file: "",
    },
    file: "",
  });

  const isCreateVisible = usePermission("pl-bs-account-create");
  const isEditable = usePermission("pl-bs-account-modify");

  const { storeList } = useDropdownApis({
    isStoreList: isStore,
  });
  const [debouncedText] = useDebounce(state.searchText, 1000);
  useEffect(() => {
    if (state.searchText !== null && !state.isSearching && isList) {
      accountList(debouncedText);
    }
  }, [debouncedText, isList]);

  const accountList = async (searchText = "") => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      let params = {};
      if (searchText) {
        params = { ...params, search: searchText };
      }

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };
      const res = await getAccountList(query);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.isSearching = false;
          draft.accountList = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.franchiseeDetails.isBusy = false;
        draft.isSearching = false;
      });
    }
  };
  const handleSearch = (v) => {
    setState((draft) => {
      draft.searchText = v;
    });
  };

  const onSeachClear = () => {
    setState((draft) => {
      draft.searchText = "";
    });
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };
  const getListDetails = async (detailsId) => {
    setState((draft) => {
      draft.isBusy = true;
    });
    try {
      const res = await getListById(detailsId);
      if (res.success) {
        setState((draft) => {
          draft.data = {
            ...res?.data,
            store_id: res?.data?.store_id,
            month: res?.data?.month,
            year: res?.data?.year,
            file: res?.data?.excel_file,
          };
          draft.isBusy = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (detailsId) {
      getListDetails(detailsId);
    }
  }, [detailsId]);

  const handleUploadExcel = async () => {
    let formData = new FormData();
    Object.keys(state.data).map((key) => {
      if (key !== "excel_file") {
        formData.append(key, state.data[key]);
      }
    });
    formData.append("excel_file", state.file);
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await uploadExcel(formData);
      if (res.success) {
        successMessage("PL & BS Account file uploaded successfully.");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate("/list");
      } else {
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const handleUpdateUploadExcel = async () => {
    let formData = new FormData();

    state.file && formData.append("excel_file", state.file);
    formData.append("store_id", state.data.store_id);
    formData.append("month", state.data.month);
    formData.append("year", state.data.year);
    formData.append("_method", "put");
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await updateExcel(formData, detailsId);
      if (res.success) {
        successMessage("PL & BS Account file uploaded successfully.");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate("/list");
      } else {
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const handleInputChangeFile = (e) => {
    setState((draft) => {
      draft.file = e?.target?.files[0];
    });
  };
  const handlePSAndBSReportList = async (id) => {
    try {
      const res = await downloadReport(id);
      const content = res.headers["content-type"];
      fileDownload(res.data, `PL&BS Report.xlsx`, content);
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  return {
    state,
    handleSearch,
    onSeachClear,
    storeList,
    handleInputChange,
    handleUploadExcel,
    handleInputChangeFile,
    handleUpdateUploadExcel,
    handlePSAndBSReportList,
    globalData,
    isCreateVisible,
    isEditable,
  };
};
