import React from "react";
import { Route, Routes } from "react-router-dom";
import { AddOrEditConfigurations } from "./containers";
import { ProtectedRoute } from "../../shared";

export const ProductConfigurations = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute allowedRoute={["review-rating-view"]} />}>
        <Route path="/" element={<AddOrEditConfigurations />} />
      </Route>
    </Routes>
  );
};
