import { REPORT_API } from "../../../utils";

export const DownloadReportList = async (payload) => {
  const res = await REPORT_API.post(`reports`, payload);
  return res;
};
export const DownloadReportTableList = async (query) => {
  const res = await REPORT_API.post(`export/reports`, query, {
    responseType: "blob",
  });
  return res;
};
