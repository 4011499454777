import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { usePermission } from "../../../shared";
import qs from "qs";
import { useDebouncedCallback } from "use-debounce";
import { DownloadRefundList, getRefund } from "../api/refundApi";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { errorMessage } from "../../../utils";
import { AppContext, TableStateContext } from "../../../store";
var fileDownload = require("js-file-download");

export const useRefundList = ({ isRefund }) => {
  const PAGE_NAME = "refunds";
  const {
    filterState: {
      params: { refunds },
      params: {
        refunds: { columnOptions },
      },
    },
    filterStateDispatch,
  } = useContext(TableStateContext);
  const { appState } = useContext(AppContext);
  const { globalData } = appState;

  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const isDeletable = usePermission("refund-delete");
  const isCreateVisible = usePermission("refund-create");
  const isEditable = usePermission("refund-modify");
  const isVisible = usePermission("refund-view");
  const [state, setState] = useImmer({
    isBusy: true,
    disableApplyButton: false,
    refund: [],
    sortBy: [],
    pagination: {
      pageIndex: 1,
      pageSize: 50,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    isOpen: false,
    id: null,
    isSaveButtonBusy: false,
    order: [],
    refund_number: "",
    display_name: "",
    httpStatusCodes: {
      details: "",
    },
    filterApplied: {
      isSearching: false,
      searchText: "",
      stores: [],
      date: {},
      channel: [],
    },
    isCalenderOpen: false,
    date: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    showDate: [],
  });

  useEffect(() => {
    if (isRefund) {
      const { stores, date, searchText, channel } = refunds.filterApplied;
      const { pagination, sortBy } = refunds;
      setState((draft) => {
        draft.filterApplied = refunds.filterApplied;
        draft.pagination = pagination;
        draft.sortBy = sortBy;
        if (Object.keys(date).length) {
          draft.date = [
            {
              endDate: new Date(date.value.endDate),
              startDate: new Date(date.value.startDate),
              key: "selection",
            },
          ];
          draft.showDate = [
            {
              endDate: new Date(date.value.endDate),
              startDate: new Date(date.value.startDate),
              key: "selection",
            },
          ];
        }
      });

      getRefunds({
        searchText,
        stores,
        date,
        pagination,
        sortBy,
        channel,
      });
    }
  }, [isRefund]);
  const debounced = useDebouncedCallback((value) => {
    const { stores, date, channel } = state.filterApplied;
    const { pagination, sortBy } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filterApplied },
    });
    getRefunds({
      searchText: value,
      stores,
      date,
      channel,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  }, 1000);

  const getRefunds = async ({
    stores = [],
    searchText = "",
    date = {},
    pagination,
    sortBy = [],
    channel = [],
  }) => {
    try {
      let IDs = stores.map((v) => {
        return v.value;
      });
      let SCIDs = channel.map((v) => {
        return v.value;
      });
      let params = {};

      if (searchText) {
        params = { ...params, search: searchText };
      }
      if (Object.keys(date).length) {
        params = {
          ...params,
          start_date: moment(date.value.startDate).format("YYYY-MM-DD"),
          end_date: moment(date.value.endDate).format("YYYY-MM-DD"), 
        };
      }
      if (IDs.length > 0) {
        params = { ...params, store_id: IDs };
      }
      if (SCIDs.length > 0) {
        params = { ...params, channel: SCIDs };
      }

      if (sortBy.length > 0) {
        const sortByParams = sortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };
      setState((draft) => {
        draft.isBusy = true;
        draft.disableApplyButton = true;
      });
      const res = await getRefund(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.refund = res.data.refunds;
          draft.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
        draft.disableApplyButton = false;
      });
    }
  };

  const handleFilter = (e, value, type) => {
    if (type === "store") {
      setState((draft) => {
        draft.filterApplied.stores = value.map((v) => {
          return { ...v, type: "Store" };
        });
      });
    }
    if (type === "channel") {
      setState((draft) => {
        draft.filterApplied.channel = value.map((v) => {
          return { ...v, type: "Sales Channel" };
        });
      });
    }
    setState((draft) => {
      draft.disableApplyButton = false;
    });
  };

  const filterCollections = [
    state.filterApplied.date,
    ...state.filterApplied.stores,
    ...state.filterApplied.channel,
  ].filter((el) => Object.keys(el).length);

  const onChangeFilter = (v) => {
    setState((draft) => {
      draft.filterApplied.searchText = v;
    });
    debounced(v);
  };
  const handleApplyFilter = () => {
    const { stores, searchText, date, channel } = state.filterApplied;
    const { pagination, sortBy } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: state.filterApplied,
        pagination,
        sortBy,
      },
    });
    getRefunds({
      searchText,
      stores,
      date,
      channel,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  };

  const handleFilterClear = (type, value) => {
    const { pagination, sortBy, filterApplied } = state;
    if (type === "single") {
      if (value.type === "Store") {
        const filteredStores = state.filterApplied.stores.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.stores = filteredStores;
        });
      }

      if (value.type === "Sales Channel") {
        const filtered = state.filterApplied.channel.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.channel = filtered;
        });
      }


      if (value.type === "Date") {
        setState((draft) => {
          draft.filterApplied.date = {};
          draft.showDate = [];
        });
      }

      setState((draft) => {
        draft.disableApplyButton = false;
      });
    } else {
      setState((draft) => {
        draft.filterApplied.date = {};
        draft.showDate = [];
        draft.filterApplied.stores = [];
        draft.filterApplied.channel = [];
        draft.date = [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ];
      });
      const initialValue = {
        all: [],
        searchText: filterApplied.searchText,
        stores: [],
        date: [],
        channel: [],
      };
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initialValue,
      });
      getRefunds({
        pagination: {
          pageIndex: 1,
          pageSize: pagination.pageSize,
        },
        sortBy,
        searchText: filterApplied.searchText,
      });
    }
  };

  const gotoPage = (page) => {
    const { stores, searchText, date, channel } = state.filterApplied;
    const { sortBy } = state;
    getRefunds({
      searchText,
      stores,
      date,
      channel,
      pagination: {
        pageIndex: page,
        pageSize: state.pagination.pageSize,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };

  const setPageSize = (e) => {
    const { stores, searchText, date, channel } = state.filterApplied;
    const { sortBy } = state;
    getRefunds({
      searchText,
      stores,
      date,
      channel,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };

  const handleDownloadRefundList = async () => {
    try {
      const { stores, date, searchText, channel } = state.filterApplied;
      const { sortBy } = state;
      let params = {};
      let IDs = stores.map((v) => {
        return v.value;
      });
      let SCIDs = channel.map((v) => {
        return v.value;
      });
      if (searchText) {
        params = { ...params, search: searchText };
      }

      if (Object.keys(date).length) {
        params = {
          ...params,
          start_date: moment(date.value.startDate).format("YYYY-MM-DD"),
          end_date: moment(date.value.endDate).format("YYYY-MM-DD"),
        };
      }
      if (IDs.length > 0) {
        params = { ...params, store_id: IDs };
      }
      if (SCIDs.length > 0) {
        params = { ...params, channel: SCIDs };
      }
      if (sortBy.length > 0) {
        const sortByParams = sortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }
      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };

      const res = await DownloadRefundList(query);
      const content = res.headers["content-type"];
      fileDownload(res.data, `RefundList.xlsx`, content);
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleSort = (sortBy) => {
    const { stores, searchText, date, channel } = state.filterApplied;
    const { pagination } = state;
    getRefunds({
      searchText,
      stores,
      date,
      channel,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_SORT",
      page: PAGE_NAME,
      value: sortBy,
    });
    setState((draft) => {
      draft.sortBy = sortBy;
    });
  };

  const onChangeDate = (e) => {
    setState((draft) => {
      draft.date = e;
    });
  };
  const handleIsOpen = (e) => {
    setState((draft) => {
      draft.isCalenderOpen = e;
    });
  };

  const handleDateChangeApply = () => {
    setState((draft) => {
      draft.showDate = state.date;
      draft.isCalenderOpen = false;
      draft.filterApplied.date = {
        value: state.date[0],
        label: `${moment(state.date[0].startDate).format(
          "yyy-MM-DD"
        )} to ${moment(state.date[0].endDate).format("yyy-MM-DD")}`,
        type: "Date",
      };
      draft.disableApplyButton = false;
    });
  };

  const toggleHiddenColumns = (column) => {
    filterStateDispatch({
      type: "UPDATE_COLUMNS",
      page: PAGE_NAME,
      value: column,
    });
  };

  return {
    state,
    globalData,
    handleFilter,
    handleApplyFilter,
    handleFilterClear,
    onChangeFilter,
    isCreateVisible,
    isDeletable,
    isEditable,
    isVisible,
    isStoreAdmin,
    gotoPage,
    setPageSize,
    handleDownloadRefundList,
    handleSort,
    onChangeDate,
    handleIsOpen,
    handleDateChangeApply,
    filterCollections,
    columnOptions,
    toggleHiddenColumns,
  };
};
