import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { errorMessage } from "../../../utils";
import { getManagerPasswordsList, EnableOrDisablePasswords } from "../api";

export const useManagerPassword = () => {
  const [state, setState] = useImmer({
    isLoading: true,
    dataList: [],
  });

  useEffect(() => {
    HandleManagerPasswordsList();
  }, []);

  const HandleManagerPasswordsList = async () => {
    try {
      const res = await getManagerPasswordsList();
      if (res.success) {
        setState((draft) => {
          draft.dataList = res.data;
          draft.isLoading = false;
        });
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const HandlePasswordsAction = async (id) => {
    try {
      const res = await EnableOrDisablePasswords(id);
      if (res.success) {
        setState((draft) => {
          draft.dataList = state.dataList.map((v) => {
            return v.id === id ? { ...v, status: v.status ? 0 : 1 } : v;
          });
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  return {
    state,
    HandlePasswordsAction,
  };
};
