import * as React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  FormControl,
  Button,
  Stack,
  InputAdornment,
  OutlinedInput,
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Loader } from "../../../shared";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";

export const InputCheckBoxListDialog = (props) => {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        Max: {
          message: "The Maximum should greater than or equal to Minimum ",
          required: true,
          rule: (value) => {
            const { max, min } = value;
            return min && parseInt(max) >= parseInt(min);
          },
        },
      },
    })
  );
  const {
    Items,
    onSearch,
    loading,
    onSelect,
    onSubmit,
    disabled = false,
    value,
    onClearSearch,
    searchBar = true,
    subHeader = "",
    selectedItems,
    inputChange,
    keys,
    options,
    page,
    isBusy,
  } = props;

  const handleSubmit = () => {
    if (validator.current.allValid()) {
      onSubmit();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      {validator.current.purgeFields()}
      {loading ? (
        <Loader />
      ) : page === 1 ? (
        <Stack
          justifyContent="space-between"
          height="100%"
          gap="8px"
          maxHeight={"calc(100% - 24px)"}
        >
          {subHeader && (
            <Typography
              sx={{
                pt: "27px",
              }}
            >
              {subHeader}
            </Typography>
          )}
          {searchBar && (
            <FormControl
              size="small"
              fullWidth={true}
              sx={{
                pt: "24px",
              }}
            >
              <OutlinedInput
                placeholder="Search"
                sx={{
                  height: "48px",
                  ".MuiOutlinedInput-input": {
                    pl: "0px !important",
                  },
                }}
                size="small"
                value={value}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon size="large" />
                  </InputAdornment>
                }
                endAdornment={
                  value &&
                  onClearSearch && (
                    <InputAdornment
                      sx={{ cursor: "pointer" }}
                      onClick={onClearSearch}
                      position="end"
                    >
                      <CloseIcon size="large" />
                    </InputAdornment>
                  )
                }
                fullWidth
                onChange={(e) => {
                  onSearch("search", e.target.value);
                }}
              />
            </FormControl>
          )}
          <Box sx={{ height: "100%", overflow: "auto" }} fullWidth>
            {Items?.length > 0 ? (
              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                {Items?.map((value, index) => {
                  const labelId = `checkbox-list-label-${index}`;
                  return (
                    <ListItem key={index} disablePadding sx={{}}>
                      <ListItemButton
                        role={undefined}
                        onClick={() => onSelect(value.id)}
                        dense
                        sx={{
                          minHeight: "50px",
                          borderBottom: "1px solid #E6E6E6",
                          "&:hover": {
                            backgroundColor: "rgba(21, 96, 212, 0.08)",
                            borderRadius: "8px",
                          },
                        }}
                      >
                        <ListItemText id={labelId} primary={value.name} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <Stack justifyContent="center" alignItems="center" height="100%">
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="caption"
                  color="text.secondary"
                  fontWeight={400}
                >
                  There is no data found
                </Typography>
              </Stack>
            )}
          </Box>
          <Button
            sx={{ height: "48px", width: "172px", alignSelf: "end" }}
            variant="contained"
            onClick={handleSubmit}
            disabled={disabled || loading}
          >
            Add
          </Button>
        </Stack>
      ) : (
        <>
          <Stack
            justifyContent="space-between"
            height="100%"
            gap="8px"
            maxHeight={"calc(100% - 24px)"}
            mt="24px"
          >
            <Stack gap="12px">
              <Typography variant="subtitle2" color="#636363">
                Type
              </Typography>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={keys[2]}
                sx={{
                  ml: "4px",
                  justifyContent: "space-between",
                  flexDirection:
                    keys[2] === "control_type" ? "row" : "row-reverse",
                }}
                value={selectedItems[0][keys[2]]}
                onChange={(e) => inputChange(e, selectedItems[0].id)}
              >
                {options.map((data) => (
                  <FormControlLabel
                    value={data.value}
                    control={<Radio />}
                    label={
                      <Typography variant="subtitle2" fontWeight={400}>
                        {data.label}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
              <Typography variant="subtitle2" color="#636363">
                Quantity
              </Typography>
              <Stack direction="row" gap="20px">
                <InputControl
                  name={keys[0]}
                  type="number"
                  label="Minimum"
                  width="100%"
                  isDisabledKeys={["-"]}
                  onKeyDown={(evt) => {
                    (evt.key === "e" ||
                      evt.key === "ArrowDown" ||
                      evt.key === ".") &&
                      evt.preventDefault();
                  }}
                  value={selectedItems[0][keys[0]]}
                  onChange={(e) => inputChange(e, selectedItems[0].id)}
                  error={validator.current.message(
                    `Minimum`,
                    selectedItems[0][keys[0]],
                    "required"
                  )}
                  helperText={validator.current.message(
                    `Minimum`,
                    selectedItems[0][keys[0]],
                    "required"
                  )}
                />
                <InputControl
                  name={keys[1]}
                  type="number"
                  label="Maximum"
                  width="100%"
                  isDisabledKeys={["-"]}
                  onKeyDown={(evt) => {
                    (evt.key === "e" ||
                      evt.key === "ArrowDown" ||
                      evt.key === ".") &&
                      evt.preventDefault();
                  }}
                  value={selectedItems[0][keys[1]]}
                  onChange={(e) => inputChange(e, selectedItems[0].id)}
                  error={validator.current.message(
                    "Maximum",
                    (selectedItems[0][keys[0]] && !selectedItems[0][keys[1]]) ? null : {
                      max: selectedItems[0][keys[1]],
                      min: selectedItems[0][keys[0]],
                    },
                    "required|Max"
                  )}
                  helperText={validator.current.message(
                    "Maximum",
                    (selectedItems[0][keys[0]] && !selectedItems[0][keys[1]]) ? null : {
                      max: selectedItems[0][keys[1]],
                      min: selectedItems[0][keys[0]],
                    },
                    "required|Max"
                  )}
                />
              </Stack>
            </Stack>

            <Button
              sx={{ height: "40px", alignSelf: "end" }}
              variant="contained"
              onClick={() => handleSubmit()}
              disabled={isBusy}
            >
              Add
            </Button>
          </Stack>
        </>
      )}
    </>
  );
};
