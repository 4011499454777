import { useEffect } from "react";
import { useImmer } from "use-immer";
import { usePermission } from "../../../shared";
import { getRefundById } from "../api/refundApi";
import { useLocation } from "react-router-dom";
import { errorMessage } from "../../../utils";

export const useRefund = ({ refundId }) => {
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const isDeletable = usePermission("refund-delete");
  const isCreateVisible = usePermission("refund-create");
  const isEditable = usePermission("refund-modify");
  const isVisible = usePermission("refund-view");
  const [state, setState] = useImmer({
    isBusy: false,
    refund_number: "",
    display_name: "",
    httpStatusCodes: {
      details: "",
    },
    data: {
      id: "",
      refund_number: "",
      refund_date: "",
      total_product_count: "",
      total_quantity_ordered: "",
      channel_id: "",
      channel_name: "",
      user_id: "",
      store_id: "",
      subtotal: "",
      tax_amount: "",
      order_id: "",
      terminal_id: "",
      grand_total: "",
      discount_amount: "",
      products: [],
      payments: [],
      order: [],
      status: "",
    },
  });




  const handleRefundDetailsList = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getRefundById(refundId);
      if (res.data) {
        setState((draft) => {
          draft.data = res.data;
          draft.display_name = res.data.display_name;
          draft.refund_number = res.data.refund_number;
          draft.isBusy = false;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.httpStatusCodes.details = err.response.status;
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (refundId) {
      handleRefundDetailsList();
    }
  }, [refundId]);
  return {
    state,
    isCreateVisible,
    isDeletable,
    isEditable,
    isVisible,
    isStoreAdmin,
  };
};
