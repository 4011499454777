import { API } from "../../utils";

export const getNotifications = async () => {
  const res = await API.get("notifications");
  return res.data;
};

export const deleteAllNotifications = async () => {
  const res = await API.delete(`notifications`);
  return res.data;
};

export const deleteNotificationById = async (itemId) => {
  const res = await API.delete(`notifications/${itemId}`);
  return res.data;
};
