import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  CustomersList,
  CreateOrEditCustomers,
  CustomersDetailsList,
  ClientAccountDetails,
  CustomersPointTransaction,
  StoreAdminClientAccountDetailsList,
  CreateClientAccount,
} from "..";
import { ProtectedRoute } from "../../shared";

export const Customers = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<CustomersList />} />
        <Route element={<ProtectedRoute allowedRoute={["customer-modify"]} />}>
          <Route path="/edit/:id" element={<CreateOrEditCustomers />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["customer-create"]} />}>
          <Route path="/create" element={<CreateOrEditCustomers />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["customer-view"]} />}>
          <Route path="/details/:id" element={<CustomersDetailsList />} />
        </Route>

        <Route element={<ProtectedRoute allowedRoute={["customer-view"]} />}>
          <Route
            path="/details/:id/customerspointTransaction/:id"
            element={<CustomersPointTransaction />}
          />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["client-account-modify"]} />}
        >
          <Route
            path="/details/:id/clientaccountId/:cid"
            element={<StoreAdminClientAccountDetailsList />}
          />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["client-account-create"]} />}
        >
          <Route
            path="/details/:id/client-account/create"
            element={<CreateClientAccount />}
          />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["client-account-modify"]} />}
        >
          <Route
            path="/client-account/:id/edit/:cid"
            element={<ClientAccountDetails />}
          />
        </Route>
      </Routes>
    </>
  );
};
