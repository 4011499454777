import * as React from "react";
import { ServerPaginatedTable } from "../../../shared";
import { Stack, Chip, Box } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import optInPic from "../../../assets/images/Icons/ic_tick_filled_blue.svg";
import optOutPic from "../../../assets/images/Icons/ic_cancel-opt-out.svg";
import InputControl from "../../../shared/components/InputControl";
import CustomerReviewListFilterSection from "../../ReviewsAndRatings/components/ReviewListFilterSection";
import useReviewAndRatings from "../../ReviewsAndRatings/hooks/useReviewAndRatings";

export const CustomerReviewList = () => {
  const { id } = useParams();

  const {
    state,
    status,
    publishedStatus,
    handleAutocompleteServerSearch,
    handleProductFetch,
    gotoPage,
    handleSortBy,
    setPageSize,
    handleApplyFilter,
    handleClearFilter,
    handleTableSearch,
    handleClearAllFilter,
    handleReviewFilterSelection,
    handleDateChangeApply,
    handleOpenDateRangeSelection,
  } = useReviewAndRatings({
    table: "customerReviewList",
    defaultFilter: id
      ? {
          customer_id: id,
        }
      : undefined,
  });

  const ActionButtons = ({ row }) => {
    return (
      <div className="action-buttons">
        <Link to={"/reviews-and-ratings/review/" + row.id}>
          <img alt="details" src="/icons/arrow_forward.svg" />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Review ID",
        accessor: "id",
      },
      {
        Header: "Submitted Date & Time",
        accessor: "submitted_date",
      },
      {
        Header: "Rating",
        accessor: "rating",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="4px" height="16px">
              <img alt="star" src="/icons/star-yellow.svg" />
              {row.original.rating}
            </Stack>
          );
        },
      },
      {
        Header: "Product",
        accessor: "product_name",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          const label =
            value === 0 ? "Pending" : value === 1 ? "Approved" : "Rejected";
          const color =
            value === 0 ? "#C07302" : value === 1 ? "#0F875B" : "#C42B1C";
          const backgroundColor =
            value === 0 ? "#FFF4CE" : value === 1 ? "#E1FBF2" : "#FFE7E3";
          return (
            <Chip
              label={label}
              variant="filled"
              size="small"
              sx={{
                color,
                backgroundColor,
              }}
            />
          );
        },
      },
      {
        Header: "Authorised By",
        accessor: "authorised_by",
        Cell: ({ value }) => {
          return value ?? "--";
        },
      },
      {
        Header: "Publish Status",
        accessor: "published_status",
        align: "center",
        Cell: ({ row }) => {
          return (
            <Box
              mr="20px"
              component="img"
              src={row.original.published_status ? optInPic : optOutPic}
              alt={"published_status-" + row.original.id}
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons key={index} row={row} />;
        },
      },
    ],
    []
  );

  return (
    <ServerPaginatedTable
      columns={columns}
      data={state.review.list}
      isLoading={state.review.isLoading}
      gotoPage={gotoPage}
      searchBar={true}
      defaultSearch={false}
      handleSort={handleSortBy}
      setPageSize={setPageSize}
      pagination={state.review.pagination}
      sortByGlobal={state.review.sortBy}
      filterContent={
        <InputControl
          type="table-search"
          name="search"
          value={state.review.search}
          onChange={handleTableSearch}
          onClear={() => handleTableSearch({ target: { value: "" } })}
        />
      }
      filterApplied={state.filter.appliedFilters}
      onAllFilterClear={handleClearAllFilter}
      onFilterClear={handleClearFilter}
      filterSection={
        <CustomerReviewListFilterSection
          {...{
            state,
            statusList: status,
            productsList: state.product.options,
            ratingsList: state.rating.options,
            publishedStatusList: publishedStatus,
            handleProductFetch,
            handleAutocompleteServerSearch,
            handleReviewFilterSelection,
            handleOpenDateRangeSelection,
            handleDateChangeApply,
            handleApplyFilter,
          }}
        />
      }
      onRowLink={(row) => `/reviews-and-ratings/review/${row.id}`}
    />
  );
};
