import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import {
  getStateList,
  deleteStateById,
  createState,
  getStateDetailsById,
  editState,
  uploadData,
} from "../..";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import { useTabs } from "../../../shared/hooks/useTabs";
import { useDebouncedCallback } from "use-debounce";
import qs from "qs";
import { TableStateContext } from "../../../store";

export const useStateList = ({ isState, stateId }) => {
  const { appState } = useContext(AppContext);
  const { setCurentTab } = useTabs();
  const { stateandSuburb: currentTab } = appState.currentTabs;
  const {
    globalData: { imports },
  } = appState;
  const navigate = useNavigate();
  const [state, setState] = useImmer({
    stateDetails: {
      isBusy: false,
      isOpen: false,
      id: null,
      isSaveButtonBusy: false,
      initialLoad: true,
      pagination: {
        pageIndex: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      filterApplied: {
        search: null,
      },
      states: [],
      data: {
        name: "",
      },
    },

    httpStatusCodes: {
      stateDetails: "",
      suburbDetails: "",
    },
  });
  const PAGE_NAME = "stateData";
  const {
    filterState: {
      params: { stateData },
    },
    initialState,
    filterStateDispatch,
  } = useContext(TableStateContext);
  const debounced = useDebouncedCallback((value) => {
    const { search } = state.stateDetails.filterApplied;
    const { pagination, sortBy } = state.stateDetails;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.stateDetails.filterApplied },
    });
    getStatesList({
      search: value,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  }, 1000);

  useEffect(() => {
    const { search } = stateData?.filterApplied;
    const { pagination, sortBy } = stateData;
    if (isState) {
      setState((draft) => {
        draft.stateDetails.filterApplied = stateData.filterApplied;
        draft.stateDetails.pagination = pagination;
        draft.stateDetails.sortBy = sortBy;
      });
      getStatesList({
        search,
        pagination: {
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
        },
      });
    }
  }, [isState]);

  useEffect(() => {
    if (stateId) {
      getStateDetails(stateId);
    }
  }, [stateId]);

  const getStateDetails = async (stateId) => {
    try {
      const res = await getStateDetailsById(stateId);
      if (res.success) {
        setState((draft) => {
          draft.stateDetails.data = res.data;
          draft.stateDetails.stateName = res.data.name;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.httpStatusCodes.stateDetails = err.response.status;
      });
    }
  };

  const getStatesList = async ({ pagination, search }) => {
    let params = {};
    if (search) {
      params = { ...params, search };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.stateDetails.isBusy = true;
      });
      const res = await getStateList(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.stateDetails.isBusy = false;
          draft.stateDetails.initialLoad = false;
          draft.stateDetails.states = res.data.data;
          draft.stateDetails.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.stateDetails.isBusy = false;
      });
    }
  };

  const handleModal = (type, id = null) => {
    if (type === "state") {
      setState((draft) => {
        draft.stateDetails.id = id;
        draft.stateDetails.isOpen = !draft.stateDetails.isOpen;
      });
    }
  };
  const handleDeleteState = async () => {
    try {
      const res = await deleteStateById(state.stateDetails.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.stateDetails.states = draft.stateDetails.states.filter(
            (item) => item.id !== state.stateDetails.id
          );
        });
        handleModal("state");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onChange = (e, type) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft[type].data[name] = value;
    });
  };

  const onSaveState = async () => {
    try {
      setState((draft) => {
        draft.stateDetails.isSaveButtonBusy = true;
      });
      const res = await createState(state.stateDetails.data);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.stateDetails.isSaveButtonBusy = false;
        });
        navigate(-1);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.stateDetails.isSaveButtonBusy = false;
      });
    }
  };

  const onUpdateState = async (id) => {
    try {
      setState((draft) => {
        draft.stateDetails.isSaveButtonBusy = true;
      });
      const res = await editState(id, state.stateDetails.data);
      if (res.success) {
        successMessage("Updated Successfully");
        navigate("/state-and-suburb");
        setState((draft) => {
          draft.stateDetails.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.stateDetails.isSaveButtonBusy = false;
      });
    }
  };

  const stateList = React.useMemo(() => {
    return state.stateDetails.states
      ? state.stateDetails.states.map((c) => {
          return { value: c.id, label: c.name };
        })
      : [];
  }, [state.stateDetails.states]);

  // suburb

  const onUpload = async (e) => {
    try {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      const res = await uploadData(formData);
      if (res.success) {
        successMessage(res.message);
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message ?? err.message);
    }
  };

  const onSearch = (e, type) => {
    setState((draft) => {
      draft[type].filterApplied.search = e.target.value;
    });
    debounced(e.target.value);
  };

  const onSeachClear = (type) => {
    const { status } = state.stateDetails.filterApplied;
    const { pagination, sortBy } = state.stateDetails;
    getStatesList({
      search: null,
      status,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft[type].filterApplied.search = "";
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: { ...state.filterApplied, searchText: "" } },
    });
  };
  const gotoPage = (page, type) => {
    if (type === "state") {
      const { search } = state.stateDetails.filterApplied;
      const { pagination } = state.stateDetails;

      getStatesList({
        search,
        pagination: {
          pageIndex: page,
          pageSize: pagination.pageSize,
        },
      });
      setState((draft) => {
        draft.stateDetails.pagination.pageIndex = page;
      });
    }
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
  };

  const setPageSize = (e, type) => {
    if (type === "state") {
      const { search } = state.stateDetails.filterApplied;
      const { pagination } = state.stateDetails;
      getStatesList({
        search,
        pagination: {
          pageIndex: 1,
          pageSize: +e.target.value,
        },
      });
      setState((draft) => {
        draft.stateDetails.pagination.pageSize = +e.target.value;
        draft.stateDetails.pagination.pageIndex = 1;
      });
    }
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: 1,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
  };

  return {
    state,
    handleModal,
    handleDeleteState,
    onChange,
    onSaveState,
    onUpdateState,
    stateList,
    currentTab,
    setCurentTab,
    imports,
    onUpload,
    onSeachClear,
    setPageSize,
    gotoPage,
    onSearch,
  };
};
