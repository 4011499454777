import { useRef, useState, useEffect } from "react";
import { Box, FormControl, Typography, Card, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SimpleReactValidator from "simple-react-validator";
import InputControl from "../../../shared/components/InputControl";
import { useSearchParams } from "react-router-dom";
import { useAuthenticate } from "../hooks";

export function ResetPassword() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const { state, setState, onReset, handleResetPassword } = useAuthenticate();
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const toggleShowPassword = (name) => {
    setState((draft) => {
      draft.data[name] = !draft.data[name];
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    setState((draft) => {
      draft.reset.token = token;
      draft.reset.email = email;
    });
  }, []);
  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Card align="center" sx={{ width: 424, height: 360 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={{ mt: 2 }} variant="h5" gutterBottom>
              Reset Password
            </Typography>

            {/* <Typography sx={{ mt: 1 }} variant="h7" gutterBottom>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography> */}
          </Box>
          <Box component="input-box" sx={{ display: "flex" }}>
            <FormControl sx={{ width: "376px", mb: 2 }} variant="outlined">
              {state.errors && (
                <Alert sx={2} severity="error">
                  {state.errors}
                </Alert>
              )}
            </FormControl>
            <FormControl sx={{ mt: 2, width: "376px" }} variant="outlined">
              <InputControl
                id="outlined-adornment-password"
                type="password"
                name="password"
                placeholder="Enter password"
                onChange={onReset}
                showPassword={state.data.showNewPassword}
                value={state.reset.password}
                error={validator.current.message(
                  "New Password",
                  state.reset.password,
                  "required|min:8"
                )}
                helperText={validator.current.message(
                  "New Password",
                  state.reset.password,
                  "required|min:8"
                )}

                onClick={(e) => toggleShowPassword("showNewPassword")}
                onMouseDown={handleMouseDownPassword}
                label="New Password"
              />
            </FormControl>
          </Box>
          <Box component="input-box" sx={{ display: "flex" }}>
            <FormControl sx={{ mt: 2, width: "376px" }} variant="outlined">
              <InputControl
                id="outlined-adornment-password"
                type="password"
                showPassword={state.data.showConfirmPassword}
                name="password_confirmation"
                placeholder="Enter password"
                onChange={onReset}
                value={state.reset.password_confirmation}
                error={validator.current.message(
                  "Confirm Password",
                  state.reset.password_confirmation,
                  "required|min:8"
                )}
                helperText={validator.current.message(
                  "Confirm Password",
                  state.reset.password_confirmation,
                  "required|min:8"
                )}
                onClick={(e) => toggleShowPassword("showConfirmPassword")}
                onMouseDown={handleMouseDownPassword}
                label="Confirm Password"
              />
            </FormControl>
          </Box>

          <FormControl sx={{ mt: 2, width: "376px" }} variant="outlined">
            <LoadingButton
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={state.isBusy}
              onClick={() => {
                if (validator.current.allValid()) {
                  handleResetPassword();
                } else {
                  validator.current.showMessages();
                  forceUpdate(1);
                }
              }}
            >
              Reset Password
            </LoadingButton>
          </FormControl>
        </Card>
      </Box>
    </>
  );
}
