import { API } from "../../../utils";

/**
 * Fetches the payment media report based on pagination and additional parameters.
 *
 * @async
 * @param {Object} params - The parameters for the API request.
 * @param {number} params.pageSize - The number of items per page.
 * @param {number} params.pageIndex - The current page index.
 * @param {string} [params.search] - An optional search term for filtering results.
 * @returns {Promise<Object>} A promise that resolves to the response data.
 */
export const getPaymentMediaReportApi = async ({
  pageSize,
  pageIndex,
  ...params
}) => {
  if (params.search === "") delete params.search;
  const response = await API.get(
    `payment-media-log?page=${pageIndex}&per_page=${pageSize}`,
    { params: params }
  );
  return response.data;
};

/**
 * Downloads the payment media report based on provided parameters.
 *
 * @async
 * @param {Object} params - The parameters for the export request.
 * @returns {Promise<Object>} A promise that resolves to the response data.
 */
export const downloadPaymentMediaReportApi = async (params) => {
  const response = await API.get(`/payment-media-export`, { params });
  return response.data;
};
