import * as React from "react";
import { Button, Stack } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";

export const ShareGiftCard = (prop) => {
  const { handleShareGiftCards, onEmailChange, state } = prop;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const onSubmit = () => {
    if (validator.current.allValid()) {
      handleShareGiftCards();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  return (
    <>
      <Stack height="100%">
        <InputControl
          name="email"
          label="Email Id"
          sx={{
            width: "100%",
            bgcolor: "#fff",
            m: "68px 0 44px",
          }}
          value={state.email}
          onChange={(e) => onEmailChange(e)}
          error={validator.current.message(
            "Email Id",
            state.email,
            "required|email"
          )}
          helperText={validator.current.message(
            "Email Id",
            state.email,
            "required|email"
          )}
          required
        />

        <Button
          variant="contained"
          size="small"
          onClick={onSubmit}
          sx={{
            alignItems: "left",
            width: "202px",
            alignSelf: "end",
          }}
          disabled={state.isSaveButtonBusy}
        >
          Share
        </Button>
      </Stack>
    </>
  );
};
