import * as React from "react";
import { Typography, Button, Stack, Chip, Box, Tab } from "@mui/material";

import {
  ContentLayout,
  CustomDetailsList,
  CustomeDialog,
  HeaderBreadcrumbs,
  Loader,
} from "../../../shared";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { CustomTable } from "../../../shared";
import { sortItems } from "../../../utils";
import {
  CreditTransactionDetails,
  SettlementDescription,
  useClientAccount,
  useCustomers,
} from "../..";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { LedgerAccountsList } from "../../Configurations/components";
import InputControl from "../../../shared/components/InputControl";

export const StoreAdminClientAccountDetailsList = (prop) => {
  const { id, cid } = useParams();
  const { pathname } = useLocation();
  const isCustomer = pathname.includes("customers");
  const navigate = useNavigate();
  const { isEditable } = useClientAccount({ isList: false });
  const {
    state,
    isStoreAdmin,
    globalData,
    clientAccDetailsTab,
    setCurentTab,
    toggleCreateOrEditModal,
    HandleClientAccountStatus,
  } = useCustomers({
    ClientAccountID: cid,
  });
  const [value, setValue] = React.useState(2);
  const { details, isLoading, updateData } = state.clientAccountDetails;
  React.useEffect(() => {
    if (!updateData.bill_day) {
      setValue(1);
    } else {
      setValue(2);
    }
  }, [updateData.bill_day]);

  const handleChange = (event, newValue) => {
    setCurentTab("clientAccDetails", newValue);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Transaction ID",
        accessor: "transaction_id",
      },
      {
        Header: "Order Number",
        accessor: "order_number",
      },
      {
        Header: "Date",
        accessor: (val) => {
          return (
            <Typography variant="subtitle2" fontWeight={400} color="#636363">
              {val.transaction_date}
            </Typography>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => {
          return <div>${value?.toFixed(2)}</div>;
        },
      },
      {
        Header: "Transaction Type",
        accessor: "transaction_type",
        Cell: ({ value }) => {
          return (
            <>
              <Chip
                label={value}
                color={value === "Payment" ? "success" : "error"}
                variant="outlined"
                size="small"
                sx={{
                  border: "none !important",
                }}
              />
            </>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return <ActionButtons row={row} details={details} />;
        },
      },
    ],
    []
  );

  const ActionButtons = ({ row }) => {
    return (
      <div className="action-buttons">
        {row.type !== "credit" ? (
          <img
            alt="details"
            onClick={() =>
              navigate(
                `/client-account/details/${cid}/transactions/${row?.transaction_id}`,
                {
                  state: {
                    from: `/customers/details/${id}/clientaccountId/${cid}`,
                  },
                }
              )
            }
            src="/icons/arrow_forward.svg"
          />
        ) : (
          <img
            alt="details"
            onClick={() => {
              navigate(`/orders/details/${row.order_id}`);
            }}
            src="/icons/arrow_forward.svg"
          />
        )}
      </div>
    );
  };
  const column = [
    {
      title: "Store Name",
      accessor: "store_name",
    },
    {
      title: "Credit Limit",
      accessor: ({ credit_limit }) => {
        return (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>{Math.round(credit_limit)?.toFixed(2)}</Typography>
            <Typography variant="subtitle2" fontWeight={600} color="#C07302">
              Due Amount - ${details.due_amount?.toFixed(2)}
            </Typography>
          </Stack>
        );
      },
    },
    {
      title: "Restrict Credit",
      accessor: ({ restrict_usage }) => {
        return restrict_usage === 1 ? (
          <Typography>Restricted</Typography>
        ) : (
          <Typography> Not Restricted</Typography>
        );
      },
    },
    {
      title: "Statement Frequency",
      accessor: ({ bill_day, bill_duration, bill_period, bill_date }) => {
        return (
          <Stack direction="row" spacing={1}>
            <SettlementDescription
              bill_day={bill_day}
              bill_duration={bill_duration}
              bill_period={bill_period}
              bill_date={bill_date}
              globalData={globalData}
            />
          </Stack>
        );
      },
    },
    // {
    //   title: "Opening Credit",
    //   accessor: ({ carry_forward }) => {
    //     return `$${carry_forward?.toFixed(2)}`;
    //   },
    // },
  ];

  const columns2 = [
    {
      title: "Business Name",
      accessor: "business_name",
    },
    {
      title: "ABN",
      accessor: "abn_number",
    },
    {
      title: "Business Address",
      accessor: (row) => {
        return (
          <Stack direction="row" gap={1}>
            {row?.business_name && <div>{row?.business_name},</div>}
            {row?.abn_number && <div> {row?.abn_number},</div>}
            {row?.business_address?.unit_number && (
              <div> {row?.business_address?.unit_number},</div>
            )}
            {row?.business_address?.street_number && (
              <div> {row?.business_address?.street_number},</div>
            )}
            {row?.business_address?.street_name && (
              <div> {row?.business_address?.street_name},</div>
            )}
            {row?.business_address?.suburb && (
              <div> {row?.business_address?.suburb},</div>
            )}
            {row?.business_address?.postcode && (
              <div> {row?.business_address?.postcode},</div>
            )}
            {row?.business_address?.state && (
              <div> {row?.business_address?.state}</div>
            )}
          </Stack>
        );
      },
    },
  ];
  return (
    <>
      <ContentLayout
        title={details?.customer_name}
        active={details?.status ? "Active" : "Inactive"}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={
                isCustomer
                  ? [
                      {
                        name: "Dashboard",
                        href: isStoreAdmin ? "/store-admin/" : "/",
                      },
                      {
                        name: "Customers",
                        href: isStoreAdmin
                          ? `/store-admin/customers`
                          : `/customers`,
                      },
                      {
                        name: "Client Account",
                        href: isStoreAdmin
                          ? `/store-admin/customers/details/${id}`
                          : `/customers/details/${id}`,
                      },
                      {
                        name: details.customer_name,
                        href: isStoreAdmin
                          ? `/store-admin/customers/details/${id}`
                          : `/customers/details/${id}`,
                      },
                    ]
                  : [
                      {
                        name: "Dashboard",
                        href: isStoreAdmin ? "/store-admin/" : "/",
                      },
                      {
                        name: "Client Account",
                        href: isStoreAdmin
                          ? "/store-admin/client-account"
                          : `/client-account`,
                      },
                      {
                        name: details.customer_name,
                        href: isStoreAdmin
                          ? "/store-admin/client-account"
                          : `/client-account`,
                      },
                    ]
              }
            />
          </>
        }
        rightContent={
          <>
            {isStoreAdmin && (
              <Link
                to={Number(details.due_amount) <= 0 ? "#" : `settlement`}
                state={details?.customer_name}
                style={{ textDecoration: "none" }}
              >
                <Button
                  type="button"
                  size="medium"
                  variant="contained"
                  disabled={Number(details.due_amount) <= 0}
                >
                  Make Settlement
                </Button>
              </Link>
            )}
          </>
        }
      >
        <TabContext value={clientAccDetailsTab}>
          <Box
            className="sticky-Tab"
            sx={{ borderBottom: 1, borderColor: "divider", top: "104px" }}
          >
            <TabList onChange={handleChange}>
              <Tab label="Details" value="1" />
              <Tab label="Transactions" value="2" />
              <Tab label="Accounts" value="3" />
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <CustomDetailsList
                  columns={column}
                  data={details}
                  title="Details"
                  headerRightContent={
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="subtitle1" fontWeight={400}>
                        Account Status
                      </Typography>
                      {isEditable && (
                        <InputControl
                          type="switch"
                          checked={details?.status === 1 ? true : false}
                          onChange={() => {
                            HandleClientAccountStatus(cid);
                          }}
                        />
                      )}
                      {isEditable && (
                        <Button
                          type="button"
                          size="medium"
                          variant="text"
                          flex-direction="right"
                          onClick={() =>
                            isCustomer
                              ? navigate(
                                  isStoreAdmin
                                    ? `/store-admin/customers/client-account/${id}/edit/${cid}`
                                    : `/customers/client-account/${id}/edit/${cid}`
                                )
                              : navigate(
                                  isStoreAdmin
                                    ? `/store-admin/client-account/edit/${id}`
                                    : navigate(`/client-account/edit/${id}`)
                                )
                          }
                        >
                          Edit
                        </Button>
                      )}
                    </Stack>
                  }
                />
                {!!details.is_business_user && (
                  <CustomDetailsList
                    columns={columns2}
                    data={details}
                    title="Business Details"
                  />
                )}
              </>
            )}
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Stack Stack={3}>
                  <CustomTable
                    columns={columns}
                    data={details?.transactions}
                    searchBar={false}
                    tableTitle="Transactions"
                    rowsSelection={false}
                    onRowLink={(e) => {
                      if (e.type !== "credit") {
                        return false;
                      } else {
                        if (isStoreAdmin) {
                          return `/store-admin/orders/details/${e.order_id}`;
                        } else {
                          return `/orders/details/${e.order_id}`;
                        }
                      }
                    }}
                    onRowAction={(e) => {
                      if (e.type !== "credit") {
                        navigate(
                          `/client-account/details/${cid}/transactions/${e?.transaction_id}`,
                          {
                            state: {
                              from: `/customers/details/${id}/clientaccountId/${cid}`,
                            },
                          }
                        );
                      }
                    }}
                  />
                </Stack>
              </>
            )}
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="3">
            <LedgerAccountsList type="clientaccount" />
          </TabPanel>
        </TabContext>
      </ContentLayout>
    </>
  );
};
