import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useActivityLog, usePermission } from "../../../shared";
import { useDebounce } from "use-debounce";
import qs from "qs";
import {
  createStoreBumpScreen,
  deleteBumpScreenById,
  getBumpScreenDetails,
  getBumpScreens,
  getBumpScreenTags,
  UnpairBumpScreen,
  updateStoreBumpScreen,
} from "../api";
import { useAuth } from "../../../shared/hooks/useAuth";
import { useChannel } from "@ably-labs/react-hooks";
import { AppContext } from "../../../store/AppScopeProvider";
import { getProducts } from "../../Products";

export const useBumpScreen = ({ isBumpscreen, BumpScreenId, isTags }) => {
  const navigate = useNavigate();
  const isDeletable = usePermission("bump-screen-delete");
  const isCreateVisible = usePermission("bump-screen-create");
  const isEditable = usePermission("bump-screen-modify");
  const { auth } = useAuth();
  const { appState } = useContext(AppContext);
  const { addActivityLog } = useActivityLog();
  const {
    globalData,
    currentTabs: { storeDetails: currentTab },
  } = appState;
  const [state, setState] = useImmer({
    isBusy: false,
    isLoading: false,
    bumpScreenList: [],
    bumpScreenTags: [],
    productsList: [],
    isUnpairOpen: false,
    isUnpairBusy: false,
    isOpen: false,
    isViewOpen: false,
    id: null,
    initialLoad: true,
    isSaveButtonBusy: false,
    name: "",
    httpStatusCodes: {
      details: "",
    },
    tagFilters: {
      isSearching: false,
      searchText: "",
      bump_screen_tags: [],
    },
    filters: {
      isSearching: false,
      searchText: "",
      type: "",
      pairing_status: "",
    },
    pagination: {
      pageIndex: 1,
      pageSize: 50,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    data: {
      name: "",
      display_product_alias: 0,
      display_category_name: 0,
      conditions: [
        {
          channel_id: "",
          tags: [],
          sales: [],
        },
      ],
    },
  });
  const [debouncedText] = useDebounce(state.filters.searchText, 1000);
  const [debounced] = useDebounce(state.tagFilters.searchText, 1000);
  const [channel, ably] = useChannel(`public:bump-screen`, (message) => {
    const { name, data } = message;
    if (name === "BumpScreenPaired") {
      updateBumScreenPairingStatus(data.bumpScreen.id, 1);
    } else if (name === "BumpScreenUnPaired") {
      updateBumScreenPairingStatus(data.bumpScreen.id, 0);
    }
  });
  useEffect(() => {
    if (!state.initialLoad && !state.tagFilters.isSearching) {
      const { bump_screen_tags } = state.tagFilters;
      handlegetProductByTags({
        searchText: debounced,
        tags: bump_screen_tags,
        pagination: {
          pageIndex: 1,
          pageSize: state.pagination.pageSize,
        },
      });
    }
  }, [debounced]);
  const updateBumScreenPairingStatus = (id, status) => {
    setState((draft) => {
      draft.bumpScreenList = draft.bumpScreenList.map((item) => {
        if (item.id === id) {
          return { ...item, pairing_status: status };
        } else {
          return item;
        }
      });
    });
  };
  const activityUnpairBumpScreen = (res) => {
    const newData = res?.data;
    const { store_name, store_id, id, name } = newData;
    const parameterData = {
      BumpscreenName: name,
      BumpscreenId: id,
      StoreName: store_name,
      StoreId: store_id,
    };
    addActivityLog({
      templateId: 21,
      parameters: parameterData,
      uniqueParameter: store_id,
      data: {
        newLogData: newData,
        oldLogData: {},
      },
    });
  };
  useEffect(() => {
    if (isBumpscreen && auth.data.selectedStoreId) {
      getBumpScreenList();
    }
  }, [isBumpscreen, auth.data.selectedStoreId]);
  useEffect(() => {
    if (!state.initialLoad && !state.filters.isSearching) {
      const { type, pairing_status } = state.filters;
      getBumpScreenList(debouncedText, type, pairing_status);
    }
  }, [debouncedText]);

  const getBumpScreenList = async (
    search = null,
    type = null,
    pairing_status = null
  ) => {
    let params = {
      store_id: auth.data.selectedStoreId,
    };

    if (search) {
      params = { ...params, search };
    }
    if (type !== null && type !== "") {
      params = { ...params, online_status: type };
    }
    if (pairing_status !== null && pairing_status !== "") {
      params = { ...params, pairing_status: pairing_status };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });
      const res = await getBumpScreens(query);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.bumpScreenList = res.data;
          draft.initialLoad = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
      });
    }
  };
  const SaleType =
    globalData?.sale_type?.map((item) => {
      return { id: item?.value, name: item?.label };
    }) || [];
  const BumpScreenTags =
    (state?.bumpScreenTags?.length > 0 &&
      state?.bumpScreenTags?.map((item) => {
        return { id: item, name: item };
      })) ||
    [];
  const handleBumpScreenDetailsList = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getBumpScreenDetails(BumpScreenId);
      if (res.data) {
        const sale = SaleType?.map((name) => name);
        const tags = BumpScreenTags?.map((name) => name);
        setState((draft) => {
          draft.data = {
            ...res.data,
            name: res?.data?.name,
            conditions: res?.data?.conditions?.map((item) => {
              return {
                sales: sale?.filter(({ id }) =>
                  item?.sale_type_id?.includes(id)
                ),
                channel_id: item?.channel_id,
                tags: tags?.filter(({ id }) => item?.tag_id?.includes(id)),
              };
            }),
          };

          draft.name = res.data.name;
          draft.isBusy = false;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (BumpScreenId && globalData && state.bumpScreenTags) {
      handleBumpScreenDetailsList();
    }
  }, [BumpScreenId, globalData, state.bumpScreenTags]);

  const onStoreUpdateBumpScreen = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const payload = {
        display_product_alias: state.data.display_product_alias,
        display_category_name: state.data.display_category_name,
        name: state?.data?.name,
        store_id: auth?.data?.selectedStoreId,
        conditions: state?.data?.conditions?.map((item) => {
          return {
            sales: item?.sales?.map((item) => item?.id),
            channel_id: item?.channel_id,
            tags: item?.tags?.map((item) => item?.id),
          };
        }),
      };
      const res = await updateStoreBumpScreen(id, payload);
      if (res.success) {
        successMessage("Updated Successfully");
        navigate("/store-admin/bump-screen");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const onStoreSaveBumpScreen = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });

      const payload = {
        name: state?.data?.name,
        store_id: auth.data?.selectedStoreId,
        display_product_alias: state.data.display_product_alias,
        display_category_name: state.data.display_category_name,
        conditions: state?.data?.conditions?.map((item) => {
          return {
            sales: item?.sales?.map((item) => item?.id),
            channel_id: item?.channel_id,
            tags: item?.tags?.map((item) => item?.id),
          };
        }),
      };

      const res = await createStoreBumpScreen(payload);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate("/store-admin/bump-screen");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const toggleModal = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isOpen = !draft.isOpen;
    });
  };
  const handleDeleteBumpScreen = async () => {
    try {
      const res = await deleteBumpScreenById(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.bumpScreenList = draft.bumpScreenList.filter(
            (item) => item.id !== state.id
          );
        });
        toggleModal();
        navigate("/store-admin/bump-screen");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onStorePairingStatus = async (id) => {
    try {
      setState((draft) => {
        draft.isUnpairBusy = true;
      });
      const res = await UnpairBumpScreen(id);
      if (res.success) {
        activityUnpairBumpScreen(res);
        successMessage("Bump Screen updated successfully.");
        setState((draft) => {
          draft.bumpScreenList = [
            ...draft.bumpScreenList.map((v) => {
              return id === v.id
                ? { ...v, pairing_status: v.pairing_status === 1 ? 0 : 1 }
                : v;
            }),
          ];
          draft.isUnpairBusy = false;
        });
        toggleUnpair();
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const onChangeFilter = (e) => {
    const { name, value } = e.target;
    const { searchText, type, pairing_status } = state.filters;
    if (name === "type") {
      getBumpScreenList(searchText, value, state.filters.pairing_status);
    }
    if (name === "pairing_status") {
      getBumpScreenList(searchText, state.filters.type, value);
    }

    setState((draft) => {
      draft.filters[name] = value;
      draft.filters.isSearching = false;
    });
  };

  const resetFilters = () => {
    if (
      state.filters.type.toString() ||
      state.filters.pairing_status.toString() ||
      state.filters.searchText
    ) {
      getBumpScreenList();
      setState((draft) => {
        draft.filters.type = "";
        draft.filters.pairing_status = "";
        draft.filters.searchText = "";
        draft.filters.isSearching = true;
      });
    }
  };
  const getBumpScreenTagsList = async () => {
    try {
      const res = await getBumpScreenTags();
      if (res.success) {
        setState((draft) => {
          draft.bumpScreenTags = res.data;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (isTags) {
      getBumpScreenTagsList();
    }
  }, [isTags]);
  const toggleViewModal = async (type, i, index, item) => {
    setState((draft) => {
      draft.isViewOpen = type;
    });

    if (type) {
      setState((draft) => {
        draft.tagFilters.bump_screen_tags = item?.map((data, i) =>
          i === index ? { value: data.id, label: data.name } : data
        );
      });
      onChangeTagFilter({
        target: {
          name: "bump_screen_tags",
          value: item?.map((v) => {
            return { value: v.id, label: v.name };
          }),
        },
      });
    }
  };

  const handlegetProductByTags = async ({
    searchText = null,
    tags = null,
    pagination,
  }) => {
    try {
      setState((draft) => {
        draft.isLoading = true;
        draft.initialLoad = true;
      });
      let params = {};

      if (searchText) {
        params = { ...params, search: searchText };
      }

      if (tags?.length > 0) {
        const bump_screen_tagIds = tags?.map((v) => {
          return v.value;
        });

        params = { ...params, bump_screen_tags: bump_screen_tagIds };
      }
      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };
      const res = await getProducts(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.isLoading = false;
          draft.initialLoad = false;
          draft.productsList = res.data.products;
          draft.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isLoading = false;
        draft.initialLoad = false;
      });
    }
  };

  const onChangeTagFilter = (e) => {
    const { name, value } = e?.target;
    const { searchText, bump_screen_tags } = state.tagFilters;
    if (name === "bump_screen_tags") {
      handlegetProductByTags({
        searchText,
        tags: value,
        pagination: {
          pageIndex: 1,
          pageSize: state.pagination.pageSize,
        },
      });
    }
    setState((draft) => {
      draft.tagFilters[name] = value;
      draft.tagFilters.isSearching = false;
    });
  };
  const gotoPage = (page) => {
    const { searchText, bump_screen_tags } = state.tagFilters;
    handlegetProductByTags({
      searchText: searchText,
      tags: bump_screen_tags,
      pagination: {
        pageIndex: page,
        pageSize: state.pagination.pageSize,
      },
    });

    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };

  const setPageSize = (e) => {
    const { searchText, bump_screen_tags } = state.tagFilters;
    handlegetProductByTags({
      searchText: searchText,
      tags: bump_screen_tags,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
    });

    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };
  const onAddConditions = (e) => {
    setState((draft) => {
      draft.data.conditions.push({
        channel_id: "",
        tags: [],
        sales: [],
      });
    });
  };
  const handleDeleteCondition = (index) => {
    setState((draft) => {
      draft.data.conditions = draft.data.conditions.filter(
        (item, i) => index !== i
      );
    });
  };
  const onChange = (e, index) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data.conditions = [
        ...draft.data.conditions.map((data, i) =>
          i === index ? { ...data, [name]: value } : data
        ),
      ];
    });
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };

  const toggleUnpair = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isUnpairOpen = !draft.isUnpairOpen;
    });
  };

  const onConfirmUnpair = () => {
    onStorePairingStatus(state.id);
  };

  const handleChangeSwitch = (e) => {
    const { checked, name } = e.target;
    setState((draft) => {
      draft.data[name] = checked;
    });
  };

  return {
    state,
    onStoreSaveBumpScreen,
    onChange,
    onStoreUpdateBumpScreen,
    toggleModal,
    handleDeleteBumpScreen,
    onStorePairingStatus,
    onChangeFilter,
    resetFilters,
    isDeletable,
    isEditable,
    isCreateVisible,
    auth,
    globalData,
    toggleViewModal,
    onChangeTagFilter,
    gotoPage,
    setPageSize,
    onAddConditions,
    handleDeleteCondition,
    handleChange,
    SaleType,
    BumpScreenTags,
    toggleUnpair,
    onConfirmUnpair,
    handleChangeSwitch,
  };
};
