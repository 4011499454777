import {
  ContentLayout,
  DeleteModal,
  Loader,
  PageNotFound,
  CustomDetailsList,
  CustomTable,
  CustomeDialog,
  HeaderBreadcrumbs,
} from "../../../shared";
import * as React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { sortItems } from "../../../utils";
import InputControl from "../../../shared/components/InputControl";
import { BundlesProductDialog } from "../components";
import { useBundles } from "../hooks";

export const BundlesDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    state,
    isDeletable,
    isEditable,
    isCreateVisible,
    handleModal,
    handleDeleteBundle,
    handleExtraPriceInput,
    handleBundleProductsSelect,
    handleAssignProductToBundle,
    handleDeleteBundleProduct,
    isCheckIndeterminate,
  } = useBundles({ isDetailsId: id });

  const {
    isOpen,
    isProductDelete,
    dataList,
    isLoading,
    productsList,
    filteredPoducts,
    alreadyProductList,
    selectedItems,
  } = state.BundleGroupDetails;
  const { isDelete } = state.BundleGroupDetails;
  const columns = [
    {
      title: "Group Name",
      accessor: "name",
    },
    {
      title: "Alias",
      accessor: "alias",
    },
  ];

  const columns2 = React.useMemo(
    () => [
      {
        Header: "Products",
        id: "name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "PLU Code",
        accessor: "plu",
      },
      {
        Header: "Add- on Price",
        disableSortBy: true,
        accessor: (row) => {
          return (
            <InputControl
              name="Price"
              label="Price"
              type="number"
              onChange={(e) => handleExtraPriceInput(e, row)}
              value={row.extra_price}
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              deleteAction={() =>
                handleModal("bundle-product-delete", true, row.product_id)
              }
            />
          );
        },
      },
    ],
    [productsList]
  );

  const ActionButtons = ({ deleteAction }) => {
    return (
      <div className="action-buttons">
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
      </div>
    );
  };

  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }

  return (
    <>
      <ContentLayout
        title={dataList?.name}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Bundle Groups", href: "/bundle" },
              {
                name: dataList?.name,
              },
            ]}
          />
        }
        rightContent={
          <>
            {isDeletable && (
              <Button
                color="primary"
                type="button"
                size="medium"
                onClick={() => handleModal("bundle-details-delete", true)}
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
              >
                <img src="/icons/ic_delete_blue.svg" />
              </Button>
            )}
          </>
        }
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <CustomDetailsList
              columns={columns}
              data={dataList}
              title="Details"
              headerRightContent={
                <>
                  {isEditable && (
                    <Button
                      size="medium"
                      variant="contained"
                      onClick={() => navigate(`/bundle/edit/${id}`)}
                    >
                      Edit
                    </Button>
                  )}
                </>
              }
            />
            <CustomTable
              columns={columns2}
              data={productsList}
              searchBar={false}
              tableTitle="Required Choice"
              pagination={false}
              headerRightContent={
                <>
                  {isCreateVisible && (
                    <Button
                      type="button"
                      size="medium"
                      variant="text"
                      startIcon={<AddIcon />}
                      onClick={() => handleModal("bundle-pro-add", true)}
                    >
                      Add Product
                    </Button>
                  )}
                </>
              }
            />
            <CustomeDialog
              open={isOpen}
              handleClose={() => handleModal("bundle-pro-add", false)}
              PaperProps={{
                sx: { width: "423px", height: "491px", p: "16px" },
              }}
              title="Products"
              content={
                <BundlesProductDialog
                  loading={isOpen}
                  Items={filteredPoducts}
                  onSelect={handleBundleProductsSelect}
                  onSubmit={handleAssignProductToBundle}
                  disabled={selectedItems.length === 0}
                  isSelected={(id) => selectedItems.includes(id)}
                  isCheckIndeterminate={isCheckIndeterminate}
                  selectedItems={selectedItems}
                  alreadyList={alreadyProductList}
                />
              }
            />
          </>
        )}
      </ContentLayout>
      <DeleteModal
        open={isDelete}
        handleClose={() => handleModal("bundle-details-delete", false)}
        onConfirm={() => {
          handleDeleteBundle("details");
        }}
      />
      <DeleteModal
        open={isProductDelete}
        handleClose={() => handleModal("bundle-product-delete", false, null)}
        onConfirm={() => {
          handleDeleteBundleProduct();
        }}
      />
    </>
  );
};
