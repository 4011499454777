import { DeleteModal } from "../../../shared";
import * as React from "react";
import { Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CustomTable } from "../../../shared";
import { useNavigate, useParams } from "react-router-dom";
import { useFuturePrice } from "../../FuturePrice";

export const ProductFuturePrice = ({ tax }) => {
  const { id } = useParams();
  const { state, handleDeleteFuturePrice, toggleModal } = useFuturePrice({
    isList: true,
    productFuturePriceId: id,
    isProduct: false,
    tax_rate: tax,
  });
  const navigate = useNavigate();
  const ActionButtons = ({ ids, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        <img
          alt="edit"
          src="/icons/ic_edit.svg"
          onClick={() =>
            navigate(`/future-price/edit/${ids}`, {
              state: { ids, from: `/products/details/${id}` },
            })
          }
        />
        <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
      </div>
    );
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Price Level",
        accessor: "price_level_name",
      },
      {
        Header: "Future Price",
        accessor: "future_price",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              ${row.original.future_price}
            </Stack>
          );
        },
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Current Price",
        accessor: "current_price",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              ${row.original.current_price}
            </Stack>
          );
        },
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              {row.original.start_date}
            </Stack>
          );
        },
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Action",
        id: "action",
        accessor: "id",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              ids={row.id}
              id={id}
              deleteAction={() => {
                toggleModal(true, row.id);
              }}
            />
          );
        },
      },
    ],
    [id]
  );
  return (
    <>
      <CustomTable
        columns={columns}
        data={state?.futureprice ?? []}
        isLoading={state?.isBusy}
        searchBar={true}
        tableId="product_futurePrice"
        tableTitle="List of Future Pricing"
        rowsSelection={false}
        defaultSearch={true}
        filterContent={
          <>
            {/* <InputControl
              type="table-search"
              value={state.filters.searchText}
              onChange={(e) => onChangeFilter(e)}
              onClear={onSeachClear}
            /> */}
          </>
        }
        headerRightContent={
          <Button
            type="button"
            size="medium"
            variant="text"
            className="delte"
            startIcon={<AddIcon />}
            onClick={() =>
              navigate("/future-price/create", {
                state: { id, from: `/products/details/${id}` },
              })
            }
            sx={{
              minWidth: "40px",
              p: "8px",
              border: "1px solid rgba(21, 96, 212, 0.12)",
            }}
          >
            Add
          </Button>
        }
        onRowLink={(e) => {
          return `/future-price/edit/${e.id}?param=/products/details/${id}`;
        }}
      />

      <DeleteModal
        open={state?.isOpen}
        handleClose={() => toggleModal(false)}
        onConfirm={() => handleDeleteFuturePrice()}
      />
    </>
  );
};
