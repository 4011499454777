import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import * as React from "react";
import { Button, Stack, Card, Typography, Grid } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useProducts } from "../hooks";

export const ProductSeoEdit = () => {
  const { id } = useParams();
  const { state, onUpdateProductSeo, onValueChange } = useProducts({
    isDetailsId: id,
  });
  const {
    slug,
    meta_title,
    meta_description,
    image_description,
    image_alt_text,
  } = state.seoDetails;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const onSubmit = () => {
    if (validator.current.allValid()) {
      onUpdateProductSeo(id);
      {
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <ContentLayout
        title={state?.basicDetails?.details?.name}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Products", href: "/products" },
                id
                  ? {
                      name: state?.basicDetails?.details?.name,
                      href: `/products/details/${id}`,
                    }
                  : null,
                { name: "Edit" },
              ].filter(Boolean)}
            />
          </>
        }
        rightContent={
          <>
            <Button
              onClick={() => onSubmit()}
              type="button"
              size="medium"
              variant="contained"
              disabled={state?.isSaveButtonBusy}
            >
              Update
            </Button>
          </>
        }
      >
        {state?.isBusy ? (
          <Loader />
        ) : (
          <>
            <Card sx={{ p: "32px 24px", mb: "24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    SEO
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack width={"100%"} gap="20px">
                    <InputControl
                      name="slug"
                      value={slug}
                      onChange={(e) => onValueChange(e)}
                      label="URL Slug"
                      required
                      error={validator.current.message(
                        "URL Slug",
                        slug,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "URL Slug",
                        slug,
                        "required"
                      )}
                    />
                    <InputControl
                      name="meta_title"
                      label="Meta Title"
                      value={meta_title}
                      onChange={(e) => onValueChange(e)}
                      required
                      error={validator.current.message(
                        "Meta Title",
                        meta_title,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "Meta Title",
                        meta_title,
                        "required"
                      )}
                    />
                    <InputControl
                      name="meta_description"
                      label="Meta Description"
                      value={meta_description}
                      onChange={(e) => onValueChange(e)}
                      rows={4}
                      multiline
                      id="outlined-multiline-static"
                    />
                    <InputControl
                      name="image_alt_text"
                      label="Image Alt Text"
                      value={image_alt_text}
                      onChange={(e) => onValueChange(e)}
                    />
                    <InputControl
                      name="image_description"
                      label="Image Description"
                      value={image_description}
                      onChange={(e) => onValueChange(e)}
                      rows={4}
                      multiline
                      id="outlined-multiline-static"
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </ContentLayout>
    </>
  );
};
