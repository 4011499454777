import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { errorMessage, successMessage } from "../../../utils";
import {
  createLedgerAccount,
  deleteLedgerbyId,
  getLedgerAccountList,
  getOtherConfigList,
  getPaymentTypeConfigList,
  getSaleTypeConfigList,
  updateCOAConfig,
  updateLedgerAccount,
} from "../api";
import { useDropdownApis } from "../../../shared";

export const useLedgerAccounts = ({ id, type, isConfig }) => {
  const { coasList } = useDropdownApis({ isAccountsList: true });

  const [state, setState] = useImmer({
    isLoading: true,
    isOpen: false,
    isDelete: false,
    isSaveButton: false,
    selectedID: null,
    dataList: [],
    dummyDataList: [],
    dataListIDs: [],
    isDataList: false,
    AddDataIDs: [],
    searchText: null,
    isEdit: false,
    isViewEdit: false,
    coa: {
      dataList: null,
      isLoading: true,
      initialLoad: true,
      filteredList: [],
      filteredData: [],
    },
    systemTypeConfigList: [],
    paymentTypeConfigList: [],
    saleTypeConfigList: [],
    accountConfigList: [],
    Isload: true,
    Isload1: false,
    Isload2: true,
    Isload3: true,
    Isload4: true,
    configType: null,
    account_id: [],
    accountable_id: null,
    accountType: "",
  });

  useEffect(() => {
    if (id && type) {
      handleLedgerAccountList(id, type);
    }
  }, [id]);

  useEffect(() => {
    setState((draft) => {
      draft.coa.dataList = coasList;
      draft.coa.filteredList = coasList;
      draft.coa.isLoading = false;
    });
  }, [coasList]);

  const handleLedgerAccountList = async (id, type) => {
    try {
      const res = await getLedgerAccountList(id, type);
      if (res.success) {
        setState((draft) => {
          draft.dataList = res.data;
          draft.dummyDataList = res.data;
          draft.dataListIDs = res.data.map((v) => v.account_id);
          draft.isDataList = true;
          draft.isLoading = false;
          draft.isViewEdit = res.data?.length > 0 ? true : false;
        });
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.isLoading = false;
        });
      }
    } catch (error) {
      console.log(error);
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const onEditButton = (value) => {
    setState((draft) => {
      draft.isEdit = value;
    });
    if (!value) {
      setState((draft) => {
        draft.dataList = draft.dummyDataList;
        draft.isEdit = value;
      });
    }
  };

  const handleModal = (type, state, data, data2) => {
    switch (type) {
      case "ledger-delete":
        setState((draft) => {
          draft.isEdit = false;
          draft.isDelete = state;
          draft.selectedID = data;
        });
        break;
      case "ledger-add":
        setState((draft) => {
          draft.isEdit = false;
          draft.isOpen = state;
          draft.AddDataIDs = [];
          draft.searchText = null;
          draft.coa.filteredList = draft.coa.dataList;
        });
        break;
      case "config-add":
        setState((draft) => {
          draft.isOpen = state;
          draft.account_id = data2 ? [data2.accountID] : [];
          draft.accountable_id = data2?.accountable_id || null;
          draft.configType = data;
          draft.accountType = data2?.header || "";
          draft.searchText = draft.searchText ? "" : null;
          draft.coa.filteredList = draft.coa.dataList;
        });
        break;
    }
  };

  const handleInputChange = (event, id) => {
    const { name, value } = event.target;

    setState((draft) => {
      draft.dataList = [
        ...draft.dataList.map((v) => {
          return v.account_id === id ? { ...v, [name]: value } : v;
        }),
      ];
    });
  };

  const onUpdateLedger = async () => {
    try {
      setState((draft) => {
        draft.isSaveButton = true;
      });
      const payload = {
        type: type,
        accountable_id: id,
        accountables: state.dataList.map((data) => {
          return {
            account_id: data.account_id,
            split_percentage: data.split_percentage,
          };
        }),
      };
      const res = await updateLedgerAccount(payload);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButton = false;
          draft.isEdit = false;
          draft.dummyDataList = draft.dataList;
        });
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButton = false;
      });
    }
  };

  const handleDeleteLedger = async () => {
    try {
      const res = await deleteLedgerbyId(state.selectedID);
      if (res.success) {
        successMessage(res.message);
        handleModal("ledger-delete", false, null);
        handleLedgerAccountList(id, type);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleCheckAccount = (e, row) => {
    if (e.target.checked) {
      setState((draft) => {
        draft.AddDataIDs = [...draft.AddDataIDs, row.original.id];
      });
    } else {
      setState((draft) => {
        draft.AddDataIDs = draft.AddDataIDs.filter(
          (val) => val !== row.original.id
        );
      });
    }
  };

  const handleChecked = (row) => {
    if (state.dataListIDs.includes(row.original.id)) {
      return true;
    } else if (state.AddDataIDs.includes(row.original.id)) {
      return true;
    } else {
      return false;
    }
  };

  const handleDisable = (row) => {
    if (state.dataListIDs.includes(row.original.id)) {
      return true;
    } else {
      return false;
    }
  };

  const onSaveLedger = async () => {
    try {
      let payload = {
        account_id: state.AddDataIDs,
        accountable_id: id,
        type: type,
      };
      setState((draft) => {
        draft.isSaveButton = true;
      });
      const res = await createLedgerAccount(payload);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButton = false;
          draft.isLoading = true;
        });
        handleModal("ledger-add", false);
        handleLedgerAccountList(id, type);
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButton = false;
      });
    }
  };

  const onSaveConfig = async () => {
    try {
      let payload = {
        account_id: state.account_id[0],
        accountable_id: state.accountable_id,
        type: state.configType,
      };
      setState((draft) => {
        draft.isSaveButton = true;
      });
      const res = await updateCOAConfig(payload);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButton = false;
          draft.isLoading = true;
        });
        if (state.configType === "PaymentType") {
          setState((draft) => {
            draft.Isload2 = true;
          });
          handlePaymentTypeConfigList();
        } else if (state.configType === "ChannelType") {
          setState((draft) => {
            draft.Isload3 = true;
          });
          handleSaleTypeConfigList();
        } else if (state.configType === "AccountConfig") {
          setState((draft) => {
            draft.Isload4 = true;
          });
          handleAccountConfigList();
        } else {
          // setState((draft) => {
          //     draft.Isload1 = true;
          // });
          // handleSystemTypeConfigList()
        }
        handleModal("config-add", false, null, null);
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButton = false;
      });
    }
  };

  const onSearch = (value) => {
    setState((draft) => {
      draft.searchText = value;
    });

    const Search = (data, text) => {
      text = text.toLowerCase();
      return data.filter((val, i) => {
        if (val.name.toString().toLowerCase().indexOf(text) > -1) {
          return true;
        } else if (val.subData.length > 0) {
          const sub = Search(val.subData, text);
          return sub.length > 0 ? true : false;
        }
      });
    };

    const result = Search(state.coa.dataList, value);
    setState((draft) => {
      draft.coa.filteredList = result;
    });
  };

  // const handleSystemTypeConfigList = async (id, type) => {
  //     try {
  //         const res = await getSystemTypeConfigList();
  //         if (res.success) {
  //             setState((draft) => {
  //                 draft.systemTypeConfigList = res.data
  //                 draft.Isload1 = false
  //             });

  //         } else {
  //             errorMessage(res.message)
  //             setState((draft) => {
  //                 draft.Isload1 = false
  //             });
  //         }
  //     } catch (error) {
  //         errorMessage(error.response.data.message);
  //         setState((draft) => {
  //             draft.Isload1 = false
  //         });
  //     }
  // };

  const handlePaymentTypeConfigList = async () => {
    try {
      const res = await getPaymentTypeConfigList();
      if (res.success) {
        setState((draft) => {
          draft.paymentTypeConfigList = res.data;
          draft.Isload2 = false;
        });
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.Isload2 = false;
        });
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.Isload2 = false;
      });
    }
  };

  const handleSaleTypeConfigList = async (id, type) => {
    try {
      const res = await getSaleTypeConfigList();
      if (res.success) {
        setState((draft) => {
          draft.saleTypeConfigList = res.data;
          draft.Isload3 = false;
        });
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.Isload3 = false;
        });
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.Isload3 = false;
      });
    }
  };
  const handleAccountConfigList = async (id, type) => {
    try {
      const res = await getOtherConfigList();
      if (res.success) {
        setState((draft) => {
          draft.accountConfigList = res.data;
          draft.Isload4 = false;
        });
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.Isload4 = false;
        });
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.Isload4 = false;
      });
    }
  };

  useEffect(() => {
    if (isConfig) {
      // handleSystemTypeConfigList()
      handlePaymentTypeConfigList();
      handleSaleTypeConfigList();
      handleAccountConfigList();
    }
  }, [isConfig]);

  useEffect(() => {
    const { Isload1, Isload2, Isload3, Isload4 } = state;
    if (!Isload1 && !Isload2 && !Isload3 && !Isload4) {
      setState((draft) => {
        draft.Isload = false;
      });
    } else {
      setState((draft) => {
        draft.Isload = true;
      });
    }
  }, [state.Isload1, state.Isload2, state.Isload3, state.Isload4]);

  const handleRadio = (id) => {
    setState((draft) => {
      draft.account_id = [id];
    });
  };

  return {
    state,
    handleInputChange,
    handleModal,
    handleDeleteLedger,
    handleCheckAccount,
    onSaveLedger,
    onUpdateLedger,
    handleChecked,
    onSearch,
    onEditButton,
    onSaveConfig,
    handleRadio,
    handleDisable,
  };
};
