import React from "react";
import { Route, Routes } from "react-router-dom";
import { RefundDetail, RefundList } from "./containers";
import { ProtectedRoute } from "../../shared";

export const Refund = () => {
  return (
    <Routes>
      <Route path="/" element={<RefundList />} />
      <Route element={<ProtectedRoute allowedRoute={["refund-view"]} />}>
        <Route path="/details/:id" element={<RefundDetail />} />
      </Route>
    </Routes>
  );
};
