import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import { AddorEditEndofDay, EndofDayDetails, EndofDayList } from "./containers";

export const EndoftheDay = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<EndofDayList />} />
        <Route element={<ProtectedRoute allowedRoute={["end-of-day-view"]} />}>
          <Route path="/details/:id" element={<EndofDayDetails />} />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["end-of-day-create"]} />}
        >
          <Route path="/create" element={<AddorEditEndofDay />} />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["end-of-day-create"]} />}
        >
          <Route path="/edit/:id" element={<AddorEditEndofDay />} />
        </Route>
      </Routes>
    </>
  );
};
