import React, { useContext, useEffect } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { AuthContext } from "../../store/AuthProvider";

export const AdminProtectedRoute = ({ allowedRole }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const storeId = searchParams.get("order_store_id");
  const {
    auth,
    auth: { data },
    setAuth,
  } = useContext(AuthContext);
  const location = useLocation();
  const { pathname, search } = location;
  const pathNamesArray = ["/orders/details", "/orders"];
  useEffect(() => {
    const hasPath = pathNamesArray.find((i) => pathname.includes(i));
    if (storeId && hasPath && data.roles.length) {
      const hasAdminPermission = data.roles.find(
        (item) => item.name === "admin"
      );
      const hasStoreAdminPermission = data.roles.find(
        (item) => item.name === "store-admin"
      );

      if (!hasAdminPermission && hasStoreAdminPermission) {
        if (data.stores.find((i) => i.id === Number(storeId))) {
          setAuth((draft) => {
            draft.data.selectedStoreId = Number(storeId);
          });
        }
        navigate(`/store-admin${pathname}${search}`);
      }
    }
  }, [data.roles, storeId]);

  const checkPermission = (data) => {
    let isAllowed = false;
    const hasRole = data.roles.find((item) => item.name === allowedRole);
    if (hasRole) {
      if (allowedRole === "store-admin" && data.stores.length) {
        isAllowed = true;
      } else if (allowedRole === "admin") {
        isAllowed = true;
      }
    }
    return isAllowed;
  };
  const hasPermission = checkPermission(data);

  return (
    !auth.isInitialLoad &&
    (hasPermission ? (
      <Outlet />
    ) : auth?.data?.id ? (
      <Navigate to={"/unauth"} replace />
    ) : (
      <Navigate
        to="/authentication/login"
        replace
        state={{ from: `${pathname}${search}` }}
      />
    ))
  );
};
