import { ContentLayout, ServerPaginatedTable } from "../../../shared";
import * as React from "react";
import { Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import CloseIcon from "@mui/icons-material/Close";
import { DashBoardDateRangePicker } from "../../Dashboard";
import { useWastageList } from "../hooks";

export const WastageList = () => {
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const {
    state,
    wastageType,
    onChangeFilter,
    onSeachClear,
    resetFilters,
    gotoPage,
    setPageSize,
    handleDownloadWastageList,
    handleSort,
    onChangeDate,
    handleIsOpen,
    handleDateChangeApply,
  } = useWastageList({ isWastage: true });
  const ActionButtons = ({ id }) => {
    return (
      <div className="action-buttons">
        <Link to={`details/${id}`}>
          <img alt="details" src="/icons/arrow_forward.svg" />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Wastage Number",
        accessor: "wastage_number",
      },
      {
        Header: "Date & Time",
        accessor: "wastage_date",
      },
      {
        Header: "Wastage Type",
        accessor: "wastage_type",
        id: "wastage_type_name",
      },
      {
        Header: "Store",
        accessor: "store_name",
      },
      {
        Header: "Total Items",
        accessor: "total_products",
      },
      {
        Header: "Grand Total",
        accessor: "grant_total",
        Cell: ({ value }) => {
          return <div>{value?.toFixed(2) || 0}</div>;
        },
      },
      {
        Header: "Staff Name",
        accessor: "user_name",
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons id={row.id} />;
        },
      },
    ],
    []
  );

  const tablecolumns = isStoreAdmin
    ? columns.filter((v) => v.Header !== "Store")
    : columns;

  return (
    <>
      <ContentLayout
        title="Wastage"
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="text"
              onClick={handleDownloadWastageList}
            >
              <img
                alt="export"
                src="/icons/ic_export.svg"
                style={{
                  marginRight: "4px",
                }}
              />
              Export
            </Button>
          </>
        }
      >
        <ServerPaginatedTable
          columns={tablecolumns}
          data={state.wastage}
          key={state.sortBy}
          isLoading={state?.isBusy}
          defaultSearch={false}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
          pagination={state.pagination}
          handleSort={handleSort}
          sortByGlobal={state.sortBy}
          filterContent={
            <>
              <InputControl
                type="table-search"
                name="searchText"
                placeholder="Search"
                size="small"
                value={state.filters.searchText}
                onChange={(e, v) => onChangeFilter(e, v, "searchText")}
                onClear={onSeachClear}
              />
              <DashBoardDateRangePicker
                onChangeDate={onChangeDate}
                isList={true}
                range={state.date}
                showRange={state.filters.date}
                isOpen={state.isCalenderOpen}
                handleIsOpen={handleIsOpen}
                handleDateChangeApply={handleDateChangeApply}
              />
              <InputControl
                type="select"
                name="wastageType"
                label="Wastage Type"
                value={state.filters.wastageType}
                optionValue={wastageType ?? []}
                width="250px"
                onChange={(e, v) => onChangeFilter(e, v, "wastage")}
              />

              <Button
                type="button"
                size="medium"
                variant="text"
                color="gray"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => resetFilters()}
              >
                <CloseIcon />
              </Button>
            </>
          }
          onRowLink={(e) => {
            return `details/${e.id}`;
          }}
        />
      </ContentLayout>
    </>
  );
};
