import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import { AddOrEditConditions } from "./containers";
import { ConditionsList } from "./containers/ConditionsList";

export const Conditions = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ConditionsList />} />

        <Route element={<ProtectedRoute allowedRoute={["condition-create"]} />}>
          <Route path="/create" element={<AddOrEditConditions />} />
        </Route>

        <Route element={<ProtectedRoute allowedRoute={["condition-modify"]} />}>
          <Route path="/edit/:id" element={<AddOrEditConditions />} />
        </Route>
      </Routes>
    </>
  );
};
