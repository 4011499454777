import { API } from "../../../utils";

export const getAllTemplates = async () => {
  const res = await API.get(`keypads`);
  return res.data;
};

export const deleteTemplate = async (id) => {
  const res = await API.delete(`keypads/${id}`);
  return res.data;
};

export const copyTemplate = async (id) => {
  const res = await API.post(`keypads/${id}/duplicate`);
  return res.data;
};

export const getTemplateDetails = async (id) => {
  const res = await API.get(`keypads/${id}/stores`);
  return res.data;
};

export const addStores = async (id, payload) => {
  const res = await API.post(`keypads/${id}/stores`, payload);
  return res.data;
};
