import { API } from "../../../utils";

export const getCategory = async (query) => {
  const res = await API.get("categories", query);
  return res.data;
};

export const changeCategoryOrder = async (payload) => {
  const res = await API.post("categories/category-order", payload);
  return res.data;
};

export const getCategoryProductsById = async (id, searchText) => {
  let url = `products?category_id[0]=${id}`;
  if (searchText) {
    url += `&search=${searchText}`;
  }
  const res = await API.get(url);
  return res.data;
};

export const editCategory = async (id, payload) => {
  const res = await API.post(`categories/${id}`, payload);
  return res.data;
};
export const createCategory = async (payload) => {
  const res = await API.post(`categories`, payload);
  return res.data;
};
export const getCategoryDetailsById = async (id) => {
  const res = await API.get(`categories/${id}`);
  return res.data;
};
export const deleteCategoryById = async (id) => {
  const res = await API.delete(`categories/${id}`);
  return res.data;
};

export const getCategoryProducts = async (id) => {
  const res = await API.get(`categories/${id}/products`);
  return res.data;
};
export const changeCategoryProductOrder = async (id, payload) => {
  const res = await API.post(`categories/${id}/products-order`, payload);
  return res.data;
};