import * as React from "react";
import { Button, Chip, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ServerPaginatedTable, usePermission } from "../../../shared";
import { CustomeDialog } from "../../../shared";
import InputControl from "../../../shared/components/InputControl";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { sortItems } from "../../../utils";

export const LoayaltyCustomers = (props) => {
  const {
    data,
    globalData,
    onChangeFilter,
    handleSearch,
    onSeachClear,
    resetFilters,
    gotoPageCustomerList,
    setPageSizeCustomerList,
    handleSortCustomerList,
    onBlurTab,
  } = props;
  const { dataList, isLoading, filterApplied, pagination, sortBy } = data;

  const navigate = useNavigate();
  const isCreate = usePermission("loyalty-cards-create");
  const ActionButtons = ({ id, row }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {!row.customer_verified && row.customer_id && (
          <Link
            style={{ textDecoration: "none" }}
            target="_blank"
            to={`/customers/details/${row.customer_id}`}
            onClick={(e) => {
              e.stopPropagation();
              onBlurTab();
            }}
            className="LinkBtn"
          >
            <Typography
              sx={{ cursor: "pointer", textDecoration: "none" }}
              fontWeight={500}
              fontSize={"14px"}
              color="#1560D4"
            >
              Verify
            </Typography>
          </Link>
        )}
        <img
          alt="details"
          src="/icons/arrow_forward.svg"
          onClick={() => navigate(`details/${id}`)}
        />
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Barcode",
        accessor: "barcode",
        disableGlobalFilter: true,
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Member Number",
        accessor: "member_number",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Verification Code",
        accessor: "verification_code",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },

      {
        Header: "Name",
        accessor: "customer_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
        Cell: ({ value, row }) => {
          return (
            <Stack
              direction="row"
              gap="4px"
              alignItems="center"
              onClick={(e) => e.preventDefault()}
            >
              {row.original.customer_verified && (
                <img
                  style={{ height: "14px" }}
                  alt="verify"
                  src="/icons/ic_verified_badge.svg"
                />
              )}
              {row.original.customer_id ? (
                <div
                  className="table-taxt-and-icon-link"
                  onClick={() =>
                    navigate(`/customers/details/${row.original.customer_id}`)
                  }
                >
                  <span className="link-text">{value}</span>
                </div>
              ) : (
                <Typography variant="body2">{value}</Typography>
              )}
            </Stack>
          );
        },
      },
      {
        Header: "Points",
        accessor: "points",
        disableGlobalFilter: true,
      },

      {
        Header: "Card Type",
        accessor: "loyalty_card_type",
        disableGlobalFilter: true,
      },
      {
        Header: "Status",
        accessor: "status",
        disableGlobalFilter: true,
        sortInverted: true,
        Cell: ({ value }) => {
          return (
            <Chip
              label={value === 1 ? "Active" : "Inactive"}
              color="success"
              variant="outlined"
              size="small"
              disabled={value === 1 ? false : "filled"}
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        accessor: "id",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons id={row.id} row={row} />;
        },
      },
    ],
    []
  );

  return (
    <>
      <ServerPaginatedTable
        columns={columns}
        data={dataList}
        isLoading={isLoading}
        searchBar={true}
        rowsSelection={true}
        defaultSearch={false}
        gotoPage={gotoPageCustomerList}
        setPageSize={setPageSizeCustomerList}
        pagination={pagination}
        handleSort={handleSortCustomerList}
        sortByGlobal={sortBy}
        filterContent={
          <>
            <InputControl
              type="table-search"
              name="searchText"
              placeholder="Search"
              size="small"
              value={filterApplied.searchText || ""}
              onChange={(e) => handleSearch(e.target.value)}
              onClear={onSeachClear}
            />
            <InputControl
              type="select"
              name="status"
              label="Status"
              optionValue={globalData.status ?? []}
              value={filterApplied.status}
              width="250px"
              onChange={onChangeFilter}
            />
            {/* {isCreate && (
              <Button
                type="button"
                size="medium"
                variant="text"
                startIcon={<AddIcon />}
                onClick={() => navigate("create")}
                sx={{
                  minWidth: "154px",
                }}
              >
                Add Card
              </Button>
            )} */}
            <Button
              type="button"
              size="medium"
              variant="text"
              color="gray"
              sx={{
                minWidth: "40px",
                p: "8px",
              }}
              onClick={() => resetFilters()}
            >
              <CloseIcon />
            </Button>
          </>
        }
        onRowLink={(e) => {
          return `details/${e.id}`;
        }}
      />

      <CustomeDialog
        PaperProps={{
          sx: { width: "423px", height: "535px", p: "16px" },
        }}
        title="Products"
      />
    </>
  );
};
