import {
  ContentLayout,
  DeleteModal,
  usePermission,
  PageNotFound,
  Loader,
  HeaderBreadcrumbs,
} from "../../../shared";
import * as React from "react";
import { Button, Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { PermissionsList, MembersList, useRoleDetailsList } from "..";

export const RoleDetailsList = ({ isStoreAdmin }) => {
  const {
    state,
    handleChecked,
    handleCheckindeterminate,
    HandleFilterUsers,
    handleUsersSelect,
    isSelectedUser,
    handleAssignUser,
    handleClickOpen,
    handleClose,
    HandleStatusFilter,
    handleModal,
    handleDeleteRole,
    handleMemberModal,
    handleDeleteMember,
    setCurentTab,
    currentTab,
    resetFilters,
  } = useRoleDetailsList();
  const { isLoading, data, allPermissionsList, roleDeleteModal, roleDetails } =
    state;
  const isDelete = usePermission("role-delete");
  const handleChange = (event, newValue) => {
    setCurentTab("rolesAndPermission", newValue);
  };

  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <ContentLayout
        title={data.name}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: isStoreAdmin ? "/store-admin" : "/" },
              {
                name: "Roles & Permissions",
                href: isStoreAdmin
                  ? "/store-admin/roles-and-permissions"
                  : "/roles-and-permissions",
              },
              { name: data.name || "" },
            ]}
          />
        }
        rightContent={
          <>
            {roleDetails?.system_role === false &&
              !isStoreAdmin &&
              isDelete && (
                <Button
                  type="button"
                  size="medium"
                  variant="text"
                  className="delte"
                  sx={{
                    minWidth: "40px",
                    p: "8px",
                  }}
                  onClick={() => handleModal(true)}
                >
                  <img alt="delete" src="/icons/ic_delete_blue.svg" />
                </Button>
              )}
          </>
        }
      >
        {data.type === "store_admin" && !isStoreAdmin ? (
          <PermissionsList
            currentTab={currentTab}
            isLoading={isLoading}
            data={allPermissionsList}
            roleDetails={roleDetails}
            handleChecked={handleChecked}
            handleCheckindeterminate={handleCheckindeterminate}
            isStoreAdmin={isStoreAdmin}
          />
        ) : (
          <TabContext value={currentTab}>
            <Box
              className="sticky-Tab"
              sx={{ borderBottom: 1, borderColor: "divider", top: "104px" }}
            >
              <TabList onChange={handleChange}>
                <Tab label="Permissions" value="1" />
                <Tab label="Members" value="2" />
              </TabList>
            </Box>
            <TabPanel sx={{ px: "0" }} value="1">
              <PermissionsList
                isLoading={isLoading}
                data={allPermissionsList}
                roleDetails={roleDetails}
                handleChecked={handleChecked}
                handleCheckindeterminate={handleCheckindeterminate}
                isStoreAdmin={isStoreAdmin}
              />
            </TabPanel>
            <TabPanel sx={{ px: "0" }} value="2">
              <MembersList
                state={state}
                HandleFilterUsers={HandleFilterUsers}
                handleUsersSelect={handleUsersSelect}
                isSelectedUser={isSelectedUser}
                handleAssignUser={handleAssignUser}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                HandleStatusFilter={HandleStatusFilter}
                handleMemberModal={handleMemberModal}
                handleDeleteMember={handleDeleteMember}
                isStoreAdmin={isStoreAdmin}
                resetFilters={resetFilters}
              />
            </TabPanel>
          </TabContext>
        )}
      </ContentLayout>
      <DeleteModal
        open={roleDeleteModal}
        handleClose={() => handleModal(false)}
        onConfirm={() => handleDeleteRole("details")}
      />
    </>
  );
};
