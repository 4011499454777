import { API } from "../../../utils";

export const getMenuBannersList = async (query) => {
  const res = await API.get(`banners`, query);
  return res.data;
};

export const getMenuBannerDetailsById = async (id) => {
  const res = await API.get(`banners/${id}`);
  return res.data;
};

export const createMenuBanner = async (payload) => {
  const res = await API.post(`banners`, payload);
  return res.data;
};

export const editMenuBanner = async (id, payload) => {
  const res = await API.post(`banners/${id}`, payload);
  return res.data;
};

export const deleteMenuBannerById = async (id) => {
  const res = await API.delete(`banners/${id}`);
  return res.data;
};

export const changeMenuBannerStatus = async (id, payload) => {
  const res = await API.post(`banners/${id}/status`, payload);
  return res.data;
};
