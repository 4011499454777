import React from "react";
import { useDropdownApis } from "../../../shared";
import { useImmer } from "use-immer";
import { deleteQrCodeDetailsApi, getQrCodeListApi } from "../apis/qrCodeApis";
import { errorMessage, successMessage } from "../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { TableStateContext } from "../../../store";

const tableId = "qr_code_list";

export const useQrCodeList = () => {
  const {
    state: { stores },
  } = useDropdownApis({
    isStoreList: true,
  });

  const { filterState, filterStateDispatch } =
    React.useContext(TableStateContext);

  const tableCache = filterState?.params[tableId];

  const [state, setState] = useImmer({
    isDialogOpen: false,
    deleteAlert: {
      open: false,
      id: null,
    },
    qr: {
      list: [],
      isLoading: false,
      search: tableCache?.search ? tableCache.search : "",
      appliedFilter: tableCache?.appliedFilter
        ? tableCache?.appliedFilter
        : null,
      resetPagination: false,
    },
    filter: {
      storeList: [],
    },
  });

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const breadcrumbLinks = [
    { name: "Dashboard", href: "/" },
    { name: "QR Code", href: "" },
  ];

  // ========================================================================== API SECTION ==========================================================================
  /**
   * Fetches a list of QR codes based on the provided search parameters and updates the state with the retrieved data.
   *
   * @async
   * @function getQrCodeList
   * @param {Object} params - The parameters to filter the QR code list.
   * @param {string} [params.search] - The search term to filter the QR codes.
   * @param {string} [params.store_id] - The ID of the store to filter QR codes.
   * @throws {Object} Throws an error object containing a message if the API call fails or if the response indicates an error.
   *
   * @returns {Promise<void>} A promise that resolves when the function completes. It does not return any value.
   */
  const getQrCodeList = async (params) => {
    triggerLoading(true);
    try {
      // Update table context
      filterStateDispatch({
        type: "SET_DATA",
        page: tableId,
        data: {
          search: params?.search,
          appliedFilter: params?.appliedFilter,
        },
      });

      const response = await getQrCodeListApi({
        search: params?.search,
        store_id: params?.store_id,
      });
      const { success, message, data } = response;
      if (success && data) {
        setState((draft) => {
          draft.qr.list = data;
        });
      } else {
        const exception = { response: { data: { message } } };
        throw exception;
      }
    } catch (error) {
      const message =
        error.response?.data?.message ?? "Failed to retrieve QR code list!!";
      errorMessage(message);
    } finally {
      triggerLoading(false);
    }
  };

  /**
   * Deletes a QR code identified by its unique ID and refreshes the table upon success.
   *
   * @async
   * @function deleteQrCode
   * @param {number|string} qrCodeId - The unique identifier of the QR code to delete.
   * @throws {Object} Throws an error object containing a message if the API call fails or if the response indicates an error.
   *
   * @returns {Promise<void>} A promise that resolves when the function completes. It does not return any value.
   */
  const deleteQrCode = async (qrCodeId) => {
    toggleDeleteDialog({ status: false });
    try {
      const response = await deleteQrCodeDetailsApi(qrCodeId);
      const { success, message } = response;
      if (success) {
        successMessage(message);
        reloadTable();
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message = error.response?.data?.message;
      errorMessage(message);
    }
  };

  // ================================================================== UTIL FUNCTIONS =================================================================================
  // Triggers loading state for QR codes by updating the isLoading flag in the state
  const triggerLoading = (status) => {
    setState((draft) => {
      draft.qr.isLoading = status;
    });
  };

  // Toggles the dialog for editing or viewing a QR code based on its ID
  const toggleDialog = (qrCodeId = null) => {
    if (qrCodeId) navigate(pathname.concat("?", qrCodeId.toString()));
    else navigate(pathname, { replace: true });

    setState((draft) => {
      draft.isDialogOpen = !draft.isDialogOpen;
    });
  };

  // Reloads the QR code list by fetching the latest data
  const reloadTable = async () => {
    await getQrCodeList();
  };

  // Toggles the delete confirmation dialog and sets the ID of the QR code to delete
  const toggleDeleteDialog = ({ status = false, id = null }) => {
    setState((draft) => {
      draft.deleteAlert.open = status;
      draft.deleteAlert.id = id;
    });
  };

  // Resets pagination and briefly changes the state to trigger a UI update
  const resetPagination = (status) => {
    setState((draft) => {
      draft.qr.resetPagination = status;
    });

    setTimeout(() => {
      setState((draft) => {
        draft.qr.resetPagination = !status;
      });
    }, 1000);
  };

  // Handles the confirmation of a QR code deletion
  const handleConfirmDelete = async () => {
    const qrCodeId = state.deleteAlert.id;

    if (qrCodeId) {
      await deleteQrCode(qrCodeId);
    } else {
      errorMessage("Something went wrong!!, try agin");
    }
  };

  // Debounced search function to minimize the number of API calls during user input
  const debounceSearch = useDebouncedCallback(async (search) => {
    const formData = {};
    const {
      qr: { appliedFilter },
    } = state;

    await appendAppliedFilters({ formData, filter: appliedFilter, search });
    getQrCodeList(formData);
    resetPagination(true);
  }, 1000);

  // Handles the search input change and triggers the debounced search function
  const appendAppliedFilters = async ({
    formData,
    filter = null,
    search = "",
  }) => {
    if (search.trim().length > 0) formData["search"] = search;
    if (filter?.value) {
      formData["store_id"] = filter.value;
      formData["appliedFilter"] = filter;
    }
  };

  // Handles the search input change and triggers the debounced search function
  const handleTableSearch = (searchValue) => {
    setState((draft) => {
      draft.qr.search = searchValue;
    });

    debounceSearch(searchValue);
  };

  // Handles changes to the filter selection
  const handleFilterChange = async (filter) => {
    const formData = {};
    const {
      qr: { search },
    } = state;
    setState((draft) => {
      draft.qr.appliedFilter = filter;
    });

    await appendAppliedFilters({ formData, filter, search });

    getQrCodeList(formData);
    resetPagination(true);
  };

  // Effect to populate the store list in the filter when stores are available
  React.useEffect(() => {
    if (stores.length > 0)
      setState((draft) => {
        draft.filter.storeList = stores.map((store) => ({
          label: store.name,
          value: store.id,
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores]);

  // Effect to fetch QR code list on component mount with applied filters and search term
  React.useEffect(() => {
    const formData = {};
    const {
      qr: { search, appliedFilter },
    } = state;

    appendAppliedFilters({ formData, filter: appliedFilter, search }).then(
      () => {
        getQrCodeList(formData);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    state,
    tableId,
    breadcrumbLinks,
    toggleDialog,
    handleFilterChange,
    handleTableSearch,
    toggleDeleteDialog,
    handleConfirmDelete,
  };
};
