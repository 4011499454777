import {
  ContentLayout,
  Loader,
  PageNotFound,
  HeaderBreadcrumbs,
} from "../../../shared";
import * as React from "react";
import {
  Button,
  Stack,
  Card,
  Switch,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";

import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useAdminUsers } from "..";
import SimpleReactValidator from "simple-react-validator";

export const CreateAndEditAdminUsers = () => {
  const { id } = useParams();
  const { state: storeName } = useLocation();
  const [searchParams] = useSearchParams();
  const isStore = searchParams.get("isStore");
  const updateMode = id ? true : false;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const {
    state,
    handleInputChange,
    onUpdateUser,
    globalData,
    sendInvitation,
    isStoreAdmin,
  } = useAdminUsers({
    isCreate: !id ? true : false,
    isDetailsId: id,
    storeAdmin: isStore,
  });
  const { details, isSaveButton, isLoading } = state.basicDetails;
  const { allRoles, roleTypes } = state.rolesDetails;
  const {
    first_name,
    last_name,
    email,
    phone,
    country_code,
    timezone,
    status,
    role_id,
    is_pos_role,
  } = state.basicDetails.updateData;

  const onSubmit = () => {
    if (validator.current.allValid()) {
      updateMode ? onUpdateUser(id) : sendInvitation();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }

  const roleTypeErrorMessage = validator.current.message(
    "Role Type",
    is_pos_role,
    "required"
  );

  return (
    <>
      <ContentLayout
        title={updateMode ? details?.name : "New User"}
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              onClick={onSubmit}
              disabled={isSaveButton}
            >
              {updateMode ? "Update" : "Send Invite"}
            </Button>
          </>
        }
        breadcrumbs={
          isStore ? (
            <>
              <HeaderBreadcrumbs
                links={[
                  { name: "Dashboard", href: "/" },
                  {
                    name: "Stores",
                    href: "/stores",
                  },
                  {
                    name: storeName,
                    href: `/stores/details/${isStore}`,
                  },
                  { name: "create" },
                ]}
              />
            </>
          ) : (
            <>
              <HeaderBreadcrumbs
                links={[
                  {
                    name: "Dashboard",
                    href: isStoreAdmin ? "/store-admin" : "/",
                  },
                  {
                    name: "Admin User",
                    href: isStoreAdmin
                      ? "/store-admin/admin-users"
                      : "/admin-users",
                  },
                  id
                    ? {
                        name: details?.name,
                        href: isStoreAdmin
                          ? `/store-admin/admin-users/details/${id}`
                          : `/admin-users/details/${id}`,
                      }
                    : null,
                  { name: id ? "Edit" : "Create" },
                ].filter(Boolean)}
              />
            </>
          )
        }
      >
        {updateMode && isLoading ? (
          <Loader />
        ) : (
          <>
            {!updateMode ? (
              <Card sx={{ p: "32px 24px", mb: "24px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      sx={{ width: "150px" }}
                    >
                      Basic Details
                    </Typography>
                  </Grid>
                  <Grid item sm={8}>
                    <Stack width={"100%"} gap="20px">
                      <InputControl
                        name="email"
                        label="Email Address *"
                        value={email}
                        onChange={handleInputChange}
                        error={validator.current.message(
                          "Email Address",
                          email,
                          "required|email"
                        )}
                        helperText={validator.current.message(
                          "Email Address",
                          email,
                          "required|email"
                        )}
                      />
                      <Stack flexDirection="row" gap="20px">
                        {isStoreAdmin && (
                          <InputControl
                            type="dropdown"
                            options={roleTypes}
                            value={
                              roleTypes?.find(
                                (roleType) => roleType.value === is_pos_role
                              ) ?? null
                            }
                            onChange={(e, newValue) => {
                              handleInputChange({
                                target: {
                                  name: "is_pos_role",
                                  value: newValue?.value ?? newValue,
                                },
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Role Type"
                                required
                                error={Boolean(roleTypeErrorMessage)}
                                helperText={roleTypeErrorMessage}
                              />
                            )}
                          />
                        )}

                        <InputControl
                          type="dropdown"
                          disableClearable
                          options={allRoles ?? []}
                          getOptionLabel={(option) => option.display_name}
                          value={
                            (allRoles?.length > 0 &&
                              role_id &&
                              allRoles?.find((c) => c.id === role_id)) ||
                            null
                          }
                          onChange={(e, newValue) => {
                            handleInputChange({
                              target: {
                                name: "role_id",
                                value: newValue.id,
                              },
                            });
                          }}
                          disabled={is_pos_role === null}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Role"
                              required
                              error={validator.current.message(
                                "Role",
                                role_id,
                                "required"
                              )}
                              helperText={validator.current.message(
                                "Role",
                                role_id,
                                "required"
                              )}
                            />
                          )}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            ) : (
              <>
                <Card sx={{ p: "32px 24px", mb: "24px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ width: "150px" }}
                      >
                        Basic Details
                      </Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <Stack width={"100%"} gap="20px">
                        <Stack direction="row" gap="20px">
                          <InputControl
                            name="first_name"
                            label="First Name *"
                            value={first_name}
                            onChange={handleInputChange}
                            error={validator.current.message(
                              "First Name",
                              first_name,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "First Name",
                              first_name,
                              "required"
                            )}
                          />
                          <InputControl
                            name="last_name"
                            label="Last Name *"
                            value={last_name}
                            onChange={handleInputChange}
                            error={validator.current.message(
                              "Last Name",
                              last_name,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Last Name",
                              last_name,
                              "required"
                            )}
                          />
                        </Stack>
                        <InputControl
                          name="email"
                          label="Email Address *"
                          value={email}
                          onChange={handleInputChange}
                          error={validator.current.message(
                            "Email Address",
                            email,
                            "required|email"
                          )}
                          helperText={validator.current.message(
                            "Email Address",
                            email,
                            "required|email"
                          )}
                        />
                        <Stack direction="row" gap="20px">
                          <InputControl
                            type="dropdown"
                            disableClearable
                            options={globalData?.country_codes ?? []}
                            getOptionLabel={(option) => option.label}
                            value={
                              globalData?.country_codes?.length > 0 &&
                              country_code &&
                              globalData?.country_codes?.find(
                                (c) => c.value === country_code
                              )
                            }
                            onChange={(e, newValue) => {
                              handleInputChange({
                                target: {
                                  name: "country_code",
                                  value: newValue.value,
                                },
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Country code"
                                required
                                error={validator.current.message(
                                  "Country Code",
                                  country_code,
                                  "required"
                                )}
                                helperText={validator.current.message(
                                  "Country Code",
                                  country_code,
                                  "required"
                                )}
                              />
                            )}
                          />

                          <InputControl
                            name="phone"
                            label="Phone Number *"
                            type="tel"
                            value={phone}
                            onChange={handleInputChange}
                            error={validator.current.message(
                              "Phone Number",
                              phone,
                              "required|numeric|size:10"
                            )}
                            helperText={validator.current.message(
                              "Phone Number",
                              phone,
                              "required|numeric|size:10"
                            )}
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>

                <Card sx={{ p: "32px 24px", mb: "24px" }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack>
                      <Typography variant="subtitle1" fontWeight={600}>
                        User Activation
                      </Typography>
                    </Stack>
                    <Switch
                      name="status"
                      defaultChecked={status ? true : false}
                      value={status}
                      onChange={handleInputChange}
                    />
                  </Stack>
                </Card>
                <Card sx={{ p: "32px 24px", mb: "24px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ width: "150px" }}
                      >
                        Time zone
                      </Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <Stack width={"100%"} gap="20px">
                        <InputControl
                          name="timezone"
                          label="Select Time Zone *"
                          type="select"
                          value={timezone}
                          optionValue={globalData?.timezones ?? []}
                          onChange={handleInputChange}
                          error={validator.current.message(
                            "Time Zone",
                            timezone,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "Time Zone",
                            timezone,
                            "required"
                          )}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              </>
            )}
          </>
        )}
      </ContentLayout>
    </>
  );
};
