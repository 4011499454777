import { useImmer } from "use-immer";
import { useLocation, useNavigate } from "react-router-dom";
import {
  authentication,
  getForgotPassword,
  resetPassword,
} from "../api/authenticate.api";
import { useContext, useState } from "react";
import { AuthContext } from "../../../store/AuthProvider";
import { errorMessage, successMessage } from "../../../utils";

export const useAuthenticate = () => {
  const navigate = useNavigate();
  const { state: location } = useLocation();
  const { auth, setAuth } = useContext(AuthContext);
  const [isDisable, setDisable] = useState(false);
  const [state, setState] = useImmer({
    errors: null,
    isBusy: false,
    isSuccess: false,
    values: {
      email: "",
    },
    data: {
      token: "",
      showNewPassword: false,
      showConfirmPassword: false,
    },
    reset: {
      email: "",
      token: "",
      password: "",
      password_confirmation: "",
    },
  });
  const [signin, setSignin] = useImmer({
    errors: "",
    isBusy: false,
    data: {
      email: "",
      password: "",
      showPassword: false,
      rememberMe: false,
    },
  });
  const toggleShowPassword = (name) => {
    setSignin((draft) => {
      draft.data[name] = !draft.data[name];
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigateAction = (res, adminType) => {
    const { action } = res.data;
    if (action === "dashboard") {
      if (adminType === "admin") {
        navigate("/", { replace: true });
      } else if (adminType === "store-admin") {
        navigate("/store-admin/", { replace: true });
      }
    } else {
      navigate("/authentication/ChangePassword", { replace: true });
    }
  };
  const handleLogin = async () => {
    setDisable(true);
    try {
      setSignin((draft) => {
        draft.isBusy = true;
      });
      const res = await authentication(signin.data);
      if (res.success) {
        const selectedStoreId =
          res.data.stores.length > 0 ? res.data?.stores[0].id : null;
        const data = {
          ...res.data,
          selectedStoreId,
        };
        setAuth((draft) => {
          draft.isAuthenticated = true;
          draft.data = data;
        });
        setSignin((draft) => {
          draft.isBusy = false;
        });
        const isSuperAdmin = res.data.roles.find(
          (item) => item.name === "admin"
        );
        const isStoreAdmin = res.data.roles.find(
          (item) => item.name === "store-admin"
        );
        if (location?.from) {
          navigate(location.from, { replace: true });
        } else if (isSuperAdmin) {
          navigateAction(res, "admin");
        } else if (isStoreAdmin) {
          navigateAction(res, "store-admin");
        } else {
          errorMessage("You Dont have any of the permission");
        }
      } else {
        setSignin((draft) => {
          draft.isAuthenticated = false;
          draft.isBusy = false;
          draft.errors = res.message;
        });
      }
      setDisable(false);
    } catch (err) {
      setDisable(false);
      setSignin((draft) => {
        draft.isBusy = false;
        draft.isAuthenticated = true;
        console.log("err", err);
        draft.errors = err.response
          ? err.response.data.message
          : "Unable to connect";
      });
    }
  };
  const handleForgot = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getForgotPassword(state.values.email);
      if (res.success) {
        setState((draft) => {
          draft.isAuthenticated = true;
          draft.isSuccess = true;
          draft.data = res.data;
          draft.isBusy = false;
        });
      } else {
        setState((draft) => {
          draft.isBusy = false;

          draft.errors = res.message;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
        draft.errors = err.response
          ? err.response.data.message
          : "Unable to connect";
      });
    }
  };
  const handleResetPassword = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await resetPassword(state.reset);
      if (res.success) {
        successMessage(" Password changed successfully");
        setState((draft) => {
          draft.isAuthenticated = true;
          draft.isSuccess = true;
          draft.reset = res.data;
          draft.isBusy = false;
        });
        navigate("/authentication/login", { replace: true });
      } else {
        setState((draft) => {
          draft.isBusy = false;

          draft.errors = res.message;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
        draft.errors = err.response
          ? err.response.data.message
          : "Unable to connect";
      });
    }
  };

  const onChngeUser = (e) => {
    const { name, value } = e.target;
    setSignin((draft) => {
      draft.data[name] = value;
    });
    if (name !== "rememberMe") {
      setSignin((draft) => {
        draft.errors = "";
      });
    }
  };

  const onChanges = (e) => {
    const { name, value } = e.target;

    setState((draft) => {
      draft.values[name] = value;
      draft.errors = null;
    });
  };
  const onReset = (event) => {
    const { name, value } = event.target;
    setState((draft) => {
      draft.reset[name] = value;
      draft.errors = null;
    });
  };

  return {
    signin,
    state,
    setState,
    isDisable,
    setSignin,
    handleResetPassword,
    onChanges,
    onReset,
    onChngeUser,
    handleLogin,
    handleForgot,
    toggleShowPassword,
    handleMouseDownPassword,
  };
};
