import {
  CustomTable,
  CustomeDialog,
  DeleteModal,
  usePermission,
  Loader,
  CheckBoxListDialog,
} from "../../../shared";
import * as React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { sortItems } from "../../../utils";

export const ProductAvailabilityGroupsList = (props) => {
  const {
    data,
    HandleFilterAG,
    handleAGSelect,
    handleModal,
    handleAssignAGToProduct,
    handleDeleteAGFromProduct,
  } = props;
  const {
    dataList,
    isOpen,
    isLoading,
    dialogLoading,
    filteredList,
    selectedItems,
    searchText,
    isDeleteOpen,
    isSaveButton,
  } = data;
  const navigate = useNavigate();
  const isDeletable = usePermission("product-delete");

  const ActionButtons = ({ deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Start Time",
        accessor: "start_time",
        disableSortBy: true,
      },
      {
        Header: "End Time",
        accessor: "end_time",
        disableSortBy: true,
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              deleteAction={() => {
                handleModal("AG-from-product", true, row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CustomTable
            columns={columns}
            data={dataList}
            searchBar={false}
            tableId="product_availabilityGroup"
            tableTitle="Availability Groups"
            headerRightContent={
              <>
                <Button
                  type="button"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => handleModal("AG-add", true)}
                >
                  Add
                </Button>
              </>
            }
            onRowLink={(e) => {
              return `/availabilityGroup/details/${e.id}`;
            }}
          />
          <CustomeDialog
            open={isOpen}
            handleClose={() => handleModal("AG-add", false)}
            PaperProps={{
              sx: { width: "423px", height: "453px", p: "16px" },
            }}
            title="Availability Groups"
            content={
              <CheckBoxListDialog
                Items={filteredList}
                onSearch={HandleFilterAG}
                loading={dialogLoading}
                onSelect={handleAGSelect}
                onSubmit={handleAssignAGToProduct}
                disabled={!selectedItems.length || isSaveButton}
                isSelected={(id) => selectedItems.includes(id)}
                onClearSearch={() => {
                  HandleFilterAG("initial");
                }}
                value={searchText}
              />
            }
          />
          <DeleteModal
            open={isDeleteOpen}
            handleClose={() => handleModal("AG-from-product", false, null)}
            onConfirm={handleDeleteAGFromProduct}
          />
        </>
      )}
    </>
  );
};
