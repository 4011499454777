import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { errorMessage, successMessage } from "../../../utils";
import {
  getAllUsers,
  getEmailSettingsDetails,
  getEmailSettingsList,
  updateEmailSettings,
} from "../api";
import qs from "qs";

export const useEmailSettings = ({ isList, isDetails }) => {
  const [state, setState] = useImmer({
    isLoading: true,
    isSaveButtonBusy: false,
    dataList: [],
    details: null,
    updateData: [],
    userList: [],
    isBusy: false,
  });

  useEffect(() => {
    if (isList) {
      handleEmailSettingsList();
    }
  }, [isList]);

  useEffect(() => {
    if (isDetails) {
      handleEmailSettingsDetails(isDetails);
    }
  }, [isDetails]);

  const handleEmailSettingsList = async () => {
    try {
      const res = await getEmailSettingsList();
      if (res.success) {
        setState((draft) => {
          draft.dataList = res.data;
          draft.isLoading = false;
        });
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const handleEmailSettingsDetails = async (id) => {
    try {
      const res = await getEmailSettingsDetails(id);
      if (res.success) {
        setState((draft) => {
          draft.details = res.data;
          draft.isLoading = false;
          draft.updateData = res.data.emails;
        });
      } else {
        errorMessage(res.message);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const handleInputChange = (e, v) => {
    onSaveEmailSettings(e);
  };

  const handleGetUserList = async ({ search = null }) => {
    let params = {};

    if (search) {
      params = { ...params, search };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      const res = await getAllUsers(query);
      if (res.success) {
        setState((draft) => {
          draft.userList = res.data.map((data) => {
            return { value: data.id, label: data.name };
          });
          draft.isBusy = false;
        });
        const selected = state.updateData.map((v) => v.id).filter(Boolean);
        const filteredData = res.data.filter((v) => !selected.includes(v.id));
        return {
          options: filteredData.map((data) => {
            return {
              value: data.id,
              id: data.id,
              name: data.name,
              email: data.email,
            };
          }),
        };
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      return {
        options: [],
      };
    }
  };

  const handleAddEmail = (value) => {
    let data = [
      ...state.updateData,
      {
        id: null,
        name: null,
        email: value,
      },
    ];
    onSaveEmailSettings(data);
  };

  const handleDeleteEmail = (value) => {
    onSaveEmailSettings(state.updateData.filter((val) => val !== value));
  };

  const onSaveEmailSettings = async (data) => {
    const payload = {
      emails: data
        .map((v) => {
          if (v.id === null) return v.email;
        })
        .filter(Boolean),
      user_id: data.map((v) => v.id).filter(Boolean),
    };
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await updateEmailSettings(state.details.id, payload);
      if (res.success) {
        setState((draft) => {
          draft.isSaveButtonBusy = false;
          draft.updateData = data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  return {
    state,
    handleGetUserList,
    handleInputChange,
    handleAddEmail,
    handleDeleteEmail,
    onSaveEmailSettings,
  };
};
