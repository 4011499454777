import { API } from "../../../utils";

export const getPushNotification = async () => {
  const res = await API.get("push-notifications");
  return res.data;
};

export const getPushNotificationDetails = async (id) => {
  const res = await API.get(`push-notifications/${id}`);
  return res.data;
};

export const updatePushNotification = async (id, payload) => {
  const res = await API.post(`push-notifications/${id}`, payload);
  return res.data;
};

export const createPushNotification = async (payload) => {
  const res = await API.post(`push-notifications`, payload);
  return res.data;
};

export const deletePushNotificationById = async (id) => {
  const res = await API.delete(`push-notifications/${id}`);
  return res.data;
};