import { createContext } from "react";
import { useTableScope } from "../shared/hooks";

export const TableStateContext = createContext({});

// context provider
export const TableScopeProvider = ({ children }) => {
  const value = useTableScope();

  return (
    <TableStateContext.Provider value={value}>
      {children}
    </TableStateContext.Provider>
  );
};
