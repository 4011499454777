import * as React from "react";
import { Button, Grid, Stack, Typography, Box } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";

export const PointAdjustment = (props) => {
  const {
    points,
    data,
    handlePointsInputChange,
    onUpdatePointAdjustment,
    pointAdjustmentLoading,
  } = props;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        points: {
          message: "The minimum point must be zero",
          rule: (value) => {
            return Number(value) + Number(points) < 0 ? false : true;
          },
        },
      },
    })
  );

  const onSubmit = () => {
    if (validator.current.allValid()) {
      onUpdatePointAdjustment();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  return (
    <>
      <Stack
        sx={{
          mt: "24px",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            height="43px"
            gap="16px"
          >
            <Typography variant="subtitle1" lineHeight="19px" minWidth="118px">
              Current Points
            </Typography>
            <Typography
              variant="subtitle1"
              lineHeight="19px"
              color="#808080"
              width="100%"
            >
              {points}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            height="72px"
          >
            <Typography variant="subtitle1">Adjustment</Typography>
            <InputControl
              label="Points"
              name="points"
              width="434px"
              required
              type="number"
              value={data.points}
              onChange={handlePointsInputChange}
              error={validator.current.message(
                "Points",
                data.points,
                "required|points"
              )}
              helperText={validator.current.message(
                "Points",
                data.points,
                "required|points"
              )}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            height="43px"
            gap="16px"
          >
            <Typography variant="subtitle1" lineHeight="19px" minWidth="118px">
              New Points
            </Typography>
            <Typography
              variant="subtitle1"
              lineHeight="19px"
              color="#808080"
              width="100%"
            >
              {Number(data.points) + Number(points)}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            height="72px"
          >
            <Typography variant="subtitle1">Remarks</Typography>

            <InputControl
              label="Enter Remarks"
              name="remarks"
              width="434px"
              onChange={handlePointsInputChange}
            />
          </Stack>
        </Box>
      </Stack>

      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "right",
          alignContent: "right",
        }}
      >
        <Button
          sx={{ height: "40px", width: "119px" }}
          variant="contained"
          fullWidth
          disabled={pointAdjustmentLoading}
          onClick={onSubmit}
        >
          Save
        </Button>
      </Stack>
    </>
  );
};
