import { ContentLayout } from "../../../shared";
import * as React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { LoayaltyCustomers } from "./LoyaltyCustomers";
import { LoyaltyPointHistory } from "./LoyaltyPointHistory";
import { LoyaltyCards } from "./LoyaltyCards";
import { useLoyalty } from "../hooks";
import { LoyaltySponsorshipGroups } from "./LoyaltySponsorshipGroups";
import { useLocation, useNavigate } from "react-router-dom";

export const LoyaltyList = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const {
    state,
    globalData,
    currentTab,
    setCurentTab,
    onChangeFilter,
    handleSearch,
    onSeachClear,
    resetFilters,
    sendEmailVerification,
    hasLoyaltyGroupview,
    gotoPageCustomerList,
    setPageSizeCustomerList,
    setPageSizePointHistory,
    gotoPagePointHistory,
    handlePointHistorySearch,
    handleSortPointHistory,
    handleSortCustomerList,
    onBlurTab,
  } = useLoyalty({ isList: true, type: search });
  const { loyaltyCards, customers, pointHistory } = state;

  const handleChange = (event, newValue) => {
    navigate({
      pathname: "/loyalty-cards",
      search: newValue,
    });
  };

  return (
    <>
      <ContentLayout title="Loyalty Cards">
        <TabContext value={search}>
          <Box
            className="sticky-Tab"
            sx={{ borderBottom: 1, borderColor: "divider", top: "88px" }}
          >
            <TabList onChange={handleChange}>
              <Tab label="Card Types" value="" />
              <Tab label="Cards" value="?cards" />
              <Tab label="Point History" value="?point-history" />
              {hasLoyaltyGroupview && <Tab label="Groups" value="?groups" />}
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="">
            <LoyaltyCards data={loyaltyCards} />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="?cards">
            <LoayaltyCustomers
              data={customers}
              globalData={globalData}
              onChangeFilter={onChangeFilter}
              handleSearch={handleSearch}
              onSeachClear={onSeachClear}
              resetFilters={resetFilters}
              sendEmailVerification={sendEmailVerification}
              gotoPageCustomerList={gotoPageCustomerList}
              setPageSizeCustomerList={setPageSizeCustomerList}
              handleSortCustomerList={handleSortCustomerList}
              onBlurTab={onBlurTab}
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="?point-history">
            <LoyaltyPointHistory
              data={pointHistory}
              setPageSizePointHistory={setPageSizePointHistory}
              gotoPagePointHistory={gotoPagePointHistory}
              handleSearch={handlePointHistorySearch}
              handleSortPointHistory={handleSortPointHistory}
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="?groups">
            <LoyaltySponsorshipGroups />
          </TabPanel>
        </TabContext>
      </ContentLayout>
    </>
  );
};
