import * as React from "react";
import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { useTabs } from "../../hooks";
import { useLocation, Link } from "react-router-dom";
import { SidebarSubMenu } from "./SidebarSubMenu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { AuthContext } from "../../../store/AuthProvider";

export const SidebarMenu = (props) => {
  const { item, index } = props;
  const [open, setOpen] = React.useState(false);
  const { resetCurrentTabs } = useTabs();
  const { auth } = React.useContext(AuthContext);
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const basepath = isStoreAdmin
    ? "/" + pathname.split("/")[2]
    : "/" + pathname.split("/")[1];

  const handleClick = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (item.subMenu) {
      setOpen(
        item.subMenu
          .map((data) => {
            return data.href;
          })
          .includes(basepath)
      );
    }
  }, [pathname]);
  const hasPermission = auth?.data?.permissions?.find(
    (permission) =>
      permission.name === item.permission ||
      permission.name === item.permission2
  );

  const IsAllPermission = (sub) => {
    if (sub) {
      const array = sub.map((data) => {
        const hasPermission = auth?.data?.permissions?.find(
          (permission) =>
            permission.name === data.permission ||
            permission.name === data.permission2
        );
        if (isStoreAdmin && data.storeAdmin) {
          return hasPermission;
        } else if (!isStoreAdmin && data.superAdmin !== false) {
          return hasPermission;
        }
      });
      return !array.every((currentValue) => currentValue === undefined);
    }
  };

  const IsALLStoreAdmin = (sub) => {
    if (!isStoreAdmin) {
      return true;
    } else if (sub) {
      const array = sub.map((data) => {
        return data.storeAdmin ? true : false;
      });
      return !array.every((v) => v === false);
    }
  };

  return (
    <>
      {item.subMenu &&
      IsAllPermission(item.subMenu) &&
      IsALLStoreAdmin(item.subMenu) ? (
        <React.Fragment key={index}>
          <Box
            sx={{
              alignItems: "center",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              py: "8px",
            }}
          >
            <ListItemButton
              key={item.title}
              sx={{ py: 0, minHeight: 32 }}
              onClick={handleClick}
            >
              <ListItemIcon className="sidebar-icon">{item.icon}</ListItemIcon>

              <ListItemText>{item.title}</ListItemText>
              <ListItemIcon sx={{ marginLeft: "auto", minWidth: "auto" }}>
                {open ? (
                  <ExpandLess sx={{ color: "#FFFCEB" }} />
                ) : (
                  <ExpandMore sx={{ color: "#FFFCEB" }} />
                )}
              </ListItemIcon>
            </ListItemButton>

            {/* </Link> */}
          </Box>
          <Collapse in={open} timeout="auto">
            {item.subMenu.map((item, index) => {
              const hasPermission = auth?.data?.permissions?.find(
                (permission) =>
                  permission.name === item.permission ||
                  permission.name === item.permission2
              );
              if (hasPermission) {
                if (isStoreAdmin) {
                  if (item.storeAdmin) {
                    return (
                      <SidebarSubMenu key={index} item={item} index={index} />
                    );
                  }
                } else if (item.superAdmin !== false) {
                  return (
                    <SidebarSubMenu key={index} item={item} index={index} />
                  );
                }
              }
            })}
          </Collapse>
        </React.Fragment>
      ) : (hasPermission && isStoreAdmin && item.storeAdmin) ||
        (hasPermission && !isStoreAdmin && item.superAdmin !== false) ? (
        <Box
          key={index}
          sx={{
            alignItems: "center",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            py: "8px",
          }}
          className={`${basepath === item.href ? "sidebar-link-active" : ""}`}
        >
          {item?.openInNewTab ? (
            <a className="sidebar-link" href={`${item.href}`} target="_blank">
              <ListItemButton key={item.title} sx={{ py: 0, minHeight: 32 }}>
                <ListItemIcon className="sidebar-icon">
                  {item?.icon}
                </ListItemIcon>
                <ListItemText>{item.title}</ListItemText>
              </ListItemButton>
            </a>
          ) : (
            <Link
              to={isStoreAdmin ? `/store-admin${item.href}` : `${item.href}`}
              onClick={() => resetCurrentTabs()}
              className="sidebar-link"
            >
              <ListItemButton key={item.title} sx={{ py: 0, minHeight: 32 }}>
                <ListItemIcon className="sidebar-icon">
                  {item.icon}
                </ListItemIcon>

                <ListItemText>{item.title}</ListItemText>
              </ListItemButton>
            </Link>
          )}
        </Box>
      ) : (
        ""
      )}
    </>
  );
};
