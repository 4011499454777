import { configureAbly, useChannel, usePresence } from "@ably-labs/react-hooks";

configureAbly({
  key: process.env.REACT_APP_ABLY_API_KEY,
  clientId: "admin",
});

export const useAbly = () => {
  const [presenceDataTerminal] = usePresence("presence-banjos", []);
  const [presenceDataKds] = usePresence("presence-kds", []);
  return { presenceDataKds, presenceDataTerminal };
};
