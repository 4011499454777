import {
  ContentLayout,
  DeleteModal,
  HeaderBreadcrumbs,
  Loader,
  PageNotFound,
} from "../../../shared";
import * as React from "react";
import { Box, Tab, Button } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  AvailableDetails,
  AvailabilityGroupStores,
  AvailabilityGroupProducts,
} from "../components";
import { useParams } from "react-router-dom";
import { useAvailabilityGroup } from "../hooks/useAvailabilityGroup";

export const AvaialabilityGroupDetails = () => {
  const { id } = useParams();
  const {
    state,
    storeList,
    handleDeleteAvailabilityGroup,
    toggleModal,
    HandleFilterStores,
    handleStoreSelect,
    handleAssignStoretoAvailability,
    handleModal,
    handleModals,
    HandleFilterProduct,
    handlePoductSelect,
    handleAssignProducttoAvailabilityGroup,
    DeleteModals,
    handleDeleteProducts,
    DeleteStoreModal,
    handleDeleteStores,
    isDeletable,
    isEditable,
    currentTab,
    setCurentTab,
    handleSelectAllStores,
    handleSelectAllProducts,
  } = useAvailabilityGroup({
    isAvailable: false,
    AvailabilityGroupId: id,
    isStore: true,
    isProduct: true,
  });

  const handleChange = (event, newValue) => {
    setCurentTab("availabilityGroupDetails", newValue);
  };
  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }
  return (
    <>
      <ContentLayout
        title={state.name}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Availability Group", href: "/availabilityGroup" },
                { name: state.name ?? "" },
              ]}
            />
          </>
        }
        rightContent={
          <>
            {isDeletable && (
              <Button
                type="button"
                size="medium"
                variant="text"
                className="delte"
                onClick={() => toggleModal(id, "detaials")}
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
              >
                <img alt="delete" src="/icons/ic_delete_blue.svg" />
              </Button>
            )}
          </>
        }
      >
        {state.isBusy ? (
          <Loader />
        ) : (
          <TabContext value={currentTab}>
            <Box
              className="sticky-Tab"
              sx={{ borderBottom: 1, borderColor: "divider", top: "104px" }}
            >
              <TabList onChange={handleChange}>
                <Tab label="Details" value="1" />
                <Tab label="Stores" value="2" />
                <Tab label="Products" value="3" />
              </TabList>
            </Box>
            <TabPanel sx={{ px: "0" }} value="1">
              <AvailableDetails state={state} isEditable={isEditable} />
            </TabPanel>
            <TabPanel sx={{ px: "0" }} value="2">
              <AvailabilityGroupStores
                state={state}
                HandleFilterStores={HandleFilterStores}
                handleStoreSelect={handleStoreSelect}
                handleAssignStoretoAvailability={
                  handleAssignStoretoAvailability
                }
                handleModal={handleModal}
                DeleteStoreModal={DeleteStoreModal}
                handleDeleteStores={handleDeleteStores}
                setCurentTab={setCurentTab}
                handleSelectAll={handleSelectAllStores}
              />
            </TabPanel>
            <TabPanel sx={{ px: "0" }} value="3">
              <AvailabilityGroupProducts
                state={state}
                handleModals={handleModals}
                HandleFilterProduct={HandleFilterProduct}
                handlePoductSelect={handlePoductSelect}
                handleAssignProducttoAvailabilityGroup={
                  handleAssignProducttoAvailabilityGroup
                }
                DeleteModals={DeleteModals}
                handleDeleteProducts={handleDeleteProducts}
                setCurentTab={setCurentTab}
                handleSelectAllProducts={handleSelectAllProducts}
              />
            </TabPanel>
          </TabContext>
        )}
      </ContentLayout>
      <DeleteModal
        open={state.isOpen}
        handleClose={() => toggleModal()}
        onConfirm={() => handleDeleteAvailabilityGroup()}
      />
    </>
  );
};
