import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import {
  getSuburbList,
  deleteSuburbById,
  getSuburbDetailsById,
  createSuburb,
  editSuburb,
  uploadData,
} from "../..";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import { useTabs } from "../../../shared/hooks/useTabs";
import { useDebouncedCallback } from "use-debounce";
import qs from "qs";
import { TableStateContext } from "../../../store";

export const useSuburbList = ({ isSuburb, stateId, suburbId }) => {
  const { appState } = useContext(AppContext);
  const { setCurentTab } = useTabs();
  const { stateandSuburb: currentTab } = appState.currentTabs;
  const {
    globalData: { imports },
  } = appState;
  const navigate = useNavigate();
  const [state, setState] = useImmer({
    suburbDetails: {
      isBusy: false,
      isOpen: false,
      id: null,
      cities: [],
      isSaveButtonBusy: false,
      initialLoad: true,
      pagination: {
        pageIndex: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      filterApplied: {
        search: null,
      },
      data: {
        name: "",
        state_id: null,
        postcode: "",
      },
    },
    httpStatusCodes: {
      stateDetails: "",
      suburbDetails: "",
    },
  });
  const PAGE_NAME = "suburbData";
  const {
    filterState: {
      params: { suburbData },
    },
    initialState,
    filterStateDispatch,
  } = useContext(TableStateContext);
  const debounced = useDebouncedCallback((value) => {
    const { search } = state.suburbDetails.filterApplied;
    const { pagination, sortBy } = state.suburbDetails;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.suburbDetails.filterApplied },
    });
    getSuburbsList({
      search: value,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  }, 1000);
  useEffect(() => {
    const { search } = suburbData.filterApplied;
    const { pagination, sortBy } = suburbData;
    if (isSuburb) {
      setState((draft) => {
        draft.suburbDetails.filterApplied = suburbData.filterApplied;
        draft.suburbDetails.pagination = pagination;
        draft.suburbDetails.sortBy = sortBy;
      });
      getSuburbsList({
        search,
        pagination: {
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
        },
        sortBy,
      });
    }
  }, [isSuburb]);

  useEffect(() => {
    if (suburbId) {
      getSuburbDetails(suburbId);
    }
  }, [suburbId]);

  const getSuburbDetails = async (suburbId) => {
    try {
      const res = await getSuburbDetailsById(suburbId);
      if (res.success) {
        setState((draft) => {
          draft.suburbDetails.data = res.data;
          draft.suburbDetails.suburbName = res.data.name;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.httpStatusCodes.suburbDetails = err.response.status;
      });
    }
  };

  const getSuburbsList = async ({ search, pagination }) => {
    let params = {};
    if (search) {
      params = { ...params, search };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.suburbDetails.isBusy = true;
      });
      const res = await getSuburbList(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.suburbDetails.isBusy = false;
          draft.suburbDetails.initialLoad = false;
          draft.suburbDetails.cities = res.data.data;
          draft.suburbDetails.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.suburbDetails.isBusy = false;
      });
    }
  };

  const handleModal = (type, id = null) => {
    setState((draft) => {
      draft.suburbDetails.id = id;
      draft.suburbDetails.isOpen = !draft.suburbDetails.isOpen;
    });
  };

  const handleDeleteSuburb = async () => {
    try {
      const res = await deleteSuburbById(state.suburbDetails.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.suburbDetails.cities = draft.suburbDetails.cities.filter(
            (item) => item.id !== state.suburbDetails.id
          );
        });
        handleModal("city");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onChange = (e, type) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft[type].data[name] = value;
    });
  };

  const onSaveSuburb = async () => {
    try {
      setState((draft) => {
        draft.suburbDetails.isSaveButtonBusy = true;
      });
      const res = await createSuburb(state.suburbDetails.data);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.suburbDetails.isSaveButtonBusy = false;
        });
        navigate(-1);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.suburbDetails.isSaveButtonBusy = false;
      });
    }
  };

  const onUpdateSuburb = async (id) => {
    try {
      setState((draft) => {
        draft.suburbDetails.isSaveButtonBusy = true;
      });
      const res = await editSuburb(id, state.suburbDetails.data);
      if (res.success) {
        successMessage("Updated Successfully");
        navigate("/state-and-suburb");
        setState((draft) => {
          draft.suburbDetails.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.suburbDetails.isSaveButtonBusy = false;
      });
      errorMessage(err.response.data.message);
    }
  };

  const onUpload = async (e) => {
    try {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      const res = await uploadData(formData);
      if (res.success) {
        successMessage(res.message);
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message ?? err.message);
    }
  };

  const onSearch = (e, type) => {
    setState((draft) => {
      draft[type].filterApplied.search = e.target.value;
    });
    debounced(e.target.value);
  };

  const onSeachClear = (type) => {
    const { status } = state.suburbDetails.filterApplied;
    const { pagination, sortBy } = state.suburbDetails;
    getSuburbsList({
      search: null,
      status,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft[type].filterApplied.search = "";
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: { ...state.filterApplied, searchText: "" } },
    });
  };
  const gotoPage = (page, type) => {
    if (type === "suburb") {
      const { search, pagination } = state.suburbDetails;
      getSuburbsList({
        search,
        pagination: {
          pageIndex: page,
          pageSize: pagination.pageSize,
        },
      });
      setState((draft) => {
        draft.suburbDetails.pagination.pageIndex = page;
      });
    }
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
  };

  const setPageSize = (e, type) => {
    if (type === "suburb") {
      const { search, pagination } = state.suburbDetails;
      getSuburbsList({
        search,
        pagination: {
          pageIndex: pagination.pageIndex,
          pageSize: +e.target.value,
        },
      });
      setState((draft) => {
        draft.suburbDetails.pagination.pageSize = +e.target.value;
        draft.suburbDetails.pagination.pageIndex = 1;
      });
    }
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: 1,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
  };

  return {
    state,
    handleModal,
    handleDeleteSuburb,
    onChange,
    onUpdateSuburb,
    currentTab,
    setCurentTab,
    imports,
    onUpload,
    onSeachClear,
    setPageSize,
    gotoPage,
    onSearch,
    onSaveSuburb,
  };
};
