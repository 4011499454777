import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { errorMessage } from "../../utils";
import {
  getAccounts,
  getAvailabilityGroupsList,
  getCategories,
  getCustomers,
  getFranchiseesList,
  getKeypads,
  getModifierGroups,
  getPriceLevels,
  getProductsList,
  getRoles,
  getStates,
  getSuburbs,
  getStore,
  getTaxGroups,
  getTerminals,
  getUsers,
  getBundleGroupsList,
  getCoupon,
} from "../api";
import qs from "qs";
import { useDebounce } from "use-debounce";

export const useDropdownApis = ({
  isStoreList,
  isCategoryList,
  isProductsList,
  isModifierGroupList,
  isAvailabilityGroupList,
  isAccountsList,
  isFranchiseeList,
  isStatesList,
  isSuburbList,
  isTerminalsList,
  isKeypadList,
  isTaxgroupList,
  isPricelevelsList,
  isRolesList,
  isCustomersList,
  isUsersList,
  isBundle,
  isBundleGroupList,
  isCouponList,
  isTerminalList,
}) => {
  const [state, setState] = useImmer({
    stores: [],
    categories: [],
    products: [],
    modifierGroups: [],
    availabilityGroups: [],
    accounts: [],
    franchisees: [],
    states: [],
    suburbs: [],
    terminals: [],
    keypads: [],
    taxgroups: [],
    pricelevels: [],
    roles: [],
    customers: [],
    users: [],
    coupons: [],
    product: {
      dataList: [],
      searchText: "",
      pagination: {
        pageIndex: 1,
        pageSize: 10,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
    },
    bundleGroups: [],
  });
  const [debouncedSearchText] = useDebounce(state.product.searchText, 1000);

  useEffect(() => {
    if (isStoreList) {
      handleGetStores();
    }
    if (isCategoryList) {
      handleGetCategories();
    }
    if (isProductsList) {
      handleGetProducts();
    }
    if (isModifierGroupList) {
      handleGetModifierGroups();
    }
    if (isAvailabilityGroupList) {
      handleGetAvailabilityGroups();
    }
    if (isAccountsList) {
      handleGetAccounts();
    }
    if (isFranchiseeList) {
      handleGetFranchisees();
    }
    if (isStatesList) {
      handleGetStates();
    }
    if (isTerminalsList) {
      handleGetTerminals();
    }
    if (isKeypadList) {
      handleGetKeypads();
    }
    if (isTaxgroupList) {
      handleGetTaxgroups();
    }
    if (isPricelevelsList) {
      handleGetPriceLevels();
    }
    if (isRolesList) {
      handleGetRoles();
    }
    if (isCustomersList) {
      handleGetCustomers();
    }
    if (isUsersList) {
      handleGetUsers();
    }
    if (isBundleGroupList) {
      handleGetBundleGroups();
    }
    if (isCouponList) {
      handleCouponList();
    }
  }, [
    isStoreList,
    isCategoryList,
    isProductsList,
    isModifierGroupList,
    isAvailabilityGroupList,
    isAccountsList,
    isFranchiseeList,
    isStatesList,
    isSuburbList,
    isTerminalsList,
    isKeypadList,
    isTaxgroupList,
    isPricelevelsList,
    isRolesList,
    isCustomersList,
    isUsersList,
    isBundleGroupList,
    isCouponList,
  ]);
  const handleGetStores = async () => {
    try {
      const res = await getStore();
      if (res.success) {
        setState((draft) => {
          draft.stores = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleGetCategories = async () => {
    try {
      const res = await getCategories();
      if (res.success) {
        setState((draft) => {
          draft.categories = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleGetProducts = async () => {
    try {
      const res = await getProductsList();
      if (res.success) {
        setState((draft) => {
          draft.products = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleGetModifierGroups = async () => {
    try {
      const res = await getModifierGroups();
      if (res.success) {
        setState((draft) => {
          draft.modifierGroups = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleGetAvailabilityGroups = async () => {
    try {
      const res = await getAvailabilityGroupsList();
      if (res.success) {
        setState((draft) => {
          draft.availabilityGroups = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleGetBundleGroups = async () => {
    try {
      const res = await getBundleGroupsList();
      if (res.success) {
        setState((draft) => {
          draft.bundleGroups = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleGetAccounts = async () => {
    try {
      const res = await getAccounts();
      if (res.success) {
        setState((draft) => {
          draft.accounts = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleGetFranchisees = async () => {
    try {
      const res = await getFranchiseesList();
      if (res.success) {
        setState((draft) => {
          draft.franchisees = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleGetStates = async () => {
    try {
      const res = await getStates();
      if (res.success) {
        setState((draft) => {
          draft.states = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleGetTerminals = async () => {
    try {
      const res = await getTerminals();
      if (res.success) {
        setState((draft) => {
          draft.terminals = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleGetKeypads = async () => {
    try {
      const res = await getKeypads();
      if (res.success) {
        setState((draft) => {
          draft.keypads = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleGetTaxgroups = async () => {
    try {
      const res = await getTaxGroups();
      if (res.success) {
        setState((draft) => {
          draft.taxgroups = res.data.map((v) => {
            return { value: v.id, label: v.name };
          });
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleGetPriceLevels = async () => {
    try {
      const res = await getPriceLevels();
      if (res.success) {
        setState((draft) => {
          draft.pricelevels = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleGetRoles = async () => {
    try {
      const res = await getRoles();
      if (res.success) {
        setState((draft) => {
          draft.roles = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleGetCustomers = async ({ search = null, page }) => {
    let params = {};
    let pagination = {
      pageIndex: page,
      pageSize: 10,
    };
    if (search) {
      params = { ...params, search };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      const res = await getCustomers(query, pagination);
      if (res.success) {
        return {
          options: res.data.customers.map((data) => {
            return {
              value: data.id,
              label: data.name,
              email: data.email,
              phone: data.phone_formatted,
            };
          }),
          hasMore: res.data.pagination.hasMorePages,
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const handleGetUsers = async ({ search = null, page, type }) => {
    let params = {};
    let pagination = {
      pageIndex: page,
      pageSize: 10,
    };
    if (search) {
      params = { ...params, search };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      const res = await getUsers(query, pagination);

      if (res.success) {
        setState((draft) => {
          draft.users = res.data.users.map((data) => {
            return { value: data.name, label: data.name };
          });
        });
        const options = res.data.users.map((data) => {
          return {
            value: data.id,
            label: data.name,
          };
        });
        return {
          options: options,
          hasMore: res.data.pagination.hasMorePages,
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (err) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const handleSuburbList = async ({ search = null, page, type }) => {
    let params = {};
    let pagination = {
      pageIndex: page,
      pageSize: 10,
    };
    if (search) {
      params = { ...params, search };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      const res = await getSuburbs(query, pagination);

      if (res.success) {
        setState((draft) => {
          draft.suburbs = res.data.suburbs.map((data) => {
            return { value: data.name, label: data.name };
          });
        });
        const options = res.data.suburbs.map((data) => {
          return {
            value: data.name,
            label: data.name,
            postcode: data.postcode,
            state: data.state,
          };
        });
        return {
          options: options,
          hasMore: res.data.pagination.hasMorePages,
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (err) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const handleGetProductsList = async ({ search = null, page, type }) => {
    let params = {};
    let pagination = {
      pageIndex: page ?? 1,
      pageSize: 10,
    };
    if (search) {
      params = { ...params, search };
    }
    if (isBundle) {
      params = { ...params, product_types: ["retail"] };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      const res = await getProductsList(query, pagination);
      if (res.success) {
        if (type === "search") {
          setState((draft) => {
            draft.product.dataList = [...res?.data?.products];
            draft.product.pagination = res.data.pagination;
          });
        } else {
          setState((draft) => {
            draft.product.dataList = [
              ...draft?.product.dataList,
              ...res?.data?.products,
            ];
            draft.product.pagination = res.data.pagination;
          });
        }
        const options =
          res.data.products.length > 0
            ? res.data.products.map((data) => {
              return {
                value: data.id,
                label: data.name,
                variants: data.variants,
              };
            })
            : [
              {
                custom: true,
              },
            ];

        return {
          options: options,
          hasMore: res.data.pagination.hasMorePages,
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (err) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const productLoadNextPage = (e) => {
    const { pageIndex } = state?.product.pagination;
    handleGetProductsList({
      page: pageIndex + 1,
      type: "",
      search: debouncedSearchText,
    });
    setState((draft) => {
      draft.product.pagination.pageIndex =
        state.product.pagination.pageIndex + 1;
    });
  };

  const HandleFilterProducts = (type, text) => {
    setState((draft) => {
      draft.product.searchText = text;
    });
  };

  useEffect(() => {
    if (isProductsList && (debouncedSearchText || debouncedSearchText === "")) {
      handleGetProductsList({
        page: 1,
        search: debouncedSearchText,
        type: "search",
      });
    }
  }, [debouncedSearchText]);

  const handleCouponList = async ({
    deletedItem,
    search,
    page,
    type,
    status,
  }) => {
    let params = {};
    let pagination = {
      pageIndex: page,
      pageSize: 10,
    };
    if (deletedItem !== null) {
      params = { ...params, deleted: deletedItem };
    }
    if (search) {
      params = { ...params, search: search };
    }
    if (status) {
      params = { ...params, status: status };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      const res = await getCoupon(query, pagination);

      if (res.success) {
        setState((draft) => {
          draft.coupons = res.data.coupons.map((data) => {
            return { value: data.id, label: data.name };
          });
        });
        const options = res.data.coupons.map((data) => {
          return {
            value: data.id,
            label: data.name,
            // postcode: data.postcode,
            // state: data.state,
          };
        });
        return {
          options: options,
          hasMore: res.data.pagination.hasMorePages,
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (err) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };
  return {
    state,
    statesList: state.states,
    suburbList: state.suburbs,
    storeList: state.stores,
    categoriesList: state.categories,
    pricelevelList: state.pricelevels,
    bundleGroupsList: state.bundleGroups,
    availabilityGroupList: state.availabilityGroups,
    modifierGroupsList: state.modifierGroups,
    coasList: state.accounts,
    taxGroupList: state.taxgroups,
    couponList: state.coupons,
    terminals: state.terminals,
    handleGetProductsList,
    productLoadNextPage,
    HandleFilterProducts,
    handleSuburbList,
    handleCouponList,
    handleGetCustomers,
    handleGetUsers,
  };
};
