import { useState, useRef } from "react";
import {
  Stack,
  Card,
  Typography,
  TextField,
  createFilterOptions,
  Chip,
  FormHelperText,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useStoreOpeningHours } from "../hooks";
import { useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { LoadingButton } from "@mui/lab";

export const AddOrEditOpeningHours = ({ details }) => {
  const { id } = useParams();
  const {
    state,
    globalData,
    onUpdateOpenigClosing,
    handleInputChange,
    onUpdateHoliday,
    onUpdateWeekdays,
    onDeleteHoliday,
    selectedHoliday,
    selectedpublicHoliday,
  } = useStoreOpeningHours({ details: details });
  const { openingClosing, holiday, week } = state;
  const [, forceUpdate] = useState();
  const validator1 = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },

      validators: {
        openTime: {
          message: "Selected time is not a valid date",
          rule: (value) => {
            return moment(value, "HH:mm:ss").isValid();
          },
        },
        closeTime: {
          message: "The close time should be greater than open time",
          rule: (value) => {
            const { openTime, closeTime } = value;
            return moment(openTime, "HH:mm:ss").isBefore(
              moment(closeTime, "HH:mm:ss")
            );
          },
        },
      },
    })
  );

  const validator2 = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },

      validators: {
        openTime: {
          message: "Selected time is not a valid date",
          rule: (value) => {
            return moment(value, "HH:mm:ss").isValid();
          },
        },
        closeTime: {
          message: "The close time should be greater than open time",
          rule: (value) => {
            const { openTime, closeTime } = value;
            return moment(openTime, "HH:mm").isBefore(
              moment(closeTime, "HH:mm")
            );
          },
        },
      },
    })
  );

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });

  const onSubmitOpeninngClosing = () => {
    if (validator1.current.allValid()) {
      onUpdateOpenigClosing(id);
    } else {
      validator1.current.showMessages();
      forceUpdate(1);
    }
  };

  const onSubmitWeekDays = () => {
    if (validator2.current.allValid()) {
      onUpdateWeekdays(id);
    } else {
      validator2.current.showMessages();
      forceUpdate(1);
    }
  };

  const onSubmitHolidays = () => {
    onUpdateHoliday(id);
  };

  const options = [
    { label: "Open", value: 1 },
    { label: "Close", value: 0 },
  ];

  return (
    <>
      <Card sx={{ p: "32px 24px", mb: "24px" }}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 3, sm: 12 }}
        >
          <Typography
            variant="subtitle1"
            fontWeight={600}
            sx={{ width: "220px" }}
          >
            Opening & Closing
          </Typography>
          <Stack gap="20px" width={"100%"}>
            <Stack gap="20px" flexDirection="row">
              <InputControl
                type="dropdown"
                options={options}
                value={
                  options.find(
                    (v) => v.value === openingClosing.data.is_open_today
                  ) ?? null
                }
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                disableClearable={true}
                filterOptions={filterOptions}
                onChange={(e, v) => {
                  handleInputChange(
                    {
                      target: {
                        name: "is_open_today",
                        value: v?.value ?? null,
                      },
                    },
                    "open"
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Open Today"
                    error={validator1.current.message(
                      "open today",
                      openingClosing.data.is_open_today,
                      "required"
                    )}
                    helperText={validator1.current.message(
                      "open today",
                      openingClosing.data.is_open_today,
                      "required"
                    )}
                  />
                )}
              />
              <InputControl
                type="dropdown"
                options={globalData.timezones ?? []}
                // value={globalData.timezones ? options.find(v => v.value === openingClosing.data.timezone) : null}
                value={
                  (globalData.timezones?.length > 0 &&
                    openingClosing.data.timezone &&
                    globalData.timezones.find(
                      (c) => c.value === openingClosing.data.timezone
                    )) ||
                  null
                }
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                disableClearable={true}
                onChange={(e, v) => {
                  handleInputChange(
                    {
                      target: {
                        name: "timezone",
                        value: v?.value ?? null,
                      },
                    },
                    "open"
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Time Zone"
                    error={validator1.current.message(
                      "Time Zone",
                      openingClosing.data.timezone,
                      "required"
                    )}
                    helperText={validator1.current.message(
                      "Time Zone",
                      openingClosing.data.timezone,
                      "required"
                    )}
                  />
                )}
              />
            </Stack>
            <Stack gap="20px" flexDirection="row">
              <InputControl
                ampm={false}
                type="timePicker"
                name="text"
                label="Opening Time Today"
                required
                value={moment(
                  openingClosing.data.opening_time,
                  "HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss")}
                onChange={(e) => {
                  handleInputChange(
                    {
                      target: {
                        name: "opening_time",
                        value: moment(e, "HH:mm:ss").format("HH:mm:ss") ?? null,
                      },
                    },
                    "open"
                  );
                }}
                error={validator1.current.message(
                  "Opening Time",
                  openingClosing.data.opening_time,
                  "required|openTime"
                )}
                helperText={validator1.current.message(
                  "Opening Time",
                  openingClosing.data.opening_time,
                  "required|openTime"
                )}
              // style={{ width: 730 }}
              />

              <InputControl
                ampm={false}
                type="timePicker"
                name="text"
                label="Closing Time Today"
                required
                value={moment(
                  openingClosing.data.closing_time,
                  "HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss")}
                onChange={(e) => {
                  handleInputChange(
                    {
                      target: {
                        name: "closing_time",
                        value: moment(e, "HH:mm:ss").format("HH:mm:ss") ?? null,
                      },
                    },
                    "open"
                  );
                }}
                error={validator1.current.message(
                  "Closing Time",
                  {
                    openTime: openingClosing.data.opening_time,
                    closeTime: openingClosing.data.closing_time,
                  },
                  "required|closeTime"
                )}
                helperText={validator1.current.message(
                  "Closing Time",
                  {
                    openTime: openingClosing.data.opening_time,
                    closeTime: openingClosing.data.closing_time,
                  },
                  "required|closeTime"
                )}
              // style={{ width: 730 }}
              />
            </Stack>
            <Stack gap="20px" flexDirection="row">
              <InputControl
                label="Online Ordering Cut off time(in Min)"
                name="cut_off_time"
                sx={{
                  width: "50%",
                }}
                type="number"
                value={openingClosing.data.cut_off_time}
                onChange={(e) => handleInputChange(e, "open")}
                required
                error={validator1.current.message(
                  "Cut off time",
                  openingClosing.data.cut_off_time,
                  "required|num"
                )}
                helperText={validator1.current.message(
                  "Cut off time",
                  openingClosing.data.cut_off_time,
                  "required|num"
                )}
              />
              <div />
            </Stack>
            <LoadingButton
              variant="contained"
              sx={{
                alignSelf: "flex-end",
              }}
              disabled={openingClosing.isSaveButton}
              loading={openingClosing.isBusy}
              onClick={onSubmitOpeninngClosing}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </Card >

      <Card sx={{ mb: "24px" }}>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "20px",
            height: "60px",
            p: "10px 24px",
            bgcolor: "#F2F2F2",
          }}
        >
          <Typography sx={{ width: "100%", fontWeight: 600 }}>
            Week Days
          </Typography>
          <Typography sx={{ width: "100%", fontWeight: 600 }}>
            Opening Hour
          </Typography>
          <Typography sx={{ width: "100%", fontWeight: 600 }}>
            Closing Hour
          </Typography>
          <Typography sx={{ width: "20%", fontWeight: 600 }}></Typography>
        </Stack>
        {week.data.map((data, index) => (
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "baseline",
              gap: "20px",
              p: "10px 24px",
              borderBottom: "1px solid #e6e6e6",
            }}
          >
            <Typography sx={{ width: "100%", textTransform: "capitalize" }}>
              {data.day_of_week}
            </Typography>

            <InputControl
              name="startAt"
              ampm={false}
              type="timePicker"
              value={moment(data.opening_time, "HH:mm:ss").format(
                "YYYY-MM-DD HH:mm:ss"
              )}
              renderInput={(params) => <TextField {...params} />}
              onChange={(e) => {
                handleInputChange(
                  {
                    target: {
                      name: "opening_time",
                      value: moment(e, "HH:mm").format("HH:mm") ?? null,
                    },
                  },
                  "week",
                  index
                );
              }}
              error={validator2.current.message(
                `Opening Time ${` `.repeat(index)}`,
                data.opening_time,
                "required|openTime"
              )}
              helperText={validator2.current.message(
                `Opening Time ${` `.repeat(index)}`,
                data.opening_time,
                "required|openTime"
              )}
            />
            <InputControl
              name="startAt"
              ampm={false}
              type="timePicker"
              value={moment(data.closing_time, "HH:mm:ss").format(
                "YYYY-MM-DD HH:mm:ss"
              )}
              renderInput={(params) => <TextField {...params} />}
              onChange={(e) => {
                handleInputChange(
                  {
                    target: {
                      name: "closing_time",
                      value: moment(e, "HH:mm").format("HH:mm") ?? null,
                    },
                  },
                  "week",
                  index
                );
              }}
              error={validator2.current.message(
                `Closing Time ${` `.repeat(index)}`,
                {
                  openTime: data.opening_time,
                  closeTime: data.closing_time,
                },
                "required|closeTime"
              )}
              helperText={validator2.current.message(
                `Closing Time ${` `.repeat(index)}`,
                {
                  openTime: data.opening_time,
                  closeTime: data.closing_time,
                },
                "required|closeTime"
              )}
            />
            <InputControl
              type="switch"
              checked={data.status ? true : false}
              onChange={(e, v) => {
                handleInputChange(
                  {
                    target: {
                      name: "status",
                      value: v ? 1 : 0,
                    },
                  },
                  "week",
                  index
                );
              }}
            />
          </Stack>
        ))}
        <Stack p="24px">
          <LoadingButton
            variant="contained"
            sx={{
              alignSelf: "flex-end",
            }}
            disabled={week.isSaveButton}
            loading={week.isBusy}
            onClick={onSubmitWeekDays}
          >
            Save
          </LoadingButton>
        </Stack>
      </Card>

      <Card sx={{ p: "32px 24px" }}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 3, sm: 12 }}
        >
          <Typography
            variant="subtitle1"
            fontWeight={600}
            sx={{ width: "220px" }}
          >
            Holidays
          </Typography>
          <Stack gap="20px" width={"100%"}>
            <Stack gap="8px">
              <InputControl
                type="datePicker"
                name="date"
                label="Holidays"
                inputFormat="dd/MM/yyyy"
                width="150px"
                value={null}
                disablePast={true}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                shouldDisableDate={(date) => {
                  return selectedHoliday.includes(
                    moment(date).format("YYYY-MM-DD")
                  );
                }}
                onChange={(e) => {
                  handleInputChange(
                    {
                      target: {
                        name: "holiday",
                        value: moment(e).format("YYYY-MM-DD") ?? null,
                      },
                    },
                    "holiday"
                  );
                }}
              />
              <Stack flexDirection="row" gap="12px" flexWrap="wrap">
                {holiday.data
                  .filter((v) => !v.is_public_holiday)
                  .map((data, index) => (
                    <Chip
                      key={index}
                      label={
                        <Typography variant="body2" fontSize="12px">
                          {data.holiday}
                        </Typography>
                      }
                      onDelete={() => {
                        onDeleteHoliday(data.holiday);
                      }}
                      sx={{
                        backgroundColor: "#F2F2F2",
                        height: "30px",
                        "& .MuiChip-deleteIcon": {
                          color: "#CCCCCC",
                        },
                      }}
                    />
                  ))}
              </Stack>
            </Stack>
            <Stack gap="8px">
              <InputControl
                type="datePicker"
                name="date"
                label="Public Holidays"
                inputFormat="dd/MM/yyyy"
                width="150px"
                value={null}
                disablePast={true}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                shouldDisableDate={(date) => {
                  return selectedpublicHoliday.includes(
                    moment(date).format("YYYY-MM-DD")
                  );
                }}
                onChange={(e) => {
                  handleInputChange(
                    {
                      target: {
                        name: "public_holiday",
                        value: moment(e).format("YYYY-MM-DD") ?? null,
                      },
                    },
                    "holiday"
                  );
                }}
              />
              <Stack flexDirection="row" gap="12px" flexWrap="wrap">
                {holiday.data
                  .filter((v) => v.is_public_holiday)
                  .map((data, index) => (
                    <Chip
                      key={index}
                      label={
                        <Typography variant="body2" fontSize="12px">
                          {data.holiday}
                        </Typography>
                      }
                      onDelete={() => {
                        onDeleteHoliday(data.holiday);
                      }}
                      sx={{
                        backgroundColor: "#F2F2F2",
                        height: "30px",
                        "& .MuiChip-deleteIcon": {
                          color: "#CCCCCC",
                        },
                      }}
                    />
                  ))}
              </Stack>
            </Stack>

            <LoadingButton
              variant="contained"
              sx={{
                alignSelf: "flex-end",
              }}
              disabled={holiday.isSaveButton}
              loading={holiday.isBusy}
              onClick={onSubmitHolidays}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </Card>
    </>
  );
};
