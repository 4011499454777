import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import { OrderDetails, OrderList } from "../Orders";
import { useOrderList } from "../Orders/hooks/useOrderList";

export const DiscountOrders = () => {
  return (
    <Routes>
      <Route path="/" element={<OrderList discountOrders={true} />} />
      <Route element={<ProtectedRoute allowedRoute={["order-view"]} />}>
        <Route
          path="/details/:id"
          element={<OrderDetails discountOrders={true} />}
        />
      </Route>
    </Routes>
  );
};
