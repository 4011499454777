import React, { useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete,
  Marker,
} from "@react-google-maps/api";
import { useImmer } from "use-immer";
import { makeStyles } from "@material-ui/styles";
import { Stack } from "@mui/system";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  searchBox: {
    boxSizing: "border-box",
    border: "1px solid transparent",
    width: "97%",
    background: "white",
    color: "black",
    height: "32px",
    marginTop: "7px",
    marginLeft: "6px",
    padding: "0 12px",
    borderRadius: "3px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
    fontSize: "14px",
    outline: "none",
    textOverflow: "ellipsis",
    zIndex: 10,
    position: "absolute",
  },
}));

const containerStyle = {
  marginTop: "20px",
  height: "400px",
  width: "400px",
};

export const GoogleMapComponent = (props) => {
  const classes = useStyles();
  const { onPlaceChange, toggleMapModal, states } = props;
  const { latitude, longitude, address } = states.data;

  const [map, setMap] = useImmer({
    autocomplete: null,
    map: null,
    latitude: "",
    longitude: "",
    address: "",
  });
  useEffect(() => {
    setMap((draft) => {
      draft.latitude = latitude === "" ? "10.1631526" : latitude;
      draft.longitude = longitude === "" ? "76.64127119999999" : longitude;
      draft.address = address;
    });
  }, [latitude, longitude]);

  const onLoad = (autocompleteVal) => {
    setMap((draft) => {
      draft.autocomplete = autocompleteVal;
    });
  };

  const onLoadMap = React.useCallback((map) => {
    setMap((draft) => {
      draft.map = map;
    });
  }, []);

  const onSaveChanges = () => {
    onPlaceChange(map.latitude, map.longitude, map.address);
    toggleMapModal();
  };

  const onMarkerDragEnd = (e) => {
    const latitude = e.latLng.lat();
    const longitude = e.latLng.lng();
    setMap((draft) => {
      draft.latitude = latitude;
      draft.longitude = longitude;
    });
  };

  const onPlaceChanged = (e) => {
    if (map.autocomplete !== null) {
      if (map.autocomplete.getPlace().geometry?.location) {
        const { lat, lng } = map.autocomplete.getPlace().geometry.location;
        const address = map.autocomplete.getPlace().formatted_address;

        // const addressComponets = map.autocomplete.getPlace().address_components;

        // const city = addressComponets.find((component) =>
        //   component.types.includes("locality")
        // )?.long_name;
        // const state = addressComponets.find((component) =>
        //   component.types.includes("administrative_area_level_1")
        // ).long_name;

        setMap((draft) => {
          draft.latitude = lat();
          draft.longitude = lng();
          draft.address = address;
        });
      }
    } else {
    }
  };
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ["places"],
    id: "searchbox-example",
  });

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    <>
      <GoogleMap
        sx={{ position: "relative" }}
        mapContainerStyle={containerStyle}
        zoom={10}
        center={{
          lat: Number(map.latitude),
          lng: Number(map.longitude),
        }}
        onLoad={onLoadMap}
        options={{
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          draggable: true,
        }}
      >
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <input
            type="text"
            placeholder="Enter Location"
            className={classes.searchBox}
          />
        </Autocomplete>
        <Marker
          position={{
            lat: Number(map.latitude),
            lng: Number(map.longitude),
          }}
          draggable
          onDragEnd={(e) => onMarkerDragEnd(e)}
        />
      </GoogleMap>
      <Stack direction="row" gap="12px" justifyContent="flex-end" marginTop={3}>
        <Button onClick={() => toggleMapModal()}>Cancel</Button>
        <Button
          onClick={() => onSaveChanges()}
          variant="contained"
          color="primary"
        >
          Save Changes
        </Button>
      </Stack>
    </>
  ) : (
    <div>Loading...</div>
  );
};
