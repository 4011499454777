import {
    ContentLayout,
    CustomTable,
    HeaderBreadcrumbs,
    Loader,
    usePermission,
} from "../../../shared";
import React from "react";
import {
    Button,
    createFilterOptions,
    TextField,
    Checkbox,
    Stack,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { sortItems } from "../../../utils";

import { useDepositSlip } from "../hooks";
import { useStore } from "../../Stores";

export const DepositSlipList = () => {

    const {
        state,
        handleFilter,
        handleFilterClear,
        handleApplyFilter,
        handleSearch,
        onSeachClear,
    } = useDepositSlip({ isList: true })
    const { isLoading, dataList, filterApplied } = state

    const { states } = useStore({ isStore: true });
    const storeOptions = states.store.map((v) => {
        return { value: v.id, label: v.name };
    });
    const { pathname } = useLocation();
    const isStoreAdmin = pathname.includes("store-admin");
    const navigate = useNavigate();
    const IsCreate = usePermission("deposit-slip-create");

    const filterOptions = createFilterOptions({
        matchFrom: "any",
        stringify: (option) => option.label,
    });

    const ActionButtons = ({ row, deleteAction }) => {
        return (
            <div className="action-buttons">
                <Link to={`details/${row.id}`}>
                    <img alt="details" src="/icons/arrow_forward.svg" />
                </Link>
            </div>
        );
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Reference ID",
                accessor: "reference_id",
            },
            {
                Header: "Date",
                accessor: "date",
            },
            {
                Header: "Store",
                accessor: "store_name",
                sortType: (prev, curr, columnId) => {
                    return sortItems(prev, curr, columnId);
                },
            },
            {
                Header: "Total Amount",
                accessor: "total_amount",
            },
            {
                Header: "Staff Name",
                accessor: "user_name",
            },
            {
                Header: "Action",
                id: "action",
                align: "right",
                disableSortBy: true,
                accessor: (row, index) => {
                    return <ActionButtons row={row}
                        deleteAction={() => {
                            // handleModal('bundle-delete', true, row.id) 
                        }} />;
                },
            },
        ],
        []
    );



    const dataColumns = isStoreAdmin ? columns.filter(v => v.Header !== "Store") : columns

    return (
        <>
            <ContentLayout
                title="Deposit Slip History"
                rightContent={
                    <>
                        {IsCreate && (
                            <Button
                                type="button"
                                size="medium"
                                variant="contained"
                                onClick={() => navigate("create")}
                            >
                                New Deposit Slip
                            </Button>
                        )}
                    </>
                }
                breadcrumbs={
                    <HeaderBreadcrumbs
                        links={[
                            { name: "Dashboard", href: isStoreAdmin ? "/store-admin" : "/" },
                            { name: "End Of Day", href: isStoreAdmin ? "/store-admin/end-of-day" : "/end-of-day" },
                            { name: "Deposit Slip History" },
                        ]}
                    />
                }

            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <CustomTable
                        columns={dataColumns}
                        data={dataList}
                        defaultSearch={false}
                        filterApplied={filterApplied.all}
                        onFilterClear={(e) => handleFilterClear("single", e)}
                        onAllFilterClear={(e) => handleFilterClear("all")}
                        filterContent={
                            <InputControl
                                type="table-search"
                                value={filterApplied.searchText}
                                onChange={(e) => handleSearch(e.target.value)}
                                onClear={onSeachClear}
                            />
                        }
                        filterSection={
                            <>
                                <Stack direction="row" gap="20px">
                                    {!isStoreAdmin && (
                                        <InputControl
                                            type="dropdown"
                                            name="store"
                                            label="Store"
                                            multiple={true}
                                            options={storeOptions}
                                            value={filterApplied.stores}
                                            disableCloseOnSelect
                                            disableClearable={true}
                                            onChange={(e, v) => handleFilter(e, v, "store")}
                                            getOptionLabel={(option) => option.label}
                                            isOptionEqualToValue={(option, value) =>
                                                option.value === value.value
                                            }
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => <></>)
                                            }
                                            renderOption={(props, option, { selected }) => (
                                                <>
                                                    <li {...props} key={option.value}>
                                                        <Checkbox
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.label}
                                                    </li>
                                                </>
                                            )}
                                            filterOptions={filterOptions}
                                            sx={{ width: 250 }}
                                            componentsProps={{
                                                paper: {
                                                    sx: {
                                                        width: "fit-content",
                                                        minWidth: 250,
                                                    },
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Store"
                                                    placeholder="Store"
                                                />
                                            )}
                                        />
                                    )}
                                    <InputControl
                                        type="datePicker"
                                        name="date"
                                        label="Date"
                                        inputFormat="dd/MM/yyyy"
                                        disableFuture
                                        width="150px"
                                        value={filterApplied.date?.value || null}
                                        onChange={(e, v) => handleFilter(e, v, "date")}
                                    />
                                </Stack>
                                <Button
                                    type="button"
                                    size="medium"
                                    variant="text"
                                    color="info"
                                    sx={{
                                        minWidth: "40px",
                                        p: "8px",
                                    }}
                                    onClick={() => handleApplyFilter()}
                                >
                                    Apply
                                </Button>
                            </>
                        }
                        onRowLink={(e) => {
                            return `details/${e.id}`
                        }}
                    />
                )}
            </ContentLayout>
        </>
    );
};
