import React from "react";
import { Box, Button, Divider, FormHelperText, Stack, Typography } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { Loader, usePermission } from "../../../shared";

export const VerifyDialog = (props) => {
  const { data, handleOtpInput, handleVerifyEmailOrPhone, state, handleForceVerify } = props;
  const isClickabe = usePermission("customer-force-verify");
  const { isVerify, otpData, isError } = data
  return (
    <>
      {isVerify === 1 ? (
        <Stack mt="32px" justifyContent="space-between" height="100%">
          <Stack gap="20px">
            <Typography color="#636363">{otpData.type === "email" ? `Verification email has been sent to ${state.email}` : `Verification sms has been sent to ${state.phone_formatted}`}.</Typography>
            <Stack gap="6px">
              <InputControl
                name="otp"
                label="Enter OTP"
                type="number"
                value={otpData.otp}
                onChange={handleOtpInput}
              />
              {isError && (
                <FormHelperText sx={{ color: "#C60808" }}>
                  {isError}
                </FormHelperText>
              )}
            </Stack>
          </Stack>
          <Button
            variant="contained"
            sx={{
              width: "100%"
            }}
            disabled={!otpData.otp}
            onClick={handleVerifyEmailOrPhone}
          >
            Verify
          </Button>
          <Stack sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "20px"
          }}>
            <Box sx={{
              height: "1px",

              background: "#D9D9D9",
              width: "100%"
            }}
            />
            <Typography color="#636363">OR</Typography>
            <Box sx={{
              height: "1px",
              background: "#D9D9D9",
              width: "100%"
            }}
            />
          </Stack>
          <Button
            variant="text"
            sx={{
              width: "100%"
            }}
            disabled={!isClickabe}
            onClick={handleForceVerify}
          >
            Verify without OTP
          </Button>
        </Stack>
      ) : isVerify === 2 ? (
        <Stack height="100%"
          justifyContent="center"
          alignItems="center"
          gap="10px"
        >
          <Loader />
          <Typography
            variant="subtitle2"
            color="#636363"
            fontWeight={400}
          >
            Verifying OTP...
          </Typography>
        </Stack>
      ) : (
        <Stack
          height="100%"
          justifyContent="center"
          alignItems="center"
          gap="4px"
        >
          <img alt="success" src="/icons/ic_tick_filled.svg" />
          <Typography
            variant="subtitle2"
            color="#636363"
            fontWeight={400}
          >
            {otpData.type === "email" ? "Email" : "Number"} Verified
          </Typography>
        </Stack>
      )}
    </>
  );
};
