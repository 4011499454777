import { API } from "../../../utils";

export const getEmailSettingsList = async () => {
    const res = await API.get(`email-settings`);
    return res.data;
};


export const getEmailSettingsDetails = async (id) => {
    const res = await API.get(`email-settings/${id}`);
    return res.data;
};

export const updateEmailSettings = async (id, payload) => {
    const res = await API.put(`email-settings/${id}`, payload);
    return res.data;
};

export const getAllUsers = async (query) => {
    const res = await API.get(`gift_card_users`, query);
    return res.data;
};