import React, { useState } from "react";
import {
  Button,
  Stack,
  Card,
  Typography,
  Divider,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import {
  CustomTable,
  CheckBoxListDialog,
  CustomeDialog,
  DeleteModal,
} from "../../../shared";
import { arraySort, sortItems } from "../../../utils";

export const CouponsStore = (prop) => {
  const navigate = useNavigate();
  const {
    HandleFilterStores,
    handleStoreSelect,
    handleAssignStoretoCoupons,
    handleModal,
    state,
    DeleteStoreModal,
    onSelectItems,
    setCurentTab,
    handleDeleteStores,
    handleAllSelect,
    onChangeTitle,
  } = prop;
  const ActionButtons = ({ id, deleteAction }) => {
    return (
      <div className="action-buttons"
        onClick={(e) => e.preventDefault()}
      >
        <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
      </div>
    );
  };
  const ActionButtons2 = ({ id, label }) => {
    return (
      <Stack direction="row" gap="12px" alignItems="center">
        <div
          className="table-taxt-and-icon-link cursor"
          onClick={() => {
            setCurentTab("storeDetails", "1");
            navigate(`/stores/details/${id}`);
          }}
        >
          <span className="link-text">{label}</span>
          <div className="link-icon-wrap">
            <img alt="link" src="/icons/ic_external_link.svg" />
          </div>
        </div>
      </Stack>
    );
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Store Name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
        accessor: "name",
      },
      {
        Header: "Action",
        id: "action",
        accessor: "id",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => {
                DeleteStoreModal(true, row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Card>
        {/* <Stack
          flex="content"
          justifyContent="left"
          direction="row"
          spacing={20}
          sx={{ height: "70px", width: "953px" }}
          marginTop={4}
        >
          <Typography
            gutterBottom
            align="center"
            variant="subtitle1"
            marginLeft={3}
          >
            Title
          </Typography>
          <Divider />
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              gap="32px"
            >
              <FormControlLabel
                checked={state.data.location_type === 2}
                name="network"
                control={<Radio />}
                label="Network"
                onClick={() => onChangeTitle(2, "Network")}
              />
              <FormControlLabel
                checked={state.data.location_type === 1}
                name="lam"
                control={<Radio />}
                label="LAM"
                onClick={() => onChangeTitle(1, "LAM")}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Divider /> */}
        {/* {state.data.location_type === 1 && ( */}
        <>
          <CustomTable
            columns={columns}
            data={arraySort(state.storeDetails.storeList || [], "name")}
            searchBar={false}
            completeFeature={false}
            tableTitle="Stores"
            rowsSelection={false}
            headerRightContent={
              <>
                <Button
                  type="button"
                  size="medium"
                  variant="text"
                  className="delte"
                  startIcon={<AddIcon />}
                  onClick={() => handleModal(true)}
                  sx={{
                    minWidth: "40px",
                    p: "8px",
                    border: "1px solid rgba(21, 96, 212, 0.12)",
                  }}
                >
                  Add Store
                </Button>
              </>
            }
            onRowLink={(e) => {
              return `/stores/details/${e.id}`
            }}
            onRowAction={() => {
              setCurentTab("storeDetails", "1");
            }}
          />
          <CustomeDialog
            open={state.storeDetails.isOpen}
            handleClose={() => handleModal(false)}
            PaperProps={{
              sx: { width: "423px", height: "535px", p: "16px" },
            }}
            title="Stores"
            content={
              <CheckBoxListDialog
                Items={state.storeDetails.filteredStores}
                onSearch={HandleFilterStores}
                onSelect={handleStoreSelect}
                onSelectItems={onSelectItems}
                onSubmit={handleAssignStoretoCoupons}
                disabled={!state.storeDetails.selectedStores.length || state.storeDetails.isSaveButton}
                isSelected={(id) =>
                  state.storeDetails.selectedStores.includes(id)
                }
                hasSelectAll={true}
                isSelectAllChecked={state.storeDetails.filteredStores.every(
                  (item) => state.storeDetails.selectedStores.includes(item.id)
                )}
                onAllSelect={handleAllSelect}
              />
            }
          />
        </>
        {/* )} */}
        {/* {state.data.location_type === 2 && (
          <Stack
            height={83}
            width={952}
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              ml: 3,
            }}
          >
            <Stack direction="row" spacing={2}>
              <img src="/icons/ic_not.svg" />

              <Typography color={"#C07302"}>
                Coupons can be restricted to specific stores. Applicable in all
                stores by default
              </Typography>
            </Stack>
          </Stack>
        )} */}
      </Card>
      <DeleteModal
        open={state.storeDetails.isDelete}
        handleClose={() => DeleteStoreModal(false, null)}
        onConfirm={handleDeleteStores}
      />
    </>
  );
};
