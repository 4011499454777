import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useDropdownApis, usePermission } from "../../../shared";
import moment from "moment";
import {
  createPriceLevel,
  getPriceLevelDetails,
  editPriceLevel,
  deletePriceLevelById,
  getPriceLevelProductDetails,
  createorupdateProductPriceLevel,
  getPriceLevelProductList,
  getPriceLevelById,
  deleteProductPriceLevel,
} from "../api/priceLevelApi";
import { useTabs } from "../../../shared/hooks/useTabs";
import { getPriceLevel } from "../api/priceLevelApi";
import { useProductsList } from "../../Products";

export const usePriceLevel = ({
  isPriceLevel,
  priceLevelId,
  priceLevelProductId,
  priceLevelProductIds,
  PId,
  isProduct,
}) => {
  const navigate = useNavigate();
  const { resetCurrentTabs } = useTabs();
  const { state: productState } = useProductsList({ isProduct });
  const {
    state: {
      product: { dataList },
    },
    handleGetProductsList,
  } = useDropdownApis({});

  const [state, setState] = useImmer({
    isBusy: false,
    pricelevel: [],
    open: false,
    isOpen: false,
    id: null,
    initialLoad: true,
    isSaveButtonBusy: false,
    product_id: "",
    display_name: "",
    httpStatusCodes: {
      details: "",
    },
    priceLevelName: "",
    filters: {
      isSearching: false,
      searchText: "",
      locked: "",
    },
    productData: [],
    pricelevelProductData: {
      product_id: "",
      product_name: "",
      status: 1,
      price: "",
      old_price: "",
      cost: "",
      cost_exclusive_gst: "",
      special_price: "",
      special_price_start_date: null,
      special_price_end_date: null,
      variants: [],
    },
    data: {
      id: "",
      name: "",
      alias: "",
      created_at: "",
      updated_at: "",
    },
  });
  const isEditable = usePermission("price-level-modify");
  const isDeletable = usePermission("price-level-delete");
  const isCreateVisible = usePermission("price-level-create");
  const isVisible = usePermission("price-level-view");
  useEffect(() => {
    if (isPriceLevel) {
      getPriceLevels();
    }
  }, [isPriceLevel]);

  const getPriceLevels = async (search) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });
      const res = await getPriceLevel(search);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.pricelevel = res.data;
          draft.initialLoad = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
      });
    }
  };

  const getPriceLevelDetail = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getPriceLevelDetails(priceLevelId);
      if (res.data) {
        setState((draft) => {
          draft.data = res.data;
          draft.name = res.data.name;
          draft.isBusy = false;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.httpStatusCodes.details = err.response.status;
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (priceLevelId) {
      getPriceLevelDetail();
    }
  }, [priceLevelId]);

  const onUpdatePriceLevel = async (priceLevelId) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await editPriceLevel(priceLevelId, state.data);
      if (res.success) {
        successMessage("Updated Successfully");
        navigate("/price-levels");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onSavePriceLevel = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });

      const res = await createPriceLevel(state.data);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate("/price-levels");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const onChange = (e, type) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };

  const handleModal = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isOpen = !draft.isOpen;
    });
  };
  const handleDeletePriceLevel = async () => {
    try {
      const res = await deletePriceLevelById(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");

        setState((draft) => {
          draft.pricelevel = draft.pricelevel.filter(
            (item) => item.id !== state.id
          );
        });
        handleModal();
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const getPriceLevelProductDetail = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getPriceLevelProductDetails(priceLevelProductId);
      if (res.data) {
        setState((draft) => {
          draft.productData = res.data;
          draft.product_name = res.data.product_name;
          draft.isBusy = false;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.httpStatusCodes.details = err.response.status;
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (priceLevelProductId) {
      getPriceLevelProductDetail();
    }
  }, [priceLevelProductId]);

  const handleProductModal = (e, product_id) => {
    if ((product_id, e === "key")) {
      setState((draft) => {
        draft.isOpen = e;
        draft.product_id = product_id;
        return draft;
      });
    } else {
      setState((draft) => {
        draft.isOpen = e;
        draft.product_id = "";
        draft.pricelevelProductData = "";
        return draft;
      });
    }
  };
  useEffect(() => {
    if (state.product_id) {
      setState((draft) => {
        draft.pricelevelProductData = state.productData.find(
          (item) => item.product_id === state.product_id
        );
        return draft;
      });
    }
  }, [state.product_id]);

  const handleOnChange = (e, type) => {
    const { value, name } = e.target;
    const filteredPoductListList = dataList.find(
      (i) => i.id === state?.pricelevelProductData?.product_id
    );
    const tax = filteredPoductListList?.tax_rate;
    const taxRate = (Number(value) * tax) / 100;
    if (name === "cost_exclusive_gst") {
      setState((draft) => {
        draft.pricelevelProductData.cost_exclusive_gst = value;
        draft.pricelevelProductData.cost = !isNaN(
          (Number(value) + taxRate)?.toFixed(3)
        )
          ? (Number(value) + taxRate)?.toFixed(3)
          : "";
      });
    } else if (name === "cost") {
      setState((draft) => {
        draft.pricelevelProductData.cost = value;
        draft.pricelevelProductData.cost_exclusive_gst = !isNaN(
          (Number(value) - taxRate)?.toFixed(3)
        )
          ? (Number(value) - taxRate)?.toFixed(3)
          : "";
      });
    } else {
      setState((draft) => {
        draft.pricelevelProductData[name] = value;
      });
    }
  };
  const handleChange = (actionType, e, index) => {
    const { value, name } = e.target;
    const filteredPoductListList = dataList.find(
      (i) => i.id === state?.pricelevelProductData?.product_id
    );
    const tax = filteredPoductListList?.tax_rate;
    const taxRate = (Number(value) * tax) / 100;
    setState((draft) => {
      draft.pricelevelProductData.variants = [
        ...draft.pricelevelProductData.variants.map((data, i) => {
          if (name === "cost_adjustment_exclusive_gst") {
            return i === index
              ? {
                  ...data,
                  [name]: value,
                  type: 1,
                  cost_adjustment: value
                    ? !isNaN((Number(value) + taxRate)?.toFixed(3))
                      ? (Number(value) + taxRate)?.toFixed(3)
                      : ""
                    : "",
                }
              : data;
          } else if (name === "cost_adjustment") {
            return i === index
              ? {
                  ...data,
                  [name]: value,
                  type: 1,
                  cost_adjustment_exclusive_gst: value
                    ? !isNaN((Number(value) - taxRate)?.toFixed(3))
                      ? (Number(value) - taxRate)?.toFixed(3)
                      : ""
                    : "",
                }
              : data;
          } else {
            return i === index ? { ...data, [name]: value, type: 1 } : data;
          }
        }),
      ];
    });
  };

  const handleDropdown = (e, newValue) => {
    setState((draft) => {
      draft.pricelevelProductData.product_id = newValue?.value;
      draft.pricelevelProductData.variants = [
        ...newValue?.variants?.map((datas, i) => {
          return {
            ...datas,
            id: datas?.id,
            name: datas?.name,
            cost_adjustment: "",
            price_adjustment: "",
            cost_adjustment_exclusive_gst: "",
          };
        }),
      ];

      draft.pricelevelProductData.cost_exclusive_gst = "";
    });
  };
  const onStartDateChange = (value) => {
    setState((draft) => {
      draft.pricelevelProductData.special_price_start_date = value || null;
    });
  };
  const onEndDateChange = (value) => {
    setState((draft) => {
      draft.pricelevelProductData.special_price_end_date = value || null;
    });
  };
  const onSaveProductPriceLevel = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const data = {
        ...state.pricelevelProductData,
        special_price: state.pricelevelProductData.special_price
          ? state.pricelevelProductData.special_price
          : null,
        special_price_start_date: state.pricelevelProductData.special_price
          ? state.pricelevelProductData.special_price_start_date
            ? moment(state.pricelevelProductData.special_price_start_date)
                .startOf("day")
                .format("YYYY-MM-DD HH:mm:ss")
            : null
          : "",
        special_price_end_date: state.pricelevelProductData.special_price
          ? state.pricelevelProductData.special_price_end_date
            ? moment(state.pricelevelProductData.special_price_end_date)
                .endOf("day")
                .format("YYYY-MM-DD HH:mm:ss")
            : null
          : "",
      };
      const res = await createorupdateProductPriceLevel(
        priceLevelProductIds,
        data
      );
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate(`/price-levels/${priceLevelProductIds}`);
      }
      handleProductModal(false);
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const handleDeleteModal = (id = null) => {
    setState((draft) => {
      draft.pricelevelProductData.product_id = id;
      draft.open = !draft.open;
      draft.pricelevelProductData.status = 0;
    });
  };
  const handleDeleteProductFromPriceLevel = async () => {
    try {
      const res = await deleteProductPriceLevel(
        priceLevelProductId,
        state.pricelevelProductData.product_id
      );
      if (res.success) {
        successMessage("Deleted Successfully");

        setState((draft) => {
          draft.pricelevel = draft.pricelevel.filter(
            (item) => item.id !== state.id
          );
        });
        handleDeleteModal(false);
        getPriceLevelProductDetail("initial");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const getPriceLevelProductLists = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getPriceLevelProductList(priceLevelProductIds, PId);
      if (res?.data) {
        const data = res.data[0];
        setState((draft) => {
          draft.pricelevelProductData = {
            ...data,
            special_price_end_date: data.special_price_end_date
              ? moment(data.special_price_end_date)
                  .utc()
                  .endOf("day")
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            special_price_start_date: data.special_price_start_date
              ? moment(data.special_price_start_date)
                  .utc()
                  .startOf("day")
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            product_id: data.product_id,
            status: 1,
          };
          draft.isBusy = false;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.httpStatusCodes.details = err.response.status;
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (priceLevelProductIds && PId) {
      getPriceLevelProductLists();
    }
  }, [priceLevelProductIds, PId]);

  const onUpdateProductPriceLevel = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const data = {
        ...state.pricelevelProductData,
        special_price: state.pricelevelProductData.special_price
          ? state.pricelevelProductData.special_price
          : null,
        special_price_start_date: state.pricelevelProductData.special_price
          ? state.pricelevelProductData.special_price_start_date
            ? moment(state.pricelevelProductData.special_price_start_date)
                .startOf("day")
                .format("YYYY-MM-DD HH:mm:ss")
            : null
          : "",
        special_price_end_date: state.pricelevelProductData.special_price
          ? state.pricelevelProductData.special_price_end_date
            ? moment(state.pricelevelProductData.special_price_end_date)
                .endOf("day")
                .format("YYYY-MM-DD HH:mm:ss")
            : null
          : "",
      };
      const res = await createorupdateProductPriceLevel(id, data);
      if (res.success) {
        successMessage("Updated Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate(`/price-levels/${priceLevelProductIds}`);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const getPriceLevelByIds = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getPriceLevelById(priceLevelProductId);
      if (res?.data) {
        setState((draft) => {
          draft.priceLevelName = res.data.name;
          draft.isBusy = false;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.httpStatusCodes.details = err.response.status;
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (priceLevelProductId) {
      getPriceLevelByIds();
    }
  }, [priceLevelProductId]);
  const getPriceLevelAddByIds = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getPriceLevelById(priceLevelProductIds);
      if (res?.data) {
        setState((draft) => {
          draft.priceLevelName = res.data.name;
          draft.isBusy = false;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.httpStatusCodes.details = err.response.status;
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (priceLevelProductIds) {
      getPriceLevelAddByIds();
    }
  }, [priceLevelProductIds]);

  return {
    state,
    onSavePriceLevel,
    onChange,
    onUpdatePriceLevel,
    handleModal,
    handleDeletePriceLevel,
    handleProductModal,
    handleOnChange,
    onStartDateChange,
    onEndDateChange,
    onSaveProductPriceLevel,
    handleDeleteModal,
    handleDeleteProductFromPriceLevel,
    handleDropdown,
    handleChange,
    onUpdateProductPriceLevel,
    isVisible,
    isCreateVisible,
    isEditable,
    isDeletable,
    resetCurrentTabs,
    productState,
    handleGetProductsList,
  };
};
