import React from "react";
import {
  Stack,
  Card,
  Typography,
  Divider,
  Button,
  TextField,
  Box,
  IconButton,
  InputAdornment,
  FormControl,
  OutlinedInput,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import InputControl from "../../../shared/components/InputControl";
import { Loader } from "../../../shared";
import SimpleReactValidator from "simple-react-validator";

export const ProductBarcode = (props) => {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const {
    state,
    onAddBarcode,
    handleDelete,
    globalData,
    handleOnChange,
    onChange,
    onSaveBarcode,
    onUpdateBarcode,
    onDeleteIndex,
    onEditBarcode,
  } = props;
  const { details, isDisable } = state.barcode;
  const onSubmit = (index, data) => {
    if (!validator.current.errorMessages[`Barcode${` `.repeat(index)}`] && !validator.current.errorMessages[`Type${` `.repeat(index)}`]) {
      {
        !data.id ? onSaveBarcode(index) : onUpdateBarcode(index, data);
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const onDelete = (index, data) => {
    !data.id ? onDeleteIndex(index) : handleDelete(index);
  };
  return (
    <>
      {state.isBusy ? (
        <Loader />
      ) : (
        <Card sx={{ mb: "24px" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height="83px"
            p="32px 24px"
          >
            <Typography
              variant="subtitle1"
              fontWeight={600}
              lineHeight="19px"
              minWidth="160px"
            >
              Barcode
            </Typography>
          </Stack>

          <Divider />
          <Stack sx={{ p: "32px 24px 24px", gap: "24px" }}>
            {details?.map((data, index) => (
              <>
                <Stack direction="row" alignItems="center" gap="12px">
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel id="demo-simple-select-helper-label">
                      Scan or Enter Barcode
                    </InputLabel>
                    <OutlinedInput
                      required
                      name="barcode"
                      value={data.barcode}
                      onChange={(e) => onChange(e, index)}
                      id="demo-simple-select-helper-label"
                      disabled={data.id ? !isDisable.includes(index) : false}
                      error={validator.current.message(
                        `Barcode${` `.repeat(index)}`,
                        data.barcode,
                        "required|alpha_num"
                      )}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <img src="/icons/barcode.svg" />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Scan or Enter Barcode"
                    />
                    <FormHelperText sx={{ color: "#C60808" }}>
                      {validator.current.message(
                        `Barcode${` `.repeat(index)}`,
                        data.barcode,
                        "required|alpha_num"
                      )}
                    </FormHelperText>
                  </FormControl>

                  <InputControl
                    type="dropdown"
                    disableClearable
                    options={globalData.product?.barcode_type ?? []}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, newValue) => {
                      handleOnChange(newValue, index, "type");
                    }}
                    disabled={data.id ? !isDisable.includes(index) : false}
                    value={
                      (globalData?.product?.barcode_type?.length > 0 &&
                        data?.type &&
                        globalData?.product?.barcode_type?.find(
                          (c) => c.value === data?.type
                        )) ||
                      null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type"
                        required
                        error={validator.current.message(
                          `Type${` `.repeat(index)}`,
                          data.type,
                          "required"
                        )}
                        helperText={validator.current.message(
                          `Type${` `.repeat(index)}`,
                          data.type,
                          "required"
                        )}
                      />
                    )}
                  />

                  <Button
                    variant="text"
                    onClick={() =>
                      data.id && !isDisable.includes(index)
                        ? onEditBarcode(index, data)
                        : onSubmit(index, data)
                    }
                  >
                    {data.id && !isDisable.includes(index)
                      ? "Edit"
                      : data.id
                        ? "Update"
                        : "Save"}
                  </Button>

                  <div className="action-buttons">
                    <img
                      alt="delete"
                      onClick={() => {
                        onDelete(index, data);
                      }}
                      src="/icons/ic_delete.svg"
                    />
                  </div>
                </Stack>

                <Box marginTop={1}>
                  <Divider />
                </Box>
              </>
            ))}
            <Box marginTop={1}>
              <Stack>
                <Stack direction="row" width="100%">
                  <Button
                    variant="contained"
                    onClick={() => onAddBarcode()}
                    disabled={details.map((i) => i.id).includes("")}
                  >
                    Add Barcode
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <Divider />
        </Card>
      )}
    </>
  );
};
