import * as React from "react";
import {
  CustomeDialog,
  ResetPassCode,
  usePermission,
  ResetPasswordDialog,
} from "../../../shared";
import { Button, Stack, Card, Typography, Grid, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export const SecurityList = (props) => {
  const hasResetPassword = usePermission("user-reset-password");

  const {
    resetPassword,
    basicDetails,
    handleResetPassword,
    handleModal,
    handlePasswordCopy,
    resetPassCode,
    resetPasscodeData,
  } = props;
  const { isOpen, isButtonBusy, passcode } = resetPasscodeData;

  return (
    <>
      <Card sx={{ p: "32px 24px", mb: "24px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="subtitle1"
              fontWeight={600}
              sx={{ width: "150px" }}
            >
              Reset Password
            </Typography>
          </Grid>
          <Grid item sm={8}>
            <Stack alignItems="flex-end">
              <Button
                type="button"
                size="medium"
                variant="text"
                sx={{
                  width: "187px",
                }}
                onClick={() => handleModal("reset-pass", true, 0)}
                disabled={!hasResetPassword}
              >
                Reset Password
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Card>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 1,
        }}
      >
        <Card sx={{ p: "32px 24px", alignContent: "center" }}>
          <Stack direction="row">
            <Stack width="100%">
              <Typography variant="subtitle1" fontWeight={600}>
                Pincode
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                fontWeight={600}
                fontSize="24px"
                lineHeight="29px"
                color="#808080"
                sx={{ marginRight: "41px" }}
              >
                {passcode !== "" ? passcode : basicDetails.details?.pass_code}
              </Typography>
              <LoadingButton
                type="button"
                sx={{ width: 140, height: 35 }}
                variant="text"
                loading={isButtonBusy}
                onClick={() => resetPassCode("open")}
              >
                Reset Pincode
              </LoadingButton>
            </Stack>
          </Stack>
        </Card>
      </Box>
      <CustomeDialog
        open={resetPassword.isOpen}
        handleClose={() => handleModal("reset-pass", false, 1)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        size="md"
        PaperProps={{
          sx: {
            width: "423px",
            height: resetPassword.slide ? "196px" : "o",
            minHeight: resetPassword.slide ? "o" : "290px",
            maxHeight: resetPassword.slide ? "o" : "332px",

            p: "24px",
          },
        }}
        title="Reset Password"
        content={
          <ResetPasswordDialog
            data={resetPassword}
            email={basicDetails.details?.email}
            handleResetPassword={handleResetPassword}
            handlePasswordCopy={handlePasswordCopy}
          />
        }
      />
      <CustomeDialog
        open={isOpen}
        handleClose={() => resetPassCode("close")}
        size="md"
        PaperProps={{
          sx: {
            width: "423px",
            minHeight: "361px",
          },
        }}
        content={
          <ResetPassCode
            email={basicDetails.details?.email}
            passCode={resetPasscodeData.passcode}
          />
        }
      />
    </>
  );
};
