import * as React from "react";
import { Button, Box, Card, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomeDialog, ResetPassCode } from "../../../shared";
import { changePasswoCode } from "../api/profileApi";
import { AuthContext } from "../../../store/AuthProvider";
import { errorMessage } from "../../../utils";
import { useImmer } from "use-immer";
import { LoadingButton } from "@mui/lab";

export const Security = ({ state: profileData }) => {
  const { pathname } = useLocation();
  const { auth } = React.useContext(AuthContext);
  const { selectedStoreId, id, email } = auth.data;
  const isStoreAdmin = pathname.includes("store-admin");
  const [state, setState] = useImmer({
    isOpen: false,
    isBusy: false,
    passCode: "",
  });

  const HandleChange = () => {
    navigate("/authentication/changePassword", {
      state: { from: isStoreAdmin ? "/store-admin/profile" : "/profile" },
    });
  };
  const navigate = useNavigate();
  const resetPassCode = async () => {
    setState((draft) => {
      draft.isBusy = true;
    });
    try {
      const payload = {
        store_id: selectedStoreId,
      };
      const res = await changePasswoCode(payload, id);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.isOpen = true;
          draft.passCode = res.data.pass_code;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
      errorMessage(err.response.data.message);
    }
  };
  const closeResetPassword = () => {
    setState((draft) => {
      draft.isOpen = false;
    });
  };
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 1,
        }}
      >
        <Card sx={{ p: "32px 24px" }}>
          <Stack direction="row">
            <Stack width="100%">
              <Typography variant="subtitle1" fontWeight={600}>
                Change Password
              </Typography>
            </Stack>

            <Stack width="100%" alignItems="flex-end">
              <Button
                type="button"
                sx={{ width: 187, height: 41 }}
                variant="text"
                onClick={HandleChange}
              >
                Change password
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Box>
      {isStoreAdmin && (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 1,
          }}
        >
          <Card sx={{ p: "32px 24px", alignContent: "center" }}>
            <Stack direction="row">
              <Stack width="100%">
                <Typography variant="subtitle1" fontWeight={600}>
                  Pincode
                </Typography>
              </Stack>

              <Stack
                direction={"row"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  fontWeight={600}
                  fontSize="24px"
                  lineHeight="29px"
                  color="#808080"
                  sx={{ marginRight: "41px" }}
                >
                  {state.passCode !== ""
                    ? state.passCode
                    : profileData.profileDetails.data.pass_code}
                </Typography>
                <LoadingButton
                  type="button"
                  sx={{ width: 140, height: 35 }}
                  variant="text"
                  loading={state.isBusy}
                  onClick={resetPassCode}
                >
                  Reset Pincode
                </LoadingButton>
              </Stack>
            </Stack>
          </Card>
        </Box>
      )}
      <CustomeDialog
        open={state.isOpen}
        handleClose={closeResetPassword}
        size="md"
        PaperProps={{
          sx: {
            width: "423px",
            minHeight: "361px",
          },
        }}
        content={<ResetPassCode email={email} passCode={state.passCode} />}
      />
    </>
  );
};
