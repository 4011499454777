import * as React from "react";
import {
  CategoryProductDialog,
  CustomeDialog,
  DeleteModal,
  DraggableReactTable,
  Loader,
} from "../../../shared";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import { useFavourites } from "../hooks";
import { sortItems } from "../../../utils";

export const FavouritesProductsList = () => {
  const naviage = useNavigate();
  const { id } = useParams();
  const {
    state: {
      productsTab: {
        productsList,
        isLoading,
        filteredProducts,
        searchText,
        selectedProducts,
        isOpen,
        isDelete,
        isSaveButtonBusy,
      },
      favDetails: {
        data: { type },
      },
    },
    HandleFilterProducts,
    handleProductSelect,
    handleAddProductModal,
    handleAssignProducts,
    togglePrdDeleteModal,
    onConfirmPrdDelete,
    hasModify,
    onProductsOrderChange,
    isStoreAdmin,
  } = useFavourites({
    isFav: false,
    isProducts: id,
    isStores: false,
  });

  const data =
    (productsList.length > 0 &&
      productsList.map((item) => ({
        id: item.product_id,
        name: item.name,
      }))) ||
    [];

  const columns = React.useMemo(
    () => [
      {
        Header: "Product",
        accessor: (row) => {
          return (
            <div
              className="table-taxt-and-icon-link"
              onClick={() => naviage(`/products/details/${row.id}`)}
            >
              <span className="link-text">{row.name}</span>
              <div className="link-icon-wrap">
                <img alt="link" src="/icons/ic_external_link.svg" />
              </div>
            </div>
          );
        },
      },
      {
        Header: "Action",
        disableGlobalFilter: true,
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons deleteAction={togglePrdDeleteModal} row={row} />
          );
        },
      },
    ],
    [data]
  );

  const storeAdminColumns = React.useMemo(
    () => [
      {
        Header: "Product",
        accessor: "name",
      },
      {
        Header: "Action",
        disableGlobalFilter: true,
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons deleteAction={togglePrdDeleteModal} row={row} />
          );
        },
      },
    ],
    [data]
  );

  const ActionButtons = ({ deleteAction, row }) => {
    return (
      <div className="action-buttons">
        {hasModify && (
          <img
            alt="delete"
            className={type === "global" && isStoreAdmin && "disabled-img"}
            onClick={() => {
              isStoreAdmin
                ? type !== "global" && deleteAction(row.id)
                : deleteAction(row.id);
            }}
            src="/icons/ic_delete.svg"
          />
        )}
      </div>
    );
  };

  if (isLoading) {
    return <Loader />;
  }
  const productList = productsList?.map((item) => ({
    id: item.product_id,
    display_order: item.display_order,
    name: item.name,
  }));

  return (
    <>
      <DraggableReactTable
        columns={isStoreAdmin ? storeAdminColumns : columns}
        data={data}
        tableTitle="Products"
        searchBar={false}
        onRowOrderChange={onProductsOrderChange}
        disableDrag={type === "global" && isStoreAdmin}
        headerRightContent={
          <>
            {hasModify && (isStoreAdmin ? type !== "global" : true) && (
              <Button
                type="button"
                size="medium"
                variant="text"
                startIcon={<AddIcon />}
                onClick={handleAddProductModal}
              >
                Add Product
              </Button>
            )}
          </>
        }
      />
      <CustomeDialog
        open={isOpen}
        handleClose={handleAddProductModal}
        PaperProps={{
          sx: { width: "423px", height: "491px", p: "16px" },
        }}
        title="Products"
        content={
          <CategoryProductDialog
            Items={filteredProducts}
            onSelect={handleProductSelect}
            selectedItems={selectedProducts}
            onSubmit={handleAssignProducts}
            onSearch={HandleFilterProducts}
            existedProductList={productList}
            disabled={isSaveButtonBusy}
          />
        }
      />
      <DeleteModal
        open={isDelete}
        handleClose={() => togglePrdDeleteModal()}
        onConfirm={() => onConfirmPrdDelete()}
      />
    </>
  );
};
