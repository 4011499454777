import React, { useState } from "react";
import {
  ContentLayout,
  CustomeDialog,
  CustomTable,
  DeleteModal,
  usePermission,
  useTabs,
} from "../../../shared";
import {
  Button,
  Checkbox,
  Chip,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { arraySort, sortItems } from "../../../utils";
import { GroupNameDialog } from "./GroupNameDialog";
import { useFavourites } from "../hooks/useFavourites";
import { useStore } from "../..";

export const SuperFavouritesList = () => {
  const { storeList } = useStore({ isStore: true, storeId: null });
  const navigate = useNavigate();
  const { resetCurrentTabs } = useTabs();
  const {
    state,
    onChangeSearchText,
    onSeachClear,
    handleFilter,
    handleFilterClear,
    handleApplyFilter,
    toggleModal,
    handleDeleteFav,
    onChangeFavGroup,
    onSaveFavGroup,
    toggleCreateOrEditModal,
    onUpdateFavGroup,
    isEditable,
    isCreateVisible,
    isDeletable,
  } = useFavourites({
    isFav: true,
    favId: null,
    isProducts: false,
    isStores: false,
  });
  const {
    favList,
    isBusy,
    filters,
    isOpen,
    data,
    isCreateOpen,
    isEditOpen,
    isSaveButtonBusy,
  } = state;

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });

  const storeOptions = storeList.map((v) => {
    return { value: v.id, label: v.name };
  });

  const ActionButtons = ({ id, deleteAction, editAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isEditable && (
          <img alt="edit" onClick={editAction} src="/icons/ic_edit.svg" />
        )}
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}

        <img
          onClick={() => {
            navigate(`details/${id}`);
            resetCurrentTabs();
          }}
          alt="details"
          src="/icons/arrow_forward.svg"
        />
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Group Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Created By",
        accessor: "user_name",
        Cell: ({ value, row }) => {
          return (
            <>
              <div>{value}</div>
              <Typography variant="caption" color="#808080">
                {row.original.type}
              </Typography>
            </>
          );
        },
      },
      {
        Header: "Stores",
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ row }) => {
          const stores = row?.original?.stores;
          return (
            <Box>
              {stores?.slice(0, 4).map((data) => {
                return (
                  <Chip
                    sx={{ mr: 1, borderRadius: "8px" }}
                    label={data.name}
                    variant="filled"
                    size="small"
                  />
                );
              })}
              {stores?.length > 4 && (
                <Chip
                  sx={{ borderRadius: "8px" }}
                  label={`+${stores?.length - stores?.slice(0, 4)?.length}`}
                  variant="filled"
                  size="small"
                />
              )}
            </Box>
          );
        },
      },

      {
        Header: "Action",
        id: "action",
        disableSortBy: true,
        align: "right",
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => {
                toggleModal(row.id);
              }}
              editAction={() => toggleCreateOrEditModal(row.id, "edit")}
            />
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <ContentLayout
        title="Favourites"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => toggleCreateOrEditModal(null, "create")}
              >
                New Group
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={favList}
          isLoading={isBusy}
          defaultSearch={false}
          filterApplied={filters.all}
          onFilterClear={(e) => handleFilterClear("single", e)}
          onAllFilterClear={(e) => handleFilterClear("all")}
          filterContent={
            <InputControl
              type="table-search"
              value={filters.searchText}
              onChange={(e) => onChangeSearchText(e)}
              onClear={onSeachClear}
            />
          }
          filterSection={
            <>
              <Stack direction="row" gap="16px" alignItems="center">
                <InputControl
                  type="dropdown"
                  name="store"
                  label="Store"
                  multiple={true}
                  options={arraySort(storeOptions || [], "label")}
                  value={filters.stores}
                  disableCloseOnSelect
                  disableClearable={true}
                  onChange={(e, v) => handleFilter(e, v, "store")}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Store" placeholder="Store" />
                  )}
                />
              </Stack>
              <Button
                type="button"
                size="medium"
                variant="text"
                color="info"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => handleApplyFilter()}
              >
                Apply
              </Button>
            </>
          }
          onRowLink={(e) => {
            return `details/${e.id}`;
          }}
          onRowAction={(e) => {
            resetCurrentTabs();
          }}
        />
      </ContentLayout>
      <CustomeDialog
        open={isCreateOpen || isEditOpen}
        handleClose={() => toggleCreateOrEditModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "423px", height: "235px", p: "16px" },
        }}
        title="New Group"
        content={
          <GroupNameDialog
            onChangeFavGroup={onChangeFavGroup}
            state={state}
            onSaveFavGroup={onSaveFavGroup}
            onUpdateFavGroup={onUpdateFavGroup}
            isEdit={isEditOpen}
          />
        }
      />
      <DeleteModal
        open={isOpen}
        handleClose={() => toggleModal()}
        onConfirm={() => handleDeleteFav()}
      />
    </>
  );
};
