import React from "react";
import { Route, Routes } from "react-router-dom";
import { MenulogList } from "./components/MenulogList";

export const Menulog = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<MenulogList />} />
            </Routes>
        </>
    );
};
