import { ContentLayout } from "../../../shared";
import * as React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TaxesList, TaxGroupList, useTax } from "..";

export const TaxGroupsAndTaxesList = () => {
  const {
    state,
    currentTab,
    handleTabChange,
    handleModal,
    handleDeleteTaxGroup,
    handleDeleteTaxes,
  } = useTax({ isList: true });
  const { taxGroup, taxes } = state;

  return (
    <>
      <ContentLayout title="Taxes">
        <TabContext value={currentTab}>
          <Box className="sticky-Tab" sx={{ borderBottom: 1, borderColor: "divider", top: "88px" }}>
            <TabList onChange={handleTabChange}>
              <Tab label="Tax Group" value="1" />
              <Tab label="Taxes" value="2" />
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            <TaxGroupList
              data={taxGroup}
              handleModal={handleModal}
              onDelete={handleDeleteTaxGroup}
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            <TaxesList
              data={taxes}
              handleModal={handleModal}
              onDelete={handleDeleteTaxes}
            />
          </TabPanel>
        </TabContext>
      </ContentLayout>
    </>
  );
};
