import {
  ContentLayout,
  CustomDetailsList,
  CustomTable,
  HeaderBreadcrumbs,
  Loader,
} from "../../../shared";
import React from "react";
import {
  Button,
  createFilterOptions,
  TextField,
  Grid,
  Typography,
  Divider,
  Card,
  Stack,
  Chip,
} from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";

import { AddCashDetailsTable, AddChequeDetailsTable } from "../components";
import { useStore } from "../../Stores";
import { useDepositSlip } from "../..";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";

export const AddDepositSlip = () => {
  const { id } = useParams();
  const {
    state,
    handleInputChange,
    handleAddCheque,
    handleDeleteCheque,
    handleChequeInputChange,
    onSaveDepositSlip,
    onUpdateDepositSlip,
    handleGetCashDetails,
    onBlurTab,
  } = useDepositSlip({
    isDetailID: id,
    IsCreate: id ? false : true,
    IsEdit: id ? true : false,
  });
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        ChequeAmount: {
          required: true,
          message: "The Check amount must be greater than 0",
          rule: ({ amount }) => {
            return amount > 0;
          },
        },
        ChequeNumber: {
          required: true,
          message: "The Check number must be greater than 0",
          rule: ({ number }) => {
            return number > 0;
          },
        },
      },
    })
  );
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const { states } = useStore({ isStore: true });
  const storeOptions = states.store.map((v) => {
    return { value: v.id, label: v.name };
  });

  const { bankAccounts, cashDetails, isSaveButton, isLoading, isApplyButton } =
    state;
  const {
    store_id,
    date,
    alias,
    account_name,
    bsb,
    account_number,
    cheque,
    total_cash,
    total_cheque,
    total_amount,
    variance,
    eod_total_cheque,
  } = state.updateData;
  const navigate = useNavigate();

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });

  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdateDepositSlip(id) : onSaveDepositSlip();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Terminal Name",
        disableSortBy: true,
        accessor: "terminal_name",
      },
      {
        Header: "Staff Name",
        disableSortBy: true,
        accessor: "staff_name",
        Cell: ({ value }) => {
          return value || "--";
        },
      },
      {
        Header: "Cash Variance",
        disableSortBy: true,
        accessor: "cash_variance",
        Cell: ({ value }) => {
          return (
            <Typography
              variant="body2"
              color={value > 0 ? "#0F875B" : "#C42B1C"}
            >
              {value ? `$${value}` : "--"}
            </Typography>
          );
        },
      },
      {
        Header: "Status",
        accessor: "eod_status",
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return value === 1 ? (
            <Chip
              label="Approved"
              color="success"
              variant="outlined"
              size="small"
              sx={{
                border: "none !important",
              }}
            />
          ) : value === 0 ? (
            <Chip
              label="Pending"
              color="info"
              variant="outlined"
              size="small"
              sx={{
                backgroundColor: "#FFF4CE",
                color: "#C07302",
                border: "none !important",
              }}
            />
          ) : (
            <Chip
              label="Not Submitted"
              color="success"
              variant="outlined"
              size="small"
              disabled
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        accessor: "id",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons row={row} />;
        },
      },
    ],
    [store_id, date]
  );

  const ActionButtons = ({ row }) => {
    let query = `?store_id=${store_id}&terminal_id=${row.terminal_id}&date=${date}`;
    const link = () => {
      if (isStoreAdmin) {
        return [1, 0].includes(row.eod_status)
          ? `/store-admin/end-of-day/details/${row.eod_id}`
          : `/store-admin/end-of-day/create${query}`;
      } else {
        return [1, 0].includes(row.eod_status)
          ? `/end-of-day/details/${row.eod_id}`
          : `/end-of-day/create${query}`;
      }
    };
    return (
      <div className="action-buttons">
        <Link target="_blank" to={link()}>
          <img
            alt="details"
            src="/icons/arrow_forward.svg"
            onClick={onBlurTab}
          />
        </Link>
      </div>
    );
  };

  const columns2 = [
    {
      title: "Generated By",
      accessor: "user_name",
    },
    {
      title: "Generated Date",
      accessor: (val) => {
        return (
          <Typography variant="subtitle2" fontWeight={400} color="#636363">
            {moment(val.created_at).format("DD-MM-YYYY")}
          </Typography>
        );
      },
    },
    {
      title: "Total Cash",
      accessor: (val) => {
        return (
          <Typography variant="subtitle2" fontWeight={400} color="#636363">
            ${val.total_cash}
          </Typography>
        );
      },
    },
    {
      title: "Total Cheque",
      accessor: (val) => {
        return (
          <Typography variant="subtitle2" fontWeight={400} color="#636363">
            ${val.total_cheque}
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <ContentLayout
        title="Deposit Slip"
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                {
                  name: "Dashboard",
                  href: isStoreAdmin ? "/store-admin" : "/",
                },
                {
                  name: "End Of Day",
                  href: isStoreAdmin
                    ? `/store-admin/end-of-day`
                    : `/end-of-day`,
                },
                {
                  name: "Deposit Slip",
                  href: isStoreAdmin
                    ? `/store-admin/deposit-slip`
                    : `/deposit-slip`,
                },
                id
                  ? {
                      name: "Details",
                      href: isStoreAdmin
                        ? `/store-admin/deposit-slip/details/${id}`
                        : `/deposit-slip/details/${id}`,
                    }
                  : false,
                { name: id ? "Edit" : "Create" },
              ].filter(Boolean)}
            />
          </>
        }
      >
        {id && isLoading ? (
          <Loader />
        ) : (
          <>
            <Card sx={{ p: "32px 24px", marginBottom: "24px" }}>
              <>
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack direction="row" gap="20px">
                    {!isStoreAdmin && (
                      <InputControl
                        type="dropdown"
                        name="store_id"
                        label="Store Name"
                        options={storeOptions}
                        disableClearable={true}
                        value={
                          (storeOptions.length > 0 &&
                            store_id &&
                            storeOptions.find((c) => c.value === store_id)) ||
                          null
                        }
                        filterSelectedOptions
                        onChange={(e, v, r) =>
                          handleInputChange(
                            {
                              target: {
                                name: "store_id",
                                value: v.value,
                              },
                            },
                            v,
                            r
                          )
                        }
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        filterOptions={filterOptions}
                        sx={{ width: 250 }}
                        disabled={id}
                        componentsProps={{
                          paper: {
                            sx: {
                              width: "fit-content",
                              minWidth: 250,
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Store Name"
                            placeholder="Store Name"
                            error={validator.current.message(
                              "Store Name",
                              store_id,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Store Name",
                              store_id,
                              "required"
                            )}
                          />
                        )}
                      />
                    )}
                    <InputControl
                      type="datePicker"
                      name="date"
                      label="Date"
                      inputFormat="dd-MM-yyyy"
                      disableFuture
                      disabled={id ? true : false}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      sx={{
                        minWidth: "226px",
                      }}
                      value={date}
                      onChange={(e) =>
                        handleInputChange({
                          target: {
                            name: "date",
                            value: e,
                          },
                        })
                      }
                      error={validator.current.message(
                        "Date",
                        date,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "Date",
                        date,
                        "required"
                      )}
                    />
                  </Stack>
                  <Stack>
                    {!id && (
                      <Button
                        variant="contained"
                        disabled={isApplyButton}
                        onClick={handleGetCashDetails}
                      >
                        Apply
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </>
            </Card>

            {cashDetails.isInit && (
              <>
                {cashDetails.isLoading ? (
                  <Loader />
                ) : (
                  <>
                    {cashDetails.dataList.existDepositSlip && !id ? (
                      <CustomDetailsList
                        columns={columns2}
                        data={cashDetails.dataList.deposit_details}
                        title="Details"
                        headerRightContent={
                          <>
                            <Button
                              size="medium"
                              onClick={() =>
                                navigate(
                                  isStoreAdmin
                                    ? `/store-admin/deposit-slip/details/${cashDetails.dataList.deposit_details.id}`
                                    : `/deposit-slip/details/${cashDetails.dataList.deposit_details.id}`
                                )
                              }
                            >
                              View Details
                            </Button>
                          </>
                        }
                      />
                    ) : (
                      <>
                        <CustomTable
                          columns={columns}
                          data={cashDetails.dataList.eod_terminal_status}
                          searchBar={false}
                          tableTitle="Terminal End Of Day Details"
                          pagination={false}
                          rowClickNewTab={true}
                          onRowLink={(e) => {
                            let query = `?store_id=${store_id}&terminal_id=${e.terminal_id}&date=${date}`;
                            if (isStoreAdmin) {
                              return [1, 0].includes(e.eod_status)
                                ? `/store-admin/end-of-day/details/${e.eod_id}`
                                : `/store-admin/end-of-day/create${query}`;
                            } else {
                              return [1, 0].includes(e.eod_status)
                                ? `/end-of-day/details/${e.eod_id}`
                                : `/end-of-day/create${query}`;
                            }
                          }}
                          onRowAction={(e) => {
                            onBlurTab();
                          }}
                        />

                        <Card sx={{ p: "32px 24px", marginBottom: "24px" }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant="subtitle1"
                                fontWeight={600}
                                sx={{ width: "150px" }}
                              >
                                Account Details
                              </Typography>
                            </Grid>
                            <Grid item sm={8}>
                              <Stack width={"100%"} gap="20px">
                                <InputControl
                                  type="dropdown"
                                  name="alias"
                                  label="Alias"
                                  options={bankAccounts.dataList ?? []}
                                  value={
                                    (bankAccounts.dataList.length > 0 &&
                                      alias &&
                                      bankAccounts.dataList.find(
                                        (c) => c.value === alias
                                      )) ||
                                    null
                                  }
                                  filterSelectedOptions
                                  onChange={(e, v, r) =>
                                    handleInputChange(
                                      {
                                        target: {
                                          name: "alias",
                                          value: v.value,
                                        },
                                      },
                                      v,
                                      r
                                    )
                                  }
                                  getOptionLabel={(option) => option.label}
                                  isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                  }
                                  filterOptions={filterOptions}
                                  sx={{ width: "100%" }}
                                  componentsProps={{
                                    paper: {
                                      sx: {
                                        width: "100%",
                                        minWidth: "100%",
                                      },
                                    },
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Alias"
                                      placeholder="Alias"
                                      error={validator.current.message(
                                        "Alias",
                                        alias,
                                        "required"
                                      )}
                                      helperText={validator.current.message(
                                        "Alias",
                                        alias,
                                        "required"
                                      )}
                                    />
                                  )}
                                />

                                <InputControl
                                  name="account_name"
                                  label="Account Name"
                                  value={account_name}
                                  onChange={(e) => handleInputChange(e)}
                                  disabled={true}
                                />
                                <Stack direction="row" gap="20px">
                                  <InputControl
                                    name="bsb"
                                    label="BSB"
                                    value={bsb}
                                    onChange={(e) => handleInputChange(e)}
                                    disabled={true}
                                  />
                                  <InputControl
                                    name="account_number"
                                    label="Account Number"
                                    value={account_number}
                                    onChange={handleInputChange}
                                    disabled={true}
                                  />
                                </Stack>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Card>

                        <Card
                          sx={{
                            marginBottom: "24px",
                          }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            height="83px"
                            p="32px 24px"
                          >
                            <Typography
                              variant="subtitle1"
                              fontWeight={600}
                              lineHeight="19px"
                              minWidth="160px"
                            >
                              Cheque Details
                            </Typography>
                          </Stack>
                          <AddChequeDetailsTable
                            data={cheque}
                            total_cheque={total_cheque}
                            validator={validator}
                            eodTotal={eod_total_cheque}
                            variance={variance}
                            handleAddCheque={handleAddCheque}
                            handleDeleteCheque={handleDeleteCheque}
                            handleChequeInputChange={handleChequeInputChange}
                          />
                        </Card>
                        <Card
                          sx={{
                            marginBottom: "24px",
                          }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            height="83px"
                            p="32px 24px"
                          >
                            <Typography
                              variant="subtitle1"
                              fontWeight={600}
                              lineHeight="19px"
                              minWidth="160px"
                            >
                              Cash Details
                            </Typography>
                          </Stack>
                          <AddCashDetailsTable
                            data={cashDetails.dataList.cash}
                          />
                        </Card>
                        <Card
                          sx={{
                            marginBottom: "54px",
                          }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            height="83px"
                            p="32px 24px"
                          >
                            <Typography
                              variant="subtitle1"
                              fontWeight={600}
                              lineHeight="19px"
                              minWidth="160px"
                            >
                              Summary
                            </Typography>
                          </Stack>
                          <Divider />
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            p="14.5px 16px 14.5px 22px"
                          >
                            <Typography
                              variant="subtitle1"
                              fontWeight={400}
                              lineHeight="19px"
                            >
                              Total Cash
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              fontWeight={400}
                              lineHeight="19px"
                            >
                              ${total_cash}
                            </Typography>
                          </Stack>
                          <Divider />
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            p="14.5px 16px 14.5px 22px"
                          >
                            <Typography
                              variant="subtitle1"
                              fontWeight={400}
                              lineHeight="19px"
                            >
                              Total Cheque
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              fontWeight={400}
                              lineHeight="19px"
                            >
                              ${total_cheque}
                            </Typography>
                          </Stack>
                          <Divider />

                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            p="14.5px 16px 14.5px 22px"
                            sx={{
                              backgroundColor: "#E1FBF2",
                              color: "#0F875B",
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              fontWeight={600}
                              lineHeight="19px"
                            >
                              Total Amount
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              fontWeight={600}
                              lineHeight="19px"
                            >
                              $
                              {(
                                Number(total_cash) + Number(total_cheque)
                              ).toFixed(2)}
                            </Typography>
                          </Stack>
                          <Stack p="24px 16px" alignItems="flex-end">
                            <Button
                              type="button"
                              size="medium"
                              variant="contained"
                              disabled={isSaveButton}
                              onClick={onSubmit}
                            >
                              {id ? "Regenerate" : "Generate"}
                            </Button>
                          </Stack>
                        </Card>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </ContentLayout>
    </>
  );
};
