import { CustomDetailsList } from "../../../shared";
import * as React from "react";
import { Button, Chip, Stack, Typography } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";

export const DiscountBasicDetails = (prop) => {
  const { state, isEditable } = prop;
  const navigate = useNavigate();
  const { id } = useParams();

  const columns = React.useMemo(
    () => [
      {
        title: "Discount Name",
        accessor: "display_name",
      },
      {
        title: "Discount",
        accessor: (row) => {
          return (
            <Stack direction="row">
              <Typography>
                {row.discount_mode === "percentage"
                  ? `${row.amount}%`
                  : `$${row.amount}`}
              </Typography>
            </Stack>
          );
        },
      },
      {
        title: "Lock Status",
        accessor: "require_manager_passcode",
        accessor: (row) => {
          return (
            <Stack direction="row">
              <Chip
                label={
                  row.require_manager_passcode === 1 ? "Locked" : "Un-Locked"
                }
                color="primary"
                variant="outlined"
                size="small"
                sx={{
                  border: "none !important",
                  bgcolor: "rgba(21, 96, 212, 0.08)",
                }}
              />
              {row.require_manager_passcode === 1 && (
                <Typography
                  color="#808080"
                  fontWeight={400}
                  fontSize="14px"
                  ml={1}
                >
                  Locked discount can be applied in POS with manager password
                  only.
                </Typography>
              )}
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <CustomDetailsList
        columns={columns}
        data={state.data}
        title="Details"
        headerRightContent={
          <>
            {isEditable && (
              <Button
                type="button"
                size="medium"
                variant="text"
                flex-direction="right"
                onClick={() => navigate(`/discount/edit/${id}`)}
              >
                Edit
              </Button>
            )}
          </>
        }
      />
    </>
  );
};
