import * as React from "react";
import {
  List,
  ListItem,
  ListItemText,
  FormControl,
  Button,
  Stack,
  InputAdornment,
  OutlinedInput,
  Box,
  ListItemButton,
  Checkbox,
  ListItemIcon,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader, useDropdownApis } from "../../../shared";

export const ProductInfiniteScrollDialog = (props) => {
  const {
    handleSelect,
    isSelected,
    handleChange,
    disabled,
    pageLoader,
    alreadyList,
    filterItemIds = [],
  } = props;
  const {
    state: { product },
    productLoadNextPage,
    HandleFilterProducts,
  } = useDropdownApis({ isProductsList: true });
  const { dataList, pagination, searchText } = product;

  const productList = !filterItemIds.length
    ? dataList
    : dataList.filter((item) => !filterItemIds.includes(item.id));

  const isDisabled = (id) => {
    return alreadyList.includes(id);
  };

  return (
    <>
      <Stack
        justifyContent="space-between"
        height="100%"
        gap="8px"
        maxHeight={"calc(100% - 24px)"}
      >
        <FormControl
          size="small"
          fullWidth={true}
          sx={{
            pt: "24px",
          }}
        >
          <OutlinedInput
            placeholder="Search"
            sx={{
              height: "48px",
              ".MuiOutlinedInput-input": {
                pl: "0px !important",
              },
            }}
            size="small"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon size="large" />
              </InputAdornment>
            }
            value={searchText}
            endAdornment={
              searchText && (
                <InputAdornment
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    HandleFilterProducts("search", "");
                  }}
                  position="end"
                >
                  <CloseIcon size="large" />
                </InputAdornment>
              )
            }
            fullWidth
            onChange={(e) => {
              HandleFilterProducts("search", e.target.value);
            }}
          />
        </FormControl>
        {pageLoader ? (
          <Loader />
        ) : (
          <Box
            id="scrollableDiv"
            sx={{ height: "100%", overflow: "auto" }}
            fullWidth
          >
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              dataLength={productList?.length}
              next={productLoadNextPage}
              hasMore={pagination.hasMorePages}
              loader={
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  Loading...
                </Box>
              }
            >
              {productList?.length > 0 ? (
                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                  {productList?.map((data, index) => {
                    const labelId = `checkbox-list-label-${data.name}`;
                    const disable = isDisabled(data.id);
                    return (
                      <ListItem key={data.name} disablePadding>
                        <ListItemButton
                          role={undefined}
                          onClick={() => !disable && handleSelect(data.id)}
                          dense
                          sx={{
                            "&:hover": {
                              backgroundColor: "rgba(21, 96, 212, 0.08)",
                              borderRadius: "8px",
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: "32px",
                            }}
                          >
                            <Checkbox
                              edge="start"
                              checked={disable || isSelected(data.id)}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                              disabled={disable}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={data.name}
                            sx={{ color: disable ? "#6B7280" : "#000" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              ) : (
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  mt="160px"
                >
                  no data found
                </Stack>
              )}
            </InfiniteScroll>
          </Box>
        )}
        <Button
          sx={{ height: "48px" }}
          variant="contained"
          fullWidth
          onClick={() => {
            handleChange();
          }}
          disabled={disabled}
        >
          Save
        </Button>
      </Stack>
    </>
  );
};
