export const tableScopeReducer = (draft, action) => {
  const { type = null, page = null, data = {}, value = null } = action;
  switch (type) {
    case "SET_DATA":
      draft.params[page] = { ...draft.params[page], ...data };
      return draft;
      break;
    case "CLEAR_FILTERS":
      draft.params[page].filterApplied = value;
      return draft;
      break;
    case "UPDATE_PAGE_INDEX":
      draft.params[page].pagination.pageIndex = value;
      return draft;
      break;
    case "UPDATE_PAGE_SIZE":
      draft.params[page].pagination.pageSize = value;
      return draft;
      break;
    case "UPDATE_SORT":
      draft.params[page].sortBy = value;
      return draft;
      break;
    case "UPDATE_COLUMNS":
      draft.params[page].columnOptions = value;
      return draft;
      break;
    case "UPDATE_INITIAL_DATA":
      draft.initialLoad = false;
      draft.params = { ...draft.params, ...data };
      return draft;
      break;
    case "UPDATE_INITIAL_LOAD":
      draft.initialLoad = false;
      return draft;
      break;
    case "RESET_STATE":
      draft.params = data;
      return draft;
      break;
    default:
      break;
  }
};
