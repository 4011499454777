import React, { useContext, useEffect } from "react";
import { AppContext } from "../../../store/AppScopeProvider";
import { useImmer } from "use-immer";
import qs from "qs";
import { getProductsSA } from "../api/ProductApi";
import { useDebouncedCallback } from "use-debounce";
import { AuthContext } from "../../../store/AuthProvider";
import { useDropdownApis } from "../../../shared";
import { TableStateContext } from "../../../store";

export const useProductsListSA = ({ isProduct = false }) => {
  const { appState } = useContext(AppContext);
  const { auth } = useContext(AuthContext);
  const { selectedStoreId } = auth.data;
  const { globalData } = appState;
  const { categoriesList, pricelevelList } = useDropdownApis({
    isCategoryList: isProduct,
    isPricelevelsList: isProduct,
  });
  const PAGE_NAME = "storeProducts";
  const {
    filterState: {
      params: { storeProducts },
    },
    filterStateDispatch,
  } = useContext(TableStateContext);
  const [state, setState] = useImmer({
    isBusy: false,
    initialLoad: true,
    disableApplyButton: false,
    priceLevels: [],
    details: {},
    pagination: {
      pageIndex: 1,
      pageSize: 25,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    sortBy: [],
    productsList: {
      isLoading: false,
      listData: [],
    },
    filterApplied: {
      categories: [],
      priceLevels: [],
      all: [],
      searchText: null,
    },

    httpStatusCodes: {
      details: {},
    },
  });

  const debounced = useDebouncedCallback((value) => {
    const { pagination, sortBy } = state;
    const { categories, priceLevels } = state.filterApplied;
    handleGetProductSA({
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
      searchText: value,
      categories,
      priceLevels,
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filterApplied },
    });
  }, 1000);

  const handleGetProductSA = async ({
    pagination,
    sortBy = [],
    searchText = null,
    categories = [],
    priceLevels = [],
  }) => {
    try {
      setState((draft) => {
        draft.productsList.isLoading = true;
        draft.disableApplyButton = true;
      });
      let CategoryIDs = categories.map((v) => {
        return v.value;
      });
      let PriceLevelIDs = priceLevels.map((v) => {
        return v.value;
      });

      let params = {};

      if (searchText) {
        params = { ...params, search: searchText };
      }
      if (sortBy.length > 0) {
        const sortByParams = sortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }
      if (CategoryIDs.length > 0) {
        params = { ...params, category_id: CategoryIDs };
      }
      if (PriceLevelIDs.length > 0) {
        params = { ...params, price_level_id: PriceLevelIDs };
      }

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };

      const res = await getProductsSA(selectedStoreId, query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.productsList.isLoading = false;
          draft.productsList.listData = res.data.store_products;
          draft.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.productsList.isLoading = false;
        draft.disableApplyButton = false;
      });
    }
  };

  useEffect(() => {
    if (isProduct) {
      const { pagination, sortBy } = storeProducts;
      const { categories, priceLevels, searchText } =
        storeProducts.filterApplied;
      setState((draft) => {
        draft.filterApplied = storeProducts.filterApplied;
        draft.pagination = pagination;
        draft.sortBy = sortBy;
      });

      handleGetProductSA({
        pagination: {
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
        },
        sortBy,
        searchText,
        categories,
        priceLevels,
      });
    }
  }, [isProduct]);

  const handleInputChange = (event, v) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "alert_stock_status") {
      setState((draft) => {
        draft.stockDetails.details.alert_stock_status = v ? 1 : 0;
      });
    } else {
      setState((draft) => {
        draft.stockDetails.details = {
          ...draft.stockDetails.details,
          [name]: value,
        };
      });
    }
  };

  const handleSearch = (v) => {
    setState((draft) => {
      draft.filterApplied.searchText = v;
    });
    debounced(v);
  };

  const handleFilterClear = (type, value) => {
    const { categories, priceLevels, searchText } = state.filterApplied;
    const { pagination, sortBy } = state;
    if (type === "single") {
      if (value.type === "Categories") {
        const newCategories = state.filterApplied.categories.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.categories = newCategories;
        });
        handleGetProductSA({
          pagination: {
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
          },
          sortBy,
          searchText,
          categories: newCategories,
          priceLevels,
        });
        filterStateDispatch({
          type: "SET_DATA",
          page: PAGE_NAME,
          data: {
            filterApplied: {
              ...state.filterApplied,
              categories: newCategories,
            },
          },
        });
      }
      if (value.type === "Price Level") {
        const newPriceLevel = state.filterApplied.priceLevels.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.priceLevels = newPriceLevel;
        });
        handleGetProductSA({
          pagination: {
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
          },
          sortBy,
          searchText,
          categories,
          priceLevels: newPriceLevel,
        });
        filterStateDispatch({
          type: "SET_DATA",
          page: PAGE_NAME,
          data: {
            filterApplied: {
              ...state.filterApplied,
              priceLevels: newPriceLevel,
            },
          },
        });
      }
    } else {
      setState((draft) => {
        draft.filterApplied.all = [];
        draft.filterApplied.categories = [];
        draft.filterApplied.priceLevels = [];
        draft.productsList.isLoading = true;
      });
      const initialValue = {
        categories: [],
        priceLevels: [],
        all: [],
        searchText: searchText,
      }
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initialValue,
      });
      handleGetProductSA({
        pagination: {
          pageIndex: 1,
          pageSize: pagination.pageSize,
        },
        sortBy,
        searchText,
      });
    }
  };

  const handleFilter = (e, value, type) => {
    if (type === "categories") {
      setState((draft) => {
        draft.filterApplied.categories = value.map((v) => {
          return { ...v, type: "Categories" };
        });
      });
    }
    if (type === "Price Level") {
      setState((draft) => {
        draft.filterApplied.priceLevels = value.map((v) => {
          return { ...v, type: "Price Level" };
        });
      });
    }
    setState((draft) => {
      draft.disableApplyButton = false;
    });
  };

  useEffect(() => {
    setState((draft) => {
      draft.filterApplied.all = [
        ...state.filterApplied.categories,
        ...state.filterApplied.priceLevels,
      ].filter((el) => Object.keys(el).length);
    });
  }, [state.filterApplied.priceLevels, state.filterApplied.categories]);

  const handleApplyFilter = () => {
    const { categories, priceLevels, searchText } = state.filterApplied;
    const { pagination, sortBy } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: state.filterApplied,
        pagination,
        sortBy,
      },
    });
    handleGetProductSA({
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      },
      sortBy,
      searchText,
      categories,
      priceLevels,
    });
  };

  const handleSort = (sortBy) => {
    const { categories, priceLevels, searchText } = state.filterApplied;
    const { pagination } = state;
    filterStateDispatch({
      type: "UPDATE_SORT",
      page: PAGE_NAME,
      value: sortBy,
    });
    handleGetProductSA({
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      },
      sortBy,
      searchText,
      categories,
      priceLevels,
    });
    setState((draft) => {
      draft.sortBy = sortBy;
    });
  };
  const gotoPage = (page) => {
    const { categories, priceLevels, searchText } = state.filterApplied;
    const { sortBy } = state;
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
    handleGetProductSA({
      pagination: {
        pageIndex: page,
        pageSize: state.pagination.pageSize,
      },
      sortBy,
      searchText,
      categories,
      priceLevels,
    });
    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };

  const setPageSize = (e) => {
    const { categories, priceLevels, searchText } = state.filterApplied;
    const { sortBy } = state;
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
    handleGetProductSA({
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
      searchText,
      categories,
      priceLevels,
    });
    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };

  return {
    state,
    globalData,
    categoriesList,
    pricelevelList,
    setState,
    handleInputChange,
    handleSearch,
    handleFilterClear,
    handleFilter,
    handleApplyFilter,
    handleSort,
    setPageSize,
    gotoPage,
  };
};
