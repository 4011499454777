import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import {
  BirthdayCouponsList,
  EditBirthdayCoupons,
  BirthdayCouponsDetails,
  BirthdayCouponAccountList,
} from "./containers";

export const BirthdayCoupons = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<BirthdayCouponsList />} />
        <Route element={<ProtectedRoute allowedRoute={["coupon-modify"]} />}>
          <Route path="/edit" element={<EditBirthdayCoupons />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["coupon-view"]} />}>
          <Route path="/details/:id" element={<BirthdayCouponsDetails />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["coupon-view"]} />}>
          <Route path="/account/:id" element={<BirthdayCouponAccountList />} />
        </Route>
      </Routes>
    </>
  );
};
