/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-throw-literal */
import React from "react";
import {
  ContentLayout,
  DeleteModal,
  HeaderBreadcrumbs,
  Loader,
  usePermission,
} from "../../../shared";
import {
  Stack,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
} from "@mui/material";
import { MembersList, PosPermissionSection, usePosRoleDetails } from "..";
import { useNavigate } from "react-router-dom";
import { sortItems } from "../../../utils";

const checkboxStyle = { "& .MuiSvgIcon-root": { fontSize: 24 } };

export const PosRoleDetails = ({ isStoreAdmin }) => {
  const navigate = useNavigate();
  const {
    roleId,
    state,
    permissions,
    breadcrumbLinks,
    isPermissionChecked,
    isIndeterminate,
    handleDeleteDialog,
    handleConfirmDeleteRole,
    handleUserDialog,
    handleFilterUsers,
    hasSelectedUser,
    handleUserSelection,
    handleAssignUser,
    handleMemberDeleteModal,
    handleUnassignUser,
    handleStatusFilter,
    resetFilters,
  } = usePosRoleDetails();

  const { roleDetails, isDeleteDialogOpen, isMemberTableLoading } = state;
  const isDeletable = usePermission("pos-role-delete");
  const isModifiable = usePermission("pos-role-modify");

  const columns = React.useMemo(
    () => [
      {
        id: "expander",
        Header: "",
        Cell: ({ row }) =>
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `0px 10px`,
                },
              })}
              style={{ padding: `0px 10px`, marginLeft: "-20px" }}
            >
              <img
                alt="forward"
                className={`${row.isExpanded && "expanded-icon"}`}
                src="/icons/ic_forward.svg"
              />
            </span>
          ) : null,
        padding: "checkbox",
      },
      {
        Header: () => {
          return <span style={{ marginLeft: `-15px` }}>Permission Name</span>;
        },
        accessor: "name",
        Cell: ({ row, value }) => {
          return (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                marginLeft: "-15px",
              }}
            >
              {row.depth ? (
                <img alt="permission" src="/icons/permission.svg" />
              ) : (
                <img alt="folder" src="/icons/Folder.svg" />
              )}
              <span>{row.original.display_name || value}</span>
            </Stack>
          );
        },
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        id: "permission",
        accessor: "permission",
        padding: "checkbox",
        disableSortBy: true,
        Header: (row) => {
          const checked = isPermissionChecked({ type: "ALL_PERMISSION" });
          const indeterminate = isIndeterminate({
            type: "HEAD",
            row,
            allChecked: checked,
          });
          return (
            <Stack
              sx={{
                flexDirection: "row ",
              }}
            >
              <FormControlLabel
                labelPlacement="start"
                disabled
                control={
                  <Checkbox
                    checked={checked}
                    indeterminate={indeterminate}
                    color="primary"
                    sx={checkboxStyle}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 600,
                      lineHeight: "15.73px",
                    }}
                  >
                    Permission
                  </Typography>
                }
              />
            </Stack>
          );
        },
        Cell: ({ row }) => {
          const type =
            row.depth === 0 ? "MODULE_PERMISSION" : "UNIQUE_PERMISSION";
          const checked = isPermissionChecked({
            type,
            row,
          });
          const indeterminate = isIndeterminate({
            type: "PERMISSION_CELL",
            row,
            allChecked: checked,
          });
          return (
            <Stack
              sx={{
                flexDirection: "row ",
              }}
              justifyContent="end"
            >
              <FormControlLabel
                labelPlacement="start"
                disabled
                control={
                  <Checkbox
                    checked={checked}
                    indeterminate={indeterminate}
                    color="primary"
                    sx={checkboxStyle}
                  />
                }
              />
            </Stack>
          );
        },
      },
      {
        id: "manager-passcode",
        accessor: "permission",
        padding: "checkbox",
        disableSortBy: true,
        Header: (row) => {
          const isAllManagerPasscodeChecked = isPermissionChecked({
            type: "ALL_MANAGER_PASSCODE",
          });
          const indeterminate = isIndeterminate({
            type: "MANAGER_PASSCODE_HEAD",
            row,
            allChecked: isAllManagerPasscodeChecked,
          });
          return (
            <Stack
              sx={{
                flexDirection: "row",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={isAllManagerPasscodeChecked}
                    indeterminate={indeterminate}
                    color="primary"
                    sx={checkboxStyle}
                  />
                }
                labelPlacement="start"
                label={
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 600,
                      lineHeight: "15.73px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Manager Passcode
                  </Typography>
                }
              />
            </Stack>
          );
        },
        Cell: ({ row }) => {
          const type =
            row.depth === 0
              ? "MANAGER_PASSCODE_MODULE_PERMISSION"
              : "MANAGER_PASSCODE_UNIQUE_PERMISSION";
          const checked = isPermissionChecked({
            type,
            row,
          });
          const indeterminate = isIndeterminate({
            type: "MANAGER_PASSCODE_CELL",
            row,
            allChecked: checked,
          });
          return (
            <Stack justifyContent="end">
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    disabled
                    checked={checked}
                    indeterminate={indeterminate}
                    color="primary"
                    sx={checkboxStyle}
                  />
                }
              />
            </Stack>
          );
        },
      },
    ],
    [roleDetails.permission_id]
  );

  const tabs = React.useMemo(() => {
    return [
      {
        label: "Permissions",
        value: "1",
        hidden: false,
        panel: (
          <PosPermissionSection
            columns={columns}
            loading={permissions.isLoading}
            permissionList={permissions.allList}
          />
        ),
      },
      {
        label: "Members",
        value: "2",
        hidden: false,
        panel: (
          <MembersList
            state={state}
            tableLoading={isMemberTableLoading}
            tableTitle="POS Users"
            HandleFilterUsers={handleFilterUsers}
            handleUsersSelect={handleUserSelection}
            handleAssignUser={handleAssignUser}
            isSelectedUser={hasSelectedUser}
            handleClickOpen={handleUserDialog}
            handleClose={handleUserDialog}
            HandleStatusFilter={handleStatusFilter}
            handleMemberModal={handleMemberDeleteModal}
            handleDeleteMember={handleUnassignUser}
            resetFilters={resetFilters}
          />
        ),
      },
    ];
  }, [
    permissions,
    roleDetails.permission_id,
    state.open,
    state.selectedUsers,
    state.userSearchText,
    state.filterStatus,
    state.memberDeleteModal,
  ]);

  return roleDetails.isLoading ? (
    <Loader />
  ) : (
    <ContentLayout
      title={roleDetails.details?.display_name ?? ""}
      tabProps={isStoreAdmin ? { label: "generalTab", list: tabs } : undefined}
      breadcrumbs={<HeaderBreadcrumbs links={breadcrumbLinks} />}
      rightContent={
        !isStoreAdmin &&
        isDeletable && (
          <Button
            title="Delete Role"
            variant="text"
            size="small"
            onClick={handleDeleteDialog}
          >
            <img alt="delete" src="/icons/ic_delete_blue.svg" />
          </Button>
        )
      }
    >
      {!isStoreAdmin && (
        <PosPermissionSection
          columns={columns}
          loading={permissions.isLoading}
          permissionList={permissions.allList}
          rightSection={
            isModifiable ? (
              <Button
                title="Edit Permissions"
                variant="contained"
                onClick={() =>
                  navigate(`/roles-and-permissions/pos/details/${roleId}/edit`)
                }
              >
                Edit
              </Button>
            ) : undefined
          }
        />
      )}

      <DeleteModal
        open={isDeleteDialogOpen}
        handleClose={handleDeleteDialog}
        onConfirm={handleConfirmDeleteRole}
      />
    </ContentLayout>
  );
};
