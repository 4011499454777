import React from "react";
import { Route, Routes } from "react-router-dom";
import { ConfigurationsList } from "./containers";

export const Configurations = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<ConfigurationsList />} />
            </Routes>
        </>
    );
};
