import { getUsersInStore, deleteUsersInStore } from "../..";
import { useImmer } from "use-immer";
import { useEffect, useContext } from "react";
import { errorMessage, successMessage } from "../../../utils";
import { useDebounce } from "use-debounce";
import { usePermission } from "../../../shared";
import { AppContext } from "../../../store/AppScopeProvider";
import qs from "qs";

export const useStoreUsers = ({ storeId }) => {
  const { appState } = useContext(AppContext);
  const { globalData } = appState;
  const isDeletable = usePermission("user-delete");
  const isCreateVisible = usePermission("user-create");
  const [state, setState] = useImmer({
    isLoading: false,
    isOpen: false,
    selectedID: null,
    initialLoad: true,
    dataList: [],
    filters: {
      isSearching: false,
      searchText: "",
      status: "",
    },
  });
  const [debouncedText] = useDebounce(state.filters.searchText, 1000);

  useEffect(() => {
    if (storeId) {
      handleGetUsersInStores(storeId);
    }
  }, [storeId]);

  const handleGetUsersInStores = async (
    storeId,
    search = null,
    status = ""
  ) => {
    try {
      setState((draft) => {
        draft.isLoading = true;
        draft.initialLoad = false;
      });
      let params = {};

      if (search) {
        params = { ...params, search: search };
      }
      if (status !== "") {
        params = { ...params, status: status };
      }

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };
      const res = await getUsersInStore(storeId, query);
      if (res.success) {
        setState((draft) => {
          draft.dataList = res.data;
          draft.isLoading = false;
        });
      }
    } catch {
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };
  useEffect(() => {
    if (!state.initialLoad && !state.filters.isSearching) {
      const { status } = state.filters;
      handleGetUsersInStores(storeId, debouncedText, status);
    }
  }, [debouncedText]);

  const handleDeleteUser = async () => {
    try {
      const res = await deleteUsersInStore(state.selectedID, storeId);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.dataList = draft.dataList.filter(
            (item) => item.id !== state.selectedID
          );
        });
        handleModals(false, null);
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleModals = (e, id) => {
    setState((draft) => {
      draft.isOpen = e;
      draft.selectedID = id;
      return draft;
    });
  };

  const onChangeFilter = (e) => {
    const { name, value } = e.target;

    const { searchText } = state.filters;
    if (name === "status") {
      handleGetUsersInStores(storeId, searchText, value);
    }
    setState((draft) => {
      draft.filters[name] = value;
      draft.filters.isSearching = false;
    });
  };

  const resetFilters = () => {
    if ((storeId, toString(state.filters.status) || state.filters.searchText)) {
      handleGetUsersInStores(storeId);
      setState((draft) => {
        draft.filters.status = "";
        draft.filters.searchText = "";
        draft.filters.isSearching = true;
      });
    }
  };

  return {
    state,
    globalData,
    handleDeleteUser,
    handleModals,
    onChangeFilter,
    resetFilters,
    isCreateVisible,
    isDeletable,
  };
};
