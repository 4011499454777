import { useState, useRef } from "react";
import { Box, Button, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ContentLayout, HeaderBreadcrumbs } from "../../../shared";
import { Security, ProfileData } from "../components";
import { useProfile } from "..";
import SimpleReactValidator from "simple-react-validator";


export function ProfileDetails() {
  const [value, setValue] = useState("1");
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const {
    state,
    isStoreAdmin,
    onEdit,
    onUpdateProfile,
    onCancel,
    onChange,
    onDropdown,
    handleDropdown,
    currentTab,
    setCurentTab,
  } = useProfile({
    isProfile: true,
  });

  const handleChange = (event, newValue) => {
    setCurentTab("profile", newValue);
  };

  return (
    <>
      <ContentLayout
        title="Profile"
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: isStoreAdmin ? "/store-admin/" : "/" },
              { name: "Profile" }
            ]}
          />
        }
      >
        <TabContext value={currentTab}>
          <Box className="sticky-Tab" sx={{ borderBottom: 1, borderColor: "divider", top: "104px" }}>
            <TabList onChange={handleChange}>
              <Tab label="General" value="1" />
              <Tab label="Security" value="2" />
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            <ProfileData
              isBusy={state.profileDetails.isBusy}
              state={state}
              onChange={onChange}
              onDropdown={onDropdown}
              handleDropdown={handleDropdown}
              validator={validator}
              setValue={setValue}
              onEdit={onEdit}
              onUpdateProfile={onUpdateProfile}
              forceUpdate={forceUpdate}
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            <Security state={state} />
          </TabPanel>
        </TabContext>
      </ContentLayout>
    </>
  );
}
