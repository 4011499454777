import * as React from "react";
import { Typography, Button } from "@mui/material";
import {
  CustomTable,
  CustomeDialog,
  DeleteModal,
  CategoryProductDialog,
} from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { sortItems } from "../../../utils";
import InputControl from "../../../shared/components/InputControl";
import CloseIcon from "@mui/icons-material/Close";
import { useStoreProducts } from "..";

export const StoreProductsList = (prop) => {
  const { id, setCurentTab } = prop;
  const {
    state,
    DeleteModals,
    handleDeleteProducts,
    handleModals,
    HandleFilterProduct,
    handlePoductSelect,
    handleAssignProducttoStore,
    onChangeFilter,
    resetFilters,
    options,
    isCreateVisible,
    isDeletable: deletable,
    handleSelectAllProducts,
  } = useStoreProducts({
    storeId: id,
  });
  const navigate = useNavigate();
  const ActionButtons = ({ id, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        <Typography
          variant="subtitle2"
          color="#1560D4"
          sx={{
            cursor: "pointer",
          }}
          className="LinkBtn"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setCurentTab("productDetails", "11");
            navigate(`/products/details/${id}`);
          }}
        >
          Price Level
        </Typography>
        {deletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "PLU Code",
        accessor: "plu",
        disableSortBy: true,
      },
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ value }) => {
          return value?.name;
        },
        disableSortBy: true,
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => {
                DeleteModals(true, row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <CustomTable
        columns={columns}
        data={state.productDetails.productList || []}
        isLoading={state?.isBusy}
        defaultSearch={true}
        tableTitle="Products"
        bulkDelete={false}
        headerRightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="text"
                startIcon={<AddIcon />}
                onClick={() => handleModals(true)}
              >
                Add Products
              </Button>
            )}
          </>
        }
        filterContent={
          <>
            {/* <InputControl
              type="table-search"
              name="searchText"
              placeholder="Search"
              size="small"
              value={state.filters.searchText}
              onChange={(e) => onChangeFilter(e)}
            /> */}
            <InputControl
              type="select"
              name="category"
              label="Category"
              optionValue={options}
              width="250px"
              onChange={(e) => onChangeFilter(e)}
              value={state.filters.category}
            />

            <Button
              type="button"
              size="medium"
              variant="text"
              color="gray"
              sx={{
                minWidth: "40px",
                p: "8px",
              }}
              onClick={() => resetFilters()}
            >
              <CloseIcon />
            </Button>
          </>
        }
        onRowLink={(e) => {
          return `/products/details/${e.id}`;
        }}
        onRowAction={() => {
          setCurentTab("productDetails", "1");
        }}
      />
      <CustomeDialog
        open={state.isOpen}
        handleClose={() => handleModals(false)}
        PaperProps={{
          sx: { width: "423px", height: "535px", p: "16px" },
        }}
        title="Products"
        content={
          <CategoryProductDialog
            Items={state.productDetails.filteredPoducts}
            onSelect={handlePoductSelect}
            selectedItems={state.productDetails.selectedPoducts}
            onSubmit={handleAssignProducttoStore}
            onSearch={HandleFilterProduct}
            existedProductList={state.productDetails.productList}
            disabled={state.productDetails.isSaveButtonBusy}
          />
        }
      />
      <DeleteModal
        open={state.isDelete}
        handleClose={() => DeleteModals(false)}
        onConfirm={handleDeleteProducts}
      />
    </>
  );
};
