import { useImmer } from "use-immer";
import { errorMessage, successMessage } from "../../../utils";
import {
  getProductConfigurationDetailsApi,
  getAllUsersApi,
  updateProductConfigurationDetailsApi,
} from "../api";
import React from "react";
// import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import qs from "qs";
import { usePermission } from "../../../shared";
const ratingList = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];

// Util function to formate delay-time
// const formatDelayTime = (time) => moment(time, "HH:mm").format("HH:mm");

export const useProductConfiguration = () => {
  const [, forceUpdate] = React.useState();
  const [state, setState] = useImmer({
    configuration: {
      mail_notification_status: false,
      notification_emails: [],
      auto_publish: false,
      minimum_rating_level: 3,
      delay_time: "00:01",
      customer_service_email: "",
    },
    list: {
      rating: ratingList,
      userList: [],
    },
    inputValue: undefined,
    isSaving: false,
    isFetching: false,
  });
  const isConfigModifiable = usePermission("review-rating-modify");

  const configurationValidator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        notificationEmails: {
          message: "Email is required",
          rule: ({ notificationEmails, status }) => {
            if (notificationEmails.length === 0 && status) return false;
            else return true;
          },
        },
        autoPublish: {
          required: true,
          message: "field is required!",
          rule: (notificationEmails, status) => {
            if (notificationEmails.length === 0 && status) return false;
            return false;
          },
        },
        delayTime: {
          required: false,
          message: "The delay time must be between 0 and 10000 milliseconds",
          rule: (delayTime) => {
            if (delayTime === null || (delayTime >= 0 && delayTime <= 10000))
              return true;
            return false;
          },
        },
      },
    })
  );

  // Util function to trigger loading
  const triggerSaveButtonLoading = () => {
    setState((draft) => {
      draft.isSaving = !draft.isSaving;
    });
  };

  // Util function to loading
  const triggerFetching = () => {
    setState((draft) => {
      draft.isFetching = !draft.isFetching;
    });
  };

  // Util function to handle switch on/off
  const handleSwitchChange = (event, name) => {
    const value = event.target.checked;
    handleConfigurationChanges({
      event: {
        target: {
          name,
          value,
        },
      },
      value,
    });
    configurationValidator.current.hideMessages();
  };

  // Util function to handle form-changes
  const handleConfigurationChanges = ({ event, value }) => {
    const { name } = event.target;
    setState((draft) => {
      draft.configuration[name] = value;
    });
  };

  // Function to fetch configuration details
  const getProductConfiguration = async () => {
    try {
      const response = await getProductConfigurationDetailsApi();
      const { success, message, data } = response;
      // Trigger loading
      triggerFetching();

      if (success) {
        setState((draft) => {
          const {
            auto_publish,
            customer_service_email,
            minimum_rating_level,
            notification_emails,
            mail_notification_status,
            delay_time,
          } = data;
          if (auto_publish !== null) {
            draft.configuration.auto_publish = auto_publish;
          }
          if (mail_notification_status !== null) {
            draft.configuration.mail_notification_status =
              mail_notification_status;
          }
          if (customer_service_email !== null) {
            draft.configuration.customer_service_email = customer_service_email;
          }
          if (minimum_rating_level !== null) {
            draft.configuration.minimum_rating_level = minimum_rating_level;
          }
          if (notification_emails !== null) {
            draft.configuration.notification_emails = notification_emails.emails
              .map((email) => {
                return { id: null, email };
              })
              .concat(notification_emails.user_ids);
          }

          draft.configuration.delay_time = delay_time;
        });
      } else {
        errorMessage(message);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      // Trigger loading
      triggerFetching();
    }
  };

  // Function to update configuration details
  const updateProductConfiguration = async (params) => {
    try {
      const response = await updateProductConfigurationDetailsApi(params);
      const { success, message } = response;
      // trigger save-button loading
      triggerSaveButtonLoading();

      if (success) {
        successMessage(message);
      } else {
        errorMessage(message);
      }
    } catch (error) {
      // trigger save-button loading
      triggerSaveButtonLoading();
      errorMessage(error.response.data.message);
    }
  };

  // Function to fetch user list
  const getAllUsers = async ({ search = null }) => {
    let params = {};
    if (search) {
      params = { ...params, search };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      const response = await getAllUsersApi(query);
      const { success, data } = response;
      if (success) {
        // Formatted list
        const formattedList = data.map((user) => {
          return {
            value: user.id,
            label: user.name,
            email: user.email,
          };
        });

        // Selected ids
        const selectedList = state.configuration.notification_emails.map(
          (user) => user.id
        );

        // Filter selected items from main list
        const filteredData = formattedList.filter(
          (user) => !selectedList.includes(user.value)
        );

        const options = filteredData.map((user) => {
          return {
            value: user.value,
            id: user.value,
            name: user.label,
            email: user.email,
          };
        });

        setState((draft) => {
          draft.list.userList = formattedList;
        });
        return {
          options,
        };
      } else {
        return {
          options: [],
        };
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      return {
        options: [],
      };
    }
  };

  const handleInputChange = (event) => {
    setState((draft) => {
      draft.configuration.notification_emails = event;
    });
    clearInputValue();
  };

  const onInputChange = (inputValue, { action }) => {
    if (action === "input-change") {
      setState((draft) => {
        draft.inputValue = inputValue;
      });
    }
  };

  const clearInputValue = () => {
    setState((draft) => {
      draft.inputValue = "";
    });
    configurationValidator.current.hideMessageFor("email");
  };

  // Util function to handle configuration save
  const handleSaveConfiguration = async () => {
    const formateNotificationEmail = (configEmails) => {
      const emails = [];
      const ids = [];
      configEmails.forEach((obj) => {
        if (obj?.id) ids.push(obj.id);
        else emails.push(obj.email);
      });
      const response = {
        emails,
        user_ids: ids,
      };
      return response;
    };
    if (configurationValidator.current.allValid()) {
      const formattedNotificationEmails = formateNotificationEmail(
        state.configuration.notification_emails
      );
      // Trigger loading
      triggerSaveButtonLoading();

      await updateProductConfiguration({
        ...state.configuration,
        emails: formattedNotificationEmails.emails,
        user_id: formattedNotificationEmails.user_ids,
      });
    } else {
      configurationValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  // Util function to add new email
  const handleAddNewEmail = () => {
    if (configurationValidator.current.fieldValid("email")) {
      setState((draft) => {
        draft.configuration.notification_emails = [
          ...draft.configuration.notification_emails,
          { id: null, email: draft.inputValue },
        ];
      });
      clearInputValue();
      configurationValidator.current.hideMessageFor("email");
    } else {
      configurationValidator.current.showMessageFor("email");
      forceUpdate(1);
    }
  };

  // Util function to delete email
  const handleDeleteEmail = (data) => {
    setState((draft) => {
      draft.configuration.notification_emails =
        draft.configuration.notification_emails.filter(
          (user) => user.id !== data.id && user.id !== null
        );
    });
  };

  // useEffect to fetch configuration details
  React.useEffect(() => {
    triggerFetching();
    getProductConfiguration();
  }, []);
  return {
    state,
    isConfigModifiable,
    getAllUsers,
    handleSwitchChange,
    handleInputChange,
    onInputChange,
    clearInputValue,
    configurationValidator,
    handleSaveConfiguration,
    handleConfigurationChanges,
    handleAddNewEmail,
    handleDeleteEmail,
  };
};
