import {
  ContentLayout,
  CustomTable,
  DeleteModal,
  HeaderBreadcrumbs,
} from "../../../shared";
import * as React from "react";
import { Button, Chip, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { sortItems } from "../../../utils";
import { useSurcharges } from "../hooks/useSurcharges";
import InputControl from "../../../shared/components/InputControl";

export const SurchargesList = () => {
  const navigate = useNavigate();
  const {
    state,
    isEditable,
    isDeletable,
    isCreateVisible,
    taxState,
    onChangeFilter,
    handleModal,
    handleDeleteSurCharges,
  } = useSurcharges({
    load: true,
    isList: false,
  });

  const ActionButtons = ({ id, deleteAction }) => {
    return (
      <div className="action-buttons">
        {isEditable && (
          <Link to={`edit/${id}`}>
            <img alt="edit" src="/icons/ic_edit.svg" />
          </Link>
        )}
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
      </div>
    );
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Charge",
        accessor: "amount",
        Cell: ({ value }) => {
          return "$" + value.toFixed(2);
        },
        disableGlobalFilter: false,
      },
      {
        Header: "Sale Type",
        accessor: "sale_types",
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <Stack gap="4px" direction="row">
              {value?.map((data) => {
                return <Chip label={data.name} variant="filled" size="small" />;
              })}
            </Stack>
          );
        },
      },
      {
        Header: "Tax Group",
        accessor: "tax_group_name",
        disableGlobalFilter: false,
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => handleModal(true, row.id)}
            />
          );
        },
      },
    ],
    [taxState]
  );
  return (
    <>
      <ContentLayout
        title="Surcharges"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("/surcharges/create")}
              >
                New Group
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          isLoading={state?.isBusy}
          data={state.surchargesList}
          defaultSearch={true}
          tableId="surcharges"
          // filterContent={
          //   <>
          //     <InputControl
          //       type="table-search"
          //       name="searchText"
          //       placeholder="Search"
          //       size="small"
          //       value={state.filters.searchText}
          //       onChange={(e) => onChangeFilter(e)}
          //       onClear={() =>
          //         onChangeFilter({
          //           target: {
          //             name: "searchText",
          //             value: "",
          //           },
          //         })
          //       }
          //     />
          //   </>
          // }
        />
        <DeleteModal
          open={state.deleteModalOpen}
          handleClose={() => handleModal(false, null)}
          onConfirm={handleDeleteSurCharges}
        />
      </ContentLayout>
    </>
  );
};
