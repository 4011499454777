import { Box, Link, Stack, Typography, Chip, Avatar, Tab } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { TabContext, TabPanel } from "@mui/lab";
import React from "react";
import { useContentLayout } from "../hooks/useContentLayout";
import TabListComponent from "./TabListComponent";

export const ContentLayout = ({
  children,
  title,
  backText,
  backImage = false,
  active = false,
  rightContent = null,
  breadcrumbs = null,
  backButtonLocation = -1,
  chipType = "status",
  label,
  labelColor,
  bgColor,
  statusContent = null,
  tabProps = { label: "", list: null },
  topContent = null,
}) => {
  const navigate = useNavigate();

  // Custom hook for handle tab component
  const { currentTabValue, currentTab } = useContentLayout({ tabProps });

  return (
    <TabContext value={currentTabValue}>
      <Box>
        {/* Header */}
        <Stack
          sx={{
            gap: "20px",
            position: "sticky",
            top: "0",
            bgcolor: "#fff",
            zIndex: 1101,
            p: "32px 32px 16px",
            pb: currentTab ? "0" : "16px",
            borderBottom: "1px solid #e6e6e6",
          }}
        >
          {backText && (
            <Box>
              <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={() => {
                  navigate(backButtonLocation);
                }}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                  height: "20px",
                  color: "#000000",
                  fontWeight: "500",
                  // mb: "20px",
                  "&:hover": {
                    color: "#0B57D0",
                  },
                }}
              >
                <ArrowBackIcon sx={{ height: "20px" }} />
                {backText}
              </Link>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: "4px",
                // height: "40px",
              }}
            >
              {backImage && (
                <Box
                  height={"80px"}
                  width={"80px"}
                  sx={{
                    mr: "20px",
                    borderRadius: "12px",
                  }}
                >
                  {typeof backImage === "string" ? (
                    <Avatar
                      sx={{
                        height: "80px",
                        width: "80px",
                        backgroundColor: "transparent",
                      }}
                      variant="rounded"
                      src={backImage}
                    />
                  ) : (
                    backImage
                  )}
                </Box>
              )}
              <Stack gap="10px">
                <Stack
                  direction="row"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    mr={1}
                    sx={{
                      lineHeight: "30px",
                    }}
                  >
                    {title}
                  </Typography>
                  {chipType === "status" ? (
                    active && (
                      <Chip
                        label={active}
                        color={"success"}
                        variant="outlined"
                        size="small"
                        disabled={active === "Active" ? false : "filled"}
                      />
                    )
                  ) : chipType === "cardStatus" ? (
                    <Chip
                      label={label}
                      color={labelColor}
                      variant={"outlined"}
                      size="small"
                      sx={{ bgcolor: bgColor, border: "none !important" }}
                      disabled={false}
                    />
                  ) : chipType === "salesStatus" ? (
                    <Chip
                      label={label}
                      color={labelColor}
                      variant={"outlined"}
                      size="small"
                    />
                  ) : chipType === "other" ? (
                    statusContent
                  ) : null}
                </Stack>

                {breadcrumbs}
              </Stack>
            </Stack>
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: "24px",
                height: "40px",
              }}
            >
              {rightContent}
              {/* {topContent} */}
            </Stack>
          </Box>
          {topContent}
          {/* Tab */}
          {currentTab && <TabListComponent {...tabProps} />}
        </Stack>

        {/* Body */}
        <Box
          sx={{
            p: "16px 24px !important",
          }}
        >
          {currentTab && (
            <TabPanel sx={{ p: "0 !important" }} value={currentTab.value}>
              {currentTab.panel}
            </TabPanel>
          )}
          {children}
        </Box>
      </Box>
    </TabContext>
  );
};
