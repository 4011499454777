import { useImmer } from "use-immer";
import { getQrCodeDetailsApi } from "../apis/qrCodeApis";
import { errorMessage } from "../../../utils";
import React from "react";
import { useLocation } from "react-router-dom";

export const useQrCodeDetails = () => {
  const { search } = useLocation();
  const [state, setState] = useImmer({
    isLoading: false,
    formData: {
      remarks: "",
      location: "",
      store_name: "",
      qrcode_image: "",
    },
  });

  /**
   * Fetches the details of a QR code using its unique ID and updates the state with the retrieved data.
   *
   * @async
   * @function getQrCodeDetails
   * @param {number} qrCodeId - The unique identifier of the QR code to fetch details for.
   * @throws {Object} Throws an error object containing a message if the API call fails or if the response indicates an error.
   *
   * @returns {Promise<void>} A promise that resolves when the function completes. It does not return any value.
   */
  const getQrCodeDetails = async (qrCodeId) => {
    triggerListLoading(true);
    try {
      const response = await getQrCodeDetailsApi(qrCodeId);
      const { success, message, data } = response;
      if (success) {
        setState((draft) => {
          draft.formData = data;
        });
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message = error.response?.data?.message;
      errorMessage(message);
    } finally {
      triggerListLoading(false);
    }
  };

  // Triggers loading state for the list by updating the isLoading flag in the state
  const triggerListLoading = (status) => {
    setState((draft) => {
      draft.isLoading = status;
    });
  };

  // Effect to fetch QR code details when the search query changes
  React.useEffect(() => {
    if (search) {
      const qrCode = search.split("?")[1];
      const qrCodeId = parseInt(qrCode);
      getQrCodeDetails(qrCodeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return { state };
};
