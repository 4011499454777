/* eslint-disable no-throw-literal */
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { errorMessage, successMessage } from "../../../utils";
import {
  createNewPosRoleApi,
  getAllPosPermissionListApi,
  getPosRoleDetailsByIdApi,
  updatePosRoleApi,
} from "../..";
import { useImmer } from "use-immer";

export const useCreateOrEditPosRoles = () => {
  const { roleId } = useParams();
  const navigate = useNavigate();
  const [, forceUpdate] = React.useState();
  const { pathname } = useLocation();
  const updateMode = roleId && pathname.split("/")[5] === "edit" ? true : false;

  const [state, setState] = useImmer({
    permissions: {
      allList: [],
      isLoading: true,
    },
    formData: {
      name: "",
      permission_id: [],
    },
    roleDetails: {},
    isSubmitButtonLoading: false,
  });

  const formValidator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const breadcrumbsLinks = [
    { name: "Dashboard", href: "/" },
    {
      name: "Roles & Permissions",
      href: "/roles-and-permissions",
    },
    updateMode
      ? {
          name: state.roleDetails?.display_name,
          href: `/roles-and-permissions/pos/details/${roleId}`,
        }
      : null,
    { name: updateMode ? "Edit" : "Create" },
  ].filter(Boolean);

  // ========================================================================= API SECTION ================================================================================
  /**
   * Fetches the list of all POS permissions and updates the application state.
   *
   * This function triggers loading indicators while fetching the data and handles
   * success and error scenarios. On success, it updates the state with the fetched
   * permissions list. In case of an error, it displays an error message.
   *
   * @async
   * @function getAllPosPermissionsList
   * @returns {Promise<void>} A promise that resolves when the fetching operation is complete.
   *
   * @throws {Object} Throws an error object if the API call fails. The error object contains
   *                  a response property with a message.
   */
  const getAllPosPermissionsList = async () => {
    triggerPermissionListLoading(true);
    try {
      const response = await getAllPosPermissionListApi();
      const { success, message, data } = response;
      if (success && data) {
        setState((draft) => {
          draft.permissions.allList = data;
        });
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message =
        error.response?.data?.message ?? "Unable to fetch POS permission list";
      errorMessage(message);
    } finally {
      triggerPermissionListLoading(false);
    }
  };

  /**
   * Creates a new POS role and navigates to the roles and permissions page on success.
   *
   * @async
   * @function createNewPosRole
   * @param {Object} payload - The data for the new POS role.
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   *
   * @throws {Object} Throws an error object if the API call fails, containing a response
   *                  property with a message.
   */
  const createNewPosRole = async (payload) => {
    triggerSubmitButtonLoading(true);
    try {
      const response = await createNewPosRoleApi(payload);
      const { success, message } = response;
      if (success) {
        successMessage(message);
        navigate("/roles-and-permissions");
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message = error.response?.data?.message;
      errorMessage(message);
    } finally {
      triggerSubmitButtonLoading(false);
    }
  };

  /**
   * Updates an existing POS role with the provided data and navigates to the role details page on success.
   *
   * @async
   * @function updatePosRole
   * @param {Object} payload - The updated data for the POS role.
   * @param {string} roleId - The ID of the role to update.
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   *
   * @throws {Object} Throws an error object if the API call fails, containing a response
   *                  property with a message.
   */
  const updatePosRole = async ({ roleId, ...payload }) => {
    triggerSubmitButtonLoading(true);
    try {
      const response = await updatePosRoleApi(roleId, payload);
      const { success, message } = response;
      if (success) {
        successMessage(message);
        navigate(`/roles-and-permissions/pos/details/${roleId}`, {
          replace: true,
        });
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message = error.response?.data?.message;
      errorMessage(message);
    } finally {
      triggerSubmitButtonLoading(false);
    }
  };

  /**
   * Fetches the details of a specific POS role by its ID and updates the state accordingly.
   *
   * @async
   * @function getPosRoleDetails
   * @param {string} roleId - The ID of the role to fetch details for.
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   *
   * @throws {Object} Throws an error object if the API call fails, containing a response
   *                  property with a message.
   */
  const getPosRoleDetails = async (roleId) => {
    triggerPermissionListLoading(true);
    try {
      const response = await getPosRoleDetailsByIdApi(roleId);
      const { success, message, data } = response;
      if (success) {
        const { permissions, ...details } = data;
        setState((draft) => {
          draft.roleDetails = details;
          draft.formData.name = details.display_name;
          draft.formData.permission_id = permissions.map((permission) => {
            return {
              ...permission,
              [permission.id]: Boolean(permission.manager_passcode),
            };
          });
        });
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message = error.response?.data?.message;
      errorMessage(message);
    } finally {
      triggerPermissionListLoading(false);
    }
  };

  // ========================================================================= UTIL FUNCTIONS SECTION =====================================================================
  /**
   * Toggles the loading state of the submit button.
   *
   * @function triggerSubmitButtonLoading
   * @param {boolean} status - The loading status to set (true for loading, false for not loading).
   * @returns {void}
   */
  const triggerSubmitButtonLoading = (status) => {
    setState((draft) => {
      draft.isSubmitButtonLoading = status;
    });
  };

  /**
   * Toggles the loading state of the permission list.
   *
   * @function triggerPermissionListLoading
   * @param {boolean} status - The loading status to set (true for loading, false for not loading).
   * @returns {void}
   */
  const triggerPermissionListLoading = (status) => {
    setState((draft) => {
      draft.permissions.isLoading = status;
    });
  };

  /**
   * Handles form input changes and updates the state with the new value.
   *
   * @function handleFormChange
   * @param {Event} event - The change event from the form input.
   * @returns {void}
   */
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setState((draft) => {
      draft.formData[name] = value;
    });
  };

  /**
   * Checks the status of permissions based on the provided type and row data.
   *
   * This function determines if specific permissions are checked based on different
   * criteria such as all permissions, module permissions, and manager passcode.
   *
   * @function isPermissionChecked
   * @param {Object} options - Options to determine the permission check status.
   * @param {string} [options.type="UNIQUE_PERMISSION"] - The type of permission check to perform.
   * @param {Object|null} [options.row=null] - The row data containing module or permission information.
   * @returns {boolean} True if the specified permissions are checked, false otherwise.
   */
  const isPermissionChecked = ({ type = "UNIQUE_PERMISSION", row = null }) => {
    switch (type) {
      case "ALL_PERMISSION": {
        const { permission_id } = state.formData;
        const permissions = state.permissions.allList.flatMap((module) =>
          module.permissions.map((permission) => permission)
        );
        return permission_id.length === permissions.length;
      }
      case "MODULE_PERMISSION": {
        const module = row.original;
        const { permission_id } = state.formData;

        const checkedModulePermissions = permission_id.filter(
          (permission) => permission.group === module.name
        );

        return checkedModulePermissions.length === module.permissions.length;
      }
      case "ALL_MANAGER_PASSCODE": {
        const { permission_id } = state.formData;

        if (permission_id.length === 0) return false;

        const hasUnchecked = permission_id.some((permission) => {
          return permission[permission.id] === false;
        });

        if (hasUnchecked) {
          return false;
        }

        const allCheckedPermissions = permission_id.filter((permission) => {
          return permission[permission.id] !== false;
        });

        return allCheckedPermissions.length === permission_id.length;
      }
      case "MANAGER_PASSCODE_MODULE_PERMISSION": {
        const module = row.original;
        const { permission_id } = state.formData;

        if (permission_id.length === 0) return false;

        const modulePermissions = permission_id.filter(
          (permission) => permission.group === module.name
        );

        const hasUnchecked = modulePermissions.some((permission) => {
          return permission[permission.id] === false;
        });

        if (hasUnchecked) {
          return false;
        }

        const allCheckedPermissions = modulePermissions.filter((permission) => {
          return permission[permission.id] !== false;
        });

        return allCheckedPermissions.length === module.permissions.length;
      }
      case "MANAGER_PASSCODE_UNIQUE_PERMISSION": {
        const module = row.original;
        const { permission_id } = state.formData;
        const checked = permission_id.some(
          (permission) => permission[module.id] === true
        );
        return checked;
      }
      default: {
        const module = row.original;
        const { permission_id } = state.formData;
        const checked = permission_id.some(
          (permission) => permission.id === module.id
        );
        return checked;
      }
    }
  };

  /**
   * Determines if a permission checkbox is in an indeterminate state based on the provided criteria.
   *
   * The function checks various conditions to determine if the checkbox should be marked as
   * indeterminate, which typically indicates that some, but not all, of the child checkboxes are checked.
   *
   * @function isIndeterminate
   * @param {Object} options - Options to determine the indeterminate state.
   * @param {boolean} options.allChecked - Indicates if all permissions are checked.
   * @param {Object} row - The current row data containing permission and module information.
   * @param {string} [options.type="CELL"] - The type of indeterminate check to perform.
   * @returns {boolean} True if the checkbox is indeterminate, false otherwise.
   */
  const isIndeterminate = ({ allChecked, row, type = "CELL" }) => {
    switch (type) {
      case "HEAD": {
        const { permission_id } = state.formData;
        const indeterminate = !allChecked && permission_id.length !== 0;
        return indeterminate;
      }
      case "MANAGER_PASSCODE_HEAD": {
        const { permission_id } = state.formData;
        const checkedPermissions = permission_id.filter(
          (permission) => permission[permission.id] === true
        );
        const indeterminate = !allChecked && checkedPermissions.length !== 0;
        return indeterminate;
      }
      case "MANAGER_PASSCODE_CELL": {
        const isModuleLevel = row.depth === 0 ? true : false;

        if (isModuleLevel) {
          const module = row.original;
          const { permission_id } = state.formData;

          const modulePermissions = permission_id.filter(
            (permission) => permission.group === module.name
          );
          const checkedPermissions = modulePermissions.filter(
            (permission) => permission[permission.id] === true
          );

          const indeterminate =
            !allChecked &&
            checkedPermissions.length !== 0 &&
            checkedPermissions.length !== modulePermissions.length;
          return indeterminate;
        }

        return isModuleLevel;
      }
      default: {
        const isModuleLevel = row.depth === 0 ? true : false;

        if (isModuleLevel) {
          const module = row.original;
          const { permission_id } = state.formData;

          if (permission_id.length === 0) return false;

          const modulePermissions = permission_id.filter(
            (permission) => permission.group === module.name
          );
          const indeterminate =
            !allChecked &&
            modulePermissions.length > 0 &&
            row.originalSubRows.length > modulePermissions.length;

          return indeterminate;
        }

        return isModuleLevel;
      }
    }
  };

  /**
   * Handles the checkbox state change for permissions, updating the form data accordingly.
   *
   * This function updates the permission state based on the checkbox status and the type of
   * permission being modified (e.g., head, module, or individual permission). It modifies the
   * `formData` state to reflect the current permissions.
   *
   * @function handleCheckPermission
   * @param {Object} options - Options for handling the checkbox state change.
   * @param {Event} options.event - The event triggered by the checkbox state change.
   * @param {Object} row - The current row data containing permission and module information.
   * @param {string} [options.type="CELL"] - The type of permission being modified.
   * @returns {void}
   */
  const handleCheckPermission = ({ event, row, type = "CELL" }) => {
    const { checked } = event.target;
    const { allList } = state.permissions;

    const updateFormData = ({ key, value }) => {
      setState((draft) => {
        draft.formData[key] = value;
      });
    };

    switch (type) {
      case "HEAD": {
        if (checked) {
          const { permission_id } = state.formData;
          const permissionIds = allList.flatMap((module) =>
            module.permissions.map((permission) => {
              const prevPermission = permission_id.find(
                (item) => item.id === permission.id
              );

              if (prevPermission) {
                return prevPermission;
              }

              return {
                [permission.id]: Boolean(permission.manager_passcode),
                ...permission,
              };
            })
          );
          updateFormData({ key: "permission_id", value: permissionIds });
        } else {
          updateFormData({ key: "permission_id", value: [] });
        }
        break;
      }
      case "MANAGER_PASSCODE_HEAD": {
        const { permission_id } = state.formData;

        const permissionIds = permission_id.map((permission) => {
          return {
            ...permission,
            [permission.id]: checked,
            manager_passcode: checked,
          };
        });
        updateFormData({ key: "permission_id", value: permissionIds });
        break;
      }
      case "MANAGER_PASSCODE_CELL": {
        const { checked } = event.target;
        const isModuleLevel = row.depth === 0 ? true : false;
        const { permission_id } = state.formData;

        if (isModuleLevel) {
          const module = row.original;
          const updatedPermissions = permission_id.map((permission) => {
            if (
              module.permissions.find(
                (modulePermission) => modulePermission.id === permission.id
              )
            ) {
              return {
                ...permission,
                [permission.id]: checked,
                manager_passcode: checked,
              };
            } else {
              return permission;
            }
          });

          updateFormData({
            key: "permission_id",
            value: updatedPermissions,
          });
        } else {
          const rowDetails = row.original;
          updateFormData({
            key: "permission_id",
            value: permission_id.map((permission) => {
              if (permission.id === rowDetails.id) {
                return {
                  ...permission,
                  [permission.id]: checked,
                  manager_passcode: checked,
                };
              } else {
                return permission;
              }
            }),
          });
        }
        break;
      }
      default: {
        const { checked } = event.target;
        const isModuleLevel = row.depth === 0 ? true : false;
        const { permission_id } = state.formData;
        const permissionIds = permission_id.map((permission) => permission.id);

        if (checked) {
          if (isModuleLevel) {
            updateFormData({
              key: "permission_id",
              value: permission_id.concat(
                row.originalSubRows
                  .map((subRow) => {
                    return permissionIds.includes(subRow.id)
                      ? null
                      : {
                          [subRow.id]: Boolean(subRow.manager_passcode),
                          ...subRow,
                        };
                  })
                  .filter(Boolean)
              ),
            });
          } else {
            const rowDetails = row.original;
            updateFormData({
              key: "permission_id",
              value: permission_id.concat([
                {
                  [rowDetails.id]: Boolean(rowDetails.manager_passcode),
                  ...rowDetails,
                },
              ]),
            });
          }
        } else {
          if (isModuleLevel) {
            updateFormData({
              key: "permission_id",
              value: permission_id.filter(
                (permission) =>
                  !row.originalSubRows
                    .map((item) => item.id)
                    .includes(permission.id)
              ),
            });
          } else {
            const rowDetails = row.original;
            updateFormData({
              key: "permission_id",
              value: permission_id.filter(
                (permission) => permission.id !== rowDetails.id
              ),
            });
          }
        }
        break;
      }
    }
  };

  /**
   * Handles the submission of the form, validating input and managing permissions.
   *
   * This function checks if the form is valid and if at least one permission is selected.
   * If valid, it either updates an existing POS role or creates a new one based on the
   * current mode (update or create). It also displays error messages if validation fails.
   *
   * @async
   * @function handleFormSubmit
   * @returns {Promise<void>} A promise that resolves when the submission process is complete.
   */
  const handleFormSubmit = async () => {
    if (formValidator.current.allValid()) {
      if (state.formData.permission_id.length === 0) {
        errorMessage("At least one permission must be selected");
      } else {
        const permissions = {};
        state.formData.permission_id.forEach((permission) => {
          permissions[permission.id] = permission[permission.id];
        });

        updateMode
          ? await updatePosRole({
              ...state.formData,
              permission_id: permissions,
              roleId,
            })
          : await createNewPosRole({
              ...state.formData,
              permission_id: permissions,
            });
      }
    } else {
      formValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  // ========================================================================= USE EFFECT SECTION =====================================================================

  React.useEffect(() => {
    getAllPosPermissionsList();

    if (updateMode) {
      getPosRoleDetails(roleId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    state,
    updateMode,
    formValidator,
    isIndeterminate,
    handleFormChange,
    handleFormSubmit,
    breadcrumbsLinks,
    isPermissionChecked,
    handleCheckPermission,
  };
};
