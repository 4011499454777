import {
  ContentLayout,
  DeleteModal,
  HeaderBreadcrumbs,
  Loader,
  PageNotFound,
} from "../../../shared";
import * as React from "react";
import { Button, Box, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  BasicDetailsList,
  AddressList,
  FavouriteProductsList,
  PurchasedProductsList,
  TransactionsList,
  ClientAccount,
  CustomersLoyaltyCards,
} from "..";
import { useCustomers } from "../hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomerReviewList } from "../components";

export const CustomersDetailsList = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    state,
    toggleModal,
    handleDeleteCustomer,
    sendEmailVerification,
    sendPhoneVerification,
    handleResetModal,
    handleResetPassword,
    handlePasswordCopy,
    isEditable,
    isDeletable,
    isReviewsViewable,
    hasResetPassword,
    globalData,
    currentTab,
    setCurentTab,
    handleModal,
    handleDeleteClientAccount,
    handleClientAccountStorsSelect,
    handleAssignStorestoClientAccount,
    handleAllSelect,
    isEditableCA,
    isDeletableCA,
    isCreateVisibleCA,
    handleOtpInput,
    handleVerifyEmailOrPhone,
    handleForceVerify,
  } = useCustomers({
    load: false,
    id: id,
  });
  const handleChange = (event, newValue) => {
    setCurentTab("customers", newValue);

    // Remove product-review search param
    if (location.search === "?product-reviews") {
      navigate(location.pathname, { replace: true });
    }
  };

  const { data, httpStatusCodes } = state;

  if (httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }

  return (
    <>
      <>
        <ContentLayout
          title={data.name}
          active={data?.status === 1 ? "Active" : "Inactive"}
          breadcrumbs={
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Customers", href: "/customers" },
                { name: data.name || "" },
              ]}
            />
          }
          rightContent={
            <>
              {isDeletable && (
                <Button
                  type="button"
                  size="medium"
                  variant="text"
                  className="delte"
                  sx={{
                    minWidth: "40px",
                    p: "8px",
                  }}
                  onClick={() => toggleModal(id, "detaials")}
                >
                  <img alt="delete" src="/icons/ic_delete_blue.svg" />
                </Button>
              )}
            </>
          }
        >
          <TabContext value={currentTab}>
            <Box
              className="sticky-Tab"
              sx={{ borderBottom: 1, borderColor: "divider", top: "104px" }}
            >
              <TabList onChange={handleChange}>
                <Tab label="Basic Details" value="1" />
                <Tab label="Address" value="2" />
                <Tab label="Transactions" value="3" />
                <Tab label="Favourite Products" value="4" />
                <Tab label="Purchased Products" value="5" />
                <Tab label="Client Account" value="6" />
                <Tab label="Loyalty Cards" value="7" />
                {isReviewsViewable && <Tab label="Product Reviews" value="8" />}
              </TabList>
            </Box>
            <NoPaddingTabPanel value="1">
              {state.isDetailsFetching ? (
                <Loader />
              ) : (
                <BasicDetailsList
                  state={state}
                  globalData={globalData}
                  id={id}
                  toggleModal={toggleModal}
                  handleDeleteCustomer={handleDeleteCustomer}
                  sendEmailVerification={sendEmailVerification}
                  sendPhoneVerification={sendPhoneVerification}
                  handleResetModal={handleResetModal}
                  handleResetPassword={handleResetPassword}
                  handlePasswordCopy={handlePasswordCopy}
                  isEditable={isEditable}
                  hasResetPassword={hasResetPassword}
                  handleModal={handleModal}
                  handleOtpInput={handleOtpInput}
                  handleVerifyEmailOrPhone={handleVerifyEmailOrPhone}
                  handleForceVerify={handleForceVerify}
                />
              )}
            </NoPaddingTabPanel>
            <NoPaddingTabPanel value="2">
              {state.isDetailsFetching ? (
                <Loader />
              ) : (
                <AddressList state={state} />
              )}
            </NoPaddingTabPanel>
            <NoPaddingTabPanel value="3">
              <TransactionsList />
            </NoPaddingTabPanel>
            <NoPaddingTabPanel value="4">
              {state.isDetailsFetching ? (
                <Loader />
              ) : (
                <FavouriteProductsList state={state} />
              )}
            </NoPaddingTabPanel>
            <NoPaddingTabPanel value="5">
              <PurchasedProductsList />
            </NoPaddingTabPanel>
            <NoPaddingTabPanel value="6">
              <ClientAccount
                state={state}
                id={id}
                handleModal={handleModal}
                handleDeleteClientAccount={handleDeleteClientAccount}
                handleClientAccountStorsSelect={handleClientAccountStorsSelect}
                handleAllSelect={handleAllSelect}
                isEditableCA={isEditableCA}
                isDeletableCA={isDeletableCA}
                isCreateVisibleCA={isCreateVisibleCA}
              />
            </NoPaddingTabPanel>
            <NoPaddingTabPanel value="7">
              <CustomersLoyaltyCards />
            </NoPaddingTabPanel>
            {isReviewsViewable && (
              <NoPaddingTabPanel value="8">
                <CustomerReviewList />
              </NoPaddingTabPanel>
            )}
          </TabContext>
        </ContentLayout>
      </>
      <DeleteModal
        open={state.isOpenDetails}
        handleClose={() => toggleModal(null, "details")}
        onConfirm={() => {
          handleDeleteCustomer("details");
        }}
      />
    </>
  );
};

const NoPaddingTabPanel = styled(TabPanel)(() => ({
  paddingInline: "0",
}));
