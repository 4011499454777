import { API } from "../../../utils";

export const getClientAccountsList = async (query) => {
  const res = await API.get(`client-accounts`, query);
  return res.data;
};
export const assignCustomersToClinetAccount = async (id, payload) => {
  const res = await API.post(
    `client-accounts/stores/${id}/add-customer`,
    payload
  );
  return res.data;
};

export const createClinetAccountSettlement = async (id, payload) => {
  const res = await API.post(`client-accounts/${id}/make-settlement`, payload);
  return res.data;
};
export const createClientAccount = async (payload) => {
  const res = await API.post(`client-accounts`, payload);
  return res.data;
};
export const getPurchasedData = async (id) => {
  const res = await API.get(`client-accounts/${id}/purchase-list`);
  return res.data;
};
