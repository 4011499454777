import {
  ContentLayout,
  CustomDetailsList,
  Loader,
  CustomTable,
  CustomeDialog,
  ServerPaginatedTable,
  HeaderBreadcrumbs,
} from "../../../shared";
import * as React from "react";
import {
  Button,
  Box,
  Card,
  Typography,
  Stack,
  Switch,
  Chip,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ShareGiftCard } from "../components";
import { GiftCardItem } from "../components";
import { useGiftCard } from "../hooks/useGiftCard";
import { sortItems } from "../../../utils";
import moment from "moment";

export const AllCardDetailsList = () => {
  const { id } = useParams();
  const {
    state,
    handleInputChangeStatus,
    handleShareGiftCards,
    onEmailChange,
    toggleModal,
    handleDownloadSingleCards,
    globalData,
    handletransactionSort,
    settransactionPageSize,
    gototransactionPage,
  } = useGiftCard({ isAllCards: false, cardDetailsId: id });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isAllCards = pathname.includes("allCards");
  const { cardDetails, cardtransactionDetails } = state;
  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ value }) => {
          return (
            <Typography variant="body2">
              {moment(value).format("YYYY-MM-DD")}
            </Typography>
          );
        },
      },
      {
        Header: "Transaction ID",
        accessor: "transaction_number",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <div
                className="table-taxt-and-icon-link cursor"
                onClick={() => {
                  row.original.type === "Sale"
                    ? navigate(`/orders/details/${row.original.transaction_id}`)
                    : navigate(
                        `/refund/details/${row.original.transaction_id}`
                      );
                }}
              >
                <span className="link-text">
                  {row.original.transaction_number}
                </span>
              </div>
            </Stack>
          );
        },
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ value }) => {
          return (
            <Chip
              label={value}
              color={value === "Sale" ? "success" : "error"}
              variant="outlined"
              size="small"
              sx={{ border: "none !important" }}
              disabled={false}
            />
          );
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <Stack direction="row">
                <Typography
                  color={row.original.type === "Sale" ? "#0F875B" : "#C42B1C"}
                >
                  $
                </Typography>
                <Typography
                  color={row.original.type === "Sale" ? "#0F875B" : "#C42B1C"}
                >
                  {Number(row.original.amount).toFixed(2)}
                </Typography>
              </Stack>
            </Stack>
          );
        },
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
        Cell: ({ row }) => {
          return (
            <>
              {row.original.customer_id ? (
                <Stack direction="row" gap="12px" alignItems="center">
                  <div
                    className="table-taxt-and-icon-link cursor"
                    onClick={() => {
                      navigate(
                        `/customers/details/${row.original.customer_id}`
                      );
                    }}
                  >
                    <span className="link-text">
                      {row.original.customer_name}
                    </span>
                  </div>
                </Stack>
              ) : (
                row.original.customer_name
              )}
            </>
          );
        },
      },
      {
        Header: "Store Name",
        accessor: "store_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <div
                className="table-taxt-and-icon-link cursor"
                onClick={() => {
                  navigate(`/stores/details/${row.original.store_id}`);
                }}
              >
                <span className="link-text">{row.original.store_name}</span>
              </div>
            </Stack>
          );
        },
      },
    ],
    []
  );

  const column = [
    {
      title: "Purchase Date",
      accessor: "active_from",
    },
    {
      title: "Card Value",
      accessor: "card_value",
      accessor: (row) => {
        return (
          <Stack direction="row">${Number(row?.card_value).toFixed(2)}</Stack>
        );
      },
    },
    {
      title: "Order ID",
      accessor: "order_id",
      accessor: (row) => {
        return (
          <Stack direction="row" gap={1}>
            <div
              className="table-taxt-and-icon-link cursor"
              onClick={() => {
                navigate(`/orders/details/${row?.order_id}`);
              }}
            >
              <span className="link-text">
                <ul>{row?.order_number}</ul>
              </span>
            </div>
          </Stack>
        );
      },
    },
    {
      title: "Purchased By",
      accessor: "customer_name",
    },

    {
      title: "Purchased Store",
      accessor: "store",
    },
    {
      title: "Applicable Store(s)",
      accessor: "applicable_store",
      accessor: (value) => {
        return (
          <Stack
            sx={{
              flexDirection: "row",
              gap: "8px",
              flexWrap: "wrap",
            }}
          >
            {value?.applicable_store === null
              ? "All stores"
              : value?.applicable_store?.map((i) => {
                  return <Chip label={i} size="small" />;
                })}
          </Stack>
        );
      },
    },
  ];
  const filterData = globalData.gift_cards?.status.find(
    (i) => i.value === cardDetails?.card_status
  );

  return (
    <>
      {state.isLoading ? (
        <Loader />
      ) : (
        <ContentLayout
          title={cardDetails.barcode}
          chipType={"cardStatus"}
          label={filterData?.label}
          bgColor={"#1560D414"}
          labelColor={"primary"}
          breadcrumbs={
            id ? (
              <>
                <HeaderBreadcrumbs
                  links={[
                    { name: "Dashboard", href: "/" },
                    {
                      name: "Gift Card",
                      href: "/gift-card",
                    },
                    isAllCards
                      ? {
                          name: cardDetails.batch_name || "",
                          href: `/gift-card/details/${cardDetails.batch_id}`,
                        }
                      : false,
                    { name: cardDetails.barcode },
                  ].filter(Boolean)}
                />
              </>
            ) : (
              false
            )
          }
        >
          {state.isLoading ? (
            <Loader />
          ) : (
            <Box>
              <Card>
                <Stack
                  sx={{
                    p: "32px 24px",
                    flexDirection: "row",
                    gap: "55px",
                  }}
                >
                  <GiftCardItem cardDetails={cardDetails} />
                  <Stack spacing={2} width="430px">
                    {cardDetails.card_status !== 0 && (
                      <Box>
                        <Button
                          variant="text"
                          fullWidth
                          onClick={() => {
                            toggleModal(true, id);
                          }}
                        >
                          <img
                            alt="export"
                            src="/icons/ic_share_blue.svg"
                            style={{
                              marginRight: "4px",
                            }}
                          />
                          Share Card
                        </Button>
                      </Box>
                    )}
                    <Box>
                      <Button
                        variant="text"
                        fullWidth
                        onClick={() => {
                          handleDownloadSingleCards(id);
                        }}
                      >
                        <img
                          alt="export"
                          src="/icons/ic_export.svg"
                          style={{
                            marginRight: "4px",
                          }}
                        />
                        Export
                      </Button>
                    </Box>
                    {(cardDetails.card_status === 4 ||
                      cardDetails.card_status === 1) && (
                      <>
                        <Stack direction="row" justifyContent="space-between">
                          <Stack>
                            <Typography variant="subtitle1" fontWeight={600}>
                              Block Card
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              fontWeight={100}
                              color="#636363"
                              fontSize="14px"
                            >
                              If enabled, card will be temporarily blocked.
                            </Typography>
                          </Stack>

                          <Box ml={18}>
                            <Switch
                              name="status"
                              checked={
                                cardDetails.card_status === 4 ? true : false
                              }
                              onChange={handleInputChangeStatus}
                            />
                          </Box>
                        </Stack>
                      </>
                    )}
                  </Stack>
                </Stack>
              </Card>
              {cardDetails.card_status !== 0 && (
                <>
                  <Box mt={3}>
                    <CustomDetailsList
                      columns={column}
                      data={state.cardDetails}
                      title="Purchase Details"
                    />
                  </Box>
                  {state.isBusy ? (
                    <Loader />
                  ) : (
                    <CustomeDialog
                      PaperProps={{
                        sx: { width: "423px", height: "535px", p: "16px" },
                      }}
                      title="Transaction Details"
                    />
                  )}
                  <ServerPaginatedTable
                    columns={columns}
                    data={cardtransactionDetails || []}
                    searchBar={false}
                    tableTitle="Transaction Details"
                    rowsSelection={false}
                    gotoPage={gototransactionPage}
                    setPageSize={settransactionPageSize}
                    pagination={state.transactionpagination}
                    sortByGlobal={state.transactionsortBy}
                    key={state.transactionsortBy}
                    handleSort={handletransactionSort}
                  />
                </>
              )}
            </Box>
          )}
        </ContentLayout>
      )}
      <CustomeDialog
        open={state.isModalOpen}
        handleClose={() => toggleModal(false, null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "618px", height: "251px", p: "16px 24px" },
        }}
        title="Share Gift Card"
        content={
          <ShareGiftCard
            handleShareGiftCards={handleShareGiftCards}
            onEmailChange={onEmailChange}
            state={state}
          />
        }
      />
    </>
  );
};
