import { ContentLayout, CustomTable } from "../../../shared";
import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { arraySort, sortItems } from "../../../utils";
import AddIcon from "@mui/icons-material/Add";
import { useClientAccount } from "../hooks";
import { useStore } from "../../Stores";
import { DeleteModal } from "../../../shared";

export const ClientAccountsList = () => {
  const navigate = useNavigate();

  const {
    state,
    isEditable,
    isDeletable,
    isCreateVisible,
    handleModal,
    HandleClientAccountStatus,
    isStoreAdmin,
    handleFilterClear,
    onSeachClear,
    onChangeFilter,
    handleFilter,
    handleDeleteClientAccount,
    handleApplyFilter,
  } = useClientAccount({
    isList: true,
  });
  const { storeList } = useStore({
    isStore: isStoreAdmin ? false : true,
    storeId: null,
  });

  const { isLoading, dataList, isDeleteOpen } = state.clientAccount;

  const storeOptions = storeList.map((v) => {
    return { value: v.id, label: v.name };
  });

  const ActionButtons = ({ id, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.stopPropagation()}>
        {isEditable && (
          <img
            onClick={() =>
              navigate(
                isStoreAdmin
                  ? `/store-admin/client-account/details/${id}`
                  : `/client-account/details/${id}`
              )
            }
            alt="details"
            src="/icons/arrow_forward.svg"
          />
        )}
      </div>
    );
  };
  const storeAdmincolumns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "customer_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Business Name",
        accessor: "business_name",
      },

      {
        Header: "Credit Limit",
        accessor: "credit_limit",
        Cell: ({ value }) => {
          return value?.toFixed(2);
        },
      },
      {
        Header: "Total Due Amount",
        accessor: "due_amount",
        Cell: ({ value }) => {
          return value?.toFixed(2);
        },
      },
      {
        Header: "Account Status",
        accessor: "status",
        sortInverted: true,
        Cell: ({ value }) => {
          return (
            <Typography
              color="#636363"
              fontSize="14px"
              fontStyle="normal"
              mt={1}
            >
              {value === 1 ? "Active" : "Inactive"}
            </Typography>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => handleModal("client-ac-delete", true, row.id)}
            />
          );
        },
      },
    ],
    []
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "customer_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Business Name",
        accessor: "business_name",
      },
      {
        Header: "Store",
        accessor: "store_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Credit Limit",
        accessor: "credit_limit",
        Cell: ({ value }) => {
          return value?.toFixed(2);
        },
      },
      {
        Header: "Total Due Amount",
        accessor: "due_amount",
        Cell: ({ value }) => {
          return value?.toFixed(2);
        },
      },
      {
        Header: "Account Status",
        accessor: "status",
        sortInverted: true,
        Cell: ({ value }) => {
          return (
            <Typography
              color="#636363"
              fontSize="14px"
              fontStyle="normal"
              mt={1}
            >
              {value === 1 ? "Active" : "Inactive"}
            </Typography>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => handleModal("client-ac-delete", true, row.id)}
            />
          );
        },
      },
    ],
    []
  );
  const options = [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 0 },
  ];
  const comparisonOperators = [
    { label: "Greater than", value: ">" },
    { label: "Greater than or Equal to", value: ">=" },
    { label: "Less than", value: "<" },
    { label: "Less than or Equal to", value: "<=" },
    { label: "Equal to", value: "=" },
  ];
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });
  const hascreditLimitOperator =
    state.filters.credit_limit_value.value &&
    !state.filters.credit_limit_operator.value;
  const hascreditLimitValue =
    state.filters.credit_limit_operator.value &&
    !state.filters.credit_limit_value.value;
  const hasdueLimitOperator =
    state.filters.due_amount_operator.value &&
    !state.filters.due_amount_value.value;
  const hasdueLimitValue =
    state.filters.due_amount_value.value &&
    !state.filters.due_amount_operator.value;
  return (
    <>
      <ContentLayout
        title="Client Account"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  isStoreAdmin
                    ? navigate(`/store-admin/client-account/create`)
                    : navigate(`/client-account/create`);
                }}
              >
                Add New
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={isStoreAdmin ? storeAdmincolumns : columns}
          data={dataList}
          isLoading={state?.isBusy}
          searchBar={true}
          rowsSelection={false}
          defaultSearch={false}
          filterApplied={state.filters.all}
          tableId="client-account"
          onFilterClear={(e) => handleFilterClear("single", e)}
          onAllFilterClear={(e) => handleFilterClear("all")}
          filterContent={
            <InputControl
              type="table-search"
              value={state.filters.searchText}
              onChange={(e) => onChangeFilter(e)}
              onClear={onSeachClear}
            />
          }
          filterSection={
            <>
              <Stack
                direction="row"
                gap="16px"
                alignItems="center"
                flexWrap="wrap"
              >
                {!isStoreAdmin && (
                  <InputControl
                    type="dropdown"
                    name="store"
                    label="Store"
                    multiple={true}
                    options={arraySort(storeOptions || [], "label")}
                    value={state.filters.stores}
                    disableCloseOnSelect
                    disableClearable={true}
                    onChange={(e, v) => handleFilter(e, v, "store")}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => <></>)
                    }
                    renderOption={(props, option, { selected }) => (
                      <>
                        <li {...props} key={option.value}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      </>
                    )}
                    filterOptions={filterOptions}
                    sx={{ width: 250 }}
                    componentsProps={{
                      paper: {
                        sx: {
                          width: "fit-content",
                          minWidth: 250,
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Store"
                        placeholder="Store"
                      />
                    )}
                  />
                )}
                <Stack direction="column">
                  <FormGroup row>
                    <InputControl
                      type="select"
                      name="credit_limit_operator"
                      label="Credit Limit"
                      placeholder="Credit Limit"
                      optionValue={comparisonOperators}
                      value={state.filters.credit_limit_operator?.value ?? ""}
                      width="150px"
                      onChange={(e, v) =>
                        handleFilter(e, v, "credit_limit_operator")
                      }
                      sx={{
                        borderRadius: "8px 0 0 8px",
                      }}
                    />

                    <InputControl
                      label="Value"
                      type="number"
                      name="credit_limit_value"
                      value={state.filters.credit_limit_value?.value ?? ""}
                      onChange={(e, v) =>
                        handleFilter(e.target.value, v, "credit_limit_value")
                      }
                      width="99px"
                      sx={{
                        ".MuiInputBase-root": {
                          borderRadius: "0 8px 8px 0px",
                        },
                      }}
                    />
                  </FormGroup>
                  <Box sx={{ color: "#cf443d" }}>
                    {hascreditLimitValue ? (
                      <Typography sx={{ color: "#cf443d" }}>
                        The value field is required
                      </Typography>
                    ) : (
                      ""
                    )}
                    {hascreditLimitOperator ? (
                      <Typography sx={{ color: "#cf443d" }}>
                        The credit limit field is required
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                </Stack>
                <Stack direction="column">
                  <FormGroup row>
                    <InputControl
                      type="select"
                      name="due_amount_operator"
                      value={state.filters.due_amount_operator?.value ?? ""}
                      label="Due Amount"
                      placeholder="Due Amount"
                      optionValue={comparisonOperators}
                      width="150px"
                      onChange={(e, v) =>
                        handleFilter(e, v, "due_amount_operator")
                      }
                      sx={{
                        borderRadius: "8px 0 0 8px",
                      }}
                    />

                    <InputControl
                      label="Value"
                      name="due_amount_value"
                      type="number"
                      value={state.filters.due_amount_value?.value ?? ""}
                      onChange={(e, v) =>
                        handleFilter(e.target.value, v, "due_amount_value")
                      }
                      width="99px"
                      sx={{
                        ".MuiInputBase-root": {
                          borderRadius: "0 8px 8px 0px",
                        },
                      }}
                    />
                  </FormGroup>
                  <Box sx={{ color: "#cf443d" }}>
                    {hasdueLimitOperator ? (
                      <Typography sx={{ color: "#cf443d" }}>
                        The value field is required
                      </Typography>
                    ) : (
                      ""
                    )}
                    {hasdueLimitValue ? (
                      <Typography sx={{ color: "#cf443d" }}>
                        The Due Amount field is required
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                </Stack>
                <InputControl
                  type="select"
                  name="Status"
                  label="Status"
                  value={state.filters.status.value ?? ""}
                  optionValue={options}
                  width="100px"
                  sx={{
                    width: "120px",
                  }}
                  onChange={(e, v) => handleFilter(e, v, "status")}
                />
              </Stack>
              <Button
                type="button"
                size="medium"
                variant="text"
                color="info"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => handleApplyFilter()}
                disabled={
                  hascreditLimitOperator ||
                  hascreditLimitValue ||
                  hasdueLimitOperator ||
                  hasdueLimitValue
                }
              >
                Apply
              </Button>
            </>
          }
          onRowAction={(e) => {
            navigate(`details/${e.id}`);
          }}
        />
        <DeleteModal
          open={isDeleteOpen}
          handleClose={() => handleModal("client-ac-delete", false, null)}
          onConfirm={() => handleDeleteClientAccount()}
        />
      </ContentLayout>
    </>
  );
};
