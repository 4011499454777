import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import * as React from "react";
import {
  Button,
  Stack,
  Card,
  Typography,
  Grid,
  TextField,
  Box,
  Divider,
  List,
  Collapse,
  ListItemText,
  ListItemButton,
  ListItem,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useLocation, useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { useFuturePrice } from "../hooks";
import { costValidator, priceValidator } from "../../../utils";

export const AddorEditFuturePrice = () => {
  const { id } = useParams();
  const location = useLocation();
  let product_id = Number(location?.state?.id);
  const queryParams = new URLSearchParams(location.search);
  const param = queryParams.get("param");
  let url = param ? param : location?.state?.from;
  const {
    state,
    optionPriceLevel,
    handleDropdown,
    onChange,
    onSaveFuturePrice,
    onUpdateFuturePrice,
    HandlePriceLevelChange,
    handleClick,
    open,
    extractedProducts,
  } = useFuturePrice({
    isList: false,
    futurePriceId: id,
    isProduct: true,
    product_id,
  });

  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        price: priceValidator,
        cost: costValidator,
        endTime: {
          message: "The end time should greater than start time",
          rule: (value) => {
            const { startTime, endTime } = value;
            return moment(startTime, "HH:mm:ss").isBefore(
              moment(endTime, "HH:mm:ss")
            );
          },
        },
      },
    })
  );

  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdateFuturePrice(id, url) : onSaveFuturePrice(url);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  return (
    <>
      <ContentLayout
        title={id ? state?.product_name : "New Future Price"}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Future Pricing", href: "/future-price" },
                id
                  ? {
                      name: state?.product_name,
                    }
                  : null,
                !id && { name: "Create" },
              ].filter(Boolean)}
            />
          </>
        }
        rightContent={
          <>
            <Button
              onClick={() => onSubmit()}
              type="button"
              size="medium"
              variant="contained"
              disabled={state?.isSaveButtonBusy}
            >
              {id ? "Update" : "Save"}
            </Button>
          </>
        }
      >
        {state?.isLoading || state?.isBusy ? (
          <Loader />
        ) : (
          <Card sx={{ p: "32px 24px", mb: "24px" }}>
            <Stack>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Details
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack width={"100%"} gap="20px">
                    <InputControl
                      name="scope"
                      type="dropdown"
                      disableCloseOnSelect
                      disableClearable
                      options={state.products}
                      disabled={id}
                      className="tags-input"
                      value={
                        (extractedProducts?.length > 0 &&
                          state.data?.product_id &&
                          extractedProducts?.find(
                            (c) => c.value === state.data?.product_id
                          )) ||
                        null
                      }
                      renderOption={(props, option, { selected }) => (
                        <>
                          <ListItemButton
                            key={1}
                            sx={{
                              "&:hover": {
                                backgroundColor: "rgba(21, 96, 212, 0.08)",
                                borderRadius: "8px",
                              },
                            }}
                            onClick={() => {
                              handleClick(option.id);
                            }}
                            z
                          >
                            <ListItemText
                              sx={{
                                "& .MuiTypography-root": {
                                  fontWeight: 600,
                                },
                              }}
                              primary={
                                <Typography
                                  sx={{ display: "inline", fontWeight: 600 }}
                                  component="span"
                                  variant="subtitle2"
                                  color="#171717"
                                >
                                  {option.name}({option.products.length})
                                </Typography>
                              }
                            />
                            {option.products.length > 0 && (
                              <>
                                <img
                                  src="/icons/ic_dropdown.svg"
                                  style={{
                                    transform:
                                      open === option.id
                                        ? ""
                                        : "rotate(180deg)",
                                  }}
                                />
                              </>
                            )}
                          </ListItemButton>

                          <Collapse
                            in={open === option.id ? true : false}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List
                              component="div"
                              disablePadding
                              sx={{
                                backgroundColor: open
                                  ? "rgba(21, 96, 212, 0.08)"
                                  : "",
                                borderRadius: "8px",
                                mb: 0.5,
                              }}
                            >
                              {option.products.map((data, i) => (
                                <ListItem
                                  sx={{
                                    "&:hover": {
                                      backgroundColor:
                                        "rgba(21, 96, 212, 0.08)",
                                      borderRadius: "8px",
                                    },
                                  }}
                                  key={i}
                                  {...props}
                                  onClick={(e) => {
                                    handleDropdown(
                                      {
                                        target: {
                                          name: "value",
                                          value: data,
                                        },
                                      },
                                      i,
                                      "products"
                                    );
                                  }}
                                >
                                  {data.label}
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        </>
                      )}
                      renderTags={(value, getTagProps) => (
                        <div>
                          {value.map((option, i) => (
                            <span key={i}>{option.label} </span>
                          ))}
                        </div>
                      )}
                      filterOptions={(options, state) => {
                        return options.map((val) => {
                          return {
                            ...val,
                            products: val.products.filter((val, i) => {
                              return (
                                val.label
                                  .toString()
                                  .toLowerCase()
                                  .indexOf(state.inputValue.toLowerCase()) > -1
                              );
                            }),
                          };
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Products Name"
                          error={validator.current.message(
                            "Products",
                            state.data.product_id,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "Products",
                            state.data.product_id,
                            "required"
                          )}
                          required
                        />
                      )}
                    />
                    <Stack direction="row" gap="20px">
                      <InputControl
                        name="price_level_id"
                        label="Price Level"
                        type="dropdown"
                        options={optionPriceLevel}
                        disabled={!state?.data?.product_id || id}
                        getOptionLabel={(option) => option.label}
                        value={
                          (optionPriceLevel?.length > 0 &&
                            state.data?.price_level_id &&
                            optionPriceLevel?.find(
                              (c) => c.value === state.data?.price_level_id
                            )) ||
                          null
                        }
                        onChange={(e, newValue) => {
                          handleDropdown(e, newValue, "priceLevel");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Price Level"
                            error={validator.current.message(
                              "Price Level",
                              state.data.price_level_id,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Price Level",
                              state.data.price_level_id,
                              "required"
                            )}
                            value={state.data.product || []}
                            required
                          />
                        )}
                      />

                      <InputControl
                        type="datePicker"
                        name="start_date"
                        label="Start Date"
                        minDate={moment().add(1, "days").format("YYYY-MM-DD")}
                        disableHighlightToday={"false"}
                        required
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        value={state?.data?.start_date || ""}
                        onChange={(e) => {
                          onChange({
                            target: {
                              name: "start_date",
                              value: moment(e).format("YYYY-MM-DD"),
                            },
                          });
                        }}
                        sx={{
                          width: "100%",
                        }}
                        error={validator.current.message(
                          "Start Date",
                          state.data.start_date,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Start Date",
                          state.data.start_date,
                          "required"
                        )}
                      />
                    </Stack>
                    <Stack direction="row" gap="20px">
                      <InputControl
                        name="future_price"
                        type="number"
                        label="Future Price"
                        value={state.data.future_price}
                        onChange={(e) => onChange(e)}
                        error={validator.current.message(
                          "Future Price",
                          state.data.future_price,
                          "required|price"
                        )}
                        helperText={validator.current.message(
                          "Future Price",
                          state.data.future_price,
                          "required|price"
                        )}
                        required
                      />
                      <InputControl
                        name="current_price"
                        label="Current Price"
                        value={
                          state.data.current_price
                            ? state.data.current_price
                            : ""
                        }
                        onChange={(e) => onChange(e)}
                        disabled
                        error={validator.current.message(
                          "Current Price",
                          state.data.current_price,
                          "required|price"
                        )}
                        helperText={validator.current.message(
                          "Current Price",
                          state.data.current_price,
                          "required|price"
                        )}
                        required
                      />
                    </Stack>
                    <Stack direction="row" gap="20px">
                      <InputControl
                        name="cost_exclusive_gst"
                        label="Cost Ex"
                        type="number"
                        isDisabledKeys={["-"]}
                        disabled={!state?.data?.product_id}
                        value={state.data.cost_exclusive_gst}
                        onChange={(e) => onChange(e)}
                        error={validator.current.message(
                          "Cost Ex",
                          state.data.cost_exclusive_gst,
                          "required|cost"
                        )}
                        helperText={validator.current.message(
                          "Cost Ex",
                          state.data.cost_exclusive_gst,
                          "required|cost"
                        )}
                        required
                      />
                      <InputControl
                        name="cost_inclusive_gst"
                        label="Cost In"
                        type="number"
                        isDisabledKeys={["-"]}
                        disabled={!state?.data?.product_id}
                        value={state.data.cost_inclusive_gst}
                        onChange={(e) => onChange(e)}
                        error={validator.current.message(
                          "Cost In",
                          state.data.cost_inclusive_gst,
                          "required|cost"
                        )}
                        helperText={validator.current.message(
                          "Cost In",
                          state.data.cost_inclusive_gst,
                          "required|cost"
                        )}
                        required
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            {state?.data?.variants?.length > 0 ? (
              <>
                <Box
                  sx={{
                    p: "32px 24px",
                    backgroundColor: "#F7F7F7",
                  }}
                  mt={3}
                >
                  <Typography
                    variant="subtitle1"
                    lineHeight="16px"
                    fontWeight={600}
                    sx={{ width: "100%" }}
                  >
                    Variants
                  </Typography>
                </Box>
                <Stack sx={{ p: "8px 24px" }}>
                  {state?.data?.variants?.map((data, vi) => (
                    <>
                      <Divider sx={{ display: vi === 0 && "none" }} />
                      <Stack
                        sx={{
                          py: "24px",
                          flexDirection: "row",
                          gap: "10px",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          lineHeight="16px"
                          fontWeight={400}
                          sx={{ maxWidth: "288px", width: "100%" }}
                        >
                          {data?.name}
                        </Typography>
                        <Stack width="100%" gap="24px">
                          <InputControl
                            name="price_adjustment"
                            type="number"
                            label="Price Adjustment"
                            value={data.price_adjustment}
                            required
                            onChange={(e) =>
                              HandlePriceLevelChange(e, "variant", vi)
                            }
                            error={validator.current.message(
                              `Price Adjustment of ${data.name}`,
                              data.price_adjustment,
                              "required|price"
                            )}
                            helperText={validator.current.message(
                              "Price Adjustment ",
                              data.price_adjustment,
                              "required|price"
                            )}
                          />
                          <Stack direction="row" gap="20px">
                            <InputControl
                              name="cost_adjustment_exclusive_gst"
                              type="number"
                              label="Cost Adjustment Ex GST "
                              value={data.cost_adjustment_exclusive_gst}
                              required
                              onChange={(e) =>
                                HandlePriceLevelChange(e, "variant", vi)
                              }
                              error={validator.current.message(
                                "Cost Adjustment Ex GST ",
                                data.cost_adjustment_exclusive_gst,
                                "required|cost"
                              )}
                              helperText={validator.current.message(
                                "Cost Adjustment Ex GST ",
                                data.cost_adjustment_exclusive_gst,
                                "required|cost"
                              )}
                            />
                            <InputControl
                              name="cost_adjustment_inclusive_gst"
                              type="number"
                              label="Cost Adjustment In GST "
                              value={data.cost_adjustment_inclusive_gst}
                              required
                              onChange={(e) =>
                                HandlePriceLevelChange(e, "variant", vi)
                              }
                              error={validator.current.message(
                                "Cost Adjustment In GST ",
                                data.cost_adjustment_inclusive_gst,
                                "required|cost"
                              )}
                              helperText={validator.current.message(
                                "Cost Adjustment In GST",
                                data.cost_adjustment_inclusive_gst,
                                "required|cost"
                              )}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                    </>
                  ))}
                </Stack>
              </>
            ) : (
              ""
            )}
          </Card>
        )}
      </ContentLayout>
    </>
  );
};
