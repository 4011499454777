import {
  TextField,
  Stack,
  Grid,
  Card,
  Box,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { Loader } from "../../../shared";

export const ProfileData = ({
  isBusy,
  state,
  onChange,
  onDropdown,
  handleDropdown,
  validator,
  setValue,
  onEdit,
  onUpdateProfile,
  forceUpdate,
}) => {
  const { profileDetails } = state;

  const options = profileDetails.timezonesList.map((item) => ({
    label: item.key,
    value: item.value,
  }));
  const countryCode = [
    { value: 91, label: "+91" },
    { value: 61, label: "+61" },
  ];

  return (
    <>
      {isBusy === true ? (
        <Loader />
      ) : (
        <Box>
          <Card sx={{ mb: "20px" }}>
            <Divider />
            <List disablePadding>
              <ListItem divider={true} disablePadding>
                <ListItemText
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    my: 0,
                    pl: 1,
                  }}
                  disableTypography={true}
                >
                  <CardHeader title="Profile" />
                  <Stack height="85px" justifyContent="center" marginRight={2}>
                    {profileDetails.isDisable ? (
                      <Button
                        type="button"
                        size="medium"
                        variant="contained"
                        onClick={() => {
                          setValue("1");
                          onEdit();
                        }}
                      >
                        Edit
                      </Button>
                    ) : (
                      <>
                        <Button
                          type="button"
                          size="medium"
                          variant="contained"
                          onClick={() => {
                            if (validator.current.allValid()) {
                              onUpdateProfile();
                            } else {
                              validator.current.showMessages();
                              forceUpdate(1);
                            }
                          }}
                        >
                          Update
                        </Button>
                      </>
                    )}
                  </Stack>
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    my: 0,
                    pl: 2,
                    mt: 2,
                    mb: 2,
                  }}
                  disableTypography={true}
                >
                  <Grid container>
                    <Grid item xs={4}>
                      <h4>Basic Details</h4>
                    </Grid>
                    <Grid xs={8}>
                      <Stack spacing={3}>
                        <Stack direction={{ sm: "row" }} spacing={{ sm: 2 }}>
                          <InputControl
                            label="First Name"
                            name="first_name"
                            variant="outlined"
                            value={profileDetails.data.first_name}
                            disabled={state.profileDetails.isDisable}
                            onChange={onChange}
                            required
                            error={validator.current.message(
                              "first Name",
                              profileDetails.data.first_name,
                              "required",
                              { className: "text-danger" }
                            )}
                            helperText={validator.current.message(
                              "first Name",
                              profileDetails.data.first_name,
                              "required"
                            )}
                          />

                          <InputControl
                            type="text"
                            name="last_name"
                            label="Last Name"
                            disabled={state.profileDetails.isDisable}
                            value={profileDetails.data.last_name}
                            onChange={onChange}
                            required
                            error={validator.current.message(
                              "last Name",
                              profileDetails.data.last_name,
                              "required",
                              { className: "text-danger" }
                            )}
                            helperText={validator.current.message(
                              "last Name",
                              profileDetails.data.last_name,
                              "required"
                            )}
                          />
                        </Stack>
                        <InputControl
                          name="email"
                          label="Email Address"
                          disabled={state.profileDetails.isDisable}
                          value={profileDetails.data.email}
                          onChange={onChange}
                          required
                          error={validator.current.message(
                            "email Address",
                            profileDetails.data.email,
                            "required|email"
                          )}
                          helperText={validator.current.message(
                            "email Address",
                            profileDetails.data.email,
                            "required|email"
                          )}
                        />
                        <Stack direction="row" gap="20px">
                          <InputControl
                            disableClearable
                            name="country_code"
                            type="dropdown"
                            sx={{
                              width: "93px",
                            }}
                            options={countryCode}
                            getOptionLabel={(option) => option.label}
                            value={
                              countryCode.length > 0 &&
                              state.profileDetails.data.country_code &&
                              countryCode.find(
                                (c) =>
                                  c.value ===
                                  state.profileDetails.data.country_code
                              )
                            }
                            onChange={(e, newValue) => {
                              handleDropdown(newValue);
                            }}
                            disabled={state.profileDetails.isDisable}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Country code"
                                error={validator.current.message(
                                  "country_code",
                                  profileDetails.data.country_code,
                                  "required"
                                )}
                                helperText={validator.current.message(
                                  "country_code",
                                  profileDetails.data.country_code,
                                  "required"
                                )}
                              />
                            )}
                          />

                          <InputControl
                            name="phone"
                            label="Phone Number"
                            type="text"
                            disabled={state.profileDetails.isDisable}
                            value={profileDetails.data.phone}
                            onChange={onChange}
                            error={validator.current.message(
                              "phoneNumber",
                              profileDetails.data.phone,
                              "required|phone|max:10"
                            )}
                            helperText={validator.current.message(
                              "phoneNumber",
                              profileDetails.data.phone,
                              "required|phone|max:10"
                            )}
                            required
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <Divider />
            </List>
          </Card>
          <Card sx={{ mt: "24px", p: "32px 24px" }}>
            <ListItemText
              sx={{
                display: "flex",
                flexDirection: "row",
                my: 0,
                pl: 2,
                mt: 1,
              }}
              disableTypography={true}
            >
              <Grid container>
                <Grid item xs={4}>
                  <h4>Time Zone</h4>
                </Grid>

                <Grid item xs={8}>
                  <InputControl
                    name="timezone"
                    type="dropdown"
                    options={options}
                    getOptionLabel={(option) => option.label}
                    value={
                      options.length > 0 &&
                      profileDetails.data.timezone &&
                      options.find(
                        (c) => c.value === profileDetails.data.timezone
                      )
                    }
                    required
                    onChange={(e, newValue) => {
                      onDropdown(newValue);
                    }}
                    disabled={state.profileDetails.isDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Time Zone"
                        error={validator.current.message(
                          "timezone",
                          profileDetails.data.timezone,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "timezone",
                          profileDetails.data.timezone,
                          "required"
                        )}
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </ListItemText>
          </Card>
        </Box>
      )}
    </>
  );
};
