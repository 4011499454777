import React from "react";
import {
  ContentLayout,
  HeaderBreadcrumbs,
  ServerPaginatedTable,
} from "../../../shared";
import { usePaymentMediaReport } from "../hooks";
import { Box, Button, Stack } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { PaymentMediaReportFilter } from "../components";
import { LoadingButton } from "@mui/lab";

export default function PaymentMediaReportList() {
  const {
    state,
    filterList,
    tableColumns,
    gotoPage,
    setPageSize,
    handleSortBy,
    breadcrumbsLink,
    handleApplyFilter,
    handleClearFilter,
    handleTableSearch,
    handleFilterChange,
    handleRestAllFilter,
    handleDateChangeApply,
    handleToggleColumnOption,
    toggleColumnConfigSidebar,
    handleOpenDateRangeSelection,
    handleExportPaymentMediaReport,
  } = usePaymentMediaReport();

  const HeaderComponent = () => {
    return (
      <Stack direction="row" gap="16px">
        <LoadingButton
          type="button"
          size="medium"
          variant="text"
          loading={state.paymentMediaReport.export.isExporting}
          onClick={handleExportPaymentMediaReport}
        >
          <img
            alt="export"
            src="/icons/ic_export.svg"
            style={{
              marginRight: "4px",
            }}
          />
          Export
        </LoadingButton>
        <Button
          type="button"
          size="medium"
          variant="text"
          onClick={toggleColumnConfigSidebar}
        >
          <Box
            alt="tools"
            component="img"
            src="/icons/ic_Tools.svg"
            mr="4px"
          />
          Column Option
        </Button>
      </Stack>
    );
  };

  return (
    <ContentLayout
      title="Payment Media Report"
      breadcrumbs={<HeaderBreadcrumbs links={breadcrumbsLink} />}
      rightContent={<HeaderComponent />}
    >
      <ServerPaginatedTable
        searchBar={true}
        defaultSearch={false}
        columns={tableColumns}
        filterContent={
          <InputControl
            type="table-search"
            name="search"
            placeholder="Search here..."
            onChange={handleTableSearch}
            value={state.paymentMediaReport.search}
            onClear={() => handleTableSearch({ target: { value: "" } })}
            autoComplete="off"
          />
        }
        filterSection={
          <PaymentMediaReportFilter
            {...{
              ...filterList,
              filter: state.filter,
              handleDateChangeApply,
              handleFilterChange,
              handleApplyFilter,
              handleOpenDateRangeSelection,
              isFilterApplied: state.filter.isFilterApplied,
            }}
          />
        }
        gotoPage={gotoPage}
        handleSort={handleSortBy}
        setPageSize={setPageSize}
        onFilterClear={handleClearFilter}
        drawerOpen={state.tableDrawer.open}
        data={state.paymentMediaReport.list}
        onAllFilterClear={handleRestAllFilter}
        drawerOnClose={toggleColumnConfigSidebar}
        filterApplied={state.filter.appliedFilters}
        toggleHiddenManual={handleToggleColumnOption}
        sortByGlobal={state.paymentMediaReport.sortBy}
        isLoading={state.paymentMediaReport.isLoading}
        pagination={state.paymentMediaReport.pagination}
        columnOptions={state.paymentMediaReport.columnOptions}
        onRowLink={(row) => `/orders/details/${row.id}`}
      />
    </ContentLayout>
  );
}
