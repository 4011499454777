import React from "react";
import { Route, Routes } from "react-router-dom";
import { COAList, AddorEditCOA } from "./containers";
import { ProtectedRoute } from "../../shared";

export const COA = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<COAList />} />
                <Route element={<ProtectedRoute allowedRoute={["coa-create"]} />}>
                    <Route path="/create" element={<AddorEditCOA />} />
                </Route>
                <Route element={<ProtectedRoute allowedRoute={["coa-modify"]} />}>
                    <Route path="/edit/:id" element={<AddorEditCOA />} />
                </Route>
            </Routes>
        </>
    );
};
