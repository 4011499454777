import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useImmer } from "use-immer";
import { useDebouncedCallback } from "use-debounce";
import qs from "qs";
import { AppContext, TableStateContext } from "../../../store";
import { DownloadOrdersList, getOrderList } from "../api";
import moment from "moment";
import { useTabs } from "../../../shared";
import { errorMessage, successMessage } from "../../../utils";

const columns = [
  "order_number",
  "order_date",
  "channel_name",
  "sales_type",
  "store_name",
  "customer_first_name",
  "grand_total",
  "status",
  "payment_type",
  "terminal_name",
];

export const useConfirmedOrderList = () => {
  const PAGE_NAME = "confirmorders";
  let { pathname } = useLocation();
  const { appState } = useContext(AppContext);
  const {
    filterState: {
      params: { confirmorders },
      params: {
        confirmorders: { columnOptions: confirmorderColumns },
      },
    },
    filterStateDispatch,
  } = useContext(TableStateContext);

  const { globalData } = appState;
  const { order: currentTab } = appState.currentTabs;
  const { setCurentTab } = useTabs();
  const [orderdata, setOrderData] = useImmer({
    disableApplyButton: false,
    isBusy: true,
    isSaveButtonBusy: false,
    isUnpairing: false,
    isOpen: false,
    isOpenDetails: false,
    id: null,
    terminalName: "",
    orders: [],
    sortBy: [],
    pagination: {
      pageIndex: 1,
      pageSize: 25,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    httpStatusCodes: {
      details: "",
    },
    filterApplied: {
      date: {},
      type: [],
      stores: [],
      searchText: null,
      feature_order: 0,
      channel: [],
    },
    isCalenderOpen: false,
    date: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    showDate: [],
    invoiceLoading: false,
  });

  const debounced = useDebouncedCallback((value) => {
    const { stores, date, type, status, feature_order, channel } =
      orderdata.filterApplied;
    const { pagination, sortBy } = orderdata;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: orderdata.filterApplied },
    });
    getOrders({
      searchText: value,
      stores,
      type,
      status,
      date,
      feature_order,
      channel,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  }, 1000);

  useEffect(() => {
    const { stores, date, type, status, searchText, feature_order, channel } =
      confirmorders.filterApplied;
    const { pagination, sortBy } = confirmorders;
    setOrderData((draft) => {
      draft.filterApplied = confirmorders.filterApplied;
      draft.pagination = pagination;
      draft.sortBy = sortBy;
      if (Object.keys(date).length) {
        draft.date = [
          {
            endDate: new Date(date.value.endDate),
            startDate: new Date(date.value.startDate),
            key: "selection",
          },
        ];
        draft.showDate = [
          {
            endDate: new Date(date.value.endDate),
            startDate: new Date(date.value.startDate),
            key: "selection",
          },
        ];
      }
    });
    getOrders({
      searchText,
      stores,
      type,
      status,
      date,
      pagination,
      sortBy,
      feature_order,
      channel,
    });
  }, []);

  const getOrders = async ({
    stores = [],
    type = [],
    status = [],
    searchText = "",
    date = {},
    pagination,
    sortBy = [],
    feature_order = {},
    channel = [],
  }) => {
    try {
      let IDs = stores.map((v) => {
        return v.value;
      });
      let SCIDs = channel.map((v) => {
        return v.value;
      });

      let typeIDs = type.map((v) => {
        return v.value;
      });
      let StatusIDs = status.map((v) => {
        return v.value;
      });

      let params = {};

      if (searchText) {
        params = { ...params, search: searchText };
      }
      if (feature_order) {
        params = { ...params, feature_order: feature_order };
      }
      if (
        pathname === "/discount-orders" ||
        pathname === "/store-admin/discount-orders"
      ) {
        params = { ...params, discount_orders: 1 };
      }
      if (StatusIDs.length > 0) {
        params = { ...params, status: StatusIDs };
      }
      if (Object.keys(date).length) {
        params = {
          ...params,
          start_date: moment(date.value.startDate).format("YYYY-MM-DD"),
          end_date: moment(date.value.endDate).format("YYYY-MM-DD"),
        };
      }
      if (IDs.length > 0) {
        params = { ...params, store_id: IDs };
      }

      if (SCIDs.length > 0) {
        params = { ...params, channel: SCIDs };
      }

      if (typeIDs.length > 0) {
        params = { ...params, sales_type: typeIDs };
      }
      if (sortBy.length > 0) {
        const sortByParams = sortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }
      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };
      setOrderData((draft) => {
        draft.isBusy = true;
        draft.disableApplyButton = true;
      });
      const res = await getOrderList(query, pagination);
      if (res.success) {
        setOrderData((draft) => {
          draft.isBusy = false;
          draft.orders = res.data.orders;
          draft.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      setOrderData((draft) => {
        draft.isBusy = false;
        draft.disableApplyButton = false;
      });
    }
  };

  const handleConfirmOrderSearch = (v) => {
    setOrderData((draft) => {
      draft.filterApplied.searchText = v;
    });
    debounced(v);
  };

  const handleConfirmOrderFilter = (e, value, type) => {
    if (type === "store") {
      setOrderData((draft) => {
        draft.filterApplied.stores = value.map((v) => {
          return { ...v, type: "Store" };
        });
      });
    } else if (type === "channel") {
      setOrderData((draft) => {
        draft.filterApplied.channel = value.map((v) => {
          return { ...v, type: "Sales Channel" };
        });
      });
    } else if (type === "status") {
      setOrderData((draft) => {
        draft.filterApplied.status = value.map((v) => {
          return { ...v, type: "Status" };
        });
      });
    } else if (type === "type") {
      setOrderData((draft) => {
        draft.filterApplied.type = value.map((v) => {
          return { ...v, type: "Type" };
        });
      });
    }
    setOrderData((draft) => {
      draft.disableApplyButton = false;
    });
  };

  const confirmFilterCollections = [
    orderdata.filterApplied.date,
    ...orderdata.filterApplied.stores,
    ...orderdata.filterApplied.channel,
    ...orderdata.filterApplied.type,
  ].filter((el) => Object.keys(el).length);
  const handleConfirmOrderApplyFilter = () => {
    const { stores, date, type, status, searchText, feature_order, channel } =
      orderdata?.filterApplied;
    const { pagination, sortBy } = orderdata;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: orderdata.filterApplied,
        pagination,
        sortBy,
      },
    });
    getOrders({
      searchText,
      stores,
      type,
      status,
      date,
      feature_order,
      channel,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  };

  const handleConfirmOrderFilterClear = (type, value) => {
    const { pagination, sortBy } = confirmorders;
    if (type === "single") {
      if (value.type === "Store") {
        const filteredStores = orderdata.filterApplied.stores.filter(
          (e) => e.value !== value.value
        );
        setOrderData((draft) => {
          draft.filterApplied.stores = filteredStores;
        });
      }
      if (value.type === "Sales Channel") {
        const filteredStatus = orderdata.filterApplied.channel.filter(
          (e) => e.value !== value.value
        );
        setOrderData((draft) => {
          draft.filterApplied.channel = filteredStatus;
        });
      }
      if (value.type === "Status") {
        const filteredStatus = orderdata.filterApplied.status.filter(
          (e) => e.value !== value.value
        );
        setOrderData((draft) => {
          draft.filterApplied.status = filteredStatus;
        });
      }
      if (value.type === "Date") {
        setOrderData((draft) => {
          draft.filterApplied.date = {};
          draft.showDate = [];
        });
      }

      if (value.type === "Type") {
        const filteredTypes = orderdata.filterApplied.type.filter(
          (e) => e.value !== value.value
        );
        setOrderData((draft) => {
          draft.filterApplied.type = filteredTypes;
        });
      }
      setOrderData((draft) => {
        draft.disableApplyButton = false;
      });
    } else {
      setOrderData((draft) => {
        draft.filterApplied.stores = [];
        draft.filterApplied.channel = [];
        draft.filterApplied.status = [];
        draft.filterApplied.date = {};
        draft.showDate = [];
        draft.date = [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ];
        draft.filterApplied.type = [];
      });
      const initialValue = {
        date: {},
        type: [],
        stores: [],
        feature_order: 0,
        channel: [],
        searchText: orderdata.filterApplied.searchText,
      };
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initialValue,
      });
      getOrders({
        pagination: {
          pageIndex: 1,
          pageSize: pagination.pageSize,
        },
        sortBy,
        searchText: orderdata.filterApplied.searchText,
      });
    }
  };

  const gotoConfirmOrderPage = (page) => {
    const { stores, date, type, status, searchText, feature_order, channel } =
      orderdata.filterApplied;
    const { sortBy } = orderdata;
    getOrders({
      searchText,
      stores,
      type,
      status,
      date,
      feature_order,
      channel,
      pagination: {
        pageIndex: page,
        pageSize: orderdata.pagination.pageSize,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
    setOrderData((draft) => {
      draft.pagination.pageIndex = page;
    });
  };

  const setConfirmOrderPageSize = (e) => {
    const { stores, date, type, status, searchText, feature_order, channel } =
      orderdata.filterApplied;
    const { sortBy } = orderdata;
    getOrders({
      searchText,
      stores,
      type,
      status,
      date,
      feature_order,
      channel,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
    setOrderData((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };

  const handleConfirmOrderSort = (sortBy) => {
    const { stores, date, type, status, searchText, feature_order, channel } =
      orderdata.filterApplied;
    const { pagination } = orderdata;
    getOrders({
      searchText,
      stores,
      type,
      status,
      date,
      pagination,
      sortBy,
      feature_order,
      channel
    });
    filterStateDispatch({
      type: "UPDATE_SORT",
      page: PAGE_NAME,
      value: sortBy,
    });
    setOrderData((draft) => {
      draft.sortBy = sortBy;
    });
  };

  const onConfirmOrederChangeDate = (e) => {
    setOrderData((draft) => {
      draft.date = e;
    });
  };
  const handleConfirmOrderIsOpen = (e) => {
    setOrderData((draft) => {
      draft.isCalenderOpen = e;
    });
  };

  const handleConfirmOrderDateChangeApply = () => {
    setOrderData((draft) => {
      draft.showDate = orderdata.date;
      draft.isCalenderOpen = false;
      draft.filterApplied.date = {
        value: orderdata.date[0],
        label: `${moment(orderdata.date[0].startDate).format(
          "yyy-MM-DD"
        )} to ${moment(orderdata.date[0].endDate).format("yyy-MM-DD")}`,
        type: "Date",
      };
      draft.disableApplyButton = false;
    });
  };

  const toggleConfirmOrderHiddenManualOrder = (column) => {
    filterStateDispatch({
      type: "UPDATE_COLUMNS",
      page: PAGE_NAME,
      value: column,
    });
  };
  const HandleFutureConfirmedOrder = async (feature_order) => {
    const { stores, date, type, status, searchText, channel } = orderdata.filterApplied;
    const { pagination, sortBy } = orderdata;
    getOrders({
      searchText,
      stores,
      type,
      status,
      date,
      pagination,
      sortBy,
      feature_order,
      channel,
    });

    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { feature_order },
    });
    setOrderData((draft) => {
      draft.filterApplied.feature_order = feature_order;
    });
  };
  const handleDownloadConfirmOrdersList = async () => {
    const columnOptions = confirmorderColumns;
    const visibleColumns = columns.filter(
      (item1) => !columnOptions.some((item2) => item1 === item2)
    );
    if (visibleColumns.length) {
      try {
        const { stores, date, type, searchText, channel } = orderdata.filterApplied;

        const { sortBy } = orderdata;
        const statusState = [{ label: "Placed", value: 1, type: "Status" }];
        let IDs = stores.map((v) => {
          return v.value;
        });
        let SCIDs = stores.map((v) => {
          return v.value;
        });

        let typeIDs = type.map((v) => {
          return v.value;
        });
        let StatusIDs = statusState.map((v) => {
          return v.value;
        });

        let params = {};

        if (searchText) {
          params = { ...params, search: searchText };
        }
        if (orderdata.filterApplied.feature_order !== "") {
          params = {
            ...params,
            feature_order: orderdata.filterApplied.feature_order,
          };
        }
        if (
          pathname === "/discount-orders" ||
          pathname === "/store-admin/discount-orders"
        ) {
          params = { ...params, discount_orders: 1 };
        }
        if (StatusIDs.length > 0) {
          params = { ...params, status: StatusIDs };
        }
        if (Object.keys(date).length) {
          params = {
            ...params,
            start_date: moment(date.value.startDate).format("YYYY-MM-DD"),
            end_date: moment(date.value.endDate).format("YYYY-MM-DD"),
          };
        }
        if (IDs.length > 0) {
          params = { ...params, store_id: IDs };
        }
        if (SCIDs.length > 0) {
          params = { ...params, channel: SCIDs };
        }

        if (typeIDs.length > 0) {
          params = { ...params, sales_type: typeIDs };
        }
        params = { ...params, columns: visibleColumns };
        if (sortBy.length > 0) {
          const sortByParams = sortBy.reduce(
            (obj, item) =>
              Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
            {}
          );

          params = {
            ...params,
            sort: sortByParams,
          };
        }
        const query = {
          params,
          paramsSerializer: (params) => qs.stringify(params),
        };

        const res = await DownloadOrdersList(query);
        successMessage(res?.message);
      } catch (err) {
        errorMessage(err.response.data.message);
      }
    } else {
      errorMessage("at least one column is required to export the list");
    }
  };

  return {
    orderdata,
    globalData,
    handleConfirmOrderSearch,
    handleConfirmOrderFilter,
    handleConfirmOrderApplyFilter,
    handleConfirmOrderFilterClear,
    gotoConfirmOrderPage,
    setConfirmOrderPageSize,
    handleConfirmOrderSort,
    onConfirmOrederChangeDate,
    handleConfirmOrderIsOpen,
    handleConfirmOrderDateChangeApply,
    toggleConfirmOrderHiddenManualOrder,
    confirmFilterCollections,
    confirmorderColumns,
    currentTab,
    setCurentTab,
    HandleFutureConfirmedOrder,
    confirmorders,
    handleDownloadConfirmOrdersList,
  };
};
