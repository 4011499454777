import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Drawer, Stack } from "@mui/material";

export const CustomDrawer = (props) => {
  const { open, onClose, anchor, title, children } = props;

  return (
    <Drawer anchor={anchor || "right"} open={open} onClose={onClose}>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#1560D4",
          height: "64px",
          minWidth: "388px",
          p: "12px 16px",
        }}
      >
        <Typography fontWeight={600} variant="h6" color="#fff">
          {title}
        </Typography>

        <CloseIcon
          color="#fff"
          sx={{
            cursor: "pointer",
            color: "#fff",
            "&:hover": {
              color: "rgba(255,255,255, 0.75)",
            },
          }}
          onClick={onClose}
        />
      </Stack>

      {children}
    </Drawer>
  );
};
