import { ContentLayout, ServerPaginatedTable } from "../../../shared";
import * as React from "react";
import { Button, Stack } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import CloseIcon from "@mui/icons-material/Close";
import { useStoreRefund } from "../hooks/useStoreRefund";
import { DashBoardDateRangePicker } from "../..";

export const StoreRefundList = () => {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const {
    state,
    onChangeFilter,
    onSeachClear,
    resetFilters,
    gotoPage,
    setPageSize,
    handleDownloadStoreRefundList,
    handleSort,
    onChangeDate,
    handleIsOpen,
    handleDateChangeApply,
    toggleHiddenColumns,
    columnOptions,
  } = useStoreRefund({ isRefund: true });

  const [hasColumnOptions, setHasColumnOptions] = useState(false);
  const toggleColumns = () => {
    setHasColumnOptions(!hasColumnOptions);
  };
  const ActionButtons = ({ id }) => {
    return (
      <div className="action-buttons">
        <Link to={`/store-admin/refund/details/${id}`}>
          <img alt="details" src="/icons/arrow_forward.svg" />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Refund Number",
        accessor: "refund_number",
      },
      {
        Header: "Order Number",
        accessor: "order_number",
        Cell: ({ row }) => {
          return <div>{row?.original.order?.order_number ?? "N/A"}</div>;
        },
      },
      {
        Header: "Refund Date",
        accessor: "refund_date",
      },
      {
        Header: "Provider",
        accessor: "channel_name",
        Cell: ({ value, row }) => {
          return (
            <div>
              {value}
              {row.original.user_name && ` - ${row.original.user_name}`}
            </div>
          );
        },
      },
      {
        Header: "Refund Total",
        accessor: "refunded_total",
        Cell: ({ value }) => {
          return <div>{value?.toFixed(2) || 0}</div>;
        },
      },
      {
        Header: "Customer Name",
        id: "customer_first_name",
        accessor: (row) => {
          return (
            <>
              {row.order !== null ? (
                <div>
                  {row?.order?.customer_first_name !== undefined
                    ? row?.order?.customer_first_name
                    : ""}{" "}
                  {row?.order?.customer_last_name !== undefined
                    ? row?.order?.customer_last_name
                    : ""}
                </div>
              ) : (
                `${row.customer_first_name ?? ""} ${
                  row.customer_last_name ?? ""
                }`
              )}
            </>
          );
        },
      },
      {
        Header: "Order Date",
        id: "order_date",
        accessor: (row) => {
          return <div>{row?.order?.order_date ?? "N/A"}</div>;
        },
      },
      {
        Header: "Order Total",
        id: "order_grand_total",
        accessor: (row) => {
          return <div>{row?.order?.grand_total?.toFixed(2) ?? "N/A"}</div>;
        },
      },
      {
        Header: "Store",
        accessor: "store_name",
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons id={row.id} />;
        },
      },
    ],
    []
  );

  return (
    <>
      <ContentLayout
        title="Refunds"
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="text"
              onClick={handleDownloadStoreRefundList}
            >
              <img
                alt="export"
                src="/icons/ic_export.svg"
                style={{
                  marginRight: "4px",
                }}
              />
              Export
            </Button>
            <Button
              type="button"
              size="medium"
              variant="text"
              onClick={() => toggleColumns()}
            >
              <img
                alt="tools"
                src="/icons/ic_Tools.svg"
                style={{
                  marginRight: "4px",
                }}
              />
              Column Option
            </Button>
          </>
        }
      >
        <ServerPaginatedTable
          columns={columns}
          data={state.refund}
          key={state.sortBy}
          isLoading={state?.isBusy}
          drawerOpen={hasColumnOptions}
          columnOptions={columnOptions}
          defaultSearch={false}
          drawerOnClose={() => toggleColumns()}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
          pagination={state.pagination}
          sortByGlobal={state.sortBy}
          handleSort={handleSort}
          toggleHiddenManual={toggleHiddenColumns}
          filterContent={
            <>
              <InputControl
                type="table-search"
                name="searchText"
                placeholder="Search"
                size="small"
                value={state.filters.searchText}
                onChange={(e) => onChangeFilter(e)}
                onClear={onSeachClear}
              />
              <DashBoardDateRangePicker
                onChangeDate={onChangeDate}
                isList={true}
                range={state.date}
                showRange={state.filters.date}
                isOpen={state.isCalenderOpen}
                handleIsOpen={handleIsOpen}
                handleDateChangeApply={handleDateChangeApply}
              />

              <Button
                type="button"
                size="medium"
                variant="text"
                color="gray"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => resetFilters()}
              >
                <CloseIcon />
              </Button>
            </>
          }
          onRowLink={(e) => {
            return `/store-admin/refund/details/${e.id}`;
          }}
        />
      </ContentLayout>
    </>
  );
};
