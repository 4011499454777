import { ContentLayout, usePermission, useTabs } from "../../../shared";
import * as React from "react";
import { Box, Tab, Button } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import { useGiftCard } from "../hooks/useGiftCard";
import { PreloadedCards } from "./PreloadedCards";
import { OpenCards } from "./OpenCards";
import { AllCards } from "./AllCards";
import { AppContext } from "../../../store";

export const GiftCardList = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { setCurentTab } = useTabs();
  const isCreateVisible = usePermission("gift-cards-create");

  const handleChange = (event, newValue) => {
    setCurentTab("giftCardList", newValue);
  };
  const { appState } = React.useContext(AppContext);
  const { giftCardList } = appState.currentTabs;
  return (
    <>
      <ContentLayout
        title="Gift Card"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate(`/gift-card/create`)}
              >
                Add Gift Card
              </Button>
            )}
          </>
        }
      >
        <TabContext value={giftCardList}>
          <Box
            className="sticky-Tab"
            sx={{ borderBottom: 1, borderColor: "divider", top: "88px" }}
          >
            <TabList onChange={handleChange}>
              <Tab label="All Cards" value="1" />
              <Tab label="Preloaded Cards" value="2" />
              <Tab label="Open Cards" value="3" />
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            <AllCards
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            <PreloadedCards

            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="3">
            <OpenCards />
          </TabPanel>
        </TabContext>
      </ContentLayout>
    </>
  );
};
