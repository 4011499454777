import {
  Box,
  Button,
  Card,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import InputControl from "../../../shared/components/InputControl";

export const StoresListTable = ({ details, isBusy, updateIncluded }) => {
  return (
    <Card sx={{ mb: "24px" }}>
      {/* <Stack p="20px 16px 16px">
        <InputControl
          type="table-search"
          placeholder="Search"
          //   value={searchText}
          //   onChange={(e) => handleProductsSearch(e.target.value)}
          //   onClear={onProductsSeachClear}
        />
      </Stack> */}
      <Stack direction="row">
        <Box width="100%">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height="48px"
            p="0 16px"
            bgcolor="rgba(0, 0, 0, 0.06)"
          >
            <Typography
              variant="subtitle1"
              fontWeight={600}
              lineHeight="19px"
              minWidth="160px"
            >
              Included Stores
            </Typography>
          </Stack>
          <Divider />
          <Stack
            sx={{
              flexDirection: "row",
              // gap: "20px",
            }}
          >
            <List
              component="div"
              disablePadding
              sx={{ width: "100%", bgcolor: "background.paper", p: 0 }}
            >
              {details?.included_stores?.map((data, i) => (
                <ListItemButton
                  key={i}
                  sx={{
                    pl: 4,
                    minHeight: "48.8px",
                    py: "6px",
                    borderBottom: "1px solid #E6E6E6",
                  }}
                  className="hover-btn"
                >
                  <ListItemText
                    primary={
                      <Typography
                        sx={{ display: "inline", fontWeight: 400 }}
                        component="span"
                        variant="subtitle2"
                        color="#171717"
                      >
                        {data.name}
                      </Typography>
                    }
                  />

                  <Button
                    className="btn"
                    size="small"
                    onClick={() => updateIncluded(data.id, "excluded_stores")}
                    disabled={isBusy}
                    sx={{
                      display: "none",
                      border: "none !important",
                      minWidth: "120px !important",
                      bgcolor: "rgba(0, 0, 0, 0.03)",
                      "&:hover": {
                        bgcolor: "rgba(0, 0, 0, 0.06)",
                      },
                    }}
                    color="info"
                  >
                    Move to Exclude
                  </Button>
                </ListItemButton>
              ))}
            </List>
          </Stack>
        </Box>
        <Box width="100%" borderLeft="1px solid #E6E6E6">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height="48px"
            p="0 16px"
            bgcolor="rgba(0, 0, 0, 0.06)"
          >
            <Typography
              variant="subtitle1"
              fontWeight={600}
              lineHeight="19px"
              minWidth="160px"
            >
              Excluded Stores
            </Typography>
          </Stack>
          <Divider />
          <Stack
            sx={{
              flexDirection: "row",
              // gap: "20px",
            }}
          >
            <List
              component="div"
              disablePadding
              sx={{ width: "100%", bgcolor: "background.paper", p: 0 }}
            >
              {details?.excluded_stores?.map((data, i) => (
                <ListItemButton
                  key={i}
                  sx={{
                    pl: 4,
                    minHeight: "48.8px",
                    py: "6px",
                    borderBottom: "1px solid #E6E6E6",
                  }}
                  className="hover-btn"
                >
                  <ListItemText
                    primary={
                      <Typography
                        sx={{ display: "inline", fontWeight: 400 }}
                        component="span"
                        variant="subtitle2"
                        color="#636363"
                      >
                        {data.name}
                      </Typography>
                    }
                  />

                  <Button
                    className="btn"
                    size="small"
                    onClick={() => updateIncluded(data.id, "included_stores")}
                    disabled={isBusy}
                    sx={{
                      display: "none",
                      border: "none !important",
                      minWidth: "120px !important",
                      bgcolor: "rgba(0, 0, 0, 0.03)",
                      "&:hover": {
                        bgcolor: "rgba(0, 0, 0, 0.06)",
                      },
                    }}
                    color="info"
                  >
                    Move to Include
                  </Button>
                </ListItemButton>
              ))}
            </List>
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};
