import React, { useEffect } from "react";
import {
  useTable,
  useAsyncDebounce,
  useGlobalFilter,
  useFilters,
} from "react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableHead,
  TableContainer,
  OutlinedInput,
  InputAdornment,
  Box,
  Paper,
  Stack,
} from "@mui/material";
import { useDebouncedCallback } from "use-debounce";
import { Loader } from "../../shared/components/Loader";

export const DraggableReactTable = (props) => {
  const {
    columns,
    data,
    tableTitle,
    headerRightContent = null,
    defaultSearch = false,
    searchBar = false,
    filterContent = null,
    onRowOrderChange,
    disableDrag = false,
    isLoading = false,
    maxHeight = "319px",
  } = props;
  const [records, setRecords] = React.useState([]);
  useEffect(() => {
    setRecords(data || []);
  }, [data]);

  const getRowId = React.useCallback((row) => {
    return row.id;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    preGlobalFilteredRows,
    state,
  } = useTable(
    {
      data: records,
      columns,
      getRowId,
    },
    useFilters,
    useGlobalFilter
  );

  const debouncedRowUpdate = useDebouncedCallback(
    // function
    (value) => {
      onRowOrderChange(value);
    },
    // delay in ms
    1000
  );

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    const updatedRecords = update(records, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragRecord],
      ],
    });
    setRecords(updatedRecords);
    debouncedRowUpdate(updatedRecords);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Paper className={"table-paper-container"}>
        <TableContainer
          sx={{ minWidth: 800, maxHeight: `calc(100vh - ${maxHeight})` }}
        >
          {tableTitle && (
            <CustomTableHeader2
              tableTitle={tableTitle}
              headerRightContent={headerRightContent}
            />
          )}
          {searchBar && (
            <GlobalFilterDrag
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setRecords={setGlobalFilter}
              filterContent={filterContent}
              defaultSearch={defaultSearch}
            />
          )}
          <DndProvider backend={HTML5Backend}>
            {
              <>
                {isLoading || (!isLoading && data.length !== rows.length) ? (
                  <Box
                    sx={{
                      padding: "10px",
                    }}
                  >
                    <Loader />
                  </Box>
                ) : (
                  <Table
                    {...getTableProps()}
                    sx={{
                      borderCollapse: "inherit",
                      overflow: "hidden",
                    }}
                  >
                    <TableHead>
                      {headerGroups.map((headerGroup) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                          {!disableDrag && (
                            <TableCell padding="checkbox"></TableCell>
                          )}
                          {headerGroup.headers.map((column) => (
                            <TableCell
                              sx={{
                                p: !column.padding && "10px",
                              }}
                              align={column?.align}
                              padding={column.padding || "normal"}
                              {...column.getHeaderProps()}
                            >
                              {column.render("Header")}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                      {rows.length > 0 ? (
                        rows.map(
                          (row, index) =>
                            prepareRow(row) || (
                              <Row
                                index={index}
                                disableDrag={disableDrag}
                                row={row}
                                moveRow={moveRow}
                                {...row.getRowProps()}
                              />
                            )
                        )
                      ) : (
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={20}
                            sx={{ py: 2, backgroundColor: "#F7F7F7" }}
                          >
                            <Typography
                              align="center"
                              variant="body2"
                              color={"#666666"}
                            >
                              No matches found
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                )}
              </>
            }
          </DndProvider>
        </TableContainer>
      </Paper>
    </Box>
  );
};

const DND_ITEM_TYPE = "row";

const Row = ({ row, index, moveRow, disableDrag }) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: () => ({ index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <TableRow ref={dropRef} style={{ opacity }}>
      {!disableDrag && (
        <TableCell
          padding={"normal"}
          sx={{
            borderBottom: "1px solid #E6E6E6",
            p: "2px",
          }}
          ref={dragRef}
        >
          <DragIndicatorIcon />
        </TableCell>
      )}
      {row.cells.map((cell) => {
        return (
          <TableCell
            align={cell.column?.align}
            padding={cell.column.padding || "normal"}
            sx={{
              borderBottom: "1px solid #E6E6E6",
              p: !cell.column.padding && "8px",
            }}
            {...cell.getCellProps()}
          >
            {cell.render("Cell")}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export function CustomTableHeader2({ tableTitle, headerRightContent }) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        py: "12px",
        px: "16px",
        gap: "16px",
        borderBottom: "1px solid #E6E6E6",
        height: "84px",
      }}
    >
      <Typography gutterBottom align="center" variant="subtitle1">
        {tableTitle}
      </Typography>
      {headerRightContent}
    </Stack>
  );
}

export function GlobalFilterDrag({
  preGlobalFilteredRows,
  globalFilter,
  setRecords,
  filterContent,
  defaultSearch,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setRecords(value || undefined);
  }, 200);
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        py: "20px",
        px: "16px",
        gap: "16px",
        borderBottom: "1px solid #E6E6E6",
      }}
    >
      {defaultSearch && (
        <OutlinedInput
          sx={{
            height: "48px",
          }}
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder="Search"
          size="small"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon size="large" />
            </InputAdornment>
          }
          endAdornment={
            value && (
              <InputAdornment
                sx={{ cursor: "pointer" }}
                onClick={(e) => {
                  setValue("");
                  onChange("");
                }}
                position="end"
              >
                <CloseIcon size="large" />
              </InputAdornment>
            )
          }
          fullWidth
        />
      )}
      {filterContent}
    </Stack>
  );
}
