import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import {
  AddorEditGiftCard,
  AllCardDetailsList,
  GiftCardList,
  PreloadedCardDetails,
} from "./containers";

export const GiftCard = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<GiftCardList />} />

        <Route
          element={<ProtectedRoute allowedRoute={["gift-cards-create"]} />}
        >
          <Route path="/create" element={<AddorEditGiftCard />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["gift-cards-view"]} />}>
          <Route path="/details/:id" element={<PreloadedCardDetails />} />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["gift-cards-modify"]} />}
        >
          <Route path="/edit/:id" element={<AddorEditGiftCard />} />
        </Route>

        <Route element={<ProtectedRoute allowedRoute={["gift-cards-view"]} />}>
          <Route
            path="/details/allCards/:id"
            element={<AllCardDetailsList />}
          />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["gift-cards-view"]} />}>
          <Route path="/details/cards/:id" element={<AllCardDetailsList />} />
        </Route>
      </Routes>
    </>
  );
};
