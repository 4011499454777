import { ContentLayout, CustomeDialog, Loader } from "../../../shared";
import * as React from "react";
import {
  Link,
  Chip,
  Stack,
  Switch,
  Typography,
  Button,
  TextField,
  Box,
  Tab,
  Grid,
  Card,
} from "@mui/material";
import { CustomTable } from "../../../shared";
import { useUbereats } from "../hook/useUbereats";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { AddUberStoreIdDialog } from "../components/AddUberStoreIdDialog";
import { sortItems } from "../../../utils";
import SimpleReactValidator from "simple-react-validator";
import InputControl from "../../../shared/components/InputControl";

export const UbereatsList = () => {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const {
    state,
    handleAllMenu,
    toggleAction,
    handlePushMenu,
    onChange,
    handleUpdate,
    isEditable,
    isErrorModal,
    currentTab,
    setCurentTab,
    optionsMenuTemplate,
    handleInputChange,
    handleSubmit,
  } = useUbereats({
    isList: true,
  });
  const handleChange = (event, newValue) => {
    setCurentTab("ubereats", newValue);
  };
  const onSubmit = () => {
    if (validator.current.allValid()) {
      handleSubmit();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const ActionButtons = ({ value, id }) => {
    const isJson = (str) => {
      try {
        return JSON.parse(str);
      } catch (error) {
        return false;
      }
    };
    let isError = isJson(value.ubereats_message);
    return (
      <div className="action-buttons">
        {isError && value.ubereats_status !== 0 && (
          <>
            <Stack gap="6px">
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 500,
                  color: "#C42B1C",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => isErrorModal(true, isError)}
              >
                Show Error
              </Typography>

              <Typography variant="caption" color="#808080">
                Last uploading at the {value?.ubereats_last_pushed_at} failed.
              </Typography>
            </Stack>
          </>
        )}
        {value?.ubereats_status === 1 ? (
          <>
            {state.isSaveButton && state?.store_id === id ? (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="4px"
                  className="uber-loader"
                >
                  <Loader />
                  <Typography
                    variant="subtitle2"
                    fontWeight={500}
                    color="#C07302"
                  >
                    Uploading...
                  </Typography>
                </Stack>
              </>
            ) : (
              <div
                onClick={() =>
                  handlePushMenu(value?.store_id, state.ubereatsData)
                }
              >
                <Stack>
                  <Stack
                    direction="row"
                    className="hover-icon-link"
                    gap={1}
                    sx={{ width: "fit-content" }}
                    alignSelf="end"
                  >
                    <img src="/icons/icon_blue_refresh.svg" />
                    <Link underline="none" component="button" variant="body2">
                      <Typography fontWeight={600}>Push Menu</Typography>
                    </Link>
                  </Stack>
                  {value.ubereats_last_pushed_at ? (
                    <Typography variant="caption" color="#808080">
                      The last upload was on {value?.ubereats_last_pushed_at}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Stack>
              </div>
            )}
          </>
        ) : (
          ""
        )}
        {isEditable && (
          <Switch
            checked={value?.ubereats_status === 1}
            onClick={(e) =>
              value?.ubereats_status
                ? handleUpdate(value, e.target.checked, "active")
                : toggleAction(true, e.target.checked, value)
            }
          />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Store",
        accessor: "store_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
        Cell: ({ value, row }) => {
          return (
            <Stack direction="row" gap={2}>
              <Typography> {value}</Typography>
              {row?.original?.ubereats_store_id && (
                <Chip
                  label={row?.original?.ubereats_store_id}
                  color="success"
                  variant="outlined"
                  size="small"
                  disabled
                  sx={{ mr: 1, borderRadius: "8px" }}
                />
              )}
            </Stack>
          );
        },
      },
      {
        Header: "Action",
        id: "id",
        accessor: "id",
        align: "right",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <ActionButtons value={row.original} id={row.original.store_id} />
          );
        },
      },
    ],
    [state.isSaveButton, state.ubereatsData]
  );

  return (
    <>
      <ContentLayout
        title="Ubereats"
        rightContent={
          <>
            {currentTab === "1" ? (
              <LoadingButton
                type="button"
                size="medium"
                variant="contained"
                onClick={handleAllMenu}
                disabled={state?.isSaveButton}
                loading={state?.isAllSaveButton}
                startIcon={<img src="/icons/icon_refresh.svg" />}
              >
                Push Menu
              </LoadingButton>
            ) : (
              <Button
                type="button"
                size="medium"
                variant="contained"
                onClick={onSubmit}
                disabled={state.isSaveButtonBusy}
              >
                Save
              </Button>
            )}
          </>
        }
      >
        <TabContext value={currentTab}>
          <Box
            className="sticky-Tab"
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              top: "88px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TabList onChange={handleChange}>
              <Tab label="Stores" value="1" />
              {/* <Tab label="Settings" value="2" /> */}
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            <CustomTable
              columns={columns}
              data={state?.ubereatsData}
              pagination={false}
              isLoading={state?.isBusy}
              tableId="ubereats"
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            <Card sx={{ p: "32px 24px", mb: "24px" }}>
              {validator.current.purgeFields()}
              {state.isBusy ? (
                <Loader />
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      sx={{ width: "150px" }}
                    >
                      Menu Template
                    </Typography>
                  </Grid>
                  <Grid item sm={8}>
                    <Stack width={"50%"} gap="20px">
                      <InputControl
                        type="dropdown"
                        disableClearable
                        options={optionsMenuTemplate ?? []}
                        getOptionLabel={(option) => option.label}
                        value={
                          (optionsMenuTemplate?.length > 0 &&
                            state.dataMenu.menu_id &&
                            optionsMenuTemplate?.find(
                              (c) => c.value === state.dataMenu.menu_id
                            )) ||
                          null
                        }
                        onChange={(e, newValue) => {
                          handleInputChange({
                            target: {
                              name: "menu_id",
                              value: newValue.value,
                            },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Menu Template"
                            required
                            error={validator.current.message(
                              "Menu Template",
                              state.dataMenu.menu_id,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Menu Template",
                              state.dataMenu.menu_id,
                              "required"
                            )}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </Card>
          </TabPanel>
        </TabContext>
      </ContentLayout>
      <CustomeDialog
        open={state?.isOpen}
        handleClose={() => toggleAction(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "423px", height: "235px", p: "16px" },
        }}
        title="Store"
        content={
          <AddUberStoreIdDialog
            onChange={onChange}
            state={state}
            handleUpdate={handleUpdate}
          />
        }
      />
      <CustomeDialog
        open={state.isErrorOpen}
        handleClose={() => isErrorModal(false, null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "423px", height: "155px", p: "24px 24px 16px" },
        }}
        isHeader={false}
        content={
          <Stack gap="12px">
            <Typography variant="subtitle2" fontWeight={600}>
              {state.errorMessages?.code}
            </Typography>
            <Typography variant="subtitle2">
              {state.errorMessages?.message}
            </Typography>
            <Button
              variant="contained"
              sx={{
                width: "fit-content",
                alignSelf: "end",
              }}
              onClick={() => isErrorModal(false, null)}
            >
              Ok
            </Button>
          </Stack>
        }
      />
    </>
  );
};
