import {
  ContentLayout,
  CustomTable,
  DeleteModal,
  HeaderBreadcrumbs,
} from "../../../shared";
import * as React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { POS_BASE_URL, sortItems } from "../../../utils";
import { useFuturePrice } from "../hooks";
import { LoadingButton } from "@mui/lab";

export const FuturePriceList = () => {
  const navigate = useNavigate();
  const {
    state,
    handleDeleteFuturePrice,
    toggleModal,
    handleImportFuturePriceList,
    isCreateVisible,
    isDeletable,
    isEditable,
    onLogoClick,
    AlbumArtRef,
  } = useFuturePrice({
    isList: true,
    isProduct: false,
  });

  const ActionButtons = ({ id, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
        {isEditable && (
          <Link to={`/future-price/edit/${id}`}>
            <img alt="details" src="/icons/arrow_forward.svg" />
          </Link>
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Product",
        accessor: "product_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Price Level",
        accessor: "price_level_name",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Future Price",
        accessor: "future_price",
        Cell: ({ value }) => {
          return "$" + Number(value)?.toFixed(2);
        },
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Current Price",
        accessor: "current_price",
        Cell: ({ value }) => {
          return "$" + Number(value)?.toFixed(2);
        },
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => {
                toggleModal(true, row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <ContentLayout
        title="Future Pricing"
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Future Pricing", href: "/future-price" },
              ]}
            />
          </>
        }
        rightContent={
          <>
            <Button
              component={Link}
              to={`${POS_BASE_URL}sample_import_files/future_price.csv`}
              type="button"
              size="medium"
              variant="text"
            >
              Download Template
            </Button>
            <div>
              <input
                id="contained-button-file"
                type="file"
                onChange={(e) => {
                  handleImportFuturePriceList(e);
                }}
                style={{ display: "none" }}
                ref={AlbumArtRef}
              />
              <LoadingButton
                type="button"
                variant="text"
                loading={state.importBtnLoading}
                onClick={onLogoClick}
              >
                Import
              </LoadingButton>
            </div>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("/future-price/create")}
              >
                Add New
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          defaultSearch={true}
          tableId="future-price"
          // filterContent={
          //   <>
          //     <InputControl
          //       type="table-search"
          //       value={state.filters.searchText}
          //       onChange={(e) => onChangeFilter(e)}
          //       onClear={onSeachClear}
          //     />
          //   </>
          // }
          columns={columns}
          data={state?.futureprice ?? []}
          isLoading={state?.isBusy}
          onRowLink={(e) => {
            return `/future-price/edit/${e.id}`;
          }}
        />
      </ContentLayout>
      <DeleteModal
        open={state?.isOpen}
        handleClose={() => toggleModal(false)}
        onConfirm={() => handleDeleteFuturePrice()}
      />
    </>
  );
};
