import {
  ContentLayout,
  CustomeDialog,
  DeleteModal,
  DraggableReactTable,
  HeaderBreadcrumbs,
} from "../../../shared";
import * as React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InputControl from "../../../shared/components/InputControl";
import { useMenuTemplate } from "../hooks";
import { useParams } from "react-router-dom";
import { CategoryProductDialoginMenuTemplate } from "../components";

export const MenuTemplateProductList = () => {
  const { id, cid } = useParams();
  const {
    state,
    isDeletable,
    isCreateVisible,
    onChangeProductFilter,
    onProductSeachClear,
    onProductOrderChange,
    toggleProductModal,
    handleDeleteProductMenuTemplate,
    HandleFilterProduct,
    handlePoductSelect,
    handleAssignProducttoMenuTemplate,
    handleProductModal,
  } = useMenuTemplate({
    isList: true,
    menuId: id,
    categoryId: cid,
    isMenuId: id,
  });

  const filterState = state.menuTemplates.find((item) => id.includes(item.id));
  const filterCategoryState = state.menuTemplateCategory.find((item) =>
    cid.includes(item.id)
  );
  const ActionButtons = ({ deleteAction }) => {
    return (
      <div className="action-buttons">
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Products",
        accessor: "product_name",
      },
      {
        Header: "PLU Code",
        accessor: "product_plu",
        disableGlobalFilter: true,
      },

      {
        Header: "Action",
        disableGlobalFilter: true,
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => {
                toggleProductModal(row?.id);
              }}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <ContentLayout
        title={filterCategoryState?.name}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Menu Template", href: "/menu-template" },
                {
                  name: filterState?.name,
                  href: `/menu-template/details/${id}`,
                },
                { name: filterCategoryState?.name },
              ]}
            />
          </>
        }
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => handleProductModal(true)}
              >
                Add Products
              </Button>
            )}
          </>
        }
      >
        <DraggableReactTable
          columns={columns}
          data={state?.menuTemplateProduct}
          isLoading={state?.isBusy}
          defaultSearch={false}
          searchBar={true}
          maxHeight="202px"
          onRowOrderChange={onProductOrderChange}
          filterContent={
            <>
              <InputControl
                type="table-search"
                name="searchText"
                placeholder="Search"
                size="small"
                value={state.productFilters.searchText}
                onChange={(e) => onChangeProductFilter(e)}
                onClear={onProductSeachClear}
              />
            </>
          }
        />
      </ContentLayout>
      <CustomeDialog
        open={state.isCreateOpen}
        handleClose={() => handleProductModal(false)}
        PaperProps={{
          sx: { width: "423px", height: "535px", p: "16px" },
        }}
        title="Products"
        content={
          <CategoryProductDialoginMenuTemplate
            isLoading={state.isBusy}
            Items={state.productDetails.filteredPoducts}
            onSelect={handlePoductSelect}
            selectedItems={state.productDetails.selectedPoducts}
            onSubmit={handleAssignProducttoMenuTemplate}
            onSearch={HandleFilterProduct}
            existedProductList={state.productDetails.productList}
            disabled={state.productDetails.isSaveButtonBusy}
          />
        }
      />
      <DeleteModal
        open={state.isCategoryOpen}
        handleClose={() => toggleProductModal()}
        onConfirm={() => handleDeleteProductMenuTemplate()}
      />
    </>
  );
};
