import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { usePermission } from "../../../shared";
import { useDebouncedCallback } from "use-debounce";
import { DownloadRefundList, getRefund, getRefundById } from "../api/refundApi";
import qs from "qs";
import { errorMessage } from "../../../utils";
import fileDownload from "js-file-download";
import { addDays } from "date-fns";
import moment from "moment";
import { TableStateContext } from "../../../store";

export const useStoreRefund = ({ isRefund, refundId }) => {
  const isDeletable = usePermission("refund-delete");
  const isCreateVisible = usePermission("refund-create");
  const isEditable = usePermission("refund-modify");
  const isVisible = usePermission("refund-view");
  const [state, setState] = useImmer({
    isBusy: false,
    refund: [],
    sortBy: [],
    pagination: {
      pageIndex: 1,
      pageSize: 50,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    isOpen: false,
    id: null,
    refund_number: "",
    isSaveButtonBusy: false,
    order: [],
    display_name: "",
    httpStatusCodes: {
      details: "",
    },
    filters: {
      searchText: "",
      date: [],
    },
    data: {
      id: "",
      refund_number: "",
      refund_date: "",
      total_product_count: "",
      total_quantity_ordered: "",
      channel_id: "",
      channel_name: "",
      user_id: "",
      store_id: "",
      subtotal: "",
      tax_amount: "",
      order_id: "",
      terminal_id: "",
      grand_total: "",
      discount_amount: "",
      products: [],
      payments: [],
      order: [],
      status: "",
    },
    isCalenderOpen: false,
    date: [
      {
        startDate: addDays(new Date(), -6),
        endDate: new Date(),
        key: "selection",
      },
    ],
  });
  const PAGE_NAME = "storerefunds";
  const {
    filterState: {
      params: { storerefunds },
      params: {
        storerefunds: { columnOptions },
      },
    },
    initialState,
    filterStateDispatch,
  } = useContext(TableStateContext);
  const toggleHiddenColumns = (column) => {
    filterStateDispatch({
      type: "UPDATE_COLUMNS",
      page: PAGE_NAME,
      value: column,
    });
  };
  const debounced = useDebouncedCallback((value) => {
    const { date } = state.filters;
    const { pagination, sortBy } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filters },
    });
    getRefunds({
      searchText: value,
      date,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  }, 1000);
  useEffect(() => {
    const { date, searchText } = storerefunds.filterApplied;
    const { pagination, sortBy } = storerefunds;
    if (isRefund) {
      setState((draft) => {
        draft.pagination = pagination;
        draft.sortBy = sortBy;
        if (Object.keys(date).length) {
          draft.date = [
            {
              endDate: new Date(date[0].endDate),
              startDate: new Date(date[0].startDate),
              key: "selection",
            },
          ];
        }
        draft.filters = {
          ...storerefunds.filterApplied,
          date: date.length
            ? [
                {
                  endDate: new Date(date[0]?.endDate),
                  startDate: new Date(date[0]?.startDate),
                  key: "selection",
                },
              ]
            : [],
        };
      });
      getRefunds({
        searchText: searchText,
        date,
        pagination: {
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
        },
        sortBy,
      });
    }
  }, [isRefund]);

  const getRefunds = async ({
    searchText = null,
    date = [],
    pagination,
    sortBy = [],
  }) => {
    let params = {};

    if (searchText) {
      params = { ...params, search: searchText };
    }
    if (date.length) {
      params = {
        ...params,
        start_date: moment(date[0].startDate).format("YYYY-MM-DD"),
        end_date: moment(date[0].endDate).format("YYYY-MM-DD"),
      };
    }
    if (sortBy.length > 0) {
      const sortByParams = sortBy.reduce(
        (obj, item) =>
          Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
        {}
      );
      params = {
        ...params,
        sort: sortByParams,
      };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getRefund(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.refund = res.data.refunds;
          draft.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const handleRefundDetailsList = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getRefundById(refundId);
      if (res.data) {
        setState((draft) => {
          draft.data = res.data;
          draft.display_name = res.data.display_name;
          draft.refund_number = res.data.refund_number;
          draft.isBusy = false;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.httpStatusCodes.details = err.response.status;
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (refundId) {
      handleRefundDetailsList();
    }
  }, [refundId]);

  const onChangeFilter = (e) => {
    setState((draft) => {
      draft.filters.searchText = e?.target?.value;
    });
    debounced(e?.target?.value);
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: { ...state.filters, [e.target.name]: e.target.value },
      },
    });
  };

  const resetFilters = () => {
    if (state.filters.date.length || state.filters.searchText) {
      getRefunds({
        pagination: {
          pageIndex: 1,
          pageSize: state.pagination.pageSize,
        },
        sortBy: state.sortBy,
      });

      setState((draft) => {
        draft.filters.date = [];
        draft.filters.searchText = "";
        draft.date = [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ];
      });
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initialState.params.storerefunds.filterApplied,
      });
    }
  };
  const onSeachClear = () => {
    const { date } = state.filters;
    const { pagination, sortBy } = state;
    getRefunds({
      search: null,
      date,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.filters.searchText = "";
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: { ...state.filters, searchText: "" } },
    });
  };

  const gotoPage = (page) => {
    const { searchText, date } = state.filters;
    const { sortBy } = state;
    getRefunds({
      search: searchText,
      date,
      pagination: {
        pageIndex: page,
        pageSize: state.pagination.pageSize,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };

  const setPageSize = (e) => {
    const { searchText, date } = state.filters;
    const { sortBy } = state;
    getRefunds({
      search: searchText,
      date,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };

  const handleDownloadStoreRefundList = async () => {
    try {
      const { date, searchText } = state.filters;
      const { sortBy } = state;
      let params = { export: 1 };
      if (date.length) {
        params = {
          ...params,
          start_date: moment(date[0].startDate).format("YYYY-MM-DD"),
          end_date: moment(date[0].endDate).format("YYYY-MM-DD"),
        };
      }

      if (searchText) {
        params = { ...params, search: searchText };
      }

      if (sortBy.length > 0) {
        const sortByParams = sortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };

      const res = await DownloadRefundList(query);
      const content = res.headers["content-type"];
      fileDownload(res.data, `StoreRefundList.xlsx`, content);
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleSort = (sortBy) => {
    const { searchText, date } = state.filters;
    const { pagination } = state;
    getRefunds({
      searchText,
      date,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_SORT",
      page: PAGE_NAME,
      value: sortBy,
    });
    setState((draft) => {
      draft.sortBy = sortBy;
    });
  };

  const onChangeDate = (e) => {
    setState((draft) => {
      draft.date = e;
    });
  };

  const handleIsOpen = (e) => {
    setState((draft) => {
      draft.isCalenderOpen = e;
    });
  };
  const handleDateChangeApply = () => {
    const { searchText } = state.filters;
    const { pagination, sortBy } = state;
    setState((draft) => {
      draft.filters.date = state.date;
      draft.isCalenderOpen = false;
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: {
          date: state.date,
        },
      },
    });
    getRefunds({
      search: searchText,
      date: state?.date,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  };

  return {
    state,
    onChangeFilter,
    resetFilters,
    onSeachClear,
    isCreateVisible,
    isDeletable,
    isEditable,
    isVisible,
    gotoPage,
    setPageSize,
    handleDownloadStoreRefundList,
    handleSort,
    onChangeDate,
    handleIsOpen,
    handleDateChangeApply,
    toggleHiddenColumns,
    columnOptions,
  };
};
