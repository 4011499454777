import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import {
  createConditions,
  deleteConditionById,
  editConditions,
  getCategoryWiseProductVariants,
  getConditionDetails,
  getConditions,
} from "../api/ConditionsApi";
import { usePermission } from "../../../shared";
import qs from "qs";

export const useConditions = ({
  isConditions,
  conditionId,
  isCreate,
  isProduct,
}) => {
  const navigate = useNavigate();
  const { appState } = useContext(AppContext);
  const { globalData } = appState;
  const isEditable = usePermission("condition-modify");
  const isDeletable = usePermission("condition-delete");
  const isCreateVisible = usePermission("condition-create");
  const isVisible = usePermission("condition-view");
  const [state, setState] = useImmer({
    isBusy: false,
    conditions: [],
    initialLoad: true,
    isSaveButtonBusy: false,
    condition_name: "",
    id: "",
    isOpen: false,
    name: "",
    customers: [],
    productList: [],
    allReadyIds: [],
    data: {
      name: "",
      scope: "",
      logical_operator: 1,
      rules: [
        {
          id: "",
          rule_type: "",
          operator: "",
          value: [],
        },
      ],
    },
  });
  useEffect(() => {
    if (isConditions) {
      getCondition();
    }
  }, [isConditions]);

  const getCondition = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });
      const res = await getConditions();
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.conditions = res.data;
          draft.initialLoad = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
      });
    }
  };

  const handleConditionsDetailsList = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getConditionDetails(conditionId);
      if (res.data) {
        setState((draft) => {
          draft.data = res.data;
          draft.condition_name = res.data.name;
          draft.isBusy = false;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.httpStatusCodes.details = err.response.status;
        draft.isBusy = false;
      });
    }
  };

  useEffect(() => {
    if (conditionId) {
      handleConditionsDetailsList();
    }
  }, [conditionId, isCreate]);

  const onSaveConditions = async () => {
    const payload = {
      ...state.data,
      rules: state.data.rules.map((item) => {
        if (Array.isArray(item.value)) {
          return { ...item, value: item.value.map((item) => item.value) };
        } else {
          return item;
        }
      }),
    };
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await createConditions(payload);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate("/coupon-rules");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const onUpdateConditions = async (conditionId) => {
    const payload = {
      ...state.data,
      rules: state.data.rules.map((item) => {
        if (Array.isArray(item.value)) {
          return { ...item, value: item.value.map((item) => item.value) };
        } else {
          return item;
        }
      }),
    };
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await editConditions(conditionId, payload);
      if (res.success) {
        successMessage("Updated Successfully");
        navigate("/coupon-rules");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onChange = (e, index, type) => {
    const { value, name, checked } = e.target;
    if (type === "product") {
      if (checked) {
        setState((draft) => {
          draft.data.rules = [
            ...draft.data.rules.map((data, i) =>
              i === index ? { ...data, [name]: [...data[name], value] } : data
            ),
          ];
        });
      } else {
        setState((draft) => {
          draft.data.rules = [
            ...draft.data.rules.map((data, i) =>
              i === index
                ? {
                    ...data,
                    [name]: [
                      ...data[name].filter((v) => v.value !== value.value),
                    ],
                  }
                : data
            ),
          ];
        });
      }
    } else if (type === "productHead") {
      if (checked) {
        const existingData = state.data.rules[index][name] || [];
        const AlraedyIDs = existingData.map((data) => data.value);
        let filterData = value.products.filter(
          (v) => !AlraedyIDs.includes(v.value)
        );
        setState((draft) => {
          draft.data.rules = [
            ...draft.data.rules.map((data, i) =>
              i === index
                ? {
                    ...data,
                    [name]: [...new Set([...data[name], ...filterData])],
                  }
                : data
            ),
          ];
        });
      } else {
        let allReadyIds = value.products.map((v) => v.value);
        setState((draft) => {
          draft.data.rules = [
            ...draft.data.rules.map((data, i) =>
              i === index
                ? {
                    ...data,
                    [name]: [
                      ...data[name].filter(
                        (v) => !allReadyIds.includes(v.value)
                      ),
                    ],
                  }
                : data
            ),
          ];
        });
      }
    } else {
      setState((draft) => {
        draft.data.rules = [
          ...draft.data.rules.map((data, i) =>
            i === index ? { ...data, [name]: value } : data
          ),
        ];
      });
    }
  };
  const onDeleteProducts = (value, index) => {
    setState((draft) => {
      draft.data.rules = [
        ...draft.data.rules.map((data, i) =>
          i === index
            ? {
                ...data,
                value: [...data.value.filter((v) => v.value !== value.value)],
              }
            : data
        ),
      ];
    });
  };

  const onAddConditions = (e) => {
    setState((draft) => {
      draft.data.rules.push({
        id: "",
        rule_type: "",
        operator: "",
        value: "",
      });
    });
  };
  const handleScopeDropdown = (e) => {
    setState((draft) => {
      draft.data.scope = e?.value;
      draft.data.rules = [
        {
          id: "",
          rule_type: "",
          operator: "",
          value: "",
        },
      ];
    });
  };
  const handleConditionDropdown = (e) => {
    setState((draft) => {
      draft.data.logical_operator = e?.value;
    });
  };
  const handleChange = (e, index, name) => {
    const { value, label } = e;
    setState((draft) => {
      draft.data.rules = [
        ...draft.data.rules.map((data, i) =>
          i === index ? { ...data, [name]: value } : data
        ),
      ];
    });
    if (name === "rule_type") {
      setState((draft) => {
        draft.data.rules = [
          ...draft.data.rules.map((data, i) =>
            i === index
              ? {
                  ...data,
                  id: "",
                  operator: "",
                  value: "",
                }
              : data
          ),
        ];
      });
    }
  };
  const handleOnChange = (e, index, type) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data.rules = [
        ...draft.data.rules.map((data, i) =>
          i === index ? { ...data, [name]: value } : data
        ),
      ];
    });
  };
  const onValueChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };
  const handleDelete = (e, index) => {
    setState((draft) => {
      draft.data.rules = draft.data.rules.filter((item, i) => index !== i);
    });
  };
  const handleModal = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isOpen = !draft.isOpen;
    });
  };
  const handleDeleteDiscount = async () => {
    try {
      const res = await deleteConditionById(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");

        setState((draft) => {
          draft.conditions = draft.conditions.filter(
            (item) => item.id !== state.id
          );
        });
        handleModal();
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleConditionType = (e) => {
    setState((draft) => {
      draft.data.logical_operator = +e.target.value;
    });
  };
  const handleGetCategoryWiseProduct = async () => {
    const product_types = ["bundle", "platter", "retail"];
    try {
      let params = {};

      if (product_types.length) {
        params = { ...params, product_types: product_types };
      }

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };
      const res = await getCategoryWiseProductVariants(query);

      if (res.data) {
        setState((draft) => {
          draft.productList = res.data
            .map((v) => {
              return (
                v.products.length > 0 && {
                  ...v,
                  products: v.products
                    .map((val) => {
                      return { value: val.id, label: val.name };
                    })
                    .filter(Boolean),
                }
              );
            })
            .filter(Boolean);
        });
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (isProduct) {
      handleGetCategoryWiseProduct();
    }
  }, [isProduct]);
  const onDeleteCustomerSelect = (data, i, index) => {
    setState((draft) => {
      draft.data.rules[index].value = state.data.rules[index].value?.filter(
        (item) => {
          return item.value !== data.value;
        }
      );
    });
  };
  return {
    state,
    globalData,
    onUpdateConditions,
    onSaveConditions,
    onAddConditions,
    onChange,
    handleScopeDropdown,
    handleConditionDropdown,
    handleChange,
    handleOnChange,
    handleDelete,
    handleModal,
    handleDeleteDiscount,
    onValueChange,
    isVisible,
    isCreateVisible,
    isEditable,
    isDeletable,
    handleConditionType,
    handleGetCategoryWiseProduct,
    onDeleteProducts,
    onDeleteCustomerSelect,
  };
};
