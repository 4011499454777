import { Box } from "@material-ui/core";
import React from "react";
import { useDrag } from "react-dnd";

export const GarbageProduct = ({ product, i }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "prd",
    item: { productId: product.id, type: "garbage" },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  return (
    <>
      <Box
        ref={drag}
        sx={{
          fontSize: "14px",
          fontWeight: "500",
          borderBottom: "1px solid #E6E6E6",
          padding: "12px 16px",
          cursor: "grab",
        }}
      >
        {product.name}
        <Box
          sx={{
            fontSize: "12px",
            fontWeight: "400",
            color: "#666",
          }}
        >
          {product.price}
        </Box>
      </Box>
    </>
  );
};
