import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

export const ChequeDetailsTable = ({ data }) => {
  return (
    <Paper sx={{ boxShadow: "none" }}>
      <TableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell align="left">Cheque Name</TableCell>
              <TableCell align="left">Cheque Number</TableCell>
              <TableCell align="right" sx={{}}></TableCell>
              <TableCell align="right">Cheque Amount</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.cheques?.map((item, index) => {
              return (
                <>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                      }}
                      key={0}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                      }}
                      key={1}
                    >
                      {item.cheque_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                      }}
                      key={2}
                    >
                      {item.cheque_number}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                      }}
                      key={3}
                      align="right"
                    ></TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                      }}
                      key={3}
                      align="right"
                    >
                      ${item.cheque_amount}
                    </TableCell>
                  </TableRow>
                </>
              );
            })}

            <TableRow>
              <TableCell colSpan={3}></TableCell>
              <TableCell
                // colSpan={1}
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  borderLeft: "1px solid #E6E6E6",
                  fontSize: "16px",
                }}
                align="left"
              >
                Cheque Total
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  fontWeight: "500",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                align="right"
              >
                ${data.total_cheque}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}></TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  borderLeft: "1px solid #E6E6E6",
                  fontSize: "16px",
                }}
              >
                Cheque Total from Eod
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                align="right"
              >
                ${data.eod_total_cheque}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={3}></TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  borderLeft: "1px solid #E6E6E6",
                  fontSize: "16px",
                }}
              >
                Variance
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  fontSize: "16px",
                  fontWeight: 600,
                  color: data.variance.includes("-") ? "#C42B1C" : "#0F875B",
                }}
                align="right"
              >
                ${data.variance}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
