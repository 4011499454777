import * as React from "react";
import {
  CustomeDialog as CustomDialog,
  ServerPaginatedTable,
} from "../../../shared";
import { Button, Stack, Checkbox, Chip, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import optInPic from "../../../assets/images/Icons/ic_tick_filled_blue.svg";
import optOutPic from "../../../assets/images/Icons/ic_cancel-opt-out.svg";
import useReviewAndRatings from "../hooks/useReviewAndRatings";
import InputControl from "../../../shared/components/InputControl";
import { LoadingButton } from "@mui/lab";
import ReviewListFilterSection from "./ReviewListFilterSection";

export const ReviewList = () => {
  const {
    state,
    status,
    publishedStatus,
    gotoPage,
    handleSortBy,
    setPageSize,
    handleDialogOpen,
    handleApplyFilter,
    handleClearFilter,
    handleTableSearch,
    handleDialogClose,
    handleReviewExport,
    handleClearAllFilter,
    handleReviewTextChange,
    handleAutocompleteServerSearch,
    handleReviewFilterSelection,
    handleDateChangeApply,
    handleProductSearchClear,
    handleProductFetch,
    handleOpenDateRangeSelection,
  } = useReviewAndRatings({ table: "allReviews" });

  const ActionButtons = ({ row }) => {
    return (
      <div className="action-buttons">
        <Link to={"review/" + row.id}>
          <img alt="details" src="/icons/arrow_forward.svg" />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Review ID",
        accessor: "id",
      },
      {
        Header: "Customer Name ",
        accessor: "customer_name",
      },
      {
        Header: "Submitted Date & Time",
        accessor: "submitted_date",
      },
      {
        Header: "Rating",
        accessor: "rating",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="4px" height="16px">
              <img alt="star" src="/icons/star-yellow.svg" />
              {row.original.rating}
            </Stack>
          );
        },
      },
      {
        Header: "Product",
        accessor: "product_name",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          const label =
            value === 0 ? "Pending" : value === 1 ? "Approved" : "Rejected";
          const color =
            value === 0 ? "#C07302" : value === 1 ? "#0F875B" : "#C42B1C";
          const backgroundColor =
            value === 0 ? "#FFF4CE" : value === 1 ? "#E1FBF2" : "#FFE7E3";
          return (
            <Chip
              label={label}
              variant="filled"
              size="small"
              sx={{
                color,
                backgroundColor,
              }}
            />
          );
        },
      },
      {
        Header: "Authorised By",
        accessor: "authorised_by",
        Cell: ({ value }) => {
          return value ?? "--";
        },
      },
      {
        Header: "Publish Status",
        accessor: "published_status",
        align: "center",
        Cell: ({ row }) => {
          return (
            <Box
              mr="20px"
              component="img"
              src={row.original.published_status ? optInPic : optOutPic}
              alt={"published_status-" + row.original.id}
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row) => {
          return <ActionButtons row={row} />;
        },
      },
    ],
    []
  );

  return (
    <>
      <ServerPaginatedTable
        columns={columns}
        data={state.review.list}
        isLoading={state.review.isLoading}
        gotoPage={gotoPage}
        searchBar={true}
        defaultSearch={false}
        handleSort={handleSortBy}
        setPageSize={setPageSize}
        pagination={state.review.pagination}
        sortByGlobal={state.review.sortBy}
        filterContent={
          <React.Fragment>
            <InputControl
              type="table-search"
              name="search"
              placeholder="Search"
              value={state.review.search}
              onChange={handleTableSearch}
              onClear={() => handleTableSearch({ target: { value: "" } })}
            />
            <Button
              type="button"
              size="medium"
              variant="text"
              onClick={handleDialogOpen}
            >
              <img
                alt="export"
                src="/icons/ic_export.svg"
                style={{
                  marginRight: "4px",
                }}
              />
              Export
            </Button>
          </React.Fragment>
        }
        filterApplied={state.filter.appliedFilters}
        onAllFilterClear={handleClearAllFilter}
        onFilterClear={handleClearFilter}
        filterSection={
          <ReviewListFilterSection
            {...{
              state,
              statusList: status,
              publishedStatusList: publishedStatus,
              productsList: state.product.options,
              ratingsList: state.rating.options,
              handleReviewFilterSelection,
              handleOpenDateRangeSelection,
              handleDateChangeApply,
              handleAutocompleteServerSearch,
              handleApplyFilter,
              handleProductFetch,
              handleProductSearchClear,
            }}
          />
        }
        onRowLink={(row) => "review/" + row.id}
      />
      <CustomDialog
        open={state.dialog.open}
        handleClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "374px", height: "206px", p: "16px" },
        }}
        title="Export Reviews"
        content={
          <Stack
            mt="25px"
            gap="25px"
            height="100%"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              alignItems="center"
              gap="16px"
              margin="auto 0"
            >
              <Checkbox
                onChange={handleReviewTextChange}
                disableRipple
                checked={state.review.export.include_review_text}
                sx={{
                  p: "0",
                }}
              />
              <Typography fontWeight={600} lineHeight="19px">
                Include “
                <Typography component="span" fontWeight="bold">
                  Review Text
                </Typography>
                ” in your export.
              </Typography>
            </Stack>

            <LoadingButton
              variant="contained"
              sx={{
                width: "120px",
                alignSelf: "end",
              }}
              loading={state.review.export.loading}
              onClick={() => handleReviewExport()}
            >
              Export
            </LoadingButton>
          </Stack>
        }
      />
    </>
  );
};
