import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";

export const AddOrEditAccountDialog = (props) => {
  const { data, handleInputChange, onSubmitBankAccount, isBusy } = props;
  const { name, number, bsb, alias } = data;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const onSubmit = () => {
    if (validator.current.allValid()) {
      onSubmitBankAccount();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  return (
    <Stack
      sx={{
        mt: "24px",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          height="72px"
        >
          <Typography variant="subtitle1">Account Name</Typography>
          <InputControl
            label="Account Name"
            name="name"
            width="434px"
            required
            value={name}
            onChange={handleInputChange}
            error={validator.current.message("Account Name", name, "required")}
            helperText={validator.current.message(
              "Account Name",
              name,
              "required"
            )}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          height="72px"
        >
          <Typography variant="subtitle1">Account Number</Typography>
          <InputControl
            type="number"
            label="Account Number"
            name="number"
            width="434px"
            required
            value={number}
            onChange={handleInputChange}
            error={validator.current.message(
              "Account Number",
              number,
              "required|numeric"
            )}
            helperText={validator.current.message(
              "Account Number",
              number,
              "required|numeric"
            )}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          height="70px"
        >
          <Typography variant="subtitle1">BSB</Typography>

          <InputControl
            label="BSB"
            name="bsb"
            width="434px"
            required
            value={bsb}
            onChange={handleInputChange}
            error={validator.current.message("bsb", bsb, "required|num")}
            helperText={validator.current.message("bsb", bsb, "required|num")}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          height="72px"
        >
          <Typography variant="subtitle1">Alias</Typography>

          <InputControl
            label="Alias"
            name="alias"
            width="434px"
            required
            value={alias}
            onChange={handleInputChange}
            error={validator.current.message("Alias", alias, "required")}
            helperText={validator.current.message("Alias", alias, "required")}
          />
        </Stack>
      </Box>
      <Button
        sx={{ height: "40px", width: "119px", alignSelf: "end" }}
        variant="contained"
        disabled={isBusy}
        onClick={onSubmit}
      >
        Save
      </Button>
    </Stack>
  );
};
