import React from "react";
import externalLink from "../../../assets/images/Icons/ic_external_link.svg";
import { useImmer } from "use-immer";
import { Box, Stack } from "@mui/material";
import { errorMessage, successMessage } from "../../../utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { usePermission } from "../../../shared";
import {
  deleteReviewApi,
  getReviewDetailsApi,
  rejectReviewApi,
  updateReviewPublishedStatusApi,
  updateReviewStatusApi,
} from "../api/reviewAndRatingsApi";

const defaultDialog = {
  open: false,
  name: "",
};

export const useReviewDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [, forceUpdate] = React.useState();
  const [state, setState] = useImmer({
    isContentLoading: false,
    reviewDetails: {},
    dialog: defaultDialog,
    status: {
      rejectReason: "",
    },
    showDownloadButton: false,
    isButtonLoading: false,
  });

  const breadcrumbsLinks = [
    { name: "Dashboard", href: "/" },
    { name: "Review & Ratings", href: "/reviews-and-ratings" },
    { name: id, href: "" },
  ];

  const detailsColumn = [
    {
      title: "Customer Name",
      accessor: "customer_name",
      accessor: (row) => (
        <Stack direction="row" alignItems="center" gap={1}>
          <Link
            to={"/customers/details/" + row.customer_id}
            style={{ color: "#636363" }}
          >
            {row.customer_name}
          </Link>
          {row.customer_review_count > 0 && (
            <Stack
              sx={{
                width: "auto",
                height: "26px",
                borderRadius: "8px",
                p: "4px 7px 4px 7px",
                color: "#C07302",
                backgroundColor: "#FFF4CE",
                gap: "4px",
                fontWeight: "500",
                cursor: "pointer",
              }}
              component="a"
              direction="row"
              alignItems="center"
              target="_blank"
              href={
                "/customers/details/" + row.customer_id + "?product-reviews"
              }
            >
              {row.customer_review_count >= 2
                ? `+ ${row.customer_review_count - 1} More reviews`
                : `${row.customer_review_count} review`}
              <Box
                component="img"
                src={externalLink}
                sx={{ width: "18px", height: "18px" }}
              />
            </Stack>
          )}
        </Stack>
      ),
    },
    {
      title: "Product",
      accessor: "product_name",
      accessor: (row) => (
        <Stack direction="row" alignItems="center" gap={1}>
          <Link
            to={"/products/details/" + row.product_id}
            style={{ color: "#636363" }}
          >
            {row.product_name}
          </Link>
          {row.product_review_count > 0 && (
            <Stack
              sx={{
                width: "auto",
                height: "26px",
                borderRadius: "8px",
                p: "4px 7px 4px 7px",
                color: "#C07302",
                backgroundColor: "#FFF4CE",
                gap: "4px",
                fontWeight: "500",
                cursor: "pointer",
              }}
              component="a"
              direction="row"
              alignItems="center"
              target="_blank"
              href={"/products/details/" + row.product_id + "?reviews"}
            >
              {row.product_review_count >= 2
                ? `+ ${row.product_review_count - 1} More reviews`
                : `${row.product_review_count} review`}
              <Box
                component="img"
                src={externalLink}
                sx={{ width: "18px", height: "18px" }}
              />
            </Stack>
          )}
        </Stack>
      ),
    },
    {
      title: "Submitted Date & Time",
      accessor: "submitted_date",
    },
    {
      title: "Published Date & Time",
      hidden: (row) => row.published_at === null || row.approved_at,
      accessor: "published_at",
    },
    {
      title: "Approved by",
      hidden: (row) => row.approved_by_name === null,
      accessor: "approved_by_name",
    },
    {
      title: "Approved Date & Time",
      hidden: (row) => row.approved_at === null,
      accessor: "approved_at",
    },
    {
      title: "Rejected By",
      hidden: (row) => row.replied_by_name === null,
      accessor: "replied_by_name",
    },
    {
      title: "Rejected Date & Time",
      hidden: (row) => row.replied_at === null,
      accessor: "replied_at",
    },
    {
      title: "Reason For Rejection",
      hidden: (row) => row.rejection_reason === null,
      accessor: "rejection_reason",
    },
  ];

  const chipDetails =
    state.reviewDetails.status === 0
      ? { label: "Pending", color: "warning", backgroundColor: "#FFF4CE" }
      : state.reviewDetails.status === 1
      ? { label: "Approved", color: "success" }
      : { label: "Rejected", color: "error" };

  const isReviewModifiable = usePermission("review-rating-modify");
  const isReviewDeletable = usePermission("review-rating-delete");

  // Validation for rejection reason
  const rejectionValidator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );

  // Util function to trigger content loading
  const triggerLoading = () => {
    setState((draft) => {
      draft.isContentLoading = !draft.isContentLoading;
    });
  };

  // Function to trigger button loading
  const triggerButtonLoading = () => {
    setState((draft) => {
      draft.isButtonLoading = !draft.isButtonLoading;
    });
  };

  const getReviewDetailsById = async (reviewId) => {
    triggerLoading();

    try {
      const response = await getReviewDetailsApi(reviewId);
      const { success, message, data } = response;

      if (success) {
        setState((draft) => {
          draft.reviewDetails = data;
        });
      } else {
        errorMessage(message);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    } finally {
      triggerLoading();
    }
  };

  // Util function to handle closing/opening of dialog
  const handleOpenDialog = (
    dialog = "approve" |
      "reject" |
      "publish" |
      "unpublish" |
      "remove" |
      "approveAndPublish"
  ) => {
    setState((draft) => {
      draft.dialog = {
        open: true,
        name: dialog,
      };
    });
  };

  // Util function to handle close the dialog
  const handleCloseDialog = () => {
    // Clear validation messages
    if (Object.keys(rejectionValidator.current.errorMessages).length > 0)
      rejectionValidator.current.hideMessages();
    setState((draft) => {
      draft.dialog = defaultDialog;
      draft.status.rejectReason = "";
    });
  };

  // Util function to handle image hover
  const handleImageHover = (visibility) => {
    setState((draft) => {
      draft.showDownloadButton = visibility;
    });
  };

  // Function to handle review-reject
  const handleRejectionSubmit = () => {
    if (rejectionValidator.current.allValid()) {
      const rejectionStatus = 2; // Reference from boot API
      handleRejectReview(rejectionStatus, state.status.rejectReason);
    } else {
      rejectionValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  // Function to handle review-reject-reason changes
  const handleRejectionChanges = (event) => {
    const { value } = event.target;
    setState((draft) => {
      draft.status.rejectReason = value;
    });
  };

  // Function to handle review-status changes
  const handleReviewStatusChange = async (status) => {
    triggerButtonLoading();
    try {
      const response = await updateReviewStatusApi({ reviewId: id, status });
      const { success, message } = response;

      if (success) {
        successMessage(message);
        // setState((draft) => {
        //   draft.reviewDetails = { ...draft.reviewDetails, status };
        // });

        // Reload the review details
        await getReviewDetailsById(id);
      } else {
        errorMessage(message);
      }
      handleCloseDialog();
    } catch (error) {
      errorMessage(error.response.data.message);
    } finally {
      triggerButtonLoading();
    }
  };

  // Function to reject the review
  const handleRejectReview = async (status, rejection_reason) => {
    triggerButtonLoading();
    try {
      const response = await rejectReviewApi({
        reviewId: id,
        status,
        rejection_reason,
      });
      const { success, message } = response;

      if (success) {
        successMessage(message);
        // setState((draft) => {
        //   draft.reviewDetails = { ...draft.reviewDetails, status };
        // });
        // Reload the review details
        await getReviewDetailsById(id);
      } else {
        errorMessage(message);
      }
      handleCloseDialog();
    } catch (error) {
      errorMessage(error.response.data.message);
    } finally {
      triggerButtonLoading();
    }
  };

  // Function to handle published status changes
  const handleReviewPublishedStatusChange = async () => {
    triggerButtonLoading();
    try {
      const response = await updateReviewPublishedStatusApi({ reviewId: id });
      const { success, message } = response;

      if (success) {
        successMessage(message);
        setState((draft) => {
          draft.reviewDetails = {
            ...draft.reviewDetails,
            published_status:
              draft.reviewDetails.published_status === 0 ? 1 : 0,
          };
        });
      } else {
        errorMessage(message);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    } finally {
      triggerButtonLoading();
      handleCloseDialog();
    }
  };

  // Function to delete the review
  const handleDeleteReview = async ({ reviewId }) => {
    triggerButtonLoading();
    try {
      const response = await deleteReviewApi({ reviewId });
      const { success, message } = response;
      if (success) {
        successMessage(message);
        navigate("/reviews-and-ratings");
      } else {
        errorMessage(message);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    } finally {
      triggerButtonLoading();
    }
  };

  /**
   * Fetches an image from a URL and converts it to a data URL.
   *
   * @param {string} url - The URL of the image to fetch.
   * @returns {Promise<string>} A promise that resolves to a data URL of the image.
   * @throws {Error} Throws an error if the network response is not ok or if the fetch fails.
   */
  const toDataURL = async (url) => {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  };

  /**
   * Downloads an image from a URL by creating a link element and triggering a download.
   *
   * @param {string} url - The URL of the image to download.
   * @returns {Promise<void>} A promise that resolves when the image has been downloaded.
   * @throws {Error} Throws an error if fetching or downloading the image fails.
   */
  async function downloadImage(url) {
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = "myImage.png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // useEffect to fetch review-details
  React.useEffect(() => {
    if (id) {
      getReviewDetailsById(id);
    } else {
      errorMessage("Oops! Review id is missing.");
      navigate(-1);
    }
  }, []);

  return {
    state,
    chipDetails,
    detailsColumn,
    downloadImage,
    handleImageHover,
    breadcrumbsLinks,
    handleOpenDialog,
    handleCloseDialog,
    rejectionValidator,
    isReviewModifiable,
    isReviewDeletable,
    handleDeleteReview,
    handleRejectionSubmit,
    handleRejectionChanges,
    handleReviewStatusChange,
    handleReviewPublishedStatusChange,
  };
};
