import * as React from "react";
import { Button, Stack } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";

export const GroupNameDialog = ({
  onChangeFavGroup,
  onSaveFavGroup,
  isEdit,
  state,
  onUpdateFavGroup,
}) => {
  const { isSaveButtonBusy, name } = state;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const onSubmit = () => {
    if (validator.current.allValid()) {
      isEdit ? onUpdateFavGroup("details") : onSaveFavGroup();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  return (
    <Stack
      justifyContent="space-between"
      height="100%"
      gap="44px"
      maxHeight={"calc(100% - 24px)"}
      mt="32px"
    >
      <InputControl
        name="name"
        label="Group Name *"
        sx={{
          width: "100%",
          bgcolor: "#fff",
        }}
        onChange={onChangeFavGroup}
        value={name}
        error={validator.current.message("Group Name", name, "required")}
        helperText={validator.current.message("Group Name", name, "required")}
      />
      <Button
        onClick={onSubmit}
        sx={{ height: "40px" }}
        variant="contained"
        fullWidth
        disabled={isSaveButtonBusy}
      >
        Save
      </Button>
    </Stack>
  );
};
