import axios from "axios";
import { ACTIVITY_LOG_URL } from "../../utils/apiConfig";
import { useContext } from "react";
import { AuthContext } from "../../store/AuthProvider";

const LOGAPI = axios.create({
  baseURL: ACTIVITY_LOG_URL,
  headers: {
    projectKey: "B8TKX7",
  },
});

export const useActivityLog = () => {
  const { auth } = useContext(AuthContext);
  const addActivityLog = ({
    templateId,
    parameters,
    uniqueParameter,
    data,
  }) => {
    const payload = {
      parameters,
      user: auth.data.name,
      uniqueParameter,
      environment: process.env.REACT_APP_SENTRY_ENV_KEY,
      data,
    };
    LOGAPI.post(`External/${templateId}`, payload);
  };
  return { addActivityLog };
};
