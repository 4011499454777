import { Button, Card, Divider, Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import {
  ContentLayout,
  CustomTable,
  HeaderBreadcrumbs,
  Loader,
} from "../../../shared";
import { RefundListTable } from "../components";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useRefund } from "../hooks/useRefund";

export const RefundDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state, isStoreAdmin } = useRefund({ isRefund: false, refundId: id });
  const printRef = useRef();

  const paymentColumns = React.useMemo(
    () => [
      {
        Header: "Type",
        accessor: "method",
        disableSortBy: true,
      },
      {
        Header: "Price",
        accessor: "amount",
        Cell: ({ value }) => {
          return "$" + value?.toFixed(2);
        },
        disableSortBy: true,
      },
      {
        Header: "Date & Time",
        accessor: "created_at",
        disableSortBy: true,
      },
      {
        Header: "Transaction ID",
        accessor: "transaction_id",
        Cell: ({ value }) => {
          return value === null ? "-------------" : value;
        },
        disableSortBy: true,
      },
      {
        Header: " ",
        accessor: "receipt",
        align: "right",
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <Typography
              fontWeight={600}
              fontSize="14px"
              sx={{
                color: "#1560D4",
              }}
            >
              {value}
            </Typography>
          );
        },
      },
    ],
    []
  );

  const customerData = [
    {
      head: "Customer Name",
      name:
        state.data.order !== null
          ? `${state?.data?.order?.customer_first_name ?? " "} ${state?.data?.order?.customer_last_name ?? " "
          } `
          : `${state.data.customer_first_name ?? ""} ${state.data.customer_last_name ?? ""
          }`,
    },
    {
      head: "Refund Date",
      name: state.data.refund_date,
    },
    {
      head: "Store Detials",
      name: state?.data?.store_name,
      Cashier: state.data.user_name && `Cashier - ${state.data.user_name}`,
    },
    {
      head: "Order Total",
      name: state?.data?.order?.grand_total
        ? "$" + state?.data?.order?.grand_total
        : "",
    },
  ];
  return (
    <>
      <ContentLayout
        title={state.refund_number}
        chipType={state?.data?.status ? "salesStatus" : ""}
        label={state.data.status}
        labelColor="error"
        breadcrumbs={
          location.state?.from ? (
            <>
              <HeaderBreadcrumbs
                links={[
                  {
                    name: "Dashboard",
                    href: isStoreAdmin ? "/store-admin" : "/",
                  },
                  {
                    name: "Sales Log",
                    href: isStoreAdmin ? "/store-admin/orders" : "/orders",
                  },
                  {
                    name: state.data.order?.order_number || "",
                    href: isStoreAdmin
                      ? `/store-admin/orders/details/${state.data.order?.id}`
                      : `/orders/details/${state.data.order?.id}`,
                  },
                  { name: state?.refund_number || "" },
                ]}
              />
            </>
          ) : (
            <HeaderBreadcrumbs
              links={[
                {
                  name: "Dashboard",
                  href: isStoreAdmin ? "/store-admin" : "/",
                },
                {
                  name: "Refunds",
                  href: isStoreAdmin ? `/store-admin/refund` : `/refund`,
                },
                { name: state?.refund_number || "" },
              ]}
            />
          )
        }
        rightContent={
          <>
            <ReactToPrint
              trigger={() => (
                <Button
                  sx={{ width: "40px" }}
                  type="button"
                  size="small"
                  variant="text"
                >
                  <img alt="tools" src="/icons/ic_print.svg" />
                </Button>
              )}
              content={() => printRef.current}
            />
          </>
        }
      >
        {state.isBusy ? (
          <Loader />
        ) : (
          <>
            <Card ref={printRef}>
              <Grid
                container
                sx={{
                  height: "83px",
                  padding: "32px 24px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    fontSize={16}
                    sx={{
                      lineHeight: "19px",
                    }}
                  >
                    Refund Summary
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid
                container
                sx={{
                  padding: "24px 16px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {customerData.map((item) => {
                  return (
                    <Grid item>
                      <Box sx={{ padding: "10px", width: "212px" }}>
                        <Typography
                          fontWeight={500}
                          fontSize={13}
                          sx={{
                            lineHeight: "15px",
                            marginBottom: "10px",
                          }}
                        >
                          {item.head}
                        </Typography>
                        {Object.keys(item).map((key) => {
                          if (key !== "head") {
                            return (
                              <Typography
                                fontWeight={400}
                                fontSize={13}
                                sx={{
                                  lineHeight: "15px",
                                  marginBottom: "6px",
                                }}
                              >
                                {item[key]}
                              </Typography>
                            );
                          }
                        })}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              <Divider />
              <Divider />
              <Grid
                container
                sx={{
                  padding: "24px 16px",
                  height: "102px",
                }}
              >
                <Grid item>
                  <Box sx={{ padding: "10px" }}>
                    <Typography
                      fontWeight={500}
                      fontSize={13}
                      sx={{
                        lineHeight: "15px",
                        marginBottom: "10px",
                      }}
                    >
                      Order Date
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontSize={13}
                      sx={{
                        lineHeight: "15px",
                        marginBottom: "6px",
                      }}
                    >
                      {state.data.order?.order_date}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <RefundListTable state={state} />
              <Divider />
              <Stack sx={{
                flexDirection: "row",
                gap: "4px",
                alignItems: "center",
                padding: "6px 24px 10px"
              }}>
                <img alt="sign" src="/icons/ic_info_small.svg" />
                <Typography variant="caption" lineHeight="15px" color="#666666">
                  Denotes taxable items.
                </Typography>
              </Stack>
              <Grid
                sx={{
                  height: "126px",
                  padding: "0px 16px 32px",
                }}
                container
              >
                <Grid sx={{ padding: "10px" }} item>
                  <Typography
                    fontWeight={500}
                    fontSize={13}
                    sx={{
                      lineHeight: "15px",
                      marginBottom: "10px",
                    }}
                  >
                    Remarks
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={13}
                    sx={{
                      lineHeight: "15px",
                    }}
                  >
                    {state.data.remarks}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
            <Card sx={{ marginTop: "24px", marginBottom: "16px" }}>
              <Grid
                container
                sx={{
                  height: "83px",
                  padding: "32px 24px",
                }}
              >
                <Grid item>
                  <Typography
                    fontWeight={600}
                    fontSize={16}
                    sx={{
                      lineHeight: "15px",
                      marginBottom: "10px",
                    }}
                  >
                    Refund Method
                  </Typography>
                </Grid>
              </Grid>

              <CustomTable
                columns={paymentColumns}
                data={state.data.payments}
                defaultSearch={false}
                completeFeature={false}
                pagination={false}
                tableTitle={null}
                searchBar={null}
              />
            </Card>
          </>
        )}
      </ContentLayout>
    </>
  );
};
