import React from "react";
import { Route, Routes } from "react-router-dom";
import { BSReportList } from "./containers";

export const BSReport = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<BSReportList />} />
      </Routes>
    </>
  );
};
