import { ContentLayout, HeaderBreadcrumbs } from "../../../shared";
import * as React from "react";
import { Button, Stack, Typography, Card, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";
import { usePriceLevel } from "../hooks";


export const CreateOrEditPriceLevels = () => {
  const { id } = useParams();
  const { state, onChange, onSavePriceLevel, onUpdatePriceLevel } =
    usePriceLevel({
      isPriceLevel: false,
      priceLevelId: id,
    });

  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdatePriceLevel(id) : onSavePriceLevel();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  return (
    <>
      <ContentLayout
        title={id ? state.name : "New Price level"}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Price Levels", href: "/price-levels" },
              {
                name: id ? `${state.name +  " / Edit "}` : "Create",
              },
            ]}
          />
        }
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              disabled={state.isSaveButtonBusy}
              onClick={() => onSubmit()}
            >
              {id ? "Update" : "Save"}
            </Button>
          </>
        }
      >
        <Card sx={{ p: "32px 24px", mb: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Details
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack width={"100%"} gap="20px">
                <InputControl
                  name="name"
                  label=" Name"
                  value={state.data.name}
                  onChange={(e) => onChange(e)}
                  error={validator.current.message(
                    " Name",
                    state.data.name,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "Name",
                    state.data.name,
                    "required"
                  )}
                  required
                />
                <InputControl
                  value={state.data.alias}
                  name="alias"
                  label="Code"
                  onChange={(e) => onChange(e)}
                  required
                  error={validator.current.message(
                    "code",
                    state.data.alias,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "code",
                    state.data.alias,
                    "required"
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </ContentLayout>
    </>
  );
};
