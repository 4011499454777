import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { errorMessage, successMessage } from "../../../utils";
import {
  changeCFDOrder,
  createCFD,
  deleteCFDbyId,
  getCFDDetails,
  getCFDList,
  updateCFD,
} from "../api";
import { useNavigate } from "react-router-dom";

export const useCFD = ({ isList, isEdit }) => {
  const navigate = useNavigate();

  const [state, setState] = useImmer({
    isLoading: true,
    isDelete: false,
    selectedID: null,
    dataList: [],
    isSaveButton: false,
    updateDetails: {
      image1: null,
      image2: null,
      image3: null,
      image4: null,
    },
    details: {
      image1: null,
      image2: null,
      image3: null,
      image4: null,
    },
    aspectRatio: {
      image1: null,
      image2: null,
      image3: null,
      image4: null,
    },
  });

  useEffect(() => {
    if (isList) {
      HandleCFDList();
    }
  }, [isList]);

  useEffect(() => {
    if (isEdit) {
      HandleCFDDetails(isEdit);
    }
  }, [isEdit]);

  const HandleCFDList = async () => {
    try {
      const res = await getCFDList();
      if (res.success) {
        setState((draft) => {
          draft.dataList = res.data;
          draft.isLoading = false;
        });
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const HandleCFDDetails = async (id) => {
    try {
      const res = await getCFDDetails(id);
      if (res.success) {
        setState((draft) => {
          draft.details = res.data;
          draft.isLoading = false;
        });
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const handleModal = (type, state, data) => {
    switch (type) {
      case "cfd-delete":
        setState((draft) => {
          draft.isDelete = state;
          draft.selectedID = data;
        });
        break;
    }
  };

  const handleDeleteCFD = async () => {
    try {
      const res = await deleteCFDbyId(state.selectedID);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.dataList = draft.dataList.filter(
            (item) => item.id !== state.selectedID
          );
        });
        handleModal("cfd-delete", false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleInputChange = (files, name, width, height) => {
    setState((draft) => {
      draft.updateDetails[name] = files[0];
      draft.details[name] = URL.createObjectURL(files[0]);
    });

    var img = new Image();
    img.src = URL.createObjectURL(files[0]);
    img.onload = () => {
      const ImgaspectRatio = img.width / img.height;
      const isSameAspectRatio =
        Math.abs(ImgaspectRatio - width / height) < 0.01; // Allow a small tolerance

      if (!isSameAspectRatio) {
        setState((draft) => {
          draft.aspectRatio[
            name
          ] = `The image does not have ${width}:${height} aspect ratio.`;
        });
      } else {
        setState((draft) => {
          draft.aspectRatio[name] = null;
        });
      }
    };
  };

  const onSaveCFD = async () => {
    try {
      setState((draft) => {
        draft.isSaveButton = true;
      });

      let formData = new FormData();
      const data = { ...state.updateDetails };
      Object.keys(data).map((key) => {
        formData.append(key, data[key]);
      });

      const res = await createCFD(formData);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButton = false;
        });
        navigate("/cfd");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButton = false;
      });
    }
  };

  const onUpdateCFD = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButton = true;
      });

      let formData = new FormData();
      const data = { ...state.updateDetails };

      data.image1 && formData.append("image1", data.image1);
      data.image2 && formData.append("image2", data.image2);
      data.image3 && formData.append("image3", data.image3);
      data.image4 && formData.append("image4", data.image4);

      formData.append("_method", "PUT");

      const res = await updateCFD(id, formData);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButton = false;
        });
        navigate("/cfd");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButton = false;
      });
    }
  };

  const onCFDOrderChange = async (data) => {
    const payload = {
      cfds: data.map((x, i) => ({
        id: x.id,
        display_order: i,
      })),
    };
    try {
      const res = await changeCFDOrder(payload);
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleDeleteInput = (name) => {
    setState((draft) => {
      draft.updateDetails[name] = null;
      draft.details[name] = null;
      draft.aspectRatio[name] = null;
    });
  };

  return {
    state,
    handleModal,
    handleDeleteCFD,
    handleInputChange,
    onSaveCFD,
    onCFDOrderChange,
    onUpdateCFD,
    handleDeleteInput,
  };
};
