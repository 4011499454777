import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import { AddOrEditOptionalGroup, OptionalGroupDetails, OptionalGroupList } from "./containers";

export const OptionalGroup = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<OptionalGroupList />} />
       
        <Route element={<ProtectedRoute allowedRoute={["optional-group-create"]} />}>
          <Route path="/create" element={<AddOrEditOptionalGroup />} />
        </Route>
         <Route element={<ProtectedRoute allowedRoute={["optional-group-modify"]} />}>
          <Route path="/details/:id" element={<OptionalGroupDetails />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["optional-group-modify"]} />}>
          <Route path="/details/edit/:id" element={<AddOrEditOptionalGroup />} />
        </Route>
        
      </Routes>
    </>
  );
};
