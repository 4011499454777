import {
  ContentLayout,
  HeaderBreadcrumbs,
  Loader,
  usePermission,
} from "../../../shared";
import * as React from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  FormHelperText,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";

import SimpleReactValidator from "simple-react-validator";
import { CFDDropzoneComponent } from "../components/CFDDropzoneComponent";
import { useCFD } from "../hooks/useCFD";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const AddOrEditCFD = () => {
  const { id } = useParams();

  const {
    state,
    handleInputChange,
    onSaveCFD,
    onUpdateCFD,
    handleDeleteInput,
  } = useCFD({ isEdit: id });
  const { isSaveButton, isLoading, aspectRatio } = state;
  const { image1, image2, image3, image4 } = state.details;
  const navigate = useNavigate();
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdateCFD(id) : onSaveCFD();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <ContentLayout
        title={id ? "Update" : "Add New Image"}
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              onClick={onSubmit}
              disabled={isSaveButton}
            >
              {id ? "Update" : "Save"}
            </Button>
          </>
        }
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                {
                  name: "CFD",
                  href: "/cfd",
                },
                { name: id ? "Edit" : "Create" },
              ]}
            />
          </>
        }
      >
        {isLoading && id ? (
          <Loader />
        ) : (
          <>
            <Card
              sx={{
                p: "16px",
                mb: "24px",
                overflow: "inherit",
                width: "fit-content",
              }}
            >
              <Typography variant="subtitle2" fontWeight={600}>
                Square Size
              </Typography>
              <Stack direction="row" gap="32px" mt="16px">
                <Stack gap="6px">
                  <CFDDropzoneComponent
                    height="280px"
                    aspectRatio="4/3"
                    handleDropzone={(e) => {
                      handleInputChange(e, "image1", 4, 3);
                    }}
                    handleDelete={(e) => {
                      handleDeleteInput("image1");
                    }}
                    imgUrl={image1}
                  />
                  <FormHelperText sx={{ color: "#C60808" }}>
                    {validator.current.message(
                      "image (4:3)",
                      image1,
                      "required"
                    )}
                  </FormHelperText>
                  {aspectRatio.image1 && (
                    <Alert
                      icon={
                        <InfoOutlinedIcon
                          sx={{ height: "20px", color: "#C07302" }}
                        />
                      }
                      sx={{
                        bgcolor: "#FFF4CE",
                        color: "#C07302",
                        lineHeight: "14px",
                        height: "40px",
                        py: "5px",
                      }}
                      severity="warning"
                    >
                      {aspectRatio.image1}
                    </Alert>
                  )}
                </Stack>
                <Stack>
                  <Stack direction="row" gap="6px">
                    <CFDDropzoneComponent
                      height="280px"
                      aspectRatio="7/9"
                      handleDropzone={(e) => {
                        handleInputChange(e, "image2", 7, 9);
                      }}
                      handleDelete={(e) => {
                        handleDeleteInput("image2");
                      }}
                      imgUrl={image2}
                      isExtent={true}
                    />
                    <Stack
                      width="110px"
                      p="16px"
                      justifyContent="space-between"
                      alignItems="center"
                      border="2px solid #F2F2F2"
                      borderLeft={0}
                    >
                      <Stack gap="8px" alignItems="center">
                        <Divider
                          sx={{
                            borderWidth: "6px",
                            width: "55px",
                          }}
                        />
                        <Divider
                          sx={{
                            borderWidth: "2.5px",
                            width: "64px",
                          }}
                        />
                      </Stack>
                      <Divider
                        sx={{
                          borderWidth: "6px",
                          width: "55px",
                        }}
                      />
                    </Stack>
                  </Stack>
                  <FormHelperText sx={{ color: "#C60808" }}>
                    {validator.current.message(
                      "image (7:9)",
                      image2,
                      "required"
                    )}
                  </FormHelperText>
                  {aspectRatio.image2 && (
                    <Alert
                      icon={
                        <InfoOutlinedIcon
                          sx={{ height: "20px", color: "#C07302" }}
                        />
                      }
                      sx={{
                        bgcolor: "#FFF4CE",
                        color: "#C07302",
                        lineHeight: "14px",
                        height: "40px",
                        py: "5px",
                      }}
                      severity="warning"
                    >
                      {aspectRatio.image2}
                    </Alert>
                  )}
                </Stack>
              </Stack>
            </Card>

            <Card
              sx={{
                p: "16px",
                mb: "24px",
                overflow: "inherit",
                width: "fit-content",
              }}
            >
              <Typography variant="subtitle2" fontWeight={600}>
                Large Size
              </Typography>
              <Stack direction="row" gap="32px" mt="16px">
                <Stack gap="6px">
                  <CFDDropzoneComponent
                    height="250px"
                    aspectRatio="16/9"
                    handleDropzone={(e) => {
                      handleInputChange(e, "image3", 16, 9);
                    }}
                    handleDelete={(e) => {
                      handleDeleteInput("image3");
                    }}
                    imgUrl={image3}
                  />
                  <FormHelperText sx={{ color: "#C60808" }}>
                    {validator.current.message(
                      "image (16:9)",
                      image3,
                      "required"
                    )}
                  </FormHelperText>
                  {aspectRatio.image3 && (
                    <Alert
                      icon={
                        <InfoOutlinedIcon
                          sx={{ height: "20px", color: "#C07302" }}
                        />
                      }
                      sx={{
                        bgcolor: "#FFF4CE",
                        color: "#C07302",
                        lineHeight: "14px",
                        height: "40px",
                        py: "5px",
                      }}
                      severity="warning"
                    >
                      {aspectRatio.image3}
                    </Alert>
                  )}
                </Stack>
                <Stack>
                  <Stack direction="row" gap="6px">
                    <CFDDropzoneComponent
                      height="250px"
                      aspectRatio="13/9"
                      handleDropzone={(e) => {
                        handleInputChange(e, "image4", 13, 9);
                      }}
                      handleDelete={(e) => {
                        handleDeleteInput("image4");
                      }}
                      imgUrl={image4}
                      isExtent={true}
                    />
                    <Stack
                      width="83px"
                      p="16px"
                      justifyContent="space-between"
                      alignItems="center"
                      border="2px solid #F2F2F2"
                      borderLeft={0}
                    >
                      <Stack gap="8px" alignItems="center">
                        <Divider
                          sx={{
                            borderWidth: "6px",
                            width: "55px",
                          }}
                        />
                        <Divider
                          sx={{
                            borderWidth: "2.5px",
                            width: "64px",
                          }}
                        />
                      </Stack>
                      <Divider
                        sx={{
                          borderWidth: "6px",
                          width: "55px",
                        }}
                      />
                    </Stack>
                  </Stack>
                  <FormHelperText sx={{ color: "#C60808" }}>
                    {validator.current.message(
                      "image (13:9)",
                      image4,
                      "required"
                    )}
                  </FormHelperText>
                  {aspectRatio.image4 && (
                    <Alert
                      icon={
                        <InfoOutlinedIcon
                          sx={{ height: "20px", color: "#C07302" }}
                        />
                      }
                      sx={{
                        bgcolor: "#FFF4CE",
                        color: "#C07302",
                        lineHeight: "14px",
                        height: "40px",
                        py: "5px",
                      }}
                      severity="warning"
                    >
                      {aspectRatio.image4}
                    </Alert>
                  )}
                </Stack>
              </Stack>
            </Card>
          </>
        )}
      </ContentLayout>
    </>
  );
};
