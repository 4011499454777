import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import * as React from "react";
import {
  Button,
  Stack,
  Card,
  Typography,
  Grid,
  Chip,
  TextField,
  createFilterOptions,
  Checkbox,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useParams } from "react-router-dom";

import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { useSurcharges } from "../hooks";
import { priceValidator } from "../../../utils";

export const AddOrEditSurCharges = () => {
  const { id } = useParams();
  const {
    state,
    onSaveSurcharges,
    onChange,
    globalData,
    onDeleteStoreSelect,
    onUpdateSurcharges,
    isEditable,
    isCreateVisible,
    taxState,
  } = useSurcharges({ load: false, SurchargesId: id, isList: true });
  const taxStateFilter = taxState.taxGroup.listData?.map((i) => {
    return { label: i.name, value: i.id };
  });
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        price: priceValidator,
        endTime: {
          message: "The end time should greater than start time",

          rule: (value) => {
            const { startTime, endTime } = value;
            return moment(startTime, "HH:mm:ss").isBefore(
              moment(endTime, "HH:mm:ss")
            );
          },
        },
      },
    })
  );
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });
  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdateSurcharges(id) : onSaveSurcharges();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const SaleType =
    globalData?.sale_type?.map((item) => {
      return { id: item?.value, name: item?.label };
    }) || [];
  return (
    <>
      <ContentLayout
        title={id ? state.name : "New Surcharge"}
        breadcrumbs={
          id ? (
            <>
              <HeaderBreadcrumbs
                links={[
                  { name: "Dashboard", href: "/" },
                  { name: "Surcharges", href: "/surcharges" },
                  { name: state.name },
                ]}
              />
            </>
          ) : (
            <>
              <HeaderBreadcrumbs
                links={[
                  { name: "Dashboard", href: "/" },
                  { name: "Surcharges", href: "/surcharges" },
                  { name: "Create" },
                ]}
              />
            </>
          )
        }
        rightContent={
          <>
            {isEditable && isCreateVisible && (
              <Button
                onClick={() => onSubmit()}
                type="button"
                size="medium"
                variant="contained"
                disabled={state.isSaveButtonBusy}
              >
                {id ? "Update" : "Save"}
              </Button>
            )}
          </>
        }
      >
        <Card sx={{ p: "32px 24px", mb: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Surcharge Details
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack width={"100%"} gap="20px">
                <InputControl
                  name="name"
                  label="Title"
                  value={state.data.name}
                  onChange={onChange}
                  required
                  error={validator.current.message(
                    "Title",
                    state.data.name,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "Title",
                    state.data.name,
                    "required"
                  )}
                />
                <InputControl
                  name="amount"
                  label="Charge"
                  type="number"
                  value={state.data.amount}
                  onChange={onChange}
                  required
                  isDisabledKeys={["-"]}
                  error={validator.current.message(
                    "Charge",
                    state.data.amount,
                    "required|price"
                  )}
                  helperText={validator.current.message(
                    "Charge",
                    state.data.amount,
                    "required|price"
                  )}
                />
                <Stack direction="column" spacing={2} marginTop={2}>
                  <Stack>
                    <>
                      <InputControl
                        type="dropdown"
                        name="sale_type_id"
                        label="Store"
                        multiple={true}
                        options={SaleType}
                        value={state?.data?.sale_type_id}
                        disableCloseOnSelect
                        disableClearable={true}
                        onChange={(e, v) =>
                          onChange({
                            target: {
                              name: "sale_type_id",
                              value: v,
                            },
                          })
                        }
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => <></>)
                        }
                        renderOption={(props, option, { selected }) => (
                          <>
                            <li {...props} key={option.id}>
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          </>
                        )}
                        filterOptions={filterOptions}
                        sx={{ width: "100%" }}
                        componentsProps={{
                          paper: {
                            sx: {
                              width: "100%",
                              minWidth: 250,
                              height: 270,
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sale Type"
                            placeholder="Sale Type "
                            required
                            error={validator.current.message(
                              "Sale Type",
                              state.data.sale_type_id,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Sale Type",
                              state.data.sale_type_id,
                              "required"
                            )}
                          />
                        )}
                      />
                    </>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "12px",
                        marginTop: 2,
                      }}
                    >
                      {state?.data?.sale_type_id?.map((data, index) => (
                        <Chip
                          key={index}
                          label={
                            <Typography variant="body2" fontSize="14px">
                              {data?.name}
                            </Typography>
                          }
                          onDelete={() => {
                            onDeleteStoreSelect(data);
                          }}
                          sx={{
                            backgroundColor: "#F2F2F2",
                            height: "30px",
                            "& .MuiChip-deleteIcon": {
                              color: "#CCCCCC",
                            },
                          }}
                        />
                      ))}
                    </Stack>
                  </Stack>
                </Stack>

                <InputControl
                  type="dropdown"
                  disableClearable
                  options={taxStateFilter}
                  getOptionLabel={(option) => option.label}
                  value={
                    (taxStateFilter &&
                      state.data.tax_group_id &&
                      taxStateFilter?.find(
                        (c) => c.value === state.data.tax_group_id
                      )) ||
                    null
                  }
                  onChange={(e, newValue) => {
                    onChange({
                      target: {
                        name: "tax_group_id",
                        value: newValue.value,
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tax Group"
                      required
                      error={validator.current.message(
                        "Tax Group",
                        state.data.tax_group_id,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "Tax Group",
                        state.data.tax_group_id,
                        "required"
                      )}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </ContentLayout>
    </>
  );
};
