import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import {
  AddOrEditStore,
  StoreDetails,
  StoreList,
  ProductPriceLevel,
  SuperAddOrEditBumpScreen,
} from "./containers";

export const Stores = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<StoreList />} />
        <Route element={<ProtectedRoute allowedRoute={["store-create"]} />}>
          <Route path="/create" element={<AddOrEditStore />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["store-view"]} />}>
          <Route path="/details/:id" element={<StoreDetails />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["store-modify"]} />}>
          <Route path="/details/edit/:id" element={<AddOrEditStore />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["store-modify"]} />}>
          <Route
            path="/details/:id/bumpscreen/edit/:bsid"
            element={<SuperAddOrEditBumpScreen />}
          />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["store-modify"]} />}>
          <Route
            path="/details/:id/bumpscreen/create"
            element={<SuperAddOrEditBumpScreen />}
          />
        </Route>
      </Routes>
    </>
  );
};
