import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { FavouritesList, FavouritesDetailsList } from "..";
import { ProtectedRoute } from "../../shared";

export const Favourites = () => {
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  return (
    <>
      <Routes>
        <Route path="/" element={<FavouritesList />} />
        <Route element={<ProtectedRoute allowedRoute={["favourite-view"]} />}>
          <Route
            path="/details/:id"
            element={<FavouritesDetailsList isStoreAdmin={isStoreAdmin} />}
          />
        </Route>
      </Routes>
    </>
  );
};
