import React, { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../../../store/AppScopeProvider";
import { useImmer } from "use-immer";
import {
  updateStockAlert,
  updateStockQuantity,
  updateStockSoldoutStatus,
  getSAProductDetailsById,
  getProductPriceLevel,
  updateClearStock,
} from "../api/ProductApi";
import { useDebounce } from "use-debounce";
import { successMessage, errorMessage } from "../../../utils";
import { AuthContext } from "../../../store/AuthProvider";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";

export const useProductsSA = ({
  isDetailsId = false,
  IsPriceLevel = false,
}) => {
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const { appState } = useContext(AppContext);
  const { auth } = useContext(AuthContext);
  const { selectedStoreId } = auth.data;
  const { globalData } = appState;
  const [state, setState] = useImmer({
    isBusy: false,
    initialLoad: true,
    details: {},
    validation: { isValidated: false, date: null, time: null },
    stockOutDetails: {
      isSaveButton: false,
      date: null,
      time: null,
      back_in_stock_type: "",
      sold_out_status: 0,
    },
    stockDetails: {
      isLoading: true,
      isSaveButton: false,
      details: {
        name: "",
        price: "",
        stock: "",
        auto_clear_stock: 0,
        sold_out_status: null,
        alert_stock_status: null,
        alert_stock: "",
        back_in_stock_type: "",
        back_in_stock_time: null,
      },
      time: null,
      date: null,
      stockAlert: true,
      isOpen: false,
      isEdit: false,
    },
    priceLevel: {
      isLoading: true,
      dataList: [],
    },
    httpStatusCodes: {
      details: {},
    },
  });
  const validateDate = (reason, name) => {
    if (reason === "invalidDate") {
      setState((draft) => {
        draft.validation[name] = "Selected Date is not a valid";
      });
    } else if (reason === "minDate") {
      setState((draft) => {
        draft.validation[name] = "Selected Date is not a valid date";
      });
    } else {
      setState((draft) => {
        draft.validation[name] = null;
      });
    }
  };
  const validateTime = (reason, name) => {
    if (reason) {
      setState((draft) => {
        draft.validation[name] = "Selected Time is not a valid";
      });
    } else if (reason === "minTime") {
      setState((draft) => {
        draft.validation[name] = "Selected Time is not a valid time";
      });
    } else {
      setState((draft) => {
        draft.validation[name] = null;
      });
    }
  };
  const [debouncedText] = useDebounce(
    state.stockDetails.details.alert_stock,
    1000
  );
  const handleModal = (v) => {
    setState((draft) => {
      draft.stockDetails.isOpen = v;
    });
  };

  const handleInputChange = (event, v) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "alert_stock_status") {
      setState((draft) => {
        draft.stockDetails.details.alert_stock_status = v ? 1 : 0;
      });
    } else {
      setState((draft) => {
        draft.stockDetails.details = {
          ...draft.stockDetails.details,
          [name]: value,
        };
      });
    }
  };

  useEffect(() => {
    if (debouncedText) {
      setState((draft) => {
        draft.initialLoad = false;
      });
    }
    if (!state.initialLoad && isDetailsId && !state.stockDetails.isLoading) {
      if (validator.current.allValid()) {
        handleStockAlertUpdate();
      } else {
        validator.current.showMessages();
        forceUpdate(1);
      }
    }
  }, [debouncedText, state.stockDetails.details.alert_stock_status]);

  const handleStockAlertUpdate = async () => {
    try {
      let data = {
        quantity: state.stockDetails.details.alert_stock,
        status: state.stockDetails.details.alert_stock_status,
      };
      const res = await updateStockAlert(selectedStoreId, isDetailsId, data);
      if (res.success) {
        successMessage("Stock alerts updated");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleStockUpdate = async () => {
    if (!state.stockDetails.isEdit) {
      setState((draft) => {
        draft.stockDetails.isEdit = !draft.isEdit;
      });
    } else {
      try {
        setState((draft) => {
          draft.stockDetails.isSaveButton = true;
        });
        let data = {
          quantity: state.stockDetails.details.stock,
        };

        const res = await updateStockQuantity(
          selectedStoreId,
          isDetailsId,
          data
        );

        if (res.success) {
          setState((draft) => {
            draft.stockDetails.isEdit = false;
            draft.stockDetails.isSaveButton = false;
          });
          successMessage("Stock Updated Successfully");
        } else {
          errorMessage(res.message);
          setState((draft) => {
            draft.stockDetails.isSaveButton = false;
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
        setState((draft) => {
          draft.stockDetails.isSaveButton = false;
        });
      }
    }
  };

  const handleClearStock = async (v) => {
    try {
      let data = {
        auto_clear_stock: v ? 1 : 0,
      };
      const res = await updateClearStock(selectedStoreId, isDetailsId, data);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.stockDetails.details.auto_clear_stock = v ? 1 : 0;
        });
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const StockSoldoutStatusChange = (e) => {
    setState((draft) => {
      draft.stockOutDetails.back_in_stock_type = e.target.value;
    });
  };

  const handleInputDateAndTime = (e, type) => {
    if (type === "time") {
      setState((draft) => {
        draft.stockOutDetails.time = e;
      });
    } else if (type === "date") {
      setState((draft) => {
        draft.stockOutDetails.date = e;
      });
    }
  };

  useEffect(() => {
    if (isDetailsId) {
      HandleStoreProducstDetails(isDetailsId);
    }
  }, [isDetailsId]);

  const HandleStoreProducstDetails = async (id) => {
    if (state.stockDetails.isLoading) {
      try {
        const res = await getSAProductDetailsById(selectedStoreId, id);
        if (res.success) {
          setState((draft) => {
            draft.stockDetails.details = res.data[0];
            draft.stockOutDetails.sold_out_status = res.data[0].sold_out_status;
            draft.stockDetails.date = res.data[0].back_in_stock_time;
            draft.stockDetails.time = res.data[0].back_in_stock_time;
            draft.stockDetails.isLoading = false;
            draft.stockOutDetails.time = res.data[0].back_in_stock_time;
            draft.stockOutDetails.date = res.data[0].back_in_stock_time;
            draft.stockOutDetails.back_in_stock_type =
              res.data[0].back_in_stock_type;
          });
        }
      } catch (err) {
        setState((draft) => {
          draft.stockDetails.isLoading = false;
        });
      }
    }
  };

  const HandleProductsPriceLevel = async (id) => {
    if (state.priceLevel.isLoading) {
      try {
        const res = await getProductPriceLevel(id);
        if (res.success) {
          setState((draft) => {
            draft.priceLevel.dataList = res.data;
            draft.priceLevel.isLoading = false;
          });
        }
      } catch (err) {
        setState((draft) => {
          draft.priceLevel.isLoading = false;
        });
      }
    }
  };

  useEffect(() => {
    if (IsPriceLevel) {
      HandleProductsPriceLevel(IsPriceLevel);
    }
  }, [IsPriceLevel]);
  const handleUpdateStockSoldoutStatus = async (v) => {
    try {
      setState((draft) => {
        draft.stockOutDetails.isSaveButton = true;
        draft.stockDetails.date = state.stockOutDetails.date;
        draft.stockDetails.time = state.stockOutDetails.time;
        draft.stockDetails.details.back_in_stock_type =
          state.stockOutDetails.back_in_stock_type;
      });
      const dataAndTime =
        state.stockOutDetails.back_in_stock_type === "sold_out_until"
          ? `${moment(state.stockOutDetails.date, "DD-MM-YYYY HH:mm:ss").format(
              "YYYY-MM-DD"
            )} ${moment(
              state.stockOutDetails.time,
              "DD-MM-YYYY HH:mm:ss"
            ).format("HH:mm")}`
          : state.stockDetails.details.back_in_stock_time;

      let data = {
        stock_status: v,
        back_in_stock_type: state.stockOutDetails.back_in_stock_type,
        back_in_stock_time:
          state.stockOutDetails.date && state.stockOutDetails.time
            ? dataAndTime
            : state.stockDetails.details.back_in_stock_time,
      };
      const res = await updateStockSoldoutStatus(
        selectedStoreId,
        isDetailsId,
        data
      );

      if (res.success) {
        successMessage("Stock Updated Successfully");
        setState((draft) => {
          draft.stockOutDetails.isSaveButton = false;
          draft.stockDetails.isOpen = false;
          draft.stockDetails.details.sold_out_status = v;
          draft.stockDetails.details.back_in_stock_time = dataAndTime;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.stockOutDetails.isSaveButton = false;
      });
      errorMessage(err.response.data.message);
    }
  };

  const handleSoldoutStatus = (v) => {
    if (v === true) {
      setState((draft) => {
        draft.stockDetails.isOpen = v;
      });
    } else if (v === false) {
      setState((draft) => {
        draft.stockDetails.isOpen = v;
        draft.stockOutDetails.date = state.stockDetails.date;
        draft.stockOutDetails.time = state.stockDetails.time;
        draft.stockOutDetails.back_in_stock_type =
          state.stockDetails.details.back_in_stock_type;
      });
      if (state.stockDetails.details.sold_out_status === 0) {
        handleUpdateStockSoldoutStatus(1);
      }
    }
  };
  return {
    state,
    globalData,
    validator,
    setState,
    handleModal,
    handleInputChange,
    handleStockUpdate,
    StockSoldoutStatusChange,
    handleInputDateAndTime,
    handleClearStock,
    handleSoldoutStatus,
    handleUpdateStockSoldoutStatus,
    validateDate,
    validateTime,
    setState,
  };
};
