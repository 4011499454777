import { createContext } from "react";
import { useAuth } from "../shared/hooks/useAuth";

export const AuthContext = createContext({});

// context provider
export const AuthProvider = ({ children }) => {
  const value = useAuth();

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
