import React, { useState } from "react";
import {
  ContentLayout,
  DeleteModal,
  HeaderBreadcrumbs,
  Loader,
  PageNotFound,
  useTabs,
} from "../../../shared";
import { Button, Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import {
  FavouritesBasicDetails,
  FavouritesProductsList,
  FavouritesStoreList,
} from "../components";
import { useFavourites } from "../hooks";
import { AppContext } from "../../../store/AppScopeProvider";


export const SuperFavouritesDetailsList = ({ isStoreAdmin }) => {
  const { id } = useParams();
  const { appState } = React.useContext(AppContext);
  const {
    globalData,
    currentTabs: { favourites: currentTab },
  } = appState;
  const { setCurentTab } = useTabs();
  const {
    state,
    onChangeFavGroup,
    onUpdateFavGroup,
    onSaveFavGroup,
    toggleCreateOrEditModal,
    toggleModal,
    handleDeleteFav,
    isEditable,
    isDeletable,
  } = useFavourites({
    isFav: false,
    favId: id,
    isProducts: false,
    isStores: false,
  });
  const { httpStatusCodes, favDetails } = state;

  const handleChange = (event, newValue) => {
    setCurentTab("favourites", newValue);
  };

  if (httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }

  return (
    <>
      <ContentLayout
        title={state.favDetails.data.name}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: isStoreAdmin ? "/store-admin" : "/" },
              { name: "Favourites", href: isStoreAdmin ? "/store-admin/favourites" : "/favourites" },
              { name: state.favDetails.data?.name || "" },
            ]}
          />
        }
        rightContent={
          <>
            {isDeletable && (
              <Button
                type="button"
                size="medium"
                variant="text"
                className="delte"
                onClick={() => toggleModal(id, "detaials")}
                sx={{
                  minWidth: "40px",
                  p: "8px",
                  opacity:
                    isStoreAdmin && favDetails.data.type === "global" && 0.5,
                }}
                disabled={isStoreAdmin && favDetails.data.type === "global"}
              >
                <img alt="delete" src="/icons/ic_delete_blue.svg" />
              </Button>
            )}
          </>
        }
      >
        <TabContext value={currentTab}>
          <Box className="sticky-Tab" sx={{ borderBottom: 1, borderColor: "divider", top: "104px" }}>
            <TabList onChange={handleChange}>
              <Tab label="Details" value="1" />
              <Tab label="Products" value="2" />
              {!isStoreAdmin && <Tab label="Stores" value="3" />}
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            <FavouritesBasicDetails
              onChangeFavGroup={onChangeFavGroup}
              state={state}
              onSaveFavGroup={onSaveFavGroup}
              onUpdateFavGroup={onUpdateFavGroup}
              toggleCreateOrEditModal={toggleCreateOrEditModal}
              isEditable={isEditable}
              isStoreAdmin={isStoreAdmin}
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            <FavouritesProductsList />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="3">
            <FavouritesStoreList />
          </TabPanel>
        </TabContext>
      </ContentLayout>
      <DeleteModal
        open={state.isOpen}
        handleClose={() => toggleModal()}
        onConfirm={() => handleDeleteFav("details")}
      />
    </>
  );
};
