import React, { useContext, useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../store";
import { API, errorMessage } from "../../utils";

export const UnAuthorisedMaster = () => {
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const onLogout = async () => {
    const res = await API.post(`logout`);
    return res.data;
  };

  const handleLogout = async () => {
    if (auth.data.id) {
      try {
        setLoading(true);
        const res = await onLogout();
        if (res.success) {
          localStorage.clear();
          sessionStorage.clear()
          window.location.pathname = "/authentication/login";
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorMessage(err.response.data.message);
      }
    } else {
      navigate("/authentication/login");
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 3,
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography align="center" color="textPrimary" variant="h4">
          Unauthorized: You don't have the permission to view this page.
        </Typography>
        <Typography align="center" color="textPrimary" variant="subtitle2">
          You either tried some shady route or you came here by mistake.
          Whichever it is, try using the navigation
        </Typography>

        <Button
          onClick={handleLogout}
          disabled={loading}
          component="a"
          sx={{ mt: 3 }}
          variant="contained"
        >
          Go to Login
        </Button>
      </Box>
    </Box>
  );
};
