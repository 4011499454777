import React from "react";
import { Route, Routes } from "react-router-dom";
import { FranchiseeList } from "./containers/FranchiseeList";
import { ProtectedRoute } from "../../shared";
import { AddOrEditFranchisee } from "./containers/AddOrEditFranchisee";
import { FranchiseeDetails } from "./containers/FranchiseeDetails";

export const Franchisee = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<FranchiseeList />} />

        <Route
          element={<ProtectedRoute allowedRoute={["franchisee-create"]} />}
        >
          <Route path="/create" element={<AddOrEditFranchisee />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["franchisee-view"]} />}>
          <Route path="/details/:id" element={<FranchiseeDetails />} />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["franchisee-modify"]} />}
        >
          <Route path="/details/edit/:id" element={<AddOrEditFranchisee />} />
        </Route>
      </Routes>
    </>
  );
};
