import { Typography } from "@mui/material";
import moment from "moment";
import * as React from "react";
import { useParams, useLocation } from "react-router-dom";
import { ContentLayout, CustomTable, Loader, DeleteModal, usePermission, HeaderBreadcrumbs } from "../../../shared";
import { useProductsSA } from "../hooks";


export const ProductsPriceLevelList = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = useProductsSA({ IsPriceLevel: id })
  const columns = React.useMemo(
    () => [
      {
        Header: "Price Level",
        accessor: "price_level_name",
        disableSortBy: true,
      },
      {
        Header: "Price",
        accessor: "price",
        Cell: ({ value }) => {
          return value ? `$${value}` : "-";
        },
        disableSortBy: true,
      },
      {
        Header: "Old Price",
        accessor: "old_price",
        Cell: ({ value }) => {
          return value ? `$${value}` : "-";
        },
        disableSortBy: true,
      },
      {
        Header: "Special Price (Start Date - End Date )",
        accessor: "special_price",
        accessor: (row, index) => {
          return (
            <>
              {row.special_price ? `$${row.special_price}` : "-"}
              {row.special_price_start_date && (
                <><br />
                  <Typography variant="caption" color="#636363"> {moment(row.special_price_start_date).format('DD MMM YYYY hh:mm a')}</Typography></>
              )
              }
              {
                row.special_price_end_date && (
                  <>
                    <br />
                    <Typography variant="caption" color="#636363">{moment(row.special_price_end_date).format('DD MMM YYYY hh:mm a')}</Typography>
                  </>
                )
              }

            </>

          );
        },
        disableSortBy: true,
      },
    ],
    []
  );
  return (
    <>
      {state.priceLevel.isLoading ? (
        <Loader />
      ) : (
        <ContentLayout
          title={location.state ?? ""}
          breadcrumbs={
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/store-admin" },
                { name: "Products", href: "/store-admin/products" },
                { name: location.state ?? "" }
              ]}
            />
          }
        >

          <CustomTable
            columns={columns}
            data={state.priceLevel.dataList}
            searchBar={false}
            tableTitle="Price Levels"
            pagination={false}
          />

        </ContentLayout>
      )}
    </>
  )
};
