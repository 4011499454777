import {
  ContentLayout,
  DeleteModal,
  CustomeDialog as CustomDialog,
  CustomTable,
  HeaderBreadcrumbs,
  usePermission,
} from "../../../shared";
import * as React from "react";
import { Button, TextField, Stack, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { ActionButtons, QRCodeDetailsDialog } from "../components";
import { useQrCodeList } from "../hooks/useQrCodeList";
import { copyToClipboard } from "../../../utils";

export const QRCodeList = () => {
  const {
    state,
    tableId,
    breadcrumbLinks,
    toggleDialog,
    handleConfirmDelete,
    toggleDeleteDialog,
    handleTableSearch,
    handleFilterChange,
  } = useQrCodeList();
  const navigate = useNavigate();

  // Check if the user has permission to modify QR codes
  const modifyPermission = usePermission("qrcode-modify");

  // Check if the user has permission to delete QR codes
  const deletePermission = usePermission("qrcode-delete");

  const columns = React.useMemo(
    () => [
      {
        Header: "QR Image",
        id: "name",
        accessor: (row) => {
          return (
            <Box
              component="img"
              alt="QR code"
              src={row.qrcode_image}
              sx={{ cursor: "pointer", width: "46px", height: "46px" }}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Store",
        accessor: "store_name",
      },

      {
        Header: "Location",
        accessor: "location",
      },

      {
        Header: "Remarks",
        accessor: "remarks",
      },
      {
        Header: "Dynamic Link",
        accessor: (row) => {
          return (
            <Stack direction="row" gap={1}>
              {row.dynamic_link}
              <div
                className="action-buttons"
                onClick={(event) => {
                  event.stopPropagation();
                  copyToClipboard(row.dynamic_link);
                }}
              >
                <img alt="details" src="/icons/ic_copy.svg" />
              </div>
            </Stack>
          );
        },
      },
      {
        Header: "Scan",
        accessor: "scan_count",
      },
      {
        Header: "Orders",
        accessor: "order_count",
      },
      {
        Header: "Conversion Percentage",
        accessor: "conversion_percentage",
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          return (
            <ActionButtons
              row={original}
              toggleDeleteDialog={toggleDeleteDialog}
              toggleDialog={toggleDialog}
              deletePermission={deletePermission}
              modifyPermission={modifyPermission}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <ContentLayout
        breadcrumbs={<HeaderBreadcrumbs heading="" links={breadcrumbLinks} />}
        title="QR Code"
        rightContent={
          <Button
            type="button"
            size="medium"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate("create")}
          >
            Add QR Code
          </Button>
        }
      >
        <CustomTable
          columns={columns}
          tableId={tableId}
          defaultSearch={false}
          data={state.qr.list}
          isLoading={state.qr.isLoading}
          resetPagination={state.qr.resetPagination}
          filterContent={
            <InputControl
              type="table-search"
              placeholder="Search"
              value={state.qr.search}
              onChange={(event) => handleTableSearch(event.target.value)}
              onClear={() => handleTableSearch("")}
            />
          }
          filterSection={
            <InputControl
              type="dropdown"
              name="store_id"
              label="Store"
              options={state.filter.storeList}
              value={state.qr.appliedFilter}
              onChange={(_, value) => handleFilterChange(value)}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  {/* <Checkbox style={{ marginRight: 8 }} checked={selected} /> */}
                  {option.label}
                </li>
              )}
              sx={{ width: "250px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Store"
                  required
                  placeholder="Select Store"
                />
              )}
            />
          }
          onRowAction={(row) => {
            return toggleDialog(row.id);
          }}
        />
      </ContentLayout>

      {/*Delete QR code dialog  */}
      <DeleteModal
        open={state.deleteAlert.open}
        handleClose={toggleDeleteDialog}
        onConfirm={handleConfirmDelete}
      />

      {/*QR code details dialog  */}
      <CustomDialog
        open={state.isDialogOpen}
        handleClose={() => toggleDialog()}
        PaperProps={{
          sx: { width: "418px", p: "26px" },
        }}
        content={<QRCodeDetailsDialog />}
      />
    </>
  );
};
