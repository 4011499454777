import React from "react";
import { Route, Routes } from "react-router-dom";
import { AddOrEditPushNotification, PushNotificationList } from "./containers";
import { ProtectedRoute } from "../../shared";

export const PushNotification = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<PushNotificationList />} />
        <Route element={<ProtectedRoute allowedRoute={["refund-view"]} />}>
          <Route path="/create" element={<AddOrEditPushNotification />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["refund-view"]} />}>
          <Route path="/edit/:id" element={<AddOrEditPushNotification />} />
        </Route>
      </Routes>
    </>
  );
};
