import React, { useState } from "react";
import {
  ContentLayout,
  CustomeDialog,
  CustomTable,
  DeleteModal,
  useTabs,
} from "../../../shared";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { sortItems } from "../../../utils";
import { GroupNameDialog } from "./GroupNameDialog";
import { useFavourites } from "../hooks/useFavourites";
import InputControl from "../../../shared/components/InputControl";

export const StoreFavouritesList = () => {
  const navigate = useNavigate();
  const { resetCurrentTabs } = useTabs();
  const {
    state,
    toggleModal,
    handleDeleteFav,
    onChangeFavGroup,
    onSaveFavGroup,
    toggleCreateOrEditModal,
    onUpdateFavGroup,
    onFavGroupOrderChange,
    isEditable,
    isCreateVisible,
    isDeletable,
    debouncedText,
    onSeachClear,
    onChangeSearchText,
  } = useFavourites({
    isFav: true,
    favId: null,
    isProducts: false,
    isStores: false,
  });
  const { favList, isBusy, isOpen, isCreateOpen, isEditOpen, filters } = state;

  const ActionButtons = ({ row, deleteAction, editAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isEditable && (
          <img
            className={row.type === "global" && "disabled-img"}
            alt="edit"
            onClick={() => {
              row.type !== "global" && editAction(row.id, "edit");
            }}
            src="/icons/ic_edit.svg"
          />
        )}
        {isDeletable && (
          <img
            alt="delete"
            className={row.type === "global" && "disabled-img"}
            onClick={() => {
              row.type !== "global" && deleteAction(row.id);
            }}
            src="/icons/ic_delete.svg"
          />
        )}

        <img
          onClick={() => {
            navigate(`/store-admin/favourites/details/${row.id}`);
            resetCurrentTabs();
          }}
          alt="details"
          src="/icons/arrow_forward.svg"
        />
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Group Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Created By",
        accessor: "user_name",
        Cell: ({ value, row }) => {
          return (
            <>
              <div>{value}</div>
              <Typography variant="caption" color="#808080">
                {row.original.type}
              </Typography>
            </>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        disableSortBy: true,
        align: "right",
        accessor: (row, index) => {
          return (
            <ActionButtons
              row={row}
              deleteAction={toggleModal}
              editAction={toggleCreateOrEditModal}
            />
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <ContentLayout
        title="Favourites"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => toggleCreateOrEditModal(null, "create")}
              >
                New Group
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={favList}
          isLoading={isBusy}
          defaultSearch={false}
          searchBar={true}
          filterContent={
            <InputControl
              type="table-search"
              value={filters.searchText}
              onChange={(e) => onChangeSearchText(e)}
              onClear={onSeachClear}
            />
          }
          onRowLink={(e) => {
            return `details/${e.id}`;
          }}
          onRowAction={(e) => {
            resetCurrentTabs();
          }}
        />
      </ContentLayout>
      <CustomeDialog
        open={isCreateOpen || isEditOpen}
        handleClose={() => toggleCreateOrEditModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "423px", height: "235px", p: "16px" },
        }}
        title="New Group"
        content={
          <GroupNameDialog
            onChangeFavGroup={onChangeFavGroup}
            state={state}
            onSaveFavGroup={onSaveFavGroup}
            onUpdateFavGroup={onUpdateFavGroup}
            isEdit={isEditOpen}
          />
        }
      />
      <DeleteModal
        open={isOpen}
        handleClose={() => toggleModal()}
        onConfirm={() => handleDeleteFav()}
      />
    </>
  );
};
