import { ContentLayout, CustomTable, DeleteModal } from "../../../shared";
import * as React from "react";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { sortItems } from "../../../utils";
import { usePriceLevel } from "../hooks/usePriceLevel";

export const PriceLevelList = () => {
  const navigate = useNavigate();
  const {
    state,
    handleModal,
    handleDeletePriceLevel,
    isDeletable,
    isCreateVisible,
    isEditable,
  } = usePriceLevel({
    isPriceLevel: true,
    isProduct: false,
  });
  const ActionButtons = ({ id, editAction, deleteAction }) => {
    return (
      <div className="action-buttons">
        <Typography
          sx={{ cursor: "pointer" }}
          fontWeight={400}
          fontSize={"14px"}
          color="#1560D4"
          onClick={() => navigate(`/price-levels/${id}`)}
          className="LinkBtn"
        >
          Products
        </Typography>
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
        {isEditable && (
          <Link to={`edit/${id}`}>
            <img alt="edit" src="/icons/ic_edit.svg" />
          </Link>
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Code",
        accessor: "alias",
        disableSortBy: true,
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => {
                handleModal(row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <ContentLayout
        title="Price levels"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("create")}
              >
                New Price level
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={state?.pricelevel}
          isLoading={state?.isBusy}
        />
      </ContentLayout>
      <DeleteModal
        open={state.isOpen}
        handleClose={() => handleModal()}
        onConfirm={() => handleDeletePriceLevel()}
      />
    </>
  );
};
