import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import * as React from "react";
import { Button, Stack, Card, Typography, Grid } from "@mui/material";

import InputControl from "../../../shared/components/InputControl";
import { useParams } from "react-router-dom";
import { useTax } from "..";
import SimpleReactValidator from "simple-react-validator";

export const CreateOrEditTaxGroup = () => {
  const { id } = useParams();
  const updateMode = id ? true : false;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      messages: {
        required: "The tax group name field is required.",
        // OR
        // will override all messages
      },
    })
  );
  const { state, handleTaxGroupInputChange, onSaveTaxGroup, onUpdateTaxGroup } =
    useTax({
      isTaxGroupId: id,
      isTaxGroupEdit: updateMode,
    });
  const { updateData, isSaveButton, editLoading, details } = state.taxGroup;
  const { name } = updateData;
  const onSubmit = () => {
    if (validator.current.allValid()) {
      updateMode ? onUpdateTaxGroup(id) : onSaveTaxGroup();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <ContentLayout
        title={updateMode ? details?.name : "New Tax Group"}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Taxes", href: "/tax-groups-and-taxes" },
              updateMode ? {
                name: details?.name,
                href: `/tax-groups-and-taxes/tax-groups/details/${id}`,
              } : null,
              { name: updateMode ? "Edit" : "Create" },
            ].filter(Boolean)}
          />
        }
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              onClick={onSubmit}
              disabled={isSaveButton}
            >
              {updateMode ? "Update" : "Save"}
            </Button>
          </>
        }
      >
        {editLoading ? (
          <Loader />
        ) : (
          <Card sx={{ p: "32px 24px", mb: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  sx={{ width: "150px" }}
                >
                  Tax Group Name
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <Stack width={"100%"} gap="20px">
                  <InputControl
                    name="name"
                    label="Name *"
                    value={name}
                    onChange={handleTaxGroupInputChange}
                    error={validator.current.message(
                      "Name",
                      name,
                      "required|min:2"
                    )}
                    helperText={validator.current.message(
                      "Name",
                      name,
                      "required|min:2"
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Card>
        )}
      </ContentLayout>
    </>
  );
};
