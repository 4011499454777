import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  CreateOrEditProducts,
  ProductsDetailsList,
  CreateOrEditProductsSA,
  ProductsPriceLevelList,
} from "..";
import { ProtectedRoute } from "../../shared";
import {
  ProductSeoEdit,
  StoreProductsList,
  SuperProductsList,
} from "./components";

export const Products = () => {
  const { pathname } = useLocation();

  const isStoreAdmin = pathname.includes("store-admin");
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={isStoreAdmin ? <StoreProductsList /> : <SuperProductsList />}
        />
        <Route element={<ProtectedRoute allowedRoute={["product-view"]} />}>
          <Route path="/details/:id" element={<ProductsDetailsList />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["product-modify"]} />}>
          <Route path="/edit/:id" element={<CreateOrEditProducts />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["product-create"]} />}>
          <Route path="/create" element={<CreateOrEditProducts />} />
        </Route>
        <Route path="/stocks/:id" element={<CreateOrEditProductsSA />} />
        <Route path="/price-level/:id" element={<ProductsPriceLevelList />} />
        <Route path="/details/:id/seo" element={<ProductSeoEdit />} />
      </Routes>
    </>
  );
};
