import * as React from "react";
import { DeleteModal, ServerPaginatedTable } from "../../../shared";
import { Link, useNavigate } from "react-router-dom";
import { useStateList } from "../hooks";
import { usePermission } from "../../../shared/hooks/usePermission";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { sortItems } from "../../../utils";
import InputControl from "../../../shared/components/InputControl";

export const StateList = () => {
  const navigate = useNavigate();
  const isEditable = usePermission("state-modify");
  const isDeletable = usePermission("state-delete");
  const isCreateVisible = usePermission("state-create");
  const {
    state,
    handleModal,
    handleDeleteState,
    onSeachClear,
    onSearch,
    setPageSize,
    gotoPage,
  } = useStateList({
    isState: true,
  });
  const { stateDetails } = state;

  const ActionButtons = ({ id, editAction, deleteAction }) => {
    return (
      <div className="action-buttons">
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
        {isEditable && (
          <Link to={`state/edit/${id}`}>
            <img alt="edit" src="/icons/ic_edit.svg" />
          </Link>
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "State Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Action",
        id: "action",
        disableSortBy: true,
        align: "right",
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              editAction={() => navigate(`state/edit/${row.id}`)}
              deleteAction={() => handleModal("state", row.id)}
            />
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <ServerPaginatedTable
        columns={columns}
        data={stateDetails.states}
        isLoading={stateDetails.isBusy}
        defaultSearch={false}
        gotoPage={(e) => gotoPage(e, "state")}
        setPageSize={(e) => setPageSize(e, "state")}
        pagination={stateDetails.pagination}
        sortByGlobal={[]}
        manualSortBy={false}
        // handleSort={(e) => console.log(e)}
        filterContent={
          <>
            <InputControl
              type="table-search"
              name="searchText"
              placeholder="Search"
              size="small"
              value={stateDetails.filterApplied.search}
              onChange={(e) => onSearch(e, "stateDetails")}
              onClear={() => onSeachClear("stateDetails")}
            />
          </>
        }
        tableTitle="States"
        headerRightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("state/create")}
              >
                New State
              </Button>
            )}
          </>
        }
      />
      <DeleteModal
        open={stateDetails.isOpen}
        handleClose={() => handleModal("state")}
        onConfirm={() => handleDeleteState()}
      />
    </>
  );
};
