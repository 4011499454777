import * as React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  FormControl,
  Button,
  Stack,
  InputAdornment,
  OutlinedInput,
  Box,
  Avatar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { Loader } from "..";
import CloseIcon from "@mui/icons-material/Close";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { ListItemAvatar } from "@material-ui/core";
import DoneIcon from "@mui/icons-material/Done";

export const InfiniteScrollDialog = (props) => {
  const navigate = useNavigate();
  const {
    handleSelect,
    isSelectedUser,
    handleChange,
    disabled,
    state,
    pageLoader,
    loadNextPage,
    hasMorePages,
    HandleFilter,
  } = props;
  const stringAvatar = (name, id) => {
    const isSelcted = isSelectedUser(id);
    return {
      sx: {
        fontSize: "16px",
        background: isSelcted ? "#1560D4" : "#edf3fc",
        color: isSelcted ? "#fff" : "#000",
      },
      children: isSelcted ? (
        <DoneIcon />
      ) : name.split(" ")[1][0] ? (
        `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      ) : (
        name.split(" ")[0][0]
      ),
    };
  };
  return (
    <>
      <Stack
        justifyContent="space-between"
        height="100%"
        gap="8px"
        maxHeight={"calc(100% - 24px)"}
      >
        <FormControl
          size="small"
          fullWidth={true}
          sx={{
            pt: "24px",
          }}
        >
          <OutlinedInput
            placeholder="Search"
            sx={{
              height: "48px",
              ".MuiOutlinedInput-input": {
                pl: "0px !important",
              },
            }}
            size="small"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon size="large" />
              </InputAdornment>
            }
            value={state?.searchText}
            endAdornment={
              state?.searchText && (
                <InputAdornment
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    HandleFilter("search", "");
                  }}
                  position="end"
                >
                  <CloseIcon size="large" />
                </InputAdornment>
              )
            }
            fullWidth
            onChange={(e) => {
              HandleFilter("search", e.target.value);
            }}
          />
        </FormControl>
        {pageLoader ? (
          <Loader />
        ) : (
          <Box
            id="scrollableDiv"
            sx={{ height: "100%", overflow: "auto" }}
            fullWidth
          >
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              dataLength={state?.customers?.length}
              next={loadNextPage}
              hasMore={hasMorePages}
              loader={
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  Loading...
                </Box>
              }
            >
              {state?.customers?.length > 0 ? (
                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                  {state?.customers?.map((data, index) => (
                    <ListItem
                      alignItems="flex-start"
                      key={index}
                      sx={{
                        height: "68px",
                        padding: " 0px 12px 12px 0px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSelect(data.id)}
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt="cemy Sharp"
                          {...stringAvatar(data.name, data.id)}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="subtitle2"
                              color="text.primary"
                            >
                              {data?.name}
                            </Typography>
                          </>
                        }
                        secondary={
                          <>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="caption"
                              color="text.secondary"
                              fontWeight={400}
                            >
                              {data?.email}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                hasMorePages !== true && (
                  <>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                      mt="160px"
                    >
                      <Button
                        variant="text"
                        startIcon={<AddIcon />}
                        onClick={() =>
                          navigate(`/store-admin/client-account/create`)
                        }
                      >
                        Add new Customer
                      </Button>
                    </Stack>
                  </>
                )
              )}
            </InfiniteScroll>
          </Box>
        )}
        <Button
          sx={{ height: "48px" }}
          variant="contained"
          fullWidth
          onClick={() => {
            handleChange(parseInt(state?.selectedUsers));
          }}
          disabled={disabled}
        >
          Next
        </Button>
      </Stack>
    </>
  );
};
