import * as React from "react";
import { Button, Chip, Box, Typography, Stack } from "@mui/material";
import { CustomDetailsList, Loader } from "../../../shared";
import { useNavigate, useParams } from "react-router-dom";

export const PreloadedCardDetailsList = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { giftCardDetails, isEditable, state } = props;
  const columns = [
    {
      title: "Gift Card Type",
      accessor: (row) => {
        return row.card_type === "1" ? "Preloaded Cards" : "Open Cards";
      },
    },
    {
      title: "Activation Type",
      hidden: (row) => {
        return row.card_type === "1" ? false : true;
      },
      accessor: (row) => {
        return (
          <Typography color="#C07302" fontWeight={400} fontSize={16}>
            {row.is_pre_activated ? "Pre-Activated" : "On Purchase"}
          </Typography>
        );
      },
    },
    {
      title: "Seller Store",
      hidden: (row) => {
        return row.card_type === "1" && row.is_pre_activated ? false : true;
      },
      accessor: "seller_store_name",
    },
    {
      title: "Batch Name",
      accessor: "batch_name",
    },
    {
      title: "Amount",
      accessor: (row) => {
        return <Stack direction="row">${row.amount?.toFixed(2)}</Stack>;
      },
      hidden: (row) => {
        return row.card_type === "1" ? false : true;
      },
    },
    {
      title: "Quantity",
      accessor: "quantity",
    },
    {
      title: "Validity",
      accessor: (row) => {
        return (
          <Stack direction="row" gap={1}>
            <Typography>
              {row?.is_year_month === 0
                ? row?.validity / 365
                : row?.validity / 30}
            </Typography>
            <Typography>
              {row.is_year_month === 1 ? "Month" : "Year"}
            </Typography>
          </Stack>
        );
      },
    },
    {
      title: "Store(s)",
      accessor: (row) => {
        return (
          <>
            {row?.stores?.length ? (
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: "8px",
                  flexWrap: "wrap",
                }}
              >
                {row?.stores?.map((data, index) => {
                  return (
                    <Chip
                      key="index"
                      sx={{
                        borderRadius: "12px",
                        background: "rgba(0, 0, 0, 0.16)",
                      }}
                      label={data.name}
                      variant="filled"
                      size="small"
                    />
                  );
                })}
              </Stack>
            ) : (
              <Typography>Gift card is applicable to all stores.</Typography>
            )}
          </>
        );
      },
    },

    {
      title: "Created Date",
      accessor: "created_date",
    },
    {
      title: "Created By",
      accessor: "created_by",
    },
  ];

  return (
    <>
      <>
        <CustomDetailsList
          columns={columns}
          data={giftCardDetails}
          title="Basic Details"
          headerRightContent={
            <>
              {isEditable && (
                <Button
                  size="medium"
                  variant="text"
                  onClick={() => navigate(`/gift-card/edit/${id}`)}
                >
                  Edit
                </Button>
              )}
            </>
          }
        />
      </>
    </>
  );
};
