import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useImmer } from "use-immer";
import { AppContext } from "../../../store/AppScopeProvider";
import qs from "qs";
import moment from "moment";
import { errorMessage, successMessage } from "../../../utils";
import {
  changeMenuBannerStatus,
  createMenuBanner,
  deleteMenuBannerById,
  editMenuBanner,
  getMenuBannerDetailsById,
  getMenuBannersList,
} from "../api/menuBannerApi";
import { usePermission } from "../../../shared";
import { getFilteredProducts } from "../../FuturePrice";

export const UseMenuBanners = ({ load = false, id = null, isProduct }) => {
  const { appState } = useContext(AppContext);
  const isEditable = usePermission("banner-modify");
  const isDeletable = usePermission("banner-delete");
  const isCreatable = usePermission("banner-create");
  const { globalData } = appState;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(null);
  const [state, setState] = useImmer({
    initialLoad: true,
    isBusy: false,
    isDetailsFetching: false,
    isSaveButtonBusy: false,
    isPublishButtonBusy: false,
    isOpen: false,
    id: null,
    bannerName: "",
    banners: [],
    products: [],
    httpStatusCodes: {
      details: "",
    },
    filters: {
      isSearching: false,
      searchText: "",
      status: "",
    },
    imageFile: "",
    data: {
      title: "",
      image: "",
      type: null,
      link_to: null,
      start_time: null,
      end_time: null,
      start_date: null,
      end_date: null,
      stores: [],
      status: 0,
    },
    validation: {
      isValidated: true,
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
    },
  });

  const [debouncedText] = useDebounce(state.filters.searchText, 1000);
  const productsArray = state.products;
  const extractedProducts = productsArray.flatMap((category) =>
    category.products.map((product) => ({
      label: product.label,
      value: product.value,
      tax: product.tax,
    }))
  );
  useEffect(() => {
    if (load) {
      getBannersList({});
    }
  }, [load]);

  useEffect(() => {
    if (id) {
      getBannerDetails(id);
    }
  }, [id]);

  const getBannersList = async ({ search = null, status = "" }) => {
    let params = {};
    if (search) {
      params = { ...params, search };
    }
    if (status !== "") {
      params = { ...params, status };
    }

    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = false;
      });
      const res = await getMenuBannersList(query);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.banners = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  useEffect(() => {
    if (!state.initialLoad && !state.filters.isSearching) {
      const { status } = state.filters;
      getBannersList({
        search: debouncedText,
        status,
      });
    }
  }, [debouncedText]);

  const getBannerDetails = async (bannerId) => {
    try {
      setState((draft) => {
        draft.isDetailsFetching = true;
      });
      const res = await getMenuBannerDetailsById(bannerId);
      if (res.success) {
        const { start_date, end_date, start_time, end_time } = res.data;
        setState((draft) => {
          draft.data = {
            ...res.data,
            start_date: start_date ? new Date(res.data.start_date) : null,
            end_date: end_date ? new Date(res.data.end_date) : null,
            start_time: start_time
              ? new Date(moment(res.data.start_time, "HH:mm:ss"))
              : null,
            end_time: end_time
              ? new Date(moment(res.data.end_time, "HH:mm:ss"))
              : null,
          };
          draft.bannerName = res.data.title;
          draft.isDetailsFetching = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isDetailsFetching = false;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };

  const handleMenuBannerDelete = async (type = "list") => {
    try {
      const res = await deleteMenuBannerById(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.banners = draft.banners.filter((item) => item.id !== state.id);
          draft.isOpen = false;
          draft.id = null;
        });
        if (type === "details") {
          navigate("/menu-banner");
        }
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onChangeFilter = (e) => {
    const { name, value } = e.target;
    const { searchText, status } = state.filters;
    if (name === "status") {
      getBannersList({
        search: searchText,
        status: value,
      });
    }
    setState((draft) => {
      draft.filters[name] = value;
      draft.filters.isSearching = false;
    });
  };

  const resetFilters = () => {
    if (state.filters.status !== "" || state.filters.searchText) {
      getBannersList({});
      setState((draft) => {
        draft.filters.status = "";
        draft.filters.searchText = "";
        draft.filters.isSearching = true;
      });
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    if (name === "type") {
      setState((draft) => {
        draft.data.link_to = null;
      });
    }
    setState((draft) => {
      draft.data[name] = value;
    });
  };

  const onImageChange = (value) => {
    setState((draft) => {
      draft.imageFile = value[0];
      draft.data.image = URL.createObjectURL(value[0]);
    });
  };

  const onSaveBanner = async (status) => {
    const {
      start_date,
      end_date,
      start_time,
      end_time,
      title,
      type,
      link_to,
      stores,
    } = state.data;
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      let payload = {
        image: state.imageFile,
        status: status === "draft" ? 0 : 1,
        title,
        type,
        link_to,
        stores,
      };
      if (start_date) {
        payload = {
          ...payload,
          start_date: moment(start_date).format("YYYY-MM-DD"),
        };
      }
      if (end_date) {
        payload = {
          ...payload,
          end_date: moment(end_date).format("YYYY-MM-DD"),
        };
      }
      if (start_time) {
        payload = {
          ...payload,
          start_time: moment(start_time).format("HH:mm:ss"),
        };
      }
      if (end_time) {
        payload = {
          ...payload,
          end_time: moment(end_time).format("HH:mm:ss"),
        };
      }
      let formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key !== "stores") {
          formData.append(key, payload[key]);
        }
      });
      payload.stores.map((store) => {
        formData.append(["store_id[]"], store.id);
      });
      const res = await createMenuBanner(formData);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate(-1);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onUpdateBanner = async (status, id) => {
    const {
      start_date,
      end_date,
      start_time,
      end_time,
      title,
      type,
      link_to,
      stores,
    } = state.data;
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      let payload = {
        status: status === "draft" ? 0 : 1,
        title,
        type,
        link_to,
        stores,
        start_date: start_date ? moment(start_date).format("YYYY-MM-DD") : "",
        end_date: end_date ? moment(end_date).format("YYYY-MM-DD") : "",
        start_time: start_time ? moment(start_time).format("HH:mm:ss") : "",
        end_time: end_time ? moment(end_time).format("HH:mm:ss") : "",
      };

      if (state.imageFile) {
        payload = {
          ...payload,
          image: state.imageFile,
        };
      }

      let formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key !== "stores") {
          formData.append(key, payload[key]);
        }
      });
      formData.append("_method", "PUT");
      payload.stores.length > 0
        ? payload.stores.map((store) => {
            formData.append(["store_id[]"], store.id);
          })
        : formData.append(["store_id"], "");

      const res = await editMenuBanner(id, formData);
      if (res.success) {
        successMessage("Updated Successfully");
        navigate(-1);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onDateChange = (e, name) => {
    setState((draft) => {
      draft.data[name] = e;
    });
  };

  const onTimeChange = (e, name) => {
    setState((draft) => {
      draft.data[name] = e;
    });
  };

  const onSeachClear = () => {
    const { status } = state.filters;
    getBannersList({
      search: null,
      status,
    });
    setState((draft) => {
      draft.filters.searchText = "";
      draft.filters.isSearching = true;
    });
  };

  const toggleModal = (isOpen = false, id = null) => {
    setState((draft) => {
      draft.isOpen = isOpen;
      draft.id = id;
    });
  };

  const toggleStatus = async (status) => {
    const payload = { status: status === 1 ? 0 : 1 };
    try {
      setState((draft) => {
        draft.isPublishButtonBusy = true;
      });
      const res = await changeMenuBannerStatus(id, payload);
      if (res.success) {
        successMessage("Status changed Successfully");
        setState((draft) => {
          draft.data.status = status === 1 ? 0 : 1;
          draft.isPublishButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isPublishButtonBusy = false;
      });
    }
  };

  const onStoresSelect = (checked, store) => {
    setState((draft) => {
      draft.data.stores = store;
    });
  };

  const checkAllChange = (allStores, e) => {
    if (e.target.checked) {
      const newItems = allStores.filter((store) => {
        return !state.data.stores.find((item) => item.id === store.id);
      });
      setState((draft) => {
        draft.data.stores = [...draft.data.stores, ...newItems];
      });
    } else {
      setState((draft) => {
        draft.data.stores = [];
      });
    }
  };

  const onDeleteSelectedStores = (id) => {
    setState((draft) => {
      draft.data.stores = draft.data.stores.filter((item) => item.id !== id);
    });
  };

  const validateDate = (reason, name, type) => {
    if (reason === "disablePast") {
      setState((draft) => {
        draft.validation[name] = "Please select a future date";
      });
    } else if (reason === "invalidDate") {
      setState((draft) => {
        draft.validation[name] =
          type === "date"
            ? "Selected Date is not a valid date"
            : "Selected Time is not valid";
      });
    } else {
      setState((draft) => {
        draft.validation[name] = null;
      });
    }
  };

  const isAllValid = () => {
    const { start_date, end_date, start_time, end_time } = state.validation;
    if (
      start_date === null &&
      end_date === null &&
      start_time === null &&
      end_time === null
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getProductsLists = async () => {
    try {
      let params = {};

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };
      const res = await getFilteredProducts(query);
      if (res.data) {
        setState((draft) => {
          draft.products = res.data
            .map((v) => {
              return (
                v.products.length > 0 && {
                  ...v,
                  products: v.products
                    .map((val) => {
                      return {
                        value: val.id,
                        label: val.name,
                        tax: val.tax_rate,
                      };
                    })
                    .filter(Boolean),
                }
              );
            })
            .filter(Boolean);
        });
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (isProduct) {
      getProductsLists();
    }
  }, [isProduct]);
  const handleClick = (id) => {
    setOpen(id === open ? null : id);
  };
  const handleDropdown = (e, newValue, type) => {
    const { value } = e.target;
    if (type === "products") {
      setState((draft) => {
        draft.data.link_to = value?.value;
      });
    }
  };
  return {
    getBannersList,
    getBannerDetails,
    handleMenuBannerDelete,
    onChangeFilter,
    resetFilters,
    onChange,
    state,
    globalData,
    resetFilters,
    toggleModal,
    onSeachClear,
    toggleStatus,
    onImageChange,
    onDateChange,
    onTimeChange,
    onStoresSelect,
    onDeleteSelectedStores,
    onUpdateBanner,
    onSaveBanner,
    isEditable,
    isCreatable,
    isDeletable,
    checkAllChange,
    validateDate,
    setState,
    isAllValid,
    extractedProducts,
    handleClick,
    open,
    handleDropdown,
  };
};
