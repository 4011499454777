import { REPORT_API } from "../../../utils";

export const getDashboardSalesGraph = async (start_date, end_date) => {
  const res = await REPORT_API.get(
    `dashboard/sales-graph?start=${start_date}&end=${end_date}`
  );
  return res.data;
};

export const getDashboardCards = async (start_date, end_date) => {
  const res = await REPORT_API.get(
    `dashboard/cards?start=${start_date}&end=${end_date}`
  );
  return res.data;
};

export const getDashboardStoreSales = async (start_date, end_date) => {
  const res = await REPORT_API.get(
    `dashboard/store-sales?start=${start_date}&end=${end_date}`
  );
  return res.data;
};
