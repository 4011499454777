import * as React from "react";
import { Chip, Typography, Button, Box, Stack, Avatar } from "@mui/material";
import { CustomDetailsList, Loader } from "../../../shared";
import { useNavigate, useParams } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "@material-ui/core";
import { successMessage } from "../../../utils";

export const StoreGeneralDetails = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { dataList, isLoading, isEditable } = props;
  const columns = [
    {
      title: "ID",
      accessor: (val) => {
        return (
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography variant="subtitle2" fontWeight={400} color="#636363">
              {val.id}
            </Typography>
            <ContentCopyIcon
              sx={{ height: "20px" }}
              className="copy-button"
              onClick={() => {
                navigator.clipboard.writeText(val.id);
                successMessage("ID is copied to the clipboard");
              }}
            />
          </Box>
        );
      },
    },
    {
      title: "Store Owner Email",
      accessor: "email",
    },
    {
      title: "Store Email",
      accessor: "contact_email",
    },
    {
      title: "Phone Number",
      accessor: "phone_formatted",
    },
    {
      title: "Suburb",
      accessor: (val) => {
        return (
          <Typography variant="subtitle2" fontWeight={400} color="#636363">
            {val.city?.name}
          </Typography>
        );
      },
    },
    {
      title: "State",
      accessor: (val) => {
        return (
          <Typography variant="subtitle2" fontWeight={400} color="#636363">
            {val.state?.name}
          </Typography>
        );
      },
    },
    {
      title: "Franchisee Group",
      accessor: (val) => {
        return (
          <>
            {val?.franchisee_id?.slice(0, 2).map((data) => {
              return (
                <Chip
                  sx={{ mr: 1, borderRadius: "8px" }}
                  label={data.name}
                  variant="filled"
                  size="small"
                />
              );
            })}
            {val?.franchisee_id?.length > 2 && (
              <Chip
                sx={{ borderRadius: "8px" }}
                label={`+${
                  val?.franchisee_id?.length -
                  val?.franchisee_id?.slice(0, 2)?.length
                }`}
                variant="filled"
                size="small"
              />
            )}
          </>
        );
      },
    },
    {
      title: "ABN",
      accessor: "abn",
    },
    {
      title: "Address",
      accessor: "address",
    },
    {
      title: "Unit Number",
      accessor: "unit_number",
    },
    {
      title: "Street Number",
      accessor: "street_number",
    },
    {
      title: "Postcode",
      accessor: "zip_code",
    },
    {
      title: "Latitude",
      accessor: "latitude",
    },
    {
      title: "Longitude",
      accessor: "longitude",
    },
  ];

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <CustomDetailsList
          columns={columns}
          data={dataList}
          title="General"
          headerRightContent={
            <>
              {isEditable && (
                <Button
                  size="medium"
                  variant="contained"
                  onClick={() => navigate(`/stores/details/edit/${id}`)}
                >
                  Edit
                </Button>
              )}
            </>
          }
        />
      )}
    </>
  );
};
