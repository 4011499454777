import {
  CustomTable,
  CustomeDialog,
  DeleteModal,
  usePermission,
  Loader,
  CheckBoxListDialog,
} from "../../../shared";
import * as React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { arraySort, sortItems } from "../../../utils";

export const ProductStoresList = (props) => {
  const {
    data,
    basicDetails,
    HandleFilterStore,
    handleStoreSelect,
    handleModal,
    handleAssignStoresToProduct,
    handleDeleteStoreFromProduct,
    handleSelectAll,
  } = props;
  const {
    dataList,
    isOpen,
    isLoading,
    dialogLoading,
    filteredList,
    selectedItems,
    searchText,
    isDeleteOpen,
    isSaveButton,
  } = data;
  const navigate = useNavigate();
  const isDeletable = usePermission("product-delete");
  const isCreate = usePermission("product-create");

  const ActionButtons = ({ deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isDeletable && (
          <img
            alt="delete"
            onClick={() => {
              !["gift_card", "delivery"].includes(
                basicDetails.details?.product_type
              ) && deleteAction();
            }}
            src="/icons/ic_delete.svg"
            className={
              ["gift_card", "delivery"].includes(
                basicDetails.details?.product_type
              ) && "disabled-img"
            }
          />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Store Name",
        id: "name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              deleteAction={() => {
                handleModal("store-from-product", true, row.id);
              }}
            />
          );
        },
      },
    ],
    [basicDetails]
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CustomTable
            columns={columns}
            data={arraySort(dataList, "name")}
            searchBar={false}
            tableTitle="Stores"
            tableId="product_stores"
            headerRightContent={
              <>
                {isCreate && (
                  <Button
                    type="button"
                    size="medium"
                    variant="text"
                    startIcon={<AddIcon />}
                    onClick={() => handleModal("store-add", true)}
                    disabled={["delivery", "gift_card"].includes(
                      basicDetails.details?.product_type
                    )}
                  >
                    Add Store
                  </Button>
                )}
              </>
            }
            onRowLink={(e) => {
              return `/stores/details/${e.id}`;
            }}
          />
          <CustomeDialog
            open={isOpen}
            handleClose={() => handleModal("store-add", false)}
            PaperProps={{
              sx: { width: "423px", height: "491px", p: "16px" },
            }}
            title="Stores"
            content={
              <CheckBoxListDialog
                Items={filteredList}
                onSearch={HandleFilterStore}
                loading={dialogLoading}
                onSelect={handleStoreSelect}
                onSubmit={handleAssignStoresToProduct}
                disabled={!selectedItems.length || isSaveButton}
                isSelected={(id) => selectedItems.includes(id)}
                onClearSearch={() => {
                  HandleFilterStore("initial");
                }}
                value={searchText}
                hasSelectAll={true}
                isSelectAllChecked={filteredList.every((item) =>
                  selectedItems.includes(item.id)
                )}
                onAllSelect={handleSelectAll}
              />
            }
          />
          <DeleteModal
            open={isDeleteOpen}
            handleClose={() => handleModal("store-from-product", false, null)}
            onConfirm={handleDeleteStoreFromProduct}
          />
        </>
      )}
    </>
  );
};
