import React from "react";
import { ContentLayout, Loader } from "../../../shared";
import { DashBoardDateRangePicker, StatsCards, DashboardChart, useDashboard, CardsLoader, DashboardChartLoader } from "..";
import { Stack, Typography } from "@mui/material";


export const DashboardUi = () => {
  const { state, isStoreAdmin, onChangeDate, handleIsOpen, handleDateChangeApply } = useDashboard()
  return (
    <>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "16px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: 600,
          }}
        >
          Overview
        </Typography>
        <DashBoardDateRangePicker
          onChangeDate={onChangeDate}
          range={state.date}
          showRange={state.showDate}
          isOpen={state.isOpen}
          handleIsOpen={handleIsOpen}
          handleDateChangeApply={handleDateChangeApply}
          className="dashBoard-calender"
        />
      </Stack>
      {state.isLoad1 ? <CardsLoader /> : <StatsCards data={state.cardsData} />}
      {state.isLoad2 ? <DashboardChartLoader title="Gross Sales Graph" /> : (
        <>
          {state.salesGraph["x-axis"]?.length > 0 && (
            <DashboardChart
              index={1}
              data={state.salesGraph}
              title="Gross Sales Graph"
              barColor="#A04D3D"
            />
          )}
        </>
      )}
      {!isStoreAdmin && state.isLoad3 ? <DashboardChartLoader title="Store Gross Sales" horizontal={true} /> : (
        <>
          {state.storeSales["x-axis"]?.length > 0 && (
            <DashboardChart
              index={2}
              data={state.storeSales}
              title="Store Gross Sales"
              barColor="#564845"
              horizontal={true}
            />
          )}
        </>
      )}
    </>
  );
};
