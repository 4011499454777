import * as React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ContentLayout, CustomTable } from "../../../shared";
import { useList } from "../hooks/useList";
import InputControl from "../../../shared/components/InputControl";

export const ListDetails = () => {
  const navigate = useNavigate();
  const {
    state,
    handleSearch,
    onSeachClear,
    handlePSAndBSReportList,
    isCreateVisible,
    isEditable,
  } = useList({
    isList: true,
    isStore: false,
  });
  const ActionButtons = ({ id }) => {
    return (
      isEditable && (
        <img
          alt="details"
          src="/icons/arrow_forward.svg"
          onClick={() => navigate(`edit/${id}`)}
        />
      )
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Store",
        accessor: "store_name",
      },
      {
        Header: "Month ",
        accessor: "month",
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Excel File",
        accessor: "excel_file",
        Cell: ({ row }) => {
          return (
            <Box
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                color: "blue",
              }}
            >
              <Link
                underline="none"
                component="button"
                variant="body2"
                onClick={() => handlePSAndBSReportList(row?.original?.id)}
              >
                <b>Download</b>
              </Link>
            </Box>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return <ActionButtons id={row.id} />;
        },
      },
    ],
    []
  );
  return (
    <>
      <ContentLayout
        title="Account Uploads"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                onClick={() => navigate("create")}
              >
                Upload
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={state?.accountList}
          defaultSearch={false}
          isLoading={state?.isBusy}
          filterContent={
            <InputControl
              type="table-search"
              value={state?.searchText}
              onChange={(e) => handleSearch(e.target.value)}
              onClear={onSeachClear}
            />
          }
        />
      </ContentLayout>
    </>
  );
};
