import React from "react";
import { Route, Routes } from "react-router-dom";
import { StateAndSuburbList, CreateOrEditState, CreateOrEditSuburb } from "..";
import { ProtectedRoute } from "../../shared";
import { AddOrEditAvailabilityGroup } from "./containers/AddOrEditAvailabilityGroup";
import { AvaialabilityGroupList } from "./containers/AvaialabilityGroupList";
import { AvaialabilityGroupDetails } from "./containers/AvailabilityGroupDetails";

export const AvailabilityGroup = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<AvaialabilityGroupList />} />
       
        <Route element={<ProtectedRoute allowedRoute={["availability-group-create"]} />}>
          <Route path="/create" element={<AddOrEditAvailabilityGroup />} />
        </Route>
         <Route element={<ProtectedRoute allowedRoute={["availability-group-modify"]} />}>
          <Route path="/details/:id" element={<AvaialabilityGroupDetails />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["availability-group-modify"]} />}>
          <Route path="/details/edit/:id" element={<AddOrEditAvailabilityGroup />} />
        </Route>
        
      </Routes>
    </>
  );
};
