import {
  ContentLayout,
  CustomeDialog,
  HeaderBreadcrumbs,
  Loader,
} from "../../../shared";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Stack,
  Card,
  Typography,
  Grid,
  TextField,
  createFilterOptions,
  Checkbox,
  Divider,
  Box,
  Switch,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useStore } from "..";
import { ProductDialog } from "../../BumpScreen";

export const SuperAddOrEditBumpScreen = () => {
  const { id, bsid } = useParams();
  const {
    states,
    onChangeInput,
    onUpdateBumpScreen,
    onSaveBumpScreen,
    globalData,
    onChangeTagFilter,
    toggleViewModal,
    gotoPage,
    setPageSize,
    onAddConditions,
    handleDeleteCondition,
    handleChange,
    SaleType,
    BumpScreenTags,
    handleChangeSwitch,
  } = useStore({
    isStore: false,
    BumpScreensId: bsid,
    storeId: id,
    isTags: true,
  });
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const onSubmit = () => {
    if (validator.current.allValid()) {
      bsid ? onUpdateBumpScreen(bsid) : onSaveBumpScreen();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const ChannelType =
    globalData?.sale_channels?.map((item) => {
      return { id: item?.value, name: item?.label };
    }) || [];
  return (
    <>
      <ContentLayout
        title={bsid ? states?.bumpscreenName : "New Bump Screen"}
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              disabled={states.isSaveButtonBusy}
              onClick={() => onSubmit()}
            >
              {!bsid ? "Save" : "Update"}
            </Button>
          </>
        }
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Stores", href: "/stores" },
                bsid
                  ? {
                      name: states?.bumpscreenName,
                      href: `/stores/details/${id}`,
                    }
                  : null,

                { name: bsid ? "edit" : "New Bump Screen" },
              ].filter(Boolean)}
            />
          </>
        }
      >
        {bsid && false ? (
          <Loader />
        ) : (
          <Card sx={{ p: "32px 0px", mb: "24px" }}>
            <Stack spacing={2}>
              <Grid
                container
                spacing={2}
                sx={{
                  padding: "0 24px 16px 24px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" fontWeight={600}>
                    Bump Screen Name
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <InputControl
                    name="name"
                    label="Name"
                    onChange={handleChange}
                    value={states.bumpScreenData.name}
                    required
                    error={validator.current.message(
                      "Name",
                      states.bumpScreenData.name,
                      "required"
                    )}
                    helperText={validator.current.message(
                      "Name",
                      states.bumpScreenData.name,
                      "required"
                    )}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2} sx={{ padding: "16px 24px" }}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" fontWeight={600}>
                    Display products by alias name in KDS
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }} fontWeight={400}>
                    Disable to display products by product name
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={8}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Switch
                    name="display_product_alias"
                    checked={states.bumpScreenData.display_product_alias}
                    onChange={handleChangeSwitch}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2} sx={{ padding: "16px 24px" }}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" fontWeight={600}>
                    Display category name in KDS
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={8}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Switch
                    name="display_category_name"
                    checked={states.bumpScreenData.display_category_name}
                    onChange={handleChangeSwitch}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container sx={{ padding: "16px 24px" }}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Condition
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  {states?.bumpScreenData?.conditions?.map((item, index) => {
                    const ids = states.bumpScreenData.conditions.map(
                      (i) => i.channel_id
                    );
                    const channel = ChannelType.map((name) => name);
                    const option = channel.filter(
                      ({ id }) => !ids.includes(id)
                    );
                    return (
                      <Stack mt={2}>
                        <Card
                          sx={{ p: "32px 16px", backgroundColor: "#00000003" }}
                        >
                          <Stack width={"100%"} spacing={2}>
                            <Stack direction="row" gap={1} alignItems="center">
                              <InputControl
                                type="dropdown"
                                sx={{ backgroundColor: "#fff" }}
                                disableClearable
                                options={option}
                                id="disable-close-on-select"
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                value={
                                  (ChannelType?.length > 0 &&
                                    item.channel_id &&
                                    ChannelType?.find(
                                      (c) => c.id === item.channel_id
                                    )) ||
                                  null
                                }
                                onChange={(event, newValue) => {
                                  onChangeInput(
                                    {
                                      target: {
                                        name: "channel_id",
                                        value: newValue.id,
                                      },
                                    },
                                    index
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Sales Channel"
                                    error={validator.current.message(
                                      "Sales Channel",
                                      item.channel_id,
                                      "required"
                                    )}
                                    helperText={validator.current.message(
                                      " Sales Channel",
                                      item.channel_id,
                                      "required"
                                    )}
                                    required
                                  />
                                )}
                              />

                              <Box sx={{ "& button": { m: 1 } }}>
                                <Button
                                  color="error"
                                  onClick={() => {
                                    handleDeleteCondition(index);
                                  }}
                                  disabled={
                                    states.bumpScreenData.conditions.length < 2
                                  }
                                >
                                  Delete
                                </Button>
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              gap={1}
                              alignContent="center"
                              alignItems="center"
                            >
                              <InputControl
                                value={item?.tags}
                                sx={{ backgroundColor: "#fff" }}
                                type="dropdown"
                                className="tags-input"
                                multiple
                                filterSelectedOptions
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option.name;
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue.name;
                                  }
                                  return option.name;
                                }}
                                options={BumpScreenTags}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(event, newValue) => {
                                  onChangeInput(
                                    {
                                      target: {
                                        name: "tags",
                                        value: newValue,
                                      },
                                    },
                                    index
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Bump Screen Tags"
                                  />
                                )}
                                renderOption={(props, option, { selected }) => (
                                  <>
                                    <li {...props} key={option}>
                                      <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.name}
                                    </li>
                                  </>
                                )}
                              />
                              <Button
                                sx={{ minWidth: "130px" }}
                                onClick={() =>
                                  toggleViewModal(
                                    true,
                                    "type",
                                    index,
                                    item.tags
                                  )
                                }
                              >
                                View Products
                              </Button>
                            </Stack>
                            <Stack direction="row" mb={1}>
                              <img alt="edit" src="/icons/icon_not.svg" />
                              <Typography
                                color="#636363"
                                fontWeight={400}
                                fontSize="14px"
                                ml={1}
                              >
                                If not selected all products are applicable.
                              </Typography>
                            </Stack>

                            <InputControl
                              name="sales"
                              sx={{ backgroundColor: "#fff" }}
                              type="dropdown"
                              className="tags-input"
                              multiple
                              options={SaleType}
                              filterSelectedOptions
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === "string") {
                                  return option.name;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue.name;
                                }
                                // Regular option
                                return option.name;
                              }}
                              value={item?.sales}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              onChange={(event, newValue) => {
                                onChangeInput(
                                  {
                                    target: {
                                      name: "sales",
                                      value: newValue,
                                    },
                                  },
                                  index
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sales Type" />
                              )}
                            />

                            <Stack direction="row">
                              <img alt="edit" src="/icons/icon_not.svg" />
                              <Typography
                                color="#636363"
                                fontWeight={400}
                                fontSize="14px"
                                ml={1}
                              >
                                If not selected all sales types are applicable.
                              </Typography>
                            </Stack>
                          </Stack>
                        </Card>
                      </Stack>
                    );
                  })}
                  <Stack mt={2}>
                    <Button
                      type="button"
                      size="small"
                      variant="contained"
                      onClick={onAddConditions}
                      startIcon={<AddIcon />}
                      sx={{ width: "153px" }}
                    >
                      Add Condition
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Card>
        )}
      </ContentLayout>
      <CustomeDialog
        open={states.isViewOpen}
        handleClose={() => toggleViewModal(false)}
        PaperProps={{
          sx: {
            width: "730px",
          },
        }}
        customTitlePadding="16px 16px 0 16px"
        title={`Products(${states?.productsList.length})`}
        content={
          <ProductDialog
            state={states}
            onChangeTagFilter={onChangeTagFilter}
            gotoPage={gotoPage}
            setPageSize={setPageSize}
          />
        }
      />
    </>
  );
};
