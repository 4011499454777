import * as React from "react";
import { Button, Stack, Card, Switch, Typography } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { Loader, usePermission } from "../../../shared";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { useImmer } from "use-immer";

export const ProductAvailability = (props) => {
  const isEdit = usePermission("product-modify");
  const { data, handleAvailabilityDate, onSubmitAvailability, validateDate, isAllValid, isNotValidated, validation, productType } = props;
  const { details, isLoading, isSaveButton } = data;

  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        endDate: {
          required: true,
          message: "The end date should not be before start date",
          rule: (value) => {
            const { startTime, endTime } = value;
            return (startTime && endTime) && moment(startTime).isSameOrBefore(moment(endTime));
          },
        },
      },
    })
  );

  const onSubmit = () => {
    if (validator.current.allValid() && isAllValid()) {
      onSubmitAvailability("date");
    } else {
      validator.current.showMessages();
      forceUpdate(1);
      isNotValidated()
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {productType !== "modifier" && (
            <>
              <Card sx={{ p: "32px 24px", mb: "24px" }}>
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Typography variant="subtitle1" fontWeight={600}>
                      Pre Order
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="#636363"
                      fontWeight={400}
                    >
                      {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. */}
                    </Typography>
                  </Stack>
                  <Switch
                    checked={details.pre_order_status === 1 ? true : false}
                    onChange={(e) => {
                      onSubmitAvailability("pre-order");
                    }}
                  />
                </Stack>
              </Card>

              <Card sx={{ p: "32px 24px", mb: "24px" }}>
                <Stack>
                  <Typography variant="subtitle1" fontWeight={600}>
                    Set Date Range
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  gap="20px"
                  alignItems="baseline"
                  sx={{
                    mt: "32px",
                  }}
                >
                  <InputControl
                    type="datePicker"
                    name="availability_start_date*"
                    label="Start Date"
                    disablePast
                    inputFormat="dd/MM/yyyy"
                    sx={{
                      width: "100%",
                    }}
                    value={details.availability_start_date}
                    onChange={(e) => {
                      handleAvailabilityDate(e, "availability_start_date");
                    }}
                    onError={(reason, value) =>
                      validateDate(reason, "start_date", "date")
                    }
                    error={
                      validation.isValidated &&
                      (validation.start_date ?? false)
                    }
                    helperText={
                      validation.isValidated &&
                      (validation.start_date ?? false)
                    }
                  />
                  <InputControl
                    type="datePicker"
                    name="availability_end_date*"
                    label="End Date"
                    inputFormat="dd/MM/yyyy"
                    disablePast
                    sx={{
                      width: "100%",
                    }}
                    value={details.availability_end_date}
                    onChange={(e) => {
                      handleAvailabilityDate(e, "availability_end_date");
                    }}
                    onError={(reason, value) =>
                      validateDate(reason, "end_date", "date")
                    }
                    error={
                      validation.isValidated &&
                      (validation.end_date
                        ? validation.end_date
                        : details.availability_start_date &&
                        validator.current.message(
                          "End Date",
                          {
                            startTime: details.availability_start_date,
                            endTime: details.availability_end_date,
                          },
                          "endDate"
                        ))
                    }
                    helperText={
                      validation.isValidated &&
                      (validation.end_date
                        ? validation.end_date
                        : details.availability_start_date &&
                        validator.current.message(
                          "End Date",
                          {
                            startTime: details.availability_start_date,
                            endTime: details.availability_end_date,
                          },
                          "endDate"
                        ))
                    }
                  />
                  {isEdit && (
                    <Button
                      variant="contained"
                      sx={{ height: "40px" }}
                      disabled={isSaveButton}
                      onClick={() => onSubmit()}
                    >
                      Save
                    </Button>
                  )}
                </Stack>
              </Card>
            </>
          )}
          <Card sx={{ p: "32px 24px", mb: "24px" }}>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography variant="subtitle1" fontWeight={600}>
                  Status
                </Typography>
              </Stack>
              <Switch
                checked={details.status === 1 ? true : false}
                onChange={(e) => {
                  onSubmitAvailability("status");
                }}
              />
            </Stack>
          </Card>

          {productType !== "modifier" && (
            <Card sx={{ p: "32px 24px", mb: "24px" }}>
              <Stack direction="row" justifyContent="space-between">
                <Stack>
                  <Typography variant="subtitle1" fontWeight={600}>
                    Show in List
                  </Typography>
                </Stack>
                <Switch
                  checked={details.visible_individually === 1 ? true : false}
                  onChange={(e) => {
                    onSubmitAvailability("visibility");
                  }}
                />
              </Stack>
            </Card>
          )}
        </>
      )}
    </>
  );
};
