import { API } from "../../../utils";

export const getOnlineOrdering = async (query) => {
  const res = await API.get("online_ordering/stores", query);
  return res.data;
};
export const editStoreStatus = async (payload) => {
  const res = await API.put(`online_ordering/stores`, payload);
  return res.data;
};
