import {
  ContentLayout,
  HeaderBreadcrumbs,
  Loader,
  useDropdownApis,
} from "../../../shared";
import * as React from "react";
import {
  Button,
  Stack,
  Card,
  Switch,
  Typography,
  Grid,
  TextField,
  Radio,
  FormHelperText,
  Box,
  FormControlLabel,
  Divider,
  Checkbox,
  Chip,
  Link,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useClientAccount } from "../hooks";
import { DayPicker } from "../../Coupons";
import { useStore } from "../../Stores";
import { AsyncPaginate } from "react-select-async-paginate";
import { useCustomers } from "../..";
import AddIcon from "@mui/icons-material/Add";

export const CreateClientAccount = () => {
  const { id, cid } = useParams();
  const { pathname } = useLocation();
  const location = useLocation();
  const isCustomer = pathname.includes("customers");
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const {
    state,
    globalData,
    handleChange,
    onDayPicker,
    onCreateClientAccount,
    isStoreAdmin,
    handleGetCustomerList,
    onDeleteCustomerSelect,
    value,
    setValue,
  } = useClientAccount({ isList: false, id: id, isCreate: true });

  const { state: ClientState } = useCustomers({ clid: id });
  const navigate = useNavigate();
  const { updateData } = state.clientAccountDetails;
  const { storeFilteredClientAccounts } = state;
  const { storeList } = useDropdownApis({ isStoreList: !isStoreAdmin });
  const array = storeList.filter((op) => {
    return !ClientState.clientAccount.dataList.find(
      (item) => item.store_id === +op.id
    );
  });
  const optionsStores = array?.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const [input, setInput] = React.useState({});
  const onClientAccountSubmit = () => {
    if (validator.current.allValid()) {
      onCreateClientAccount(value);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  React.useEffect(() => {
    if (!updateData.bill_day) {
      setValue(1);
    } else {
      setValue(2);
    }
  }, [updateData.bill_day]);

  const options = [
    {
      label: "First",
      value: 1,
    },
    {
      label: "Second",
      value: 2,
    },
    {
      label: "Third",
      value: 3,
    },
    {
      label: "Fourth",
      value: 4,
    },
  ];
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: "2px solid #E6E8F0",
      height: "48px",
    }),
    option: (provided, state) => ({
      ...provided,
      maxHeight: "239px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
  };
  const CustomOption = (props) => {
    const { data, innerRef, innerProps, isSelected } = props;
    return (
      <>
        {data.custom ? (
          <Stack
            ref={innerRef}
            {...innerProps}
            sx={{
              flexDirection: "row",
              p: "7px 16px",
              gap: "4px",
              alignItems: "center",
            }}
          >
            <AddIcon
              sx={{
                color: "#1560D4",
              }}
            />

            <Link
              target="_blank"
              sx={{ textDecoration: "none" }}
              onClick={() =>
                navigate(
                  isStoreAdmin
                    ? "/store-admin/client-account/customer/create"
                    : "/client-account/customer/create"
                )
              }
            >
              Add new Customer
            </Link>
          </Stack>
        ) : (
          <Stack
            ref={innerRef}
            {...innerProps}
            sx={{
              flexDirection: "row",

              p: "7px 8px",

              gap: "2px",
            }}
          >
            <Checkbox
              checked={isSelected}
              sx={{
                p: "8px",
              }}
            />
            <Stack
              sx={{
                gap: "2px",
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight={400}
                lineHeight="17px"
              >
                {data.label}
              </Typography>
              <Typography color="#666666" variant="caption" lineHeight="15px">
                {data.email} {data.phone}
              </Typography>
            </Stack>
          </Stack>
        )}
      </>
    );
  };
  const onInputChange = (
    inputValue,

    { action }
  ) => {
    if (action === "input-change") {
      setInput(inputValue);
    }
  };
  return (
    <>
      <ContentLayout
        title="New Client Account"
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              disabled={state.isSaveButtonBusy}
              onClick={() => onClientAccountSubmit()}
            >
              Save
            </Button>
          </>
        }
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={
                isCustomer
                  ? [
                      {
                        name: "Dashboard",
                        href: isStoreAdmin ? "/store-admin" : "/",
                      },
                      {
                        name: "Customers",
                        href: isStoreAdmin
                          ? `/store-admin/customers/details/${id}`
                          : `/customers/details/${id}`,
                      },
                      { name: "create" },
                    ]
                  : [
                      {
                        name: "Dashboard",
                        href: isStoreAdmin ? "/store-admin" : "/",
                      },
                      {
                        name: "Client Account",
                        href: isStoreAdmin
                          ? `/store-admin/client-account`
                          : `/client-account`,
                      },
                      { name: "create" },
                    ]
              }
            />
          </>
        }
      >
        {false ? (
          <Loader />
        ) : (
          <>
            <Card sx={{ marginBottom: "20px" }}>
              {validator.current.purgeFields()}
              {!isStoreAdmin ? (
                <>
                  <Stack p="24px 24px 16px">
                    <Stack direction="row" gap="24px" width="100%">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                          <Typography
                            variant="subtitle1"
                            fontWeight={600}
                            sx={{ width: "150px" }}
                          >
                            Store
                          </Typography>
                        </Grid>
                        <Grid item sm={8}>
                          <Stack direction="row" spacing={2}>
                            {isCustomer ? (
                              <InputControl
                                type="dropdown"
                                disableClearable
                                options={optionsStores}
                                getOptionLabel={(option) => option.label}
                                value={
                                  (optionsStores.length > 0 &&
                                    updateData?.store_id &&
                                    optionsStores?.find(
                                      (c) => c.value === updateData?.store_id
                                    )) ||
                                  null
                                }
                                onChange={(e, newValue) => {
                                  handleChange({
                                    target: {
                                      name: "store_id",
                                      value: newValue.value,
                                    },
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Store"
                                    required
                                    error={validator.current.message(
                                      "Store",
                                      updateData.store_id,
                                      "required"
                                    )}
                                    helperText={validator.current.message(
                                      "Store",
                                      updateData.store_id,
                                      "required"
                                    )}
                                  />
                                )}
                              />
                            ) : (
                              <InputControl
                                type="dropdown"
                                disableClearable
                                options={optionsStores}
                                getOptionLabel={(option) => option.label}
                                value={
                                  (optionsStores.length > 0 &&
                                    updateData?.store_id &&
                                    optionsStores?.find(
                                      (c) => c.value === updateData?.store_id
                                    )) ||
                                  null
                                }
                                onChange={(e, newValue) => {
                                  handleChange({
                                    target: {
                                      name: "store_id",
                                      value: newValue.value,
                                    },
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Store"
                                    required
                                    error={validator.current.message(
                                      "Store",
                                      updateData.store_id,
                                      "required"
                                    )}
                                    helperText={validator.current.message(
                                      "Store",
                                      updateData.store_id,
                                      "required"
                                    )}
                                  />
                                )}
                              />
                            )}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                  <Divider marginTop={2} />
                </>
              ) : (
                ""
              )}
              <Stack p="24px 24px 16px">
                <Stack direction="row" gap="24px" width="100%">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ width: "150px" }}
                      >
                        Customer
                      </Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <Stack direction="column" spacing={2}>
                        {isCustomer ? (
                          <InputControl
                            value={location?.state?.name}
                            disabled
                          />
                        ) : (
                          <>
                            <Stack>
                              <AsyncPaginate
                                debounceTimeout={1000}
                                placeholder="Name*"
                                className="AsyncPaginate-dropdown"
                                classNamePrefix="react-select"
                                isMulti
                                hideSelectedOptions={false}
                                closeMenuOnSelect={false}
                                inputValue={input}
                                onInputChange={onInputChange}
                                value={updateData.customer_id}
                                key={JSON.stringify(updateData.store_id)}
                                components={{ Option: CustomOption }}
                                loadOptions={async (
                                  e,
                                  loadedOptions,
                                  { page }
                                ) => {
                                  const customerData =
                                    await handleGetCustomerList({
                                      search: e,
                                      page,
                                      loadedOptions,
                                    });
                                  const filteredCustomerData = {
                                    ...customerData,
                                    options: customerData.options.filter(
                                      (op) => {
                                        return !storeFilteredClientAccounts.find(
                                          (item) =>
                                            item.customer_id === +op.value
                                        );
                                      }
                                    ),
                                  };

                                  return filteredCustomerData;
                                }}
                                controlShouldRenderValue={false}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "customer_id",
                                      value: e,
                                    },
                                  });
                                }}
                                additional={{
                                  page: 1,
                                }}
                                styles={customStyles}
                              />
                              <FormHelperText sx={{ color: "#C60808" }}>
                                {validator.current.message(
                                  "Customer Name",
                                  updateData.customer_id,
                                  "required"
                                )}
                              </FormHelperText>
                              <Stack
                                sx={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                  gap: "12px",
                                }}
                              >
                                {updateData.customer_id.map((data, index) => (
                                  <Chip
                                    key={index}
                                    label={
                                      <Typography
                                        variant="body2"
                                        fontSize="12px"
                                      >
                                        {data.label}
                                      </Typography>
                                    }
                                    onDelete={() => {
                                      onDeleteCustomerSelect(data);
                                    }}
                                    sx={{
                                      backgroundColor: "#F2F2F2",
                                      height: "30px",
                                      "& .MuiChip-deleteIcon": {
                                        color: "#CCCCCC",
                                      },
                                    }}
                                  />
                                ))}
                              </Stack>
                            </Stack>
                          </>
                        )}
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          py="10px"
                        >
                          <Typography variant="subtitle1" fontWeight={600}>
                            Status
                          </Typography>
                          <Switch
                            name="status"
                            defaultChecked={updateData.status ? true : false}
                            value={updateData.status}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>

              <Divider marginTop={2} />
              <Stack p="24px 24px 16px">
                <Stack direction="row" gap="24px" width="100%">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ width: "150px" }}
                      >
                        Credit Limit
                      </Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <InputControl
                        name="carry_forward"
                        type="number"
                        label="Opening Credit / Carry Forward ($)"
                        value={updateData.carry_forward}
                        onChange={handleChange}
                        error={validator.current.message(
                          "Opening Credit",
                          updateData.carry_forward,
                          "numeric|min:0,num"
                        )}
                        helperText={validator.current.message(
                          "Opening Credit",
                          updateData.carry_forward,
                          "numeric|min:0,num"
                        )}
                      />
                      <Stack direction="row" spacing={2} mt={2}>
                        <InputControl
                          label="Maximum Credit Amount (In $)"
                          type="number"
                          name="credit_limit"
                          value={updateData.credit_limit?.replace(/\,/g, "")}
                          onChange={handleChange}
                          error={validator.current.message(
                            "Maximum Credit Amount",
                            updateData.credit_limit,
                            "required|numeric|min:0,num"
                          )}
                          helperText={validator.current.message(
                            "Maximum Credit Amount",
                            updateData.credit_limit,
                            "required|numeric|min:0,num"
                          )}
                          required
                        />

                        <Grid item xs={12} sm={8}>
                          <Typography variant="subtitle1" fontWeight={600}>
                            Restrict Credit
                          </Typography>
                          <Typography color="#636363">
                            Restrict user to maximum credit amount only.
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <Stack gap="20px" alignItems="flex-end">
                            <Switch
                              name="restrict_usage"
                              defaultChecked={
                                updateData.restrict_usage ? true : false
                              }
                              value={updateData.restrict_usage}
                              onChange={handleChange}
                            />
                          </Stack>
                        </Grid>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
              <Divider marginTop={2} />
              <Grid
                container
                spacing={2}
                marginTop={2}
                sx={{ padding: "20px" }}
              >
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" fontWeight={600}>
                    Statement Frequency
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack width={"100%"} gap="20px" direction="column">
                    <Stack direction="row" gap="24px">
                      <Typography width="95px" mt={1}>
                        Every
                      </Typography>
                      <Stack>
                        <Stack direction="row" gap="24px">
                          <InputControl
                            type="number"
                            name="bill_duration"
                            width="48px"
                            value={updateData.bill_duration}
                            onChange={handleChange}
                            error={validator.current.message(
                              "Bill duration",
                              updateData.bill_duration,
                              updateData.bill_period === 1
                                ? "required|numeric|min:1,num"
                                : updateData.bill_period === 2
                                ? "required|numeric|max:12,num|min:1,num"
                                : "required|numeric|min:1,num"
                            )}
                          />

                          <InputControl
                            type="select"
                            name="bill_period"
                            label=""
                            value={updateData.bill_period}
                            optionValue={
                              globalData?.client_accounts?.bill_period ?? []
                            }
                            width="150px"
                            onChange={(e, v) => handleChange(e)}
                            error={validator.current.message(
                              "Bill Period",
                              updateData.bill_period,
                              "required"
                            )}
                          />
                        </Stack>
                        <FormHelperText sx={{ color: "#C60808" }}>
                          {validator.current.message(
                            "Bill duration",
                            updateData.bill_duration,
                            updateData.bill_period === 1
                              ? "required|numeric|min:1,num"
                              : updateData.bill_period === 2
                              ? "required|numeric|max:12,num|min:1,num"
                              : "required|numeric|min:1,num"
                          )}
                          {validator.current.message(
                            "Bill Period",
                            updateData.bill_period,
                            "required"
                          )}
                        </FormHelperText>
                      </Stack>
                    </Stack>
                    {updateData.bill_period === 1 ? (
                      <Stack direction="row" gap="24px" spacing={8}>
                        <Typography width="89">Day</Typography>
                        <Stack width="100%">
                          <DayPicker
                            options={globalData?.client_accounts?.bill_day}
                            onDayPicker={onDayPicker}
                            data={updateData?.bill_day}
                          />
                          <FormHelperText sx={{ color: "#C60808" }}>
                            {updateData.bill_period === 1 &&
                              validator.current.message(
                                "Bill Day",
                                updateData.bill_day,
                                "required"
                              )}
                          </FormHelperText>
                        </Stack>
                      </Stack>
                    ) : updateData.bill_period === 2 ? (
                      <>
                        <Stack
                          direction="row"
                          gap="22px"
                          alignItems={"flex-start"}
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={value === 1 ? true : false}
                                value={1}
                                onClick={() => {
                                  setValue(1);
                                }}
                              />
                            }
                            label="On day"
                          />

                          <Box>
                            <InputControl
                              type="number"
                              name="bill_date"
                              value={updateData.bill_date}
                              onChange={handleChange}
                              disabled={value === 2}
                              error={
                                value === 1 &&
                                validator.current.message(
                                  "Bill Date",
                                  updateData.bill_date,
                                  "required|numeric|max:31,num|min:1,num"
                                )
                              }
                              width={"45px"}
                            />
                            <FormHelperText sx={{ color: "#C60808" }}>
                              {value === 1 &&
                                validator.current.message(
                                  "Bill Date",
                                  updateData.bill_date,
                                  "required|numeric|max:31,num|min:1,num"
                                )}
                            </FormHelperText>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          gap="24px"
                          alignItems="flex-start"
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={value === 2 ? true : false}
                                value={2}
                                onClick={() => setValue(2)}
                              />
                            }
                            label="On the"
                          />
                          <Stack>
                            <Stack direction="row" gap="25px">
                              <InputControl
                                type="select"
                                name="bill_date2"
                                value={updateData.bill_date2}
                                optionValue={options}
                                disabled={value === 1}
                                onChange={handleChange}
                                width="180px"
                                error={
                                  value === 2 &&
                                  validator.current.message(
                                    "Bill Date",
                                    updateData.bill_date2,
                                    "required"
                                  )
                                }
                                helperText={
                                  value === 2 &&
                                  validator.current.message(
                                    "Bill Date",
                                    updateData.bill_date2,
                                    "required"
                                  )
                                }
                              />
                              <InputControl
                                type="select"
                                name="bill_day"
                                value={updateData.bill_day}
                                optionValue={
                                  globalData?.client_accounts?.bill_day ?? []
                                }
                                disabled={value === 1}
                                onChange={handleChange}
                                width="180px"
                                error={
                                  value === 2 &&
                                  validator.current.message(
                                    "Bill Day",
                                    updateData.bill_day,
                                    "required"
                                  )
                                }
                                helperText={
                                  value === 2 &&
                                  validator.current.message(
                                    "Bill Day",
                                    updateData.bill_day,
                                    "required"
                                  )
                                }
                              />
                            </Stack>
                          </Stack>
                        </Stack>
                      </>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Box mt={3}>
                <Divider />
              </Box>
            </Card>
          </>
        )}
      </ContentLayout>
    </>
  );
};
