import {
  ContentLayout,
  PageNotFound,
  HeaderBreadcrumbs,
} from "../../../shared";
import * as React from "react";
import {
  Button,
  Stack,
  Card,
  Typography,
  Grid,
  Chip,
  TextField,
  Checkbox,
  createFilterOptions,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useParams } from "react-router-dom";

import SimpleReactValidator from "simple-react-validator";
import { useAvailabilityGroup } from "../hooks/useAvailabilityGroup";
import moment from "moment";

export const AddOrEditAvailabilityGroup = () => {
  const { id } = useParams();
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        endTime: {
          message: "The end time should greater than start time",
          rule: (value) => {
            const { startTime, endTime } = value;
            return moment(startTime, "HH:mm:ss").isSameOrBefore(
              moment(endTime, "HH:mm:ss")
            );
          },
        },
      },
    })
  );
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.name,
  });
  const {
    state,
    onChange,
    onChangeTime,
    onSaveAvailabilityGroup,
    onUpdateAvailabilityGroup,
    salesChannelOptions,
    onDeleteSalesChannel,
    isEditable,
    isCreateVisible,
  } = useAvailabilityGroup({
    isAvailable: false,
    AvailabilityGroupId: id,
    isPoduct: false,
    isStore: false,
    isSales: true,
  });

  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdateAvailabilityGroup(id) : onSaveAvailabilityGroup();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }
  return (
    <>
      <ContentLayout
        title={id ? state.name : "New Group"}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Availability Group", href: "/availabilityGroup" },
                id
                  ? {
                      name: state.name,
                      href: `/availabilityGroup/details/${id}`,
                    }
                  : null,
                { name: id ? "Edit" : "Create" },
              ].filter(Boolean)}
            />
          </>
        }
        rightContent={
          <>
            <Button
              onClick={() => onSubmit()}
              type="button"
              size="medium"
              variant="contained"
              disabled={state.isSaveButtonBusy}
            >
              {id ? "Update" : "Save"}
            </Button>
          </>
        }
      >
        <Card sx={{ p: "32px 24px", mb: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Details
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack width={"100%"} gap="20px">
                <InputControl
                  name="name"
                  label="Group Name"
                  value={state.data.name}
                  onChange={onChange}
                  required
                  error={validator.current.message(
                    "Group Name",
                    state.data.name,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "Group Name",
                    state.data.name,
                    "required"
                  )}
                />

                <Stack direction="row" gap="20px">
                  <InputControl
                    ampm={false}
                    type="timePicker"
                    label="Start Time *"
                    name="start_time"
                    value={moment(state.data.start_time, "HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                    onChange={(time) => onChangeTime(time, "start_time")}
                    error={validator.current.message(
                      "Start Time",
                      state.data.start_time,
                      "required"
                    )}
                    helperText={validator.current.message(
                      "Start Time",
                      state.data.start_time,
                      "required"
                    )}
                  />

                  <InputControl
                    ampm={false}
                    type="timePicker"
                    label="End Time *"
                    name="end_time"
                    value={moment(state.data.end_time, "HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                    onChange={(time) => onChangeTime(time, "end_time")}
                    error={
                      !state.data.start_time
                        ? validator.current.message(
                            "End Time",
                            state.data.start_time,
                            "required"
                          )
                        : validator.current.message(
                            "End Time",
                            {
                              startTime: state.data.start_time,
                              endTime: state.data.end_time,
                            },
                            "required|endTime"
                          )
                    }
                    helperText={
                      !state.data.start_time
                        ? validator.current.message(
                            "End Time",
                            state.data.start_time,
                            "required"
                          )
                        : validator.current.message(
                            "End Time",
                            {
                              startTime: state.data.start_time,
                              endTime: state.data.end_time,
                            },
                            "required|endTime"
                          )
                    }
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Sales Channel
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack width={"100%"} gap="20px">
                <Stack gap="20px">
                  <InputControl
                    type="dropdown"
                    name="sale_channels"
                    label="Sales Channel"
                    multiple={true}
                    options={salesChannelOptions ?? []}
                    value={
                      salesChannelOptions?.filter((op) => {
                        return state.data.sale_channels?.find(
                          (item) => item.id === op.id
                        );
                      }) || null
                    }
                    disableCloseOnSelect
                    disableClearable={true}
                    onChange={(e, v) =>
                      onChange({
                        target: {
                          name: "sale_channels",
                          value: v,
                        },
                      })
                    }
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => <></>)
                    }
                    renderOption={(props, option, { selected }) => (
                      <>
                        <li {...props} key={option.id}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      </>
                    )}
                    filterOptions={filterOptions}
                    sx={{ width: "100%" }}
                    componentsProps={{
                      paper: {
                        sx: {
                          width: "100%",
                          minWidth: 250,
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Sales Channel"
                        placeholder="Sales Channel"
                        error={validator.current.message(
                          "Sales Channel",
                          state.data.sale_channels,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Sales Channel",
                          state.data.sale_channels,
                          "required"
                        )}
                      />
                    )}
                  />

                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "12px",
                    }}
                  >
                    {state?.data?.sale_channels?.map((data, index) => (
                      <Chip
                        key={index}
                        label={
                          <Typography variant="body2" fontSize="14px">
                            {data?.name}
                          </Typography>
                        }
                        onDelete={() => {
                          onDeleteSalesChannel(data.id);
                        }}
                        sx={{
                          backgroundColor: "#F2F2F2",
                          height: "30px",
                          "& .MuiChip-deleteIcon": {
                            color: "#CCCCCC",
                          },
                        }}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </ContentLayout>
    </>
  );
};
