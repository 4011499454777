import {
  ContentLayout,
  DeleteModal,
  HeaderBreadcrumbs,
  Loader,
  PageNotFound,
} from "../../../shared";
import * as React from "react";
import { Button, Avatar, Box, Tab } from "@mui/material";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useCategory } from "../hooks";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { CategoryGeneralDetails, CategoryProductsDetails } from "../components";


export const CategoryDetails = () => {
  const { id } = useParams();
  const {
    state,
    toggleModal,
    handleDeleteCategory,
    isEditable,
    isDeletable,
    currentTab,
    setCurentTab,
    onCategoryProductOrderChange,
    onSaveCategoryProductOrderChange,
  } = useCategory({
    isCategory: false,
    categoryId: id,
    isProduct: false,
  });
  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }
  const handleChange = (event, newValue) => {
    setCurentTab("categoryDetails", newValue);
  };
  return (
    <>
      <ContentLayout
        title={state.name}
        active={state.data?.status === 1 ? "Active" : "Inactive"}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Category", href: "/category" },
              { name: state.name, },
            ]}
          />
        }
        backImage={
          <Avatar
            sx={{ bgcolor: state.data.color, width: "80px", height: "80px" }}
          >
            <img
              width="37px"
              height="25px"
              alt="details avatar"
              src={state.data.icon}
              className="details-avater-img"
            />
          </Avatar>
        }
        rightContent={
          currentTab === 1 ? (
            <>
              {isDeletable && (
                <Button
                  aria-label="delete"
                  color="primary"
                  type="button"
                  size="medium"
                  variant="outlined"
                  className="delte"
                  disabled={state.data.product_count > 0}
                  onClick={() => toggleModal(id, "detaials")}
                  sx={{
                    minWidth: "40px",
                    p: "8px",
                  }}
                >
                  <img
                    className={state.data.product_count > 0 && "img-disabled "}
                    src="/icons/ic_delete_blue.svg"
                  />
                </Button>
              )}
            </>
          ) : (
            <>
              {/* <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                // onClick={() => navigate("create")}
              >
                New section
              </Button> */}
            </>
          )
        }
      >
        {state.isBusy ? (
          <Loader />
        ) : (
          <TabContext value={currentTab}>
            <Box className="sticky-Tab" sx={{ borderBottom: 1, borderColor: "divider", top: "128px" }}>
              <TabList onChange={handleChange}>
                <Tab label="Details" value="1" />
                <Tab label="Products" value="2" />
              </TabList>
            </Box>
            <TabPanel sx={{ px: "0" }} value="1">
              <CategoryGeneralDetails
                state={state}
                isEditable={isEditable}
                id={id}
              />
            </TabPanel>
            <TabPanel sx={{ px: "0" }} value="2">
              <CategoryProductsDetails
                state={state}
                onCategoryProductOrderChange={onCategoryProductOrderChange}
                setCurentTab={setCurentTab}
                onSave={onSaveCategoryProductOrderChange}
              />
            </TabPanel>
          </TabContext>
        )}
      </ContentLayout>
      <DeleteModal
        open={state.isOpen}
        handleClose={() => toggleModal(null, "details")}
        onConfirm={() => {
          handleDeleteCategory("details");
        }}
      />
    </>
  );
};
