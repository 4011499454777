import {
  getProductsInStore,
  deleteProductFromStores,
  assignProductToStore,
  getCategorywiseProductsInStore,
} from "../..";
import { useImmer } from "use-immer";
import { useEffect } from "react";
import { errorMessage, successMessage } from "../../../utils";
import { useDebounce } from "use-debounce";
import { useCategory } from "../../Category/hooks";
import { usePermission } from "../../../shared";

export const useStoreProducts = ({ storeId, isProduct, isCategory }) => {
  const isEditable = usePermission("store-modify");
  const isDeletable = usePermission("store-delete");
  const isCreateVisible = usePermission("store-create");
  const isVisible = usePermission("store-view");
  const [state, setState] = useImmer({
    isBusy: false,
    isDelete: false,
    id: null,
    isOpen: false,
    initialLoad: true,
    productDetails: {
      isLoading: true,
      isSaveButtonBusy: false,
      isOpen: false,
      isDelete: false,
      id: null,
      productList: [],
      allPoducts: [],
      filteredPoducts: [],
      selectedPoducts: [],
      allCategoryProductList: [],
    },
    PriceLevels: [],
    filters: {
      isSearching: false,
      searchText: "",
      category: "",
    },
    data: [
      {
        id: "",
        name: "",
        slug: "",
        plu: "",
        image: "",
        category: {
          id: "",
          name: "",
        },
        tags: [],
        status: "",
      },
      {
        id: "",
        name: "",
        slug: "",
        plu: "",
        image: "",
        category: {
          id: "",
          name: "",
        },
        tags: [],
        status: "",
      },
    ],
  });
  const [debouncedText] = useDebounce(state.filters.searchText, 1000);
  const { state: CategoryState } = useCategory({ isCategory: true });
  const options = CategoryState?.category?.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  useEffect(() => {
    if (storeId) {
      getProductsInStores(storeId);
    }
  }, [storeId]);

  const getProductsInStores = async (
    storeId,
    search = null,
    category = null
  ) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });
      const res = await getProductsInStore(storeId, search, category);
      if (res.success) {
        setState((draft) => {
          draft.data = res.data;
          draft.productDetails.productList = res.data;
          draft.isBusy = false;
          draft.initialLoad = false;
        });
      }
    } catch {
      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
      });
    }
  };
  useEffect(() => {
    if (!state.initialLoad && !state.filters.isSearching) {
      const { category } = state.filters;
      getProductsInStores(storeId, debouncedText, category);
    }
  }, [debouncedText]);
  const DeleteModals = (state, data) => {
    setState((draft) => {
      draft.isDelete = state;
      draft.productDetails.id = data;
    });
  };
  const handleDeleteProducts = async (id) => {
    try {
      const res = await deleteProductFromStores(
        storeId,
        state.productDetails.id
      );
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.productDetails.productList =
            draft.productDetails.productList.filter(
              (item) => item.id !== state.productDetails.id
            );
        });
        DeleteModals(false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleModals = (e, id) => {
    setState((draft) => {
      draft.isOpen = e;

      draft.productDetails.selectedPoducts = [];
      return draft;
    });
    HandleFilterProduct("initial");
  };
  const HandleFilterProduct = (type, text) => {
    const ProductList = state.productDetails.allCategoryProductList.map((val) => {
      let filtered = val.products.filter((data) => {
        if (!['delivery', 'gift_card'].includes(data.type)) return true;
      })
      return filtered.length ? { ...val, products: filtered } : undefined
    }).filter(Boolean)
    if (type === "initial") {
      setState((draft) => {
        draft.productDetails.filteredPoducts = ProductList;
        return draft;
      });
    } else {
      const Search = (data, text) => {
        text = text.toLowerCase();
        return data.map((val, i) => {
          let filtered = val.products.filter((data) => {
            if (data.name.toString().toLowerCase().indexOf(text) > -1) return true;
          })
          return filtered.length ? { ...val, products: filtered } : undefined
        }).filter(Boolean)
      };

      setState((draft) => {
        draft.productDetails.filteredPoducts = Search(ProductList, text)
        return draft;
      });
    }
  };
  useEffect(() => {
    HandleFilterProduct("initial");
  }, [
    state.productDetails.allCategoryProductList,
    state.productDetails.productList,
  ]);
  const handlePoductSelect = (type, value, event) => {
    if (type === "body") {
      if (event.target.checked) {
        setState((draft) => {
          draft.productDetails.selectedPoducts = [
            ...draft.productDetails.selectedPoducts,
            value.id,
          ];
        });
      } else {
        setState((draft) => {
          draft.productDetails.selectedPoducts =
            draft.productDetails.selectedPoducts.filter(
              (val) => val !== value.id
            );
        });
      }
    } else {
      const subRowsID = value.products?.map((e, i) => {
        return e.id;
      });
      if (event.target.checked) {
        const alReadyIds = state.productDetails.productList.map((e, i) => {
          return e.id;
        });
        setState((draft) => {
          draft.productDetails.selectedPoducts = [
            ...new Set([...state.productDetails.selectedPoducts, ...subRowsID]),
          ].filter((val) => !alReadyIds.includes(val));
        });
      } else {
        setState((draft) => {
          draft.productDetails.selectedPoducts =
            draft.productDetails.selectedPoducts.filter(
              (val) => !subRowsID.includes(val)
            );
        });
      }
    }
  };
  const handleAssignProducttoStore = async () => {
    const data = {
      product_id: state.productDetails.selectedPoducts,
    };
    setState((draft) => {
      draft.productDetails.isSaveButtonBusy = true;
    });
    try {
      const res = await assignProductToStore(storeId, data);
      if (res.success === true) {
        successMessage(res.message);
        setState((draft) => {
          draft.productDetails.productList = [
            ...draft.productDetails.productList,
            ...draft.productDetails.allPoducts.filter((val) =>
              draft.productDetails.selectedPoducts.includes(val.id)
            ),
          ];
          draft.productDetails.isLoading = false;
          draft.productDetails.selectedPoducts = [];
          draft.productDetails.isSaveButtonBusy = false;
          return draft;
        });
        handleModals(false);
        getProductsInStores(storeId);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.productDetails.isSaveButtonBusy = false;
      });
    }
  };
  const onChangeFilter = (e) => {
    const { name, value } = e.target;

    const { searchText, category } = state.filters;
    if (name === "category") {
      getProductsInStores(storeId, searchText, value);
    }
    setState((draft) => {
      draft.filters[name] = value;
      draft.filters.isSearching = false;
    });
  };

  const resetFilters = () => {
    if (
      (storeId, toString(state.filters.category) || state.filters.searchText)
    ) {
      getProductsInStores(storeId);
      setState((draft) => {
        draft.filters.category = "";
        draft.filters.searchText = "";
        draft.filters.isSearching = true;
      });
    }
  };

  const handleSelectAllProducts = (checked) => {
    if (checked) {
      setState((draft) => {
        draft.productDetails.selectedPoducts = [
          ...draft.productDetails.filteredPoducts.map((item) => item.id),
        ];
      });
    } else {
      setState((draft) => {
        draft.productDetails.selectedPoducts = [];
      });
    }
  };
  useEffect(() => {
    if (storeId) {
      getCategorywiseProductInStoreList();
    }
  }, [storeId]);
  const getCategorywiseProductInStoreList = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = false;
      });
      const res = await getCategorywiseProductsInStore();
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.productDetails.allCategoryProductList = res.data;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  return {
    state,
    DeleteModals,
    handleDeleteProducts,
    handleModals,
    handlePoductSelect,
    HandleFilterProduct,
    handleAssignProducttoStore,
    onChangeFilter,
    resetFilters,
    options,
    isCreateVisible,
    isDeletable,
    isEditable,
    isVisible,
    handleSelectAllProducts,
  };
};
