import { CustomDetailsList } from "../../../shared";
import * as React from "react";
import { Button, Chip, Stack } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";

export const AvailableDetails = (prop) => {
  const { state, isEditable } = prop;
  const navigate = useNavigate();
  const { id } = useParams();

  const [value, setValue] = React.useState("1");

  const columns = React.useMemo(
    () => [
      {
        title: "Group Name",
        accessor: "name",
      },
      {
        title: "Start Time",
        accessor: "start_time",
      },
      {
        title: "End Time",
        accessor: "end_time",
      },
      {
        title: "Sales Channel",
        accessor: (row) => {
          return (
            <>
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: "8px",
                  flexWrap: "wrap",
                }}
              >
                {row?.sale_channels?.map((data, index) => {
                  return (
                    <Chip
                      key="index"
                      sx={{
                        borderRadius: "12px",
                        background: "rgba(0, 0, 0, 0.16)",
                      }}
                      label={data?.name}
                      variant="filled"
                      size="small"
                    />
                  );
                })}
              </Stack>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <CustomDetailsList
        columns={columns}
        data={state.data}
        title="Details"
        headerRightContent={
          <>
            {isEditable && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                flex-direction="right"
                onClick={() =>
                  navigate(`/availabilityGroup/details/edit/${id}`)
                }
              >
                Edit
              </Button>
            )}
          </>
        }
      />
    </>
  );
};
