import React from "react";
import { Route, Routes } from "react-router-dom";
import { DiscountsList, CreateOrEditDiscount, DiscountDetailsList } from "..";
import { ProtectedRoute } from "../../shared";

export const Discount = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<DiscountsList />} />

        <Route element={<ProtectedRoute allowedRoute={["discount-create"]} />}>
          <Route path="/create" element={<CreateOrEditDiscount />} />
        </Route>

        <Route element={<ProtectedRoute allowedRoute={["discount-modify"]} />}>
          <Route path="/edit/:id" element={<CreateOrEditDiscount />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["discount-view"]} />}>
          <Route path="/details/:id" element={<DiscountDetailsList />} />
        </Route>
      </Routes>
    </>
  );
};
