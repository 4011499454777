import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { errorMessage } from "../../../utils";
import { useLocation } from "react-router-dom";
import { DownloadReportList } from "../../Reports";
import { AuthContext } from "../../../store";

export const useStaffSales = () => {
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const { auth } = useContext(AuthContext);
  const { selectedStoreId } = auth.data;

  const [state, setState] = useImmer({
    isLoading: true,
    dataList: [],
  });

  useEffect(() => {
    HandleStaffSales();
  }, []);

  const HandleStaffSales = async () => {
    try {
      const params = {
        type: 12,
        store_id: [selectedStoreId],
      };

      const res = await DownloadReportList(params);
      if (res.data.success) {
        setState((draft) => {
          draft.dataList = res.data.data;
          draft.isLoading = false;
        });
      } else {
        setState((draft) => {
          draft.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  return {
    state,
    isStoreAdmin,
  };
};
