import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  TerminalsList,
  CreateOrEditTerminal,
  TerminalDetails,
  EndofDay,
} from "..";
import { ProtectedRoute } from "../../shared";

export const Terminals = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<TerminalsList />} />
        <Route path="/details/:id" element={<TerminalDetails />} />
        <Route element={<ProtectedRoute allowedRoute={["terminal-create"]} />}>
          <Route path="/create" element={<CreateOrEditTerminal />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["terminal-modify"]} />}>
          <Route path="/edit/:id" element={<CreateOrEditTerminal />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["terminal-view"]} />}>
          <Route path="/details/:id/endofday" element={<EndofDay />} />
        </Route>
      </Routes>
    </>
  );
};
