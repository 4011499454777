import {
  CustomTable,
  Loader,
  usePermission,
  DeleteModal,
  CustomeDialog,
} from "../../../shared";
import React from "react";
import { Button, Radio } from "@mui/material";
import { Link } from "react-router-dom";
import { sortItems } from "../../../utils";
import AddIcon from "@mui/icons-material/Add";
import { AddOrEditAccountDialog } from "./AddOrEditAccountDialog";

export const StoreAccountsList = (props) => {
  const {
    data,
    handleAccountsModal,
    handleDeleteAccontsFromProduct,
    handleInputChange,
    onSubmitBankAccount,
    handleDefaultAccount,
  } = props;
  const { isLoading, dataList, isDeleteOpen, isOpen, updateData, isBusy } =
    data;

  const ActionButtons = ({ row, deleteAction, editAction }) => {
    return (
      <div className="action-buttons">
        <img alt="delete" src="/icons/ic_delete.svg" onClick={deleteAction} />
        <img alt="edit" src="/icons/ic_edit.svg" onClick={editAction} />
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Default",
        id: "defualt",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <Radio
              checked={row.is_default ? true : false}
              value={row.id}
              onClick={() => {
                if (!row.is_default) {
                  handleDefaultAccount(row.id);
                }
              }}
              name="radio-buttons"
              sx={{
                p: "3.5px",
              }}
            />
          );
        },
      },
      {
        Header: "Account Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Account Number",
        accessor: "number",
      },
      {
        Header: "BSB",
        accessor: "bsb",
      },
      {
        Header: "Alias",
        accessor: "alias",
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              row={row}
              deleteAction={() => {
                handleAccountsModal("delete-accounts", true, row.id);
              }}
              editAction={() =>
                handleAccountsModal("accounts-add", true, 2, row.id)
              }
            />
          );
        },
      },
    ],
    [dataList]
  );
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <CustomTable
          columns={columns}
          data={dataList}
          tableTitle="Bank Accounts"
          defaultSearch={false}
          searchBar={false}
          pagination={false}
          headerRightContent={
            <>
              <Button
                type="button"
                size="medium"
                variant="text"
                startIcon={<AddIcon />}
                onClick={() =>
                  handleAccountsModal("accounts-add", true, 1, null)
                }
              >
                Add Account
              </Button>
            </>
          }
        />
      )}
      <CustomeDialog
        open={isOpen}
        handleClose={(e, r) =>
          handleAccountsModal("accounts-add", false, null, r)
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "612px", height: "410px", p: "16px" },
        }}
        title="Account Details"
        content={
          <AddOrEditAccountDialog
            data={updateData}
            isBusy={isBusy}
            handleInputChange={handleInputChange}
            onSubmitBankAccount={onSubmitBankAccount}
          />
        }
      />
      <DeleteModal
        open={isDeleteOpen}
        handleClose={() => handleAccountsModal("delete-accounts", false, null)}
        onConfirm={() => {
          handleDeleteAccontsFromProduct();
        }}
      />
    </>
  );
};
