import { API } from "../../../utils";

export const getDepositSlip = async (query) => {
  const res = await API.get("deposit-slips", query);
  return res.data;
};

export const getDepositSlipById = async (id) => {
  const res = await API.get(`deposit-slips/${id}`);
  return res.data;
};

export const getCashDetails = async (payload) => {
  const res = await API.post(`deposit-slips/cash_total`, payload);
  return res.data;
}

export const createDepositSlip = async (payload) => {
  const res = await API.post(`deposit-slips`, payload);
  return res.data;
};

export const updateDepositSlip = async (id, payload) => {
  const res = await API.put(`deposit-slips/${id}`, payload);
  return res.data;
};