import { API } from "../../../utils";

export const getSettingsDetails = async (id) => {
  const res = await API.get(`stores/${id}/settings-list`);
  return res.data;
};

export const updateSettings = async (id, payload) => {
  const res = await API.put(`stores/${id}/update`, payload);
  return res.data;
};
