import {
  ContentLayout,
  CustomTable,
  CustomeDialog,
  DeleteModal,
  HeaderBreadcrumbs,
  Loader,
  PageNotFound,
  useAbly,
} from "../../../shared";
import * as React from "react";
import {
  Button,
  Stack,
  Card,
  Typography,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  Switch,
  Radio,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTerminals } from "../hooks";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";

export const TerminalDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { presenceDataTerminal } = useAbly();
  const isOnline = presenceDataTerminal.find(
    (item) => item.clientId.replace("banjos_terminal_", "") === id
  );
  const {
    state,
    toggleModal,
    handleDeleteTerminal,
    isEditable,
    isDeletable,
    unPairTerminal,
    onDefaultChange,
    handleSaleStatusChange,
    isEODCreateVisible,
    toggleUnpair,
    onConfirmUnpair,
  } = useTerminals({
    load: false,
    id: id,
  });
  const {
    name,
    status,
    order_prefix,
    registration_code,
    uuid,
    store_name,
    online_status,
    cashless_till,
    connection_status,
    sale_type,
    meta_data,
  } = state.data;

  const { isDetailsFetching, httpStatusCodes } = state;

  const columns = React.useMemo(
    () => [
      {
        Header: "Default",
        accessor: "selected",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Radio
              size="small"
              checked={row.original.selected}
              onChange={(e) => {
                onDefaultChange(row.original.code);
              }}
              value={true}
              name="radio-buttons"
              sx={{
                p: "3.5px",
              }}
            />
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "Action",
        accessor: "status",
        align: "right",
        Cell: ({ row }) => {
          return (
            <Switch
              name="status"
              size="small"
              checked={row.original.status}
              onChange={() =>
                handleSaleStatusChange(row.original.code, row.original.status)
              }
            />
          );
        },
      },
    ],
    [state.data, state.terminalDetails]
  );

  if (isDetailsFetching) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </Box>
    );
  }

  if (httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }
  return (
    <>
      <ContentLayout
        title={name}
        active={status === 1 ? "Active" : "Inactive"}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Terminals", href: "/terminal" },
              { name: name || "" },
            ]}
          />
        }
        rightContent={
          <>
            {isDeletable && (
              <Button
                type="button"
                size="medium"
                variant="text"
                className="delte"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => toggleModal(id, "details")}
              >
                <img alt="delete" src="/icons/ic_delete_blue.svg" />
              </Button>
            )}
            {isEODCreateVisible && status === 1 && connection_status === 1 && (
              <Button
                type="button"
                size="medium"
                variant="text"
                onClick={() => navigate(`/terminal/details/${id}/endofday`)}
              >
                End of Day
              </Button>
            )}
            {isEditable && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                onClick={() => navigate(`/terminal/edit/${id}`)}
              >
                Edit
              </Button>
            )}
          </>
        }
      >
        <Card>
          <CardHeader title="Terminal Information" />
          <Divider />
          <List disablePadding>
            <ListItem divider={true} disablePadding>
              <ListItemText
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  my: 0,
                  pl: 2,
                }}
                disableTypography={true}
              >
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    minWidth="160px"
                  >
                    Token Prefix
                  </Typography>
                </Stack>
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography variant="body1" fontWeight={400} color="#636363">
                    {order_prefix}
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItem>
            <ListItem divider={true} disablePadding>
              <ListItemText
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  my: 0,
                  pl: 2,
                }}
                disableTypography={true}
              >
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    minWidth="160px"
                  >
                    Registration Code
                  </Typography>
                </Stack>
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography variant="body1" fontWeight={400} color="#636363">
                    {registration_code}
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItem>
            <ListItem divider={true} disablePadding>
              <ListItemText
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  my: 0,
                  pl: 2,
                }}
                disableTypography={true}
              >
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    minWidth="160px"
                  >
                    UID
                  </Typography>
                </Stack>
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography variant="body1" fontWeight={400} color="#636363">
                    {uuid}
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItem>
            <ListItem divider={true} disablePadding>
              <ListItemText
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  my: 0,
                  pl: 2,
                }}
                disableTypography={true}
              >
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    minWidth="160px"
                  >
                    Store
                  </Typography>
                </Stack>
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography variant="body1" fontWeight={400} color="#636363">
                    {store_name}
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItem>
            <ListItem divider={true} disablePadding>
              <ListItemText
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  my: 0,
                  pl: 2,
                }}
                disableTypography={true}
              >
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    minWidth="160px"
                  >
                    App Version
                  </Typography>
                </Stack>
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography variant="body1" fontWeight={400} color="#636363">
                    {meta_data?.app_version ?? "N/A"}
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItem>
            <ListItem divider={true} disablePadding>
              <ListItemText
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  my: 0,
                  pl: 2,
                }}
                disableTypography={true}
              >
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    minWidth="160px"
                  >
                    Cashless Till?
                  </Typography>
                </Stack>
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography variant="body1" fontWeight={400} color="#636363">
                    {cashless_till ? "Yes" : "No"}
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItem>
            <ListItem divider={true} disablePadding>
              <ListItemText
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  my: 0,
                  pl: 2,
                }}
                disableTypography={true}
              >
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    minWidth="160px"
                  >
                    Pairing Status
                  </Typography>
                </Stack>
                <Stack height="48px" justifyContent="center" p="8px">
                  <Stack direction="row" alignItems="center" gap="4px">
                    {connection_status === 1 ? (
                      <>
                        <img alt="paired" src="/icons/paired-icon.svg" />
                        <Typography variant="subtitle2" color="#0F875B">
                          Paired
                        </Typography>
                        <LoadingButton
                          sx={{ ml: "20px", height: "32px" }}
                          size="small"
                          onClick={() => toggleUnpair(id)}
                          loading={state.isUnpairing}
                        >
                          Unpair
                        </LoadingButton>
                      </>
                    ) : (
                      <>
                        <img alt="paired" src="/icons/unpaired-icon.svg" />
                        <Typography variant="subtitle2" color="#A3A3A3">
                          Unpaired
                        </Typography>
                      </>
                    )}
                  </Stack>
                </Stack>
              </ListItemText>
            </ListItem>
            <ListItem divider={true} disablePadding>
              <ListItemText
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  my: 0,
                  pl: 2,
                }}
                disableTypography={true}
              >
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    minWidth="160px"
                  >
                    Connection Status
                  </Typography>
                </Stack>
                <Stack height="48px" justifyContent="center" p="10px">
                  {isOnline ? (
                    <img alt="online" src="/icons/ic_online.svg" />
                  ) : (
                    <img alt="online" src="/icons/ic_offline.svg" />
                  )}
                </Stack>
              </ListItemText>
            </ListItem>
          </List>
        </Card>

        <Card sx={{ my: "24px" }}>
          <CardHeader title="Sales Type" />
          <Divider />
          <CustomTable
            columns={columns}
            data={sale_type ?? []}
            defaultSearch={false}
            completeFeature={false}
            pagination={false}
            tableTitle={null}
            searchBar={null}
          />
        </Card>
      </ContentLayout>

      <DeleteModal
        open={state.isOpenDetails}
        handleClose={() => toggleModal(null, "details")}
        onConfirm={() => {
          handleDeleteTerminal("details");
        }}
      />
      <CustomeDialog
        open={state.isUnpairOpen}
        handleClose={toggleUnpair}
        PaperProps={{
          sx: { p: "24px 24px 16px 24px", minWidth: "432px" },
        }}
        title="Unpair"
        content={
          <Stack sx={{ gap: "16px", paddingTop: "16px" }}>
            <Stack sx={{ padding: "16px 0 10px 0" }}>
              <Typography>Are you sure you want to unpair?</Typography>
            </Stack>

            <Stack direction="row" gap="12px" justifyContent="flex-end">
              <Button onClick={toggleUnpair}>No</Button>
              <Button
                onClick={onConfirmUnpair}
                variant="contained"
                disabled={state.isUnpairing}
              >
                Yes
              </Button>
            </Stack>
          </Stack>
        }
      />
    </>
  );
};
