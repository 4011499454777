import {
  ContentLayout,
  CustomTable,
  usePermission,
  DeleteModal,
} from "../../../shared";
import React from "react";
import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { sortItems } from "../../../utils";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useBundles } from "../hooks";

export const BundlesList = () => {
  const {
    state,
    globalData,
    isDeletable,
    isCreateVisible,
    isEditable,
    onChangeFilter,
    resetFilters,
    handleModal,
    handleDeleteBundle,
  } = useBundles({ isList: true });
  const navigate = useNavigate();
  const { BundleGroupsList, isLoading, isOpen } = state;
  const ActionButtons = ({ id, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isDeletable && (
          <img alt="delete" src="/icons/ic_delete.svg" onClick={deleteAction} />
        )}
        {isEditable && (
          <Link to={`details/${id}`}>
            <img alt="details" src="/icons/arrow_forward.svg" />
          </Link>
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => {
                handleModal("bundle-delete", true, row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <ContentLayout
        title="Bundle Groups"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("create")}
              >
                New Group
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={BundleGroupsList}
          isLoading={isLoading}
          tableId="bundle_list"
          onRowLink={(e) => {
            if (isEditable) {
              return `details/${e.id}`;
            }
          }}
        />
      </ContentLayout>
      <DeleteModal
        open={isOpen}
        handleClose={() => handleModal("bundle-delete", false, null)}
        onConfirm={() => {
          handleDeleteBundle("list");
        }}
      />
    </>
  );
};
