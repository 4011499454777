import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { usePermission, useTabs } from "../../../shared";
import { AppContext } from "../../../store/AppScopeProvider";
import { errorMessage, successMessage } from "../../../utils";
import {
  createLoyaltyCustomer,
  getLoyaltyCardDetails,
  getLoyaltyCardList,
  getLoyaltyCustomersList,
  getLoyaltyPointHistoryDetails,
  getLoyaltyPointHistoryList,
  updateLoyaltyCard,
} from "../api";
import qs from "qs";
import { useDebounce } from "use-debounce";
import { useNavigate } from "react-router-dom";
import { getCustomersList } from "../../Customers";
import {
  changeLoyaltyCustomerStatus,
  deleteLoyaltyCustomer,
  getLoyaltyCustomerDetails,
  updateLoyaltyCustomer,
  updatePointAdjustment,
} from "../api/LoyaltyApi";

export const useLoyalty = ({
  isList = false,
  type = null,
  IsType = false,
  isPointDetails = false,
  isCusAdd = false,
  isCusEdit = false,
  isCusDetails = false,
  isCusID = false,
}) => {
  const { appState } = useContext(AppContext);
  const { globalData } = appState;
  const { setCurentTab } = useTabs();
  const { loyaltyList: currentTab, loyaltyCardTypeDetails } =
    appState.currentTabs;
  const navigate = useNavigate();
  const hasLoyaltyGroupview = usePermission("loyalty-card-groups-view");

  const [state, setState] = useImmer({
    isLoading: true,
    pointAdjustmentLoading: false,
    loyaltyCards: {
      isLoading: true,
      isSave: false,
      isSaveButton: false,
      dataList: [],
      searchText: null,
      details: {
        included_products: [],
        excluded_products: [],
        included_products_count: {},
        excluded_products_count: {},
      },
      filterProducts: {
        included_products: [],
        excluded_products: [],
      },
      updateData: {
        purchase_percentage: null,
        redeem_percentage: null,
        product_id: [],
      },
    },
    customers: {
      isLoading: true,
      isOpen: false,
      isDeleteOpen: false,
      isSave: false,
      isFocus: false,
      dataList: [],
      pagination: {
        pageIndex: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
      optionsList: [],
      isBusy: true,
      searchText: "",
      isSaveButtonBusy: false,
      customerSelect: null,
      filterApplied: {
        searchText: null,
        status: "",
      },
      updateData: {
        customer_id: null,
        barcode: "",
        loyalty_card_type_id: "",
        verification_code: "",
        member_number: "",
        status: 0,
        customer_name: "",
      },
      pointsUpdateData: {
        points: null,
        loyalty_card_type_id: null,
        remarks: "",
      },
      details: {
        customer_name: "",
      },
    },
    pointHistory: {
      isLoading: true,
      dataList: [],
      details: {},
      filterApplied: {
        searchText: null,
      },
      pagination: {
        pageIndex: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
    },
  });

  const [debouncedText] = useDebounce(
    state.customers.filterApplied.searchText,
    1000
  );
  const [debouncedText2] = useDebounce(state.customers.searchText, 1000);

  const [debouncedTextPointHistory] = useDebounce(
    state.pointHistory.filterApplied.searchText,
    1000
  );

  useEffect(() => {
    if (isList) {
      if (type === "") {
        HandleLoyaltyCardList();
      } else if (type === "?cards") {
        HandleLoyaltyCustomersList({ pagination: state.customers.pagination });
      } else if (type === "?point-history") {
        HandleLoyaltyPointHistoryList({
          pagination: state.customers.pagination,
        });
      }
    }
  }, [isList, type]);

  useEffect(() => {
    if (isCusDetails && state.customers.details?.loyalty_card_type) {
      HandleLoyaltyPointHistoryList({
        loyalty_card_type: globalData.loyalty_cards?.loyalty_card_types?.find(
          (e) => e.label === state.customers.details?.loyalty_card_type
        ).value,
      });
    }
  }, [isCusDetails, state.customers.details]);

  useEffect(() => {
    if (isCusID) {
      HandleLoyaltyCustomersList({ cusId: isCusID });
    }
  }, [isCusID]);
  useEffect(() => {
    if (state.customers.isFocus && isCusID) {
      HandleLoyaltyCustomersList({ cusId: isCusID });
    }
  }, [state.customers.isFocus]);

  useEffect(() => {
    if (state.customers.isFocus && type === "?cards") {
      const { searchText, status } = state.customers.filterApplied;
      const { pagination, sortBy } = state.customers;
      HandleLoyaltyCustomersList({
        search: searchText,
        status,
        pagination,
        sortBy,
      });
    }
  }, [state.customers.isFocus]);

  useEffect(() => {
    if (isCusAdd) {
      HandleLoyaltyCustomersList({});
    }
  }, [isCusEdit, isCusAdd]);

  useEffect(() => {
    if (isPointDetails) {
      HandleLoyaltyPointHistoryDetails(isPointDetails);
    }
  }, [isPointDetails]);

  const HandleLoyaltyCardList = async () => {
    try {
      const res = await getLoyaltyCardList();
      if (res.success) {
        setState((draft) => {
          draft.loyaltyCards.dataList = res.data;
          draft.loyaltyCards.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.loyaltyCards.isLoading = false;
      });
    }
  };

  useEffect(() => {
    if (IsType) {
      HandleLoyaltyCardDetails(IsType);
    }
  }, [IsType]);

  const HandleLoyaltyCardDetails = async (id) => {
    if (state.loyaltyCards.isLoading) {
      try {
        const res = await getLoyaltyCardDetails(id);
        if (res.success) {
          setState((draft) => {
            draft.loyaltyCards.details = res.data;
            draft.loyaltyCards.updateData.purchase_percentage =
              res.data.purchase_percentage;
            draft.loyaltyCards.updateData.redeem_percentage =
              res.data.redeem_percentage;
            draft.loyaltyCards.details.excluded_products_count =
              res.data.excluded_products.reduce(
                (count, current) => count + current.products.length,
                0
              );
            draft.loyaltyCards.details.included_products_count =
              res.data.included_products.reduce(
                (count, current) => count + current.products.length,
                0
              );
            draft.loyaltyCards.filterProducts.included_products =
              res.data.included_products;
            draft.loyaltyCards.filterProducts.excluded_products =
              res.data.excluded_products;
            draft.loyaltyCards.isLoading = false;
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
        setState((draft) => {
          draft.loyaltyCards.isLoading = false;
        });
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setState((draft) => {
      draft.loyaltyCards.updateData = {
        ...draft.loyaltyCards.updateData,
        [name]: value,
      };
    });
  };

  const onUpdateLoyaltyCards = async (id) => {
    const payload = {
      ...state.loyaltyCards.updateData,
      product_id: state.loyaltyCards.filterProducts.included_products.flatMap(
        (data) => {
          return data.products.map((data) => {
            return data.id;
          });
        }
      ),
    };
    try {
      setState((draft) => {
        draft.loyaltyCards.isSaveButton = true;
      });
      const res = await updateLoyaltyCard(id, payload);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.loyaltyCards.isSaveButton = false;
        });
        navigate(-1);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.loyaltyCards.isSaveButton = false;
      });
    }
  };

  const handleMoveIncludeOrExclude = (type, value, index) => {
    if (type === "include") {
      setState((draft) => {
        draft.loyaltyCards.details.included_products =
          state.loyaltyCards.details.included_products.map((data, i) => {
            if (i === index) {
              return {
                ...data,
                products: [...data.products, value].sort((a, b) =>
                  a.name.localeCompare(b.name)
                ),
              };
            } else {
              return data;
            }
          });
        draft.loyaltyCards.details.excluded_products =
          state.loyaltyCards.details.excluded_products.map((data, i) => {
            if (i === index) {
              return {
                ...data,
                products: data.products.filter((val) => val !== value),
              };
            } else {
              return data;
            }
          });
        draft.loyaltyCards.filterProducts.included_products =
          state.loyaltyCards.filterProducts.included_products.map((data, i) => {
            if (i === index) {
              return {
                ...data,
                products: [...data.products, value].sort((a, b) =>
                  a.name.localeCompare(b.name)
                ),
              };
            } else {
              return data;
            }
          });
        draft.loyaltyCards.filterProducts.excluded_products =
          state.loyaltyCards.filterProducts.excluded_products.map((data, i) => {
            if (i === index) {
              return {
                ...data,
                products: data.products.filter((val) => val !== value),
              };
            } else {
              return data;
            }
          });
      });
    } else {
      setState((draft) => {
        draft.loyaltyCards.details.excluded_products =
          state.loyaltyCards.details.excluded_products.map((data, i) => {
            if (i === index) {
              return {
                ...data,
                products: [...data.products, value].sort((a, b) =>
                  a.name.localeCompare(b.name)
                ),
              };
            } else {
              return data;
            }
          });
        draft.loyaltyCards.details.included_products =
          state.loyaltyCards.details.included_products.map((data, i) => {
            if (i === index) {
              return {
                ...data,
                products: data.products.filter((val) => val !== value),
              };
            } else {
              return data;
            }
          });
        draft.loyaltyCards.filterProducts.excluded_products =
          state.loyaltyCards.filterProducts.excluded_products.map((data, i) => {
            if (i === index) {
              return {
                ...data,
                products: [...data.products, value].sort((a, b) =>
                  a.name.localeCompare(b.name)
                ),
              };
            } else {
              return data;
            }
          });
        draft.loyaltyCards.filterProducts.included_products =
          state.loyaltyCards.filterProducts.included_products.map((data, i) => {
            if (i === index) {
              return {
                ...data,
                products: data.products.filter((val) => val !== value),
              };
            } else {
              return data;
            }
          });
      });
    }
  };

  useEffect(() => {
    setState((draft) => {
      draft.loyaltyCards.details.excluded_products_count =
        draft.loyaltyCards.details.excluded_products.reduce(
          (count, current) => count + current.products.length,
          0
        );
      draft.loyaltyCards.details.included_products_count =
        draft.loyaltyCards.details.included_products.reduce(
          (count, current) => count + current.products.length,
          0
        );
    });
  }, [
    state.loyaltyCards.details.excluded_products,
    state.loyaltyCards.details.included_products,
  ]);

  const handleProductsSearch = (text) => {
    text = text.toLowerCase();
    setState((draft) => {
      draft.loyaltyCards.searchText = text;
      draft.loyaltyCards.details.included_products =
        state.loyaltyCards.filterProducts.included_products.map((cat) => {
          return {
            ...cat,
            products: cat.products.filter((val) => {
              return val.name.toString().toLowerCase().indexOf(text) > -1;
            }),
          };
        });
      draft.loyaltyCards.details.excluded_products =
        state.loyaltyCards.filterProducts.excluded_products.map((cat) => {
          return {
            ...cat,
            products: cat.products.filter((val) => {
              return val.name.toString().toLowerCase().indexOf(text) > -1;
            }),
          };
        });
    });
  };

  const onProductsSeachClear = () => {
    setState((draft) => {
      draft.loyaltyCards.searchText = "";
      draft.loyaltyCards.details.included_products =
        state.loyaltyCards.filterProducts.included_products;
      draft.loyaltyCards.details.excluded_products =
        state.loyaltyCards.filterProducts.excluded_products;
    });
  };

  //customers
  const HandleLoyaltyCustomersList = async ({
    search = null,
    status = "",
    cusId = null,
    pagination = {
      pageIndex: 1,
      pageSize: state.customers.pagination.pageSize,
    },
    sortBy = [],
  }) => {
    try {
      setState((draft) => {
        draft.customers.isLoading = true;
      });
      let params = {};
      if (search) {
        params = { ...params, search };
      }
      if (status !== "") {
        params = { ...params, status };
      }
      if (cusId) {
        params = { ...params, customer_id: cusId };
      }

      if (sortBy.length > 0) {
        const sortByParams = sortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };
      const res = await getLoyaltyCustomersList(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.customers.dataList = res.data.loyalty_cards;
          draft.customers.pagination = res.data.pagination;
          draft.customers.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.customers.isLoading = false;
      });
    }
  };

  const gotoPageCustomerList = (page) => {
    const { searchText, status } = state.customers.filterApplied;
    const { pagination, sortBy } = state.customers;

    HandleLoyaltyCustomersList({
      search: searchText,
      status,
      pagination: {
        pageIndex: page,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.customers.pagination.pageIndex = page;
    });
  };

  const setPageSizeCustomerList = (e) => {
    const { searchText, status } = state.customers.filterApplied;
    const { pagination, sortBy } = state.customers;
    HandleLoyaltyCustomersList({
      search: searchText,
      status,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    setState((draft) => {
      draft.customers.pagination.pageSize = +e.target.value;
    });
  };

  const handleSortCustomerList = (sortBy) => {
    const { searchText, status } = state.customers.filterApplied;
    const { pagination } = state.customers;
    HandleLoyaltyCustomersList({
      search: searchText,
      status,
      pagination,
      sortBy,
    });
    setState((draft) => {
      draft.customers.sortBy = sortBy;
    });
  };

  useEffect(() => {
    if (state.customers.filterApplied.searchText !== null) {
      const { status } = state.customers.filterApplied;
      const { sortBy } = state.customers;
      HandleLoyaltyCustomersList({
        search: debouncedText,
        status,
        sortBy,
      });
    }
  }, [debouncedText]);

  const onChangeFilter = (e) => {
    const { value } = e.target;
    const { searchText } = state.customers.filterApplied;
    const { sortBy } = state.customers;
    HandleLoyaltyCustomersList({
      search: searchText,
      status: value,
      sortBy,
    });
    setState((draft) => {
      draft.customers.filterApplied.status = value;
      draft.customers.isLoading = true;
    });
  };

  const handleSearch = (v) => {
    setState((draft) => {
      draft.customers.filterApplied.searchText = v;
    });
  };

  const onSeachClear = () => {
    const { status } = state.customers.filterApplied;
    const { sortBy } = state.customers;
    HandleLoyaltyCustomersList({
      search: null,
      status,
      sortBy,
    });
    setState((draft) => {
      draft.customers.filterApplied.searchText = null;
      draft.customers.isLoading = true;
    });
  };

  const resetFilters = () => {
    if (
      state.customers.filterApplied.status !== "" ||
      state.customers.filterApplied.searchText
    ) {
      HandleLoyaltyCustomersList({});
      setState((draft) => {
        draft.customers.filterApplied.searchText = null;
        draft.customers.filterApplied.status = "";
        draft.customers.isLoading = true;
      });
    }
  };

  const handleGetCustomerList = async ({ search = null, page }) => {
    let params = {};
    let pagination = {
      pageIndex: page,
      pageSize: 10,
    };
    if (search) {
      params = { ...params, search };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      const res = await getCustomersList(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.customers.optionsList = res.data.customers.map((data) => {
            return { value: data.id, label: data.name };
          });
          draft.customers.isBusy = false;
        });
        return {
          options: res.data.customers.map((data) => {
            return {
              value: data.id,
              label: data.name,
              email: data.email,
              phone: data.phone_formatted,
            };
          }),
          hasMore: res.data.pagination.hasMorePages,
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  useEffect(() => {
    if (isCusAdd && state.customers.searchText && state.customers.isBusy) {
      handleGetCustomerList({ search: state.customers.searchText });
    }
  }, [debouncedText2]);

  const handleCustomerInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "customer_id") {
      setState((draft) => {
        draft.customers.customerSelect = value;
        draft.customers.updateData = {
          ...draft.customers.updateData,
          [name]: value.value,
          customer_name: value.label,
        };
      });
    } else if (name === "status") {
      setState((draft) => {
        draft.customers.updateData = {
          ...draft.customers.updateData,
          [name]: event.target.checked ? 1 : 0,
        };
      });
    } else {
      setState((draft) => {
        draft.customers.updateData = {
          ...draft.customers.updateData,
          [name]: value,
        };
      });
    }
  };
  const onSaveLoyaltyCustomer = async () => {
    try {
      setState((draft) => {
        draft.customers.isSaveButtonBusy = true;
      });
      const res = await createLoyaltyCustomer(state.customers.updateData);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.customers.isSaveButtonBusy = false;
        });
        navigate("/loyalty-cards");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.customers.isSaveButtonBusy = false;
      });
    }
  };

  const onUpdateCustomer = async (id) => {
    try {
      setState((draft) => {
        draft.customers.isSaveButtonBusy = true;
      });
      const res = await updateLoyaltyCustomer(id, state.customers.updateData);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.customers.isSaveButtonBusy = false;
        });
        navigate(`/loyalty-cards/details/${id}`);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.customers.isSaveButtonBusy = false;
      });
    }
  };

  useEffect(() => {
    if (isCusDetails || isCusEdit) {
      HandleLoyaltyCustomerDetails(isCusDetails || isCusEdit);
    }
  }, [isCusDetails]);

  const HandleLoyaltyCustomerDetails = async (id) => {
    if (state.customers.isLoading) {
      try {
        const res = await getLoyaltyCustomerDetails(id);
        if (res.success) {
          let updateData = {
            barcode: res.data.barcode,
            member_number: res.data.member_number,
            verification_code: res.data.verification_code,
            loyalty_card_type_id:
              res.data.loyalty_card_type === "Customer" ? 1 : 2,
            status: res.data.status,
          };
          setState((draft) => {
            draft.customers.details = res.data;
            draft.customers.pointsUpdateData.loyalty_card_type_id =
              res.data.loyalty_card_type === "Customer" ? 1 : 2;
            draft.customers.isLoading = false;
            draft.customers.updateData = updateData;
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
        setState((draft) => {
          draft.customers.isLoading = false;
        });
      }
    }
  };

  const handleChangeCustomerStatus = async (id) => {
    try {
      setState((draft) => {
        draft.customers.details = {
          ...draft.customers.details,
          status: draft.customers.details.status ? 0 : 1,
        };
      });
      const res = await changeLoyaltyCustomerStatus(id);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.customers.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const togglePointAdjustmentModal = async (e) => {
    setState((draft) => {
      draft.customers.isOpen = e;
      draft.customers.pointsUpdateData.remarks = "";
      draft.customers.pointsUpdateData.points = null;
    });
  };

  const handlePointsInputChange = (event) => {
    const { name, value } = event.target;

    setState((draft) => {
      draft.customers.pointsUpdateData = {
        ...draft.customers.pointsUpdateData,
        [name]: value,
      };
    });
  };

  const onUpdatePointAdjustment = async () => {
    try {
      setState((draft) => {
        draft.pointAdjustmentLoading = true;
      });
      const res = await updatePointAdjustment(
        isCusDetails,
        state.customers.pointsUpdateData
      );
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.customers.details.points =
            Number(draft.customers.details.points) +
            Number(draft.customers.pointsUpdateData.points);
          draft.customers.pointsUpdateData.remarks = "";
          draft.customers.pointsUpdateData.points = null;
          draft.customers.isOpen = false;
          draft.pointAdjustmentLoading = false;
        });
        HandleLoyaltyPointHistoryList({});
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.pointAdjustmentLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.pointAdjustmentLoading = false;
      });
    }
  };

  const handleDeleteModal = async (e) => {
    setState((draft) => {
      draft.customers.isDeleteOpen = e;
    });
  };

  const handleDeleteUserDetails = async () => {
    try {
      const res = await deleteLoyaltyCustomer(isCusDetails);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.customers.isDeleteOpen = false;
        });
        navigate("/loyalty-cards");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  //Point History
  const HandleLoyaltyPointHistoryList = async ({
    customerID = state.customers.details.customer_id,
    loyalty_card_type = isCusDetails
      ? globalData.loyalty_cards?.loyalty_card_types?.find(
          (e) => e.label === state.customers.details?.loyalty_card_type
        ).value
      : null,
    pagination = {
      pageIndex: 1,
      pageSize: state.pointHistory.pagination.pageSize,
    },
    sortBy = [],
    searchText,
  }) => {
    try {
      setState((draft) => {
        draft.pointHistory.isLoading = true;
      });
      let params = {};

      // if (customerID) {
      //   params = { ...params, customer_id: customerID };
      // }
      if (searchText) {
        params = { ...params, search: searchText };
      }
      if (loyalty_card_type) {
        params = { ...params, loyalty_card_type: loyalty_card_type };
      }
      if (isCusDetails) {
        params = { ...params, loyalty_card_id: isCusDetails };
      }
      if (sortBy.length > 0) {
        const sortByParams = sortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }
      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };
      const res = await getLoyaltyPointHistoryList(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.pointHistory.dataList = res.data.loyalty_points_history;
          draft.pointHistory.pagination = res.data.pagination;
          draft.pointHistory.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.pointHistory.isLoading = false;
      });
    }
  };
  const handlePointHistorySearch = (value) => {
    setState((draft) => {
      draft.pointHistory.filterApplied.searchText = value;
    });
  };

  useEffect(() => {
    if (state.pointHistory.filterApplied.searchText !== null) {
      const { pagination, sortBy } = state.pointHistory;
      HandleLoyaltyPointHistoryList({
        searchText: debouncedTextPointHistory,
        pagination,
        sortBy,
      });
    }
  }, [debouncedTextPointHistory]);

  const gotoPagePointHistory = (page) => {
    const { pagination, filterApplied, sortBy } = state.pointHistory;
    HandleLoyaltyPointHistoryList({
      searchText: filterApplied.searchText,
      pagination: {
        pageIndex: page,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.pointHistory.pagination.pageIndex = page;
    });
  };

  const setPageSizePointHistory = (e) => {
    const { pagination, filterApplied, sortBy } = state.pointHistory;
    HandleLoyaltyPointHistoryList({
      searchText: filterApplied.searchText,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    setState((draft) => {
      draft.pointHistory.pagination.pageSize = +e.target.value;
    });
  };

  const handleSortPointHistory = (sortBy) => {
    const { searchText } = state.pointHistory.filterApplied;

    HandleLoyaltyPointHistoryList({
      pagination: {
        pageIndex: state.pointHistory.pagination.pageIndex,
        pageSize: state.pointHistory.pagination.pageSize,
      },
      sortBy,
      search: searchText,
    });
    setState((draft) => {
      draft.pointHistory.sortBy = sortBy;
    });
  };

  const HandleLoyaltyPointHistoryDetails = async (id) => {
    if (state.pointHistory.isLoading) {
      try {
        const res = await getLoyaltyPointHistoryDetails(id);
        if (res.success) {
          setState((draft) => {
            draft.pointHistory.details = res.data;
            draft.pointHistory.isLoading = false;
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
        setState((draft) => {
          draft.pointHistory.isLoading = false;
        });
      }
    }
  };

  // User has switched back to the tab
  const onFocusTab = () => {
    setState((draft) => {
      draft.customers.isFocus = true;
    });
  };

  const onBlurTab = () => {
    setState((draft) => {
      draft.customers.isFocus = false;
    });
  };

  useEffect(() => {
    window.addEventListener("focus", onFocusTab);
    return () => {
      window.removeEventListener("focus", onFocusTab);
    };
  }, []);

  return {
    state,
    globalData,
    setState,
    currentTab,
    loyaltyCardTypeDetails,
    setCurentTab,
    handleInputChange,
    onUpdateLoyaltyCards,
    handleMoveIncludeOrExclude,
    onChangeFilter,
    handleSearch,
    onSeachClear,
    resetFilters,
    handleProductsSearch,
    onProductsSeachClear,
    handleCustomerInputChange,
    onSaveLoyaltyCustomer,
    handleChangeCustomerStatus,
    togglePointAdjustmentModal,
    handlePointsInputChange,
    onUpdatePointAdjustment,
    handleDeleteModal,
    handleDeleteUserDetails,
    handleGetCustomerList,
    onUpdateCustomer,
    hasLoyaltyGroupview,
    gotoPageCustomerList,
    setPageSizeCustomerList,
    setPageSizePointHistory,
    gotoPagePointHistory,
    handlePointHistorySearch,
    handleSortPointHistory,
    handleSortCustomerList,
    onBlurTab,
  };
};
