import * as React from "react";
import {
  ContentLayout,
  CustomTable,
  CustomeDialog,
  CustomDetailsList,
  Loader,
  DeleteModal,
  usePermission,
  PageNotFound,
  CheckBoxListDialog,
  HeaderBreadcrumbs,
} from "../../../shared";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTax } from "..";
import { useNavigate, useParams } from "react-router-dom";
import { sortItems } from "../../../utils";


export const TaxGroupsDetails = () => {
  const isDeletable = usePermission("tax-delete");
  const isEditable = usePermission("tax-modify");
  const { id } = useParams();
  const navigate = useNavigate();
  const updateMode = id ? true : false;
  const {
    state,
    handleModal,
    HandleFilterTaxes,
    handleTaxesSelect,
    handleAssignTaxToTaxGroup,
    handleDeleteTaxFromTG,
    handleDeleteTaxGroup,
  } = useTax({
    isTaxGroupId: id,
    isTaxGroupEdit: updateMode,
  });
  const { details } = state.taxGroup;
  const {
    isLoading,
    filteredList,
    isOpen,
    taxesList,
    deleteModalOpen,
    selectedtaxes,
    deleteDetailsModalOpen,
    searchText,
    isSaveButton,
  } = state.taxGroupDetails;

  const ActionButtons = ({ id, deleteAction }) => {
    return (
      <div className="action-buttons">
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Tax Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Tax Rate",
        disableSortBy: true,
        accessor: (row, index) => {
          return <Box>{row.tax_rate}%</Box>;
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() =>
                handleModal("tax-delete-from-TG", true, row.id)
              }
            />
          );
        },
      },
    ],
    []
  );

  const detailsColumns = [
    {
      title: "Tax Group Name",
      accessor: "name",
    },
  ];

  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }

  return (
    <>
      <ContentLayout
        title={details?.name}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Taxes", href: "/tax-groups-and-taxes" },
              { name: details?.name || "" },
            ]}
          />
        }
        rightContent={
          <>
            {isDeletable && (
              <Button
                type="button"
                size="medium"
                variant="text"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => handleModal("TG-detail-delete", true, id)}
              >
                <img alt="delete" src="/icons/ic_delete_blue.svg" />
              </Button>
            )}
          </>
        }
      >
        {/* Basic Details */}

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <CustomDetailsList
              columns={detailsColumns}
              data={details}
              title="Details"
              headerRightContent={
                <>
                  {isEditable && (
                    <Button
                      size="medium"
                      variant="contained"
                      onClick={() =>
                        navigate(`/tax-groups-and-taxes/tax-groups/edit/${id}`)
                      }
                    >
                      Edit
                    </Button>
                  )}
                </>
              }
            />
            <CustomTable
              columns={columns}
              data={taxesList}
              searchBar={false}
              tableTitle="Taxes"
              headerRightContent={
                <>
                  {isEditable && (
                    <Button
                      type="button"
                      size="medium"
                      variant="text"
                      className="delte"
                      startIcon={<AddIcon />}
                      onClick={() => handleModal("tax-add", true)}
                      sx={{
                        minWidth: "40px",
                        p: "8px",
                        border: "1px solid rgba(21, 96, 212, 0.12)",
                      }}
                    >
                      Add Tax
                    </Button>
                  )}
                </>
              }
            />
            <CustomeDialog
              open={isOpen}
              handleClose={() => handleModal("tax-add", false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                sx: { width: "423px", height: "535px", p: "16px" },
              }}
              title="Taxes"
              content={
                <CheckBoxListDialog
                  Items={filteredList}
                  onSearch={HandleFilterTaxes}
                  loading={state.taxes.isLoading}
                  onSelect={handleTaxesSelect}
                  onSubmit={handleAssignTaxToTaxGroup}
                  disabled={!selectedtaxes.length || isSaveButton}
                  isSelected={(id) => selectedtaxes.includes(id)}
                  onClearSearch={() => {
                    HandleFilterTaxes("initial");
                  }}
                  value={searchText}
                />
              }
            />
          </>
        )}
      </ContentLayout>
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => handleModal("tax-delete-from-TG", false, null)}
        onConfirm={handleDeleteTaxFromTG}
      />
      <DeleteModal
        open={deleteDetailsModalOpen}
        handleClose={() => handleModal("TG-detail-delete", false, null)}
        onConfirm={handleDeleteTaxGroup}
      />
    </>
  );
};
