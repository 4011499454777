import React, { useContext, useRef, useState } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import MenuPopover from "./MenuPropover";
import moment from "moment";
import { AppContext } from "../../store";
import { Link } from "react-router-dom";

export default function NavbarNotification() {
  const { appState, setAppState, deleteNotification, deleteAllNotification } =
    useContext(AppContext);
  const {
    notificationData,
    notificationMeta: { isAllbusy, isbusy },
  } = appState;
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    setAppState((draft) => {
      draft.isIconButton = false;
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        cursor="pointer"
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          width: 40,
          height: 40,
          borderRadius: 1,
          padding: "10px",
          bgcolor: "#0000000F",
          "&:hover": {
            bgcolor: "#0000000F",
          },
        }}
      >
        {appState?.isIconButton && (
          <Box position="relative" top="-10px" left="7px" sx={{ zIndex: 1 }}>
            <img alt="details" src="/icons/ellips_ic.png" />
          </Box>
        )}
        <Box position="absolute" top="18px" left="17px">
          <img alt="details" src="/icons/ic_notification.png" />
        </Box>
      </Box>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          width: 377,
          minHeight: 370,
          py: "8px",
          boxShadow: "0px 2px 7px rgb(0 0 0 / 25%)",
        }}
      >
        <Stack
          sx={{
            position: "sticky",
            top: "0",
            bgcolor: "#fff",
            zIndex: 1101,
            p: "22px 22px 10px",
          }}
        >
          <Typography
            fontWeight={600}
            fontSize="16px"
            lineHeight="19.36px
"
          >
            Notification
          </Typography>
        </Stack>
        {notificationData.length ? (
          <Stack
            sx={{ height: "40px" }}
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
            padding={"16px"}
          >
            <Box
              sx={{
                color: "#1560D4",
                fontWeight: 600,
                fontSize: "14px",
                cursor: "pointer",
                padding: "8px 16px",
                "&:hover": {
                  color: "#073c91",
                },
              }}
              onClick={(e) => {
                !isAllbusy && deleteAllNotification();
              }}
            >
              Clear All
            </Box>
          </Stack>
        ) : null}
        <Box
          sx={{
            overflow: "hidden",
            overflowY: "auto",
            height: "311px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            marginTop: "4px",
          }}
        >
          {notificationData?.map((items, index) => (
            <Link
              key={index}
              to={items.data.url}
              style={{ textDecoration: "none" }}
            >
              <Stack
                sx={{
                  cursor: "pointer",
                  gap: "16px",
                  maxWidth: "377px",
                  padding: "6px 16px",
                  "&:hover": {
                    backgroundColor: "rgba(21, 96, 212, 0.08)",
                    borderRadius: "8px",
                  },
                }}
              >
                <Stack direction="row" gap="16px" maxWidth="377px">
                  <Stack>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        borderRadius: 100,
                        padding: "10px",
                        bgcolor: "#0000000F",
                        "&:hover": {
                          bgcolor: "#0000000F",
                        },
                      }}
                    >
                      <img alt="details" src="/icons/nav_download_icon.png" />
                    </Box>
                  </Stack>
                  <Stack gap={"4px"}>
                    <Stack
                      direction="row"
                      gap={"4px"}
                      justifyContent={"space-between"}
                    >
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="subtitle2"
                        color="text.primary"
                        fontWeight={600}
                        fontSize="14px"
                        lineHeight="16.94px"
                      >
                        {items?.data.title}
                      </Typography>
                      <Typography
                        component="span"
                        variant="caption"
                        color="text.secondary"
                        fontWeight={400}
                      >
                        {items?.data?.formatted_date}
                      </Typography>
                      <Box
                        sx={{
                          backgroundColor: "#00000008",
                          padding: "8px",
                          borderRadius: "8px",
                          height: "32px",
                          width: "32px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          ":hover": {
                            backgroundColor: " rgba(0, 0, 0, 0.07)",
                          },
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          isbusy !== items.id && deleteNotification(items.id);
                        }}
                      >
                        <Box component="img" src="/icons/clear.png"></Box>
                      </Box>
                    </Stack>
                    <Typography
                      color="#000000"
                      fontWeight={400}
                      fontSize="14px"
                      lineHeight="16.94px"
                    >
                      {items?.data?.desc}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Link>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
