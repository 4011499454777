import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import { AddOrEditCoupons, CouponDetailsList, CouponsList } from "./containers";
import { CouponDetailsView } from "./components";
import { useCouponList } from "./hooks";

export const Coupons = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<CouponsList />} />
        <Route element={<ProtectedRoute allowedRoute={["coupon-create"]} />}>
          <Route path="/create" element={<AddOrEditCoupons />} />
        </Route>

        <Route element={<ProtectedRoute allowedRoute={["coupon-modify"]} />}>
          <Route path="/edit/:id" element={<AddOrEditCoupons />} />
        </Route>

        <Route element={<ProtectedRoute allowedRoute={["coupon-view"]} />}>
          <Route path="/details/:id" element={<CouponDetailsList />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["coupon-view"]} />}>
          <Route path="/details/view/:id" element={<CouponDetailsView />} />
        </Route>
      </Routes>
    </>
  );
};
