import { ContentLayout, CustomeDialog } from "../../../shared";
import * as React from "react";
import { Button, Chip, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CustomTable } from "../../../shared";
import { useNavigate } from "react-router-dom";
import { DeleteModal } from "../../../shared";
import { sortItems } from "../../../utils";
import { AddOrEditMenuTemplate } from "../components";
import { useMenuTemplate } from "../hooks/useMenuTemplate";
import InputControl from "../../../shared/components/InputControl";

export const MenuTemplateList = () => {
  const {
    state,
    isDeletable,
    isEditable,
    isCreateVisible,
    onChangeFilter,
    onChangeMenuTemplate,
    onSaveMenuTemplate,
    handleModal,
    toggleModal,
    handleDeleteMenuTemplate,
    onUpdateMenuTemplate,
    onSeachClear,
  } = useMenuTemplate({
    isList: true,
  });
  const navigate = useNavigate();

  const ActionButtons = ({ id, editAction, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isEditable && (
          <img alt="edit" src="/icons/ic_edit.svg" onClick={editAction} />
        )}
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
        <img
          alt="details"
          src="/icons/arrow_forward.svg"
          onClick={() => navigate(`/menu-template/details/${id}`)}
        />
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Template Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Sales Channel",
        accessor: "sales_channels",
        disableGlobalFilter: true,
        sortInverted: true,
        Cell: ({ cell }) => {
          const channel = cell?.row?.original?.sales_channels;
          return (
            <Box>
              {channel?.map((data) => {
                return (
                  <Chip
                    sx={{ mr: 1, borderRadius: "12px" }}
                    label={data}
                    variant="filled"
                    size="small"
                  />
                );
              })}
            </Box>
          );
        },
      },

      {
        Header: "Action",
        id: "action",
        accessor: "id",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ row }) => {
          return (
            <ActionButtons
              id={row.original.id}
              deleteAction={() => {
                toggleModal(row.original.id);
              }}
              editAction={() => handleModal(row.original.id, "edit")}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <ContentLayout
        title="Menu Template"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => handleModal(null, "create")}
              >
                Add New
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={state?.menuTemplates}
          isLoading={state?.isBusy}
          defaultSearch={false}
          filterContent={
            <>
              <InputControl
                type="table-search"
                name="searchText"
                placeholder="Search"
                size="small"
                value={state.filters.searchText}
                onChange={(e) => onChangeFilter(e)}
                onClear={onSeachClear}
              />
            </>
          }
          onRowLink={(e) => {
            return `/menu-template/details/${e?.id}`;
          }}
        />
        <CustomeDialog
          open={state.isCreateOpen || state.isEditOpen}
          handleClose={() => handleModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx: { width: "618px", p: "16px", gap: "12px" },
          }}
          title={state.selectedId ? "Edit" : "Add New"}
          content={
            <AddOrEditMenuTemplate
              state={state}
              onChangeMenuTemplate={onChangeMenuTemplate}
              onSaveMenuTemplate={onSaveMenuTemplate}
              onUpdateMenuTemplate={onUpdateMenuTemplate}
            />
          }
        />
        <DeleteModal
          open={state.isOpen}
          handleClose={() => toggleModal()}
          onConfirm={() => handleDeleteMenuTemplate()}
        />
      </ContentLayout>
    </>
  );
};
