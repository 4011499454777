import { Stack, Typography, Box } from "@mui/material";
import React, { useCallback } from "react";

import { useDropzone } from "react-dropzone";
import { errorMessage } from "../../../utils";

export const CFDDropzoneComponent = (props) => {
  const {
    width,
    aspectRatio,
    handleDropzone,
    imgUrl,
    height,
    isExtent = false,
    handleDelete,
  } = props;

  const maxSizeValidator = (file) => {
    const maxSize = 1048576;
    if (file.size > maxSize) {
      errorMessage("The Image Size should be less than 1MB");
      return true;
    }
    return null;
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.length > 0 && handleDropzone(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxFiles: 1,
    validator: maxSizeValidator,
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          justifyItems: "center",
          cursor: "pointer",
          height: height,
          backgroundColor: "#F2F2F2 ",
          overflow: "hidden",
          aspectRatio: aspectRatio,
        }}
      >
        <section className="container" style={{ width: "100%" }}>
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{ position: "relative" }}
          >
            <input {...getInputProps()} />
            {imgUrl ? (
              <>
                <Box
                  component={"img"}
                  width="100%"
                  height={height}
                  aspect
                  sx={{ maxHeight: height, objectFit: "cover" }}
                  src={imgUrl && imgUrl}
                />
                <Box
                  sx={{
                    position: "absolute",
                    right: "12px",
                    top: "12px",
                    borderRadius: "50%",
                    bgcolor: "#E6E6E6",
                    height: "33px",
                    width: "33px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      height: "36px",
                      width: "36px",
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete();
                  }}
                >
                  <img
                    alt="delete"
                    src="/icons/ic_close.svg"
                    className="disabled"
                  />
                </Box>
              </>
            ) : (
              <Stack
                height={height}
                sx={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "4px",
                  p: "8px",
                  textAlign: "center",
                }}
              >
                <img width="72px" height="72px" src="/icons/icon_image.svg" />

                <Typography variant="subtitle2" color="#808080">
                  Drag and Drop or{" "}
                  <span style={{ color: "#1560D4", fontWeight: "500" }}>
                    Select
                  </span>{" "}
                  Image
                </Typography>
                <Typography variant="subtitle2" color="#808080">
                  with {aspectRatio.replace("/", ":")} and less than 1MB.
                </Typography>
              </Stack>
            )}
          </div>
        </section>
      </Box>
    </>
  );
};
