import { API } from "../../../utils";

export const getAllCardList = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `gift-cards?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};
export const getBatchwiseCard = async (payload, id, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `gift-card-groups?card_type=${id}&page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, payload);
  return res.data;
};
export const createGiftCard = async (payload) => {
  const res = await API.post(`gift-card-groups`, payload);
  return res.data;
};
export const getGiftCardDetails = async (id) => {
  const res = await API.get(`gift-card-groups/${id}`);
  return res.data;
};

export const deleteGiftCardById = async (id) => {
  const res = await API.delete(`gift-card-groups/${id}`);
  return res.data;
};

export const downloadAllCards = async (id) => {
  const res = await API.get(`gift-card-groups/${id}/export`, {
    responseType: "blob",
  });
  return res;
};
export const downloadSingleCards = async (id) => {
  const res = await API.get(`gift-cards/${id}/export`, {
    responseType: "blob",
  });
  return res;
};
export const shareGiftCard = async (id, email) => {
  const res = await API.post(`gift-cards/${id}/share`, email);
  return res.data;
};
export const updateGiftCard = async (id, payload) => {
  const res = await API.put(`gift-card-groups/${id}`, payload);
  return res.data;
};

export const getCardDetail = async (id) => {
  const res = await API.get(`gift-cards/${id}`);
  return res.data;
};
export const changeCardStatus = async (id) => {
  const res = await API.put(`gift-cards/${id}`);
  return res.data;
};
// export const getGiftCardGroups = async () => {
//   const res = await API.get(`gift_card_groups/list`);
//   return res.data;
// };
export const getGiftCardGroups = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `gift_card_groups/list?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data;
};
export const getCardTransactionDetails = async (id, query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `gift-cards/${id}/transactions?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};

export const getAllBathCardList = async (params, id, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `gift-cards?batch_id=${id}&page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, params);
  return res.data;
};
