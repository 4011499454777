import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { errorMessage } from "../../../utils";
import qs from "qs";
import { DownloadCouponReportList, getCouponReport } from "../api";
import { useDebouncedCallback } from "use-debounce";
import moment from "moment";
import { getCoupons } from "../../Coupons";
import { TableStateContext } from "../../../store";

export const useCouponReport = ({ isCouponReport }) => {
  var fileDownload = require("js-file-download");
  const [state, setState] = useImmer({
    isBusy: false,
    disableApplyButton: false,
    couponreports: [],
    coupons: [],
    filterApplied: {
      date: {},
      type: [],
      stores: [],
      coupon: [],
      all: [],
      searchText: null,
    },
    sortBy: [],
    pagination: {
      pageIndex: 1,
      pageSize: 25,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    isCalenderOpen: false,
    date: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    showDate: [],
  });
  const PAGE_NAME = "couponReport";
  const {
    filterState: {
      params: { couponReport },
    },
    filterStateDispatch,
  } = useContext(TableStateContext);

  const couponOptions = state?.coupons?.map((v) => {
    return { value: v.id, label: v.name };
  });

  useEffect(() => {
    if (isCouponReport) {
      const { searchText, date, stores, coupon } = couponReport.filterApplied;
      const { pagination, sortBy } = couponReport;
      setState((draft) => {
        draft.filterApplied = couponReport.filterApplied;
        draft.pagination = pagination;
        draft.sortBy = sortBy;
      });
      handleGetCouponReport({
        searchText,
        stores,
        coupon,
        date,
        pagination: {
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
        },
        sortBy,
      });
    }
  }, [isCouponReport]);
  const debounced = useDebouncedCallback((value) => {
    const { date, stores, coupon } = couponReport.filterApplied;
    const { pagination, sortBy } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filterApplied },
    });
    handleGetCouponReport({
      searchText: value,
      date,
      stores,
      coupon,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  }, 1000);

  const handleGetCouponReport = async ({
    searchText = "",
    date = {},
    stores = [],
    coupon = [],
    pagination,
    sortBy = [],
  }) => {
    try {
      let IDs = stores?.map((v) => {
        return v?.value;
      });
      let params = {};

      if (searchText) {
        params = { ...params, search: searchText };
      }
      if (Object.keys(date).length) {
        params = {
          ...params,
          start_date: moment(date.value.startDate).format("YYYY-MM-DD"),
          end_date: moment(date.value.endDate).format("YYYY-MM-DD"),
        };
      }
      if (IDs.length > 0) {
        params = { ...params, store_id: IDs };
      }
      if (sortBy.length > 0) {
        const sortByParams = sortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }

      let CouponIDs = coupon.map((v) => {
        return v.value;
      });
      if (CouponIDs.length > 0) {
        params = { ...params, coupon_id: CouponIDs };
      }
      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getCouponReport(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.couponreports = res?.data?.coupon_history;
          draft.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      console.log(err);

      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  const handleSearch = (v) => {
    setState((draft) => {
      draft.filterApplied.searchText = v;
    });
    debounced(v);
  };
  const handleDateChangeApply = () => {
    setState((draft) => {
      draft.showDate = state.date;
      draft.isCalenderOpen = false;
      draft.filterApplied.date = {
        value: state.date[0],
        label: `${moment(state.date[0].startDate).format(
          "yyy-MM-DD"
        )} to ${moment(state.date[0].endDate).format("yyy-MM-DD")}`,
        type: "Date",
      };
    });
  };
  const onChangeDate = (e) => {
    setState((draft) => {
      draft.date = e;
    });
  };
  const handleIsOpen = (e) => {
    setState((draft) => {
      draft.isCalenderOpen = e;
    });
  };
  const handleFilterClear = (type, value) => {
    const { pagination, sortBy, filterApplied } = couponReport;
    if (type === "single") {
      if (value.type === "Store") {
        const filteredStores = state.filterApplied.stores.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.stores = filteredStores;
        });
      }

      if (value.type === "Date") {
        setState((draft) => {
          draft.filterApplied.date = {};
          draft.showDate = [];
        });
      }
      if (value.type === "Coupons") {
        const filteredItem = state.filterApplied.coupon.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.coupon = filteredItem;
        });
      }
      setState((draft) => {
        draft.disableApplyButton = false;
      });
    } else {
      setState((draft) => {
        draft.filterApplied.all = [];
        draft.filterApplied.stores = [];
        draft.filterApplied.date = {};
        draft.filterApplied.coupon = [];
        draft.showDate = [];
        draft.isBusy = true;
      });
      handleGetCouponReport({
        searchText: filterApplied.searchText,
        pagination: {
          pageIndex: 1,
          pageSize: pagination.pageSize,
        },
        sortBy,
      });
      const initialValue = {
        date: {},
        type: [],
        stores: [],
        coupon: "",
        all: [],
        searchText: filterApplied.searchText,
      };
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initialValue,
      });
    }
  };
  const handleApplyFilter = () => {
    const { stores, date, coupon, searchText } = state.filterApplied;
    const { pagination, sortBy } = state;
    handleGetCouponReport({
      searchText,
      stores,
      coupon,
      date,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: state.filterApplied,
        pagination,
        sortBy,
      },
    });
  };

  const handleFilter = (e, value, type) => {
    if (type === "store") {
      setState((draft) => {
        draft.filterApplied.stores = value.map((v) => {
          return { ...v, type: "Store" };
        });
      });
    } else if (type === "coupons") {
      setState((draft) => {
        draft.filterApplied.coupon = e.map((d) => {
          return { ...d, type: "Coupons" };
        });
      });
    }
    setState((draft) => {
      draft.disableApplyButton = false;
    });
  };
  const gotoPage = (page) => {
    const { searchText, date, coupon, stores } = state.filterApplied;
    const { sortBy } = state;
    handleGetCouponReport({
      searchText,
      date,
      coupon,
      stores,
      pagination: {
        pageIndex: page,
        pageSize: state.pagination.pageSize,
      },
      sortBy,
    });

    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };

  const setPageSize = (e) => {
    const { searchText, date, coupon, stores } = state.filterApplied;
    const { sortBy } = state;
    handleGetCouponReport({
      searchText,
      date,
      coupon,
      stores,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };
  const handleSort = (sortBy) => {
    const { stores, date, coupon, searchText } = state.filterApplied;
    const { pagination } = state;
    handleGetCouponReport({
      searchText,
      stores,
      coupon,
      date,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_SORT",
      page: PAGE_NAME,
      value: sortBy,
    });
    setState((draft) => {
      draft.sortBy = sortBy;
    });
  };
  useEffect(() => {
    setState((draft) => {
      draft.filterApplied.all = [
        state.filterApplied.date,
        ...state.filterApplied.coupon,
        ...state.filterApplied.stores,
      ].filter((el) => Object.keys(el).length);
    });
  }, [
    state.filterApplied.stores,
    state.filterApplied.date,
    state.filterApplied.coupon,
  ]);
  const handleDownloadCouponReportList = async () => {
    try {
      const { stores, date, coupon, searchText } = state.filterApplied;
      const { sortBy } = state;
      let IDs = stores?.map((v) => {
        return v?.value;
      });
      let params = {};

      if (searchText) {
        params = { ...params, search: searchText };
      }
      if (coupon) {
        params = { ...params, coupon_id: coupon };
      }
      if (Object.keys(date).length) {
        params = {
          ...params,
          date: moment(date.value.startDate).format("YYYY-MM-DD"),
        };
      }
      if (IDs.length > 0) {
        params = { ...params, store_id: IDs };
      }
      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };
      const res = await DownloadCouponReportList(query);
      const content = res.headers["content-type"];
      fileDownload(res.data, `Usage History.xlsx`, content);
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const getCouponsList = async () => {
    try {
      const res = await getCoupons();
      if (res.success) {
        setState((draft) => {
          draft.coupons = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  useEffect(() => {
    if (isCouponReport) {
      getCouponsList();
    }
  }, [isCouponReport]);
  return {
    state,
    handleSearch,
    handleDateChangeApply,
    onChangeDate,
    handleIsOpen,
    handleFilterClear,
    handleApplyFilter,
    handleFilter,
    gotoPage,
    setPageSize,
    handleSort,
    handleDownloadCouponReportList,
    couponOptions,
  };
};
