import * as React from "react";
import { Box, Button, Checkbox, Stack, TextField } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { DashBoardDateRangePicker } from "../..";
import { useDebouncedCallback } from "use-debounce";

const ReviewAndProductFilterComponent = ({
  state,
  productsList,
  categoriesList,
  ratingsList,
  statusList,
  publishedStatusList,
  handleApplyFilter,
  handleReviewFilterSelection,
  handleOpenDateRangeSelection,
  handleDateChangeApply,
  handleProductFetch,
  handleAutocompleteServerSearch,
}) => {
  const { filter } = state;

  const getOptionName = (option) => option.name;
  const isOptionEqualToId = (option, current) => option.id === current.id;
  const renderTags = (value, _) => <></>;

  const componentsProps = {
    paper: {
      sx: {
        minWidth: 250,
      },
    },
  };

  const fetchMore = useDebouncedCallback(
    // The function to fetch products based on the provided index
    (index) => handleProductFetch(index),
    // Wait for 1000 milliseconds (1 second) after the last call before executing
    1000
  );

  return (
    <React.Fragment>
      <Stack direction="row" gap="20px" width="100%">
        {productsList && (
          <InputControl
            type="dropdown"
            name="product"
            label="Product"
            multiple={true}
            options={productsList}
            value={filter.list.product}
            loading={state.product.isLoading}
            disableCloseOnSelect
            disableClearable={true}
            onChange={(_, value) =>
              handleReviewFilterSelection({ value, type: "product" })
            }
            getOptionLabel={getOptionName}
            isOptionEqualToValue={isOptionEqualToId}
            renderTags={renderTags}
            renderOption={(props, option, { selected }) => {
              return (
                <React.Fragment key={option.id}>
                  <Stack
                    direction="row"
                    component="li"
                    {...props}
                    alignItems="center"
                  >
                    <Checkbox checked={selected} />
                    <Stack direction="column">
                      <Box component="span">{option.name}</Box>
                      <Box component="span">{option.id}</Box>
                    </Stack>
                  </Stack>
                  {/* Showing load more option */}
                  {props["data-option-index"] === productsList.length - 1 &&
                    state.product.pagination.hasMorePages && (
                      <Stack justifyContent="center" alignItems="center">
                        Loading...
                      </Stack>
                    )}
                </React.Fragment>
              );
            }}
            onInputChange={handleAutocompleteServerSearch}
            sx={{ minWidth: "150px" }}
            componentsProps={componentsProps}
            renderInput={(params) => (
              <TextField {...params} label="Product" placeholder="Products" />
            )}
            ListboxProps={{
              onScroll: (event) => {
                const listboxNode = event.currentTarget;
                const threshold = 5; // Adjust as needed
                const { pagination } = state.product;

                if (
                  listboxNode.scrollHeight - listboxNode.scrollTop <=
                  listboxNode.clientHeight + threshold
                ) {
                  const currentPage = pagination.pageIndex + 1;
                  if (
                    pagination.hasMorePages &&
                    currentPage <= pagination.lastPage
                  ) {
                    fetchMore(pagination.pageIndex + 1);
                  }
                }
              },
            }}
          />
        )}

        {ratingsList && (
          <InputControl
            type="dropdown"
            name="rating"
            label="Rating"
            multiple={true}
            options={ratingsList}
            value={filter.list.rating}
            disableCloseOnSelect
            disableClearable={true}
            onChange={(_, value) =>
              handleReviewFilterSelection({ value, type: "rating" })
            }
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderTags={renderTags}
            renderOption={(props, option, { selected }) => (
              <>
                <li {...props} key={option.value}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option.label}
                </li>
              </>
            )}
            sx={{ minWidth: "150px" }}
            componentsProps={componentsProps}
            renderInput={(params) => (
              <TextField {...params} label="Rating" placeholder="Ratings" />
            )}
          />
        )}

        {statusList && (
          <InputControl
            type="dropdown"
            name="status"
            label="Status"
            multiple={true}
            options={statusList}
            value={filter.list.status}
            disableCloseOnSelect
            disableClearable={true}
            onChange={(_, value) =>
              handleReviewFilterSelection({ value, type: "status" })
            }
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderTags={renderTags}
            renderOption={(props, option, { selected }) => (
              <>
                <li {...props} key={option.value}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option.label}
                </li>
              </>
            )}
            sx={{ maxWidth: "150px" }}
            componentsProps={{
              paper: {
                sx: {
                  minWidth: 150,
                },
              },
            }}
            renderInput={(params) => (
              <TextField {...params} label="Status" placeholder="Status" />
            )}
          />
        )}

        {categoriesList && (
          <InputControl
            type="dropdown"
            name="category"
            label="Category"
            multiple={true}
            disableCloseOnSelect
            disableClearable={true}
            renderTags={renderTags}
            options={categoriesList}
            value={filter.list.category}
            getOptionLabel={getOptionName}
            isOptionEqualToValue={isOptionEqualToId}
            onChange={(_, value) =>
              handleReviewFilterSelection({ value, type: "category" })
            }
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.value}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option.name}
              </li>
            )}
            sx={{ minWidth: "150px" }}
            componentsProps={componentsProps}
            renderInput={(params) => (
              <TextField {...params} label="Category" placeholder="Category" />
            )}
          />
        )}

        {publishedStatusList && (
          <InputControl
            type="dropdown"
            name="published_status"
            label="Published Status"
            multiple={true}
            options={publishedStatusList}
            value={filter.list.published_status}
            disableCloseOnSelect
            disableClearable={true}
            onChange={(_, value) =>
              handleReviewFilterSelection({ value, type: "published_status" })
            }
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => <></>)
            }
            renderOption={(props, option, { selected }) => (
              <>
                <li {...props} key={option.value}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option.label}
                </li>
              </>
            )}
            sx={{ minWidth: "150px" }}
            componentsProps={componentsProps}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Publish Status"
                placeholder="Publish Status"
              />
            )}
          />
        )}

        {handleOpenDateRangeSelection && handleDateChangeApply && (
          <DashBoardDateRangePicker
            isList={true}
            range={filter.date.value}
            showRange={filter.date.showDate}
            isOpen={filter.date.isCalenderOpen}
            handleIsOpen={handleOpenDateRangeSelection}
            handleDateChangeApply={handleDateChangeApply}
            position="left"
            onChangeDate={(event) => {
              handleReviewFilterSelection({ value: event, type: "date_range" });
            }}
          />
        )}
      </Stack>
      <Button
        type="button"
        size="medium"
        variant="contained"
        color="info"
        sx={{
          width: "72px",
        }}
        onClick={handleApplyFilter}
      >
        Apply
      </Button>
    </React.Fragment>
  );
};

export default ReviewAndProductFilterComponent;
