import { type } from "@testing-library/user-event/dist/type";
import { API } from "../../../utils";

export const getCustomersList = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `customers?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};
export const getDeletedCustomersList = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `customers/delete-requests?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};
export const getCustomerDetailsById = async (id) => {
  const res = await API.get(`customers/${id}`);
  return res.data;
};

export const createCustomer = async (payload) => {
  const res = await API.post(`customers`, payload);
  return res.data;
};

export const editCustomer = async (id, payload) => {
  const res = await API.put(`customers/${id}`, payload);
  return res.data;
};

export const deleteCustomerById = async (id) => {
  const res = await API.delete(`customers/${id}`);
  return res.data;
};

export const resetCustomerPassword = async (id) => {
  const res = await API.put(`customers/${id}/password/reset`);
  return res.data;
};

export const sendCustomerEmailVerification = async (id) => {
  const res = await API.post(`customers/${id}/email/verify`);
  return res.data;
};

export const sendCustomerPhoneVerification = async (id) => {
  const res = await API.post(`customers/${id}/phone/verify`);
  return res.data;
};

export const verifyCustomerEmailorPhone = async (id, payload) => {
  const res = await API.post(`verify/${id}`, payload);
  return res.data;
};

export const forceVerifyCustomer = async (id) => {
  const res = await API.post(`customers/${id}/force-verify/`);
  return res.data;
};

export const resetCustomerPasswordById = async (email, id) => {
  const res = await API.put(`customers/${id}/password/reset`, email);
  return res.data;
};

export const getCustomerTransaction = async (query, pagination, id) => {
  const { pageIndex, pageSize } = pagination;
  const url = `customers/${id}/transactions?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};
export const getCustomerPurchasedProduct = async (query, pagination, id) => {
  const { pageIndex, pageSize } = pagination;
  const url = `customers/${id}/products?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};

export const DownloadCustomersList = async (query) => {
  const res = await API.get(`export/customers`, query);
  return res.data;
};

export const getCustomerClientAccount = async (id) => {
  const res = await API.get(`client-accounts/customers/${id}`);
  return res.data;
};

export const EnableOrDisableClientAccount = async (id) => {
  const res = await API.post(`client-accounts/${id}/change-status`);
  return res.data;
};

export const deleteClientAccountById = async (id) => {
  const res = await API.delete(`client-accounts/${id}`);
  return res.data;
};

export const assignStoresToClinetAccount = async (id, payload) => {
  const res = await API.post(
    `client-accounts/customers/${id}/add-store`,
    payload
  );
  return res.data;
};

export const getClientAccountDetailsById = async (id) => {
  const res = await API.get(`client-accounts/${id}`);
  return res.data;
};

export const updateClientAccount = async (id, payload) => {
  const res = await API.put(`client-accounts/${id}`, payload);
  return res.data;
};
export const getCustomerAddressById = async (id) => {
  const res = await API.get(`customers/${id}/addresses`);
  return res.data;
};
export const getCustomerFavouriteProduct = async (id) => {
  const res = await API.get(`customers/${id}/favourite-products`);
  return res.data;
};
export const editClientAccountCustomers = async (id, payload) => {
  const res = await API.put(`client-customers/${id}`, payload);
  return res.data;
};

export const cancelDeleteRequest = async (id) => {
  const res = await API.post(`customers/${id}/cancel`);
  return res.data;
};
