import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import { AddorEditFuturePrice, FuturePriceList } from "./containers";

export const FuturePrice = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<FuturePriceList />} />

        <Route
          element={<ProtectedRoute allowedRoute={["future-price-create"]} />}
        >
          <Route path="/create" element={<AddorEditFuturePrice />} />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["future-price-modify"]} />}
        >
          <Route path="/edit/:id" element={<AddorEditFuturePrice />} />
        </Route>
      </Routes>
    </>
  );
};
