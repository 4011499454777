import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import InputControl from "./InputControl";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert2";
import { createRef } from "react";

const tableCellStyle = {
  borderBottom: "1px solid #E6E6E6",
  borderRight: "1px solid #E6E6E6",
  padding: "2px 2px",
  width: "110px",
};

export const EndofDayTableSimple = (prop) => {
  const {
    denominations,
    float_amount,
    setState,
    stateKey,
    disableTable = false,
    cash_banked_total,
    isDetails = false,
  } = prop;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const inputRefs = denominations.map((item) => createRef(null));

  useEffect(() => {
    if (denominations.length) {
      const updatedDeno = denominations.map((deno) => {
        if (deno.entry_count) {
          return { ...deno, bank: deno.entry_count - deno.float_count };
        } else {
          return deno;
        }
      });
      setState((draft) => {
        draft[stateKey].denominations = updatedDeno;
      });
    }
  }, []);

  const handleModificationChange = (e, index) => {
    const { value } = e.target;
    setState((draft) => {
      draft[stateKey].denominations[index].modification = value;
    });
  };

  const handleEntryChange = (e, index) => {
    const { value } = e.target;
    setState((draft) => {
      draft[stateKey].denominations[index].entry_count = value;
      draft[stateKey].denominations[index].bank =
        value - draft[stateKey].denominations[index].float_count;
    });
  };

  const handleFloatChange = (e, index) => {
    const { value } = e.target;
    setState((draft) => {
      draft[stateKey].denominations[index].float_count = value;
      draft[stateKey].denominations[index].bank =
        draft[stateKey].denominations[index].entry_count - value;
    });
  };

  const checkMinValues = (data) => {
    const errors = [];
    data.forEach((item) => {
      if (item.min && item.entry_count < item.min) {
        errors.push({ label: item.denomination_label, min: item.min });
      }
    });
    if (errors.length) {
      let errorString = "";
      errors.forEach((item) => {
        errorString += `${item.label} must be atleast ${item.min}` + "</br>";
      });
      Swal.fire({
        title: "Check min value",
        icon: "error",
        html: errorString,
      });
    }
    return errors.length > 0 ? true : false;
  };

  const handleCalulate = () => {
    let data = JSON.parse(JSON.stringify(denominations));
    const hasError = checkMinValues(data);
    if (hasError) {
      console.log("hasErrror");
    } else {
      data.forEach((item, index, array) => {
        if (index < 6) {
          array[index].bank = array[index].entry_count - array[index].min;
          array[index].float_count = array[index].min;
        }
      });
      setState((draft) => {
        draft[stateKey].denominations = data;
      });
    }
  };

  const entryTotal = denominations.reduce((acc, item) => {
    return acc + item.entry_count * item.denomination_cash;
  }, 0);

  const cashTotal = denominations.reduce((acc, item) => {
    return (
      acc +
      (Number(item.modification) + Number(item.bank)) * item.denomination_cash
    );
  }, 0);

  const floatTotal = denominations.reduce((acc, item) => {
    return acc + item.float_count * item.denomination_cash;
  }, 0);

  const coinsIds = [1, 2, 3, 4, 5, 6];
  return (
    <>
      <Paper sx={{ boxShadow: "none" }}>
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderRight: "1px solid #E6E6E6" }}>
                  Denomination
                </TableCell>
                <TableCell align="left">Cash</TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #E6E6E6" }}
                >
                  Float
                </TableCell>
                <TableCell align="center">Bank</TableCell>
                <TableCell align="left">Modifications</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {denominations.map((item, index) => {
                const {
                  denomination_label,
                  denomination_cash,
                  id,
                  modification,
                  entry_count,
                  float_count,
                  bank,
                  min,
                  max,
                } = item;

                const cashSum = (
                  Number(modification * denomination_cash) +
                  Number(bank * denomination_cash)
                ).toFixed(2);
                const entrySum = (denomination_cash * entry_count).toFixed(2);
                const floatSum = (float_count * denomination_cash).toFixed(2);

                return (
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        ...tableCellStyle,
                        backgroundColor:
                          !coinsIds.includes(id) && "rgba(0, 0, 0, 0.03)",
                      }}
                    >
                      <Stack>
                        <Box>
                          <Typography
                            fontWeight={600}
                            fontSize={14}
                            sx={{
                              lineHeight: "19px",
                            }}
                          >
                            {denomination_label}
                          </Typography>
                        </Box>
                        {index < 6 && (
                          <Box>
                            <Typography
                              fontWeight={400}
                              fontSize={12}
                              sx={{
                                lineHeight: "19px",
                                color: "#808080",
                              }}
                            >
                              {!disableTable && `Min:${min}`}
                            </Typography>
                          </Box>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyle,
                        backgroundColor:
                          !coinsIds.includes(id) && "rgba(0, 0, 0, 0.03)",
                      }}
                    >
                      <Stack direction="row" alignItems="center" gap={"20px"}>
                        <InputControl
                          name={id}
                          type="number"
                          required
                          value={entry_count}
                          disabled={disableTable}
                          onChange={(e) => handleEntryChange(e, index)}
                          width="130px"
                          inputRef={inputRefs[index]}
                          onKeyDown={(event) => {
                            if (
                              event.key === "Enter" &&
                              index < denominations.length - 1
                            ) {
                              inputRefs[index + 1].current.focus();
                            }
                            if (["e", "+", "-"].includes(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          sx={{
                            lineHeight: "22px",
                          }}
                        >
                          {`= $${entrySum}`}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyle,
                        backgroundColor:
                          !coinsIds.includes(id) && "rgba(0, 0, 0, 0.03)",
                      }}
                    >
                      <Stack
                        direction="row"
                        gap="10px"
                        alignItems="center"
                        justifyContent="space-around"
                      >
                        <InputControl
                          name={id}
                          type="number"
                          required
                          width="130px"
                          value={float_count}
                          onChange={(e) => handleFloatChange(e, index)}
                          isDisabledKeys={["-"]}
                        />

                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          sx={{
                            lineHeight: "22px",
                          }}
                        >
                          = ${floatSum}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyle,
                        width: "100px !important",
                        borderRight: "none",
                        backgroundColor:
                          bank < 0
                            ? "red"
                            : !coinsIds.includes(id) && "rgba(0, 0, 0, 0.03)",
                      }}
                    >
                      <Stack
                        direction="row"
                        gap="10px"
                        alignItems="center"
                        justifyContent="space-around"
                      >
                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          sx={{
                            lineHeight: "22px",
                          }}
                        >
                          {bank}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyle,
                        backgroundColor:
                          !coinsIds.includes(id) && "rgba(0, 0, 0, 0.03)",
                      }}
                    >
                      <Stack
                        direction="row"
                        gap="10px"
                        alignItems="center"
                        justifyContent="space-around"
                      >
                        <InputControl
                          name={id}
                          type="number"
                          value={modification}
                          onChange={(e) => handleModificationChange(e, index)}
                          width="120px"
                          required
                          disabled={disableTable}
                        />

                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          sx={{
                            lineHeight: "22px",
                          }}
                        >
                          = ${cashSum}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    ...tableCellStyle,
                    height: "48px",
                  }}
                >
                  {!disableTable && (
                    <Button onClick={handleCalulate}>Calculate</Button>
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    ...tableCellStyle,
                    minWidth: "191px",
                    height: "48px",
                  }}
                >
                  <Stack direction={"row"} gap={"50px"}>
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      sx={{
                        lineHeight: "19px",
                      }}
                    >
                      Total Cash
                    </Typography>
                    <Typography
                      fontWeight={600}
                      sx={{
                        lineHeight: "22px",
                      }}
                    >
                      = ${entryTotal.toFixed(2)}
                    </Typography>
                  </Stack>
                </TableCell>

                <TableCell
                  align="left"
                  sx={{
                    ...tableCellStyle,
                    height: "48px",
                    backgroundColor:
                      (float_amount - floatTotal).toFixed(2) != 0
                        ? "red"
                        : "green",
                  }}
                >
                  <Stack
                    direction={"row"}
                    gap={"20px"}
                    justifyContent={"space-around"}
                  >
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      sx={{
                        lineHeight: "19px",
                      }}
                    >
                      {float_amount === floatTotal
                        ? ""
                        : floatTotal < float_amount
                        ? `Under $${(float_amount - floatTotal).toFixed(2)}`
                        : floatTotal > float_amount
                        ? `Over $${(floatTotal - float_amount).toFixed(2)}`
                        : ""}
                    </Typography>
                    <Typography
                      fontWeight={600}
                      sx={{
                        lineHeight: "22px",
                      }}
                    >
                      = ${floatTotal.toFixed(2)}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    ...tableCellStyle,
                    borderRight: "none",
                    height: "48px",
                  }}
                ></TableCell>
                <TableCell
                  align="left"
                  sx={{
                    ...tableCellStyle,
                    height: "48px",
                  }}
                >
                  <Stack
                    direction={"row"}
                    gap={"50px"}
                    justifyContent={"space-around"}
                  >
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      sx={{
                        lineHeight: "19px",
                      }}
                    >
                      Total to Bank
                    </Typography>
                    <Typography
                      fontWeight={600}
                      sx={{
                        lineHeight: "22px",
                      }}
                    >
                      = $
                      {isDetails
                        ? cash_banked_total.toFixed(2)
                        : cashTotal.toFixed(2)}
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
