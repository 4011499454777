import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import * as React from "react";
import { Button, Stack, Card, Typography } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useParams } from "react-router-dom";
import { useTax } from "..";
import SimpleReactValidator from "simple-react-validator";


export const CreateOrEditTaxes = () => {
  const { id } = useParams();
  const updateMode = id ? true : false;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const { state, handleTaxesInputChange, onSaveTaxes, onUpdateTaxes } = useTax({
    isTaxesId: id,
    isTaxesEdit: updateMode,
  });
  const { updateData, isSaveButton, editLoading, details } = state.taxes;
  const { name, tax_rate } = updateData;
  const onSubmit = () => {
    if (validator.current.allValid()) {
      updateMode ? onUpdateTaxes(id) : onSaveTaxes();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  return (
    <>
      <ContentLayout
        title={updateMode ? details?.name : "Add Tax"}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Taxes", href: "/tax-groups-and-taxes" },
              { name: updateMode ? details?.name : "Add Tax" },
            ]}
          />
        }
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              onClick={onSubmit}
              disabled={isSaveButton}
            >
              {updateMode ? "Update" : "Save"}
            </Button>
          </>
        }
      >
        {editLoading ? (
          <Loader />
        ) : (
          <Card sx={{ p: "32px 24px", mb: 3 }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 3, sm: 24 }}
              gap="24px"
            >
              <Typography variant="subtitle1" fontWeight={600}>
                Details
              </Typography>

              <Stack width={"100%"} gap="20px">
                <InputControl
                  name="name"
                  label="Name *"
                  value={name}
                  onChange={handleTaxesInputChange}
                  error={validator.current.message(
                    "Name",
                    name,
                    "required|min:2"
                  )}
                  helperText={validator.current.message(
                    "Name",
                    name,
                    "required|min:2"
                  )}
                />
                <InputControl
                  name="tax_rate"
                  label="Tax Rate(%)"
                  onChange={handleTaxesInputChange}
                  value={tax_rate}
                  error={validator.current.message(
                    "Tax Rate(%)",
                    tax_rate,
                    "required|numeric|min:0,num"
                  )}
                  helperText={validator.current.message(
                    "Tax Rate(%)",
                    tax_rate,
                    "required|numeric|min:0,num"
                  )}
                />
              </Stack>
            </Stack>
          </Card>
        )}
      </ContentLayout>
    </>
  );
};
