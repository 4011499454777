import {
    CustomTable,
    CustomeDialog,
    DeleteModal,
    usePermission,
    Loader,
} from "../../../shared";
import * as React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { sortItems } from "../../../utils";
import { InputCheckBoxListDialog } from "./InputCheckBoxListDialog";
import { DetailsEditDialog } from "./DetailsEditDialog";

export const ProductBundleGroups = (props) => {
    const { data,
        variants,
        globalData,
        handleModal,
        handleBundleProductSelect,
        HandleFilterBundleGroup,
        handleAssignproductToBundle,
        handleDeleteProductFromBundle,
        handleChangeBundleInput
    } = props
    const {
        isBusy,
        isLoading,
        dataList,
        filteredList,
        isOpen,
        dialogLoading,
        selectedItems,
        searchText,
        isDeleteOpen,
        isOpenEdit,
        page,
    } = data

    const navigate = useNavigate();
    const isDeletable = usePermission("product-delete");
    const isCreate = usePermission("product-create");
    const isEdit = usePermission("product-modify");

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>

                    {dataList.map((data, index) => (
                        <BundleTable
                            data={data}
                            index={index}
                            handleModal={handleModal}
                            isCreate={isCreate}
                            isDeletable={isDeletable}
                            isEdit={isEdit}
                            navigate={navigate}
                            globalData={globalData}
                        />
                    ))
                    }

                    <CustomeDialog
                        open={isOpen}
                        handleClose={() => handleModal("product-bundle-add", false, null)}
                        PaperProps={{
                            sx: { width: "432px", height: "531px", p: "16px" },
                        }}
                        title="Bundles Group"
                        content={
                            <InputCheckBoxListDialog
                                Items={filteredList}
                                onSearch={HandleFilterBundleGroup}
                                loading={dialogLoading}
                                onSelect={handleBundleProductSelect}
                                onSubmit={handleAssignproductToBundle}
                                disabled={selectedItems.length === 0}
                                isSelected={(id) => selectedItems.includes(id)}
                                onClearSearch={() => {
                                    HandleFilterBundleGroup("search", "");
                                }}
                                inputChange={handleChangeBundleInput}
                                value={searchText}
                                selectedItems={selectedItems}
                                keys={['min_choice', 'max_choice', 'selection_type']}
                                options={globalData?.bundle_groups?.selection_type}
                                isBusy={isBusy}
                                page={page}
                            />
                        }
                    />
                    <CustomeDialog
                        open={isOpenEdit}
                        handleClose={() => handleModal("product-bundle-edit", false, null, null)}
                        PaperProps={{
                            sx: { width: "389px", height: "341px", p: "16px", maxWidth: '975px' },
                        }}
                        title={selectedItems[0]?.name || ""}
                        content={
                            <DetailsEditDialog
                                data={selectedItems[0]}
                                isBusy={isBusy}
                                onSubmit={handleAssignproductToBundle}
                                inputChange={handleChangeBundleInput}
                                keys={['min_choice', 'max_choice', 'selection_type']}
                                options={globalData?.bundle_groups?.selection_type}
                            />
                        }
                    />
                    <DeleteModal
                        open={isDeleteOpen}
                        handleClose={() => handleModal("product-bundle-delete", false, null, null)}
                        onConfirm={handleDeleteProductFromBundle}
                    />
                </>
            )}
        </>
    );
};

export function BundleTable({ data, handleModal, isCreate, isDeletable, index, navigate, globalData, isEdit }) {
    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                id: "name",
                accessor: (row) => {
                    return (
                        <div
                            className="table-taxt-and-icon-link"
                            onClick={() => navigate(`/bundle/details/${row.id}`)}
                        >
                            <span className="link-text">{row.name}</span>
                            <div className="link-icon-wrap">
                                <img alt="link" src="/icons/ic_external_link.svg" />
                            </div>
                        </div>
                    );
                },
                disableSortBy: true,
            },
            {
                Header: "Type",
                accessor: (row) => {
                    return globalData.bundle_groups?.selection_type.find(
                        (e) => Number(e.value) === row.selection_type
                    ).label
                },
                disableSortBy: true,
            },
            {
                Header: "Minimum",
                accessor: "min_choice",
                disableSortBy: true,
            },
            {
                Header: "Maximum",
                accessor: "max_choice",
                disableSortBy: true,
            },
            {
                Header: "Action",
                id: "action",
                align: "right",
                disableSortBy: true,
                accessor: (row, index) => {
                    return (
                        <ActionButtons
                            deleteAction={() => {
                                handleModal("product-bundle-delete", true, row.id, data.id)
                            }}
                            editAction={
                                () => {
                                    handleModal("product-bundle-edit", true, data.id, row)
                                }
                            }
                        />
                    );
                },
            },
        ],
        []
    );
    const ActionButtons = ({ deleteAction, editAction }) => {
        return (
            <div className="action-buttons">
                {isEdit && (
                    <img alt="edit" src="/icons/ic_edit.svg"
                        onClick={editAction}
                    />
                )}
                {isDeletable && (
                    <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
                )}
            </div>
        );
    };
    return (
        <CustomTable
            columns={columns}
            data={data.bundle}
            searchBar={false}
            tableTitle={data.name}
            pagination={false}
            index={index}
            headerRightContent={
                <>
                    {isCreate && (
                        <Button
                            type="button"
                            size="medium"
                            variant="text"
                            startIcon={<AddIcon />}
                            onClick={() => handleModal("product-bundle-add", true, data.id)}
                        >
                            Add
                        </Button>
                    )}
                </>
            }
        />
    );
}