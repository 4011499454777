import React from "react";
import { Route, Routes } from "react-router-dom";
import { TemplateList } from "./containers/TemplateList";
import { TemplateDetails } from "./containers/TemplateDetails";
import { ProtectedRoute } from "../../shared";

export const ProductSorting = () => {
  return (
    <Routes>
      <Route path="/" element={<TemplateList />} />
      <Route element={<ProtectedRoute allowedRoute={["keypad-modify"]} />}>
        <Route path="/details/:id" element={<TemplateDetails />} />
      </Route>
    </Routes>
  );
};
