import * as React from "react";
import { Button } from "@mui/material";
import { CustomDetailsList, Loader } from "../../../shared";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";

export const ProductSeo = (props) => {
  const { id, state } = props;
  const navigate = useNavigate();
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const columns = [
    {
      title: "URL Slug",
      accessor: "slug",
    },

    {
      title: "Meta Title",
      accessor: "meta_title",
    },
    {
      title: "Meta Description",
      accessor: "meta_description",
    },
    {
      title: "Image Alt Text",
      accessor: "image_alt_text",
    },
    {
      title: "Image Description",
      accessor: "image_description",
    },
  ];

  return (
    <>
      {state?.isBusy ? (
        <Loader />
      ) : (
        <>
          <CustomDetailsList
            columns={columns}
            data={state?.seoDetails}
            title="SEO"
            headerRightContent={
              <>
                <Button
                  size="medium"
                  variant="text"
                  onClick={() => navigate(`/products/details/${id}/seo`)}
                >
                  Edit
                </Button>
              </>
            }
          />
        </>
      )}
    </>
  );
};
