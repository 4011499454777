import { Button, Container, Stack } from "@mui/material";
import React from "react";
import {
  ContentLayout,
  HeaderBreadcrumbs,
  Loader,
  usePermission,
} from "../../../shared";
import { useNavigate, useParams } from "react-router-dom";
import { StoresListTable } from "../components/StoresListTable";
import { useProductSort } from "../hooks/useProductSort";

export const TemplateDetails = () => {
  const IsEditable = usePermission("keypad-modify");
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    state: { isDirty, details, isBusy },
    updateIncluded,
  } = useProductSort({ isList: false, id });

  return (
    <ContentLayout
      backImage={false}
      title={details?.name}
      breadcrumbs={
        <HeaderBreadcrumbs
          links={[
            { name: "Dashboard", href: "/" },
            {
              name: "Keypad",
              href: "/keypad",
            },
            { name: `${details?.name}` },
          ]}
        />
      }
      rightContent={
        <>
          {IsEditable && (
            <Button
              type="button"
              size="medium"
              variant="contained"
              onClick={() => navigate(`/sales-screen-layout/edit/${id}`)}
            >
              Edit Template
            </Button>
          )}
        </>
      }
    >
      {isDirty ? (
        <Loader />
      ) : (
        <Stack p="16px">
          <StoresListTable
            details={details}
            isBusy={isBusy}
            updateIncluded={updateIncluded}
          />
        </Stack>
      )}
    </ContentLayout>
  );
};
