import { ContentLayout } from "../../../shared";
import * as React from "react";
import { Box, Button, Stack, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { StateList, SuburbList, useStateList } from "..";
import { Link } from "react-router-dom";
import { useRef } from "react";

export const StateAndSuburbList = () => {
  const fileRef = useRef(null);
  const { currentTab, setCurentTab, imports, onUpload } = useStateList({
    isState: false,
    isSuburb: false,
    stateId: null,
    suburbId: null,
  });

  const handleChange = (event, newValue) => {
    setCurentTab("stateandSuburb", newValue);
  };
  const onRefClick = () => {
    fileRef.current.click();
  };

  return (
    <>
      <ContentLayout title="State & Suburb">
        <TabContext value={currentTab}>
          <Box
            className="sticky-Tab"
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              top: "88px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TabList onChange={handleChange}>
              <Tab label="State" value="1" />
              <Tab label="Suburb" value="2" />
            </TabList>
            <Stack direction="row" gap="16px">
              <Button
                component={Link}
                to={imports?.states}
                type="button"
                size="medium"
                variant="text"
              >
                Download Template
              </Button>
              <Button
                type="button"
                size="medium"
                variant="text"
                onClick={(e) => onRefClick(e)}
              >
                <input
                  id="contained-button-file"
                  type="file"
                  onChange={(e) => onUpload(e)}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  style={{ display: "none" }}
                  ref={fileRef}
                />
                <img
                  alt="export"
                  src="/icons/ic_export.svg"
                  style={{
                    marginRight: "4px",
                  }}
                />
                Upload
              </Button>
            </Stack>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            <StateList />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            <SuburbList />
          </TabPanel>
        </TabContext>
      </ContentLayout>
    </>
  );
};
