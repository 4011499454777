import { API } from "../../../utils";

export const getTopSellingProductsApi = async () => {
  const res = await API.get("top-pick-products");
  return res.data;
};

export const updateTitleTextApi = async (newTitle) => {
  return await API.post("top-picks/title", {
    top_picks_title: newTitle
  })
}

export const getProductCategoryList = async (searchVal) => {
  const res = await API.get("product/list");
  return res.data;
};

export const setAsTopSellingProducts = async (product) => {
  const res = await API.put("set-as-top-picks", product);
  return res.data
}

export const RemoveTopSellingProduct = async (productId) => {
  const res = await API.put(`products/${productId}/remove-top-pick`);
  return res.data
}