import {
  ContentLayout,
  HeaderBreadcrumbs,
  Loader,
  PageNotFound,
} from "../../../shared";
import * as React from "react";
import { CustomTable } from "../../../shared";
import {
  Box,
  Button,
  CardHeader,
  Container,
  Card,
  Chip,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { DeleteModal } from "../../../shared";
import { sortItems } from "../../../utils";
import { useFranchisee } from "../hooks/useFranchisee";

export const FranchiseeDetails = () => {
  const { id } = useParams();

  const {
    handleEdit,
    state,
    handleModals,
    handleDeleteFranchisees,
    isEditable,
  } = useFranchisee({
    isFranchisee: false,
    franchiseelistId: id,
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Store Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
    ],
    []
  );
  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }
  return (
    <>
      <Container>
        <ContentLayout
          title={state.franchiseelistDetails.data.name}
          breadcrumbs={
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Franchisee", href: "/franchisee" },
                {
                  name: state.franchiseelistDetails.data?.name || "",
                },
              ]}
            />
          }
          rightContent={<></>}
        >
          {state.franchiseelistDetails.isBusy ? (
            <Loader />
          ) : (
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 2,
              }}
            >
              <Card sx={{ mb: "24px" }}>
                <List disablePadding>
                  <ListItem divider={true} disablePadding>
                    <ListItemText
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        my: 0,
                        pl: 1,
                      }}
                      disableTypography={true}
                    >
                      <CardHeader title="Details" />
                      <Stack
                        height="85px"
                        justifyContent="center"
                        marginRight={2}
                      >
                        {isEditable && (
                          <Button
                            type="button"
                            size="medium"
                            variant="contained"
                            onClick={handleEdit}
                          >
                            Edit
                          </Button>
                        )}
                      </Stack>
                    </ListItemText>
                  </ListItem>

                  <ListItem divider={true} disablePadding>
                    <ListItemText
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        my: 0,
                        pl: 2,
                      }}
                      disableTypography={true}
                    >
                      <Stack height="48px" justifyContent="center" p="10px">
                        <Typography
                          variant="subtitle2"
                          fontWeight={400}
                          minWidth="160px"
                        >
                          Franchisee Name
                        </Typography>
                      </Stack>
                      <Stack height="48px" justifyContent="center" p="10px">
                        <Typography
                          variant="subtitle2"
                          fontWeight={400}
                          color="#636363"
                        >
                          {state.franchiseelistDetails.data.name}
                        </Typography>
                      </Stack>
                    </ListItemText>
                  </ListItem>
                  <ListItem divider={true} disablePadding>
                    <ListItemText
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        my: 0,
                        pl: 2,
                      }}
                      disableTypography={true}
                    >
                      <Stack height="48px" justifyContent="center" p="10px">
                        <Typography
                          variant="subtitle2"
                          fontWeight={400}
                          minWidth="160px"
                        >
                          Status
                        </Typography>
                      </Stack>
                      <Stack height="48px" justifyContent="center" p="10px">
                        <Typography
                          variant="subtitle2"
                          fontWeight={400}
                          color="#636363"
                        >
                          {state.franchiseelistDetails.data.status === 1 ? (
                            <Chip
                              label="Active"
                              color="success"
                              variant="outlined"
                              size="small"
                            />
                          ) : (
                            <Chip
                              label="Inactive"
                              color="success"
                              variant="outlined"
                              size="small"
                              disabled
                            />
                          )}
                        </Typography>
                      </Stack>
                    </ListItemText>
                  </ListItem>
                </List>
              </Card>

              <Box sx={{ pt: 3 }}>
                <CustomTable
                  columns={columns}
                  data={state.franchiseelistDetails.data.stores}
                  searchBar={false}
                  tableTitle="Stores"
                  onRowLink={(e) => {
                    return `/stores/details/${e.id}`;
                  }}
                />
              </Box>
            </Box>
          )}

          <DeleteModal
            open={state.roleDeleteModal}
            handleClose={() => handleModals(false)}
            onConfirm={handleDeleteFranchisees}
          />
        </ContentLayout>
      </Container>
    </>
  );
};
