import {
  ContentLayout,
  HeaderBreadcrumbs,
  PageNotFound,
  useDropdownApis,
} from "../../../shared";
import * as React from "react";
import {
  Button,
  Stack,
  Card,
  Container,
  Typography,
  TextField,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useSuburbList } from "..";

export const CreateOrEditSuburb = ({ params }) => {
  const { id } = useParams();
  const updateMode = id ? true : false;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const { onChange, state, onSaveSuburb, onUpdateSuburb } = useSuburbList({
    isSuburb: false,
    suburbId: id,
  });
  const { statesList } = useDropdownApis({
    isStatesList: true,
  });
  const stateList = statesList.map((c) => {
    return { value: c.id, label: c.name };
  });
  const { name, state_id, postcode } = state.suburbDetails.data;

  const onSubmit = () => {
    if (validator.current.allValid()) {
      updateMode ? onUpdateSuburb(id) : onSaveSuburb();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  if (state.httpStatusCodes.suburbDetails === 404) {
    return <PageNotFound />;
  }

  return (
    <>
      <ContentLayout
        title={updateMode ? state.suburbDetails.suburbName : "New Suburb"}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "State & Suburb", href: "/state-and-suburb" },
              {
                name: updateMode
                  ? state.suburbDetails.suburbName
                  : "New Suburb",
              },
            ]}
          />
        }
        rightContent={
          <>
            <Button
              onClick={() => onSubmit()}
              type="button"
              size="medium"
              variant="contained"
              disabled={state.suburbDetails.isSaveButtonBusy}
            >
              {updateMode ? "Update" : "Save"}
            </Button>
          </>
        }
      >
        <Card sx={{ p: "32px 24px" }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 3, sm: 24 }}
          >
            <Typography variant="subtitle1" fontWeight={600}>
              Details
            </Typography>
            <Container>
              <Stack gap="20px">
                <InputControl
                  value={name}
                  name="name"
                  onChange={(e) => onChange(e, "suburbDetails")}
                  label="Suburb"
                  required
                  error={validator.current.message(
                    "Suburb name",
                    name,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "Suburb name",
                    name,
                    "required"
                  )}
                />
                <InputControl
                  type="dropdown"
                  options={stateList}
                  getOptionLabel={(option) => option.label}
                  value={
                    stateList &&
                    state_id &&
                    stateList.find((el) => {
                      return el.value === state_id;
                    })
                  }
                  onChange={(e, value) => {
                    onChange(
                      { target: { name: "state_id", value: value.value } },
                      "suburbDetails"
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State *"
                      error={validator.current.message(
                        "state",
                        state_id,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "state",
                        state_id,
                        "required"
                      )}
                    />
                  )}
                />
                <InputControl
                  label="Post code*"
                  type="number"
                  value={postcode}
                  name="postcode"
                  onChange={(e) => onChange(e, "suburbDetails")}
                  error={validator.current.message(
                    "post code",
                    postcode,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "post code",
                    postcode,
                    "required"
                  )}
                />
              </Stack>
            </Container>
          </Stack>
        </Card>
      </ContentLayout>
    </>
  );
};
