import * as React from "react";
import { Link } from "react-router-dom";
import { CustomTable } from "../../../shared";

export const LoyaltyCards = ({ data }) => {
  const { isLoading, dataList } = data;
  const ActionButtons = ({ id }) => {
    return (
      <div className="action-buttons">
        <Link to={`types/${id}`}>
          <img alt="details" src="/icons/arrow_forward.svg" />
        </Link>
      </div>
    );
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Card Type",
        accessor: "card_type",
        disableSortBy: true,
      },
      {
        Header: "Purchase Points",
        accessor: "purchase_percentage",
        Cell: ({ value }) => {
          return `${value}%`;
        },
        disableSortBy: true,
      },
      // {
      //   Header: "Redeem Points",
      //   accessor: "redeem_percentage",
      //   disableSortBy: true,
      //   Cell: ({ value }) => {
      //     return `${value}%`;
      //   },
      // },
      {
        Header: "Product Count",
        accessor: "product_count",
        disableSortBy: true,
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons id={row.id} />;
        },
      },
    ],
    []
  );
  return (
    <>
      <CustomTable
        columns={columns}
        data={dataList}
        isLoading={isLoading}
        searchBar={false}
        rowsSelection={false}
        pagination={false}
          onRowLink={(e) => {
            return `types/${e.id}`
          }}
      />
    </>
  );
};
