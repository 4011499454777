import { Routes, Route } from "react-router-dom";
import {
  DashboardLayout,
  PageNotFound,
  ProtectedRoute,
  UnAuthorised,
} from "../../shared";

import {
  Profile,
  RolesAndPermissions,
  AdminUsers,
  Products,
  Orders,
  Refund,
  Favourites,
  Wastage,
  BFITDashboard,
  BumpScreen,
  ClientAccounts,
  EndoftheDay,
  DepositSlip,
  Report,
  BankAccounts,
  Settings,
  DiscountOrders,
  PaymentMediaReport,
} from "../../modules";
import { Myob, Xero } from "../../modules/XeroAndMyObCopy";

export function StoreAdminDashboard() {
  return (
    <div id="app">
      <DashboardLayout>
        <div id="main-content">
          <Routes>
            <Route
              element={<ProtectedRoute allowedRoute={["dashboard-view"]} />}
            >
              <Route path="/" element={<BFITDashboard />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["role-view"]} />}>
              <Route
                path="/roles-and-permissions/*"
                element={<RolesAndPermissions />}
              />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["user-view"]} />}>
              <Route path="/admin-users/*" element={<AdminUsers />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["product-view"]} />}>
              <Route path="/products/*" element={<Products />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["favourite-view"]} />}
            >
              <Route exact element={<Favourites />} path={"/favourites/*"} />
            </Route>
            <Route exact element={<Orders />} path={"/orders/*"} />
            <Route element={<ProtectedRoute allowedRoute={["order-view"]} />}>
              <Route
                exact
                element={<DiscountOrders />}
                path={"/discount-orders/*"}
              />
            </Route>
            <Route exact element={<Profile />} path={"/profile/*"} />
            <Route element={<ProtectedRoute allowedRoute={["refund-view"]} />}>
              <Route exact element={<Refund />} path={"/refund/*"} />
            </Route>

            {/* Payment Media Report */}
            <Route
              element={<ProtectedRoute allowedRoute={["payment-media-view"]} />}
            >
              <Route
                element={<PaymentMediaReport />}
                path="/payment-media-report/*"
              />
            </Route>

            <Route element={<ProtectedRoute allowedRoute={["wastage-view"]} />}>
              <Route path="/wastage/*" element={<Wastage />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["bump-screen-view"]} />}
            >
              <Route path="/bump-screen/*" element={<BumpScreen />} />
            </Route>
            <Route
              element={
                <ProtectedRoute allowedRoute={["client-account-view"]} />
              }
            >
              <Route
                exact
                element={<ClientAccounts />}
                path={"/client-account/*"}
              />
            </Route>

            <Route element={<ProtectedRoute allowedRoute={["wastage-view"]} />}>
              <Route path="/end-of-day/*" element={<EndoftheDay />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["wastage-view"]} />}>
              <Route path="/reports/*" element={<Report />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["deposit-slip-view"]} />}
            >
              <Route path="/deposit-slip/*" element={<DepositSlip />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["store-view"]} />}>
              <Route path="/accounts/*" element={<BankAccounts />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["dashboard-view"]} />}
            >
              <Route exact element={<Settings />} path={"/settings/*"} />
            </Route>

            <Route
              element={
                <ProtectedRoute allowedRoute={["xero-myob-report-view"]} />
              }
            >
              {/* <Route exact element={<XeroAndMyOb />} path={"/xero-myob/*"} /> */}
              <Route exact element={<Xero />} path={"/xero/*"} />
              <Route exact element={<Myob />} path={"/myob/*"} />
            </Route>
            <Route exact element={<UnAuthorised />} path={"/unauthorized"} />
            <Route exact element={<PageNotFound />} path={"/404"} />
            <Route exact element={<PageNotFound />} path={"*"} />
          </Routes>
        </div>
      </DashboardLayout>
    </div>
  );
}
