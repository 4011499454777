import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../../store/AuthProvider";

export const AuthProtectedRoute = () => {
  const { auth } = useContext(AuthContext);

  const isSuperAdmin = auth.data.roles.find((item) => item.name === "admin");

  return !auth.data.id ? (
    <Outlet />
  ) : (
    <Navigate to={isSuperAdmin ? "/" : "/store-admin/"} replace />
  );
};
