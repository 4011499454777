import { ContentLayout, usePermission } from "../../../shared";
import * as React from "react";
import { CustomTable } from "../../../shared";
import { sortItems } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useEmailSettings } from "../hooks";

export const EmailSettingsList = () => {
  const { state } = useEmailSettings({ isList: true });
  const navigate = useNavigate();
  const isEdit = usePermission("email-setting-modify");
  const { isLoading, dataList } = state;
  const ActionButtons = ({ id }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isEdit && (
          <img
            onClick={() => {
              navigate(`details/${id}`);
            }}
            alt="details"
            src="/icons/arrow_forward.svg"
          />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => {
          return <span style={{ textTransform: "capitalize" }}>{value}</span>;
        },
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Action",
        id: "id",
        accessor: "id",
        align: "right",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return <ActionButtons id={value} />;
        },
      },
    ],
    []
  );

  return (
    <>
      <ContentLayout title="Email Settings">
        <CustomTable
          columns={columns}
          data={dataList}
          isLoading={isLoading}
          tableId="emailsetting_list"
          onRowLink={(e) => {
            return `details/${e.id}`;
          }}
        />
      </ContentLayout>
    </>
  );
};
