import {
  ContentLayout,
  CustomTable,
  DeleteModal,
  usePermission,
  ExportCsv,
  useTabs,
} from "../../../shared";
import * as React from "react";
import { Button, Chip, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { sortItems } from "../../../utils";
import CloseIcon from "@mui/icons-material/Close";
import { useStoreAdminUsers } from "../..";
import { AppContext } from "../../../store/AppScopeProvider";

export const StoreAdminList = () => {
  const { setCurentTab } = useTabs();
  const isDeletable = usePermission("user-delete");
  const isCreate = usePermission("user-create");
  const { appState } = React.useContext(AppContext);
  const { globalData } = appState;
  const {
    state,
    handleModal,
    handleDeleteUser,
    handleStatusFilter,
    handleSearch,
    onSeachClear,
    clearFilters,
  } = useStoreAdminUsers({ isList: true });

  const { isLoading, usersList, deleteModalOpen, searchText, status } = state;
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const ActionButtons = ({ id, deleteAction, editAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
        <Link to={`details/${id}`}>
          <img
            alt="details"
            onClick={editAction}
            src="/icons/arrow_forward.svg"
          />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        id: "name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Phone Number",
        accessor: "phone_formatted",
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ row }) => {
          return (
            <Typography variant="body2">
              {row.original.phone_formatted}
            </Typography>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableGlobalFilter: true,
        sortInverted: true,
        Cell: ({ value }) => {
          return value ? (
            <Chip
              label="Active"
              color="success"
              variant="outlined"
              size="small"
            />
          ) : (
            <Chip
              label="Inactive"
              color="success"
              variant="outlined"
              size="small"
              disabled
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => {
                handleModal(row.id);
              }}
              editAction={() => {
                setCurentTab("adminUsers", "1");
              }}
            />
          );
        },
      },
    ],
    []
  );

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Phone Number", key: "phone_formatted" },
    { label: "Status", key: "status" },
  ];

  const csvData = usersList.map((item) => {
    return {
      name: item.name,
      email: item.email,
      phone_formatted: item.phone_formatted,
      status: item.status ? "Active" : "Inactive",
    };
  });

  return (
    <>
      <ContentLayout
        title="Admin Users"
        rightContent={
          <>
            <ExportCsv
              fileName="users_Infomation_xlsx"
              csvData={csvData}
              csvHeaders={headers}
            />
            <Button
              type="button"
              size="medium"
              variant="text"
              onClick={() => setOpen(!open)}
            >
              <img
                alt="tools"
                src="/icons/ic_Tools.svg"
                style={{
                  marginRight: "4px",
                }}
              />
              Column Option
            </Button>
            {isCreate && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("create")}
              >
                New User
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={usersList}
          isLoading={isLoading}
          drawerOpen={open}
          drawerOnClose={() => setOpen(!open)}
          defaultSearch={false}
          tableId="store_user_list"
          filterContent={
            <>
              <InputControl
                type="table-search"
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
                onClear={onSeachClear}
              />
              <InputControl
                type="select"
                name="Status"
                label="Status"
                value={state.status ?? ""}
                optionValue={globalData.status ?? []}
                width="150px"
                onChange={(e, v) => handleStatusFilter(e)}
              />
              <Button
                type="button"
                size="medium"
                variant="text"
                color="gray"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => clearFilters()}
              >
                <CloseIcon />
              </Button>
            </>
          }
          onRowLink={(e) => {
            return `details/${e.id}`;
          }}
          onRowAction={(e) => {
            setCurentTab("adminUsers", "1");
          }}
        />
      </ContentLayout>
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => handleModal()}
        onConfirm={handleDeleteUser}
      />
    </>
  );
};
