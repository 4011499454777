import { ContentLayout, CustomTable, DeleteModal } from "../../../shared";
import React from "react";
import { Button, Chip, Stack, Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { sortItems } from "../../../utils";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { UseMenuBanners } from "../hooks/UseMenuBanners";
import moment from "moment";

export const MenuBannersList = () => {
  const {
    globalData,
    state,
    onChangeFilter,
    resetFilters,
    onSeachClear,
    toggleModal,
    handleMenuBannerDelete,
    isCreatable,
    isDeletable,
  } = UseMenuBanners({
    load: true,
  });
  const { banners, isBusy, filters, isOpen } = state;
  const { banner } = globalData;
  const navigate = useNavigate();

  const ActionButtons = ({ row, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isDeletable && (
          <img alt="delete" src="/icons/ic_delete.svg" onClick={deleteAction} />
        )}
        <Link to={`details/${row.id}`}>
          <img alt="details" src="/icons/arrow_forward.svg" />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Image",
        accessor: (row, index) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <Avatar
                src={row.image}
                variant="rounded"
                sx={{ width: 60, height: 30 }}
              />
            </Stack>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Title",
        accessor: "title",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Type",
        accessor: "model",
        disableSortBy: true,
        Cell: ({ value }) => {
          return value?.name;
        },
      },
      {
        Header: "Link To",
        Cell: ({ row }) => {
          return row?.original?.model?.type_name;
        },
      },
      {
        Header: "Start Date",
        accessor: (val) => {
          return (
            <div>
              {val.start_date
                ? moment(val.start_date).format("DD MMM, YYYY")
                : ""}
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "End Date",
        accessor: (val) => {
          return (
            <div>
              {val.end_date ? moment(val.end_date).format("DD MMM, YYYY") : ""}
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Start Time",
        accessor: (val) => {
          return val.start_time && val.start_time;
        },
        disableSortBy: true,
      },
      {
        Header: "End Time",
        accessor: (val) => {
          return val.end_time && val.end_time;
        },
        disableSortBy: true,
      },
      {
        Header: "Status",
        disableSortBy: true,
        accessor: (val) => {
          return val.status === 1 ? (
            <Chip
              label="Published"
              color="success"
              variant="outlined"
              size="small"
              sx={{ border: "none !important" }}
            />
          ) : (
            <Chip
              label="Draft"
              color="primary"
              variant="outlined"
              size="small"
              sx={{ bgcolor: "#1560D414", border: "none" }}
              disabled={false}
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              row={row}
              deleteAction={() => {
                toggleModal(true, row.id);
              }}
            />
          );
        },
      },
    ],
    [globalData, banners]
  );
  return (
    <>
      <ContentLayout
        title="Menu Banners"
        rightContent={
          <>
            {isCreatable && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("create")}
              >
                New Banner
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={banners}
          isLoading={isBusy}
          defaultSearch={false}
          filterContent={
            <>
              <InputControl
                type="table-search"
                name="searchText"
                placeholder="Search"
                size="small"
                value={filters.searchText}
                onChange={(e) => onChangeFilter(e)}
                onClear={onSeachClear}
              />
              <InputControl
                type="select"
                name="status"
                label="Status"
                optionValue={banner?.status ?? []}
                width="250px"
                onChange={(e) => onChangeFilter(e)}
                value={filters.status}
              />

              <Button
                type="button"
                size="medium"
                variant="text"
                color="gray"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => resetFilters()}
              >
                <CloseIcon />
              </Button>
            </>
          }
          onRowLink={(e) => {
            return `details/${e.id}`;
          }}
        />
      </ContentLayout>
      <DeleteModal
        open={isOpen}
        handleClose={() => toggleModal()}
        onConfirm={() => {
          handleMenuBannerDelete("list");
        }}
      />
    </>
  );
};
