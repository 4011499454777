import {
  ContentLayout,
  ServerPaginatedTable,
  usePermission,
} from "../../../shared";
import * as React from "react";
import {
  Button,
  Chip,
  createFilterOptions,
  Checkbox,
  TextField,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { arraySort, sortItems } from "../../../utils";
import { useProductsListSA } from "..";

export const StoreProductsList = ({ data }) => {
  const navigate = useNavigate();
  const IsEditable = usePermission("product-modify");

  const {
    state,
    categoriesList,
    pricelevelList,
    handleSearch,
    handleFilterClear,
    handleFilter,
    handleApplyFilter,
    handleSort,
    gotoPage,
    setPageSize,
  } = useProductsListSA({
    isProduct: true,
  });


  const { filterApplied, disableApplyButton } = state;
  const { isLoading, listData } = state.productsList;
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });

  const ActionButtons = ({ name, id, editAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        <Link
          to={`price-level/${id}`}
          state={name}
          style={{ textDecoration: "none" }}
        >
          <Typography
            variant="subtitle2"
            color="#1560D4"
            sx={{
              cursor: "pointer",
            }}
            className="LinkBtn"
          >
            Price Level
          </Typography>
        </Link>
        {IsEditable && (
          <img alt="edit" onClick={editAction} src="/icons/ic_edit.svg" />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        id: "name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ value }) => {
          return value.name;
        },
      },
      {
        Header: "Price Level",
        Cell: ({ cell }) => {
          const price_levels = cell.row.original.price_levels;
          return (
            <Box>
              {price_levels?.slice(0, 1).map((data) => {
                return (
                  <Chip
                    sx={{ mr: 1, borderRadius: "8px" }}
                    label={data.price_level_name}
                    variant="filled"
                    size="small"
                  />
                );
              })}
              {price_levels?.length > 1 && (
                <Chip
                  sx={{ borderRadius: "8px" }}
                  label={`+${price_levels?.length - price_levels?.slice(0, 1)?.length
                    }`}
                  variant="filled"
                  size="small"
                />
              )}
            </Box>
          );
        },
        disableSortBy: true,
      },
      // {
      //   Header: "Price",
      //   accessor: "Price",
      //   Cell: ({ value }) => {
      //     return "$10";
      //   },
      //   disableSortBy: true,
      // },
      {
        Header: "Stock",
        accessor: "stock",
      },

      {
        Header: "Sold Out Status",
        id: "stock_status",
        accessor: (row, index) => {
          return row.sold_out_status === 1
            ? "Available"
            : row.back_in_stock_type === "sold_out_until"
              ? "Sold out until " + row.back_in_stock_time
              : row.back_in_stock_type === "one_hour"
                ? "1 hour "
                : row.back_in_stock_type === "today"
                  ? "Today"
                  : "Indefinitely";
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              name={row.name}
              editAction={() => navigate(`stocks/${row.id}`)}
              deleteAction={() => {
                // handleModal("all-list-delete", true, row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );
  const pricelevelOptions = pricelevelList.map((v) => {
    return { value: v.id, label: v.name };
  });
  const categoryOptions = categoriesList.map((v) => {
    return { value: v.id, label: v.name };
  });
  return (
    <>
      <ContentLayout title="Products">
        <ServerPaginatedTable
          columns={columns}
          data={listData}
          isLoading={isLoading}
          filterApplied={filterApplied.all}
          onFilterClear={(e) => handleFilterClear("single", e)}
          onAllFilterClear={(e) => handleFilterClear("all")}
          defaultSearch={false}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
          pagination={state.pagination}
          sortByGlobal={state.sortBy}
          handleSort={handleSort}
          filterContent={
            <InputControl
              type="table-search"
              value={filterApplied.searchText}
              onChange={(e) => handleSearch(e.target.value)}
              onClear={() => handleSearch("")}
            />
          }
          filterSection={
            <>
              <Stack direction="row" gap="16px" alignItems="center">
                <InputControl
                  type="dropdown"
                  name="price_level"
                  label="Price Level"
                  multiple={true}
                  options={arraySort(pricelevelOptions || [], "label")}
                  value={filterApplied.priceLevels}
                  disableCloseOnSelect
                  disableClearable={true}
                  onChange={(e, v) => handleFilter(e, v, "Price Level")}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Price Level"
                      placeholder="Price Level"
                    />
                  )}
                />
                <InputControl
                  type="dropdown"
                  name="Category"
                  label="Category"
                  multiple={true}
                  options={arraySort(categoryOptions || [], "label")}
                  value={filterApplied.categories}
                  disableCloseOnSelect
                  disableClearable={true}
                  onChange={(e, v) => handleFilter(e, v, "categories")}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category"
                      placeholder="Category"
                    />
                  )}
                />
              </Stack>
              <Button
                type="button"
                size="medium"
                variant="text"
                color="info"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                disabled={disableApplyButton}
                onClick={() => handleApplyFilter()}
              >
                Apply
              </Button>
            </>
          }
          onRowLink={(e) => {
            return IsEditable && `stocks/${e.id}`;
          }}
        />
      </ContentLayout>
    </>
  );
};
