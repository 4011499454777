import { Skeleton, Stack, Typography } from "@mui/material";
import * as React from "react";

export const DashboardChartLoader = (props) => {
    const { title, horizontal = false } = props

    return (
        <Stack sx={{
            boxShadow: "0px 2px 8px 1px #0000000F",
            border: "1px solid #0000001A",
            borderRadius: "8px",
            minHeight: "458px"
        }} gap="16px" mb="24px">
            <Typography variant="subtitle2" fontWeight={600} p="24px">{title}</Typography>
            <Stack
                sx={{
                    width: horizontal ? "max-content" : "100% ",
                    gap: "19px",
                    flexDirection: horizontal ? "column" : "row",
                    alignItems: "flex-end",
                    p: "32px",
                    mt: "auto",
                }}
            >
                {horizontal ? (<>
                    <Stack sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "16px"
                    }}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "50px" }} />
                        <Skeleton variant="rounded" width="280px" height={60} sx={{ borderRadius: "0 8px  8px 0px !important" }} />

                    </Stack>
                    <Stack sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "16px"
                    }}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "50px" }} />
                        <Skeleton variant="rounded" width="280px" height={60} sx={{ borderRadius: "0 8px  8px 0px !important" }} />

                    </Stack>
                    <Stack sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "16px"
                    }}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "50px" }} />
                        <Skeleton variant="rounded" width="280px" height={60} sx={{ borderRadius: "0 8px  8px 0px !important" }} />

                    </Stack>
                    <Stack sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "16px"
                    }}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "50px" }} />
                        <Skeleton variant="rounded" width="280px" height={60} sx={{ borderRadius: "0 8px  8px 0px !important" }} />

                    </Stack>
                </>) : (<>
                    <Stack sx={{
                        alignItems: "center"
                    }}>
                        <Skeleton variant="rounded" width="60px" height={280} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "50%" }} />
                    </Stack>
                    <Stack sx={{
                        alignItems: "center"
                    }}>
                        <Skeleton variant="rounded" width="60px" height={120} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "50%" }} />
                    </Stack>
                    <Stack sx={{
                        alignItems: "center"
                    }}>
                        <Skeleton variant="rounded" width="60px" height={200} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "50%" }} />
                    </Stack>
                    <Stack sx={{
                        alignItems: "center"
                    }}>
                        <Skeleton variant="rounded" width="60px" height={220} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "50%" }} />
                    </Stack>
                </>)}
            </Stack>
        </Stack>
    );
};
