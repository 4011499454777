import {
  ContentLayout,
  CustomeDialog,
  DeleteModal,
  DraggableReactTable,
  HeaderBreadcrumbs,
} from "../../../shared";
import * as React from "react";
import { Button, Stack, Avatar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link, useParams } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { sortItems } from "../../../utils";
import { AddOrEditMenuNames } from "../components";
import { useMenuTemplate } from "../hooks";

export const MenuTemplateDetailsList = () => {
  const { id } = useParams();
  const {
    state,
    isDeletable,
    isEditable,
    isCreateVisible,
    onCategoryOrderChange,
    onChangeCategoryFilter,
    onCategorySeachClear,
    toggleCategoryModal,
    handleDeleteCategoryMenuTemplate,
    handleCategoryModal,
    onSaveMenuTemplateCategory,
    onChangeMenuTemplateCategory,
    handleColor,
    onFileChange,
    onUpdateMenuTemplateCategory,
  } = useMenuTemplate({
    isList: true,
    isMenuId: id,
  });
  const filterState = state.menuTemplates.find((item) => id.includes(item.id));
  const ActionButtons = ({ cid, deleteAction, editAction }) => {
    return (
      <div className="action-buttons">
        {isEditable && (
          <img alt="edit" src="/icons/ic_edit.svg" onClick={editAction} />
        )}
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
        <Link to={`/menu-template/details/${id}/menu_name/${cid}`}>
          <img alt="details" src="/icons/arrow_forward.svg" />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Menu Name",
        accessor: "name",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <Avatar sx={{ bgcolor: row.original.color }}>
                <img src={row.original.icon} className="avater-img" />
              </Avatar>

              <span className="link-text">{row.original.name}</span>
            </Stack>
          );
        },

        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Product Count",
        accessor: "product_count",
        disableGlobalFilter: true,
        disableSortBy: true,
      },

      {
        Header: "Action",
        disableGlobalFilter: true,
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row) => {
          return (
            <ActionButtons
              cid={row?.id}
              deleteAction={() => {
                toggleCategoryModal(row?.id);
              }}
              editAction={() => handleCategoryModal(row?.id, "edit")}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <ContentLayout
        title={filterState?.name}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Menu Template", href: "/menu-template" },
                { name: filterState?.name },
              ]}
            />
          </>
        }
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => handleCategoryModal(null, "create")}
              >
                Add New
              </Button>
            )}
          </>
        }
      >
        <DraggableReactTable
          columns={columns}
          data={state?.menuTemplateCategory}
          defaultSearch={false}
          searchBar={true}
          onRowOrderChange={onCategoryOrderChange}
          isLoading={state?.isBusy}
          maxHeight="202px"
          filterContent={
            <>
              <InputControl
                type="table-search"
                name="searchText"
                placeholder="Search"
                size="small"
                value={state.categoryFilters.searchText}
                onChange={(e) => onChangeCategoryFilter(e)}
                onClear={onCategorySeachClear}
              />
            </>
          }
        />
      </ContentLayout>
      <CustomeDialog
        open={state.isCreateOpen || state?.isEditOpen}
        handleClose={() => handleCategoryModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "618px", height: "462px", p: "16px", gap: "12px" },
        }}
        title="Add New"
        content={
          <AddOrEditMenuNames
            state={state}
            onSaveMenuTemplateCategory={onSaveMenuTemplateCategory}
            onChangeMenuTemplateCategory={onChangeMenuTemplateCategory}
            handleColor={handleColor}
            onFileChange={onFileChange}
            onUpdateMenuTemplateCategory={onUpdateMenuTemplateCategory}
          />
        }
      />
      <DeleteModal
        open={state.isCategoryOpen}
        handleClose={() => toggleCategoryModal()}
        onConfirm={() => handleDeleteCategoryMenuTemplate()}
      />
    </>
  );
};
