import { LoadingButton } from "@mui/lab";
import { Box, Card, Typography } from "@mui/material";
import React from "react";
import { Loader } from "../../../shared";
import { useInvitation } from "../hooks/useInvitation";
export const AcceptInvitation = () => {
  const { state, onSubmit } = useInvitation();
  const {
    userDetails: { message, role_name, store_name },
  } = state;

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "125px",
        }}
      >
        {state.isLoading ? (
          <Loader />
        ) : (
          <Card align="center" sx={{ width: "424px", minHeight: "266px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "24px",
              }}
            >
              <Typography
                fontWeight={700}
                fontSize="32px"
                lineHeight={"42px"}
                mb={1}
              >
                Invitation To Join
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  mb: 3,
                }}
              >
                <Typography
                  fontWeight={600}
                  fontSize="16px"
                  lineHeight={"21px"}
                >
                  {store_name}
                </Typography>
                <Typography
                  color="#636363"
                  fontWeight={400}
                  fontSize="16px"
                  lineHeight={"21px"}
                >
                  , {role_name}
                </Typography>
              </Box>

              <Typography sx={{ mb: 2 }} color="#636363" variant="h7">
                {message}
              </Typography>
              <LoadingButton
                color="primary"
                fullWidth
                variant="contained"
                sx={{ height: "48px" }}
                onClick={() => onSubmit()}
                loading={state.isBusy}
              >
                Accept Invitation
              </LoadingButton>
            </Box>
          </Card>
        )}
      </Box>
    </>
  );
};
