import { ContentLayout, CustomTable, HeaderBreadcrumbs, Loader } from "../../../shared";
import * as React from "react";
import { Chip, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useCoupons } from "../hooks/useCoupons";
import { sortItems } from "../../../utils";


export const CouponDetailsView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useCoupons({
    isCoupons: false,
    isStore: false,
    couponsById: id,
    isBarcode: true
  });
  const columns = React.useMemo(
    () => [
      {
        Header: "Barcode",
        accessor: "coupon_barcode",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Coupon Code",
        accessor: "coupon_code",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Status",
        accessor: "is_used",
        Cell: ({ value }) => {
          return state.data.customer_type === 1 ? (
            <>
              <Chip
                label={value === 1 ? "Used" : "Not Applicable"}
                color={value === 1 ? "error" : "primary"}
                variant="outlined"
                size="small"
                sx={{
                  bgcolor: value !== 1 ? "#1560D414" : "#FFF4CE",
                  border: "none !important",
                }}
                disabled={false}
              />
            </>
          ) : (
            <>
              <Chip
                label={value === 1 ? "Used" : "Not Used"}
                color={value === 1 ? "error" : "primary"}
                variant="outlined"
                size="small"
                sx={{
                  bgcolor: value !== 1 ? "#1560D414" : "#FFF4CE",
                  border: "none !important",
                }}
                disabled={false}
              />
            </>
          );
        },
      },

      {
        Header: "Used Date & Time",
        accessor: "used_date_time",
        Cell: ({ value }) => {
          return state.data.customer_type === 1
            ? null
            : value === null
            ? "--"
            : value;
        },
      },
      {
        Header: "Customer Name",
        accessor: "customer_id",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              {state.data.customer_type === 1 ? null : (
                <>
                  {row.original.customer_id === null ? (
                    row.original.customer_name
                  ) : (
                    <div
                      className="table-taxt-and-icon-link cursor"
                      onClick={() =>
                        navigate(
                          `/customers/details/${row.original.customer_id}`
                        )
                      }
                    >
                      <span className="link-text">
                        {row.original.customer_name}
                      </span>
                    </div>
                  )}
                </>
              )}
            </Stack>
          );
        },
      },
      {
        Header: "Order Id",
        accessor: "order_id",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              {state.data.customer_type === 1 ? null : (
                <>
                  <div
                    className="table-taxt-and-icon-link cursor"
                    onClick={() =>
                      navigate(`/orders/details/${row.original.order_id}`)
                    }
                  >
                    <span className="link-text">
                      {row.original.order_number}
                    </span>
                  </div>
                </>
              )}
            </Stack>
          );
        },
      },
    ],
    [state.data.customer_type]
  );

  return (
    <>
      <ContentLayout
        title="Coupons List"
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Coupons", href: "/coupons" },
                { name: state.name, href: `/coupons/details/${id}` },
                { name: "Coupons List" },
              ]}
            />
          </>
        }
      >
        {state.isBusy ? (
          <Loader />
        ) : (
          <CustomTable
            defaultSearch={true}
            columns={columns}
            data={state?.barcodeViewList}
          />
        )}
      </ContentLayout>
    </>
  );
};
