import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import { useDropdownApis, usePermission, useTabs } from "../../../shared";
import {
  assignStoreToDiscount,
  createDiscount,
  deleteDiscountById,
  deleteStoreFromDiscount,
  editDiscount,
  getDiscount,
  getDiscountDetails,
  getStoreFromDiscount,
} from "../api/DiscountApi";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import qs from "qs";
import { TableStateContext } from "../../../store";

export const useDiscount = ({ isDiscount, discountId, isStore }) => {
  const navigate = useNavigate();
  const { appState } = useContext(AppContext);
  const { globalData } = appState;
  const { setCurentTab } = useTabs();
  const { discountGroupDetails: currentTab } = appState.currentTabs;
  const { storeList } = useDropdownApis({
    isStoreList: isDiscount || currentTab === "2" ? true : false,
  });
  const [state, setState] = useImmer({
    isBusy: false,
    discounts: [],
    isOpen: false,
    id: null,
    initialLoad: true,
    isSaveButtonBusy: false,
    display_name: "",
    httpStatusCodes: {
      details: "",
    },
    filters: {
      isSearching: false,
      all: [],
      searchText: "",
      stores: [],
      locked: "",
    },
    data: {
      display_name: "",
      discount_mode: "percentage",
      require_manager_passcode: 1,
      amount: "",
    },
    storeDetails: {
      isLoading: true,
      isSaveButton: false,
      isOpen: false,
      isDelete: false,
      id: null,
      storeList: [],
      allStores: [],
      filteredStores: [],
      selectedStores: [],
    },
  });
  const isDeletable = usePermission("discount-delete");
  const isCreateVisible = usePermission("discount-create");
  const isEditable = usePermission("discount-modify");
  const [debouncedText] = useDebounce(state.filters.searchText, 1000);
  const PAGE_NAME = "discount";
  const {
    filterState: {
      params: { discount },
    },
    initialState,
    filterStateDispatch,
  } = useContext(TableStateContext);


  useEffect(() => {
    if (isDiscount) {
      const {
        searchText,
        stores,
        locked,
      } = discount.filterApplied;
      setState((draft) => {
        draft.filters = discount.filterApplied;
      });
      getDiscounts(stores, searchText, locked);
    }
  }, [isDiscount]);

  const debounced = useDebouncedCallback((value) => {
    const { stores, locked } = state.filters;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filters },
    });
    getDiscounts({
      stores, value, locked
    });
  }, 1000);


  const getDiscounts = async (stores = [], searchText = "", locked = "") => {
    let IDs = stores.map((v) => {
      return v.value;
    });
    let params = {};

    if (searchText) {
      params = { ...params, search: searchText };
    }
    if (locked !== null && locked !== "") {
      params = { ...params, locked: locked.value };
    }
    if (IDs.length > 0) {
      params = { ...params, store_id: IDs };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });
      const res = await getDiscount(query);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.discounts = res.data;
          draft.initialLoad = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
      });
    }
  };

  const handleDiscountDetailsList = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getDiscountDetails(discountId);
      if (res.data) {
        setState((draft) => {
          draft.data = res.data;
          draft.display_name = res.data.display_name;
          draft.isBusy = false;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.httpStatusCodes.details = err.response.status;
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (discountId) {
      handleDiscountDetailsList();
    }
  }, [discountId]);

  const onUpdateDiscount = async (discountId) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await editDiscount(discountId, state.data);
      if (res.success) {
        successMessage("Updated Successfully");
        navigate(`/discount/details/${res.data.id}`);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onSaveState = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await createDiscount(state.data);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate("/discount");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const onChange = (e, type) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };
  const onDiscountMode = (e) => {
    setState((draft) => {
      draft.data.discount_mode = e;
    });
  };

  const handleModals = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isOpen = !draft.isOpen;
    });
  };
  const handleDeleteDiscount = async () => {
    try {
      const res = await deleteDiscountById(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");

        setState((draft) => {
          draft.discounts = draft.discounts.filter(
            (item) => item.id !== state.id
          );
        });
        handleModals();
        navigate("/discount");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onSwitchChange = (e) => {
    let value = e.target.checked === true ? "1" : "0";
    setState((draft) => {
      draft.data.require_manager_passcode = value;
    });
  };

  const getStoreFromDiscountList = async (discountId) => {
    setState((draft) => {
      draft.isBusy = true;
    });
    try {
      const res = await getStoreFromDiscount(discountId);
      if (res.success) {
        setState((draft) => {
          draft.storeDetails.storeList = res.data;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (discountId && currentTab === "2") {
      getStoreFromDiscountList(discountId);
    }
  }, [discountId, currentTab]);
  const HandleFilterStores = (type, text) => {
    const alreadyStores = state.storeDetails.storeList.map((val) => {
      return val.id;
    });

    const filteredStoreList = storeList?.filter(
      (val) => !alreadyStores.includes(val.id)
    );
    if (type === "initial") {
      setState((draft) => {
        draft.storeDetails.filteredStores = filteredStoreList;

        return draft;
      });
    } else {
      text = text.toLowerCase();
      setState((draft) => {
        draft.storeDetails.filteredStores = filteredStoreList.filter((val) => {
          return val.name.toString().toLowerCase().indexOf(text) > -1;
        });
        return draft;
      });
    }
  };
  useEffect(() => {
    HandleFilterStores("initial");
  }, [storeList, state.storeDetails.storeList]);

  const handleStoreSelect = (id) => {
    const isSelcted = state.storeDetails.selectedStores.includes(id);
    if (isSelcted) {
      setState((draft) => {
        draft.storeDetails.selectedStores =
          draft.storeDetails.selectedStores.filter((val) => val !== id);
      });
    } else {
      setState((draft) => {
        draft.storeDetails.selectedStores = [
          ...draft.storeDetails.selectedStores,
          id,
        ];
        return draft;
      });
    }
  };
  const handleAssignStoretoDiscount = async () => {
    const data = {
      store_id: state.storeDetails.selectedStores,
    };
    setState((draft) => {
      draft.storeDetails.isSaveButton = true;
    });
    try {
      const res = await assignStoreToDiscount(data, discountId);
      if (res.success === true) {
        successMessage(res.message);
        setState((draft) => {
          draft.storeDetails.storeList = [
            ...draft.storeDetails.storeList,
            ...draft.storeDetails.allStores.filter((val) =>
              draft.storeDetails.selectedStores.includes(val.id)
            ),
          ];
          draft.storeDetails.isLoading = false;
          draft.storeDetails.selectedStores = [];
          draft.storeDetails.isSaveButton = false;
          return draft;
        });
        handleModal(false);
        getStoreFromDiscountList(discountId);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.storeDetails.isSaveButton = false;
      });
    }
  };
  const handleModal = (e) => {
    setState((draft) => {
      draft.storeDetails.isOpen = e;
      draft.isAvailable = true;
      draft.storeDetails.selectedStores = [];
      return draft;
    });
    HandleFilterStores("initial");
  };
  const handleSelectAllStores = (checked) => {
    if (checked) {
      setState((draft) => {
        draft.storeDetails.selectedStores = [
          ...draft.storeDetails.filteredStores.map((item) => item.id),
        ];
      });
    } else {
      setState((draft) => {
        draft.storeDetails.selectedStores = [];
      });
    }
  };
  const handleDeleteStores = async (id) => {
    try {
      const res = await deleteStoreFromDiscount(
        discountId,
        state.storeDetails.id
      );
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.storeDetails.storeList = draft.storeDetails.storeList.filter(
            (item) => item.id !== state.storeDetails.id
          );
        });
        DeleteStoreModal(false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const DeleteStoreModal = (state, data) => {
    setState((draft) => {
      draft.storeDetails.isDelete = state;
      draft.storeDetails.id = data;
    });
  };
  const handleFilterClear = (type, value) => {
    const { stores, searchText, locked } = state.filters;
    if (type === "single") {
      if (value.type === "Store") {
        const filteredStores = state.filters.stores.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filters.stores = filteredStores;
        });
      } else if (value.type === "Status") {
        setState((draft) => {
          draft.filters.locked = "";
        });
      }
    } else {
      getDiscounts([], searchText, "");
      setState((draft) => {
        draft.filters.all = [];
        draft.filters.stores = [];
        draft.filters.locked = "";
      });

      const initialValue = {
        isSearching: false,
        all: [],
        searchText: "",
        stores: [],
        locked: "",
      };
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initialValue,
      });
    }
  };

  const handleFilter = (e, value, type) => {
    if (type === "store") {
      setState((draft) => {
        draft.filters.stores = value.map((v) => {
          return { ...v, type: "Store" };
        });
      });
    } else if (type === "locked") {
      setState((draft) => {
        draft.filters.locked = {
          value: value.props.value,
          label: value.props.children,
          type: "Status",
        };
      });
    }
  };

  useEffect(() => {
    setState((draft) => {
      draft.filters.all = [
        ...state.filters.stores,
        state.filters.locked,
      ].filter((el) => Object.keys(el).length);
    });
  }, [state.filters.stores, state.filters.locked]);

  const onChangeFilter = (e) => {
    debounced(e.target.value);
    setState((draft) => {
      draft.filters.searchText = e.target.value;
      draft.filters.isSearching = false;
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: { ...state.filters, searchText: e.target.value },
      },
    });
  };

  const handleApplyFilter = () => {
    const { stores, searchText, locked } = state.filters;
    getDiscounts(stores, searchText, locked);
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: { ...state.filters },
      },
    });
  };

  const onSeachClear = () => {
    setState((draft) => {
      draft.filters.searchText = "";
      draft.filters.isSearching = true;
    });
    const { stores, locked } = state.filters;
    getDiscounts(stores, "", locked);

    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: { ...state.filters, searchText: "" } },
    });
  };

  return {
    state,
    handleModal,
    onSaveState,
    onChange,
    onDiscountMode,
    onUpdateDiscount,
    handleDeleteDiscount,
    onSwitchChange,
    globalData,
    isDeletable,
    isEditable,
    isCreateVisible,
    setCurentTab,
    currentTab,
    storeList,
    handleAssignStoretoDiscount,
    handleStoreSelect,
    handleSelectAllStores,
    handleDeleteStores,
    DeleteStoreModal,
    HandleFilterStores,
    handleModals,
    handleFilterClear,
    onChangeFilter,
    onSeachClear,
    handleFilter,
    handleApplyFilter,
  };
};
