import React from "react";
import { Route, Routes } from "react-router-dom";
import { ReviewDetails, ReviewsAndRatingsList } from "./containers";
import { ProtectedRoute } from "../../shared";

export const ReviewsAndRatings = () => {
  return (
    <>
      <Routes>
        <Route
          element={<ProtectedRoute allowedRoute={["review-rating-view"]} />}
        >
          <Route path="/" element={<ReviewsAndRatingsList />} />
          <Route path="review/:id" element={<ReviewDetails />} />
          <Route path="pending-review/:id" element={<ReviewDetails />} />
        </Route>
      </Routes>
    </>
  );
};
