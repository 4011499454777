import { ServerPaginatedTable } from "../../../shared";
import * as React from "react";
import { Button, Chip, Stack, Typography } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { Link } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import { sortItems, sortStatus } from "../../../utils";
import { AsyncPaginate } from "react-select-async-paginate";
import SimpleReactValidator from "simple-react-validator";
import { useGiftCard } from "../hooks";

export const AllCards = (prop) => {
  const { appState } = React.useContext(AppContext);
  const { globalData } = appState;
  const [, forceUpdate] = React.useState();

  const {
    state,
    handleFilter,
    handleFilterClear,
    handleApplyFilter,
    onChangeFilter,
    onSeachClear,
    isEditable,
    gotoPage,
    setPageSize,
    getAllGiftCardGroupsList,
    handleSort,
  } = useGiftCard({
    isAllCards: true,
  });

  const ActionButtons = ({ row }) => {
    return (
      <div className="action-buttons">
        <Link to={`/gift-card/details/cards/${row.id}`}>
          <img alt="details" src="/icons/arrow_forward.svg" />
        </Link>
      </div>
    );
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: "2px line #000000",
      height: "48px",
      width: "237px",
    }),
    option: (provided, state) => ({
      ...provided,
      maxHeight: "239px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Barcode",
        accessor: "barcode",
      },
      {
        Header: "Card Number",
        accessor: "card_number",
      },
      {
        Header: "Batch Name",
        accessor: "batch_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Card Type",
        accessor: "card_type",
        Cell: ({ value }) => {
          return (
            <Stack direction="row">
              {value === "1" ? "Preloaded" : "Open card"}
            </Stack>
          );
        },
        sortInverted: true,
      },
      {
        Header: "Card Value",
        accessor: "card_value",
        Cell: ({ value }) => {
          return <Stack direction="row">${Number(value)?.toFixed(2)}</Stack>;
        },
      },
      {
        Header: "Balance Value",
        accessor: "balance_value",
        Cell: ({ value }) => {
          return <Stack direction="row">${Number(value)?.toFixed(2)}</Stack>;
        },
      },
      {
        Header: "Validity",
        accessor: "validity",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap={1}>
              <Typography fontWeight={400} fontSize="14px">
                {row?.original?.is_year_month === 0
                  ? row?.original?.validity / 365
                  : row?.original?.validity / 30}
              </Typography>
              <Typography fontWeight={400} fontSize="14px">
                {row?.original?.is_year_month === 0 ? "Year" : "Month"}
              </Typography>
            </Stack>
          );
        },
      },
      {
        Header: "Expires On",
        accessor: "expiry_date",
        Cell: ({ value }) => {
          return value === null ? "--" : value;
        },
      },
      {
        Header: "Card Status",
        accessor: "card_status",
        Cell: ({ value }) => {
          return (
            (value === 1 && (
              <Chip
                label={"Active"}
                color={"success"}
                variant="outlined"
                size="small"
                sx={{
                  border: "none !important",
                }}
              />
            )) ||
            (value === 0 && (
              <Chip
                label={"Inactive"}
                color="success"
                variant="outlined"
                size="small"
                disabled={"filled"}
              />
            )) ||
            (value === 3 && (
              <Chip
                label={"Redeemed"}
                color="primary"
                variant="outlined"
                size="small"
                sx={{ bgcolor: "#1560D414", border: "none !important" }}
                disabled={false}
              />
            )) ||
            (value === 4 && (
              <Chip
                label={"Blocked"}
                color="warning"
                variant="outlined"
                size="small"
                sx={{
                  border: "none !important",
                  bgcolor: "#FFF4CE",
                }}
              />
            )) ||
            (value === 2 && (
              <Chip
                label={"Expired"}
                color={"error"}
                variant="outlined"
                size="small"
                sx={{
                  border: "none !important",
                }}
              />
            ))
          );
        },
        sortType: (prev, curr, columnId) => {
          return sortStatus(
            globalData.gift_cards?.status.find(
              (e) => e.value === prev.original[columnId]
            ).label,
            globalData.gift_cards?.status.find(
              (e) => e.value === curr.original[columnId]
            ).label
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons row={row} />;
        },
      },
    ],
    [globalData]
  );
  return (
    <>
      <ServerPaginatedTable
        columns={columns}
        data={state?.allCards || []}
        key={state.sortBy}
        isLoading={state?.isBusy}
        defaultSearch={false}
        filterApplied={state?.filters?.all ?? []}
        onFilterClear={(e) => handleFilterClear("single", e)}
        onAllFilterClear={(e) => handleFilterClear("all")}
        gotoPage={gotoPage}
        setPageSize={setPageSize}
        pagination={state?.pagination}
        sortByGlobal={state.sortBy}
        handleSort={handleSort}
        // manualSortBy={false}
        filterContent={
          <>
            <InputControl
              type="table-search"
              value={state.filters.searchText}
              onChange={(e) => onChangeFilter(e)}
              onClear={onSeachClear}
            />
          </>
        }
        filterSection={
          <>
            <Stack direction="row" gap="20px" flexWrap="wrap">
              <AsyncPaginate
                debounceTimeout={1000}
                placeholder="Batch Name"
                className="AsyncPaginate-dropdown"
                menuPlacement="bottom"
                value={state?.filters?.batch_name}
                loadOptions={async (e, p, { page }) => {
                  return await getAllGiftCardGroupsList({
                    search: e,
                    page,
                  });
                }}
                onChange={(e) => {
                  handleFilter(
                    {
                      target: {
                        name: "batch_name",
                        value: e.value || null,
                      },
                    },
                    e,
                    "batch_name"
                  );
                }}
                additional={{
                  page: 1,
                }}
                styles={customStyles}
              />

              <InputControl
                type="select"
                label="Card Type"
                name="card_type"
                value={state.filters.card_type?.value ?? ""}
                optionValue={globalData?.gift_cards?.card_type ?? []}
                onChange={(e, v) => handleFilter(e, v, "card_type")}
                width="237px"
              />
              <InputControl
                type="datePicker"
                name="expired_on"
                label="Expires On"
                value={state.filters.expired_on?.value || null}
                onChange={(e, v) => handleFilter(e, v, "expired_on")}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                inputFormat="dd/MM/yyyy"
                sx={{
                  minWidth: "237px",
                }}
              />

              <InputControl
                label="Card Status"
                type="select"
                name="status"
                value={state.filters.status?.value ?? ""}
                optionValue={globalData?.gift_cards?.status ?? []}
                onChange={(e, v) => handleFilter(e, v, "status")}
                width="237px"
              />
            </Stack>
            <Button
              type="button"
              size="medium"
              variant="text"
              color="info"
              disabled={state.disableApplyButton}
              sx={{
                minWidth: "40px",
                p: "8px",
              }}
              onClick={() => handleApplyFilter()}
            >
              Apply
            </Button>
          </>
        }
        onRowLink={(e) => {
          return `/gift-card/details/cards/${e.id}`;
        }}
      />
    </>
  );
};
