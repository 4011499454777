import { API } from "../../../utils";

export const getCoupons = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `coupons?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};
export const deleteCouponsById = async (id) => {
  const res = await API.delete(`coupons/${id}`);
  return res.data;
};
export const duplicateCoupons = async (id) => {
  const res = await API.post(`coupons/${id}/duplicate`);
  return res.data;
};
export const createCoupons = async (payload) => {
  const res = await API.post(`coupons`, payload);
  return res.data;
};
export const getCouponsDetailsById = async (id) => {
  const res = await API.get(`coupons/${id}`);
  return res.data;
};
export const assignStoreToCoupons = async (payload, id) => {
  const res = await API.post(`coupons/${id}/stores`, payload);
  return res.data;
};
export const editCoupons = async (id, payload) => {
  const res = await API.post(`coupons/${id}`, payload);
  return res.data;
};
export const getCondition = async () => {
  const res = await API.get("rule-sets");
  return res.data;
};
export const deleteStoreFromCoupons = async (CouponsId, storeId) => {
  const res = await API.delete(`coupons/${CouponsId}/stores/${storeId}`);
  return res.data;
};
export const playpauseCoupons = async (id) => {
  const res = await API.post(`coupons/${id}/play-pause`);
  return res.data;
};
export const statuschangeCoupons = async (id) => {
  const res = await API.post(`coupons/${id}/change-status/${2}`);
  return res.data;
};
export const getUsageHistory = async (query, id) => {
  const res = await API.get(`coupons/${id}/history`, query);
  return res.data;
};

export const downloadCoupons = async (id) => {
  const res = await API.get(`coupons/${id}/download`, {
    responseType: "blob",
  });
  return res;
};
export const getBarcodeView = async (id) => {
  const res = await API.get(`coupons/${id}/barcode_list`);
  return res.data;
};
