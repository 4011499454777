import { API } from "../../../utils";

export const getBumpScreens = async (query) => {
  const res = await API.get(`bump_screens`, query);
  return res.data;
};
export const createStoreBumpScreen = async (payload) => {
  const res = await API.post(`bump_screens`, payload);
  return res.data;
};
export const updateStoreBumpScreen = async (id, payload) => {
  const res = await API.put(`bump_screens/${id}`, payload);
  return res.data;
};
export const getBumpScreenDetails = async (id) => {
  const res = await API.get(`bump_screens/${id}`);
  return res.data;
};
export const deleteBumpScreenById = async (id) => {
  const res = await API.delete(`bump_screens/${id}`);
  return res.data;
};
export const UnpairBumpScreen = async (id) => {
  const res = await API.put(`bump_screens/${id}/remove`);
  return res.data;
};
export const getBumpScreenTags = async (data) => {
  const res = await API.get(`tags?type=bump_screen`);
  return res.data;
};
