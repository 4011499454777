import React from "react";
import { Route, Routes } from "react-router-dom";
import { EmailSettingsDetails, EmailSettingsList } from "./containers";
import { ProtectedRoute } from "../../shared";

export const EmailSettings = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<EmailSettingsList />} />
                <Route element={<ProtectedRoute allowedRoute={["email-setting-modify"]} />}>
                    <Route path="/details/:id" element={<EmailSettingsDetails />} />
                </Route>
            </Routes>
        </>
    );
};
