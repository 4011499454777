import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { errorMessage } from "../../../utils";
import { DownloadReportList, DownloadReportTableList } from "../api";
import { AppContext } from "../../../store/AppScopeProvider";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../../store/AuthProvider";
var fileDownload = require("js-file-download");

export const useReports = () => {
  const { appState } = useContext(AppContext);
  const { auth } = useContext(AuthContext);
  const { selectedStoreId } = auth.data;
  const { globalData } = appState;
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const [state, setState] = useImmer({
    isLoading: false,
    dataList: [],
    generateData: [],
    columnType: "",
    title: "",
    filters: {
      type: "",
      start_date: null,
      end_date: null,
      store_id: [],
      day: [],
    },
  });

  const onChangeFilter = (e, v) => {
    if (["start_date", "end_date"]?.includes(v)) {
      setState((draft) => {
        draft.filters[v] = e;
      });
    } else {
      const { name, value } = e.target;
      if (value === 9) {
        setState((draft) => {
          draft.filters[name] = value;
          draft.filters.start_date = moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD");
          draft.filters.end_date = moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD");
        });
      } else {
        setState((draft) => {
          draft.filters[name] = value;
        });
      }
    }
  };
  const onClearFilter = (e) => {
    setState((draft) => {
      draft.filters.day = [];
      draft.filters.end_date = null;
      draft.filters.start_date = null;
      draft.filters.store_id = [];
      draft.filters.type = "";
    });
  };

  const onDeleteStoreSelect = (data) => {
    setState((draft) => {
      draft.filters.store_id = [
        ...state.filters.store_id.filter((value) => {
          return value !== data;
        }),
      ];
    });
  };
  const onDeleteDaySelect = (data) => {
    setState((draft) => {
      draft.filters.day = [
        ...state.filters.day.filter((value) => {
          return value !== data;
        }),
      ];
    });
  };
  const handleGenerateReport = async () => {
    try {
      setState((draft) => {
        draft.isLoading = true;
      });
      const { type, day, start_date, end_date, store_id } = state.filters;

      let IDs = store_id.map((v) => {
        return v.value;
      });
      let days = day?.map((v) => {
        return v.value;
      });
      let params = {};

      if (type) {
        params = { ...params, type: type };
      }
      if (day?.length > 0) {
        params = { ...params, day: days };
      }
      if (start_date) {
        params = {
          ...params,
          start_date: moment(start_date).format("YYYY-MM-DD"),
        };
      }
      if (end_date) {
        params = {
          ...params,
          end_date: moment(end_date).format("YYYY-MM-DD"),
        };
      }
      if (isStoreAdmin) {
        params = { ...params, store_id: [selectedStoreId] };
      } else if (store_id.length > 0) {
        params = { ...params, store_id: IDs };
      }

      const res = await DownloadReportList(params);
      setState((draft) => {
        draft.generateData = res.data;
        draft.columnType = state?.filters?.type;
        draft.isLoading = false;
      });
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };
  useEffect(() => {
    if (state.filters.type === 4) {
      setState((draft) => {
        draft.filters.day = [];
      });
    }
  }, [state.filters.type]);

  const handleExportTableList = async () => {
    try {
      const { type, day, start_date, end_date, store_id } = state.filters;

      let IDs = store_id.map((v) => {
        return v.value;
      });
      let params = {};

      if (type) {
        params = { ...params, type: type };
      }
      if (day !== null) {
        params = { ...params, day: day };
      }
      if (start_date) {
        params = {
          ...params,
          start_date: moment(start_date).format("YYYY-MM-DD"),
        };
      }
      if (end_date) {
        params = {
          ...params,
          end_date: moment(end_date).format("YYYY-MM-DD"),
        };
      }
      if (isStoreAdmin) {
        params = { ...params, store_id: [selectedStoreId] };
      } else if (store_id.length > 0) {
        params = { ...params, store_id: IDs };
      }
      const res = await DownloadReportTableList(params);
      const content = res.headers["content-type"];
      fileDownload(res.data, `ReportList.xlsx`, content);
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  return {
    state,
    globalData,
    isStoreAdmin,
    onChangeFilter,
    onClearFilter,
    handleGenerateReport,
    onDeleteStoreSelect,
    handleExportTableList,
    onDeleteDaySelect,
  };
};
