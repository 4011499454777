import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import {
  MenuTemplateDetailsList,
  MenuTemplateList,
  MenuTemplateProductList,
} from "./containers";

export const MenuTemplate = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<MenuTemplateList />} />

        <Route element={<ProtectedRoute allowedRoute={["discount-view"]} />}>
          <Route path="/details/:id" element={<MenuTemplateDetailsList />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["discount-view"]} />}>
          <Route
            path="/details/:id/menu_name/:cid"
            element={<MenuTemplateProductList />}
          />
        </Route>
      </Routes>
    </>
  );
};
