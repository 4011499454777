import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { errorMessage, successMessage } from "../../../utils";
import { getSettingsDetails, updateSettings } from "../api";
import { getAllUsers } from "../../EmailSettings/api";
import qs from "qs";
import { useProductSort } from "../../ProductSorting";
import { AppContext } from "../../../store";
import { useLocation } from "react-router-dom";

export const useSettings = (storeId = null) => {
  const { state: stateTemplates } = useProductSort({ isList: true });
  const { pathname } = useLocation();
  const { appState } = useContext(AppContext);
  const isStoreAdmin = pathname.includes("store-admin");

  const { globalData } = appState;
  const [state, setState] = useImmer({
    isSaveButton: true,
    isLoading: true,
    userList: [],
    details: {
      variance_status: 0,
      eod_variance: null,
      auto_log_status: 0,
      auto_log_time: null,
      email: [],
      float_amount: null,
      keypad_id: "",
      delivery: 0,
      delivery_provider: "",
      delivery_radius: "",
      pre_order_days: "",
      eod_template: "",
      cart_product_count_limit: "",
      cart_product_waiting_time_limit: "",
    },
    input: "",
  });

  useEffect(() => {
    if (storeId && state.isLoading) {
      HandleSettingsDetails(storeId);
    }
  }, [storeId]);

  const HandleSettingsDetails = async (storeId) => {
    try {
      const res = await getSettingsDetails(storeId);
      if (res.success) {
        setState((draft) => {
          draft.details = res.data;
          draft.isLoading = false;
        });
      }
    } catch (error) {
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const HandleInputChange = (event) => {
    const { value, name } = event.target;
    setState((draft) => {
      draft.details[name] = value;
      draft.isSaveButton = false;
    });
  };

  const onUpdateSettings = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButton = true;
      });
      const payload = {
        ...state.details,
        email: state.details.email
          .map((v) => {
            if (v.id === null) return v.email;
          })
          .filter(Boolean),
        user_id: state.details.email.map((v) => v.id).filter(Boolean),
      };
      if (!payload.auto_log_status && !payload.auto_log_time) {
        delete payload.auto_log_time;
      }
      if (!payload.variance_status && !payload.eod_variance) {
        delete payload.eod_variance;
      }
      if (isStoreAdmin) {
        delete payload.delivery;
        delete payload.delivery_provider;
        delete payload.delivery_radius;
        delete payload.pre_order_days;
      }
      const res = await updateSettings(storeId, payload);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButton = false;
        });
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButton = false;
      });
    }
  };

  const handleAddMaIL = (input) => {
    const data = [
      ...state.details.email,
      {
        email: input,
        name: null,
        id: null,
      },
    ];
    setState((draft) => {
      draft.details.email = data;
      draft.isSaveButton = false;
    });
  };

  const handleDeleteMaIL = (email) => {
    setState((draft) => {
      draft.details.email = [
        ...draft.details.email.filter((data) => {
          return data.email !== email;
        }),
      ];
      draft.isSaveButton = false;
    });
  };

  const handleGetUserList = async ({ search = null }) => {
    let params = {};

    if (search) {
      params = { ...params, search };
    }
    const query = {
      params,
      headers: { "X-STORE": storeId },
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      const res = await getAllUsers(query);
      if (res.success) {
        setState((draft) => {
          draft.userList = res.data.map((data) => {
            return { value: data.id, label: data.name };
          });
          draft.isBusy = false;
        });
        const selected = state.details.email.map((v) => v.id).filter(Boolean);
        const filteredData = res.data.filter((v) => !selected.includes(v.id));
        return {
          options: filteredData.map((data) => {
            return {
              value: data.id,
              id: data.id,
              name: data.name,
              email: data.email,
            };
          }),
        };
      }
    } catch (err) {
      return {
        options: [],
      };
    }
  };
  const handleOnChange = (e, name) => {
    setState((draft) => {
      draft.details[name] = e?.value;
      draft.isSaveButton = false;
    });
  };

  return {
    state,
    globalData,
    HandleInputChange,
    onUpdateSettings,
    handleAddMaIL,
    handleDeleteMaIL,
    handleGetUserList,
    stateTemplates,
    handleOnChange,
  };
};
