import {
  ContentLayout,
  DeleteModal,
  ServerPaginatedTable,
} from "../../../shared";
import * as React from "react";
import { Box, Button, Chip, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import InputControl from "../../../shared/components/InputControl";
import { useCustomers } from "../hooks";
import { styled } from "@mui/material/styles";
import optInPic from "../../../assets/images/Icons/ic_tick_filled_blue.svg";
import optOutPic from "../../../assets/images/Icons/ic_cancel-opt-out.svg";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  padding: "6px 12px",
  border: "2px",
  lineHeight: 1.5,
  backgroundColor: "#DFF0EA",
  width: "65px",
  height: "25px",
  "&:hover": {
    backgroundColor: "#bccfbf",
    boxShadow: "none",
  },
});

const BootstrapButton1 = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  padding: "6px 12px",
  border: "2px",
  lineHeight: 1.5,
  backgroundColor: "#E6E6E6",
  width: "65px",
  height: "25px",
  "&:hover": {
    backgroundColor: "#D3D3D3",
    boxShadow: "none",
  },
});
export const CustomersList = () => {
  const navigate = useNavigate();
  const {
    state,
    toggleModal,
    handleDeleteCustomer,
    onChangeFilter,
    resetFilters,
    onSeachClear,
    isDeletable,
    isCreateVisible,
    isEditable,
    globalData,
    gotoPage,
    setPageSize,
    handleDownloadCustomersList,
    setCurentTab,
    handleSort,
    columnOptions,
    toggleHiddenColumns,
    HandleDeletedCustomersRequest,
    handleCustomerDeleteRequest,
  } = useCustomers({
    load: true,
  });
  const { customers, isBusy, isOpen, filters, pagination, sortBy } = state;
  const [hasColumnOptions, setHasColumnOptions] = React.useState(false);
  const toggleColumns = () => {
    setHasColumnOptions(!hasColumnOptions);
  };
  const ActionButtons = ({ id, deleteAction, currentTabAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {state.deletedCustomers === 1 && (
          <>
            <BootstrapButton1
              variant="contained"
              disableRipple
              onClick={() => handleCustomerDeleteRequest(id, "cancel")}
            >
              <Typography
                color="#414251"
                fontWeight={600}
                lineHeight="16px"
                fontSize="13px"
              >
                Cancel
              </Typography>
            </BootstrapButton1>
            <BootstrapButton
              variant="contained"
              disableRipple
              onClick={() => handleCustomerDeleteRequest(id, "confirm")}
            >
              <Typography
                color="#238F3B"
                fontWeight={600}
                lineHeight="16px"
                fontSize="13px"
              >
                Approve
              </Typography>
            </BootstrapButton>
          </>
        )}
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}

        <img
          alt="details"
          onClick={currentTabAction}
          src="/icons/arrow_forward.svg"
        />
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        id: "first_name",
        Cell: ({ value, row }) => {
          return (
            <div className="table-taxt-verified">
              {(row.original.email_verified_at ||
                row.original.phone_verified_at) && (
                <img
                  style={{ height: "14px", marginTop: "2px" }}
                  alt="verify"
                  src="/icons/ic_verified_badge.svg"
                />
              )}
              <span className="link-text">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone Number",
        accessor: "phone_formatted",
        id: "phone",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Typography variant="body2">
              {row.original.phone_formatted}
            </Typography>
          );
        },
      },
      {
        Header: "Date Of Birth",
        accessor: "date_of_birth",
        color: "rgba(0, 0, 0, 0.03)",
      },
      {
        Header: "Gender",
        id: "gender",
        accessor: (row) => {
          return globalData?.gender?.find((item) => item.value === row.gender)
            ?.label;
        },
      },
      {
        Header: "ATV",
        accessor: "ATV",
      },
      {
        Header: "LTV",
        accessor: "LTV",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return value === 1 ? (
            <Chip
              label="Active"
              color="success"
              variant="outlined"
              size="small"
            />
          ) : (
            <Chip
              label="Inactive"
              color="success"
              variant="outlined"
              size="small"
              disabled
            />
          );
        },
      },
      {
        Header: "Opt in",
        accessor: "opt_in",
        Cell: ({ value }) => (
          <Box
            component="img"
            src={value === 1 ? optInPic : optOutPic}
            alt={value === 1 ? "opt-in-icon" : "opt-out-icon"}
          />
        ),
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              currentTabAction={() => {
                setCurentTab("customers", "1");
                navigate(`/customers/details/${row.id}`);
              }}
              deleteAction={() => {
                toggleModal(row.id);
              }}
            />
          );
        },
      },
    ],
    [globalData, state.deletedCustomers]
  );

  return (
    <>
      <ContentLayout
        title="Customers"
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="text"
              onClick={handleDownloadCustomersList}
            >
              <img
                alt="export"
                src="/icons/ic_export.svg"
                style={{
                  marginRight: "4px",
                }}
              />
              Export
            </Button>
            <Button
              type="button"
              size="medium"
              variant="text"
              onClick={() => toggleColumns()}
            >
              <img
                alt="tools"
                src="/icons/ic_Tools.svg"
                style={{
                  marginRight: "4px",
                }}
              />
              Column Option
            </Button>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("create")}
              >
                New Customer
              </Button>
            )}
          </>
        }
      >
        <ServerPaginatedTable
          columns={columns}
          data={customers || []}
          key={sortBy}
          isLoading={isBusy}
          defaultSearch={false}
          drawerOpen={hasColumnOptions}
          toggleHiddenManual={toggleHiddenColumns}
          columnOptions={columnOptions}
          drawerOnClose={() => toggleColumns()}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
          pagination={pagination}
          sortByGlobal={sortBy}
          handleSort={handleSort}
          filterContent={
            <>
              <InputControl
                type="table-search"
                name="searchText"
                placeholder="Search"
                size="small"
                value={filters.searchText}
                onChange={(e) => onChangeFilter(e)}
                onClear={onSeachClear}
              />
              <InputControl
                type="select"
                name="gender"
                label="Gender"
                optionValue={globalData.gender ?? []}
                width="300px"
                value={filters.gender}
                onChange={(e) => onChangeFilter(e)}
              />
              <InputControl
                type="select"
                name="status"
                label="Status"
                optionValue={globalData.status ?? []}
                width="250px"
                onChange={(e) => onChangeFilter(e)}
                value={filters.status}
              />
              <Typography minWidth="130px" fontWeight={400} fontSize="16px">
                Delete requests
              </Typography>
              <InputControl
                type="switch"
                checked={state?.deletedCustomers === 1 ? true : false}
                onChange={() => {
                  HandleDeletedCustomersRequest(
                    state?.deletedCustomers === 1 ? 0 : 1
                  );
                }}
              />
              <Button
                type="button"
                size="medium"
                variant="text"
                color="gray"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => resetFilters()}
              >
                <CloseIcon />
              </Button>
            </>
          }
          onRowLink={(e) => {
            return `/customers/details/${e.id}`;
          }}
          onRowAction={(e) => {
            setCurentTab("customers", "1");
          }}
        />
      </ContentLayout>

      <DeleteModal
        open={isOpen}
        handleClose={() => toggleModal()}
        onConfirm={() => handleDeleteCustomer()}
      />
    </>
  );
};
