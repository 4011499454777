import {
  ContentLayout,
  DeleteModal,
  HeaderBreadcrumbs,
  Loader,
  PageNotFound,
} from "../../../shared";
import * as React from "react";
import { Box, Tab, Button } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useParams } from "react-router-dom";

import { useDiscount } from "../hooks";
import { DiscountBasicDetails, DiscountStoresDetails } from "../components";
import { LedgerAccountsList } from "../../Configurations/components";

export const DiscountDetailsList = () => {
  const { id } = useParams();
  const {
    state,
    currentTab,
    setCurentTab,
    isDeletable,
    isEditable,
    storeList,
    HandleFilterStores,
    handleStoreSelect,
    handleAssignStoretoDiscount,
    handleModal,
    DeleteStoreModal,
    handleDeleteStores,
    handleSelectAllStores,
    handleModals,
    handleDeleteDiscount,
  } = useDiscount({
    isDiscount: false,
    discountId: id,
  });
  const { data } = state;
  const handleChange = (event, newValue) => {
    setCurentTab("discountGroupDetails", newValue);
  };

  return (
    <>
      <ContentLayout
        title={data.display_name}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Discount", href: "/discount" },
                { name: data.display_name, href: `/discount/details/${id}` },
              ]}
            />
          </>
        }
        rightContent={
          <>
            {isDeletable && (
              <Button
                type="button"
                size="medium"
                variant="text"
                className="delte"
                onClick={() => handleModals(id, "detaials")}
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
              >
                <img alt="delete" src="/icons/ic_delete_blue.svg" />
              </Button>
            )}
          </>
        }
      >
        <TabContext value={currentTab}>
          <Box
            className="sticky-Tab"
            sx={{ borderBottom: 1, borderColor: "divider", top: "104px" }}
          >
            <TabList onChange={handleChange}>
              <Tab label="Discount Details" value="1" />
              <Tab label="Stores" value="2" />
              <Tab label="Accounts" value="3" />
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            {state.isBusy ? (
              <Loader />
            ) : (
              <DiscountBasicDetails state={state} isEditable={isEditable} />
            )}
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            {state.isBusy ? (
              <Loader />
            ) : (
              <DiscountStoresDetails
                state={state}
                states={storeList}
                HandleFilterStores={HandleFilterStores}
                handleStoreSelect={handleStoreSelect}
                handleAssignStoretoDiscount={handleAssignStoretoDiscount}
                handleModal={handleModal}
                DeleteStoreModal={DeleteStoreModal}
                handleDeleteStores={handleDeleteStores}
                setCurentTab={setCurentTab}
                handleSelectAll={handleSelectAllStores}
              />
            )}
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="3">
            <LedgerAccountsList type="discount" />
          </TabPanel>
        </TabContext>
      </ContentLayout>
      <DeleteModal
        open={state.isOpen}
        handleClose={() => handleModals()}
        onConfirm={() => handleDeleteDiscount()}
      />
    </>
  );
};
