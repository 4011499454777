import {
  ContentLayout,
  CustomeDialog,
  HeaderBreadcrumbs,
} from "../../../shared";
import React from "react";
import {
  Button,
  Stack,
  Card,
  Typography,
  Grid,
  TextField,
  Divider,
  Box,
  Switch,
  FormHelperText,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useLocation, useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useClientAccount } from "../hooks";
import { TransactionDialog } from "../components";

export const ClientAccountSettlement = () => {
  const { id } = useParams();
  const {
    state,
    handleInputChange,
    onMakeSettlement,
    globalData,
    onSwitchChange,
    toggleViewModal,
    handleChecked,
    handleCheckindeterminate,
    handleSelectedIds,
    handleAllChecked,
    handleAllSelectedIds,
  } = useClientAccount({
    settlementId: id,
  });
  const location = useLocation();
  const { isSaveButtonBusy, data } = state.settlement;

  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        amountValidation: {
          message: "The purchased amount is required",
          rule: (value) => {
            if (value <= 0) {
              return false;
            }
          },
        },
      },
    })
  );
  const onSubmit = () => {
    if (validator.current.allValid()) {
      onMakeSettlement(id);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const paymentTypes = globalData?.payment_types?.filter((v) => {
    return v.value !== 105 && v.value !== 103 && v.value !== 104;
  });
  return (
    <>
      <ContentLayout
        title={"Make Settlement"}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Client Accounts", href: "/store-admin/client-account" },
              {
                name: location.state ?? "",
                href: `/store-admin/client-account/details/${id}`,
              },
              { name: "Make Settlement" },
            ]}
          />
        }
      >
        <Card sx={{ p: "32px 24px", mb: "24px" }}>
          {validator.current.purgeFields()}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Settlement
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack width={"100%"} gap="20px">
                <InputControl
                  type="datePicker"
                  name="settlement_date"
                  label="Statement Date"
                  inputFormat="dd-MM-yyyy"
                  disableFuture
                  required
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  value={data.settlement_date}
                  onChange={(e) =>
                    handleInputChange({
                      target: {
                        name: "settlement_date",
                        value: e,
                      },
                    })
                  }
                  sx={{
                    width: "100%",
                  }}
                  error={validator.current.message(
                    "Date",
                    data.settlement_date,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "Date",
                    data.settlement_date,
                    "required"
                  )}
                />
                <Stack direction="row" gap="20px">
                  <InputControl
                    type="dropdown"
                    name="payment_type_id"
                    disableClearable
                    options={paymentTypes}
                    getOptionLabel={(option) => option?.label}
                    sx={{
                      width: "100%",
                    }}
                    onChange={(e, newValue) => {
                      handleInputChange({
                        target: {
                          name: "payment_type_id",
                          value: newValue.value,
                        },
                      });
                    }}
                    value={
                      (paymentTypes &&
                        data.payment_type_id &&
                        paymentTypes?.find(
                          (c) => c.value === data.payment_type_id
                        )) ||
                      null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Payment Media"
                        error={validator.current.message(
                          "Payment Media",
                          data.payment_type_id,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Payment Media",
                          data.payment_type_id,
                          "required"
                        )}
                        required
                      />
                    )}
                  />
                  <InputControl
                    name="reference_id"
                    label="Reference ID"
                    onChange={handleInputChange}
                    value={data.reference_id}
                  />
                </Stack>
                <InputControl
                  type="textarea"
                  name="notes"
                  label="Note"
                  rows={4}
                  onChange={handleInputChange}
                  value={data.notes}
                />
              </Stack>
            </Grid>
          </Grid>
          <Box marginTop={3}>
            <Divider />
          </Box>
          <Grid container spacing={2} mt={3}>
            <Grid item xs={12} sm={4}>
              <Stack direction="column">
                <Typography>Total Due Amount</Typography>
                <Typography
                  color="#C07302"
                  fontWeight={600}
                  fontSize="17px"
                  lineHeight="22px"
                >
                  ${state?.amount}
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={8}>
              <Stack width={"100%"} gap="20px">
                <Stack direction="row" justifyContent="space-between" py="10px">
                  <Typography>
                    Do you want to select transactions instead?
                  </Typography>
                  <Switch
                    defaultChecked={
                      state.settlement.data.select_type ? true : false
                    }
                    checked={state.settlement.data.select_type}
                    onChange={(loading) => onSwitchChange(loading)}
                  />
                </Stack>
                {state.settlement.data.select_type === 1 ? (
                  <Stack direction="row" justifyContent="space-between">
                    <Stack>
                      <Stack direction="row" gap={1}>
                        <Typography>Selected Amount:</Typography>
                        <Typography fontWeight={600} fontSize="17px">
                          ${state?.purchaseAmount?.toFixed(2)}
                        </Typography>
                      </Stack>
                      <FormHelperText sx={{ color: "#C60808" }}>
                        {state?.purchaseAmount <= 0
                          ? validator.current.message(
                              "purchased amount",
                              state?.purchaseAmount,
                              "amountValidation"
                            )
                          : ""}
                      </FormHelperText>
                    </Stack>

                    <Button
                      variant="text"
                      onClick={() => toggleViewModal(true)}
                    >
                      Select Transactions
                    </Button>
                  </Stack>
                ) : (
                  <InputControl
                    type="number"
                    name="amount"
                    label="Amount"
                    onChange={handleInputChange}
                    value={data?.amount}
                    required
                    error={validator.current.message(
                      "Amount",
                      data.amount,
                      "required|numeric|min:0,num"
                    )}
                    helperText={validator.current.message(
                      "Amount",
                      data.amount,
                      "required|numeric|min:0,num"
                    )}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
          <Box marginTop={3}>
            <Divider />
          </Box>
          <Stack mt={3} alignContent="center" alignItems="end">
            <Button
              type="button"
              sx={{ width: 25 }}
              variant="contained"
              disabled={isSaveButtonBusy}
              onClick={() => onSubmit()}
            >
              Settle
            </Button>
          </Stack>
        </Card>
      </ContentLayout>
      <CustomeDialog
        open={state.isViewOpen}
        handleClose={() => toggleViewModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
        PaperProps={{
          sx: {
            p: "12px",
            overflow: "hidden",
            height: "550px",
          },
        }}
        title={`Select Transactions(${state.filterData.length}/${state?.purchasedData.length})`}
        content={
          <TransactionDialog
            state={state.settlement}
            data={state?.purchasedData}
            item={state}
            handleChecked={handleChecked}
            handleCheckindeterminate={handleCheckindeterminate}
            handleSelectedIds={handleSelectedIds}
            handleAllChecked={handleAllChecked}
            handleAllSelectedIds={handleAllSelectedIds}
            toggleViewModal={toggleViewModal}
          />
        }
      />
    </>
  );
};
