import React from "react";
import { TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import { useTabs } from "../hooks";

/**
 * Functional component that renders a list of tabs using TabList from MUI.
 *
 * @param {Array} list - Array of tab objects with label, value, and panel.
 * @param {string} label - Label for the tab list.
 * @returns {JSX.Element} TabList component.
 */
const TabListComponent = ({ list, label }) => {
  // Custom hook for managing tabs
  const { setCurentTab: setCurrentTab } = useTabs();

  // Function to handle tab change
  const handleTabChange = (_, value) => {
    setCurrentTab(label, value);
  };

  return (
    <TabList onChange={handleTabChange}>
      {list.map((tab) => {
        
        if (!tab.hidden) {
          return <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            sx={{
              alignItems: "start",
              p: "0px 8px 0px 0px",
              m: 0,
              mr: 1,
              minWidth: "auto",
            }}
          />
        }
      }
      )}
    </TabList>
  );
};

export default TabListComponent;
