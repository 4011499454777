import React from "react";
import { useImmer } from "use-immer";
import { errorMessage, successMessage } from "../../../utils";
import {
  updateTitleTextApi,
  updateCartProductsApi,
  removeFromCartProductApi,
  getCartProductsApi,
  getProductCategoryListApi,
  updateCartStatusApi,
} from "../api";

export const useCartProductList = () => {
  const [state, setState] = useImmer({
    isBusy: false,
    title: "",
    status: false,
    searchVal: "",
    isOpenDeleteDialog: false,
    productIdToDelete: null,
    selectedItems: [],
    cartProducts: [],
    productCategories: [],
    filteredProductsCategory: [],
    existedProductList: [],
    dialogOpen: false,
    isEditMode: false,
    isModalContentLoad: true,
  });

  // Breadcrumbs
  const breadcrumbsLink = [
    { name: "Dashboard", href: "/" },
    { name: "Cart Product", href: "/cart-product" },
  ];

  // Table columns
  const columns = React.useMemo(
    () => [
      {
        Header: "Product",
        accessor: "name",
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        accessor: (row) => (
          <ActionButtons
            onClick={(e) => {
              e.preventDefault();
              setState((draft) => {
                draft.productIdToDelete = row.id;
              });
              handleToggleDeleteModal({ confirm: false });
            }}
          />
        ),
      },
    ],
    []
  );

  // ========================================== Title and status utility functions =============================================

  // Handle update cartProducts, title and loading
  const updateState = ({ loading, title = "", data = [], status = false }) => {
    setState((draft) => {
      draft.cartProducts = data;
      draft.isBusy = loading;
      draft.title = title;
      draft.status = status;
    });
  };

  // Handle title updates
  const handleTitleUpdatesMode = (isSave = false) => {
    // If title saved then update the value on database
    if (isSave) {
      (async () => await handleUpdateTitle())();
    }
    setState((draft) => {
      draft.isEditMode = !draft.isEditMode;
    });
  };

  // Handle title changes
  const handleTitleChanges = (event) => {
    setState((draft) => {
      draft.title = event.target.value;
    });
  };

  // Save title changes
  const handleUpdateTitle = async () => {
    try {
      const response = await updateTitleTextApi(state.title);
      const { message } = response.data;

      // Enable after change the api structure
      // if (response.status) return successMessage(message);
      // else errorMessage(message)

      // Remove after change the api structure
      if (response.status === 200) return successMessage(message);
      else errorMessage(message);
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };

  // Update status
  const updateCartStatus = async () => {
    try {
      const response = await updateCartStatusApi(state.status);
      const { message } = response.data;

      // Enable after change the api structure
      // if (response.status) return successMessage(message);
      // else errorMessage(message)

      // Remove after change the api structure
      if (response.status === 200) {
        return successMessage(message);
      } else {
        setState((draft) => {
          draft.status = !draft.checked;
        });
        errorMessage(message);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };

  // handle cart status
  const handleToggleStatus = async (event) => {
    const { checked } = event.target;
    setState((draft) => {
      draft.status = checked;
    });
    await updateCartStatus();
  };

  // ========================================== Product utility functions =============================================

  // Action buttons component
  const ActionButtons = ({ onClick }) => (
    <div className="action-buttons" onClick={onClick}>
      <img alt="delete" src="/icons/ic_delete.svg" />
    </div>
  );

  // Handle fetch cart products
  const getCartProducts = async () => {
    try {
      // Enable loading
      updateState({ loading: true });

      // Fetch product list
      const response = await getCartProductsApi();

      // If response is success
      if (response.success) {
        const { products, title, status } = response?.data;
        updateState({ loading: false, data: products, title, status });
      }
      // If response is failure
      else {
        updateState({ busy: false });
        errorMessage(response.message);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      updateState({ loading: false });
    }
  };

  // Handle fetch product-categories
  const getProductCategories = async () => {
    try {
      // Enable content loading
      setState((draft) => {
        draft.isModalContentLoad = true;
      });

      // Fetch product category list
      const response = await getProductCategoryListApi(state.searchVal);

      // If response is success
      if (response.success) {
        setState((draft) => {
          draft.productCategories = response.data;
          draft.filteredProductsCategory = response.data;
        });
      } else {
        errorMessage(response.message);
      }

      // Disable content loading
      setState((draft) => {
        draft.isModalContentLoad = false;
      });
    } catch (error) {
      //Disable content loading
      setState((draft) => {
        draft.isModalContentLoad = false;
      });

      errorMessage(error.response.data.message);
    }
  };

  // Handle Add product dialog
  const handleProductDialog = async () => {
    if (!state.dialogOpen === true) {
      // Trigger category fetch api call
      getProductCategories();
    } else {
      // Clear the selectedItems while closing the dialog
      setState((draft) => {
        draft.selectedItems = [];
      });
    }

    setState((draft) => {
      draft.dialogOpen = !draft.dialogOpen;
    });
  };

  // Handle Category selection
  const handleCategorySelect = (type, item, _) => {
    // Declare global variable for storing updated selectedItems
    let updatedSelectedItems;

    if (type === "head") {
      // Construct productId array
      const productIds = item.products.map((p) => p.id);

      // Filter selected-item ids from selected list
      const alreadySelectedValues = state.selectedItems.filter((val) =>
        productIds.includes(val)
      );

      // Updated selected values
      updatedSelectedItems =
        alreadySelectedValues.length > 0
          ? state.selectedItems.filter(
              (val) => !alreadySelectedValues.includes(val)
            ) // Remove alreadySelected values from selectedItems
          : state.selectedItems.concat(productIds); // Attach new array with selected items

      // Update the selectedItems's state
      setState((draft) => {
        draft.selectedItems = updatedSelectedItems;
      });
    } else if (type === "body") {
      // Find item from selected-items array
      const isItemExist = state.selectedItems.find((v) => v === item.id);

      // Filter selected-item ids from selected list
      updatedSelectedItems = isItemExist
        ? state.selectedItems.filter((val) => val !== item.id)
        : [...state.selectedItems, item.id];

      // Update the selectedItems's state
      setState((draft) => {
        draft.selectedItems = updatedSelectedItems;
      });
    }
  };

  // Add new cart products
  const handleAddCartProduct = async () => {
    try {
      const newTopPicks = {
        product_id: state.selectedItems,
      };

      // Add product to cart product list
      const response = await updateCartProductsApi(newTopPicks);

      // If response is success
      if (response.success) {
        successMessage(response.message);

        // Reload the table data
        getCartProducts();
      } else {
        errorMessage(response.message);
      }

      // Close product dialog
      handleProductDialog();
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };

  // Handler to filter deleted products from list
  const filterProduct = ({ productId }) => {
    setState((draft) => {
      draft.cartProducts = draft.cartProducts.filter(
        (product) => product.id !== productId
      );
    });
  };

  // Remove cart product
  const handleRemoveCartProduct = async (productId) => {
    try {
      // Api call to remove product from cart product
      const response = await removeFromCartProductApi(productId);

      // If response is success
      if (response.success) {
        successMessage(response.message);

        // Remove the deleted product from list
        filterProduct({ productId });
      } else {
        errorMessage(response.message);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };

  // Handle product search
  const handleProductSearch = (_, value) => {
    setState((draft) => {
      let filteredProductsCategory;

      if (value.trim() !== "") {
        // Function to match the search-value with product-name
        const filterProduct = (product) =>
          product.name.toLowerCase().includes(value.toLowerCase());

        // Function to match the search-value with product-name
        const filterCategory = (category) => {
          const newProducts = category.products.filter(filterProduct);
          return newProducts.length > 0; // Only include categories that have matching products
        };

        filteredProductsCategory = state.productCategories
          .map((category) => ({
            ...category,
            products: category.products.filter(filterProduct),
          }))
          .filter(filterCategory);
      } else {
        filteredProductsCategory = state.productCategories.map((category) => ({
          ...category,
          products: category.products, // Reset to original products array when value is empty
        }));
      }

      draft.filteredProductsCategory = filteredProductsCategory;
    });
  };

  // Handle toggle of delete dialog
  const handleToggleDeleteModal = async ({ confirm = false }) => {
    const productId = state.productIdToDelete;
    // Toggle the delete dialog
    setState((draft) => {
      draft.isOpenDeleteDialog = !draft.isOpenDeleteDialog;
    });

    // If user confirm to deletion then initiate the api call with current id
    if (confirm === true && productId !== null) {
      await handleRemoveCartProduct(productId);
      setState((draft) => {
        draft.productIdToDelete = null;
      });
    }
  };

  // Initial fetch of cart products
  React.useEffect(() => {
    getCartProducts();
  }, []);

  return {
    state,
    columns,
    breadcrumbsLink,
    handleTitleChanges,
    handleProductDialog,
    handleToggleStatus,
    handleToggleDeleteModal,
    handleTitleUpdatesMode,
    handleCategorySelect,
    handleAddCartProduct,
    handleProductSearch,
  };
};
