import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import {
  MenuBannersList,
  CreateOrEditMenuBanners,
  MenuBannersDetails,
} from "./containers";

export const MenuBanners = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<MenuBannersList />} />
        <Route element={<ProtectedRoute allowedRoute={["banner-view"]} />}>
          <Route path="/details/:id" element={<MenuBannersDetails />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["banner-modify"]} />}>
          <Route
            path="/details/:id/edit"
            element={<CreateOrEditMenuBanners />}
          />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["banner-create"]} />}>
          <Route path="/create" element={<CreateOrEditMenuBanners />} />
        </Route>
      </Routes>
    </>
  );
};
