import { Categories, CategoryGrid, Header, ProductList } from "../components";
import { Box, Container, Grid, Stack } from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSalesLayout } from "../hooks/useSalesLayout";
import { useParams } from "react-router-dom";
import { Loader } from "../../../shared";
import SimpleReactValidator from "simple-react-validator";
import { useState } from "react";
import { useRef } from "react";

export const AddOrEditLayout = () => {
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const { id } = useParams();
  const {
    state,
    changeCategory,
    moveItemToGarbage,
    addColumns,
    moveItemToGrid,
    onChange,
    onChangeColor,
    onSaveGrid,
    onUpdateGrid,
  } = useSalesLayout({ id });
  const { isBusy } = state;
  if (isBusy) {
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </Box>
    );
  }
  return (
    <Container maxWidth={false} disableGutters>
      <Header
        state={state}
        onChange={onChange}
        onSaveGrid={onSaveGrid}
        onUpdateGrid={onUpdateGrid}
        id={id}
        validator={validator}
        forceUpdate={forceUpdate}
      />
      <DndProvider backend={HTML5Backend}>
        <Grid container>
          <Grid item xs={9}>
            <Categories
              data={state.data.categories}
              selectedCategory={state.selectedCategory}
              changeCategory={changeCategory}
            />
            <CategoryGrid
              state={state}
              addColumns={addColumns}
              moveProduct={moveItemToGrid}
              onChangeColor={onChangeColor}
            />
          </Grid>
          <Grid item xs={3}>
            <ProductList
              garbage={state.data.categories[state.selectedCategory].garbage}
              category={state.data.categories[state.selectedCategory].name}
              moveItemToGarbage={moveItemToGarbage}
            />
          </Grid>
        </Grid>
      </DndProvider>
    </Container>
  );
};
