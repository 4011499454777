import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import {
  deleteBundleGroupById,
  getBundleGroupsList,
  getBundleGroupDetailsById,
  createBundleGroup,
  updateBundleGroup,
  updateBundleProductExtraPrice,
  assignProductToBundle,
  deleteBundleProductById,
} from "..";
import { usePermission } from "../../../shared";
import { AppContext } from "../../../store/AppScopeProvider";
import { errorMessage, successMessage } from "../../../utils";
import { useDebounce } from "use-debounce";

export const useBundles = ({ isList, isDetailsId, isAdd }) => {
  const { appState } = useContext(AppContext);
  const isEditable = usePermission("bundle-group-modify");
  const isDeletable = usePermission("bundle-group-delete");
  const isCreateVisible = usePermission("bundle-group-create");
  const { globalData } = appState;
  const navigate = useNavigate();
  const [state, setState] = useImmer({
    isLoading: false,
    initialLoad: true,
    isOpen: false,
    id: null,
    isSaveButtonBusy: false,
    BundleGroupsName: "",
    BundleGroupsList: [],
    httpStatusCodes: {
      details: "",
    },
    BundleGroupDetails: {
      isOpen: false,
      isDelete: false,
      isProductDelete: false,
      id: null,
      isLoading: true,
      isSaveButton: false,
      dataList: null,
      productsList: [],
      updateProduct: {
        extra_price: null,
        product_id: null,
      },
      filteredPoducts: [],
      searchText: null,
      selectedItems: [],
      alreadyProductList: [],
    },
    filters: {
      isSearching: false,
      searchText: "",
      selection_type: "",
    },
    updateData: {
      name: "",
      alias: "",
      selection_type: "",
      min_choice: "",
      max_choice: "",
    },
  });

  const [debouncedText] = useDebounce(state.filters.searchText, 1000);
  const [debouncedExtraPrice] = useDebounce(
    state.BundleGroupDetails.updateProduct.extra_price,
    1000
  );

  useEffect(() => {
    if (isList) {
      getBundleGroups();
    }
    if (isDetailsId && state.BundleGroupDetails.isLoading) {
      HandleBundleGroupDetails(isDetailsId);
    }
  }, [isList, isDetailsId, state.BundleGroupDetails.isLoading]);

  const handleModal = (type, state, data) => {
    switch (type) {
      case "bundle-delete":
        setState((draft) => {
          draft.isOpen = state;
          draft.id = data;
        });
        break;
      case "bundle-details-delete":
        setState((draft) => {
          draft.BundleGroupDetails.isDelete = state;
        });
        break;
      case "bundle-pro-add":
        if (!state) {
          setState((draft) => {
            draft.BundleGroupDetails.selectedItems = [];
            draft.BundleGroupDetails.filteredPoducts = [];
            draft.BundleGroupDetails.isOpen = state;
            draft.BundleGroupDetails.searchText = "";
          });
        } else {
          setState((draft) => {
            draft.BundleGroupDetails.isOpen = state;
          });
        }
        break;
      case "bundle-product-delete":
        setState((draft) => {
          draft.BundleGroupDetails.isProductDelete = state;
          draft.BundleGroupDetails.id = data;
        });
        break;
    }
  };

  useEffect(() => {
    if (!state.initialLoad && !state.filters.isSearching) {
      const { selection_type } = state.filters;
      getBundleGroups(debouncedText, selection_type);
    }
  }, [debouncedText]);

  const getBundleGroups = async (search = null, selection_type = null) => {
    try {
      setState((draft) => {
        draft.isLoading = true;
        draft.initialLoad = false;
      });
      const res = await getBundleGroupsList(search, selection_type);
      if (res.success) {
        setState((draft) => {
          draft.isLoading = false;
          draft.BundleGroupsList = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const onChangeFilter = (e) => {
    const { name, value } = e.target;
    const { searchText, selection_type } = state.filters;
    if (name === "selection_type") {
      getBundleGroups(searchText, value);
    }
    setState((draft) => {
      draft.filters[name] = value;
      draft.filters.isSearching = false;
    });
  };

  const resetFilters = () => {
    if (state.filters.selection_type || state.filters.searchText) {
      getBundleGroups();
      setState((draft) => {
        draft.filters.selection_type = "";
        draft.filters.searchText = "";
        draft.filters.isSearching = true;
      });
    }
  };

  const handleDeleteBundle = async (type = "") => {
    if (type === "list") {
      try {
        const res = await deleteBundleGroupById(state.id);
        if (res.success) {
          successMessage("Deleted Successfully");
          setState((draft) => {
            draft.BundleGroupsList = draft.BundleGroupsList.filter(
              (item) => item.id !== state.id
            );
          });
          handleModal("bundle-delete", false, null);
        }
      } catch (err) {
        errorMessage(err.response.data.message);
      }
    } else {
      try {
        const res = await deleteBundleGroupById(isDetailsId);
        if (res.success) {
          successMessage("Deleted Successfully");
          handleModal("bundle-details-delete", false);
          navigate("/bundle");
        }
      } catch (err) {
        errorMessage(err.response.data.message);
      }
    }
  };

  const HandleBundleGroupDetails = async (id) => {
    try {
      const res = await getBundleGroupDetailsById(id);
      if (res.success) {
        setState((draft) => {
          draft.BundleGroupDetails.dataList = res.data;
          draft.updateData = res.data;
          draft.BundleGroupDetails.isLoading = false;
          draft.BundleGroupDetails.productsList = res.data.products;
          draft.BundleGroupDetails.alreadyProductList = res.data.products?.map(
            (val) => {
              return val.product_id;
            }
          );
          draft.initialLoad = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.BundleGroupDetails.isLoading = false;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.updateData = {
        ...draft.updateData,
        [name]: value,
      };
    });
  };

  const onSaveBundleGroup = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await createBundleGroup(state.updateData);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate("/bundle");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onUpdateBundleGroup = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await updateBundleGroup(id, state.updateData);
      if (res.success) {
        successMessage("Updated Successfully");
        navigate(`/bundle/details/${id}`);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const handleExtraPriceInput = (e, data) => {
    if (
      data.product_id === state.BundleGroupDetails.updateProduct.product_id ||
      !state.BundleGroupDetails.updateProduct.product_id
    ) {
      setState((draft) => {
        draft.BundleGroupDetails.updateProduct = {
          ...data,
          extra_price: e.target.value,
        };
        draft.BundleGroupDetails.productsList = [
          ...draft.BundleGroupDetails.productsList.map((v) => {
            return v.product_id === data.product_id
              ? { ...v, extra_price: e.target.value }
              : v;
          }),
        ];
      });
    } else {
      handleUpdateExtraPrice(state.BundleGroupDetails.updateProduct);
      setState((draft) => {
        draft.BundleGroupDetails.updateProduct = {
          ...data,
          extra_price: e.target.value,
        };
        draft.BundleGroupDetails.productsList = [
          ...draft.BundleGroupDetails.productsList.map((v) => {
            return v.product_id === data.product_id
              ? { ...v, extra_price: e.target.value }
              : v;
          }),
        ];
      });
    }
  };

  const handleUpdateExtraPrice = async (data) => {
    try {
      const payload = {
        extra_price: data.extra_price,
      };
      const res = await updateBundleProductExtraPrice(
        isDetailsId,
        data.product_id,
        payload
      );
      if (res.success) {
        setState((draft) => {
          draft.BundleGroupDetails.updateProduct = {
            extra_price: null,
            product_id: null,
          };
        });
        successMessage("Extra Price Updated Successfully");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  useEffect(() => {
    if (!state.initialLoad && debouncedExtraPrice) {
      handleUpdateExtraPrice(state.BundleGroupDetails.updateProduct);
    }
  }, [debouncedExtraPrice]);

  const handleBundleProductsSelect = (v, e) => {
    if (v.has_variant) {
      const subRowsID = v.variants?.map((e, i) => {
        return e.id;
      });
      if (e.target.checked) {
        setState((draft) => {
          draft.BundleGroupDetails.selectedItems = [
            ...new Set([
              ...state.BundleGroupDetails.selectedItems,
              ...subRowsID,
            ]),
          ];
          // ...new Set => for avoid duplicates
        });
      } else {
        setState((draft) => {
          draft.BundleGroupDetails.selectedItems =
            draft.BundleGroupDetails.selectedItems.filter(
              (val) => !subRowsID.includes(val)
            );
        });
      }
    } else {
      const isSelcted = state.BundleGroupDetails.selectedItems.includes(v.id);
      if (isSelcted) {
        setState((draft) => {
          draft.BundleGroupDetails.selectedItems =
            draft.BundleGroupDetails.selectedItems.filter(
              (val) => val !== v.id
            );
        });
      } else {
        setState((draft) => {
          draft.BundleGroupDetails.selectedItems = [
            ...draft.BundleGroupDetails.selectedItems,
            v.id,
          ];
          return draft;
        });
      }
    }
  };

  const handleAssignProductToBundle = async () => {
    const payload = {
      product_id: state.BundleGroupDetails.selectedItems,
    };

    try {
      const res = await assignProductToBundle(isDetailsId, payload);
      if (res.success === true) {
        successMessage(res.message);
        setState((draft) => {
          draft.BundleGroupDetails.selectedItems = [];
          draft.BundleGroupDetails.isLoading = true;
          return draft;
        });
        handleModal("bundle-pro-add", false);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };

  const handleDeleteBundleProduct = async () => {
    try {
      const res = await deleteBundleProductById(
        isDetailsId,
        state.BundleGroupDetails.id
      );
      if (res.success) {
        successMessage("Product removed from the Bundle group");
        setState((draft) => {
          draft.BundleGroupDetails.productsList =
            draft.BundleGroupDetails.productsList.filter(
              (item) => item.product_id !== state.BundleGroupDetails.id
            );
        });
        handleModal("bundle-product-delete", false, null);
        HandleBundleGroupDetails(isDetailsId);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const isCheckIndeterminate = (value) => {
    if (value.has_variant) {
      const subArray = value.variants.map((e, i) => {
        return e.id;
      });
      const commonIDs = state.BundleGroupDetails.selectedItems.filter((val) =>
        subArray.includes(val)
      );
      const notAllIDs = subArray.every((val) =>
        state.BundleGroupDetails.selectedItems.includes(val)
      );
      return commonIDs.length > 0 && !notAllIDs;
    } else {
      return false;
    }
  };

  return {
    state,
    isEditable,
    isDeletable,
    isCreateVisible,
    globalData,
    onChangeFilter,
    resetFilters,
    handleModal,
    handleDeleteBundle,
    onChangeInput,
    onSaveBundleGroup,
    onUpdateBundleGroup,
    handleExtraPriceInput,
    handleBundleProductsSelect,
    handleAssignProductToBundle,
    handleDeleteBundleProduct,
    isCheckIndeterminate,
  };
};
