import { API } from "../../../utils";

export const getRolesList = async (type) => {
  let url = "roles";
  if (type) {
    url = `roles?type=${type}`;
  }
  const res = await API.get(url);
  return res.data;
};

export const getRoleDetailsList = async (id) => {
  const res = await API.get(`roles/${id}`);
  return res.data;
};

export const getPermissionList = async () => {
  const res = await API.get(`permissions`);
  return res.data;
};

export const createRoleItem = async (payload) => {
  const res = await API.post("roles", payload);
  return res.data;
};

export const editRoleItem = async (id, payload) => {
  const res = await API.put(`roles/${id}`, payload);
  return res.data;
};

export const getUsersList = async () => {
  const res = await API.get("users");
  return res.data;
};

export const assignUserToRole = async (payload, id) => {
  const res = await API.post(`roles/${id}/assign_user`, payload);
  return res.data;
};

export const deleteRoleById = async (id) => {
  const res = await API.delete(`roles/${id}`);
  return res.data;
};

export const deleteMemberFromRole = async (payload, id) => {
  const res = await API.post(`roles/${id}/delete_user`, payload);
  return res.data;
};

// ======================================================== POS Roles and Permission APIs ============================================
/**
 * Fetches the list of POS roles.
 *
 * @param {Object} params - The parameters for the API request.
 * @returns {Promise<Object>} - A promise that resolves to the data returned from the API.
 */
export const getPosRolesListApi = async (params) => {
  const response = await API.get(`pos_roles`, params);
  return response.data;
};

/**
 * Fetches the list of all POS permissions.
 *
 * @returns {Promise<Object>} - A promise that resolves to the data returned from the API.
 */
export const getAllPosPermissionListApi = async () => {
  const response = await API.get("pos_permissions");
  return response.data;
};

/**
 * Creates a new POS role.
 *
 * @param {Object} payload - The data for the new role.
 * @returns {Promise<Object>} - A promise that resolves to the data returned from the API.
 */
export const createNewPosRoleApi = async (payload) => {
  const response = await API.post("pos_roles", payload);
  return response.data;
};

/**
 * Updates an existing POS role.
 *
 * @param {string} roleId - The ID of the role to update.
 * @param {Object} payload - The updated data for the role.
 * @returns {Promise<Object>} - A promise that resolves to the data returned from the API.
 */
export const updatePosRoleApi = async (roleId, payload) => {
  const response = await API.put(`pos_roles/${roleId}`, payload);
  return response.data;
};

/**
 * Deletes a POS role.
 *
 * @param {string} roleId - The ID of the role to delete.
 * @returns {Promise<Object>} - A promise that resolves to the data returned from the API.
 */
export const deletePosRoleApi = async (roleId) => {
  const response = await API.delete(`pos_roles/${roleId}`);
  return response.data;
};

/**
 * Fetches the details of a specific POS role by its ID.
 *
 * @param {string} roleId - The ID of the role to fetch.
 * @returns {Promise<Object>} - A promise that resolves to the data returned from the API.
 */
export const getPosRoleDetailsByIdApi = async (roleId) => {
  const response = await API.get(`pos_roles/${roleId}`);
  return response.data;
};

/**
 * Assigns a user to a POS role.
 *
 * @param {Object} payload - The data for the user assignment.
 * @param {string} roleId - The ID of the role to which the user will be assigned.
 * @returns {Promise<Object>} - A promise that resolves to the data returned from the API.
 */
export const assignUserToPosRoleApi = async (payload, roleId) => {
  const res = await API.post(`pos_roles/${roleId}/assign_user`, payload);
  return res.data;
};

/**
 * Deletes a member from a POS role.
 *
 * @param {Object} payload - The data for the member deletion.
 * @param {string} roleId - The ID of the role from which the member will be deleted.
 * @returns {Promise<Object>} - A promise that resolves to the data returned from the API.
 */
export const deleteMemberFromPosRoleApi = async (payload, roleId) => {
  const res = await API.post(`pos_roles/${roleId}/delete_user`, payload);
  return res.data;
};
