import { API } from "../../../utils";

export const getCFDList = async () => {
    const res = await API.get(`cfds`);
    return res.data;
};

export const deleteCFDbyId = async (id) => {
    const res = await API.delete(`cfds/${id}`);
    return res.data;
};


export const createCFD = async (payload) => {
    const res = await API.post(`cfds`, payload);
    return res.data;
};


export const changeCFDOrder = async (payload) => {
    const res = await API.post(`cfds/sort-order`, payload);
    return res.data;
};

export const getCFDDetails = async (id) => {
    const res = await API.get(`cfds/${id}`);
    return res.data;
};

export const updateCFD = async (id, payload) => {
    const res = await API.post(`cfds/${id}`, payload);
    return res.data;
};