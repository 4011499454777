import React from "react";
import { useImmer } from "use-immer";
import {
  addStores,
  copyTemplate,
  deleteTemplate,
  getAllTemplates,
  getTemplateDetails,
} from "../api/ProductSortApi";
import { useEffect } from "react";
import { errorMessage, successMessage } from "../../../utils";

export const useProductSort = ({ isList = true, id = null }) => {
  const [state, setState] = useImmer({
    isOpen: false,
    id: null,
    isBusy: false,
    isLoading: false,
    isDeleteButtonBusy: false,
    isDirty: false,
    duplicateId: "",
    templates: [],
    details: { excluded_stores: [], included_stores: [], name: "" },
  });

  useEffect(() => {
    if (isList) {
      getTemplates();
    }
  }, [isList]);

  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, [id]);

  const getTemplates = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getAllTemplates();
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.templates = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const handleModal = (id = null) => {
    setState((draft) => {
      draft.isOpen = !draft.isOpen;
      draft.id = id;
    });
  };
  const onConfirmDelete = async () => {
    setState((draft) => {
      draft.isDeleteButtonBusy = true;
    });
    try {
      const res = await deleteTemplate(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.isDeleteButtonBusy = false;
          draft.templates = draft.templates.filter(
            (item) => item.id !== state.id
          );
        });
        handleModal();
      } else {
        errorMessage(res.message ?? "");
        setState((draft) => {
          draft.isDeleteButtonBusy = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isDeleteButtonBusy = false;
      });
      errorMessage(err.response.data.message);
    }
  };
  const onDuplicate = async (id) => {
    setState((draft) => {
      draft.isLoading = true;
      draft.duplicateId = id;
    });
    try {
      const res = await copyTemplate(id);
      if (res.success) {
        successMessage("Template duplicated successfully");
        getTemplates();
        setState((draft) => {
          draft.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const getDetails = async (id) => {
    try {
      setState((draft) => {
        draft.isDirty = true;
      });
      const res = await getTemplateDetails(id);
      if (res.success) {
        setState((draft) => {
          draft.isDirty = false;
          draft.details = res.data;
        });
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.isDirty = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isDirty = false;
      });
    }
  };

  const updateStores = async (store_id, type, storeId) => {
    try {
      const payload = {
        store_id,
      };
      const res = await addStores(id, payload);
      if (res.success) {
        successMessage("Stores updates successfully");
        updateStoreOnState(type, storeId);
        setState((draft) => {
          draft.isBusy = false;
        });
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const updateStoreOnState = (type, id) => {
    const property =
      type === "excluded_stores" ? "included_stores" : "excluded_stores";
    const store = state.details[property].find((item) => item.id === id);
    if (type === "excluded_stores") {
      setState((draft) => {
        draft.details.excluded_stores = [
          store,
          ...draft.details.excluded_stores,
        ];
        draft.details.included_stores = draft.details.included_stores.filter(
          (item) => item.id !== id
        );
      });
    } else {
      setState((draft) => {
        draft.details.included_stores = [
          store,
          ...draft.details.included_stores,
        ];
        draft.details.excluded_stores = draft.details.excluded_stores.filter(
          (item) => item.id !== id
        );
      });
    }
  };

  const updateIncluded = (id, type) => {
    setState((draft) => {
      draft.isBusy = true;
    });
    const property =
      type === "excluded_stores" ? "included_stores" : "excluded_stores";
    const store = state.details[property].find((item) => item.id === id);
    let includedStoreIds = state.details.included_stores.map((item) => item.id);
    if (type === "excluded_stores") {
      includedStoreIds = includedStoreIds.filter((item) => item !== id);
    } else {
      includedStoreIds = [...includedStoreIds, store.id];
    }
    updateStores(includedStoreIds, type, id);
  };

  return { state, handleModal, onConfirmDelete, onDuplicate, updateIncluded };
};
