import React from "react";
import { Loader } from "../../../shared";
import { Typography } from "@mui/material";
import { useStaffSales } from "../hooks/useStaffSales";
import SalesTable from "../components/SalesTable";


export const StaffSales = () => {
    const { state } = useStaffSales()

    const findSum = (data, key, isDollar = true, roundOff = 2) => {
        const total = data.reduce(
            (acc, item) => Number(item.original[key]) + acc,
            0
        );
        return isDollar ? "$" + total.toFixed(roundOff) : total.toFixed(roundOff);
    };

    const findTotal = (data, key) => {
        const total = data.reduce(
            (acc, item) => Number(item.original[key]) + acc,
            0
        );
        return total;
    };


    const columns = React.useMemo(
        () => [
            {
                Header: 'Staff',
                accessor: 'Staff',
                Cell: ({ row }) => {
                    return (
                        <Typography
                            sx={{ lineHeight: "16px" }}
                            color="black"
                            fontWeight={500}
                            fontSize="14px"
                        >
                            {row?.original?.Staff}
                        </Typography>
                    )
                },
                Footer: (
                    <Typography
                        sx={{ lineHeight: "16px" }}
                        color="black"
                        fontWeight={500}
                        fontSize="14px"
                    >
                        Total
                    </Typography>
                ),
            },
            {
                Header: 'Transactions',
                accessor: 'Transactions',
                Footer: ({ rows }) => findTotal(rows, "Transactions", false,),
            },
            {
                Header: 'Gross Sales',
                accessor: 'GrossSales',
                Cell: ({ row }) => {
                    return "$" + row?.original?.GrossSales?.toFixed(2);
                },
                Footer: ({ rows }) => findSum(rows, "GrossSales"),
            },
            {
                Header: 'Gross ATV',
                accessor: 'GrossATV',
                Cell: ({ row }) => {
                    return "$" + row?.original?.GrossATV?.toFixed(2);
                },
                Footer: ({ rows }) => {
                    const totalGrossSales = findTotal(rows, "GrossSales");
                    const totalTransactions = findTotal(rows, "Transactions");
                    const grossAtv =
                        Number(totalGrossSales) / Number(totalTransactions) || 0;
                    return grossAtv.toFixed(2);
                },
            },
            {
                Header: 'Net Sales',
                accessor: 'NetSales',
                Cell: ({ row }) => {
                    return "$" + row?.original?.NetSales?.toFixed(2);
                },
                Footer: ({ rows }) => findSum(rows, "NetSales"),
            },
            {
                Header: 'Net ATV',
                accessor: 'NetATV',
                Cell: ({ row }) => {
                    return "$" + row?.original?.NetATV?.toFixed(2);
                },
                Footer: ({ rows }) => {
                    const totalNetSales = findTotal(rows, "NetSales");
                    const totalTransactions = findTotal(rows, "Transactions");
                    const netAtv = Number(totalNetSales) / Number(totalTransactions) || 0;
                    return netAtv.toFixed(2);
                },
            },
            {
                Header: 'Cleared Transactions',
                accessor: 'ClearedTransactions',
                Footer: ({ rows }) => findTotal(rows, "ClearedTransactions"),

            },
            {
                Header: 'Cleared Value',
                accessor: 'ClearedValue',
                Cell: ({ row }) => {
                    return "$" + row?.original?.ClearedValue?.toFixed(2);
                },
                Footer: ({ rows }) => findSum(rows, "ClearedValue"),

            },
            {
                Header: 'Discounts',
                accessor: 'Discounts',
                Footer: ({ rows }) => findTotal(rows, "Discounts"),
            },
            {
                Header: 'Discount Value',
                accessor: 'DiscountsValue',
                Cell: ({ row }) => {
                    return "$" + row?.original?.DiscountsValue?.toFixed(2);
                },
                Footer: ({ rows }) => findSum(rows, "DiscountsValue"),
            }
        ],
        []
    );
    return (
        <>
            {state.isLoading ? <Loader /> : (<>

                <SalesTable data={state.dataList} columns={columns} />
            </>
            )}
        </ >
    );
};
