import * as React from "react";
import {
  ContentLayout,
  HeaderBreadcrumbs,
  ServerPaginatedTable,
} from "../../../shared";
import { Stack } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { useProductRatings } from "../hooks/useProductRatings";
import ReviewListFilterSection from "../../ReviewsAndRatings/components/ReviewListFilterSection";
export const ProductRatingsList = () => {
  const {
    state,
    categoriesList,
    gotoPage,
    handleSortBy,
    setPageSize,
    handleApplyFilter,
    handleClearFilter,
    handleTableSearch,
    handleClearAllFilter,
    handleReviewFilterSelection,
    handleProductFetch,
    handleAutocompleteServerSearch,
  } = useProductRatings({
    table: "products-list",
  });
  const navigate = useNavigate();
  const ActionButtons = ({ row }) => {
    return (
      <div className="action-buttons">
        <Link to={`product/details/${row.id}`} state={row}>
          <img alt="details" src="/icons/arrow_forward.svg" />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Products",
        accessor: "name",
      },
      {
        Header: "PLU Code ",
        accessor: "plu",
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Average Rating",
        accessor: "avg_rating",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="4px" height="16px">
              <img alt="star" src="/icons/star-yellow.svg" />
              {row.original.avg_rating}
            </Stack>
          );
        },
      },
      {
        Header: "Review Count",
        accessor: "num_of_reviews",
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons key={index} row={row} />;
        },
      },
    ],
    []
  );

  const breadcrumbsLinks = [
    {
      name: "Dashboard",
      href: "/",
    },
    {
      name: "Product Ratings",
      href: "",
    },
  ];

  return (
    <>
      <ContentLayout
        title="Product Ratings"
        breadcrumbs={<HeaderBreadcrumbs links={breadcrumbsLinks} />}
      >
        <ServerPaginatedTable
          columns={columns}
          data={state.review.list}
          isLoading={state.review.isLoading}
          gotoPage={gotoPage}
          searchBar={true}
          defaultSearch={false}
          handleSort={handleSortBy}
          setPageSize={setPageSize}
          pagination={state.review.pagination}
          sortByGlobal={state.review.sortBy}
          filterContent={
            <InputControl
              type="table-search"
              name="search"
              placeholder="Search"
              value={state.review.search}
              onChange={handleTableSearch}
              onClear={() => handleTableSearch({ target: { value: "" } })}
            />
          }
          filterApplied={state.filter.appliedFilters}
          onAllFilterClear={handleClearAllFilter}
          onFilterClear={handleClearFilter}
          filterSection={
            <ReviewListFilterSection
              {...{
                state,
                categoriesList,
                ratingsList: state.rating.options,
                productsList: state.product.options,
                handleReviewFilterSelection,
                handleApplyFilter,
                handleProductFetch,
                handleAutocompleteServerSearch,
              }}
            />
          }
          onRowAction={(row) =>
            navigate(`product/details/${row.id}`, { state: row })
          }
        />
      </ContentLayout>
    </>
  );
};
