import {
  ContentLayout,
  DeleteModal,
  ServerPaginatedTable,
  usePermission,
} from "../../../shared";
import React from "react";
import { Button, Stack, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { sortItems } from "../../../utils";
import CloseIcon from "@mui/icons-material/Close";
import PauseCircleOutlinedIcon from "@mui/icons-material/PauseCircleOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import { Link } from "react-router-dom";
import { useBirthdayCoupons } from "..";

export const BirthdayCouponsList = () => {
  const {
    state,
    globalData,
    handleBirthdayCouponStatus,
    deleteModal,
    handleDeleteCoupons,
    onChangeFilter,
    resetFilters,
    handleAllBirthdayCouponStatus,
    gotoPage,
    setPageSize,
    handleSort,
    onSeachClear,
  } = useBirthdayCoupons({ isList: true });
  const navigate = useNavigate();
  const isEditable = usePermission("coupon-modify");
  const isDeletable = usePermission("coupon-delete");

  const { birthdayCouponsList, isLoading, isOpen, filterApplied } = state;
  const { details } = state.BirthdayCouponsDetails;

  const ActionButtons = ({ id, row, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {row.active !== 4 && isEditable && (
          <img
            alt="status"
            src={
              row.active === 2 ? "/icons/ic_pause.svg" : "/icons/ic_play.svg"
            }
            onClick={() => {
              handleBirthdayCouponStatus(id);
            }}
          />
        )}
        {isDeletable && (
          <img alt="delete" src="/icons/ic_delete.svg" onClick={deleteAction} />
        )}
        {
          <Link to={`details/${id}`}>
            <img alt="details" src="/icons/arrow_forward.svg" />
          </Link>
        }
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Start Date",
        accessor: "start_from",
        disableSortBy: true,
      },
      {
        Header: "End Date",
        accessor: "end_till",
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "active",
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return value === 2 ? (
            <Chip
              label="Active"
              color="success"
              variant="outlined"
              size="small"
            />
          ) : value === 3 ? (
            <Chip label="Pause" color="info" variant="outlined" size="small" />
          ) : value === 1 ? (
            <Chip label="Draft" color="warning" variant="filled" size="small" />
          ) : (
            <Chip
              label="Ended"
              color="success"
              variant="outlined"
              size="small"
              disabled
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              row={row}
              deleteAction={() => {
                deleteModal(row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <ContentLayout
        title="Birthday Coupons"
        rightContent={
          <>
            {isEditable && (
              <>
                <Button
                  type="button"
                  size="medium"
                  variant="outlined"
                  onClick={() => navigate(`account/${1}`)}
                >
                  Accounts
                </Button>
                <Button
                  type="button"
                  size="medium"
                  variant="contained"
                  startIcon={
                    details.active === 2 ? (
                      <PauseCircleOutlinedIcon />
                    ) : (
                      <PlayCircleFilledWhiteOutlinedIcon />
                    )
                  }
                  onClick={() => handleAllBirthdayCouponStatus()}
                >
                  {details.active === 2 ? " Pause " : "Active"}
                </Button>
                <Button
                  type="button"
                  size="medium"
                  variant="contained"
                  onClick={() => navigate("edit")}
                >
                  Edit
                </Button>
              </>
            )}
          </>
        }
      >
        <ServerPaginatedTable
          columns={columns}
          data={birthdayCouponsList}
          key={state.sortBy}
          isLoading={isLoading}
          defaultSearch={false}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
          pagination={state.pagination}
          handleSort={handleSort}
          sortByGlobal={state.sortBy}
          filterContent={
            <>
              <InputControl
                type="table-search"
                name="searchText"
                placeholder="Search"
                size="small"
                value={filterApplied.searchText}
                onChange={(e) => onChangeFilter(e)}
                onClear={onSeachClear}
              />
              <InputControl
                type="select"
                name="status"
                label="Status"
                optionValue={globalData.coupons?.status ?? []}
                width="250px"
                onChange={(e) => onChangeFilter(e)}
                value={filterApplied.status}
              />

              <Button
                type="button"
                size="medium"
                variant="text"
                color="gray"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => resetFilters()}
              >
                <CloseIcon />
              </Button>
            </>
          }
          onRowLink={(e) => {
            return `details/${e.id}`;
          }}
        />
      </ContentLayout>
      <DeleteModal
        open={isOpen}
        handleClose={() => deleteModal()}
        onConfirm={() => handleDeleteCoupons()}
      />
    </>
  );
};
