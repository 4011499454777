import * as React from "react";
import { ContentLayout, HeaderBreadcrumbs, Loader, PageNotFound } from "../../../shared";
import { useParams } from "react-router-dom";
import {
  Button,
  Grid,
  Stack,
  Card,
  Switch,
  FormControlLabel,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useFranchisee } from "../hooks/useFranchisee";
import SimpleReactValidator from "simple-react-validator";


export const AddOrEditFranchisee = () => {
  const { id } = useParams();

  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const { state, onSaveState, onChange, onSwitchChange, onUpdateFranchisee, isEditable, isCreateVisible } =
    useFranchisee({
      franchiseeId: id,
      isFranchisee: false,
      isStore: false,
    });

  const { name } = state.franchiseeDetails.data;


  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdateFranchisee(id) : onSaveState();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }

  return (
    <>
      <ContentLayout
        title={id ? state.franchiseeDetails.data.name : "New Franchisee"}
        rightContent={
          <>
            {(isEditable || isCreateVisible) && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                disabled={state.franchiseeDetails.isSaveButtonBusy}
                onClick={() => onSubmit()}
              >
                {!id ? "Save" : "Update"}
              </Button>
            )}
          </>
        }
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Franchisee", href: "/franchisee" },
              id ? {
                name: state.franchiseeDetails.data.name,
                href: `/franchisee/details/${id}`,
              } : null,
              { name: id ? "Edit" : "Create" },
            ].filter(Boolean)}
          />
        }
      >
        {state.franchiseeDetails.isBusy ? (
          <Loader />
        ) : (
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 1,
            }}
          >
            <Card sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={6} md={4}>
                    <h4>Franchisee Details</h4>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <Stack spacing={3}>
                      <InputControl
                        type="text"
                        label="Franchisee Name"
                        required
                        value={name}
                        name="name"
                        onChange={(e) => onChange(e, "franchiseeDetails")}
                        error={validator.current.message(
                          "Franchisee name",
                          name,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Franchisee name",
                          name,
                          "required"
                        )}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Card>
            <Card sx={{ marginTop: "15px" }}>
              <List disablePadding>
                <ListItem disablePadding>
                  <ListItemText
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      my: 4,
                      pl: 1,
                      ml: 2,
                    }}
                    disableTypography={true}
                  >
                    <Box>
                      <h4>Status</h4>
                    </Box>

                    <FormControlLabel
                      sx={{
                        display: "block",
                      }}
                      control={
                        <Switch
                          checked={state.franchiseeDetails.data.status}
                          onChange={(loading) => onSwitchChange(loading)}
                          name={state.franchiseeDetails.data.status}
                          color="primary"
                        />
                      }
                    />
                  </ListItemText>
                </ListItem>
              </List>
            </Card>
          </Box>
        )}
      </ContentLayout>
    </>
  );
};
