import { Box, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { ImageView } from "./ImageView";
import { TextView } from "./TextView";

export const Product = ({ product, grid_view, onChangeColor }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "prd",
    item: { productId: product.id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "6px",
        cursor: "grab",
        width: "100%",
      }}
      key={product.id}
      ref={drag}
    >
      {grid_view === "image" ? (
        <ImageView product={product} />
      ) : (
        <TextView product={product} onChangeColor={onChangeColor} />
      )}
    </Box>
  );
};
