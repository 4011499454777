import React from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
  useExpanded,
} from "react-table";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableHead,
  TableContainer,
  TableSortLabel,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Box,
  Paper,
  Stack,
  Checkbox,
  IconButton,
  Chip,
  Button,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

export const LedgerAccountTable = (props) => {
  const {
    columns,
    data,
    initialPage,
    tableTitle,
    headerRightContent = null,
    pagination = false,
    bulkDelete = false,
    onBulkDelete = {},
    filterSection = null,
    completeFeature = true,
    hiddenColumns = [],
    emptyMessage = "",
    subRow = null,
  } = props;
  const {
    allColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    globalFilteredRows,
    setGlobalFilter,
    state,
    state: { pageIndex, pageSize },
    selectedFlatRows,
    rows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: initialPage ?? 10,
        sortBy: [],
        hiddenColumns,
      },
      getSubRows: (row) => row[subRow] || [],
    },

    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    // ,
    (hooks) => {
      bulkDelete &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox
                classes={{ root: "custom-checkbox-root" }}
                color="primary"
                sx={{
                  marginLeft: "-10px",
                }}
                {...getToggleAllRowsSelectedProps()}
              />
            ),
            Cell: ({ row }) => (
              <Checkbox
                classes={{ root: "custom-checkbox-root" }}
                color="primary"
                sx={{
                  marginLeft: "-10px",
                }}
                {...row.getToggleRowSelectedProps()}
              />
            ),
            padding: "checkbox",
          },
          ...columns,
        ]);
    }
  );
  const tableData = pagination ? page : rows;
  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <TableContainer>
          {tableTitle && (
            <CustomTableHeader
              tableTitle={tableTitle}
              headerRightContent={headerRightContent}
            />
          )}

          <Table
            {...getTableProps()}
            sx={{
              borderCollapse: "inherit",
              overflow: "hidden",
            }}
          >
            <TableHead>
              {headerGroups.map((headerGroup, i) => (
                <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        p: !column.padding && "10px",
                      }}
                      align={column?.align}
                      padding={column.padding || "normal"}
                      {...column.getHeaderProps(
                        bulkDelete && selectedFlatRows.length > 0
                          ? ""
                          : column.getSortByToggleProps()
                      )}
                    >
                      {bulkDelete &&
                      selectedFlatRows.length > 0 &&
                      index >= 1 ? (
                        <>
                          {index > 1 ? (
                            <></>
                          ) : (
                            <Typography
                              variant="subtitle2"
                              color="#1560D4"
                              lineHeight="18px"
                              onClick={() => {
                                onBulkDelete(selectedFlatRows);
                              }}
                            >
                              Delete
                            </Typography>
                          )}
                        </>
                      ) : (
                        <>
                          {column.canSort ? (
                            <TableSortLabel
                              active={column.isSorted}
                              direction={column.isSortedDesc ? "dsc" : "asc"}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  height: "15px",
                                  width: "15px",
                                },
                              }}
                            >
                              {column.render("Header")}
                            </TableSortLabel>
                          ) : (
                            <>{column.render("Header")}</>
                          )}
                        </>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            {preGlobalFilteredRows.length > 0 &&
            globalFilteredRows.length > 0 ? (
              <TableBody {...getTableBodyProps()}>
                {tableData.map((row, i) => {
                  prepareRow(row);
                  return (
                    <TableRow key={i} {...row.getRowProps()}>
                      {row.cells.map((cell, i) => {
                        return (
                          <TableCell
                            key={i}
                            align={cell.column?.align}
                            padding={cell.column.padding || "normal"}
                            sx={{
                              borderBottom: "1px solid #E6E6E6",
                              p: !cell.column.padding && "8px",
                            }}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={20}
                    sx={{ py: 2, backgroundColor: "#F7F7F7" }}
                  >
                    <Typography
                      align="center"
                      variant="body2"
                      color={"#666666"}
                    >
                      {emptyMessage ? (
                        <Stack direction="row" gap={1} justifyContent="center">
                          <img alt="edit" src="/icons/icon_not.svg" />
                          {emptyMessage}
                        </Stack>
                      ) : (
                        "No matches found"
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>

      {/* <pre>
        <code>
          {JSON.stringify(
            {
              selectedFlatRows: selectedFlatRows.map((row) => row.original),
            },
            null,
            2
          )}
        </code>
      </pre> */}
    </>
  );
};

export function CustomTableHeader({ tableTitle, headerRightContent }) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        py: "12px",
        px: "16px",
        gap: "16px",
        borderBottom: "1px solid #E6E6E6",
        height: "84px",
      }}
    >
      <Typography gutterBottom align="center" variant="subtitle1">
        {tableTitle}
      </Typography>
      {headerRightContent}
    </Stack>
  );
}
