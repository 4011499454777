import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  CategoryList,
  CategoryDetails,
  CreateAndEditCategory,
  CategoryProductList,
} from "..";
import { ProtectedRoute } from "../../shared";

export const Category = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<CategoryList />} />
        <Route element={<ProtectedRoute allowedRoute={["category-modify"]} />}>
          <Route path="/details/edit/:id" element={<CreateAndEditCategory />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["category-modify"]} />}>
          <Route path="/details/:id" element={<CategoryDetails />} />
        </Route>
        {/* <Route
          element={<ProtectedRoute allowedRoute={["category-modify"]} />}
        >
          <Route path="/details/:id/products" element={<CategoryProductList />} />
        </Route> */}
        <Route element={<ProtectedRoute allowedRoute={["category-create"]} />}>
          <Route path="/create" element={<CreateAndEditCategory />} />
        </Route>
      </Routes>
    </>
  );
};
