import * as React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Stack,
  Box,
} from "@mui/material";
import { useQrCodeDetails } from "../hooks/useQrCodeDetails";
import { Loader } from "../../../shared";
import { saveImageToDevice } from "../../../utils";

export const QRCodeDetailsDialog = () => {
  const { state } = useQrCodeDetails();
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      minHeight="425px"
      gap="16px"
    >
      {state.isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Box
            component="img"
            width="259px"
            height="259px"
            alt="qr-code"
            src={state.formData.qrcode_image}
          />
          <Stack alignSelf="center">
            <Button
              variant="contained"
              size="small"
              startIcon={
                <Box component="img" src="/icons/download_white_icon.svg" />
              }
              onClick={() =>
                saveImageToDevice(state.formData.qrcode_image, "Qrcode.png")
              }
            >
              Download
            </Button>
          </Stack>
          <List sx={{ width: "100%" }}>
            <ListItem divider={true} disablePadding>
              <ListItemText
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "80px",
                }}
                disableTypography={true}
              >
                <Stack justifyContent="center">
                  <Typography variant="subtitle2" fontWeight={400}>
                    Store
                  </Typography>
                </Stack>
                <Stack height="30px" justifyContent="center">
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    color="#636363"
                  >
                    {state.formData.store_name}
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItem>
            <ListItem divider={true} disablePadding>
              <ListItemText
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "60px",
                }}
                disableTypography={true}
              >
                <Stack justifyContent="center">
                  <Typography variant="subtitle2" fontWeight={400}>
                    Location
                  </Typography>
                </Stack>
                <Stack height="30px" justifyContent="center">
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    color="#636363"
                  >
                    {state.formData.location}
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItem>
            <ListItemText
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "60px",
              }}
              disableTypography={true}
            >
              <Stack justifyContent="center">
                <Typography variant="subtitle2" fontWeight={400}>
                  Remarks
                </Typography>
              </Stack>
              <Stack height="30px" justifyContent="center">
                <Typography
                  variant="subtitle2"
                  fontWeight={400}
                  color="#636363"
                >
                  {state.formData.remarks}
                </Typography>
              </Stack>
            </ListItemText>
          </List>
        </React.Fragment>
      )}
    </Stack>
  );
};
