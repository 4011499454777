import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import * as React from "react";
import { Stack, Card, Typography, Grid, TextField } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useAddOrEditQrCode } from "../hooks";
import { LoadingButton } from "@mui/lab";

export const AddOrEditQRCode = () => {
  const {
    state,
    qrCodeId,
    qrCodeValidator,
    headBreadCrumbsLinks,
    handleSubmit,
    handleFormChange,
  } = useAddOrEditQrCode();

  // Validate the store ID field and get the corresponding helper text if it's required
  const storeHelperText = qrCodeValidator.current.message(
    "store_id",
    state.formData.store_id,
    "required"
  );

  // Validate the location field and get the corresponding helper text if it's required
  const locationHelperText = qrCodeValidator.current.message(
    "location",
    state.formData.location,
    "required"
  );

  // Validate the order type field and get the corresponding helper text if it's required
  const orderTypeHelperText = qrCodeValidator.current.message(
    "type",
    state.formData.type,
    "required"
  );

  return (
    <ContentLayout
      title={qrCodeId ? "Update QR Code" : "Create QR Code"}
      breadcrumbs={<HeaderBreadcrumbs links={headBreadCrumbsLinks} />}
      rightContent={
        <LoadingButton
          onClick={handleSubmit}
          type="button"
          size="medium"
          variant="contained"
          loading={state.isSaveButtonLoading}
        >
          {qrCodeId ? "Update" : "Save"}
        </LoadingButton>
      }
    >
      <Card sx={{ p: "32px 24px", mb: "24px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="subtitle1"
              fontWeight={600}
              sx={{ width: "150px" }}
            >
              Details
            </Typography>
          </Grid>
          <Grid
            item
            sm={8}
            xs={12}
            display="flex"
            gap="20px"
            flexDirection="column"
          >
            {(qrCodeId && state.isContentLoading) ||
            (state.list.orderTypeListLoading && state.list.storeListLoading) ? (
              <Stack minHeight="180px" justifyContent="center">
                <Loader />
              </Stack>
            ) : (
              <React.Fragment>
                <Stack flexDirection={{ md: "row", xs: "column" }} gap="20px">
                  <InputControl
                    type="dropdown"
                    name="store_id"
                    label="Store"
                    options={state.list.storeList}
                    value={state.formData.store_id}
                    onChange={(_, value) =>
                      handleFormChange({
                        target: { value: value, name: "store_id" },
                      })
                    }
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    )}
                    sx={{ minWidth: "150px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Store"
                        required
                        placeholder="Select Store"
                        helperText={storeHelperText}
                        error={Boolean(storeHelperText)}
                      />
                    )}
                  />
                  <InputControl
                    type="dropdown"
                    name="type"
                    label="Default Order Type"
                    options={state.list.orderTypeList}
                    value={state.formData.type}
                    onChange={(_, value) =>
                      handleFormChange({
                        target: { value: value, name: "type" },
                      })
                    }
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    )}
                    sx={{ minWidth: "150px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Default Order Type"
                        required
                        placeholder="Select Type"
                        helperText={orderTypeHelperText}
                        error={Boolean(orderTypeHelperText)}
                      />
                    )}
                  />
                </Stack>
                <InputControl
                  required
                  name="location"
                  label="Location"
                  value={state.formData.location}
                  onChange={handleFormChange}
                  helperText={locationHelperText}
                  error={Boolean(locationHelperText)}
                />
                <InputControl
                  name="remarks"
                  label="Remarks"
                  value={state.formData.remarks}
                  onChange={handleFormChange}
                />
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Card>
    </ContentLayout>
  );
};
