import * as React from "react";
import {
    CheckBoxListDialog,
    CustomeDialog,
    CustomTable,
    DeleteModal,
    DraggableReactTable,
    Loader,
    usePermission,
} from "../../../shared";
import { Button, Card, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import { useFavourites } from "../hooks";
import { sortItems } from "../../../utils";
import { GroupNameDialog } from "./GroupNameDialog";

export const UserFavouritesList = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { favoritesList, handleModal, onSaveUserFavGroup, onChangeUserFavGroup, onDeleteUserFavGroup, onUserFavOrderChange } = props
    const { isLoading, dataList, isOpen, isDelete } = favoritesList
    const isEditable = usePermission("user-modify");
    const isDeletable = usePermission("user-delete");
    const isCreate = usePermission("user-create");
    const columns = React.useMemo(
        () => [
            {
                Header: "Group Name",
                accessor: "name",
                sortType: (prev, curr, columnId) => {
                    return sortItems(prev, curr, columnId);
                },
                Cell: ({ value, row }) => {
                    return (
                        <div
                            className="table-taxt-link cursor"
                            onClick={() => {
                                navigate(`favourite/${row.original.id}`);
                            }}
                        >
                            <span className="link-text">{value}</span>
                        </div>
                    );
                },
            },
            {
                Header: "Created By",
                accessor: "user_name",
                Cell: ({ value, row }) => {
                    return (
                        <>
                            <div>{value}</div>
                            <Typography variant="caption" color="#808080">
                                {row.original.type}
                            </Typography>
                        </>
                    );
                },
            },
            {
                Header: "Action",
                disableGlobalFilter: true,
                id: "action",
                align: "right",
                disableSortBy: true,
                accessor: (row, index) => {
                    return (
                        <ActionButtons
                            deleteAction={() => { handleModal('fav-list-delete', true, row.id) }}
                            row={row}
                        />
                    );
                },
            },
        ],
        []
    );


    const ActionButtons = ({ deleteAction, row }) => {
        return (
            <div className="action-buttons">
                {isDeletable && (
                    <img
                        alt="delete"
                        onClick={() => {
                            deleteAction(row.id)
                        }}
                        src="/icons/ic_delete.svg"
                    />
                )}
                <img
                    onClick={() => {
                        navigate(`favourite/${row.id}`);
                    }}
                    alt="details"
                    src="/icons/arrow_forward.svg"
                />

            </div>
        );
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <DraggableReactTable
                columns={columns}
                data={dataList}
                tableTitle="Favourites"
                searchBar={false}
                onRowOrderChange={onUserFavOrderChange}
                headerRightContent={
                    <>
                        {isCreate &&
                            <Button
                                type="button"
                                size="medium"
                                variant="text"
                                startIcon={<AddIcon />}
                                onClick={() => handleModal("fav-add", true)}
                            >
                                New Group
                            </Button>
                        }
                    </>
                }
            />
            <CustomeDialog
                open={isOpen}
                handleClose={() => handleModal("fav-add", false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: { width: "423px", height: "235px", p: "16px" },
                }}
                title="Group"
                content={
                    <GroupNameDialog
                        onChangeFavGroup={onChangeUserFavGroup}
                        state={favoritesList}
                        onSaveFavGroup={onSaveUserFavGroup}
                        onUpdateFavGroup={() => { }}
                        isEdit={false}
                    />
                }
            />
            <DeleteModal
                open={isDelete}
                handleClose={() => handleModal('fav-list-delete', false, null)}
                onConfirm={() => onDeleteUserFavGroup()}
            />
        </>
    );
};
