import React, { useEffect, useContext } from "react";
import moment from "moment";
import { useImmer } from "use-immer";
import {
  getDepositSlip,
  getDepositSlipById,
  getCashDetails,
  createDepositSlip,
  updateDepositSlip,
} from "..";
import { errorMessage, getStoreId, successMessage } from "../../../utils";
import { useDebounce } from "use-debounce";
import qs from "qs";
import { getStoresBankAccount } from "../../Stores";
import { useNavigate } from "react-router-dom";

export const useDepositSlip = ({
  isList = false,
  isDetailID = false,
  IsCreate,
  IsEdit,
}) => {
  const storeId = getStoreId();
  const navigate = useNavigate();
  const [state, setState] = useImmer({
    isLoading: true,
    dataList: [],
    isSaveButton: false,
    initialLoad: true,
    details: {
      aliasLoading: true,
      isLoading: true,
      dataList: {},
    },
    bankAccounts: {
      isLoading: true,
      dataList: [],
      allData: [],
    },
    filterApplied: {
      stores: [],
      date: {},
      all: [],
      searchText: null,
    },
    cashDetails: {
      isInit: false,
      isLoading: true,
      dataList: {
        cash: [],
        eod_exist: false,
        eod_total_cheque: "0",
        eod_terminal_status: [],
        deposit_details: {},
        existDepositSlip: false,
      },
    },
    updateData: {
      store_id: null,
      date: null,
      alias: "",
      account_name: "",
      bsb: "",
      account_number: "",
      total_cheque: 0,
      total_cash: 0,
      cheque: [],
      cash: [],
      variance: "",
      eod_total_cheque: "",
    },
    isApplyButton: true,
    isChanged: false,
    isFocus: false,
    httpStatusCodes: {
      details: "",
    },
  });

  const [debouncedText] = useDebounce(state.filterApplied.searchText, 1000);

  useEffect(() => {
    if (isList && state.isLoading) {
      HandleDepositSlipList();
    }
  }, [isList, state.isLoading]);

  useEffect(() => {
    if (isDetailID && state.details.isLoading) {
      getDepositSlipDetails(isDetailID);
    }
  }, [isDetailID, state.details.isLoading]);

  useEffect(() => {
    if (state.filterApplied.searchText !== null) {
      HandleDepositSlipList();
    }
  }, [debouncedText]);

  useEffect(() => {
    if (IsCreate && storeId) {
      setState((draft) => {
        draft.updateData.store_id = storeId;
      });
    }
  }, [IsCreate, storeId]);

  const HandleDepositSlipList = async () => {
    try {
      let IDs = state.filterApplied.stores.map((v) => {
        return v.value;
      });

      let params = {};

      if (state.filterApplied.searchText) {
        params = { ...params, search: state.filterApplied.searchText };
      }
      if (state.filterApplied.date && toString(state.filterApplied.date)) {
        params = { ...params, date: state.filterApplied.date.label };
      }
      if (IDs.length > 0) {
        params = { ...params, store_id: IDs };
      }

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };

      const res = await getDepositSlip(query);
      if (res.success) {
        setState((draft) => {
          draft.dataList = res.data;
          draft.isLoading = false;
        });
      }
    } catch (error) {
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const handleFilter = (e, value, type) => {
    if (type === "store") {
      setState((draft) => {
        draft.filterApplied.stores = value.map((v) => {
          return { ...v, type: "Store" };
        });
      });
    }
    if (type === "date") {
      setState((draft) => {
        draft.filterApplied.date = {
          value: e,
          label: moment(e).format("yyy-MM-DD"),
          type: "Date",
        };
      });
    }
  };

  useEffect(() => {
    setState((draft) => {
      draft.filterApplied.all = [
        ...state.filterApplied.stores,
        state.filterApplied.date,
      ].filter((el) => Object.keys(el).length);
    });
  }, [state.filterApplied.date, state.filterApplied.stores]);

  const handleFilterClear = (type, value) => {
    if (type === "single") {
      if (value.type === "Store") {
        setState((draft) => {
          draft.filterApplied.stores = draft.filterApplied.stores.filter(
            (e) => e.value !== value.value
          );
          draft.isLoading = true;
        });
      }
      if (value.type === "Date") {
        setState((draft) => {
          draft.filterApplied.date = {};
          draft.isLoading = true;
        });
      }
    } else {
      setState((draft) => {
        draft.filterApplied.all = [];
        draft.filterApplied.stores = [];
        draft.filterApplied.date = {};
        draft.isLoading = true;
      });
    }
  };

  const handleApplyFilter = () => {
    setState((draft) => {
      draft.isLoading = true;
    });
  };

  const handleSearch = (v) => {
    setState((draft) => {
      draft.filterApplied.searchText = v;
    });
  };
  const onSeachClear = () => {
    setState((draft) => {
      draft.filterApplied.searchText = "";
    });
  };

  const getDepositSlipDetails = async (Id) => {
    try {
      setState((draft) => {
        draft.details.isLoading = true;
        draft.isLoading = true;
      });
      const res = await getDepositSlipById(Id);
      if (res.success) {
        setState((draft) => {
          draft.details.dataList = res.data;
          draft.details.isLoading = false;
          draft.updateData = {
            ...res.data,
            cheque:
              res.data.cheques.length > 0
                ? res.data.cheques?.map((data) => {
                    return {
                      name: data.cheque_name,
                      number: data.cheque_number,
                      amount: data.cheque_amount,
                    };
                  })
                : [],
            cash: res.data.cashes.map((data) => {
              return {
                id: data.denomination_id,
                count: data.count,
              };
            }),
          };
          draft.cashDetails.dataList.cash = res.data.cashes.map((data) => {
            return {
              cash_type: data.denomination_id <= 6 ? "coin" : "cash",
              label: data.denomination_label,
              total_cash: data.total,
              total_count: data.count,
            };
          });
          draft.isLoading = false;
          draft.cashDetails.isLoading = IsEdit ? true : false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.details.isLoading = false;
        draft.isLoading = IsEdit ? false : true;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };

  const handleInputChange = (event, v, r) => {
    const name = event?.target.name;
    const value = event?.target.value;

    setState((draft) => {
      draft.updateData = {
        ...draft.updateData,
        [name]: value,
      };
      draft.isChanged = true;
    });
  };
  useEffect(() => {
    if (state.isChanged && state.updateData.store_id && state.updateData.date) {
      setState((draft) => {
        draft.isApplyButton = false;
      });
    }
  }, [state.updateData.store_id, state.updateData.date]);

  const handleChequeInputChange = (event, index) => {
    const name = event?.target.name;
    const value = event?.target.value;

    setState((draft) => {
      draft.updateData.cheque = [
        ...draft.updateData.cheque.map((data, i) =>
          i === index ? { ...data, [name]: value } : data
        ),
      ];
    });
  };

  useEffect(() => {
    if (state.updateData.store_id) {
      handleGetBankAccounts(state.updateData.store_id);
    }
  }, [state.updateData.store_id]);

  const handleGetBankAccounts = async (Id) => {
    try {
      if (IsEdit && state.initialLoad) {
        setState((draft) => {
          draft.initialLoad = false;
        });
      } else {
        setState((draft) => {
          draft.updateData.account_name = "";
          draft.updateData.account_number = "";
          draft.updateData.bsb = "";
          draft.updateData.alias = "";
          draft.bankAccounts.isLoading = true;
        });
      }

      const res = await getStoresBankAccount(Id);
      if (res.success) {
        setState((draft) => {
          draft.bankAccounts.dataList = res.data?.map((v) => {
            return { value: v.alias, label: v.alias };
          });
          draft.bankAccounts.allData = res.data;
          draft.bankAccounts.isLoading = false;
        });
        let data = res.data.find((v) => v.is_default === 1);
        if (IsCreate && data) {
          setState((draft) => {
            draft.updateData.account_name = data.name;
            draft.updateData.account_number = data.number;
            draft.updateData.bsb = data.bsb;
            draft.updateData.alias = data.alias;
            draft.bankAccounts.isLoading = true;
          });
        } else if (res.data.length === 1) {
          setState((draft) => {
            draft.updateData.account_name = res.data[0].name;
            draft.updateData.account_number = res.data[0].number;
            draft.updateData.bsb = res.data[0].bsb;
            draft.updateData.alias = res.data[0].alias;
            draft.bankAccounts.isLoading = true;
          });
        }
      }
    } catch (err) {
      setState((draft) => {
        draft.bankAccounts.isLoading = false;
      });
    }
  };

  useEffect(() => {
    if (state.updateData.alias && (IsCreate || IsEdit)) {
      let data = state.bankAccounts.allData.find(
        (v) => v.alias === state.updateData.alias
      );

      if (IsEdit && !state.initialLoad) {
        setState((draft) => {
          draft.updateData.account_name = data.name;
          draft.updateData.bsb = data.bsb;
          draft.updateData.account_number = data.number;
        });
      } else if (IsCreate) {
        setState((draft) => {
          draft.updateData.account_name = data.name;
          draft.updateData.bsb = data.bsb;
          draft.updateData.account_number = data.number;
        });
      }
    }
  }, [state.updateData.alias]);

  const handleAddCheque = () => {
    setState((draft) => {
      draft.updateData.cheque = [
        ...draft.updateData.cheque,
        {
          name: "",
          number: "",
          amount: "",
        },
      ];
    });
  };

  const handleDeleteCheque = (i) => {
    setState((draft) => {
      draft.updateData.cheque = draft.updateData.cheque.filter(
        (data, index) => index !== i
      );
    });
  };

  useEffect(() => {
    if (IsEdit && state.updateData.store_id && state.updateData.date) {
      handleGetCashDetails();
    }
  }, [state.updateData.store_id, state.updateData.date]);

  useEffect(() => {
    if (
      state.isFocus &&
      state.updateData.store_id &&
      state.updateData.date &&
      (IsEdit || IsCreate)
    ) {
      handleGetCashDetails();
    }
  }, [state.isFocus]);

  const handleGetCashDetails = async () => {
    try {
      setState((draft) => {
        draft.cashDetails.isLoading = true;
        draft.cashDetails.isInit = true;
      });
      let payload = {
        store_id: state.updateData.store_id,
        date: moment(state.updateData.date).format("YYYY-MM-DD"),
      };
      const res = await getCashDetails(payload);
      if (res.success) {
        if (res.data.eod_exist) {
          setState((draft) => {
            draft.updateData.cash = [
              ...res.data?.cash?.map((v) => {
                return { id: v.id, count: v.total_count };
              }),
            ];
            draft.updateData.total_cash = res.data.cash
              ?.map((data) => Number(data.total_cash))
              .reduce((pv, cv) => pv + cv, 0);
            draft.updateData.eod_total_cheque = res.data.eod_total_cheque;
            draft.updateData.variance =
              draft.updateData.cheque
                .map((data) => Number(data.amount))
                .reduce((pv, cv) => pv + cv, 0) - res.data.eod_total_cheque;
            draft.cashDetails.dataList = res.data;
            draft.cashDetails.isLoading = false;
            draft.isApplyButton = true;
          });
        } else {
          setState((draft) => {
            draft.updateData.cash = [];
            draft.updateData.total_cash = 0;
            draft.cashDetails.dataList = {
              cash: [],
              eod_exist: false,
              eod_total_cheque: "0",
            };
            draft.cashDetails.isLoading = true;
            draft.isApplyButton = true;
            draft.cashDetails.isInit = false;
          });
          errorMessage("End of Day not available");
        }
      }
    } catch (err) {
      setState((draft) => {
        draft.cashDetails.isLoading = true;
        draft.cashDetails.isInit = false;
      });
    }
  };

  //total cheque amount calculate

  useEffect(() => {
    if (IsCreate || IsEdit) {
      setState((draft) => {
        draft.updateData.total_cheque = draft.updateData.cheque
          .map((data) => Number(data.amount))
          .reduce((pv, cv) => pv + cv, 0);
        draft.updateData.variance =
          draft.updateData.cheque
            .map((data) => Number(data.amount))
            .reduce((pv, cv) => pv + cv, 0) - draft.updateData.eod_total_cheque;
      });
    }
  }, [state.updateData.cheque]);

  const onSaveDepositSlip = async () => {
    try {
      setState((draft) => {
        draft.isSaveButton = true;
      });
      const payload = {
        ...state.updateData,
        date: moment(state.updateData.date).format("YYYY-MM-DD"),
      };
      const res = await createDepositSlip(payload);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButton = false;
        });
        navigate(-1);
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.isSaveButton = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButton = false;
      });
    }
  };

  const onUpdateDepositSlip = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButton = true;
      });
      const payload = {
        ...state.updateData,
        date: moment(state.updateData.date).format("YYYY-MM-DD"),
      };
      const res = await updateDepositSlip(id, payload);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButton = false;
        });
        navigate(-1);
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.isSaveButton = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButton = false;
      });
    }
  };

  // User has switched back to the tab
  const onFocusTab = () => {
    setState((draft) => {
      draft.isFocus = true;
    });
  };

  const onBlurTab = () => {
    setState((draft) => {
      draft.isFocus = false;
    });
  };

  useEffect(() => {
    window.addEventListener("focus", onFocusTab);
    return () => {
      window.removeEventListener("focus", onFocusTab);
    };
  }, []);

  return {
    state,
    handleFilter,
    handleFilterClear,
    handleApplyFilter,
    handleSearch,
    onSeachClear,
    handleInputChange,
    handleAddCheque,
    handleDeleteCheque,
    handleChequeInputChange,
    onSaveDepositSlip,
    onUpdateDepositSlip,
    handleGetCashDetails,
    onBlurTab,
  };
};
