import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import { DepositSlipList, DepositSlipDetailsList, AddDepositSlip } from "..";

export const DepositSlip = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<DepositSlipList />} />
                <Route element={<ProtectedRoute allowedRoute={["deposit-slip-view"]} />}>
                    <Route path="/details/:id" element={<DepositSlipDetailsList />} />
                </Route>
                <Route
                    element={<ProtectedRoute allowedRoute={["deposit-slip-modify"]} />}
                >
                    <Route path="/details/:id/edit" element={<AddDepositSlip />} />
                </Route>
                <Route
                    element={<ProtectedRoute allowedRoute={["deposit-slip-create"]} />}
                >
                    <Route path="/create" element={<AddDepositSlip />} />
                </Route>
            </Routes>
        </>
    );
};
