import React, { useState } from "react";
import { Avatar, Button, Stack } from "@mui/material";
import { CustomTable, Loader } from "../../../shared";
import InputControl from "../../../shared/components/InputControl";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { sortItems } from "../../../utils";

export const UsageHistory = (prop) => {
  const { state, onChangeusageHistoryFilter, resetusageHistoryFilters } = prop;
  const navigate = useNavigate();
  const columns = React.useMemo(
    () => [
      {
        Header: "Barcode",
        accessor: "barcode",
      },
      {
        Header: "Used Date & Time",
        accessor: "order_date",
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              {row.original.customer_id === null ? (
                row.original.customer_name
              ) : (
                <div
                  className="table-taxt-and-icon-link cursor"
                  onClick={() =>
                    navigate(`/customers/details/${row.original.customer_id}`)
                  }
                >
                  <span className="link-text">
                    {row.original.customer_name}
                  </span>
                </div>
              )}
            </Stack>
          );
        },

        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Order Id",
        accessor: "order_number",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <div
                className="table-taxt-and-icon-link cursor"
                onClick={() =>
                  navigate(`/orders/details/${row.original.order_id}`)
                }
              >
                <span className="link-text">{row.original.order_number}</span>
              </div>
            </Stack>
          );
        },

        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
    ],
    []
  );

  return (
    <>
      {state.isBusy ? (
        <Loader />
      ) : (
        <CustomTable
          defaultSearch={false}
          filterContent={
            <>
              <InputControl
                type="table-search"
                name="searchText"
                placeholder="Search"
                size="small"
                value={state.usageHistoryfilters.searchText}
                onChange={(e) => onChangeusageHistoryFilter(e)}
              />
              <Button
                type="button"
                size="medium"
                variant="text"
                color="gray"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => resetusageHistoryFilters()}
              >
                <CloseIcon />
              </Button>
            </>
          }
          columns={columns}
          data={state?.usageHistory}
        />
      )}
    </>
  );
};
