import * as React from "react";
import { CustomTable } from "../../../shared";

export const AddressList = (props) => {
  const { state } = props;
  const columns = React.useMemo(
    () => [
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Unit Number",
        accessor: "unit_number",
      },
      {
        Header: "Street Number",
        accessor: "street_number",
      },
      {
        Header: "Street",
        accessor: "street",
      },
      {
        Header: "Suburb",
        accessor: "suburb",
      },
      {
        Header: "Postal Code",
        accessor: "postal_code",
      },
    ],
    []
  );
  return (
    <CustomTable
      rowsSelection={false}
      tableId="customer_addressList"
      columns={columns}
      data={state?.customersAddress}
    />
  );
};
