import * as React from "react";
import { Box, Button, Stack } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";
import { Loader } from "../../../shared";

export const DoordashSetupDialog = ({
  onChange,
  state,
  handleSetupDoordash,
}) => {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const onSubmit = () => {
    if (validator.current.allValid()) {
      handleSetupDoordash();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <Stack justifyContent="space-between" height="100%" gap={2} mt="32px">
      {state?.isBusy ? (
        <Loader />
      ) : (
        <>
          <InputControl
            name="requestor_email"
            label="Requestor Email"
            required
            onChange={onChange}
            value={state?.storeDetails?.requestor_email}
            error={validator.current.message(
              "Requestor Email",
              state?.storeDetails?.requestor_email,
              "required"
            )}
            helperText={validator.current.message(
              "Requestor Email",
              state?.storeDetails?.requestor_email,
              "required"
            )}
          />
          <InputControl
            name="merchant_decision_maker_email"
            label="Merchant Decision Maker Email"
            required
            onChange={onChange}
            value={state?.storeDetails?.merchant_decision_maker_email}
            error={validator.current.message(
              "Merchant Decision Maker Email",
              state?.storeDetails?.merchant_decision_maker_email,
              "required"
            )}
            helperText={validator.current.message(
              "Merchant Decision Maker Email",
              state?.storeDetails?.merchant_decision_maker_email,
              "required"
            )}
          />
          {/* <InputControl
        name="name"
        label="Doordash Store Id"
        sx={{
          width: "100%",
          bgcolor: "#fff",
        }}
        required
        // onChange={onChangeFavGroup}
        // value={name}
        // error={validator.current.message("Group Name", name, "required")}
        // helperText={validator.current.message("Group Name", name, "required")}
      /> */}
          <InputControl
            name="partner_store_name"
            label="Doordash Store Name"
            disabled
            value={state?.storeDetails?.partner_store_name}
          />
          <InputControl
            name="provider_type"
            label="Provider Type"
            disabled
            value={state?.storeDetails?.provider_type}
          />
          <InputControl
            name="requestor_first_name"
            label="Requestor First Name"
            disabled
            value={state?.storeDetails?.requestor_first_name}
          />
          <InputControl
            name="requestor_last_name"
            label="Requestor Last Name"
            disabled
            value={state?.storeDetails?.requestor_last_name}
          />

          <InputControl
            name="address_city"
            label="Address City"
            disabled
            value={state?.storeDetails?.address_city}
          />
          <InputControl
            name="address_state"
            label="Address State"
            disabled
            value={state?.storeDetails?.address_state}
          />
          <InputControl
            name="address_zip"
            label="Address Zip"
            disabled
            value={state?.storeDetails?.address_zip}
          />
          <InputControl
            name="address_line_1"
            label="Address Line 1"
            disabled
            value={state?.storeDetails?.address_line_1}
          />
          <Box
            sx={{
              position: "sticky",
              bottom: -1,
              backgroundColor: "#FFFFFF",
              boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Button
              onClick={onSubmit}
              sx={{ height: "40px", alignSelf: "end" }}
              variant="contained"
              fullWidth
              disabled={state?.isSaveButtonBusy}
            >
              Save
            </Button>
          </Box>
        </>
      )}
    </Stack>
  );
};
