import * as React from "react";
import {
  ContentLayout,
  CustomTable,
  CustomeDialog,
  DeleteModal,
  HeaderBreadcrumbs,
  Loader,
} from "../../../shared";
import { Box, Button, Card, Stack, Typography } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import AddIcon from "@mui/icons-material/Add";
import { CategoryProductDialog } from "../../../shared";
import { useTopSellingList } from "../hooks";

export const TopSellingList = () => {
  // Custom hook
  const {
    state,
    columns,
    breadcrumbsLink,
    handleProductSearch,
    handleToggleDeleteModal,
    handleTitleUpdatesMode,
    handleProductDialog,
    handleTitleChanges,
    handleCategorySelect,
    handleAddTopSellingProduct,
  } = useTopSellingList();

  const {
    isOpenDeleteDialog,
    topSellingProducts,
    isBusy,
    filteredProductsCategory,
    selectedItems,
    dialogOpen,
    isModalContentLoad,
    isEditMode,
    title,
  } = state;

  return (
    <>
      <ContentLayout
        title="Top Selling"
        breadcrumbs={<HeaderBreadcrumbs heading="" links={breadcrumbsLink} />}
      >
        {false ? (
          <Loader />
        ) : (
          <>
            <Card
              sx={{
                padding: "32px 18px",
                marginBottom: "20px",
                minHeight: "110px",
              }}
            >
              <Stack direction="row" gap="20px">
                <Box width="250px">
                  <Typography variant="subtitle1">Title</Typography>
                </Box>
                <InputControl
                  type="text"
                  label="Title"
                  value={title}
                  disabled={!isEditMode}
                  onChange={handleTitleChanges}
                />
                <Button
                  sx={{ minWidth: "65px" }}
                  onClick={() => handleTitleUpdatesMode(isEditMode)}
                >
                  {isEditMode ? "Save" : "Edit"}
                </Button>
              </Stack>
            </Card>

            <CustomTable
              data={topSellingProducts}
              columns={columns}
              searchBar={false}
              isLoading={isBusy}
              tableTitle="Products"
              onRowLink={(e) => `/products/details/${e.id}`}
              headerRightContent={
                <Button
                  type="button"
                  sx={{ minWidth: "141px" }}
                  startIcon={<AddIcon />}
                  onClick={handleProductDialog}
                >
                  Add Product
                </Button>
              }
            />
          </>
        )}
      </ContentLayout>

      {/* Dialog */}
      <CustomeDialog
        open={dialogOpen}
        handleClose={handleProductDialog}
        PaperProps={{
          sx: { width: "423px", height: "535px", p: "16px" },
        }}
        title="Select Products"
        content={
          isModalContentLoad ? (
            <Box
              sx={{
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Loader />
            </Box>
          ) : (
            <CategoryProductDialog
              Items={filteredProductsCategory}
              onSelect={handleCategorySelect}
              selectedItems={selectedItems}
              onSubmit={handleAddTopSellingProduct}
              onSearch={handleProductSearch}
              existedProductList={topSellingProducts}
            />
          )
        }
      />
      {/* Handle delete dialog */}
      <DeleteModal
        open={isOpenDeleteDialog}
        handleClose={handleToggleDeleteModal}
        onConfirm={() => handleToggleDeleteModal({ confirm: true })}
      />
    </>
  );
};
