import {
  ContentLayout,
  DeleteModal,
  DraggableReactTable,
} from "../../../shared";
import * as React from "react";
import { Button, Chip, Stack, Avatar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import InputControl from "../../../shared/components/InputControl";
import { useCategory } from "../hooks";
import { sortItems } from "../../../utils";

export const CategoryList = () => {
  const {
    state,
    onChangeFilter,
    resetFilters,
    isCreateVisible,
    isEditable,
    isDeletable,
    isVisible,
    toggleModal,
    handleDeleteCategory,
    globalData,
    onCategoryOrderChange,
    debouncedText,
  } = useCategory({
    isCategory: true,
    isProduct: false,
  });
  const navigate = useNavigate();
  const { filters } = state;

  const ActionButtons = ({ id, deleteAction, product_count }) => {
    return (
      <div className="action-buttons">
        {isDeletable && (
          <img
            alt="delete"
            onClick={() => {
              product_count < 1 && deleteAction();
            }}
            className={` ${product_count > 0 && "disabled-img"}`}
            src="/icons/ic_delete.svg"
          />
        )}
        {isVisible && (
          <Link to={`/category/details/${id}`}>
            <img alt="details" src="/icons/arrow_forward.svg" />
          </Link>
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <Avatar sx={{ bgcolor: row.original.color }}>
                <img src={row.original.icon} className="avater-img" />
              </Avatar>
              <div
                className="table-taxt-and-icon-link cursor"
                onClick={() => navigate(`/category/details/${row.original.id}`)}
              >
                <span className="link-text">{row.original.name}</span>
              </div>
            </Stack>
          );
        },

        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Product Count",
        accessor: "product_count",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "status",
        disableGlobalFilter: true,
        sortInverted: true,
        Cell: ({ value }) => {
          return (
            <Chip
              label={value === 1 ? "Active" : "Inactive"}
              color="success"
              variant="outlined"
              size="small"
              disabled={value === 1 ? false : "filled"}
            />
          );
        },
      },
      {
        Header: "Action",
        disableGlobalFilter: true,
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              editAction
              deleteAction={() => {
                toggleModal(row.id);
              }}
              status={row.status}
              product_count={row.product_count}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <ContentLayout
        title="Category"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("create")}
              >
                Add Category
              </Button>
            )}
          </>
        }
      >
        <DraggableReactTable
          columns={columns}
          data={state.category || []}
          defaultSearch={false}
          searchBar={true}
          onRowOrderChange={onCategoryOrderChange}
          disableDrag={
            (debouncedText !== "" && filters.searchText !== "") ||
            filters.status !== ""
          }
          isLoading={state?.isBusy}
          maxHeight="202px"
          filterContent={
            <>
              <InputControl
                type="table-search"
                name="searchText"
                placeholder="Search"
                size="small"
                value={filters.searchText}
                onChange={(e) => onChangeFilter(e)}
              />
              <InputControl
                type="select"
                name="status"
                label="Status"
                optionValue={globalData.status ?? []}
                width="250px"
                onChange={(e) => onChangeFilter(e)}
                value={filters.status}
              />

              <Button
                type="button"
                size="medium"
                variant="text"
                color="gray"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => resetFilters()}
              >
                <CloseIcon />
              </Button>
            </>
          }
        />
      </ContentLayout>
      <DeleteModal
        open={state.isOpen}
        handleClose={() => toggleModal()}
        onConfirm={() => handleDeleteCategory()}
      />
    </>
  );
};
