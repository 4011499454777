import { Button, Card, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
// import { OrdersTable } from "../components";
import { useParams, useLocation } from "react-router-dom";
// import { useWastage } from "../hooks/useWastage";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { OrdersTable } from "../../Wastage";

import { LoyaltyTable } from "../../LoyaltyPoints/components";

export const CustomersPointTransaction = () => {
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const printRef = useRef();
  const { id } = useParams();
  //   const { state } = useWastage({ wastageId: id });

  const customerData = [
    {
      head: "Customer Details",
      name: "Jane Cooper",
      email: "Jane Cooper",
      phone: "+62 8934839748",
    },
    {
      head: "Store Details",
      name: "Banjo's Beerwah",
      Cashier: "Cashier: Darlene Robertson",
    },
    {
      head: "Order Date",
      date: "12/072022, 09:00 AM",
    },
    {
      head: "Pickup Date",
      date: "12/07/2022, 12:00 AM",
    },
  ];
  return (
    <>
      <ContentLayout
        title="BANJO6541"
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Customer", href: "/customers" },
                { name: "Jane Cooper", href: `` },
                { name: "BANJO6541" },
              ]}
            />
          </>
        }
      >
        {false ? (
          <Loader />
        ) : (
          <>
            <Card sx={{ marginBottom: "20px" }} ref={printRef}>
              <Grid
                container
                sx={{
                  height: "83px",
                  padding: "32px 24px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    fontSize={16}
                    sx={{
                      lineHeight: "19px",
                    }}
                  >
                    Details
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid
                container
                sx={{
                  padding: "24px 16px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {customerData.map((item) => {
                  return (
                    <Grid item>
                      <Box sx={{ padding: "10px", width: "212px" }}>
                        <Typography
                          fontWeight={500}
                          fontSize={13}
                          sx={{
                            lineHeight: "15px",
                            marginBottom: "10px",
                          }}
                        >
                          {item.head}
                        </Typography>
                        {Object.keys(item).map((key) => {
                          if (key !== "head" && item[key]) {
                            return (
                              <Typography
                                fontWeight={400}
                                fontSize={13}
                                sx={{
                                  lineHeight: "15px",
                                  marginBottom: "6px",
                                }}
                              >
                                {key === "Cashier"
                                  ? `Cashier:  ${item[key]}`
                                  : item[key]}
                              </Typography>
                            );
                          }
                        })}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>

              <Divider />
              <LoyaltyTable />
              <Divider />
            </Card>
          </>
        )}
      </ContentLayout>
    </>
  );
};
