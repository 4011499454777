import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage, infoMessage } from "../../../utils";
import {
  createTemplate,
  editMenuLogin,
  getMenuLogList,
  showChannel,
  uploadAllMenulog,
  uploadPushMenulog,
} from "../api/menuLogApi";
import { usePermission, useTabs } from "../../../shared";
import { AppContext } from "../../../store";
import { useMenuTemplate } from "../../MenuTemplate";

export const useMenuLog = ({ isList }) => {
  const isEditable = usePermission("menulog-modify");
  const { appState } = useContext(AppContext);
  const { menulog: currentTab } = appState.currentTabs;
  const { setCurentTab } = useTabs();
  const { state: stateMenuTempalte } = useMenuTemplate({ isList: true });
  const optionsMenuTemplate = stateMenuTempalte?.menuTemplates?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));
  const [state, setState] = useImmer({
    isBusy: false,
    isAllSaveButton: false,
    isSaveButton: false,
    isSaveButtonBusy: false,
    menuLogData: [],
    data: {
      store_id: "",
      menulog: 0,
    },
    dataMenu: {
      channel_id: 103,
      menu_id: "",
    },
  });
  useEffect(() => {
    if (isList) {
      getMenuLogStore();
    }
  }, [isList]);
  const getMenuLogStore = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getMenuLogList();
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.menuLogData = res.data;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  const handleAllMenu = async () => {
    try {
      setState((draft) => {
        draft.isAllSaveButton = true;
      });
      const res = await uploadAllMenulog();
      if (res.status === 200) {
        setState((draft) => {
          draft.isAllSaveButton = false;
        });
        infoMessage(res.data.message);
      } else {
        setState((draft) => {
          draft.isAllSaveButton = false;
        });
        errorMessage("Error Occured!");
      }
    } catch (err) {
      setState((draft) => {
        draft.isAllSaveButton = false;
      });
      errorMessage("Error Occured!");
    }
  };
  const handlePushMenu = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButton = true;
      });
      const res = await uploadPushMenulog(id);
      infoMessage(res.data.message);
      if (res.status === 200) {
        setState((draft) => {
          draft.isSaveButton = false;
        });
      } else {
        setState((draft) => {
          draft.isSaveButton = false;
        });
        errorMessage("Error Occured!");
      }
    } catch (err) {
      setState((draft) => {
        draft.isSaveButton = false;
      });
      errorMessage("Error Occured!");
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data.ubereats_store_id = value;
    });
  };
  const handleUpdate = async (value, e, type) => {
    let payload = {
      store_id: value?.store_id,
      menulog: e ? 1 : 0,
    };
    try {
      const res = await editMenuLogin(payload);
      if (res.success) {
        successMessage(res.message);
        getMenuLogStore();
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((draft) => {
      draft.dataMenu[name] = value;
    });
  };
  const handleSubmit = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await createTemplate(state.dataMenu);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const getTemplatesDetails = async (channel_id) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await showChannel(channel_id);
      if (res.data) {
        setState((draft) => {
          draft.dataMenu.menu_id = res?.data?.id;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (currentTab === "2") {
      getTemplatesDetails(state?.dataMenu?.channel_id);
    }
  }, [currentTab]);
  return {
    state,
    handleAllMenu,
    handlePushMenu,
    onChange,
    handleUpdate,
    isEditable,
    currentTab,
    setCurentTab,
    handleInputChange,
    optionsMenuTemplate,
    handleSubmit,
  };
};
