import {
  ContentLayout,
  DeleteModal,
  usePermission,
  PageNotFound,
  HeaderBreadcrumbs,
} from "../../../shared";
import * as React from "react";
import { Button, Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  BasicDetailsList,
  StoresList,
  RolesList,
  SecurityList,
  UserFavouritesList
} from "../components";
import { useParams } from "react-router-dom";
import { useAdminUsers } from "..";

export const AdminUsersDetails = () => {
  const isDeletable = usePermission("user-delete");

  const { id } = useParams();
  const {
    state,
    globalData,
    handleModal,
    handleResetPassword,
    handlePasswordCopy,
    HandleFilterStores,
    handleStorsSelect,
    handleAssignStorestoUser,
    HandleFilterRoles,
    handleRolesSelect,
    handleAssignRolestoUser,
    handleDeleteStore,
    handleDeleteRole,
    handleDeleteUserDetails,
    currentTab,
    setCurentTab,
    resetPassCode,
    isStoreAdmin,
    onSaveUserFavGroup,
    onChangeUserFavGroup,
    onDeleteUserFavGroup,
    onUserFavOrderChange,
    handleSelectAllStores,
    handleSelectAllRoles
  } = useAdminUsers({
    isDetailsId: id,
  });
  const {
    basicDetails,
    storesDetails,
    rolesDetails,
    resetPassword,
    resetPasscodeData,
    favoritesList
  } = state;
  const handleChange = (event, newValue) => {
    setCurentTab("adminUsers", newValue);
  };

  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }

  return (
    <>
      <ContentLayout
        title={basicDetails.details?.name || ""}
        active={basicDetails.details?.status === 1 ? "Active" : "Inactive"}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: isStoreAdmin ? "/store-admin" : "/" },
              {
                name: "Admin User",
                href: isStoreAdmin
                  ? "/store-admin/admin-users"
                  : "/admin-users",
              },
              {
                name: basicDetails.details?.name || "",
              },
            ]}
          />
        }
        rightContent={
          <>
            {isDeletable && (
              <Button
                type="button"
                size="medium"
                variant="text"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => handleModal("detail-delete", true)}
              >
                <img alt="delete" src="/icons/ic_delete_blue.svg" />
              </Button>
            )}
          </>
        }
      >
        <TabContext value={currentTab}>
          <Box className="sticky-Tab" sx={{ borderBottom: 1, borderColor: "divider", top: "104px", }}>
            <TabList onChange={handleChange}>
              <Tab label="Basic Details" value="1" />
              {!isStoreAdmin && <Tab label="Stores" value="2" />}
              <Tab label="Roles" value="3" />
              <Tab label="Security" value="4" />
              {isStoreAdmin && <Tab label="Favourites" value="5" />}
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            <BasicDetailsList
              userDetails={basicDetails}
              isStoreAdmin={isStoreAdmin}
              timezones={globalData.timezones ?? []}
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            <StoresList
              storesDetails={storesDetails}
              handleModal={handleModal}
              HandleFilterStores={HandleFilterStores}
              handleStorsSelect={handleStorsSelect}
              handleAssignStorestoUser={handleAssignStorestoUser}
              handleDeleteStore={handleDeleteStore}
              handleSelectAllStores={handleSelectAllStores}
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="3">
            <RolesList
              rolesDetails={rolesDetails}
              handleModal={handleModal}
              HandleFilterRoles={HandleFilterRoles}
              handleRolesSelect={handleRolesSelect}
              handleAssignRolestoUser={handleAssignRolestoUser}
              handleDeleteRole={handleDeleteRole}
              isStoreAdmin={isStoreAdmin}
              setCurentTab={setCurentTab}
              handleSelectAllRoles={handleSelectAllRoles}
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="4">
            <SecurityList
              handleResetPassword={handleResetPassword}
              resetPassword={resetPassword}
              basicDetails={basicDetails}
              handleModal={handleModal}
              handlePasswordCopy={handlePasswordCopy}
              resetPassCode={resetPassCode}
              resetPasscodeData={resetPasscodeData}
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="5">
            <UserFavouritesList
              favoritesList={favoritesList}
              handleModal={handleModal}
              onSaveUserFavGroup={onSaveUserFavGroup}
              onChangeUserFavGroup={onChangeUserFavGroup}
              onDeleteUserFavGroup={onDeleteUserFavGroup}
              onUserFavOrderChange={onUserFavOrderChange}
            />
          </TabPanel>
        </TabContext>
      </ContentLayout >
      <DeleteModal
        open={basicDetails.isOpen}
        handleClose={() => handleModal("detail-delete", false)}
        onConfirm={handleDeleteUserDetails}
      />
    </>
  );
};
