import { API } from "../../../utils";

export const getMenuLogList = async () => {
  const res = await API.get(`menulog/stores`);
  return res.data;
};
export const uploadAllMenulog = async () => {
  const res = await API.get(`push_menu_menulog`);
  return res;
};
export const uploadPushMenulog = async (id) => {
  const res = await API.get(`push_menu_ubereats/${id}`);
  return res;
};
export const editMenuLogin = async (payload) => {
  const res = await API.put(`menulog/stores`, payload);
  return res.data;
};

export const createTemplate = async (payload) => {
  const res = await API.post(`menus/add-channel`, payload);
  return res.data;
};
export const showChannel = async (channel_id) => {
  const res = await API.get(`menus/show-channel/${channel_id}`);
  return res.data;
};
