import { API } from "../../../utils";

export const getAccountList = async (payload) => {
  const res = await API.get(`pl_bs_accounts`, payload);
  return res.data;
};
export const getListById = async (id) => {
  const res = await API.get(`pl_bs_accounts/${id}`);
  return res.data;
};

export const getPLReport = async (payload) => {
  const res = await API.get(`pl_report`, payload);
  return res.data;
};
export const getBSReport = async (payload) => {
  const res = await API.get(`bs_report`, payload);
  return res.data;
};

export const uploadExcel = async (payload) => {
  const res = await API.post(`pl_bs_accounts`, payload);
  return res.data;
};
export const updateExcel = async (payload, id) => {
  const res = await API.post(`pl_bs_accounts/${id}`, payload);
  return res.data;
};

export const downloadReport = async (id) => {
  const res = await API.get(`pl_bs_accounts/${id}/download`, {
    responseType: "blob",
  });
  return res;
};
