import { Typography } from "@mui/material";
import * as React from "react";
import { Loader } from "../../../shared";
import { PriceLevelCard } from "../../Stores";

export const ProductPriceLevel = (props) => {
  const { data, HandlePriceLevelChange, handleUpdateProductPricelevel } = props;

  return (
    <>
      {data.isLoading ? (
        <Loader />
      ) : data.dataList.length > 0 ? (
        data.dataList.map((datas, index) => (
          <PriceLevelCard
            isSaveButton={data?.isSaveButton}
            data={datas}
            staticData={data.staticDataList[index]}
            index={index}
            HandlePriceLevelChange={HandlePriceLevelChange}
            handleUpdateProductPricelevel={handleUpdateProductPricelevel}
          />
        ))
      ) : (
        <Typography mt="20px">No Price Level</Typography>
      )}
    </>
  );
};
