import React from "react";
import { CustomTable, DeleteModal, usePermission } from "../../../shared";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { sortItems } from "../../../utils";
import { usePosRolesList } from "../..";

export default function PosRolesList({ setCurrentTab, isStoreAdmin }) {
  const { state, handleCreateRole, handleDeletePosRole, toggleDeleteDialog } =
    usePosRolesList();
  const isCreatable = usePermission("pos-role-create");
  const isDeletable = usePermission("pos-role-delete");

  const ActionButtons = ({ id, row, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {!isStoreAdmin && (
          <img
            alt="delete"
            src="/icons/ic_delete.svg"
            title="Delete Role"
            onClick={isDeletable ? () => deleteAction(row) : undefined}
            className={isDeletable ? undefined : "disabled-img"}
          />
        )}
        <Link to={`details/${id}`}>
          <img alt="details" src="/icons/arrow_forward.svg" />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Role Names",
        accessor: "display_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Members",
        disableSortBy: true,
        id: "count",
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return (
            <div className="members-count">
              <img src="/icons/ic_people.svg" alt="people" />
              {row.member_count}
            </div>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        accessor: (row) => {
          return (
            <ActionButtons
              id={row.id}
              row={row}
              editAction={() => {}}
              deleteAction={toggleDeleteDialog}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <CustomTable
        columns={columns}
        data={state.roles.list}
        isLoading={state.roles.isLoading}
        tableTitle={isStoreAdmin ? "" : "POS"}
        tableId="POSAdminRoles"
        headerRightContent={
          isCreatable ? (
            <Button
              type="button"
              size="medium"
              variant="contained"
              startIcon={<Add />}
              onClick={handleCreateRole}
            >
              New Roles
            </Button>
          ) : undefined
        }
        onRowLink={(row) => `pos/details/${row.id}`}
        onRowAction={() => setCurrentTab("rolesAndPermission", "3")}
      />
      <DeleteModal
        open={state.isDeleteOpenModal}
        handleClose={toggleDeleteDialog}
        onConfirm={handleDeletePosRole}
      />
    </React.Fragment>
  );
}
