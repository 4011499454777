import {
  ContentLayout,
  HeaderBreadcrumbs,
  PageNotFound,
} from "../../../shared";
import * as React from "react";
import { Button, Stack, Card, Container, Typography } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useStateList } from "../hooks";
import SimpleReactValidator from "simple-react-validator";
import { useParams } from "react-router-dom";

export const CreateOrEditState = () => {
  const { id } = useParams();
  const updateMode = id ? true : false;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const { onChange, state, onSaveState, onUpdateState } = useStateList({
    isState: false,
    isSuburb: false,
    stateId: id,
  });

  const { name } = state.stateDetails.data;

  const onSubmit = () => {
    if (validator.current.allValid()) {
      updateMode ? onUpdateState(id) : onSaveState();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  if (state.httpStatusCodes.stateDetails === 404) {
    return <PageNotFound />;
  }
  return (
    <>
      <ContentLayout
        title={updateMode ? state.stateDetails.stateName : "New State"}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "State & Suburb", href: "/state-and-suburb" },
              { name: updateMode ? state.stateDetails.stateName : "New State" },
            ]}
          />
        }
        rightContent={
          <>
            <Button
              onClick={() => onSubmit()}
              type="button"
              size="medium"
              variant="contained"
              disabled={state.stateDetails.isSaveButtonBusy}
            >
              {updateMode ? "Update" : "Save"}
            </Button>
          </>
        }
      >
        <Card sx={{ p: "32px 24px" }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 3, sm: 24 }}
          >
            <Typography variant="subtitle1" fontWeight={600}>
              Details
            </Typography>
            <Container>
              <InputControl
                name="name"
                value={name}
                label="State Name"
                required
                onChange={(e) => onChange(e, "stateDetails")}
                error={validator.current.message(
                  "State name",
                  name,
                  "required"
                )}
                helperText={validator.current.message(
                  "State name",
                  name,
                  "required"
                )}
              />
            </Container>
          </Stack>
        </Card>
      </ContentLayout>
    </>
  );
};
