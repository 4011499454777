import { Box, Skeleton, Stack } from "@mui/material";
import * as React from "react";
export const CardsLoader = (props) => {

    return (
        <Stack
            sx={{
                flexDirection: "row",
                rowGap: "24px",
                columnGap: "38px",
                mb: "24px",
            }}
        >
            {[...Array(4)]?.map((value, i) => (
                <Skeleton variant="rounded" width="25%" height={88} />
            ))}
        </Stack>
    );
};
