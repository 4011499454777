import { Box, FormControl, RadioGroup } from "@material-ui/core";
import { Stack, Button } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useNavigate } from "react-router-dom";

export const Header = ({
  state,
  onChange,
  onSaveGrid,
  onUpdateGrid,
  id,
  validator,
  forceUpdate,
}) => {
  const navigate = useNavigate();
  const {
    isSaveButtonBusy,
    data: { grid_view, name },
  } = state;
  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdateGrid() : onSaveGrid();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "24px 32px",
        borderBottom: "1px solid var(--gray-90, #E6E6E6)",
        gap: "32px",
      }}
    >
      <Box sx={{ width: "30%" }}>
        <InputControl
          label="Template Name"
          value={name}
          name="name"
          onChange={onChange}
          error={validator.current.message("Template Name", name, "required")}
          helperText={validator.current.message(
            "Template Name",
            name,
            "required"
          )}
        />
      </Box>
      <Box
        sx={{
          borderLeft: "1px solid #E6E6E6",
          padding: "0 32px",
          display: "flex",
          marginRight: "auto",
        }}
      >
        <Box
          sx={{
            border: "1px solid #E6E6E6",
            padding: "0 12px",
            borderRadius: "8px",
            marginRight: "12px",
          }}
        >
          <InputControl
            type="radio-button"
            name="grid_view"
            label="Image View"
            checked={grid_view === "image"}
            onChange={() =>
              onChange({
                target: { value: "image", name: "grid_view" },
              })
            }
          />
        </Box>
        <Box
          sx={{
            border: "1px solid #E6E6E6",
            padding: "0 12px",
            borderRadius: "8px",
          }}
        >
          <InputControl
            type="radio-button"
            label="Text View"
            name="grid_view"
            checked={grid_view === "text"}
            onChange={() =>
              onChange({
                target: { value: "text", name: "grid_view" },
              })
            }
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "16px" }}>
        <Button
          type="button"
          size="medium"
          disabled={isSaveButtonBusy}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Button
          type="button"
          size="medium"
          variant="contained"
          disabled={isSaveButtonBusy}
          onClick={onSubmit}
        >
          {id ? "Update" : "Save"}
        </Button>
      </Box>
    </Stack>
  );
};
