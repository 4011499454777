import {
  Box,
  Chip,
  Button,
  Typography,
  Card,
  Stack,
  CardHeader,
  CardContent,
} from "@mui/material";
import React from "react";
import {
  ContentLayout,
  CustomDetailsList,
  CustomeDialog as CustomDialog,
  HeaderBreadcrumbs,
  Loader,
} from "../../../shared";
import { useParams } from "react-router";
import InputControl from "../../../shared/components/InputControl";
import optInPic from "../../../assets/images/Icons/ic_tick_filled_blue.svg";
import optOutPic from "../../../assets/images/Icons/ic_cancel-opt-out.svg";
import yellowStar from "../../../assets/images/Icons/star-yellow.svg";
import grayStar from "../../../assets/images/Icons/star-gray.svg";
import { useReviewDetails } from "../hooks/useReviewDetails";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import "react-photo-view/dist/react-photo-view.css";
import { PhotoProvider, PhotoView } from "react-photo-view";

export const ReviewDetails = () => {
  const { id } = useParams();
  const {
    state,
    chipDetails,
    detailsColumn,
    handleOpenDialog,
    handleImageHover,
    breadcrumbsLinks,
    handleCloseDialog,
    rejectionValidator,
    isReviewModifiable,
    isReviewDeletable,
    handleDeleteReview,
    handleRejectionSubmit,
    handleReviewStatusChange,
    handleRejectionChanges,
    downloadImage,
    handleReviewPublishedStatusChange,
  } = useReviewDetails();
  const { isContentLoading, reviewDetails, dialog, isButtonLoading } = state;
  const dialogDetails =
    dialog.name === "reject"
      ? {
          title: "Reject",
          content: (
            <Stack mt="35px" gap="25px" justifyContent="space-between">
              <Stack gap="16px">
                <Typography>Why do you want to reject the review?</Typography>
                <InputControl
                  type="textarea"
                  name="rejection_reason"
                  label="Reason*"
                  rows={5}
                  defaultValue={state.status.rejectReason}
                  onChange={handleRejectionChanges}
                  error={rejectionValidator.current.message(
                    "rejection_reason",
                    state.status.rejectReason,
                    "required"
                  )}
                  helperText={rejectionValidator.current.message(
                    "rejection_reason",
                    state.status.rejectReason,
                    "required"
                  )}
                />
              </Stack>
              <Stack direction="col" justifyContent="end" gap={2}>
                <Button
                  disabled={isButtonLoading}
                  variant="text"
                  onClick={handleCloseDialog}
                >
                  No
                </Button>
                <LoadingButton
                  loading={isButtonLoading}
                  variant="contained"
                  onClick={handleRejectionSubmit}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Stack>
          ),
        }
      : dialog.name === "unpublish"
      ? {
          title: "Unpublish",
          content: (
            <Stack
              mt="25px"
              gap="25px"
              height="100%"
              justifyContent="space-between"
            >
              <Typography>
                Are you sure you want to Unpublish the Review?
              </Typography>
              <Stack direction="col" justifyContent="end" gap={2}>
                <Button
                  disabled={isButtonLoading}
                  variant="text"
                  onClick={handleCloseDialog}
                >
                  No
                </Button>
                <LoadingButton
                  loading={isButtonLoading}
                  variant="contained"
                  onClick={() => handleReviewPublishedStatusChange()}
                >
                  Yes
                </LoadingButton>
              </Stack>
            </Stack>
          ),
        }
      : dialog.name === "publish"
      ? {
          title: "Publish",
          content: (
            <Stack
              mt="25px"
              gap="25px"
              height="100%"
              justifyContent="space-between"
            >
              <Typography>Do you want to Publish the review?</Typography>
              <Stack direction="col" justifyContent="end" gap={2}>
                <Button
                  disabled={isButtonLoading}
                  variant="text"
                  onClick={handleCloseDialog}
                >
                  No
                </Button>
                <LoadingButton
                  loading={isButtonLoading}
                  variant="contained"
                  onClick={() => handleReviewPublishedStatusChange()}
                >
                  Yes
                </LoadingButton>
              </Stack>
            </Stack>
          ),
        }
      : dialog.name === "approve"
      ? {
          title: "Approve",
          content: (
            <Stack
              mt="25px"
              gap="25px"
              height="100%"
              justifyContent="space-between"
            >
              <Typography>Do you want to Approve the review?</Typography>
              <Stack direction="col" justifyContent="end" gap={2}>
                <Button
                  disabled={isButtonLoading}
                  variant="text"
                  onClick={handleCloseDialog}
                >
                  No
                </Button>
                <LoadingButton
                  loading={isButtonLoading}
                  variant="contained"
                  onClick={() => handleReviewStatusChange(1)} // 1 for approve, reference from boot API
                >
                  Yes
                </LoadingButton>
              </Stack>
            </Stack>
          ),
        }
      : dialog.name === "remove"
      ? {
          title: "Remove",
          content: (
            <Stack
              mt="25px"
              gap="25px"
              height="100%"
              justifyContent="space-between"
            >
              <Typography>
                Are you sure you want to Remove the Review?
              </Typography>
              <Stack direction="col" justifyContent="end" gap={2}>
                <Button
                  disabled={isButtonLoading}
                  variant="text"
                  onClick={handleCloseDialog}
                >
                  No
                </Button>
                <LoadingButton
                  loading={isButtonLoading}
                  variant="contained"
                  onClick={() => handleDeleteReview({ reviewId: id })}
                >
                  Yes
                </LoadingButton>
              </Stack>
            </Stack>
          ),
        }
      : dialog.name === "approveAndPublish"
      ? {
          title: "Approve",
          content: (
            <Stack
              mt="25px"
              gap="25px"
              height="100%"
              justifyContent="space-between"
            >
              <Typography>
                Do you want to approve and publish the review?
              </Typography>
              <Stack direction="col" justifyContent="end" gap={2}>
                <Button
                  disabled={isButtonLoading}
                  variant="text"
                  onClick={handleCloseDialog}
                >
                  No
                </Button>
                <LoadingButton
                  loading={isButtonLoading}
                  variant="contained"
                  onClick={() => handleReviewStatusChange(1)} // 1 for approve, reference from boot API
                >
                  Yes
                </LoadingButton>
              </Stack>
            </Stack>
          ),
        }
      : null;

  return (
    <>
      <PhotoProvider>
        <ContentLayout
          backButtonLocation="/reviews-and-ratings"
          breadcrumbs={<HeaderBreadcrumbs links={breadcrumbsLinks} />}
          title={id}
          chipType={"other"}
          statusContent={
            <Stack direction="row" gap={0.5}>
              {!isContentLoading && (
                <>
                  {reviewDetails.status !== 2 && (
                    <Box
                      component="img"
                      src={
                        reviewDetails.published_status === 1
                          ? optInPic
                          : optOutPic
                      }
                      alt={"opt-in-icon"}
                    />
                  )}
                  <Chip
                    label={chipDetails.label}
                    color={chipDetails.color}
                    variant="outlined"
                    size="small"
                    sx={{
                      border: "none !important",
                      background: chipDetails?.backgroundColor ?? "initial",
                    }}
                  />
                </>
              )}
            </Stack>
          }
          rightContent={
            <React.Fragment>
              {isReviewModifiable && (
                <>
                  {/* Show reject,approve button */}
                  {reviewDetails.status === 0 && (
                    <React.Fragment>
                      <Button
                        type="button"
                        size="medium"
                        variant="text"
                        onClick={() => handleOpenDialog("reject")}
                      >
                        Reject
                      </Button>

                      <LoadingButton
                        type="button"
                        size="medium"
                        variant="contained"
                        onClick={() =>
                          handleOpenDialog(
                            reviewDetails.published_status === 1
                              ? "approve"
                              : "approveAndPublish"
                          )
                        }
                      >
                        Approve
                      </LoadingButton>
                    </React.Fragment>
                  )}

                  {/* Show unpublish button */}
                  {reviewDetails.status === 1 &&
                    reviewDetails.published_status === 1 && (
                      <Button
                        type="button"
                        size="medium"
                        variant="text"
                        onClick={() => handleOpenDialog("unpublish")}
                      >
                        Unpublish
                      </Button>
                    )}

                  {/* Show publish button */}
                  {reviewDetails.status === 1 &&
                    reviewDetails.published_status === 0 && (
                      <Button
                        type="button"
                        size="medium"
                        sx={{
                          minWidth: "59px",
                        }}
                        onClick={() => handleOpenDialog("publish")}
                      >
                        Publish
                      </Button>
                    )}
                </>
              )}

              {isReviewDeletable && (
                <Button
                  type="button"
                  size="medium"
                  variant="contained"
                  sx={{
                    width: "87px",
                    visibility: isContentLoading ? "hidden" : "visible",
                  }}
                  onClick={() => handleOpenDialog("remove")}
                >
                  Remove
                </Button>
              )}
            </React.Fragment>
          }
        >
          {isContentLoading ? (
            <Loader />
          ) : (
            <>
              <CustomDetailsList
                columns={detailsColumn}
                data={reviewDetails}
                title="Review Details"
              />
              <Card sx={{ p: "20px" }}>
                <CardHeader title="Content" sx={{ pl: 0 }} />
                <CardContent
                  sx={{
                    backgroundColor: "#EBEEEC",
                    borderRadius: "16px",
                  }}
                >
                  <Stack gap="12px">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack direction="column" gap="8px">
                        <Typography
                          fontWeight={400}
                          fontSize="20px"
                          lineHeight="26.4px"
                        >
                          {reviewDetails.customer_name}
                        </Typography>
                        <Stack direction="row" gap="8px">
                          <Stack direction="row" gap="6px">
                            {Array.from({ length: 5 }).map((_, index) => {
                              if (index < reviewDetails.rating) {
                                return (
                                  <img
                                    alt="star"
                                    src={yellowStar}
                                    key={"star-" + index}
                                  />
                                );
                              } else {
                                return (
                                  <img
                                    alt="star"
                                    src={grayStar}
                                    key={"star-" + index}
                                  />
                                );
                              }
                            })}
                          </Stack>
                        </Stack>
                      </Stack>
                      <Typography
                        color="#636363"
                        fontWeight={400}
                        fontSize="20px"
                        lineHeight="26.4px"
                      >
                        {moment(reviewDetails.created_at).format("DD-MM-YYYY")}
                      </Typography>
                    </Stack>

                    <Typography>{reviewDetails.review_text}</Typography>
                    <Stack direction="row" gap="22px">
                      {reviewDetails?.review_images?.map((image, index) => {
                        return (
                          <PhotoView key={index} src={image}>
                            <Box
                              key={"rew-img-" + index}
                              sx={{
                                position: "relative",
                                width: "150px",
                                height: "150px",
                                overflow: "hidden",
                                borderRadius: "8px",
                                cursor: "pointer",
                              }}
                            >
                              <Box
                                component="img"
                                src={image}
                                alt={"pro-img" + index}
                                sx={{
                                  objectFit: "cover",
                                  width: "100%",
                                  height: "100%",
                                }}
                                onMouseOver={() => handleImageHover(true)}
                                onMouseLeave={() => handleImageHover(false)}
                              />

                              <Box
                                component="span"
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                  display: state.showDownloadButton
                                    ? "block"
                                    : "none",
                                }}
                              >
                                <Button
                                  sx={{
                                    bgcolor: "#F7F7F7 !important",
                                    minWidth: "32px",
                                    width: "32px",
                                    height: "32px !important",
                                    border: "none !important",
                                    "&:hover": {
                                      bgcolor: "#c2c2c2 !important",
                                    },
                                  }}
                                  size="small"
                                  onMouseOver={() => handleImageHover(true)}
                                  onMouseLeave={() => handleImageHover(false)}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    downloadImage(image);
                                  }}
                                >
                                  <Box
                                    component="img"
                                    alt="download"
                                    src="/icons/ic_Import.svg"
                                  />
                                </Button>
                              </Box>
                            </Box>
                          </PhotoView>
                        );
                      })}
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </>
          )}
        </ContentLayout>
      </PhotoProvider>
      {/* Custom dialog */}
      {dialogDetails !== null && (
        <CustomDialog
          open={dialog.open}
          handleClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx: {
              width: dialogDetails.title === "Reject" ? "648px" : "423px",
              height: dialogDetails.title === "Reject" ? "350  px" : "182px",
              p: "16px",
            },
          }}
          title={dialogDetails.title}
          content={dialogDetails.content}
        />
      )}
    </>
  );
};
