import React from "react";
import { Route, Routes } from "react-router-dom";
import { AddOrEditQRCode, QRCodeList } from "./containers";
import { ProtectedRoute } from "../../shared";

export const QRCode = () => {
  return (
    <Routes>
      <Route path="/" element={<QRCodeList />} />
      <Route element={<ProtectedRoute allowedRoute={["qrcode-create"]} />}>
        <Route path="/create" element={<AddOrEditQRCode />} />
      </Route>

      <Route element={<ProtectedRoute allowedRoute={["qrcode-modify"]} />}>
        <Route path="/edit/:qrCodeId" element={<AddOrEditQRCode />} />
      </Route>
    </Routes>
  );
};
