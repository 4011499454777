import { Stack, Typography } from "@mui/material";
import * as React from "react";

export const StatsCards = (props) => {
  const { data } = props;
  const colors = [
    "#A04D3D",
    "#5E7360",
    "#564845",
    "#9A8260",
    "#A04D3D",
    "#5E7360",
    "#564845",
    "#9A8260",
  ];
  return (
    <Stack
      sx={{
        flexDirection: "row",
        rowGap: "24px",
        columnGap: "38px",
        flexWrap: "wrap",
        mb: "24px",
      }}
    >
      {data?.map((value, i) => (
        <Stack
          sx={{
            flexDirection: "row",
            gap: "24px",
            justifyContent: "space-between",
            backgroundColor: colors[i],
            borderRadius: "8px",
            padding: "16px",
            minWidth: "calc(25% - 28.5px)",
            color: "#fff",
            // flexGrow:'1'
          }}
        >
          <Stack gap="8px">
            <Typography variant="h5" lineHeight="29px">
              {value.type === "currency" ? "$" : ""}
              {value.current}
            </Typography>
            <Typography fontWeight="600" lineHeight="19px">
              {value.label}
            </Typography>
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              gap: "8px",
              height: "20px",
            }}
          >
            <img
              alt="upOrDown"
              style={{
                transform: `rotate(${value.progress < 0 ? 180 : 0}deg)`,
              }}
              src="/icons/up_and_down.svg"
            />
            <Typography lineHeight="19px">{value.progress}%</Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
