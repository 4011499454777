import React, { useEffect } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
  useExpanded,
} from "react-table";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableHead,
  TableContainer,
  TableSortLabel,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Box,
  Paper,
  Stack,
  Checkbox,
  IconButton,
  Chip,
  Button,
  TableFooter,
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { CustomDrawer, Loader } from "..";
import CloseIcon from "@mui/icons-material/Close";

export const CustomTable = (props) => {
  const {
    columns,
    data,
    searchBar = true,
    initialPageSize,
    initialPageIndex,
    minWidth = 800,
    maxHeight = "",
    rowsSelection = false,
    filterContent = null,
    tableTitle,
    headerRightContent = null,
    pagination = true,
    drawerOpen = false,
    drawerOnClose,
    bulkDelete = false,
    onBulkDelete = {},
    filterApplied = [],
    onFilterClear = {},
    onAllFilterClear = {},
    filterSection = null,
    defaultSearch = true,
    defaultFooter = false,
    completeFeature = true,
    hiddenColumns = [],
    rowProps = () => { },
    emptyMessage = "",
    subRow = null,
    footerContent = null,
    isLoading = false,
    rowClickNewTab = false,
    onRowLink = false,
    onRowAction = false,
    customGotoPage = false,
    customSetPageSize = false,
    tableId = null,
    getRowProps = () => ({}),
    filterPosition = "defaultTop",
    hideContents = false,
    resetPagination = false,
  } = props;

  const previousState = tableId
    ? JSON.parse(localStorage.getItem(`table_${tableId}`))
    : null;

  const {
    allColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    globalFilteredRows,
    setGlobalFilter,
    state,
    state: { pageIndex, pageSize },
    selectedFlatRows,
    rows,
    footerGroups,
  } = useTable(
    {
      columns,
      data,
      initialState:  previousState ?? { pageSize: 50 },
      getSubRows: (row) => row[subRow] || [],
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      autoResetPage: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    // ,
    (hooks) => {
      bulkDelete &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox
                classes={{ root: "custom-checkbox-root" }}
                color="primary"
                sx={{
                  marginLeft: "-10px",
                }}
                {...getToggleAllRowsSelectedProps()}
              />
            ),
            Cell: ({ row }) => (
              <Checkbox
                classes={{ root: "custom-checkbox-root" }}
                color="primary"
                sx={{
                  marginLeft: "-10px",
                }}
                {...row.getToggleRowSelectedProps()}
              />
            ),
            padding: "checkbox",
          },
          ...columns,
        ]);
    }
  );
  const tableData = pagination ? page : rows;
  useEffect(() => {
    if (tableId) {
      localStorage.setItem(`table_${tableId}`, JSON.stringify(state));
    }
  }, [state]);

  useEffect(() => {
    if (resetPagination) {
      gotoPage(0);
    }
  }, [resetPagination]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Paper className={completeFeature && "table-paper-container"}>
          <TableContainer sx={{ minWidth: minWidth, maxHeight: maxHeight }}>
            {tableTitle && (
              <CustomTableHeader
                tableTitle={tableTitle}
                headerRightContent={headerRightContent}
              />
            )}
            {searchBar && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                filterContent={filterContent}
                defaultSearch={defaultSearch}
              />
            )}
            {filterPosition === "defaultTop" && filterApplied.length > 0 && (
              <FiltersApplied
                data={filterApplied}
                onFilterClear={onFilterClear}
                onAllFilterClear={onAllFilterClear}
              />
            )}
            {filterSection && <FiltersBar filterSection={filterSection} />}
            {filterPosition === "defaultBottom" && filterApplied.length > 0 && (
              <FiltersApplied
                data={filterApplied}
                onFilterClear={onFilterClear}
                onAllFilterClear={onAllFilterClear}
              />
            )}

            {isLoading ? (
              <Box sx={{ padding: "10px" }}>
                <Loader />
              </Box>
            ) : (
              !hideContents && (
                <Table
                  {...getTableProps()}
                  sx={{
                    borderCollapse: "inherit",
                    overflow: "hidden",
                  }}
                >
                  <TableHead>
                    {headerGroups.map((headerGroup, i) => (
                      <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                          <TableCell
                            key={index}
                            sx={{
                              p: !column.padding && "10px",
                            }}
                            align={column?.align}
                            padding={column.padding || "normal"}
                            {...column.getHeaderProps(
                              bulkDelete && selectedFlatRows.length > 0
                                ? ""
                                : column.getSortByToggleProps()
                            )}
                          >
                            {bulkDelete &&
                              selectedFlatRows.length > 0 &&
                              index >= 1 ? (
                              <>
                                {index > 1 ? (
                                  <></>
                                ) : (
                                  <Typography
                                    variant="subtitle2"
                                    color="#1560D4"
                                    lineHeight="18px"
                                    onClick={() => {
                                      onBulkDelete(selectedFlatRows);
                                    }}
                                  >
                                    Delete
                                  </Typography>
                                )}
                              </>
                            ) : (
                              <>
                                {column.canSort ? (
                                  <TableSortLabel
                                    active={column.isSorted}
                                    direction={
                                      column.isSortedDesc ? "dsc" : "asc"
                                    }
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        height: "15px",
                                        width: "15px",
                                      },
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {column.render("Header")}
                                  </TableSortLabel>
                                ) : (
                                  <>{column.render("Header")}</>
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>
                  {preGlobalFilteredRows.length > 0 &&
                    globalFilteredRows.length > 0 ? (
                    <>
                      <TableBody {...getTableBodyProps()}>
                        {tableData.map((row, i) => {
                          prepareRow(row);
                          const link = onRowLink
                            ? onRowLink(row.original)
                            : false;
                          return (
                            <TableRow
                              key={i}
                              {...row.getRowProps(getRowProps(row))}
                              component={link ? Link : null}
                              to={link ? link : undefined}
                              onClick={() => {
                                onRowAction && onRowAction(row.original);
                              }}
                              target={rowClickNewTab ? "_blank	" : ""}
                              rel="noopener noreferrer"
                              sx={{
                                textDecoration: "none",
                                cursor:
                                  link || onRowAction ? "pointer" : "default",
                                "&:hover": {
                                  backgroundColor:
                                    link || onRowAction
                                      ? "rgba(55, 65, 81, 0.04)"
                                      : false,
                                },
                              }}
                            // {...row.getRowProps(rowProps && rowProps(row))}
                            >
                              {row.cells.map((cell, i) => {
                                const darkenStyle = row.original.Head // Change `someCondition` to your specific condition
                                  ? { fontWeight: 600, fontSize: "16px" }
                                  : { fontWeight: 400, fontSize: "14px" };
                                return (
                                  <TableCell
                                    key={i}
                                    align={cell.column?.align}
                                    padding={cell.column.padding || "normal"}
                                    sx={{
                                      borderBottom: "1px solid #E6E6E6",
                                      p: !cell.column.padding && "8px",
                                    }}
                                    {...cell.getCellProps()}
                                  >
                                    <Typography sx={{ ...darkenStyle }}>
                                      {cell.render("Cell")}
                                    </Typography>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                      {defaultFooter && (
                        <TableFooter>
                          {footerGroups.map((group) => (
                            <TableRow {...group.getFooterGroupProps()}>
                              {group.headers.map((cell, i) => (
                                <TableCell
                                  key={i}
                                  sx={{
                                    borderBottom: "1px solid #E6E6E6",
                                    padding: "8px",
                                  }}
                                  {...cell.getFooterProps()}
                                >
                                  <Typography
                                    color="black"
                                    fontWeight={400}
                                    fontSize="13px"
                                    sx={{ lineHeight: "16px", height: "17px" }}
                                  >
                                    {cell.render("Footer")}
                                  </Typography>
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableFooter>
                      )}
                    </>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={20}
                          sx={{ py: 2, backgroundColor: "#F7F7F7" }}
                        >
                          <Typography
                            align="center"
                            variant="body2"
                            color={"#666666"}
                          >
                            {emptyMessage ? (
                              <Stack
                                direction="row"
                                gap={1}
                                justifyContent="center"
                              >
                                <img alt="edit" src="/icons/icon_not.svg" />
                                {emptyMessage}
                              </Stack>
                            ) : (
                              "No matches found"
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              )
            )}
          </TableContainer>
          {isLoading ? null : (
            <>
              {pagination && !hideContents && (
                <TablePagination
                  rowsPerPageOptions={[8, 10, 20, 30, 40, 50]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={pageSize}
                  page={pageIndex}
                  onPageChange={(e, page) => {
                    customGotoPage && customGotoPage(page);
                    gotoPage(page);
                  }}
                  onRowsPerPageChange={(e) => {
                    customSetPageSize && customSetPageSize(e.target.value);
                    setPageSize(e.target.value);
                  }}
                />
              )}
              {footerContent && (
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    py: "12px",
                    px: "16px",
                    gap: "16px",
                    // borderBottom: "1px solid #E6E6E6",
                    height: "84px",
                  }}
                >
                  {footerContent}
                </Stack>
              )}
            </>
          )}
        </Paper>
      </Box>
      <CustomDrawer
        title="Column Option"
        open={drawerOpen}
        onClose={drawerOnClose}
      >
        <Stack p="24px 16px" gap="16px">
          {allColumns.map((column, index) => (
            <React.Fragment key={index}>
              {column.id === "selection" ? (
                ""
              ) : (
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="10px"
                  width="auto"
                  key={index}
                >
                  <Box
                    sx={{
                      border: "1px solid #CCCCCC",
                      borderRadius: "8px",
                      p: "14px",
                    }}
                    width="100%"
                  >
                    <Typography
                      lineHeight="19px"
                      color={column.isVisible ? "black" : "GrayText"}
                    >
                      {column.Header}
                    </Typography>
                  </Box>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      column.toggleHidden();
                    }}
                  >
                    {column.isVisible ? (
                      <img alt="eye" src="/icons/ic_eye.svg" />
                    ) : (
                      <img
                        alt="closed eye"
                        src="/icons/ic_close_eye.svg"
                        className="disabled"
                      />
                    )}
                  </IconButton>
                </Stack>
              )}
            </React.Fragment>
          ))}
        </Stack>
      </CustomDrawer>
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              selectedFlatRows: selectedFlatRows.map((row) => row.original),
            },
            null,
            2
          )}
        </code>
      </pre> */}
    </>
  );
};
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  filterContent,
  defaultSearch,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        py: "20px",
        px: "16px",
        gap: "16px",
        borderBottom: "1px solid #E6E6E6",
        position: "sticky",
        left: 0,
      }}
    >
      {defaultSearch && (
        <OutlinedInput
          sx={{
            height: "48px",
          }}
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder="Search"
          size="small"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon size="large" />
            </InputAdornment>
          }
          endAdornment={
            value && (
              <InputAdornment
                sx={{ cursor: "pointer" }}
                onClick={(e) => {
                  setValue("");
                  onChange("");
                }}
                position="end"
              >
                <CloseIcon size="large" />
              </InputAdornment>
            )
          }
          fullWidth
        />
      )}
      {filterContent}
    </Stack>
  );
}
export default GlobalFilter;

export function CustomTableHeader({ tableTitle, headerRightContent }) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        py: "12px",
        px: "16px",
        gap: "16px",
        borderBottom: "1px solid #E6E6E6",
        height: "84px",
      }}
    >
      <Typography gutterBottom align="center" variant="subtitle1">
        {tableTitle}
      </Typography>
      {headerRightContent}
    </Stack>
  );
}

export function FiltersApplied({ data, onFilterClear, onAllFilterClear }) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",

        py: "20px",
        px: "16px",
        gap: "16px",
        borderBottom: "1px solid #E6E6E6",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "12px",
        }}
      >
        {data.map((data, index) => (
          <Chip
            key={index}
            label={
              <Typography variant="body2" fontSize="14px">
                {data.type}: {data.label}
              </Typography>
            }
            onDelete={() => onFilterClear(data)}
            sx={{
              backgroundColor: "#E6E6E6",
              height: "30px",
              "& .MuiChip-deleteIcon": {
                color: "#808080",
              },
            }}
          />
        ))}
      </Stack>

      <Button
        color="info"
        sx={{ width: "81px", minWidth: "81px", height: "30px" }}
        onClick={onAllFilterClear}
      >
        Clear All
      </Button>
    </Stack>
  );
}

export function FiltersBar({ filterSection }) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        py: "20px",
        px: "16px",
        gap: "16px",
        borderBottom: "1px solid #E6E6E6",
      }}
    >
      {filterSection}
    </Stack>
  );
}
