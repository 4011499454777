import * as React from "react";
import { CustomTable, DeleteModal, usePermission } from "../../../shared";
import { Link } from "react-router-dom";
import { Box, Chip, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { sortItems } from "../../../utils";
import AddIcon from "@mui/icons-material/Add";

export const TaxGroupList = (props) => {
  const isDeletable = usePermission("tax-delete");
  const isCreatable = usePermission("tax-create");
  const { data, handleModal, onDelete } = props;
  const { isLoading, listData, deleteModalOpen } = data;
  const navigate = useNavigate();

  const ActionButtons = ({ id, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}

        <Link to={`tax-groups/details/${id}`}>
          <img alt="details" src="/icons/arrow_forward.svg" />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Tax Group",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Total Tax Rate",
        disableSortBy: true,
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return <Box>{row.tax_rate}%</Box>;
        },
      },
      {
        Header: "Taxes",
        id: "Taxes",
        disableSortBy: true,
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return (
            <Box>
              {row.Taxes?.slice(0, 3).map((data, i) => {
                return (
                  <Chip
                    key={i}
                    sx={{ mr: 1, borderRadius: "8px" }}
                    label={data.name}
                    variant="filled"
                    size="small"
                  />
                );
              })}
              {row.Taxes?.length > 3 && (
                <Chip
                  sx={{ borderRadius: "8px" }}
                  label={`+${
                    row.Taxes?.length - row.Taxes?.slice(0, 3)?.length
                  }`}
                  variant="filled"
                  size="small"
                />
              )}
            </Box>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => {
                handleModal("TG-list-delete", true, row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <CustomTable
        columns={columns}
        data={listData}
        isLoading={isLoading}
        tableTitle="Tax Group"
        tableId="taxgroup_list"
        headerRightContent={
          <>
            {isCreatable && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("tax-groups/create")}
              >
                New Tax Group
              </Button>
            )}
          </>
        }
        onRowLink={(e) => {
          return `tax-groups/details/${e.id}`;
        }}
      />

      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => handleModal("TG-list-delete", false, null)}
        onConfirm={onDelete}
      />
    </>
  );
};
