import {
  ContentLayout,
  CustomDetailsList,
  CustomeDialog,
  DeleteModal,
  HeaderBreadcrumbs,
  InfiniteScrollDialog,
  Loader,
  PageNotFound,
  ServerPaginatedTable,
} from "../../../shared";
import * as React from "react";
import {
  Button,
  Box,
  Switch,
  Stack,
  Tab,
  Typography,
  Chip,
  createFilterOptions,
  Checkbox,
  TextField,
  Radio,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PointAdjustment } from "../components/PointAdjustment";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { POS_BASE_URL, sortItems } from "../../../utils";
import InputControl from "../../../shared/components/InputControl";
import AddIcon from "@mui/icons-material/Add";
import { AddMemberDialog } from "../components";
import useLoyaltyGroups from "../hooks/useLoyaltyGroups";
import { useRef } from "react";
import { useStore } from "../../Stores";
import { AsyncPaginate } from "react-select-async-paginate";

export const LoyaltySponsorshipGroupsDetails = () => {
  const { id } = useParams();
  const fileRef = useRef();

  const {
    state,
    togglePointAdjustmentModal,
    changeStatus,
    onPointsUpdateChange,
    onUpdatePointAdjustment,
    toggleModal,
    handleDeleteGroup,
    toggleModalAddMember,
    setCurentTab,
    appState: { currentTabs },
    handleChangeCustomerStatus,
    onChangeAddmember,
    handleGetCustomerList,
    onAddMember,
    onUploadMembers,
    toggleDeleteMember,
    handleDeleteMember,
    handleFilter,
    handleApplyFilterHistory,
    handleFilterClearHistory,
    onHistorySearchChange,
    isEditable,
    isDeletable,
    gotoPageMember,
    setPageSizeMember,
    setPageSizeCardHistory,
    gotoPageCardHistory,
    handleSortMember,
    handleSortCardHistory,
    toggleChangeOwner,
    handleChangeOwner,
    isSelectedUser,
    handleUsersSelect,
    HandleFilterUsers,
    loadNextPage,
  } = useLoyaltyGroups({
    isGroupList: false,
    groupId: id,
  });
  const {
    data,
    name,
    isOpen,
    isDetailsFetching,
    isOpenPoitAdjustment,
    pointsUpdateData,
    isAddMemberOpen,
    members,
    addMember,
    isMemberSaveButtonBusy,
    isMembersListLoading,
    isMemberDeleteOpen,
    cardHistory,
    ishistoryLoading,
    cardHistoryFilters,
    httpStatusCodes,
    pointAdjustmentLoading,
    pagination,
    cardHistoryTable,
    sortBy,
    isOpenChangeOwner,
  } = state;
  const navigate = useNavigate();
  const { storeList } = useStore({
    isStore: currentTabs.loyaltyGroupDetails === "3" ? true : false,
    storeId: null,
  });
  const storeOptions = storeList.map((v) => {
    return { value: v.id, label: v.name };
  });
  const onLogoClick = () => {
    fileRef.current.click();
  };

  const handleChange = (event, newValue) => {
    setCurentTab("loyaltyGroupDetails", newValue);
  };
  const ActionButtons1 = ({ id, type }) => {
    return (
      <>
        {type !== "REDCAT ADJUSTMENT" && (
          <div className="action-buttons">
            <img
              alt="details"
              src="/icons/arrow_forward.svg"
              onClick={() => navigate(`/loyalty-cards/pointhistory/${id}`)}
            />
          </div>
        )}
      </>
    );
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <Stack
        ref={innerRef}
        {...innerProps}
        sx={{
          p: "7px 10px",
          gap: "2px",
        }}
      >
        <Typography variant="subtitle2" fontWeight={400} lineHeight="17px">
          {data.label}
        </Typography>
        <Typography color="#666666" variant="caption" lineHeight="15px">
          {data.email}, {data.phone}
        </Typography>
      </Stack>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: "2px solid #E6E8F0",
      height: "48px",
      width: "200px",
    }),
    option: (provided, state) => ({
      ...provided,
      maxHeight: "239px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
  };

  const ActionButtons2 = ({ id, row, deleteAction }) => {
    return (
      isEditable && (
        <div className="action-buttons" onClick={(e) => e.stopPropagation()}>
          <Switch
            checked={row.status === 1 ? true : false}
            onChange={(e) => {
              e.preventDefault();
              handleChangeCustomerStatus(id);
            }}
            disabled={row.is_owner}
          />
          <img
            alt="delete"
            onClick={(e) => {
              e.preventDefault();
              !row.is_owner && deleteAction();
            }}
            className={row.is_owner ? "disabled-img" : ""}
            src="/icons/ic_delete.svg"
          />
        </div>
      )
    );
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });

  const column = React.useMemo(
    () => [
      {
        title: "Group Name",
        accessor: "club_name",
      },
      {
        title: "Owner Name",
        accessor: (row) => {
          return (
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div>{row.customer_id.label}</div>
              <Button
                type="button"
                size="small"
                variant="text"
                onClick={() => toggleChangeOwner(true)}
              >
                Change Owner
              </Button>
            </Stack>
          );
        },
      },
      {
        title: "Owner Barcode",
        accessor: "barcode",
      },
      {
        title: "Balance Points",
        accessor: (row) => {
          return (
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack>{row?.owner_loyalty_card?.points}</Stack>
              <Stack>
                {isEditable && (
                  <Button
                    type="button"
                    size="small"
                    variant="text"
                    onClick={() => togglePointAdjustmentModal(true)}
                  >
                    Point Adjustment
                  </Button>
                )}
              </Stack>
            </Stack>
          );
        },
      },
      {
        title: "Store(s)",
        accessor: (row) => {
          return (
            <Stack direction="row">
              {row?.stores?.length > 0 ? (
                <>
                  {row.stores?.slice(0, 2).map((data) => {
                    return (
                      <Chip
                        sx={{ mr: 1, borderRadius: "8px" }}
                        label={data.name}
                        variant="filled"
                        size="small"
                      />
                    );
                  })}
                  {row.stores?.length > 2 && (
                    <Chip
                      sx={{ borderRadius: "8px" }}
                      label={`+${
                        row.stores?.length - row.stores?.slice(0, 2)?.length
                      }`}
                      variant="filled"
                      size="small"
                    />
                  )}
                </>
              ) : (
                <Chip
                  sx={{ mr: 1, borderRadius: "8px" }}
                  label={"All stores"}
                  variant="filled"
                  size="small"
                />
              )}
            </Stack>
          );
        },
      },
      {
        title: "Created On",
        accessor: "created_on",
      },

      {
        title: "Status",
        accessor: (row) => {
          return (
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Chip
                label={row?.status ? "Active" : "Inactive"}
                color="success"
                variant="outlined"
                size="small"
                disabled={!row?.status}
              />
              <Stack>
                {isEditable && (
                  <Switch
                    name="status"
                    checked={row?.status}
                    onChange={(e) => changeStatus(row?.id)}
                  />
                )}
              </Stack>
            </Stack>
          );
        },
      },
      {
        title: "Last Used Date",
        accessor: "last_used_date",
      },
    ],
    [state]
  );

  const columns = React.useMemo(
    () => [
      {
        title: "Starting Points",
        accessor: "starting_points",
      },
      {
        title: "Card Type",
        accessor: "loyalty_card_type",
      },
      {
        title: "Multiplier",
        accessor: "multiplier",
      },
    ],
    []
  );

  const columns1 = React.useMemo(
    () => [
      {
        Header: "Transaction ID",
        accessor: "id",
      },
      {
        Header: "Date & Time",
        accessor: "date_time",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Member",
        accessor: "customer_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Order Number",
        accessor: "order_number",
        disableGlobalFilter: true,
        Cell: ({ value, row }) => {
          return (
            <div
              className="table-taxt-and-icon-link"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/orders/details/${row.original.order_id}`);
              }}
            >
              <span className="link-text">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Store",
        accessor: "store_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Type",
        accessor: "type",
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return (
            <span style={{ textTransform: "capitalize" }}>
              {value?.toLowerCase()}
            </span>
          );
        },
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Points Accumulated",
        accessor: "accumulated",
        disableGlobalFilter: true,
      },
      {
        Header: "Points Redeemed",
        accessor: "redeemed",
        disableGlobalFilter: true,
      },
      {
        Header: "Transaction Amount",
        accessor: "trans_amount",
        Cell: ({ value }) => {
          return <span style={{ textTransform: "capitalize" }}>{value}</span>;
        },
        disableGlobalFilter: true,
      },
      {
        Header: "Running Total",
        accessor: "running_total",
        disableGlobalFilter: true,
      },
      {
        Header: "Action",
        id: "action",
        accessor: "id",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons1 id={row.id} type={row.type} />;
        },
      },
    ],
    []
  );

  const columns2 = React.useMemo(
    () => [
      {
        Header: "Barcode",
        accessor: "barcode",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Member Number",
        accessor: "member_number",
      },
      {
        Header: "Verification Code",
        accessor: "verification_code",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },

      {
        Header: "Type",
        accessor: (row) => {
          return row.is_owner ? "Owner" : "Member";
        },
      },
      {
        Header: "Member Name",
        accessor: "customer_name",
      },
      {
        Header: "Status",
        accessor: "status",
        disableGlobalFilter: true,
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <Chip
              label={value === 1 ? "Active" : "Inactive"}
              color="success"
              variant="outlined"
              size="small"
              disabled={value === 1 ? false : "filled"}
            />
          );
        },
      },
      {
        Header: "Make Owner",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row) => {
          return (
            row.customer_id && (
              <Radio
                checked={row.is_owner === 1 ? true : false}
                value={row.is_owner}
                onClick={(e) => {
                  e.stopPropagation();
                  handleChangeOwner(row?.customer_id);
                }}
                name="radio-buttons"
              />
            )
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        accessor: "id",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons2
              id={row.id}
              row={row}
              deleteAction={() => toggleDeleteMember(row.id)}
            />
          );
        },
      },
    ],
    [state.members]
  );

  if (httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }

  return (
    <>
      <ContentLayout
        title={name}
        active={data.status ? "Active" : "Inactive"}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                {
                  name: "Loyalty Cards",
                  href: "/loyalty-cards",
                },
                {
                  name: "Groups",
                  href: `/loyalty-cards?groups`,
                },
                { name: name },
              ]}
            />
          </>
        }
        rightContent={
          <>
            {isDeletable && (
              <Button
                type="button"
                size="medium"
                variant="text"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => toggleModal(id)}
              >
                <img alt="delete" src="/icons/ic_delete_blue.svg" />
              </Button>
            )}
          </>
        }
      >
        <TabContext value={currentTabs.loyaltyGroupDetails}>
          <Box
            className="sticky-Tab"
            sx={{ borderBottom: 1, borderColor: "divider", top: "104px" }}
          >
            <TabList onChange={handleChange}>
              <Tab label="Details" value="1" />
              <Tab label="Members" value="2" />
              <Tab label="Transactions" value="3" />
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            {isDetailsFetching ? (
              <Loader />
            ) : (
              <>
                <CustomDetailsList
                  columns={column}
                  data={data}
                  title="Basic Details"
                  headerRightContent={
                    <>
                      <Button
                        type="button"
                        size="medium"
                        onClick={() => navigate(`edit`)}
                      >
                        Edit
                      </Button>
                    </>
                  }
                />
                <CustomDetailsList
                  columns={columns}
                  data={data}
                  title="Point Configurations"
                />
              </>
            )}
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            {isMembersListLoading ? (
              <Loader />
            ) : (
              <ServerPaginatedTable
                columns={columns2}
                data={members}
                searchBar={true}
                tableTitle="Members"
                rowsSelection={false}
                gotoPage={gotoPageMember}
                setPageSize={setPageSizeMember}
                pagination={pagination}
                handleSort={handleSortMember}
                sortByGlobal={sortBy}
                manualSortBy={false}
                headerRightContent={
                  isEditable && (
                    <Stack direction="row" gap="16px">
                      <Button
                        component={Link}
                        to={`${POS_BASE_URL}sample_import_files/loyalty_group_member.csv`}
                        type="button"
                        size="medium"
                        variant="text"
                      >
                        Download Template
                      </Button>
                      <Button
                        type="button"
                        size="medium"
                        variant="text"
                        onClick={(e) => onLogoClick(e)}
                      >
                        <input
                          id="contained-button-file"
                          type="file"
                          onChange={(e) => onUploadMembers(e)}
                          onClick={(e) => {
                            e.target.value = null;
                          }}
                          style={{ display: "none" }}
                          ref={fileRef}
                        />
                        <img
                          alt="export"
                          src="/icons/ic_export.svg"
                          style={{
                            marginRight: "4px",
                          }}
                        />
                        Upload
                      </Button>
                      <Button
                        type="button"
                        size="medium"
                        variant="text"
                        startIcon={<AddIcon />}
                        onClick={() => toggleModalAddMember()}
                      >
                        Add Member
                      </Button>
                    </Stack>
                  )
                }
                onRowAction={(e) => {
                  if (e.customer_id) {
                    navigate(`/customers/details/${e.customer_id}`);
                  } else {
                    return false;
                  }
                }}
                isClickDisabled={(e) => {
                  if (e.customer_id) {
                    return true;
                  } else {
                    return false;
                  }
                }}
              />
            )}
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="3">
            {ishistoryLoading ? (
              <Loader />
            ) : (
              <ServerPaginatedTable
                columns={columns1}
                data={cardHistory}
                searchBar={true}
                rowsSelection={true}
                defaultSearch={false}
                filterApplied={cardHistoryFilters.all}
                onFilterClear={(e) => handleFilterClearHistory("single", e)}
                onAllFilterClear={(e) => handleFilterClearHistory("all")}
                gotoPage={gotoPageCardHistory}
                setPageSize={setPageSizeCardHistory}
                pagination={cardHistoryTable.pagination}
                handleSort={handleSortCardHistory}
                sortByGlobal={cardHistoryTable.sortBy}
                filterContent={
                  <InputControl
                    type="table-search"
                    value={cardHistoryFilters.search}
                    onChange={onHistorySearchChange}
                    onClear={(e) =>
                      onHistorySearchChange({ target: { value: "" } })
                    }
                  />
                }
                filterSection={
                  <>
                    <Stack direction="row" gap="20px">
                      <InputControl
                        type="dropdown"
                        name="Store"
                        label="Store"
                        multiple={true}
                        options={storeOptions}
                        value={cardHistoryFilters.store_id}
                        disableCloseOnSelect
                        disableClearable={true}
                        onChange={(e, v) => handleFilter(e, v, "store")}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => <></>)
                        }
                        renderOption={(props, option, { selected }) => (
                          <>
                            <li {...props} key={option.value}>
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.label}
                            </li>
                          </>
                        )}
                        filterOptions={filterOptions}
                        sx={{ width: 250 }}
                        componentsProps={{
                          paper: {
                            sx: {
                              width: "fit-content",
                              minWidth: 250,
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Store"
                            placeholder="Store"
                          />
                        )}
                      />
                      <AsyncPaginate
                        debounceTimeout={1000}
                        value={cardHistoryFilters.customer_id.value}
                        placeholder="Member"
                        className="AsyncPaginate-dropdown"
                        classNamePrefix="react-select"
                        components={{ Option: CustomOption }}
                        loadOptions={(e, p, { page }) => {
                          return handleGetCustomerList({
                            search: e,
                            page,
                          });
                        }}
                        onChange={(e) => {
                          handleFilter(null, e, "Owner");
                        }}
                        additional={{
                          page: 1,
                        }}
                        styles={customStyles}
                      />
                    </Stack>
                    <Button
                      type="button"
                      size="medium"
                      variant="text"
                      // color="gray"
                      sx={{
                        minWidth: "40px",
                        p: "8px",
                      }}
                      disabled={
                        !cardHistoryFilters.store_id.length > 0 &&
                        !cardHistoryFilters.customer_id?.value
                      }
                      onClick={() => handleApplyFilterHistory()}
                    >
                      Apply
                    </Button>
                  </>
                }
                onRowLink={(e) => {
                  if (e.type !== "REDCAT ADJUSTMENT")
                    return `/loyalty-cards/pointhistory/${e.id}`;
                  return;
                }}
              />
            )}
          </TabPanel>
        </TabContext>
      </ContentLayout>
      <CustomeDialog
        open={isOpenPoitAdjustment}
        handleClose={togglePointAdjustmentModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "618px", height: "389px", p: "16px" },
        }}
        title="Point Adjustment"
        content={
          <PointAdjustment
            points={data?.owner_loyalty_card?.points}
            pointAdjustmentLoading={pointAdjustmentLoading}
            data={pointsUpdateData}
            handlePointsInputChange={(e) => onPointsUpdateChange(e)}
            onUpdatePointAdjustment={onUpdatePointAdjustment}
          />
        }
      />
      <CustomeDialog
        open={isOpenChangeOwner}
        handleClose={() => toggleChangeOwner(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "423px", height: "535px", p: "16px" },
        }}
        title="Customers"
        content={
          <InfiniteScrollDialog
            handleSelect={handleUsersSelect}
            isSelectedUser={isSelectedUser}
            handleChange={handleChangeOwner}
            disabled={state.selectedUsers.length === 0}
            state={state}
            loadNextPage={loadNextPage}
            hasMorePages={state?.pagination?.hasMorePages}
            HandleFilter={HandleFilterUsers}
          />
        }
      />
      <CustomeDialog
        open={isAddMemberOpen}
        handleClose={() => toggleModalAddMember()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "618px", Height: "490px", p: "16px" },
        }}
        title="Add Member"
        content={
          <AddMemberDialog
            onChangeAddmember={onChangeAddmember}
            data={addMember}
            handleGetCustomerList={handleGetCustomerList}
            onAddMember={onAddMember}
            isMemberSaveButtonBusy={isMemberSaveButtonBusy}
            members={members}
          />
        }
      />
      <DeleteModal
        open={isOpen}
        handleClose={() => toggleModal()}
        onConfirm={() => handleDeleteGroup("details")}
      />
      <DeleteModal
        open={isMemberDeleteOpen}
        handleClose={() => toggleDeleteMember()}
        onConfirm={() => handleDeleteMember()}
      />
    </>
  );
};
