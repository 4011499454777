import { ContentLayout, HeaderBreadcrumbs } from "../../../shared";
import * as React from "react";
import {
  Button,
  Card,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { usePosSettings } from "../hooks";
import SimpleReactValidator from "simple-react-validator";
import { useState } from "react";
import { useRef } from "react";

export const PosSettingsDetails = () => {
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const {
    state,
    onRadioButtonChange,
    handleOnChange,
    onUpdateTemplate,
    stateTemplates,
  } = usePosSettings({
    isList: true,
  });
  const optionTemplates = stateTemplates?.templates?.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const onSubmit = () => {
    if (validator.current.allValid()) {
      onUpdateTemplate();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  return (
    <>
      <ContentLayout
        title="POS Settings"
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "POS Settings" },
              ]}
            />
          </>
        }
      >
        <Card sx={{ p: "32px 24px", mb: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1" fontWeight={600}>
                Product Display View
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="discount_type"
              >
                <FormControlLabel
                  control={<Radio />}
                  label="Image View"
                  checked={state.display_mode === "image" ? true : false}
                  value={state.display_mode}
                  onChange={() => onRadioButtonChange("image", "display_mode")}
                />
                <FormControlLabel
                  value={state.display_mode}
                  control={<Radio />}
                  label="Text View"
                  checked={state.display_mode === "text" ? true : false}
                  onChange={() => onRadioButtonChange("text", "display_mode")}
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </Card>

        <Card
          sx={{
            p: "32px 24px",
            mb: "24px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1" fontWeight={600}>
                Product Listing Mode
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="discount_type"
              >
                <FormControlLabel
                  control={<Radio />}
                  label="Grid Mode"
                  value={state.display_position}
                  checked={state.display_position === "grid" ? true : false}
                  onChange={() =>
                    onRadioButtonChange("grid", "display_position")
                  }
                />
                <FormControlLabel
                  value={state.display_position}
                  control={<Radio />}
                  label="Dynamic Mode"
                  checked={state.display_position === "dynamic" ? true : false}
                  onChange={() =>
                    onRadioButtonChange("dynamic", "display_position")
                  }
                />
              </RadioGroup>
            </Grid>
          </Grid>
          {state.display_position === "grid" && (
            <>
              <Stack mt={2}>
                <Divider />
              </Stack>
              <Grid container spacing={1} marginTop={4}>
                <Grid item sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Default Template
                  </Typography>
                </Grid>

                <Grid item sm={8}>
                  <Grid container spacing={1}>
                    <Stack direction="row" gap={1}>
                      <InputControl
                        type="dropdown"
                        name="conditions"
                        sx={{
                          width: "635px",
                        }}
                        disableClearable
                        options={optionTemplates}
                        filteredOptions
                        getOptionLabel={(option) => option?.label}
                        onChange={(e, newValue) => {
                          handleOnChange(newValue);
                        }}
                        value={
                          (optionTemplates?.length > 0 &&
                            state?.keypad_id &&
                            optionTemplates?.find(
                              (c) => c.value === state?.keypad_id
                            )) ||
                          null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            sx={{
                              borderRadius: "8px",
                              ".MuiInputBase-root": { height: "44px" },
                            }}
                            label="Default Template"
                            required
                            error={validator.current.message(
                              "Default Template",
                              state?.keypad_id,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Default Template",
                              state?.keypad_id,
                              "required"
                            )}
                          />
                        )}
                      />
                      <div className="action-buttons">
                        <Button
                          onClick={() => onSubmit()}
                          disabled={state.isSaveButtonBusy}
                        >
                          Save
                        </Button>
                      </div>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Card>
      </ContentLayout>
    </>
  );
};
