import * as React from "react";
import {
  Checkbox,
  FormControlLabel,
  Stack,
  Button,
  Typography,
} from "@mui/material";
import { CustomTable, Loader, usePermission } from "../../../shared";
import { sortItems } from "../../../utils";
import { useNavigate } from "react-router-dom";

export const PermissionsList = (props) => {
  const {
    data,
    roleDetails,
    isLoading,
    handleChecked,
    handleCheckindeterminate,
    isStoreAdmin,
    currentTab,
  } = props;
  const navigate = useNavigate();
  const isEditable = usePermission("role-modify");
  const columns = React.useMemo(() => {
    const tableColumns = [
      {
        id: "expander",
        Header: "",
        Cell: ({ row }) =>
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: { paddingLeft: `0px 10px` },
              })}
              style={{ padding: `0px 10px`, marginLeft: "-20px" }}
            >
              <img
                className={row.isExpanded ? "expanded-icon" : ""}
                src="/icons/ic_forward.svg"
                alt="Toggle"
              />
            </span>
          ) : null,
        padding: "checkbox",
      },
      {
        Header: () => (
          <span style={{ marginLeft: `-15px` }}>Permission Name</span>
        ),
        accessor: "name",
        Cell: ({ row, value }) => (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ marginLeft: "-15px" }}
          >
            <img
              src={row.depth ? "/icons/permission.svg" : "/icons/Folder.svg"}
              alt={row.depth ? "Permission Icon" : "Folder Icon"}
            />
            <span>{row.original.display_name || value}</span>
          </Stack>
        ),
        sortType: (prev, curr, columnId) => sortItems(prev, curr, columnId),
      },
      {
        id: "action",
        accessor: "permission",
        padding: "checkbox",
        disableSortBy: true,
        Header: () => (
          <FormControlLabel
            control={
              <Checkbox
                disabled
                defaultChecked={handleChecked(2)}
                indeterminate={handleCheckindeterminate("head")}
                color="primary"
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 24,
                    height: "24px",
                    width: "24px",
                  },
                }}
              />
            }
            labelPlacement="start"
            label={
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 600,
                  lineHeight: "15.73px",
                }}
              >
                Permission
              </Typography>
            }
          />
        ),
        Cell: ({ row }) => (
          <Stack justifyContent="end">
            <FormControlLabel
              labelPlacement="start"
              control={
                <Checkbox
                  defaultChecked={handleChecked(
                    row.depth,
                    row.original.id,
                    row.originalSubRows
                  )}
                  indeterminate={handleCheckindeterminate(row)}
                  color="primary"
                  disabled
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                />
              }
            />
          </Stack>
        ),
      },
    ];

    // Conditionally add the manager-pass-code column
    const managerPassCodeColumn = {
      id: "manager-passcode",
      accessor: "permission",
      padding: "checkbox",
      disableSortBy: true,
      Header: () => (
        <Stack sx={{ flexDirection: "row" }}>
          <FormControlLabel
            control={
              <Checkbox
                disabled
                checked={handleChecked(2)}
                indeterminate={handleCheckindeterminate("head")}
                color="primary"
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 24,
                    height: "24px",
                    width: "24px",
                  },
                }}
              />
            }
            labelPlacement="start"
            label={
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 600,
                  lineHeight: "15.73px",
                  whiteSpace: "nowrap",
                }}
              >
                Manager Passcode
              </Typography>
            }
          />
        </Stack>
      ),
      Cell: ({ row }) => (
        <Stack sx={{ flexDirection: "row" }} justifyContent="end">
          <FormControlLabel
            labelPlacement="start"
            control={
              <Checkbox
                defaultChecked={handleChecked(
                  row.depth,
                  row.original.id,
                  row.originalSubRows
                )}
                indeterminate={handleCheckindeterminate(row)}
                color="primary"
                disabled
                sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
              />
            }
          />
        </Stack>
      ),
    };

    return currentTab === "3"
      ? tableColumns.concat([managerPassCodeColumn])
      : tableColumns;
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <CustomTable
          columns={columns}
          data={data}
          searchBar={false}
          rowsSelection={false}
          rowsExpand={true}
          tableTitle="Permissions"
          pagination={false}
          subRow={"permissions"}
          headerRightContent={
            <>
              {!isStoreAdmin && isEditable && (
                <Button
                  type="button"
                  size="medium"
                  disabled={roleDetails?.system_role}
                  variant="contained"
                  onClick={() => navigate("edit")}
                >
                  Edit
                </Button>
              )}
            </>
          }
        />
      )}
    </>
  );
};
