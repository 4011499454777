/* eslint-disable no-throw-literal */
import React from "react";
import { errorMessage, successMessage } from "../../../utils";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router-dom";
import { deletePosRoleApi, getPosRolesListApi } from "../api";

export const usePosRolesList = () => {
  const [state, setState] = useImmer({
    roles: {
      list: [],
      isLoading: false,
    },
    activeRowId: null,
    isDeleteOpenModal: false,
  });

  const navigate = useNavigate();
  //   ======================================================================== API Section ============================================================================

  /**
   * Fetches the list of POS roles with optional search parameter.
   * Triggers loading state during the fetch operation.
   *
   * @param {string|null} [search=null] - The search term to filter roles.
   * @returns {Promise<void>} - A promise that resolves when the operation is complete.
   */
  const getRolesList = async (search = null) => {
    triggerListLoading(true);
    const params = {};
    try {
      if (search) params["search"] = search;

      const response = await getPosRolesListApi(params);

      const { success, message, data } = response;
      if (success) {
        setState((draft) => {
          draft.roles.list = data;
        });
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message =
        error.response?.data?.message ??
        "Failed to fetch POS roles. Please try again later.";
      errorMessage(message);
    } finally {
      triggerListLoading(false);
    }
  };

  /**
   * Deletes a specified POS role by its ID.
   * Triggers the fetching of the updated roles list after deletion.
   *
   * @param {string} roleId - The ID of the role to be deleted.
   * @returns {Promise<void>} - A promise that resolves when the operation is complete.
   */
  const deletePosRole = async (roleId) => {
    try {
      const response = await deletePosRoleApi(roleId);

      const { success, message } = response;
      if (success) {
        successMessage(message);
        await getRolesList();
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message =
        error.response?.data?.message ??
        "Failed to fetch POS roles. Please try again later.";
      errorMessage(message);
    }
  };

  //   ========================================================================= Util Functions ==========================================================================
  /**
   * Toggles the loading state for the roles list.
   *
   * @param {boolean} status - The loading status to set.
   */
  const triggerListLoading = (status) => {
    setState((draft) => {
      draft.roles.isLoading = status;
    });
  };

  /**
   * Toggles the visibility of the delete dialog for a specific row.
   *
   * @param {Object} row - The row object containing the ID to be deleted.
   * @param {string|null} row.id - The ID of the row. If null, closes the dialog.
   */
  const toggleDeleteDialog = (row) => {
    const rowId = row?.id ?? null;
    setState((draft) => {
      draft.isDeleteOpenModal = Boolean(rowId);
      draft.activeRowId = rowId;
    });
  };

  /**
   * Handles the deletion of a POS role based on the active row ID.
   * Toggles the delete dialog and performs the deletion if a valid ID is present.
   *
   * @returns {Promise<void>} - A promise that resolves when the deletion is complete.
   */
  const handleDeletePosRole = async () => {
    const rowId = state.activeRowId;
    toggleDeleteDialog();

    if (rowId) return await deletePosRole(rowId);

    errorMessage("Role details is missing, please try agin");
  };

  /**
   * Navigates to the role creation page.
   */
  const handleCreateRole = () => navigate("pos/details/create");

  //   ============================================================================ Use Effects ===========================================================================
  React.useEffect(() => {
    getRolesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    state,
    deletePosRole,
    toggleDeleteDialog,
    handleDeletePosRole,
    handleCreateRole,
  };
};
