import React from "react";
import { Route, Routes } from "react-router-dom";
import { CFDlist, AddOrEditCFD } from "./containers";
import { ProtectedRoute } from "../../shared";

export const CFD = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<CFDlist />} />
                <Route element={<ProtectedRoute allowedRoute={["cfd-create"]} />}>
                    <Route path="/create" element={<AddOrEditCFD />} />
                </Route><Route element={<ProtectedRoute allowedRoute={["cfd-modify"]} />}>
                    <Route path="/edit/:id" element={<AddOrEditCFD />} />
                </Route>
            </Routes>
        </>
    );
};
