import {
  CustomTable,
  CustomeDialog,
  DeleteModal,
  usePermission,
  Loader,
} from "../../../shared";
import * as React from "react";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { sortItems } from "../../../utils";
import { ProductInfiniteScrollDialog } from "../../AdminUsers/components";

export const ProductPromotions = (props) => {
  const {
    data,
    handleModal,
    setCurentTab,
    handleDeleteCrossSelling,
    handleDeleteSimilarProducts,
    isCrossSelling,
    isSimilarProduct,
    handleStatusCrossSelling,
    handlePromotionsSelect,
    handleAssignProductToPromotions,
    handleStatusSimilarProducts,
    updateIsloading,
    id,
  } = props;

  const {
    crossSelling,
    similarProducts,
    isLoading,
    isOpen,
    selectedItems,
    type,
    isSaveButton,
  } = data;
  const isDeletable = usePermission("product-delete");
  const isCreate = usePermission("product-create");

  const ActionButtons = ({ deleteAction }) => {
    return (
      <div className="action-buttons">
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        id: "name",
        accessor: (row, index) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <Avatar
                src={row.image}
                variant="rounded"
                sx={{ width: 44, height: 44 }}
              />
              <div className="cursor">
                <span className="link-text">{row.name}</span>
              </div>
            </Stack>
          );
        },
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ value }) => {
          return value.name;
        },
        disableSortBy: true,
      },
      {
        Header: "PLU",
        accessor: "plu",
        disableSortBy: true,
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              deleteAction={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleModal("delete-crossSelling", true, row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );

  const columns2 = React.useMemo(
    () => [
      {
        Header: "Name",
        id: "name",
        accessor: (row, index) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <Avatar
                src={row.image}
                variant="rounded"
                sx={{ width: 44, height: 44 }}
              />
              <div className=" cursor">
                <span className="link-text">{row.name}</span>
              </div>
            </Stack>
          );
        },
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ value }) => {
          return value.name;
        },
        disableSortBy: true,
      },
      {
        Header: "PLU",
        accessor: "plu",
        disableSortBy: true,
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              deleteAction={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleModal("delete-similarProducts", true, row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Card sx={{ mb: "24px" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              height="83px"
              p="32px 24px"
            >
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="160px"
              >
                Cross Selling
              </Typography>
              <Switch
                checked={isCrossSelling ? true : false}
                onChange={handleStatusCrossSelling}
                disabled={crossSelling.dataList < 1}
              />
            </Stack>
            <Divider />
            <CustomTable
              columns={columns}
              data={crossSelling.dataList}
              searchBar={false}
              tableTitle="Products"
              tableId="crossSelling_products"
              completeFeature={false}
              headerRightContent={
                <>
                  {isCreate && (
                    <Button
                      type="button"
                      size="medium"
                      startIcon={<AddIcon />}
                      onClick={() => handleModal("crossSelling-add", true, 1)}
                    >
                      Add
                    </Button>
                  )}
                </>
              }
              onRowLink={(e) => {
                return `/products/details/${e.id}`;
              }}
              onRowAction={() => {
                setCurentTab("productDetails", "1");
                updateIsloading();
              }}
            />
          </Card>
          <Card sx={{ mb: "24px" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              height="83px"
              p="32px 24px"
            >
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="160px"
              >
                Similar Products
              </Typography>
              <Switch
                checked={isSimilarProduct ? true : false}
                onChange={handleStatusSimilarProducts}
                disabled={similarProducts.dataList.length < 1}
              />
            </Stack>
            <Divider />
            <CustomTable
              columns={columns2}
              data={similarProducts.dataList}
              searchBar={false}
              tableTitle="Products"
              tableId="similarProducts_product"
              completeFeature={false}
              headerRightContent={
                <>
                  {isCreate && (
                    <Button
                      type="button"
                      size="medium"
                      startIcon={<AddIcon />}
                      onClick={() => handleModal("crossSelling-add", true, 2)}
                    >
                      Add
                    </Button>
                  )}
                </>
              }
              onRowLink={(e) => {
                return `/products/details/${e.id}`;
              }}
              onRowAction={() => {
                setCurentTab("productDetails", "1");
              }}
            />
          </Card>
          <CustomeDialog
            open={isOpen}
            handleClose={() => handleModal("crossSelling-add", false, null)}
            PaperProps={{
              sx: { width: "423px", height: "453px", p: "16px" },
            }}
            title={type === 1 ? "Cross Selling Products" : "Similar Products"}
            content={
              <ProductInfiniteScrollDialog
                handleSelect={handlePromotionsSelect}
                filterItemIds={[Number(id)]}
                isSelected={(id) => selectedItems.includes(id)}
                handleChange={handleAssignProductToPromotions}
                disabled={!selectedItems.length || isSaveButton}
                alreadyList={
                  type === 1
                    ? crossSelling.alreadyInList
                    : similarProducts.alreadyInList
                }
              />
            }
          />
          <DeleteModal
            open={crossSelling.isDeleteOpen}
            handleClose={() => handleModal("delete-crossSelling", false, null)}
            onConfirm={handleDeleteCrossSelling}
          />
          <DeleteModal
            open={similarProducts.isDeleteOpen}
            handleClose={() =>
              handleModal("delete-similarProducts", false, null)
            }
            onConfirm={handleDeleteSimilarProducts}
          />
        </>
      )}
    </>
  );
};
