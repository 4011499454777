import {
  ContentLayout,
  CustomeDialog,
  HeaderBreadcrumbs,
  Loader,
} from "../../../shared";
import * as React from "react";
import { Button, Stack, Card, Switch, Typography, Grid } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useParams } from "react-router-dom";
import { SoldOutDialog } from "../components";
import { useProductsSA } from "..";

export const CreateOrEditProductsSA = () => {
  const { id } = useParams();
  const {
    state,
    validator,
    handleInputChange,
    handleStockUpdate,
    StockSoldoutStatusChange,
    handleInputDateAndTime,
    handleClearStock,
    handleSoldoutStatus,
    handleUpdateStockSoldoutStatus,
    validateDate,
    validateTime,
    setState,
  } = useProductsSA({ isDetailsId: id });
  const { stockDetails, initialLoad, stockOutDetails } = state;

  return (
    <>
      {stockDetails.isLoading ? (
        <Loader />
      ) : (
        <ContentLayout
          title={stockDetails.details.name}
          breadcrumbs={
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/store-admin" },
                { name: "Products", href: "/store-admin/products" },
                {
                  name: stockDetails.details?.name || "",
                },
              ]}
            />
          }
        >
          <Card sx={{ p: "32px 24px", mb: "24px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Stack>
                  <Typography variant="subtitle1" fontWeight={600}>
                    Stock Details
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color="#636363"
                    fontWeight={400}
                  >
                    {/* ${stockDetails.details.price} */}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item sm={8}>
                <Stack width={"100%"} gap="20px">
                  <Stack width={"100%"} gap="12px" direction="row">
                    <InputControl
                      name="stock"
                      label="Stock"
                      disabled={!state.stockDetails.isEdit}
                      value={stockDetails.details.stock}
                      onChange={handleInputChange}
                      error={validator.current.message(
                        "Stock",
                        stockDetails.details.stock,
                        "required|numeric|min:0,num"
                      )}
                      helperText={validator.current.message(
                        "Stock",
                        stockDetails.details.stock,
                        "required|numeric|min:0,num"
                      )}
                    />
                    <Button
                      sx={{ width: "58px", height: "48px !important" }}
                      disabled={state.stockDetails.isSaveButton}
                      onClick={handleStockUpdate}
                    >
                      {state.stockDetails.isEdit ? "Save" : "Edit"}
                    </Button>
                  </Stack>
                  <Stack
                    width={"100%"}
                    gap="12px"
                    direction="row"
                    alignContent="center"
                    py="10px"
                  >
                    <Stack width={"100%"}>
                      <Typography variant="subtitle1" fontWeight={600}>
                        Sold Out
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="#636363"
                        fontWeight={400}
                      >
                        {stockDetails.details.sold_out_status === 1
                          ? "Available"
                          : stockDetails.details.back_in_stock_type ===
                            "sold_out_until"
                          ? "Sold out until " +
                            stockDetails.details.back_in_stock_time
                          : stockDetails.details.back_in_stock_type ===
                            "one_hour"
                          ? "Not available for one hour"
                          : stockDetails.details.back_in_stock_type === "today"
                          ? "Not available for today"
                          : "Not available for Indefinite time"}
                      </Typography>
                    </Stack>
                    <Switch
                      checked={
                        stockDetails.details.sold_out_status === 0
                          ? true
                          : false
                      }
                      onChange={(e, v) =>
                        handleSoldoutStatus(
                          stockDetails.details.sold_out_status ? true : false
                        )
                      }
                    />
                  </Stack>
                  <Stack
                    width={"100%"}
                    gap="12px"
                    direction="row"
                    alignContent="center"
                    py="10px"
                  >
                    <Stack width={"100%"}>
                      <Typography variant="subtitle1" fontWeight={600}>
                        Clear Stock
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="#636363"
                        fontWeight={400}
                      >
                        Clear stock at mid night
                      </Typography>
                    </Stack>
                    <Switch
                      checked={
                        stockDetails.details.auto_clear_stock ? true : false
                      }
                      onChange={(e, v) => handleClearStock(v)}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Card>
          {initialLoad ? (
            <Loader />
          ) : (
            <Card sx={{ p: "32px 24px", mb: "24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Stack>
                    <Typography variant="subtitle1" fontWeight={600}>
                      Stock Alert
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="#636363"
                      fontWeight={400}
                    >
                      Turn ON for stock alert
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item sm={8}>
                  <Stack
                    width={"100%"}
                    gap="12px"
                    direction="row"
                    alignContent="center"
                  >
                    {stockDetails.details.alert_stock_status === 0 ? (
                      <InputControl
                        name="alert_stock"
                        label="Stock"
                        disabled={
                          stockDetails.details.alert_stock_status ? false : true
                        }
                        value={stockDetails.details.alert_stock}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <InputControl
                        name="alert_stock"
                        label="Stock"
                        disabled={
                          stockDetails.details.alert_stock_status ? false : true
                        }
                        value={stockDetails.details.alert_stock}
                        onChange={handleInputChange}
                        error={validator.current.message(
                          "Stock Alert",
                          stockDetails.details.alert_stock,
                          "required|numeric|min:0,num"
                        )}
                        helperText={validator.current.message(
                          "Stock Alert",
                          stockDetails.details.alert_stock,
                          "required|numeric|min:0,num"
                        )}
                      />
                    )}
                    <Switch
                      name="alert_stock_status"
                      checked={
                        stockDetails.details.alert_stock_status ? true : false
                      }
                      onChange={(e, v) => {
                        handleInputChange(e, v);
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          )}
        </ContentLayout>
      )}
      <CustomeDialog
        open={state.stockDetails.isOpen}
        handleClose={() => handleSoldoutStatus(false)}
        PaperProps={{
          sx: {
            width: "423px",
            p: "16px",
          },
        }}
        title="Sold Out"
        content={
          <SoldOutDialog
            state={state}
            values={stockOutDetails}
            handleChange={StockSoldoutStatusChange}
            onSubmitAction={() => handleUpdateStockSoldoutStatus(0)}
            handleInputDateAndTime={handleInputDateAndTime}
            validateDate={validateDate}
            validateTime={validateTime}
            setState={setState}
          />
        }
      />
    </>
  );
};
