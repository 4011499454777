import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import * as React from "react";
import { Button, Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useParams } from "react-router-dom";
import { useCoupons } from "../hooks/useCoupons";
import { CouponsGeneralDetails, UsageHistory } from "../components";
import { CouponsStore } from "../components/CouponsStore";
import { LedgerAccountsList } from "../../Configurations/components";

export const CouponDetailsList = () => {
  const { id } = useParams();

  const handleChange = (event, newValue) => {
    setCurentTab("CouponseDetails", newValue);
  };
  const {
    state,
    states,
    HandleFilterStores,
    handleStoreSelect,
    handleAssignStoretoCoupons,
    handleModal,
    DeleteStoreModal,
    handleDeleteStores,
    currentTab,
    setCurentTab,
    handlePlayPauseStatus,
    handleStatusChange,
    handleAllSelect,
    onChangeTitle,
    isEditable,
    globalData,
    onChangeusageHistoryFilter,
    resetusageHistoryFilters,
    downloadCouponsList,
  } = useCoupons({
    couponsById: id,
    isCoupons: false,
    isStore: true,
  });
  return (
    <>
      <ContentLayout
        title={state.name}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Coupons", href: "/coupons" },
              { name: state.name || "" },
            ]}
          />
        }
        rightContent={
          <>
            {state.data.active === 3 && (
              <img
                onClick={() => handlePlayPauseStatus(id)}
                src="/icons/ic_play_blue2.svg"
              />
            )}
            {state.data.active === 2 && (
              <Button
                aria-label="delete"
                color="primary"
                type="button"
                size="medium"
                variant="text"
                className="delte"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
              >
                <img
                  onClick={() => handlePlayPauseStatus(id)}
                  src="/icons/play_icon.svg"
                />
              </Button>
            )}
            <Button
              type="button"
              size="medium"
              variant="outlined"
              disabled={state.data.active === 1}
              onClick={() => downloadCouponsList(id)}
            >
              <img
                alt="export"
                src={
                  state.data.active === 1
                    ? "/icons/ic_download_gray.svg"
                    : "/icons/ic_download_blue.svg"
                }
                style={{
                  marginRight: "4px",
                }}
              />
              Download Coupons
            </Button>
            {state.data.active === 1 && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                disabled={state.isSaveButtonBusy}
                onClick={() => handleStatusChange(id)}
              >
                Publish
              </Button>
            )}
          </>
        }
      >
        <TabContext value={currentTab}>
          <Box
            className="sticky-Tab"
            sx={{ borderBottom: 1, borderColor: "divider", top: "104px" }}
          >
            <TabList onChange={handleChange}>
              <Tab label="Coupon Details" value="1" />
              <Tab label="Store" value="2" />
              <Tab label="Usage history" value="3" />
              <Tab label="Accounts" value="4" />
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            {state.isBusy ? (
              <Loader />
            ) : (
              <CouponsGeneralDetails
                state={state}
                globalData={globalData}
                isEditable={isEditable}
              />
            )}
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            {state.isBusy ? (
              <Loader />
            ) : (
              <CouponsStore
                state={state}
                HandleFilterStores={HandleFilterStores}
                handleStoreSelect={handleStoreSelect}
                handleAssignStoretoCoupons={handleAssignStoretoCoupons}
                handleModal={handleModal}
                DeleteStoreModal={DeleteStoreModal}
                handleDeleteStores={handleDeleteStores}
                handleAllSelect={handleAllSelect}
                setCurentTab={setCurentTab}
                onChangeTitle={onChangeTitle}
              />
            )}
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="3">
            <UsageHistory
              state={state}
              onChangeusageHistoryFilter={onChangeusageHistoryFilter}
              resetusageHistoryFilters={resetusageHistoryFilters}
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="4">
            <LedgerAccountsList type="promotion" />
          </TabPanel>
        </TabContext>
      </ContentLayout>
    </>
  );
};
