import {
  ContentLayout,
  CustomTable,
  DeleteModal,
  useAbly,
} from "../../../shared";
import * as React from "react";
import {
  Button,
  Checkbox,
  Chip,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { useTerminals } from "../hooks";
import { arraySort, sortItems } from "../../../utils";

export const TerminalsList = () => {
  const navigate = useNavigate();
  const { presenceDataTerminal } = useAbly();

  const {
    state,
    toggleModal,
    onChangeFilter,
    isDeletable,
    isCreateVisible,
    handleDeleteTerminal,
    storeList,
    handleFilterClear,
    handleFilter,
    handleApplyFilter,
    statusList,
    onSeachClear,
    TerminalsList,
  } = useTerminals({
    load: true,
    isStoreList: true,
  });
  const { terminals, isBusy, isOpen, filterApplied } = state;
  const storeOptions = storeList?.map((v) => {
    return { value: v.id, label: v.name };
  });
  const terminalOptions = TerminalsList?.pairing_status?.map((v) => {
    return { value: v.value, label: v.label };
  });
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });

  const ActionButtons = ({ id, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
        <Link to={`details/${id}`}>
          <img alt="details" src="/icons/arrow_forward.svg" />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Token Prefix",
        accessor: "order_prefix",
        disableSortBy: true,
      },
      {
        Header: "Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Store Name",
        accessor: "store_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "App Version",
        accessor: ({ meta_data }) => {
          return meta_data && meta_data.app_version
            ? meta_data.app_version
            : "N/A";
        },
      },
      {
        Header: "Pairing Status",
        accessor: (row) => {
          return row.connection_status === 1 ? "paired" : "unpaired";
        },
        sortInverted: true,
        Cell: ({ value }) => {
          return value === "paired" ? (
            <Stack direction="row" alignItems="center" gap="4px">
              <img alt="paired" src="/icons/paired-icon.svg" />
              <Typography variant="subtitle2" color="#0F875B">
                Paired
              </Typography>
            </Stack>
          ) : (
            <Stack direction="row" alignItems="center" gap="4px">
              <img alt="unpaired" src="/icons/unpaired-icon.svg" />
              <Typography variant="subtitle2" color="#A3A3A3">
                Unpaired
              </Typography>
            </Stack>
          );
        },
      },
      {
        Header: "Connection Status",
        accessor: "id",
        disableSortBy: true,
        Cell: ({ value }) => {
          const isOnline = presenceDataTerminal.find(
            (item) =>
              Number(item.clientId.replace("banjos_terminal_", "")) === value
          );
          return isOnline ? (
            <img alt="online" src="/icons/ic_online.svg" />
          ) : (
            <img alt="online" src="/icons/ic_offline.svg" />
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sortInverted: true,
        Cell: ({ value }) => {
          return value === 1 ? (
            <Chip
              label="Active"
              color="success"
              variant="outlined"
              size="small"
            />
          ) : (
            <Chip
              label="Inactive"
              color="success"
              variant="outlined"
              size="small"
              disabled
            />
          );
        },
      },
      {
        Header: "Cashless Till",
        accessor: "cashless_till",
        sortInverted: true,
        Cell: ({ value }) => {
          return value === 1 ? "Yes" : "No";
        },
      },
      {
        Header: "Action",
        id: "action",
        disableSortBy: true,
        align: "right",
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => {
                toggleModal(row.id);
              }}
            />
          );
        },
      },
    ],
    [presenceDataTerminal]
  );

  return (
    <>
      <ContentLayout
        title="Terminals"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("create")}
              >
                New Terminal
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={terminals}
          isLoading={isBusy}
          defaultSearch={true}
          filterApplied={filterApplied.all}
          tableId="terminal_list"
          onFilterClear={(e) => handleFilterClear("single", e)}
          onAllFilterClear={(e) => handleFilterClear("all")}
          // filterContent={
          //   <InputControl
          //     type="table-search"
          //     value={filterApplied.searchText}
          //     onChange={(e) => onChangeFilter(e.target.value)}
          //     onClear={(e) => onChangeFilter("")}
          //   />
          // }
          filterSection={
            <>
              <Stack direction="row" gap="16px" alignItems="center">
                <InputControl
                  type="dropdown"
                  name="store"
                  label="Store"
                  multiple={true}
                  options={arraySort(storeOptions || [], "label")}
                  value={filterApplied.stores}
                  disableCloseOnSelect
                  disableClearable={true}
                  onChange={(e, v) => handleFilter(e, v, "store")}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Store" placeholder="Store" />
                  )}
                />
                {/* <InputControl
                  type="select"
                  name="Status"
                  label="Pairing Status"
                  value={filterApplied.pairing_status.value}
                  optionValue={arraySort(terminalOptions || [], "label")}
                  width="150px"
                  onChange={(e, v) => handleFilter(e, v, "pairing_status")}
                /> */}
                <InputControl
                  type="select"
                  name="Status"
                  label="Status"
                  value={state.filterApplied?.status?.value ?? ""}
                  optionValue={statusList ?? []}
                  width="150px"
                  onChange={(e, v) => handleFilter(e, v, "status")}
                />
              </Stack>
              <Button
                type="button"
                size="medium"
                variant="text"
                color="info"
                disabled={state.disableApplyButton}
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => handleApplyFilter()}
              >
                Apply
              </Button>
            </>
          }
          onRowLink={(e) => {
            return `/terminal/details/${e.id}`;
          }}
        />
      </ContentLayout>
      <DeleteModal
        open={isOpen}
        handleClose={() => toggleModal()}
        onConfirm={() => {
          handleDeleteTerminal();
        }}
      />
    </>
  );
};
