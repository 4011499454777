import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { getProfiles, getTimeZone } from "../..";
import { successMessage, errorMessage } from "../../../utils";
import { editProfile } from "../..";
import { changePassword } from "../..";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import { useTabs } from "../../../shared";

export const useProfile = ({ isProfile }) => {
  const { appState } = useContext(AppContext);
  const { setCurentTab } = useTabs();
  const { profile: currentTab } = appState.currentTabs;
  const location = useLocation();
  const isStoreAdmin = location.pathname.includes("store-admin");
  const [state, setState] = useImmer({
    resetPasswordDetails: {
      errors: null,
      isBusy: false,
      isSuccess: false,
      data: {
        current_password: "",
        password: "",
        password_confirmation: "",
        showCurrentPassword: false,
        showNewPassword: false,
        showConfirmPassword: false,
      },
    },
    profileDetails: {
      isBusy: false,
      isDisable: true,
      isOpen: false,
      id: null,
      data: {
        first_name: "",
        last_name: "",
        phone: "",
        timezone: "",
        email: "",
        country_code: 61,
      },
      timezonesList: [],
    },
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (isProfile) {
      getProfile();
    }
  }, [isProfile]);
  useEffect(() => {
    if (isProfile) {
      getTimeZones();
    }
  }, [isProfile]);
  const onUpdateProfile = async (id) => {
    try {
      setState((draft) => {
        draft.profileDetails.isBusy = true;
      });
      const res = await editProfile(state.profileDetails.data);
      if (res.success) {
        successMessage("Updated Successfully");

        setState((draft) => {
          draft.profileDetails.isBusy = false;
          draft.profileDetails.isDisable = true;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.profileDetails.isBusy = false;
      });
    }
  };
  const getProfile = async () => {
    try {
      setState((draft) => {
        draft.profileDetails.isBusy = true;
      });
      const res = await getProfiles();
      if (res.success) {
        setState((draft) => {
          draft.profileDetails.isBusy = false;
          draft.profileDetails.data = res.data;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.profileDetails.isBusy = false;
      });
    }
  };
  const onDropdown = (e) => {
    setState((draft) => {
      draft.profileDetails.data.timezone = e?.value;
    });
  };
  const handleDropdown = (e) => {
    setState((draft) => {
      draft.profileDetails.data.country_code = e?.value;
    });
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.profileDetails.data[name] = value;
    });
  };

  const getTimeZones = async () => {
    try {
      setState((draft) => {
        draft.profileDetails.isBusy = true;
      });
      const res = await getTimeZone();
      if (res.success) {
        setState((draft) => {
          draft.profileDetails.isBusy = false;
          draft.profileDetails.timezonesList = res.data;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.profileDetails.isBusy = false;
      });
    }
  };

  const onEdit = () => {
    setState((draft) => {
      draft.profileDetails.isDisable = false;
    });
  };
  const onCancel = () => {
    setState((draft) => {
      draft.profileDetails.isDisable = true;
    });
  };
  const handleResetPassword = async () => {
    try {
      setState((draft) => {
        draft.resetPasswordDetails.isBusy = true;
      });
      const res = await changePassword(state.resetPasswordDetails.data);
      if (res.success) {
        successMessage(" Password changed successfully");
        setState((draft) => {
          draft.resetPasswordDetails.isBusy = false;
        });

        navigate(location?.state?.from ?? "/authentication/login");
      } else {
        setState((draft) => {
          draft.resetPasswordDetails.isBusy = false;
          draft.resetPasswordDetails.errors = res.message;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.resetPasswordDetails.isBusy = false;
        draft.resetPasswordDetails.errors = err.response
          ? err.response.data.message
          : "Unable to connect";
      });
    }
  };
  const onChanges = (event) => {
    const { name, value } = event.target;
    setState((draft) => {
      draft.resetPasswordDetails.data[name] = value;
      draft.resetPasswordDetails.errors = null;
    });
  };
  const toggleShowPassword = (name) => {
    setState((draft) => {
      draft.resetPasswordDetails.data[name] =
        !state.resetPasswordDetails.data[name];
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return {
    state,
    isStoreAdmin,
    onChange,
    setState,
    onEdit,
    getTimeZones,
    onDropdown,
    onUpdateProfile,
    handleDropdown,
    onCancel,
    handleResetPassword,
    onChanges,
    toggleShowPassword,
    handleMouseDownPassword,
    currentTab,
    setCurentTab,
  };
};
