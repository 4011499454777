import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import * as React from "react";
import {
  Button,
  Stack,
  Card,
  Typography,
  Grid,
  Divider,
  Box,
  FormHelperText,
  Chip,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useProductConfiguration } from "../hooks/useProductConfiguration";
import { Link, useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { Add } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "8px",
    border: "2px solid #E6E8F0",
    height: "48px",
  }),
  option: (provided, state) => ({
    ...provided,
    maxHeight: "239px",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 99,
  }),
};

export const AddOrEditConfigurations = () => {
  const navigate = useNavigate();
  const {
    state,
    getAllUsers,
    clearInputValue,
    isConfigModifiable,
    configurationValidator,
    handleSwitchChange,
    handleInputChange,
    onInputChange,
    handleSaveConfiguration,
    handleConfigurationChanges,
    handleAddNewEmail,
    handleDeleteEmail,
  } = useProductConfiguration();

  const {
    auto_publish,
    customer_service_email,
    delay_time,
    mail_notification_status,
    minimum_rating_level,
    notification_emails,
  } = state.configuration;

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <>
        {data.custom ? (
          <Stack
            ref={innerRef}
            {...innerProps}
            sx={{
              flexDirection: "row",
              p: "7px 8px",
              gap: "2px",
              alignItems: "center",
            }}
          >
            <Add
              sx={{
                color: "#1560D4",
              }}
            />

            <Link
              target="_blank"
              sx={{ textDecoration: "none" }}
              onClick={() => navigate("/client-account/customer/create")}
            >
              Add new User
            </Link>
          </Stack>
        ) : (
          <Stack
            ref={innerRef}
            {...innerProps}
            sx={{
              flexDirection: "row",

              p: "7px 8px",

              gap: "2px",
            }}
          >
            <Stack
              sx={{
                gap: "2px",
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight={400}
                lineHeight="17px"
              >
                {data.name}
              </Typography>
              <Typography color="#666666" variant="caption" lineHeight="15px">
                {data.email}
              </Typography>
            </Stack>
          </Stack>
        )}
      </>
    );
  };

  return (
    <>
      <ContentLayout
        title="Configurations"
        rightContent={
          <>
            {isConfigModifiable && (
              <LoadingButton
                type="button"
                size="medium"
                variant="contained"
                onClick={handleSaveConfiguration}
                loading={state.isSaving}
              >
                Save
              </LoadingButton>
            )}
          </>
        }
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Configurations", href: "" },
            ]}
          />
        }
      >
        {state.isFetching ? (
          <Stack justifyContent="center" alignItems="center" height="50vh">
            <Loader />
          </Stack>
        ) : (
          <Card>
            <Grid container spacing={2} p="32px">
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  sx={{ width: "150px" }}
                >
                  Recipients for Email Notifications
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <Stack width={"100%"} gap="20px">
                  <Stack direction="row" justifyContent="space-between">
                    <Box>
                      <Typography variant="subtitle1" fontWeight={600}>
                        Enable Email Notification
                      </Typography>
                      <Typography fontWeight={300} color="#636363">
                        If enabled, recipients will receive email notification
                        once a review is submitted.
                      </Typography>
                    </Box>
                    <InputControl
                      id="mail_notification_status"
                      type="switch"
                      checked={mail_notification_status}
                      onChange={(event) =>
                        handleSwitchChange(event, "mail_notification_status")
                      }
                      disabled={!isConfigModifiable}
                    />
                  </Stack>
                  {isConfigModifiable && (
                    <Stack
                      direction="row"
                      width="100%"
                      gap="16px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Stack width="100%">
                        <AsyncPaginate
                          label={"Enter Email".concat(
                            mail_notification_status ? " *" : ""
                          )}
                          debounceTimeout={1000}
                          placeholder="Add Mail address"
                          className="AsyncPaginate-dropdown"
                          classNamePrefix="react-select"
                          isMulti
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false}
                          inputValue={state.inputValue}
                          onInputChange={onInputChange}
                          value={notification_emails}
                          key={JSON.stringify(notification_emails)}
                          components={{ Option: CustomOption }}
                          loadOptions={(e) => getAllUsers({ search: e })}
                          onChange={(e, v) => {
                            if (v.action === "clear") {
                              clearInputValue();
                            } else if (v.action === "pop-value") {
                            } else {
                              handleInputChange(e, v);
                              configurationValidator.current.hideMessages();
                            }
                          }}
                          controlShouldRenderValue={false}
                          additional={{
                            page: 1,
                          }}
                          styles={customStyles}
                          error={configurationValidator.current.message(
                            "notificationEmails",
                            {
                              notificationEmails: notification_emails,
                              status: mail_notification_status,
                            },
                            "notificationEmails"
                          )}
                        />
                        <FormHelperText sx={{ color: "#C60808" }}>
                          {configurationValidator.current.message(
                            "notificationEmails",
                            {
                              notificationEmails: notification_emails,
                              status: mail_notification_status,
                            },
                            "notificationEmails"
                          )}
                          {configurationValidator.current.message(
                            "email",
                            state.inputValue,
                            "email"
                          )}
                        </FormHelperText>
                      </Stack>
                      <Button
                        sx={{ mb: "1px" }}
                        disabled={state.inputValue === undefined || state.inputValue === ""}
                        onClick={handleAddNewEmail}
                      >
                        Add
                      </Button>
                    </Stack>
                  )}
                </Stack>
                <Stack
                  mt={1}
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "16px",
                    rowGap: "8px",
                  }}
                >
                  {notification_emails.map((data, index) => (
                    <Chip
                      key={index}
                      label={
                        <Typography variant="subtitle2">
                          {data.email ?? data}
                        </Typography>
                      }
                      onDelete={
                        isConfigModifiable
                          ? () => handleDeleteEmail(data)
                          : undefined
                      }
                      sx={{
                        backgroundColor: "#F2F2F2",
                        height: "30px",
                        "& .MuiChip-deleteIcon": {
                          color: "#CCCCCC",
                        },
                      }}
                    />
                  ))}
                </Stack>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} p="32px">
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  sx={{ width: "150px" }}
                >
                  Auto-Publish
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <Stack width={"100%"} gap="20px">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    py="10px"
                  >
                    <Box>
                      <Typography variant="subtitle1" fontWeight={600}>
                        Enable Auto-publishing
                      </Typography>
                      <Typography fontWeight={300} color="#636363">
                        If enabled, all ratings equal or above the rating level
                        will be auto-published even if it is pending to be
                        approved from admin.
                      </Typography>
                    </Box>
                    <InputControl
                      id="auto_publish"
                      type="switch"
                      checked={auto_publish}
                      onChange={(event) =>
                        handleSwitchChange(event, "auto_publish")
                      }
                      disabled={!isConfigModifiable}
                    />
                  </Stack>
                  <InputControl
                    type="select"
                    name="minimum_rating_level"
                    label={"Minimum Rating Level".concat(
                      auto_publish ? " *" : ""
                    )}
                    placeholder="Minimum Rating Level"
                    optionValue={state.list.rating}
                    value={minimum_rating_level}
                    onChange={(event) =>
                      handleConfigurationChanges({
                        event,
                        value: event.target.value,
                      })
                    }
                    inputProps={{ readOnly: !isConfigModifiable }}
                  />
                  <InputControl
                    label={"Delay Time (mins)"}
                    name="delay_time"
                    type="text"
                    format="HH"
                    // disableOpenPicker
                    value={delay_time}
                    onChange={(event) =>
                      handleConfigurationChanges({
                        event,
                        value: event.target.value,
                      })
                    }
                    error={configurationValidator.current.message(
                      "delay_time",
                      delay_time,
                      "delayTime"
                    )}
                    helperText={configurationValidator.current.message(
                      "delay_time",
                      delay_time,
                      "delayTime"
                    )}
                    InputProps={{
                      readOnly: !isConfigModifiable,
                    }}
                    // InputProps={{
                    //   sx: {
                    //     '& input[type="time"]::-webkit-calendar-picker-indicator':
                    //       {
                    //         display: "none", // Hide the default picker icon
                    //       },
                    //     '& input[type="time"]': {
                    //       cursor: "text", // Change cursor to text for better UX
                    //     },
                    //   },
                    // }}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} p="32px">
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" fontWeight={600}>
                  Customer Service Email
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <Stack width={"100%"} gap="6px">
                  <InputControl
                    name="customer_service_email"
                    type="email"
                    label="Customer Service Email *"
                    value={customer_service_email}
                    onChange={(event) => {
                      handleConfigurationChanges({
                        event,
                        value: event.target.value,
                      });
                    }}
                    error={configurationValidator.current.message(
                      "customer_service_email",
                      customer_service_email,
                      "required|email"
                    )}
                    helperText={configurationValidator.current.message(
                      "customer_service_email",
                      customer_service_email,
                      "required|email"
                    )}
                    InputProps={{
                      readOnly: !isConfigModifiable,
                    }}
                  />
                  <FormHelperText
                    sx={{ fontSize: "14px", lineHeight: "16.94px" }}
                  >
                    Emails sent to the customer side will have this as the
                    'From' ID.
                  </FormHelperText>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        )}
      </ContentLayout>
    </>
  );
};
