import React from "react";
import { Route, Routes } from "react-router-dom";
import { DashboardContainer } from "..";


export const BFITDashboard = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<DashboardContainer />} />
            </Routes>
        </>
    );
};
