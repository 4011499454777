import {
  ContentLayout,
  HeaderBreadcrumbs,
  Loader,
  useDropdownApis,
} from "../../../shared";
import React, { useState } from "react";
import {
  Button,
  Stack,
  Card,
  Typography,
  Grid,
  TextField,
  createFilterOptions,
  Checkbox,
  Chip,
  FormHelperText,
  Box,
  Divider,
  styled,
  Popper,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { DropzoneComponents } from "../../BirthdayCoupons/components";

import { UseMenuBanners } from "../hooks/UseMenuBanners";
import { useCouponList } from "../../Coupons";
import { useCategory } from "../../Category/hooks";
import { useProductsList } from "../../Products";
import moment from "moment";
import { useStore } from "../..";
import { AsyncPaginate } from "react-select-async-paginate";

export const CreateOrEditMenuBanners = () => {
  const { id } = useParams();
  const {
    state,
    onChange,
    globalData,
    onImageChange,
    onDateChange,
    onTimeChange,
    onStoresSelect,
    onDeleteSelectedStores,
    onUpdateBanner,
    onSaveBanner,
    checkAllChange,
    validateDate,
    setState,
    isAllValid,
    extractedProducts,
    handleClick,
    open,
    handleDropdown,
  } = UseMenuBanners({
    load: false,
    id,
    isProduct: true,
  });

  const { handleGetProductsList } = useDropdownApis({});
  const {
    bannerName,
    isDetailsFetching,
    isSaveButtonBusy,
    data: {
      title,
      image,
      type,
      link_to,
      start_time,
      end_time,
      start_date,
      end_date,
      stores,
      status,
    },
    validation,
  } = state;
  const { coupons } = useCouponList({ isCoupons: true });
  const { categories } = useCategory({ isCategory: true });
  const { storeList } = useDropdownApis({
    isStoreList: true,
  });
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        endDate: {
          message: "The start date should be before end date",
          rule: (value) => {
            const { startTime, endTime } = value;
            return endTime
              ? moment(startTime).isSameOrBefore(moment(endTime))
              : true;
          },
        },
        endTime: {
          message: "The start time should be before end time",
          rule: (value) => {
            const { startTime, endTime, start_date, end_date } = value;
            return startTime && end_date
              ? moment(start_date).isBefore(moment(end_date))
                ? true
                : moment(startTime).isBefore(moment(endTime))
                ? true
                : false
              : true;
          },
        },
      },
    })
  );

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.name,
  });

  const PopperStyledComponent = styled(Popper)(({ theme }) => ({
    border: `1px solid ${
      theme.palette.mode === "light"
        ? "rgba(149, 157, 165, 0.2)"
        : "rgb(1, 4, 9)"
    }`,
  }));

  const onSubmit = (type) => {
    if (validator.current.allValid() && isAllValid()) {
      id ? onUpdateBanner(type, id) : onSaveBanner(type);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
      setState((draft) => {
        draft.validation.isValidated = true;
      });
    }
  };

  const isValueValid = validator.current.message(
    "Product",
    link_to,
    "required"
  );

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      minHeight: "55px",
      borderColor: isValueValid ? "#C60808" : "#E6E8F0",
      boxShadow: null,
      "&:hover": {
        borderColor: isValueValid ? "#C60808" : "#E6E8F0",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      height: "300px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: isValueValid ? "#C60808" : "#8e8e8e",
      };
    },
  };

  return (
    <>
      {validator.current.purgeFields()}
      <ContentLayout
        title={id ? bannerName : "New Banner"}
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="text"
              sx={{
                minWidth: "59px",
                // p: "8px",
              }}
              onClick={() => onSubmit("draft")}
              disabled={isSaveButtonBusy}
            >
              Save As Draft
            </Button>
            <Button
              type="button"
              size="medium"
              variant="contained"
              onClick={() => onSubmit("publish")}
              disabled={isSaveButtonBusy}
            >
              Publish
            </Button>
          </>
        }
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              {
                name: "Menu Banners",
                href: "/menu-banner",
              },
              id
                ? {
                    name: `${bannerName}`,
                    href: `/menu-banner/details/${id}`,
                  }
                : null,
              { name: id ? "Edit" : "Create" },
            ].filter(Boolean)}
          />
        }
        chipType={!id ? false : "other"}
        statusContent={
          status === 1 ? (
            <Chip
              label="Published"
              color="success"
              variant="outlined"
              size="small"
              sx={{ border: "none !important" }}
            />
          ) : (
            <Chip
              label="Draft"
              color="primary"
              variant="outlined"
              size="small"
              sx={{ bgcolor: "#1560D414", border: "none" }}
              disabled={false}
            />
          )
        }
      >
        {id && isDetailsFetching ? (
          <Loader />
        ) : (
          <>
            <Card sx={{ p: "32px 24px", mb: "24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Basic Details
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack width={"100%"} gap="20px">
                    <InputControl
                      name="title"
                      label="Title"
                      onChange={onChange}
                      value={title}
                      required
                      error={validator.current.message(
                        "Title",
                        title,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "Title",
                        title,
                        "required"
                      )}
                    />
                    <Stack width="400px">
                      <DropzoneComponents
                        imgUrl={image}
                        handleDropzone={(e) => {
                          onImageChange(e);
                        }}
                        aspectRatio="2/1"
                      />
                      <FormHelperText sx={{ color: "#C60808" }}>
                        {validator.current.message("Image", image, "required")}
                      </FormHelperText>
                    </Stack>
                    <InputControl
                      type="dropdown"
                      name="type"
                      label="Type"
                      getOptionLabel={(option) => option.label}
                      value={
                        (globalData?.banner?.banner_types?.length > 0 &&
                          type &&
                          globalData?.banner?.banner_types?.find(
                            (c) => c.value === type
                          )) ||
                        null
                      }
                      required
                      options={globalData?.banner?.banner_types ?? []}
                      disableClearable
                      onChange={(e, newValue) => {
                        onChange({
                          target: {
                            name: "type",
                            value: newValue.value,
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"type*"}
                          name="type"
                          error={validator.current.message(
                            "Type",
                            type,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "Type",
                            type,
                            "required"
                          )}
                        />
                      )}
                    />
                    {type === 4 ? (
                      <InputControl
                        name="link_to"
                        label="URL"
                        onChange={onChange}
                        value={link_to}
                        required
                        error={validator.current.message(
                          "URL",
                          link_to,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "URL",
                          link_to,
                          "required"
                        )}
                      />
                    ) : type === 3 ? (
                      <Box>
                        <InputControl
                          name="scope"
                          type="dropdown"
                          disableCloseOnSelect
                          disableClearable
                          options={state.products}
                          className="tags-input"
                          value={
                            (extractedProducts?.length > 0 &&
                              link_to &&
                              extractedProducts?.find(
                                (c) => c.value === link_to
                              )) ||
                            null
                          }
                          renderOption={(props, option, { selected }) => (
                            <>
                              <ListItemButton
                                key={1}
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "rgba(21, 96, 212, 0.08)",
                                    borderRadius: "8px",
                                  },
                                }}
                                onClick={() => {
                                  handleClick(option.id);
                                }}
                                z
                              >
                                <ListItemText
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontWeight: 600,
                                    },
                                  }}
                                  primary={
                                    <Typography
                                      sx={{
                                        display: "inline",
                                        fontWeight: 600,
                                      }}
                                      component="span"
                                      variant="subtitle2"
                                      color="#171717"
                                    >
                                      {option.name}({option.products.length})
                                    </Typography>
                                  }
                                />
                                {option.products.length > 0 && (
                                  <>
                                    <img
                                      src="/icons/ic_dropdown.svg"
                                      style={{
                                        transform:
                                          open === option.id
                                            ? ""
                                            : "rotate(180deg)",
                                      }}
                                    />
                                  </>
                                )}
                              </ListItemButton>

                              <Collapse
                                in={open === option.id ? true : false}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List
                                  component="div"
                                  disablePadding
                                  sx={{
                                    backgroundColor: open
                                      ? "rgba(21, 96, 212, 0.08)"
                                      : "",
                                    borderRadius: "8px",
                                    mb: 0.5,
                                  }}
                                >
                                  {option.products.map((data, i) => (
                                    <ListItem
                                      sx={{
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(21, 96, 212, 0.08)",
                                          borderRadius: "8px",
                                        },
                                      }}
                                      key={i}
                                      {...props}
                                      onClick={(e) => {
                                        handleDropdown(
                                          {
                                            target: {
                                              name: "value",
                                              value: data,
                                            },
                                          },
                                          i,
                                          "products"
                                        );
                                      }}
                                    >
                                      {data.label}
                                    </ListItem>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}
                          renderTags={(value, getTagProps) => (
                            <div>
                              {value.map((option, i) => (
                                <span key={i}>{option.label} </span>
                              ))}
                            </div>
                          )}
                          filterOptions={(options, state) => {
                            return options.map((val) => {
                              return {
                                ...val,
                                products: val.products.filter((val, i) => {
                                  return (
                                    val.label
                                      .toString()
                                      .toLowerCase()
                                      .indexOf(state.inputValue.toLowerCase()) >
                                    -1
                                  );
                                }),
                              };
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Products Name"
                              error={validator.current.message(
                                "Products",
                                link_to,
                                "required"
                              )}
                              helperText={validator.current.message(
                                "Products",
                                link_to,
                                "required"
                              )}
                              required
                            />
                          )}
                        />
                      </Box>
                    ) : (
                      <InputControl
                        type="dropdown"
                        name="link_to"
                        getOptionLabel={(option) => option.name}
                        value={
                          (link_to &&
                            (type === 1
                              ? coupons?.find((c) => c.id === link_to)
                              : type === 2
                              ? categories?.find((c) => c.id === link_to)
                              : null)) ||
                          null
                        }
                        required
                        options={
                          type === 1 ? coupons : type === 2 ? categories : []
                        }
                        disableClearable
                        onChange={(e, newValue) => {
                          onChange({
                            target: {
                              name: "link_to",
                              value: newValue.id,
                            },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              globalData?.banner?.banner_types?.find(
                                (c) => c.value === type
                              )?.label || "Link to"
                            }
                            name="link_to"
                            error={validator.current.message(
                              globalData?.banner?.banner_types?.find(
                                (c) => c.value === type
                              )?.label || "Link to",
                              link_to,
                              "required"
                            )}
                            helperText={validator.current.message(
                              globalData?.banner?.banner_types?.find(
                                (c) => c.value === type
                              )?.label || "Link to",
                              link_to,
                              "required"
                            )}
                            required
                          />
                        )}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Card>
            <Card sx={{ p: "32px 24px", mb: "24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Date & Store
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack width={"100%"} gap="20px">
                    <Stack direction="row" width={"100%"} gap="20px">
                      <InputControl
                        type="datePicker"
                        name="start_date"
                        label="Start Date"
                        disablePast
                        inputFormat="dd-MM-yyyy"
                        sx={{
                          width: "100%",
                        }}
                        value={start_date}
                        onChange={(e) => {
                          onDateChange(e, "start_date");
                        }}
                        onError={(reason, value) =>
                          validateDate(reason, "start_date", "date")
                        }
                        error={
                          validation.isValidated &&
                          (validation.start_date ?? false)
                        }
                        helperText={
                          validation.isValidated &&
                          (validation.start_date ?? false)
                        }
                      />
                      <InputControl
                        type="datePicker"
                        name="end_date"
                        label="End Date"
                        inputFormat="dd-MM-yyyy"
                        disablePast
                        sx={{
                          width: "100%",
                        }}
                        value={end_date}
                        onChange={(e) => {
                          onDateChange(e, "end_date");
                        }}
                        onError={(reason, value) =>
                          validateDate(reason, "end_date", "date")
                        }
                        error={
                          validation.isValidated &&
                          (validation.end_date
                            ? validation.end_date
                            : start_date &&
                              validator.current.message(
                                "End Date",
                                {
                                  startTime: start_date,
                                  endTime: end_date,
                                },
                                "endDate"
                              ))
                        }
                        helperText={
                          validation.isValidated &&
                          (validation.end_date
                            ? validation.end_date
                            : start_date &&
                              validator.current.message(
                                "End Date",
                                {
                                  startTime: start_date,
                                  endTime: end_date,
                                },
                                "endDate"
                              ))
                        }
                      />
                    </Stack>
                    <Stack direction="row" width={"100%"} gap="20px">
                      <InputControl
                        ampm={false}
                        type="timePicker"
                        label="Start Time"
                        name="start_time"
                        value={
                          start_time
                            ? moment(start_time).format("YYYY-MM-DD HH:mm:ss")
                            : null
                        }
                        onChange={(time) => onTimeChange(time, "start_time")}
                        onError={(reason, value) =>
                          validateDate(reason, "start_time", "time")
                        }
                        error={
                          validation.isValidated &&
                          (validation.start_time ?? false)
                        }
                        helperText={
                          validation.isValidated &&
                          (validation.start_time ?? false)
                        }
                      />

                      <InputControl
                        ampm={false}
                        type="timePicker"
                        label="End Time"
                        name="end_time"
                        value={end_time}
                        onChange={(time) => onTimeChange(time, "end_time")}
                        onError={(reason, value) =>
                          validateDate(reason, "end_time", "time")
                        }
                        error={
                          validation.isValidated &&
                          (validation.end_time
                            ? validation.end_time
                            : end_time &&
                              validator.current.message(
                                "End Time",
                                {
                                  startTime: start_time,
                                  endTime: end_time,
                                  start_date,
                                  end_date,
                                },
                                "endTime"
                              ))
                        }
                        helperText={
                          validation.isValidated &&
                          (validation.end_time
                            ? validation.end_time
                            : end_time &&
                              validator.current.message(
                                "End Time",
                                {
                                  startTime: start_time,
                                  endTime: end_time,
                                  start_date,
                                  end_date,
                                },
                                "endTime"
                              ))
                        }
                      />
                    </Stack>
                    <Stack gap="8px">
                      <InputControl
                        type="dropdown"
                        name="Store"
                        label="Store"
                        multiple={true}
                        options={storeList || []}
                        value={stores}
                        disableCloseOnSelect
                        disableClearable={true}
                        onChange={(e, v) => onStoresSelect(e.target.checked, v)}
                        getOptionLabel={(option) => option.name}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => <></>)
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        PopperComponent={(param) => (
                          <PopperStyledComponent {...param}>
                            <Box {...param} />
                            <Divider />
                            <Box
                              sx={{
                                backgroundColor: "white",
                                height: "45px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <Checkbox
                                checked={storeList.length === stores.length}
                                onChange={(e) => checkAllChange(storeList, e)}
                                id="check-all"
                                sx={{ marginRight: "8px" }}
                                onMouseDown={(e) => e.preventDefault()}
                              />
                              Select All
                            </Box>
                          </PopperStyledComponent>
                        )}
                        renderOption={(props, option, { selected }) => (
                          <>
                            <li {...props} key={option.id}>
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          </>
                        )}
                        filterOptions={filterOptions}
                        sx={{}}
                        componentsProps={{
                          paper: {
                            sx: {
                              width: "fit-content",
                              minWidth: "100%",
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Store"
                            placeholder="Store"
                          />
                        )}
                      />
                      <Stack
                        sx={{
                          flexDirection: "row",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "12px",
                        }}
                      >
                        {stores.map((data, index) => (
                          <Chip
                            key={index}
                            label={
                              <Typography variant="body2" fontSize="14px">
                                {data.name}
                              </Typography>
                            }
                            onDelete={() => onDeleteSelectedStores(data.id)}
                            sx={{
                              backgroundColor: "#E6E6E6",
                              height: "30px",
                              "& .MuiChip-deleteIcon": {
                                color: "#808080",
                              },
                            }}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </ContentLayout>
    </>
  );
};

const CustomOption = (props) => {
  const { data, innerRef, innerProps, isSelected } = props;
  return (
    <Stack
      ref={innerRef}
      {...innerProps}
      sx={{
        flexDirection: "row",
        p: "7px 8px",
        gap: "2px",
        alignItems: "center",
      }}
    >
      <Typography variant="subtitle2" fontWeight={400} lineHeight="17px">
        {data.label}
      </Typography>
    </Stack>
  );
};
