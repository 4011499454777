import { API } from "../../../utils";

export const getTerminalList = async (query) => {
  let url = "terminals";
  const res = await API.get(url, query);
  return res.data;
};

export const getTerminalDetailsById = async (id) => {
  const res = await API.get(`terminals/${id}`);
  return res.data;
};

export const createTerminal = async (payload) => {
  const res = await API.post(`terminals`, payload);
  return res.data;
};

export const editTerminal = async (id, payload) => {
  const res = await API.put(`terminals/${id}`, payload);
  return res.data;
};

export const deleteTerminalById = async (id) => {
  const res = await API.delete(`terminals/${id}`);
  return res.data;
};

export const unPairTerminalId = async (id) => {
  const res = await API.put(`terminals/${id}/remove`);
  return res.data;
};

export const setDefaultSaleType = async (terminalId, saleTypeId) => {
  const res = await API.get(
    `terminals/${terminalId}/saletype/${saleTypeId}/default`
  );
  return res.data;
};

export const updateSaleType = async (terminalId, saleTypeId, status) => {
  const res = await API.get(
    `terminals/${terminalId}/saletype/${saleTypeId}/status/${status}`
  );
  return res.data;
};

export const createTerminalEndofDays = async (payload) => {
  const res = await API.post(`end-of-days`, payload);
  return res.data;
};
export const getTerminalEndofDaySummary = async (payload) => {
  const res = await API.post(`end-of-days/orders-summary`, payload);
  return res.data;
};
export const getTerminalListToEndofdayInTerminal = async (id, query) => {
  const res = await API.get(
    `terminals?store[]=${id}&status=${1}&connection_status=${1}`
  );
  return res.data;
};