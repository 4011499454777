import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import { useDebounce } from "use-debounce";
import qs from "qs";
import {
  assignPoductToMenuTemplate,
  changeCategoryOrderMenuTemplate,
  changeProductOrderMenuTemplate,
  createMenuTemplate,
  createMenuTemplateCategory,
  deleteCategoryMenuTemplateById,
  deleteMenuTemplateById,
  deleteProductMenuTemplateById,
  editMenuTemplateCategory,
  getCategoryMenuTemplateById,
  getCategorywiseProductsMenuTemplate,
  getMenuTemplate,
  getMenuTemplateById,
  getMenuTemplateCategory,
  getMenuTemplateProduct,
  updateMenuTemplate,
} from "../api/menuTemplateApi";
import { usePermission } from "../../../shared";

export const useMenuTemplate = ({ isList, isMenuId, menuId, categoryId }) => {
  const isEditable = usePermission("menu-modify");
  const isDeletable = usePermission("menu-delete");
  const isCreateVisible = usePermission("menu-create");
  const [state, setState] = useImmer({
    isBusy: false,
    initialLoad: true,
    isSaveButtonBusy: false,
    isCreateOpen: false,
    isEditOpen: false,
    selectedId: null,
    isOpen: false,
    isCategoryOpen: false,
    cid: "",
    id: "",
    pid: "",
    iconFile: "",
    menuTemplates: [],
    menuTemplateCategory: [],
    menuTemplateProduct: [],
    filters: {
      isSearching: false,
      searchText: "",
    },
    categoryFilters: {
      isSearching: false,
      searchText: "",
    },
    productFilters: {
      isSearching: false,
      searchText: "",
    },
    data: {
      name: "",
    },
    categoryData: { name: "", icon: "", color: "" },
    productDetails: {
      isLoading: true,
      isSaveButtonBusy: false,
      isOpen: false,
      isDelete: false,
      id: null,
      productList: [],
      allPoducts: [],
      filteredPoducts: [],
      selectedPoducts: [],
      allCategoryProductList: [],
    },
  });

  const [debouncedData] = useDebounce(state.productFilters.searchText, 1000);
  useEffect(() => {
    if (menuId) {
      getMenuTemplateProductList({ menuId });
    }
  }, [menuId]);
  useEffect(() => {
    if (!state.initialLoad && !state.productFilters.isSearching) {
      getMenuTemplateProductList({ menuId, debouncedData });
    }
  }, [menuId, debouncedData]);
  const getMenuTemplateProductList = async ({ menuId, debouncedData }) => {
    let params = {};
    if (debouncedData) {
      params = { ...params, search: debouncedData };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });
      const res = await getMenuTemplateProduct(menuId, categoryId, query);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.initialLoad = false;
          draft.menuTemplateProduct = res.data;
          draft.productDetails.productList = res.data;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
      });
    }
  };

  const onChangeProductFilter = (e) => {
    const { value } = e.target;
    setState((draft) => {
      draft.productFilters.searchText = value;
      draft.productFilters.isSearching = false;
    });
  };
  const onProductSeachClear = () => {
    setState((draft) => {
      draft.productFilters.searchText = "";
    });
  };
  const onProductOrderChange = async (data) => {
    const payload = {
      menu_products: data.map((x, i) => ({
        id: x.id,
        display_order: i,
      })),
    };
    try {
      const res = await changeProductOrderMenuTemplate(
        menuId,
        categoryId,
        payload
      );
      successMessage(res.message);
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const toggleProductModal = (id = null) => {
    setState((draft) => {
      draft.pid = id;
      draft.isCategoryOpen = !draft.isCategoryOpen;
    });
  };

  const handleDeleteProductMenuTemplate = async () => {
    try {
      const res = await deleteProductMenuTemplateById(
        menuId,
        categoryId,
        state.pid
      );
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.menuTemplateProduct = draft.menuTemplateProduct.filter(
            (item) => item.id !== state.pid
          );
        });
        toggleProductModal();
        getMenuTemplateProductList({ menuId });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  //----------------------------------------------------

  const [debounced] = useDebounce(state.categoryFilters.searchText, 1000);
  useEffect(() => {
    if (isMenuId) {
      getMenuTemplateCategoryList({ isMenuId });
    }
  }, [isMenuId]);
  useEffect(() => {
    if (!state.initialLoad && !state.filters.isSearching) {
      getMenuTemplateCategoryList({ isMenuId, debounced });
    }
  }, [isMenuId, debounced]);
  const getMenuTemplateCategoryList = async ({ isMenuId, debounced }) => {
    let params = {};
    if (debounced) {
      params = { ...params, search: debounced };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });
      const res = await getMenuTemplateCategory(isMenuId, query);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.initialLoad = false;
          draft.menuTemplateCategory = res.data;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
      });
    }
  };
  const onCategoryOrderChange = async (data) => {
    const payload = {
      menu_categories: data.map((x, i) => ({
        id: x.id,
        display_order: i,
      })),
    };
    try {
      const res = await changeCategoryOrderMenuTemplate(isMenuId, payload);
      successMessage(res.message);
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const onChangeCategoryFilter = (e) => {
    const { value } = e.target;
    setState((draft) => {
      draft.categoryFilters.searchText = value;
      draft.categoryFilters.isSearching = false;
    });
  };
  const onCategorySeachClear = () => {
    setState((draft) => {
      draft.categoryFilters.searchText = "";
    });
  };
  const toggleCategoryModal = (id = null) => {
    setState((draft) => {
      draft.cid = id;
      draft.isCategoryOpen = !draft.isCategoryOpen;
    });
  };
  const handleDeleteCategoryMenuTemplate = async () => {
    try {
      const res = await deleteCategoryMenuTemplateById(isMenuId, state.cid);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.menuTemplateCategory = draft.menuTemplateCategory.filter(
            (item) => item.id !== state.cid
          );
        });
        toggleCategoryModal();
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleCategoryModal = async (id = null, type) => {
    if (type === "create") {
      setState((draft) => {
        draft.id = null;
        draft.isCreateOpen = !draft.isCreateOpen;
      });
    } else if (type === "edit") {
      try {
        const res = await getCategoryMenuTemplateById(isMenuId, id);
        if (res.success) {
          setState((draft) => {
            draft.selectedId = id;
            draft.isEditOpen = !draft.isEditOpen;
            draft.categoryData = res.data;
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
      }
    } else {
      setState((draft) => {
        draft.cid = null;
        draft.isEditOpen = false;
        draft.isCreateOpen = false;
        draft.selectedId = null;
      });
    }
  };
  const onSaveMenuTemplateCategory = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      let formData = new FormData();
      Object.keys(state.categoryData).map((key) => {
        formData.append(key, state.categoryData[key]);
      });
      !state.iconFile
        ? formData.delete("icon")
        : formData.append("icon", state.iconFile);

      const res = await createMenuTemplateCategory(isMenuId, formData);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        handleCategoryModal(false);
        getMenuTemplateCategoryList({ isMenuId });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onChangeMenuTemplateCategory = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.categoryData.name = e.target.value;
    });
  };
  const handleColor = (e) => {
    setState((draft) => {
      draft.categoryData.color = e === null ? e : e?.value;
    });
  };

  const onFileChange = (e, name) => {
    if (e.target.value !== "") {
      if (e?.target?.files[0]?.type.includes("image")) {
        setState((draft) => {
          draft.iconFile = e.target.files[0];
        });
      } else {
        errorMessage("Unsupported file type ");
      }
    }
  };
  const onUpdateMenuTemplateCategory = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      let formData = new FormData();
      Object.keys(state.categoryData).map((key) => {
        if (key !== "icon") {
          formData.append(key, state.categoryData[key]);
        }
      });
      state.iconFile && formData.append("icon", state.iconFile);
      formData.append("_method", "PUT");
      const res = await editMenuTemplateCategory(
        isMenuId,
        state.selectedId,
        formData
      );
      if (res.success) {
        successMessage("Updated Successfully");
        handleCategoryModal(false);
        getMenuTemplateCategoryList({ isMenuId });
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  //----------------------------------------------------------

  const [debouncedText] = useDebounce(state.filters.searchText, 1000);
  useEffect(() => {
    if (isList) {
      getMenuTemplateList();
    }
  }, [isList]);
  useEffect(() => {
    if (!state.initialLoad && !state.filters.isSearching) {
      getMenuTemplateList(debouncedText);
    }
  }, [debouncedText]);
  const getMenuTemplateList = async (searchText) => {
    let params = {};
    if (searchText) {
      params = { ...params, search: searchText };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });
      const res = await getMenuTemplate(query);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.initialLoad = false;
          draft.menuTemplates = res.data;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
      });
    }
  };
  const onChangeFilter = (e) => {
    const { value } = e.target;
    setState((draft) => {
      draft.filters.searchText = value;
      draft.filters.isSearching = false;
    });
  };
  const onSaveMenuTemplate = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await createMenuTemplate(state.data);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        handleModal(false);
        getMenuTemplateList();
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const onChangeMenuTemplate = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };

  const handleModal = async (id = null, type) => {
    if (type === "create") {
      setState((draft) => {
        draft.id = null;
        draft.isCreateOpen = !draft.isCreateOpen;
      });
    } else if (type === "edit") {
      try {
        const res = await getMenuTemplateById(id);
        if (res.success) {
          setState((draft) => {
            draft.selectedId = id;
            draft.isEditOpen = !draft.isEditOpen;
            draft.data.name = res.data.name;
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
      }
    } else {
      setState((draft) => {
        draft.id = null;
        draft.isEditOpen = false;
        draft.isCreateOpen = false;
        draft.data.name = "";
        draft.selectedId = null;
      });
    }
  };
  useEffect(() => {
    if (!state.isCreateOpen) {
      setState((draft) => {
        draft.data.name = "";
        draft.categoryData.name = "";
        draft.categoryData.color = "";
        draft.categoryData.icon = "";
      });
    }
  }, [state.isCreateOpen]);
  const toggleModal = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isOpen = !draft.isOpen;
    });
  };
  const onUpdateMenuTemplate = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await updateMenuTemplate(state.selectedId, state.data);
      if (res.success) {
        successMessage("Updated Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        getMenuTemplateList();
        handleModal();
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const handleDeleteMenuTemplate = async () => {
    try {
      const res = await deleteMenuTemplateById(state.id);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.menuTemplates = draft.menuTemplates.filter(
            (item) => item.id !== state.id
          );
        });
        toggleModal();
      } else {
        errorMessage(res.message);
        toggleModal();
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const onSeachClear = () => {
    setState((draft) => {
      draft.filters.searchText = "";
    });
  };
  //---------------------------------------------
  useEffect(() => {
    if (state.isCreateOpen && menuId) {
      getCategorywiseProductsList();
    }
  }, [menuId, state.isCreateOpen]);
  const getCategorywiseProductsList = async () => {
    const product_types = ["bundle", "platter", "retail", "modifier"];
    try {
      let params = {};

      if (product_types.length) {
        params = { ...params, productTypeArray: product_types };
      }

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = false;
      });
      const res = await getCategorywiseProductsMenuTemplate(query);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.productDetails.allCategoryProductList = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const HandleFilterProduct = (type, text) => {
    const ProductList = state.productDetails.allCategoryProductList;
    if (type === "initial") {
      setState((draft) => {
        draft.productDetails.filteredPoducts = ProductList;
        return draft;
      });
    } else {
      const Search = (data, text) => {
        text = text.toLowerCase();
        return data
          .map((val, i) => {
            let filtered = val.products.filter((data) => {
              if (data.name.toString().toLowerCase().indexOf(text) > -1)
                return true;
            });
            return filtered.length ? { ...val, products: filtered } : undefined;
          })
          .filter(Boolean);
      };
      setState((draft) => {
        draft.productDetails.filteredPoducts = Search(ProductList, text);
        return draft;
      });
    }
  };

  useEffect(() => {
    HandleFilterProduct("initial");
  }, [
    state.productDetails.productList,
    state.productDetails.allCategoryProductList,
  ]);
  const handlePoductSelect = (type, value, event) => {
    if (type === "body") {
      if (event.target.checked) {
        setState((draft) => {
          draft.productDetails.selectedPoducts = [
            ...draft.productDetails.selectedPoducts,
            value.id,
          ];
        });
      } else {
        setState((draft) => {
          draft.productDetails.selectedPoducts =
            draft.productDetails.selectedPoducts.filter(
              (val) => val !== value.id
            );
        });
      }
    } else {
      const subRowsID = value.products?.map((e, i) => {
        return e.id;
      });
      if (event.target.checked) {
        const alReadyIds = state.productDetails.productList.map((e, i) => {
          return e.id;
        });
        setState((draft) => {
          draft.productDetails.selectedPoducts = [
            ...new Set([...state.productDetails.selectedPoducts, ...subRowsID]),
          ].filter((val) => !alReadyIds.includes(val));
        });
      } else {
        setState((draft) => {
          draft.productDetails.selectedPoducts =
            draft.productDetails.selectedPoducts.filter(
              (val) => !subRowsID.includes(val)
            );
        });
      }
    }
  };

  const handleAssignProducttoMenuTemplate = async () => {
    const filterProducts = state?.productDetails?.productList?.map(
      (i) => i.product_id
    );

    const data = {
      product_id: [...state.productDetails.selectedPoducts, ...filterProducts],
    };

    setState((draft) => {
      draft.productDetails.isSaveButtonBusy = true;
    });

    try {
      const res = await assignPoductToMenuTemplate(data, menuId, categoryId);
      if (res.success === true) {
        successMessage(res.message);

        setState((draft) => {
          draft.productDetails.productList = [
            ...draft.productDetails.productList,
            ...draft.productDetails.allPoducts.filter((val) =>
              draft.productDetails.selectedPoducts.includes(val.id)
            ),
          ];
          draft.productDetails.isLoading = false;
          draft.productDetails.isSaveButtonBusy = false;
          draft.productDetails.selectedPoducts = [];
          return draft;
        });
        handleProductModal(false);
        getMenuTemplateProductList({ menuId });
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.productDetails.isSaveButtonBusy = false;
      });
    }
  };
  const handleProductModal = async (type) => {
    setState((draft) => {
      draft.isCreateOpen = type;
    });
  };
  return {
    state,
    isCreateVisible,
    isEditable,
    isDeletable,
    onChangeFilter,
    onSaveMenuTemplate,
    onChangeMenuTemplate,
    handleModal,
    toggleModal,
    handleDeleteMenuTemplate,
    onUpdateMenuTemplate,
    onCategoryOrderChange,
    onChangeCategoryFilter,
    onSeachClear,
    onCategorySeachClear,
    toggleCategoryModal,
    handleDeleteCategoryMenuTemplate,
    handleCategoryModal,
    onSaveMenuTemplateCategory,
    onChangeMenuTemplateCategory,
    handleColor,
    onFileChange,
    onUpdateMenuTemplateCategory,
    onChangeProductFilter,
    onProductSeachClear,
    onProductOrderChange,
    toggleProductModal,
    handleDeleteProductMenuTemplate,
    HandleFilterProduct,
    handlePoductSelect,
    handleAssignProducttoMenuTemplate,
    handleProductModal,
  };
};
