import { API } from "../../../utils";

export const getManagerPasswordsList = async () => {
    const res = await API.get(`manager-passwords`);
    return res.data;
};

export const EnableOrDisablePasswords = async (id) => {
    const res = await API.get(`manager-passwords/${id}/status`);
    return res.data;
};

