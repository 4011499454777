import { API } from "../../../utils";

export const acceptInvitation = async (token) => {
  const res = await API.post(`invitations/${token}/accept`);
  return res.data;
};
export const registerProfile = async (token,payload) => {
  const res = await API.post(`invitations/${token}`,payload);
  return res.data;
};
export const getInvitationDetails = async (token) => {
    const res = await API.get(`invitations/${token}`);
    return res.data;
  };