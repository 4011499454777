import { ContentLayout, HeaderBreadcrumbs } from "../../../shared";
import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Stack,
  Card,
  Typography,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  Switch,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";

import { useLoyalty } from "../hooks";
import { AsyncPaginate } from "react-select-async-paginate";

export const LoyaltyAddorEditCustomer = () => {
  const { id } = useParams();
  const updateMode = id ? id : false;
  const [, forceUpdate] = useState();

  const {
    state,
    globalData,
    handleCustomerInputChange,
    onSaveLoyaltyCustomer,
    handleGetCustomerList,
    onUpdateCustomer,
  } = useLoyalty({
    isCusAdd: id ? false : true,
    isCusEdit: updateMode,
  });

  const { updateData, customerSelect, details, dataList, isLoading } =
    state.customers;


  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        isCus: {
          message: "Customer with same type already exist.",
          rule: (value) => {
            const { data, customers, type, allTypes } = value;
            if (customers && type) {
              let isType = allTypes?.find((i) => i.value === type)?.label;

              return !data.some(
                (data) =>
                  data.customer_id === customers.value &&
                  data.loyalty_card_type === isType
              );
            } else {
              return true;
            }
          },
        },
      },
    })
  );

  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdateCustomer(id) : onSaveLoyaltyCustomer();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: "2px solid #E6E8F0",
      height: "48px",
    }),
    option: (provided, state) => ({
      ...provided,
      maxHeight: "239px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <Stack
        ref={innerRef}
        {...innerProps}
        sx={{
          p: "7px 10px",
          gap: "2px",
        }}
      >
        <Typography variant="subtitle2" fontWeight={400} lineHeight="17px">
          {data.label}
        </Typography>
        <Typography color="#666666" variant="caption" lineHeight="15px">
          {data.email}, {data.phone}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      {validator.current.purgeFields()}
      <ContentLayout
        title={id ? details?.customer_name : "New Card"}
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              onClick={onSubmit}
            >
              {id ? "Update" : "Save"}
            </Button>
          </>
        }
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Loyalty Cards", href: "/loyalty-cards" },
                {
                  name: "Cards",
                  href: `/loyalty-cards?cards`,
                },
                id
                  ? {
                    name: details?.customer_name,
                    href: `/loyalty-cards/details/${id}`,
                  }
                  : false,
                { name: id ? "Edit" : "Create" },
              ].filter(Boolean)}
            />
          </>
        }
      >
        <Card sx={{ p: "32px 24px" }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 3, sm: 24 }}
            gap="24px"
          >
            <Typography variant="subtitle1" fontWeight={600}>
              Details
            </Typography>

            <Stack width={"100%"} gap="20px">
              {!id && (
                <Stack>
                  <AsyncPaginate
                    debounceTimeout={1000}
                    value={customerSelect}
                    placeholder="Customer Name"
                    className="AsyncPaginate-dropdown"
                    classNamePrefix="react-select"
                    components={{ Option: CustomOption }}
                    loadOptions={(e, p, { page }) =>
                      handleGetCustomerList({ search: e, page })
                    }
                    onChange={(e) => {
                      handleCustomerInputChange({
                        target: {
                          name: "customer_id",
                          value: e,
                        },
                      });
                    }}
                    additional={{
                      page: 1,
                    }}
                    styles={customStyles}
                  />
                  <FormHelperText sx={{ color: "#C60808" }}>
                    {validator.current.message(
                      "Customer Name",
                      customerSelect,
                      "required"
                    )}
                  </FormHelperText>
                  <FormHelperText sx={{ color: "#C60808" }}>
                    {validator.current.message(
                      "Amount",
                      {
                        data: dataList,
                        customers: customerSelect,
                        type: updateData.loyalty_card_type_id,
                        allTypes: globalData.loyalty_cards?.loyalty_card_types,
                      },
                      "required|isCus"
                    )}
                  </FormHelperText>
                </Stack>
              )}
              {id && (
                <InputControl
                  label="Name"
                  value={details.customer_name}
                  disabled
                />
              )}
              <InputControl
                type="select"
                name="loyalty_card_type_id"
                label="Card Type"
                value={updateData.loyalty_card_type_id}
                optionValue={globalData.loyalty_cards?.loyalty_card_types || []}
                onChange={handleCustomerInputChange}
                error={validator.current.message(
                  "Card Type",
                  updateData.loyalty_card_type_id,
                  "required"
                )}
                helperText={validator.current.message(
                  "Card Type",
                  updateData.loyalty_card_type_id,
                  "required"
                )}
                disabled={id}
              />
              <Stack width={"100%"} gap="20px">
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-helper-label">
                    Scan or Enter Barcode
                  </InputLabel>
                  <OutlinedInput
                    required
                    name="barcode"
                    id="demo-simple-select-helper-label"
                    value={updateData.barcode}
                    onChange={handleCustomerInputChange}
                    error={validator.current.message(
                      "barcode",
                      updateData.barcode,
                      "required"
                    )}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <img src="/icons/barcode.svg" />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Scan or Enter Barcode"
                  />
                  <FormHelperText sx={{ color: "#C60808" }}>
                    {validator.current.message(
                      "barcode",
                      updateData.barcode,
                      "required"
                    )}
                  </FormHelperText>
                </FormControl>
                <InputControl
                  type="outlinedInput"
                  inputType="text"
                  inputLabel="Member Number"
                  label="Member Number"
                  placeholder="Member Number"
                  name="member_number"
                  size="small"
                  onChange={handleCustomerInputChange}
                  value={updateData.member_number}
                  endAdornment={
                    <InputAdornment position="start">
                      <Tooltip title="If left empty,it will be auto generated">
                        <img alt="details" src="/icons/ic_info.svg" />
                      </Tooltip>
                    </InputAdornment>
                  }
                />

                <InputControl
                  type="outlinedInput"
                  inputType="text"
                  inputLabel="Verification Code"
                  label="Verification Code"
                  placeholder="Verification Code"
                  size="small"
                  key={`${state.isLoading}5`}
                  name="verification_code"
                  value={updateData.verification_code}
                  onChange={handleCustomerInputChange}
                  error={validator.current.message(
                    "Verification Code",
                    updateData.verification_code,
                    "required|alpha_num|size:6"
                  )}
                  helperText={validator.current.message(
                    "Verification Code",
                    updateData.verification_code,
                    "required|alpha_num|size:6"
                  )}
                />

                <Stack direction="row" justifyContent="space-between" py="10px">
                  <Typography variant="subtitle1" fontWeight={600}>
                    Status
                  </Typography>
                  <Switch
                    name="status"
                    defaultChecked={updateData.status === 1 ? true : false}
                    checked={updateData.status === 1 ? true : false}
                    onChange={handleCustomerInputChange}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </ContentLayout>
    </>
  );
};
