/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useImmer } from "use-immer";
import {
  getAdminUsersList,
  deleteUserById,
  getUserDetailsById,
  resetPasswordById,
  assignStoresToUser,
  assignRolesToUser,
  deleteStoresFromUser,
  deleteRolesFromUser,
  createUser,
  updateUser,
  sendEmailInvitation,
  getUserFavoriteList,
  createUserFavGroup,
  deleteUserFavById,
  getUserFavoriteDetails,
  assignProductsToUserFav,
  deleteUserFavProduct,
  updateUserFavGroup,
  changeUserFevProductOrder,
  changeUserFevOrder,
} from "..";
import { successMessage, errorMessage } from "../../../utils";
import { getStoreList } from "../../Stores";
import qs from "qs";
import { AppContext } from "../../../store/AppScopeProvider";
import { useTabs } from "../../../shared/hooks/useTabs";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { changePasswoCode } from "../../Profile";
import { AuthContext } from "../../../store/AuthProvider";
import { useDropdownApis } from "../../../shared";
import { TableStateContext } from "../../../store";
import { getPosRolesListApi, getRolesList } from "../../RolesAndPermissions";

export const useAdminUsers = ({
  isList,
  isDetailsId,
  isCreate,
  isFavDetails,
  storeAdmin,
}) => {
  const { appState } = useContext(AppContext);
  const { auth } = useContext(AuthContext);
  const { selectedStoreId } = auth.data;
  const { setCurentTab } = useTabs();
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const [searchParams] = useSearchParams();
  const isStoreId = searchParams.get("isStoreId");
  const PAGE_NAME = "superUser";
  const {
    filterState: {
      params: { superUser },
      params: {
        superUser: { columnOptions },
      },
    },
    initialState,
    filterStateDispatch,
  } = useContext(TableStateContext);
  const { storeList } = useDropdownApis({
    isStoreList: true,
  });
  const {
    globalData,
    currentTabs: { adminUsers: currentTab },
  } = appState;
  const navigate = useNavigate();

  const [state, setState] = useImmer({
    isLoading: true,
    usersList: [],
    basicDetails: {
      isOpen: false,
      isLoading: true,
      isSaveButton: false,
      details: null,
      timezones: [],
      updateData: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country_code: 61,
        timezone: "",
        status: 1,
        role_id: null,
        is_pos_role: isStoreAdmin ? null : 0,
      },
    },
    storesDetails: {
      isLoading: true,
      isSaveButton: false,
      isOpen: false,
      isDelete: false,
      id: null,
      storesList: [],
      allStores: [],
      filteredStore: [],
      selectedStores: [],
      searchText: null,
    },
    rolesDetails: {
      isLoading: true,
      isOpen: false,
      isDelete: false,
      id: null,
      rolesList: [],
      allRoles: [],
      roleTypes: [
        { label: "Store Admin", value: 0 },
        // { label: "POS Admin", value: 1 },
      ],
      filteredRoles: [],
      selectedRoles: [],
      searchText: null,
    },
    resetPassword: {
      isOpen: false,
      slide: 0,
      temporaryPassword: "",
      email: "",
      isDisabled: false,
    },
    resetPasscodeData: {
      isOpen: false,
      isButtonBusy: false,
      passcode: "",
    },
    disableApplyButton: false,
    deleteModalOpen: false,
    selectedUser: null,
    filterApplied: {
      stores: [],
      status: {},
      all: [],
      searchText: null,
    },
    favoritesList: {
      isLoading: true,
      isSaveButtonBusy: false,
      isOpen: false,
      isDelete: false,
      isDeleteFev: false,
      isProductModal: false,
      id: null,
      dataList: [],
      name: "",
      details: {
        name: "",
        display_order: "",
      },
      productsList: [],
      allProducts: [],
      filteredProducts: [],
      selectedProducts: [],
      searchText: null,
    },
    filterStores: [],
    httpStatusCodes: {
      details: "",
    },
    roles: [],
  });

  const [debouncedText] = useDebounce(state.filterApplied.searchText, 1000);

  useEffect(() => {
    if (isList && state.isLoading) {
      HandleUsersList();
      HandleStoreList();
    }
    if (isDetailsId) {
      HandleUserDetailsList(isDetailsId);
    }
  }, [isList, isDetailsId, state.isLoading]);

  // useEffect to fetch role list
  useEffect(() => {
    if (isList) {
      const { stores, searchText, status } = superUser.filterApplied;
      setState((draft) => {
        draft.filterApplied = superUser.filterApplied;
      });
      HandleUsersList({
        stores: stores,
        searchText: searchText,
        status: status,
      });
      HandleStoreList();
    }
  }, [isList]);

  // useEffect to fetch role list
  useEffect(() => {
    if (isList) {
      const { stores, searchText, status } = superUser.filterApplied;
      setState((draft) => {
        draft.filterApplied = superUser.filterApplied;
      });
      HandleUsersList({
        stores: stores,
        searchText: searchText,
        status: status,
      });
      HandleStoreList();
    }
  }, [isList]);

  // useEffect to fetch role list
  useEffect(() => {
    // Fetch the default role list for the super admin
    const shouldFetchRoles =
      (isCreate && !isStoreAdmin) || (isDetailsId && currentTab === "3");

    if (shouldFetchRoles) {
      HandleRolesList();
    }
  }, [isCreate, isDetailsId, currentTab]);

  const debounced = useDebouncedCallback((value) => {
    const { stores, status } = state.filterApplied;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filterApplied },
    });
    HandleUsersList({
      stores: stores,
      searchText: value,
      status: status,
    });
  }, 1000);

  useEffect(() => {
    if (currentTab === "5" && state.favoritesList.isLoading && isDetailsId) {
      HandleUserFavoriteList(isDetailsId);
    }
  }, [currentTab, isDetailsId]);

  useEffect(() => {
    if (state.favoritesList.isLoading && isFavDetails) {
      HandleUserFavoriteDetails(isFavDetails);
    }
  }, [isFavDetails]);

  // useEffect(() => {
  //   HandleFilterProducts("initial");
  // }, [listData, state.favoritesList.details]);

  //Admin User List
  const HandleUsersList = async ({
    stores = [],
    searchText = "",
    status = {},
  }) => {
    try {
      let IDs = stores.map((v) => {
        return v.value;
      });

      let params = {};

      if (searchText) {
        params = { ...params, search: state.filterApplied.searchText };
      }
      if (toString(status.value)) {
        params = { ...params, status: state.filterApplied.status.value };
      }
      if (IDs.length > 0) {
        params = { ...params, store_id: IDs };
      }
      setState((draft) => {
        draft.isLoading = true;
        draft.disableApplyButton = true;
      });
      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };
      const res = await getAdminUsersList(query);
      if (res.success) {
        setState((draft) => {
          draft.usersList = res.data;
          draft.isLoading = false;
        });
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
        draft.disableApplyButton = false;
      });
    }
  };

  //Admin User Details
  const HandleUserDetailsList = async (id) => {
    try {
      const res = await getUserDetailsById(id);
      if (res.success) {
        setState((draft) => {
          draft.basicDetails.details = res.data;
          draft.storesDetails.storesList = res.data.stores;
          draft.rolesDetails.rolesList = res.data.roles;
          draft.basicDetails.updateData = res.data;
          draft.resetPassword.email = res.data.email;
          draft.basicDetails.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.basicDetails.isLoading = false;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };

  const handleModal = (type, state, data) => {
    switch (type) {
      case "all-list-delete":
        setState((draft) => {
          draft.deleteModalOpen = state;
          draft.selectedUser = data;
        });
        break;
      case "reset-pass":
        setState((draft) => {
          draft.resetPassword.email = draft.basicDetails.details.email;
          draft.resetPassword.isOpen = state;
          draft.resetPassword.slide =
            state === false ? draft.resetPassword.slide : data;
        });
        break;
      case "store-add":
        if (!state) {
          setState((draft) => {
            draft.storesDetails.selectedStores = [];
            draft.storesDetails.filteredStore = [];
            draft.storesDetails.isOpen = state;
            draft.storesDetails.searchText = "";
          });
        } else {
          HandleStoreList();
          setState((draft) => {
            draft.storesDetails.isOpen = state;
          });
        }
        break;
      case "roles-add":
        if (!state) {
          setState((draft) => {
            draft.rolesDetails.selectedRoles = [];
            draft.rolesDetails.filteredRoles = [];
            draft.rolesDetails.isOpen = state;
          });
        } else {
          HandleRolesList();
          setState((draft) => {
            draft.rolesDetails.isOpen = state;
          });
        }
        break;
      case "store-delete":
        setState((draft) => {
          draft.storesDetails.isDelete = state;
          draft.storesDetails.id = data;
        });
        break;
      case "role-delete":
        setState((draft) => {
          draft.rolesDetails.isDelete = state;
          draft.rolesDetails.id = data;
        });
        break;
      case "detail-delete":
        setState((draft) => {
          draft.basicDetails.isOpen = state;
        });
        break;
      case "fav-add":
        setState((draft) => {
          draft.favoritesList.isOpen = state;
          draft.favoritesList.name = "";
        });
        break;
      case "fav-edit":
        setState((draft) => {
          draft.favoritesList.isOpen = state;
        });
        break;
      case "fav-list-delete":
        setState((draft) => {
          draft.favoritesList.isDelete = state;
          draft.favoritesList.id = data;
        });
        break;
      case "fav-pro-list":
        setState((draft) => {
          draft.favoritesList.isProductModal = state;
          draft.favoritesList.searchText = "";
          draft.favoritesList.selectedProducts = [];
        });
        break;
      case "fav-pro-list-delete":
        setState((draft) => {
          draft.favoritesList.isDelete = state;
          draft.favoritesList.id = data;
        });
        break;
      case "fav-delete":
        setState((draft) => {
          draft.favoritesList.isDeleteFev = state;
        });
        break;
    }
  };

  const handleDeleteUser = async () => {
    try {
      const res = await deleteUserById(state.selectedUser);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.usersList = draft.usersList.filter(
            (item) => item.id !== state.selectedUser
          );
        });
        handleModal("all-list-delete", false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleDeleteUserDetails = async () => {
    try {
      const res = await deleteUserById(isDetailsId);
      if (res.success) {
        successMessage("Deleted Successfully");
        handleModal("detail-delete", false);
        navigate(isStoreAdmin ? "/store-admin/admin-users" : "/admin-users");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleResetPassword = async (type, value) => {
    if (type === "email") {
      setState((draft) => {
        draft.resetPassword.email = value;
      });
    } else if (type === "disable") {
      setState((draft) => {
        draft.resetPassword.isDisabled = value;
      });
    } else {
      let data = {
        email: state.resetPassword.email,
      };

      try {
        const res = await resetPasswordById(
          state.resetPassword.isDisabled ? data : {},
          isDetailsId
        );
        if (res.success) {
          setState((draft) => {
            draft.resetPassword.temporaryPassword = res.data.temporary_password;
            draft.resetPassword.slide = 1;
            draft.resetPassword.isDisabled = false;
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
      }
    }
  };

  const handlePasswordCopy = () => {
    navigator.clipboard.writeText(state.resetPassword.temporaryPassword);
    successMessage("The temporary password is copied to the clipboard");
  };

  const HandleStoreList = async () => {
    if (state.storesDetails.allStores.length === 0) {
      try {
        const res = await getStoreList();
        if (res.data) {
          setState((draft) => {
            draft.storesDetails.allStores = res.data;
            draft.filterStores = res.data.map((v) => {
              return { value: v.id, label: v.name };
            });
            draft.storesDetails.isLoading = false;
            return draft;
          });
        }
      } catch (error) {
        errorMessage(error.response.data.message);
      }
    } else {
      HandleFilterStores("initial");
    }
  };

  const HandleFilterStores = (type, text) => {
    const alreadyStoreList = state.storesDetails.storesList.map((val) => {
      return val.id;
    });
    const filteredStoreList = state.storesDetails.allStores.filter(
      (val) => !alreadyStoreList.includes(val.id)
    );

    if (type === "initial") {
      setState((draft) => {
        draft.storesDetails.filteredStore = filteredStoreList;
        return draft;
      });
    } else {
      text = text.toLowerCase();
      setState((draft) => {
        draft.storesDetails.searchText = text;
        draft.storesDetails.filteredStore = filteredStoreList.filter((val) => {
          return val.name.toString().toLowerCase().indexOf(text) > -1;
        });
        return draft;
      });
    }
  };

  useEffect(() => {
    HandleFilterStores("initial");
  }, [state.storesDetails.allStores, state.storesDetails.storesList]);

  const handleStorsSelect = (id) => {
    const isSelcted = state.storesDetails.selectedStores.includes(id);
    if (isSelcted) {
      setState((draft) => {
        draft.storesDetails.selectedStores =
          draft.storesDetails.selectedStores.filter((val) => val !== id);
      });
    } else {
      setState((draft) => {
        draft.storesDetails.selectedStores = [
          ...draft.storesDetails.selectedStores,
          id,
        ];
        return draft;
      });
    }
  };

  const handleAssignStorestoUser = async () => {
    const data = {
      store_id: state.storesDetails.selectedStores,
    };

    try {
      setState((draft) => {
        draft.storesDetails.isLoading = true;
      });
      const res = await assignStoresToUser(data, isDetailsId);
      if (res.success === true) {
        successMessage(res.message);
        setState((draft) => {
          draft.storesDetails.storesList = [
            ...draft.storesDetails.storesList,
            ...draft.storesDetails.allStores.filter((val) =>
              draft.storesDetails.selectedStores.includes(val.id)
            ),
          ];
          draft.storesDetails.selectedStores = [];
          draft.storesDetails.isLoading = false;
          return draft;
        });
        handleModal("store-add", false);
        HandleFilterStores("initial");
      } else {
        setState((draft) => {
          draft.storesDetails.isLoading = false;
        });
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.storesDetails.isLoading = false;
      });
    }
  };

  const handleDeleteStore = async () => {
    try {
      const res = await deleteStoresFromUser(
        isDetailsId,
        state.storesDetails.id
      );
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.storesDetails.storesList =
            draft.storesDetails.storesList.filter(
              (item) => item.id !== state.storesDetails.id
            );
        });
        handleModal("store-delete", false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  useEffect(() => {
    HandleFilterRoles("initial");
  }, [state.rolesDetails.allRoles, state.rolesDetails.rolesList]);

  const HandleFilterRoles = (type, text) => {
    const alreadyRolesList = state.rolesDetails.rolesList.map((val) => {
      return val.id;
    });
    const filteredRolesList = state.rolesDetails.allRoles.filter(
      (val) => !alreadyRolesList.includes(val.id)
    );
    if (type === "initial") {
      setState((draft) => {
        draft.rolesDetails.searchText = "";
        draft.rolesDetails.filteredRoles = filteredRolesList;
        return draft;
      });
    } else {
      text = text.toLowerCase();
      setState((draft) => {
        draft.rolesDetails.searchText = text;
        draft.rolesDetails.filteredRoles = filteredRolesList.filter((val) => {
          return val.name.toString().toLowerCase().indexOf(text) > -1;
        });
        return draft;
      });
    }
  };

  const HandleRolesList = async (initialFetch) => {
    const type = isStoreAdmin || storeAdmin ? "store_admin" : "super_admin";

    // Fetch from the API if either allRoles is empty or initialFetch is zero.
    if (state.rolesDetails.allRoles.length === 0 || initialFetch === true) {
      try {
        const res = await getRolesList(type);
        if (res.data) {
          setState((draft) => {
            draft.rolesDetails.allRoles = res.data;
            draft.rolesDetails.isLoading = false;
            return draft;
          });
        }
      } catch (error) {
        errorMessage(error.response.data.message);
      }
    } else {
      HandleFilterRoles("initial");
    }
  };

  /**
   * Fetches the list of POS roles from the API.
   *
   * This asynchronous function calls the `getPosRolesListApi` to retrieve the roles.
   * On success, it updates the state with the retrieved roles and sets loading to false.
   * If the API call fails, it throws an error with the provided message or a default error message.
   *
   * @async
   * @function getPosRolesList
   * @returns {Promise<void>} Resolves when the roles are fetched and state is updated.
   * @throws {Error} Throws an error if the API call fails, with a message indicating the failure reason.
   */
  const getPosRolesList = async () => {
    try {
      const response = await getPosRolesListApi();

      const { success, message, data } = response;
      if (success) {
        setState((draft) => {
          draft.rolesDetails.allRoles = data;
          draft.rolesDetails.isLoading = false;
        });
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message =
        error.response?.data?.message ??
        "Failed to fetch POS roles. Please try again later.";
      errorMessage(message);
    }
  };

  const handleRolesSelect = (id) => {
    const isSelcted = state.rolesDetails.selectedRoles.includes(id);
    if (isSelcted) {
      setState((draft) => {
        draft.rolesDetails.selectedRoles =
          draft.rolesDetails.selectedRoles.filter((val) => val !== id);
      });
    } else {
      setState((draft) => {
        draft.rolesDetails.selectedRoles = [
          ...draft.rolesDetails.selectedRoles,
          id,
        ];
        return draft;
      });
    }
  };

  const handleAssignRolestoUser = async () => {
    const data = {
      role_id: state.rolesDetails.selectedRoles,
    };
    setState((draft) => {
      draft.rolesDetails.isSaveButton = true;
    });

    try {
      const res = await assignRolesToUser(data, isDetailsId);
      if (res.success === true) {
        successMessage(res.message);
        setState((draft) => {
          draft.rolesDetails.rolesList = [
            ...draft.rolesDetails.rolesList,
            ...draft.rolesDetails.allRoles.filter((val) =>
              draft.rolesDetails.selectedRoles.includes(val.id)
            ),
          ];
          draft.rolesDetails.selectedRoles = [];
          draft.rolesDetails.isSaveButton = false;
          return draft;
        });
        handleModal("roles-add", false);
        HandleFilterRoles("initial");
      }
    } catch (error) {
      setState((draft) => {
        draft.rolesDetails.isSaveButton = false;
      });
      errorMessage(error.response.data.message);
    }
  };

  const handleDeleteRole = async () => {
    try {
      const res = await deleteRolesFromUser(isDetailsId, state.rolesDetails.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.rolesDetails.rolesList = draft.rolesDetails.rolesList.filter(
            (item) => item.id !== state.rolesDetails.id
          );
        });
        handleModal("role-delete", false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  //admin user Create
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "status") {
      setState((draft) => {
        draft.basicDetails.updateData = {
          ...draft.basicDetails.updateData,
          status: event.target.checked ? 1 : 0,
        };
      });
    } else {
      const isRoleTypeChanged = name === "is_pos_role";

      setState((draft) => {
        if (isRoleTypeChanged) {
          draft.rolesDetails.allRoles = [];
        }

        draft.basicDetails.updateData = {
          ...draft.basicDetails.updateData,
          role_id: isRoleTypeChanged
            ? null
            : draft.basicDetails.updateData.role_id,
          [name]: value,
        };
      });

      // Fetch roles based on the type change:
      // If the role type has changed,
      // - Fetch store roles if the value is 0
      // - Fetch POS roles if the value is 1
      if (isRoleTypeChanged) {
        (value === 0 && HandleRolesList(true)) ||
          (value === 1 && getPosRolesList());
      }
    }
  };

  const onSaveUser = async () => {
    try {
      setState((draft) => {
        draft.basicDetails.isSaveButton = true;
      });
      const res = await createUser(state.basicDetails.updateData);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.basicDetails.isSaveButton = false;
        });
        navigate(-1);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.basicDetails.isSaveButton = false;
      });
    }
  };
  const onUpdateUser = async (id) => {
    try {
      setState((draft) => {
        draft.basicDetails.isSaveButton = true;
      });
      const res = await updateUser(state.basicDetails.updateData, id);
      if (res.success) {
        successMessage("Updated Successfully");
        setState((draft) => {
          draft.basicDetails.isSaveButton = false;
        });
        navigate(-1);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.basicDetails.isSaveButton = false;
      });
    }
  };

  const handleFilter = (e, value, type) => {
    if (type === "store") {
      setState((draft) => {
        draft.filterApplied.stores = value.map((v) => {
          return { ...v, type: "Store" };
        });
      });
    }
    if (type === "status") {
      setState((draft) => {
        draft.filterApplied.status = {
          value: value.props.value,
          label: value.props.children,
          type: "Status",
        };
      });
    }
    setState((draft) => {
      draft.disableApplyButton = false;
    });
  };
  const handleFilterClear = (type, value) => {
    if (type === "single") {
      if (value.type === "Store") {
        setState((draft) => {
          draft.filterApplied.stores = draft.filterApplied.stores.filter(
            (e) => e.value !== value.value
          );
        });
      }
      if (value.type === "Status") {
        setState((draft) => {
          draft.filterApplied.status = {};
        });
      }
      setState((draft) => {
        draft.disableApplyButton = false;
      });
    } else {
      const { searchText } = state.filterApplied;
      setState((draft) => {
        draft.filterApplied.all = [];
        draft.filterApplied.stores = [];
        draft.filterApplied.status = {};
        draft.isLoading = true;
      });
      const initail = {
        stores: [],
        status: {},
        all: [],
        searchText: state.filterApplied.searchText,
      };
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initail,
      });
      HandleUsersList({
        searchText: searchText,
      });
    }
  };

  const handleApplyFilter = () => {
    const { stores, searchText, status } = state.filterApplied;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: state.filterApplied,
      },
    });
    HandleUsersList({
      stores: stores,
      searchText: searchText,
      status: status,
    });
  };

  const handleSearch = (v) => {
    setState((draft) => {
      draft.filterApplied.searchText = v;
    });
    debounced(v);
  };

  useEffect(() => {
    setState((draft) => {
      draft.filterApplied.all = [
        ...state.filterApplied.stores,
        state.filterApplied.status,
      ].filter((el) => Object.keys(el).length);
    });
  }, [state.filterApplied.status, state.filterApplied.stores]);

  const onSeachClear = () => {
    setState((draft) => {
      draft.filterApplied.searchText = "";
    });
    debounced("");
  };

  const sendInvitation = async () => {
    const { email, role_id, is_pos_role } = state.basicDetails.updateData;
    const payload = {
      email,
      role_id,
      is_pos_role,
    };
    try {
      setState((draft) => {
        draft.basicDetails.isSaveButton = true;
      });
      const query = {
        headers: { "X-STORE": storeAdmin },
      };
      const res = await sendEmailInvitation(payload, storeAdmin ? query : {});
      if (res.success) {
        successMessage("Invitation send successfully");
        setState((draft) => {
          draft.basicDetails.isSaveButton = false;
          return draft;
        });
        navigate(-1);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.basicDetails.isSaveButton = false;
      });
    }
  };

  const resetPassCode = async (type) => {
    if (type === "open") {
      setState((draft) => {
        draft.resetPasscodeData.isButtonBusy = true;
      });
      try {
        const payload = {
          store_id: selectedStoreId,
        };
        const res = await changePasswoCode(payload, isDetailsId);
        if (res.success) {
          setState((draft) => {
            draft.resetPasscodeData.isOpen = true;
            draft.resetPasscodeData.isButtonBusy = false;
            draft.resetPasscodeData.passcode = res.data.pass_code;
          });
        }
      } catch (err) {
        setState((draft) => {
          draft.resetPasscodeData.isButtonBusy = false;
        });
        errorMessage(err.response.data.message);
      }
    } else if (type === "close") {
      setState((draft) => {
        draft.resetPasscodeData.isOpen = false;
      });
    }
  };

  //user ADmin Favorite

  const HandleUserFavoriteList = async (id) => {
    try {
      const res = await getUserFavoriteList(id);
      if (res.success) {
        setState((draft) => {
          draft.favoritesList.dataList = res.data;
          draft.favoritesList.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.favoritesList.isLoading = false;
      });
    }
  };

  const onSaveUserFavGroup = async () => {
    try {
      setState((draft) => {
        draft.favoritesList.isSaveButtonBusy = true;
      });
      const payload = {
        name: state.favoritesList.name,
      };
      const res = await createUserFavGroup(isDetailsId, payload);
      if (res.success) {
        successMessage("Created Successfully");

        setState((draft) => {
          draft.favoritesList.isSaveButtonBusy = false;
          draft.favoritesList.isLoading = true;
        });
        handleModal("fav-add", false);
        HandleUserFavoriteList(isDetailsId);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.favoritesList.isSaveButtonBusy = false;
      });
    }
  };

  const onUpdateUserFavGroup = async () => {
    try {
      setState((draft) => {
        draft.favoritesList.isSaveButtonBusy = true;
      });
      const payload = {
        name: state.favoritesList.name,
      };
      const res = await updateUserFavGroup(
        isFavDetails.id,
        isFavDetails.favid,
        payload
      );
      if (res.success) {
        successMessage("Updated Successfully");
        setState((draft) => {
          draft.favoritesList.isSaveButtonBusy = false;
          draft.favoritesList.isLoading = true;
        });
        handleModal("fav-edit", false);
        HandleUserFavoriteDetails(isFavDetails);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.favoritesList.isSaveButtonBusy = false;
      });
    }
  };

  const onChangeUserFavGroup = (e) => {
    const { value } = e.target;
    setState((draft) => {
      draft.favoritesList.name = value;
    });
  };

  const onDeleteUserFavGroup = async () => {
    try {
      const res = await deleteUserFavById(isDetailsId, state.favoritesList.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.favoritesList.isLoading = true;
        });
        handleModal("fav-list-delete", false, null);
        HandleUserFavoriteList(isDetailsId);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const HandleUserFavoriteDetails = async (params) => {
    try {
      const res = await getUserFavoriteDetails(params.id, params.favid);
      if (res.success) {
        setState((draft) => {
          draft.favoritesList.details = res.data;
          draft.favoritesList.alreadyList = res.data.products?.map(
            (item) => item.product_id
          );
          draft.favoritesList.name = res.data.name;
          draft.favoritesList.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.favoritesList.isLoading = false;
      });
    }
  };

  const handleProductSelect = (id) => {
    const isSelcted = state.favoritesList.selectedProducts.includes(id);
    if (isSelcted) {
      setState((draft) => {
        draft.favoritesList.selectedProducts =
          draft.favoritesList.selectedProducts.filter((val) => val !== id);
        return draft;
      });
    } else {
      setState((draft) => {
        draft.favoritesList.selectedProducts = [
          ...draft.favoritesList.selectedProducts,
          id,
        ];
        return draft;
      });
    }
  };

  const handleAssignProducts = async () => {
    const payload = {
      products: state.favoritesList.selectedProducts.map((item) => ({
        id: item,
      })),
    };
    try {
      const res = await assignProductsToUserFav(
        isFavDetails.id,
        isFavDetails.favid,
        payload
      );
      if (res.success === true) {
        successMessage(res.message);
        HandleUserFavoriteDetails(isFavDetails);
        setState((draft) => {
          draft.favoritesList.isProductModal = false;
          draft.favoritesList.searchText = "";
          draft.favoritesList.selectedProducts = [];
        });
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };

  const onDeleteUserFavGroupProducts = async (type) => {
    if (type === "list") {
      try {
        const res = await deleteUserFavProduct(
          isFavDetails.id,
          isFavDetails.favid,
          state.favoritesList.id
        );
        if (res.success) {
          successMessage("Deleted Successfully");
          setState((draft) => {
            draft.favoritesList.isLoading = true;
          });
          handleModal("fav-pro-list-delete", false, null);
          HandleUserFavoriteDetails(isFavDetails);
        }
      } catch (err) {
        errorMessage(err.response.data.message);
      }
    } else {
      try {
        const res = await deleteUserFavById(
          isFavDetails.id,
          isFavDetails.favid
        );
        if (res.success) {
          successMessage("Deleted Successfully");
          handleModal("fav-delete", false);
          navigate(`/store-admin/admin-users/details/${isFavDetails.id}`);
        }
      } catch (err) {
        errorMessage(err.response.data.message);
      }
    }
  };

  const onProductsOrderChange = async (data) => {
    const payload = {
      products: data.map((x, i) => ({
        id: x.id,
        display_order: i,
      })),
    };
    try {
      const res = await changeUserFevProductOrder(
        isFavDetails.id,
        isFavDetails.favid,
        payload
      );
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onUserFavOrderChange = async (data) => {
    const payload = {
      favourites: data.map((x, i) => ({
        id: x.id,
        display_order: i,
      })),
    };
    try {
      const res = await changeUserFevOrder(isDetailsId, payload);
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleSelectAllStores = (checked) => {
    if (checked) {
      setState((draft) => {
        draft.storesDetails.selectedStores = [
          ...draft.storesDetails.filteredStore.map((item) => item.id),
        ];
      });
    } else {
      setState((draft) => {
        draft.storesDetails.selectedStores = [];
      });
    }
  };

  const handleSelectAllRoles = (checked) => {
    if (checked) {
      setState((draft) => {
        draft.rolesDetails.selectedRoles = [
          ...draft.rolesDetails.filteredRoles.map((item) => item.id),
        ];
      });
    } else {
      setState((draft) => {
        draft.rolesDetails.selectedRoles = [];
      });
    }
  };

  const isSelected = (id) => {
    return state.favoritesList.selectedProducts.includes(id);
  };

  return {
    state,
    currentTab,
    globalData,
    setCurentTab,
    handleModal,
    handleDeleteUser,
    handleResetPassword,
    handlePasswordCopy,
    HandleFilterStores,
    handleStorsSelect,
    handleAssignStorestoUser,
    HandleFilterRoles,
    handleRolesSelect,
    handleAssignRolestoUser,
    handleDeleteStore,
    handleDeleteRole,
    handleInputChange,
    onSaveUser,
    onUpdateUser,
    handleDeleteUserDetails,
    handleFilter,
    handleFilterClear,
    handleApplyFilter,
    handleSearch,
    onSeachClear,
    sendInvitation,
    resetPassCode,
    isStoreAdmin,
    onSaveUserFavGroup,
    onChangeUserFavGroup,
    onDeleteUserFavGroup,
    handleProductSelect,
    handleAssignProducts,
    onDeleteUserFavGroupProducts,
    onUpdateUserFavGroup,
    onProductsOrderChange,
    onUserFavOrderChange,
    handleSelectAllStores,
    handleSelectAllRoles,
    isSelected,
    storeList,
  };
};
