import { API } from "../../../utils";

export const getAvailabilityGroup = async () => {
  const res = await API.get(`availability-groups`);
  return res.data;
};

export const createAvailabilityGroup = async (payload) => {
  const res = await API.post(`availability-groups`, payload);
  return res.data;
};
export const getAvailabilityGroupDetailsById = async (id) => {
  const res = await API.get(`availability-groups/${id}`);
  return res.data;
};

export const editAvailabilityGroup = async (id, payload) => {
  const res = await API.put(`availability-groups/${id}`, payload);
  return res.data;
};

export const deleteAvailabilityGroupById = async (id) => {
  const res = await API.delete(`availability-groups/${id}`);
  return res.data;
};
export const getStoreFromAvailabilityGroup = async (id) => {
  const res = await API.get(`availability-groups/${id}/stores`);
  return res.data;
};
export const assignStoreToAvailabilityGroup = async (payload, id) => {
  const res = await API.post(`availability-groups/${id}/stores`, payload);
  return res.data;
};
export const getProductFromAvailabilityGroup = async (id) => {
  const res = await API.get(`availability-groups/${id}/products`);
  return res.data;
};
export const assignPoductToAvailabilityGroup = async (payload, id) => {
  const res = await API.post(`availability-groups/${id}/products`, payload);
  return res.data;
};
export const deleteProductFromAvailabilityGroup = async (
  AvailabilityGroupId,
  productId
) => {
  const res = await API.delete(
    `availability-groups/${AvailabilityGroupId}/products/${productId}`
  );
  return res.data;
};
export const deleteStoreFromAvailabilityGroup = async (
  AvailabilityGroupId,
  storeId
) => {
  const res = await API.delete(
    `availability-groups/${AvailabilityGroupId}/stores/${storeId}`
  );
  return res.data;
};
export const getCategorywiseProducts = async () => {
  const res = await API.get(`product/list`);
  return res.data;
};
