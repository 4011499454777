import { useRef, useState } from "react";
import {
  Box,
  FormControl,
  Typography,
  Card,
  Alert,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SimpleReactValidator from "simple-react-validator";
import InputControl from "../../../shared/components/InputControl";
import { Link as RouterLink } from "react-router-dom";
import { useAuthenticate } from "../hooks";
import SentIcon from "../../../assets/scss/sentIcon.js";

export function ForgotPassword() {
  const { state, setState, handleForgot, onChanges } = useAuthenticate();
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "75vh",
        }}
      >
        <Card align="center" sx={{ width: "424px", height: "330px" }}>
          {!state.isSuccess ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ mt: 3 }} variant="h5" gutterBottom>
                  Forgot Password
                </Typography>

                <Typography sx={{ mt: 1 }} variant="h7" gutterBottom>
                  Enter your email to reset your password
                </Typography>
              </Box>

              <Box
                component="input-box"
                sx={{ display: "flex", height: "48px" }}
              >
                <Box sx={{ width: "376px", mb: 2 }} variant="outlined">
                  {state.errors && (
                    <Alert sx={2} severity="error">
                      {state.errors}
                    </Alert>
                  )}
                </Box>
                <FormControl sx={{ m: 1, width: "376px" }} variant="outlined">
                  <InputControl
                    label="Email Address"
                    name="email"
                    type="email"
                    placeholder="Enter email"
                    onChange={onChanges}
                    value={state.values.email}
                    error={validator.current.message(
                      "email",
                      state.values.email,
                      "required|email"
                    )}
                    helperText={validator.current.message(
                      "email",
                      state.values.email,
                      "required|email"
                    )}
                  />
                </FormControl>

                <Box sx={{ flexGrow: 1 }}>
                  <FormControl sx={{ m: 2, width: "376px" }} variant="outlined">
                    <LoadingButton
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={state.isBusy}
                      onClick={() => {
                        if (validator.current.allValid()) {
                          handleForgot();
                        } else {
                          validator.current.showMessages();
                          forceUpdate(1);
                        }
                      }}
                    >
                      Send
                    </LoadingButton>
                  </FormControl>
                </Box>
              </Box>
            </>
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <SentIcon sx={{ mt: 1, mx: "auto", height: 80 }} />

              <Typography variant="h3" gutterBottom>
                Sent successfully
              </Typography>
              <Typography>
                We have sent a password reset email to &nbsp;
                <strong>{state.values.email}</strong>
                <br />
                Please check your email.
              </Typography>

              <Button
                size="large"
                variant="contained"
                component={RouterLink}
                to={"/authentication/login"}
                sx={{ mt: 5 }}
              >
                Back
              </Button>
            </Box>
          )}
        </Card>
      </Box>
    </>
  );
}
