import * as React from "react";
import { CustomTable } from "../../../shared";
import { sortItems, sortNestedItems } from "../../../utils";

export const FavouriteProductsList = (props) => {
  const { state } = props;
  const columns = React.useMemo(
    () => [
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ value }) => {
          return value.name;
        },
        sortType: (prev, curr, columnId) => {
          return sortNestedItems(prev, curr, columnId, "name");
        },
      },
      {
        Header: "Product Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
    ],
    []
  );
  return (
    <CustomTable
      rowsSelection={false}
      columns={columns}
      tableId="customer_fav_products"
      data={state?.customersFavoriteProducts}
    />
  );
};
