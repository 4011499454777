import { ContentLayout, PageNotFound } from "../../../shared";
import * as React from "react";
import { Button, Chip, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CustomTable } from "../../../shared";
import { useNavigate, Link } from "react-router-dom";
import { useFranchisee } from "../hooks/useFranchisee";
import { AuthContext } from "../../../store/AuthProvider";
import { DeleteModal } from "../../../shared";
import { sortItems } from "../../../utils";

export const FranchiseeList = () => {
  const navigate = useNavigate();
  const { auth } = React.useContext(AuthContext);
  const {
    state,
    handleModal,
    handleDeleteFranchisee,
    isCreateVisible,
    isEditable,
    isDeletable,
  } = useFranchisee({
    isFranchisee: true,
  });
  const { franchiseeDetails } = state;
  const onAdd = () => {
    navigate("/franchisee/create");
  };

  const ActionButtons = ({ id, editAction, deleteAction }) => {
    return (
      <div className="action-buttons"
        onClick={(e) => e.preventDefault()}
      >
        {/* {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )} */}
        {isEditable && (
          <Link to={`/franchisee/details/${id}`}>
            <img
              alt="details"
              onClick={editAction}
              src="/icons/arrow_forward.svg"
            />
          </Link>
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Franchisee Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableGlobalFilter: true,
        sortInverted: true,
        Cell: ({ value }) => {
          return (
            <Chip
              label={value === 1 ? "Active" : "Inactive"}
              color="success"
              variant="outlined"
              size="small"
              disabled={value === 1 ? false : "filled"}
            />
          );
        },
      },
      {
        Header: "Stores",
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ cell }) => {
          const stores = cell.row.original.stores;
          return (
            <Box>
              {stores?.slice(0, 5).map((data) => {
                return (
                  <Chip
                    sx={{ mr: 1, borderRadius: "8px" }}
                    label={data.name}
                    variant="filled"
                    size="small"
                  />
                );
              })}
              {stores?.length > 5 && (
                <Chip
                  sx={{ borderRadius: "8px" }}
                  label={`+${stores?.length - stores?.slice(0, 5)?.length}`}
                  variant="filled"
                  size="small"
                />
              )}
            </Box>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        accessor: "id",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return (
            <ActionButtons
              id={value}
              deleteAction={() => handleModal("franchisee", value)}
            />
          );
        },
      },
    ],
    []
  );
  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }
  return (
    <>
      <ContentLayout
        title="Franchisee"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={onAdd}
              >
                New Franchisee
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={franchiseeDetails.franchisee}
          isLoading={franchiseeDetails.isBusy}
          tableId="Franchisee"
          onRowLink={(e) => {
            return `/franchisee/details/${e.id}`
          }}
        />
        <DeleteModal
          open={franchiseeDetails.isOpen}
          handleClose={() => handleModal("franchisee")}
          onConfirm={() => handleDeleteFranchisee()}
        />
      </ContentLayout>
    </>
  );
};
