import React from "react";
import { Route, Routes } from "react-router-dom";
import { NavBar } from "../../shared";
import { AcceptInvitation, CreateProfile, SuccessPage } from "./containers";

export const Invitation = () => {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<AcceptInvitation />} />
        <Route path="/create-profile" element={<CreateProfile />} />
        <Route path="/success" element={<SuccessPage />} />
      </Routes>
    </>
  );
};
