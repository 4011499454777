import { ContentLayout, usePermission } from "../../../shared";
import * as React from "react";
import { Switch } from "@mui/material";
import { CustomTable } from "../../../shared";
import { useManagerPassword } from "../hooks";

export const ManagerPasswordList = () => {
  const { state, HandlePasswordsAction } = useManagerPassword();
  const { isLoading, dataList } = state;
  const isEdit = usePermission("manager-password-modify");
  const ActionButtons = ({ value, id }) => {
    return (
      <div className="action-buttons">
        {isEdit && (
          <Switch
            checked={value.original.status ? true : false}
            onChange={() => {
              HandlePasswordsAction(id);
            }}
          />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Action",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "",
        id: "id",
        accessor: "id",
        align: "right",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return <ActionButtons value={row} id={value} />;
        },
      },
    ],
    [dataList]
  );

  return (
    <>
      <ContentLayout title="Manager Password">
        <CustomTable
          columns={columns}
          data={dataList}
          isLoading={isLoading}
          defaultSearch={false}
          searchBar={false}
          pagination={false}
        />
      </ContentLayout>
    </>
  );
};
