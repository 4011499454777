import { useState, useRef } from "react";
import {
  ContentLayout,
  HeaderBreadcrumbs,
  Loader,
  PageNotFound,
} from "../../../shared";
import * as React from "react";
import {
  Grid,
  Typography,
  Button,
  Stack,
  Card,
  Divider,
  Switch,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  TextField,
  FormHelperText,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
} from "@mui/material";
import SimpleReactValidator from "simple-react-validator";
import ReactQuill from "react-quill";
import InputControl from "../../../shared/components/InputControl";
import { DropzoneComponents } from "../components";
import AddIcon from "@mui/icons-material/Add";
import { useBirthdayCoupons } from "..";
import styled from "@emotion/styled";

const ToggleButtonStyled = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#1560D4",
  },
});

export const EditBirthdayCoupons = () => {
  const {
    state,
    globalData,
    handleInputChange,
    onUpdateBirthdayCoupon,
    handleAddCondition,
  } = useBirthdayCoupons({ isDetailsId: true });
  const { isLoading, details, ConditionList, isSaveButton } =
    state.BirthdayCouponsDetails;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const onSubmit = () => {
    if (validator.current.allValid()) {
      onUpdateBirthdayCoupon();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }

  const conditionType = [
    { label: "AND", value: 1 },
    { label: "OR", value: 2 },
  ];
  return (
    <>
      <ContentLayout
        title={"Update Coupon"}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Birthday Coupons", href: "/birthday-coupons" },
              { name: "Edit" },
            ]}
          />
        }
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              onClick={onSubmit}
              disabled={isSaveButton}
            >
              Save
            </Button>
          </>
        }
      >
        {isLoading ? (
          <Loader />
        ) : (
          <Card sx={{ p: "32px 24px", mb: "24px" }}>
            {validator.current.purgeFields()}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  sx={{ width: "150px" }}
                >
                  Details
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <Stack width={"100%"} gap="20px">
                  <InputControl
                    name="name"
                    label="Name"
                    required
                    value={details.name}
                    onChange={(e) => handleInputChange(e)}
                    error={validator.current.message(
                      "Name",
                      details.name,
                      "required"
                    )}
                    helperText={validator.current.message(
                      "Name",
                      details.name,
                      "required"
                    )}
                  />
                  <InputControl
                    name="label"
                    label="Label"
                    autoComplete="off"
                    required
                    value={details.label}
                    onChange={(e) => handleInputChange(e)}
                    error={validator.current.message(
                      "Label",
                      details.label,
                      "required"
                    )}
                    helperText={validator.current.message(
                      "Label",
                      details.label,
                      "required"
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 3 }} />
            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" fontWeight={600}>
                  Description
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <Stack width={"100%"} gap="5px">
                  <ReactQuill
                    theme="snow"
                    placeholder="Description *"
                    required
                    value={details.description}
                    onChange={(e) => {
                      handleInputChange({
                        target: {
                          name: "description",
                          value: e,
                        },
                      });
                    }}
                  />
                  <FormHelperText sx={{ color: "#C60808" }}>
                    {validator.current.message(
                      "Description",
                      details.description.replace(/<(.|\n)*?>/g, "").trim(),
                      "required"
                    )}
                  </FormHelperText>
                </Stack>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 3 }} />
            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" fontWeight={600}>
                  Images
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <Stack
                  width="100%"
                  direction="row"
                  justifyContent="space-between"
                  gap="10px"
                >
                  <Stack>
                    <DropzoneComponents
                      imgUrl={details.mobile_image}
                      handleDropzone={(e) => {
                        handleInputChange({
                          target: {
                            name: "mobile_image",
                            value: e,
                          },
                        });
                      }}
                      aspectRatio="1/1"
                    />
                  </Stack>
                  <Stack>
                    <DropzoneComponents
                      imgUrl={details.desktop_image}
                      handleDropzone={(e) => {
                        handleInputChange({
                          target: {
                            name: "desktop_image",
                            value: e,
                          },
                        });
                      }}
                      aspectRatio="2/1"
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 3 }} />

            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" fontWeight={600}>
                  Availability
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <Stack width={"100%"} gap="20px">
                  <Stack direction="row" gap="20px">
                    <InputControl
                      label="Days Before"
                      name="start_before_days"
                      sx={{
                        width: "100%",
                      }}
                      type="number"
                      value={details.start_before_days}
                      onChange={(e) => handleInputChange(e)}
                      required
                      error={validator.current.message(
                        "Days Before",
                        details.start_before_days,
                        "required|num"
                      )}
                      helperText={validator.current.message(
                        "Days Before",
                        details.start_before_days,
                        "required|num"
                      )}
                    />
                    <InputControl
                      label="Days After"
                      name="end_before_days"
                      sx={{
                        width: "100%",
                      }}
                      value={details.end_before_days}
                      onChange={(e) => handleInputChange(e)}
                      required
                      error={validator.current.message(
                        "Days After",
                        details.end_before_days,
                        "required|num"
                      )}
                      helperText={validator.current.message(
                        "Days After",
                        details.end_before_days,
                        "required|num"
                      )}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 3 }} />
            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" fontWeight={600}>
                  Action Type
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <Stack gap="20px">
                  <Grid item sm={8}>
                    <Stack width={"100%"} gap="20px">
                      <Stack direction="row" gap="20px">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="discount_type"
                          defaultValue={details?.discount_type}
                          sx={{
                            flexDirection: "row-reverse",
                          }}
                        >
                          {globalData?.coupons?.discount_type?.map(
                            (data, index) => (
                              <FormControlLabel
                                key={index}
                                value={parseInt(data.value)}
                                control={<Radio />}
                                label={data.label}
                                onChange={(e) => handleInputChange(e)}
                              />
                            )
                          )}
                        </RadioGroup>
                      </Stack>
                    </Stack>
                  </Grid>
                  <InputControl
                    name="discount_amount"
                    label={`Discount Amount (${
                      parseInt(details.discount_type) === 1 ? "$" : "%"
                    })`}
                    value={details.discount_amount}
                    onChange={(e) => handleInputChange(e)}
                    required
                    error={validator.current.message(
                      "Discount Amount",
                      details.discount_amount,
                      "required|num"
                    )}
                    helperText={validator.current.message(
                      "Discount Amount",
                      details.discount_amount,
                      "required|num"
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 3 }} />
            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" fontWeight={600}>
                  Rules
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <Stack width={"100%"} gap="20px">
                  <Stack
                    direction="row"
                    gap="24px"
                    width="100%"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      color="#636363"
                      fontWeight={400}
                      fontSize="14px"
                    >
                      Condition Type
                    </Typography>

                    <ToggleButtonGroup
                      color="primary"
                      value={details.condition_type}
                      exclusive
                      onChange={(e) =>
                        handleInputChange({
                          target: {
                            value: +e.target.value,
                            name: "condition_type",
                          },
                        })
                      }
                      aria-label="Platform"
                    >
                      {conditionType.map((item) => (
                        <ToggleButtonStyled
                          sx={{
                            width: "102px",
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#636363",
                          }}
                          value={item.value}
                        >
                          {item.label}
                        </ToggleButtonStyled>
                      ))}
                    </ToggleButtonGroup>
                  </Stack>
                  {details.conditions?.map((data, index) => {
                    return (
                      <>
                        {index > 0 && (
                          <Chip
                            label={
                              conditionType.find(
                                (item) => item.value === details.condition_type
                              )?.label
                            }
                            color="primary"
                            variant="outlined"
                            size="small"
                            sx={{
                              bgcolor: "#1560D414",
                              marginTop: "3px",
                              width: "10%",
                            }}
                            disabled={false}
                          />
                        )}
                        <Stack direction="row" gap={1} justifyContent="center">
                          <InputControl
                            type="dropdown"
                            name="conditions"
                            disableClearable={true}
                            options={ConditionList}
                            getOptionLabel={(option) => option.label}
                            onChange={(e, newValue) => {
                              handleInputChange(
                                {
                                  target: {
                                    name: "conditions",
                                    value: newValue.value,
                                  },
                                },
                                index
                              );
                            }}
                            value={
                              (ConditionList?.length > 0 &&
                                data &&
                                ConditionList?.find(
                                  (c) => c.value === parseInt(data)
                                )) ||
                              null
                            }
                            sx={{
                              width: "100%",
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Rule"
                                required
                                error={validator.current.message(
                                  "Rule",
                                  data,
                                  "required"
                                )}
                                helperText={validator.current.message(
                                  "Rule",
                                  data,
                                  "required"
                                )}
                              />
                            )}
                          />

                          <Stack
                            alignItems="center"
                            height="48px"
                            justifyContent="center"
                          >
                            <div className="action-buttons">
                              <img
                                alt="delete"
                                onClick={() => {
                                  if (details.conditions?.length > 1) {
                                    handleAddCondition("delete", index);
                                  }
                                }}
                                className={` ${
                                  details.conditions?.length === 1 &&
                                  "disabled-img"
                                }`}
                                src="/icons/ic_delete.svg"
                              />
                            </div>
                          </Stack>
                        </Stack>
                      </>
                    );
                  })}

                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{
                      width: "fit-content",
                      p: "7.75px  16px",
                    }}
                    disabled={details.conditions.includes("")}
                    onClick={() => handleAddCondition("add")}
                  >
                    Add Rule
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        )}
      </ContentLayout>
    </>
  );
};
