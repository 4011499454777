import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import {
  assignProductToOptionalGroup,
  changeOGProductOrder,
  createOptionalGroup,
  deleteOptionalGroupById,
  deleteProductFromOptionalGroup,
  editOptionalGroup,
  getCategorywiseProductsInOptionalgroup,
  getOptionalGroup,
  getOptionalGroupDetailsById,
} from "../api";
import { usePermission } from "../../../shared";
import { AppContext } from "../../../store/AppScopeProvider";
import { errorMessage, successMessage } from "../../../utils";
import { useDebounce } from "use-debounce";

export const useOptionalGroup = ({ isOptionalGroup, optionalId }) => {
  const { appState } = useContext(AppContext);
  const isEditable = usePermission("optional-group-modify");
  const isDeletable = usePermission("optional-group-delete");
  const isCreateVisible = usePermission("optional-group-create");
  const { globalData } = appState;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isEditPath = pathname.includes("/details/edit");
  const [state, setState] = useImmer({
    isBusy: false,
    initialLoad: true,
    isProducts: false,
    isOpen: false,
    id: null,
    optionalGroupName: "",
    optionalGroup: [],
    httpStatusCodes: {
      details: "",
    },
    optionalGroupDetails: {
      isSaveButtonBusy: false,
      isLoading: true,
      isOpen: false,
      isDelete: false,
      id: null,
      optionalGroupList: [],
      allOptionalGroup: [],
      filteredOptionalGroup: [],
      selectedOptionalGroup: [],
      allCategoryProductList: [],
    },
    filters: {
      isSearching: false,
      searchText: "",
      control_type: "",
    },
    data: {
      name: "",
      control_type: null,
      min_selection: "",
      max_selection: "",
      Products: [],
    },
  });

  const [debouncedText] = useDebounce(state.filters.searchText, 1000);

  useEffect(() => {
    if (isOptionalGroup) {
      getOptionalGroups();
    }
  }, [isOptionalGroup]);

  useEffect(() => {
    if (!state.initialLoad && !state.filters.isSearching) {
      const { control_type } = state.filters;
      getOptionalGroups(debouncedText, control_type);
    }
  }, [debouncedText]);

  const getOptionalGroups = async (search = null, control_type = null) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = false;
      });
      const res = await getOptionalGroup(search, control_type);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.optionalGroup = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const getOptionalGroupDetails = async (optionalId) => {
    try {
      setState((draft) => {
        draft.isDetailsFetching = true;
        draft.isBusy = true;
      });
      const res = await getOptionalGroupDetailsById(optionalId);
      if (res.success) {
        setState((draft) => {
          draft.data = res.data;
          draft.isBusy = false;
          draft.optionalGroupDetails.optionalGroupList = res.data.Products;
          draft.optionalGroupName = res.data.name;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isDetailsFetching = false;
        draft.isBusy = false;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };
  useEffect(() => {
    if (optionalId) {
      getOptionalGroupDetails(optionalId);
    }
  }, [optionalId]);
  const toggleModal = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isOpen = !draft.isOpen;
    });
  };

  const handleDeleteOptionalGroup = async (type = "list") => {
    try {
      const res = await deleteOptionalGroupById(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.optionalGroup = draft.optionalGroup.filter(
            (item) => item.id !== state.id
          );
        });
        toggleModal();
        if (type === "details") {
          navigate("/optionalGroup");
        }
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };

  const onSaveOptionalGroup = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await createOptionalGroup(state.data);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate("/optionalGroup");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onDropdown = (e) => {
    setState((draft) => {
      draft.data.control_type = e?.value;
    });
  };

  const onChangeFilter = (e) => {
    const { name, value } = e.target;
    const { searchText, control_type } = state.filters;
    if (name === "control_type") {
      getOptionalGroups(searchText, value);
    }
    setState((draft) => {
      draft.filters[name] = value;
      draft.filters.isSearching = false;
    });
  };
  const resetFilters = () => {
    if (state.filters.control_type || state.filters.searchText) {
      getOptionalGroups();
      setState((draft) => {
        draft.filters.control_type = "";
        draft.filters.searchText = "";
        draft.filters.isSearching = true;
      });
    }
  };
  const onUpdateOptionalGroup = async (optionalId) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await editOptionalGroup(optionalId, state.data);
      if (res.success) {
        successMessage("Updated Successfully");
        navigate("/optionalGroup");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const handleModal = (e) => {
    setState((draft) => {
      draft.optionalGroupDetails.isOpen = e;
      draft.isProducts = true;
      draft.optionalGroupDetails.selectedOptionalGroup = [];
      return draft;
    });
    HandleFilterOptionalGroup("initial");
  };

  const DeleteModals = (state, data) => {
    setState((draft) => {
      draft.optionalGroupDetails.isDelete = state;
      draft.optionalGroupDetails.id = data;
    });
  };
  const HandleFilterOptionalGroup = (type, text) => {
    const ProductList = state.optionalGroupDetails.allCategoryProductList
      .map((val) => {
        let filtered = val.products.filter((data) => {
          if (!["delivery", "gift_card"].includes(data.type)) return true;
        });
        return filtered.length ? { ...val, products: filtered } : undefined;
      })
      .filter(Boolean);

    if (type === "initial") {
      setState((draft) => {
        draft.optionalGroupDetails.filteredOptionalGroup = ProductList;
        return draft;
      });
    } else {
      const Search = (data, text) => {
        text = text.toLowerCase();
        return data
          .map((val, i) => {
            let filtered = val.products.filter((data) => {
              if (data.name.toString().toLowerCase().indexOf(text) > -1)
                return true;
            });
            return filtered.length ? { ...val, products: filtered } : undefined;
          })
          .filter(Boolean);
      };
      setState((draft) => {
        draft.optionalGroupDetails.filteredOptionalGroup = Search(
          ProductList,
          text
        );
        return draft;
      });
    }
  };
  useEffect(() => {
    HandleFilterOptionalGroup("initial");
  }, [
    state.optionalGroupDetails.allCategoryProductList,
    state.optionalGroupDetails.optionalGroupList,
  ]);

  const handleOptionalGroupSelect = (type, value, event) => {
    if (type === "body") {
      if (event.target.checked) {
        setState((draft) => {
          draft.optionalGroupDetails.selectedOptionalGroup = [
            ...draft.optionalGroupDetails.selectedOptionalGroup,
            value.id,
          ];
        });
      } else {
        setState((draft) => {
          draft.optionalGroupDetails.selectedOptionalGroup =
            draft.optionalGroupDetails.selectedOptionalGroup.filter(
              (val) => val !== value.id
            );
        });
      }
    } else {
      const subRowsID = value.products?.map((e, i) => {
        return e.id;
      });
      if (event.target.checked) {
        const alReadyIds = state.optionalGroupDetails.optionalGroupList.map(
          (e, i) => {
            return e.id;
          }
        );
        setState((draft) => {
          draft.optionalGroupDetails.selectedOptionalGroup = [
            ...new Set([
              ...state.optionalGroupDetails.selectedOptionalGroup,
              ...subRowsID,
            ]),
          ].filter((val) => !alReadyIds.includes(val));
        });
      } else {
        setState((draft) => {
          draft.optionalGroupDetails.selectedOptionalGroup =
            draft.optionalGroupDetails.selectedOptionalGroup.filter(
              (val) => !subRowsID.includes(val)
            );
        });
      }
    }
  };

  const handleAssignProducttoOptionalGroup = async () => {
    const data = {
      product_id: state.optionalGroupDetails.selectedOptionalGroup,
    };
    setState((draft) => {
      draft.optionalGroupDetails.isSaveButtonBusy = true;
    });
    try {
      const res = await assignProductToOptionalGroup(data, optionalId);
      if (res.success === true) {
        successMessage(res.message);
        setState((draft) => {
          draft.optionalGroupDetails.optionalGroupList = [
            ...draft.optionalGroupDetails.optionalGroupList,
            ...draft.optionalGroupDetails.allOptionalGroup.filter((val) =>
              draft.optionalGroupDetails.selectedOptionalGroup.includes(val.id)
            ),
          ];
          draft.optionalGroupDetails.isLoading = false;
          draft.optionalGroupDetails.selectedOptionalGroup = [];
          draft.optionalGroupDetails.isSaveButtonBusy = false;
          return draft;
        });
        handleModal(false);
        getOptionalGroupDetails(optionalId);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.optionalGroupDetails.isSaveButtonBusy = false;
      });
    }
  };
  const handleDeleteProducts = async (id) => {
    try {
      const res = await deleteProductFromOptionalGroup(
        optionalId,
        state.optionalGroupDetails.id
      );
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.optionalGroupDetails.optionalGroupList =
            draft.optionalGroupDetails.optionalGroupList.filter(
              (item) => item.id !== state.optionalGroupDetails.id
            );
        });
        DeleteModals(false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleSelectAllProducts = (checked) => {
    if (checked) {
      setState((draft) => {
        draft.optionalGroupDetails.selectedOptionalGroup = [
          ...draft.optionalGroupDetails.filteredOptionalGroup.map(
            (item) => item.id
          ),
        ];
      });
    } else {
      setState((draft) => {
        draft.optionalGroupDetails.selectedOptionalGroup = [];
      });
    }
  };
  useEffect(() => {
    if (optionalId && !isEditPath) {
      getCategorywiseProducts();
    }
  }, [optionalId, isEditPath]);
  const getCategorywiseProducts = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = false;
      });
      const res = await getCategorywiseProductsInOptionalgroup();
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.optionalGroupDetails.allCategoryProductList = res.data;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  const onProductsOrderChange = async (data) => {
    const payload = {
      products: data.map((x, i) => ({
        id: x.id,
        display_order: i,
      })),
    };
    try {
      const res = await changeOGProductOrder(optionalId, payload);
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  return {
    state,
    onSaveOptionalGroup,
    onChange,
    onDropdown,
    onChangeFilter,
    resetFilters,
    handleDeleteOptionalGroup,
    toggleModal,
    onUpdateOptionalGroup,
    handleModal,
    HandleFilterOptionalGroup,
    handleOptionalGroupSelect,
    handleAssignProducttoOptionalGroup,
    handleDeleteProducts,
    DeleteModals,
    isEditable,
    isDeletable,
    isCreateVisible,
    globalData,
    handleSelectAllProducts,
    onProductsOrderChange,
  };
};
