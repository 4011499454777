import * as React from "react";
import { ContentLayout, HeaderBreadcrumbs } from "../../../shared";
import { PendingReviewList, ReviewList } from "../components";

// Breadcrumbs links for navigation
const breadcrumbsLinks = [
  { name: "Dashboard", href: "/" },
  {
    name: "Review & Ratings",
    href: "/reviews-and-ratings",
  },
];

export const ReviewsAndRatingsList = () => {
  // Define tabs for the reviews and ratings section
  const tabs = React.useMemo(() => {
    return [
      {
        label: "All Reviews", // Label for the first tab
        value: "1", // Unique value for the first tab
        panel: <ReviewList table="all-reviews" />, // Component to render for this tab
      },
      {
        label: "Pending Reviews", // Label for the second tab
        value: "2", // Unique value for the second tab
        panel: <PendingReviewList table="pending-reviews" />, // Component to render for this tab
      },
    ];
  }, []); // Dependency array is empty, memoized value won't change

  return (
    <ContentLayout
      title="Reviews & Ratings" // Title for the content layout
      breadcrumbs={<HeaderBreadcrumbs links={breadcrumbsLinks} />} // Render the breadcrumbs
      tabProps={{
        label: "generalTab", // Label for the tab component
        list: tabs, // List of tabs to display
      }}
    />
  );
};
