import { API } from "../../../utils";

export const getCouponReport = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `coupons/usage-history?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};

export const DownloadCouponReportList = async (query) => {
  const res = await API.get(`export/coupon_history`, {
    ...query,
    responseType: "blob",
  });
  return res;
};
