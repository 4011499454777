import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import { OrderDetails, OrderList } from "./containers";

export const Orders = () => {
  return (
    <Routes>
      <Route path="/" element={<OrderList />} />
      <Route element={<ProtectedRoute allowedRoute={["order-view"]} />}>
        <Route path="/details/:id" element={<OrderDetails />} />
      </Route>
    </Routes>
  );
};
