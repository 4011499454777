import { useRef, useState } from "react";
import {
  Box,
  FormControl,
  Stack,
  Typography,
  Card,
  Alert,
} from "@mui/material";
import SimpleReactValidator from "simple-react-validator";
import InputControl from "../../../shared/components/InputControl";
import { useProfile } from "../hooks";
import { LoadingButton } from "@mui/lab";

export function ChangePassword() {
  const {
    state,
    setState,
    handleResetPassword,
    onChanges,
    toggleShowPassword,
    handleMouseDownPassword,
  } = useProfile({ isProfile: true });
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Card align="center" sx={{ width: 450, height: 473 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "24px",
            }}
          >
            <Typography sx={{ mt: 1 }} variant="h5" gutterBottom>
              Change Password
            </Typography>

            <FormControl sx={{ width: "390px" }} variant="outlined">
              <Typography variant="h3" gutterBottom>
                {state.resetPasswordDetails.errors && (
                  <Alert severity="error">
                    {state.resetPasswordDetails.errors}
                  </Alert>
                )}
              </Typography>
            </FormControl>
          </Box>
          <Box component="input-box" sx={{ display: "flex" }}>
            <FormControl sx={{ width: "390px" }} variant="outlined">
              <InputControl
                id="outlined-adornment-password"
                type="password"
                name="current_password"
                placeholder="Enter password"
                onChange={onChanges}
                showPassword={
                  state.resetPasswordDetails.data.showCurrentPassword
                }
                value={state.resetPasswordDetails.data.current_password}
                error={validator.current.message(
                  "Current Password",
                  state.resetPasswordDetails.data.current_password,
                  "required|min:8"
                )}
                helperText={validator.current.message(
                  "Current Password",
                  state.resetPasswordDetails.data.current_password,
                  "required|min:8"
                )}
                onClick={(e) => toggleShowPassword("showCurrentPassword")}
                onMouseDown={handleMouseDownPassword}
                label="Current Password *"
              />
            </FormControl>
          </Box>
          <Stack direction="row" alignItems="center">
            <Box sx={{ flexGrow: 1 }}>
              <FormControl sx={{ mt: 2, width: "390px" }} variant="outlined">
                <InputControl
                  id="outlined-adornment-password"
                  type="password"
                  name="password"
                  placeholder="Enter password"
                  onChange={onChanges}
                  showPassword={state.resetPasswordDetails.data.showNewPassword}
                  value={state.resetPasswordDetails.data.password}
                  error={validator.current.message(
                    "New Password",
                    state.resetPasswordDetails.data.password,
                    "required|min:8"
                  )}
                  helperText={validator.current.message(
                    "New Password",
                    state.resetPasswordDetails.data.password,
                    "required|min:8"
                  )}
                  onClick={(e) => toggleShowPassword("showNewPassword")}
                  onMouseDown={handleMouseDownPassword}
                  label="New Password *"
                />
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box sx={{ flexGrow: 1 }}>
              <FormControl
                sx={{ mt: 2, width: "390px", height: 48 }}
                variant="outlined"
              >
                <InputControl
                  id="outlined-adornment-password"
                  type="password"
                  name="password_confirmation"
                  placeholder="Enter password"
                  onChange={onChanges}
                  showPassword={
                    state.resetPasswordDetails.data.showConfirmPassword
                  }
                  value={state.resetPasswordDetails.data.password_confirmation}
                  error={validator.current.message(
                    "Confirm Password",
                    state.resetPasswordDetails.data.password_confirmation,
                    "required|min:8"
                  )}
                  helperText={validator.current.message(
                    "Confirm Password",
                    state.resetPasswordDetails.data.password_confirmation,
                    "required|min:8"
                  )}
                  onClick={(e) => toggleShowPassword("showConfirmPassword")}
                  onMouseDown={handleMouseDownPassword}
                  label="Confirm Password *"
                />
              </FormControl>
            </Box>
          </Stack>
          <Box sx={{ flexGrow: 1 }}>
            <FormControl sx={{ mt: 4, width: "390px" }} variant="outlined">
              <LoadingButton
                width="376px"
                height="48px"
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                loading={state.resetPasswordDetails.isBusy}
                onClick={() => {
                  if (validator.current.allValid()) {
                    handleResetPassword();
                  } else {
                    validator.current.showMessages();
                    forceUpdate(1);
                  }
                }}
              >
                Change Password
              </LoadingButton>
            </FormControl>
          </Box>
        </Card>
      </Box>
    </>
  );
}
