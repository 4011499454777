import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import {
  editDisplayMode,
  editDisplayPosition,
  editTemplate,
  getPosSetting,
} from "../api";
import { useProductSort } from "../../ProductSorting";

export const usePosSettings = ({ isList }) => {
  const { state: stateTemplates } = useProductSort({ isList });
  const [state, setState] = useImmer({
    isSaveButtonBusy: false,
    display_mode: "",
    display_position: "",
    posSettingData: {},
    keypad_id: "",
  });

  useEffect(() => {
    if (isList) {
      getPosSettingList();
    }
  }, [isList]);

  const getPosSettingList = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getPosSetting();
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.display_mode = res.data[0]?.display_mode;
          draft.display_position = res.data[0]?.display_position;
          draft.keypad_id = res.data[0]?.keypad_id;
          draft.posSettingData = res?.data[0];
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  const onRadioButtonChange = (value, name) => {
    if (name === "display_mode") {
      setState((draft) => {
        draft[name] = value;
      });
      onUpdatePosSettings("display-mode", value);
    } else if (name === "display_position") {
      setState((draft) => {
        draft[name] = value;
      });
      onUpdatePosSettings("display-position", value);
    }
  };
  const onUpdatePosSettings = async (data, value) => {
    let params = {};
    if (data === "display-mode") {
      params = { ...params, display_mode: value };
    }
    if (data === "display-position") {
      params = { ...params, display_position: value };
    }
    const item =
      data === "display-mode"
        ? editDisplayMode(params)
        : editDisplayPosition(params);
    try {
      const res = item;
      if (res.success) {
        successMessage("Display Mode Updated");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleOnChange = (e) => {
    setState((draft) => {
      draft.keypad_id = e?.value;
    });
  };
  const onUpdateTemplate = async () => {
    const payload = { keypad_id: state.keypad_id };

    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await editTemplate(payload);
      if (res.success) {
        successMessage("Keypad Template Updated");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  return {
    state,
    stateTemplates,
    onRadioButtonChange,
    handleOnChange,
    onUpdateTemplate,
  };
};
