import React from "react";
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
  useExpanded,
} from "react-table";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Box,
  Paper,
  Stack,
  Checkbox,
  Pagination,
  TableFooter,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Loader } from "..";
import CloseIcon from "@mui/icons-material/Close";

export const DialogTable = (props) => {
  const {
    columns,
    data,
    searchBar = true,
    filterContent = null,
    bulkDelete = false,
    defaultSearch = true,
    gotoPage,
    setPageSize,
    pagination,
    isLoading = false,
  } = props;
  const { pageIndex, pageSize, total, lastPage } = pagination;
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    preGlobalFilteredRows,
    globalFilteredRows,
    setGlobalFilter,
    state,
    rows,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      autoResetSortBy: true,
      getSubRows: (row) => row.permissions || [],
    },

    useFilters,
    useGlobalFilter,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      bulkDelete &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox
                classes={{ root: "custom-checkbox-root" }}
                {...getToggleAllRowsSelectedProps()}
              />
            ),
            Cell: ({ row }) => (
              <Checkbox
                classes={{ root: "custom-checkbox-root" }}
                {...row.getToggleRowSelectedProps()}
              />
            ),
            padding: "checkbox",
          },
          ...columns,
        ]);
    }
  );
  return (
    <>
      <Paper
        sx={{
          p: "0",
          boxShadow: "none",
        }}
      >
        <TableContainer sx={{ height: "589px" }}>
          {searchBar && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              filterContent={filterContent}
              defaultSearch={defaultSearch}
            />
          )}

          {isLoading ? (
            <Box sx={{ padding: "10px" }}>
              <Loader />
            </Box>
          ) : (
            <Table {...getTableProps()}>
              {preGlobalFilteredRows.length > 0 &&
              globalFilteredRows.length > 0 ? (
                <TableBody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <TableRow
                        key={i}
                        rel="noopener noreferrer"
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell, i) => {
                          return (
                            <TableCell
                              key={i}
                              align={cell.column?.align}
                              padding={cell.column.padding || "normal"}
                              sx={{
                                borderBottom: "1px solid #E6E6E6",
                                p: !cell.column.padding && "8px",
                              }}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={20}
                      sx={{ py: 2, backgroundColor: "#F7F7F7" }}
                    >
                      <Typography align="center" variant="body2">
                        No matches found
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              <TableFooter
                sx={{
                  background: "white",
                  position: "sticky",
                  bottom: -1,
                }}
              >
                <TablePagination
                  rowsPerPageOptions={[50, 100]}
                  showFirstButton={false}
                  count={total}
                  rowsPerPage={pageSize}
                  page={pageIndex - 1}
                  onPageChange={(e, v) => gotoPage(v + 1)}
                  onRowsPerPageChange={setPageSize}
                />
              </TableFooter>
            </Table>
          )}
        </TableContainer>
      </Paper>
    </>
  );
};
function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  filterContent,
  defaultSearch,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        background: "white",
        position: "sticky",
        top: 0,
        bottom: 0,
        zIndex: 5,
        py: "20px",
        px: "16px",
        gap: "16px",
      }}
    >
      {defaultSearch && (
        <OutlinedInput
          sx={{
            height: "48px",
          }}
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder="Search"
          size="small"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon size="large" />
            </InputAdornment>
          }
          endAdornment={
            value && (
              <InputAdornment
                sx={{ cursor: "pointer" }}
                onClick={(e) => {
                  setValue("");
                  onChange("");
                }}
                position="end"
              >
                <CloseIcon size="large" />
              </InputAdornment>
            )
          }
          fullWidth
        />
      )}
      {filterContent}
    </Stack>
  );
}
export default GlobalFilter;

export function TablePaginationsActions(props) {
  const { onPageChange, lastPage, page } = props;
  return (
    <Box sx={{ flexShrink: 0 }}>
      <Pagination
        count={lastPage}
        page={page + 1}
        shape="rounded"
        siblingCount={0}
        showFirstButton
        showLastButton
        onChange={(e, page) => onPageChange(page)}
      />
    </Box>
  );
}
