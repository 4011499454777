import { useState, useRef } from "react";
import {
  ContentLayout,
  HeaderBreadcrumbs,
  Loader,
  useDropdownApis,
} from "../../../shared";
import * as React from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Stack,
  Card,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  Checkbox,
  createFilterOptions,
  Divider,
  Switch,
} from "@mui/material";
import SimpleReactValidator from "simple-react-validator";
import InputControl from "../../../shared/components/InputControl";
import { styled } from "@mui/material/styles";
import { useGiftCard } from "../hooks/useGiftCard";

export const AddorEditGiftCard = () => {
  const { id } = useParams();
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const {
    state,
    globalData,
    onChange,
    handleConditionType,
    onSaveGiftCard,
    onDeleteStoreSelect,
    onUpdateGiftCard,
    isCreateVisible,
  } = useGiftCard({
    isAllCards: false,
    isBatchCards: false,
    giftCardId: id,
    isStore: true,
  });
  const { storeList } = useDropdownApis({
    isStoreList: true,
    storeId: null,
  });
  const ToggleButtonStyled = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: "#1560D4",
    },
  });

  const ConditionsType = [
    { label: "Years", value: 0 },
    { label: "Months", value: 1 },
  ];

  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdateGiftCard(id) : onSaveGiftCard();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const StoreOptions = storeList.map((v) => {
    return { id: v.id, name: v.name };
  });
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.name,
  });

if (state.isBusy) {
  return <Loader />;
}
  return (
    <>
      {validator.current.purgeFields()}
      <ContentLayout
        title={id ? state.giftCardDetails.batch_name : "New Gift Card"}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={
                !id
                  ? [
                      {
                        name: "Dashboard",
                        href: "/",
                      },
                      {
                        name: "Gift Card",
                        href: "/gift-card",
                      },
                      { name: "create" },
                    ]
                  : [
                      {
                        name: "Dashboard",
                        href: "/",
                      },
                      {
                        name: "Gift Card",
                        href: "/gift-card",
                      },
                      {
                        name: state.giftCardDetails.batch_name,
                        href: `/gift-card/details/${id}`,
                      },
                      { name: "edit" },
                    ]
              }
            />
          </>
        }
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              disabled={state.isSaveButtonBusy}
              onClick={() => onSubmit()}
            >
              {id ? "Update" : "Save"}
            </Button>
          </>
        }
      >
        {!id && (
          <Card sx={{ p: "32px 24px", mb: "24px" }}>
            {validator.current.purgeFields()}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  sx={{ width: "150px" }}
                >
                  Card Type
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <Stack direction="row" gap="24px" width="100%">
                  <InputControl
                    type="dropdown"
                    name="card_type"
                    disableClearable
                    options={globalData?.gift_cards?.card_type ?? []}
                    value={
                      globalData?.gift_cards?.card_type &&
                      state.data.card_type &&
                      globalData?.gift_cards?.card_type
                        .filter((i) => i.value === state.data.card_type)
                        .map((i) => i.label)
                    }
                    sx={{
                      width: "100%",
                    }}
                    onChange={(e, newValue) => {
                      onChange({
                        target: {
                          name: "card_type",
                          value: newValue.value,
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        sx={{
                          borderRadius: "8px",
                          ".MuiInputBase-root": { height: "44px" },
                        }}
                        label="Gift Card Type"
                        error={validator.current.message(
                          "Gift Card Type",
                          state.data.card_type,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Gift Card Type",
                          state.data.card_type,
                          "required"
                        )}
                        required
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Card>
        )}

        <Card sx={{ p: "32px 24px", mb: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Basic Details
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack mt={2} spacing={2}>
                <InputControl
                  name="batch_name"
                  label="Batch Name"
                  value={state.data.batch_name}
                  onChange={(e) => onChange(e)}
                  error={validator.current.message(
                    "Batch Name",
                    state.data.batch_name,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "Batch Name",
                    state.data.batch_name,
                    "required"
                  )}
                  required
                />
                {state.data.card_type === 1 && (
                  <InputControl
                    name="amount"
                    label="Amount"
                    type="number"
                    value={state.data.amount}
                    disabled={id}
                    onChange={(e) => onChange(e)}
                    error={validator.current.message(
                      "Amount",
                      state.data.amount,
                      "required|numeric"
                    )}
                    helperText={validator.current.message(
                      "Amount",
                      state.data.amount,
                      "required|numeric"
                    )}
                    required
                  />
                )}
                <InputControl
                  name="quantity"
                  label="Quantity"
                  type="number"
                  disabled={id}
                  value={state.data.quantity}
                  onChange={(e) => onChange(e)}
                  error={validator.current.message(
                    "Quantity",
                    state.data.quantity,
                    "required|numeric|integer"
                  )}
                  helperText={validator.current.message(
                    "Quantity",
                    state.data.quantity,
                    "required|numeric|integer"
                  )}
                  required
                />
                <Grid container spacing={2} gap={2}>
                  <Grid xs={8}>
                    <InputControl
                      name="validity"
                      label="Validity"
                      type="number"
                      disabled={id}
                      value={
                        id
                          ? state.data.is_year_month === 1
                            ? state.data.validity / 30
                            : state.data.validity / 365
                          : state.data.validity
                      }
                      onChange={(e) => onChange(e)}
                      error={validator.current.message(
                        "Validity",
                        state.data.validity,
                        "required|integer"
                      )}
                      helperText={validator.current.message(
                        "Validity",
                        state.data.validity,
                        "required|integer"
                      )}
                      required
                    />
                  </Grid>
                  <Grid xs={3}>
                    <ToggleButtonGroup
                      color="primary"
                      value={state.data.is_year_month}
                      exclusive
                      onChange={handleConditionType}
                      aria-label="Platform"
                      className="custom-pos"
                      disabled={id}
                    >
                      {ConditionsType.map((item) => (
                        <ToggleButtonStyled
                          sx={{
                            width: "102px",
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#636363",
                          }}
                          value={item.value}
                        >
                          {item.label}
                        </ToggleButtonStyled>
                      ))}
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                <InputControl
                  type="dropdown"
                  name="store_id"
                  label="Store"
                  multiple={true}
                  options={StoreOptions ?? []}
                  value={
                    StoreOptions?.filter((op) => {
                      return state?.data?.stores?.find(
                        (item) => item.id === op.id
                      );
                    }) || null
                  }
                  getOptionDisabled={(value) =>
                    !!state.data.stores.find((item) => item.id === value.id)
                  }
                  disableCloseOnSelect
                  disableClearable={true}
                  onChange={(e, v) => {
                    onChange({
                      target: {
                        name: "stores",
                        value: v,
                      },
                    });
                  }}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.id}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: "100%" }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "100%",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Store" placeholder="Store" />
                  )}
                />

                <Typography
                  color="#636363"
                  sx={{ fontSize: "14px", fontWeight: "400px" }}
                >
                  <img alt="delete" src="/icons/icon_not.svg" /> If not selected
                  Gift card is applicable to all stores.
                </Typography>

                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "12px",
                    marginTop: 2,
                  }}
                >
                  {state?.data?.stores?.map((data, index) => (
                    <Chip
                      key={index}
                      disabled={id}
                      label={
                        <Typography variant="body2" fontSize="14px">
                          {data.name}
                        </Typography>
                      }
                      onDelete={() => {
                        onDeleteStoreSelect(data.id);
                      }}
                      sx={{
                        backgroundColor: "#F2F2F2",
                        height: "30px",
                        "& .MuiChip-deleteIcon": {
                          color: "#CCCCCC",
                        },
                      }}
                    />
                  ))}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          {Number(state.data.card_type) === 1 && (
            <>
              <Divider sx={{ m: "16px -24px" }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} mt="10px">
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Pre-Activation
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack gap="20px">
                    <Switch
                      defaultChecked={
                        state.data.is_pre_activated ? true : false
                      }
                      checked={state.data.is_pre_activated}
                      onChange={(e, v) =>
                        onChange({
                          target: {
                            name: "is_pre_activated",
                            value: v ? 1 : 0,
                          },
                        })
                      }
                      disabled={id}
                      name="is_pre_activated"
                    />
                    {!!state.data.is_pre_activated && (
                      <Stack direction="row" gap="24px" width="100%">
                        <InputControl
                          type="dropdown"
                          name="card_type"
                          disableClearable
                          options={StoreOptions ?? []}
                          multiple={false}
                          disabled={id}
                          value={
                            (StoreOptions.length > 0 &&
                              state.data.seller_store_id !== null &&
                              StoreOptions?.find(
                                (c) => c.id === state.data.seller_store_id
                              )) ||
                            null
                          }
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value
                          }
                          sx={{
                            width: "100%",
                          }}
                          onChange={(e, newValue) => {
                            onChange({
                              target: {
                                name: "seller_store_id",
                                value: newValue.id,
                              },
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              sx={{
                                borderRadius: "8px",
                                ".MuiInputBase-root": { height: "44px" },
                              }}
                              label="Seller Store"
                              error={validator.current.message(
                                "Seller Store",
                                state.data.seller_store_id,
                                "required"
                              )}
                              helperText={validator.current.message(
                                "Seller Store",
                                state.data.seller_store_id,
                                "required"
                              )}
                              required
                            />
                          )}
                        />
                      </Stack>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </>
          )}
        </Card>
      </ContentLayout>
    </>
  );
};
