import { HeaderBreadcrumbs, ContentLayout } from "../../../shared";
import * as React from "react";
import { LedgerAccountsList } from "../../Configurations/components";

export const BirthdayCouponAccountList = () => {
  return (
    <>
      <ContentLayout
        title="Birthday Coupons"
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Birthday Coupons", href: "/birthday-coupons" },
                { name: "Accounts" },
              ]}
            />
          </>
        }
      >
        <LedgerAccountsList type="BirthdayCoupon" />
      </ContentLayout>
    </>
  );
};
