import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import {
  ContentLayout,
  CustomDetailsList,
  EndofDayTable,
  EndofDayTableSimple,
  HeaderBreadcrumbs,
  Loader,
} from "../../../shared";

import InputControl from "../../../shared/components/InputControl";
import { useTerminals } from "../hooks";
import SimpleReactValidator from "simple-react-validator";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { errorMessage } from "../../../utils";
import { useEffect } from "react";
import Swal from "sweetalert2";

export const EndofDay = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [rerender, forceUpdate] = React.useState(false);
  const eodvalidator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        floatAmount: {
          message: "The float amount cannot be negative",
          rule: (value) => {
            return value >= 0 ? true : false;
          },
        },
      },
    })
  );
  const filtervalidator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const {
    state,
    setState,
    storeList,
    onTerminalChange,
    onSaveTerminalEndofDay,
    handleFloatChange,
    handleCashChange,
    handleDateChange,
    Cashtotal,
    Floattotal,
    isSummaryVisble,
    handleTerminalEndofDaySummary,
    handleDropdown,
    onTerminalChanges,
    onChangeRemarks,
    variance,
    onChangeEodType,
  } = useTerminals({ id: id, eod: true, isStoreList: true });

  const { endofdaySummary } = state;

  const checkMinValues = (data) => {
    const errors = [];
    data.forEach((item) => {
      if (+item.entry_count > 0 && +item.bank < 0) {
        errors.push({ label: item.denomination_label });
      }
    });
    return errors.length > 0 ? true : false;
  };

  const onSubmit = () => {
    const hasError = checkMinValues(state.endofday.denominations);
    if (hasError) {
      Swal.fire({
        title: "Check bank values",
        icon: "error",
        text: "Bank fields must be a positive value",
      });
    } else {
      const { variance_status, eod_variance } = endofdaySummary;
      if (state.endofday.float_total < 0) {
        errorMessage("The float amount cannot be negative");
      }
      if (eodvalidator.current.allValid()) {
        if (
          Number(Floattotal) !== Number(state.endofday.float_total) &&
          !state.endofday.cashless_till
        ) {
          errorMessage("Float amount and float total is not equal");
        } else if (
          variance_status &&
          Math.abs(variance.toFixed(2)) > eod_variance &&
          !state.isSubmitted
        ) {
          errorMessage("Please recount and submit.");
          setState((draft) => {
            draft.varianceError = true;
            draft.isSubmitted = true;
          });
          forceUpdate(!rerender);
        } else {
          onSaveTerminalEndofDay(id);
        }
      } else {
        eodvalidator.current.showMessages();
        forceUpdate(!rerender);
      }
    }
  };

  const onApply = () => {
    if (filtervalidator.current.allValid()) {
      handleTerminalEndofDaySummary();
    } else {
      filtervalidator.current.showMessages();
      forceUpdate(!rerender);
    }
  };
  const optionsStores = storeList?.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const optionsTerminals = state?.terminalData.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const column = [
    {
      title: "Staff Name",
      accessor: "user_name",
    },
    {
      title: "Submit Date",
      accessor: "sale_date",
    },
    {
      title: "Status",
      accessor: ({ status }) => {
        return (
          <Chip
            label={status === 1 ? "Approved" : "Pending"}
            color={status === 1 ? "success" : "warning"}
            variant={"outlined"}
            sx={{ bgcolor: "#FFF4CE" }}
            size="small"
          />
        );
      },
    },
  ];
  useEffect(() => {
    const { variance_status, eod_variance } = endofdaySummary;
    if (state.isSubmitted) {
      if (Math.abs(variance) > eod_variance && variance_status) {
        setState((draft) => {
          draft.varianceError = true;
        });
        forceUpdate(!rerender);
      } else {
        setState((draft) => {
          draft.varianceError = false;
        });
        forceUpdate(!rerender);
      }
    }
  }, [variance, state.isSubmitted]);

  return (
    <ContentLayout
      title={state.data.name}
      breadcrumbs={
        true ? (
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                {
                  name: "Terminals",
                  href: `/terminal`,
                },
                {
                  name: state.data?.name || "",
                  href: `/terminal/details/${id}`,
                },
                { name: "End of Day" },
              ]}
            />
          </>
        ) : (
          " "
        )
      }
    >
      {eodvalidator.current.purgeFields()}
      <Card sx={{ p: "32px 24px", mb: "24px", height: "110px" }}>
        <Stack direction="row" spacing={2}>
          <InputControl
            name="store_id"
            type="dropdown"
            options={optionsStores}
            value={
              (optionsStores.length > 0 &&
                state.filterData.store_id &&
                optionsStores?.find(
                  (c) => c.value === state.filterData.store_id
                )) ||
              null
            }
            getOptionLabel={(option) => option.label}
            onChange={(e, newValue) => handleDropdown(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Store Name"
                error={filtervalidator.current.message(
                  "Store Name",
                  state.filterData.store_id,
                  "required"
                )}
                helperText={filtervalidator.current.message(
                  "Store Name",
                  state.filterData.store_id,
                  "required"
                )}
              />
            )}
          />

          <InputControl
            name="terminal_id"
            type="dropdown"
            options={optionsTerminals}
            getOptionLabel={(option) => option.label}
            value={
              (optionsTerminals.length > 0 &&
                state.filterData.terminal_id &&
                optionsTerminals?.find(
                  (c) => c.value === state.filterData.terminal_id
                )) ||
              null
            }
            onChange={(e, newValue) =>
              onTerminalChanges({
                target: {
                  name: "terminal_id",
                  value: newValue?.value || "",
                },
              })
            }
            renderInput={(params) => (
              <TextField
                label="Terminal Name"
                {...params}
                error={filtervalidator.current.message(
                  "Terminal Name",
                  state.filterData.terminal_id,
                  "required"
                )}
                helperText={filtervalidator.current.message(
                  "Terminal Name",
                  state.filterData.terminal_id,
                  "required"
                )}
              />
            )}
          />
          <InputControl
            type="datePicker"
            disableFuture={true}
            name="sale_date"
            label="Sale Date"
            inputFormat="dd/MM/yyyy"
            sx={{
              width: "100%",
            }}
            required
            value={state.filterData.sale_date && state.filterData.sale_date}
            onChange={(e) => {
              handleDateChange(e, "sale_date");
            }}
            error={filtervalidator.current.message(
              "Sale Date",
              state.filterData.sale_date,
              "required"
            )}
            helperText={filtervalidator.current.message(
              "Sale Date",
              state.filterData.sale_date,
              "required"
            )}
          />
          <Button
            variant="contained"
            sx={{ height: "40px", width: "68px" }}
            disabled={state.isApplyButtonBusy}
            onClick={() => onApply()}
          >
            Apply
          </Button>
        </Stack>
      </Card>
      {state.isLoading ? (
        <Loader />
      ) : (
        <>
          {state.showEodTable === false && (
            <Box>
              <CustomDetailsList
                columns={column}
                data={state?.eod ? state?.eod : {}}
                title="Submitted Details"
                headerRightContent={
                  <>
                    <Button
                      type="button"
                      size="medium"
                      variant="text"
                      flex-direction="right"
                      onClick={() =>
                        navigate(`/end-of-day/details/${state?.eod?.id}`)
                      }
                    >
                      View Details
                    </Button>
                  </>
                }
              />
            </Box>
          )}
          {state.showEodTable === true && (
            <>
              <Card>
                {!state.endofday.cashless_till && (
                  <>
                    <Grid container sx={{ p: "32px 24px", height: "110px" }}>
                      <Grid item xs={2}>
                        <Typography variant="subtitle1" fontWeight={600}>
                          Float Amount
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <InputControl
                          name="float_total"
                          label="Amount ($)"
                          type="number"
                          value={state.endofday.float_total}
                          onChange={(e) => onTerminalChange(e)}
                          error={eodvalidator.current.message(
                            "Amount",
                            state.endofday.float_total,
                            "required|numeric|integer|floatAmount"
                          )}
                          helperText={eodvalidator.current.message(
                            "Amount",
                            state.endofday.float_total,
                            "required|numeric|integer|floatAmount"
                          )}
                          disabled
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Typography sx={{ fontSize: 600, fontSize: "16px" }}>
                          {state.endofday.eod_template_name}
                        </Typography>
                      </Grid>
                    </Grid>
                    {state.endofday.simple ? (
                      <EndofDayTableSimple
                        denominations={state.endofday.denominations}
                        float_amount={state.endofday.float_total}
                        isCalculated={state.isCalculated}
                        setState={setState}
                        stateKey="endofday"
                      />
                    ) : (
                      <EndofDayTable
                        denominations={state.endofday.denominations}
                        float_amount={state.endofday.float_total}
                        isCalculated={state.isCalculated}
                        setState={setState}
                        stateKey="endofday"
                      />
                    )}
                  </>
                )}
                <Stack
                  sx={{
                    flexDirection: "row",
                    padding: "48px 24px 16px",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    minWidth="200px"
                  >
                    Cheque
                  </Typography>
                  <InputControl
                    name="cheque_total"
                    label="(In $)"
                    type="number"
                    value={state.endofday.cheque_total}
                    onChange={(e) => onTerminalChange(e)}
                  />
                </Stack>
                <Divider />
                {!isSummaryVisble && (
                  <>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        bgcolor: "#E1FBF2",
                        height: "77px",
                        p: "24px",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        color="#0F875B"
                        minWidth="200px"
                      >
                        Total Amount
                      </Typography>
                      <Typography
                        fontWeight={600}
                        fontSize={24}
                        color="#0F875B"
                      >
                        ${(Cashtotal + +state.endofday.cheque_total).toFixed(2)}
                      </Typography>
                    </Stack>
                    <Divider />
                    {state.varianceError && (
                      <RemarksSection
                        state={state}
                        onChangeRemarks={onChangeRemarks}
                        eodvalidator={eodvalidator}
                      />
                    )}
                    <Stack
                      sx={{
                        p: "24px 16px",
                        alignItems: "flex-end",
                      }}
                    >
                      <Button
                        type="button"
                        size="medium"
                        variant="contained"
                        disabled={state.isSaveButtonBusy}
                        onClick={() => onSubmit()}
                      >
                        {isSummaryVisble ? "End The Day" : "Submit"}
                      </Button>
                    </Stack>
                  </>
                )}
              </Card>
            </>
          )}
          {state.showEodTable === true && (
            <Box marginTop={2} marginBottom={5}>
              {isSummaryVisble && (
                <Card>
                  <Grid
                    container
                    sx={{
                      height: "83px",
                      padding: "32px 24px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item>
                      <Typography
                        variant="h5"
                        fontSize={16}
                        sx={{
                          lineHeight: "19px",
                        }}
                      >
                        Summary
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Stack direction="row">
                    <Stack
                      border={1}
                      borderColor="#E6E6E6"
                      width="100%"
                      height="48px"
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "14.5px 26px",
                      }}
                    >
                      <Typography>Cash in Hand</Typography>
                      <Typography>${Cashtotal.toFixed(2)}</Typography>
                    </Stack>
                    <Stack
                      border={1}
                      borderColor="#E6E6E6"
                      width="100%"
                      height="48px"
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "14.5px 26px",
                      }}
                    >
                      <Typography>Loyalty</Typography>

                      <Typography>
                        <Typography>
                          ${state?.endofdaySummary?.loyalty_total?.toFixed(2)}
                        </Typography>
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row">
                    <Stack
                      border={1}
                      borderColor="#E6E6E6"
                      width="100%"
                      height="48px"
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "14.5px 26px",
                      }}
                    >
                      <Typography>System Cash</Typography>
                      <Typography>
                        ${state?.endofdaySummary?.system_cash?.toFixed(2)}
                      </Typography>
                    </Stack>
                    <Stack
                      border={1}
                      borderColor="#E6E6E6"
                      width="100%"
                      height="48px"
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "14.5px 26px",
                      }}
                    >
                      <Typography>Gift Card</Typography>
                      <Typography>
                        ${state?.endofdaySummary?.gift_card_total?.toFixed(2)}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row">
                    <Stack
                      border={1}
                      borderColor="#E6E6E6"
                      width="100%"
                      height="48px"
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "14.5px 26px",
                      }}
                    >
                      <Typography>Round off</Typography>
                      <Typography>
                        <Typography>
                          ${state?.endofdaySummary?.round_off_total?.toFixed(2)}
                        </Typography>
                      </Typography>
                    </Stack>
                    <Stack
                      border={1}
                      borderColor="#E6E6E6"
                      width="100%"
                      height="48px"
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "14.5px 26px",
                      }}
                    >
                      <Typography>Client Account</Typography>
                      <Typography>
                        ${state.endofdaySummary.client_account_total.toFixed(2)}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row">
                    <Stack
                      border={1}
                      borderColor="#E6E6E6"
                      width="100%"
                      height="48px"
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "14.5px 26px",
                      }}
                    >
                      <Typography>Variance</Typography>
                      <Typography color={variance < 0 ? "#C42B1C" : "#0F875B"}>
                        ${variance.toFixed(2)}
                      </Typography>
                    </Stack>
                    <Stack
                      border={1}
                      borderColor="#E6E6E6"
                      width="100%"
                      height="48px"
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "14.5px 26px",
                      }}
                    >
                      <Typography>EFT/Card</Typography>
                      <Typography>
                        <Typography>
                          ${state.endofdaySummary.card_total?.toFixed(2)}
                        </Typography>
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row">
                    <Stack
                      border={1}
                      borderColor="#E6E6E6"
                      width="100%"
                      height="48px"
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "14.5px 26px",
                      }}
                    >
                      <Typography>Cheque</Typography>
                      <Typography>
                        $
                        {state?.endofdaySummary?.cheque_total
                          ? state?.endofdaySummary?.cheque_total?.toFixed(2)
                          : "0.00"}
                      </Typography>
                    </Stack>
                    <Stack
                      border={1}
                      borderColor="#E6E6E6"
                      width="100%"
                      height="48px"
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "14.5px 26px",
                      }}
                    >
                      <Typography color="#808080">Total Tax</Typography>
                      <Typography color="#808080">
                        ${state.endofdaySummary.total_tax?.toFixed(2)}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Box
                    component="main"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#E1FBF2",
                      p: "32px 24px",
                    }}
                  >
                    <Typography fontWeight={600} fontSize={24} color="#0F875B">
                      Total Amount: $
                      {(Cashtotal + +state.endofday.cheque_total).toFixed(2)}
                    </Typography>
                  </Box>
                  <Divider />
                  {state.varianceError && (
                    <RemarksSection
                      state={state}
                      onChangeRemarks={onChangeRemarks}
                      eodvalidator={eodvalidator}
                    />
                  )}
                  <Button
                    type="button"
                    size="medium"
                    variant="contained"
                    sx={{
                      float: "right",
                      margin: "24px",
                    }}
                    onClick={() => onSubmit()}
                    disabled={state.isSaveButtonBusy}
                  >
                    {isSummaryVisble ? "End The Day" : "Submit"}
                  </Button>
                </Card>
              )}
            </Box>
          )}
        </>
      )}
    </ContentLayout>
  );
};

const RemarksSection = ({ state, onChangeRemarks, eodvalidator }) => {
  return (
    <Stack margin="24px" gap="14px">
      <Alert color="error" severity="warning">
        Please recount and submit.
      </Alert>
      <InputControl
        type="text"
        multiline
        name="remarks"
        value={state.endofday.remarks}
        onChange={onChangeRemarks}
        placeholder="Remarks*"
        sx={{
          ".MuiInputBase-multiline": {
            minHeight: "110px",
            alignItems: "flex-start",
          },
        }}
        error={eodvalidator.current.message(
          "Remarks",
          state.endofday.remarks,
          "required"
        )}
        helperText={eodvalidator.current.message(
          "Remarks",
          state.endofday.remarks,
          "required"
        )}
      />
    </Stack>
  );
};
