import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

import MBreadcrumbs from "../../shared/components/MBreadcrumbs";

HeaderBreadcrumbs.propTypes = {
  links: PropTypes.array,
  action: PropTypes.node,
  heading: PropTypes.string,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sx: PropTypes.object,
};

export function HeaderBreadcrumbs({
  links,
  action,
  heading = "",
  moreLink = "" || [],
  sx,
  ...other
}) {
  return (
    <Box sx={{ ...sx }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="caption" gutterBottom>
            <b> {heading}</b>
          </Typography>
          <MBreadcrumbs
            links={links}
            {...other}
            sx={{
              "& .separator": {
                mx: "4px",
              },
            }}
          />
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>
    </Box>
  );
}
