import { Avatar, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import InputControl from "./InputControl";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { successMessage } from "../../utils";

export const ResetPassCode = ({ email, passCode }) => {
  const handlePasswordCopy = () => {
    navigator.clipboard.writeText("");
    successMessage("The temporary pincode is copied to the clipboard");
  };
  return (
    <Box
      sx={{
        margin: "68px 24px 24px 24px",
        minHeight: "269px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img height={80} width={80} src="/icons/Ellipse.png" />
      <Typography my={2} fontWeight={400} fontSize="32px" lineHeight="42px">
        Pincode Changed
      </Typography>
      <Typography
        sx={{ marginBottom: "24px" }}
        fontWeight={400}
        fontSize="14px"
        lineHeight="18px"
        textAlign={"center"}
      >
        {`Your pincode has been successfully changed. And mailed to
         ${email}`}
      </Typography>
      <Stack direction="row" gap="10px" alignItems="center" width="100%">
        <InputControl
          type="text"
          name="pass"
          label="New Pincode"
          value={passCode ?? ""}
          disabled
          InputProps={{
            sx: {
              bgcolor: "rgba(0, 0, 0, 0.03)",
            },
          }}
        />

        <Avatar
          sx={{
            height: 46,
            width: 46,
            bgcolor: " rgba(0, 0, 0, 0.03)",
            cursor: "pointer",
            "&:hover": {
              bgcolor: "rgba(0,0,0, 0.08)",
            },
          }}
          variant="rounded"
          onClick={handlePasswordCopy}
        >
          <ContentCopyIcon
            sx={{
              color: "#000",
            }}
          />
        </Avatar>
      </Stack>
    </Box>
  );
};
