import * as React from "react";
import { CustomeDialog, ServerPaginatedTable } from "../../../shared";
import { Box, Button, Card, Stack, Typography } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { DashBoardDateRangePicker } from "../..";
import CloseIcon from "@mui/icons-material/Close";
import SyncSharpIcon from '@mui/icons-material/SyncSharp';
import { keyframes } from "@mui/system";

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;


// Main component that renders the collapsible table
const HistoryList = ({
  title,
  state,
  globalData,
  gotoPage,
  setPageSize,
  handleSort,
  onChangeFilter,
  handleChangeStatus,
  handleIsOpen,
  handleDateChangeApply,
  onChangeDate,
  resetFilters,
  validator,
  validateDate,
  handleInputChange,
  onSubmit,
  handleConnectAndDisconnect,
  handleSync,
  handleExportModal,
  handleMoreModal,
}) => {

  const { pagination, sortBy, isHistoryBusy, filterApplied } = state


  const TableCell2 = ({ value }) => {

    const [isTextOverflowing, setIsTextOverflowing] = React.useState(false);
    const textRef = React.useRef(null);

    React.useEffect(() => {
      if (textRef.current) {
        const isOverflowing =
          textRef.current.scrollWidth > textRef.current.clientWidth;
        setIsTextOverflowing(isOverflowing);
      }
    }, [textRef]);

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          ref={textRef}
          variant="subtitle2"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '400px'
          }} >
          {value}
        </Typography>
        {isTextOverflowing && (<Button
          variant="text"
          size="small"
          sx={{
            height: "28px !important",
            border: "none !important",
            p: "6px 8px"
          }}
          onClick={() => handleMoreModal(true, value)}
        >
          More
        </Button>
        )}
      </Box>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Sync Date",
        accessor: "sync_date",
        minWidth: 210,
      },
      {
        Header: "Journal Date",
        accessor: "journal_date",
        minWidth: 210,
      },
      {
        Header: "Activity Name",
        accessor: "name",
        minWidth: 116,
        align: "center",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return (
            <Typography variant="subtitle2" color={value == "Failure" ? "#C42B1C" : value == "Success" ? "#0F875B" : "#C07302"}>{value}</Typography>
          );
        },
        minWidth: 142,
      },
      {
        Header: "Remarks",
        accessor: "remarks",
        minWidth: 380,
        disableSortBy: true,
        Cell: TableCell2,
      },
      {
        Header: " ",
        accessor: "action",
        minWidth: 380,
        Cell: ({ row, value }) => {

          return row.original.status == "Failure" && (
            <Button
              variant="text"
              size="small"
              color="inherit"
              sx={{
                height: "34px",
                color: "#1560D4",
                border: "none !important",
                fontWeight: "600"
              }} //
              onClick={() => handleSync(row.original.journal_date, row.original.store_id)}
            // disabled={state.syncLoading}
            >
              Sync
            </Button>
          );
        },
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <>
      <Card
        sx={{ mb: "16px" }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            p: "32px 24px",
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              // justifyContent: "space-between",
              alignItems: "center",
              gap: "16px"
            }}
          >
            <Stack gap="4px" flexDirection="row" alignItems="center">
              <Typography variant="subtitle1" textTransform={"capitalize"} fontWeight={600} lineHeight="24px" width="190px">
                Sync to {title}
              </Typography>
              <InputControl
                type="datePicker"
                name="date"
                label="Date"
                disableFuture
                inputFormat="dd/MM/yyyy"
                sx={{
                  minWidth: "251.33px",
                }}
                value={state.syncDates ? state.syncDates : null}
                onChange={(e) => {
                  handleInputChange(e, "syncDate");
                }}
              />
            </Stack>
            <Button
              variant="contained"
              size="small"
              sx={{ height: "40px", border: "none !important", fontWeight: "600" }} //
              onClick={() => handleSync(null, null)}
              disabled={state.syncLoading}
              startIcon={
                <SyncSharpIcon
                  sx={{
                    animation: state.syncLoading
                      ? `${rotate} 1s linear infinite` // Apply rotation when loading
                      : "none",
                  }}
                />
              }
            >
              Sync
            </Button>
          </Stack>
          <Button
            variant="text"
            size="small"
            color="inherit"
            sx={{ height: "40px", color: "#1560D4", border: "none !important", fontWeight: "600" }} //
            onClick={() => handleExportModal(true)}
          >
            Export to CSV
          </Button>
        </Stack>
      </Card>
      <ServerPaginatedTable
        columns={columns}
        data={state.history}
        searchBar={true}
        rowsSelection={false}
        key={sortBy}
        isLoading={isHistoryBusy}
        defaultSearch={false}
        gotoPage={gotoPage}
        setPageSize={setPageSize}
        pagination={pagination}
        handleSort={handleSort}
        sortByGlobal={sortBy}
        filterContent={
          <Stack direction="row" gap="16px" alignItems="center" width="100%">
            <InputControl
              type="table-search"
              name="search"
              placeholder="Search"
              value={filterApplied.searchText}
              onChange={onChangeFilter}
              onClear={() => onChangeFilter({ target: { value: "" } })}
            />
            <InputControl
              type="select"
              name="Status"
              label="Status"
              value={filterApplied.status ?? ""}
              optionValue={globalData?.xero_myob_integration?.status ?? []}
              width="280px"
              onChange={(e, v) => handleChangeStatus(e)}
            />
            <DashBoardDateRangePicker
              onChangeDate={(e) => onChangeDate(e, "sync")}
              isList={true}
              position="left"
              range={state.syncDate}
              showRange={state.syncShowDate}
              isOpen={state.isSyncOpen}
              handleIsOpen={(v) => handleIsOpen(v, "sync")}
              handleDateChangeApply={() => handleDateChangeApply("sync")}
              label="Sync Date"
            />
            <DashBoardDateRangePicker
              onChangeDate={(e) => onChangeDate(e, "journal")}
              isList={true}
              position="left"
              range={state.journalDate}
              showRange={state.journalShowDate}
              isOpen={state.isJournalOpen}
              handleIsOpen={(v) => handleIsOpen(v, "journal")}
              handleDateChangeApply={() => handleDateChangeApply("journal")}
              label="Journal Date"
            />
            <Button
              type="button"
              size="medium"
              variant="text"
              color="gray"
              sx={{
                minWidth: "40px",
                p: "8px",
              }}
              onClick={() => resetFilters()}
            >
              <CloseIcon />
            </Button>
          </Stack>
        }
      />
        <CustomeDialog
          open={state.exportModal}
          handleClose={() => handleExportModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx: { width: "423px", height: "290px", p: "16px" },
          }}
          title="Export To CSV"
          content={
            <Stack sx={{
              gap: "20px",
              pt: "20px",
              height: "100%"
            }}>
              <InputControl
                type="datePicker"
                name="start_date"
                label="Start Date"
                disableFuture
                inputFormat="dd/MM/yyyy"
                sx={{
                  minWidth: "251.33px",
                }}
                value={state.data.start_date ? state.data.start_date : null}
                onChange={(e) => {
                  handleInputChange(e, "start_date");
                }}
                onError={(reason, _) => validateDate(reason, "start_date")}
                error={
                  state.validation.start_date
                    ? state.validation.isValidated && state.validation.start_date
                    : validator.current.message(
                      "start date",
                      state.data.start_date,
                      "required"
                    )
                }
                helperText={
                  state.validation.start_date
                    ? state.validation.isValidated && state.validation.start_date
                    : validator.current.message(
                      "start date",
                      state.data.start_date,
                      "required"
                    )
                }
              />
              <InputControl
                type="datePicker"
                name="end_date"
                label="End Date"
                disableFuture
                inputFormat="dd/MM/yyyy"
                sx={{
                  minWidth: "251.33px",
                }}
                value={state.data.end_date ? state.data.end_date : null}
                onChange={(e) => {
                  handleInputChange(e, "end_date");
                }}
                onError={(reason, value) => validateDate(reason, "end_date")}
                error={
                  state.validation.end_date
                    ? state.validation.isValidated && state.validation.end_date
                    : validator.current.message(
                      "end date",
                      {
                        startDate: state.data.start_date,
                        endDate: state.data.end_date,
                      },
                      "required|endDate"
                    )
                }
                helperText={
                  state.validation.end_date
                    ? state.validation.isValidated && state.validation.end_date
                    : validator.current.message(
                      "end date",
                      {
                        startDate: state.data.start_date,
                        endDate: state.data.end_date,
                      },
                      "required|endDate"
                    )
                }
              />
              <Button
                variant="contained"
                size="small"
                sx={{ height: "40px", alignSelf: "end", marginTop: "auto" }} //
                disabled={
                  state.isBusy ||
                  state.data.start_date === "" ||
                  state.data.end_date === "" ||
                  state.data.store_id === ""
                }
                onClick={onSubmit}
              >
                Export
              </Button>
            </Stack>}
        />
      <CustomeDialog
        open={state.moreModal}
        handleClose={() => handleMoreModal(false, "")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "423px",
            minHeight: "150px",
            height: "auto",
            p: "16px"
          },
        }}
        title="Remark"
        content={
          <Stack sx={{
            gap: "20px",
            pt: "20px",

          }}>
            <Typography variant="subtitle2">
              {state.moreTexts}
            </Typography>
          </Stack>}
      />
    </>
  );
};

export default HistoryList;
