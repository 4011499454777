import React from "react";
import { Typography } from "@mui/material";

export const SettlementDescription = (prop) => {
  const { bill_day, bill_duration, bill_period, bill_date, globalData } = prop;
  const toOrdinalSuffix = (bill_duration) => {
    const int = parseInt(bill_duration),
      digits = [int % 10, int % 100],
      ordinals = ["st", "nd", "rd", "th"],
      oPattern = [1, 2, 3, 4],
      tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
      ? int + ordinals[digits[0] - 1]
      : int + ordinals[3];
  };
  const options = [
    {
      label: "First",
      value: 1,
    },
    {
      label: "Second",
      value: 2,
    },
    {
      label: "Third",
      value: 3,
    },
    {
      label: "Fourth",
      value: 4,
    },
    {
      label: "Fifth",
      value: 5,
    },
  ];
  return (
    <>
      {bill_period === 1 && (
        <>
          <Typography color="#171717" fontWeight={600}>
            {
              globalData.client_accounts?.bill_day.find(
                (i) => i.value === bill_day
              )?.label
            }
          </Typography>
          <Typography> of every</Typography>
          <Typography color="#171717" fontWeight={600}>
            {bill_duration}
          </Typography>
          <Typography color="#171717" fontWeight={600}>
            weeks
          </Typography>
        </>
      )}
      {bill_period === 3 && (
        <>
          <Typography>Every</Typography>
          {bill_duration !== 1 && (
            <Typography color="#171717" fontWeight={600}>
              {bill_duration}
            </Typography>
          )}
          <Typography color="#171717" fontWeight={600}>
            {bill_duration !== 1 ? "Days" : "Day"}
          </Typography>
        </>
      )}
      {bill_period === 2 && bill_date && bill_day && (
        <>
          <Typography color="#171717" fontWeight={600}>
            {options.find((i) => i.value === bill_date)?.label}
          </Typography>
          <Typography color="#171717" fontWeight={600}>
            {
              globalData?.client_accounts?.bill_day.find(
                (i) => i.value === bill_day
              )?.label
            }
          </Typography>
          <Typography>of every</Typography>
          <Typography color="#171717" fontWeight={600}>
            {bill_duration === 0 ? "" : bill_duration}
          </Typography>
          <Typography color="#171717" fontWeight={600}>
            Months
          </Typography>
        </>
      )}
      {bill_period === 2 && bill_date && bill_day === null && (
        <>
          <Typography color="#171717" fontWeight={600}>
            {toOrdinalSuffix(bill_date)}
          </Typography>
          <Typography> day of every</Typography>
          <Typography color="#171717" fontWeight={600}>
            {bill_duration === 0 ? "" : bill_duration}
          </Typography>
          <Typography color="#171717" fontWeight={600}>
            Months
          </Typography>
        </>
      )}
    </>
  );
};
