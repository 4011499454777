import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import PaymentMediaReportList from "./containers/PaymentMediaReportList";

export const PaymentMediaReport = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute allowedRoute={["payment-media-view"]} />}>
        <Route path="/" element={<PaymentMediaReportList />} />
      </Route>
    </Routes>
  );
};
