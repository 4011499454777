import * as React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  FormControl,
  Button,
  Stack,
  InputAdornment,
  OutlinedInput,
  Box,
  Checkbox,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Loader } from "../../../shared";

export const CategoryProductDialoginMenuTemplate = (props) => {
  const {
    Items,
    onClearSearch,
    onSearch,
    onSelect,
    disabled,
    onSubmit,
    selectedItems,
    existedProductList,
    isLoading,
  } = props;
  const [value, setValue] = React.useState("");
  return (
    <>
      <Stack
        justifyContent="space-between"
        height="100%"
        gap="8px"
        maxHeight={"calc(100% - 24px)"}
      >
        <FormControl
          size="small"
          fullWidth={true}
          sx={{
            pt: "24px",
          }}
        >
          <OutlinedInput
            placeholder="Search"
            sx={{
              height: "48px",
              ".MuiOutlinedInput-input": {
                pl: "0px !important",
              },
            }}
            size="small"
            value={value}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon size="large" />
              </InputAdornment>
            }
            endAdornment={
              value &&
              onSearch && (
                <InputAdornment
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setValue("");
                    onSearch("search", "");
                  }}
                  position="end"
                >
                  <CloseIcon size="large" />
                </InputAdornment>
              )
            }
            fullWidth
            onChange={(e) => {
              setValue(e.target.value);
              onSearch("search", e.target.value);
            }}
          />
        </FormControl>
        {isLoading ? (
          <Loader />
        ) : (
          <Box sx={{ height: "100%", overflow: "auto" }} fullWidth>
            {Items.length > 0 ? (
              <List sx={{ width: "100%", py: 0, bgcolor: "background.paper" }}>
                {Items.map((value) => {
                  const labelId = `checkbox-list-label-${value}`;

                  return (
                    <>
                      {value.products.length === 0 ? (
                        ""
                      ) : (
                        <ListOfProduct
                          value={value}
                          labelId={labelId}
                          onSelect={onSelect}
                          selectedItems={selectedItems}
                          alReadyIDs={existedProductList.map((e, i) => {
                            return e.product_id;
                          })}
                        />
                      )}
                    </>
                  );
                })}
              </List>
            ) : (
              <Stack justifyContent="center" alignItems="center" height="100%">
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="caption"
                  color="text.secondary"
                  fontWeight={400}
                >
                  There is no products found
                </Typography>
              </Stack>
            )}
          </Box>
        )}
        <Button
          sx={{ height: "48px" }}
          variant="contained"
          fullWidth
          disabled={!selectedItems.length || disabled}
          onClick={onSubmit}
        >
          Save
        </Button>
      </Stack>
    </>
  );
};

function ListOfProduct({
  value,
  labelId,
  onSelect,
  selectedItems,
  alReadyIDs,
}) {
  const [open, setOpen] = React.useState(false);
  const isSelectedCheck = (value) => {
    let allIDs = selectedItems.concat(alReadyIDs);
    let aa = value.products?.map((e, i) => {
      return e.id;
    });
    let a = value.products
      ?.map((e, i) => {
        return e.id;
      })
      .every((val) => allIDs.includes(val));
    return value.products
      ?.map((e, i) => {
        return e.id;
      })
      .every((val) => allIDs.includes(val));
  };

  const isCheckIndeterminate = (data) => {
    const subArray = data.products.map((e, i) => e.id);
    const commonIDs1 = selectedItems.filter((val) => subArray.includes(val));
    const commonIDs2 = alReadyIDs.filter((val) => subArray.includes(val));

    let commonIDs = commonIDs1.concat(commonIDs2);
    const notAllIDs = subArray.every((val) => commonIDs.includes(val));

    return commonIDs.length > 0 && !notAllIDs;
  };

  const isSelected = (id) => {
    if (alReadyIDs.includes(id)) {
      return true;
    } else {
      return selectedItems.includes(id);
    }
  };

  const isDisabled = (value) => {
    return alReadyIDs.includes(value);
  };

  return (
    <Box
      sx={{
        backgroundColor: open ? "rgba(21, 96, 212, 0.08)" : "",
        borderRadius: "8px",
        mb: 0.5,
      }}
    >
      <ListItem key={value} disablePadding>
        <ListItemButton
          role={undefined}
          dense
          sx={{
            "&:hover": {
              backgroundColor: "rgba(21, 96, 212, 0.08)",
              borderRadius: "8px",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: "32px",
            }}
          >
            <Checkbox
              edge="start"
              disabled={isDisabled(value)}
              checked={isSelectedCheck(value)}
              indeterminate={isCheckIndeterminate(value)}
              onChange={(e) => {
                onSelect("head", value, e);
                setOpen(true);
              }}
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
            />
          </ListItemIcon>
          <ListItemText
            id={labelId}
            primary={value.name}
            onClick={() => setOpen(!open)}
            sx={{
              "& .MuiTypography-root": {
                fontWeight: 600,
              },
            }}
          />
          {value.products?.length > 0 && (
            <img
              src="/icons/ic_dropdown.svg"
              style={{
                transform: !open ? "rotate(180deg)" : "",
              }}
              onClick={() => setOpen(!open)}
            />
          )}
        </ListItemButton>
      </ListItem>
      {open &&
        value.products.map((v) => {
          return (
            <ListItemButton
              role={undefined}
              dense
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(21, 96, 212, 0.08)",
                  borderRadius: "8px",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "32px",
                  marginLeft: "12px",
                }}
              >
                <Checkbox
                  edge="start"
                  checked={isSelected(v.id)}
                  disabled={isDisabled(v.id)}
                  defaultChecked={true}
                  // tabIndex={-1}
                  onChange={(e) => onSelect("body", v, e)}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={v.name} />
            </ListItemButton>
          );
        })}
    </Box>
  );
}
export default ListOfProduct;
