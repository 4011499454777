import {
  CustomTable,
  CustomeDialog,
  DeleteModal,
  usePermission,
  Loader,
  CheckBoxListDialog,
} from "../../../shared";
import * as React from "react";
import { Button, FormHelperText, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import { sortItems } from "../../../utils";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InputControl from "../../../shared/components/InputControl";
import { LedgerAccountDialog } from "./LedgerAccountDialog";
import { useLedgerAccounts } from "../hooks";
import SimpleReactValidator from "simple-react-validator";

export const LedgerAccountsList = ({ type }) => {
  const { cid, id } = useParams()

  const {
    state,
    handleModal,
    handleDeleteLedger,
    handleCheckAccount,
    onSaveLedger,
    onUpdateLedger,
    handleChecked,
    onSearch,
    onEditButton,
    handleInputChange,
    handleDisable
  } = useLedgerAccounts({ id: cid || id, type: type })

  const { isLoading, dataList, AddDataIDs, searchText, isSaveButton, dataListIDs, isViewEdit } = state
  const { isDelete, isOpen, coa, isEdit } = state

  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        isEqual: {
          required: true,
          message: "The Total Split % should be 100",
          rule: ({ data }) => {
            // const { max_choice, min_choice } = value;
            let sum = data.map((val) => {
              return val.split_percentage
            }).reduce((a, b) => Number(a) + Number(b), 0)

            return sum === 100
          },
        },
        isSplit: {
          required: true,
          message: "Split must be between 0.01 and 100.00. Only two decimal places are allowed.",
          rule: (value) => {
            return /^(?:100(?:\.00?)?|\b(?:0\.\d{1,2}|[1-9]\d?(?:\.\d{1,2})?)\b)$/.test(value);
          },
        },
      },
    })
  );

  const onSubmit = () => {
    if (validator.current.allValid()) {
      onUpdateLedger()
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const navigate = useNavigate();
  const isDeletable = usePermission("ledger-delete");
  const isCreate = usePermission("ledger-create");
  const isModify = usePermission("ledger-modify");

  const ActionButtons = ({ deleteAction }) => {
    return (
      <div className="action-buttons">
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Account Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Account Code",
        accessor: "code",
        disableSortBy: true,
      },
      {
        Header: "Split %",
        accessor: "split_percentage",
        Cell: ({ value, row }) => {
          return isEdit ? (
            <InputControl
              type="outlinedInput"
              inputType="number"
              onChange={(e) => {
                handleInputChange(e, row.original.account_id)
              }}
              name="split_percentage"
              value={value}
              error={validator.current.message(
                `Split % ${` `.repeat(row.index)}`,
                value,
                "isSplit"
              )}
              helperText={validator.current.message(
                "Split %",
                value,
                "isSplit"
              )}
            />
          ) : <span> {value}</span>
        },
        disableSortBy: true,
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              deleteAction={() => {
                handleModal("ledger-delete", true, row.id)
              }}
            />
          );
        },
      },
    ],
    [isEdit]
  );


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CustomTable
            columns={columns}
            data={dataList}
            searchBar={false}
            tableTitle="Account"
            pagination={false}
            footerContent={
              isEdit ? (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                >
                  <FormHelperText sx={{ color: "#C60808" }}>
                    {validator.current.message(
                      "Totl Split %",
                      { data: state.dataList },
                      "isEqual"
                    )}
                  </FormHelperText>
                  <Stack direction="row" gap="16px">
                    <Button
                      variant="outlined"
                      sx={{
                        border: "none",
                        "&:hover": {
                          border: "none",
                        },
                      }}
                      onClick={() => onEditButton(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={onSubmit}
                    >
                      Save
                    </Button>
                  </Stack>
                </Stack>) : null
            }
            headerRightContent={
              <Stack direction="row" gap="16px">
                {(isModify && isViewEdit) && (<>
                  {!isEdit && (
                    <Button
                      type="button"
                      size="medium"
                      variant="text"
                      startIcon={<EditOutlinedIcon />}
                      onClick={() => onEditButton(true)}
                    >
                      Edit
                    </Button>
                  )}
                </>)}
                {isCreate && (
                  <Button
                    type="button"
                    size="medium"
                    variant="text"
                    startIcon={<AddIcon />}
                    onClick={() => handleModal("ledger-add", true)}
                  >
                    Add
                  </Button>
                )}
              </Stack>
            }
          />
          <CustomeDialog
            open={isOpen}
            handleClose={() => handleModal("ledger-add", false)}
            PaperProps={{
              sx: { width: "686px", height: "687px", p: "16px" },
            }}
            title="Accounts"
            content={
              <LedgerAccountDialog
                dataList={coa.filteredList}
                loading={coa.isLoading}
                selectedIDs={AddDataIDs}
                handleCheckAccount={handleCheckAccount}
                onSubmit={onSaveLedger}
                handleChecked={handleChecked}
                onSearch={onSearch}
                searchText={searchText}
                isSaveButton={isSaveButton}
                dataListIDs={dataListIDs}
                handleDisable={handleDisable}
              />
            }
          />
          <DeleteModal
            open={isDelete}
            handleClose={() => handleModal("ledger-delete", false, null)}
            onConfirm={handleDeleteLedger}
          />
        </>
      )}
    </>
  );
};
