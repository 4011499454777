import { Stack, Typography, Box } from "@mui/material";
import React, { useRef, useCallback } from "react";
import Union from "../../../assets/images/Icons/Union.png";
import SimpleReactValidator from "simple-react-validator";
import { useDropzone } from "react-dropzone";
import { errorMessage } from "../../../utils";

export const DropzoneComponents = (props) => {
  const { width, aspectRatio, handleDropzone, imgUrl } = props;

  const maxSizeValidator = (file) => {
    const maxSize = 1048576;
    if (file.size > maxSize) {
      errorMessage("The Image Size should be less than 1MB");
      return true;
    }
    return null;
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.length > 0 && handleDropzone(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxFiles: 1,
    validator: maxSizeValidator,
  });

  return (
    <>
      <Box
        sx={{
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          justifyItems: "center",
          cursor: "pointer",
          height: 200,
          backgroundColor: "#F2F2F2 ",
          overflow: "hidden",
          aspectRatio: aspectRatio,
        }}
      >
        <section className="container" style={{ width: "100%" }}>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            {imgUrl ? (
              <Box
                component={"img"}
                width="100%"
                height={200}
                aspect
                sx={{ maxHeight: "200px", objectFit: "cover" }}
                src={imgUrl && imgUrl}
              />
            ) : (
              <Stack
                height={200}
                // width={width}
                sx={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={Union} />

                <p>Image Square</p>
                <p>Drag & Drop Image</p>
                <p>Or Click Here</p>
              </Stack>
            )}
          </div>
        </section>
      </Box>
      <Typography variant="body2" color="#808080" mt={1}>
        Upload an image with {aspectRatio.replace("/", ":")} ratio and less than
        1MB.
      </Typography>
    </>
  );
};
