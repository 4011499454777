import React from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import {
  AdminProtectedRoute,
  ErrorFallback,
  Theme,
  UnAuthorisedMaster,
} from "../shared";
import { Dashboard, StoreAdminDashboard } from "./containers";
import { Authentication, Invitation, SalesScreenLayout } from "../modules";
import { AppScopeProvider, AuthProvider, TableScopeProvider } from "../store";
import { ErrorBoundary } from "react-error-boundary";

export function Master() {
  return (
    <>
      <ErrorBoundary
        fallbackRender={ErrorFallback}
        onReset={(details) => {
          // Reset the state of your app so the error doesn't happen again
        }}
      >
        <ThemeProvider theme={Theme}>
          <CssBaseline />
          <AuthProvider>
            <AppScopeProvider>
              <TableScopeProvider>
                <Routes>
                  <Route
                    element={<Authentication />}
                    path={"/authentication/*"}
                  />
                  <Route element={<Invitation />} path={"/invitation/*"} />
                  <Route element={<AdminProtectedRoute allowedRole="admin" />}>
                    <Route exact element={<Dashboard />} path={"/*"} />
                    <Route
                      exact
                      element={<SalesScreenLayout />}
                      path={"/sales-screen-layout/*"}
                    />
                  </Route>
                  <Route
                    element={<AdminProtectedRoute allowedRole="store-admin" />}
                  >
                    <Route
                      exact
                      element={<StoreAdminDashboard />}
                      path={"/store-admin/*"}
                    />
                  </Route>
                  <Route
                    exact
                    element={<UnAuthorisedMaster />}
                    path={"/unauth"}
                  />
                </Routes>
              </TableScopeProvider>
            </AppScopeProvider>
          </AuthProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
}
