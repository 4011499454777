import { API } from "../../../utils";

export const getMenuTemplate = async (query) => {
  const res = await API.get("menus", query);
  return res.data;
};

export const createMenuTemplate = async (payload) => {
  const res = await API.post(`menus`, payload);
  return res.data;
};
export const getMenuTemplateById = async (id) => {
  const res = await API.get(`menus/${id}`);
  return res.data;
};
export const deleteMenuTemplateById = async (id) => {
  const res = await API.delete(`menus/${id}`);
  return res.data;
};
export const updateMenuTemplate = async (id, payload) => {
  const res = await API.put(`menus/${id}`, payload);
  return res.data;
};
export const getMenuTemplateCategory = async (id, query) => {
  const res = await API.get(`menus/${id}/categories`, query);
  return res.data;
};
export const changeCategoryOrderMenuTemplate = async (id, payload) => {
  const res = await API.post(`menus/${id}/categories-change-order`, payload);
  return res.data;
};
export const deleteCategoryMenuTemplateById = async (id, cid) => {
  const res = await API.delete(`menus/${id}/categories/${cid}`);
  return res.data;
};
export const createMenuTemplateCategory = async (id, payload) => {
  const res = await API.post(`menus/${id}/categories`, payload);
  return res.data;
};
export const editMenuTemplateCategory = async (id, cid, payload) => {
  const res = await API.post(`menus/${id}/categories/${cid}`, payload);
  return res.data;
};
export const getCategoryMenuTemplateById = async (id, cid) => {
  const res = await API.get(`menus/${id}/categories/${cid}`);
  return res.data;
};
export const getMenuTemplateProduct = async (id, cid, query) => {
  const res = await API.get(`menus/${id}/categories/${cid}/products`, query);
  return res.data;
};
export const changeProductOrderMenuTemplate = async (id, cid, payload) => {
  const res = await API.post(
    `menus/${id}/categories/${cid}/products-change-order`,
    payload
  );
  return res.data;
};
export const deleteProductMenuTemplateById = async (id, cid, pid) => {
  const res = await API.delete(`menus/${id}/categories/${cid}/products/${pid}`);
  return res.data;
};
export const assignPoductToMenuTemplate = async (payload, id, cid) => {
  const res = await API.post(`menus/${id}/categories/${cid}/products`, payload);
  return res.data;
};
export const getCategorywiseProductsMenuTemplate = async (params) => {
  const res = await API.get(`product/list`, params);
  return res.data;
};
