import {
  ContentLayout,
  DeleteModal,
  DraggableReactTable,
  usePermission,
} from "../../../shared";
import * as React from "react";
import { Avatar, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useCFD } from "../hooks/useCFD";
import { useNavigate } from "react-router-dom";

export const CFDlist = () => {
  const { state, handleModal, handleDeleteCFD, onCFDOrderChange } = useCFD({
    isList: true,
  });
  const { isLoading, dataList, isDelete } = state;

  const navigate = useNavigate();
  const isCreate = usePermission("cfd-create");
  const isDeletable = usePermission("cfd-delete");
  const isEdit = usePermission("cfd-modify");
  const ActionButtons = ({ value, id }) => {
    return (
      <div className="action-buttons">
        {isDeletable && (
          <img
            alt="delete"
            onClick={() => handleModal("cfd-delete", true, id)}
            src="/icons/ic_delete.svg"
          />
        )}
        <img
          alt="details"
          onClick={() => navigate(`edit/${id}`)}
          src="/icons/arrow_forward.svg"
        />
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "No.",
        accessor: "title",
        Cell: ({ row }) => {
          return <span>{row.index + 1}</span>;
        },
        padding: "checkbox",
      },
      {
        Header: "Photo",
        accessor: "image",
        Cell: ({ row }) => {
          return (
            <Avatar variant="rounded">
              <img src={row.original.image1} style={{ height: "100%" }} />
            </Avatar>
          );
        },
      },
      {
        Header: "Action",
        id: "id",
        accessor: "id",
        align: "right",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return <ActionButtons value={row} id={value} />;
        },
      },
    ],
    []
  );

  return (
    <>
      <ContentLayout
        title="CFD Configuration"
        rightContent={
          <>
            {isCreate && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("create")}
              >
                Add New
              </Button>
            )}
          </>
        }
      >
        <>
          <DraggableReactTable
            columns={columns}
            data={dataList}
            isLoading={isLoading}
            defaultSearch={false}
            searchBar={false}
            onRowOrderChange={onCFDOrderChange}
            disableDrag={!isEdit}
            maxHeight="210px"
          />
          <DeleteModal
            open={isDelete}
            handleClose={() => handleModal("cfd-delete", false, null)}
            onConfirm={handleDeleteCFD}
          />
        </>
      </ContentLayout>
    </>
  );
};
