import { API } from "../../../utils";

export const getAllProducts = async () => {
  const params = { product_types: ["bundle", "platter", "retail"] };
  const res = await API.get(`variant_product/list?include_parent=1`, params);
  return res.data;
};

export const createGrid = async (payload) => {
  const res = await API.post("keypads", payload);
  return res.data;
};

export const updateGrid = async (payload, id) => {
  const res = await API.put(`keypads/${id}`, payload);
  return res.data;
};

export const getDetails = async (id) => {
  const res = await API.get(`keypads/${id}`);
  return res.data;
};
