import {
  CustomeDialog,
  HeaderBreadcrumbs,
  Loader,
  PageNotFound,
} from "../../../shared";
import { ContentLayout } from "../../../shared";
import React, { useRef } from "react";
import {
  Button,
  Stack,
  Card,
  Typography,
  Box,
  Avatar,
  Grid,
  TextField,
  Divider,
  Switch,
  FormHelperText,
} from "@mui/material";
import Avadar_img from "../../../assets/images/Icons/Avadar_img.png";
import { useParams } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { useStore } from "../hooks/useStore";
import SimpleReactValidator from "simple-react-validator";
import { useFranchisee } from "../../Franchisee/hooks/useFranchisee";
import { GoogleMapComponent } from "../components";
import { AsyncPaginate } from "react-select-async-paginate";

export const AddOrEditStore = () => {
  const { id } = useParams();
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        IconFormat: {
          message: "The Image  should be png, jpg, jpeg",
          rule: (value) => {
            if (
              ![
                "image/png",
                "image/jpeg",
                "image/jpg",
                "image/svg",
                "image/svg+xml",
                "image/gif",
              ].includes(value.type)
            ) {
              return false;
            }
          },
        },
        IconSize: {
          message: "The Image Size should be less than 1MB",
          rule: (value) => {
            const maxSize = 1048576; //1mb
            if (value?.size > maxSize) {
              return false;
            }
          },
        },
      },
    })
  );
  const AlbumArtRef = useRef();
  const {
    states,
    onChange,
    onSaveState,
    handleDropdown,
    onDropdown,
    onSwitchChange,
    onCityDropdown,
    onStateDropdown,
    onFileChange,
    onTimezoneDropdown,
    onUpdateStore,
    toggleMapModal,
    onPlaceChange,
    handleSuburbList,
    globalData,
  } = useStore({
    storeId: id,
  });
  const { state } = useFranchisee({ isFranchisee: true });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: "2px solid #E6E8F0",
      height: "48px",
    }),
    option: (provided, state) => ({
      ...provided,
      maxHeight: "239px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
  };

  const options = state?.franchiseeDetails?.franchisee?.map((item) => ({
    name: item.name,
    id: item.id,
  }));

  const onLogoClick = () => {
    AlbumArtRef.current.click();
  };
  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdateStore(id) : onSaveState();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
    if (states.httpStatusCodes.details === 404) {
      return <PageNotFound />;
    }
  };
  return (
    <>
      <ContentLayout
        backImage={false}
        title={id ? states.name : "New Store"}
        active={id ? (states.status === 0 ? "Inactive" : "Active") : ""}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              {
                name: "Stores",
                href: "/stores",
              },
              id
                ? {
                    name: states.name,
                    href: `/stores/details/${id}`,
                  }
                : null,
              { name: id ? "Edit" : "Create" },
            ].filter(Boolean)}
          />
        }
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              disabled={states.isSaveButtonBusy}
              onClick={() => onSubmit()}
            >
              {id ? "Update" : "Save"}
            </Button>
          </>
        }
      >
        {states.isBusy ? (
          <Loader />
        ) : (
          <Box>
            <Card sx={{ p: "32px 24px", mb: "24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Basic Details
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack width={"100%"} gap="20px">
                    <Stack direction="row" gap="20px">
                      <Avatar
                        sx={{ height: "80px", width: "80px" }}
                        variant="rounded"
                      >
                        <img
                          width="80px"
                          height="80px"
                          src={
                            states.iconFile
                              ? URL.createObjectURL(states.iconFile)
                              : id
                              ? states.data.icon
                              : Avadar_img
                          }
                        />
                      </Avatar>
                      <div>
                        <input
                          id="contained-button-file"
                          error={validator.current.message(
                            "Store name",
                            states.data.icon,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "Store name",
                            states.data.icon,
                            "required"
                          )}
                          required
                          type="file"
                          onChange={(e) => onFileChange(e)}
                          style={{ display: "none" }}
                          ref={AlbumArtRef}
                        />
                        <Button
                          color="info"
                          component="span"
                          sx={{ mt: 2 }}
                          onClick={(e) => onLogoClick(e)}
                        >
                          {id ? "Change" : "Upload"}
                        </Button>
                      </div>
                    </Stack>
                    <Stack>
                      <Typography variant="body2" color="#808080" mt={1}>
                        Upload an image with 1:1 ratio and less than 1MB.
                      </Typography>
                      <FormHelperText sx={{ color: "#C60808" }}>
                        {validator.current.message(
                          "Image",
                          states.iconFile,
                          "IconSize|IconFormat"
                        )}
                      </FormHelperText>
                    </Stack>

                    <InputControl
                      name="name"
                      label="Store Name"
                      value={states.data.name}
                      onChange={(e) => onChange(e)}
                      error={validator.current.message(
                        "Store name",
                        states.data.name,
                        "required|min:3"
                      )}
                      helperText={validator.current.message(
                        "Store name",
                        states.data.name,
                        "required|min:3"
                      )}
                      required
                    />
                    <InputControl
                      name="abn"
                      label="ABN"
                      value={states.data.abn}
                      onChange={(e) => onChange(e)}
                      error={validator.current.message(
                        "abn",
                        states.data.abn,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "abn",
                        states.data.abn,
                        "required"
                      )}
                      required
                    />
                    <Stack direction="row" gap="20px">
                      <InputControl
                        name="email"
                        label="Store Owner Email"
                        value={states.data.email}
                        onChange={(e) => onChange(e)}
                        error={validator.current.message(
                          "Store Owner Email",
                          states.data.email,
                          "required|email"
                        )}
                        helperText={validator.current.message(
                          "Store Owner Email",
                          states.data.email,
                          "required|email"
                        )}
                        required
                      />
                      <InputControl
                        name="contact_email"
                        label="Store Email"
                        value={states.data.contact_email}
                        onChange={(e) => onChange(e)}
                        error={validator.current.message(
                          "Store Email",
                          states.data.contact_email,
                          "required|email"
                        )}
                        helperText={validator.current.message(
                          "Store Email",
                          states.data.contact_email,
                          "required|email"
                        )}
                        required
                      />
                    </Stack>

                    <Stack direction="row" gap="20px">
                      <InputControl
                        disableClearable
                        name="country_code"
                        type="dropdown"
                        options={globalData.country_codes ?? []}
                        getOptionLabel={(option) => option.label}
                        value={
                          (globalData?.country_codes?.length > 0 &&
                            states.data?.country_code &&
                            globalData?.country_codes?.find(
                              (c) => c.value === states.data.country_code
                            )) ||
                          null
                        }
                        onChange={(e, newValue) => {
                          handleDropdown(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country code"
                            error={validator.current.message(
                              "country_code",
                              states.data.country_code,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "country_code",
                              states.data.country_code,
                              "required"
                            )}
                            required
                          />
                        )}
                      />

                      <InputControl
                        name="phone"
                        label="Phone Number"
                        type="text"
                        onChange={(e) => onChange(e)}
                        value={states.data.phone}
                        error={validator.current.message(
                          "phone",
                          states.data.phone,
                          "required|phone|max:10"
                        )}
                        helperText={validator.current.message(
                          "phone",
                          states.data.phone,
                          "required|phone|max:10"
                        )}
                        required
                      />
                    </Stack>

                    <InputControl
                      name="franchisee_id"
                      type="dropdown"
                      multiple
                      className="tags-input"
                      options={options}
                      getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      value={states.data.franchisee_id}
                      onChange={(e, newValue) => {
                        onDropdown(newValue);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Franchisee Group"
                          error={validator.current.message(
                            "Franchisee Group",
                            states.data.franchisee_id,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "Franchisee Group",
                            states.data.franchisee_id,
                            "required"
                          )}
                          required
                        />
                      )}
                    />

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      py="10px"
                    >
                      <Typography variant="subtitle1" fontWeight={600}>
                        Store Status
                      </Typography>
                      <Switch
                        checked={+states.data.status}
                        onChange={(loading) => onSwitchChange(loading)}
                        name={states.data.status}
                        color="primary"
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
            <Card sx={{ p: "32px 24px", mb: "24px", overflow: "inherit" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Address
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack width={"100%"} gap="20px">
                    <InputControl
                      name="address"
                      label="Address"
                      value={states.data.address}
                      onChange={(e) => onChange(e)}
                      error={validator.current.message(
                        "address",
                        states.data.address,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "address",
                        states.data.address,
                        "required"
                      )}
                      required
                    />
                    <Stack direction="row" gap="20px">
                      <InputControl
                        name="unit_number"
                        label="Unit Number"
                        value={states.data.unit_number}
                        onChange={(e) => onChange(e)}
                      />
                      <InputControl
                        name="street_number"
                        label="Street Number"
                        value={states.data.street_number}
                        onChange={(e) => onChange(e)}
                        error={validator.current.message(
                          "Sreat Number",
                          states.data.street_number,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Sreat Number",
                          states.data.street_number,
                          "required"
                        )}
                        required
                      />
                    </Stack>
                    <Stack direction="row" gap="20px">
                      <Box width="100%">
                        <AsyncPaginate
                          debounceTimeout={1000}
                          placeholder="Suburb *"
                          menuPlacement="bottom"
                          value={states.data?.city_id}
                          getOptionValue={(e) => e.id}
                          getOptionLabel={(e) => e.name}
                          loadOptions={async (e, p, { page }) => {
                            return await handleSuburbList({
                              search: e,
                              page,
                              type: "suburb",
                            });
                          }}
                          onChange={(e) => {
                            onCityDropdown(e);
                          }}
                          additional={{
                            page: 1,
                          }}
                          styles={customStyles}
                        />
                        <FormHelperText sx={{ color: "#C60808" }}>
                          {validator.current.message(
                            "Suburb Name",
                            states.data.city_id,
                            "required"
                          )}
                        </FormHelperText>
                      </Box>
                      <Box width="100%">
                        <AsyncPaginate
                          debounceTimeout={1000}
                          placeholder="State *"
                          menuPlacement="bottom"
                          value={states.data.state_id}
                          getOptionValue={(e) => e.id}
                          getOptionLabel={(e) => e.name}
                          loadOptions={async (e, p, { page }) => {
                            return await handleSuburbList({
                              search: e,
                              page,
                              type: "state",
                            });
                          }}
                          onChange={(e) => {
                            onStateDropdown(e);
                          }}
                          additional={{
                            page: 1,
                          }}
                          styles={customStyles}
                        />
                        <FormHelperText sx={{ color: "#C60808" }}>
                          {validator.current.message(
                            "State Name",
                            states.data.state_id,
                            "required"
                          )}
                        </FormHelperText>
                      </Box>
                    </Stack>
                    <Stack direction="row" gap="20px">
                      <InputControl
                        type="number"
                        name="zip_code"
                        label="Post Code"
                        value={states.data.zip_code}
                        onChange={(e) => onChange(e)}
                        error={validator.current.message(
                          "Post Code",
                          states.data.zip_code,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Post Code",
                          states.data.zip_code,
                          "required"
                        )}
                        required
                      />
                      <InputControl
                        disableClearable
                        name="timezone"
                        type="dropdown"
                        options={globalData.timezones ?? []}
                        getOptionLabel={(option) => option.label}
                        value={
                          (globalData?.timezones?.length > 0 &&
                            states.data?.timezone &&
                            globalData?.timezones?.find(
                              (c) => c.value === states.data?.timezone
                            )) ||
                          null
                        }
                        onChange={(e, newValue) => {
                          onTimezoneDropdown(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            label="Time Zone"
                            {...params}
                            error={validator.current.message(
                              "Time Zone",
                              states.data.timezone,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Time Zone",
                              states.data.timezone,
                              "required"
                            )}
                            required
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 3 }} />
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" fontWeight={600}>
                    Latitude And Longitude
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack width={"100%"} gap="20px">
                    <Stack direction="row" gap="20px">
                      <InputControl
                        name="latitude"
                        label="Latitude"
                        value={states.data.latitude}
                        onChange={(e) => onChange(e)}
                        error={validator.current.message(
                          "Latitude",
                          states.data.latitude,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Latitude",
                          states.data.latitude,
                          "required"
                        )}
                        required
                      />
                      <InputControl
                        name="longitude"
                        label="Longitude"
                        value={states.data.longitude}
                        onChange={(e) => onChange(e)}
                        error={validator.current.message(
                          "Longitude",
                          states.data.longitude,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Longitude",
                          states.data.longitude,
                          "required"
                        )}
                        required
                      />
                      <Grid item xs={4} md={3} sx={{ width: "131px" }}>
                        <Button
                          onClick={() => toggleMapModal()}
                          sx={{ width: "131px" }}
                          variant="text"
                        >
                          Pick From Map
                        </Button>
                      </Grid>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </Box>
        )}
      </ContentLayout>

      <CustomeDialog
        open={states.isMapOpen}
        handleClose={toggleMapModal}
        closeIcon={false}
        size="xl"
        PaperProps={{
          sx: {
            minWidth: "423px",
            minHeight: "423px",
            p: "30px",
          },
        }}
        content={
          <GoogleMapComponent
            states={states}
            onPlaceChange={onPlaceChange}
            toggleMapModal={toggleMapModal}
          />
        }
      />
    </>
  );
};
