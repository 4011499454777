import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  TaxGroupsAndTaxesList,
  CreateOrEditTaxGroup,
  CreateOrEditTaxes,
  TaxGroupsDetails,
} from "..";
import { ProtectedRoute } from "../../shared";

export const TaxGroupsAndTaxes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<TaxGroupsAndTaxesList />} />

        <Route element={<ProtectedRoute allowedRoute={["tax-view"]} />}>
          <Route
            path="/tax-groups/details/:id"
            element={<TaxGroupsDetails />}
          />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["tax-create"]} />}>
          <Route path="/tax-groups/create" element={<CreateOrEditTaxGroup />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["tax-modify"]} />}>
          <Route
            path="/tax-groups/edit/:id"
            element={<CreateOrEditTaxGroup />}
          />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["tax-create"]} />}>
          <Route path="/taxes/create" element={<CreateOrEditTaxes />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["tax-modify"]} />}>
          <Route path="/taxes/edit/:id" element={<CreateOrEditTaxes />} />
        </Route>
      </Routes>
    </>
  );
};
