import * as React from "react";
import { Button, Chip, Typography } from "@mui/material";
import { CustomDetailsList, Loader, usePermission } from "../../../shared";
import { useNavigate, useParams } from "react-router-dom";

export const BasicDetailsList = (props) => {
  const isEditable = usePermission("user-modify");

  const navigate = useNavigate();
  const { id } = useParams();
  const { userDetails, isStoreAdmin, timezones } = props;
  const { isLoading, details } = userDetails;

  const findTimezone = (v) => {
    return timezones?.find(x => x.value === v)?.label;
  }

  const columns = [
    {
      title: "Name",
      accessor: "name",
    },
    {
      title: "Email",
      accessor: "email",
    },
    {
      title: "Phone Number",
      accessor: (val) => {
        return (
          <Typography variant="subtitle2" fontWeight={400} color="#636363">
            {val.phone_formatted}
          </Typography>
        );
      },
    },
    {
      title: "Time zone",
      accessor: (val) => {
        return (
          <Typography variant="subtitle2" fontWeight={400} color="#636363">
            {findTimezone(val.timezone)}
          </Typography>
        );
      },
    },
    {
      title: "Status",
      accessor: (val) => {
        return val.status === 1 ? (
          <Chip
            label="Active"
            color="success"
            variant="outlined"
            size="small"
          />
        ) : (
          <Chip
            label="Inactive"
            color="success"
            variant="outlined"
            size="small"
            disabled
          />
        );
      },
    },
  ];

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <CustomDetailsList
          columns={columns}
          data={details}
          title="Basic Details"
          headerRightContent={
            <>
              {isEditable && (
                <Button
                  size="medium"
                  variant="contained"
                  onClick={() =>
                    navigate(
                      isStoreAdmin
                        ? `/store-admin/admin-users/edit/${id}`
                        : `/admin-users/edit/${id}`
                    )
                  }
                >
                  Edit
                </Button>
              )}
            </>
          }
        />
      )}
    </>
  );
};
