import React from "react";
import {
  CheckBoxListDialog,
  CustomeDialog,
  CustomTable,
  DeleteModal,
  Loader,
} from "../../../shared";
import { Button } from "@mui/material";
import { sortItems } from "../../../utils";
import AddIcon from "@mui/icons-material/Add";
import { useFavourites } from "../hooks";
import { useParams } from "react-router-dom";

export const FavouritesStoreList = () => {
  const { id } = useParams();
  const {
    state: {
      storesTab: {
        storesList,
        isLoading,
        filteredStores,
        searchText,
        selectedStores,
        isOpen,
        isDelete,
        isSaveButton,
      },
    },
    HandleFilterStores,
    handleStoreSelect,
    handleAddStoreModal,
    handleAssignStores,
    toggleStoreDeleteModal,
    onConfirmStoreDelete,
    hasModify,
  } = useFavourites({
    isFav: false,
    favId: null,
    isProducts: false,
    isStores: id,
  });

  const ActionButtons = ({ deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {hasModify && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Store Name",
        accessor: "name",
        id: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Action",
        disableGlobalFilter: true,
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              deleteAction={() => {
                toggleStoreDeleteModal(row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <CustomTable
          columns={columns}
          data={storesList}
          searchBar={false}
          tableTitle="Stores"
          headerRightContent={
            <>
              {hasModify && (
                <Button
                  type="button"
                  size="medium"
                  variant="text"
                  startIcon={<AddIcon />}
                  onClick={handleAddStoreModal}
                >
                  Add Store
                </Button>
              )}
            </>
          }
          onRowLink={(e) => {
            return `/stores/details/${e.id}`;
          }}
        />
      )}
      <CustomeDialog
        open={isOpen}
        handleClose={handleAddStoreModal}
        PaperProps={{
          sx: { width: "423px", height: "491px", p: "16px" },
        }}
        title="Stores"
        content={
          <CheckBoxListDialog
            Items={filteredStores}
            onSearch={HandleFilterStores}
            loading={false}
            onSelect={handleStoreSelect}
            disabled={!selectedStores.length || isSaveButton}
            isSelected={(id) => selectedStores.includes(id)}
            onClearSearch={() => HandleFilterStores("initial")}
            value={searchText}
            onSubmit={handleAssignStores}
          />
        }
      />
      <DeleteModal
        open={isDelete}
        handleClose={() => toggleStoreDeleteModal()}
        onConfirm={() => onConfirmStoreDelete()}
      />
    </>
  );
};
