import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useDropdownApis, usePermission } from "../../../shared";
import {
  assignPoductToAvailabilityGroup,
  assignStoreToAvailabilityGroup,
  createAvailabilityGroup,
  deleteAvailabilityGroupById,
  deleteProductFromAvailabilityGroup,
  deleteStoreFromAvailabilityGroup,
  editAvailabilityGroup,
  getAvailabilityGroup,
  getAvailabilityGroupDetailsById,
  getCategorywiseProducts,
  getProductFromAvailabilityGroup,
  getStoreFromAvailabilityGroup,
} from "../api/AvailabilityGroupApi";
import moment from "moment";
import { useTabs } from "../../../shared/hooks/useTabs";
import { AppContext } from "../../../store/AppScopeProvider";

export const useAvailabilityGroup = ({
  isAvailable,
  AvailabilityGroupId,
  isSales,
}) => {
  const navigate = useNavigate();
  const { appState } = useContext(AppContext);
  const { setCurentTab } = useTabs();
  const { globalData } = appState;
  const { availabilityGroupDetails: currentTab } = appState.currentTabs;
  const { storeList } = useDropdownApis({
    isStoreList: currentTab === "2" ? true : false,
  });

  const isVisible = usePermission("availability-group-view");
  const isEditable = usePermission("availability-group-modify");
  const isDeletable = usePermission("optional-group-delete");
  const isCreateVisible = usePermission("availability-group-create");
  const [state, setState] = useImmer({
    isBusy: false,
    isSaveButtonBusy: false,
    isOpen: false,
    id: null,
    name: "",
    httpStatusCodes: {
      details: "",
    },
    productDetails: {
      isLoading: true,
      isSaveButtonBusy: false,
      isOpen: false,
      isDelete: false,
      id: null,
      productList: [],
      allPoducts: [],
      filteredPoducts: [],
      selectedPoducts: [],
      allCategoryProductList: [],
    },
    storeDetails: {
      isLoading: true,
      isSaveButtonBusy: false,
      isOpen: false,
      isDelete: false,
      id: null,
      storeList: [],
      allStores: [],
      filteredStores: [],
      selectedStores: [],
    },

    availabilityGroups: [],
    store: [],

    data: {
      name: "",
      start_time: "",
      end_time: "",
      sale_channels: [],
    },
  });

  const salesChannelOptions =
    globalData?.sale_channels?.map((item) => {
      return { id: item?.value, name: item?.label };
    }) || [];
  const onDeleteSalesChannel = (data) => {
    setState((draft) => {
      draft.data.sale_channels = state.data.sale_channels.filter((value) => {
        return value.id !== data;
      });
    });
  };
  useEffect(() => {
    if (isAvailable) {
      getAvailabilityGroups();
    }
  }, [isAvailable]);
  const getAvailabilityGroups = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = false;
      });
      const res = await getAvailabilityGroup();
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.availabilityGroups = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };
  const onChangeTime = (time, name) => {
    setState((draft) => {
      draft.data[name] = moment(time).format("HH:mm:ss");
    });
  };

  const onSaveAvailabilityGroup = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });

      const payload = {
        ...state.data,
        sale_channels: [
          ...state?.data?.sale_channels?.map((x) => {
            return x.id;
          }),
        ],
      };
      const res = await createAvailabilityGroup(payload);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate("/availabilityGroup");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const toggleModal = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isOpen = !draft.isOpen;
    });
  };
  const handleDeleteAvailabilityGroup = async (type = "list") => {
    try {
      const res = await deleteAvailabilityGroupById(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.availabilityGroups = draft.availabilityGroups.filter(
            (item) => item.id !== state.id
          );
        });
        toggleModal();
        navigate("/availabilityGroup");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const getAvailabilityGroupDetail = async (AvailabilityGroupId) => {
    setState((draft) => {
      draft.isBusy = true;
    });
    try {
      const res = await getAvailabilityGroupDetailsById(AvailabilityGroupId);
      if (res.success) {
        setState((draft) => {
          draft.name = res.data.name;
          draft.data = res.data;

          setState((draft) => {
            draft.isBusy = false;
          });
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };
  useEffect(() => {
    if (AvailabilityGroupId) {
      getAvailabilityGroupDetail(AvailabilityGroupId);
    }
  }, [AvailabilityGroupId]);

  const onUpdateAvailabilityGroup = async (AvailabilityGroupId) => {
    const payload = {
      ...state.data,
      sale_channels: [
        ...state?.data?.sale_channels?.map((x) => {
          return x.id;
        }),
      ],
    };
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });

      const res = await editAvailabilityGroup(AvailabilityGroupId, payload);
      if (res.success) {
        successMessage("Updated Successfully");
        navigate("/availabilityGroup");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const getStoreFromAvailabilityGroups = async (AvailabilityGroupId) => {
    setState((draft) => {
      draft.isBusy = true;
    });
    try {
      const res = await getStoreFromAvailabilityGroup(AvailabilityGroupId);
      if (res.success) {
        setState((draft) => {
          draft.storeDetails.storeList = res.data;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (AvailabilityGroupId && currentTab === "2") {
      getStoreFromAvailabilityGroups(AvailabilityGroupId);
    }
  }, [AvailabilityGroupId, currentTab]);
  const HandleFilterStores = (type, text) => {
    const alreadyStores = state.storeDetails.storeList.map((val) => {
      return val.id;
    });

    const filteredStoreList = storeList.filter(
      (val) => !alreadyStores.includes(val.id)
    );
    if (type === "initial") {
      setState((draft) => {
        draft.storeDetails.filteredStores = filteredStoreList;

        return draft;
      });
    } else {
      text = text.toLowerCase();
      setState((draft) => {
        draft.storeDetails.filteredStores = filteredStoreList.filter((val) => {
          return val.name.toString().toLowerCase().indexOf(text) > -1;
        });
        return draft;
      });
    }
  };
  useEffect(() => {
    HandleFilterStores("initial");
  }, [storeList, state.storeDetails.storeList]);

  const handleStoreSelect = (id) => {
    const isSelcted = state.storeDetails.selectedStores.includes(id);
    if (isSelcted) {
      setState((draft) => {
        draft.storeDetails.selectedStores =
          draft.storeDetails.selectedStores.filter((val) => val !== id);
      });
    } else {
      setState((draft) => {
        draft.storeDetails.selectedStores = [
          ...draft.storeDetails.selectedStores,
          id,
        ];
        return draft;
      });
    }
  };
  const handleAssignStoretoAvailability = async () => {
    const data = {
      store_id: state.storeDetails.selectedStores,
    };
    setState((draft) => {
      draft.storeDetails.isSaveButtonBusy = true;
    });
    try {
      const res = await assignStoreToAvailabilityGroup(
        data,
        AvailabilityGroupId
      );
      if (res.success === true) {
        successMessage(res.message);
        setState((draft) => {
          draft.storeDetails.isSaveButtonBusy = false;
          draft.storeDetails.storeList = [
            draft.store,
            ...draft.storeDetails.allStores.filter((val) =>
              draft.storeDetails.selectedStores.includes(val.id)
            ),
          ];
          draft.storeDetails.isLoading = false;
          draft.storeDetails.selectedStores = [];
          return draft;
        });
        handleModal(false);
        getStoreFromAvailabilityGroups(AvailabilityGroupId);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.storeDetails.isLoading = false;
        draft.storeDetails.isSaveButtonBusy = false;
      });
    }
  };
  const handleModal = (e) => {
    setState((draft) => {
      draft.storeDetails.isOpen = e;
      draft.isAvailable = true;
      draft.storeDetails.selectedStores = [];
      return draft;
    });
    HandleFilterStores("initial");
  };
  const getProductFromAvailabilityGroups = async (AvailabilityGroupId) => {
    setState((draft) => {
      draft.isBusy = true;
    });
    try {
      const res = await getProductFromAvailabilityGroup(AvailabilityGroupId);
      if (res.success) {
        setState((draft) => {
          draft.productDetails.productList = res.data;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (AvailabilityGroupId && currentTab === "3") {
      getProductFromAvailabilityGroups(AvailabilityGroupId);
    }
  }, [AvailabilityGroupId, currentTab]);
  const handleModals = (e) => {
    setState((draft) => {
      draft.productDetails.isOpen = e;
      draft.isAvailable = true;
      draft.isProducts = true;
      draft.productDetails.selectedPoducts = [];
      return draft;
    });
    HandleFilterProduct("initial");
  };
  const HandleFilterProduct = (type, text) => {
    const ProductList = state.productDetails.allCategoryProductList
      .map((val) => {
        let filtered = val.products.filter((data) => {
          if (!["delivery", "gift_card"].includes(data.type)) return true;
        });
        return filtered.length ? { ...val, products: filtered } : undefined;
      })
      .filter(Boolean);
    if (type === "initial") {
      setState((draft) => {
        draft.productDetails.filteredPoducts = ProductList;
        return draft;
      });
    } else {
      const Search = (data, text) => {
        text = text.toLowerCase();
        return data
          .map((val, i) => {
            let filtered = val.products.filter((data) => {
              if (data.name.toString().toLowerCase().indexOf(text) > -1)
                return true;
            });
            return filtered.length ? { ...val, products: filtered } : undefined;
          })
          .filter(Boolean);
      };
      setState((draft) => {
        draft.productDetails.filteredPoducts = Search(ProductList, text);
        return draft;
      });
    }
  };
  useEffect(() => {
    HandleFilterProduct("initial");
  }, [state.productDetails.productList]);
  const handlePoductSelect = (type, value, event) => {
    if (type === "body") {
      if (event.target.checked) {
        setState((draft) => {
          draft.productDetails.selectedPoducts = [
            ...draft.productDetails.selectedPoducts,
            value.id,
          ];
        });
      } else {
        setState((draft) => {
          draft.productDetails.selectedPoducts =
            draft.productDetails.selectedPoducts.filter(
              (val) => val !== value.id
            );
        });
      }
    } else {
      const subRowsID = value.products?.map((e, i) => {
        return e.id;
      });
      if (event.target.checked) {
        const alReadyIds = state.productDetails.productList.map((e, i) => {
          return e.id;
        });
        setState((draft) => {
          draft.productDetails.selectedPoducts = [
            ...new Set([...state.productDetails.selectedPoducts, ...subRowsID]),
          ].filter((val) => !alReadyIds.includes(val));
        });
      } else {
        setState((draft) => {
          draft.productDetails.selectedPoducts =
            draft.productDetails.selectedPoducts.filter(
              (val) => !subRowsID.includes(val)
            );
        });
      }
    }
  };

  const handleAssignProducttoAvailabilityGroup = async () => {
    const data = {
      product_id: state.productDetails.selectedPoducts,
    };
    setState((draft) => {
      draft.productDetails.isSaveButtonBusy = true;
    });
    try {
      const res = await assignPoductToAvailabilityGroup(
        data,
        AvailabilityGroupId
      );
      if (res.success === true) {
        successMessage(res.message);

        setState((draft) => {
          draft.productDetails.productList = [
            ...draft.productDetails.productList,
            ...draft.productDetails.allPoducts.filter((val) =>
              draft.productDetails.selectedPoducts.includes(val.id)
            ),
          ];
          draft.productDetails.isLoading = false;
          draft.productDetails.isSaveButtonBusy = false;
          draft.productDetails.selectedPoducts = [];
          return draft;
        });
        handleModals(false);
        getProductFromAvailabilityGroups(AvailabilityGroupId);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.productDetails.isSaveButtonBusy = false;
      });
    }
  };

  const handleDeleteProducts = async (id) => {
    try {
      const res = await deleteProductFromAvailabilityGroup(
        AvailabilityGroupId,
        state.productDetails.id
      );
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.productDetails.productList =
            draft.productDetails.productList.filter(
              (item) => item.id !== state.productDetails.id
            );
        });
        DeleteModals(false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const DeleteModals = (state, data) => {
    setState((draft) => {
      draft.productDetails.isDelete = state;
      draft.productDetails.id = data;
    });
  };
  const handleDeleteStores = async (id) => {
    try {
      const res = await deleteStoreFromAvailabilityGroup(
        AvailabilityGroupId,
        state.storeDetails.id
      );
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.storeDetails.storeList = draft.storeDetails.storeList.filter(
            (item) => item.id !== state.storeDetails.id
          );
        });
        DeleteStoreModal(false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const DeleteStoreModal = (state, data) => {
    setState((draft) => {
      draft.storeDetails.isDelete = state;
      draft.storeDetails.id = data;
    });
  };

  const handleSelectAllStores = (checked) => {
    if (checked) {
      setState((draft) => {
        draft.storeDetails.selectedStores = [
          ...draft.storeDetails.filteredStores.map((item) => item.id),
        ];
      });
    } else {
      setState((draft) => {
        draft.storeDetails.selectedStores = [];
      });
    }
  };

  const handleSelectAllProducts = (checked) => {
    if (checked) {
      setState((draft) => {
        draft.productDetails.selectedPoducts = [
          ...draft.productDetails.filteredPoducts.map((item) => item.id),
        ];
      });
    } else {
      setState((draft) => {
        draft.productDetails.selectedPoducts = [];
      });
    }
  };
  useEffect(() => {
    if (AvailabilityGroupId && currentTab === "3") {
      getCategorywiseProductsList();
    }
  }, [AvailabilityGroupId, currentTab]);
  const getCategorywiseProductsList = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = false;
      });
      const res = await getCategorywiseProducts();
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.productDetails.allCategoryProductList = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  return {
    state,
    storeList,
    onChange,
    onChangeTime,
    onSaveAvailabilityGroup,
    toggleModal,
    handleDeleteAvailabilityGroup,
    onUpdateAvailabilityGroup,
    HandleFilterStores,
    handleStoreSelect,
    handleAssignStoretoAvailability,
    handleModal,
    handleModals,
    HandleFilterProduct,
    handlePoductSelect,
    handleAssignProducttoAvailabilityGroup,
    handleDeleteProducts,
    DeleteModals,
    DeleteStoreModal,
    handleDeleteStores,
    isDeletable,
    isEditable,
    isVisible,
    isCreateVisible,
    currentTab,
    setCurentTab,
    handleSelectAllStores,
    handleSelectAllProducts,
    salesChannelOptions,
    onDeleteSalesChannel,
  };
};
