import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import { usePermission, useTabs } from "../../../shared";
import {
  deleteCouponsById,
  duplicateCoupons,
  getCoupons,
  playpauseCoupons,
} from "../api/CouponsApi";
import { useDebouncedCallback } from "use-debounce";
import qs from "qs";
import { TableStateContext } from "../../../store";

export const useCouponList = ({ isCoupons }) => {
  const { appState } = useContext(AppContext);
  const {
    globalData,
    currentTabs: { CouponseDetails: currentTab },
  } = appState;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setCurentTab } = useTabs();
  const isEditable = usePermission("coupon-modify");
  const isDeletable = usePermission("coupon-delete");
  const isCreateVisible = usePermission("coupon-create");
  const isVisible = usePermission("coupon-view");

  const [state, setState] = useImmer({
    isBusy: false,
    coupons: [],
    initialLoad: true,
    isOpen: false,
    isSearching: false,
    searchText: "",
    status: "",
    id: null,
    filterApplied: {
      isSearching: false,
      searchText: "",
      status: "",
    },
    pagination: {
      pageIndex: 1,
      pageSize: 50,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    sortBy: [],
  });

  const PAGE_NAME = "coupon";
  const {
    filterState: {
      params: { coupon },
    },
    initialState,
    filterStateDispatch,
  } = useContext(TableStateContext);
  const debounced = useDebouncedCallback((value) => {
    const { searchText, status } = state.filterApplied;
    const { pagination, sortBy } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filterApplied },
    });
    getCouponsList({
      search: value,
      status,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  }, 1000);
  useEffect(() => {
    const { searchText, status } = coupon.filterApplied;
    const { pagination, sortBy } = coupon;
    if (isCoupons) {
      setState((draft) => {
        draft.filterApplied = coupon.filterApplied;
        draft.pagination = pagination;
        draft.sortBy = sortBy;
      });
      getCouponsList({
        search: searchText,
        status,
        pagination: {
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
        },
        sortBy,
      });
    }
  }, [isCoupons]);

  const getCouponsList = async ({
    search,
    status,
    pagination,
    sortBy = [],
  }) => {
    let params = {};
    if (search) {
      params = { ...params, search: search };
    }
    if (status !== null && status !== "") {
      params = { ...params, status };
    }
    if (sortBy.length > 0) {
      const sortByParams = sortBy.reduce(
        (obj, item) =>
          Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
        {}
      );
      params = {
        ...params,
        sort: sortByParams,
      };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });
      const res = await getCoupons(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.initialLoad = false;
          draft.coupons = res.data.coupons;
          draft.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.initialLoad = false;
        draft.isBusy = false;
      });
    }
  };
  const onChangeFilter = (e) => {
    const { searchText, status } = state.filterApplied;
    const { pagination, sortBy } = state;
    if (e.target.name === "status") {
      getCouponsList({
        search: searchText,
        status: e.target.value,
        pagination: {
          pageIndex: 1,
          pageSize: pagination.pageSize,
        },
        sortBy,
      });
    } else {
      debounced(e.target.value);
    }

    setState((draft) => {
      draft.filterApplied[e.target.name] = e.target.value;
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: {
          ...state.filterApplied,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const resetFilters = () => {
    if (
      state.filterApplied.status.toString() ||
      state.filterApplied.searchText
    ) {
      getCouponsList({
        pagination: {
          pageIndex: 1,
          pageSize: state.pagination.pageSize,
        },
        sortBy: state.sortBy,
      });
      setState((draft) => {
        draft.filterApplied.status = "";
        draft.filterApplied.searchText = "";
        draft.filterApplied.isSearching = true;
      });
    }
    filterStateDispatch({
      type: "CLEAR_FILTERS",
      page: PAGE_NAME,
      value: initialState.params.coupon.filterApplied,
    });
  };
  const deleteModal = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isOpen = !draft.isOpen;
    });
  };
  const handleDeleteCoupons = async () => {
    try {
      const res = await deleteCouponsById(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");

        setState((draft) => {
          draft.coupons = draft.coupons.filter((item) => item.id !== state.id);
        });
        deleteModal();
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleDuplicateCoupons = async (id) => {
    try {
      const res = await duplicateCoupons(id);
      if (res.success) {
        successMessage("Coupon duplicated successfully");
        setCurentTab("CouponseDetails", "1");
        navigate(`/coupons/edit/${res.data.id}`);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handlePlayPause = async (id) => {
    try {
      const res = await playpauseCoupons(id);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.coupons = [
            ...draft.coupons.map((v) => {
              return id === v.id ? { ...v, active: v.active === 2 ? 3 : 2 } : v;
            }),
          ];
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const gotoPage = (page) => {
    const { searchText, status } = state.filterApplied;
    const { sortBy } = state;
    getCouponsList({
      search: searchText,
      status,
      pagination: {
        pageIndex: page,
        pageSize: state.pagination.pageSize,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };
  const setPageSize = (e) => {
    const { searchText, status } = state.filterApplied;
    const { sortBy } = state;
    getCouponsList({
      search: searchText,
      status,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };
  const handleSort = (sortBy) => {
    const { searchText, status } = state.filterApplied;
    const { pagination } = state;
    getCouponsList({
      search: searchText,
      status,
      pagination,
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_SORT",
      page: PAGE_NAME,
      value: sortBy,
    });
    setState((draft) => {
      draft.sortBy = sortBy;
    });
  };
  const onSeachClear = () => {
    const { status } = state.filterApplied;
    const { pagination, sortBy } = state;
    getCouponsList({
      search: null,
      status,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.filterApplied.searchText = "";
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: { ...state.filterApplied, searchText: "" } },
    });
  };
  return {
    isDeletable,
    isEditable,
    isVisible,
    isCreateVisible,
    state,
    coupons: state.coupons,
    currentTab,
    setCurentTab,
    onChangeFilter,
    resetFilters,
    deleteModal,
    handleDeleteCoupons,
    handleDuplicateCoupons,
    globalData,
    handlePlayPause,
    gotoPage,
    setPageSize,
    handleSort,
    onSeachClear,
  };
};
