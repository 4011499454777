import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import {
  useActivityLog,
  useDropdownApis,
  usePermission,
} from "../../../shared";
import { errorMessage, successMessage } from "../../../utils";
import { useDebouncedCallback } from "use-debounce";
import {
  createTerminal,
  createTerminalEndofDays,
  deleteTerminalById,
  editTerminal,
  getTerminalDetailsById,
  getTerminalEndofDaySummary,
  getTerminalList,
  getTerminalListToEndofdayInTerminal,
  setDefaultSaleType,
  unPairTerminalId,
  updateSaleType,
} from "../api";
import qs from "qs";
import { AppContext } from "../../../store/AppScopeProvider";
import moment from "moment";
import { TableStateContext } from "../../../store";

const initialData = {
  terminal_id: "",
  store_id: "",
  cashless_till: 1,
  float_total: null,
  cheque_total: "",
  sale_date: "",
  cash_total: "",
  status: "",
  card_total: "",
  remarks: "",
  disableApplyButton: false,
  simple: false,
  eod_template_name: "",
  denominations: [
    {
      id: 1,
      eod_id: 1,
      denomination_id: 1,
      denomination_label: "5¢",
      denomination_cash: 0.05,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 80,
      min: 20,
    },
    {
      id: 2,
      eod_id: 1,
      denomination_id: 2,
      denomination_label: "10¢",
      denomination_cash: 0.1,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 80,
      min: 20,
    },
    {
      id: 3,
      eod_id: 1,
      denomination_id: 3,
      denomination_label: "20¢",
      denomination_cash: 0.2,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 40,
      min: 20,
    },
    {
      id: 4,
      eod_id: 1,
      denomination_id: 4,
      denomination_label: "50¢",
      denomination_cash: 0.5,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 40,
      min: 10,
    },
    {
      id: 5,
      eod_id: 1,
      denomination_id: 5,
      denomination_label: "$1",
      denomination_cash: 1,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 40,
      min: 10,
    },
    {
      id: 6,
      eod_id: 1,
      denomination_id: 6,
      denomination_label: "$2",
      denomination_cash: 2,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 35,
      min: 10,
    },
    {
      id: 7,
      eod_id: 1,
      denomination_id: 7,
      denomination_label: "$5",
      denomination_cash: 5,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 15,
    },
    {
      id: 8,
      eod_id: 1,
      denomination_id: 8,
      denomination_label: "$10",
      denomination_cash: 10,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 10,
    },
    {
      id: 9,
      eod_id: 1,
      denomination_id: 9,
      denomination_label: "$20",
      denomination_cash: 20,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 10,
    },
    {
      id: 10,
      eod_id: 1,
      denomination_id: 10,
      denomination_label: "$50",
      denomination_cash: 50,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
    },
    {
      id: 11,
      eod_id: 1,
      denomination_id: 11,
      denomination_label: "$100",
      denomination_cash: 100,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
    },
  ],
};

export const useTerminals = ({ load, id, isStoreList, eod }) => {
  const { appState } = useContext(AppContext);
  const isEditable = usePermission("terminal-modify");
  const isDeletable = usePermission("terminal-delete");
  const isCreateVisible = usePermission("terminal-create");
  const isEODCreateVisible = usePermission("end-of-day-create");
  const isSummaryVisble = usePermission("end-of-day-summary-view");
  const navigate = useNavigate();
  const { addActivityLog } = useActivityLog();
  const { storeList } = useDropdownApis({
    isStoreList: isStoreList,
    storeId: null,
  });
  const {
    globalData: { status: statusList, terminals: TerminalsList },
  } = appState;
  const [state, setState] = useImmer({
    showEodTable: null,
    initialLoad: true,
    isUnpairOpen: false,
    isBusy: false,
    isLoading: false,
    isDetailsFetching: false,
    isSaveButtonBusy: false,
    isUnpairing: false,
    isOpen: false,
    isOpenDetails: false,
    columnOptions: false,
    isApplyButtonBusy: false,
    id: null,
    terminalName: "",
    terminals: [],
    httpStatusCodes: {
      details: "",
    },
    filterApplied: {
      all: [],
      searchText: "",
      stores: [],
      status: {},
      pairing_status: {},
    },
    terminalDetails: {},
    data: {
      name: "",
      store_id: null,
      status: 1,
      order_prefix: "",
      cashless_till: 0,
    },
    terminalData: [],
    endofdaySummary: {
      card_total: 0,
      client_account_total: 0,
      gross_sales: 0,
      system_cash: 0,
      total_tax: 0,
      loyalty_total: 0,
      gift_card_total: 0,
      round_off_total: 0,
      variance_status: false,
      eod_variance: 0,
    },
    eod: {},
    varianceError: false,
    isSubmitted: false,
    isCalculated: false,
    filterData: { sale_date: new Date(), terminal_id: "", store_id: "" },
    endofday: initialData,
  });
  const PAGE_NAME = "terminals";
  const {
    filterState: {
      params: { terminals },
    },
    initialState,
    filterStateDispatch,
  } = useContext(TableStateContext);

  const Cashtotal = state.endofday.denominations.reduce((acc, item) => {
    return (
      acc +
      (Number(item.modification) + Number(item.bank)) * item.denomination_cash
    );
  }, 0);

  const variance =
    Number(Cashtotal) - Number(state?.endofdaySummary?.system_cash);
  const Floattotal = state.endofday.denominations.reduce((acc, item) => {
    return acc + item.float_count * item.denomination_cash;
  }, 0);

  useEffect(() => {
    if (load) {
      const { stores, status, pairing_status, searchText } =
        terminals.filterApplied;
      setState((draft) => {
        draft.filterApplied = terminals.filterApplied;
      });
      getTerminalsList({ stores, searchText, status, pairing_status });
    }
  }, [load]);
  const debounced = useDebouncedCallback((value) => {
    const { stores, status, pairing_status, searchText } = state.filterApplied;
    getTerminalsList({ stores, searchText: value, status, pairing_status });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filterApplied },
    });
  }, 1000);
  useEffect(() => {
    if (id) {
      getTerminalDetails(id);
    }
  }, [id]);

  const getTerminalsList = async ({
    stores = [],
    searchText,
    status = "",
    pairing_status = {},
  }) => {
    try {
      let params = {};

      if (searchText) {
        params = { ...params, search: searchText };
      }
      if (toString(status.value)) {
        params = { ...params, status: status.value };
      }
      if (stores?.length > 0) {
        let IDs = stores.map((v) => {
          return v.value;
        });
        params = { ...params, store: IDs };
      }
      if (toString(pairing_status.value)) {
        params = { ...params, connection_status: pairing_status.value };
      }

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = false;
      });
      const res = await getTerminalList(query);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.terminals = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const getTerminalDetails = async (customerId) => {
    try {
      setState((draft) => {
        draft.isDetailsFetching = true;
      });
      const res = await getTerminalDetailsById(customerId);
      if (res.success) {
        setState((draft) => {
          draft.terminalDetails = res.data;
          draft.data = res.data;
          draft.terminalName = res.data.name;
          draft.filterData.store_id = res.data.store_id;
          draft.filterData.terminal_id = Number(id);
          draft.isDetailsFetching = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isDetailsFetching = false;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };

  const toggleModal = (id = null, type = "list") => {
    if (type === "list") {
      setState((draft) => {
        draft.id = id;
        draft.isOpen = !draft.isOpen;
      });
    } else {
      setState((draft) => {
        draft.id = id;
        draft.isOpenDetails = !draft.isOpenDetails;
      });
    }
  };

  const handleDeleteTerminal = async (type = "list") => {
    try {
      const res = await deleteTerminalById(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.terminals = draft.terminals.filter(
            (item) => item.id !== state.id
          );
        });
        toggleModal();
        if (type === "details") {
          navigate("/terminal");
        }
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };
  const activityLogCreation = (res) => {
    const newData = res?.data;
    const { id, store_name, name } = newData;
    const parameters = {
      TerminalId: id,
      StoreName: store_name,
      TerminalName: name,
    };

    addActivityLog({
      templateId: 22,
      parameters: parameters,
      uniqueParameter: id,
      data: { newLogData: newData, oldLogData: {} },
    });
  };
  const onSaveTerminal = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await createTerminal(state.data);
      if (res.success) {
        activityLogCreation(res);
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate(`/terminal/details/${res.data.id}`);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const activityLogUpdate = (res) => {
    const newData = res?.data;
    const { id, store_name, name } = newData;
    const parameters = {
      TerminalId: id,
      StoreName: store_name,
      TerminalName: name,
    };

    addActivityLog({
      templateId: 23,
      parameters: parameters,
      uniqueParameter: id,
      data: {
        newLogData: newData,
        oldLogData: state?.terminalDetails,
      },
    });
  };
  const onUpdateTerminal = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await editTerminal(id, state.data);
      if (res.success) {
        activityLogUpdate(res);
        successMessage("Updated Successfully");
        navigate(`/terminal/details/${id}`);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const activityLogUnpair = (res) => {
    const newData = res?.data;
    const { id, store_name, name } = newData;
    const parameters = {
      TerminalId: id,
      StoreName: store_name,
      TerminalName: name,
    };

    addActivityLog({
      templateId: 24,
      parameters: parameters,
      uniqueParameter: id,
      data: {
        newLogData: newData,
        oldLogData: state?.terminalDetails,
      },
    });
  };
  const unPairTerminal = async (id) => {
    try {
      setState((draft) => {
        draft.isUnpairing = true;
      });
      const res = await unPairTerminalId(id);
      if (res.success) {
        activityLogUnpair(res);
        successMessage("UnPaired Successfully");
        setState((draft) => {
          draft.data.connection_status = "Unpaired";
          draft.isUnpairing = false;
        });
        toggleUnpair();
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.isUnpairing = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isUnpairing = false;
      });
    }
  };

  const handleFilterClear = (type, value) => {
    if (type === "single") {
      if (value.type === "Store") {
        const filteredStores = state.filterApplied.stores.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.stores = filteredStores;
        });
      } else if (value.type === "Status") {
        setState((draft) => {
          draft.filterApplied.status = {};
        });
      } else if (value.type === "pairing_status") {
        setState((draft) => {
          draft.filterApplied.pairing_status = {};
        });
      }
    } else {
      const { searchText } = state.filterApplied;
      getTerminalsList({ searchText });
      setState((draft) => {
        draft.filterApplied.all = [];
        draft.filterApplied.stores = [];
        draft.filterApplied.status = {};
        draft.filterApplied.pairing_status = {};
      });
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initialState.params.terminals.filterApplied,
      });
    }
  };

  const handleFilter = (e, value, type) => {
    if (type === "store") {
      setState((draft) => {
        draft.filterApplied.stores = value.map((v) => {
          return { ...v, type: "Store" };
        });
      });
    } else if (type === "status") {
      setState((draft) => {
        draft.filterApplied.status = {
          value: value.props.value,
          label: value.props.children,
          type: "Status",
        };
      });
    } else if (type === "pairing_status") {
      setState((draft) => {
        draft.filterApplied.pairing_status = {
          value: value.props.value,
          label: value.props.children,
          type: "pairing_status",
        };
      });
      setState((draft) => {
        draft.disableApplyButton = false;
      });
    }
  };

  useEffect(() => {
    setState((draft) => {
      draft.filterApplied.all = [
        ...state.filterApplied.stores,
        state.filterApplied.status,
        state.filterApplied.pairing_status,
      ].filter((el) => Object.keys(el).length);
    });
  }, [
    state.filterApplied.status,
    state.filterApplied.stores,
    state.filterApplied.pairing_status,
  ]);

  const onChangeFilter = (e) => {
    setState((draft) => {
      draft.filterApplied.searchText = e;
    });
    debounced(e);
  };

  const handleApplyFilter = () => {
    const { stores, searchText, status, pairing_status } = state.filterApplied;
    getTerminalsList({ stores, searchText, status, pairing_status });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: state.filterApplied,
      },
    });
  };

  const onSeachClear = () => {
    setState((draft) => {
      draft.filterApplied.searchText = "";
    });
    const { stores, status, pairing_status } = state.filterApplied;
    getTerminalsList({ stores, status, pairing_status });
  };

  const onDefaultChange = async (saleTypeId) => {
    try {
      const res = await setDefaultSaleType(id, saleTypeId);

      if (res.success) {
        getTerminalDetails(id);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const activityLogSaleTypeStatusChange = () => {
    const { id, store_name, name } = state.terminalDetails;
    const parameters = {
      TerminalId: id,
      StoreName: store_name,
      TerminalName: name,
    };

    addActivityLog({
      templateId: 25,
      parameters: parameters,
      uniqueParameter: id,
      data: { newLogData: {}, oldLogData: {} },
    });
  };
  const handleSaleStatusChange = async (saleTypeId, status) => {
    const paloadStatus = status === 1 ? 0 : 1;
    try {
      const res = await updateSaleType(id, saleTypeId, paloadStatus);
      if (res.success) {
        activityLogSaleTypeStatusChange();
        getTerminalDetails(id);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const onTerminalChange = (e, type) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.endofday[name] = value;
    });
  };
  const onTerminalChanges = (e, type) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.filterData[name] = value;
      draft.isApplyButtonBusy = false;
    });
  };
  const onSaveTerminalEndofDay = async (id) => {
    setState((draft) => {
      draft.isSaveButtonBusy = true;
    });

    try {
      const payload = {
        ...state.endofday,
        sale_date: state.endofday.sale_date
          ? moment(state.endofday.sale_date, "MM-DD-YYYY HH:mm:ss").format(
              "YYYY-MM-DD"
            )
          : moment().format("YYYY-MM-DD"),
        terminal_id: state.endofday.terminal_id,
        cash_total: Cashtotal,
        cheque_total:
          state.endofday.cheque_total === 0 ? "" : state.endofday.cheque_total,
        float_total:
          state.endofday.float_total === "" ? 0 : state.endofday.float_total,
        denominations: [
          ...state.endofday.denominations.map((data) => {
            return {
              ...data,
              cash_count: data.cash_count === "" ? 0 : data.cash_count,
              float_count: data.float_count === "" ? 0 : data.float_count,
            };
          }),
        ],
      };
      const res = await createTerminalEndofDays(payload);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate(`/terminal/details/${id}`);
      } else {
        errorMessage(res?.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const handleFloatChange = (e, index, cash) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.endofday.denominations[index].denomination_id = name;
      draft.endofday.denominations[index].float_count = value;
      draft.endofday.denominations[index].float_total = value * cash;
    });
  };
  const handleCashChange = (e, index, cash) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.endofday.denominations[index].denomination_id = name;
      draft.endofday.denominations[index].cash_count = value;
      draft.endofday.denominations[index].cash_total = value * cash;
    });
  };
  const handleDateChange = (e) => {
    setState((draft) => {
      draft.filterData.sale_date = e;
      draft.isApplyButtonBusy = false;
    });
  };

  const handleTerminalEndofDaySummary = async (date) => {
    try {
      setState((draft) => {
        draft.isApplyButtonBusy = false;
        draft.isLoading = true;
        draft.endofday.terminal_id = draft.filterData.terminal_id;
        draft.endofday.store_id = draft.filterData.store_id;
        draft.endofday.sale_date = draft.filterData.sale_date;
      });
      const payload = {
        terminal_id: state.filterData.terminal_id,
        date:
          state.filterData.sale_date &&
          moment(state.filterData.sale_date, "MM-DD-YYYY HH:mm:ss").format(
            "YYYY-MM-DD"
          ),
      };
      const res = await getTerminalEndofDaySummary(payload);

      if (res.success) {
        setState((draft) => {
          draft.isLoading = false;
          draft.showEodTable = res.data.eod === null ? true : false;
          draft.endofdaySummary = {
            system_cash: res.data.system_cash,
            card_total: res.data.card_total,
            client_account_total: res.data.client_account_total,
            gross_sales: res.data.gross_sales,
            total_tax: res.data.total_tax,
            loyalty_total: res.data.loyalty_total,
            gift_card_total: res.data.gift_card_total,
            round_off_total: res.data?.round_off_total,
            variance_status: res.data.variance_status,
            eod_variance: Number(res.data.eod_variance),
          };
          draft.eod = res.data.eod === null ? {} : res.data.eod;
          draft.endofday = {
            ...initialData,
            float_total: res.data.float_amount,
            terminal_id: state.filterData.terminal_id,
            sale_date: state.filterData.sale_date,
            store_id: state.filterData.store_id,
            cashless_till: res.data.cashless_till,
            simple: res.data.simple,
            eod_template_name: res.data.eod_template_name,
          };
          draft.varianceError = false;
          draft.isSubmitted = false;
          draft.isSummary = true;
          draft.isApplyButtonBusy = true;
        });
      } else {
        setState((draft) => {
          draft.endofdaySummary = res.data;
          draft.isSummary = true;
          draft.isApplyButtonBusy = true;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSummary = false;
        draft.isApplyButtonBusy = false;
      });
    }
  };
  const handleDropdown = (e) => {
    setState((draft) => {
      draft.filterData.store_id = e?.value;
      draft.filterData.terminal_id = "";
    });
  };

  const getTeminalListDate = async () => {
    try {
      const selectedStoreId = state.filterData.store_id;
      const res = await getTerminalListToEndofdayInTerminal(selectedStoreId);

      if (res.success) {
        setState((draft) => {
          draft.terminalData = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  useEffect(() => {
    if (state.filterData.store_id && eod) {
      getTeminalListDate();
    }
  }, [state.filterData.store_id]);

  const onChangeRemarks = (e) => {
    setState((draft) => {
      draft.endofday.remarks = e.target.value;
    });
  };
  const onChangeEodType = (e) => {
    setState((draft) => {
      draft.endofday.simple = e.target.checked;
    });
  };

  const toggleUnpair = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isUnpairOpen = !draft.isUnpairOpen;
    });
  };

  const onConfirmUnpair = () => {
    unPairTerminal(state.id);
  };
  return {
    state,
    toggleModal,
    handleDeleteTerminal,
    onChange,
    onSaveTerminal,
    onUpdateTerminal,
    isEditable,
    isDeletable,
    isCreateVisible,
    unPairTerminal,
    storeList,
    handleFilterClear,
    handleFilter,
    handleApplyFilter,
    onChangeFilter,
    statusList,
    onSeachClear,
    TerminalsList,
    onDefaultChange,
    handleSaleStatusChange,
    onTerminalChange,
    onSaveTerminalEndofDay,
    handleFloatChange,
    handleCashChange,
    handleDateChange,
    isEODCreateVisible,
    Cashtotal,
    Floattotal,
    isSummaryVisble,
    handleTerminalEndofDaySummary,
    handleDropdown,
    onTerminalChanges,
    setState,
    onChangeRemarks,
    variance,
    onChangeEodType,
    toggleUnpair,
    onConfirmUnpair,
  };
};
