import * as React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  FormControl,
  Button,
  Stack,
  InputAdornment,
  OutlinedInput,
  Box,
  Checkbox,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Loader } from "..";
import CloseIcon from "@mui/icons-material/Close";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

export const CheckBoxListDialog = (props) => {
  const ref = React.useRef(null);
  const {
    Items,
    onSearch,
    loading,
    onSelect,
    onSubmit,
    onAllSelect,
    disabled = false,
    isSelected,
    value,
    onClearSearch,
    hasSelectAll = false,
    isSelectAllChecked,
    searchBar = true,
    subHeader = "",
    selectAllText = "Select All",
    valueKey = "name",
  } = props;
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Stack
          justifyContent="space-between"
          height="100%"
          gap="8px"
          maxHeight={"calc(100% - 24px)"}
        >
          {subHeader && (
            <Typography
              sx={{
                pt: "27px",
              }}
            >
              {subHeader}
            </Typography>
          )}
          {searchBar && (
            <FormControl
              size="small"
              fullWidth={true}
              sx={{
                pt: "24px",
              }}
            >
              <OutlinedInput
                placeholder="Search"
                sx={{
                  height: "48px",
                  ".MuiOutlinedInput-input": {
                    pl: "0px !important",
                  },
                }}
                size="small"
                value={value}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon size="large" />
                  </InputAdornment>
                }
                endAdornment={
                  value &&
                  onClearSearch && (
                    <InputAdornment
                      sx={{ cursor: "pointer" }}
                      onClick={onClearSearch}
                      position="end"
                    >
                      <CloseIcon size="large" />
                    </InputAdornment>
                  )
                }
                fullWidth
                onChange={(e) => {
                  onSearch("search", e.target.value);
                }}
              />
            </FormControl>
          )}
          <Box sx={{ height: "100%", overflow: "auto" }} fullWidth>
            {Items?.length > 0 ? (
              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                {hasSelectAll && (
                  <ListItem key={"all"} disablePadding>
                    <ListItemButton
                      dense
                      sx={{
                        "&:hover": {
                          backgroundColor: "#E6E6E6",
                          borderRadius: "8px",
                        },
                      }}
                      onClick={(e) => onAllSelect(!isSelectAllChecked)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "32px",
                        }}
                      >
                        <Checkbox
                          edge="start"
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<LibraryAddCheckIcon />}
                          disableRipple
                          checked={isSelectAllChecked}
                        />
                      </ListItemIcon>
                      <ListItemText primary={selectAllText} />
                    </ListItemButton>
                  </ListItem>
                )}
                {Items?.map((value) => {
                  const labelId = `checkbox-list-label-${value}`;
                  return (
                    <ListItem key={value} disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={() => onSelect(value.id)}
                        dense
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(21, 96, 212, 0.08)",
                            borderRadius: "8px",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "32px",
                          }}
                        >
                          <Checkbox
                            edge="start"
                            checked={isSelected(value.id)}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value[valueKey]} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <Stack justifyContent="center" alignItems="center" height="100%">
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="caption"
                  color="text.secondary"
                  fontWeight={400}
                >
                  There is no data found
                </Typography>
              </Stack>
            )}
          </Box>
          <Button
            sx={{ height: "48px" }}
            variant="contained"
            fullWidth
            onClick={onSubmit}
            disabled={disabled}
          >
            Save
          </Button>
        </Stack>
      )}
    </>
  );
};
