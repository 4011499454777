import { ContentLayout, ServerPaginatedTable } from "../../../shared";
import * as React from "react";
import {
  Button,
  Chip,
  Checkbox,
  TextField,
  Stack,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import AddIcon from "@mui/icons-material/Add";
import { useEndofDay } from "../hooks/useEndofDay";
import { arraySort } from "../../../utils";
import { DashBoardDateRangePicker } from "../../Dashboard";

export const EndofDayList = () => {
  const navigate = useNavigate();
  const {
    state,
    globalData,
    storeList,
    onChangeFilter,
    onSeachClear,
    handleFilterClear,
    handleApplyFilter,
    handleFilter,
    isCreateVisible,
    isVisible,
    isSummaryVisble,
    isDepositSlipVisible,
    onChangeDate,
    handleIsOpen,
    handleDateChangeApply,
    handleSort,
    gotoPage,
    setPageSize,
  } = useEndofDay({
    isEndofDay: true,
    isStore: true,
    isDetail: false,
  });

  const storeOptions = storeList?.map((v) => {
    return { value: v.id, label: v.name };
  });

  const TerminalOptions = state?.filteredPairedterminallist?.map((v) => {
    return { value: v.id, label: v.name };
  });
  const StoreAdminTerminalOptions = state?.terminalData?.map((v) => {
    return { value: v.id, label: v.name };
  });
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const ActionButtons = ({ id }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isVisible && (
          <Link to={`details/${id}`}>
            <img alt="details" src="/icons/arrow_forward.svg" />
          </Link>
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Sale Date",
        accessor: "sale_date",
      },
      {
        Header: "Updated Date",
        accessor: "formatted_updated_at",
      },
      {
        Header: "Store",
        accessor: "store_name",
      },
      {
        Header: "Terminal Name",
        accessor: "terminal_name",
      },
      {
        Header: "Staff Name",
        accessor: "user_name",
      },
      {
        Header: "Cash Variance",
        accessor: "variance",
        Cell: ({ value }) => {
          return (
            <>
              <Typography color={Number(value) === 0 ? "#0F875B" : "#C42B1C"}>
                {value}
              </Typography>
            </>
          );
        },
      },

      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          return (
            <Chip
              label={row.original.status === 1 ? "Approved" : "Pending"}
              color={row.original.status === 1 ? "success" : "warning"}
              variant={"outlined"}
              sx={{ bgcolor: "#FFF4CE" }}
              size="small"
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons id={row.id} />;
        },
      },
    ],
    []
  );
  const column = React.useMemo(
    () => [
      {
        Header: "Sale Date",
        accessor: "sale_date",
      },
      {
        Header: "Updated Date",
        accessor: "formatted_updated_at",
      },

      {
        Header: "Terminal Name",
        accessor: "terminal_name",
      },
      {
        Header: "Staff Name",
        accessor: "user_name",
      },
      {
        Header: "Cash Variance",
        accessor: "variance",
        Cell: ({ value }) => {
          return (
            <>
              <Typography color={Number(value) === 0 ? "#0F875B" : "#C42B1C"}>
                {value}
              </Typography>
            </>
          );
        },
      },

      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          return (
            <Chip
              label={row.original.status === 1 ? "Approved" : "Pending"}
              color={row.original.status === 1 ? "success" : "warning"}
              variant={"outlined"}
              sx={{ bgcolor: "#FFF4CE" }}
              size="small"
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons id={row.id} />;
        },
      },
    ],
    []
  );
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });
  return (
    <>
      <ContentLayout
        title="End of Day"
        rightContent={
          <>
            {isDepositSlipVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                onClick={() =>
                  navigate(
                    isStoreAdmin ? "/store-admin/deposit-slip" : "/deposit-slip"
                  )
                }
              >
                Deposit Slip
              </Button>
            )}
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("create")}
              >
                End of Day
              </Button>
            )}
          </>
        }
      >
        <ServerPaginatedTable
          columns={isStoreAdmin ? column : columns}
          data={state?.endofday}
          isLoading={state?.isBusy}
          defaultSearch={false}
          filterApplied={state.filterApplied.all}
          onFilterClear={(e) => handleFilterClear("single", e)}
          onAllFilterClear={(e) => handleFilterClear("all")}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
          pagination={state.pagination}
          sortByGlobal={state.sortBy}
          handleSort={handleSort}
          key={state.sortBy}
          filterContent={
            <InputControl
              type="table-search"
              value={state.filterApplied.searchText}
              onChange={(e) => onChangeFilter(e)}
              onClear={onSeachClear}
            />
          }
          filterSection={
            <>
              <Stack
                direction="row"
                gap="16px"
                alignItems="center"
                flexWrap="wrap"
              >
                <InputControl
                  type="select"
                  name="status"
                  label="Status"
                  value={
                    state?.filterApplied?.status?.value
                      ? state?.filterApplied?.status?.value
                      : ""
                  }
                  optionValue={globalData?.end_of_day?.status ?? []}
                  width="150px"
                  onChange={(e, v) => handleFilter(e, v, "status")}
                />
                {!isStoreAdmin && (
                  <InputControl
                    type="dropdown"
                    name="store"
                    label="Store"
                    multiple={true}
                    options={arraySort(storeOptions, "label")}
                    value={state.filterApplied.stores}
                    disableCloseOnSelect
                    disableClearable={true}
                    onChange={(e, v) => handleFilter(e, v, "store")}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => <></>)
                    }
                    renderOption={(props, option, { selected }) => (
                      <>
                        <li {...props} key={option.value}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      </>
                    )}
                    filterOptions={filterOptions}
                    sx={{ width: 250 }}
                    componentsProps={{
                      paper: {
                        sx: {
                          width: "fit-content",
                          minWidth: 250,
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Store"
                        placeholder="Store"
                      />
                    )}
                  />
                )}
                <InputControl
                  type="dropdown"
                  name="terminals"
                  multiple={true}
                  options={arraySort(TerminalOptions, "label")}
                  value={state.filterApplied.terminals}
                  disableCloseOnSelect
                  disableClearable={true}
                  onChange={(e, v) => handleFilter(e, v, "terminals")}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Terminal"
                      placeholder="Terminals"
                    />
                  )}
                />
                <DashBoardDateRangePicker
                  onChangeDate={onChangeDate}
                  isList={true}
                  range={state.date}
                  showRange={state.showDate}
                  isOpen={state.isCalenderOpen}
                  handleIsOpen={handleIsOpen}
                  handleDateChangeApply={handleDateChangeApply}
                />
              </Stack>
              <Button
                type="button"
                size="medium"
                variant="text"
                color="info"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => handleApplyFilter()}
              >
                Apply
              </Button>
            </>
          }
          onRowLink={(e) => {
            return `details/${e.id}`;
          }}
        />
      </ContentLayout>
    </>
  );
};
