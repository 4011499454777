import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import { useSearchParams } from "react-router-dom";

import {
  acceptInvitation,
  getInvitationDetails,
  registerProfile,
} from "../api/InvitationApi";

export const useInvitation = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const navigate = useNavigate();
  const { appState } = useContext(AppContext);
  const { globalData } = appState;
  const [state, setState] = useImmer({
    isBusy: false,
    isLoading: false,
    showPassword: false,
    user_exist: null,
    userDetails: {},
    data: {
      first_name: "",
      last_name: "",
      password: "",
      phone: "",
      country_code: 61,
    },
  });

  useEffect(() => {
    if (code) {
      getInvitationDetail();
    }
  }, [code]);
  const getInvitationDetail = async () => {
    try {
      setState((draft) => {
        draft.isLoading = true;
      });
      const res = await getInvitationDetails(code);
      if (res.success) {
        setState((draft) => {
          draft.user_exist = res.data.user_exist;
          draft.userDetails = res.data;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isLoading = true;
      });
    }
  };

  const AcceptInvitation = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });

      const res = await acceptInvitation(code);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
        });

        navigate("/invitation/success", { replace: true });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const onRegister = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });

      const res = await registerProfile(code, state.data);
      if (res.success) {
        successMessage("Invitation accepted successfully");
        setState((draft) => {
          draft.isBusy = false;
        });
        navigate("/invitation/success", { replace: true });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  const handleOnChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };
  const handleNavigate = () => {
    navigate(`/invitation/create-profile?code=${code}`);
  };
  const toggleShowPassword = (name) => {
    setState((draft) => {
      draft.showPassword = !state.showPassword;
    });
  };
  const onSubmit = (e) => {
    const hasUser = state.user_exist;
    hasUser === true ? AcceptInvitation() : handleNavigate();
  };
  return {
    state,
    AcceptInvitation,
    globalData,
    handleOnChange,
    onRegister,
    handleNavigate,
    toggleShowPassword,
    onSubmit,
  };
};
