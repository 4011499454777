import {
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React from "react";

export const AddCashDetailsTable = (props) => {
    const { data } = props
    const coinsDataList = data.filter((data) => data.cash_type === "coin")
    const cashDataList = data.filter((data) => data.cash_type === "cash")
    const coinTotal = coinsDataList.map((data) => Number(data.total_cash.replace(/\,/g, ''))).reduce((pv, cv) => pv + cv, 0)
    const cashTotal = cashDataList.map((data) => Number(data.total_cash.replace(/\,/g, ''))).reduce((pv, cv) => pv + cv, 0)

    return (
        <Stack sx={{ boxShadow: "none", flexDirection: "row" }}>
            <TableContainer sx={{
                borderRight: "1px solid #E6E6E6"
            }}>
                <Table aria-label="spanning table">
                    <TableHead>
                        <TableRow sx={{ outline: "1px solid #E6E6E6" }}>
                            <TableCell align="left" sx={{ borderRight: "1px solid #E6E6E6" }}>Coins</TableCell>
                            <TableCell align="left" >Count</TableCell>
                            <TableCell align="left" ></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {coinsDataList?.map((item, index) => {
                            return (
                                <>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                verticalAlign: "baseline",
                                                borderBottom: "1px solid #E6E6E6",
                                                borderRight: "1px solid #E6E6E6",
                                                p: "10px",
                                                backgroundColor: "rgba(0, 0, 0, 0.03)",
                                                p: "24.5px 10px",
                                                fontSize: '16px',
                                                fontWeight: 700,
                                            }}
                                            key={0}
                                        >
                                            {item.label}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                verticalAlign: "baseline",
                                                borderBottom: "1px solid #E6E6E6",
                                                borderRight: "1px solid #E6E6E6",
                                                fontSize: '16px'
                                            }}
                                            key={1}
                                        >
                                            {item.total_count}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                verticalAlign: "baseline",
                                                borderBottom: "1px solid #E6E6E6",
                                                fontSize: '16px'
                                            }}
                                            key={2}
                                        >
                                            =${item.total_cash}
                                        </TableCell>
                                    </TableRow>
                                </>
                            );
                        })}

                        <TableRow sx={{ outline: "1px solid #E6E6E6" }}>
                            <TableCell >
                            </TableCell>
                            <TableCell

                                colSpan={1}
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    p: "24.5px 10px",
                                }}
                                align="left"
                            >
                                Total Coin
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: 600,
                                }}
                                align="left"
                            >
                                =${coinTotal.toFixed(2)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer>
                <Table aria-label="spanning table">
                    <TableHead>
                        <TableRow sx={{ outline: "1px solid #E6E6E6" }}>
                            <TableCell align="left" sx={{ borderRight: "1px solid #E6E6E6" }}>Cash</TableCell>
                            <TableCell align="left" >Count</TableCell>
                            <TableCell align="left" ></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {cashDataList?.map((item, index) => {
                            return (
                                <>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                verticalAlign: "baseline",
                                                borderBottom: "1px solid #E6E6E6",
                                                borderRight: "1px solid #E6E6E6",
                                                backgroundColor: "rgba(0, 0, 0, 0.03)",
                                                p: "24.5px 10px",
                                                fontSize: '16px',
                                                fontWeight: 700,
                                            }}
                                            key={0}
                                        >
                                            {item.label}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                verticalAlign: "baseline",
                                                borderBottom: "1px solid #E6E6E6",
                                                borderRight: "1px solid #E6E6E6",

                                                fontSize: '16px'
                                            }}
                                            key={1}
                                        >
                                            {item.total_count}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                verticalAlign: "baseline",
                                                borderBottom: "1px solid #E6E6E6",

                                                fontSize: '16px'
                                            }}
                                            key={2}
                                        >
                                            =${item.total_cash}
                                        </TableCell>
                                    </TableRow>
                                </>
                            );
                        })}
                        <TableRow sx={{
                            height: "65.5px"
                        }}>
                        </TableRow>

                        <TableRow sx={{ outline: "1px solid #E6E6E6" }}>
                            <TableCell></TableCell>
                            <TableCell
                                colSpan={1}
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    p: "24.5px 10px",
                                }}
                                align="left"
                            >
                                Total Cash
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: 600,
                                }}
                                align="left"
                            >
                                =${cashTotal.toFixed(2)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
};
