import {
  Button,
  Card,
  Divider,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import { useParams } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { usePriceLevel } from "../hooks";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { useImmer } from "use-immer";
import { priceValidator, costValidator } from "../../../utils";
import { AsyncPaginate } from "react-select-async-paginate";

export const AddOrEditProducts = () => {
  const { id, productId } = useParams();
  const [, forceUpdate] = React.useState();

  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        price: priceValidator,
        cost: costValidator,
        endTime: {
          message: "the end date should not be before start date",
          rule: (value) => {
            const { startTime, endTime } = value;
            return moment(startTime).isSameOrBefore(moment(endTime));
          },
        },
      },
    })
  );
  const {
    state,
    handleOnChange,
    onStartDateChange,
    onEndDateChange,
    onSaveProductPriceLevel,
    handleDropdown,
    handleChange,
    onUpdateProductPriceLevel,
    isCreateVisible,
    isEditable,
    productState,
    handleGetProductsList,
  } = usePriceLevel({
    isPriceLevel: false,
    priceLevelProductIds: id,
    priceLevelProductId: id,
    PId: productId,
    isProduct: true,
  });
  const [validate, setValidate] = useImmer({
    validation: {
      isValidated: true,
      start_date: null,
      end_date: null,
    },
  });
  const filteredPoductListList = productState?.productsList.listData?.filter(
    (val) => !state.productData.map((val) => val.product_id).includes(val.id)
  );
  const option = filteredPoductListList?.map((item) => ({
    label: item.name,
    value: item.id,
    variants: item.variants,
  }));

  const onSubmit = () => {
    if (validator.current.allValid() && isAllValid()) {
      productId ? onUpdateProductPriceLevel(id) : onSaveProductPriceLevel();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
      setValidate((draft) => {
        draft.validation.isValidated = true;
      });
    }
  };

  const isDisable = state.pricelevelProductData.special_price <= 0;

  const validateDate = (reason, name, type) => {
    if (reason === "disablePast") {
      setValidate((draft) => {
        draft.validation[name] = "Please select a future date";
      });
    } else if (reason === "invalidDate") {
      setValidate((draft) => {
        draft.validation[name] =
          type === "date"
            ? "Selected Date is not a valid date"
            : "Selected Time is not valid";
      });
    } else {
      setValidate((draft) => {
        draft.validation[name] = null;
      });
    }
  };
  const isAllValid = () => {
    const { start_date, end_date } = validate.validation;
    if (!state.pricelevelProductData.special_price) {
      return true;
    } else if (start_date === null && end_date === null) {
      return true;
    } else {
      return false;
    }
  };

  const isValueValid = validator.current.message(
    "Product Name",
    state.pricelevelProductData.product_id,
    "required"
  );

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      minHeight: "55px",
      borderColor: isValueValid ? "#C60808" : "#E6E8F0",
      boxShadow: null,
      "&:hover": {
        borderColor: isValueValid ? "#C60808" : "#E6E8F0",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      height: "300px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: isValueValid ? "#C60808" : "#8e8e8e",
      };
    },
  };
  return (
    <>
      <ContentLayout
        title={state.priceLevelName}
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              onClick={onSubmit}
            >
              {productId ? "Update" : "Save"}
            </Button>
          </>
        }
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Price Levels", href: "/price-levels" },
              {
                name: state.priceLevelName + "-Products",
                href: `/price-levels/${id}`,
              },
              { name: productId ? "Edit Product" : "Add Product" },
            ]}
          />
        }
      >
        {productState?.productsList?.isLoading || state?.isBusy ? (
          <Loader />
        ) : (
          <Card sx={{ marginBottom: "20px" }}>
            {validator.current.purgeFields()}
            <Grid
              container
              sx={{
                height: "83px",
                padding: "32px 24px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item>
                <Typography
                  variant="h5"
                  fontSize={16}
                  sx={{
                    lineHeight: "19px",
                  }}
                >
                  {state.pricelevelProductData.product_name
                    ? state.pricelevelProductData.product_name
                    : "Add Product"}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Stack p="32px 24px">
              <Stack gap="24px">
                <Stack direction="row" width="100%">
                  {!productId && (
                    <>
                      <Box sx={{ width: "100%" }}>
                        <AsyncPaginate
                          debounceTimeout={1000}
                          menuPosition="fixed"
                          placeholder="Product*"
                          className="AsyncPaginate-dropdown"
                          classNamePrefix="react-select"
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          multiValue={false}
                          loadOptions={async (e, p, { page }) => {
                            const productData = await handleGetProductsList({
                              search: e,
                              page,
                            });
                            const filteredProductsData = {
                              ...productData,
                              options: productData.options.filter((option) => {
                                return !state.productData.find(
                                  (item) => item.product_id === +option.value
                                );
                              }),
                            };

                            return filteredProductsData;
                          }}
                          onChange={(e) => {
                            handleDropdown({}, e);
                          }}
                          additional={{
                            page: 1,
                          }}
                          styles={customStyles}
                        />

                        <FormHelperText sx={{ color: "#C60808" }}>
                          {validator.current.message(
                            "Product Name",
                            state.pricelevelProductData.product_id,
                            "required"
                          )}
                        </FormHelperText>
                      </Box>
                    </>
                  )}
                </Stack>
                <Stack direction="row" gap="20px" width="100%">
                  <InputControl
                    name="price"
                    label="Price"
                    value={state.pricelevelProductData.price}
                    onChange={(e) => handleOnChange(e)}
                    required
                    error={validator.current.message(
                      "Price",
                      state.pricelevelProductData.price,
                      "required|price"
                    )}
                    helperText={validator.current.message(
                      "Price",
                      state.pricelevelProductData.price,
                      "required|price"
                    )}
                  />

                  <InputControl
                    name="old_price"
                    label="Old Price"
                    value={state.pricelevelProductData.old_price}
                    onChange={(e) => handleOnChange(e)}
                    required
                    error={validator.current.message(
                      "Old Price ",
                      state.pricelevelProductData.old_price,
                      "required|price"
                    )}
                    helperText={validator.current.message(
                      "Old Price ",
                      state.pricelevelProductData.old_price,
                      "required|price"
                    )}
                  />
                </Stack>
                <Stack direction="row" gap="20px">
                  <InputControl
                    name="cost_exclusive_gst"
                    label="Cost Ex GST"
                    value={state.pricelevelProductData.cost_exclusive_gst}
                    onChange={(e) => handleOnChange(e)}
                    disabled={!state.pricelevelProductData?.product_id}
                    required
                    error={validator.current.message(
                      "Cost Ex GST",
                      state.pricelevelProductData.cost_exclusive_gst,
                      "required|cost"
                    )}
                    helperText={validator.current.message(
                      "Cost Ex GST",
                      state.pricelevelProductData.cost_exclusive_gst,
                      "required|cost"
                    )}
                  />
                  <InputControl
                    name="cost"
                    label="Cost In GST"
                    value={state.pricelevelProductData.cost}
                    onChange={(e) => handleOnChange(e)}
                    required
                    disabled={!state.pricelevelProductData?.product_id}
                    error={validator.current.message(
                      "Cost In GST",
                      state.pricelevelProductData.cost,
                      "required|cost"
                    )}
                    helperText={validator.current.message(
                      "Cost In GST",
                      state.pricelevelProductData.cost,
                      "required|cost"
                    )}
                  />
                </Stack>
              </Stack>
              <Divider
                sx={{
                  mt: "24px",
                }}
              />
              <Typography
                variant="subtitle2"
                fontWeight={600}
                lineHeight="17px"
                mt="20px"
              >
                Special Price
              </Typography>
              <Stack marginTop={3} direction="row" gap={2} width="100%">
                <InputControl
                  name="special_price"
                  label="Special price"
                  value={state.pricelevelProductData.special_price}
                  onChange={(e) => handleOnChange(e)}
                  error={
                    state.pricelevelProductData.special_price
                      ? validator.current.message(
                          "Special price",
                          state.pricelevelProductData.special_price,
                          "price"
                        )
                      : ""
                  }
                  helperText={
                    state.pricelevelProductData.special_price
                      ? validator.current.message(
                          "Special price",
                          state.pricelevelProductData.special_price,
                          "price"
                        )
                      : ""
                  }
                />

                <InputControl
                  type="datePicker"
                  name="special_price_start_date"
                  label="Start Date"
                  disablePast={true}
                  inputFormat="dd/MM/yyyy"
                  sx={{
                    width: "100%",
                  }}
                  value={
                    state.pricelevelProductData.special_price_start_date
                      ? state.pricelevelProductData.special_price_start_date
                      : null
                  }
                  disabled={isDisable}
                  onChange={(e) => {
                    onStartDateChange(e, "special_price_start_date");
                  }}
                  onError={(reason, value) =>
                    validateDate(reason, "start_date", "date")
                  }
                  error={
                    validate.validation.isValidated &&
                    (validate.validation.start_date ?? false)
                  }
                  helperText={
                    validate.validation.isValidated &&
                    (validate.validation.start_date ?? false)
                  }
                />

                <InputControl
                  type="datePicker"
                  name="special_price_end_date"
                  label="End Date"
                  disablePast={true}
                  inputFormat="dd/MM/yyyy"
                  disabled={isDisable}
                  sx={{
                    width: "100%",
                  }}
                  value={
                    state.pricelevelProductData.special_price_end_date
                      ? state.pricelevelProductData.special_price_end_date
                      : null
                  }
                  onChange={(e) => {
                    onEndDateChange(e, "special_price_end_date");
                  }}
                  onError={(reason, value) =>
                    validateDate(reason, "end_date", "date")
                  }
                  error={
                    validate.validation.isValidated &&
                    (validate.validation.end_date
                      ? validate.validation.end_date
                      : state.pricelevelProductData.special_price_start_date &&
                        validator.current.message(
                          "End Date",
                          state.pricelevelProductData
                            .special_price_start_date &&
                            !state.pricelevelProductData.special_price_end_date
                            ? null
                            : {
                                startTime:
                                  state.pricelevelProductData
                                    .special_price_start_date,
                                endTime:
                                  state.pricelevelProductData
                                    .special_price_end_date,
                              },
                          "required|endTime"
                        ))
                  }
                  helperText={
                    validate.validation.isValidated &&
                    (validate.validation.end_date
                      ? validate.validation.end_date
                      : state.pricelevelProductData.special_price_start_date &&
                        validator.current.message(
                          "End Date",
                          state.pricelevelProductData
                            .special_price_start_date &&
                            !state.pricelevelProductData.special_price_end_date
                            ? null
                            : {
                                startTime:
                                  state.pricelevelProductData
                                    .special_price_start_date,
                                endTime:
                                  state.pricelevelProductData
                                    .special_price_end_date,
                              },
                          "required|endTime"
                        ))
                  }
                />
              </Stack>
            </Stack>

            {state?.pricelevelProductData.variants &&
              state?.pricelevelProductData?.variants.length > 0 && (
                <Box
                  sx={{
                    p: "32px 24px",
                    width: "1,100",
                    backgroundColor: "#F7F7F7",
                    marginTop: 3,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    lineHeight="16px"
                    fontWeight={600}
                    sx={{ width: "100%" }}
                  >
                    Variants
                  </Typography>
                </Box>
              )}
            {state?.pricelevelProductData?.variants?.map((item, index) => {
              return (
                <div>
                  <Divider
                    sx={{ display: index === 0 && "none", mx: "24px" }}
                  />
                  <Stack sx={{ p: "8px 24px" }}>
                    <Stack
                      sx={{
                        py: "24px",
                        flexDirection: "row",
                        gap: "5px",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        lineHeight="16px"
                        sx={{ width: "100%", maxWidth: "288px" }}
                      >
                        {item.name}
                      </Typography>
                      <Stack width="100%" gap="24px">
                        <InputControl
                          name="price_adjustment"
                          label="Price Adjustment"
                          value={item?.price_adjustment}
                          onChange={(e) => {
                            handleChange("onChange", e, index);
                          }}
                          required
                          error={validator.current.message(
                            `price adjustment ${` `.repeat(index)}`,
                            item?.price_adjustment,
                            "required|price"
                          )}
                          helperText={validator.current.message(
                            `price adjustment ${` `.repeat(index)}`,
                            item?.price_adjustment,
                            "required|price"
                          )}
                        />
                        <Stack direction="row" gap="20px">
                          <InputControl
                            name="cost_adjustment_exclusive_gst"
                            label="Cost Adjustment Ex GST"
                            value={item?.cost_adjustment_exclusive_gst}
                            onChange={(e) => {
                              handleChange("onChange", e, index);
                            }}
                            required
                            error={validator.current.message(
                              `Cost Adjustment Ex GST ${` `.repeat(index)}`,
                              item?.cost_adjustment_exclusive_gst,
                              "required|cost"
                            )}
                            helperText={validator.current.message(
                              `Cost Adjustment Ex GST ${` `.repeat(index)}`,
                              item?.cost_adjustment_exclusive_gst,
                              "required|cost"
                            )}
                          />
                          <InputControl
                            name="cost_adjustment"
                            label="Cost Adjustment In GST"
                            value={item?.cost_adjustment}
                            onChange={(e) => {
                              handleChange("onChange", e, index);
                            }}
                            required
                            error={validator.current.message(
                              `Cost Adjustment In GST ${` `.repeat(index)}`,
                              item?.cost_adjustment,
                              "required|cost"
                            )}
                            helperText={validator.current.message(
                              `Cost Adjustment In GST ${` `.repeat(index)}`,
                              item?.cost_adjustment,
                              "required|cost"
                            )}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </div>
              );
            })}
          </Card>
        )}
      </ContentLayout>
    </>
  );
};
