import { API } from "../../../utils";

export const getCOAList = async () => {
    const res = await API.get(`coas`);
    return res.data;
};


export const getCOADetails = async (id) => {
    const res = await API.get(`coas/${id}`);
    return res.data;
};

export const getCOAParentList = async () => {
    const res = await API.get(`coas/parent-list`);
    return res.data;
};

export const createCOA = async (payload) => {
    const res = await API.post(`coas`, payload);
    return res.data;
};

export const updateCOA = async (id, payload) => {
    const res = await API.put(`coas/${id}`, payload);
    return res.data;
};

export const deleteCOAbyId = async (id) => {
    const res = await API.delete(`coas/${id}`);
    return res.data;
};