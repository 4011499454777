import { ContentLayout, HeaderBreadcrumbs, PageNotFound } from "../../../shared";
import * as React from "react";
import {
  Button,
  Stack,
  Card,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useParams } from "react-router-dom";

import { useOptionalGroup } from "../hooks/useOptionalGroup";
import SimpleReactValidator from "simple-react-validator";

export const AddOrEditOptionalGroup = () => {
  const { id } = useParams();
  const {
    state,
    onSaveOptionalGroup,
    onChange,
    onDropdown,
    onUpdateOptionalGroup,
    globalData,
  } = useOptionalGroup({
    isOptionalGroup: false,
    optionalId: id,
    isProduct: false,
  });
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdateOptionalGroup(id) : onSaveOptionalGroup();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }
  return (
    <>
      <ContentLayout
        title={id ? state.optionalGroupName : "New Group"}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Modifier Groups", href: "/optionalGroup" },
              id ? {
                name: state.optionalGroupName,
                href: `/optionalGroup/details/${id}`,
              } : null,
              { name: id ? "Edit" : "Create" },
            ].filter(Boolean)}
          />
        }
        rightContent={
          <>
            <Button
              onClick={() => onSubmit()}
              type="button"
              size="medium"
              variant="contained"
              disabled={state.isSaveButtonBusy}
            >
              {id ? "Update" : "Save"}
            </Button>
          </>
        }
      >
        <Card sx={{ p: "32px 24px", mb: "24px" }}>
          {validator.current.purgeFields()}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Details
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack width={"100%"} gap="20px">
                <InputControl
                  name="name"
                  label="Group Name"
                  value={state.data.name}
                  onChange={onChange}
                  required
                  error={validator.current.message(
                    "Group Name",
                    state.data.name,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "Group Name",
                    state.data.name,
                    "required"
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </ContentLayout>
    </>
  );
};
