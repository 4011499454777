import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import InputControl from "../../../shared/components/InputControl";
import AddIcon from "@mui/icons-material/Add";

export const AddChequeDetailsTable = (props) => {
  const {
    data,
    total_cheque,
    handleAddCheque,
    handleDeleteCheque,
    handleChequeInputChange,
    validator,
    eodTotal,
    variance = "",
  } = props;

  return (
    <Paper sx={{ boxShadow: "none" }}>
      {validator.current.purgeFields()}
      <TableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderRight: "1px solid #E6E6E6" }}>
                Cheque Name
              </TableCell>
              <TableCell sx={{ borderRight: "1px solid #E6E6E6" }}>
                Cheque Number
              </TableCell>
              <TableCell>Cheque Amount</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.map((item, index) => {
              return (
                <>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        borderRight: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                        p: "10px",
                      }}
                      key={1}
                    >
                      <InputControl
                        name="name"
                        label="Name"
                        value={item.name}
                        sx={{
                          width: "100%",
                        }}
                        onChange={(e) => handleChequeInputChange(e, index)}
                        error={validator.current.message(
                          "Name",
                          item.name,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Name",
                          item.name,
                          "required"
                        )}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        borderRight: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                        p: "10px",
                      }}
                      key={2}
                    >
                      <InputControl
                        name="number"
                        label="Cheque Number"
                        type="number"
                        value={item.number}
                        sx={{
                          width: "100%",
                        }}
                        onChange={(e) => handleChequeInputChange(e, index)}
                        error={validator.current.message(
                          "Cheque Number",
                          {
                            number: item.number,
                          },
                          "ChequeNumber"
                        )}
                        helperText={validator.current.message(
                          "Cheque Number",
                          {
                            number: item.number,
                          },
                          "ChequeNumber"
                        )}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        p: "10px",
                      }}
                      key={3}
                    >
                      <InputControl
                        name="amount"
                        label="Cheque Amount"
                        type="number"
                        value={item.amount}
                        width="80%"
                        onChange={(e) => handleChequeInputChange(e, index)}
                        error={validator.current.message(
                          "Cheque Amount",
                          {
                            amount: item.amount,
                          },
                          "ChequeAmount"
                        )}
                        helperText={validator.current.message(
                          "Cheque Amount",
                          {
                            amount: item.amount,
                          },
                          "ChequeAmount"
                        )}
                      />
                      <Button
                        variant="contained"
                        sx={{
                          minWidth: "40px",
                          maxWidth: "40px",
                          height: "40px",
                          mt: "4px",
                          ml: "10px",
                          bgcolor: "rgba(0, 0, 0, 0.03)",
                          "&:hover": {
                            bgcolor: "rgba(0, 0, 0, 0.06)",
                          },
                        }}
                        onClick={() => handleDeleteCheque(index)}
                      >
                        <img alt="delete" src="/icons/ic_delete.svg" />
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
            <TableRow>
              <TableCell
                colSpan={1}
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  borderRight: "1px solid #E6E6E6",
                  fontSize: "16px",
                  p: "14px",
                }}
                align="left"
              >
                <Button
                  type="button"
                  size="medium"
                  variant="text"
                  startIcon={<AddIcon />}
                  onClick={handleAddCheque}
                >
                  Add
                </Button>
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                }}
              ></TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={1}></TableCell>
              <TableCell
                // colSpan={1}
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  borderLeft: "1px solid #E6E6E6",
                  fontSize: "16px",
                }}
                align="left"
              >
                Cheque Total
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  fontWeight: "500",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                align="right"
              >
                ${total_cheque}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={1}></TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  borderLeft: "1px solid #E6E6E6",
                  fontSize: "16px",
                }}
              >
                Cheque Total from Eod
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                align="right"
              >
                ${eodTotal}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={1}></TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  borderLeft: "1px solid #E6E6E6",
                  fontSize: "16px",
                }}
              >
                Variance
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  fontSize: "16px",
                  fontWeight: 600,
                  color: variance < 0 ? "#C42B1C" : "#0F875B",
                }}
                align="right"
              >
                ${variance}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
