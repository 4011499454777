import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ServerPaginatedTable } from "../../../shared";
import { sortItems } from "../../../utils";
import InputControl from "../../../shared/components/InputControl";

export const LoyaltyPointHistory = (props) => {
  const {
    data,
    setPageSizePointHistory,
    gotoPagePointHistory,
    handleSortPointHistory,
    handleSearch,
  } = props;
  const { isLoading, dataList, pagination, filterApplied, sortBy } = data;
  const navigate = useNavigate();

  const ActionButtons = ({ id, type }) => {
    return (
      <>
        {type !== "REDCAT ADJUSTMENT" && (
          <div className="action-buttons">
            <img
              alt="details"
              src="/icons/arrow_forward.svg"
              onClick={() => navigate(`pointhistory/${id}`)}
            />
          </div>
        )}
      </>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Transaction ID",
        accessor: "id",
      },
      {
        Header: "Date & Time",
        accessor: "date_time",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Customer",
        accessor: "customer_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
        disableGlobalFilter: true,
        Cell: ({ value, row }) => {
          return (
            <div
              className="table-taxt-and-icon-link"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/customers/details/${row.original.customer_id}`);
              }}
            >
              <span className="link-text">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Order Number",
        accessor: "order_number",
        disableGlobalFilter: true,
        Cell: ({ value, row }) => {
          return (
            <div
              className="table-taxt-and-icon-link"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/orders/details/${row.original.order_id}`);
              }}
            >
              <span className="link-text">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Type",
        accessor: "type",
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return (
            <span style={{ textTransform: "capitalize" }}>
              {value?.toLowerCase()}
            </span>
          );
        },
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Points Accumulated",
        accessor: "accumulated",
        disableGlobalFilter: true,
      },
      {
        Header: "Points Redeemed",
        accessor: "redeemed",
        disableGlobalFilter: true,
      },
      {
        Header: "Running Total",
        accessor: "running_total",
        disableGlobalFilter: true,
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons id={row.id} type={row.type} />;
        },
      },
    ],
    []
  );

  return (
    <>
      <ServerPaginatedTable
        columns={columns}
        data={dataList}
        isLoading={isLoading}
        defaultSearch={false}
        gotoPage={gotoPagePointHistory}
        setPageSize={setPageSizePointHistory}
        pagination={pagination}
        handleSort={handleSortPointHistory}
        sortByGlobal={sortBy}
        filterContent={
          <InputControl
            type="table-search"
            value={filterApplied.searchText}
            onChange={(e) => handleSearch(e.target.value)}
            onClear={() => handleSearch("")}
          />
        }
        onRowLink={(e) => {
          if (e.type !== "REDCAT ADJUSTMENT") return `pointhistory/${e.id}`;
          return;
        }}
      />
    </>
  );
};
