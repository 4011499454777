import React from "react";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { createGrid, getAllProducts, getDetails, updateGrid } from "../api";
import { useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "../../../utils";

export const useSalesLayout = ({ id }) => {
  const navigate = useNavigate();
  const [state, setState] = useImmer({
    isBusy: false,
    isSaveButtonBusy: false,
    selectedCategory: 0,
    data: {
      name: "",
      grid_view: "image",
      categories: [
        {
          id: 1,
          name: "Savoury",
          no_of_grids: 24,
          items: [],
          garbage: [
            {
              id: 5,
              type: "product",
              name: "Cape Grim Beef",
              alias: null,
              image:
                "https://banjoslivestorage-ordering.s3.ap-southeast-2.amazonaws.com/products/PE8xNz0ZhLIFjYMNUtHcmRfkiZBBP1OLAwZHfyfk.jpeg",
              colour: "#02340c",
              font_color: "#FFFFFF",
              grid_color: null,
              price: "10.00",
              position: [0, 0],
            },
          ],
        },
      ],
    },
  });

  useEffect(() => {
    if (id) {
      getTemplateDetails(id);
    } else {
      getProducts();
    }
  }, [id]);

  const getTemplateDetails = async (id) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getDetails(id);
      const categories = res.data.categories.map((category) => {
        let greatesIndex = 0;
        category.items.forEach((prd) => {
          if (prd.position[0] * 8 + prd.position[1] > greatesIndex) {
            greatesIndex = prd.position[0] * 8 + prd.position[1];
          }
        });
        return { ...category, no_of_grids: greatesIndex + 2 };
      });
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.data = { ...res.data, categories };
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const getProducts = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getAllProducts();
      let data = res.data.map((category) => {
        const garbage =
          category.products.length > 0
            ? category.products.map((item) => ({
                id: item.id,
                name: item.name,
                grid_color: null,
                image: item.image,
                price: item.price,
                type: "product",
                position: [0, 0],
                alias: item.alias,
                colour: item.colour,
                font_color: "#FFFFFF",
              }))
            : [];
        return {
          id: category.id,
          name: category.name,
          no_of_grids: 24,
          items: [],
          garbage,
        };
      });
      data = { name: "", grid_view: "image", categories: data };
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.data = data;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const onUpdateGrid = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const { name, grid_view, categories } = state.data;
      let items = [];
      categories.forEach((category) => {
        category.items.forEach((product) => {
          items.push({
            product_id: product.id,
            position: product.position,
            color: product.grid_color,
            font_color: product.font_color ?? "#FFFFFF",
          });
        });
      });
      const payload = { name, grid_view, items: items, status: 1 };
      const res = await updateGrid(payload, id);
      if (res.success) {
        successMessage("Template updated Successfully");
        navigate(`/keypad`);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onSaveGrid = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const { name, grid_view, categories } = state.data;
      let items = [];
      categories.forEach((category) => {
        category.items.forEach((product) => {
          items.push({
            product_id: product.id,
            position: product.position,
            color: product.grid_color,
            font_color: product.font_color,
          });
        });
      });
      const payload = { name, grid_view, items: items, status: 1 };
      const res = await createGrid(payload);
      if (res.success) {
        successMessage("Template created Successfully");
        navigate(`/keypad`);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const changeCategory = (index) => {
    setState((draft) => {
      draft.selectedCategory = index;
    });
  };

  const addColumns = () => {
    setState((draft) => {
      draft.data.categories[draft.selectedCategory].no_of_grids =
        draft.data.categories[draft.selectedCategory].no_of_grids + 8;
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };

  const onChangeColor = (color, id, type) => {
    const updatedProducts = state.data.categories[
      state.selectedCategory
    ].items.map((item) => {
      if (item.id === id) {
        return { ...item, [type]: color };
      } else {
        return item;
      }
    });
    setState((draft) => {
      draft.data.categories[draft.selectedCategory].items = updatedProducts;
    });
  };

  const moveItemToGarbage = (item) => {
    setState((draft) => {
      const selectedCategory = draft.data.categories[draft.selectedCategory];
      draft.data.categories[draft.selectedCategory].garbage = [
        ...selectedCategory.garbage,
        selectedCategory.items.find((prd) => prd.id === item.productId),
      ];
      draft.data.categories[draft.selectedCategory].items =
        selectedCategory.items.filter((prd) => prd.id !== item.productId);
    });
  };

  const moveItemToGrid = (x, y, item, prds) => {
    if (item.type === "garbage") {
      const garbage = state.data.categories[state.selectedCategory].garbage;
      const updatedItem = garbage.find((prd) => prd.id === item.productId);
      const updatedGarbage = garbage.filter((prd) => prd.id !== item.productId);
      setState((draft) => {
        draft.data.categories[draft.selectedCategory].items = [
          ...draft.data.categories[draft.selectedCategory].items,
          {
            ...updatedItem,
            position: [x, y],
          },
        ];
        draft.data.categories[draft.selectedCategory].garbage = updatedGarbage;
      });
    } else {
      const updatedProducts = prds.map((prd) => {
        if (prd.id === item.productId) {
          return { ...prd, position: [x, y] };
        } else {
          return prd;
        }
      });
      setState((draft) => {
        draft.data.categories[draft.selectedCategory].items = updatedProducts;
      });
    }
  };
  return {
    state,
    changeCategory,
    moveItemToGarbage,
    addColumns,
    moveItemToGrid,
    onChange,
    onChangeColor,
    onSaveGrid,
    onUpdateGrid,
  };
};
