import {
  ContentLayout,
  CustomTable,
  DeleteModal,
  PageNotFound,
} from "../../../shared";
import * as React from "react";
import { Box, Button, Chip, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { sortItems } from "../../../utils";
import { useAvailabilityGroup } from "../hooks/useAvailabilityGroup";

export const AvaialabilityGroupList = () => {
  const navigate = useNavigate();
  const {
    state,
    toggleModal,
    handleDeleteAvailabilityGroup,
    isDeletable,
    setCurentTab,
    isCreateVisible,
  } = useAvailabilityGroup({
    isAvailable: true,
    isProduct: false,
    isStore: false,
  });

  const ActionButtons = ({ id, currentTabAction, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
        <img
          alt="details"
          onClick={currentTabAction}
          src="/icons/arrow_forward.svg"
        />
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Group Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Start Time",
        accessor: "start_time",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "End Time",
        accessor: "end_time",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Sales Channel",
        accessor: "sale_channels",
        disableGlobalFilter: true,
        disableSortBy: true,
        Cell: ({ cell }) => {
          const sale_channels = cell.row.original.sale_channels;
          return (
            <Box>
              {sale_channels?.slice(0, 5).map((data) => {
                return (
                  <Chip
                    sx={{ mr: 1, borderRadius: "8px" }}
                    label={data.name}
                    variant="filled"
                    size="small"
                  />
                );
              })}
              {sale_channels?.length > 5 && (
                <Chip
                  sx={{ borderRadius: "8px" }}
                  label={`+${
                    sale_channels?.length - sale_channels?.slice(0, 5)?.length
                  }`}
                  variant="filled"
                  size="small"
                />
              )}
            </Box>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              currentTabAction={() => {
                setCurentTab("availabilityGroupDetails", "1");
                navigate(`/availabilityGroup/details/${row.id}`);
              }}
              deleteAction={() => {
                toggleModal(row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );
  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }
  return (
    <>
      <ContentLayout
        title="Availability Group"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("/availabilityGroup/create")}
              >
                New Group
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={state.availabilityGroups}
          isLoading={state?.isBusy}
          tableId="availability_list"
          onRowLink={(e) => {
            return `/availabilityGroup/details/${e.id}`;
          }}
          onRowAction={() => {
            setCurentTab("availabilityGroupDetails", "1");
          }}
        />
      </ContentLayout>
      <DeleteModal
        open={state.isOpen}
        handleClose={() => toggleModal()}
        onConfirm={() => handleDeleteAvailabilityGroup()}
      />
    </>
  );
};
