import * as React from "react";
import {
  Checkbox,
  Chip,
  Divider,
  Stack,
  TextField,
  createFilterOptions,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { DialogTable } from "../../../shared";

export const ProductDialog = ({
  state,
  onChangeTagFilter,
  gotoPage,
  setPageSize,
}) => {
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });
  const columns = React.useMemo(
    () => [
      {
        Header: "Products",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "Tags",
        accessor: "bump_screen_tags",
        align: "right",
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <Stack direction="row" justifyContent="flex-end" gap="4px">
              {value?.map((i) => {
                return (
                  <Chip
                    sx={{ borderRadius: "8px" }}
                    label={i}
                    variant="filled"
                    size="small"
                  />
                );
              })}
            </Stack>
          );
        },
      },
    ],
    []
  );
  return (
    <Stack mt={3} spacing={2}>
      <Divider />

      <DialogTable
        isLoading={state.isLoading}
        defaultSearch={false}
        filterContent={
          <>
            <InputControl
              type="table-search"
              name="searchText"
              value={state.tagFilters.searchText}
              onChange={(e) => onChangeTagFilter(e)}
            />
            <InputControl
              type="dropdown"
              name="bump_screen_tags"
              multiple={true}
              options={
                state?.bumpScreenTags?.map((item) => ({
                  value: item,
                  label: item,
                })) || []
              }
              value={state.tagFilters.bump_screen_tags}
              disableCloseOnSelect
              disableClearable={true}
              onChange={(e, v) =>
                onChangeTagFilter({
                  target: {
                    name: "bump_screen_tags",
                    value: v,
                  },
                })
              }
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderTags={(value, getTagProps) => {
                const numTags = value.length;
                const limitTags = 2;

                return (
                  <>
                    {value.slice(0, limitTags).map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={index}
                        label={option.label}
                      />
                    ))}

                    {numTags > limitTags && ` +${numTags - limitTags}`}
                  </>
                );
              }}
              renderOption={(props, option, { selected }) => (
                <>
                  <li {...props} key={option.value}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option.label}
                  </li>
                </>
              )}
              filterOptions={filterOptions}
              componentsProps={{
                paper: {
                  sx: {
                    width: "fit-content",
                    minWidth: 250,
                  },
                },
              }}
              renderInput={(params) => <TextField {...params} label="Tags" />}
              sx={{
                "& .MuiAutocomplete-tag": {
                  alignContent: "inherit",
                  p: "1px",
                  maxWidth: "31%",
                },
              }}
            />
          </>
        }
        data={state?.productsList ?? []}
        columns={columns}
        gotoPage={gotoPage}
        setPageSize={setPageSize}
        pagination={state.pagination}
        sortByGlobal={[]}
      />
    </Stack>
  );
};
