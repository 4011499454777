import { Card, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import { useParams, useLocation } from "react-router-dom";
import { useRef } from "react";
import { LoyaltyTable } from "./LoyaltyTable";
import { useLoyalty } from "../hooks";

export const LoyaltyPointHistoryDetails = () => {
  const { pathname } = useLocation();
  const isFromCus = pathname.includes("pointTransaction");
  const printRef = useRef();
  const { id, cusID } = useParams();

  const { state } = useLoyalty({ isPointDetails: id });

  const { details, isLoading } = state.pointHistory;
  return (
    <>
      <ContentLayout
        title={details.customer_name || ""}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Loyalty Cards", href: "/loyalty-cards" },
                {
                  name: isFromCus ? "Cards" : "Point History",
                  href: isFromCus
                    ? `/loyalty-cards?cards`
                    : `/loyalty-cards?point-history`,
                },
                isFromCus
                  ? {
                      name: details?.customer_name,
                      href: `/loyalty-cards/details/${cusID}`,
                    }
                  : false,
                { name: isFromCus ? "Point History" : details?.customer_name },
              ].filter(Boolean)}
            />
          </>
        }
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Card sx={{ marginBottom: "20px" }} ref={printRef}>
              <Grid
                container
                sx={{
                  height: "83px",
                  padding: "32px 24px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    fontSize={16}
                    sx={{
                      lineHeight: "19px",
                    }}
                  >
                    Details
                  </Typography>
                </Grid>
              </Grid>
              <Divider />

              <Stack
                sx={{
                  p: "24px 16px 16px",
                  flexDirection: "row",
                  gap: "24px",
                }}
              >
                <Stack p="10px" gap="10px" width="100%">
                  <Typography fontWeight={500} variant="body2">
                    Customer Details
                  </Typography>
                  <Stack gap="6px">
                    <Typography variant="body2">
                      {details?.customer?.name}
                    </Typography>
                    <Typography variant="body2">
                      {details?.customer?.email}
                    </Typography>
                    <Typography variant="body2">
                      {details?.customer?.phone_formatted}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack p="10px" gap="10px" width="100%">
                  <Typography fontWeight={500} variant="body2">
                    Store Details
                  </Typography>
                  <Stack gap="6px">
                    <Typography variant="body2">
                      {details?.order?.store_name}
                    </Typography>
                    <Typography variant="body2">
                      Cashier: {details?.order?.finalised_by_user}
                    </Typography>
                    <Typography variant="body2">
                      Provider: {details?.order?.channel_name}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack p="10px" gap="10px" width="100%">
                  <Typography fontWeight={500} variant="body2">
                    Order Date
                  </Typography>
                  <Stack gap="6px">
                    <Typography variant="body2">
                      {details?.order?.order_date}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack p="10px" gap="10px" width="100%">
                  <Typography fontWeight={500} variant="body2">
                    Type
                  </Typography>
                  <Stack gap="6px">
                    <Typography variant="body2" textTransform="capitalize">
                      {details?.type?.toLowerCase()}
                    </Typography>
                    <Typography variant="body2" textTransform="capitalize">
                      Payment Media: {details?.order?.payment_type}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Divider />
              <LoyaltyTable data={details} />
              <Divider />
            </Card>
          </>
        )}
      </ContentLayout>
    </>
  );
};
