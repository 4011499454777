import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import { ListDetails } from "./containers/ListDetails";
import { UploadList } from "./containers/UploadList";

export const List = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ListDetails />} />

        <Route
          element={<ProtectedRoute allowedRoute={["pl-bs-account-create"]} />}
        >
          <Route path="/create" element={<UploadList />} />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["pl-bs-account-modify"]} />}
        >
          <Route path="/edit/:id" element={<UploadList />} />
        </Route>
      </Routes>
    </>
  );
};
