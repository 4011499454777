import { API } from "../../../utils";

export const getFranchisees = async () => {
  const res = await API.get("franchisees");
  return res.data;
};

export const deleteFranchiseeById = async (id) => {
  const res = await API.delete(`franchisees/${id}`);
  return res.data;
};
export const createFranchisee = async (payload) => {
  const res = await API.post(`franchisees`, payload);
  return res.data;
};
export const getFranchiseeDetails = async (id) => {
  const res = await API.get(`franchisees/${id}`);
  return res.data;
};

export const editFranchisee = async (id, payload) => {
  const res = await API.put(`franchisees/${id}`, payload);
  return res.data;
};
