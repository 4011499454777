import {
  CustomTable,
  CustomeDialog,
  Loader,
  ServerPaginatedTable,
} from "../../../shared";
import * as React from "react";
import { Button, Chip, Stack } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { ShareGiftCard } from "../components";
import { sortStatus } from "../../../utils";

export const PreloadedCardGiftCardDetailsList = (prop) => {
  const {
    allBatchCards,
    handleShareGiftCards,
    state,
    onEmailChange,
    toggleModal,
    handleDownloadSingleCards,
    globalData,
    onChangeFilter,
    resetFilters,
    handleSort,
    gotoPage,
    setPageSize,
  } = prop;
  const ActionButtons = ({ row, shareAction, downloadAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {row.card_status !== 0 && (
          <img alt="share" onClick={shareAction} src="/icons/ic_share.svg" />
        )}
        <img
          alt="print"
          onClick={downloadAction}
          src="/icons/ic_export_black.svg"
        />
        <Link to={`/gift-card/details/allCards/${row.id}`}>
          <img alt="details" src="/icons/arrow_forward.svg" />
        </Link>
      </div>
    );
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Barcode",
        accessor: "barcode",
      },
      {
        Header: "Card Number",
        accessor: "card_number",
      },
      {
        Header: "Status",
        accessor: "card_status",
        Cell: ({ value }) => {
          return (
            <>
              {value === 4 && (
                <Chip
                  label={"Blocked"}
                  color="warning"
                  variant="outlined"
                  size="small"
                  sx={{
                    border: "none !important",
                    bgcolor: "#FFF4CE",
                  }}
                />
              )}
              {value === 1 && (
                <Chip
                  label={"Active"}
                  color="success"
                  variant="outlined"
                  size="small"
                  disabled={false}
                />
              )}
              {value === 3 && (
                <Chip
                  label={"Redeemed"}
                  color="primary"
                  variant="outlined"
                  size="small"
                  sx={{ bgcolor: "#1560D414", border: "none !important" }}
                  disabled={false}
                />
              )}

              {value === 0 && (
                <Chip
                  label={"Inactive"}
                  color="success"
                  variant="outlined"
                  size="small"
                  disabled={"filled"}
                />
              )}
              {value === 2 && (
                <Chip
                  label={"Expired"}
                  color={"error"}
                  variant="outlined"
                  size="small"
                  sx={{
                    border: "none !important",
                  }}
                />
              )}
            </>
          );
        },
        sortType: (prev, curr, columnId) => {
          return sortStatus(
            globalData.gift_cards?.status.find(
              (e) => e.value === prev.original[columnId]
            ).label,
            globalData.gift_cards?.status.find(
              (e) => e.value === curr.original[columnId]
            ).label
          );
        },
      },
      {
        Header: "Card Value",
        accessor: "card_value",
        Cell: ({ value }) => {
          return <Stack direction="row">${value.toFixed(2)}</Stack>;
        },
      },
      {
        Header: "Balance Value",
        accessor: "balance_value",
        Cell: ({ value }) => {
          return <Stack direction="row">${value.toFixed(2)}</Stack>;
        },
      },

      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              row={row}
              shareAction={() => {
                toggleModal(true, row.id);
              }}
              downloadAction={() => {
                handleDownloadSingleCards(row.id);
              }}
            />
          );
        },
      },
    ],
    [globalData]
  );
  return (
    <>
      <ServerPaginatedTable
        columns={columns}
        data={allBatchCards?.gift_cards || []}
        isLoading={state.isBusy}
        defaultSearch={false}
        gotoPage={gotoPage}
        setPageSize={setPageSize}
        pagination={state.allBatchCardspagination}
        handleSort={handleSort}
        sortByGlobal={state?.allBatchCardssortBy}
        key={state?.allBatchCardssortBy}
        filterContent={
          <>
            <InputControl
              type="table-search"
              name="searchText"
              placeholder="Search"
              size="small"
              value={state.detailsfilters.searchText}
              onChange={(e) => onChangeFilter(e)}
            />

            <InputControl
              type="select"
              name="status"
              optionValue={globalData?.gift_cards?.status ?? []}
              onChange={(e) => onChangeFilter(e)}
              value={state.detailsfilters.status}
              label="Status"
              width="250px"
            />
            <Button
              type="button"
              size="medium"
              variant="text"
              color="gray"
              onClick={(e) => resetFilters(e)}
              sx={{
                minWidth: "40px",
                p: "8px",
              }}
            >
              <CloseIcon />
            </Button>
          </>
        }
        onRowLink={(e) => {
          return `/gift-card/details/allCards/${e.id}`;
        }}
      />
      <CustomeDialog
        open={state.isModalOpen}
        handleClose={() => toggleModal(false, null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "618px", height: "251px", p: "16px 24px" },
        }}
        title="Share Gift Card"
        content={
          <ShareGiftCard
            handleShareGiftCards={handleShareGiftCards}
            state={state}
            onEmailChange={onEmailChange}
          />
        }
      />
    </>
  );
};
