import React, { useState } from "react";
import { Chip, Typography, Button, Stack } from "@mui/material";
import { CustomDetailsList, CustomeDialog, Loader } from "../../../shared";
import { GroupNameDialog } from "./GroupNameDialog";

export const FavouritesBasicDetails = ({
  onChangeFavGroup,
  state,
  onSaveFavGroup,
  onUpdateFavGroup,
  toggleCreateOrEditModal,
  isEditable,
  isStoreAdmin,
}) => {
  const columns = [
    {
      title: "Group Name",
      accessor: (val) => {
        return (
          <Stack direction="row" alignItems="center" gap="10px">
            <Typography variant="subtitle2" fontWeight={400} color="#636363">
              {val.name}
            </Typography>
            {isEditable && (
              <Button
                color="info"
                onClick={() =>
                  toggleCreateOrEditModal(state.favDetails.data.id, "edit")
                }
                sx={{
                  minWidth: "40px",
                  p: "8px",
                  opacity:
                    isStoreAdmin &&
                    state.favDetails.data.type === "global" &&
                    0.9,
                }}
                disabled={
                  isStoreAdmin && state.favDetails.data.type === "global"
                }
              >
                Edit
              </Button>
            )}
          </Stack>
        );
      },
    },
    {
      title: "Created By",
      accessor: "user_name",
    },
  ];

  return (
    <>
      {false ? (
        <Loader />
      ) : (
        <CustomDetailsList
          columns={columns}
          data={state.favDetails.data}
          title="Details"
        />
      )}
      <CustomeDialog
        open={state.isEditOpen}
        handleClose={() => toggleCreateOrEditModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "423px", height: "235px", p: "16px" },
        }}
        title="Group"
        content={
          <GroupNameDialog
            onChangeFavGroup={onChangeFavGroup}
            state={state}
            onSaveFavGroup={onSaveFavGroup}
            onUpdateFavGroup={onUpdateFavGroup}
            isEdit={true}
          />
        }
      />
    </>
  );
};
