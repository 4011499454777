import { API } from "../../../utils";

// GET request to fetch product-configuration-details
export const getProductConfigurationDetailsApi = async () => {
  const response = await API.get(`/product-review-settings`);
  return response.data;
};

// POST request to update product-configuration-details
export const updateProductConfigurationDetailsApi = async (configuration) => {
  const response = await API.post("/product-review-settings", {
    ...configuration,
  });
  return response.data;
};

// GET request to fetch users list
export const getAllUsersApi = async (query) => {
  const response = await API.get(`gift_card_users`, query);
  return response.data;
};
