import { ContentLayout, CustomTable, DeleteModal, Loader, usePermission } from "../../../shared";
import * as React from "react";
import { Button, Stack, Dialog, Typography, FormControl, OutlinedInput, InputAdornment, Box, FormControlLabel, Checkbox, Radio } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { LedgerAccountTable } from "./LedgerAccountTable";


export const LedgerAccountDialog = (props) => {
    const {
        dataList,
        loading,
        selectedIDs = [],
        handleCheckAccount,
        onSubmit,
        handleChecked,
        onSearch,
        searchText,
        isSaveButton,
        isConfig = false,
        handleDisable,
    } = props

    const columns = React.useMemo(
        () => [
            {
                Header: () => {
                    return <span style={{ marginLeft: `-15px` }}>Account Name</span>;
                },
                accessor: "name",
                Cell: ({ row, value }) => {

                    return (
                        <>

                            <Stack
                                direction="row"
                                alignItems="center"
                                gap="4px"
                                sx={{
                                    fontWeight: row.depth ? 400 : 600
                                }}
                            >
                                <div style={{ marginLeft: `${row.depth * 32 - 15}px`, height: "24px" }}>
                                    {row.canExpand ? (
                                        <span
                                            {...row.getToggleRowExpandedProps({
                                                style: {
                                                    paddingLeft: `0px 10px`,
                                                },
                                            })}
                                        >
                                            <img
                                                height="24px"
                                                alt="forward"
                                                className={`${row.isExpanded && "expanded-icon"}`}
                                                src="/icons/ic_forward.svg"
                                            />
                                        </span>
                                    ) : null}
                                </div>
                                <span style={{ marginLeft: (!row.canExpand && !row.depth) && "24px" }}>{row.original.name || value}</span>
                            </Stack>
                        </>
                    );
                },
                disableSortBy: true,

            },
            {
                Header: "Account Code",
                accessor: "code",
                disableSortBy: true,
            },
            {
                id: "action",
                padding: "checkbox",
                align: "left",
                disableSortBy: true,
                Cell: ({ row }) => {
                    return (
                        <Stack
                            sx={{
                                flexDirection: "row ",
                            }}
                        >
                            {isConfig ? (<>
                                <Radio
                                    checked={selectedIDs.includes(row.original.id) ? true : false}
                                    onClick={() => {
                                        handleCheckAccount(row.original.id)
                                    }}

                                    name="radio-buttons"
                                    sx={{
                                        p: "3.5px",
                                    }}
                                />
                            </>) : (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={handleChecked(row)}
                                            onChange={(e) => {
                                                handleCheckAccount(e, row);
                                            }}
                                            color="primary"
                                            sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                                            disabled={handleDisable(row)}
                                        />
                                    }
                                />
                            )}
                        </Stack>
                    );
                },
            },
        ],
        [selectedIDs]
    );


    return (
        <>

            {loading ? (
                <Loader />
            ) : (
                <Stack
                    justifyContent="space-between"
                    height="100%"
                    gap="8px"
                    maxHeight={"calc(100% - 24px)"}
                >
                    {true && (
                        <FormControl
                            size="small"
                            fullWidth={true}
                            sx={{
                                pt: "24px",
                            }}
                        >
                            <OutlinedInput
                                placeholder="Search"
                                sx={{
                                    height: "48px",
                                    ".MuiOutlinedInput-input": {
                                        pl: "0px !important",
                                    },
                                }}
                                size="small"
                                value={searchText}
                                onChange={(e) => onSearch(e.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon size="large" />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    searchText &&

                                    (
                                        <InputAdornment
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => onSearch('')}
                                            position="end"
                                        >
                                            <CloseIcon size="large" />
                                        </InputAdornment>
                                    )
                                }
                                fullWidth
                            />
                        </FormControl>
                    )}
                    <Box sx={{ height: "100%", overflow: "auto" }} fullWidth>
                        <LedgerAccountTable
                            columns={columns}
                            data={dataList}
                            subRow={'subData'} />
                    </Box>
                    <Button
                        sx={{ height: "48px", alignSelf: "self-end", width: "172px" }}
                        variant="contained"
                        fullWidth
                        onClick={onSubmit}
                        disabled={!isSaveButton && !selectedIDs.length > 0}
                    >
                        Save
                    </Button>
                </Stack>
            )}
        </>
    );
};
