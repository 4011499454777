import * as React from "react";
import {
  Typography,
  Button,
  Stack,
  FormControlLabel,
  Checkbox,
  Avatar,
} from "@mui/material";
import InputControl from "./InputControl";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SimpleReactValidator from "simple-react-validator";

export const ResetPasswordDialog = (props) => {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const { data, handleResetPassword, handlePasswordCopy } = props;
  const onSubmit = () => {
    if (validator.current.allValid()) {
      handleResetPassword("submit");
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  return (
    <>
      {!data.slide ? (
        <Stack
          justifyContent="space-between"
          height="100%"
          gap="16px"
          maxHeight={"calc(100% - 24px)"}
          mt="32px"
        >
          <Typography fontWeight={400} variant="subtitle2">
            Do you want to send the temporary password to your email?
          </Typography>
          <FormControlLabel
            sx={{
              ml: 0,
            }}
            control={
              <Checkbox
                sx={{
                  p: "0 10px 0 0",
                }}
                focusRipple={false}
                checked={data.isDisabled}
                onChange={(e) =>
                  handleResetPassword("disable", e.target.checked)
                }
              />
            }
            label={
              <Typography fontWeight={400} variant="subtitle2">
                Yes please.
              </Typography>
            }
          />
          <InputControl
            name="email"
            label="Mail address"
            value={data.email}
            disabled={!data.isDisabled}
            onChange={(e) => handleResetPassword("email", e.target.value)}
            error={validator.current.message(
              "Email Address",
              data.email,
              "required|email"
            )}
            helperText={validator.current.message(
              "Email Address",
              data.email,
              "required|email"
            )}
          />

          <Button
            sx={{ width: "86px", ml: "auto" }}
            variant="contained"
            onClick={onSubmit}
          >
            Confirm
          </Button>
        </Stack>
      ) : (
        <Stack
          justifyContent="space-between"
          height="100%"
          gap="16px"
          maxHeight={"calc(100% - 24px)"}
          mt="32px"
        >
          <Stack direction="row" gap="10px" alignItems="center">
            <InputControl
              name="pass"
              label="Temporary Password"
              value={data.temporaryPassword}
              disabled
              InputProps={{
                sx: {
                  bgcolor: "rgba(0, 0, 0, 0.03)",
                },
              }}
            />

            <Avatar
              sx={{
                height: 46,
                width: 46,
                bgcolor: " rgba(0, 0, 0, 0.03)",
                cursor: "pointer",
                "&:hover": {
                  bgcolor: "rgba(0,0,0, 0.08)",
                },
              }}
              variant="rounded"
              onClick={handlePasswordCopy}
            >
              <ContentCopyIcon
                sx={{
                  color: "#000",
                }}
              />
            </Avatar>
          </Stack>
        </Stack>
      )
      }
    </>
  );
};
