import React from "react";
import { Route, Routes } from "react-router-dom";
import {  ReportList } from "..";

export const Report = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ReportList />} />
      </Routes>
    </>
  );
};
