import { API } from "../../../utils";

export const getSurcharges = async (search) => {
  let url = "surcharges";
  if (search) {
    url += `?search=${search}`;
  }
  const res = await API.get(url);
  return res.data;
};
export const createSurcharges = async (payload) => {
  const res = await API.post(`surcharges`, payload);
  return res.data;
};
export const editSurcharges = async (id, payload) => {
  const res = await API.put(`surcharges/${id}`, payload);
  return res.data;
};
export const getSurchargesDetails = async (id) => {
  const res = await API.get(`surcharges/${id}`);
  return res.data;
};

export const deleteSurChargesById = async (id) => {
  const res = await API.delete(`surcharges/${id}`);
  return res.data;
};