import {
  ContentLayout,
  HeaderBreadcrumbs,
  Loader,
  PageNotFound,
  useDropdownApis,
} from "../../../shared";
import * as React from "react";
import {
  Button,
  Stack,
  Card,
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Divider,
  FormHelperText,
  Box,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useLocation, useParams } from "react-router-dom";
import { useCustomers } from "../hooks";
import SimpleReactValidator from "simple-react-validator";
import { AsyncPaginate } from "react-select-async-paginate";

export const CreateOrEditCustomers = () => {
  const { id } = useParams();
  const { statesList, handleSuburbList } = useDropdownApis({
    isStatesList: true,
  });
  const updateMode = id ? true : false;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <Stack
        ref={innerRef}
        {...innerProps}
        sx={{
          flexDirection: "row",
          p: "7px 8px",
          gap: "2px",
          "&:hover": {
            backgroundColor: "#edf3fc",
          },
        }}
      >
        <Stack
          sx={{
            gap: "2px",
          }}
        >
          <Typography variant="subtitle2" fontWeight={400} lineHeight="17px">
            {data.label}
          </Typography>
          <Typography color="#666666" variant="caption" lineHeight="15px">
            {data.state}
          </Typography>
        </Stack>
      </Stack>
    );
  };
  const {
    onChange,
    state,
    onSaveCustomer,
    onUpdateCustomer,
    globalData,
    onDateChange,
    validateDate,
    setState,
    isStoreAdmin,
    onChangeBusinessUser,
  } = useCustomers({
    load: false,
    id: id,
  });
  const { pathname } = useLocation();
  const isClient = pathname.includes("client-account");
  const {
    isSaveButtonBusy,
    customerName,
    httpStatusCodes,
    validation,
    isBussiness,
    isDetailsFetching,
  } = state;
  const {
    first_name,
    last_name,
    email,
    country_code,
    phone,
    date_of_birth,
    gender,
    status,
    is_business_user,
    opt_in,
    business_name,
    abn_number,
    have_a_card,
    member_number,
    verification_code,
  } = state.data;

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: "2px solid #E6E8F0",
      height: "48px",
    }),
    option: (provided, state) => ({
      ...provided,
      maxHeight: "239px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
  };

  const onSubmit = () => {
    if (validator.current.allValid() && !validation.date_of_birth) {
      updateMode ? onUpdateCustomer(id) : onSaveCustomer();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
      setState((draft) => {
        draft.validation.isValidated = true;
      });
    }
  };
  if (httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }

  return (
    <>
      <ContentLayout
        title={updateMode ? customerName : "New Customer"}
        rightContent={
          <>
            <Button
              onClick={() => onSubmit()}
              type="button"
              size="medium"
              variant="contained"
              disabled={isSaveButtonBusy}
            >
              {id ? "Update" : "Save"}
            </Button>
          </>
        }
        breadcrumbs={
          !id ? (
            <>
              <HeaderBreadcrumbs
                links={
                  !isClient
                    ? [
                        {
                          name: "Dashboard",
                          href: isStoreAdmin ? "/store-admin" : "/",
                        },
                        {
                          name: "Customers",
                          href: isStoreAdmin
                            ? "store-admin/customers"
                            : "/customers",
                        },

                        { name: "create" },
                      ]
                    : [
                        {
                          name: "Dashboard",
                          href: isStoreAdmin ? "/store-admin" : "/",
                        },
                        {
                          name: "Client Account",
                          href: isStoreAdmin
                            ? "/store-admin/client-account"
                            : "/client-account",
                        },

                        {
                          name: "Client Account Create",
                          href: isStoreAdmin
                            ? "/store-admin/client-account/create"
                            : "/client-account/create",
                        },
                        { name: "Create" },
                      ]
                }
              />
            </>
          ) : (
            <>
              <HeaderBreadcrumbs
                links={[
                  {
                    name: "Dashboard",
                    href: isStoreAdmin ? "/store-admin" : "/",
                  },
                  {
                    name: "Customers",
                    href: isStoreAdmin
                      ? "/store-admin/customers"
                      : "/customers",
                  },
                  {
                    name: customerName,
                    href: isStoreAdmin
                      ? `/store-admin/customers/details/${id}`
                      : `/customers/details/${id}`,
                  },
                  { name: "Edit" },
                ]}
              />
            </>
          )
        }
      >
        {id && isDetailsFetching ? (
          <Loader />
        ) : (
          <>
            {validator.current.purgeFields()}
            <Card sx={{ p: "32px 24px", mb: "24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Basic Details
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack width={"100%"} gap="20px">
                    <Stack direction="row" gap="20px">
                      <InputControl
                        name="first_name"
                        label="First Name"
                        value={first_name}
                        onChange={onChange}
                        required
                        error={validator.current.message(
                          "First Name",
                          first_name,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "First Name",
                          first_name,
                          "required"
                        )}
                      />
                      <InputControl
                        name="last_name"
                        label="Last Name"
                        required
                        value={last_name}
                        onChange={onChange}
                        error={validator.current.message(
                          "Last Name",
                          last_name,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Last Name",
                          last_name,
                          "required"
                        )}
                      />
                    </Stack>
                    <InputControl
                      name="email"
                      label="Email Address"
                      value={email}
                      onChange={onChange}
                      required
                      error={validator.current.message(
                        "Email",
                        email,
                        "required|email"
                      )}
                      helperText={validator.current.message(
                        "Email",
                        email,
                        "required|email"
                      )}
                    />
                    <Stack direction="row" gap="20px">
                      <InputControl
                        type="dropdown"
                        id="country_code"
                        disableClearable
                        options={globalData.country_codes ?? []}
                        getOptionLabel={(option) => option.label}
                        value={
                          globalData?.country_codes?.length > 0 && country_code
                            ? globalData?.country_codes?.find(
                                (c) => c.value === country_code
                              )
                            : null
                        }
                        onChange={(e, newValue) => {
                          onChange({
                            target: {
                              name: "country_code",
                              value: newValue.value,
                            },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country code"
                            required
                            error={validator.current.message(
                              "Country Code",
                              country_code,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Country Code",
                              country_code,
                              "required"
                            )}
                          />
                        )}
                      />

                      <InputControl
                        name="phone"
                        label="Phone Number"
                        type="text"
                        value={phone}
                        onChange={onChange}
                        required
                        error={validator.current.message(
                          "Phone Number",
                          phone,
                          "required|phone|max:10"
                        )}
                        helperText={validator.current.message(
                          "Phone Number",
                          phone,
                          "required|phone|max:10"
                        )}
                      />
                    </Stack>

                    <InputControl
                      type="datePicker"
                      name="date_of_birth"
                      label="Date Of Birth"
                      inputFormat="dd/MM/yyyy"
                      disableFuture
                      value={date_of_birth}
                      onChange={(value, keyboardInputValue) =>
                        onDateChange(value, keyboardInputValue)
                      }
                      onError={(reason, value) =>
                        validateDate(reason, "date_of_birth")
                      }
                      error={
                        state.validation.isValidated &&
                        state.validation.date_of_birth
                      }
                      helperText={
                        state.validation.isValidated &&
                        state.validation.date_of_birth
                      }
                    />
                    <InputControl
                      type="dropdown"
                      disableClearable
                      id="gender"
                      getOptionLabel={(option) => option.label}
                      value={
                        globalData?.gender?.length > 0 && gender
                          ? globalData.gender.find((c) => c.value === gender)
                          : null
                      }
                      options={globalData.gender ?? []}
                      onChange={(e, newValue) => {
                        onChange({
                          target: { name: "gender", value: newValue.value },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Gender"
                          required
                          error={validator.current.message(
                            "Gender",
                            gender,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "Gender",
                            gender,
                            "required"
                          )}
                        />
                      )}
                    />
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 1, sm: 11 }}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Stack spacing={1}>
                        <h4>Status</h4>
                      </Stack>
                      <InputControl
                        type="switch"
                        value={status}
                        onChange={(e) =>
                          onChange({
                            target: {
                              name: "status",
                              value: e.target.checked ? 1 : 0,
                            },
                          })
                        }
                      />
                    </Stack>
                    <FormControlLabel
                      sx={{ mx: 0 }}
                      control={
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          checked={opt_in}
                          onChange={(e) =>
                            onChange({
                              target: {
                                name: "opt_in",
                                value: e.target.checked ? 1 : 0,
                              },
                            })
                          }
                        />
                      }
                      label="Opt-In"
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Divider sx={{ m: "16px -24px" }} />
              {!id && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      sx={{ width: "150px" }}
                    >
                      Do you have a card?
                    </Typography>
                  </Grid>
                  <Grid item sm={8}>
                    <Stack gap="20px">
                      <InputControl
                        type="switch"
                        value={have_a_card}
                        onChange={(e) =>
                          onChange({
                            target: {
                              name: "have_a_card",
                              value: e.target.checked ? 1 : 0,
                            },
                          })
                        }
                      />
                      {!!have_a_card && (
                        <Stack direction="row" gap="20px">
                          <InputControl
                            name="member_number"
                            label="Member Number"
                            value={state?.data?.member_number}
                            onChange={onChange}
                            error={validator.current.message(
                              "Member Number",
                              member_number,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Member Number",
                              member_number,
                              "required"
                            )}
                            required
                          />
                          <InputControl
                            name="verification_code"
                            label="Verification Code"
                            value={state.data.verification_code}
                            onChange={onChange}
                            error={validator.current.message(
                              "Verification Code",
                              verification_code,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Verification Code",
                              verification_code,
                              "required"
                            )}
                            required
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              )}
              <Divider sx={{ m: "16px -24px" }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Business User
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack gap="20px">
                    <InputControl
                      type="switch"
                      value={is_business_user}
                      onChange={(e) =>
                        onChange({
                          target: {
                            name: "is_business_user",
                            value: e.target.checked ? 1 : 0,
                          },
                        })
                      }
                    />
                    {!!isBussiness && (
                      <>
                        <InputControl
                          name="business_name"
                          label="Business Name"
                          value={business_name}
                          onChange={onChange}
                          required
                          error={validator.current.message(
                            "Business Name",
                            business_name,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "Business Name",
                            business_name,
                            "required"
                          )}
                          disabled={!is_business_user && id}
                        />
                        <InputControl
                          name="abn_number"
                          label="ABN"
                          value={abn_number}
                          onChange={onChange}
                          required
                          error={validator.current.message(
                            "ABN",
                            abn_number,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "ABN",
                            abn_number,
                            "required"
                          )}
                          disabled={!is_business_user && id}
                        />
                        <Stack direction="row" gap="20px">
                          <InputControl
                            name="unit_number"
                            label="Unit Number"
                            type="number"
                            value={state.data.business_address.unit_number}
                            onChange={onChangeBusinessUser}
                            disabled={!is_business_user && id}
                          />
                          <InputControl
                            name="street_number"
                            label="Street Number"
                            value={state.data.business_address.street_number}
                            onChange={onChangeBusinessUser}
                            disabled={!is_business_user && id}
                            error={validator.current.message(
                              "Street Number",
                              state.data.business_address.street_number,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Street Number",
                              state.data.business_address.street_number,
                              "required"
                            )}
                            required
                          />
                        </Stack>
                        <Stack direction="row" gap="20px">
                          <InputControl
                            name="street_name"
                            label="Street Name"
                            value={state.data.business_address.street_name}
                            onChange={onChangeBusinessUser}
                            required
                            error={validator.current.message(
                              "Street Name",
                              state.data.business_address.street_name,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Street Names",
                              state.data.business_address.street_name,
                              "required"
                            )}
                            disabled={!is_business_user && id}
                          />
                          <Box width="100%">
                            <AsyncPaginate
                              debounceTimeout={1000}
                              placeholder="Suburb *"
                              menuPlacement="top"
                              isDisabled={!is_business_user && id}
                              components={{
                                Option: CustomOption,
                              }}
                              value={
                                state.data.business_address.suburb
                                  ? {
                                      value: state.data.business_address.suburb,
                                      label: state.data.business_address.suburb,
                                    }
                                  : ""
                              }
                              loadOptions={async (e, p, { page }) => {
                                return await handleSuburbList({
                                  search: e,
                                  page,
                                  type: "suburb",
                                });
                              }}
                              onChange={(e) => {
                                onChangeBusinessUser(
                                  {
                                    target: {
                                      name: "suburb",
                                      value: e.value || null,
                                    },
                                  },
                                  e
                                );
                              }}
                              additional={{
                                page: 1,
                              }}
                              styles={customStyles}
                            />
                            <FormHelperText sx={{ color: "#C60808" }}>
                              {validator.current.message(
                                "Suburb",
                                state.data.business_address.suburb,
                                "required"
                              )}
                            </FormHelperText>
                          </Box>
                        </Stack>
                        <Stack direction="row" gap="20px">
                          <InputControl
                            name="postcode"
                            label="Postcode"
                            type="number"
                            value={Number(state.data.business_address.postcode)}
                            onChange={onChangeBusinessUser}
                            required
                            error={validator.current.message(
                              "Postcode",
                              state.data.business_address.postcode,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Postcode",
                              state.data.business_address.postcode,
                              "required"
                            )}
                            disabled={!is_business_user && id}
                          />
                          <InputControl
                            type="dropdown"
                            disableClearable
                            getOptionLabel={(option) => option.name}
                            disabled={!is_business_user && id}
                            value={
                              statesList.find(
                                (c) =>
                                  c.name === state.data.business_address.state
                              ) ?? null
                            }
                            options={statesList ?? []}
                            onChange={(e, v) => {
                              onChangeBusinessUser({
                                target: {
                                  name: "state",
                                  value: v.name || null,
                                },
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="State"
                                required
                                error={validator.current.message(
                                  "State",
                                  state.data.business_address.state,
                                  "required"
                                )}
                                helperText={validator.current.message(
                                  "State",
                                  state.data.business_address.state,
                                  "required"
                                )}
                              />
                            )}
                          />
                        </Stack>
                      </>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </ContentLayout>
    </>
  );
};
