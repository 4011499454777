import {
    ContentLayout,
    HeaderBreadcrumbs,
    Loader,
    usePermission,
} from "../../../shared";
import React from "react";
import {
    Button,
    Typography,
    Divider,
    Card,
    Stack
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import ReactToPrint from "react-to-print";
import { CashDetailsTable, ChequeDetailsTable } from "../components";
import { useDepositSlip } from "../..";
import moment from "moment";

export const DepositSlipDetailsList = () => {
    const { id } = useParams()
    const {
        state,
    } = useDepositSlip({ isDetailID: id })
    const isEditable = usePermission("deposit-slip-modify");
    const { isLoading, dataList } = state.details
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const printRef = React.useRef();
    const isStoreAdmin = pathname.includes("store-admin");

    return (
        <>
            <ContentLayout
                title="Detail View"
                rightContent={
                    <>
                        <ReactToPrint
                            trigger={() => (
                                <Button
                                    sx={{ width: "40px" }}
                                    type="button"
                                    size="small"
                                    variant="text"
                                >
                                    <img alt="tools" src="/icons/ic_print.svg" />
                                </Button>
                            )}
                            content={() => printRef.current}
                        />
                        {isEditable && (
                            <Button
                                type="button"
                                size="medium"
                                variant="contained"
                                onClick={() => navigate("edit")}
                            >
                                Edit
                            </Button>
                        )}

                    </>
                }
                breadcrumbs={
                    <>
                        <HeaderBreadcrumbs
                            links={[
                                { name: "Dashboard", href: isStoreAdmin ? "/store-admin" : "/" },
                                {
                                    name: "End Of Day", href: isStoreAdmin
                                        ? `/store-admin/end-of-day`
                                        : `/end-of-day`
                                },
                                {
                                    name: "Deposit Slip", href: isStoreAdmin
                                        ? `/store-admin/deposit-slip`
                                        : `/deposit-slip`
                                },
                                { name: "Details" },
                            ]}
                        />
                    </>
                }

            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <Card ref={printRef} sx={{
                        marginBottom: "24px"
                    }}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            height="83px"
                            p="32px 24px"
                        >
                            <Typography
                                variant="subtitle1"
                                fontWeight={600}
                                lineHeight="19px"
                                minWidth="160px"
                            >
                                Summary
                            </Typography>
                        </Stack>
                        <Divider />
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            height="83px"
                            p="24px 16px"
                        >
                            <Stack p="10px" gap="10px">
                                <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    minWidth="160px"
                                >
                                    Store Name
                                </Typography>
                                <Typography
                                    variant="body2"
                                    minWidth="160px"
                                >
                                    {dataList?.store_name}
                                </Typography>
                            </Stack>
                            <Stack p="10px" gap="10px">
                                <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    minWidth="160px"
                                >
                                    Date
                                </Typography>
                                <Typography
                                    variant="body2"
                                    minWidth="160px"
                                >
                                    {dataList?.date}
                                </Typography>
                            </Stack>
                            <Stack p="10px" gap="10px">
                                <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    minWidth="160px"
                                >
                                    Generated By
                                </Typography>
                                <Typography
                                    variant="body2"
                                    minWidth="160px"
                                    color="#636363"
                                >
                                    {dataList?.user_name}
                                </Typography>
                            </Stack>
                            <Stack p="10px" gap="10px">
                                <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    minWidth="160px"
                                >
                                    Generated Date
                                </Typography>
                                <Typography
                                    variant="body2"
                                    minWidth="160px"
                                >
                                    {moment(dataList?.created_at).format('DD/MM/YYYY, HH:MM a')}
                                </Typography>
                            </Stack>


                        </Stack>
                        <Divider />
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            height="83px"
                            p="24px 16px"
                        >
                            <Stack p="10px" gap="10px">
                                <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    minWidth="160px"
                                >
                                    BSB
                                </Typography>
                                <Typography
                                    variant="body2"
                                    minWidth="160px"
                                >
                                    {dataList?.bsb}
                                </Typography>
                            </Stack>

                            <Stack p="10px" gap="10px">
                                <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    minWidth="160px"
                                >
                                    Account Number
                                </Typography>
                                <Typography
                                    variant="body2"
                                    minWidth="160px"
                                    color="#636363"
                                >
                                    {dataList?.account_number}
                                </Typography>
                            </Stack>

                            <Stack p="10px" gap="10px">
                                <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    minWidth="160px"
                                >
                                    Account Name
                                </Typography>
                                <Typography
                                    variant="body2"
                                    minWidth="160px"
                                >
                                    {dataList?.account_name}
                                </Typography>
                            </Stack>
                            <Stack p="10px" gap="10px" minWidth="180px"></Stack>

                        </Stack>
                        <Stack
                            direction="row"
                            alignItems="center"
                            height="83px"
                            p="32px 24px"
                        >
                            <Typography
                                variant="subtitle1"
                                fontWeight={600}
                                lineHeight="19px"
                                minWidth="160px"
                            >
                                Cheque Details
                            </Typography>
                        </Stack>
                        <ChequeDetailsTable
                            data={dataList}
                        />
                        <Divider />
                        <Stack
                            direction="row"
                            alignItems="center"
                            height="83px"
                            p="32px 24px"
                        >
                            <Typography
                                variant="subtitle1"
                                fontWeight={600}
                                lineHeight="19px"
                                minWidth="160px"
                            >
                                Cash Details
                            </Typography>
                        </Stack>
                        <CashDetailsTable data={dataList} />
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            p="14.5px 16px 14.5px 22px"
                        >
                            <Typography
                                variant="subtitle1"
                                fontWeight={400}
                                lineHeight="19px"
                            >
                                Total Cheque
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                fontWeight={600}
                                lineHeight="19px"
                            >
                                ${dataList?.total_cheque}
                            </Typography>
                        </Stack>
                        <Divider />
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            p="14.5px 16px 14.5px 22px"
                        >
                            <Typography
                                variant="subtitle1"
                                fontWeight={400}
                                lineHeight="19px"
                            >
                                Total Cash
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                fontWeight={600}
                                lineHeight="19px"
                            >
                                ${dataList?.total_cash}
                            </Typography>
                        </Stack>
                        <Divider />
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            p="14.5px 16px 14.5px 22px"
                        >
                            <Typography
                                variant="subtitle1"
                                fontWeight={400}
                                lineHeight="19px"
                            >
                                Total Amount
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                fontWeight={600}
                                lineHeight="19px"
                            >
                                ${dataList?.total_amount}
                            </Typography>
                        </Stack>
                    </Card>
                )}
            </ContentLayout>
        </>
    );
};
