import { API } from "../../../utils";

export const getUbereatsList = async () => {
  const res = await API.get(`ubereats/stores`);
  return res.data;
};
export const uploadAllMenu = async () => {
  const res = await API.get(`push_menu_ubereats`);
  return res;
};
export const uploadPushMenu = async (id) => {
  const res = await API.get(`push_menu_ubereats/${id}`);
  return res;
};
export const editUbereats = async (payload) => {
  const res = await API.put(`ubereats/stores`, payload);
  return res.data;
};
