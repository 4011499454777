import React from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  Chip,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SyncSharpIcon from "@mui/icons-material/SyncSharp";
import { keyframes } from "@mui/system";
import SimpleReactValidator from "simple-react-validator";

const ReportGenerationPanel = ({
  title,
  state,
  validator,
  validateDate,
  handleInputChange,
  onSubmit,
  handleAddEmail,
  onInputEmailChange,
}) => {
  const [, forceUpdate] = React.useState();
  const validator1 = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const onSubmitEmail = () => {
    if (validator1.current.allValid()) {
      handleAddEmail(state.emailInput, "add");
      validator1.current.hideMessages();
    } else {
      validator1.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 1,
      }}
    >
      <Card sx={{ p: "32px 24px", mb: "24px", overflow: "inherit" }}>
        <Stack direction="row" gap="32px">
          <Stack direction="row" gap="4px">
            <Typography
              variant="subtitle1"
              fontWeight={600}
              width="136px"
              lineHeight="19px"
            >
              Email alert for Auto Sync Fail
            </Typography>
            {/* <img style={{ height: "24px" }} alt="details" src="/icons/ic_info.svg" /> */}
          </Stack>

          <Stack
            direction={"row"}
            sx={{ display: "flex", alignItems: "center", width: "100%" }}
          >
            <Stack width="100%" gap="16px">
              <Stack direction={"row"} gap="20px">
                <Stack width="100%">
                  <InputControl
                    name="email"
                    label="Enter Email"
                    value={state.emailInput}
                    onChange={onInputEmailChange}
                    error={validator1.current.message(
                      "Email Address",
                      state.emailInput,
                      "required|email"
                    )}
                    helperText={validator1.current.message(
                      "Email Address",
                      state.emailInput,
                      "required|email"
                    )}
                  />
                </Stack>
                <Button
                  sx={{
                    height: "44px !important",
                  }}
                  onClick={onSubmitEmail}
                  disabled={!state.emailInput || state.isSaveButtonBusy}
                >
                  Add
                </Button>
              </Stack>
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "16px",
                  rowGap: "8px",
                }}
              >
                {state.connectionDetails.emails?.map((data, index) => (
                  <Chip
                    key={index}
                    label={
                      <Typography variant="subtitle2" lineHeight="18px">
                        {data}
                      </Typography>
                    }
                    onDelete={() => {
                      handleAddEmail(data, "delete");
                    }}
                    sx={{
                      backgroundColor: "#F2F2F2",
                      height: "30px",
                      "& .MuiChip-deleteIcon": {
                        color: "#CCCCCC",
                      },
                    }}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export default ReportGenerationPanel;
