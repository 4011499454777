import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useImmer } from "use-immer";
import { errorMessage, successMessage } from "../../../utils";
import { useDebouncedCallback } from "use-debounce";
import qs from "qs";
import { AppContext, TableStateContext } from "../../../store";
import { getOrder, DownloadOrdersList } from "../api";
import moment from "moment";
import { useTabs } from "../../../shared";

const columns = [
  "order_number",
  "order_date",
  "channel_name",
  "sales_type",
  "store_name",
  "customer_first_name",
  "grand_total",
  "status",
  "payment_type",
  "terminal_name",
];

export const useOrderList = ({ discountOrders }) => {
  const PAGE_NAME = discountOrders ? "discountedOrders" : "orders";
  let { pathname } = useLocation();
  const { appState } = useContext(AppContext);
  const {
    filterState: {
      params: { orders, discountedOrders },
      params: {
        orders: { columnOptions: orderColumns },
        discountedOrders: { columnOptions: discountColumns },
      },
    },
    filterStateDispatch,
  } = useContext(TableStateContext);
  const { globalData } = appState;
  const { order: currentTab } = appState.currentTabs;
  const { setCurentTab } = useTabs();
  const [state, setState] = useImmer({
    disableApplyButton: false,
    isBusy: true,
    isSaveButtonBusy: false,
    isUnpairing: false,
    isOpen: false,
    isOpenDetails: false,
    id: null,
    terminalName: "",
    orders: [],
    sortBy: [],
    pagination: {
      pageIndex: 1,
      pageSize: 25,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    httpStatusCodes: {
      details: "",
    },
    filterApplied: {
      date: {},
      type: [],
      stores: [],
      status: [],
      searchText: null,
      feature_order: 0,
      channel: [],
      platform: [],
      payment: [],
      customer_type: [],
      terminal: [],
    },
    isCalenderOpen: false,
    date: [],
    showDate: [],
    invoiceLoading: false,
  });

  const debounced = useDebouncedCallback((value) => {
    const {
      stores,
      date,
      type,
      status,
      feature_order,
      channel,
      platform,
      payment,
      customer_type,
      terminal,
    } = state.filterApplied;
    const { pagination, sortBy } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filterApplied },
    });
    getOrders({
      searchText: value,
      stores,
      type,
      status,
      date,
      feature_order,
      channel,
      platform,
      payment,
      customer_type,
      terminal,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  }, 1000);

  useEffect(() => {
    const {
      stores,
      date,
      type,
      status,
      searchText,
      feature_order,
      channel,
      platform,
      payment,
      customer_type,
      terminal,
    } = discountOrders ? discountedOrders.filterApplied : orders.filterApplied;
    const { pagination, sortBy } = discountOrders ? discountedOrders : orders;
    const newDate = Object.keys(date).length
      ? {
          endDate: new Date(date.value.endDate),
          startDate: new Date(date.value.startDate),
          key: "selection",
        }
      : {
          endDate: new Date(),
          startDate: new Date(),
          key: "selection",
        };
    const appliedDate = {
      value: newDate,
      label: `${moment(newDate.startDate).format("yyy-MM-DD")} to ${moment(
        newDate.endDate
      ).format("yyy-MM-DD")}`,
      type: "Date",
    };
    const filterApplied = discountOrders
      ? discountedOrders.filterApplied
      : orders.filterApplied;

    setState((draft) => {
      draft.filterApplied = { ...filterApplied, date: appliedDate };
      draft.pagination = pagination;
      draft.sortBy = sortBy;
      draft.date = [newDate];
      draft.showDate = [newDate];
    });
    getOrders({
      searchText,
      stores,
      type,
      status,
      date: appliedDate,
      pagination,
      sortBy,
      feature_order,
      channel,
      platform,
      payment,
      customer_type,
      terminal,
    });
  }, []);

  const getOrders = async ({
    stores = [],
    type = [],
    status = [],
    searchText = "",
    date = {},
    pagination,
    sortBy = [],
    channel = [],
    platform = [],
    payment = [],
    customer_type = [],
    terminal = [],
    feature_order = {},
  }) => {
    try {
      let IDs = stores.map((v) => {
        return v.value;
      });
      let SCIDs = channel.map((v) => {
        return v.value;
      });

      let typeIDs = type.map((v) => {
        return v.value;
      });
      let StatusIDs = status.map((v) => {
        return v.value;
      });
      let platformIDs = platform.map((v) => {
        return v.value;
      });

      let paymentIDs = payment.map((v) => {
        return v.value;
      });

      let CusTypeIDs = customer_type.map((v) => {
        return v.value;
      });

      let terminalIDs = terminal.map((v) => {
        return v.value;
      });

      let params = {};

      if (searchText) {
        params = { ...params, search: searchText };
      }
      if (feature_order) {
        params = { ...params, feature_order: feature_order };
      }
      if (
        pathname === "/discount-orders" ||
        pathname === "/store-admin/discount-orders"
      ) {
        params = { ...params, discount_orders: 1 };
      }
      if (StatusIDs.length > 0) {
        params = { ...params, status: StatusIDs };
      }
      if (Object.keys(date).length) {
        params = {
          ...params,
          start_date: moment(date.value.startDate).format("YYYY-MM-DD"),
          end_date: moment(date.value.endDate).format("YYYY-MM-DD"),
        };
      }
      if (IDs.length > 0) {
        params = { ...params, store_id: IDs };
      }

      if (SCIDs.length > 0) {
        params = { ...params, channel: SCIDs };
      }

      if (typeIDs.length > 0) {
        params = { ...params, sales_type: typeIDs };
      }

      if (platformIDs.length > 0) {
        params = { ...params, platform: platformIDs };
      }

      if (paymentIDs.length > 0) {
        params = { ...params, payment_type: paymentIDs };
      }

      if (CusTypeIDs.length > 0) {
        params = { ...params, customer_type: CusTypeIDs };
      }

      if (terminalIDs.length > 0) {
        params = { ...params, terminal_id: terminalIDs };
      }

      if (sortBy.length > 0) {
        const sortByParams = sortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }
      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };
      setState((draft) => {
        draft.isBusy = true;
        draft.disableApplyButton = true;
      });
      const res = await getOrder(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.orders = res.data.orders;
          draft.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
        draft.disableApplyButton = false;
      });
    }
  };

  const handleSearch = (v) => {
    setState((draft) => {
      draft.filterApplied.searchText = v;
    });
    debounced(v);
  };

  const handleFilter = (e, value, type) => {
    if (type === "store") {
      setState((draft) => {
        draft.filterApplied.stores = value.map((v) => {
          return { ...v, type: "Store" };
        });
      });
    } else if (type === "channel") {
      setState((draft) => {
        draft.filterApplied.channel = value.map((v) => {
          return { ...v, type: "Sales Channel" };
        });
      });
    } else if (type === "status") {
      setState((draft) => {
        draft.filterApplied.status = value.map((v) => {
          return { ...v, type: "Status" };
        });
      });
    } else if (type === "type") {
      setState((draft) => {
        draft.filterApplied.type = value.map((v) => {
          return { ...v, type: "Type" };
        });
      });
    } else if (type === "platform") {
      setState((draft) => {
        draft.filterApplied.platform = value.map((v) => {
          return { ...v, type: "Platform" };
        });
      });
    } else if (type === "payment") {
      setState((draft) => {
        draft.filterApplied.payment = value.map((v) => {
          return { ...v, type: "Payment Media" };
        });
      });
    } else if (type === "customerType") {
      setState((draft) => {
        draft.filterApplied.customer_type = value.map((v) => {
          return { ...v, type: "Customer Type" };
        });
      });
    } else if (type === "terminal") {
      setState((draft) => {
        draft.filterApplied.terminal = value.map((v) => {
          return { ...v, type: "Terminal" };
        });
      });
    }
    setState((draft) => {
      draft.disableApplyButton = false;
    });
  };

  const filterCollections = [
    state.filterApplied.date,
    ...state.filterApplied.status,
    ...state.filterApplied.stores,
    ...state.filterApplied.type,
    ...state.filterApplied.channel,
    ...state.filterApplied.platform,
    ...state.filterApplied.payment,
    ...state.filterApplied.customer_type,
    ...state.filterApplied.terminal,
  ].filter((el) => Object.keys(el).length);

  const handleApplyFilter = () => {
    const {
      stores,
      date,
      type,
      status,
      searchText,
      feature_order,
      channel,
      platform,
      payment,
      customer_type,
      terminal,
    } = state.filterApplied;
    const { pagination, sortBy } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: state.filterApplied,
        pagination,
        sortBy,
      },
    });
    getOrders({
      searchText,
      stores,
      type,
      status,
      date,
      feature_order,
      channel,
      platform,
      payment,
      customer_type,
      terminal,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  };

  const handleFilterClear = (type, value) => {
    const { pagination, sortBy } = discountOrders ? discountedOrders : orders;
    if (type === "single") {
      if (value.type === "Store") {
        const filteredStores = state.filterApplied.stores.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.stores = filteredStores;
        });
      }
      if (value.type === "Sales Channel") {
        const filteredStatus = state.filterApplied.channel.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.channel = filteredStatus;
        });
      }
      if (value.type === "Status") {
        const filteredStatus = state.filterApplied.status.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.status = filteredStatus;
        });
      }
      if (value.type === "Date") {
        setState((draft) => {
          draft.filterApplied.date = {};
          draft.showDate = [];
        });
      }

      if (value.type === "Type") {
        const filteredTypes = state.filterApplied.type.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.type = filteredTypes;
        });
      }
      if (value.type === "Platform") {
        const filteredPlatform = state.filterApplied.platform.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.platform = filteredPlatform;
        });
      }
      if (value.type === "Payment Media") {
        const filteredPayment = state.filterApplied.payment.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.payment = filteredPayment;
        });
      }
      if (value.type === "Customer Type") {
        const filteredCusType = state.filterApplied.customer_type.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.customer_type = filteredCusType;
        });
      }
      if (value.type === "Terminal") {
        const filteredTerminal = state.filterApplied.terminal.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.terminal = filteredTerminal;
        });
      }
      setState((draft) => {
        draft.disableApplyButton = false;
      });
    } else {
      setState((draft) => {
        draft.filterApplied.stores = [];
        draft.filterApplied.status = [];
        draft.filterApplied.channel = [];
        draft.filterApplied.date = {};
        draft.filterApplied.platform = [];
        draft.filterApplied.payment = [];
        draft.filterApplied.customer_type = [];
        draft.filterApplied.terminal = [];
        draft.showDate = [];
        draft.date = [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ];
        draft.filterApplied.type = [];
      });
      const initialValue = {
        date: {},
        type: [],
        stores: [],
        status: [],
        feature_order: 0,
        searchText: state.filterApplied.searchText,
        channel: [],
        platform: [],
        payment: [],
        customer_type: [],
        terminal: [],
      };
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initialValue,
      });
      getOrders({
        pagination: {
          pageIndex: 1,
          pageSize: pagination.pageSize,
        },
        sortBy,
        searchText: state.filterApplied.searchText,
      });
    }
  };

  const gotoPage = (page) => {
    const {
      stores,
      date,
      type,
      status,
      searchText,
      feature_order,
      channel,
      platform,
      payment,
      customer_type,
      terminal,
    } = state.filterApplied;
    const { sortBy } = state;
    getOrders({
      searchText,
      stores,
      type,
      status,
      date,
      feature_order,
      channel,
      platform,
      payment,
      customer_type,
      terminal,
      pagination: {
        pageIndex: page,
        pageSize: state.pagination.pageSize,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };

  const setPageSize = (e) => {
    const {
      stores,
      date,
      type,
      status,
      searchText,
      feature_order,
      channel,
      platform,
      payment,
      customer_type,
      terminal,
    } = state.filterApplied;
    const { sortBy } = state;
    getOrders({
      searchText,
      stores,
      type,
      status,
      date,
      feature_order,
      channel,
      platform,
      payment,
      customer_type,
      terminal,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };

  const handleDownloadOrdersList = async () => {
    const columnOptions = discountOrders ? discountColumns : orderColumns;
    const visibleColumns = columns.filter(
      (item1) => !columnOptions.some((item2) => item1 === item2)
    );
    if (visibleColumns.length) {
      try {
        const {
          stores,
          date,
          type,
          status,
          searchText,
          feature_order,
          channel,
          platform,
          payment,
          customer_type,
          terminal,
        } = state.filterApplied;
        const { sortBy } = state;

        let IDs = stores.map((v) => {
          return v.value;
        });
        let SCIDs = channel.map((v) => {
          return v.value;
        });

        let typeIDs = type.map((v) => {
          return v.value;
        });
        let StatusIDs = status.map((v) => {
          return v.value;
        });

        let platformIDs = platform.map((v) => {
          return v.value;
        });
        let paymentIDs = payment.map((v) => {
          return v.value;
        });

        let CusTypeIDs = customer_type.map((v) => {
          return v.value;
        });

        let TerminalIDs = terminal.map((v) => {
          return v.value;
        });

        let params = {};

        if (searchText) {
          params = { ...params, search: searchText };
        }
        if (state.filterApplied.feature_order !== "") {
          params = {
            ...params,
            feature_order: state.filterApplied.feature_order,
          };
        }
        if (
          pathname === "/discount-orders" ||
          pathname === "/store-admin/discount-orders"
        ) {
          params = { ...params, discount_orders: 1 };
        }
        if (StatusIDs.length > 0) {
          params = { ...params, status: StatusIDs };
        }

        if (Object.keys(date).length) {
          params = {
            ...params,
            start_date: moment(date.value.startDate).format("YYYY-MM-DD"),
            end_date: moment(date.value.endDate).format("YYYY-MM-DD"),
          };
        }
        if (IDs.length > 0) {
          params = { ...params, store_id: IDs };
        }

        if (SCIDs.length > 0) {
          params = { ...params, channel: SCIDs };
        }

        if (typeIDs.length > 0) {
          params = { ...params, sales_type: typeIDs };
        }

        if (platformIDs.length > 0) {
          params = { ...params, platform: platformIDs };
        }

        if (paymentIDs.length > 0) {
          params = { ...params, payment_type: paymentIDs };
        }

        if (CusTypeIDs.length > 0) {
          params = { ...params, customer_type: CusTypeIDs };
        }

        if (TerminalIDs.length > 0) {
          params = { ...params, terminal_id: TerminalIDs };
        }

        params = { ...params, columns: visibleColumns };
        if (sortBy.length > 0) {
          const sortByParams = sortBy.reduce(
            (obj, item) =>
              Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
            {}
          );

          params = {
            ...params,
            sort: sortByParams,
          };
        }
        const query = {
          params,
          paramsSerializer: (params) => qs.stringify(params),
        };

        const res = await DownloadOrdersList(query);
        successMessage(res?.message);
      } catch (err) {
        errorMessage(err.response.data.message);
      }
    } else {
      errorMessage("at least one column is required to export the list");
    }
  };

  const handleSort = (sortBy) => {
    const {
      stores,
      date,
      type,
      status,
      searchText,
      feature_order,
      channel,
      platform,
      payment,
      customer_type,
      terminal,
    } = state.filterApplied;
    const { pagination } = state;
    getOrders({
      searchText,
      stores,
      type,
      status,
      date,
      pagination,
      sortBy,
      feature_order,
      channel,
      platform,
      payment,
      customer_type,
      terminal,
    });
    filterStateDispatch({
      type: "UPDATE_SORT",
      page: PAGE_NAME,
      value: sortBy,
    });
    setState((draft) => {
      draft.sortBy = sortBy;
    });
  };

  const onChangeDate = (e) => {
    setState((draft) => {
      draft.date = e;
    });
  };
  const handleIsOpen = (e) => {
    setState((draft) => {
      draft.isCalenderOpen = e;
    });
  };

  const handleDateChangeApply = () => {
    setState((draft) => {
      draft.showDate = state.date;
      draft.isCalenderOpen = false;
      draft.filterApplied.date = {
        value: state.date[0],
        label: `${moment(state.date[0].startDate).format(
          "yyy-MM-DD"
        )} to ${moment(state.date[0].endDate).format("yyy-MM-DD")}`,
        type: "Date",
      };
      draft.disableApplyButton = false;
    });
  };

  const toggleHiddenManualOrder = (column) => {
    filterStateDispatch({
      type: "UPDATE_COLUMNS",
      page: PAGE_NAME,
      value: column,
    });
  };
  const HandleFutureOrder = async (feature_order) => {
    const {
      stores,
      date,
      type,
      status,
      searchText,
      channel,
      platform,
      payment,
      customer_type,
      terminal,
    } = state.filterApplied;
    const { pagination, sortBy } = state;
    getOrders({
      searchText,
      stores,
      type,
      status,
      date,
      pagination,
      sortBy,
      feature_order,
      channel,
      platform,
      payment,
      customer_type,
      terminal,
    });

    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: { ...state.filterApplied, feature_order } },
    });
    setState((draft) => {
      draft.filterApplied.feature_order = feature_order;
    });
  };

  return {
    state,
    globalData,
    handleSearch,
    handleFilter,
    handleApplyFilter,
    handleFilterClear,
    gotoPage,
    setPageSize,
    handleDownloadOrdersList,
    handleSort,
    onChangeDate,
    handleIsOpen,
    handleDateChangeApply,
    toggleHiddenManualOrder,
    filterCollections,
    discountColumns,
    orderColumns,
    currentTab,
    setCurentTab,
    HandleFutureOrder,
  };
};
