import { ContentLayout } from "../../../shared";
import * as React from "react";
import { Button, Stack, Typography, Box, Tab } from "@mui/material";
import { useState } from "react";
import { useConfirmedOrderList, useOrderList } from "../hooks";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { OrderListComponent } from "../components";
import { useSearchParams } from "react-router-dom";

export const OrderList = ({ discountOrders = false }) => {
  const [searchParams] = useSearchParams();
  const currentTabName = searchParams.get("tab");
  const {
    state,
    globalData,
    handleSearch,
    handleFilter,
    handleApplyFilter,
    handleFilterClear,
    gotoPage,
    setPageSize,
    handleDownloadOrdersList,
    handleSort,
    onChangeDate,
    handleIsOpen,
    handleDateChangeApply,
    toggleHiddenManualOrder,
    filterCollections,
    discountColumns,
    orderColumns,
    setCurentTab,
    currentTab,
    HandleFutureOrder,
  } = useOrderList({ discountOrders });
  const {
    orderdata,
    confirmorderColumns,
    confirmFilterCollections,
    handleConfirmOrderApplyFilter,
    HandleFutureConfirmedOrder,
    handleConfirmOrderFilter,
    handleConfirmOrderDateChangeApply,
    handleConfirmOrderIsOpen,
    onConfirmOrederChangeDate,
    handleConfirmOrderSearch,
    toggleConfirmOrderHiddenManualOrder,
    handleConfirmOrderSort,
    setConfirmOrderPageSize,
    gotoConfirmOrderPage,
    handleConfirmOrderFilterClear,
    handleDownloadConfirmOrdersList,
  } = useConfirmedOrderList();

  React.useEffect(() => {
    if (currentTabName === "confirm") {
      setCurentTab("order", "2");
    }
  }, [currentTabName]);
  const handleChange = (event, newValue) => {
    setCurentTab("order", newValue);
  };
  const [hasColumnOptions, setHasColumnOptions] = useState(false);

  const toggleColumns = () => {
    setHasColumnOptions(!hasColumnOptions);
  };

  return (
    <>
      <ContentLayout
        title={discountOrders ? "Discount Sales Log" : "Sales Log"}
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="text"
              onClick={
                currentTab === "1"
                  ? handleDownloadOrdersList
                  : handleDownloadConfirmOrdersList
              }
            >
              <img
                alt="export"
                src="/icons/ic_export.svg"
                style={{
                  marginRight: "4px",
                }}
              />
              Export
            </Button>
            <Button
              type="button"
              size="medium"
              variant="text"
              onClick={() => toggleColumns()}
            >
              <img
                alt="tools"
                src="/icons/ic_Tools.svg"
                style={{
                  marginRight: "4px",
                }}
              />
              Column Option
            </Button>
          </>
        }
      >
        <TabContext value={currentTab}>
          <Box
            className="sticky-Tab"
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              top: "88px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TabList onChange={handleChange}>
              <Tab label="All Orders" value="1" />
              <Tab
                value="2"
                label={
                  <Stack direction="row" gap={1}>
                    <Typography
                      variant="body2"
                      mt={0.5}
                      fontWeight={600}
                      fontSize="14px"
                    >
                      Order For Confirmation
                    </Typography>
                  </Stack>
                }
              />
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            <OrderListComponent
              state={state}
              globalData={globalData}
              filterCollections={filterCollections}
              hasColumnOptions={hasColumnOptions}
              handleApplyFilter={handleApplyFilter}
              HandleFutureOrder={HandleFutureOrder}
              handleFilter={handleFilter}
              handleDateChangeApply={handleDateChangeApply}
              handleIsOpen={handleIsOpen}
              onChangeDate={onChangeDate}
              handleSearch={handleSearch}
              toggleHiddenManualOrder={toggleHiddenManualOrder}
              handleSort={handleSort}
              setPageSize={setPageSize}
              gotoPage={gotoPage}
              handleFilterClear={handleFilterClear}
              toggleColumns={toggleColumns}
              orderColumns={orderColumns}
              discountOrders={discountOrders}
              discountColumns={discountColumns}
              currentTab={currentTab}
              type="all-orders"
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            <OrderListComponent
              state={orderdata}
              globalData={globalData}
              filterCollections={confirmFilterCollections}
              hasColumnOptions={hasColumnOptions}
              handleApplyFilter={handleConfirmOrderApplyFilter}
              HandleFutureOrder={HandleFutureConfirmedOrder}
              handleFilter={handleConfirmOrderFilter}
              handleDateChangeApply={handleConfirmOrderDateChangeApply}
              handleIsOpen={handleConfirmOrderIsOpen}
              onChangeDate={onConfirmOrederChangeDate}
              handleSearch={handleConfirmOrderSearch}
              toggleHiddenManualOrder={toggleConfirmOrderHiddenManualOrder}
              handleSort={handleConfirmOrderSort}
              setPageSize={setConfirmOrderPageSize}
              gotoPage={gotoConfirmOrderPage}
              handleFilterClear={handleConfirmOrderFilterClear}
              toggleColumns={toggleColumns}
              orderColumns={confirmorderColumns}
              discountOrders={discountOrders}
              discountColumns={discountColumns}
              currentTab={currentTab}
              type="future-orders"
            />
          </TabPanel>
        </TabContext>
      </ContentLayout>
    </>
  );
};
