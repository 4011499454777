import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { AppContext } from "../../../store/AppScopeProvider";
import { usePermission } from "../../../shared";
import { DownloadWastageList, getWastage } from "../api/wastageApi";
import { useDebouncedCallback } from "use-debounce";
import qs from "qs";
import { errorMessage } from "../../../utils";
import moment from "moment/moment";
import { useLocation } from "react-router-dom";
import { TableStateContext } from "../../../store";
var fileDownload = require("js-file-download");

export const useWastageList = ({ isWastage }) => {
  const isDeletable = usePermission("wastage-delete");
  const isCreateVisible = usePermission("wastage-create");
  const isEditable = usePermission("wastage-modify");
  const isVisible = usePermission("wastage-view");
  const { appState } = useContext(AppContext);
  const { pathname } = useLocation();
  const {
    globalData: { wastage_types: wastageType },
  } = appState;
  const [state, setState] = useImmer({
    isBusy: true,
    pagination: {
      pageIndex: 1,
      pageSize: 50,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    sortBy: [],
    wastage: [],
    filters: {
      searchText: "",
      wastageType: "",
      date: [],
    },
    wastage_number: "",
    data: {
      id: "",
      wastage_number: "",
      wastage_date: "",
      wastage_type: "",
      terminal_id: "",
      store_id: "",
      user_id: "",
      grant_total: "",
      total_products: "",
      notes: "",
      created_at: "",
      updated_at: "",
      Products: [],
    },
    isCalenderOpen: false,
    date: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
  });
  const PAGE_NAME = "wastage";
  const {
    filterState: {
      params: { wastage },
    },
    initialState,
    filterStateDispatch,
  } = useContext(TableStateContext);

  const debounced = useDebouncedCallback((value) => {
    const { wastageType, date } = state.filters;
    const { pagination, sortBy } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filters },
    });
    getWastages({
      search: value,
      date,
      wastageType,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  }, 1000);

  useEffect(() => {
    if (isWastage) {
      const { wastageType, date, searchText } = wastage.filterApplied;
      const { pagination, sortBy } = wastage;
      setState((draft) => {
        draft.pagination = pagination;
        draft.sortBy = sortBy;
        if (Object.keys(date).length) {
          draft.date = [
            {
              endDate: new Date(date[0].endDate),
              startDate: new Date(date[0].startDate),
              key: "selection",
            },
          ];
        }
        draft.filters = {
          ...wastage.filterApplied,
          date: date.length
            ? [
                {
                  endDate: new Date(date[0]?.endDate),
                  startDate: new Date(date[0]?.startDate),
                  key: "selection",
                },
              ]
            : [],
        };
      });
      getWastages({
        search: searchText,
        wastageType,
        date,
        pagination: {
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
        },
        sortBy,
      });
    }
  }, [isWastage]);

  const getWastages = async ({
    search = null,
    wastageType: wastage_type = "",
    date = [],
    pagination,
    sortBy = [],
  }) => {
    let params = {};
    if (search) {
      params = { ...params, search };
    }
    if (wastage_type !== "") {
      params = { ...params, wastage_type };
    }
    if (date.length) {
      params = {
        ...params,
        start_date: moment(date[0].startDate).format("YYYY-MM-DD"),
        end_date: moment(date[0].endDate).format("YYYY-MM-DD"),
      };
    }
    if (sortBy.length > 0) {
      const sortByParams = sortBy.reduce(
        (obj, item) =>
          Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
        {}
      );
      params = {
        ...params,
        sort: sortByParams,
      };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getWastage(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.wastage = res.data.wastages;
          draft.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const onChangeFilter = (e, v, type) => {
    const { searchText, date } = state.filters;
    const { pagination, sortBy } = state;
    if (e.target.name === "wastageType") {
      getWastages({
        search: searchText,
        wastageType: e.target.value,
        date,
        pagination: {
          pageIndex: 1,
          pageSize: pagination.pageSize,
        },
        sortBy,
      });
    } else if (e.target.name === "searchText") {
      debounced(e.target.value);
    }

    setState((draft) => {
      draft.filters[e?.target?.name] = e?.target?.value;
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: { ...state.filters, [e.target.name]: e.target.value },
      },
    });
  };

  const resetFilters = () => {
    if (
      state.filters.wastageType ||
      state.filters.date.length ||
      state.filters.searchText
    ) {
      getWastages({
        pagination: {
          pageIndex: 1,
          pageSize: state.pagination.pageSize,
        },
        sortBy: state.sortBy,
      });

      setState((draft) => {
        draft.filters.date = [];
        draft.filters.searchText = "";
        draft.filters.wastageType = "";
        draft.date = [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ];
      });
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initialState.params.wastage.filterApplied,
      });
    }
  };
  const onSeachClear = () => {
    const { wastageType, date } = state.filters;
    const { pagination, sortBy } = state;
    getWastages({
      search: null,
      wastageType,
      date,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.filters.searchText = "";
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: { ...state.filters, searchText: "" } },
    });
  };

  const gotoPage = (page) => {
    const { searchText, wastageType, date } = state.filters;
    const { sortBy } = state;
    getWastages({
      search: searchText,
      wastageType,
      date,
      pagination: {
        pageIndex: page,
        pageSize: state.pagination.pageSize,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };

  const setPageSize = (e) => {
    const { searchText, wastageType, date } = state.filters;
    const { sortBy } = state;
    getWastages({
      search: searchText,
      wastageType,
      date,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };

  const handleDownloadWastageList = async () => {
    try {
      const { wastageType: wastage_type, date, searchText } = state.filters;
      const { sortBy } = state;
      let params = {};
      if (searchText) {
        params = { ...params, search: searchText };
      }
      if (wastage_type !== "") {
        params = { ...params, wastage_type };
      }
      if (date.length) {
        params = {
          ...params,
          start_date: moment(date[0].startDate).format("YYYY-MM-DD"),
          end_date: moment(date[0].endDate).format("YYYY-MM-DD"),
        };
      }

      if (sortBy.length > 0) {
        const sortByParams = sortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }
      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };

      const res = await DownloadWastageList(query);
      const content = res.headers["content-type"];
      fileDownload(res.data, `WastageList.xlsx`, content);
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleSort = (sortBy) => {
    const { searchText, wastageType, date } = state.filters;
    const { pagination } = state;
    getWastages({
      search: searchText,
      wastageType,
      date,
      pagination,
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_SORT",
      page: PAGE_NAME,
      value: sortBy,
    });
    setState((draft) => {
      draft.sortBy = sortBy;
    });
  };

  const onChangeDate = (e) => {
    setState((draft) => {
      draft.date = e;
    });
  };
  const handleIsOpen = (e) => {
    setState((draft) => {
      draft.isCalenderOpen = e;
    });
  };

  const handleDateChangeApply = () => {
    const { searchText, wastageType } = state.filters;
    const { pagination, sortBy } = state;
    getWastages({
      search: searchText,
      wastageType,
      date: state.date,
      pagination,
      sortBy,
    });
    setState((draft) => {
      draft.filters.date = state.date;
      draft.isCalenderOpen = false;
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: {
          date: state.date,
        },
      },
    });
  };

  return {
    state,
    onChangeFilter,
    wastageType,
    onSeachClear,
    isVisible,
    isDeletable,
    isCreateVisible,
    isEditable,
    resetFilters,
    gotoPage,
    setPageSize,
    handleDownloadWastageList,
    handleSort,
    handleIsOpen,
    onChangeDate,
    handleDateChangeApply,
  };
};
