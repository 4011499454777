import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import {
  successMessage,
  errorMessage,
  successRedMessage,
} from "../../../utils";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import { usePermission, useTabs } from "../../../shared";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import qs from "qs";
import {
  changeCardStatus,
  createGiftCard,
  deleteGiftCardById,
  downloadAllCards,
  downloadSingleCards,
  getAllBathCardList,
  getAllCardList,
  getCardDetail,
  getCardTransactionDetails,
  getGiftCardDetails,
  getGiftCardGroups,
  shareGiftCard,
  updateGiftCard,
} from "../api/GiftCardApi";
import moment from "moment";
import { TableStateContext } from "../../../store";
var fileDownload = require("js-file-download");

export const useGiftCard = ({
  isAllCards,
  isBatchCards,
  giftCardId,
  cardDetailsId = null,
}) => {
  const navigate = useNavigate();
  const { appState } = useContext(AppContext);
  const { globalData } = appState;
  const { setCurentTab, resetCurrentTabs } = useTabs();
  const { giftCardGroupDetails: currentTab, giftCardList } =
    appState.currentTabs;
  const PAGE_NAME = "allCards";
  const {
    filterState: {
      params: { allCards },
    },
    filterStateDispatch,
  } = useContext(TableStateContext);
  const isEditable = usePermission("gift-cards-modify");
  const isDeletable = usePermission("gift-cards-delete");
  const isCreateVisible = usePermission("gift-cards-create");
  const isVisible = usePermission("gift-cards-view");
  const [state, setState] = useImmer({
    isBusy: false,
    isOpen: false,
    Loading: false,
    isModalOpen: false,
    isLoading: true,
    status: 1,
    id: "",
    email: "",
    initialLoad: true,
    isSaveButtonBusy: false,
    allBatchCards: [],
    allBatchCardssortBy: [],
    allBatchCardspagination: {
      pageIndex: 1,
      pageSize: 25,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    cardtransactionDetails: [],
    allGiftCardGroups: [],
    redemptionDetails: [],
    cardDetails: {},
    giftCardDetails: [],
    allCards: [],
    batchCards: [],
    condition_type: 1,
    transactionpagination: {
      pageIndex: 1,
      pageSize: 25,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    transactionsortBy: [],
    pagination: {
      pageIndex: 1,
      pageSize: 25,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    sortBy: [],
    disableApplyButton: false,
    detailsfilters: {
      isSearching: false,
      searchText: "",
      status: "",
    },
    filters: {
      isSearching: false,
      all: [],
      searchText: "",
      batch_name: "",
      card_type: "",
      expired_on: "",
      status: "",
    },
    preloadedFilters: {
      isSearching: false,
      all: [],
      searchText: "",
      created_date: "",
      amount_limit_operator: "",
      amount_limit_value: "",
      validity_limit_value: "",
      validity_limit_operator: "",
      validity_limit_value: "",
      created_by: "",
    },
    data: {
      card_type: "",
      batch_name: "",
      quantity: "",
      amount: "",
      validity: "",
      is_year_month: 0,
      stores: [],
      is_pre_activated: 0,
      seller_store_id: null,
    },
  });

  const [debounced] = useDebounce(state.preloadedFilters.searchText, 1000);
  const [debouncedTextFilter] = useDebounce(
    state.detailsfilters.searchText,
    1000
  );
  useEffect(() => {
    if (cardDetailsId) {
      getCardDetails(cardDetailsId);
    }
  }, [cardDetailsId]);

  useEffect(() => {
    if (giftCardList === "1" && isAllCards) {
      const { batch_name, card_type, expired_on, searchText, status } =
        allCards.filterApplied;
      const { pagination, sortBy } = allCards;

      setState((draft) => {
        draft.filters = allCards.filterApplied;
        draft.pagination = pagination;
        draft.sortBy = sortBy;
      });
      getAllCard({
        batch_name: batch_name,
        card_type: card_type,
        expired_on: expired_on,
        searchText: searchText,
        status: status,
        pagination: {
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
        },
        sortBy,
      });
    }
  }, [isAllCards, giftCardList]);

  const debouncedAllCards = useDebouncedCallback((value) => {
    const { batch_name, card_type, expired_on, status } = state.filters;
    const { pagination, sortBy } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filters },
    });
    getAllCard({
      batch_name: batch_name,
      card_type: card_type,
      expired_on: expired_on,
      searchText: value,
      status: status,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  }, 1000);

  useEffect(() => {
    if (!state.initialLoad && !state.detailsfilters.isSearching) {
      const { searchText, status } = state.detailsfilters;
      const { allBatchCardspagination, allBatchCardssortBy } = state;
      getAllBatchCard({
        searchText: searchText,
        status,
        pagination: {
          pageIndex: 1,
          pageSize: allBatchCardspagination.pageSize,
        },
        allBatchCardssortBy,
      });
    }
  }, [debouncedTextFilter]);

  const getAllCard = async ({
    batch_name = "",
    card_type = "",
    expired_on = "",
    searchText = "",
    status = "",
    pagination,
    sortBy = [],
  }) => {
    let params = {};
    if (batch_name !== "") {
      params = { ...params, batch_name: batch_name.label };
    }
    if (card_type !== "") {
      params = { ...params, card_type: card_type.value };
    }

    if (searchText) {
      params = { ...params, search: searchText };
    }
    if (status && toString(status.value)) {
      params = { ...params, status: status.value };
    }

    if (expired_on && toString(expired_on)) {
      params = { ...params, expired_on: expired_on.label };
    }
    if (sortBy.length > 0) {
      const sortByParams = sortBy.reduce(
        (obj, item) =>
          Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
        {}
      );
      params = {
        ...params,
        sort: sortByParams,
      };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
        draft.disableApplyButton = true;
      });
      const res = await getAllCardList(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.allCards = res.data.gift_cards;
          draft.pagination = res.data.pagination;
          draft.initialLoad = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
        draft.disableApplyButton = false;
      });
    }
  };

  useEffect(() => {
    if (giftCardList === "1") {
      setState((draft) => {
        draft.data.card_type = "";
      });
    }
  }, [giftCardList]);

  const onChange = (e, type) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };

  const handleConditionType = (e) => {
    setState((draft) => {
      draft.data.is_year_month = +e.target.value;
    });
  };
  const onSaveGiftCard = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const data = { ...state.data };
      delete data.amount;
      const payload = state.data.card_type === 2 ? data : state.data;
      const payload2 = {
        ...payload,
        store_id: payload.stores.map((i) => i.id),
        validity:
          state.data.is_year_month === 0
            ? state.data.validity * 365
            : state.data.validity * 30,
      };
      if (state.data.card_type === 2) {
        delete payload2.seller_store_id;
        delete payload2.is_pre_activated;
      }

      const res = await createGiftCard(payload2);
      if (res.success) {
        successRedMessage(
          "Gift Card creation will continue in background. You will receive an email confirmation after creation."
        );
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate("/gift-card");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  useEffect(() => {
    if (giftCardId) {
      getGiftCardDetailsList(giftCardId);
    }
  }, [giftCardId]);
  const getGiftCardDetailsList = async (giftCardId) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getGiftCardDetails(giftCardId);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.giftCardDetails = res.data;
          draft.data = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  const handleDeleteGiftCard = async (type) => {
    try {
      const res = await deleteGiftCardById(state.id);
      if (res.success) {
        successMessage("Gift Card Group Deleted.");
        setState((draft) => {
          draft.batchCards = draft.batchCards.filter(
            (item) => item.id !== state.id
          );
        });

        handleDeleteModal("details", false, null);
        navigate("/gift-card");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleDeleteModal = (type, state, data) => {
    switch (type) {
      case "details":
        setState((draft) => {
          draft.isOpen = state;
          draft.id = data;
        });
        break;
      case "list":
        setState((draft) => {
          draft.isOpen = state;
          draft.id = data;
        });
        break;
    }
  };

  const handleDownloadAllCards = async () => {
    try {
      const res = await downloadAllCards(giftCardId);
      const content = res.headers["content-type"];
      fileDownload(res.data, `GiftCards.xlsx`, content);
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleDownloadSingleCards = async (id) => {
    try {
      const res = await downloadSingleCards(id);
      const content = res.headers["content-type"];
      fileDownload(res.data, `GiftCards.xlsx`, content);
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const onEmailChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.email = value;
    });
  };
  const handleShareGiftCards = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const payload = { email: state.email };
      const res = await shareGiftCard(state.id || cardDetailsId, payload);
      if (res.success) {
        successMessage("Gift card successfully sent to the mail.");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        toggleModal(false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const toggleModal = (state, data) => {
    setState((draft) => {
      draft.isModalOpen = state;
      draft.id = data;
    });
    if (!state) {
      setState((draft) => {
        draft.email = "";
      });
    }
  };
  const onChangeFilter = (e) => {
    setState((draft) => {
      draft.filters.searchText = e.target.value;
      draft.filters.isSearching = false;
    });
    debouncedAllCards(e.target.value);
  };

  const onSeachClear = () => {
    setState((draft) => {
      draft.filters.searchText = "";
      draft.filters.isSearching = true;
    });
    debouncedAllCards("");
  };

  const handleFilterClear = (type, value) => {
    const { batch_name, card_type, expired_on, searchText, status, date } =
      state.filters;
    const { pagination, sortBy } = state;
    if (type === "single") {
      if (value.type === "Batch name") {
        setState((draft) => {
          draft.filters.batch_name = "";
        });
      } else if (value.type === "Card type") {
        setState((draft) => {
          draft.filters.card_type = "";
        });
      } else if (value.type === "Status") {
        setState((draft) => {
          draft.filters.status = "";
        });
      } else if (value.type === "Expired on") {
        setState((draft) => {
          draft.filters.expired_on = "";
        });
      }
      setState((draft) => {
        draft.disableApplyButton = false;
      });
    } else {
      getAllCard({
        searchText,
        pagination: {
          pageIndex: 1,
          pageSize: pagination.pageSize,
        },
        sortBy,
      });
      const initialValue = {
        isSearching: false,
        all: [],
        searchText: searchText,
        batch_name: "",
        card_type: "",
        expired_on: "",
        status: "",
      };
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initialValue,
      });
      setState((draft) => {
        draft.filters.all = [];
        draft.filters.batch_name = "";
        draft.filters.card_type = "";
        draft.filters.terminals = [];
        draft.filters.status = "";
        draft.filters.expired_on = "";
        draft.filters.date = "";
      });
    }
  };
  const handleApplyFilter = () => {
    const { batch_name, card_type, expired_on, searchText, status } =
      state.filters;
    const { sortBy, pagination } = state;
    getAllCard({
      batch_name,
      card_type,
      expired_on,
      searchText,
      status,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: state.filters,
      },
    });
  };
  const handleFilter = (e, value, type) => {
    const { pagination, sortBy } = state;
    if (type === "batch_name") {
      setState((draft) => {
        draft.filters.batch_name = {
          value: value?.value,
          label: value?.label,
          type: "Batch name",
        };
      });
    } else if (type === "card_type") {
      setState((draft) => {
        draft.filters.card_type = {
          value: value.props.value,
          label: value.props.children,
          type: "Card type",
        };
      });
    } else if (type === "status") {
      setState((draft) => {
        draft.filters.status = {
          value: value.props.value,
          label: value.props.children,
          type: "Status",
        };
      });
    } else if (type === "expired_on") {
      setState((draft) => {
        draft.filters.expired_on = {
          value: e,
          label: moment(e).format("yyy-MM-DD"),
          type: "Expired on",
        };
      });
    }
    setState((draft) => {
      draft.disableApplyButton = false;
    });
  };
  useEffect(() => {
    setState((draft) => {
      draft.filters.all = [
        state.filters.batch_name,
        state.filters.card_type,
        state.filters.status,
        state.filters.expired_on,
      ].filter((el) => Object.keys(el).length);
    });
  }, [
    state.filters.batch_name,
    state.filters.expired_on,
    state.filters.batch_name,
    state.filters.card_type,
    state.filters.status,
  ]);

  const onDeleteStoreSelect = (data) => {
    setState((draft) => {
      draft.data.stores = state.data.stores.filter((value) => {
        return value.id !== data;
      });
    });
  };
  const onUpdateGiftCard = async (gId) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await updateGiftCard(gId, {
        ...state.data,
        store_id: state.data.stores.map((i) => i.id),
      });
      if (res.success) {
        successMessage("Updated Successfully");
        navigate("/gift-card");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const getCardDetails = async (cardDetailsId) => {
    try {
      setState((draft) => {
        draft.Loading = true;
      });
      const res = await getCardDetail(cardDetailsId);
      if (res.success) {
        setState((draft) => {
          draft.isLoading = false;
          draft.cardDetails = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const handleInputChangeStatus = async () => {
    const { sortBy } = state;
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await changeCardStatus(cardDetailsId);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isBusy = false;
        });
        getAllCard({
          pagination: {
            pageIndex: 1,
            pageSize: state.pagination.pageSize,
          },
          sortBy,
        });
        getCardDetails(cardDetailsId);
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  const onChangeFilterData = (e) => {
    const { name, value } = e.target;
    const { searchText, status } = state.detailsfilters;
    const { allBatchCardssortBy } = state;
    if (name === "status") {
      getAllBatchCard({
        searchText,
        pagination: {
          pageIndex: 1,
          pageSize: state.allBatchCardspagination.pageSize,
        },
        allBatchCardssortBy,
        status: value,
      });
    }
    setState((draft) => {
      draft.detailsfilters[name] = value;
      draft.detailsfilters.isSearching = false;
    });
  };
  const resetFilters = () => {
    const { allBatchCardssortBy } = state;
    if (
      state.detailsfilters.status.toString() ||
      state.detailsfilters.searchText
    ) {
      getAllBatchCard({
        pagination: {
          pageIndex: 1,
          pageSize: state.allBatchCardspagination.pageSize,
        },
        allBatchCardssortBy,
      });
      setState((draft) => {
        draft.detailsfilters.status = "";
        draft.detailsfilters.searchText = "";
        draft.detailsfilters.isSearching = true;
      });
    }
  };

  const getCardTransactionDetailsList = async ({
    pagination,
    transactionsortBy = [],
  }) => {
    let params = {};
    if (transactionsortBy.length > 0) {
      const sortByParams = transactionsortBy.reduce(
        (obj, item) =>
          Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
        {}
      );
      params = {
        ...params,
        sort: sortByParams,
      };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getCardTransactionDetails(
        cardDetailsId,
        query,
        pagination
      );
      if (res.success) {
        setState((draft) => {
          draft.cardtransactionDetails = res.data.gift_card_transactions;
          draft.transactionpagination = res.data.pagination;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (cardDetailsId && giftCardList) {
      const { transactionsortBy } = state;
      getCardTransactionDetailsList(
        {
          pagination: state.transactionpagination,
        },
        transactionsortBy
      );
    }
  }, [cardDetailsId, giftCardList]);

  const getAllBatchCard = async ({
    searchText = "",
    status,
    pagination,
    allBatchCardssortBy = [],
  }) => {
    try {
      let params = {};
      if (searchText) {
        params = { ...params, search: searchText };
      }
      if (status !== "") {
        params = { ...params, status };
      }

      if (allBatchCardssortBy.length > 0) {
        const sortByParams = allBatchCardssortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });
      const res = await getAllBathCardList(query, giftCardId, pagination);
      if (res.success) {
        setState((draft) => {
          draft.allBatchCards = res.data;
          draft.isBusy = false;
          draft.allBatchCardspagination = res.data.pagination;
          draft.initialLoad = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
      });
    }
  };
  useEffect(() => {
    const { allBatchCardssortBy } = state;
    if (giftCardId && currentTab === "2") {
      getAllBatchCard({
        giftCardId,
        pagination: {
          pageIndex: 1,
          pageSize: state.allBatchCardspagination.pageSize,
        },
        allBatchCardssortBy,
      });
    }
  }, [giftCardId, currentTab]);

  const gotoPage = (page) => {
    const { batch_name, card_type, expired_on, searchText, status } =
      state.filters;
    const { sortBy } = state;

    getAllCard({
      batch_name: batch_name,
      card_type: card_type,
      expired_on: expired_on,
      searchText: searchText,
      status: status,
      pagination: {
        pageIndex: page,
        pageSize: state.pagination.pageSize,
      },
      sortBy,
    });

    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };

  const setPageSize = (e) => {
    const { batch_name, card_type, expired_on, searchText, status } =
      state.filters;
    const { sortBy } = state;
    getAllCard({
      batch_name: batch_name,
      card_type: card_type,
      expired_on: expired_on,
      searchText: searchText,
      status: status,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };
  const handleSort = (sortBy) => {
    const { batch_name, card_type, expired_on, searchText, status } =
      state.filters;
    const { pagination } = state;
    getAllCard({
      batch_name: batch_name,
      card_type: card_type,
      expired_on: expired_on,
      searchText: searchText,
      status: status,
      pagination,
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_SORT",
      page: PAGE_NAME,
      value: sortBy,
    });
  };

  const gototransactionPage = (page) => {
    const { transactionsortBy } = state;
    getCardTransactionDetailsList({
      pagination: {
        pageIndex: page,
        pageSize: state.transactionpagination.pageSize,
      },
      transactionsortBy,
    });
    setState((draft) => {
      draft.transactionpagination.pageIndex = page;
    });
  };

  const settransactionPageSize = (e) => {
    const { transactionsortBy } = state;
    getCardTransactionDetailsList({
      pagination: {
        pageIndex: state.transactionpagination.pageIndex,
        pageSize: +e.target.value,
      },
      transactionsortBy,
    });
    setState((draft) => {
      draft.transactionpagination.pageSize = +e.target.value;
    });
  };
  const handletransactionSort = (transactionsortBy) => {
    const { transactionpagination } = state;
    getCardTransactionDetailsList({
      pagination: transactionpagination,
      transactionsortBy,
    });
  };

  const gotoBatchcardPage = (page) => {
    const { searchText, status } = state.detailsfilters;
    const { allBatchCardssortBy } = state;
    getAllBatchCard({
      searchText: searchText,
      status: status,
      pagination: {
        pageIndex: page,
        pageSize: state.allBatchCardspagination.pageSize,
      },
      allBatchCardssortBy,
    });
    setState((draft) => {
      draft.allBatchCardspagination.pageIndex = page;
    });
  };

  const setBatchcardPageSize = (e) => {
    const { searchText, status } = state.detailsfilters;
    const { allBatchCardssortBy } = state;
    getAllBatchCard({
      searchText: searchText,
      status: status,
      pagination: {
        pageIndex: state.allBatchCardspagination.pageIndex,
        pageSize: +e.target.value,
      },
      allBatchCardssortBy,
    });
    setState((draft) => {
      draft.allBatchCardspagination.pageSize = +e.target.value;
    });
  };
  const handleBatchcardSort = (allBatchCardssortBy) => {
    const { searchText, status } = state.detailsfilters;
    const { allBatchCardspagination } = state;
    getAllBatchCard({
      searchText: searchText,
      status: status,
      pagination: allBatchCardspagination,
      allBatchCardssortBy,
    });
    setState((draft) => {
      draft.allBatchCardssortBy = allBatchCardssortBy;
    });
  };

  const getAllGiftCardGroupsList = async ({ search = null, page }) => {
    let params = {};
    let pagination = {
      pageIndex: page,
      pageSize: 2,
    };
    if (search) {
      params = { ...params, search };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      const res = await getGiftCardGroups(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.allGiftCardGroups = res?.data?.gift_card_groups_list?.map(
            (data) => {
              return { value: data.id, label: data.label };
            }
          );
        });
        const options = res?.data?.gift_card_groups_list?.map((data) => {
          return {
            value: data.id,
            label: data.label,
          };
        });
        return {
          options: options,
          hasMore: res.data.pagination.hasMorePages,
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (err) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  return {
    state,
    globalData,
    setCurentTab,
    currentTab,
    giftCardList,
    onChange,
    handleConditionType,
    onSaveGiftCard,
    handleDeleteGiftCard,
    handleDeleteModal,
    handleDownloadAllCards,
    handleShareGiftCards,
    onEmailChange,
    toggleModal,
    handleFilter,
    handleFilterClear,
    handleApplyFilter,
    onChangeFilter,
    onSeachClear,
    handleDownloadSingleCards,
    onDeleteStoreSelect,
    onUpdateGiftCard,
    onChangeFilterData,
    resetFilters,
    handleInputChangeStatus,
    isCreateVisible,
    isVisible,
    isEditable,
    isDeletable,
    resetCurrentTabs,
    handleSort,
    gotoPage,
    setPageSize,
    handletransactionSort,
    settransactionPageSize,
    gototransactionPage,
    gotoBatchcardPage,
    setBatchcardPageSize,
    handleBatchcardSort,
    getAllGiftCardGroupsList,
  };
};
