import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import React from "react";
import { GarbageProduct } from "./GarbageProduct";
import { useDrop } from "react-dnd";
import InputControl from "../../../shared/components/InputControl";
import { useState } from "react";

export const ProductList = ({ garbage, category, moveItemToGarbage }) => {
  const [searchText, setSearchText] = useState("");
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: "prd",
      drop: (item, monitor) => moveItemToGarbage(item),
      canDrop: (item) => {
        const hasItem = garbage.find((prd) => prd.id === item.productId);
        if (hasItem) {
          return false;
        }
        return true;
      },
      collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    }),
    [garbage]
  );

  const filteredProducts = garbage.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLocaleLowerCase())
  );

  return (
    <Stack
      sx={{
        borderLeft: "1px solid #E6E6E6",
        height: "calc(100vh - 100px)",
        overflow: "auto",
      }}
      ref={drop}
    >
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          borderBottom: "1px solid #E6E6E6",
          padding: "12px 16px",
        }}
      >
        {category} Products
      </Box>
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          borderBottom: "1px solid #E6E6E6",
          padding: "6px 16px",
        }}
      >
        <InputControl
          type="table-search"
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onClear={() => setSearchText("")}
          sx={{ height: "44px", backgroundColor: "rgba(0, 0, 0, 0.06)" }}
        />
      </Box>
      {filteredProducts.map((item, i) => (
        <GarbageProduct key={item.id} product={item} />
      ))}
    </Stack>
  );
};
