import { CustomDetailsList, Loader } from "../../../shared";
import * as React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";

export const CategoryGeneralDetails = (props) => {
  const navigate = useNavigate();
  const { state, isEditable, id } = props;
  const columns = React.useMemo(
    () => [
      {
        title: "Display Name",
        accessor: "display_name",
      },
      {
        title: "Display Order",
        accessor: "display_order",
      },
      {
        title: "Report Category",
        accessor: "report_category_name",
      },
      {
        title: "Report Class Name",
        accessor: "report_class_name",
      },
      {
        title: "Colour Code",
        accessor: "color",
      },
      {
        title: "Description",
        accessor: "description",
        accessor: (row) => {
          return <div>{parse(row?.description ?? "")}</div>;
        },
      },
      {
        title: "Show in List",
        accessor: (row) => {
          return row.show_in_list ? "Yes" : "No";
        },
      },
    ],
    []
  );
  return (
    <>
      {state.product.isBusy ? (
        <Loader />
      ) : (
        <CustomDetailsList
          columns={columns}
          data={state.data}
          title="Basic Informations"
          headerRightContent={
            <>
              {isEditable && (
                <Button
                  type="button"
                  size="medium"
                  variant="contained"
                  onClick={() => navigate(`/category/details/edit/${id}`)}
                >
                  Edit
                </Button>
              )}
            </>
          }
        />
      )}
    </>
  );
};
