import {
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import {
  ContentLayout,
  CustomTable,
  CustomeDialog,
  HeaderBreadcrumbs,
  Loader,
  PageNotFound,
  usePermission,
} from "../../../shared";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { ConfirmOrderDialog, MorePopOver, OrderListTable } from "../components";
import { useRef } from "react";
import { useOrderDetails } from "../hooks/useOrderDetails";
import { RejectOrderDialog } from "../components/RejectOrderDialog";
import { RefundOrderDialog } from "../components/RefundOrderDialog";
import BankIcon from "../../../assets/images/Icons/icons8_merchant_account 1.svg";

export const OrderDetails = ({ discountOrders }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isStoreAdmin = pathname.includes("store-admin");
  const IsView = usePermission("refund-view");
  const { id } = useParams();
  const {
    state,
    setState,
    globalData,
    handleSendInvoice,
    handleDownloadInvoice,
    handleRefundInputs,
    handleModal,
    handleOrderConfirm,
    handleRejectInputs,
    handleOrderReject,
    handleOrderRefund,
  } = useOrderDetails({
    orderId: id,
  });
  const printRef = useRef();
  const paymentColumns = React.useMemo(
    () => [
      {
        Header: "Type",
        accessor: "method",
        disableSortBy: true,
      },
      {
        Header: "Amount",
        accessor: (val) => {
          return `$${val?.amount?.toFixed(2)}`;
        },
        disableSortBy: true,
      },
      {
        Header: "Cash Tendered ",
        Cell: ({ row }) => {
          return row.original.method === "Cash"
            ? `$${(
              row?.original?.amount + row?.original?.change_returned
            )?.toFixed(2)}`
            : "--";
        },

        disableSortBy: true,
      },
      {
        Header: "Change Given",
        accessor: "change_returned",
        Cell: ({ value }) => {
          return value === null ? "--" : `$${value?.toFixed(2)}`;
        },
        disableSortBy: true,
      },
      {
        Header: "Date & Time",
        accessor: "created_at",
        disableSortBy: true,
      },
    ],
    []
  );

  const refundColumns = React.useMemo(
    () => [
      {
        Header: "Refund Number",
        accessor: (val) => {
          return `${val.refund_number}`;
        },
        disableSortBy: true,
      },
      {
        Header: "Refunded",
        accessor: (val) => {
          return `$${val.grand_total?.toFixed(2)}`;
        },
        disableSortBy: true,
      },
      {
        Header: "Date & Time",
        accessor: "refund_date",
        disableSortBy: true,
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <div className="action-buttons">
              {IsView && (
                <Link
                  to={
                    isStoreAdmin
                      ? `/store-admin/refund/details/${row.original.id}`
                      : `/refund/details/${row.original.id}`
                  }
                  state={{ from: isStoreAdmin ? "store-order" : "super-order" }}
                >
                  <img alt="details" src="/icons/arrow_forward.svg" />
                </Link>
              )}
            </div>
          );
        },
      },
    ],
    []
  );
  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }
  return (
    <>
      {state.isDetailsFetching ? (
        <Loader />
      ) : (
        <ContentLayout
          title={state.order_number}
          breadcrumbs={
            <HeaderBreadcrumbs
              links={[
                {
                  name: "Dashboard",
                  href: isStoreAdmin ? "/store-admin" : "/",
                },
                discountOrders
                  ? {
                    name: "Discount Sales Log",
                    href: isStoreAdmin
                      ? "/store-admin/discount-orders"
                      : "/discount-orders",
                  }
                  : {
                    name: "Sales Log",
                    href: isStoreAdmin ? "/store-admin/orders" : "/orders",
                  },
                {
                  name: state.order_number || "",
                },
              ]}
            />
          }
          chipType={"salesStatus"}
          label={
            globalData.order_statues?.find(
              (e) => e.value === state.data.status_id
            )?.label
          }
          labelColor={state.data?.status_id <= 4 ? "success" : "error"}
          rightContent={
            <>
              <Button
                sx={{ width: "40px" }}
                type="button"
                size="small"
                variant="text"
                onClick={() => handleDownloadInvoice("print")}
                disabled={state.invoiceLoading}
              >
                <img alt="tools" src="/icons/ic_print.svg" />
              </Button>
              <MorePopOver
                SendInvoice={handleSendInvoice}
                DownloadInvoice={handleDownloadInvoice}
              />
              {/* "Refund Order" button */}
              {/* "Reject" button */}
              {state?.data?.channel_id === 101 &&
                (state.data.status_id === 2 || state.data.status_id === 4) ? (
                <Button
                  color="error"
                  onClick={() => handleModal("refund", true)}
                >
                  Refund Order
                </Button>
              ) : (
                ""
              )}

              {/* "Reject" button */}

              {/* RefundOrderDialog */}
              <CustomeDialog
                title={
                  <Typography variant="h6" sx={{ padding: "20px" }}>
                    Refund
                  </Typography>
                }
                PaperProps={{
                  sx: {
                    minWidth: "579px",
                    minHeight: "297px",
                  },
                }}
                open={state.isOpenRefund}
                handleClose={() => handleModal("refund", false)}
                content={
                  <RefundOrderDialog
                    onChange={handleRefundInputs}
                    onSubmit={handleOrderRefund}
                    isButton={state.isButtonRefund}
                    data={state.refundInputs}
                    handleClose={() => handleModal("refund", false)}
                  />
                }
              />
              {/* RejectOrderDialog */}
              <CustomeDialog
                title={
                  <Typography variant="h6" sx={{ padding: "20px" }}>
                    {state.rejectText} Order
                  </Typography>
                }
                PaperProps={{
                  sx: {
                    minWidth: "600px",
                    minHeight: "190px",
                  },
                }}
                open={state.isOpenReject}
                handleClose={() => handleModal("reject", false)}
                content={
                  <RejectOrderDialog
                    onChange={handleRejectInputs}
                    onSubmit={handleOrderReject}
                    isButton={state.isButtonReject}
                    data={state.rejectInput}
                    handleClose={() => handleModal("reject", false)}
                    text={state.rejectText}
                  />
                }
              />
              <CustomeDialog
                title={
                  <Typography variant="h6" sx={{ padding: "20px" }}>
                    Confirm Order
                  </Typography>
                }
                PaperProps={{
                  sx: {
                    minWidth: "423px",
                    minHeight: "190px",
                  },
                }}
                open={state.isOpenConfirm}
                handleClose={() => handleModal("confirm", false)}
                content={
                  <ConfirmOrderDialog
                    onSubmit={handleOrderConfirm}
                    isButton={state.isButtonConfirm}
                    handleClose={() => handleModal("confirm", false)}
                  />
                }
              />

              {state?.data?.status_id === 2 &&
                state?.data?.channel_id === 104 ? (
                <>
                  <Button
                    color="error"
                    onClick={() => handleModal("reject", true, "Cancel")}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                ""
              )}
              {state.data.status_id === 1 ? (
                <>
                  <Button
                    color="error"
                    onClick={() => handleModal("reject", true)}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleModal("confirm", true)}
                  >
                    Confirm
                  </Button>
                </>
              ) : (
                ""
              )}
            </>
          }
        >
          <Card>
            <Box ref={printRef}>
              <Grid
                container
                sx={{
                  height: "83px",
                  padding: "32px 24px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    fontSize={16}
                    sx={{
                      lineHeight: "19px",
                    }}
                  >
                    Order Summary
                  </Typography>
                </Grid>
                <Grid item sx={{ display: "flex" }}>
                  <Typography fontWeight={400} mr={1}>
                    Payment Status:
                  </Typography>
                  <Chip
                    label={state.data.payment_status}
                    color="success"
                    variant="filled"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  />
                </Grid>
              </Grid>
              <Divider />

              <Stack direction="row">
                <Box sx={{ padding: "15px" }}>
                  <Typography
                    fontWeight={700}
                    fontSize={13}
                    sx={{
                      lineHeight: "15px",
                      marginBottom: "6px",
                    }}
                  >
                    Store Details
                  </Typography>

                  <div
                    className="text-link"
                    onClick={() =>
                      navigate(`/stores/details/${state?.data?.store_id}`)
                    }
                  >
                    <Typography
                      fontWeight={400}
                      fontSize={12}
                      sx={{
                        lineHeight: "13px",
                        marginBottom: "4px",
                        mt: 1,
                      }}
                    >
                      {state?.data?.store_name}
                    </Typography>
                  </div>
                  <Typography
                    fontWeight={400}
                    fontSize={13}
                    sx={{
                      lineHeight: "15px",
                      marginBottom: "6px",
                      mt: 1,
                    }}
                  >
                    ABN: {state?.data?.store?.abn ?? ""}
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={13}
                    sx={{
                      lineHeight: "15px",
                      marginBottom: "6px",
                      mt: 1,
                    }}
                  >
                    Address: {state?.data?.store?.address ?? ""}
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={13}
                    sx={{
                      lineHeight: "15px",
                      marginBottom: "6px",
                      mt: 1,
                    }}
                  >
                    Phone: {state?.data?.store?.phone ?? ""}
                  </Typography>
                </Box>

                <Box sx={{ padding: "10px" }}>
                  <Typography
                    fontWeight={700}
                    fontSize={13}
                    sx={{
                      lineHeight: "15px",
                      marginBottom: "6px",
                    }}
                  >
                    Bill To
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={13}
                    sx={{
                      lineHeight: "15px",
                      marginBottom: "6px",
                      mt: 1,
                    }}
                  >
                    {state?.data?.customer?.id !== null ? (
                      <div
                        className="text-link"
                        onClick={() =>
                          navigate(
                            `/customers/details/${state?.data?.customer?.id}`
                          )
                        }
                      >
                        {state?.data?.customer?.name}
                      </div>
                    ) : (
                      state?.data?.customer?.name
                    )}
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={13}
                    sx={{
                      lineHeight: "15px",
                      marginBottom: "6px",
                      mt: 1,
                    }}
                  >
                    {state?.data?.customer?.email ?? ""}
                  </Typography>
                  {state.data.is_it_business_user === true && (
                    <>
                      <Typography
                        fontWeight={400}
                        fontSize={13}
                        sx={{
                          lineHeight: "15px",
                          marginBottom: "6px",
                          mt: 1,
                        }}
                      >
                        {state?.data?.customer?.business_name ?? ""}
                      </Typography>
                      <Typography
                        fontWeight={400}
                        fontSize={13}
                        sx={{
                          lineHeight: "15px",
                          marginBottom: "6px",
                          mt: 1,
                        }}
                      >
                        ABN: {state?.data?.customer?.abn_number ?? ""}
                      </Typography>
                      <Typography
                        fontWeight={400}
                        fontSize={13}
                        sx={{
                          lineHeight: "15px",
                          marginBottom: "6px",
                          mt: 1,
                        }}
                      >
                        {state?.data?.customer_address ?? ""}
                      </Typography>
                    </>
                  )}
                  <Typography
                    fontWeight={400}
                    fontSize={13}
                    sx={{
                      lineHeight: "15px",
                      marginBottom: "6px",
                      mt: 1,
                    }}
                  >
                    {state?.data?.customer?.phone_formatted ?? ""}
                  </Typography>
                </Box>

                {state.data.sales_type_id === 103 && (
                  <Box sx={{ padding: "10px" }}>
                    <Typography
                      fontWeight={700}
                      fontSize={13}
                      sx={{
                        lineHeight: "15px",
                        marginBottom: "6px",
                      }}
                    >
                      Delivery Address
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontSize={13}
                      sx={{
                        lineHeight: "15px",
                        marginBottom: "6px",
                        mt: 1,
                      }}
                    >
                      {state?.data?.customer_address ?? ""}
                    </Typography>
                  </Box>
                )}
              </Stack>
              <Divider />
              <Grid
                container
                sx={{
                  padding: "24px 16px",
                }}
              >
                <Grid item xs={3}>
                  <Box sx={{ padding: "10px" }}>
                    <Typography
                      fontWeight={700}
                      fontSize={13}
                      sx={{
                        lineHeight: "15px",
                        marginBottom: "6px",
                      }}
                    >
                      Order Details
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontSize={13}
                      sx={{
                        lineHeight: "15px",
                        marginBottom: "6px",
                        mt: 1,
                      }}
                    >
                      Order Type: {state.data?.sales_type}{" "}
                      {state.data?.sales_type_id === 103
                        ? `- ${state.data?.delivery_provider}`
                        : ""}
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontSize={13}
                      sx={{
                        lineHeight: "15px",
                        marginBottom: "6px",
                        mt: 1,
                      }}
                    >
                      Cashier:{""}
                      {state?.data?.finalised_by_user
                        ? state?.data?.finalised_by_user
                        : ""}
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontSize={13}
                      sx={{
                        lineHeight: "15px",
                        marginBottom: "6px",
                        mt: 1,
                      }}
                    >
                      Sales Channel: {state?.data?.channel_name} {state?.data.channel_id == "101" ? `- ${state?.data.platform ?? ""}` : ""}
                    </Typography>
                    {state?.data?.receipt ? (
                      <>
                        <Typography
                          fontWeight={400}
                          fontSize={13}
                          sx={{
                            lineHeight: "15px",
                            marginBottom: "6px",
                            mt: 1,
                          }}
                        >
                          Web Order No: {state?.data?.receipt}
                        </Typography>
                        {state.data?.channel_id === 104 ? (
                          <Typography
                            fontWeight={400}
                            fontSize={13}
                            sx={{
                              lineHeight: "15px",
                              marginBottom: "6px",
                              mt: 1,
                            }}
                          >
                            Merchant Tip Amount:{" "}
                            {state?.data?.third_party_merchant_tip_amount}
                          </Typography>
                        ) : null}
                      </>
                    ) : null}
                  </Box>
                </Grid>
                <Grid item mt={1} xs={3}>
                  <Stack sx={{ padding: "10px" }}>
                    <Typography
                      fontWeight={400}
                      fontSize={13}
                      sx={{
                        lineHeight: "15px",
                        marginBottom: "6px",
                        mt: 1,
                      }}
                    >
                      Order Number: {state.data?.order_number}
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontSize={13}
                      sx={{
                        lineHeight: "15px",
                        marginBottom: "6px",
                        mt: 1,
                      }}
                    >
                      Order Date: {state.data?.created_at}
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontSize={13}
                      sx={{
                        lineHeight: "15px",
                        marginBottom: "6px",
                        mt: 1,
                      }}
                    >
                      {state.data.sales_type_id === 103 ? "Delivery Date" : "Pick Up Date"} : {state.data?.order_date}
                    </Typography>
                  </Stack>
                </Grid>
                {state.data?.po_number !== null ||
                  state.data.sales_type_id === 100 ? (
                  <Grid item mt={1} xs={3}>
                    <Stack sx={{ padding: "10px" }}>
                      {state.data?.po_number === null ? (
                        ""
                      ) : (
                        <Typography
                          fontWeight={400}
                          fontSize={13}
                          sx={{
                            lineHeight: "15px",
                            marginBottom: "6px",
                            mt: 1,
                          }}
                        >
                          PO Number: {state.data?.po_number}
                        </Typography>
                      )}
                      {state.data.sales_type_id === 100 && (
                        <>
                          <Typography
                            fontWeight={400}
                            fontSize={13}
                            sx={{
                              lineHeight: "15px",
                              marginBottom: "6px",
                              mt: 1,
                            }}
                          >
                            Table Number: {state.data?.table_number}
                          </Typography>
                          <Typography
                            fontWeight={400}
                            fontSize={13}
                            sx={{
                              lineHeight: "15px",
                              marginBottom: "6px",
                              mt: 1,
                            }}
                          >
                            Cover: {state.data?.table_cover}
                          </Typography>
                        </>
                      )}
                    </Stack>
                  </Grid>
                ) : null}

                {/*  */}
                {state?.data?.delivery_status ? (
                  <Grid item mt={1} xs={3}>
                    <Stack sx={{ padding: "10px" }}>
                      <Typography
                        fontWeight={400}
                        fontSize={13}
                        sx={{
                          lineHeight: "15px",
                          marginBottom: "6px",
                          mt: 1,
                        }}
                      >
                        Estimated Pickup: {state?.data?.estimated_pick_up_time}
                      </Typography>
                      <Stack
                        sx={{
                          backgroundColor: "#5E736033",
                          padding: "4px 1px",
                          width: "fit-content",
                        }}
                      >
                        <Typography
                          fontWeight={500}
                          fontSize={14}
                          sx={{
                            lineHeight: "17px",
                            marginBottom: "6px",
                            m: 0,
                          }}
                        >
                          Delivery Status: {state?.data?.delivery_status}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                ) : null}
              </Grid>
              <OrderListTable state={state} />
              <Divider />
              <Stack sx={{
                flexDirection: "row",
                gap: "4px",
                alignItems: "center",
                padding: "6px 24px 10px"
              }}>
                <img alt="sign" src="/icons/ic_info_small.svg" />
                <Typography variant="caption" lineHeight="15px" color="#666666">
                  Denotes taxable items.
                </Typography>
              </Stack>
              {(state.data.coupons.length > 0 ||
                state.data.discounts.length > 0) && (
                  <>
                    <Grid
                      sx={{
                        padding: "32px 16px",
                      }}
                      container
                    >
                      <Grid item width="100%">
                        <Typography
                          fontWeight={500}
                          fontSize={14}
                          sx={{
                            lineHeight: "16px",
                            marginBottom: "17px",
                          }}
                        >
                          Coupons & Discounts
                        </Typography>
                        <Box>
                          {state.data.coupons.length > 0 &&
                            state.data.coupons.map((item) => (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      alt="details"
                                      src="/icons/icons8_discount.svg"
                                    />
                                    <Box ml={2}>
                                      <Typography
                                        fontWeight={400}
                                        fontSize={13}
                                        sx={{
                                          lineHeight: "15px",
                                          marginBottom: "7px",
                                        }}
                                      >
                                        {item.name}
                                      </Typography>
                                      <Typography
                                        fontWeight={400}
                                        fontSize={12}
                                        sx={{
                                          lineHeight: "14px",
                                        }}
                                        color="#C07302"
                                      >
                                        {item.code}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Typography
                                    fontWeight={400}
                                    fontSize={13}
                                    sx={{
                                      lineHeight: "15px",
                                    }}
                                  >
                                    ${item.discount?.toFixed(2)}
                                  </Typography>
                                </Box>
                                {state.data.discounts.length > 0 && <Divider />}
                              </>
                            ))}
                          {state.data.discounts.length > 0 &&
                            state.data.discounts.map((item) => (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "13px",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      alt="details"
                                      src="/icons/ic_discount.svg"
                                    />
                                    <Typography
                                      fontWeight={400}
                                      fontSize={13}
                                      sx={{
                                        lineHeight: "15px",

                                        marginLeft: "15px",
                                      }}
                                    >
                                      {item.name}
                                    </Typography>
                                  </Box>
                                  <Typography
                                    fontWeight={400}
                                    fontSize={13}
                                    sx={{
                                      lineHeight: "15px",
                                    }}
                                  >
                                    ${item?.discount}
                                  </Typography>
                                </Box>
                              </>
                            ))}
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                )}
              <Grid
                sx={{
                  padding: "16px 16px 32px",
                }}
                container
              >
                <Grid sx={{ padding: "10px" }} item>
                  <Typography
                    fontWeight={500}
                    fontSize={13}
                    sx={{
                      lineHeight: "15px",
                      marginBottom: "10px",
                    }}
                  >
                    Order Note
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={13}
                    sx={{
                      lineHeight: "15px",
                    }}
                  >
                    {state.data.order_notes}
                  </Typography>
                </Grid>
              </Grid>
              {/* bank details if it is a client account payement */}
              {/* {state.data?.client_account_total > 0 ? (
                <Stack
                  sx={{
                    backgroundColor: "#F2F2F2",
                    padding: "16px",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component="img"
                    src={BankIcon}
                    height={40}
                    width={40}
                    alt="bank_icon"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "6px",
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                      Pay your bill to
                    </Typography>
                    <Box
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#4D4D4D",
                      }}
                    >
                      <Typography>
                        Account Name: {state.data.store?.bank_account?.name}
                      </Typography>
                      <Typography>
                        BSB: {state.data.store?.bank_account?.bsb}
                      </Typography>
                      <Typography>
                        Account Number: {state.data.store?.bank_account?.number}
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              ) : null} */}
            </Box>
          </Card>
          <Card sx={{ marginTop: "24px", marginBottom: "16px" }}>
            <Grid
              container
              sx={{
                height: "83px",
                padding: "32px 24px",
              }}
            >
              <Grid item>
                <Typography
                  fontWeight={600}
                  fontSize={16}
                  sx={{
                    lineHeight: "15px",
                    marginBottom: "10px",
                  }}
                >
                  Payment Method
                </Typography>
              </Grid>
            </Grid>

            <CustomTable
              columns={paymentColumns}
              data={state.data.payments}
              defaultSearch={false}
              completeFeature={false}
              pagination={false}
              tableTitle={null}
              searchBar={null}
            />
          </Card>

          {state.data.refunds?.length > 0 ? (
            <Card sx={{ marginTop: "24px", marginBottom: "16px" }}>
              <Grid
                container
                sx={{
                  height: "83px",
                  padding: "32px 24px",
                }}
              >
                <Grid item>
                  {" "}
                  <Typography
                    fontWeight={600}
                    fontSize={16}
                    sx={{
                      lineHeight: "15px",
                      marginBottom: "10px",
                    }}
                  >
                    Refund
                  </Typography>
                </Grid>
              </Grid>

              <CustomTable
                columns={refundColumns}
                data={state.data.refunds}
                defaultSearch={false}
                completeFeature={false}
                pagination={false}
                tableTitle={null}
                searchBar={null}
              />
            </Card>
          ) : (
            ""
          )}
        </ContentLayout>
      )}
    </>
  );
};
