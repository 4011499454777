import * as React from "react";
import {
  Typography,
  Button,
  Stack,
  Grid,
  Card,
  Divider,
  Switch,
  CardHeader,
  List,
  ListItemText,
  ListItem,
  Box,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";

import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import { useParams, useLocation } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { DayPicker } from "../../Coupons/components";
import { useCustomers } from "../hooks";
import SimpleReactValidator from "simple-react-validator";

export const ClientAccountDetails = (prop) => {
  const { id, cid } = useParams();
  const { pathname } = useLocation();
  const isCustomer = pathname.includes("customers");
  const {
    state,
    globalData,
    isStoreAdmin,
    handleInputChange,
    onUpdateClientAccount,
    onDayPicker,
    value,
    setValue,
  } = useCustomers({ ClientAccountID: cid, cuid: id });
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const { details, isLoading, isSaveButtonBusy, updateData } =
    state.clientAccountDetails;

  React.useEffect(() => {
    if (!updateData.bill_day) {
      setValue(1);
    } else {
      setValue(2);
    }
  }, [updateData.bill_day]);
  const options = [
    {
      label: "First",
      value: 1,
    },
    {
      label: "Second",
      value: 2,
    },
    {
      label: "Third",
      value: 3,
    },
    {
      label: "Fourth",
      value: 4,
    },
  ];

  const onSubmit = () => {
    if (validator.current.allValid()) {
      onUpdateClientAccount(cid, value);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <ContentLayout
        title={details?.customer_name}
        active={details?.status ? "Active" : "Inactive"}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={
                isCustomer
                  ? [
                      { name: "Dashboard", href: "/" },
                      { name: "Customer", href: "/customers" },
                      {
                        name: "Client Account",
                        href: `/customers/details/${id}`,
                      },
                      {
                        name: details.customer_name,
                        href: `/customers/details/${id}/clientaccountId/${cid}`,
                      },
                      { name: "edit" },
                    ]
                  : [
                      {
                        name: "Dashboard",
                        href: isStoreAdmin ? "/store-admin" : "/",
                      },
                      {
                        name: "Client Account",
                        href: isStoreAdmin
                          ? `/store-admin/client-account`
                          : `/client-account`,
                      },
                      {
                        name: details.customer_name,
                        href: isStoreAdmin
                          ? `/store-admin/client-account/details/${cid}`
                          : `/client-account/details/${cid}`,
                      },
                      { name: "edit" },
                    ]
              }
            />
          </>
        }
        rightContent={
          !isLoading && (
            <Button
              type="button"
              size="medium"
              variant="contained"
              disabled={isSaveButtonBusy}
              onClick={() => onSubmit()}
            >
              Update
            </Button>
          )
        }
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Card sx={{ marginBottom: "20px" }}>
              {validator.current.purgeFields()}
              <List disablePadding>
                <ListItem divider={true} disablePadding>
                  <ListItemText
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                    disableTypography={true}
                  >
                    <CardHeader title={details.store_name} />
                  </ListItemText>
                </ListItem>
              </List>
              <Stack p="24px 24px 16px">
                <Stack direction="row" gap="24px" width="100%">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ width: "150px" }}
                      >
                        Credit Limit
                      </Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <InputControl
                        name="carry_forward"
                        label="Opening Credit / Carry Forward ($)"
                        value={updateData.carry_forward}
                        onChange={handleInputChange}
                        disabled={true}
                      />
                      <Stack direction="row" spacing={2} mt={2}>
                        <InputControl
                          label="Maximum Credit Amount (In $)"
                          type="number"
                          name="credit_limit"
                          value={
                            updateData.credit_limit
                              ? Math.round(Number(updateData.credit_limit))
                              : null
                          }
                          onChange={handleInputChange}
                          error={validator.current.message(
                            "Maximum Credit Amount",
                            updateData.credit_limit,
                            "required|numeric"
                          )}
                          helperText={validator.current.message(
                            "Maximum Credit Amount",
                            updateData.credit_limit,
                            "required|numeric"
                          )}
                        />

                        <Grid item xs={12} sm={8}>
                          <Typography variant="subtitle1" fontWeight={600}>
                            Restrict Credit
                          </Typography>
                          <Typography color="#636363">
                            Restrict user to maximum credit amount only.
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <Stack gap="20px" alignItems="flex-end">
                            <Switch
                              name="restrict_usage"
                              defaultChecked={
                                updateData.restrict_usage ? true : false
                              }
                              value={updateData.restrict_usage}
                              onChange={handleInputChange}
                            />
                          </Stack>
                        </Grid>
                      </Stack>
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        color="#C07302"
                      >
                        Total Due Amount - ${details.due_amount}
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
              <Divider marginTop={2} />
              <Grid
                container
                spacing={2}
                marginTop={2}
                sx={{ padding: "20px" }}
              >
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" fontWeight={600}>
                    Statement Frequency
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack width={"100%"} gap="20px" direction="column">
                    <Stack direction="row" gap="24px">
                      <Typography width="95px" mt={1}>
                        Every
                      </Typography>
                      <Stack>
                        <Stack direction="row" gap="24px">
                          <InputControl
                            type="tel"
                            name="bill_duration"
                            width="48px"
                            value={updateData.bill_duration}
                            onChange={handleInputChange}
                            error={validator.current.message(
                              "Bill duration",
                              updateData.bill_duration,
                              updateData.bill_period === 1
                                ? "required|numeric|min:1,num"
                                : updateData.bill_period === 2
                                ? "required|numeric|max:12,num|min:1,num"
                                : "required|numeric,num|min:1,num"
                            )}
                          />

                          <InputControl
                            type="select"
                            name="bill_period"
                            label=""
                            value={updateData.bill_period}
                            optionValue={
                              globalData?.client_accounts?.bill_period ?? []
                            }
                            width="150px"
                            onChange={(e, v) => handleInputChange(e)}
                          />
                        </Stack>
                        <FormHelperText sx={{ color: "#C60808" }}>
                          {validator.current.message(
                            "Bill duration",
                            updateData.bill_duration,
                            updateData.bill_period === 1
                              ? "required|numeric|min:1,num"
                              : updateData.bill_period === 2
                              ? "required|numeric|max:12,num|min:1,num"
                              : "required|numeric|min:1,num"
                          )}
                        </FormHelperText>
                      </Stack>
                    </Stack>
                    {updateData.bill_period === 1 ? (
                      <Stack direction="row" gap="24px" spacing={8}>
                        <Typography width="89">Day</Typography>
                        <Stack width="100%">
                          <DayPicker
                            options={globalData?.client_accounts?.bill_day}
                            onDayPicker={onDayPicker}
                            data={updateData?.bill_day}
                          />
                          <FormHelperText sx={{ color: "#C60808" }}>
                            {updateData.bill_period === 1 &&
                              validator.current.message(
                                "Bill Day",
                                updateData.bill_day,
                                "required"
                              )}
                          </FormHelperText>
                        </Stack>
                      </Stack>
                    ) : updateData.bill_period === 2 ? (
                      <>
                        <Stack
                          direction="row"
                          gap="22px"
                          alignItems={"flex-start"}
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={value === 1 ? true : false}
                                value={1}
                                onClick={() => {
                                  setValue(1);
                                }}
                              />
                            }
                            label="On day"
                          />
                          <Box>
                            <InputControl
                              type="tel"
                              name="bill_date"
                              value={updateData.bill_date}
                              onChange={handleInputChange}
                              disabled={value === 2}
                              error={
                                value === 1 &&
                                validator.current.message(
                                  "Bill Date",
                                  updateData.bill_date,
                                  "required|numeric|max:31,num|min:1,num"
                                )
                              }
                              width={"45px"}
                            />
                            <FormHelperText sx={{ color: "#C60808" }}>
                              {value === 1 &&
                                validator.current.message(
                                  "Bill Date",
                                  updateData.bill_date,
                                  "required|numeric|max:31,num|min:1,num"
                                )}
                            </FormHelperText>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          gap="24px"
                          alignItems="flex-start"
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={value === 2 ? true : false}
                                value={2}
                                onClick={() => setValue(2)}
                              />
                            }
                            label="On the"
                          />

                          <Stack>
                            <Stack direction="row" gap="25px">
                              <InputControl
                                type="select"
                                name="bill_date2"
                                value={updateData.bill_date2}
                                optionValue={options}
                                disabled={value === 1}
                                onChange={handleInputChange}
                                width="180px"
                                error={
                                  value === 2 &&
                                  validator.current.message(
                                    "Bill Date",
                                    updateData.bill_date2,
                                    "required"
                                  )
                                }
                                helperText={
                                  value === 2 &&
                                  validator.current.message(
                                    "Bill Date",
                                    updateData.bill_date2,
                                    "required"
                                  )
                                }
                              />
                              <InputControl
                                type="select"
                                name="bill_day"
                                value={updateData.bill_day}
                                optionValue={
                                  globalData?.client_accounts?.bill_day ?? []
                                }
                                disabled={value === 1}
                                onChange={handleInputChange}
                                width="180px"
                                error={
                                  value === 2 &&
                                  validator.current.message(
                                    "Bill Day",
                                    updateData.bill_day,
                                    "required"
                                  )
                                }
                                helperText={
                                  value === 2 &&
                                  validator.current.message(
                                    "Bill Day",
                                    updateData.bill_day,
                                    "required"
                                  )
                                }
                              />
                            </Stack>
                          </Stack>
                        </Stack>
                      </>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Box mt={3}>
                <Divider />
              </Box>

              <Grid
                container
                spacing={2}
                marginTop={1}
                marginBottom={1}
                sx={{ padding: "20px" }}
              >
                <Grid item xs={12} sm={8}>
                  <Typography variant="subtitle1" fontWeight={600}>
                    Account Status
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <Stack gap="20px" alignItems="flex-end">
                    <Switch
                      name="status"
                      defaultChecked={updateData.status ? true : false}
                      value={updateData.status}
                      onChange={handleInputChange}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </ContentLayout>
    </>
  );
};
