import { useImmer } from "use-immer";
import { useDropdownApis } from "../../../shared";
import { errorMessage, successMessage } from "../../../utils";
import { getBSReport } from "../../List";
import React, { useEffect } from "react";
import qs from "qs";
import SimpleReactValidator from "simple-react-validator";
import { AppContext } from "../../../store";

export const useBSReport = () => {
  const { appState } = React.useContext(AppContext);
  const { globalData } = appState;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const [state, setState] = useImmer({
    isBusy: false,
    isApply: false,
    data: [],
    filterApplied: {
      all: [],
      store_id: [],
      year: {},
      month: [],
    },
  });
  const { storeList } = useDropdownApis({
    isStoreList: true,
  });

  const onSaveBSReport = async ({ store_id, month, year }) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      let params = {};
      if (store_id?.length > 0) {
        let IDs = store_id.map((v) => {
          return v.value;
        });
        params = { ...params, store_id: IDs };
      }
      if (toString(year.value)) {
        params = { ...params, year: year.value };
      }
      if (month?.length > 0) {
        let IDs = month.map((v) => {
          return v.value;
        });
        params = { ...params, month: IDs };
      }
      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };
      const res = await getBSReport(query);

      if (res?.length) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.data = res.length ? res : [];
          draft.isSaveButtonBusy = false;
        });
      } else {
        errorMessage("Report is not available.");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onChange = (e, value, type) => {
    if (type === "store") {
      setState((draft) => {
        draft.filterApplied.store_id = value.map((v) => {
          return { ...v, type: "Store" };
        });
      });
    } else if (type === "year") {
      setState((draft) => {
        draft.filterApplied.year = {
          value: value.value,
          label: value.label,
          type: "Year",
        };
      });
    }
    if (type === "month") {
      setState((draft) => {
        draft.filterApplied.month = value.map((v) => {
          return { ...v, type: "Month" };
        });
      });
      setState((draft) => {
        draft.disableApplyButton = false;
      });
    }
  };

  useEffect(() => {
    setState((draft) => {
      draft.filterApplied.all = [
        ...state?.filterApplied?.store_id,
        ...state?.filterApplied?.month,
        state?.filterApplied?.year,
      ].filter((el) => Object.keys(el).length);
    });
  }, [
    state.filterApplied.store_id,
    state.filterApplied.month,
    state.filterApplied.year,
  ]);
  const handleApplyFilter = () => {
    const { store_id, month, year } = state.filterApplied;
    setState((draft) => {
      draft.isApply = true;
    });
    if (validator.current.allValid()) {
      onSaveBSReport({ store_id, month, year });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const handleFilterClear = (type, value) => {
    if (type === "single") {
      if (value.type === "Store") {
        const filteredStores = state.filterApplied.store_id.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.store_id = filteredStores;
        });
      }
      if (value.type === "Month") {
        const filteredStores = state.filterApplied.month.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.month = filteredStores;
        });
      } else if (value.type === "Year") {
        setState((draft) => {
          draft.filterApplied.year = {};
        });
      }
    } else {
      setState((draft) => {
        draft.filterApplied.all = [];
        draft.filterApplied.store_id = [];
        draft.filterApplied.month = [];
        draft.filterApplied.year = {};
        draft.isApply = false;
      });
    }
  };
  return {
    state,
    storeList,
    validator,
    onChange,
    handleApplyFilter,
    handleFilterClear,
    globalData,
  };
};
