import React from "react";
import { Route, Routes } from "react-router-dom";
import { StateAndSuburbList, CreateOrEditState, CreateOrEditSuburb } from "..";
import { ProtectedRoute } from "../../shared";

export const StateAndSuburb = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<StateAndSuburbList />} />
        <Route element={<ProtectedRoute allowedRoute={["state-modify"]} />}>
          <Route path="/state/edit/:id" element={<CreateOrEditState />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["state-create"]} />}>
          <Route path="/state/create" element={<CreateOrEditState />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["city-modify"]} />}>
          <Route path="/suburb/edit/:id" element={<CreateOrEditSuburb />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["city-create"]} />}>
          <Route path="/suburb/create" element={<CreateOrEditSuburb />} />
        </Route>
      </Routes>
    </>
  );
};
