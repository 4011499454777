import { ContentLayout } from "../../../shared";
import * as React from "react";
import { Button, Chip, Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CustomTable } from "../../../shared";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../../../store/AuthProvider";
import { DeleteModal } from "../../../shared";
import { sortItems } from "../../../utils";
import { usePushNotification } from "..";

export const PushNotificationList = () => {
  const navigate = useNavigate();
  const { state, globalData, handleModal, handleDelete } = usePushNotification({
    isList: true,
  });
  const { isLoading, dataList, deleteModalOpen } = state;

  const ActionButtons = ({ id, editAction, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        <Link to={`/push-notification/edit/${id}`}>
          <img
            alt="details"
            onClick={editAction}
            src="/icons/arrow_forward.svg"
          />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Type",
        accessor: "type",
        sortInverted: true,
        Cell: ({ value }) => {
          return globalData?.push_notifications?.push_notification_type?.find(
            (v) => v.value == value
          ).label;
        },
      },
      {
        Header: "Notification text",
        accessor: "description",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Scheduled Time",
        accessor: "scheduled_at",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Created By",
        accessor: "name",
      },
      {
        Header: "Target",
        accessor: "target_count",
      },
      {
        id: "up",
        Header: "Updated At",
        accessor: "created_at",
      },
      {
        Header: "Status",
        accessor: "status",
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return (
            <>
              {value === 2 && (
                <Chip
                  label="Completed"
                  color={"success"}
                  variant="outlined"
                  size="small"
                  sx={{
                    border: "none !important",
                  }}
                />
              )}
              {value === 1 && (
                <Chip
                  sx={{
                    border: "none",
                  }}
                  label={
                    <Typography
                      color="#C07302"
                      variant="subtitle2"
                      fontWeight={400}
                    >
                      Published
                    </Typography>
                  }
                  style={{ backgroundColor: "#FFF4CE" }}
                  variant="outlined"
                  size="small"
                />
              )}
              {value === 0 && (
                <Chip
                  label="Draft"
                  color="primary"
                  variant="outlined"
                  size="small"
                  sx={{ bgcolor: "#1560D414", border: "none !important" }}
                  disabled={false}
                />
              )}
            </>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        accessor: "id",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return (
            <ActionButtons
              id={value}
              deleteAction={() => handleModal(true, value)}
            />
          );
        },
      },
    ],
    [globalData]
  );

  return (
    <>
      <ContentLayout
        title="Push Notification"
        rightContent={
          <>
            {/* {isCreateVisible && ( */}
            <Button
              type="button"
              size="medium"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate("create")}
            >
              New Notification
            </Button>
            {/* )} */}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={dataList}
          isLoading={isLoading}
          tableId="push-notification"
          onRowLink={(e) => {
            return `/push-notification/edit/${e.id}`;
          }}
        />
        <DeleteModal
          open={deleteModalOpen}
          handleClose={() => handleModal(false, null)}
          onConfirm={() => handleDelete()}
        />
      </ContentLayout>
    </>
  );
};
