import * as React from "react";
import { CustomTable, DeleteModal, usePermission } from "../../../shared";
import { Link } from "react-router-dom";
import { Button, Box } from "@mui/material";
import { sortItems } from "../../../utils";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

export const TaxesList = (props) => {
  const isDeletable = usePermission("tax-delete");
  const isEditable = usePermission("tax-modify");
  const isCreatable = usePermission("tax-create");
  const { data, handleModal, onDelete } = props;
  const { isLoading, listData, deleteModalOpen } = data;
  const navigate = useNavigate();

  const ActionButtons = ({ id, deleteAction }) => {
    return (
      <div className="action-buttons">
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
        {isEditable && (
          <Link to={`taxes/edit/${id}`}>
            <img alt="edit" src="/icons/ic_edit.svg" />
          </Link>
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Tax Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Tax Rate",
        disableSortBy: true,
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return <Box>{row.tax_rate}%</Box>;
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,

        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() =>
                handleModal("Taxes-list-delete", true, row.id)
              }
            />
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <CustomTable
        columns={columns}
        data={listData}
        isLoading={isLoading}
        tableTitle="Taxes"
        tableId="tax_list"
        headerRightContent={
          <>
            {isCreatable && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("taxes/create")}
              >
                New Tax
              </Button>
            )}
          </>
        }
      />
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => handleModal("Taxes-list-delete", false, null)}
        onConfirm={onDelete}
      />
    </>
  );
};
