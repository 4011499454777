import { ContentLayout, CustomTable } from "../../../shared";
import React from "react";
import {
  Button,
  Checkbox,
  Stack,
  TextField,
  createFilterOptions,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { arraySort } from "../../../utils";
import { usePLReport } from "../hooks/usePLReport";

export const PLReportList = () => {
  const {
    state,
    storeList,
    onChange,
    handleApplyFilter,
    handleFilterClear,
    validator,
    globalData,
  } = usePLReport({
    isList: true,
  });

  const storeOptions = storeList?.map((v) => {
    return { value: v.id, label: v.name };
  });
  const getRowProps = (row) => {
    return {
      style: {
        backgroundColor: row.original.Head ? "#E6E6E6" : "#ffffff",
      },
    };
  };

  const columns = React.useMemo(() => {
    if (state?.data?.length === 0) return [];
    const headers = state?.data?.length && Object?.keys(state?.data[0]);
    const headerFilter = headers?.filter((i) => i !== "Head");

    return (
      headerFilter.length &&
      headerFilter?.map((header) => ({
        Header: header.replace(/_/g, " "),
        accessor: header,
      }))
    );
  }, [state?.data]);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });
  return (
    <>
      <ContentLayout title="Profit and Loss">
        <CustomTable
          columns={columns}
          data={state?.data ?? []}
          searchBar={true}
          rowsSelection={false}
          defaultSearch={false}
          pagination={false}
          filterApplied={state?.filterApplied?.all}
          onFilterClear={(e) => handleFilterClear("single", e)}
          onAllFilterClear={(e) => handleFilterClear("all")}
          getRowProps={getRowProps}
          filterPosition={"defaultBottom"}
          hideContents={!state.isApply}
          filterSection={
            <>
              <Stack
                direction="row"
                gap="16px"
                alignItems="center"
                flexWrap="wrap"
              >
                <InputControl
                  type="dropdown"
                  name="store_id"
                  label="Store"
                  multiple={true}
                  options={arraySort(storeOptions ?? [], "label")}
                  value={state?.filterApplied?.store_id}
                  onChange={(e, v) => onChange(e, v, "store")}
                  disableCloseOnSelect
                  disableClearable={true}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Store"
                      placeholder="Store"
                      error={validator.current.message(
                        "Store",
                        state?.filterApplied?.store_id,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "Store",
                        state?.filterApplied?.store_id,
                        "required"
                      )}
                      required
                    />
                  )}
                />
                <InputControl
                  type="dropdown"
                  disableClearable
                  options={
                    globalData?.pl_bs_reports?.pl_bs_accounts_years || []
                  }
                  getOptionLabel={(option) => option.label}
                  value={
                    (globalData?.pl_bs_reports?.pl_bs_accounts_years?.length >
                      0 &&
                      state?.data?.year &&
                      globalData?.pl_bs_reports?.pl_bs_accounts_years?.find(
                        (c) => c.value === state?.filterApplied?.year.value
                      )) ||
                    null
                  }
                  sx={{ width: 250 }}
                  onChange={(e, v) => onChange(e, v, "year")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Financial Year"
                      error={validator.current.message(
                        "Financial Year",
                        state?.filterApplied?.year.value,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "Financial Year",
                        state?.filterApplied?.year.value,
                        "required"
                      )}
                      required
                    />
                  )}
                />

                <InputControl
                  type="dropdown"
                  label="Month"
                  multiple={true}
                  options={
                    globalData?.pl_bs_reports?.pl_bs_accounts_months ?? []
                  }
                  onChange={(e, v) => onChange(e, v, "month")}
                  disableCloseOnSelect
                  // disableClearable={true}
                  value={state?.filterApplied?.month}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value?.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Month"
                      placeholder="Month"
                      error={validator.current.message(
                        "Month",
                        state?.filterApplied?.month,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "Month",
                        state?.filterApplied?.month,
                        "required"
                      )}
                      required
                    />
                  )}
                />
              </Stack>
              <Button
                type="button"
                size="medium"
                variant="text"
                color="info"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => handleApplyFilter()}
              >
                Apply
              </Button>
            </>
          }
        />
      </ContentLayout>
    </>
  );
};
