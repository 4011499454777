import {
  ContentLayout,
  DeleteModal,
  HeaderBreadcrumbs,
  Loader,
} from "../../../shared";
import * as React from "react";
import { Box, Tab, Button } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useParams } from "react-router-dom";
import { useGiftCard } from "../hooks/useGiftCard";
import { PreloadedCardDetailsList } from "./PreloadedCardDetailsList";
import { PreloadedCardGiftCardDetailsList } from "./PreloadedCardGiftCardDetailsList";

export const PreloadedCardDetails = () => {
  const { id } = useParams();
  const {
    state,
    currentTab,
    setCurentTab,
    handleDeleteModal,
    handleDeleteGiftCard,
    handleDownloadAllCards,
    handleShareGiftCards,
    onEmailChange,
    toggleModal,
    handleDownloadSingleCards,
    onChangeFilterData,
    globalData,
    resetFilters,
    isEditable,
    isDeletable,
    giftCardGroupDetails,
    gotoBatchcardPage,
    setBatchcardPageSize,
    handleBatchcardSort,
  } = useGiftCard({ giftCardId: id, isAllCards: true, isBatchCards: false });
  const { giftCardDetails, isBusy, allBatchCards } = state;

  const handleChange = (event, newValue) => {
    setCurentTab("giftCardGroupDetails", newValue);
  };
  return (
    <>
      <ContentLayout
        title={giftCardDetails.batch_name}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                {
                  name: "Gift Card",
                  href: "/gift-card",
                },
                { name: giftCardDetails.batch_name },
              ]}
            />
          </>
        }
        rightContent={
          <>
            {isDeletable && (
              <Button
                aria-label="delete"
                color="primary"
                type="button"
                size="medium"
                variant="text"
                className="delte"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => {
                  handleDeleteModal("details", true, id);
                }}
              >
                <img src="/icons/ic_delete_blue.svg" />
              </Button>
            )}
            <Button
              type="button"
              size="medium"
              variant="contained"
              onClick={handleDownloadAllCards}
            >
              Export All
            </Button>
          </>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <TabContext value={currentTab}>
            <Box
              className="sticky-Tab"
              sx={{ borderBottom: 1, borderColor: "divider", top: "104px" }}
            >
              <TabList onChange={handleChange}>
                <Tab label="Basic Details" value="1" />
                <Tab label="Gift Cards" value="2" />
              </TabList>
            </Box>

            <TabPanel sx={{ px: "0" }} value="1">
              <PreloadedCardDetailsList
                giftCardDetails={giftCardDetails}
                isEditable={isEditable}
                state={state}
                onChangeFilter={onChangeFilterData}
              />
            </TabPanel>
            <TabPanel sx={{ px: "0" }} value="2">
              <PreloadedCardGiftCardDetailsList
                allBatchCards={allBatchCards}
                handleShareGiftCards={handleShareGiftCards}
                state={state}
                globalData={globalData}
                onEmailChange={onEmailChange}
                toggleModal={toggleModal}
                handleDownloadSingleCards={handleDownloadSingleCards}
                onChangeFilter={onChangeFilterData}
                resetFilters={resetFilters}
                handleSort={handleBatchcardSort}
                gotoPage={gotoBatchcardPage}
                setPageSize={setBatchcardPageSize}
              />
            </TabPanel>
          </TabContext>
        )}
      </ContentLayout>
      <DeleteModal
        open={state.isOpen}
        handleClose={() => handleDeleteModal("details", false, null)}
        onConfirm={() => handleDeleteGiftCard()}
      />
    </>
  );
};
