import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { AppContext } from "../../../store/AppScopeProvider";
import { editWastageQty, getWastageDetailsById } from "../api/wastageApi";
import { errorMessage } from "../../../utils";
import moment from "moment";
var fileDownload = require("js-file-download");

export const useWastage = ({ wastageId }) => {
  const { appState } = useContext(AppContext);
  const {
    globalData: { wastage_types: wastageType },
  } = appState;
  const [state, setState] = useImmer({
    isBusy: false,
    wastage: [],
    initialLoad: true,
    wastage_number: "",
    data: {
      id: "",
      wastage_number: "",
      wastage_date: "",
      wastage_type: "",
      terminal_id: "",
      store_id: "",
      user_id: "",
      grant_total: "",
      total_products: "",
      notes: "",
      created_at: "",
      updated_at: "",
      Products: [],
    },
    isEdit: false,
    isLoadingSave: false,
    is72HourPassed: false,
  });

  const getWastageDetails = async (wastageId) => {
    try {
      setState((draft) => {
        draft.isDetailsFetching = true;
        draft.isBusy = true;
      });
      const res = await getWastageDetailsById(wastageId);
      if (res.success) {
        const referenceTime = moment(res.data.created_at);
        const currentTime = moment();
        const hoursDifference = currentTime.diff(referenceTime, 'hours');

        setState((draft) => {
          draft.data = {
            ...res.data,
          };
          draft.is72HourPassed = hoursDifference >= 72
          draft.isBusy = false;
          draft.wastage_number = res.data.wastage_number;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isDetailsFetching = false;
        draft.isBusy = false;
        // draft.httpStatusCodes.details = err?.response?.status;
      });
    }
  };
  useEffect(() => {
    if (wastageId) {
      getWastageDetails(wastageId);
    }
  }, [wastageId]);

  const onChangeQty = (e, i) => {

    let data = {
      ...state.data, Products: state.data.Products.map((data, index) => {
        if (i == index) {
          return { ...data, quantity: e.target.value, total: (data.price * e.target.value).toFixed(2) }
        } else return data
      }),
    }

    const totalSum = data.Products.reduce((sum, product) => {
      // Make sure product.total is a valid number
      return sum + parseFloat(product.total || 0);
    }, 0)
    data = {
      ...data, grant_total: totalSum.toFixed(2)
    }

    setState((draft) => {
      draft.data = data
    });
  }

  const handleEditWastage = async () => {
    try {
      setState((draft) => {
        draft.isLoadingSave = true;
      });
      let payload = {
        products: state.data.Products.map((data) => {
          return { product_id: data.product_id, quantity: data.quantity }
        })
      }
      const res = await editWastageQty(wastageId, payload);
      if (res.success) {
        setState((draft) => {
          draft.isLoadingSave = false;
          draft.isEdit = false;
        });
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.isLoadingSave = false;
          draft.isEdit = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isLoadingSave = false;
        draft.isEdit = false;
      });
    }
  }

  const handleEditBtn = () => {
    setState((draft) => {
      draft.isEdit = true;
    });
  }

  return {
    state,
    onChangeQty,
    handleEditWastage,
    handleEditBtn,
  };
};
