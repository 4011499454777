import React from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Card, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import InputControl from "../../../shared/components/InputControl";
import { useInvitation } from "../hooks/useInvitation";
import SimpleReactValidator from "simple-react-validator";
import { Loader } from "../../../shared";

export const CreateProfile = () => {
  const { state, globalData, handleOnChange, onRegister, toggleShowPassword } =
    useInvitation();
  const {
    userDetails: { store_name, role_name },
  } = state;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const onSubmit = () => {
    if (validator.current.allValid()) {
      onRegister();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "125px",
        }}
      >
        {state.isLoading ? (
          <Loader />
        ) : (
          <Card align="center" sx={{ width: "424px", minHeight: "266px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "24px",
              }}
            >
              <Typography
                fontWeight={700}
                fontSize="32px"
                lineHeight={"42px"}
                mb={1}
              >
                Invitation To Join
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  mb: 3,
                }}
              >
                <Typography
                  fontWeight={600}
                  fontSize="16px"
                  lineHeight={"21px"}
                >
                  {store_name}
                </Typography>
                <Typography
                  color="#636363"
                  fontWeight={400}
                  fontSize="16px"
                  lineHeight={"21px"}
                >
                  , {role_name}
                </Typography>
              </Box>

              <Box mb={4}>
                <Stack direction={"row"} width={"100%"} gap="10px" mb={2}>
                  <InputControl
                    label="First Name"
                    name="first_name"
                    type="text"
                    placeholder="First Name"
                    onChange={(e) => handleOnChange(e)}
                    value={state.data.first_name}
                    required
                    error={validator.current.message(
                      "First Name",
                      state.data.first_name,
                      "required"
                    )}
                    helperText={validator.current.message(
                      "First Name",
                      state.data.first_name,
                      "required"
                    )}
                  />
                  <InputControl
                    label="Last Name"
                    name="last_name"
                    type="text"
                    placeholder="Last Name"
                    onChange={(e) => handleOnChange(e)}
                    value={state.data.last_name}
                    required
                    error={validator.current.message(
                      "Last Name",
                      state.data.last_name,
                      "required"
                    )}
                    helperText={validator.current.message(
                      "Last Name",
                      state.data.last_name,
                      "required"
                    )}
                  />
                </Stack>

                <InputControl
                  type="dropdown"
                  disableClearable
                  options={globalData?.country_codes ?? []}
                  getOptionLabel={(option) => option.label}
                  value={
                    globalData?.country_codes?.length > 0 &&
                    state.data.country_code &&
                    globalData?.country_codes?.find(
                      (c) => c.value === state.data.country_code
                    )
                  }
                  onChange={(e, newValue) => {
                    handleOnChange({
                      target: {
                        name: "country_code",
                        value: newValue.value,
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country code"
                      required
                      error={validator.current.message(
                        "Country Code",
                        state.data.country_code,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "Country Code",
                        state.data.country_code,
                        "required"
                      )}
                    />
                  )}
                  sx={{ mb: 2 }}
                />

                <InputControl
                  name="phone"
                  label="Phone Number"
                  type="text"
                  autoComplete="off"
                  value={state.data.phone}
                  onChange={handleOnChange}
                  required
                  error={validator.current.message(
                    "Phone Number",
                    state.data.phone,
                    "required|numeric|size:10"
                  )}
                  helperText={validator.current.message(
                    "Phone Number",
                    state.data.phone,
                    "required|numeric|size:10"
                  )}
                  sx={{ mb: 2 }}
                />
                <InputControl
                  name="password"
                  label="Password"
                  type="password"
                  placeholder="Enter password"
                  autoComplete="off"
                  showPassword={state.showPassword}
                  onChange={handleOnChange}
                  value={state.data.password}
                  required
                  error={validator.current.message(
                    "password",
                    state.data.password,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "password",
                    state.data.password,
                    "required"
                  )}
                  onClick={(e) => toggleShowPassword("showPassword")}
                />
              </Box>

              <LoadingButton
                color="primary"
                fullWidth
                variant="contained"
                sx={{ height: "48px" }}
                loading={state.isBusy}
                onClick={onSubmit}
              >
                Register
              </LoadingButton>
            </Box>
          </Card>
        )}
      </Box>
    </>
  );
};
