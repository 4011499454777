import {
  ContentLayout,
  HeaderBreadcrumbs,
  Loader,
  useDropdownApis,
} from "../../../shared";
import * as React from "react";
import {
  Button,
  Stack,
  Card,
  Typography,
  Grid,
  TextField,
  Divider,
  FormHelperText,
  Box,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useLocation, useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { AsyncPaginate } from "react-select-async-paginate";
import { useCustomers } from "../../Customers";

export const ClientaccountCustomerEdit = () => {
  const { customerId, id } = useParams();
  const {
    state,
    onChange,
    onChangeBusinessUser,
    onUpdateClientAccountCustomers,
  } = useCustomers({
    id: id,
    customerId,
  });
  const { isSaveButtonBusy, customerName, validation, isDetailsFetching } =
    state;
  const {
    first_name,
    last_name,
    email,
    is_business_user,
    business_name,
    abn_number,
  } = state.data;

  const { statesList, handleSuburbList } = useDropdownApis({
    isStatesList: true,
  });
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <Stack
        ref={innerRef}
        {...innerProps}
        sx={{
          flexDirection: "row",
          p: "7px 8px",
          gap: "2px",
          "&:hover": {
            backgroundColor: "#edf3fc",
          },
        }}
      >
        <Stack
          sx={{
            gap: "2px",
          }}
        >
          <Typography variant="subtitle2" fontWeight={400} lineHeight="17px">
            {data.label}
          </Typography>
          <Typography color="#666666" variant="caption" lineHeight="15px">
            {data.state}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: "2px solid #E6E8F0",
      height: "48px",
    }),
    option: (provided, state) => ({
      ...provided,
      maxHeight: "239px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
  };

  const onSubmit = () => {
    if (validator.current.allValid() && !validation.date_of_birth) {
      onUpdateClientAccountCustomers();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <ContentLayout
        title={customerName}
        rightContent={
          <>
            <Button
              onClick={() => onSubmit()}
              type="button"
              size="medium"
              variant="contained"
              disabled={isSaveButtonBusy}
            >
              Update
            </Button>
          </>
        }
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                {
                  name: "Dashboard",
                  href: "/store-admin",
                },
                {
                  name: "Client Account",
                  href: "/store-admin/client-account",
                },
                {
                  name: customerName,
                  href: `/store-admin/client-account/details/${customerId}`,
                },
                { name: "Customer Edit" },
              ]}
            />
          </>
        }
      >
        {isDetailsFetching ? (
          <Loader />
        ) : (
          <>
            {validator.current.purgeFields()}
            <Card sx={{ p: "32px 24px", mb: "24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Basic Details
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack width={"100%"} gap="20px">
                    <Stack direction="row" gap="20px">
                      <InputControl
                        name="first_name"
                        label="First Name"
                        value={first_name}
                        onChange={onChange}
                        required
                        error={validator.current.message(
                          "First Name",
                          first_name,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "First Name",
                          first_name,
                          "required"
                        )}
                      />
                      <InputControl
                        name="last_name"
                        label="Last Name"
                        required
                        value={last_name}
                        onChange={onChange}
                        error={validator.current.message(
                          "Last Name",
                          last_name,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Last Name",
                          last_name,
                          "required"
                        )}
                      />
                    </Stack>
                    <InputControl
                      name="email"
                      label="Email Address"
                      value={email}
                      onChange={onChange}
                      required
                      error={validator.current.message(
                        "Email",
                        email,
                        "required|email"
                      )}
                      helperText={validator.current.message(
                        "Email",
                        email,
                        "required|email"
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Divider sx={{ m: "16px -24px" }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Business User
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack gap="20px">
                    <InputControl
                      type="switch"
                      value={is_business_user}
                      onChange={(e) =>
                        onChange({
                          target: {
                            name: "is_business_user",
                            value: e.target.checked ? 1 : 0,
                          },
                        })
                      }
                    />
                    <>
                      <InputControl
                        name="business_name"
                        label="Business Name"
                        value={business_name}
                        disabled={!is_business_user}
                        onChange={onChange}
                        required
                        error={
                          is_business_user
                            ? validator.current.message(
                                "Business Name",
                                business_name,
                                "required"
                              )
                            : ""
                        }
                        helperText={
                          is_business_user
                            ? validator.current.message(
                                "Business Name",
                                business_name,
                                "required"
                              )
                            : ""
                        }
                      />
                      <InputControl
                        name="abn_number"
                        label="ABN"
                        value={abn_number}
                        onChange={onChange}
                        disabled={!is_business_user}
                        required
                        error={
                          is_business_user
                            ? validator.current.message(
                                "ABN",
                                abn_number,
                                "required"
                              )
                            : ""
                        }
                        helperText={
                          is_business_user
                            ? validator.current.message(
                                "ABN",
                                abn_number,
                                "required"
                              )
                            : ""
                        }
                      />
                      <Stack direction="row" gap="20px">
                        <InputControl
                          name="unit_number"
                          label="Unit Number"
                          type="number"
                          value={state.data.business_address.unit_number}
                          onChange={onChangeBusinessUser}
                          disabled={!is_business_user}
                        />
                        <InputControl
                          name="street_number"
                          label="Street Number"
                          value={state.data.business_address.street_number}
                          onChange={onChangeBusinessUser}
                          error={
                            is_business_user
                              ? validator.current.message(
                                  "Street Number",
                                  state.data.business_address.street_number,
                                  "required"
                                )
                              : ""
                          }
                          helperText={
                            is_business_user
                              ? validator.current.message(
                                  "Street Number",
                                  state.data.business_address.street_number,
                                  "required"
                                )
                              : ""
                          }
                          required
                          disabled={!is_business_user}
                        />
                      </Stack>
                      <Stack direction="row" gap="20px">
                        <InputControl
                          name="street_name"
                          label="Street Name"
                          value={state.data.business_address.street_name}
                          onChange={onChangeBusinessUser}
                          required
                          disabled={!is_business_user}
                          error={
                            is_business_user
                              ? validator.current.message(
                                  "Street Name",
                                  state.data.business_address.street_name,
                                  "required"
                                )
                              : ""
                          }
                          helperText={
                            is_business_user
                              ? validator.current.message(
                                  "Street Names",
                                  state.data.business_address.street_name,
                                  "required"
                                )
                              : ""
                          }
                        />
                        <Box width="100%">
                          <AsyncPaginate
                            debounceTimeout={1000}
                            placeholder="Suburb *"
                            menuPlacement="top"
                            isDisabled={!is_business_user}
                            components={{
                              Option: CustomOption,
                            }}
                            value={
                              state.data.business_address.suburb
                                ? {
                                    value: state.data.business_address.suburb,
                                    label: state.data.business_address.suburb,
                                  }
                                : ""
                            }
                            loadOptions={async (e, p, { page }) => {
                              return await handleSuburbList({
                                search: e,
                                page,
                                type: "suburb",
                              });
                            }}
                            onChange={(e) => {
                              onChangeBusinessUser(
                                {
                                  target: {
                                    name: "suburb",
                                    value: e.value || null,
                                  },
                                },
                                e
                              );
                            }}
                            additional={{
                              page: 1,
                            }}
                            styles={customStyles}
                          />
                          <FormHelperText sx={{ color: "#C60808" }}>
                            {is_business_user
                              ? validator.current.message(
                                  "Suburb",
                                  state.data.business_address.suburb,
                                  "required"
                                )
                              : ""}
                          </FormHelperText>
                        </Box>
                      </Stack>
                      <Stack direction="row" gap="20px">
                        <InputControl
                          name="postcode"
                          label="Postcode"
                          type="number"
                          value={Number(state.data.business_address.postcode)}
                          onChange={onChangeBusinessUser}
                          required
                          disabled={!is_business_user}
                          error={
                            is_business_user
                              ? validator.current.message(
                                  "Postcode",
                                  state.data.business_address.postcode,
                                  "required"
                                )
                              : ""
                          }
                          helperText={
                            is_business_user
                              ? validator.current.message(
                                  "Postcode",
                                  state.data.business_address.postcode,
                                  "required"
                                )
                              : ""
                          }
                        />
                        <InputControl
                          type="dropdown"
                          disableClearable
                          getOptionLabel={(option) => option.name}
                          disabled={!is_business_user}
                          value={
                            statesList.find(
                              (c) =>
                                c.name === state.data.business_address.state
                            ) ?? null
                          }
                          options={statesList ?? []}
                          onChange={(e, v) => {
                            onChangeBusinessUser({
                              target: {
                                name: "state",
                                value: v.name || null,
                              },
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="State"
                              required
                              error={
                                is_business_user
                                  ? validator.current.message(
                                      "State",
                                      state.data.business_address.state,
                                      "required"
                                    )
                                  : ""
                              }
                              helperText={
                                is_business_user
                                  ? validator.current.message(
                                      "State",
                                      state.data.business_address.state,
                                      "required"
                                    )
                                  : ""
                              }
                            />
                          )}
                        />
                      </Stack>
                    </>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </ContentLayout>
    </>
  );
};
