import { createContext } from "react";
import { useAppScope } from "../shared/hooks/useAppScope";

export const AppContext = createContext({});

// context provider
export const AppScopeProvider = ({ children }) => {
  const value = useAppScope();

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
