import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";
import Avadar_img from "../../../assets/images/Icons/Avadar_img.png";

export const AddOrEditMenuNames = ({
  state,
  onSaveMenuTemplateCategory,
  onChangeMenuTemplateCategory,
  handleColor,
  onFileChange,
  onUpdateMenuTemplateCategory,
}) => {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const AlbumArtRef = React.useRef();
  const onLogoClick = () => {
    AlbumArtRef.current.click();
  };
  const colorOptions = [
    {
      value: "#A04D3D",
      label: "#A04D3D",
    },
    {
      value: "#5E7360",
      label: "#5E7360",
    },
    {
      value: "#564845",
      label: "#564845",
    },
    {
      value: "#F2F2F2",
      label: "#F2F2F2",
    },
  ];
  const onSubmit = () => {
    if (validator.current.allValid()) {
      state.selectedId
        ? onUpdateMenuTemplateCategory()
        : onSaveMenuTemplateCategory();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  return (
    <Stack
      justifyContent="space-between"
      height="100%"
      gap="4px"
      maxHeight={"calc(100% - 24px)"}
      mt="25px"
    >
      <InputControl
        name="name"
        label="Menu Name"
        onChange={onChangeMenuTemplateCategory}
        value={state.categoryData.name}
        sx={{
          width: "100%",
          bgcolor: "#fff",
        }}
        required
        error={validator.current.message(
          "Menu Name",
          state.categoryData.name,
          "required"
        )}
        helperText={validator.current.message(
          "Menu Name",
          state.categoryData.name,
          "required"
        )}
      />
      <Typography
        width="218px"
        lineHeight="19.36px"
        fontWeight={600}
        fontSize="16px"
      >
        Icon and Colour
      </Typography>
      <Stack direction="row" gap="10px" alignItems="center">
        <Avatar
          sx={{
            bgcolor:
              state.categoryData.color === null
                ? "#F2F2F2"
                : state.categoryData.color,
            width: "84px",
            height: "80px",
            borderRadius: "8px",
          }}
          variant="rounded"
        >
          <img
            width="50px"
            src={
              state.iconFile
                ? URL.createObjectURL(state.iconFile)
                : state?.selectedId
                ? state.categoryData.icon
                : Avadar_img
            }
          />
        </Avatar>

        <div>
          <input
            id="contained-button-file"
            type="file"
            onChange={(e) => onFileChange(e, "image")}
            style={{ display: "none" }}
            ref={AlbumArtRef}
          />
          <Button color="info" component="span" onClick={onLogoClick}>
            Upload
          </Button>
        </div>
      </Stack>
      <Stack>
        <Typography variant="body2" color="#808080">
          Upload an image with 1:1 ratio and less than 1MB.
        </Typography>
      </Stack>
      <InputControl
        type="dropdown"
        name="color"
        options={colorOptions}
        value={state.categoryData.color}
        label="Color Code"
        includeInputInList
        filterSelectedOptions
        required
        onChange={(e, newValue) => {
          handleColor(newValue);
        }}
        sx={{
          "& input": { px: "4px !important" },
        }}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <Box
              sx={{
                bgcolor: option.label,
                width: "20px",
                height: "20px",
                borderRadius: "3px",
                mr: "4px",
              }}
            ></Box>

            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            sx={{
              borderRadius: "8px",
              ".MuiInputBase-root": { height: "44px" },
            }}
            label="Colour Code"
            required
            error={validator.current.message(
              "Color Code",
              state.categoryData.color,
              "required"
            )}
            helperText={validator.current.message(
              "Color Code",
              state.categoryData.color,
              "required"
            )}
            InputProps={{
              ...params.InputProps,
              startAdornment:
                state.categoryData.color === null ? (
                  ""
                ) : (
                  <InputAdornment position="start">
                    <Box
                      sx={{
                        bgcolor: !state.categoryData.color
                          ? "#F2F2F2"
                          : state.categoryData.color,
                        width: "20px",
                        height: "20px",
                        borderRadius: "3px",
                      }}
                    ></Box>
                  </InputAdornment>
                ),
            }}
          />
        )}
      />
      <Button
        sx={{ height: "40px", width: "33px", alignSelf: "end" }}
        variant="contained"
        disabled={state?.isSaveButtonBusy}
        onClick={onSubmit}
      >
        {state.selectedId ? "Update" : "Save"}
      </Button>
    </Stack>
  );
};
