import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

export const LoyaltyTable = (props) => {
  const { data } = props;
  return (
    <Paper sx={{ boxShadow: "none" }}>
      <TableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell align="left">Item</TableCell>
              <TableCell align="left">Product Price</TableCell>
              <TableCell align="left">Quantity</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.order_product?.map((item, index) => {
              return (
                <>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                      }}
                      key={0}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                      }}
                      key={1}
                    >
                      {item.product_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                      }}
                      key={2}
                    >
                      ${item.price}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                      }}
                      key={3}
                    >
                      {item.quantity}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                      }}
                      key={3}
                    >
                      {item.total}
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
            <TableRow>
              <TableCell colSpan={3}></TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  borderLeft: "1px solid #E6E6E6",
                }}
              >
                Grant Total
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                }}
                align="right"
              >
                ${data?.order?.subtotal_inc_tax}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}></TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  borderLeft: "1px solid #E6E6E6",
                  fontWeight: 700,
                }}
              >
                Paid
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  fontWeight: 700,
                }}
                align="right"
              >
                ${data?.order?.grand_total}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}></TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  borderLeft: "1px solid #E6E6E6",
                }}
              >
                Change
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  color: "#636363",
                }}
                align="right"
              >
                ${data?.order?.change_returned}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Stack direction="row">
          <Stack
            border={1}
            borderColor="#E6E6E6"
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "14.5px 26px",
              height: "48px",
              width: "50%",
              display: "flex",
              alignItems: "center",
              backgroundColor: "rgba(21, 96, 212, 0.08)",
            }}
          >
            <Typography>Points Accumulated</Typography>
            <Typography fontWeight={500}>{data.accumulated}</Typography>
          </Stack>
          <Stack
            border={1}
            borderColor="#E6E6E6"
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "14.5px 26px",
              height: "48px",
              display: "flex",
              width: "50%",
              alignItems: "center",
              backgroundColor: "rgba(21, 96, 212, 0.08)",
            }}
          >
            <Typography>Points Redeemed</Typography>
            <Typography fontWeight={500}>{data.redeemed}</Typography>
          </Stack>
          <Stack
            border={1}
            borderColor="#E6E6E6"
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "14.5px 26px",
              height: "48px",
              width: "50%",
              display: "flex",
              alignItems: "center",
              backgroundColor: "rgba(21, 96, 212, 0.08)",
            }}
          >
            <Typography>Running Total</Typography>
            <Typography fontWeight={500}>{data.running_total}</Typography>
          </Stack>
        </Stack>

        <Stack p="15.5px 26px" direction="row" gap="20px">
          <Typography
            variant="subtitle2"
            lineHeight="17px"
            fontWeight={400}
            width="160px"
          >
            Remarks
          </Typography>
          <Typography variant="subtitle2" lineHeight="17px" fontWeight={400}>
            {data?.remarks ?? "--"}
          </Typography>
        </Stack>
      </TableContainer>
    </Paper>
  );
};
