import {
  ContentLayout,
  HeaderBreadcrumbs,
  PageNotFound,
} from "../../../shared";
import { useState, useRef } from "react";
import { Button, Stack, Card, Typography, TextField } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useTerminals } from "../hooks";

export const CreateOrEditTerminal = () => {
  const { id } = useParams();
  const updateMode = id ? true : false;
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const { onChange, state, storeList, onSaveTerminal, onUpdateTerminal } =
    useTerminals({
      load: false,
      id: id,
      isStoreList: true,
    });

  const { isSaveButtonBusy, terminalName, httpStatusCodes } = state;
  const { name, store_id, status, order_prefix, cashless_till } = state.data;

  const onSubmit = () => {
    if (validator.current.allValid()) {
      updateMode ? onUpdateTerminal(id) : onSaveTerminal();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  if (httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }
  return (
    <>
      <ContentLayout
        title={id ? terminalName : "New Terminal"}
        rightContent={
          <>
            <Button
              onClick={() => onSubmit()}
              type="button"
              size="medium"
              variant="contained"
              disabled={isSaveButtonBusy}
            >
              {id ? "Update" : "Save"}
            </Button>
          </>
        }
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Terminals", href: "/terminal" },
              id
                ? {
                    name: terminalName,
                    href: `/terminal/details/${id}`,
                  }
                : null,
              { name: id ? "Edit" : "Create" },
            ].filter(Boolean)}
          />
        }
      >
        <Card sx={{ p: "32px 24px" }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 3, sm: 24 }}
            gap="24px"
          >
            <Typography variant="subtitle1" fontWeight={600}>
              Details
            </Typography>

            <Stack width={"100%"} gap="20px">
              <InputControl
                name="name"
                label="Terminal Name"
                value={name}
                onChange={onChange}
                required
                error={validator.current.message(
                  "Terminal Name",
                  name,
                  "required"
                )}
                helperText={validator.current.message(
                  "Terminal Name",
                  name,
                  "required"
                )}
              />
              <InputControl
                type="dropdown"
                disableClearable
                disabled={updateMode}
                getOptionLabel={(option) => option.name}
                value={
                  (storeList.length > 0 &&
                    store_id &&
                    storeList.find((c) => c.id === store_id)) ||
                  null
                }
                options={storeList}
                onChange={(e, newValue) => {
                  onChange({
                    target: { name: "store_id", value: newValue.id },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Store Name"
                    required
                    disabled={updateMode}
                    error={validator.current.message(
                      "Store Name",
                      store_id,
                      "required"
                    )}
                    helperText={validator.current.message(
                      "Store Name",
                      store_id,
                      "required"
                    )}
                  />
                )}
              />
              <InputControl
                name="order_prefix"
                label="Token Prefix"
                value={order_prefix}
                onChange={onChange}
                inputProps={{ maxLength: 6 }}
                required
                error={validator.current.message(
                  "Token Prefix",
                  order_prefix,
                  "required|alpha_num"
                )}
                helperText={validator.current.message(
                  "Token Prefix",
                  order_prefix,
                  "required|alpha_num"
                )}
              />
              <Stack direction="row" justifyContent="space-between" py="8px">
                <Typography variant="subtitle1" fontWeight={600}>
                  Is it a Cashless Till?
                </Typography>
                <InputControl
                  type="switch"
                  value={cashless_till}
                  onChange={(e) =>
                    onChange({
                      target: {
                        name: "cashless_till",
                        value: e.target.checked ? 1 : 0,
                      },
                    })
                  }
                />
              </Stack>
              <Stack direction="row" justifyContent="space-between" py="8px">
                <Typography variant="subtitle1" fontWeight={600}>
                  Active Status
                </Typography>
                <InputControl
                  type="switch"
                  value={status}
                  onChange={(e) =>
                    onChange({
                      target: {
                        name: "status",
                        value: e.target.checked ? 1 : 0,
                      },
                    })
                  }
                />
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </ContentLayout>
    </>
  );
};
