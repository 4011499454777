import * as React from "react";
import { Stack, Typography } from "@mui/material";
import LogoBlack from "../../../assets/images/Logo/Logo.png";
import moment from "moment/moment";

export const GiftCardItem = ({ cardDetails }) => {
  return (
    <>
      <Stack
        sx={{
          p: "24px",
          bgcolor: "#5E7360",
          borderRadius: "24px",
          minWidth: "450px",
          minHeight: "250px",
          justifyContent: "space-between",
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Stack
            sx={{
              gap: "4px",
            }}
          >
            <Typography variant="h4" lineHeight="39px" color="#fff">
              ${cardDetails?.balance_value}
            </Typography>
            <Typography
              variant="subtitle1"
              lineHeight="19px"
              fontWeight={400}
              color="#fff"
            >
              Balance
            </Typography>
          </Stack>
          <Stack direction="column" alignItems="end">
            <img
              src={LogoBlack}
              alt="logo"
              style={{
                width: "103px",
                height: "32px",
              }}
            />
            <Typography
              variant="subtitle1"
              lineHeight="19.36px"
              fontWeight={500}
              color="#fff"
            >
              Gift Card
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="h5" lineHeight="17px" color="#fff">
            {cardDetails?.card_number}
          </Typography>
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Stack gap="6px" justifyContent="center" marginTop="auto">
            <Typography variant="subtitle2" lineHeight="15px" color="#fff">
              Valid Up To :
            </Typography>
            <Typography variant="h6" lineHeight="17px" color="#fff">
              {moment(cardDetails.expiry_date, 'DD/MM/YYYY, hh:mm A').format("DD MMM YYYY")}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            bgcolor="#fff"
            width="232px"
            height="100.31px"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              p: "8px"
            }}
          >
            <Typography
              fontWeight={500}
              fontSize="14px"
            >
              Verification Code: {cardDetails.verification_code}
            </Typography>
            <img
              alt="barcode"
              src="/icons/barcode.png"
              style={{
                width: "198.04px",
                height: "28.31px",
                alignSelf: "center",
              }}
            />
            <Typography
              fontWeight={500}
              fontSize="14px"
            >
              {cardDetails.barcode}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
