import React, { useEffect } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
  useExpanded,
} from "react-table";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableHead,
  TableContainer,
  TableSortLabel,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Box,
  Paper,
  Stack,
  Checkbox,
  IconButton,
  Chip,
  Button,
  Pagination,
  TableFooter,
} from "@mui/material";
import { Link } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import { CustomDrawer, Loader } from "..";
import CloseIcon from "@mui/icons-material/Close";
import { useImmer } from "use-immer";

export const ServerPaginatedTable = ({
  columns,
  data,
  searchBar = true,
  minWidth = 800,
  maxHeight = "",
  filterContent = null,
  tableTitle,
  headerRightContent = null,
  drawerOpen = false,
  drawerOnClose,
  bulkDelete = false,
  onBulkDelete = {},
  filterApplied = [],
  onFilterClear = {},
  onAllFilterClear = {},
  filterSection = null,
  defaultSearch = true,
  completeFeature = true,
  gotoPage,
  setPageSize,
  pagination,
  handleSort,
  sortByGlobal,
  manualSortBy = true,
  isLoading = false,
  rowClickNewTab = false,
  onRowLink = false,
  onRowAction = false,
  toggleHiddenManual = false,
  columnOptions,
  isClickDisabled = false,
}) => {
  const [localState, setLocalState] = useImmer({
    initialLoad: true,
    initialColumns: [],
  });
  // const {
  //   columns,
  //   data,
  //   searchBar = true,
  //   minWidth = 800,
  //   maxHeight = "",
  //   filterContent = null,
  //   tableTitle,
  //   headerRightContent = null,
  //   drawerOpen = false,
  //   drawerOnClose,
  //   bulkDelete = false,
  //   onBulkDelete = {},
  //   filterApplied = [],
  //   onFilterClear = {},
  //   onAllFilterClear = {},
  //   filterSection = null,
  //   defaultSearch = true,
  //   completeFeature = true,
  //   gotoPage,
  //   setPageSize,
  //   pagination,
  //   handleSort,
  //   sortByGlobal,
  //   manualSortBy = true,
  //   isLoading = false,
  //   rowClickNewTab = false,
  //   onRowLink = false,
  //   onRowAction = false,
  //   toggleHiddenManual = false,
  //   columnOptions,
  //   isClickDisabled = false,
  // } = props;
  const { pageIndex, pageSize, pageCount, total, hasMorePages, lastPage } =
    pagination;

  const {
    allColumns,
    visibleColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    preGlobalFilteredRows,
    globalFilteredRows,
    setGlobalFilter,
    state,
    state: { sortBy, hiddenColumns },
    selectedFlatRows,
    rows,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: manualSortBy,
      autoResetSortBy: true,
      disableMultiSort: true,
      initialState: {
        hiddenColumns: toggleHiddenManual ? columnOptions : [],
        sortBy: sortByGlobal,
      },
      getSubRows: (row) => row.permissions || [],
    },

    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    // ,
    (hooks) => {
      bulkDelete &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox
                classes={{ root: "custom-checkbox-root" }}
                color="primary"
                sx={{
                  marginLeft: "-10px",
                }}
                {...getToggleAllRowsSelectedProps()}
              />
            ),
            Cell: ({ row }) => (
              <Checkbox
                classes={{ root: "custom-checkbox-root" }}
                color="primary"
                sx={{
                  marginLeft: "-10px",
                }}
                {...row.getToggleRowSelectedProps()}
              />
            ),
            padding: "checkbox",
          },
          ...columns,
        ]);
    }
  );
  useEffect(() => {
    if (manualSortBy) {
      !localState.initialLoad && handleSort(sortBy);
    }
  }, [sortBy]);

  useEffect(() => {
    setLocalState((draft) => {
      draft.initialLoad = false;
    });
  }, []);

  useEffect(() => {
    if (!localState.initialLoad && toggleHiddenManual) {
      toggleHiddenManual(hiddenColumns);
    }
  }, [hiddenColumns]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Paper className={completeFeature && "table-paper-container"}>
          <TableContainer sx={{ minWidth: minWidth, maxHeight: maxHeight }}>
            {tableTitle && (
              <CustomTableHeaderSection
                tableTitle={tableTitle}
                headerRightContent={headerRightContent}
              />
            )}
            {searchBar && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                filterContent={filterContent}
                defaultSearch={defaultSearch}
              />
            )}
            {filterApplied.length > 0 && (
              <FilterSection
                data={filterApplied}
                onFilterClear={onFilterClear}
                onAllFilterClear={onAllFilterClear}
              />
            )}
            {filterSection && <FilterBar filterSection={filterSection} />}
            {isLoading ? (
              <Box sx={{ padding: "10px" }}>
                <Loader />
              </Box>
            ) : (
              <Table
                {...getTableProps()}
                sx={{
                  borderCollapse: "inherit",
                  overflow: "hidden",
                }}
              >
                <TableHead>
                  {headerGroups.map((headerGroup, i) => (
                    <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            p: !column.padding && "10px",
                          }}
                          align={column?.align}
                          padding={column.padding || "normal"}
                          {...column.getHeaderProps(
                            bulkDelete && selectedFlatRows.length > 0
                              ? ""
                              : column.getSortByToggleProps()
                          )}
                        >
                          {bulkDelete &&
                          selectedFlatRows.length > 0 &&
                          index >= 1 ? (
                            <>
                              {index > 1 ? (
                                <></>
                              ) : (
                                <Typography
                                  variant="subtitle2"
                                  color="#1560D4"
                                  lineHeight="18px"
                                  onClick={() => {
                                    onBulkDelete(selectedFlatRows);
                                  }}
                                >
                                  Delete
                                </Typography>
                              )}
                            </>
                          ) : (
                            <>
                              {column.canSort ? (
                                <TableSortLabel
                                  active={column.isSorted}
                                  direction={
                                    column.isSortedDesc ? "dsc" : "asc"
                                  }
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      height: "15px",
                                      width: "15px",
                                    },
                                  }}
                                >
                                  {column.render("Header")}
                                </TableSortLabel>
                              ) : (
                                <>{column.render("Header")}</>
                              )}
                            </>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                {preGlobalFilteredRows.length > 0 &&
                globalFilteredRows.length > 0 ? (
                  <TableBody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      const link = onRowLink ? onRowLink(row.original) : false;
                      const hasClick = isClickDisabled
                        ? isClickDisabled(row.original)
                        : false;
                      return (
                        <TableRow
                          key={i}
                          component={link ? Link : null}
                          to={link ? link : undefined}
                          onClick={() => {
                            onRowAction && onRowAction(row.original);
                          }}
                          target={rowClickNewTab ? "_blank	" : ""}
                          rel="noopener noreferrer"
                          sx={{
                            textDecoration: "none",
                            cursor:
                              link || hasClick || onRowAction !== false
                                ? "pointer"
                                : "default",
                            "&:hover": {
                              backgroundColor:
                                link || hasClick
                                  ? "rgba(55, 65, 81, 0.04)"
                                  : false,
                            },
                          }}
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell, i) => {
                            return (
                              <TableCell
                                key={i}
                                align={cell.column?.align}
                                padding={cell.column.padding || "normal"}
                                sx={{
                                  borderBottom: "1px solid #E6E6E6",
                                  p: !cell.column.padding && "8px",
                                }}
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={20}
                        sx={{ py: 2, backgroundColor: "#F7F7F7" }}
                      >
                        <Typography
                          align="center"
                          variant="body2"
                          color={"#666666"}
                        >
                          No matches found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        2, 5, 10, 25, 50, 100,
                        // { label: "All", value: -1 },
                      ]}
                      colSpan={0}
                      count={total}
                      rowsPerPage={pageSize}
                      page={pageIndex > 0 ? pageIndex - 1 : 0}
                      onPageChange={gotoPage}
                      onRowsPerPageChange={setPageSize}
                      ActionsComponent={(subProps) => (
                        <TablePaginationActions
                          {...subProps}
                          lastPage={lastPage}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </TableContainer>
        </Paper>
      </Box>

      <CustomDrawer
        title="Column Option"
        open={drawerOpen}
        onClose={drawerOnClose}
      >
        <Stack p="24px 16px" gap="16px">
          {allColumns.map((column, index) => (
            <React.Fragment key={index}>
              {column.id === "selection" ? (
                ""
              ) : (
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="10px"
                  width="auto"
                  key={index}
                >
                  <Box
                    sx={{
                      border: "1px solid #CCCCCC",
                      borderRadius: "8px",
                      p: "14px",
                    }}
                    width="100%"
                  >
                    <Typography
                      lineHeight="19px"
                      color={column.isVisible ? "black" : "GrayText"}
                    >
                      {column.Header}
                    </Typography>
                  </Box>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      column.toggleHidden();
                    }}
                  >
                    {column.isVisible ? (
                      <img alt="eye" src="/icons/ic_eye.svg" />
                    ) : (
                      <img
                        alt="closed eye"
                        src="/icons/ic_close_eye.svg"
                        className="disabled"
                      />
                    )}
                  </IconButton>
                </Stack>
              )}
            </React.Fragment>
          ))}
        </Stack>
      </CustomDrawer>
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              selectedFlatRows: selectedFlatRows.map((row) => row.original),
            },
            null,
            2
          )}
        </code>
      </pre> */}
    </>
  );
};
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  filterContent,
  defaultSearch,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        py: "20px",
        px: "16px",
        gap: "16px",
        borderBottom: "1px solid #E6E6E6",
      }}
    >
      {defaultSearch && (
        <OutlinedInput
          sx={{
            height: "48px",
          }}
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder="Search"
          size="small"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon size="large" />
            </InputAdornment>
          }
          endAdornment={
            value && (
              <InputAdornment
                sx={{ cursor: "pointer" }}
                onClick={(e) => {
                  setValue("");
                  onChange("");
                }}
                position="end"
              >
                <CloseIcon size="large" />
              </InputAdornment>
            )
          }
          fullWidth
        />
      )}
      {filterContent}
    </Stack>
  );
}
export default GlobalFilter;

export function CustomTableHeaderSection({ tableTitle, headerRightContent }) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        py: "12px",
        px: "16px",
        gap: "16px",
        borderBottom: "1px solid #E6E6E6",
        height: "84px",
      }}
    >
      <Typography gutterBottom align="center" variant="subtitle1">
        {tableTitle}
      </Typography>
      {headerRightContent}
    </Stack>
  );
}

export function FilterSection({ data, onFilterClear, onAllFilterClear }) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",

        py: "20px",
        px: "16px",
        gap: "16px",
        borderBottom: "1px solid #E6E6E6",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "12px",
        }}
      >
        {data.map((data, index) => (
          <Chip
            key={index}
            label={
              <Typography variant="body2" fontSize="14px" py="5px">
                <Typography
                  variant="body2"
                  fontSize="14px"
                  component="span"
                  textTransform="capitalize"
                >
                  {data.type}
                </Typography>
                : {data.label}
              </Typography>
            }
            onDelete={() => onFilterClear(data)}
            sx={{
              backgroundColor: "#E6E6E6",
              height: "30px",
              "& .MuiChip-deleteIcon": {
                color: "#808080",
              },
            }}
          />
        ))}
      </Stack>

      <Button
        color="info"
        sx={{ width: "81px", minWidth: "81px", height: "30px" }}
        onClick={onAllFilterClear}
      >
        Clear All
      </Button>
    </Stack>
  );
}

export function FilterBar({ filterSection }) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        py: "20px",
        px: "16px",
        gap: "16px",
        borderBottom: "1px solid #E6E6E6",
      }}
    >
      {filterSection}
    </Stack>
  );
}

export function TablePaginationActions(props) {
  const { onPageChange, lastPage, page } = props;
  return (
    <Box sx={{ flexShrink: 0, marginLeft: "20px" }}>
      <Pagination
        count={lastPage}
        page={page + 1}
        shape="rounded"
        showFirstButton
        showLastButton
        onChange={(e, page) => onPageChange(page)}
      />
    </Box>
  );
}
