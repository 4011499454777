import { useEffect } from "react";
import { useImmer } from "use-immer";
import qs from "qs";
import {
  addGroupMembers,
  changeGroupStatus,
  changeLoyaltyCustomerStatus,
  createGroup,
  deleteLoyaltyCustomer,
  deleteLoyaltyGroup,
  editGroup,
  getGroupDetailsById,
  getGroupList,
  getLoyaltyPointHistoryList,
  getMembers,
  onChangeOwner,
  updatePointAdjustment,
  uploadMember,
} from "../api";
import moment from "moment";
import { useDebounce } from "use-debounce";
import { useContext } from "react";
import { AppContext } from "../../../store/AppScopeProvider";
import { errorMessage, successMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { getCustomersList } from "../..";
import { usePermission, useTabs } from "../../../shared";

const useLoyaltyGroups = ({ isGroupList, groupId }) => {
  const navigate = useNavigate();
  const { setCurentTab } = useTabs();
  const {
    appState,
    appState: { currentTabs },
  } = useContext(AppContext);
  const isEditable = usePermission("loyalty-card-groups-modify");
  const isCreatable = usePermission("loyalty-card-groups-create");
  const isDeletable = usePermission("loyalty-card-groups-delete");
  const [state, setState] = useImmer({
    isListBusy: false,
    initialLoad: true,
    isOpen: false,
    isOpenPoitAdjustment: false,
    isOpenChangeOwner: false,
    pointAdjustmentLoading: false,
    isAddMemberOpen: false,
    memberId: null,
    isMemberDeleteOpen: false,
    isSaveButtonBusy: false,
    isDetailsFetching: false,
    isMembersListLoading: false,
    isMemberSaveButtonBusy: false,
    name: "",
    searchText: null,
    customers: [],
    selectedUsers: "",
    userSearchText: null,
    groups: [],
    pagination: {
      pageIndex: 1,
      pageSize: 25,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    sortBy: [],
    filters: {
      isSearching: false,
      all: [],
      search: "",
      member_count_operator: {},
      member_count_value: {},
      points_operator: {},
      points_value: {},
      status: {},
      date: {},
    },
    data: {
      club_name: "",
      customer_id: {},
      barcode: "",
      verification_code: "",
      member_number: "",
      store_id: [],
      status: 1,
      starting_points: null,
      loyalty_card_type_id: null,
      multiplier: 1,
    },
    pointsUpdateData: {
      points: null,
      loyalty_card_type_id: null,
      remarks: "",
    },
    members: [],
    addMember: {
      barcode: "",
      verification_code: "",
      customer_id: null,
      customer_name: "",
      isGuest: "1",
      member_number: "",
    },
    cardHistory: [],
    cardHistoryTable: {
      pagination: {
        pageIndex: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
    },
    ishistoryLoading: false,
    isCalenderOpen: false,
    cardHistoryFilters: {
      all: [],
      search: null,
      customer_id: {},
      store_id: [],
    },
    httpStatusCodes: {
      details: "",
    },
    date: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    showDate: [],
    multiplierModal: false,
  });

  const loadNextPage = (e) => {
    const { pageIndex, pageSize } = state?.pagination;
    handleGetCustomerList({
      page: pageIndex + 1,
      type: "",
    });
    setState((draft) => {
      draft.pagination.pageIndex = state.pagination.pageIndex + 1;
    });
  };

  useEffect(() => {
    if (isGroupList) {
      getLoyaltyGroupList({});
    }
  }, [isGroupList]);

  useEffect(() => {
    if (groupId) {
      getGroupDetails(groupId);
    }
    if (groupId && currentTabs.loyaltyGroupDetails === "2") {
      getMembersList({});
    }
    if (groupId && currentTabs.loyaltyGroupDetails === "3") {
      handleLoyaltyPointHistoryList({});
    }
  }, [groupId, currentTabs.loyaltyGroupDetails]);

  const getMembersList = async ({
    id = groupId,
    pagination = {
      pageIndex: 1,
      pageSize: state.pagination.pageSize,
    },
    sortBy = [],
  }) => {
    try {
      setState((draft) => {
        draft.isMembersListLoading = true;
      });

      const res = await getMembers(id, {}, pagination);
      if (res.success) {
        const arr = res.data.loyalty_group_card;
        let fromIndex = 0;
        arr.forEach((item, index) => {
          if (item.is_owner === 1) {
            return (fromIndex = index);
          }
        });
        const element = arr.splice(fromIndex, 1)[0];
        arr.splice(0, 0, element);
        setState((draft) => {
          draft.members = arr;
          draft.pagination = res.data.pagination;
          draft.isMembersListLoading = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isMembersListLoading = false;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };

  const getGroupDetails = async (id) => {
    try {
      setState((draft) => {
        draft.isDetailsFetching = true;
      });
      const res = await getGroupDetailsById(id);
      if (res.success) {
        const {
          customer_id,
          customer_name,
          barcode,
          loyalty_card_type_id,
          verification_code,
          points,
          member_number,
        } = res.data.owner_loyalty_card;
        setState((draft) => {
          draft.data = {
            ...res.data,
            store_id: res.data.stores,
            barcode,
            member_number,
            loyalty_card_type_id,
            verification_code,
            customer_id: { value: customer_id, label: customer_name },
          };
          draft.pointsUpdateData.loyalty_card_type_id = loyalty_card_type_id;
          draft.name = res.data.club_name;
          draft.isDetailsFetching = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isDetailsFetching = false;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };

  const getLoyaltyGroupList = async ({
    status = {},
    search = null,
    member_count_operator = {},
    member_count_value = {},
    points_operator = {},
    points_value = {},
    date = {},
    pagination = {
      pageIndex: 1,
      pageSize: state.pagination.pageSize,
    },
    sortBy = [],
  }) => {
    let params = {};
    if (search) {
      params = { ...params, search };
    }
    if (status !== null) {
      params = { ...params, status: status.value };
    }
    if (member_count_operator) {
      params = {
        ...params,
        member_count_operator: member_count_operator.value,
      };
    }
    if (member_count_value) {
      params = { ...params, member_count_value: member_count_value.value };
    }
    if (points_operator) {
      params = { ...params, points_operator: points_operator.value };
    }
    if (points_value) {
      params = { ...params, points_value: points_value.value };
    }
    if (Object.keys(date).length) {
      params = {
        ...params,
        last_used_date_start_date: moment(date.value.startDate).format(
          "YYYY-MM-DD"
        ),
        last_used_date_end_date: moment(date.value.endDate).format(
          "YYYY-MM-DD"
        ),
      };
    }
    if (sortBy.length > 0) {
      const sortByParams = sortBy.reduce(
        (obj, item) =>
          Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
        {}
      );
      params = {
        ...params,
        sort: sortByParams,
      };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isListBusy = true;
        draft.initialLoad = false;
      });
      const res = await getGroupList(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.isListBusy = false;
          draft.groups = res.data.loyalty_card_groups;
          draft.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isListBusy = false;
      });
    }
  };

  const [debouncedSearch] = useDebounce(state.filters.search, 1000);
  const [debouncedSearchText] = useDebounce(state.searchText, 1000);
  useEffect(() => {
    if (!state.initialLoad && !state.filters.isSearching) {
      const {
        member_count_operator,
        member_count_value,
        points_operator,
        points_value,
        status,
        date,
      } = state.filters;
      getLoyaltyGroupList({
        search: debouncedSearch,
        member_count_operator,
        member_count_value,
        points_operator,
        points_value,
        status,
        date,
      });
    }
  }, [debouncedSearch]);

  const onChangeFilter = (e, label, v) => {
    const { name, value } = e.target;
    if (name !== "search") {
      setState((draft) => {
        draft.filters[name] = {
          value,
          label: v ? v.props.children : value,
          type: label,
        };
      });
    } else {
      setState((draft) => {
        draft.filters[name] = value;
      });
    }
  };

  const handleFilterClear = (type, value) => {
    const {
      member_count_operator,
      member_count_value,
      points_operator,
      points_value,
      status,
      date,
      search,
    } = state.filters;
    if (type === "single") {
      if (value.type === "Member count") {
        setState((draft) => {
          draft.filters.member_count_operator = {};
        });
        getLoyaltyGroupList({
          search,
          member_count_operator: {},
          member_count_value,
          points_operator,
          points_value,
          status,
          date,
        });
      } else if (value.type === "Member count value") {
        setState((draft) => {
          draft.filters.member_count_value = {};
        });
        getLoyaltyGroupList({
          search,
          member_count_operator,
          member_count_value: {},
          points_operator,
          points_value,
          status,
          date,
        });
      } else if (value.type === "Date") {
        setState((draft) => {
          draft.filters.date = {};
          draft.showDate = [];
        });
        getLoyaltyGroupList({
          search,
          member_count_operator,
          member_count_value,
          points_operator,
          points_value,
          status,
          date: {},
        });
      } else if (value.type === "Points") {
        setState((draft) => {
          draft.filters.points_operator = {};
        });
        getLoyaltyGroupList({
          search,
          member_count_operator,
          member_count_value,
          points_operator: {},
          points_value,
          status,
          date,
        });
      } else if (value.type === "Points value") {
        setState((draft) => {
          draft.filters.points_value = {};
        });
        getLoyaltyGroupList({
          search,
          member_count_operator,
          member_count_value,
          points_operator,
          points_value: {},
          status,
          date,
        });
      } else if (value.type === "Status") {
        setState((draft) => {
          draft.filters.status = {};
        });
        getLoyaltyGroupList({
          search,
          member_count_operator,
          member_count_value,
          points_operator,
          points_value,
          status: {},
          date,
        });
      }
    } else {
      setState((draft) => {
        draft.filters.all = [];
        draft.filters.member_count_operator = {};
        draft.filters.member_count_value = {};
        draft.filters.points_operator = {};
        draft.filters.points_value = {};
        draft.filters.status = {};
        draft.filters.date = {};
        draft.showDate = [];
        draft.filters.type = [];
        draft.isBusy = true;
      });
      getLoyaltyGroupList({
        search,
      });
    }
  };

  const handleApplyFilter = () => {
    const {
      member_count_operator,
      member_count_value,
      points_operator,
      points_value,
      status,
      date,
      search,
    } = state.filters;
    getLoyaltyGroupList({
      search,
      member_count_operator,
      member_count_value,
      points_operator,
      points_value,
      status,
      date,
    });
  };

  useEffect(() => {
    setState((draft) => {
      draft.filters.all = [
        state.filters.member_count_operator,
        state.filters.member_count_value,
        state.filters.points_operator,
        state.filters.points_value,
        state.filters.status,
        state.filters.date,
      ].filter((el) => Object.keys(el).length);
    });
  }, [
    state.filters.member_count_operator,
    state.filters.member_count_value,
    state.filters.points_operator,
    state.filters.points_value,
    state.filters.status,
    state.filters.date,
  ]);

  const onSeachClear = () => {
    setState((draft) => {
      draft.filters.search = "";
      draft.filters.isSearching = false;
    });
  };

  const onChangeDate = (e) => {
    setState((draft) => {
      draft.date = e;
    });
  };
  const handleIsOpen = (e) => {
    setState((draft) => {
      draft.isCalenderOpen = e;
    });
  };

  const handleDateChangeApply = () => {
    setState((draft) => {
      draft.showDate = state.date;
      draft.isCalenderOpen = false;
      draft.filters.date = {
        value: state.date[0],
        label: `${moment(state.date[0].startDate).format(
          "yyy-MM-DD"
        )} to ${moment(state.date[0].endDate).format("yyy-MM-DD")}`,
        type: "Date",
      };
    });
  };

  const toggleModal = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isOpen = !draft.isOpen;
    });
  };

  const handleDeleteGroup = async (type = "list") => {
    try {
      const res = await deleteLoyaltyGroup(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.groups = draft.groups.filter((item) => item.id !== state.id);
        });
        toggleModal();
        if (type === "details") {
          navigate("/loyalty-cards");
        }
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };
  const onOwnerChange = (e) => {
    const { name, value } = e.target;
    const customerFilter = state?.customers?.find(
      (i) => i?.customer_id === value?.value
    );
    setState((draft) => {
      draft.data[name] = value;
      draft.data.barcode = customerFilter?.barcode;
      draft.data.member_number = customerFilter?.member_number;
      draft.data.verification_code = customerFilter?.verification_code;
    });
  };

  const handleGetCustomerList = async ({ search = null, page, type }) => {
    let params = {};
    let pagination = {
      pageIndex: page ?? 1,
      pageSize: 10,
    };
    if (search) {
      params = { ...params, search };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      const res = await getCustomersList(query, pagination);
      if (res.success) {
        if (type === "search") {
          setState((draft) => {
            draft.customers = [...res?.data?.customers];
            draft.pagination.hasMorePages = res.data.pagination.hasMorePages;
          });
        } else {
          setState((draft) => {
            draft.customers = [...draft?.customers, ...res?.data?.customers];
            draft.pagination.hasMorePages = res.data.pagination.hasMorePages;
          });
        }
        const options =
          res.data.customers.length > 0
            ? res.data.customers.map((data) => {
              return {
                value: data.id,
                label: data.name,
                email: data.email,
                phone: data.phone_formatted,
                custom: false,
              };
            })
            : [
              {
                custom: true,
              },
            ];

        return {
          options: options,
          hasMore: res.data.pagination.hasMorePages,
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (err) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const handleGetMemberList = async ({ id, page }) => {
    let pagination = {
      pageIndex: page ?? 1,
      pageSize: 10,
    };

    try {
      const res = await getMembers(id, {}, pagination);
      if (res.success) {
        setState((draft) => {
          draft.customers = [
            ...draft?.customers,
            ...res?.data?.loyalty_group_card,
          ];
          draft.pagination.hasMorePages = res.data.pagination.hasMorePages;
        });
        const filterMemberList = res.data.loyalty_group_card.filter(
          (item) => item.customer_id !== null
        );
        const options =
          filterMemberList.length > 0
            ? filterMemberList.map((data) => {
              return {
                custom: false,
                value: data.customer_id,
                label: data.customer_name,
              };
            })
            : [
              {
                custom: true,
              },
            ];

        return {
          options: options,
          hasMore: res.data.pagination.hasMorePages,
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (err) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };
  const deleteSelectedStore = (id) => {
    setState((draft) => {
      draft.data.store_id = draft.data.store_id.filter(
        (item) => item.id !== id
      );
    });
  };

  const onSaveGroup = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const payload = {
        ...state.data,
        store_id: state.data.store_id.map((item) => item.id),
        customer_id: state.data.customer_id.value,
      };
      const res = await createGroup(payload);
      if (res.success) {
        setCurentTab("loyaltyGroupDetails", "1");
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate(`/loyalty-cards/sponsorship-group/${res.data.id}`);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onUpdateGroup = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const payload = {
        ...state.data,
        store_id: state.data.store_id.map((item) => item.id),
        customer_id: state.data.customer_id.value,
      };
      const res = await editGroup(id, payload);
      if (res.success) {
        setCurentTab("loyaltyGroupDetails", "1");
        successMessage("Updated Successfully");
        navigate(`/loyalty-cards/sponsorship-group/${res.data.id}`);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const changeStatus = async (id) => {
    try {
      setState((draft) => {
        draft.data.status = draft.data.status === 1 ? 0 : 1;
      });
      const res = await changeGroupStatus(id);
      if (res.success) {
        successMessage("Status changed successfully");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const togglePointAdjustmentModal = () => {
    setState((draft) => {
      draft.isOpenPoitAdjustment = !draft.isOpenPoitAdjustment;
    });
    if (state.isCalenderOpen) {
      setState((draft) => {
        draft.pointsUpdateData.remarks = "";
      });
    }
  };
  const toggleChangeOwner = (data) => {
    setState((draft) => {
      draft.isOpenChangeOwner = data;
      draft.selectedUsers = state.data.customer_id?.value;
      draft.searchText = "";
    });
  };
  const onPointsUpdateChange = (e) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.pointsUpdateData[name] = value;
    });
  };

  const onUpdatePointAdjustment = async () => {
    try {
      setState((draft) => {
        draft.pointAdjustmentLoading = true;
      });
      const res = await updatePointAdjustment(
        state.data.owner_loyalty_card.id,
        state.pointsUpdateData
      );
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.data.owner_loyalty_card.points =
            Number(draft.data.owner_loyalty_card.points) +
            Number(draft.pointsUpdateData.points);
          draft.pointsUpdateData.remarks = "";
          draft.pointsUpdateData.points = null;
          draft.isOpenPoitAdjustment = false;
          draft.pointAdjustmentLoading = false;
        });
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.pointAdjustmentLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.pointAdjustmentLoading = false;
      });
    }
  };

  const toggleModalAddMember = () => {
    setState((draft) => {
      draft.isAddMemberOpen = !draft.isAddMemberOpen;
      draft.addMember.barcode = "";
      draft.addMember.verification_code = "";
      draft.addMember.customer_id = null;
      draft.addMember.customer_name = "";
      draft.addMember.member_number = "";
      draft.addMember.isGuest = "1";
    });
  };

  const handleChangeCustomerStatus = async (id, index) => {
    try {
      const updatedMembers = state.members.map((member) => {
        if (member.id === +id) {
          return { ...member, status: member.status === 1 ? 0 : 1 };
        } else {
          return member;
        }
      });
      setState((draft) => {
        draft.members = updatedMembers;
      });
      const res = await changeLoyaltyCustomerStatus(id);
      if (res.success) {
        successMessage("Loyalty member status updated successfully");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onChangeAddmember = (e) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.addMember[name] = value;
    });
    if (name === "isGuest") {
      setState((draft) => {
        draft.addMember.customer_id = null;
        draft.addMember.customer_name = "";
      });
    }
    if (name === "customer_id") {
      setState((draft) => {
        draft.addMember.customer_name = value.label;
      });
    }
  };

  const onAddMember = async () => {
    try {
      setState((draft) => {
        draft.isMemberSaveButtonBusy = true;
      });
      const payload = {
        ...state.addMember,
        customer_id: state.addMember.customer_id?.value || null,
      };
      const res = await addGroupMembers(groupId, payload);

      if (res.success) {
        toggleModalAddMember();
        successMessage("Member added successfully");
        getMembersList({});
        setState((draft) => {
          draft.isMemberSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isMemberSaveButtonBusy = false;
      });
    }
  };

  const onUploadMembers = async (e) => {
    try {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      const res = await uploadMember(groupId, formData);
      if (res.success) {
        successMessage("Member added successfully");
        getMembersList({});
      }
    } catch (err) {
      errorMessage(err.response.data.errors ?? err.message);
    }
  };

  const toggleDeleteMember = (memberId = null) => {
    setState((draft) => {
      draft.isMemberDeleteOpen = !draft.isMemberDeleteOpen;
      draft.memberId = memberId;
    });
  };

  const handleDeleteMember = async (customerId) => {
    try {
      const res = await deleteLoyaltyCustomer(state.memberId);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isMemberDeleteOpen = false;
          draft.members = draft.members.filter(
            (item) => item.id !== state.memberId
          );
          draft.memberId = null;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const [debouncedText] = useDebounce(state.cardHistoryFilters.search, 1000);

  const handleLoyaltyPointHistoryList = async ({
    search = null,
    customer_id = {},
    store_id = [],
    pagination = {
      pageIndex: 1,
      pageSize: state.pagination.pageSize,
    },
    sortBy = [],
  }) => {
    try {
      setState((draft) => {
        draft.ishistoryLoading = true;
      });
      let storeIds = store_id.map((v) => {
        return v.value;
      });
      let params = { loyalty_card_group_id: groupId };
      if (search) {
        params = { ...params, search };
      }
      if (Object.keys(customer_id).length > 0) {
        params = { ...params, customer_id: customer_id.value };
      }
      if (storeIds.length > 0) {
        params = { ...params, store_id: storeIds };
      }
      if (sortBy.length > 0) {
        const sortByParams = sortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };
      const res = await getLoyaltyPointHistoryList(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.cardHistory = res.data.loyalty_points_history;
          draft.cardHistoryTable.pagination = res.data.pagination;
          draft.ishistoryLoading = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.ishistoryLoading = false;
      });
    }
  };

  useEffect(() => {
    if (state.cardHistoryFilters.search !== null) {
      const { search, customer_id, store_id } = state.cardHistoryFilters;
      handleLoyaltyPointHistoryList({
        search,
        customer_id,
        store_id,
      });
    }
  }, [debouncedText]);

  const handleFilter = (e, value, type) => {
    if (type === "store") {
      setState((draft) => {
        draft.cardHistoryFilters.store_id = value.map((v) => {
          return { ...v, type: "Store" };
        });
      });
    } else if (type === "Owner") {
      setState((draft) => {
        draft.cardHistoryFilters.customer_id = { ...value, type: "Owner" };
      });
    }
  };

  useEffect(() => {
    setState((draft) => {
      draft.cardHistoryFilters.all = [
        ...state.cardHistoryFilters.store_id,
        state.cardHistoryFilters.customer_id,
      ].filter((el) => Object.keys(el).length);
    });
  }, [state.cardHistoryFilters.store_id, state.cardHistoryFilters.customer_id]);

  const handleApplyFilterHistory = () => {
    const { search, customer_id, store_id } = state.cardHistoryFilters;
    handleLoyaltyPointHistoryList({
      search,
      customer_id,
      store_id,
    });
  };

  const handleFilterClearHistory = (type, value) => {
    const { search, customer_id, store_id } = state.cardHistoryFilters;
    if (type === "single") {
      if (value.type === "Store") {
        const filteredStores = state.cardHistoryFilters.store_id.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.cardHistoryFilters.store_id = filteredStores;
        });
        handleLoyaltyPointHistoryList({
          search,
          customer_id,
          store_id: filteredStores,
        });
      } else if (value.type === "Owner") {
        setState((draft) => {
          draft.cardHistoryFilters.customer_id = {};
        });
        handleLoyaltyPointHistoryList({
          search,
          store_id,
        });
      }
    } else {
      setState((draft) => {
        draft.cardHistoryFilters.all = [];
        draft.cardHistoryFilters.store_id = [];
        draft.cardHistoryFilters.customer_id = {};
      });
      handleLoyaltyPointHistoryList({
        search,
      });
    }
  };

  const onHistorySearchChange = (e) => {
    setState((draft) => {
      draft.cardHistoryFilters.search = e.target.value;
    });
  };

  const gotoPageGroup = (page) => {
    const { pagination, sortBy } = state;
    const {
      member_count_operator,
      member_count_value,
      points_operator,
      points_value,
      status,
      date,
    } = state.filters;
    getLoyaltyGroupList({
      search: debouncedSearch,
      member_count_operator,
      member_count_value,
      points_operator,
      points_value,
      status,
      date,
      pagination: {
        pageIndex: page,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };

  const setPageSizeGroup = (e) => {
    const { pagination, sortBy } = state;
    const {
      member_count_operator,
      member_count_value,
      points_operator,
      points_value,
      status,
      date,
    } = state.filters;
    getLoyaltyGroupList({
      search: debouncedSearch,
      member_count_operator,
      member_count_value,
      points_operator,
      points_value,
      status,
      date,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };

  const handleSortGroup = (sortBy) => {
    const { pagination } = state;
    const {
      member_count_operator,
      member_count_value,
      points_operator,
      points_value,
      status,
      date,
    } = state.filters;
    getLoyaltyGroupList({
      search: debouncedSearch,
      member_count_operator,
      member_count_value,
      points_operator,
      points_value,
      status,
      date,
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.sortBy = sortBy;
    });
  };

  const gotoPageMember = (page) => {
    const { pagination, sortBy } = state;
    getMembersList({
      search: debouncedSearch,
      pagination: {
        pageIndex: page,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };

  const setPageSizeMember = (e) => {
    const { pagination, sortBy } = state;
    getMembersList({
      // search: debouncedSearch,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };

  const handleSortMember = (sortBy) => {
    const { pagination } = state;
    getMembersList({
      search: debouncedSearch,
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.sortBy = sortBy;
    });
  };

  const gotoPageCardHistory = (page) => {
    const { pagination, sortBy } = state.cardHistoryTable;
    const { search, customer_id, store_id } = state.cardHistoryFilters;
    handleLoyaltyPointHistoryList({
      search,
      customer_id,
      store_id,
      pagination: {
        pageIndex: page,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.cardHistoryTable.pagination.pageIndex = page;
    });
  };

  const handleSortCardHistory = (sortBy) => {
    const { pagination } = state.cardHistoryTable;
    const { search, customer_id, store_id } = state.cardHistoryFilters;
    handleLoyaltyPointHistoryList({
      search,
      customer_id,
      store_id,
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });

    setState((draft) => {
      draft.cardHistoryTable.sortBy = sortBy;
    });
  };

  const setPageSizeCardHistory = (e) => {
    const { pagination, sortBy } = state.cardHistoryTable;
    const { search, customer_id, store_id } = state.cardHistoryFilters;
    handleLoyaltyPointHistoryList({
      search,
      customer_id,
      store_id,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
    });

    setState((draft) => {
      draft.cardHistoryTable.pagination.pageSize = +e.target.value;
    });
  };
  const handleChangeOwner = async (id) => {
    try {
      const res = await onChangeOwner(groupId, id);
      if (res.success) {
        successMessage("Loyalty Group owner changed succesfully.");
        getMembersList({});
        toggleChangeOwner(false);
        getGroupDetails(groupId);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleUsersSelect = (id) => {
    setState((draft) => {
      draft.selectedUsers = id;
    });
  };
  const isSelectedUser = (id) => {
    return state.selectedUsers === id;
  };
  const HandleFilterUsers = (type, text) => {
    setState((draft) => {
      draft.searchText = text;
    });
  };
  useEffect(() => {
    if (debouncedSearchText || debouncedSearchText === "") {
      handleGetCustomerList({ search: debouncedSearchText, type: "search" });
    }
  }, [debouncedSearchText]);

  const handleModal = (state) => {
    setState((draft) => {
      draft.multiplierModal = state;
    });
  }

  return {
    state,
    onChangeFilter,
    onSeachClear,
    appState,
    handleDateChangeApply,
    handleIsOpen,
    onChangeDate,
    handleFilterClear,
    handleApplyFilter,
    toggleModal,
    handleDeleteGroup,
    onChange,
    handleGetCustomerList,
    deleteSelectedStore,
    onSaveGroup,
    onUpdateGroup,
    changeStatus,
    togglePointAdjustmentModal,
    onPointsUpdateChange,
    onUpdatePointAdjustment,
    toggleModalAddMember,
    setCurentTab,
    handleChangeCustomerStatus,
    onChangeAddmember,
    onAddMember,
    onUploadMembers,
    toggleDeleteMember,
    handleDeleteMember,
    handleFilter,
    handleApplyFilterHistory,
    handleFilterClearHistory,
    onHistorySearchChange,
    setCurentTab,
    isEditable,
    isCreatable,
    setCurentTab,
    isDeletable,
    gotoPageGroup,
    setPageSizeGroup,
    gotoPageMember,
    setPageSizeMember,
    setPageSizeCardHistory,
    gotoPageCardHistory,
    handleSortGroup,
    handleSortMember,
    handleSortCardHistory,
    toggleChangeOwner,
    handleChangeOwner,
    onOwnerChange,
    isSelectedUser,
    handleUsersSelect,
    HandleFilterUsers,
    loadNextPage,
    handleGetMemberList,
    handleModal,
  };
};

export default useLoyaltyGroups;
