import { Routes, Route } from "react-router-dom";
import {
  DashboardLayout,
  PageNotFound,
  ProtectedRoute,
  UnAuthorised,
} from "../../shared";

import {
  Profile,
  RolesAndPermissions,
  StateAndSuburb,
  Terminals,
  TaxGroupsAndTaxes,
  Customers,
  Stores,
  Category,
  AdminUsers,
  Discount,
  Products,
  Orders,
  Wastage,
  PriceLevels,
  Invitation,
  ManagerPassword,
  Favourites,
  Bundles,
  BirthdayCoupons,
  BFITDashboard,
  EndoftheDay,
  MenuBanners,
  Report,
  DepositSlip,
  ReviewsAndRatings,
  ClientAccounts,
  Loyalty,
  EmailSettings,
  CFD,
  Surcharges,
  COA,
  POSSettings,
  ProductSorting,
  Ubereats,
  DiscountOrders,
  FuturePrice,
  CouponReport,
  CartProduct,
  PushNotification,
  List,
  BSReport,
  PLReport,
  Doordash,
  ProductConfigurations,
  QRCode,
  PaymentMediaReport,
} from "../../modules";
import { Franchisee } from "../../modules/Franchisee/Franchisee";
import { AvailabilityGroup } from "../../modules/AvailabilityGroup/AvailabilityGroup";
import { OptionalGroup } from "../../modules/OptionalGroup.js/OptionalGroup";
import { Refund } from "../../modules/Refund";
import { Coupons } from "../../modules/Coupons/Coupons";
import { Conditions } from "../../modules/Conditions/Conditions";
import { ProductRatings } from "../../modules/ProductRatings/ProductRatings";
import { GiftCard } from "../../modules/GiftCard/GiftCard";
import { Menulog } from "../../modules/Menulog/Menulog";
import Test from "../../shared/Test";
import { Configurations } from "../../modules/Configurations";
import { TopSelling } from "../../modules/TopSelling";
import { MenuTemplate } from "../../modules/MenuTemplate/MenuTemplate";
import { OnlineOrdering } from "../../modules/OnlineOrdering/OnlineOrdering";
import { Myob, Xero } from "../../modules/XeroAndMyObCopy";

export function Dashboard() {
  return (
    <div id="app">
      <DashboardLayout>
        <div id="main-content">
          <Routes>
            <Route
              element={<ProtectedRoute allowedRoute={["dashboard-view"]} />}
            >
              <Route path="/" element={<BFITDashboard />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["role-view"]} />}>
              <Route
                path="/roles-and-permissions/*"
                element={<RolesAndPermissions />}
              />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["user-view"]} />}>
              <Route path="/admin-users/*" element={<AdminUsers />} />
            </Route>
            <Route
              element={
                <ProtectedRoute allowedRoute={["state-view", "city-view"]} />
              }
            >
              <Route path="/state-and-suburb/*" element={<StateAndSuburb />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["franchisee-view"]} />}
            >
              <Route path="/franchisee/*" element={<Franchisee />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["tax-view"]} />}>
              <Route
                path="/tax-groups-and-taxes/*"
                element={<TaxGroupsAndTaxes />}
              />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["category-view"]} />}
            >
              <Route path="/category/*" element={<Category />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["product-view"]} />}>
              <Route path="/products/*" element={<Products />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["terminal-view"]} />}
            >
              <Route path="/terminal/*" element={<Terminals />} />
            </Route>
            <Route
              element={
                <ProtectedRoute allowedRoute={["availability-group-view"]} />
              }
            >
              <Route
                path="/availabilityGroup/*"
                element={<AvailabilityGroup />}
              />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["customer-view"]} />}
            >
              <Route path="/customers/*" element={<Customers />} />
            </Route>
            <Route
              element={
                <ProtectedRoute allowedRoute={["optional-group-view"]} />
              }
            >
              <Route path="/optionalGroup/*" element={<OptionalGroup />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["store-view"]} />}>
              <Route path="/stores/*" element={<Stores />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["discount-view"]} />}
            >
              <Route path="/discount/*" element={<Discount />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["price-level-view"]} />}
            >
              <Route path="/price-levels/*" element={<PriceLevels />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["refund-view"]} />}>
              <Route path="/refund/*" element={<Refund />} />
            </Route>

            {/* Payment Media Report */}
            <Route
              element={<ProtectedRoute allowedRoute={["payment-media-view"]} />}
            >
              <Route
                element={<PaymentMediaReport />}
                path="/payment-media-report/*"
              />
            </Route>
            <Route
              element={
                <ProtectedRoute allowedRoute={["manager-password-view"]} />
              }
            >
              <Route path="/manager-password/*" element={<ManagerPassword />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["favourite-view"]} />}
            >
              <Route exact element={<Favourites />} path={"/favourites/*"} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["wastage-view"]} />}>
              <Route path="/wastage/*" element={<Wastage />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["condition-view"]} />}
            >
              <Route exact element={<Conditions />} path={"/coupon-rules/*"} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["coupon-view"]} />}>
              <Route exact element={<Coupons />} path={"/coupons/*"} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["coupon-view"]} />}>
              <Route
                exact
                element={<CouponReport />}
                path={"/coupon-report/*"}
              />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["order-view"]} />}>
              <Route exact element={<Orders />} path={"/orders/*"} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["order-view"]} />}>
              <Route
                exact
                element={<DiscountOrders />}
                path={"/discount-orders/*"}
              />
            </Route>
            <Route exact element={<Bundles />} path={"/bundle/*"} />
            <Route element={<ProtectedRoute allowedRoute={["coupon-view"]} />}>
              <Route path="/birthday-coupons/*" element={<BirthdayCoupons />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["banner-view"]} />}>
              <Route exact element={<MenuBanners />} path={"/menu-banner/*"} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["end-of-day-view"]} />}
            >
              <Route path="/end-of-day/*" element={<EndoftheDay />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["loyalty-cards-view"]} />}
            >
              <Route path="/loyalty-cards/*" element={<Loyalty />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["report-view"]} />}>
              <Route element={<Report />} path={"/reports/*"} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["deposit-slip-view"]} />}
            >
              <Route path="/deposit-slip/*" element={<DepositSlip />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["gift-cards-view"]} />}
            >
              <Route path="/gift-card/*" element={<GiftCard />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["menulog-view"]} />}>
              <Route path="/menulog/*" element={<Menulog />} />
            </Route>
            <Route
              element={
                <ProtectedRoute allowedRoute={["client-account-view"]} />
              }
            >
              <Route path="/client-account/*" element={<ClientAccounts />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["email-setting-view"]} />}
            >
              <Route
                exact
                element={<EmailSettings />}
                path={"/email-settings/*"}
              />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["cfd-view"]} />}>
              <Route exact element={<CFD />} path={"/cfd/*"} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["surcharge-view"]} />}
            >
              <Route exact element={<Surcharges />} path={"/surcharges/*"} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["pos-setting-view"]} />}
            >
              <Route exact element={<POSSettings />} path={"/pos-settings/*"} />
              <Route
                element={<ProtectedRoute allowedRoute={["ubereats-view"]} />}
              >
                <Route exact element={<Ubereats />} path={"/ubereats/*"} />
              </Route>
              <Route
                element={<ProtectedRoute allowedRoute={["doordash-view"]} />}
              >
                <Route exact element={<Doordash />} path={"/doordash/*"} />
              </Route>
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["coa-view"]} />}>
              <Route exact element={<COA />} path={"/coa/*"} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["coa-view"]} />}>
              <Route
                exact
                element={<Configurations />}
                path={"/configurations/*"}
              />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["dashboard-view"]} />}
            >
              <Route exact element={<ProductSorting />} path={"/keypad/*"} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["future-price-view"]} />}
            >
              <Route exact element={<FuturePrice />} path={"/future-price/*"} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["product-view"]} />}>
              <Route exact element={<TopSelling />} path={"/top-selling/*"} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["product-view"]} />}>
              <Route exact element={<CartProduct />} path={"/cart-product/*"} />
            </Route>
            <Route
              element={
                <ProtectedRoute allowedRoute={["xero-myob-report-view"]} />
              }
            >
              {/* <Route exact element={<XeroAndMyOb />} path={"/xero-myob/*"} /> */}
              <Route exact element={<Xero />} path={"/xero/*"} />
              <Route exact element={<Myob />} path={"/myob/*"} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["product-view"]} />}>
              <Route
                exact
                element={<PushNotification />}
                path={"/push-notification/*"}
              />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["pl-bs-account-view"]} />}
            >
              <Route exact element={<List />} path={"/list/*"} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["pl-bs-account-view"]} />}
            >
              <Route exact element={<BSReport />} path={"/bs-report/*"} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["pl-bs-account-view"]} />}
            >
              <Route exact element={<PLReport />} path={"/pl-report/*"} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["menu-view"]} />}>
              <Route path="/menu-template/*" element={<MenuTemplate />} />
            </Route>
            <Route
              element={
                <ProtectedRoute allowedRoute={["online-ordering-view"]} />
              }
            >
              <Route path="/online-ordering/*" element={<OnlineOrdering />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["review-rating-view"]} />}
            >
              <Route
                exact
                element={<ProductConfigurations />}
                path={"/configurations-review/*"}
              />
              <Route
                exact
                element={<ProductRatings />}
                path={"/products-ratings/*"}
              />
              <Route
                exact
                element={<ReviewsAndRatings />}
                path={"/reviews-and-ratings/*"}
              />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["qrcode-view"]} />}>
              <Route path="/qr-code/*" element={<QRCode />} />
            </Route>
            <Route exact element={<Invitation />} path={"/invitation/*"} />
            <Route exact element={<Profile />} path={"/profile/*"} />
            <Route exact element={<UnAuthorised />} path={"/unauthorized"} />
            <Route exact element={<PageNotFound />} path={"/404"} />
            <Route exact element={<PageNotFound />} path={"*"} />
            <Route exact element={<Test />} path={"/test"} />
          </Routes>
        </div>
      </DashboardLayout>
    </div>
  );
}
