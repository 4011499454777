import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProfileDetails } from "./containers";

export const Profile = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ProfileDetails />} />
      </Routes>
    </>
  );
};
