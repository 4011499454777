import { useState, useRef } from "react";
import {
  CheckBoxListDialog,
  ContentLayout,
  CustomeDialog,
  DeleteModal,
  HeaderBreadcrumbs,
  PageNotFound,
} from "../../../shared";
import * as React from "react";
import { useParams } from "react-router-dom";
import { Box, Avatar, Tab, Button } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import SimpleReactValidator from "simple-react-validator";
import { useStore } from "../hooks";
import Avadar_img from "../../../assets/images/Icons/Avadar_img.png";
import {
  SalesChannels,
  StoreGeneralDetails,
  StoreProductsList,
  StoreSettings,
  StoreBumpScreenList,
  StoreAccountsList,
  StoreUsersList,
} from "../components";
import { AddOrEditOpeningHours } from "../components/AddOrEditOpeningHours";

export const StoreDetails = () => {
  const { id } = useParams();

  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const {
    states,
    currentTab,
    setCurentTab,
    handleModal,
    handleDeleteStore,
    resetCurrentTabs,
    isCreateVisible,
    onStoreCopyClick,
    storeCloneOptions,
    onCloneSubmit,
    onCloneOptionsSelect,
    handleSelectAllCopyOptions,
    handleAccountsModal,
    handleDeleteAccontsFromProduct,
    handleInputChange,
    onSubmitBankAccount,
    globalData,
    handleDefaultAccount,
    isEditable,
  } = useStore({
    storeId: id,
    isStore: false,
  });
  const {
    bankAccounts,
    copyStore: { isOpen, storeName, copyOptions, isBusy },
  } = states;
  const handleChange = (event, newValue) => {
    setCurentTab("storeDetails", newValue);
  };

  if (states.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }
  return (
    <>
      <ContentLayout
        backImage={
          <Avatar
            sx={{ height: "80px", width: "80px" }}
            variant="rounded"
            src={id ? states.data.icon : Avadar_img}
          >
            <img
              width="80px"
              height="80px"
              src={
                states.iconFile
                  ? URL.createObjectURL(states.iconFile)
                  : states.data.icon
              }
            />
          </Avatar>
        }
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => onStoreCopyClick(states.data.name, id)}
              >
                <img alt="delete" src="/icons/ic_copy_blue.svg" />
              </Button>
            )}
          </>
        }
        title={states.data.name}
        active={states.data.status === 0 ? "Inactive" : "Active"}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Stores", href: "/stores" },
              { name: states.data.name || "" },
            ]}
          />
        }
      >
        <TabContext value={currentTab}>
          <Box
            className="sticky-Tab"
            sx={{ borderBottom: 1, borderColor: "divider", top: "128px" }}
          >
            <TabList onChange={handleChange}>
              <Tab label="General" value="1" />
              <Tab label="Sales Channels" value="2" />
              <Tab label="Products" value="3" />
              <Tab label="Bump Screens" value="4" />
              <Tab label="Bank Accounts" value="6" />
              <Tab label="Settings" value="5" />
              <Tab label="Opening Hours" value="9" />
              <Tab label="Users" value="8" />
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            <StoreGeneralDetails
              dataList={states.data}
              isLoading={states.isBusy}
              isEditable={isEditable}
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            <SalesChannels storeName={states.data.name} />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="3">
            <StoreProductsList
              id={id}
              setCurentTab={setCurentTab}
              resetCurrentTabs={resetCurrentTabs}
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="4">
            <StoreBumpScreenList globalData={globalData} />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="6">
            <StoreAccountsList
              data={bankAccounts}
              handleAccountsModal={handleAccountsModal}
              handleDeleteAccontsFromProduct={handleDeleteAccontsFromProduct}
              handleInputChange={handleInputChange}
              onSubmitBankAccount={onSubmitBankAccount}
              handleDefaultAccount={handleDefaultAccount}
            />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="5">
            <StoreSettings />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="8">
            <StoreUsersList storeName={states.name} />
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="9">
            <AddOrEditOpeningHours details={states.data} />
          </TabPanel>
        </TabContext>
      </ContentLayout>
      <DeleteModal
        open={states.storeDeleteModal}
        handleClose={() => handleModal(false)}
        onConfirm={handleDeleteStore}
      />
      <CustomeDialog
        open={isOpen}
        handleClose={() => onStoreCopyClick()}
        PaperProps={{
          sx: { width: "423px", height: "408px", p: "16px" },
        }}
        title="Copy Store"
        content={
          <CheckBoxListDialog
            Items={storeCloneOptions || []}
            onSelect={onCloneOptionsSelect}
            onSubmit={onCloneSubmit}
            isSelected={(id) => copyOptions.includes(id)}
            searchBar={false}
            subHeader={`Creating a copy of store "${storeName}"`}
            hasSelectAll={true}
            onAllSelect={handleSelectAllCopyOptions}
            isSelectAllChecked={storeCloneOptions?.every((item) =>
              copyOptions.includes(item.id)
            )}
            selectAllText="Include All"
            loading={isBusy}
          />
        }
      />
    </>
  );
};
