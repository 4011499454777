import * as React from "react";
import { useNavigate } from "react-router-dom";
import { DeleteModal, ServerPaginatedTable } from "../../../shared";
import { sortItems, sortNestedItems } from "../../../utils";
import {
  Button,
  Chip,
  FormGroup,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import AddIcon from "@mui/icons-material/Add";
import useLoyaltyGroups from "../hooks/useLoyaltyGroups";
import { DashBoardDateRangePicker } from "../..";
import SimpleReactValidator from "simple-react-validator";

export const LoyaltySponsorshipGroups = () => {
  const {
    state,
    onChangeFilter,
    onSeachClear,
    appState,
    handleDateChangeApply,
    handleIsOpen,
    onChangeDate,
    handleFilterClear,
    handleApplyFilter,
    handleDeleteGroup,
    toggleModal,
    setCurentTab,
    isCreatable,
    isDeletable,
    gotoPageGroup,
    setPageSizeGroup,
    handleSortGroup,
  } = useLoyaltyGroups({
    isGroupList: true,
  });

  const {
    globalData: { number_operator, loyalty_cards },
  } = appState;
  const { groups, isListBusy, filters, isOpen, pagination, sortBy } = state;
  const {
    search,
    status,
    points_value,
    points_operator,
    member_count_operator,
    member_count_value,
    all,
    date,
  } = filters;
  const navigate = useNavigate();
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const onSubmit = () => {
    if (validator.current.allValid()) {
      handleApplyFilter();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const ActionButtons = ({ id, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
        <img
          alt="details"
          src="/icons/arrow_forward.svg"
          onClick={() => {
            navigate(`sponsorship-group/${id}`);
            setCurentTab("loyaltyGroupDetails", "1");
          }}
        />
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Group Name",
        accessor: "club_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Owner Name",
        id: "customer_name",
        accessor: "owner_loyalty_card.customer_name",
      },
      {
        Header: "Member Count",
        accessor: "member_count",
      },
      {
        Header: "Points",
        id: "points",
        accessor: "owner_loyalty_card.points",
        Cell: ({ value }) => {
          return <Typography variant="body2">{value}</Typography>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sortInverted: true,
        Cell: ({ value }) => {
          return (
            <Chip
              label={value === 1 ? "Active" : "Inactive"}
              color="success"
              variant="outlined"
              size="small"
              disabled={value === 1 ? false : "filled"}
            />
          );
        },
      },
      {
        Header: "Last Used Date",
        accessor: "last_used_date",
        disableGlobalFilter: true,
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => {
                toggleModal(row.id);
              }}
            />
          );
        },
      },
    ],
    [groups]
  );

  const disableApplyButton =
    status.label ||
    member_count_operator.value ||
    member_count_value.value ||
    points_operator.value ||
    points_value.value ||
    date.value;
  return (
    <>
      <ServerPaginatedTable
        tableTitle="Groups"
        columns={columns}
        data={groups}
        isLoading={isListBusy}
        defaultSearch={false}
        rowsSelection={true}
        filterApplied={all}
        onFilterClear={(e) => handleFilterClear("single", e)}
        onAllFilterClear={(e) => handleFilterClear("all")}
        gotoPage={gotoPageGroup}
        setPageSize={setPageSizeGroup}
        pagination={pagination}
        handleSort={handleSortGroup}
        sortByGlobal={sortBy}
        headerRightContent={
          isCreatable && (
            <Button
              type="button"
              size="medium"
              variant="text"
              startIcon={<AddIcon />}
              onClick={() => navigate("sponsorship-group/create")}
            >
              Add New
            </Button>
          )
        }
        filterContent={
          <InputControl
            type="table-search"
            name="search"
            value={search}
            onChange={onChangeFilter}
            onClear={onSeachClear}
          />
        }
        filterSection={
          <>
            <Stack
              direction="row"
              gap="16px"
              alignItems="center"
              flexWrap="wrap"
            >
              <Stack direction="column">
                <FormGroup row>
                  <InputControl
                    type="select"
                    name="member_count_operator"
                    label="Member Count"
                    placeholder="Member Count"
                    optionValue={number_operator || []}
                    value={state.filters.member_count_operator?.value ?? ""}
                    width="150px"
                    sx={{
                      borderRadius: "8px 0 0 8px",
                    }}
                    onChange={(e, v) => {
                      onChangeFilter(e, "Member count", v);
                    }}
                  />

                  <InputControl
                    label="Value"
                    name="member_count_value"
                    value={member_count_value?.value || ""}
                    onChange={(e) => onChangeFilter(e, "Member count value")}
                    width="99px"
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "0 8px 8px 0px",
                      },
                    }}
                  />
                </FormGroup>
                <FormHelperText sx={{ color: "#C60808" }}>
                  {member_count_value.value &&
                    validator.current.message(
                      "Member count",
                      member_count_operator.value,
                      "required"
                    )}
                  {member_count_operator.value &&
                    validator.current.message(
                      "Member count value",
                      member_count_value.value,
                      "required"
                    )}
                </FormHelperText>
              </Stack>
              <Stack direction="column">
                <FormGroup row>
                  <InputControl
                    type="select"
                    name="points_operator"
                    value={state.filters.points_operator?.value ?? ""}
                    label="Points"
                    placeholder="Points"
                    optionValue={number_operator || []}
                    width="150px"
                    sx={{
                      borderRadius: "8px 0 0 8px",
                    }}
                    onChange={(e, v) => onChangeFilter(e, "Points", v)}
                  />

                  <InputControl
                    label="Value"
                    name="points_value"
                    value={points_value?.value || ""}
                    onChange={(e) => onChangeFilter(e, "Points value")}
                    optionValue={number_operator || []}
                    width="99px"
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "0 8px 8px 0px",
                      },
                    }}
                  />
                </FormGroup>
                <FormHelperText sx={{ color: "#C60808" }}>
                  {points_value.value &&
                    validator.current.message(
                      "Points",
                      points_operator.value,
                      "required"
                    )}
                  {points_operator.value &&
                    validator.current.message(
                      "Points value",
                      points_value.value,
                      "required"
                    )}
                </FormHelperText>
              </Stack>
              <InputControl
                type="select"
                name="status"
                label="Status"
                value={status?.value ? status.value : ""}
                optionValue={loyalty_cards?.status || []}
                width="200px"
                onChange={(e, v) => onChangeFilter(e, "Status", v)}
              />

              <DashBoardDateRangePicker
                onChangeDate={onChangeDate}
                isList={true}
                isBottom={true}
                range={state.date}
                showRange={state.showDate}
                isOpen={state.isCalenderOpen}
                handleIsOpen={handleIsOpen}
                handleDateChangeApply={handleDateChangeApply}
              />
            </Stack>

            <Button
              type="button"
              size="medium"
              variant="text"
              color="info"
              sx={{
                minWidth: "40px",
                p: "8px",
              }}
              disabled={!disableApplyButton}
              onClick={() => onSubmit()}
            >
              Apply
            </Button>
          </>
        }
        onRowLink={(e) => {
          return `sponsorship-group/${e.id}`;
        }}
        onRowAction={() => {
          setCurentTab("loyaltyGroupDetails", "1");
        }}
      />
      <DeleteModal
        open={isOpen}
        handleClose={() => toggleModal()}
        onConfirm={() => handleDeleteGroup()}
      />
    </>
  );
};
