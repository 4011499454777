import * as React from "react";
import {
  Typography,
  Card,
  Stack,
  Switch,
  Button,
  Divider,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputControl from "../../../shared/components/InputControl";
import moment from "moment/moment";
import SimpleReactValidator from "simple-react-validator";
import { useImmer } from "use-immer";
import { priceValidator, costValidator } from "../../../utils";

export const PriceLevelCard = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenInit, setIsOpenInit] = useImmer({
    isOpen: false,
    isInit: false,
    type: null,
    value: null,
  });
  const [state, setState] = useImmer({
    validation: {
      isValidated: true,
      start_date: null,
      end_date: null,
    },
  });
  const {
    isSaveButton,
    data,
    staticData,
    index,
    HandlePriceLevelChange,
    handleUpdateProductPricelevel,
  } = props;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        price: priceValidator,
        cost: costValidator,
        endDate: {
          required: true,
          message: "The end date should not be before start date",
          rule: (value) => {
            const { startTime, endTime } = value;
            return endTime && moment(startTime).isSameOrBefore(moment(endTime));
          },
        },
      },
    })
  );
  const onSubmit = (i, type, value) => {
    if (isOpenInit.isInit && validator.current.allValid() && isAllValid()) {
      handleUpdateProductPricelevel(index, type, value);
    } else if (!isOpenInit.isInit) {
      setIsOpen(true);
      setIsOpenInit((draft) => {
        draft.isInit = true;
        draft.open = true;
        draft.type = type;
        draft.value = value;
      });
    } else if (
      isOpenInit.isInit &&
      !validator.current.allValid() &&
      !isAllValid()
    ) {
      setIsOpen(true);
      validator.current.showMessages();
      forceUpdate(1);
      setState((draft) => {
        draft.validation.isValidated = true;
      });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
      setState((draft) => {
        draft.validation.isValidated = true;
      });
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      setIsOpenInit((draft) => {
        draft.isInit = true;
        draft.isInit = true;
        draft.isInit = true;
        draft.isInit = true;
      });
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (isOpenInit.isOpen) {
      onSubmit(index, isOpenInit.type, isOpenInit.value);
      setIsOpenInit((draft) => {
        draft.isOpen = false;
      });
    }
  }, [isOpenInit.isOpen]);

  const validateDate = (reason, name, type) => {
    if (reason === "disablePast") {
      setState((draft) => {
        draft.validation[name] = "Please select a future date";
      });
    } else if (reason === "invalidDate") {
      setState((draft) => {
        draft.validation[name] =
          type === "date"
            ? "Selected Date is not a valid date"
            : "Selected Time is not valid";
      });
    } else {
      setState((draft) => {
        draft.validation[name] = null;
      });
    }
  };

  const isAllValid = () => {
    const { start_date, end_date } = state.validation;
    if (!data.special_price) {
      return true;
    } else if (start_date === null && end_date === null) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <Card
        sx={{
          mb: "24px",
        }}
      >
        {validator.current.purgeFields()}
        <Stack
          sx={{
            p: "14px 16px",
            backgroundColor: "#F7F7F7",
            borderBottom: "1px solid #E6E6E6",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
            justifyContent: "space-between",
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "16px",
              cursor: "pointer",
            }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <ExpandMoreIcon
              sx={{
                m: "8px",
                color: "#999999",
                transform: isOpen ? "rotate(180deg)" : "",
              }}
            />
            <Typography variant="subtitle1" width="190px">
              {staticData.price_level_name}
            </Typography>
            <Stack
              sx={{
                p: "10px",
                gap: "4px",
              }}
            >
              <Typography variant="caption" lineHeight="15px" color="#666666">
                Price
              </Typography>
              <Typography variant="body2" lineHeight="16px">
                ${staticData.price || "0"}
              </Typography>
            </Stack>
            <Stack
              sx={{
                p: "10px",
                gap: "4px",
              }}
            >
              <Typography variant="caption" lineHeight="15px" color="#666666">
                Old Price
              </Typography>
              <Typography variant="body2" lineHeight="16px">
                ${staticData.old_price || "0"}
              </Typography>
            </Stack>
            <Stack
              sx={{
                p: "10px",
                gap: "4px",
              }}
            >
              <Typography variant="caption" lineHeight="15px" color="#666666">
                Cost Ex GST
              </Typography>
              <Typography variant="body2" lineHeight="16px">
                ${staticData.cost_exclusive_gst || "0"}
              </Typography>
            </Stack>
            <Stack
              sx={{
                p: "10px",
                gap: "4px",
              }}
            >
              <Typography variant="caption" lineHeight="15px" color="#666666">
                Special Price
              </Typography>
              <Typography variant="body2" lineHeight="16px">
                ${staticData.special_price || "0"}{" "}
                <Typography
                  variant="body2"
                  lineHeight="16px"
                  color="#636363"
                  display="inline"
                >
                  {staticData.special_price_start_date &&
                  data.special_price_end_date
                    ? `(${
                        moment(staticData.special_price_start_date).format(
                          "DD MMM YYYY h:mm:ss a"
                        ) || "..."
                      } - ${moment(staticData.special_price_end_date).format(
                        "DD MMM YYYY h:mm:ss a"
                      )})`
                    : "(----)"}
                </Typography>
              </Typography>
            </Stack>
          </Stack>
          <Switch
            name="status"
            checked={data.status ? true : false}
            onChange={(e, v) => {
              if (!isOpenInit.isInit) {
                setIsOpen(true);
                setIsOpenInit((draft) => {
                  draft.isInit = true;
                  draft.isOpen = true;
                  draft.type = "status";
                  draft.value = v ? 1 : 0;
                });
              } else {
                onSubmit(index, "status", v ? 1 : 0);
              }
            }}
          />
        </Stack>
        {!isOpen ? (
          ""
        ) : (
          <Stack>
            <Stack sx={{ p: "8px 24px" }}>
              <Stack sx={{ py: "24px", gap: "24px" }}>
                <Stack sx={{ flexDirection: "row", gap: "10px" }}>
                  <InputControl
                    name="price"
                    label="Price"
                    value={data.price}
                    required
                    onChange={(e) => HandlePriceLevelChange(e, index)}
                    error={validator.current.message(
                      "Price",
                      data.price,
                      "required|price"
                    )}
                    helperText={validator.current.message(
                      "Price",
                      data.price,
                      "required|price"
                    )}
                  />
                  <InputControl
                    name="old_price"
                    label="Old Price"
                    value={data.old_price}
                    required
                    onChange={(e) => HandlePriceLevelChange(e, index)}
                    error={validator.current.message(
                      "Old Price",
                      data.old_price,
                      "required|price"
                    )}
                    helperText={validator.current.message(
                      "Old Price",
                      data.old_price,
                      "required|price"
                    )}
                  />
                </Stack>
                <Stack sx={{ flexDirection: "row", gap: "10px" }}>
                  <InputControl
                    name="cost_exclusive_gst"
                    label="Cost Ex GST"
                    value={data?.cost_exclusive_gst}
                    required
                    onChange={(e) => HandlePriceLevelChange(e, index)}
                    error={validator.current.message(
                      "Cost Ex GST",
                      data.cost_exclusive_gst,
                      "required|cost"
                    )}
                    helperText={validator.current.message(
                      "Cost Ex GST",
                      data.cost_exclusive_gst,
                      "required|cost"
                    )}
                  />
                  <InputControl
                    name="cost"
                    label="Cost In GST"
                    value={data?.cost}
                    required
                    onChange={(e) => HandlePriceLevelChange(e, index)}
                    error={validator.current.message(
                      "Cost",
                      data.cost,
                      "required|cost"
                    )}
                    helperText={validator.current.message(
                      "Cost",
                      data.cost,
                      "required|cost"
                    )}
                  />
                </Stack>
              </Stack>
              <Divider />
              <Typography
                variant="subtitle2"
                lineHeight="16px"
                fontWeight={600}
                mt="20px"
              >
                Special price
              </Typography>
              <Stack sx={{ py: "24px", flexDirection: "row", gap: "10px" }}>
                <InputControl
                  name="special_price"
                  label="Special Price"
                  value={data.special_price}
                  onChange={(e) => HandlePriceLevelChange(e, index)}
                  error={
                    data.special_price &&
                    validator.current.message(
                      "Special price",
                      data.special_price,
                      "price"
                    )
                  }
                  helperText={
                    data.special_price &&
                    validator.current.message(
                      "Special price",
                      data.special_price,
                      "price"
                    )
                  }
                />
                {data.special_price ? (
                  <>
                    <InputControl
                      type="datePicker"
                      name="special_price_start_date"
                      label="Start Date"
                      disablePast={true}
                      inputFormat="dd/MM/yyyy"
                      sx={{ width: "100%" }}
                      value={data.special_price_start_date}
                      onChange={(e, v) =>
                        HandlePriceLevelChange(
                          {
                            target: {
                              name: "special_price_start_date",
                              value: e,
                            },
                          },
                          index,
                          "date"
                        )
                      }
                      onError={(reason, value) =>
                        validateDate(reason, "start_date", "date")
                      }
                      error={
                        state.validation.isValidated &&
                        (state.validation.start_date ?? false)
                      }
                      helperText={
                        state.validation.isValidated &&
                        (state.validation.start_date ?? false)
                      }
                    />
                    <InputControl
                      type="datePicker"
                      name="special_price_end_date"
                      label="End Date"
                      disablePast={true}
                      inputFormat="dd/MM/yyyy"
                      sx={{ width: "100%" }}
                      value={data.special_price_end_date}
                      onError={(reason, value) =>
                        validateDate(reason, "end_date", "date")
                      }
                      onChange={(e) =>
                        HandlePriceLevelChange(
                          {
                            target: {
                              name: "special_price_end_date",
                              value: e,
                            },
                          },
                          index,
                          "date"
                        )
                      }
                      error={
                        state.validation.isValidated &&
                        (state.validation.end_date
                          ? state.validation.end_date
                          : data.special_price_start_date &&
                            validator.current.message(
                              "End Date",
                              data.special_price_start_date &&
                                !data.special_price_end_date
                                ? null
                                : {
                                    startTime: data.special_price_start_date,
                                    endTime: data.special_price_end_date,
                                  },
                              "required|endDate"
                            ))
                      }
                      helperText={
                        state.validation.isValidated &&
                        (state.validation.end_date
                          ? state.validation.end_date
                          : data.special_price_start_date &&
                            validator.current.message(
                              "End Date",
                              data.special_price_start_date &&
                                !data.special_price_end_date
                                ? null
                                : {
                                    startTime: data.special_price_start_date,
                                    endTime: data.special_price_end_date,
                                  },
                              "required|endDate"
                            ))
                      }
                    />
                  </>
                ) : (
                  <>
                    <Stack width="100%"></Stack>
                    <Stack width="100%"></Stack>
                  </>
                )}
              </Stack>
            </Stack>
            {data.variants.length > 0 ? (
              <>
                <Box sx={{ p: "32px 24px", backgroundColor: "#F7F7F7" }}>
                  <Typography
                    variant="subtitle1"
                    lineHeight="16px"
                    fontWeight={600}
                    sx={{ width: "100%" }}
                  >
                    Variants
                  </Typography>
                </Box>
                <Stack sx={{ p: "8px 24px" }}>
                  {data?.variants?.map((data, vi) => (
                    <>
                      <Divider sx={{ display: vi === 0 && "none" }} />
                      <Stack
                        sx={{
                          py: "24px",
                          flexDirection: "row",
                          gap: "10px",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          lineHeight="16px"
                          fontWeight={400}
                          sx={{ maxWidth: "288px", width: "100%" }}
                        >
                          {data.name}
                        </Typography>
                        <Stack width="100%" gap="24px">
                          <InputControl
                            name="price_adjustment"
                            label="Price Adjustment"
                            value={data.price_adjustment}
                            required
                            onChange={(e) =>
                              HandlePriceLevelChange(e, index, "variant", vi)
                            }
                            error={validator.current.message(
                              `Price Adjustment of ${data.name}`,
                              data.price_adjustment,
                              "required|price"
                            )}
                            helperText={validator.current.message(
                              `Price Adjustment of ${data.name} `,
                              data.price_adjustment,
                              "required|price"
                            )}
                          />
                          <Stack direction="row" gap="20px">
                            <InputControl
                              name="cost_adjustment_exclusive_gst"
                              label="Cost Adjustment Ex GST "
                              value={data.cost_adjustment_exclusive_gst}
                              required
                              onChange={(e) =>
                                HandlePriceLevelChange(e, index, "variant", vi)
                              }
                              error={validator.current.message(
                                `Cost Adjustment Ex GST ${` `.repeat(vi)}`,
                                data.cost_adjustment_exclusive_gst,
                                "required|cost"
                              )}
                              helperText={validator.current.message(
                                `Cost Adjustment Ex GST ${` `.repeat(vi)}`,
                                data.cost_adjustment_exclusive_gst,
                                "required|cost"
                              )}
                            />
                            <InputControl
                              name="cost_adjustment"
                              label="Cost Adjustment In GST "
                              value={data.cost_adjustment}
                              required
                              onChange={(e) =>
                                HandlePriceLevelChange(e, index, "variant", vi)
                              }
                              error={validator.current.message(
                                `Cost Adjustment In GST ${` `.repeat(vi)}`,
                                data.cost_adjustment,
                                "required|cost"
                              )}
                              helperText={validator.current.message(
                                `Cost Adjustment In GST ${` `.repeat(vi)}`,
                                data.cost_adjustment,
                                "required|cost"
                              )}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                    </>
                  ))}
                </Stack>
              </>
            ) : (
              ""
            )}
            <Divider />
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              gap="16px"
              p="21.5px 24px"
            >
              <Button
                variant="contained"
                type="button"
                onClick={() => onSubmit()}
                size="medium"
                disabled={isSaveButton}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        )}
      </Card>
    </>
  );
};
