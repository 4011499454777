import { ContentLayout, HeaderBreadcrumbs, PageNotFound } from "../../../shared";
import * as React from "react";
import { Button, Stack, Typography, Card, Grid, Switch } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";
import { useParams } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { useDiscount } from "../hooks";
import SimpleReactValidator from "simple-react-validator";


export const CreateOrEditDiscount = () => {
  const { id } = useParams();
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const {
    state,
    onSwitchChange,
    onChange,
    isCreateVisible,
    isEditable,
    onSaveState,
    onDiscountMode,
    onUpdateDiscount,
  } = useDiscount({ isDiscount: false, discountId: id });
  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdateDiscount(id) : onSaveState();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }
  return (
    <>
      <ContentLayout
        title={id ? state.display_name : "New Discount"}
        breadcrumbs={
          id ? (
            <>
              <HeaderBreadcrumbs
                links={[
                  { name: "Dashboard", href: "/" },
                  { name: "Discount", href: "/discount" },
                  { name: state.display_name, href: `/discount/details/${id}` },
                  { name: "Edit" },
                ]}
              />
            </>
          ) : (
            <>
              <HeaderBreadcrumbs
                links={[
                  { name: "Dashboard", href: "/" },
                  { name: "Discount", href: "/discount" },
                  { name: "New Discount" },
                ]}
              />
            </>
          )
        }
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              disabled={state.isSaveButtonBusy}
              onClick={() => onSubmit()}
            >
              {id ? "Update" : "Save"}
            </Button>
          </>
        }
      >
        <Card sx={{ p: "32px 24px", mb: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Details
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack width={"100%"} gap="20px">
                <InputControl
                  name="display_name"
                  label="Discount Name"
                  value={state.data.display_name}
                  onChange={(e) => onChange(e)}
                  error={validator.current.message(
                    "Discount Name",
                    state.data.display_name,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "Discount Name",
                    state.data.display_name,
                    "required"
                  )}
                  required
                />
                <Stack direction="row" gap="12px" alignItems="center">
                  {state.data.discount_mode === "percentage" ? (
                    <InputControl
                      name="amount"
                      label="Discount"
                      value={state.data.amount}
                      onChange={(e) => onChange(e)}
                      error={validator.current.message(
                        "Discount",
                        state.data.amount,
                        "required|numeric|max:100,num"
                      )}
                      helperText={validator.current.message(
                        "Discount",
                        state.data.amount,
                        "required|numeric|max:100,num"
                      )}
                      required
                    />
                  ) : (
                    <InputControl
                      name="amount"
                      label="Discount"
                      value={state.data.amount}
                      onChange={(e) => onChange(e)}
                      error={validator.current.message(
                        "Discount",
                        state.data.amount,
                        "required|numeric"
                      )}
                      helperText={validator.current.message(
                        "Discount",
                        state.data.amount,
                        "required|numeric"
                      )}
                      required
                    />
                  )}
                  <Button
                    sx={{
                      minWidth: "44px",
                      height: "44px",
                      p: "8px",
                    }}
                    variant="contained"
                    name={state.data.discount_mode}
                    color={
                      state.data.discount_mode === "percentage"
                        ? "primary"
                        : "gray2"
                    }
                    onClick={(e) => onDiscountMode("percentage")}
                  >
                    <PercentIcon />
                  </Button>
                  <Button
                    sx={{
                      minWidth: "44px",
                      height: "44px",
                      p: "8px",
                    }}
                    name={state.data.discount_mode}
                    variant="contained"
                    color={
                      state.data.discount_mode === "fixed" ? "primary" : "gray2"
                    }
                    onClick={(e) => onDiscountMode("fixed")}
                  >
                    <AttachMoneyIcon />
                  </Button>
                </Stack>

                <Stack direction="row" justifyContent="space-between" py="10px">
                  <Stack gap="4px">
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      lineHeight="19px"
                    >
                      Status
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight={400}
                      color="#636363"
                    >
                      lock the Discount
                    </Typography>
                  </Stack>

                  <Switch
                    checked={+state.data.require_manager_passcode}
                    onChange={(loading) => onSwitchChange(loading)}
                    name={state.data.require_manager_passcode}
                    color="primary"
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </ContentLayout>
    </>
  );
};
