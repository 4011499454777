import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthProtectedRoute, NavBar } from "../../shared";
import { ChangePassword } from "../Profile";
import { ForgotPassword, Login, ResetPassword } from "./containers";

export const Authentication = () => {
  return (
    <>
      <NavBar />
      <Routes>
        <Route element={<AuthProtectedRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
        </Route>
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/ChangePassword" element={<ChangePassword />} />
      </Routes>
    </>
  );
};
