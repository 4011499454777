import {
  ContentLayout,
  ServerPaginatedTable,
  useDropdownApis,
} from "../../../shared";
import React, { useState } from "react";
import {
  Button,
  createFilterOptions,
  TextField,
  Checkbox,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { arraySort } from "../../../utils";
import { DashBoardDateRangePicker, useRefundList } from "../..";

export const SuperRefundList = () => {
  const {
    state,
    globalData,
    handleFilter,
    handleApplyFilter,
    handleFilterClear,
    onChangeFilter,
    gotoPage,
    setPageSize,
    handleDownloadRefundList,
    handleSort,
    onChangeDate,
    handleIsOpen,
    handleDateChangeApply,
    filterCollections,
    toggleHiddenColumns,
    columnOptions,
  } = useRefundList({ isRefund: true });
  const [hasColumnOptions, setHasColumnOptions] = useState(false);
  const toggleColumns = () => {
    setHasColumnOptions(!hasColumnOptions);
  };

  const {
    state: { stores },
  } = useDropdownApis({
    isStoreList: true,
  });
  const storeOptions = stores.map((v) => {
    return { value: v.id, label: v.name };
  });

  const ActionButtons = ({ id }) => {
    return (
      <div className="action-buttons">
        <Link to={`/refund/details/${id}`}>
          <img alt="details" src="/icons/arrow_forward.svg" />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Refund Number",
        accessor: "refund_number",
      },
      {
        Header: "Order Number",
        accessor: "order_number",
        Cell: ({ row }) => {
          return <div>{row?.original.order?.order_number ?? "N/A"}</div>;
        },
      },
      {
        Header: "Refund Date",
        accessor: "refund_date",
      },
      {
        Header: "Provider",
        accessor: "channel_name",
        Cell: ({ value, row }) => {
          return (
            <div>
              {value}
              {row.original.user_name && ` - ${row.original.user_name}`}
            </div>
          );
        },
      },
      {
        Header: "Refund Total",
        accessor: "refunded_total",
        Cell: ({ value }) => {
          return <div>{value?.toFixed(2) || 0}</div>;
        },
      },
      {
        Header: "Customer Name",
        id: "customer_first_name",
        accessor: (row) => {
          return (
            <>
              {row.order !== null ? (
                <div>
                  {row?.order?.customer_first_name !== undefined
                    ? row?.order?.customer_first_name
                    : ""}{" "}
                  {row?.order?.customer_last_name !== undefined
                    ? row?.order?.customer_last_name
                    : ""}
                </div>
              ) : (
                `${row.customer_first_name ?? ""} ${row.customer_last_name ?? ""
                }`
              )}
            </>
          );
        },
      },
      {
        Header: "Order Date",
        id: "order_date",
        accessor: (row) => {
          return <div>{row?.order?.order_date ?? "N/A"}</div>;
        },
      },
      {
        Header: "Order Total",
        id: "order_grand_total",
        accessor: (row) => {
          return <div>{row?.order?.grand_total?.toFixed(2) ?? "N/A"}</div>;
        },
      },
      {
        Header: "Store",
        accessor: "store_name",
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons id={row.id} />;
        },
      },
    ],
    []
  );

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });
  return (
    <>
      <ContentLayout
        title="Refunds"
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="text"
              onClick={handleDownloadRefundList}
            >
              <img
                alt="export"
                src="/icons/ic_export.svg"
                style={{
                  marginRight: "4px",
                }}
              />
              Export
            </Button>
            <Button
              type="button"
              size="medium"
              variant="text"
              onClick={() => toggleColumns()}
            >
              <img
                alt="tools"
                src="/icons/ic_Tools.svg"
                style={{
                  marginRight: "4px",
                }}
              />
              Column Option
            </Button>
          </>
        }
      >
        <ServerPaginatedTable
          columns={columns}
          data={state.refund}
          isLoading={state?.isBusy}
          drawerOpen={hasColumnOptions}
          defaultSearch={false}
          key={state.sortBy}
          filterApplied={filterCollections}
          columnOptions={columnOptions}
          drawerOnClose={() => toggleColumns()}
          onFilterClear={(e) => handleFilterClear("single", e)}
          onAllFilterClear={(e) => handleFilterClear("all")}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
          pagination={state.pagination}
          handleSort={handleSort}
          sortByGlobal={state.sortBy}
          toggleHiddenManual={toggleHiddenColumns}
          filterContent={
            <InputControl
              type="table-search"
              value={state.filterApplied.searchText}
              onChange={(e) => onChangeFilter(e.target.value)}
              onClear={() => onChangeFilter("")}
            />
          }
          filterSection={
            <>
              <Stack direction="row" gap="16px" alignItems="center">
                <DashBoardDateRangePicker
                  onChangeDate={onChangeDate}
                  isList={true}
                  range={state.date}
                  showRange={state.showDate}
                  isOpen={state.isCalenderOpen}
                  handleIsOpen={handleIsOpen}
                  handleDateChangeApply={handleDateChangeApply}
                  position="left"
                />
                <InputControl
                  type="dropdown"
                  name="store"
                  label="Store"
                  multiple={true}
                  disableClearable={true}
                  options={arraySort(storeOptions || [], "label")}
                  value={state?.filterApplied?.stores}
                  onChange={(e, v) => handleFilter(e, v, "store")}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Store" placeholder="Store" />
                  )}
                />

                <InputControl
                  type="dropdown"
                  disableClearable
                  options={globalData?.sale_channels ?? []}
                  id="disable-close-on-select"
                  disableCloseOnSelect
                  multiple={true}
                  filterOptions={filterOptions}
                  value={state.filterApplied?.channel}
                  onChange={(e, v) => handleFilter(e, v, "channel")}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sales Channel"
                      placeholder="Sales Channel"
                    />
                  )}
                />
              </Stack>
              <Button
                type="button"
                size="medium"
                variant="text"
                color="info"
                disabled={state?.disableApplyButton}
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => handleApplyFilter()}
              >
                Apply
              </Button>
            </>
          }
          onRowLink={(e) => {
            return `/refund/details/${e.id}`;
          }}
        />
      </ContentLayout>
    </>
  );
};
