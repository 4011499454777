import { Box } from "@material-ui/core";
import { Fade, Popper, Typography } from "@mui/material";
import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { useImmer } from "use-immer";
import { useEffect } from "react";
import { useRef } from "react";
import { presetColors } from "../../../utils";

export const TextView = ({ product, onChangeColor }) => {
  const ref = useRef(null);
  const refContainer = useRef(null);
  const textRef = useRef(null);
  const textRefcontainer = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [textOpen, setTextOpen] = useState(false);
  const [anchorElText, setAnchorElText] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        refContainer.current &&
        !refContainer.current.contains(event.target) &&
        !ref.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, refContainer]);

  const handleClickText = (event) => {
    setAnchorElText(event.currentTarget);
    setTextOpen((previousOpen) => !previousOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        textRef.current &&
        textRefcontainer.current &&
        !textRefcontainer.current.contains(event.target) &&
        !textRef.current.contains(event.target)
      ) {
        setTextOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [textRef, textRefcontainer]);

  return (
    <Box
      sx={{
        backgroundColor: product.grid_color ?? product.colour,
        color: product.font_color ?? "#FFFFFF",
        width: "100%",
        height: "100%",
        display: "flex",
        padding: "8px",
        alignItems: "end",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", gap: "5px" }}>
        <Box
          sx={{
            cursor: "pointer",
            height: "28px",
            width: "28px",
            backgroundColor: "#FFFFFF",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#808080",
            },
          }}
          onClick={handleClickText}
          ref={textRefcontainer}
        >
          <Box
            component="img"
            src="/icons/ic_text_color.svg"
            width="18px"
            height="18px"
          />
        </Box>
        <Popper ref={textRef} open={textOpen} anchorEl={anchorElText}>
          <SketchPicker
            color={product.font_color ?? "#FFFFFF"}
            onChange={(e) => onChangeColor(e.hex, product.id, "font_color")}
            presetColors={presetColors}
            disableAlpha
          />
        </Popper>
        <Box
          sx={{
            cursor: "pointer",
            height: "28px",
            width: "28px",
            backgroundColor: "#FFFFFF",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#808080",
            },
          }}
          ref={refContainer}
          onClick={handleClick}
        >
          <Box
            component="img"
            src="/icons/paint_palette.svg"
            width="18px"
            height="18px"
          />
        </Box>
        <Popper ref={ref} open={open} anchorEl={anchorEl}>
          <SketchPicker
            color={product.grid_color ?? product.colour}
            onChange={(e) => {
              onChangeColor(e.hex, product.id, "grid_color");
            }}
            presetColors={presetColors}
            disableAlpha
          />
        </Popper>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
          {product.alias ?? product.name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Typography sx={{ fontSize: "14px", fontWeight: 500 }} mt={1}>
            ${product.price?.toFixed(2)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
