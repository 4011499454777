import * as React from "react";
import { Stack, Card, Typography } from "@mui/material";
import { useStoreSaleChannels } from "../hooks";
import { Loader } from "../../../shared";
import { SalesListComponent } from ".";

export const SalesChannels = ({ storeName }) => {
  const { state, onSalesChannelChange } = useStoreSaleChannels({
    isPriceLevel: true,
    storeName,
  });

  if (state.isBusy) {
    return <Loader />;
  }
  return (
    <>
      <Card sx={{ mb: "24px" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="83px"
          p="32px 24px"
        >
          <Typography
            variant="subtitle1"
            fontWeight={600}
            lineHeight="19px"
            minWidth="160px"
          >
            Sales Channels
          </Typography>
        </Stack>
        {state.data.map((item, index) => {
          return (
            <SalesListComponent
              state={state}
              onSalesChannelChange={onSalesChannelChange}
              item={item}
              index={index}
            />
          );
        })}
      </Card>
    </>
  );
};
