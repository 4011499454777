import React from "react";
import { Route, Routes } from "react-router-dom";
import { WastageDetails, WastageList } from "./containers";
import { ProtectedRoute } from "../../shared";
import { useWastageList } from "./hooks";

export const Wastage = () => {
  return (
    <Routes>
      <Route path="/" element={<WastageList />} />
      <Route element={<ProtectedRoute allowedRoute={["wastage-view"]} />}>
        <Route path="/details/:id" element={<WastageDetails />} />
      </Route>
    </Routes>
  );
};
