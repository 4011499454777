import {
  StoreFavouritesDetailsList,
  SuperFavouritesDetailsList,
} from "../components";

export const FavouritesDetailsList = ({ isStoreAdmin }) => {
  //   return isStoreAdmin ? (
  //     <StoreFavouritesDetailsList />
  //   ) : (
  //     <SuperFavouritesDetailsList />
  //   );
  return <SuperFavouritesDetailsList isStoreAdmin={isStoreAdmin} />;
};
