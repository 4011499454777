import { API } from "../../../utils";

export const getRefund = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `refunds?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};
export const getRefundById = async (id) => {
  const res = await API.get(`refunds/${id}`);
  return res.data;
};

export const DownloadRefundList = async (query) => {
  const res = await API.get(`export/refunds`, {
    ...query,
    responseType: "blob",
  });
  return res;
};
