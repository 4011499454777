import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import { BundlesList, CreateOrEditBundles, BundlesDetails } from "./containers";

export const Bundles = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<BundlesList />} />
                <Route
                    element={<ProtectedRoute allowedRoute={["bundle-group-modify"]} />}
                >
                    <Route path="/edit/:id" element={<CreateOrEditBundles />} />
                </Route>
                <Route
                    element={<ProtectedRoute allowedRoute={["bundle-group-create"]} />}
                >
                    <Route path="/create" element={<CreateOrEditBundles />} />
                </Route>
                <Route
                    element={<ProtectedRoute allowedRoute={["bundle-group-modify"]} />}
                >
                    <Route path="/details/:id" element={<BundlesDetails />} />
                </Route>
            </Routes>
        </>
    );
};
