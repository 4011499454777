import { API } from "../../../utils";

export const getBirthdayCouponsList = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `birthday-coupons?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};

export const PlayOrPauseAllBirthdayCoupon = async (id) => {
  const res = await API.post(`birthday-coupons/${id}/change-status`);
  return res.data;
};

export const getBirthdayCouponsDetailsById = async (id) => {
  const res = await API.get(`birthday-coupons/${id}`);
  return res.data;
};

export const updateBirthdayCoupons = async (payload) => {
  const res = await API.post(`birthday-coupons/1`, payload);
  return res.data;
};

export const getAllConditionList = async () => {
  const res = await API.get("rule-sets ");
  return res.data;
};
