import * as React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  Stack,
  Box,
  Radio,
} from "@mui/material";
import { Loader } from "../../../shared";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";

export const SoldOutDialog = (props) => {
  const {
    state,
    values,
    onSubmitAction,
    handleChange,
    handleInputDateAndTime,
    validateDate,
    validateTime,
    setState,
  } = props;

  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const onSubmit = () => {
    if (values.back_in_stock_type === "sold_out_until") {
      if (
        validator.current.allValid() &&
        !state.validation.date &&
        !state.validation.time
      ) {
        onSubmitAction();
      } else {
        validator.current.showMessages();
        forceUpdate(1);
        setState((draft) => {
          draft.validation.isValidated = true;
        });
      }
    } else {
      onSubmitAction();
    }
  };
  const Items = [
    {
      name: "Today Only",
      value: "today",
    },
    {
      name: "1 hour",
      value: "one_hour",
    },
    {
      name: "Indefinitely",
      value: "indefinitely",
    },
    {
      name: "Sold Out Until -- --",
      value: "sold_out_until",
    },
  ];

  return (
    <Stack
      justifyContent="space-between"
      height="100%"
      gap="8px"
      maxHeight={"calc(100% - 24px)"}
    >
      <Box sx={{ height: "100%", overflow: "auto" }} fullWidth>
        {false ? (
          <Loader />
        ) : Items.length > 0 ? (
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {Items.map((value) => {
              const labelId = `checkbox-list-label-${value.value}`;

              return (
                <ListItem key={value.value} disablePadding>
                  <ListItemButton
                    role={undefined}
                    // onClick={handleToggle(value)}
                    dense
                    sx={{
                      minHeight: "48px",
                      px: "8px",
                      flexWrap: "wrap",
                      "&:hover": {
                        backgroundColor: "rgba(21, 96, 212, 0.08)",
                        borderRadius: "8px",
                      },
                      border:
                        values.back_in_stock_type === "sold_out_until" &&
                        value.value === "sold_out_until"
                          ? `1px solid #E6E6E6`
                          : "",
                      borderRadius: "8px",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "32px",
                      }}
                    >
                      <Radio
                        checked={values.back_in_stock_type === value.value}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={value.value}
                        name="radio-buttons"
                        sx={{
                          p: "3.5px",
                        }}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        ml: "8px",
                      }}
                      id={labelId}
                      primary={value.name}
                    />
                    {values.back_in_stock_type === "sold_out_until" &&
                    value.value === "sold_out_until" ? (
                      <>
                        <Stack
                          direction="row"
                          alignItems="center"
                          width="100%"
                          height="66px"
                          gap="8px"
                          mt="10px"
                        >
                          <InputControl
                            type="datePicker"
                            name="Set Date"
                            label="Set Date"
                            inputFormat="dd-MM-yyyy"
                            disablePast={true}
                            sx={{
                              width: "100%",
                              bgcolor: "#fff",
                            }}
                            value={
                              values.date
                                ? moment(
                                    values.date,
                                    "DD-MM-YYYY HH:mm:ss"
                                  ).format("YYYY-MM-DD")
                                : null
                            }
                            onChange={(e) => handleInputDateAndTime(e, "date")}
                            onError={(reason, value) =>
                              validateDate(reason, "date")
                            }
                            error={
                              state.validation.date
                                ? state.validation.isValidated &&
                                  state.validation.date
                                : validator.current.message(
                                    "date",
                                    values.date,
                                    "required"
                                  )
                            }
                            helperText={
                              state.validation.date
                                ? state.validation.isValidated &&
                                  state.validation.date
                                : validator.current.message(
                                    "date",
                                    values.date,
                                    "required"
                                  )
                            }
                          />

                          <InputControl
                            ampm={false}
                            type="timePicker"
                            name="Set Time"
                            label="Set Time"
                            value={moment(values.time, "DD-MM-YYYY HH:mm:ss")}
                            minTime={
                              moment(new Date()).format("YYYY-MM-DD") ===
                              moment(values.date).format("YYYY-MM-DD")
                                ? new Date()
                                : false
                            }
                            sx={{
                              bgcolor: "#fff",
                            }}
                            onChange={(e) => handleInputDateAndTime(e, "time")}
                            onError={(reason, value) =>
                              validateTime(reason, "time")
                            }
                            error={
                              state.validation.time
                                ? state.validation.isValidated &&
                                  state.validation.time
                                : validator.current.message(
                                    "time",
                                    values.time,
                                    "required"
                                  )
                            }
                            helperText={
                              state.validation.time
                                ? state.validation.isValidated &&
                                  state.validation.time
                                : validator.current.message(
                                    "time",
                                    values.time,
                                    "required"
                                  )
                            }
                          />
                        </Stack>
                      </>
                    ) : (
                      ""
                    )}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Stack justifyContent="center" alignItems="center" height="100%">
            <Typography
              sx={{ display: "inline" }}
              component="span"
              variant="caption"
              color="text.secondary"
              fontWeight={400}
            >
              There is no data found
            </Typography>
          </Stack>
        )}
      </Box>
      <Button
        sx={{ minHeight: "48px" }}
        variant="contained"
        fullWidth
        onClick={onSubmit}
        disabled={values.isSaveButton}
      >
        ADD
      </Button>
    </Stack>
  );
};
