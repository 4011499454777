import { API } from "../../../utils";

export const getProducts = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `products?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data;
};
export const getProductDetailsById = async (id) => {
  const res = await API.get(`products/${id}`);
  return res.data;
};
export const getProductDetailsInfo = async (id) => {
  const res = await API.get(`products/${id}/detail-info`);
  return res.data;
};

export const updateProductDetailsInfo = async (payload, id) => {
  const res = await API.put(`products/${id}/detail-info`, payload);
  return res.data;
};
export const getProductAvailability = async (id) => {
  const res = await API.get(`products/${id}/availablility`);
  return res.data;
};

export const updateAvailablilityDate = async (payload, id) => {
  const res = await API.put(`products/${id}/availablility`, payload);
  return res.data;
};

export const updateProductPreOrder = async (id) => {
  const res = await API.get(`products/${id}/pre-order`);
  return res.data;
};
export const updateProductStatus = async (id) => {
  const res = await API.get(`products/${id}/status`);
  return res.data;
};

export const updateProductVisibility = async (id) => {
  const res = await API.get(`products/${id}/toggle-visibility`);
  return res.data;
};

export const getProductAvailabilityGroup = async (id) => {
  const res = await API.get(`products/${id}/availability-groups`);
  return res.data;
};

export const getAllAvailabilityGroup = async () => {
  const res = await API.get(`availability-groups`);
  return res.data;
};

export const assignAGToProduct = async (payload, id) => {
  const res = await API.put(`products/${id}/availability-groups`, payload);
  return res.data;
};

export const deleteAGFromProduct = async (ProductId, AGId) => {
  const res = await API.delete(
    `products/${ProductId}/availability-groups/${AGId}`
  );
  return res.data;
};

export const getProductOptionalGroup = async (id) => {
  const res = await API.get(`products/${id}/optional-groups`);
  return res.data;
};

export const getAllOptionalGroup = async () => {
  const res = await API.get(`optional-groups`);
  return res.data;
};

export const assignOGToProduct = async (payload, id) => {
  const res = await API.put(`products/${id}/optional-groups`, payload);
  return res.data;
};

export const deleteOGFromProduct = async (ProductId, OGId) => {
  const res = await API.delete(`products/${ProductId}/optional-groups/${OGId}`);
  return res.data;
};

export const getProductStores = async (id) => {
  const res = await API.get(`products/${id}/stores`);
  return res.data;
};

export const getAllStores = async () => {
  const res = await API.get(`stores`);
  return res.data;
};

export const assignStoresToProduct = async (payload, id) => {
  const res = await API.put(`products/${id}/stores`, payload);
  return res.data;
};

export const deleteStoreFromProduct = async (ProductId, StoresId) => {
  const res = await API.delete(`products/${ProductId}/stores/${StoresId}`);
  return res.data;
};
export const createProduct = async (payload) => {
  const res = await API.post(`products`, payload);
  return res.data;
};

export const copyProduct = async (payload, id) => {
  const res = await API.post(`products/${id}/duplicate`, payload);
  return res.data;
};

export const updateProduct = async (id, payload) => {
  const res = await API.post(`products/${id}`, payload);
  return res.data;
};

export const deleteProductById = async (id) => {
  const res = await API.delete(`products/${id}`);
  return res.data;
};

export const getProductBundleGroup = async (id) => {
  const res = await API.get(`products/${id}/bundle-groups`);
  return res.data;
};

export const assignBundleToProduct = async (payload, id) => {
  const res = await API.put(`products/${id}/bundle-groups`, payload);
  return res.data;
};

export const deleteBundleFromProduct = async (id, bundleId) => {
  const res = await API.delete(`products/${id}/bundle-groups/${bundleId}`);
  return res.data;
};

//Store ADmin
export const getProductsSA = async (storeID, query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `stores/${storeID}/store-admin-products?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};
export const updateStockAlert = async (storeID, id, payload) => {
  const res = await API.put(
    `stores/${storeID}/store-admin-products/${id}/stock_alert`,
    payload
  );
  return res.data;
};

export const updateClearStock = async (storeID, id, payload) => {
  const res = await API.put(
    `stores/${storeID}/store-admin-products/${id}/auto_clear_stock`,
    payload
  );
  return res.data;
};

export const updateStockQuantity = async (storeID, id, payload) => {
  const res = await API.put(
    `stores/${storeID}/store-admin-products/${id}/stock_update`,
    payload
  );
  return res.data;
};

export const updateStockSoldoutStatus = async (storeID, id, payload) => {
  const res = await API.put(
    `stores/${storeID}/store-admin-products/${id}/stock_soldout_status`,
    payload
  );
  return res.data;
};

export const getSAProductDetailsById = async (storeID, id) => {
  const res = await API.get(`stores/${storeID}/store-admin-products/${id}`);
  return res.data;
};

//////

export const getPriceLevelsList = async () => {
  const res = await API.get(`price-levels`);
  return res.data;
};

export const updateVariant = async (productID, id, payload) => {
  const res = await API.put(`products/${productID}/variants/${id}`, payload);
  return res.data;
};

export const createVariant = async (productID, payload) => {
  const res = await API.post(`products/${productID}/variants`, payload);
  return res.data;
};

export const updateDefaultVariant = async (id) => {
  const res = await API.put(`products/${id}/default`);
  return res.data;
};

export const getProductPriceLevel = async (id) => {
  const res = await API.get(`products/${id}/price-levels`);
  return res.data;
};

export const updateProductPriceLevel = async (id, payload) => {
  const res = await API.put(`products/${id}/price-levels`, payload);
  return res.data;
};

export const getProductBarcode = async (id) => {
  const res = await API.get(`products/${id}/product_barcodes`);
  return res.data;
};
export const deleteBarcode = async (ProductId, id) => {
  const res = await API.delete(`products/${ProductId}/product_barcodes/${id}`);
  return res.data;
};
export const createBarcode = async (id, payload) => {
  const res = await API.post(`products/${id}/product_barcodes`, payload);
  return res.data;
};
export const editBarcode = async (productid, id, payload) => {
  const res = await API.put(
    `products/${productid}/product_barcodes/${id}`,
    payload
  );
  return res.data;
};

export const getProductCrossSelling = async (id) => {
  const res = await API.get(`products/${id}/cross-selling-products`);
  return res.data;
};

export const deleteCrossSellingProduct = async (ProductId, CSId) => {
  const res = await API.delete(
    `products/${ProductId}/cross-selling-products/${CSId}`
  );
  return res.data;
};

export const changeStatusCrossSellingProduct = async (id) => {
  const res = await API.put(
    `products/${id}/cross-selling-products-status-change`
  );
  return res.data;
};

export const assignProductToCrossSelling = async (payload, id) => {
  const res = await API.post(`products/${id}/cross-selling-products`, payload);
  return res.data;
};

export const getProductSimilarProducts = async (id) => {
  const res = await API.get(`products/${id}/similar-products`);
  return res.data;
};

export const deleteCrossSimilarProducts = async (ProductId, SPId) => {
  const res = await API.delete(
    `products/${ProductId}/similar-products/${SPId}`
  );
  return res.data;
};

export const assignProductToSimilarProducts = async (payload, id) => {
  const res = await API.post(`products/${id}/similar-products`, payload);
  return res.data;
};

export const changeStatusSimilarProducts = async (id) => {
  const res = await API.put(`products/${id}/similar-products-status-change`);
  return res.data;
};

export const getProductTagsList = async () => {
  const res = await API.get("tags?type=product");
  return res.data;
};

export const getBumpScreenTagsList = async () => {
  const res = await API.get("tags?type=bump_screen");
  return res.data;
};

export const getMarketingTagsList = async () => {
  const res = await API.get("tags?type=marketing");
  return res.data;
};

export const getProductSeo = async (id) => {
  const res = await API.get(`products/${id}/seo`);
  return res.data;
};
export const updateProductSeo = async (id, payload) => {
  const res = await API.put(`products/${id}/seo`, payload);
  return res.data;
};

// Simple accounts listing API
export const getAccountsApi = async () => {
  const response = await API.get("lists/simple-accounts");
  return response.data;
};
