import { API } from "../../../utils";

export const getFavList = async (query) => {
  const res = await API.get("favourites", query);
  return res.data;
};

export const deleteFavById = async (id) => {
  const res = await API.delete(`favourites/${id}`);
  return res.data;
};

export const createFavGroup = async (payload) => {
  const res = await API.post(`favourites`, payload);
  return res.data;
};

export const updateFavGroup = async (id, payload) => {
  const res = await API.put(`favourites/${id}`, payload);
  return res.data;
};

export const getFavGroupById = async (id) => {
  const res = await API.get(`favourites/${id}`);
  return res.data;
};

export const getFavProductsById = async (id) => {
  const res = await API.get(`favourites/${id}/products`);
  return res.data;
};

export const assignProductsToFav = async (id, paload) => {
  const res = await API.post(`favourites/${id}/products`, paload);
  return res.data;
};

export const deleteFavPrdById = async (favId, prdId) => {
  const res = await API.delete(`favourites/${favId}/products/${prdId}`);
  return res.data;
};
// //////

export const getFavStoresById = async (id) => {
  const res = await API.get(`favourites/${id}/stores`);
  return res.data;
};

export const assignStoresToFav = async (id, paload) => {
  const res = await API.post(`favourites/${id}/stores`, paload);
  return res.data;
};

export const deleteFavStoresById = async (favId, storeId) => {
  const res = await API.delete(`favourites/${favId}/stores/${storeId}`);
  return res.data;
};

export const changeProductOrder = async (favId, payload) => {
  const res = await API.post(`favourites/${favId}/products-order`, payload);
  return res.data;
};

export const changeFavGroupOrder = async (payload) => {
  const res = await API.post(`favourites/change-order`, payload);
  return res.data;
};

export const getCategorywiseProductsInFavorite = async (id) => {
  const res = await API.get(`product/list`);
  return res.data;
};
