import {
  CustomTable,
  CustomeDialog,
  DeleteModal,
  usePermission,
  Loader,
  CheckBoxListDialog,
} from "../../../shared";
import * as React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { sortItems } from "../../../utils";
import { InputCheckBoxListDialog } from "./InputCheckBoxListDialog";
import { DetailsEditDialog } from "./DetailsEditDialog";

export const ProductOptionalGroupsList = (props) => {
  const { data, HandleFilterOG, handleOGSelect, handleModal, handleAssignOGToProduct, handleDeleteOGFromProduct, globalData, handleChangeOGInput } = props
  const { dataList, isOpen, isLoading, dialogLoading, filteredList, selectedItems, searchText, isDeleteOpen, isBusy, isOpenEdit, page } = data
  const navigate = useNavigate();
  const isDeletable = usePermission("product-delete");
  const isCreate = usePermission("product-create");
  const isEdit = usePermission("product-modify");


  const ActionButtons = ({ deleteAction, editAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isEdit && (
          <img alt="edit" src="/icons/ic_edit.svg"
            onClick={editAction}
          />
        )}
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />)}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Type",
        accessor: "control_type",
        Cell: ({ value, row }) => {
          return (
            <div >
              {
                globalData?.optional_groups?.type.find((i) => i.value === value)
                  ?.label
              }
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Minimum Choice",
        accessor: "min_selection",
        disableSortBy: true,
      },
      {
        Header: "Maximum Choice",
        accessor: "max_selection",
        disableSortBy: true,
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              deleteAction={() => {
                handleModal("OG-from-product", true, row.id)
              }}
              editAction={
                () => {
                  handleModal("OG-from-product-edit", true, data.id, row)
                }
              }
            />
          );
        },
      },
    ],
    [globalData]
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CustomTable
            columns={columns}
            data={dataList}
            searchBar={false}
            tableTitle="Modifier Groups"
            headerRightContent={
              <>
                {isCreate && (
                  <Button
                    type="button"
                    size="medium"
                    startIcon={<AddIcon />}
                    onClick={() => handleModal("OG-add", true)}
                  >
                    Add
                  </Button>
                )}
              </>
            }
            onRowLink={(e) => {
              return `/optionalGroup/details/${e.id}`
            }}
          />
          <CustomeDialog
            open={isOpen}
            handleClose={() => handleModal("OG-add", false)}
            PaperProps={{
              sx: { width: "432px", height: "531px", p: "16px" },
            }}
            title="Modifier Groups"
            content={
              <InputCheckBoxListDialog
                Items={filteredList}
                onSearch={HandleFilterOG}
                loading={dialogLoading}
                page={page}
                onSelect={handleOGSelect}
                onSubmit={handleAssignOGToProduct}
                disabled={selectedItems.length === 0}
                isSelected={(id) => selectedItems.includes(id)}
                onClearSearch={() => {
                  HandleFilterOG("initial");
                }}
                value={searchText}
                selectedItems={selectedItems}
                keys={["min_selection", "max_selection", "control_type"]}
                options={globalData?.optional_groups?.type}
                inputChange={handleChangeOGInput}
                isBusy={isBusy}
              />
            }
          />
          <CustomeDialog
            open={isOpenEdit}
            handleClose={() =>
              handleModal("OG-from-product-edit", false, null, null)
            }
            PaperProps={{
              sx: {
                width: "389px",
                height: "341px",
                p: "16px",
                maxWidth: "975px",
              },
            }}
            title={selectedItems[0]?.name || ""}
            content={
              <DetailsEditDialog
                data={selectedItems[0]}
                isBusy={isBusy}
                onSubmit={handleAssignOGToProduct}
                inputChange={handleChangeOGInput}
                keys={["min_selection", "max_selection", "control_type"]}
                options={globalData?.optional_groups?.type}
              />
            }
          />
          <DeleteModal
            open={isDeleteOpen}
            handleClose={() => handleModal("OG-from-product", false, null)}
            onConfirm={handleDeleteOGFromProduct}
          />
        </>
      )}
    </>
  );
};
