import React from "react";
import { AddOrEditLayout } from "./containers";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";

export const SalesScreenLayout = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute allowedRoute={["keypad-create"]} />}>
        <Route path="/create" element={<AddOrEditLayout />} />
      </Route>
      <Route element={<ProtectedRoute allowedRoute={["keypad-modify"]} />}>
        <Route path="/edit/:id" element={<AddOrEditLayout />} />
      </Route>
    </Routes>
  );
};
