import { API } from "../../../utils";

// state APIs
export const getStateList = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `states?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data;
};

export const getStateDetailsById = async (id) => {
  const res = await API.get(`states/${id}`);
  return res.data;
};

export const createState = async (payload) => {
  const res = await API.post(`states`, payload);
  return res.data;
};

export const editState = async (id, payload) => {
  const res = await API.put(`states/${id}`, payload);
  return res.data;
};

export const deleteStateById = async (id) => {
  const res = await API.delete(`states/${id}`);
  return res.data;
};

// suburb APIs

export const getSuburbList = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `city?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data;
};

export const getSuburbDetailsById = async (id) => {
  const res = await API.get(`city/${id}`);
  return res.data;
};

export const createSuburb = async (payload) => {
  const res = await API.post(`city`, payload);
  return res.data;
};

export const editSuburb = async (id, payload) => {
  const res = await API.put(`city/${id}`, payload);
  return res.data;
};

export const deleteSuburbById = async (id) => {
  const res = await API.delete(`city/${id}`);
  return res.data;
};

export const uploadData = async (payload) => {
  const res = await API.post(`city/import`, payload);
  return res.data;
};
