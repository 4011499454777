import { Box, Button } from "@mui/material";
import React from "react";
import { CSVLink } from "react-csv";

export const ExportCsv = ({ csvHeaders, csvData, fileName }) => {
  return (
    <CSVLink
      style={{ textDecoration: "none" }}
      headers={csvHeaders}
      data={csvData}
      filename={fileName}
    >
      <Button type="button" size="medium" variant="text">
        <img
          alt="export"
          src="/icons/ic_export.svg"
          style={{
            marginRight: "4px",
          }}
        />
        Export
      </Button>
    </CSVLink>
  );
};
