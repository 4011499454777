import qs from "qs";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import {
  assignProductsToFav,
  assignStoresToFav,
  changeProductOrder,
  createFavGroup,
  deleteFavById,
  deleteFavPrdById,
  deleteFavStoresById,
  getFavGroupById,
  getFavList,
  getFavProductsById,
  getFavStoresById,
  updateFavGroup,
  changeFavGroupOrder,
  getCategorywiseProductsInFavorite,
} from "../api";
import { useDebounce } from "use-debounce";
import { errorMessage, successMessage } from "../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { usePermission } from "../../../shared";
import { useStore } from "../../Stores";

export const useFavourites = ({ isFav, favId, isProducts, isStores }) => {
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const navigate = useNavigate();
  const isEditable = usePermission("favourite-modify");
  const isDeletable = usePermission("favourite-delete");
  const isCreateVisible = usePermission("favourite-create");
  const hasModify = usePermission("favourite-modify");

  const { storeList: storeListData } = useStore({
    isStore: isStores ? true : false,
  });
  const [state, setState] = useImmer({
    isBusy: false,
    initialLoad: true,
    favList: [],
    isOpen: false,
    id: null,
    isSaveButtonBusy: false,
    isCreateOpen: false,
    isEditOpen: false,
    selectedId: null,
    httpStatusCodes: {
      details: "",
    },
    data: {
      name: "",
    },
    favDetails: {
      isBusy: false,
      data: {},
    },
    filters: {
      isSearching: false,
      all: [],
      searchText: "",
      stores: [],
    },
    productsTab: {
      isLoading: false,
      isSaveButtonBusy: false,
      isOpen: false,
      isDelete: false,
      id: null,
      productsList: [],
      allProducts: [],
      filteredProducts: [],
      selectedProducts: [],
      allCategoryProductList: [],
      searchText: null,
    },
    storesTab: {
      isLoading: false,
      isSaveButton: false,
      isOpen: false,
      isDelete: false,
      id: null,
      storesList: [],
      allstores: [],
      filteredStores: [],
      selectedStores: [],
      searchText: null,
    },
  });

  useEffect(() => {
    if (isFav) {
      getFavouriteList();
    }
  }, [isFav]);

  const [debouncedText] = useDebounce(state.filters.searchText, 1000);
  useEffect(() => {
    if (!state.initialLoad && !state.filters.isSearching) {
      const { stores } = state.filters;
      getFavouriteList(stores, debouncedText);
    }
  }, [debouncedText]);

  useEffect(() => {
    setState((draft) => {
      draft.filters.all = [...state.filters.stores].filter(
        (el) => Object.keys(el).length
      );
    });
  }, [state.filters.stores]);

  useEffect(() => {
    if (favId) {
      getFavDetailsById(favId);
    }
  }, [favId]);

  useEffect(() => {
    if (isProducts) {
      getFavouriteProducts(isProducts);
    }
  }, [isProducts]);

  useEffect(() => {
    if (isStores) {
      getFavouriteStores(isStores);
    }
  }, [isStores]);

  const HandleFilterStores = (type, text) => {
    const filteredStores = storeListData?.filter((store) => {
      return !state.storesTab.storesList.find((item) => item.id === store.id);
    });
    if (type === "initial") {
      setState((draft) => {
        draft.storesTab.filteredStores = filteredStores;
        draft.storesTab.searchText = "";
        return draft;
      });
    } else {
      setState((draft) => {
        draft.storesTab.searchText = text.toLowerCase();
        draft.storesTab.filteredStores = filteredStores.filter((val) => {
          return (
            val.name.toString().toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        });
        return draft;
      });
    }
  };

  useEffect(() => {
    HandleFilterStores("initial");
  }, [storeListData, state.storesTab.storesList]);

  const HandleFilterProducts = (type, text) => {
    const ProductList = state.productsTab.allCategoryProductList
      .map((val) => {
        let filtered = val.products.filter((data) => {
          if (!["delivery", "gift_card"].includes(data.type)) return true;
        });
        return filtered.length ? { ...val, products: filtered } : undefined;
      })
      .filter(Boolean);
    if (type === "initial") {
      setState((draft) => {
        draft.productsTab.filteredProducts = ProductList;
        return draft;
      });
    } else {
      const Search = (data, text) => {
        text = text.toLowerCase();
        return data
          .map((val, i) => {
            let filtered = val.products.filter((data) => {
              if (data.name.toString().toLowerCase().indexOf(text) > -1)
                return true;
            });
            return filtered.length ? { ...val, products: filtered } : undefined;
          })
          .filter(Boolean);
      };
      setState((draft) => {
        draft.productsTab.filteredProducts = Search(ProductList, text);
        return draft;
      });
    }
  };

  useEffect(() => {
    HandleFilterProducts("initial");
  }, [
    state.productsTab.allCategoryProductList,
    state.productsTab.productsList,
  ]);

  const getFavouriteProducts = async (id) => {
    try {
      setState((draft) => {
        draft.productsTab.isLoading = true;
      });
      const res = await getFavProductsById(id);
      if (res.success) {
        setState((draft) => {
          draft.productsTab.productsList = res.data;
          draft.productsTab.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.httpStatusCodes.details = err.response.status;
        draft.productsTab.isLoading = false;
      });
    }
  };

  const getFavouriteStores = async (id) => {
    try {
      setState((draft) => {
        draft.storesTab.isLoading = true;
      });
      const res = await getFavStoresById(id);
      if (res.success) {
        setState((draft) => {
          draft.storesTab.storesList = res.data;
          draft.storesTab.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.httpStatusCodes.details = err.response.status;
        draft.storesTab.isLoading = false;
      });
    }
  };

  const getFavDetailsById = async (id) => {
    try {
      setState((draft) => {
        draft.favDetails.isBusy = true;
      });
      const res = await getFavGroupById(id);
      if (res.success) {
        setState((draft) => {
          draft.favDetails.data = res.data;
          draft.favDetails.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.httpStatusCodes.details = err.response.status;
        draft.favDetails.isBusy = false;
      });
    }
  };

  const getFavouriteList = async (stores = [], searchText = "") => {
    try {
      let IDs = stores.map((v) => {
        return v.value;
      });
      let params = {};

      if (searchText) {
        params = { ...params, search: searchText };
      }
      if (IDs.length > 0) {
        params = { ...params, store_id: IDs };
      }

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = false;
      });
      const res = await getFavList(query);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.favList = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const onSeachClear = () => {
    setState((draft) => {
      draft.filters.searchText = "";
      draft.filters.isSearching = true;
    });
    const { stores } = state.filters;
    getFavouriteList(stores, "");
  };

  const onChangeSearchText = (e) => {
    setState((draft) => {
      draft.filters.searchText = e.target.value;
      draft.filters.isSearching = false;
    });
  };

  const handleFilter = (e, value, type) => {
    if (type === "store") {
      setState((draft) => {
        draft.filters.stores = value.map((v) => {
          return { ...v, type: "Store" };
        });
      });
    }
  };

  const handleFilterClear = (type, value) => {
    const { stores, searchText } = state.filters;
    if (type === "single") {
      if (value.type === "Store") {
        const filteredStores = stores.filter((e) => e.value !== value.value);
        getFavouriteList(filteredStores, searchText);
        setState((draft) => {
          draft.filters.stores = filteredStores;
        });
      }
    } else {
      getFavouriteList([], searchText);
      setState((draft) => {
        draft.filters.all = [];
        draft.filters.stores = [];
      });
    }
  };

  const handleApplyFilter = () => {
    const { stores, searchText } = state.filters;
    getFavouriteList(stores, searchText);
  };

  const toggleModal = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isOpen = !draft.isOpen;
    });
  };
  const toggleCreateOrEditModal = async (id = null, type) => {
    if (type === "create") {
      setState((draft) => {
        draft.id = null;
        draft.isCreateOpen = !draft.isCreateOpen;
      });
    } else if (type === "edit") {
      try {
        const res = await getFavGroupById(id);
        if (res.success) {
          setState((draft) => {
            draft.selectedId = id;
            draft.isEditOpen = !draft.isEditOpen;
            draft.data.name = res.data.name;
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
      }
    } else {
      setState((draft) => {
        draft.id = null;
        draft.isEditOpen = false;
        draft.isCreateOpen = false;
        draft.data.name = "";
        draft.selectedId = null;
      });
    }
  };

  const handleDeleteFav = async (type = "list") => {
    try {
      const res = await deleteFavById(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.favList = draft.favList.filter((item) => item.id !== state.id);
        });
        toggleModal();
        if (type === "details") {
          navigate(isStoreAdmin ? "/store-admin/favourites" : "/favourites");
        }
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onChangeFavGroup = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };

  const onUpdateFavGroup = async (type = "list") => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await updateFavGroup(state.selectedId, state.data);
      if (res.success) {
        if (favId) {
          getFavDetailsById(favId);
        }
        successMessage("Updated Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        getFavouriteList();
        toggleCreateOrEditModal();
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onSaveFavGroup = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await createFavGroup(state.data);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        getFavouriteList();
        toggleCreateOrEditModal();
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const handleProductSelect = (type, value, event) => {
    if (type === "body") {
      if (event.target.checked) {
        setState((draft) => {
          draft.productsTab.selectedProducts = [
            ...draft.productsTab.selectedProducts,
            value.id,
          ];
        });
      } else {
        setState((draft) => {
          draft.productsTab.selectedProducts =
            draft.productsTab.selectedProducts.filter(
              (val) => val !== value.id
            );
        });
      }
    } else {
      const subRowsID = value.products?.map((e, i) => {
        return e.id;
      });
      if (event.target.checked) {
        const alReadyIds = state.productsTab.productsList.map((e, i) => {
          return e.id;
        });
        setState((draft) => {
          draft.productsTab.selectedProducts = [
            ...new Set([...state.productsTab.selectedProducts, ...subRowsID]),
          ].filter((val) => !alReadyIds.includes(val));
        });
      } else {
        setState((draft) => {
          draft.productsTab.selectedProducts =
            draft.productsTab.selectedProducts.filter(
              (val) => !subRowsID.includes(val)
            );
        });
      }
    }
  };

  const handleStoreSelect = (id) => {
    const isSelcted = state.storesTab.selectedStores.includes(id);
    if (isSelcted) {
      setState((draft) => {
        draft.storesTab.selectedStores = draft.storesTab.selectedStores.filter(
          (val) => val !== id
        );
      });
    } else {
      setState((draft) => {
        draft.storesTab.selectedStores = [
          ...draft.storesTab.selectedStores,
          id,
        ];
        return draft;
      });
    }
  };

  const handleAddProductModal = () => {
    HandleFilterProducts("initial");
    setState((draft) => {
      draft.productsTab.isOpen = !draft.productsTab.isOpen;
      draft.productsTab.searchText = "";
      draft.productsTab.selectedProducts = [];
    });
  };

  const handleAddStoreModal = () => {
    HandleFilterStores("initial");
    setState((draft) => {
      draft.storesTab.isOpen = !draft.storesTab.isOpen;
      draft.storesTab.searchText = "";
      draft.storesTab.selectedStores = [];
    });
  };

  const handleAssignProducts = async () => {
    setState((draft) => {
      draft.productsTab.isSaveButtonBusy = true;
    });
    const payload = {
      products: state.productsTab.selectedProducts.map((item) => ({
        id: item,
      })),
    };
    try {
      const res = await assignProductsToFav(isProducts, payload);
      if (res.success === true) {
        successMessage(res.message);

        setState((draft) => {
          draft.productsTab.productsList = [
            ...draft.productsTab.productsList,
            ...draft.productsTab.allProducts.filter((val) =>
              draft.productsTab.selectedProducts.includes(val.id)
            ),
          ];
          draft.productsTab.isLoading = false;
          draft.productsTab.isSaveButtonBusy = false;
          draft.productsTab.selectedProducts = [];
          return draft;
        });
        handleAddProductModal(false);
        getFavouriteProducts(isProducts);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.productsTab.isSaveButtonBusy = false;
      });
    }
  };

  const handleAssignStores = async () => {
    const payload = {
      store_id: state.storesTab.selectedStores,
    };
    setState((draft) => {
      draft.storesTab.isSaveButton = true;
    });
    try {
      const res = await assignStoresToFav(isStores, payload);
      if (res.success === true) {
        successMessage(res.message);
        getFavouriteStores(isStores);
        setState((draft) => {
          draft.storesTab.isOpen = !draft.storesTab.isOpen;
          draft.storesTab.searchText = "";
          draft.storesTab.selectedStores = [];
          draft.storesTab.isSaveButton = false;
        });
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.storesTab.isSaveButton = true;
      });
    }
  };

  const togglePrdDeleteModal = (id = null) => {
    setState((draft) => {
      draft.productsTab.isDelete = !draft.productsTab.isDelete;
      draft.productsTab.id = id;
    });
  };

  const toggleStoreDeleteModal = (id = null) => {
    setState((draft) => {
      draft.storesTab.isDelete = !draft.storesTab.isDelete;
      draft.storesTab.id = id;
    });
  };

  const onConfirmPrdDelete = async () => {
    try {
      const res = await deleteFavPrdById(isProducts, state.productsTab.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.productsTab.productsList =
            draft.productsTab.productsList.filter(
              (item) => item.id !== state.productsTab.id
            );
        });
        getFavouriteProducts(isProducts);
        togglePrdDeleteModal();
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onConfirmStoreDelete = async () => {
    try {
      const res = await deleteFavStoresById(isStores, state.storesTab.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        getFavouriteStores(isStores);
        toggleStoreDeleteModal();
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onProductsOrderChange = async (data) => {
    const payload = {
      products: data.map((x, i) => ({
        product_id: x.id,
        display_order: i,
      })),
    };
    try {
      const res = await changeProductOrder(isProducts, payload);
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onFavGroupOrderChange = async (data) => {
    const payload = data.map((x) => x.id);
    try {
      const res = await changeFavGroupOrder({ favourites: payload });
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleSelectAllProducts = (checked) => {
    if (checked) {
      setState((draft) => {
        draft.productsTab.selectedProducts = [
          ...draft.productsTab.filteredProducts.map((item) => item.id),
        ];
      });
    } else {
      setState((draft) => {
        draft.productsTab.selectedProducts = [];
      });
    }
  };
  useEffect(() => {
    if (isProducts) {
      getCategorywiseProductsInFavList();
    }
  }, [isProducts]);

  const getCategorywiseProductsInFavList = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = false;
      });
      const res = await getCategorywiseProductsInFavorite();
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.productsTab.allCategoryProductList = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  return {
    state,
    onChangeSearchText,
    onSeachClear,
    handleFilter,
    handleFilterClear,
    handleApplyFilter,
    toggleModal,
    handleDeleteFav,
    onChangeFavGroup,
    onSaveFavGroup,
    toggleCreateOrEditModal,
    onUpdateFavGroup,
    isEditable,
    isCreateVisible,
    isDeletable,
    hasModify,
    HandleFilterProducts,
    handleProductSelect,
    handleAddProductModal,
    handleAssignProducts,
    togglePrdDeleteModal,
    onConfirmPrdDelete,
    HandleFilterStores,
    handleStoreSelect,
    handleAddStoreModal,
    handleAssignStores,
    toggleStoreDeleteModal,
    onConfirmStoreDelete,
    onProductsOrderChange,
    isStoreAdmin,
    onFavGroupOrderChange,
    handleSelectAllProducts,
  };
};
