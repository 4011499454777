import * as React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  FormControl,
  Button,
  Stack,
  InputAdornment,
  OutlinedInput,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

export const AddUserDialog = (props) => {
  const navigate = useNavigate();
  const {
    userList,
    HandleFilterUsers,
    handleUsersSelect,
    isSelectedUser,
    handleAssignUser,
    disabled,
    isStoreAdmin,
    value
  } = props;

  const stringAvatar = (name, id) => {
    const isSelcted = isSelectedUser(id);
    return {
      sx: {
        fontSize: "16px",
        background: isSelcted ? "#1560D4" : "#edf3fc",
        color: isSelcted ? "#fff" : "#000",
      },
      children: isSelcted ? (
        <DoneIcon />
      ) : (
        `${name.split(' ').map(function(str) { return str ? str[0] : "";}).join('')}`
      ),
    };
  };
  return (
    <>
      <Stack
        justifyContent="space-between"
        height="100%"
        gap="8px"
        maxHeight={"calc(100% - 24px)"}
      >
        <FormControl
          size="small"
          fullWidth={true}
          sx={{
            pt: "24px",
          }}
        >
          <OutlinedInput
            placeholder="Search"
            sx={{
              height: "48px",
              ".MuiOutlinedInput-input": {
                pl: "0px !important",
              },
            }}
            value={value}
            size="small"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon size="large" />
              </InputAdornment>
            }
            endAdornment={
              value &&
              (
                <InputAdornment
                  sx={{ cursor: "pointer" }}
                  onClick={() => { HandleFilterUsers("search", "") }}
                  position="end"
                >
                  <CloseIcon size="large" />
                </InputAdornment>
              )
            }
            fullWidth
            onChange={(e) => {
              HandleFilterUsers("search", e.target.value);
            }}
          />
        </FormControl>
        <Box sx={{ height: "100%", overflow: "auto" }} fullWidth>
          {userList.length > 0 ? (
            <List sx={{ width: "100%", py: 0, bgcolor: "background.paper" }}>
              {userList.map((data, index) => (
                <ListItem
                  alignItems="flex-start"
                  key={index}
                  sx={{
                    height: "68px",
                    padding: " 0px 12px 12px 0px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleUsersSelect(data.id)}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt="cemy Sharp"
                      {...stringAvatar(data.name, data.id)}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="subtitle2"
                          color="text.primary"
                        >
                          {data.name}
                        </Typography>
                      </>
                    }
                    secondary={
                      <>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="caption"
                          color="text.secondary"
                          fontWeight={400}
                        >
                          {data.email}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Stack justifyContent="center" alignItems="center" height="100%">
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="caption"
                color="text.secondary"
                fontWeight={400}
              >
                There is no users found
              </Typography>
              <Button
                type="button"
                size="medium"
                variant="text"
                className="delte"
                startIcon={<AddIcon />}
                sx={{
                  minWidth: "40px",
                  p: "8px",
                  border: "1px solid rgba(21, 96, 212, 0.12)",
                  mt: "8px",
                }}
                onClick={() =>
                  navigate(
                    isStoreAdmin
                      ? "/store-admin/admin-users/create"
                      : "/admin-users/create"
                  )
                }
              >
                Add User
              </Button>
            </Stack>
          )}
        </Box>
        <Button
          sx={{ height: "48px" }}
          variant="contained"
          fullWidth
          onClick={() => {
            handleAssignUser();
          }}
          disabled={disabled}
        >
          Save
        </Button>
      </Stack>
    </>
  );
};
