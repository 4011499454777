import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const ActionButtons = ({
  row,
  toggleDialog,
  toggleDeleteDialog,
  modifyPermission,
  deletePermission,
}) => {
  const navigate = useNavigate();
  return (
    <div className="action-buttons" onClick={(e) => e.stopPropagation()}>
      <Box
        component="img"
        width="24px"
        height="24px"
        alt="details"
        src="/icons/ic_edit.svg"
        title="Edit"
        display={modifyPermission ? "block" : "none"}
        onClick={() => navigate(`edit/${row.id}`)}
      />
      <Box
        component="img"
        width="24px"
        height="24px"
        alt="details"
        src="/icons/ic_download.svg"
        title="Download"
        onClick={() => toggleDialog(row.id)}
      />
      <Box
        component="img"
        width="24px"
        height="24px"
        alt="delete"
        title="Delete"
        onClick={() => toggleDeleteDialog({ status: true, id: row.id })}
        display={deletePermission ? "block" : "none"}
        src="/icons/ic_delete.svg"
      />
      <Box
        component="img"
        width="24px"
        height="24px"
        alt="forward"
        title="Details"
        onClick={() => toggleDialog(row.id)}
        src="/icons/ic_forward.svg"
      />
    </div>
  );
};
