import {
  ContentLayout,
  HeaderBreadcrumbs,
  PageNotFound,
} from "../../../shared";
import React from "react";
import {
  Button,
  Stack,
  Card,
  Switch,
  Typography,
  Grid,
  Avatar,
  Box,
  FormHelperText,
  Popper,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { useCategory } from "../hooks/useCategory";
import SimpleReactValidator from "simple-react-validator";
import Avadar_img from "../../../assets/images/Icons/Avadar_img.png";
import { SketchPicker } from "react-color";
import { presetColors } from "../../../utils";

export const CreateAndEditCategory = () => {
  const [, forceUpdate] = React.useState();
  const [anchorElColorPicker, setAnchorElColorPicker] = React.useState(null);
  const [openColorPicker, setOpenColorPicker] = React.useState(
    Boolean(anchorElColorPicker)
  );
  const ref = React.useRef(null);
  const refContainer = React.useRef(null);
  const colorPickerPopperId = openColorPicker
    ? "color-picker-popper"
    : undefined;

  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        IconFormat: {
          message: "The Image  should be png, jpg, jpeg",
          rule: (value) => {
            if (
              !["image/png", "image/jpeg", "image/jpg"].includes(value.type)
            ) {
              return false;
            }
          },
        },
        IconSize: {
          message: "The Image Size should be less than 1MB",
          rule: (value) => {
            const maxSize = 1048576; //1mb
            if (value?.size > maxSize) {
              return false;
            }
          },
        },
      },
    })
  );

  const { id } = useParams();
  const {
    onChange,
    state,
    onSwitchChange,
    onDescription,
    handleColorPick,
    onFileChange,
    onSaveCategory,
    onUpdateCategory,
    isEditable,
    isCreateVisible,
  } = useCategory({ isCategory: false, categoryId: id, isProduct: false });
  const AlbumArtRef = React.useRef();

  // Effect for handle outside click of color picker
  React.useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        ref.current &&
        refContainer.current &&
        !refContainer.current.contains(event.target) &&
        !ref.current.contains(event.target)
      ) {
        setOpenColorPicker(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [ref, refContainer]);

  React.useEffect(() => {
    setOpenColorPicker(Boolean(anchorElColorPicker));
  }, [anchorElColorPicker]);

  const onLogoClick = () => {
    AlbumArtRef.current.click();
  };

  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdateCategory(id) : onSaveCategory();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }

  // Handler for open color palate
  const handleColorPickerOpen = (event) => {
    setAnchorElColorPicker(anchorElColorPicker ? null : event.currentTarget);
  };

  return (
    <>
      <ContentLayout
        title={id ? state.name : "Add Category"}
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              disabled={state.isSaveButtonBusy}
              onClick={() => onSubmit()}
            >
              {!id ? "Save" : "Update"}
            </Button>
          </>
        }
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Category", href: "/category" },
              id ? { name: state.name, href: `/category/details/${id}` } : null,
              { name: id ? "Edit" : "Create" },
            ].filter(Boolean)}
          />
        }
      >
        <Card sx={{ p: "32px 24px", mb: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Details
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack width={"100%"} gap="20px">
                <InputControl
                  name="name"
                  label="Name"
                  onChange={onChange}
                  value={state.data.name}
                  required
                  error={validator.current.message(
                    "Name",
                    state.data.name,
                    "required|min:3"
                  )}
                  helperText={validator.current.message(
                    "Name",
                    state.data.name,
                    "required|min:3"
                  )}
                />
                <Stack direction="row" gap={1}>
                  <InputControl
                    name="display_name"
                    label="Display Name"
                    autoComplete="off"
                    onChange={onChange}
                    value={state.data.display_name}
                    required
                    error={validator.current.message(
                      "Display Name",
                      state.data.display_name,
                      "required"
                    )}
                    helperText={validator.current.message(
                      "Display Name",
                      state.data.display_name,
                      "required"
                    )}
                  />
                  <InputControl
                    name="display_order"
                    label="Display Order"
                    autoComplete="off"
                    onChange={onChange}
                    value={state.data.display_order}
                    required
                    error={validator.current.message(
                      "Display Order",
                      state.data.display_order,
                      "required"
                    )}
                    helperText={validator.current.message(
                      "Display Order",
                      state.data.display_order,
                      "required"
                    )}
                  />
                </Stack>
                {/* Report */}
                <Stack direction="row" gap={1}>
                  <InputControl
                    name="report_category_name"
                    label="Report Category"
                    autoComplete="off"
                    onChange={onChange}
                    value={state.data.report_category_name}
                    required
                    error={validator.current.message(
                      "Report Category",
                      state.data.report_category_name,
                      "required"
                    )}
                    helperText={validator.current.message(
                      "Report Category",
                      state.data.report_category_name,
                      "required"
                    )}
                  />
                  <InputControl
                    name="report_class_name"
                    label="Report Class Name"
                    autoComplete="off"
                    onChange={onChange}
                    value={state.data.report_class_name}
                    required
                    error={validator.current.message(
                      "Report Class Name",
                      state.data.report_class_name,
                      "required"
                    )}
                    helperText={validator.current.message(
                      "Report Class Name",
                      state.data.report_class_name,
                      "required"
                    )}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between" py="10px">
                  <Typography variant="subtitle1" fontWeight={600}>
                    Status
                  </Typography>
                  <Switch
                    defaultChecked={state.data.status ? true : false}
                    checked={state.data.status}
                    onChange={(loading) => onSwitchChange(loading, "status")}
                    name={state.data.status}
                    color="primary"
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ p: "32px 24px", mb: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Icon and Colour
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack width={"100%"} gap="20px">
                <Stack direction="row" gap="10px" alignItems="center">
                  <Avatar
                    sx={{
                      bgcolor:
                        state.data.color === null
                          ? "#F2F2F2"
                          : state.data.color,
                      width: "84px",
                      height: "80px",
                      borderRadius: "8px",
                      top: "10px",
                    }}
                    variant="rounded"
                  >
                    <img
                      width="50px"
                      src={
                        state.iconFile
                          ? URL.createObjectURL(state.iconFile)
                          : id
                          ? state.data.icon
                          : Avadar_img
                      }
                    />
                  </Avatar>

                  <div>
                    <input
                      id="contained-button-file"
                      type="file"
                      onChange={(e) => onFileChange(e, "image")}
                      style={{ display: "none" }}
                      ref={AlbumArtRef}
                    />
                    <Button
                      color="info"
                      component="span"
                      sx={{ mt: 2 }}
                      onClick={onLogoClick}
                    >
                      {id ? "Change" : "Upload"}
                    </Button>
                  </div>
                </Stack>
                <Stack>
                  <Typography variant="body2" color="#808080" mt={1}>
                    Upload an image with 1:1 ratio and less than 1MB.
                  </Typography>
                  <FormHelperText sx={{ color: "#C60808" }}>
                    {validator.current.message(
                      "Image",
                      state.iconFile,
                      "IconSize|IconFormat"
                    )}
                  </FormHelperText>
                </Stack>

                {/* Color picker container */}
                <>
                  <Box
                    sx={{
                      height: 50,
                      borderRadius: 1,
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "24px",
                    }}
                  >
                    <Typography
                      fontWeight={600}
                      fontSize="16px"
                      lineHeight="19.36px"
                    >
                      Colour Code
                    </Typography>
                    <Box
                      sx={{
                        width: "138px",
                        height: "48px",
                        borderRadius: "8px",
                        border: "1px solid #e6e6e6",
                        padding: "14px",
                        gap: "12px",
                        bgcolor: "#fffff",
                        display: "flex",
                        alignItems: "center",
                      }}
                      aria-describedby={colorPickerPopperId}
                      onClick={handleColorPickerOpen}
                      ref={refContainer}
                    >
                      <Box
                        component="button"
                        sx={{
                          width: "26px",
                          height: "26px",
                          borderRadius: "3px",
                          border: "1px",
                          bgcolor: state.data.color,
                          cursor: "pointer",
                        }}
                      />
                      <Typography
                        fontSize="16px"
                        lineHeight="19.36px"
                        fontWeight={400}
                      >
                        {state.data.color?.toUpperCase()}
                      </Typography>
                    </Box>
                  </Box>
                  {/* Popper component */}
                  <Popper
                    ref={ref}
                    id={colorPickerPopperId}
                    open={openColorPicker}
                    anchorEl={anchorElColorPicker}
                  >
                    <SketchPicker
                      color={state.data.color}
                      onChangeComplete={(color) => {
                        handleColorPick(color.hex);
                      }}
                      presetColors={presetColors}
                      disableAlpha
                    />
                  </Popper>
                </>
              </Stack>
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ p: "32px 24px", mb: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Description
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack width={"100%"} gap="20px">
                <ReactQuill
                  theme="snow"
                  value={state.data.description}
                  onChange={onDescription}
                />
              </Stack>
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ p: "32px 24px", mb: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Show in List
              </Typography>
            </Grid>
            <Grid item sm={8} display={"flex"} justifyContent={"end"}>
              <Switch
                checked={state.data.show_in_list}
                onChange={(e) => onSwitchChange(e, "show_in_list")}
                name="show_in_list"
                color="primary"
              />
            </Grid>
          </Grid>
        </Card>
      </ContentLayout>
    </>
  );
};
