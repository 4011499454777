import * as React from "react";
import { useParams } from "react-router-dom";
import { CustomTable, Loader, ServerPaginatedTable } from "../../../shared";
import { useCustomers } from "../hooks";
import InputControl from "../../../shared/components/InputControl";

export const PurchasedProductsList = () => {
  const { id } = useParams();
  const {
    state,
    state: {
      purchasedProductDetails: { sortBy, pagination, searchText },
    },
    gotoPageOrders,
    setPageSizeOrders,
    handleSortOrders,
    onChangeOrderSearch,
    onSeachClearOrders,
  } = useCustomers({ purchasedId: id });
  const columns = React.useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "product_name",
      },
      {
        Header: "Txn Count",
        accessor: "txn_count",
      },
      {
        Header: "Total Spent",
        accessor: "dollar_spend",
        Cell: ({ value }) => {
          return <div>${value?.toFixed(2)}</div>;
        },
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
    ],
    []
  );

  return (
    <>
      <ServerPaginatedTable
        rowsSelection={false}
        columns={columns}
        data={state?.purchasedProductDetails.data}
        key={sortBy}
        isLoading={state.isBusy}
        gotoPage={gotoPageOrders}
        setPageSize={setPageSizeOrders}
        pagination={pagination}
        sortByGlobal={sortBy}
        handleSort={handleSortOrders}
        defaultSearch={false}
        filterContent={
          <>
            <InputControl
              type="table-search"
              name="searchText"
              placeholder="Search"
              size="small"
              value={searchText}
              onChange={(e) => onChangeOrderSearch(e)}
              onClear={onSeachClearOrders}
            />
          </>
        }
      />
    </>
  );
};
