import * as React from "react";
import {
  Typography,
  Button,
  Stack,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { Loader } from "../../../shared";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";

export const DetailsEditDialog = (props) => {
  const { data, onSubmit, inputChange, isBusy, keys, options } = props;

  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        Max: {
          message: "The Maximum should greater than  or equal to Minimum",
          required: true,
          rule: (value) => {
            const { max, min } = value;
            return parseInt(max) >= parseInt(min);
          },
        },
      },
    })
  );

  const handleSubmit = () => {
    if (validator.current.allValid()) {
      onSubmit("edit");
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  return (
    <>
      <Stack
        justifyContent="space-between"
        height="100%"
        gap="8px"
        maxHeight={"calc(100% - 24px)"}
        mt="24px"
      >
        <Stack gap="12px">
          <Typography variant="subtitle2" color="#636363">
            Type
          </Typography>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name={keys[2]}
            sx={{
              ml: "4px",
              justifyContent: "space-between",
              flexDirection: keys[2] === "control_type" ? "row" : "row-reverse",
            }}
            value={data[keys[2]]}
            onChange={(e) => inputChange(e, data.id)}
          >
            {options.map((data) => (
              <FormControlLabel
                value={data.value}
                control={<Radio />}
                label={
                  <Typography variant="subtitle2" fontWeight={400}>
                    {data.label}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
          <Typography variant="subtitle2" color="#636363">
            Quantity
          </Typography>
          <Stack direction="row" gap="20px">
            <InputControl
              name={keys[0]}
              type="number"
              label="Minimum"
              width="100%"
              isDisabledKeys={["-"]}
              onKeyDown={(evt) => {
                (evt.key === "e" ||
                  evt.key === "ArrowDown" ||
                  evt.key === ".") &&
                  evt.preventDefault();
              }}
              value={data[keys[0]]}
              onChange={(e) => inputChange(e, data.id)}
              error={validator.current.message(
                `Minimum`,
                data[keys[0]],
                "required"
              )}
              helperText={validator.current.message(
                `Minimum`,
                data[keys[0]],
                "required"
              )}
            />
            <InputControl
              name={keys[1]}
              type="number"
              label="Maximum"
              width="100%"
              isDisabledKeys={["-"]}
              onKeyDown={(evt) => {
                (evt.key === "e" ||
                  evt.key === "ArrowDown" ||
                  evt.key === ".") &&
                  evt.preventDefault();
              }}
              value={data[keys[1]]}
              onChange={(e) => inputChange(e, data.id)}
              error={validator.current.message(
                "Maximum",
                {
                  max: data[keys[1]],
                  min: data[keys[0]],
                },
                "Max"
              )}
              helperText={validator.current.message(
                "Maximum",
                {
                  max: data[keys[1]],
                  min: data[keys[0]],
                },
                "Max"
              )}
            />
          </Stack>
        </Stack>

        <Button
          sx={{ height: "40px", alignSelf: "end" }}
          variant="contained"
          onClick={() => handleSubmit()}
          disabled={isBusy}
        >
          Update
        </Button>
      </Stack>
    </>
  );
};
