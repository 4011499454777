import React, { useState } from "react";
import { Square } from "./Square";
import { Button, Grid, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const CategoryGrid = ({
  state,
  addColumns,
  moveProduct,
  onChangeColor,
}) => {
  const selectedCategory = state.data.categories[state.selectedCategory];
  const { no_of_grids } = selectedCategory;
  const { grid_view } = state.data;

  function renderSquare(i, data) {
    const x = Math.floor(i / 8);
    const y = i % 8;
    return (
      <Square
        key={i}
        i={i}
        x={x}
        y={y}
        products={data.items}
        garbage={data.garbage}
        moveProduct={moveProduct}
        grid_view={grid_view}
        onChangeColor={onChangeColor}
      />
    );
  }

  const squares = [];
  for (let i = 0; i < no_of_grids; i++) {
    squares.push(renderSquare(i, selectedCategory));
  }
  return (
    <Stack
      sx={{
        padding: "10px",
        backgroundColor: "#F7F9FC",
        height: "calc(100vh - 160px)",
        overflow: "auto",
      }}
    >
      <Stack
        sx={{
          padding: "12px",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Grid container spacing={1}>
          {squares}
        </Grid>
        <Button
          type="button"
          size="medium"
          sx={{ maxWidth: "118px", marginTop: "10px" }}
          startIcon={<AddIcon />}
          onClick={addColumns}
        >
          Add New
        </Button>
      </Stack>
    </Stack>
  );
};
