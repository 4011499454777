import { API } from "../../../utils";

export const getDiscount = async (query) => {
  const res = await API.get("discounts", query);
  return res.data;
};

export const deleteDiscountById = async (id) => {
  const res = await API.delete(`discounts/${id}`);
  return res.data;
};
export const createDiscount = async (payload) => {
  const res = await API.post(`discounts`, payload);
  return res.data;
};
export const getDiscountDetails = async (id) => {
  const res = await API.get(`discounts/${id}`);
  return res.data;
};

export const editDiscount = async (id, payload) => {
  const res = await API.put(`discounts/${id}`, payload);
  return res.data;
};
export const getStoreFromDiscount = async (id) => {
  const res = await API.get(`discounts/${id}/stores`);
  return res.data;
};
export const assignStoreToDiscount = async (payload, id) => {
  const res = await API.put(`discounts/${id}/stores`, payload);
  return res.data;
};
export const deleteStoreFromDiscount = async (discountId, storeId) => {
  const res = await API.delete(
    `discounts/${discountId}/stores/${storeId}`
  );
  return res.data;
};
