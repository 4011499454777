import { Box, Chip, Button } from "@mui/material";
import React from "react";
import "react-quill/dist/quill.snow.css";
import {
  ContentLayout,
  CustomDetailsList,
  DeleteModal,
  HeaderBreadcrumbs,
  Loader,
} from "../../../shared";
import { useNavigate, useParams } from "react-router";
import { UseMenuBanners } from "../hooks/UseMenuBanners";
import { LoadingButton } from "@mui/lab";
import moment from "moment";

export const MenuBannersDetails = () => {
  const { id } = useParams();
  const {
    state,
    toggleModal,
    handleMenuBannerDelete,
    toggleStatus,
    isEditable,
    isDeletable,
    globalData,
  } = UseMenuBanners({
    load: false,
    id,
  });
  const { data, isDetailsFetching, isOpen, isPublishButtonBusy } = state;
  const navigate = useNavigate();

  const column = [
    {
      title: "Title",
      accessor: "title",
    },
    {
      title: "Image",
      accessor: (row) => {
        return (
          <Box
            sx={{
              width: "150px",
              height: "75px",
              borderRadius: 1,
            }}
          >
            <img
              style={{
                width: "100%",
                height: "75px",
                maxHeight: "75px",
                objectFit: "cover",
                borderRadius: "8px",
              }}
              alt="img"
              src={row.image}
            />
          </Box>
        );
      },
    },
    {
      title: "Type",
      accessor: (row) => {
        return row?.model?.name;
      },
    },
    {
      title: "Link To",
      accessor: ({ model }) => {
        return model?.type_name;
      },
    },
  ];

  const column2 = [
    {
      title: "Start Date",
      accessor: ({ start_date }) => {
        return start_date && moment(start_date).format("DD MMM, YYYY");
      },
    },
    {
      title: "End Date",
      accessor: ({ end_date }) => {
        return end_date && moment(end_date).format("DD MMM, YYYY");
      },
    },
    {
      title: "Start Time",
      accessor: ({ start_time }) => {
        return start_time && moment(start_time).format("HH:mm");
      },
    },
    {
      title: "End Time",
      accessor: ({ end_time }) => {
        return end_time && moment(end_time).format("HH:mm");
      },
    },
    {
      title: "Store(s)",
      accessor: (row) => {
        return (
          <Box>
            {row.stores?.slice(0, 2).map((data) => {
              return (
                <Chip
                  sx={{
                    mr: 1,
                    borderRadius: "12px",
                    background: "rgba(0, 0, 0, 0.16)",
                  }}
                  label={data.name}
                  variant="filled"
                  size="small"
                />
              );
            })}
            {row.stores?.length > 2 && (
              <Chip
                sx={{ borderRadius: "12px", background: "rgba(0, 0, 0, 0.16)" }}
                label={`+${
                  row.stores?.length - row.stores?.slice(0, 2)?.length
                }`}
                variant="filled"
                size="small"
              />
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <ContentLayout
        title={data.title || ""}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              {
                name: "Menu Banners",
                href: "/menu-banner",
              },
              {
                name: data.title || "",
              },
            ]}
          />
        }
        chipType={"other"}
        statusContent={
          data?.status === 1 ? (
            <Chip
              label="Published"
              color="success"
              variant="outlined"
              size="small"
              sx={{ border: "none !important" }}
            />
          ) : (
            <Chip
              label="Draft"
              color="primary"
              variant="outlined"
              size="small"
              sx={{ bgcolor: "#1560D414", border: "none" }}
              disabled={false}
            />
          )
        }
        rightContent={
          <>
            {isDeletable && (
              <Button
                type="button"
                size="medium"
                variant="text"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => toggleModal(true, data.id)}
              >
                <img alt="delete" src="/icons/ic_delete_blue.svg" />
              </Button>
            )}
            {isEditable && (
              <>
                <Button
                  type="button"
                  size="medium"
                  variant="text"
                  sx={{
                    minWidth: "59px",
                    // p: "8px",
                  }}
                  onClick={() => navigate("edit")}
                >
                  Edit
                </Button>
                <LoadingButton
                  onClick={() => toggleStatus(data.status)}
                  loading={isPublishButtonBusy}
                  type="button"
                  size="medium"
                  variant="contained"
                >
                  {data?.status === 1 ? "Move To Draft" : "Publish"}
                </LoadingButton>
              </>
            )}
          </>
        }
      >
        {isDetailsFetching ? (
          <Loader />
        ) : (
          <>
            <CustomDetailsList
              columns={column}
              data={data}
              title="Basic Details"
            />
            <CustomDetailsList
              columns={column2}
              data={data}
              title="Date & Store"
            />
          </>
        )}
      </ContentLayout>
      <DeleteModal
        open={isOpen}
        handleClose={() => toggleModal()}
        onConfirm={() => {
          handleMenuBannerDelete("details");
        }}
      />
    </>
  );
};
