import * as React from "react";
import { Button, Chip } from "@mui/material";
import {
  CustomTable,
  DeleteModal,
  Loader,
  useTabs,
} from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate, useParams } from "react-router-dom";
import { sortItems } from "../../../utils";
import InputControl from "../../../shared/components/InputControl";
import CloseIcon from "@mui/icons-material/Close";
import { useStoreUsers } from "..";

export const StoreUsersList = ({ storeName }) => {
  const { id } = useParams();
  const { setCurentTab } = useTabs();
  const {
    state,
    globalData,
    handleDeleteUser,
    handleModals,
    onChangeFilter,
    resetFilters,
    isCreateVisible,
    isDeletable: deletable,
  } = useStoreUsers({
    storeId: id,
  });

  const { isLoading, dataList, filters, isOpen } = state
  const navigate = useNavigate();
  const ActionButtons = ({ id, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {deletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
        <img
          onClick={() => {
            setCurentTab("adminUsers", "1");
            navigate(`/admin-users/details/${id}?isStoreId=${id}`);
          }}
          alt="details"
          src="/icons/arrow_forward.svg"
        />
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Phone Number",
        accessor: "phone_formatted",

      },
      {
        Header: "Status",
        accessor: "status",
        disableGlobalFilter: true,
        sortInverted: true,
        Cell: ({ value }) => {
          return value ? (
            <Chip
              label="Active"
              color="success"
              variant="outlined"
              size="small"
            />
          ) : (
            <Chip
              label="Inactive"
              color="success"
              variant="outlined"
              size="small"
              disabled
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => {
                handleModals(true, row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      {isLoading ? <Loader /> : (
        <CustomTable
          columns={columns}
          data={dataList || []}
          isLoading={state?.isBusy}
          defaultSearch={false}
          tableTitle="Users List"
          bulkDelete={false}
          headerRightContent={
            <>
              {isCreateVisible && (
                <Link
                  sx={{ textDecoration: "none" }}
                  to={`/admin-users/create?isStore=${id}`}
                  state={storeName}
                >
                  <Button
                    type="button"
                    size="medium"
                    variant="text"
                    startIcon={<AddIcon />}
                  >
                    New User
                  </Button>
                </Link>
              )}
            </>
          }
          filterContent={
            <>
              <InputControl
                type="table-search"
                name="searchText"
                placeholder="Search"
                size="small"
                value={filters.searchText}
                onChange={(e) => onChangeFilter(e)}
                onClear={(e) => onChangeFilter({
                  target: {
                    name: "searchText",
                    value: "",
                  },
                })}
              />
              <InputControl
                type="select"
                name="status"
                label="Status"
                optionValue={globalData.status ?? []}
                width="250px"
                onChange={(e) => onChangeFilter(e)}
                value={filters.status}
              />

              <Button
                type="button"
                size="medium"
                variant="text"
                color="gray"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => resetFilters()}
              >
                <CloseIcon />
              </Button>
            </>
          }
          onRowLink={(e) => {
            return `/admin-users/details/${e.id}?isStoreId=${id}`;
          }}
          onRowAction={() => {
            setCurentTab("adminUsers", "1");
          }}
        />
      )}
      <DeleteModal
        open={isOpen}
        handleClose={() => handleModals(false, null)}
        onConfirm={handleDeleteUser}
      />
    </>
  );
};
