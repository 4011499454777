import { API } from "../../../utils";

export const getLoyaltyCardList = async () => {
  const res = await API.get(`loyalty-card_types`);
  return res.data;
};

export const getLoyaltyCardDetails = async (id) => {
  const res = await API.get(`loyalty-card_types/${id}`);
  return res.data;
};

export const updateLoyaltyCard = async (id, payload) => {
  const res = await API.put(`loyalty-card_types/${id}`, payload);
  return res.data;
};

export const getLoyaltyCustomersList = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `loyalty-cards?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data;
};

export const getLoyaltyPointHistoryList = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `loyalty-card-history?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data;
};

export const getLoyaltyPointHistoryDetails = async (id) => {
  const res = await API.get(`loyalty-card-history/${id}`);
  return res.data;
};

export const createLoyaltyCustomer = async (payload) => {
  const res = await API.post(`loyalty-cards`, payload);
  return res.data;
};

export const updateLoyaltyCustomer = async (id, payload) => {
  const res = await API.put(`loyalty-cards/${id}`, payload);
  return res.data;
};

export const getLoyaltyCustomerDetails = async (id) => {
  const res = await API.get(`loyalty-cards/${id}`);
  return res.data;
};

export const changeLoyaltyCustomerStatus = async (id) => {
  const res = await API.post(`loyalty-cards/${id}/change-customer-status`);
  return res.data;
};

export const updatePointAdjustment = async (id, payload) => {
  const res = await API.post(`loyalty-cards/${id}/point-adjustment`, payload);
  return res.data;
};

export const deleteLoyaltyCustomer = async (id) => {
  const res = await API.delete(`loyalty-cards/${id}`);
  return res.data;
};

//  loyalty groups
export const getGroupList = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;

  const res = await API.get(
    `loyalty-card-groups?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data;
};

export const deleteLoyaltyGroup = async (id) => {
  const res = await API.delete(`loyalty-card-groups/${id}`);
  return res.data;
};

export const getGroupDetailsById = async (id) => {
  const res = await API.get(`loyalty-card-groups/${id}`);
  return res.data;
};

export const createGroup = async (payload) => {
  const res = await API.post(`loyalty-card-groups`, payload);
  return res.data;
};

export const editGroup = async (id, payload) => {
  const res = await API.put(`loyalty-card-groups/${id}`, payload);
  return res.data;
};

export const changeGroupStatus = async (id) => {
  const res = await API.put(`loyalty-card-groups/${id}/batch-status-change`);
  return res.data;
};

export const getMembers = async (id, query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `loyalty-card-groups/${id}/list-members?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data;
};

export const addGroupMembers = async (id, payload) => {
  const res = await API.post(`loyalty-card-groups/${id}/add-member`, payload);
  return res.data;
};

export const uploadMember = async (id, payload) => {
  const res = await API.post(
    `loyalty-card-groups/${id}/import-add-member`,
    payload
  );
  return res.data;
};

export const onChangeOwner = async (id, customer_id) => {
  const res = await API.get(
    `loyalty-card-groups/${id}/customers/${customer_id}/change-owner`
  );
  return res.data;
};
