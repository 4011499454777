import { API } from "../../../utils";

export const getLedgerAccountList = async (id, type) => {
  const res = await API.get(`ledger-account?accountable_id=${id}&type=${type}`);
  return res.data;
};

export const createLedgerAccount = async (payload) => {
  const res = await API.post(`ledger-account`, payload);
  return res.data;
};

export const updateLedgerAccount = async (payload) => {
  const res = await API.put(`ledger-account/split-percentage`, payload);
  return res.data;
};

export const deleteLedgerbyId = async (id) => {
  const res = await API.delete(`ledger-account/${id}`);
  return res.data;
};

export const getSystemTypeConfigList = async () => {
  const res = await API.get(`coas/system-config-list`);
  return res.data;
};

export const getPaymentTypeConfigList = async () => {
  const res = await API.get(`coas/payment-config-list`);
  return res.data;
};

export const getSaleTypeConfigList = async () => {
  const res = await API.get(`coas/sale-config-list`);
  return res.data;
};

export const updateCOAConfig = async (payload) => {
  const res = await API.put(`coas/update-config-list`, payload);
  return res.data;
};
export const getOtherConfigList = async () => {
  const res = await API.get(`coas/other-config-list`);
  return res.data;
};
