import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import { usePermission, useTabs } from "../../../shared";
import { useDebounce } from "use-debounce";
import {
  createSurcharges,
  deleteSurChargesById,
  editSurcharges,
  getSurcharges,
  getSurchargesDetails,
} from "../api";
import { useTax } from "../..";

export const useSurcharges = ({ load, SurchargesId, isList }) => {
  const navigate = useNavigate();
  const { appState } = useContext(AppContext);
  const { globalData } = appState;
  const [state, setState] = useImmer({
    isBusy: false,
    initialLoad: true,
    surchargesList: [],
    deleteModalOpen: false,
    selectedID: null,
    name: "",
    data: {
      name: "",
      amount: "",
      sale_type_id: [],
      tax_group_id: "",
    },
    filters: {
      isSearching: false,
      searchText: "",
    },
  });
  const { state: taxState } = useTax({ isList });
  const [debouncedText] = useDebounce(state.filters.searchText, 1000);
  const isCreateVisible = usePermission("surcharge-create");
  const isEditable = usePermission("surcharge-modify");
  const isDeletable = usePermission("surcharge-delete");
  useEffect(() => {
    if (load) {
      getSurChargesList();
    }
  }, [load]);
  const getSurChargesList = async (search = null) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = false;
      });
      const res = await getSurcharges(search);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.surchargesList = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  const onSaveSurcharges = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const data = {
        ...state.data,
        sale_type_id: state.data.sale_type_id.map((i) => i.id),
      };
      const res = await createSurcharges(data);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate("/surcharges");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const onUpdateSurcharges = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const data = {
        name: state.data.name,
        sale_type_id: state.data.sale_type_id.map((i) => i.id),
        amount: state.data.amount,
        tax_group_id: state.data.tax_group_id,
      };
      const res = await editSurcharges(id, data);
      if (res.success) {
        successMessage("Updated Successfully");
        navigate(`/surcharges`);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const getSurchargesDetailsById = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getSurchargesDetails(SurchargesId);
      if (res.data) {
        setState((draft) => {
          draft.data = { ...res.data, sale_type_id: res.data.sale_types };
          draft.name = res.data.name;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (SurchargesId) {
      getSurchargesDetailsById();
    }
  }, [SurchargesId]);
  const onChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };
  const onDeleteStoreSelect = (data) => {
    setState((draft) => {
      draft.data.sale_type_id = [
        ...state.data.sale_type_id.filter((value) => {
          return value !== data;
        }),
      ];
    });
  };
  const onChangeFilter = (e) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.filters[name] = value;
      draft.filters.isSearching = false;
    });
  };

  useEffect(() => {
    if (!state.initialLoad && !state.filters.isSearching) {
      getSurChargesList(debouncedText);
    }
  }, [debouncedText]);

  const handleModal = (data, id) => {
    setState((draft) => {
      draft.deleteModalOpen = data;
      draft.selectedID = id;
    });
  };

  const handleDeleteSurCharges = async () => {
    try {
      const res = await deleteSurChargesById(state.selectedID);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.surchargesList = draft.surchargesList.filter(
            (item) => item.id !== state.selectedID
          );
        });
        handleModal(false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  return {
    state,
    onSaveSurcharges,
    onChange,
    globalData,
    onDeleteStoreSelect,
    onUpdateSurcharges,
    isCreateVisible,
    isEditable,
    isDeletable,
    taxState,
    onChangeFilter,
    handleModal,
    handleDeleteSurCharges,
  };
};
