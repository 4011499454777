import * as React from "react";
import {
  ContentLayout,
  HeaderBreadcrumbs,
  Loader,
  ServerPaginatedTable,
} from "../../../shared";
import { Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import optInPic from "../../../assets/images/Icons/ic_tick_filled_blue.svg";
import optOutPic from "../../../assets/images/Icons/ic_cancel-opt-out.svg";
import externalLink from "../../../assets/images/Icons/ic_external_link_black.svg";
import ReviewListFilterSection from "../../ReviewsAndRatings/components/ReviewListFilterSection";
import { useProductRatingsDetails } from "../hooks";

export const ProductRatingsDetails = () => {
  const { productId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const breadcrumbsLinks = [
    {
      name: "Dashboard",
      href: "/",
    },
    {
      name: "Product Ratings",
      href: "/products-ratings",
    },
    {
      name: location.state?.product_name,
      href: "",
    },
  ];

  const ActionButtons = ({ row }) => {
    return (
      <IconButton>
        <img alt="details" src={externalLink} width="16px" height="16px" />
      </IconButton>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Review ID",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
      },
      {
        Header: "Submitted Date&Time",
        accessor: "submitted_date",
      },
      {
        Header: "Rating",
        accessor: "rating",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="4px" height="16px">
              <img alt="star" src="/icons/star-yellow.svg" />
              {row.original.rating}
            </Stack>
          );
        },
      },
      {
        Header: "Authorised By",
        accessor: "authorised_by",
        Cell: ({ value }) => {
          return value ?? "--";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          const label =
            value === 0 ? "Pending" : value === 1 ? "Approved" : "Rejected";
          const color =
            value === 0 ? "#C07302" : value === 1 ? "#0F875B" : "#C42B1C";
          const backgroundColor =
            value === 0 ? "#FFF4CE" : value === 1 ? "#E1FBF2" : "#FFE7E3";
          return (
            <Chip
              label={label}
              variant="filled"
              size="small"
              sx={{
                color,
                backgroundColor,
              }}
            />
          );
        },
      },
      {
        Header: "Publish Status",
        accessor: "published_status",
        align: "center",
        Cell: ({ row }) => {
          return (
            <Box
              mr="20px"
              component="img"
              src={row.original.published_status ? optInPic : optOutPic}
              alt={"published_status-" + row.original.id}
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons key={index} row={row} />;
        },
      },
    ],
    []
  );

  const {
    state,
    gotoPage,
    handleSortBy,
    handleTableSearch,
    setPageSize,
    handleApplyFilter,
    handleClearAllFilter,
    handleClearFilter,
    handleReviewFilterSelection,
    status,
    publishedStatusList,
    handleDateChangeApply,
    handleOpenDateRangeSelection,
  } = useProductRatingsDetails({
    defaultFilter: {
      product_id: productId ? productId : undefined,
    },
    table: "product-details",
  });

  React.useEffect(() => {
    if (!location.state?.name) {
      navigate("/products-ratings", { replace: true });
    }
  }, [location.state]);

  return (
    <React.Fragment>
      {location.state?.name ? (
        <ContentLayout
          title={
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
                {location.state.name}
              </Typography>
              <Stack direction="row" gap="4px" height="16px">
                <img alt="star" src="/icons/star-yellow.svg" />
              </Stack>
              <Typography>
                {location.state?.avg_rating}
                {` (${location.state?.num_of_reviews})`}
              </Typography>
            </Stack>
          }
          breadcrumbs={<HeaderBreadcrumbs links={breadcrumbsLinks} />}
        >
          <ServerPaginatedTable
            columns={columns}
            data={state.review.list}
            isLoading={state.review.isLoading}
            gotoPage={gotoPage}
            searchBar={true}
            defaultSearch={false}
            handleSort={handleSortBy}
            setPageSize={setPageSize}
            pagination={state.review.pagination}
            sortByGlobal={state.review.sortBy}
            filterContent={
              <InputControl
                type="table-search"
                name="search"
                placeholder="Search"
                value={state.review.search}
                onChange={handleTableSearch}
                onClear={() => handleTableSearch({ target: { value: "" } })}
              />
            }
            filterApplied={state.filter.appliedFilters}
            onAllFilterClear={handleClearAllFilter}
            onFilterClear={handleClearFilter}
            filterSection={
              <ReviewListFilterSection
                {...{
                  state,
                  publishedStatusList,
                  statusList: status,
                  ratingsList: state.rating.options,
                  handleReviewFilterSelection,
                  handleOpenDateRangeSelection,
                  handleDateChangeApply,
                  handleApplyFilter,
                }}
              />
            }
            onRowLink={(row) => `/products-ratings/review/details/${row.id}`}
            rowClickNewTab
          />
        </ContentLayout>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
};
