import { DraggableReactTable, Loader } from "../../../shared";
import * as React from "react";
import { Button, Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const CategoryProductsDetails = (props) => {
  const navigate = useNavigate();
  const { state, onCategoryProductOrderChange, setCurentTab, onSave } = props;
  const data =
    (state?.product?.products?.length > 0 &&
      state?.product?.products?.map((item, index) => ({
        ...item,
        id: index,
      }))) ||
    [];
  const ActionButtons2 = ({ id, editAction, label }) => {
    return (
      <Stack direction="row" gap="12px" alignItems="center">
        <div
          className="table-taxt-and-icon-link cursor"
          onClick={() => {
            setCurentTab("categoryDetails", "1");
            navigate(`/products/details/${id}`);
          }}
        >
          <span className="link-text">{label}</span>
          <div className="link-icon-wrap">
            <img
              alt="link"
              onClick={editAction}
              src="/icons/ic_external_link.svg"
            />
          </div>
        </div>
      </Stack>
    );
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "product_name",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <ActionButtons2
                id={row.original.product_id}
                label={row.original.product_name}
              />
            </Stack>
          );
        },
      },
      {
        Header: "PLU Code",
        accessor: "plu",
      },
    ],
    [data]
  );

  return (
    <>
      {state.product.isBusy ? (
        <Loader />
      ) : (
        <Box>
          <DraggableReactTable
            columns={columns}
            data={data}
            defaultSearch={false}
            onRowOrderChange={onCategoryProductOrderChange}
            disableDrag={false}
            tableTitle="Products"
            rowsSelection={false}
            maxHeight="343px"
            headerRightContent={
              <Button
                type="button"
                size="mediam"
                variant="contained"
                className="delte"
                onClick={onSave}
                disabled={!state.product.isChanged}
              >
                Save
              </Button>
            }
          />
        </Box>
      )}
    </>
  );
};
