import * as React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import { Loader } from "..";
import moment from "moment";

export const ReviewMessageDialog = (props) => {
  const { data, onSubmit, isLoading, state } = props;
  return (
    <>
      {false ? (
        <Loader />
      ) : (
        <Stack justifyContent="space-between" height="100%" mt={2}>
          <List disablePadding>
            <ListItem divider={true} disablePadding>
              <ListItemText
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  my: 0,
                  pl: 2,
                }}
                disableTypography={true}
              >
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    minWidth="160px"
                  >
                    Notification Content
                  </Typography>
                </Stack>
                <Stack
                  height="fit-content"
                  justifyContent="center"
                  p="10px"
                  direction="column"
                >
                  <Typography variant="subtitle2" fontWeight={400}>
                    {data.title}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    color="#636363"
                  >
                    {data.description}
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItem>
            <ListItem divider={true} disablePadding>
              <ListItemText
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  my: 0,
                  pl: 2,
                }}
                disableTypography={true}
              >
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    minWidth="160px"
                  >
                    Topic
                  </Typography>
                </Stack>
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    color="#636363"
                  >
                    {data.subject?.label}
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  my: 0,
                  pl: 2,
                }}
                disableTypography={true}
              >
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    minWidth="160px"
                  >
                    Scheduling
                  </Typography>
                </Stack>
                <Stack height="48px" justifyContent="center" p="10px">
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    color="#636363"
                  >
                    {data.schedule === 0
                      ? "Now"
                      : ` Send on ${moment(data.scheduled_date).format(
                        "DD MMM YYYY "
                      )}, ${moment(data.scheduled_time, "HH:mm:ss").format("hh:mm A")}`}
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItem>
          </List>
          <Stack flexDirection="row" justifyContent="right" gap={1}>
            {state.details.status !== 1 && (
              <Button
                variant="text"
                size="small"
                disabled={isLoading}
                onClick={() => onSubmit(0)}
              >
                Save Draft
              </Button>
            )}
            <Button
              variant="contained"
              size="small"
              disabled={isLoading}
              onClick={() => onSubmit(1)}
            >
              Publish
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
};
