import React from "react";
import { Route, Routes } from "react-router-dom";
import { AdminUsersList, AdminUsersDetails, CreateAndEditAdminUsers } from "..";
import { ProtectedRoute } from "../../shared";
import { UserFavouriteDetails } from './components'
export const AdminUsers = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<AdminUsersList />} />
        <Route element={<ProtectedRoute allowedRoute={["user-view"]} />}>
          <Route path="/details/:id" element={<AdminUsersDetails />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["user-modify"]} />}>
          <Route path="/edit/:id" element={<CreateAndEditAdminUsers />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["user-create"]} />}>
          <Route path="/create" element={<CreateAndEditAdminUsers />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["user-modify"]} />}>
          <Route path="/details/:id/favourite/:favid" element={<UserFavouriteDetails />} />
        </Route>
      </Routes>
    </>
  );
};
