import { API } from "../../utils";

export const getStore = async () => {
  const res = await API.get("lists/stores");
  return res.data;
};
export const getCategories = async () => {
  const res = await API.get("lists/categories");
  return res.data;
};
export const getProductsList = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `lists/products?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data;
};
export const getModifierGroups = async () => {
  const res = await API.get("lists/modifier-groups");
  return res.data;
};
export const getAvailabilityGroupsList = async () => {
  const res = await API.get("lists/availability-groups");
  return res.data;
};
export const getBundleGroupsList = async () => {
  const res = await API.get("lists/bundle-groups");
  return res.data;
};
export const getAccounts = async () => {
  const res = await API.get("lists/accounts");
  return res.data;
};
export const getFranchiseesList = async () => {
  const res = await API.get("lists/franchisees");
  return res.data;
};
export const getStates = async () => {
  const res = await API.get("lists/states");
  return res.data;
};
export const getSuburbs = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `lists/suburbs?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data;
};
export const getTerminals = async () => {
  const res = await API.get("lists/terminals");
  return res.data;
};
export const getKeypads = async () => {
  const res = await API.get("lists/keypads");
  return res.data;
};
export const getTaxGroups = async () => {
  const res = await API.get("lists/tax-groups");
  return res.data;
};
export const getPriceLevels = async () => {
  const res = await API.get("lists/price-levels");
  return res.data;
};
export const getRoles = async () => {
  const res = await API.get("lists/roles");
  return res.data;
};
export const getCustomers = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `lists/customers?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};
export const getUsers = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `lists/users?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};
export const getCoupon = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `lists/coupons?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};
