import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";
import { ProductRatingsDetails, ProductRatingsList } from "./containers";
import { ReviewDetails } from "../ReviewsAndRatings";

export const ProductRatings = () => {
  return (
    <>
      <Routes>
        <Route
          element={<ProtectedRoute allowedRoute={["review-rating-view"]} />}
        >
          <Route path="/" element={<ProductRatingsList />} />
          <Route
            path="/product/details/:productId"
            element={<ProductRatingsDetails />}
          />
          <Route path="review/details/:id" element={<ReviewDetails />} />
        </Route>
      </Routes>
    </>
  );
};
