export const presetColors = ["#A04D3D", "#5E7360", "#564845", "#F2F2F2"];
export const ratingOptions = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "4",
    value: "4",
  },
  {
    label: "5",
    value: "5",
  },
];
