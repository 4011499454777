import { Paper, Stack, Typography } from "@mui/material";
import { CustomTable, Loader } from "../../../shared";

export const PosPermissionSection = ({
  permissionList,
  columns,
  loading,
  rightSection = null,
}) => (
  <Stack component={Paper} justifyContent="space-between">
    <Stack
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      sx={{
        pt: "20px",
        pb: "5px",
        px: "22px",
      }}
    >
      <Typography
        sx={{
          width: "150px",
          fontWeight: 600,
          fontSize: "16px",
        }}
      >
        Permissions
      </Typography>
      {rightSection}
    </Stack>
    {loading ? (
      <Loader />
    ) : (
      <Stack sx={{ transform: "translateY(17px)" }}>
        <CustomTable
          columns={columns}
          data={permissionList}
          rowsSelection={false}
          searchBar={false}
          pagination={false}
          subRow={"permissions"}
        />
      </Stack>
    )}
  </Stack>
);
