import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticate } from "../hooks";
import {
  Box,
  Link,
  FormControl,
  Stack,
  Typography,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SimpleReactValidator from "simple-react-validator";
import InputControl from "../../../shared/components/InputControl";

export function Login() {
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const {
    signin,
    handleLogin,
    onChngeUser,
    toggleShowPassword,
    handleMouseDownPassword,
    isDisable,
  } = useAuthenticate();
  const navigate = useNavigate();

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const HandleForgot = () => {
    navigate("/authentication/forgotPassword");
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "95vh",
        }}
      >
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            if (validator.current.allValid()) {
              handleLogin();
            } else {
              validator.current.showMessages();
              forceUpdate(1);
            }
          }}
        >
          <Card align="center" sx={{ width: "424px", height: "430px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography sx={{ mt: 1.5 }} variant="h5" gutterBottom>
                Login
              </Typography>

              <Typography sx={{ mt: 0.5 }} variant="h7" gutterBottom>
                Sign in to your account to continue
              </Typography>
            </Box>
            <Box component="input-box" sx={{ display: "flex" }}>
              <FormControl sx={{ width: "376px", mb: 2 }} variant="outlined">
                {signin.errors && (
                  <Alert sx={2} severity="error">
                    {signin.errors}
                  </Alert>
                )}
              </FormControl>

              <FormControl sx={{ mt: 1.5, width: "376px" }}>
                <InputControl
                  label="Email Address"
                  name="email"
                  placeholder="Enter Email"
                  onChange={onChngeUser}
                  value={signin.data.email || null}
                  error={validator.current.message(
                    "email",
                    signin.data.email,
                    "required|email"
                  )}
                  helperText={validator.current.message(
                    "email",
                    signin.data.email,
                    "required|email"
                  )}
                />
              </FormControl>

              <Stack direction="row" alignItems="center">
                <Box sx={{ flexGrow: 1 }}>
                  <FormControl
                    sx={{ mt: 1, width: "376px" }}
                    variant="outlined"
                  >
                    <InputControl
                      name="password"
                      label="Password"
                      type="password"
                      disabled={isDisable}
                      placeholder="Enter password"
                      showPassword={signin.data.showPassword}
                      onChange={onChngeUser}
                      value={signin.data.password || null}
                      error={validator.current.message(
                        "password",
                        signin.data.password,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "password",
                        signin.data.password,
                        "required"
                      )}
                      onClick={(e) => toggleShowPassword("showPassword")}
                      onMouseDown={handleMouseDownPassword}
                    />
                  </FormControl>
                </Box>
              </Stack>
              <FormControl sx={{ width: "376px", mt: 1 }} variant="outlined">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={signin.data.rememberMe}
                        onChange={(e) =>
                          onChngeUser({
                            target: {
                              name: "rememberMe",
                              value: e.target.checked,
                            },
                          })
                        }
                      />
                    }
                    label="Remember Me"
                  />
                </FormGroup>
              </FormControl>

              <LoadingButton
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={signin.isBusy}
                sx={{ width: 376 }}
              >
                Login
              </LoadingButton>
              <Box
                sx={{
                  pt: 2,
                  mb: 3,
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "blue",
                }}
              >
                <Link
                  sx={{ mb: 4 }}
                  underline="none"
                  component="button"
                  variant="body2"
                  onClick={HandleForgot}
                >
                  <b>Forgot password?</b>
                </Link>
              </Box>
            </Box>
          </Card>
        </form>
      </Box>
    </>
  );
}
