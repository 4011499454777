import { API, serializeParams } from "../../../utils";

export const getReviewListApi = async (params) => {
  const response = await API.get("review-ratings", {
    params,
    paramsSerializer: serializeParams,
  });

  return response.data;
};

export const getProductReviewsListApi = async (params) => {
  const response = await API.get("product-ratings", {
    params,
    paramsSerializer: serializeParams,
  });

  return response.data;
};

// Review details API
export const getReviewDetailsApi = async (reviewId) => {
  const response = await API.get(`review-ratings/${reviewId}`);
  return response.data;
};

// Change review status API
export const updateReviewStatusApi = async ({ reviewId, status }) => {
  const response = await API.put(`review-ratings/${reviewId}/status/${status}`);
  return response.data;
};

export const rejectReviewApi = async ({
  reviewId,
  status,
  rejection_reason,
}) => {
  const response = await API.put(
    `review-ratings/${reviewId}/status/${status}`,
    {
      rejection_reason,
    }
  );
  return response.data;
};

// Change review's  published-status API
export const updateReviewPublishedStatusApi = async ({ reviewId }) => {
  const response = await API.put(`review-ratings/${reviewId}/published-status`);
  return response.data;
};

export const deleteReviewApi = async ({ reviewId }) => {
  const response = await API.delete(`review-ratings/${reviewId}`);
  return response.data;
};

export const exportReviewApi = async (params) => {
  const response = await API.get(`reviews/export`, {
    params: params,
    paramsSerializer: serializeParams,
  });
  return response;
};
