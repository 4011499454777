import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import { usePermission, useTabs } from "../../../shared";
import { useDebouncedCallback } from "use-debounce";
import qs from "qs";
import { deleteGiftCardById, getBatchwiseCard } from "../api/GiftCardApi";
import moment from "moment";
import { TableStateContext } from "../../../store";

export const usePreloadedCardList = ({ isBatchCards }) => {
  const navigate = useNavigate();
  const { appState } = useContext(AppContext);
  const { globalData } = appState;
  const { setCurentTab, resetCurrentTabs } = useTabs();
  const { giftCardGroupDetails: currentTab, giftCardList } =
    appState.currentTabs;
  const PAGE_NAME = "PreloadedCard";
  const {
    filterState: {
      params: { PreloadedCard },
    },
    filterStateDispatch,
  } = useContext(TableStateContext);
  const isEditable = usePermission("gift-cards-modify");
  const isDeletable = usePermission("gift-cards-delete");
  const isCreateVisible = usePermission("gift-cards-create");
  const isVisible = usePermission("gift-cards-view");
  const [state, setState] = useImmer({
    isBusy: true,
    isOpen: false,
    Loading: false,
    isModalOpen: false,
    isLoading: true,
    status: 1,
    id: "",
    email: "",
    initialLoad: true,
    isSaveButtonBusy: false,
    allBatchCards: [],
    allBatchCardssortBy: [],
    cardtransactionDetails: [],
    allGiftCardGroups: [],
    redemptionDetails: [],
    cardDetails: {},
    giftCardDetails: [],
    allCards: [],
    batchCards: [],
    condition_type: 1,
    preloadedpagination: {
      pageIndex: 1,
      pageSize: 25,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    preloadedsortBy: [],
    sortBy: [],
    disableApplyButton: false,
    preloadedFilters: {
      isSearching: false,
      all: [],
      searchText: "",
      created_date: "",
      amount_limit_operator: "",
      amount_limit_value: "",
      validity_limit_value: "",
      validity_limit_operator: "",
      validity_limit_value: "",
      created_by: "",
    },
    data: {
      card_type: "",
      batch_name: "",
      quantity: "",
      amount: "",
      validity: "",
      is_year_month: 0,
      stores: [],
      is_pre_activated: 0,
      seller_store_id: null,
    },
  });

  const debounced = useDebouncedCallback((value) => {
    const {
      created_date,
      amount_limit_operator,
      amount_limit_value,
      validity_limit_operator,
      validity_limit_value,
      created_by,
    } = state.preloadedFilters;
    const { preloadedsortBy } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.preloadedFilters },
    });
    getBatchwiseCards({
      created_date,
      amount_limit_operator,
      amount_limit_value,
      validity_limit_operator,
      validity_limit_value,
      created_by,
      searchText: value,
      pagination: {
        pageIndex: 1,
        pageSize: state.preloadedpagination.pageSize,
      },
      preloadedsortBy,
    });
  }, 1000);

  useEffect(() => {
    if (giftCardList === "2") {
      const {
        created_date,
        amount_limit_operator,
        amount_limit_value,
        validity_limit_operator,
        validity_limit_value,
        created_by,
        searchText,
      } = PreloadedCard.filterApplied;
      const { pagination, sortBy } = PreloadedCard;

      setState((draft) => {
        draft.preloadedFilters = PreloadedCard.filterApplied;
        draft.preloadedpagination = pagination;
        draft.preloadedsortBy = sortBy;
      });

      getBatchwiseCards({
        created_date,
        amount_limit_operator,
        amount_limit_value,
        validity_limit_operator,
        validity_limit_value,
        created_by,
        searchText,
        pagination: {
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
        },
        preloadedsortBy: sortBy,
      });
    }
  }, [giftCardList]);
  useEffect(() => {
    if (giftCardList === "1") {
      setState((draft) => {
        draft.data.card_type = "";
      });
    }
    if (giftCardList === "2") {
      setState((draft) => {
        draft.data.card_type = 1;
      });
    }
    if (giftCardList === "3") {
      setState((draft) => {
        draft.data.card_type = 2;
      });
    }
  }, [giftCardList]);
  const getBatchwiseCards = async ({
    created_date = "",
    amount_limit_operator = "",
    amount_limit_value = "",
    validity_limit_operator = "",
    validity_limit_value = "",
    created_by = "",
    searchText = "",
    pagination,
    preloadedsortBy = [],
  }) => {
    const tab = giftCardList === "2" ? 1 : 2;

    let params = {};
    if (created_date && toString(created_date)) {
      params = { ...params, created_date: created_date.label };
    }
    if (amount_limit_operator !== "") {
      params = {
        ...params,
        amount_limit_operator: amount_limit_operator.value,
      };
    }
    if (amount_limit_value !== "") {
      params = { ...params, amount_limit_value: amount_limit_value.value };
    }
    if (validity_limit_operator !== "") {
      params = {
        ...params,
        validity_limit_operator: validity_limit_operator.value,
      };
    }
    if (validity_limit_value !== "") {
      params = { ...params, validity_limit_value: validity_limit_value.value };
    }
    if (created_by !== "") {
      params = { ...params, created_by: created_by.value };
    }
    if (searchText) {
      params = { ...params, search: searchText };
    }
    if (preloadedsortBy.length > 0) {
      const sortByParams = preloadedsortBy.reduce(
        (obj, item) =>
          Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
        {}
      );
      params = {
        ...params,
        sort: sortByParams,
      };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
        draft.disableApplyButton = true;
      });
      const res = await getBatchwiseCard(query, tab, pagination);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.batchCards = res.data.gift_cards_groups;
          draft.preloadedpagination = res.data.pagination;
          draft.initialLoad = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
        draft.disableApplyButton = false;
      });
    }
  };

  const handleDeleteGiftCard = async (type) => {
    try {
      const res = await deleteGiftCardById(state.id);
      if (res.success) {
        successMessage("Gift Card Group Deleted.");
        setState((draft) => {
          draft.batchCards = draft.batchCards.filter(
            (item) => item.id !== state.id
          );
        });

        handleDeleteModal("details", false, null);
        navigate("/gift-card");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleDeleteModal = (type, state, data) => {
    switch (type) {
      case "details":
        setState((draft) => {
          draft.isOpen = state;
          draft.id = data;
        });
        break;
      case "list":
        setState((draft) => {
          draft.isOpen = state;
          draft.id = data;
        });
        break;
    }
  };

  const onChangePreloadedFilter = (e) => {
    setState((draft) => {
      draft.preloadedFilters.searchText = e.target.value;
      draft.preloadedFilters.isSearching = false;
    });
    debounced(e.target.value);
  };
  const onSeachPreloadedClear = () => {
    setState((draft) => {
      draft.preloadedFilters.searchText = "";
      draft.preloadedFilters.isSearching = true;
    });
    debounced("");
  };
  const handleFilterPreloadedClear = (type, value) => {
    const { searchText } = state.preloadedFilters;
    const { preloadedsortBy } = state;
    if (type === "single") {
      if (value.type === "created_date") {
        setState((draft) => {
          draft.preloadedFilters.created_date = "";
        });
      } else if (value.type === "amount_limit_operator") {
        setState((draft) => {
          draft.preloadedFilters.amount_limit_operator = "";
        });
      } else if (value.type === "amount_limit_value") {
        setState((draft) => {
          draft.preloadedFilters.amount_limit_value = "";
        });
      } else if (value.type === "validity_limit_operator") {
        setState((draft) => {
          draft.preloadedFilters.validity_limit_operator = "";
        });
      } else if (value.type === "validity_limit_value") {
        setState((draft) => {
          draft.preloadedFilters.validity_limit_value = "";
        });
      } else if (value.type === "created_by") {
        setState((draft) => {
          draft.preloadedFilters.created_by = "";
        });
      }
      setState((draft) => {
        draft.disableApplyButton = false;
      });
    } else {
      getBatchwiseCards({
        searchText,
        pagination: {
          pageIndex: 1,
          pageSize: state.preloadedpagination.pageSize,
        },
        preloadedsortBy,
      });
      const initialValue = {
        isSearching: false,
        all: [],
        searchText: searchText,
        created_date: "",
        amount_limit_operator: "",
        amount_limit_value: "",
        validity_limit_value: "",
        validity_limit_operator: "",
        validity_limit_value: "",
        created_by: "",
      };
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initialValue,
      });
      setState((draft) => {
        draft.preloadedFilters.all = [];
        draft.preloadedFilters.created_date = "";
        draft.preloadedFilters.amount_limit_operator = "";
        draft.preloadedFilters.amount_limit_value = "";
        draft.preloadedFilters.validity_limit_operator = "";
        draft.preloadedFilters.validity_limit_value = "";
        draft.preloadedFilters.created_by = "";
      });
    }
  };
  const handlePreloadedApplyFilter = () => {
    const {
      created_date,
      amount_limit_operator,
      amount_limit_value,
      validity_limit_operator,
      validity_limit_value,
      created_by,
      searchText,
    } = state.preloadedFilters;
    const { preloadedsortBy } = state;
    getBatchwiseCards({
      created_date,
      amount_limit_operator,
      amount_limit_value,
      validity_limit_operator,
      validity_limit_value,
      created_by,
      searchText,
      pagination: {
        pageIndex: 1,
        pageSize: state.preloadedpagination.pageSize,
      },
      preloadedsortBy,
    });

    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: state.preloadedFilters,
      },
    });
  };
  const handlePreloadedFilter = (e, value, type) => {
    if (type === "created_date") {
      setState((draft) => {
        draft.preloadedFilters.created_date = {
          value: e,
          label: moment(e).format("yyy-MM-DD"),
          type: "created_date",
        };
      });
    } else if (type === "amount_limit_operator") {
      setState((draft) => {
        draft.preloadedFilters.amount_limit_operator = {
          value: value.props.value,
          label: value.props.children,
          type: "amount_limit_operator",
        };
      });
    } else if (type === "amount_limit_value") {
      setState((draft) => {
        draft.preloadedFilters.amount_limit_value = {
          value: e,
          label: e,
          type: "amount_limit_value",
        };
      });
    } else if (type === "validity_limit_operator") {
      setState((draft) => {
        draft.preloadedFilters.validity_limit_operator = {
          value: value.props.value,
          label: value.props.children,
          type: "validity_limit_operator",
        };
      });
    } else if (type === "validity_limit_value") {
      setState((draft) => {
        draft.preloadedFilters.validity_limit_value = {
          value: e,
          label: e,
          type: "validity_limit_value",
        };
      });
    } else if (type === "created_by") {
      setState((draft) => {
        draft.preloadedFilters.created_by = {
          value: value.value,
          label: value.label,
          type: "created_by",
        };
      });
    }
    setState((draft) => {
      draft.disableApplyButton = false;
    });
  };
  useEffect(() => {
    setState((draft) => {
      draft.preloadedFilters.all = [
        state.preloadedFilters.created_date,
        state.preloadedFilters.amount_limit_operator,
        state.preloadedFilters.amount_limit_value,
        state.preloadedFilters.validity_limit_operator,
        state.preloadedFilters.validity_limit_value,
        state.preloadedFilters.created_by,
      ].filter((el) => Object.keys(el).length);
    });
  }, [
    state.preloadedFilters.created_date,
    state.preloadedFilters.amount_limit_operator,
    state.preloadedFilters.amount_limit_value,
    state.preloadedFilters.validity_limit_operator,
    state.preloadedFilters.validity_limit_value,
    state.preloadedFilters.created_by,
  ]);

  const gotopreloadedPage = (page) => {
    const {
      created_date,
      amount_limit_operator,
      amount_limit_value,
      validity_limit_operator,
      validity_limit_value,
      created_by,
      searchText,
    } = state.preloadedFilters;
    const { preloadedsortBy } = state;
    getBatchwiseCards({
      created_date: created_date,
      amount_limit_operator: amount_limit_operator,
      amount_limit_value: amount_limit_value,
      validity_limit_operator: validity_limit_operator,
      validity_limit_value: validity_limit_value,
      created_by: created_by,
      searchText: searchText,
      pagination: {
        pageIndex: page,
        pageSize: state.preloadedpagination.pageSize,
      },
      preloadedsortBy,
    });
    setState((draft) => {
      draft.preloadedpagination.pageIndex = page;
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
  };

  const setpreloadedPageSize = (e) => {
    const {
      created_date,
      amount_limit_operator,
      amount_limit_value,
      validity_limit_operator,
      validity_limit_value,
      created_by,
      searchText,
    } = state.preloadedFilters;
    const { preloadedsortBy } = state;
    getBatchwiseCards({
      created_date: created_date,
      amount_limit_operator: amount_limit_operator,
      amount_limit_value: amount_limit_value,
      validity_limit_operator: validity_limit_operator,
      validity_limit_value: validity_limit_value,
      created_by: created_by,
      searchText: searchText,
      pagination: {
        pageIndex: state.preloadedpagination.pageIndex,
        pageSize: +e.target.value,
      },
      preloadedsortBy,
    });
    setState((draft) => {
      draft.preloadedpagination.pageSize = +e.target.value;
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
  };
  const handlepreloadedSort = (preloadedsortBy) => {
    const {
      created_date,
      amount_limit_operator,
      amount_limit_value,
      validity_limit_operator,
      validity_limit_value,
      created_by,
      searchText,
    } = state.preloadedFilters;
    const { preloadedpagination } = state;
    getBatchwiseCards({
      created_date: created_date,
      amount_limit_operator: amount_limit_operator,
      amount_limit_value: amount_limit_value,
      validity_limit_operator: validity_limit_operator,
      validity_limit_value: validity_limit_value,
      created_by: created_by,
      searchText: searchText,
      pagination: preloadedpagination,
      preloadedsortBy,
    });
    filterStateDispatch({
      type: "UPDATE_SORT",
      page: PAGE_NAME,
      value: preloadedsortBy,
    });
  };

  return {
    state,
    setCurentTab,
    currentTab,
    globalData,
    resetCurrentTabs,
    handleDeleteModal,
    handleDeleteGiftCard,
    handlePreloadedFilter,
    handleFilterPreloadedClear,
    handlePreloadedApplyFilter,
    onChangePreloadedFilter,
    onSeachPreloadedClear,
    isEditable,
    isDeletable,
    handlepreloadedSort,
    gotopreloadedPage,
    setpreloadedPageSize,
  };
};
