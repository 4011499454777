import * as React from "react";
import {
  ContentLayout,
  CustomTable,
  CustomeDialog,
  DeleteModal,
  HeaderBreadcrumbs,
  Loader,
} from "../../../shared";
import {
  Box,
  Button,
  Card,
  Stack,
  Typography,
  Grid,
  Switch,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import AddIcon from "@mui/icons-material/Add";
import { CategoryProductDialog } from "../../../shared";
import { useCartProductList } from "../hooks/useCartProductList";

export const CartProductList = () => {
  // Custom hook
  const {
    state,
    columns,
    breadcrumbsLink,
    handleProductSearch,
    handleToggleStatus,
    handleTitleUpdatesMode,
    handleToggleDeleteModal,
    handleProductDialog,
    handleTitleChanges,
    handleCategorySelect,
    handleAddCartProduct,
  } = useCartProductList();

  const {
    cartProducts,
    isOpenDeleteDialog,
    isBusy,
    filteredProductsCategory,
    selectedItems,
    dialogOpen,
    isModalContentLoad,
    isEditMode,
    title,
  } = state;

  return (
    <>
      <ContentLayout
        title="Cart Product"
        breadcrumbs={<HeaderBreadcrumbs heading="" links={breadcrumbsLink} />}
      >
        {false ? (
          <Loader />
        ) : (
          <>
            <Card
              sx={{
                padding: "32px 18px",
                marginBottom: "20px",
                minHeight: "110px",
              }}
            >
              <Grid container>
                <Grid item sm={3}>
                  <Typography variant="subtitle1">Title</Typography>
                </Grid>

                <Grid item sm={9}>
                  <Stack gap="24px">
                    <Stack direction="row" gap="20px">
                      <InputControl
                        type="text"
                        label="Title"
                        value={title}
                        disabled={!isEditMode}
                        onChange={handleTitleChanges}
                      />
                      <Button
                        sx={{ minWidth: "65px" }}
                        onClick={() => handleTitleUpdatesMode(isEditMode)}
                      >
                        {isEditMode ? "Save" : "Edit"}
                      </Button>
                    </Stack>

                    <Stack direction="row">
                      <Box sx={{ width: "775px" }}>
                        <Typography variant="subtitle1">Status</Typography>
                        {/* <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </Typography> */}
                      </Box>
                      <Switch
                        checked={state.status}
                        onChange={handleToggleStatus}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ minWidth: "38px", minHeight: "38px" }}
                      ></Switch>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Card>

            <CustomTable
              data={cartProducts}
              columns={columns}
              searchBar={false}
              isLoading={isBusy}
              tableTitle="Products"
              onRowLink={(e) => `/products/details/${e.id}`}
              headerRightContent={
                <Button
                  type="button"
                  sx={{ minWidth: "141px" }}
                  startIcon={<AddIcon />}
                  onClick={handleProductDialog}
                >
                  Add Product
                </Button>
              }
            />
          </>
        )}
      </ContentLayout>

      <CustomeDialog
        open={state.dialogOpen}
        handleClose={handleProductDialog}
        PaperProps={{
          sx: { width: "423px", height: "535px", p: "16px" },
        }}
        title=" Select Products"
        content={
          isModalContentLoad ? (
            <Box
              sx={{
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Loader />
            </Box>
          ) : (
            <CategoryProductDialog
              Items={filteredProductsCategory}
              onSelect={handleCategorySelect}
              selectedItems={selectedItems}
              onSubmit={handleAddCartProduct}
              onSearch={handleProductSearch}
              existedProductList={cartProducts}
            />
          )
        }
      />

      {/* Handle delete dialog */}
      <DeleteModal
        open={isOpenDeleteDialog}
        handleClose={handleToggleDeleteModal}
        onConfirm={() => handleToggleDeleteModal({ confirm: true })}
      />
    </>
  );
};
