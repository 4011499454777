import { DeleteModal, ServerPaginatedTable, useDropdownApis } from "../../../shared";
import * as React from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useNavigate } from "react-router-dom";
import { arraySort, sortItems } from "../../../utils";
import moment from "moment";
import { useOpenCardsList } from "../hooks";
import { AsyncPaginate } from "react-select-async-paginate";

export const OpenCards = (prop) => {
  const {
    state,
    currentTab,
    setCurentTab,
    globalData,
    handleDeleteModal,
    handleDeleteGiftCard,
    handlePreloadedFilter,
    handleFilterPreloadedClear,
    handlePreloadedApplyFilter,
    onChangePreloadedFilter,
    onSeachPreloadedClear,
    isEditable,
    isDeletable,
    handlepreloadedSort,
    gotopreloadedPage,
    setpreloadedPageSize,
  } = useOpenCardsList({
    isBatchCards: true
  });

  const { handleGetUsers } = useDropdownApis({});

  const navigate = useNavigate();
  const ActionButtons = ({ row, id, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
        <img
          alt="details"
          src="/icons/arrow_forward.svg"
          onClick={() => {
            setCurentTab("giftCardGroupDetails", "1");
            navigate(`/gift-card/details/${row.id}`);
          }}
        />
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Created Date",
        accessor: "created_at",
        Cell: ({ value }) => {
          return (
            <Stack direction="row">{moment(value).format("DD-MM-YYYY")}</Stack>
          );
        },
      },
      {
        Header: "Batch Name",
        accessor: "batch_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },

      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Validity",
        accessor: "validity",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap={1}>
              <Typography fontWeight={400} fontSize="14px">
                {row?.original?.is_year_month === 0
                  ? row?.original?.validity / 365
                  : row?.original?.validity / 30}
              </Typography>
              <Typography fontWeight={400} fontSize="14px">
                {row?.original?.is_year_month === 0 ? "Year" : "Month"}
              </Typography>
            </Stack>
          );
        },
      },
      {
        Header: "Created By",
        accessor: "created_by",
      },

      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              row={row}
              deleteAction={() => {
                handleDeleteModal("list", true, row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: "2px line #000000",
      height: "48px",
      width: "237px",
    }),
    option: (provided, state) => ({
      ...provided,
      maxHeight: "239px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
  };

  return (
    <>
      <ServerPaginatedTable
        columns={columns}
        data={state?.batchCards || []}
        isLoading={state?.isBusy}
        defaultSearch={false}
        filterApplied={state.preloadedFilters.all}
        onFilterClear={(e) => handleFilterPreloadedClear("single", e)}
        onAllFilterClear={(e) => handleFilterPreloadedClear("all")}
        gotoPage={gotopreloadedPage}
        setPageSize={setpreloadedPageSize}
        key={state?.preloadedsortBy}
        pagination={state.preloadedpagination}
        sortByGlobal={state.preloadedsortBy}
        handleSort={handlepreloadedSort}
        filterContent={
          <>
            <InputControl
              type="table-search"
              value={state.preloadedFilters.searchText}
              onChange={(e) => onChangePreloadedFilter(e)}
              onClear={onSeachPreloadedClear}
            />
          </>
        }
        filterSection={
          <>
            <Stack direction="row" gap="20px">
              <InputControl
                type="datePicker"
                name="created_date"
                label="Created Date"
                value={state.preloadedFilters.created_date?.value || null}
                onChange={(e, v) => handlePreloadedFilter(e, v, "created_date")}
                inputFormat="dd/MM/yyyy"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                disableFuture
                sx={{
                  minWidth: "239.5px",
                }}
              />

              <AsyncPaginate
                debounceTimeout={1000}
                placeholder="Created By"
                className="AsyncPaginate-dropdown"
                menuPlacement="bottom"
                value={state?.filters?.batch_name}
                loadOptions={async (e, p, { page }) => {
                  return await handleGetUsers({
                    search: e,
                    page,
                  });
                }}
                onChange={(e, v) => {
                  handlePreloadedFilter(
                    {
                      target: {
                        name: "created_by",
                        value: e.value || null,
                      },
                    },
                    e,
                    "created_by"
                  );
                }}
                additional={{
                  page: 1,
                }}
                styles={customStyles}
              />
            </Stack>
            <Button
              type="button"
              size="medium"
              variant="text"
              color="info"
              sx={{
                minWidth: "40px",
                p: "8px",
              }}
              onClick={() => handlePreloadedApplyFilter()}
              disabled={state.disableApplyButton}
            >
              Apply
            </Button>
          </>
        }
        onRowLink={(e) => {
          return `/gift-card/details/${e.id}`;
        }}
        onRowAction={(e) => {
          setCurentTab("giftCardGroupDetails", "1");
        }}
      />
      <DeleteModal
        open={state.isOpen}
        handleClose={() => handleDeleteModal("list", false, null)}
        onConfirm={() => handleDeleteGiftCard()}
      />
    </>
  );
};
