import { API } from "../../../utils";

export const getEndofDay = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(`end-of-days?page=${pageIndex}&per_page=${pageSize}`, query);
  return res.data;
};

export const createEndofDays = async (payload) => {
  const res = await API.post(`end-of-days`, payload);
  return res.data;
};

export const getTerminalListToEndofday = async (id, query) => {
  //crete/edit
  const res = await API.get(
    `terminals?store[]=${id}&status=${1}&connection_status=${1}`
  );
  return res.data;
};
export const getEndofDayDetails = async (id) => {
  const res = await API.get(`end-of-days/${id}`);
  return res.data;
};
export const getEndofDaySummary = async (id) => {
  const res = await API.get(`end-of-days/${id}/summary`);
  return res.data;
};

export const editEndofDay = async (id, payload) => {
  const res = await API.put(`end-of-days/${id}`, payload);
  return res.data;
};
export const getEODSummary = async (payload) => {
  const res = await API.post(`end-of-days/orders-summary`, payload);
  return res.data;
};
//list
export const getPairedTerminalListToEndofday = async (query) => {
  const res = await API.get(`terminals`, query);
  return res.data;
};
