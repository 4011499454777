import { API } from "../../../utils";

export const getAdminUsersList = async (query) => {
  const res = await API.get(`users`, query);
  return res.data;
};

export const getUserDetailsById = async (id,) => {
  const res = await API.get(`users/${id}`);
  return res.data;
};

export const deleteUserById = async (id) => {
  const res = await API.delete(`users/${id}`);
  return res.data;
};

export const resetPasswordById = async (payload, id) => {
  const res = await API.put(`users/${id}/reset/password`, payload);
  return res.data;
};

export const assignStoresToUser = async (payload, id) => {
  const res = await API.put(`users/${id}/store`, payload);
  return res.data;
};
export const deleteStoresFromUser = async (userId, storeId) => {
  const res = await API.delete(`users/${userId}/store/${storeId}`);
  return res.data;
};

export const assignRolesToUser = async (payload, id) => {
  const res = await API.put(`users/${id}/role`, payload);
  return res.data;
};

export const deleteRolesFromUser = async (userId, roleId) => {
  const res = await API.delete(`users/${userId}/role/${roleId}`);
  return res.data;
};

export const createUser = async (payload) => {
  const res = await API.post(`users`, payload);
  return res.data;
};
export const updateUser = async (payload, id) => {
  const res = await API.put(`users/${id}`, payload);
  return res.data;
};

export const getTimezoneList = async () => {
  const res = await API.get("timezones");
  return res.data;
};

export const sendEmailInvitation = async (payload, query) => {
  const res = await API.post("users/invitation", payload, query);
  return res.data;
};

export const getUserFavoriteList = async (id) => {
  const res = await API.get(`users/${id}/favourites`);
  return res.data;
};

export const createUserFavGroup = async (id, payload) => {
  const res = await API.post(`users/${id}/favourites`, payload);
  return res.data;
};

export const deleteUserFavById = async (UserId, favId) => {
  const res = await API.delete(`users/${UserId}/favourites/${favId}`);
  return res.data;
};

export const getUserFavoriteDetails = async (UserId, favId) => {
  const res = await API.get(`users/${UserId}/favourites/${favId}`);
  return res.data;
};

export const assignProductsToUserFav = async (UserId, favId, payload) => {
  const res = await API.post(`users/${UserId}/favourites/${favId}/products`, payload);
  return res.data;
};

export const deleteUserFavProduct = async (UserId, favId, proId) => {
  const res = await API.delete(`users/${UserId}/favourites/${favId}/products/${proId}`);
  return res.data;
};

export const updateUserFavGroup = async (UserId, favId, payload) => {
  const res = await API.put(`users/${UserId}/favourites/${favId}`, payload);
  return res.data;
};

export const changeUserFevProductOrder = async (UserId, favId, payload) => {
  const res = await API.post(`users/${UserId}/favourites/${favId}/products-order`, payload);
  return res.data;
};

export const changeUserFevOrder = async (UserId, payload) => {
  const res = await API.post(`users/${UserId}/favourites/change-order`, payload);
  return res.data;
};