import { API } from "../../../utils";

export const getOptionalGroup = async (search, control_type) => {
  let url = "optional-groups?";
  if (search) {
    url += `&search=${search}`;
  }
  if (control_type && control_type !== "all") {
    url += `&control_type=${control_type}`;
  }

  const res = await API.get(url);
  return res.data;
};

export const getOptionalGroupDetailsById = async (id) => {
  const res = await API.get(`optional-groups/${id}`);
  return res.data;
};

export const createOptionalGroup = async (payload) => {
  const res = await API.post(`optional-groups`, payload);
  return res.data;
};

export const editOptionalGroup = async (id, payload) => {
  const res = await API.put(`optional-groups/${id}`, payload);
  return res.data;
};

export const deleteOptionalGroupById = async (id) => {
  const res = await API.delete(`optional-groups/${id}`);
  return res.data;
};
export const assignProductToOptionalGroup = async (payload, id) => {
  const res = await API.post(`optional-groups/${id}/products`, payload);
  return res.data;
};
export const deleteProductFromOptionalGroup = async (optionalId, productId) => {
  const res = await API.delete(
    `optional-groups/${optionalId}/products/${productId}`
  );
  return res.data;
};
export const getCategorywiseProductsInOptionalgroup = async () => {
  const res = await API.get(`product/list?productType=modifier`);
  return res.data;
};

export const changeOGProductOrder = async (id, payload) => {
  const res = await API.post(`optional-groups/${id}/products-order `, payload);
  return res.data;
};
