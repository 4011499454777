import {
  ContentLayout,
  CustomDetailsList,
  DeleteModal,
  Loader,
  PageNotFound,
  CustomTable,
  CheckBoxListDialog,
  CustomeDialog,
  CategoryProductDialog,
  HeaderBreadcrumbs,
  DraggableReactTable,
} from "../../../shared";
import * as React from "react";
import { Button, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import { useOptionalGroup } from "../hooks/useOptionalGroup";
import { sortItems } from "../../../utils";

export const OptionalGroupDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    state,
    isEditable,
    isDeletable,
    toggleModal,
    handleDeleteOptionalGroup,
    handleModal,
    HandleFilterOptionalGroup,
    handleOptionalGroupSelect,
    handleAssignProducttoOptionalGroup,
    handleDeleteProducts,
    DeleteModals,
    globalData,
    handleSelectAllProducts,
    onProductsOrderChange,
  } = useOptionalGroup({
    isOptionalGroup: false,
    optionalId: id,
    isProduct: true,
  });
  const ActionButtons = ({ id, editAction, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Products",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "PLU Code",
        accessor: "plu",
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => {
                DeleteModals(true, row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );

  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }

  return (
    <>
      <ContentLayout
        title={state.optionalGroupName}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Modifier Groups", href: "/optionalGroup" },
              {
                name: state?.optionalGroupName || "",
              },
            ]}
          />
        }
        rightContent={
          <>
            {isDeletable && (
              <Button
                type="button"
                size="medium"
                variant="text"
                className="delte"
                onClick={() => toggleModal(id, "detaials")}
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
              >
                <img alt="delete" src="/icons/ic_delete_blue.svg" />
              </Button>
            )}
            {isEditable && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                flex-direction="right"
                onClick={() => navigate(`/optionalGroup/details/edit/${id}`)}
              >
                Edit
              </Button>
            )}
          </>
        }
      >
        {state.isBusy ? (
          <Loader />
        ) : (
          <Box>
            <DraggableReactTable
              columns={columns}
              data={state.optionalGroupDetails.optionalGroupList}
              searchBar={false}
              tableTitle="Modifiers"
              rowsSelection={false}
              onRowOrderChange={onProductsOrderChange}
              maxHeight="220px"
              headerRightContent={
                <Button
                  type="button"
                  size="medium"
                  variant="text"
                  className="delte"
                  startIcon={<AddIcon />}
                  onClick={() => handleModal(true)}
                  sx={{
                    minWidth: "40px",
                    p: "8px",
                    border: "1px solid rgba(21, 96, 212, 0.12)",
                  }}
                >
                  Add Product
                </Button>
              }
            />

            <CustomeDialog
              open={state.optionalGroupDetails.isOpen}
              handleClose={() => handleModal(false)}
              PaperProps={{
                sx: { width: "423px", height: "535px", p: "16px" },
              }}
              title="Products"
              content={
                <CategoryProductDialog
                  Items={state.optionalGroupDetails.filteredOptionalGroup}
                  onSelect={handleOptionalGroupSelect}
                  selectedItems={
                    state.optionalGroupDetails.selectedOptionalGroup
                  }
                  onSubmit={handleAssignProducttoOptionalGroup}
                  onSearch={HandleFilterOptionalGroup}
                  existedProductList={
                    state.optionalGroupDetails.optionalGroupList
                  }
                  disabled={state.optionalGroupDetails.isSaveButtonBusy}
                />
              }
            />
          </Box>
        )}
      </ContentLayout>

      <DeleteModal
        open={state.optionalGroupDetails.isDelete}
        handleClose={() => DeleteModals(false, null)}
        onConfirm={handleDeleteProducts}
      />
      <DeleteModal
        open={state.isOpen}
        handleClose={() => toggleModal(null, "details")}
        onConfirm={() => {
          handleDeleteOptionalGroup("details");
        }}
      />
    </>
  );
};
