import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import React from "react";
import {
  Button,
  Stack,
  Card,
  Typography,
  createFilterOptions,
  TextField,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useParams } from "react-router-dom";

import { useCOA } from "../hooks";
import SimpleReactValidator from "simple-react-validator";

export const AddorEditCOA = () => {
  const { id } = useParams();
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const { state, globalData, handleInputChange, onSaveCOA, onUpdateCOA } = useCOA({ isEdit: id, isAdd: true })
  const { name, code, parent_id, account_type } = state.updateDetails
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });

  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdateCOA(id) : onSaveCOA();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <ContentLayout
        title={id ? "Catering" : "New Account"}
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              onClick={onSubmit}
              disabled={state.isSaveButton}
            >
              {id ? "Update" : "Save"}
            </Button>
          </>
        }
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Chart of Accounts", href: "/coa" },

                { name: id ? "Edit" : "Create" },
              ]}
            />
          </>
        }
      >
        {(state.isLoading && id) ? <Loader /> : (
          <Card sx={{ p: "32px 24px" }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 3, sm: 20 }}
              gap="24px"
            >
              <Typography variant="subtitle1" fontWeight={600} minWidth="114px">
                Account Details
              </Typography>

              <Stack width={"100%"} gap="20px">
                <InputControl
                  name="name"
                  label="Account Name"
                  value={name}
                  onChange={handleInputChange}
                  required
                  error={validator.current.message(
                    "Account Name",
                    name,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "Account Name",
                    name,
                    "required"
                  )}
                />
                <Stack flexDirection="row" gap="16px">
                  <InputControl
                    type="dropdown"
                    name="day"
                    label="Day"
                    multiple={false}
                    options={state.parentList}
                    value={
                      (state.parentList.length > 0 &&
                        parent_id !== null &&
                        state.parentList.find((c) => c.value === parent_id)) ||
                      null
                    }
                    onChange={(e, v) => {
                      handleInputChange({
                        target: {
                          name: "parent_id",
                          value: v ? v.value : null,
                        },
                      })
                    }
                    }
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    filterOptions={filterOptions}
                    sx={{ width: "100%" }}
                    componentsProps={{
                      paper: {
                        sx: {
                          width: "100%",
                          minWidth: 250,
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Parent Account *"
                        placeholder="Parent Account *"
                        error={validator.current.message(
                          "Parent Account",
                          parent_id,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Parent Account",
                          parent_id,
                          "required"
                        )}
                      />
                    )}
                  />
                  <InputControl
                    type="dropdown"
                    name="day"
                    label="Day"
                    multiple={false}
                    options={globalData.coa_account_type ?? []}
                    value={
                      (globalData.coa_account_type?.length > 0 &&
                        account_type !== null &&
                        globalData.coa_account_type?.find((c) => c.value === account_type)) ||
                      null
                    }
                    onChange={(e, v) => {
                      handleInputChange({
                        target: {
                          name: "account_type",
                          value: v ? v.value : null,
                        },
                      })
                    }
                    }
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    filterOptions={filterOptions}
                    sx={{ width: "100%" }}
                    componentsProps={{
                      paper: {
                        sx: {
                          width: "100%",
                          minWidth: 250,
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Account Type *"
                        placeholder="Account Type *"
                        error={validator.current.message(
                          "Account Type",
                          account_type,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Account Type",
                          account_type,
                          "required"
                        )}
                      />
                    )}
                  />
                </Stack>
                <InputControl
                  name="code"
                  label="Account Code"
                  value={code}
                  onChange={handleInputChange}
                  required
                  error={validator.current.message(
                    "Account Code",
                    code,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "Account Code",
                    code,
                    "required"
                  )}
                />
              </Stack>
            </Stack>
          </Card>
        )}
      </ContentLayout>
    </>
  );
};
