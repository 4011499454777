import React from "react";
import { Route, Routes } from "react-router-dom";
import { EditSettings } from "./containers";

export const Settings = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<EditSettings />} />
            </Routes>
        </>
    );
};
