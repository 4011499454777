import { API } from "../../../utils";

export const getPriceLevel = async () => {
  const res = await API.get("price-levels");
  return res.data;
};
export const deletePriceLevelById = async (id) => {
  const res = await API.delete(`price-levels/${id}`);
  return res.data;
};
export const createPriceLevel = async (payload) => {
  const res = await API.post(`price-levels`, payload);
  return res.data;
};
export const getPriceLevelDetails = async (id) => {
  const res = await API.get(`price-levels/${id}`);
  return res.data;
};

export const editPriceLevel = async (id, payload) => {
  const res = await API.put(`price-levels/${id}`, payload);
  return res.data;
};
export const getPriceLevelProductDetails = async (id) => {
  const res = await API.get(`price-levels/${id}/products`);
  return res.data;
};
export const createorupdateProductPriceLevel = async (id, payload) => {
  const res = await API.put(`price-levels/${id}/products`, payload);
  return res.data;
};
export const getPriceLevelProductList = async (id, productId) => {
  const res = await API.get(`price-levels/${id}/products/${productId}`);
  return res.data;
};
export const getPriceLevelById = async (id) => {
  const res = await API.get(`price-levels/${id}`);
  return res.data;
};
export const deleteProductPriceLevel = async (id, productId) => {
  const res = await API.delete(`price-levels/${id}/products/${productId}`);
  return res.data;
};
