import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Card,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import InputControl from "../shared/components/InputControl";
import { useImmer } from "use-immer";
import Swal from "sweetalert2";
import { Box } from "@mui/system";

const tableCellStyle = {
  borderBottom: "1px solid #E6E6E6",
  borderRight: "1px solid #E6E6E6",
  padding: "2px 2px",
  width: "191px !important",
};

const Test = () => {
  const [state, setState] = useImmer({
    isCalculated: false,
    float_amount: 250,
    denominations: [
      {
        id: 1,
        eod_id: 1,
        denomination_id: 1,
        denomination_label: "5¢",
        denomination_cash: 0.05,
        entry_count: "",
        float_count: "",
        modification: "",
        bank: "",
        max: 80,
        min: 20,
      },
      {
        id: 2,
        eod_id: 1,
        denomination_id: 2,
        denomination_label: "10¢",
        denomination_cash: 0.1,
        entry_count: "",
        float_count: "",
        modification: "",
        bank: "",
        max: 80,
        min: 20,
      },
      {
        id: 3,
        eod_id: 1,
        denomination_id: 3,
        denomination_label: "20¢",
        denomination_cash: 0.2,
        entry_count: "",
        float_count: "",
        modification: "",
        bank: "",
        max: 40,
        min: 20,
      },
      {
        id: 4,
        eod_id: 1,
        denomination_id: 4,
        denomination_label: "50¢",
        denomination_cash: 0.5,
        entry_count: "",
        float_count: "",
        modification: "",
        bank: "",
        max: 40,
        min: 10,
      },
      {
        id: 5,
        eod_id: 1,
        denomination_id: 5,
        denomination_label: "$1",
        denomination_cash: 1,
        entry_count: "",
        float_count: "",
        modification: "",
        bank: "",
        max: 40,
        min: 10,
      },
      {
        id: 6,
        eod_id: 1,
        denomination_id: 6,
        denomination_label: "$2",
        denomination_cash: 2,
        entry_count: "",
        float_count: "",
        modification: "",
        bank: "",
        max: 35,
        min: 10,
      },
      {
        id: 7,
        eod_id: 1,
        denomination_id: 7,
        denomination_label: "$5",
        denomination_cash: 5,
        entry_count: "",
        float_count: "",
        modification: "",
        bank: "",
        max: 15,
      },
      {
        id: 8,
        eod_id: 1,
        denomination_id: 8,
        denomination_label: "$10",
        denomination_cash: 10,
        entry_count: "",
        float_count: "",
        modification: "",
        bank: "",
        max: 10,
      },
      {
        id: 9,
        eod_id: 1,
        denomination_id: 9,
        denomination_label: "$20",
        denomination_cash: 20,
        entry_count: "",
        float_count: "",
        modification: "",
        bank: "",
        max: 10,
      },
      {
        id: 10,
        eod_id: 1,
        denomination_id: 10,
        denomination_label: "$50",
        denomination_cash: 50,
        entry_count: "",
        float_count: "",
        modification: "",
        bank: "",
      },
      {
        id: 11,
        eod_id: 1,
        denomination_id: 11,
        denomination_label: "$100",
        denomination_cash: 100,
        entry_count: "",
        float_count: "",
        modification: "",
        bank: "",
      },
    ],
  });
  const inputRefs = state.denominations.map((item) => createRef(null));

  const handleModificationChange = (e, index) => {
    const { value } = e.target;
    setState((draft) => {
      draft.denominations[index].modification = value;
    });
  };

  const onFloatChange = (e) => {
    setState((draft) => {
      draft.float_amount = e.target.value;
    });
  };

  const calculateSum = (index, count, array) => {
    let sum = array.reduce((acc, item, i) => {
      if (i <= index) {
        if (i === index) {
          return acc + count * item.denomination_cash;
        } else {
          return acc + item.float_count * item.denomination_cash;
        }
      } else {
        return acc + 0;
      }
    }, 0);
    return sum;
  };

  const checkDivisible = (index, topLevelDenomination, float_count, array) => {
    let excess = 0;
    const sum = calculateSum(index, float_count, array);
    const value = Number((sum / topLevelDenomination).toFixed(2));
    if (Number.isInteger(value)) {
      excess = 0;
    } else {
      let count = float_count;
      let sum = calculateSum(index, count, array);
      let total = Number((sum / topLevelDenomination).toFixed(2));
      while (Number.isInteger(total) === false) {
        if (!count || count == 0) {
          count = 0;
          break;
        }
        count -= 1;
        sum = calculateSum(index, count, array);
        total = Number((sum / topLevelDenomination).toFixed(2));
      }
      excess = float_count - count;
    }
    return excess;
  };

  const splitFloatAndCash = (index, array) => {
    const { entry_count, max } = array[index];
    const { denomination_cash: topLevelDenomination } = array[index + 1];
    let excess = 0;
    if (entry_count > max) {
      const excesswithMax = checkDivisible(
        index,
        topLevelDenomination,
        max,
        array
      );
      if (excesswithMax !== 0) {
        excess =
          checkDivisible(index, topLevelDenomination, max, array) +
          (entry_count - max);
      } else {
        excess = entry_count - max;
      }
    } else {
      excess = checkDivisible(index, topLevelDenomination, entry_count, array);
    }

    return excess;
  };
  const handleEntryChange = (e, index) => {
    const { value } = e.target;
    if (index === 6 || index === 7) {
      setState((draft) => {
        draft.denominations[index].float_count = value;
      });
    } else if (index > 7) {
      setState((draft) => {
        draft.denominations[index].bank = value;
      });
    }
    setState((draft) => {
      draft.denominations[index].entry_count = value;
    });
  };

  const handleFloatChange = (e, index) => {
    const { value } = e.target;
    setState((draft) => {
      draft.denominations[index].float_count = value;
      draft.denominations[index].bank =
        draft.denominations[index].entry_count - value;
    });
  };

  const handleCalulate = () => {
    let data = JSON.parse(JSON.stringify(state.denominations));
    const hasError = checkMinValues(data);
    if (hasError) {
      console.log("hasErrror");
    } else {
      data.forEach((item, index, array) => {
        if (index < 6) {
          const excess = splitFloatAndCash(index, array);
          array[index].bank = excess;
          array[index].float_count = array[index].entry_count - excess;
        }
      });
      setState((draft) => {
        draft.denominations = data;
        draft.isCalculated = true;
      });
    }
  };

  const checkMinValues = (data) => {
    const errors = [];
    data.forEach((item) => {
      if (item.min && item.entry_count < item.min) {
        errors.push({ label: item.denomination_label, min: item.min });
      }
    });
    if (errors.length) {
      let errorString = "";
      errors.forEach((item) => {
        errorString += `${item.label} must be atleast ${item.min}` + "</br>";
      });
      Swal.fire({
        title: "Check min value",
        icon: "error",
        html: errorString,
      });
    }
    return errors.length > 0 ? true : false;
  };

  const entryTotal = state.denominations.reduce((acc, item) => {
    return acc + item.entry_count * item.denomination_cash;
  }, 0);

  const cashTotal = state.denominations.reduce((acc, item) => {
    return (
      acc +
      (Number(item.modification) + Number(item.bank)) * item.denomination_cash
    );
  }, 0);

  const floatTotal = state.denominations.reduce((acc, item) => {
    return acc + item.float_count * item.denomination_cash;
  }, 0);

  const coinsIds = [1, 2, 3, 4, 5, 6];

  return (
    <>
      <Grid container sx={{ p: "32px 24px", height: "110px" }}>
        <Grid item md={3}>
          <Typography variant="subtitle1" fontWeight={600}>
            Float Amount
          </Typography>
        </Grid>
        <Grid item md={9}>
          <InputControl
            name="float_total"
            label="Amount ($)"
            type="number"
            value={state.float_amount}
            onChange={(e) => onFloatChange(e)}
            disabled
            required
          />
        </Grid>
      </Grid>

      {state.isCalculated ? (
        <Paper sx={{ boxShadow: "none" }}>
          <TableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ borderRight: "1px solid #E6E6E6" }}>
                    Denomination
                  </TableCell>
                  <TableCell align="left">Cash</TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #E6E6E6" }}
                  >
                    Float
                  </TableCell>
                  <TableCell align="center">Bank</TableCell>
                  <TableCell align="left">Modifications</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.denominations.map((item, index) => {
                  const {
                    denomination_label,
                    denomination_cash,
                    id,
                    modification,
                    entry_count,
                    float_count,
                    bank,
                  } = item;

                  const cashSum = (
                    Number(modification * denomination_cash) +
                    Number(bank * denomination_cash)
                  ).toFixed(2);
                  const entrySum = (denomination_cash * entry_count).toFixed(2);
                  const floatSum = (float_count * denomination_cash).toFixed(2);

                  return (
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          ...tableCellStyle,
                          backgroundColor:
                            !coinsIds.includes(id) && "rgba(0, 0, 0, 0.03)",
                        }}
                      >
                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          sx={{
                            lineHeight: "19px",
                          }}
                        >
                          {denomination_label}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          ...tableCellStyle,
                          backgroundColor:
                            !coinsIds.includes(id) && "rgba(0, 0, 0, 0.03)",
                        }}
                      >
                        <Stack direction="row" alignItems="center" gap={"20px"}>
                          <InputControl
                            name={id}
                            type="number"
                            required
                            value={entry_count}
                            onChange={(e) => handleEntryChange(e, index)}
                            width="130px"
                            inputRef={inputRefs[index]}
                            onKeyDown={(event) => {
                              if (
                                event.key === "Enter" &&
                                index < state.denominations.length - 1
                              ) {
                                inputRefs[index + 1].current.focus();
                              }
                            }}
                          />
                          <Typography
                            fontWeight={600}
                            fontSize={14}
                            sx={{
                              lineHeight: "22px",
                            }}
                          >
                            {`= $${entrySum}`}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        sx={{
                          ...tableCellStyle,
                          backgroundColor:
                            !coinsIds.includes(id) && "rgba(0, 0, 0, 0.03)",
                        }}
                      >
                        <Stack
                          direction="row"
                          gap="10px"
                          alignItems="center"
                          justifyContent="space-around"
                        >
                          {index < 6 ? (
                            <Typography
                              fontWeight={600}
                              fontSize={14}
                              sx={{
                                lineHeight: "22px",
                                width: "130px",
                              }}
                            >
                              {float_count}
                            </Typography>
                          ) : (
                            <InputControl
                              name={id}
                              type="number"
                              required
                              width="130px"
                              value={float_count}
                              onChange={(e) => handleFloatChange(e, index)}
                            />
                          )}

                          <Typography
                            fontWeight={600}
                            fontSize={14}
                            sx={{
                              lineHeight: "22px",
                            }}
                          >
                            = ${floatSum}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        sx={{
                          ...tableCellStyle,
                          width: "100px !important",
                          borderRight: "none",
                          backgroundColor:
                            !coinsIds.includes(id) && "rgba(0, 0, 0, 0.03)",
                        }}
                      >
                        <Stack
                          direction="row"
                          gap="10px"
                          alignItems="center"
                          justifyContent="space-around"
                        >
                          <Typography
                            fontWeight={600}
                            fontSize={14}
                            sx={{
                              lineHeight: "22px",
                            }}
                          >
                            {bank}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        sx={{
                          ...tableCellStyle,
                          backgroundColor:
                            !coinsIds.includes(id) && "rgba(0, 0, 0, 0.03)",
                        }}
                      >
                        <Stack
                          direction="row"
                          gap="10px"
                          alignItems="center"
                          justifyContent="space-around"
                        >
                          <InputControl
                            name={id}
                            type="number"
                            value={modification}
                            onChange={(e) => handleModificationChange(e, index)}
                            width="120px"
                            required
                          />

                          <Typography
                            fontWeight={600}
                            fontSize={14}
                            sx={{
                              lineHeight: "22px",
                            }}
                          >
                            = ${cashSum}
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{
                      ...tableCellStyle,
                      height: "48px",
                    }}
                  >
                    <Button onClick={handleCalulate}>Calculate</Button>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      minWidth: "191px",
                      height: "48px",
                    }}
                  >
                    <Stack direction={"row"} gap={"50px"}>
                      <Typography
                        fontWeight={600}
                        fontSize={14}
                        sx={{
                          lineHeight: "19px",
                        }}
                      >
                        Total Cash
                      </Typography>
                      <Typography
                        fontWeight={600}
                        sx={{
                          lineHeight: "22px",
                        }}
                      >
                        = ${entryTotal.toFixed(2)}
                      </Typography>
                    </Stack>
                  </TableCell>

                  <TableCell
                    align="left"
                    sx={{
                      ...tableCellStyle,
                      height: "48px",
                      backgroundColor:
                        (state.float_amount - floatTotal).toFixed(2) != 0
                          ? "red"
                          : "green",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      gap={"20px"}
                      justifyContent={"space-around"}
                    >
                      <Typography
                        fontWeight={600}
                        fontSize={14}
                        sx={{
                          lineHeight: "19px",
                        }}
                      >
                        {state.float_amount === floatTotal
                          ? ""
                          : floatTotal < state.float_amount
                          ? `Under $${(state.float_amount - floatTotal).toFixed(
                              2
                            )}`
                          : floatTotal > state.float_amount
                          ? `Over $${(floatTotal - state.float_amount).toFixed(
                              2
                            )}`
                          : ""}
                      </Typography>
                      <Typography
                        fontWeight={600}
                        sx={{
                          lineHeight: "22px",
                        }}
                      >
                        = ${floatTotal.toFixed(2)}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      ...tableCellStyle,
                      borderRight: "none",
                      height: "48px",
                    }}
                  ></TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      ...tableCellStyle,
                      height: "48px",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      gap={"50px"}
                      justifyContent={"space-around"}
                    >
                      <Typography
                        fontWeight={600}
                        fontSize={14}
                        sx={{
                          lineHeight: "19px",
                        }}
                      >
                        Total to Bank
                      </Typography>
                      <Typography
                        fontWeight={600}
                        sx={{
                          lineHeight: "22px",
                        }}
                      >
                        = ${cashTotal.toFixed(2)}
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Card sx={{ padding: "24px" }}>
          <TableContainer>
            <Table sx={{ width: "500px" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      borderRight: "1px solid #E6E6E6",
                      borderLeft: "1px solid #E6E6E6",
                    }}
                  >
                    Denomination
                  </TableCell>
                  <TableCell align="left">Cash</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.denominations.map((item, index) => {
                  const {
                    denomination_label,
                    denomination_cash,
                    id,
                    entry_count,
                  } = item;

                  const entrySum = (denomination_cash * entry_count).toFixed(2);

                  return (
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          ...tableCellStyle,
                          backgroundColor:
                            !coinsIds.includes(id) && "rgba(0, 0, 0, 0.03)",
                          borderLeft: "1px solid #E6E6E6",
                        }}
                      >
                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          sx={{
                            lineHeight: "19px",
                          }}
                        >
                          {denomination_label}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          ...tableCellStyle,
                          backgroundColor:
                            !coinsIds.includes(id) && "rgba(0, 0, 0, 0.03)",
                        }}
                      >
                        <Stack direction="row" alignItems="center" gap={"20px"}>
                          <InputControl
                            name={id}
                            type="number"
                            required
                            value={entry_count}
                            onChange={(e) => handleEntryChange(e, index)}
                            width="130px"
                            inputRef={inputRefs[index]}
                            onKeyDown={(event) => {
                              if (
                                event.key === "Enter" &&
                                index < state.denominations.length - 1
                              ) {
                                inputRefs[index + 1].current.focus();
                              }
                            }}
                          />
                          <Typography
                            fontWeight={600}
                            fontSize={14}
                            sx={{
                              lineHeight: "22px",
                            }}
                          >
                            {`= $${entrySum}`}
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{
                      ...tableCellStyle,
                      height: "48px",
                      borderLeft: "1px solid #E6E6E6",
                    }}
                  >
                    <Button onClick={handleCalulate}>Calculate</Button>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      ...tableCellStyle,
                      minWidth: "191px",
                      height: "48px",
                    }}
                  >
                    <Stack direction={"row"} gap={"50px"}>
                      <Typography
                        fontWeight={600}
                        fontSize={14}
                        sx={{
                          lineHeight: "19px",
                        }}
                      >
                        Total Cash
                      </Typography>
                      <Typography
                        fontWeight={600}
                        sx={{
                          lineHeight: "22px",
                        }}
                      >
                        = ${entryTotal.toFixed(2)}
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}
    </>
  );
};

export default Test;
