import { API } from "../../../utils";

export const getProfiles = async () => {
  const res = await API.get("profile");
  return res.data;
};
export const getTimeZone = async () => {
  const res = await API.get("timezones");
  return res.data;
};
export const editProfile = async (payload) => {
  const res = await API.put("profile", payload);
  return res.data;
};
export const changePassword = async (payload) => {
  const res = await API.put("change_password", payload);
  return res.data;
};

export const changePasswoCode = async (payload, userId) => {
  const res = await API.get(`users/${userId}/reset-passcode`);
  return res.data;
};
