import { ContentLayout, HeaderBreadcrumbs } from "../../../shared";
import * as React from "react";
import {
  Button,
  Stack,
  Typography,
  Card,
  Grid,
  TextField,
  Divider,
  Box,
  FormHelperText,
} from "@mui/material";
import { useParams } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";
import { useList } from "../hooks";

export const UploadList = () => {
  const { id } = useParams();
  const {
    state,
    storeList,
    globalData,
    handleInputChange,
    handleUploadExcel,
    handleInputChangeFile,
    handleUpdateUploadExcel,
  } = useList({
    isList: false,
    detailsId: id,
    isStore: true,
  });

  const fileRef = React.useRef();
  const onLogoClick = () => {
    fileRef.current.click();
  };

  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        IconFormat: {
          message: "The file should be an Excel file (.xlsx or .xls)",
          rule: (val) => {
            if (val) {
              const fileName = val.name ? val.name : val;
              const extension = fileName.split(".").pop().toLowerCase();
              return ["xlsx", "xls"].includes(extension);
            }
            return false;
          },
          required: true,
        },
      },
    })
  );

  const storeOptions = storeList?.map((v) => {
    return { value: v.id, label: v.name };
  });

  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? handleUpdateUploadExcel() : handleUploadExcel();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <ContentLayout
        title={"Account File Upload"}
        breadcrumbs={
          id ? (
            <>
              <HeaderBreadcrumbs
                links={[
                  { name: "Dashboard", href: "/" },
                  { name: "Account Uploads", href: "/list" },
                  { name: "Edit" },
                ]}
              />
            </>
          ) : (
            <>
              <HeaderBreadcrumbs
                links={[
                  { name: "Dashboard", href: "/" },
                  { name: "Account Uploads", href: "/list" },
                  { name: "create" },
                ]}
              />
            </>
          )
        }
      >
        <Card sx={{ p: "32px 24px", mb: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1" fontWeight={600}>
                Account File Upload
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack width={"100%"} gap="20px">
                <Stack direction="row" justifyContent="right" gap={1}>
                  <Typography
                    fontWeight={400}
                    fontSize="12px"
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    <a
                      href={globalData?.pl_bs_reports?.sample_import_file}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      P&L and BS Template
                      <img
                        src="/icons/simple_download_ic.png"
                        style={{
                          marginLeft: 2,
                          marginTop: 1,
                          alignContent: "center",
                        }}
                      />
                    </a>
                  </Typography>
                </Stack>
                <InputControl
                  type="dropdown"
                  disableClearable
                  options={storeOptions ?? []}
                  getOptionLabel={(option) => option?.label}
                  value={
                    (storeOptions?.length > 0 &&
                      state?.data?.store_id &&
                      storeOptions?.find(
                        (c) => c.value === state?.data?.store_id
                      )) ||
                    null
                  }
                  onChange={(e, newValue) => {
                    handleInputChange({
                      target: {
                        name: "store_id",
                        value: newValue.value,
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Store"
                      required
                      error={validator.current.message(
                        "Store",
                        state?.data?.store_id,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "Store",
                        state?.data?.store_id,
                        "required"
                      )}
                    />
                  )}
                />
                <Stack direction="row" gap={1}>
                  <InputControl
                    type="dropdown"
                    options={
                      globalData?.pl_bs_reports?.pl_bs_accounts_months ?? []
                    }
                    getOptionLabel={(option) => option?.label}
                    value={
                      (globalData?.pl_bs_reports?.pl_bs_accounts_months
                        ?.length > 0 &&
                        state?.data?.month &&
                        globalData?.pl_bs_reports?.pl_bs_accounts_months?.find(
                          (c) => c.label === state?.data?.month
                        )) ||
                      null
                    }
                    onChange={(e, newValue) => {
                      handleInputChange({
                        target: {
                          name: "month",
                          value: newValue.label,
                        },
                      });
                    }}
                    sx={{
                      width: "100%",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Month"
                        required
                        error={validator.current.message(
                          "Month",
                          state?.data?.month,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Month",
                          state?.data?.month,
                          "required"
                        )}
                      />
                    )}
                  />
                  <InputControl
                    type="dropdown"
                    disableClearable={true}
                    options={
                      globalData?.pl_bs_reports?.pl_bs_accounts_years ?? []
                    }
                    getOptionLabel={(option) => option.label}
                    value={
                      (globalData?.pl_bs_reports?.pl_bs_accounts_years?.length >
                        0 &&
                        state?.data?.year &&
                        globalData?.pl_bs_reports?.pl_bs_accounts_years?.find(
                          (c) => c.value === state?.data?.year
                        )) ||
                      null
                    }
                    onChange={(e, newValue) => {
                      handleInputChange({
                        target: {
                          name: "year",
                          value: newValue.value,
                        },
                      });
                    }}
                    sx={{
                      width: "100%",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Year"
                        required
                        error={validator.current.message(
                          "Year",
                          state?.data?.year,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Year",
                          state?.data?.year,
                          "required"
                        )}
                      />
                    )}
                  />
                </Stack>
                <Typography variant="subtitle1" gutterBottom>
                  File*
                </Typography>
                <Stack
                  onClick={(e) => onLogoClick(e)}
                  sx={{
                    padding: "16px",
                    borderRadius: "8px",
                    bgcolor: "#F5F8FC",
                    height: "121px",
                  }}
                >
                  <input
                    id="contained-button-file"
                    type="file"
                    onChange={(e) => handleInputChangeFile(e)}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    style={{ display: "none" }}
                    ref={fileRef}
                  />
                  <Box
                    sx={{
                      border: "1px solid #E0E0E0",
                      borderRadius: "4px",
                      padding: "16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      minHeight: "50px",
                      bgcolor: "#FFFFFF",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {state?.file?.name || state?.data?.excel_file ? (
                        <img
                          src="/icons/ic_files.svg"
                          style={{
                            marginRight: "4px",
                          }}
                        />
                      ) : (
                        ""
                      )}
                      <Typography
                        variant="body1"
                        sx={{
                          width: 500,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          flexGrow: 1,
                        }}
                      >
                        {state?.file?.name
                          ? state?.file?.name
                          : state?.data?.excel_file}
                      </Typography>
                    </Box>
                  </Box>
                  <Stack
                    direction="row"
                    gap={1}
                    alignContent="center"
                    mt={2}
                    alignSelf="center"
                  >
                    <Typography
                      fontWeight={400}
                      fontSize="12px"
                      color="#808080"
                    >
                      Replace,Drag & Drop Or
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontSize="12px"
                      sx={{ textDecoration: "underline" }}
                    >
                      Upload
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontSize="12px"
                      color="#808080"
                    >
                      Excel File Here
                    </Typography>
                  </Stack>
                </Stack>
                <FormHelperText sx={{ color: "#C60808" }}>
                  {state?.file
                    ? validator.current.message(
                        "Excel File",
                        state?.file,
                        "required|IconFormat"
                      )
                    : validator.current.message(
                        "Excel File",
                        state?.data?.excel_file,
                        "required|IconFormat"
                      )}
                </FormHelperText>
              </Stack>
            </Grid>
          </Grid>
          <Stack mt={3}>
            <Divider />
          </Stack>
          <Stack direction="row" justifyContent="right" mt={2}>
            <Button
              type="button"
              size="medium"
              variant="contained"
              onClick={(e) => onSubmit(e)}
              disabled={state?.isSaveButtonBusy}
            >
              Upload
            </Button>
          </Stack>
        </Card>
      </ContentLayout>
    </>
  );
};
