import { API } from "../../../utils/api";

export const authentication = async (payload) => {
  const res = await API.post("login", payload);
  return res.data;
};

export const getForgotPassword = async (email) => {
  const res = await API.post("password/email", { email });
  return res.data;
};

export const resetPassword = async (payload) => {
  const res = await API.post("authentication/resetPassword", payload);
  return res.data;
};
