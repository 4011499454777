import {
  ContentLayout,
  HeaderBreadcrumbs,
  ServerPaginatedTable,
  useDropdownApis,
} from "../../../shared";
import * as React from "react";
import {
  Button,
  createFilterOptions,
  Checkbox,
  TextField,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { DashBoardDateRangePicker } from "../../Dashboard";
import { useCouponReport } from "../hooks";
import { useStore } from "../../Stores";
import { arraySort } from "../../../utils";
import { AsyncPaginate } from "react-select-async-paginate";

export const CouponReportList = () => {
  const {
    state,
    handleSearch,
    handleDateChangeApply,
    handleIsOpen,
    onChangeDate,
    handleFilterClear,
    handleApplyFilter,
    handleFilter,
    gotoPage,
    setPageSize,
    handleSort,
    handleDownloadCouponReportList,
  } = useCouponReport({
    isCouponReport: true,
  });
  const { handleCouponList } = useDropdownApis({
    isCouponList: true,
  });
  const navigate = useNavigate();
  const { storeList } = useStore({ isStore: true, storeId: null });
  const [input, setInput] = React.useState();

  const storeOptions = storeList.map((v) => {
    return { value: v.id, label: v.name };
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: "2px solid #E6E8F0",
      height: "48px",
      width: "250px",
    }),
    option: (provided, state) => ({
      ...provided,
      maxHeight: "239px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps, isSelected } = props;
    return (
      <>
        <Stack
          ref={innerRef}
          {...innerProps}
          sx={{
            flexDirection: "row",
            alignItems: "center",
            p: "7px 8px",
            gap: "2px",
          }}
        >
          <Checkbox
            checked={isSelected}
            sx={{
              p: "8px",
            }}
          />
          <Stack
            sx={{
              gap: "2px",
            }}
          >
            <Typography
              variant="subtitle2"
              fontWeight={400}
              lineHeight="17px"
            >
              {data.label}
            </Typography>
          </Stack>
        </Stack>
      </>
    );
  };

  const onInputChange = (inputValue, { action }) => {
    if (action === "input-change") {
      setInput(inputValue);
    }
  };

  const ActionButtons = ({ id }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        <Link to={`/orders/details/${id}`}>
          <img alt="details" src="/icons/arrow_forward.svg" />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "order_number",
        Cell: ({ row }) => {
          return (
            <>
              <Stack direction="row" gap="12px" alignItems="center">
                <div
                  className="table-taxt-and-icon-link cursor"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/orders/details/${row.original.order_id}`);
                  }}
                >
                  <span className="link-text">{row.original.order_number}</span>
                </div>
              </Stack>
            </>
          );
        },
      },
      {
        Header: "Coupon Name",
        accessor: "coupon_name",
      },
      {
        Header: "Customer",
        accessor: "customer_name",
        Cell: ({ row }) => {
          return (
            <>
              {row.original.customer_id ? (
                <Stack direction="row" gap="12px" alignItems="center">
                  <div
                    className="table-taxt-and-icon-link cursor"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(
                        `/customers/details/${row.original.customer_id}`
                      );
                    }}
                  >
                    <span className="link-text">
                      {row.original.customer_name}
                    </span>
                  </div>
                </Stack>
              ) : (
                row.original.customer_name
              )}
            </>
          );
        },
      },
      {
        Header: "Coupon Code",
        accessor: "barcode",
      },
      {
        Header: "Used Date & Time",
        accessor: "order_date",
      },
      {
        Header: "Store",
        accessor: "store_name",
      },

      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons id={row?.order_id} />;
        },
      },
    ],
    []
  );
  return (
    <>
      <ContentLayout
        title={"Coupon Report"}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Coupon Report", href: "/coupon-report" },
              ]}
            />
          </>
        }
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="text"
              onClick={handleDownloadCouponReportList}
            >
              <img
                alt="export"
                src="/icons/ic_export.svg"
                style={{
                  marginRight: "4px",
                }}
              />
              Export
            </Button>
          </>
        }
      >
        <ServerPaginatedTable
          columns={columns}
          data={state?.couponreports ?? []}
          isLoading={state.isBusy}
          filterApplied={state?.filterApplied?.all}
          defaultSearch={false}
          onFilterClear={(e) => handleFilterClear("single", e)}
          onAllFilterClear={(e) => handleFilterClear("all")}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
          pagination={state?.pagination}
          sortByGlobal={state?.sortBy}
          handleSort={handleSort}
          key={state?.sortBy}
          filterContent={
            <InputControl
              type="table-search"
              value={state?.filterApplied?.searchText}
              onChange={(e) => handleSearch(e.target.value)}
              onClear={() => handleSearch("")}
            />
          }
          filterSection={
            <>
              <Stack
                direction="row"
                gap="16px"
                alignItems="center"
                flexWrap="wrap"
              >
                <DashBoardDateRangePicker
                  onChangeDate={onChangeDate}
                  isList={true}
                  range={state.date}
                  showRange={state.showDate}
                  isOpen={state.isCalenderOpen}
                  handleIsOpen={handleIsOpen}
                  handleDateChangeApply={handleDateChangeApply}
                  position="left"
                />
                <Box>
                  <AsyncPaginate
                    debounceTimeout={1000}
                    placeholder="Coupon"
                    isMulti
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    classNamePrefix="react-select"
                    components={{ Option: CustomOption }}
                    menuPlacement="bottom"
                    inputValue={input}
                    onInputChange={onInputChange}
                    value={state?.filterApplied?.coupon}
                    isClearable={false}
                    loadOptions={async (e, p, { page }) => {
                      return await handleCouponList({
                        deletedItem: 1,
                        search: e,
                        page,
                        type: "coupon",
                      });
                    }}
                    onChange={(e, v) => handleFilter(e, v, "coupons")}
                    controlShouldRenderValue={false}
                    additional={{
                      page: 1,
                    }}
                    styles={customStyles}
                  />
                </Box>

                <InputControl
                  type="dropdown"
                  name="Store"
                  label="Store"
                  multiple={true}
                  options={arraySort(storeOptions || [], "label")}
                  value={state?.filterApplied?.stores}
                  disableCloseOnSelect
                  disableClearable={true}
                  onChange={(e, v) => handleFilter(e, v, "store")}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Store" placeholder="Store" />
                  )}
                />
              </Stack>

              <Button
                type="button"
                size="medium"
                variant="text"
                color="info"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => {
                  setInput("")
                  handleApplyFilter()
                }}
                disabled={state.disableApplyButton}
              >
                Apply
              </Button>
            </>
          }
          onRowLink={(e) => {
            return `/orders/details/${e.order_id}`;
          }}
        />
      </ContentLayout>
    </>
  );
};
