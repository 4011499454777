import * as React from "react";
import { Loader, ServerPaginatedTable } from "../../../shared";
import { Box, Chip, Stack } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { Link, useParams } from "react-router-dom";
import optInPic from "../../../assets/images/Icons/ic_tick_filled_blue.svg";
import optOutPic from "../../../assets/images/Icons/ic_cancel-opt-out.svg";
import externalLink from "../../../assets/images/Icons/ic_external_link_black.svg";
import ProductReviewListFilterSection from "../../ReviewsAndRatings/components/ReviewListFilterSection";
import { useProductRatingsDetails } from "../../ProductRatings/hooks";

export const ProductReviews = () => {
  const { id } = useParams();

  const ActionButtons = ({ row }) => {
    return (
      <div className="action-buttons">
        <Link to={`/products-ratings/review/details/${row.id}`}>
          <img alt="details" src={externalLink} />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Review ID",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
      },
      {
        Header: "Submitted Date&Time",
        accessor: "submitted_date",
      },
      {
        Header: "Rating",
        accessor: "rating",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="4px" height="16px">
              <img alt="star" src="/icons/star-yellow.svg" />
              {row.original.rating}
            </Stack>
          );
        },
      },
      {
        Header: "Authorised By",
        accessor: "authorised_by",
        Cell: ({ value }) => {
          return value ?? "--";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          const label =
            value === 0 ? "Pending" : value === 1 ? "Approved" : "Rejected";
          const color =
            value === 0 ? "#C07302" : value === 1 ? "#0F875B" : "#C42B1C";
          const backgroundColor =
            value === 0 ? "#FFF4CE" : value === 1 ? "#E1FBF2" : "#FFE7E3";
          return (
            <Chip
              label={label}
              variant="filled"
              size="small"
              sx={{
                color,
                backgroundColor,
              }}
            />
          );
        },
      },
      {
        Header: "Publish Status",
        accessor: "published_status",
        align: "center",
        Cell: ({ row }) => {
          return (
            <Box
              mr="20px"
              component="img"
              src={row.original.published_status ? optInPic : optOutPic}
              alt={"published_status-" + row.original.id}
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons key={index} row={row} />;
        },
      },
    ],
    []
  );

  const {
    state,
    gotoPage,
    handleSortBy,
    handleTableSearch,
    setPageSize,
    status,
    publishedStatusList,
    handleApplyFilter,
    handleClearAllFilter,
    handleClearFilter,
    handleReviewFilterSelection,
    filterOptions,
    handleOpenDateRangeSelection,
    handleDateChangeApply,
  } = useProductRatingsDetails({
    table: "productReviewsList",
    defaultFilter: {
      product_id: id ? id : undefined,
    },
  });

  return (
    <React.Fragment>
      {false ? (
        <Loader />
      ) : (
        <ServerPaginatedTable
          columns={columns}
          data={state.review.list}
          isLoading={state.review.isLoading}
          gotoPage={gotoPage}
          searchBar={true}
          defaultSearch={false}
          handleSort={handleSortBy}
          setPageSize={setPageSize}
          pagination={state.review.pagination}
          sortByGlobal={state.review.sortBy}
          filterContent={
            <InputControl
              type="table-search"
              name="search"
              placeholder="Search"
              value={state.review.search}
              onChange={handleTableSearch}
              onClear={() => handleTableSearch({ target: { value: "" } })}
            />
          }
          filterApplied={state.filter.appliedFilters}
          onAllFilterClear={handleClearAllFilter}
          onFilterClear={handleClearFilter}
          filterSection={
            <ProductReviewListFilterSection
              {...{
                state,
                filterOptions,
                ratingsList: state.rating.options,
                statusList: status,
                publishedStatusList,
                handleReviewFilterSelection,
                handleOpenDateRangeSelection,
                handleDateChangeApply,
                handleApplyFilter,
              }}
            />
          }
          onRowLink={(row) => `/products-ratings/review/details/${row.id}`}
        />
      )}
    </React.Fragment>
  );
};
