import { API } from "../../../utils";

export const getCartProductsApi = async () => {
  const res = await API.get("cart-products");
  return res.data;
};

export const updateTitleTextApi = async (newTitle) => {
  return await API.post("cart-products/title", {
    cart_products_title: newTitle
  })
}

export const updateCartStatusApi = async (status = false) => {
  return await API.post("cart-products/status")
}

export const getProductCategoryListApi = async (searchVal) => {
  const res = await API.get("product/list");
  return res.data;
};

export const updateCartProductsApi = async (product) => {
  const res = await API.put("update-cart-products", product);
  return res.data
}

export const removeFromCartProductApi = async (productId) => {
  const res = await API.put(`products/${productId}/remove-from-cart-product`);
  return res.data
}