import { useContext, useEffect, useMemo } from "react";
import { AppContext } from "../../../store/AppScopeProvider";
import { useTabs } from "../../../shared/hooks/useTabs";
import { useImmer } from "use-immer";
import qs from "qs";
import {
  getProducts,
  copyProduct,
  getBumpScreenTagsList,
} from "../api/ProductApi";
import { useDebouncedCallback } from "use-debounce";
import { errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import {
  useActivityLog,
  useDropdownApis,
  usePermission,
} from "../../../shared";
import { TableStateContext } from "../../../store";

export const useProductsList = ({
  isProduct = false,
  isList = false,
  isBundle = false,
  isFromOptionalGroup = false,
}) => {
  const { addActivityLog } = useActivityLog();
  const navigate = useNavigate();
  const { appState } = useContext(AppContext);
  const { setCurentTab } = useTabs();
  const isCreate = usePermission("product-create");
  const IsEditable = usePermission("product-modify");
  const { categoriesList } = useDropdownApis({ isCategoryList: isList });
  const {
    globalData,
    currentTabs: { productDetails: currentTab },
  } = appState;
  const PAGE_NAME = "superProducts";
  const {
    filterState: {
      params: { superProducts },
    },
    filterStateDispatch,
  } = useContext(TableStateContext);

  const [state, setState] = useImmer({
    isBusy: false,
    isOpen: false,
    disableApplyButton: false,
    id: null,
    initialLoad: true,
    isSaveButtonBusy: false,
    productTags: [],
    BumpScreenTags: [],
    taxGroup: [],
    productsList: {
      isLoading: true,
      listData: [],
    },
    pagination: {
      pageIndex: 1,
      pageSize: 25,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    sortBy: [],
    filterApplied: {
      categories: [],
      product_types: [],
      bump_screen_tags: [],
      status: {},
      all: [],
      searchText: null,
    },
    copyProduct: {
      isOpen: false,
      id: null,
      productName: "",
      copyOptions: [],
      isBusy: false,
    },
    httpStatusCodes: {
      details: {},
    },
  });

  const debounced = useDebouncedCallback((value) => {
    const { categories, product_types, bump_screen_tags, status, searchText } =
      state.filterApplied;
    const { sortBy, pagination } = state;
    handleGetProduct({
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
      searchText,
      categories,
      product_types,
      bump_screen_tags,
      status,
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filterApplied },
    });
  }, 1000);

  useEffect(() => {
    if (isProduct) {
      const {
        categories,
        product_types,
        bump_screen_tags,
        status,
        searchText,
      } = superProducts.filterApplied;
      const { sortBy, pagination } = superProducts;
      setState((draft) => {
        draft.filterApplied = superProducts.filterApplied;
        draft.pagination = pagination;
        draft.sortBy = sortBy;
      });
      handleGetProduct({
        pagination: {
          pageIndex: 1,
          pageSize: pagination.pageSize,
        },
        sortBy,
        searchText,
        categories,
        product_types,
        bump_screen_tags,
        status,
      });
    }
    if (isList) {
      HandleBumpScreeenTags();
    }
  }, [isProduct, isList]);

  const handleGetProduct = async ({
    pagination,
    sortBy = [],
    searchText = null,
    categories = [],
    product_types = [],
    bump_screen_tags = [],
    status = {},
  }) => {
    setState((draft) => {
      draft.productsList.isLoading = true;
      draft.disableApplyButton = true;
    });
    try {
      let params = {};

      if (isFromOptionalGroup) {
        params = { ...params, product_types: ["modifier"] };
      }
      if (isBundle) {
        params = { ...params, product_types: ["retail"] };
      }
      if (searchText) {
        params = { ...params, search: searchText };
      }
      if (toString(status.value)) {
        params = { ...params, status: status.value };
      }
      if (categories.length > 0) {
        const categoryIds = categories.map((v) => {
          return v.value;
        });
        params = { ...params, category_id: categoryIds };
      }
      if (product_types.length > 0) {
        const product_typeIds = product_types.map((v) => {
          return v.value;
        });
        params = { ...params, product_types: product_typeIds };
      }
      if (bump_screen_tags.length > 0) {
        const bump_screen_tagIds = bump_screen_tags.map((v) => {
          return v.value;
        });
        params = { ...params, bump_screen_tags: bump_screen_tagIds };
      }

      if (sortBy.length > 0) {
        const sortByParams = sortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };

      const res = await getProducts(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.productsList.isLoading = false;
          draft.productsList.listData = res.data.products;
          draft.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.productsList.isLoading = false;
        draft.disableApplyButton = false;
      });
    }
  };

  const HandleBumpScreeenTags = async () => {
    if (state.BumpScreenTags.length === 0) {
      try {
        const res = await getBumpScreenTagsList();
        if (res.data?.length > 0) {
          setState((draft) => {
            draft.BumpScreenTags = res.data;
            return draft;
          });
        }
      } catch (error) {
        errorMessage(error.response.data.message);
      }
    }
  };

  const handleFilter = (e, value, type) => {
    if (type === "categories") {
      setState((draft) => {
        draft.filterApplied.categories = value.map((v) => {
          return { ...v, type: "Categories" };
        });
      });
    } else if (type === "status") {
      setState((draft) => {
        draft.filterApplied.status = {
          value: value.props.value,
          label: value.props.children,
          type: "Status",
        };
      });
    } else if (type === "Type") {
      setState((draft) => {
        draft.filterApplied.product_types = value.map((v) => {
          return { ...v, type: "Type" };
        });
      });
    } else if (type === "Bump Screen Tag") {
      setState((draft) => {
        draft.filterApplied.bump_screen_tags = value.map((v) => {
          return { ...v, type: "Bump Screen Tag" };
        });
      });
    }
    setState((draft) => {
      draft.disableApplyButton = false;
    });
  };

  const handleFilterClear = (type, value) => {
    if (type === "single") {
      if (value.type === "Categories") {
        setState((draft) => {
          draft.filterApplied.categories =
            draft.filterApplied.categories.filter(
              (e) => e.value !== value.value
            );
        });
      } else if (value.type === "Status") {
        setState((draft) => {
          draft.filterApplied.status = {};
        });
      } else if (value.type === "Type") {
        setState((draft) => {
          draft.filterApplied.product_types =
            draft.filterApplied.product_types.filter(
              (e) => e.value !== value.value
            );
        });
      } else if (value.type === "Bump Screen Tag") {
        setState((draft) => {
          draft.filterApplied.bump_screen_tags =
            draft.filterApplied.bump_screen_tags.filter(
              (e) => e.value !== value.value
            );
        });
      }
      setState((draft) => {
        draft.disableApplyButton = false;
      });
    } else {
      setState((draft) => {
        draft.filterApplied.all = [];
        draft.filterApplied.categories = [];
        draft.filterApplied.bump_screen_tags = [];
        draft.filterApplied.product_types = [];
        draft.filterApplied.status = {};
      });
      const { searchText } = state.filterApplied;
      const { sortBy, pagination } = state;
      handleGetProduct({
        pagination: {
          pageIndex: 1,
          pageSize: pagination.pageSize,
        },
        sortBy,
        searchText,
      });
      const initialValue = {
        categories: [],
        product_types: [],
        bump_screen_tags: [],
        status: {},
        all: [],
        searchText: searchText,
      };
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initialValue,
      });
    }
  };

  const handleApplyFilter = () => {
    const { categories, product_types, bump_screen_tags, status, searchText } =
      state.filterApplied;
    const { sortBy, pagination } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: state.filterApplied,
        pagination,
        sortBy,
      },
    });
    handleGetProduct({
      pagination,
      sortBy,
      searchText,
      categories,
      product_types,
      bump_screen_tags,
      status,
    });
  };

  useEffect(() => {
    setState((draft) => {
      draft.filterApplied.all = [
        state.filterApplied.status,
        ...state.filterApplied.categories,
        ...state.filterApplied.product_types,
        ...state.filterApplied.bump_screen_tags,
      ].filter((el) => Object.keys(el).length);
    });
  }, [
    state.filterApplied.status,
    state.filterApplied.categories,
    state.filterApplied.product_types,
    state.filterApplied.bump_screen_tags,
  ]);

  const handleSearch = (v) => {
    setState((draft) => {
      draft.filterApplied.searchText = v;
    });
    debounced(v);
  };

  const onProductCopyClick = (productName = "", id = null) => {
    setState((draft) => {
      draft.copyProduct.id = id;
      draft.copyProduct.productName = productName;
      draft.copyProduct.isOpen = !draft.copyProduct.isOpen;
      draft.copyProduct.copyOptions = [];
    });
  };

  const productCloneOptions = useMemo(
    () =>
      globalData?.clone?.products.map((item) => ({
        id: item.value,
        name: item.label,
      })) || [][globalData?.clone?.products]
  );

  const onCloneOptionsSelect = (id) => {
    const isSelected = state.copyProduct.copyOptions.find(
      (item) => item === id
    );
    if (isSelected) {
      setState((draft) => {
        draft.copyProduct.copyOptions = draft.copyProduct.copyOptions.filter(
          (item) => item !== id
        );
      });
    } else {
      setState((draft) => {
        draft.copyProduct.copyOptions = [...draft.copyProduct.copyOptions, id];
      });
    }
  };
  const handleSelectAllCopyOptions = (checked) => {
    if (checked) {
      setState((draft) => {
        draft.copyProduct.copyOptions = [
          ...productCloneOptions.map((item) => item.id),
        ];
      });
    } else {
      setState((draft) => {
        draft.copyProduct.copyOptions = [];
      });
    }
  };

  const onCloneSubmit = async () => {
    const payload = {
      contents: state.copyProduct.copyOptions,
    };
    const { id } = state.copyProduct;
    try {
      setState((draft) => {
        draft.copyProduct.isBusy = true;
      });
      const res = await copyProduct(payload, id);
      if (res.success) {
        setState((draft) => {
          draft.copyProduct.isBusy = false;
        });
        navigate(`/products/edit/${res.data.id}`);
        onProductCopyClick();
      }
    } catch (err) {
      setState((draft) => {
        draft.copyProduct.isBusy = false;
      });
    }
  };

  const activityLog = (filteredData) => {
    const { plu, name } = filteredData;
    const parameters = {
      ProductName: name,
      PLUcode: plu,
    };

    addActivityLog({
      templateId: 9,
      parameters: parameters,
      uniqueParameter: state.id,
      data: {
        newLogData: filteredData,
        oldLogData: {},
      },
    });
  };

  const handleSort = (sortBy) => {
    const { categories, product_types, bump_screen_tags, status, searchText } =
      state.filterApplied;
    const { pagination } = state;
    filterStateDispatch({
      type: "UPDATE_SORT",
      page: PAGE_NAME,
      value: sortBy,
    });
    handleGetProduct({
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      },
      sortBy,
      searchText,
      categories,
      product_types,
      bump_screen_tags,
      status,
    });
    setState((draft) => {
      draft.sortBy = sortBy;
    });
  };
  const gotoPage = (page) => {
    const { categories, product_types, bump_screen_tags, status, searchText } =
      state.filterApplied;
    const { sortBy } = state;
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
    handleGetProduct({
      pagination: {
        pageIndex: page,
        pageSize: state.pagination.pageSize,
      },
      sortBy,
      searchText,
      categories,
      product_types,
      bump_screen_tags,
      status,
    });
    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };

  const setPageSize = (e) => {
    const { categories, product_types, bump_screen_tags, status, searchText } =
      state.filterApplied;
    const { sortBy } = state;
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
    handleGetProduct({
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
      searchText,
      categories,
      product_types,
      bump_screen_tags,
      status,
    });
    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };

  return {
    state,
    globalData,
    currentTab,
    IsEditable,
    isCreate,
    categoriesList,
    setCurentTab,
    getProducts,
    handleFilter,
    handleFilterClear,
    handleApplyFilter,
    handleSearch,
    onCloneSubmit,
    handleSelectAllCopyOptions,
    onCloneOptionsSelect,
    productCloneOptions,
    onProductCopyClick,
    products: state.productsList.listData,
    handleSort,
    setPageSize,
    gotoPage,
  };
};
