import {
  ContentLayout,
  CustomeDialog,
  HeaderBreadcrumbs,
  Loader,
  useDropdownApis,
} from "../../../shared";
import React from "react";
import {
  Button,
  Stack,
  Card,
  Typography,
  Grid,
  Box,
  TextField,
  InputAdornment,
  Tooltip,
  Checkbox,
  createFilterOptions,
  FormHelperText,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { ReviewMessageDialog } from "../components";
import { usePushNotification } from "..";
import moment from "moment";
import { AsyncPaginate } from "react-select-async-paginate";

export const AddOrEditPushNotification = () => {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const { id } = useParams();
  const {
    state,
    globalData,
    onChange,
    onUpdatePushNotification,
    onCreatePushNotification,
    handleReviewModal,
    handleChangeCoupons,
  } = usePushNotification({ isAdd: !id, isEdit: id });
  const { isLoading, details, title, couponList, isOpen, isButtonDisable } =
    state;
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });

  const { handleCouponList } = useDropdownApis({
    isCouponList: true,
  });

  const onSubmit = (status = 1) => {
    if (validator.current.allValid()) {
      if (!status) {
        submit(status);
      } else {
        handleReviewModal(true);
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const submit = (status) => {
    id
      ? onUpdatePushNotification(id, status)
      : onCreatePushNotification(status);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: "2px solid #E6E8F0",
      height: "48px",
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      maxHeight: "239px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
  };
  return (
    <>
      <ContentLayout
        title={id ? title : "New Push Notification"}
        rightContent={
          <Stack direction="row" gap={2}>
            {state.details.status !== 1 && (
              <Button
                type="button"
                size="medium"
                variant="text"
                disabled={isButtonDisable}
                onClick={() => onSubmit(0)}
              >
                Save As Draft
              </Button>
            )}
            <Button
              type="button"
              size="medium"
              variant="contained"
              disabled={isButtonDisable}
              onClick={() => onSubmit()}
            >
              Review
            </Button>
          </Stack>
        }
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Push Notification", href: "/push-notification" },
              { name: id ? "Edit" : "Create" },
            ].filter(Boolean)}
          />
        }
      >
        {id && isLoading ? (
          <Loader />
        ) : (
          <>
            {validator.current.purgeFields()}
            <Card sx={{ p: "32px 24px", mb: "24px", overflow: "unset" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Basic Details
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack width={"100%"} gap="15px">
                    <InputControl
                      name="type"
                      type="dropdown"
                      multiple={false}
                      options={
                        globalData.push_notifications?.push_notification_type ??
                        []
                      }
                      filterSelectedOptions
                      value={
                        (globalData.push_notifications?.push_notification_type
                          ?.length > 0 &&
                          details.type &&
                          globalData.push_notifications?.push_notification_type?.find(
                            (c) => c.value == details.type
                          )) ||
                        null
                      }
                      onChange={(e, v) => {
                        onChange({
                          target: {
                            name: "type",
                            value: v ? v.value : null,
                          },
                        });
                      }}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) => {
                        return option.value === value.value;
                      }}
                      filterOptions={filterOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Type"
                          required
                          error={validator.current.message(
                            "Type",
                            details.type,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "Type",
                            details.type,
                            "required"
                          )}
                        />
                      )}
                    />
                    {details.type == 1 && (
                      <Box>
                        <AsyncPaginate
                          debounceTimeout={1000}
                          placeholder="Promotion*"
                          menuPlacement="bottom"
                          value={
                            details?.subject
                              ? {
                                value: details?.subject?.id,
                                label: details?.subject?.label,
                              }
                              : ""
                          }
                          loadOptions={async (e, p, { page }) => {
                            return await handleCouponList({
                              deletedItem: 0,
                              search: e,
                              page,
                              status: 2,
                            });
                          }}
                          onChange={(e, v) =>
                            handleChangeCoupons(e, v, "coupons")
                          }
                          additional={{
                            page: 1,
                          }}
                          styles={customStyles}
                        />
                        <FormHelperText sx={{ color: "#C60808" }}>
                          {validator.current.message(
                            "Promotion",
                            details.subject,
                            "required"
                          )}
                        </FormHelperText>
                      </Box>
                    )}
                    {/* <Typography fontWeight={400} color="#808080" fontSize="10px">
                      The campaign target approximatly 130 cutomers
                    </Typography> */}
                  </Stack>
                </Grid>
              </Grid>
            </Card>
            <Card sx={{ p: "32px 24px", mb: "24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Notification
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack width={"100%"} gap="15px">
                    <InputControl
                      name="title"
                      type="outlinedInput"
                      label="Notification Title*"
                      inputLabel="Notification Title*"
                      placeholder="Notification Title *"
                      value={details.title}
                      endAdornment={
                        <InputAdornment position="start">
                          <Tooltip title="Text.">
                            <img alt="details" src="/icons/ic_info.svg" />
                          </Tooltip>
                        </InputAdornment>
                      }
                      onChange={onChange}
                      error={validator.current.message(
                        "Notification Title",
                        details.title,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "Notification Title",
                        details.title,
                        "required"
                      )}
                    />
                    <Stack gap="2px">
                      <InputControl
                        type="textarea"
                        name="description"
                        label="Notification Text"
                        rows={4}
                        onChange={onChange}
                        value={details.description}
                        required
                        error={validator.current.message(
                          "Notification Text",
                          details.description,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Notification Text",
                          details.description,
                          "required"
                        )}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
            <Card sx={{ p: "32px 24px", mb: "24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Other Details
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack spacing={2}>
                    <InputControl
                      name="schedule"
                      type="dropdown"
                      options={
                        globalData.push_notifications
                          ?.push_notification_schedule ?? []
                      }
                      getOptionLabel={(option) => option.label}
                      value={
                        (globalData.push_notifications
                          ?.push_notification_schedule?.length > 0 &&
                          globalData.push_notifications?.push_notification_schedule?.find(
                            (c) => c.value === details.schedule
                          )) ||
                        null
                      }
                      onChange={(e, v) => {
                        onChange({
                          target: {
                            name: "schedule",
                            value: v ? v.value : null,
                          },
                        });
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option.value === value.value;
                      }}
                      filterOptions={filterOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Scheduling"
                          required
                          error={validator.current.message(
                            "schedule",
                            details.schedule,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "schedule",
                            details.schedule,
                            "required"
                          )}
                        />
                      )}
                    />
                    {details.schedule == 1 && (
                      <>
                        <Stack
                          sx={{
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          <InputControl
                            type="datePicker"
                            name="scheduled_date"
                            label="Date"
                            inputFormat="dd-MM-yyyy"
                            disablePast={true}
                            required
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            value={details.scheduled_date}
                            onChange={(e) =>
                              onChange({
                                target: {
                                  name: "scheduled_date",
                                  value: e,
                                },
                              })
                            }
                            sx={{
                              width: "100%",
                            }}
                            error={validator.current.message(
                              "Date",
                              details.scheduled_date,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Date",
                              details.scheduled_date,
                              "required"
                            )}
                          />
                          <InputControl
                            ampm={false}
                            type="timePicker"
                            label="Time *"
                            name="scheduled_time"
                            value={moment(
                              details.scheduled_time,
                              "HH:mm:ss"
                            ).format("YYYY-MM-DD HH:mm:ss")}
                            onChange={(e) =>
                              onChange({
                                target: {
                                  name: "scheduled_time",
                                  value: moment(e).format("HH:mm:ss"),
                                },
                              })
                            }
                            error={validator.current.message(
                              "Start Time",
                              details.scheduled_time,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Start Time",
                              details.scheduled_time,
                              "required"
                            )}
                          />
                        </Stack>
                        <InputControl
                          name="timezone"
                          label="Select Time Zone"
                          type="select"
                          value={details.timezone}
                          optionValue={globalData?.timezones ?? []}
                          onChange={onChange}
                          error={validator.current.message(
                            "timezone",
                            details.timezone,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "timezone",
                            details.timezone,
                            "required"
                          )}
                        />
                        <Stack direction="row" gap={1}>
                          <Checkbox
                            checked={details.store_wise_timezone}
                            onChange={(e) =>
                              onChange({
                                target: {
                                  name: "store_wise_timezone",
                                  value: e.target.checked,
                                },
                              })
                            }
                          />
                          <Typography fontWeight={400} mt={1}>
                            Send On Store Timezone
                          </Typography>

                          <Tooltip title="Send on store time zone.">
                            <Box mt={1}>
                              <img alt="delete" src="/icons/icon_not.svg" />
                            </Box>
                          </Tooltip>
                        </Stack>
                      </>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Card>
            <Card sx={{ p: "32px 24px", mb: "24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    <Stack direction="row" gap={1}>
                      Analytics Label
                      <Tooltip title="Adding a label to your message will allow you to filter by it under the Reports tab.">
                        <Box>
                          <img alt="delete" src="/icons/icon_not.svg" />
                        </Box>
                      </Tooltip>
                    </Stack>
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack width={"100%"} gap="10px">
                    <InputControl
                      name="analytics_label"
                      label="Test Push"
                      value={details.analytics_label}
                      onChange={onChange}
                    />
                    <Typography
                      fontWeight={400}
                      color="#808080"
                      fontSize="10px"
                    >
                      Max 60 Charecters
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </ContentLayout>
      <CustomeDialog
        open={isOpen}
        handleClose={() => handleReviewModal(false)}
        PaperProps={{
          sx: { width: "612px", height: "332px", p: "16px" },
        }}
        title="Review Message"
        content={
          <ReviewMessageDialog
            couponList={couponList}
            isLoading={isButtonDisable}
            data={details}
            state={state}
            onSubmit={submit}
          />
        }
      />
    </>
  );
};
