import {
  ContentLayout,
  CustomTable,
  CustomeDialog,
  Loader,
  usePermission,
} from "../../../shared";
import * as React from "react";
import { useLedgerAccounts } from "../hooks/useLedgerAccounts";
import { LedgerAccountDialog } from "../components/LedgerAccountDialog";

export const ConfigurationsList = () => {
  const { state, handleModal, onSearch, onSaveConfig, handleRadio } =
    useLedgerAccounts({ isConfig: true });

  const {
    paymentTypeConfigList,
    saleTypeConfigList,
    accountConfigList,
    Isload,
    searchText,
    isSaveButton,
    isOpen,
    coa,
    account_id,
    accountType,
  } = state;
  const isEdit = usePermission("coa-modify");
  const ActionButtons = ({ onAction }) => {
    return (
      <div className="action-buttons">
        {isEdit && (
          <img alt="edit" src="/icons/ic_edit.svg" onClick={onAction} />
        )}
      </div>
    );
  };

  const columns2 = React.useMemo(
    () => [
      {
        Header: "Headers",
        accessor: "header",
      },
      {
        Header: "Account Name",
        accessor: "accountName",
      },
      {
        Header: "Action",
        id: "id",
        accessor: "id",
        align: "right",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <ActionButtons
              onAction={() =>
                handleModal("config-add", true, "PaymentType", row.original)
              }
            />
          );
        },
      },
    ],
    []
  );

  const columns3 = React.useMemo(
    () => [
      {
        Header: "Headers",
        accessor: "header",
      },
      {
        Header: "Account Name",
        accessor: "accountName",
      },
      {
        Header: "Action",
        id: "id",
        accessor: "id",
        align: "right",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <ActionButtons
              onAction={() =>
                handleModal("config-add", true, "ChannelType", row.original)
              }
            />
          );
        },
      },
    ],
    []
  );
  const columns4 = React.useMemo(
    () => [
      {
        Header: "Headers",
        accessor: "header",
      },
      {
        Header: "Account Name",
        accessor: "accountName",
      },
      {
        Header: "Action",
        id: "id",
        accessor: "id",
        align: "right",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <ActionButtons
              onAction={() =>
                handleModal("config-add", true, "AccountConfig", row.original)
              }
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <ContentLayout title="Configurations">
        {Isload ? (
          <Loader />
        ) : (
          <>
            <CustomTable
              tableTitle="Payment Media"
              defaultSearch={false}
              searchBar={false}
              columns={columns2}
              data={paymentTypeConfigList}
              pagination={false}
            />

            <CustomTable
              tableTitle="Sales Channel"
              defaultSearch={false}
              searchBar={false}
              columns={columns3}
              data={saleTypeConfigList}
              pagination={false}
            />
            <CustomTable
              tableTitle="Others"
              defaultSearch={false}
              searchBar={false}
              columns={columns4}
              data={accountConfigList}
              pagination={false}
            />
          </>
        )}
      </ContentLayout>
      <CustomeDialog
        open={isOpen}
        handleClose={() => handleModal("config-add", false, null, null)}
        PaperProps={{
          sx: { width: "686px", height: "687px", p: "16px" },
        }}
        title={accountType}
        content={
          <LedgerAccountDialog
            dataList={coa.filteredList}
            loading={coa.isLoading}
            selectedIDs={account_id}
            handleCheckAccount={handleRadio}
            onSubmit={onSaveConfig}
            onSearch={onSearch}
            searchText={searchText}
            isSaveButton={isSaveButton}
            isConfig={true}
          />
        }
      />
    </>
  );
};
