import * as React from "react";
import {
  ContentLayout,
  CustomTable,
  CustomDetailsList,
  Loader,
  HeaderBreadcrumbs,
} from "../../../shared";
import { useLocation, useParams } from "react-router-dom";
import { sortItems } from "../../../utils";
import { useClientAccount, useCustomers } from "../..";
import { Chip, Typography } from "@mui/material";
import moment from "moment";

export const ClientAccountTransactions = () => {
  const location = useLocation();
  let url = location?.state?.from;
  const { id, t_id } = useParams();
  const { isStoreAdmin } = useClientAccount({ isList: false });
  const { state: customersState } = useCustomers({
    ClientAccountID: id,
    tId: t_id,
  });
  const { isLoading, transaction } = customersState?.clientAccountDetails;
  const columns = React.useMemo(
    () => [
      {
        Header: "order number",
        accessor: "transaction_id",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Transaction Date",
        disableSortBy: true,
        accessor: "transaction_date",
      },
      {
        Header: "Order Amount",
        disableSortBy: true,
        accessor: (value) => {
          return value?.order_amount?.toFixed(2);
        },
      },
      {
        Header: "Amount Paid",
        disableSortBy: true,
        accessor: (value) => {
          return value?.amount_paid?.toFixed(2);
        },
      },
      {
        Header: "Settled Amount",
        disableSortBy: true,
        accessor: (value) => {
          return value?.settled_amount?.toFixed(2);
        },
      },
      {
        Header: "Balance",
        align: "right",
        accessor: (value) => {
          return value?.balance > 0 ? (
            <Typography color="#C42B1C" fontSize="14px">
              {value?.balance?.toFixed(2)}
            </Typography>
          ) : (
            <Typography fontSize="14px">
              {value?.balance?.toFixed(2)}
            </Typography>
          );
        },
        disableSortBy: true,
      },
    ],
    []
  );

  const detailsColumns = [
    {
      title: "Customer",
      accessor: "customer_name",
    },
    {
      title: "Transaction Id",
      accessor: "transaction_id",
    },
    {
      title: "Reference Id",
      accessor: "reference_id",
    },
    {
      title: "Payment Media",
      accessor: "payment_method",
    },
    {
      title: "Amount",
      accessor: ({ amount }) => {
        return "$" + amount?.toFixed(2);
      },
    },
    {
      title: "Transaction Type",
      accessor: (value) => {
        return (
          <Chip
            label={value?.transaction_type}
            color={value?.transaction_type === "Payment" ? "success" : "error"}
            variant="outlined"
            size="small"
            sx={{
              border: "none !important",
            }}
          />
        );
      },
    },
    {
      title: "Transaction Date",
      accessor: "transaction_date",
    },
    {
      title: "Statement Date",
      accessor: ({ settlement_date }) =>
        settlement_date ? moment(settlement_date).format("DD/MM/yy") : "",
    },
    {
      title: "Note",
      accessor: "notes",
    },
  ];
  return (
    <>
      <ContentLayout
        title={transaction?.transaction_id}
        breadcrumbs={
          url ? (
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },

                {
                  name: "Customers",
                  href: url,
                },
                {
                  name: "Client Account",
                  href: isStoreAdmin
                    ? `/store-admin/client-account`
                    : "/client-account",
                },
                {
                  name: transaction?.customer_name,
                  href: isStoreAdmin
                    ? `/store-admin/client-account/details/${id}`
                    : `/client-account/details/${id}`,
                },
                {
                  name: transaction?.transaction_id,
                },
              ]}
            />
          ) : (
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                {
                  name: "Client Account",
                  href: isStoreAdmin
                    ? `/store-admin/client-account`
                    : "/client-account",
                },
                {
                  name: transaction?.customer_name,
                  href: isStoreAdmin
                    ? `/store-admin/client-account/details/${id}`
                    : `/client-account/details/${id}`,
                },
                {
                  name: transaction?.transaction_id,
                },
              ]}
            />
          )
        }
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <CustomDetailsList
              columns={detailsColumns}
              data={transaction}
              title="Transaction Details"
            />
            {!transaction?.refund_id && (
              <CustomTable
                columns={columns}
                data={transaction?.settlement_details ?? []}
                searchBar={false}
                tableTitle="Settled Transactions"
              />
            )}
          </>
        )}
      </ContentLayout>
    </>
  );
};
