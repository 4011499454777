import * as React from "react";
import { Button, Stack } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";

export const AddUberStoreIdDialog = ({ state, onChange, handleUpdate }) => {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const onSubmit = () => {
    if (validator.current.allValid()) {
      handleUpdate();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  return (
    <Stack
      justifyContent="space-between"
      height="100%"
      gap="44px"
      maxHeight={"calc(100% - 24px)"}
      mt="32px"
    >
      <InputControl
        name="ubereats_store_id"
        label="Ubereats Store Id"
        sx={{
          width: "100%",
          bgcolor: "#fff",
        }}
        onChange={onChange}
        value={state?.data?.ubereats_store_id}
        error={validator.current.message(
          "Ubereats Store Id",
          state.data.ubereats_store_id,
          "required"
        )}
        helperText={validator.current.message(
          "Ubereats Store Id",
          state.data.ubereats_store_id,
          "required"
        )}
      />
      <Button
        onClick={onSubmit}
        sx={{ height: "40px" }}
        variant="contained"
        fullWidth
        disabled={state?.isSaveButtonBusy}
      >
        Save
      </Button>
    </Stack>
  );
};
