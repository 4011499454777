import * as React from "react";
import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useTabs } from "../../hooks";
import { useLocation, Link } from "react-router-dom";

export const SidebarSubMenu = (props) => {
  const { item, index } = props;
  const [open, setOpen] = React.useState(false);
  const { resetCurrentTabs } = useTabs();
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const basepath = isStoreAdmin
    ? "/" + pathname.split("/")[2]
    : "/" + pathname.split("/")[1];

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <Box
      key={index}
      sx={{
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        py: "8px",
      }}
      className={`${basepath === item.href ? "sidebar-link-active" : ""}`}
    >
      {item?.openInNewTab ? (
        <a className="sidebar-link" href={`${item.href}`} target="_blank">
          <ListItemButton
            key={item.title}
            sx={{ py: 0, minHeight: 32, }}
            onClick={() => handleClick(index)}
          >
            <ListItemIcon className="sidebar-icon">{item?.icon}</ListItemIcon>
            <ListItemText>{item.title}</ListItemText>
          </ListItemButton>
        </a>
      ) : (
        <Link
          to={isStoreAdmin ? `/store-admin${item.href}` : `${item.href}`}
          onClick={() => resetCurrentTabs()}
          className="sidebar-link"
        >
          <ListItemButton
            key={item.title}
            sx={{ py: 0, minHeight: 32 }}
            onClick={() => handleClick(index)}
          >
            <ListItemIcon className="sidebar-icon">{item?.icon}</ListItemIcon>
            <ListItemText>{item.title}</ListItemText>
          </ListItemButton>
        </Link>
      )}
    </Box>
  );
};
