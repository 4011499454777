import { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import {
  getTaxGroupList,
  deleteTaxGroupById,
  getTaxesList,
  deleteTaxesById,
  createTaxes,
  getTaxDetails,
  updateTaxes,
  createTaxGroup,
  getTaxGroupDetails,
  updateTaxGroup,
  assignTaxToTaxGroup,
  deleteTaxFromTaxGroup,
} from "..";
import { successMessage, errorMessage } from "../../../utils";
import { AppContext } from "../../../store/AppScopeProvider";
import { useTabs } from "../../../shared/hooks/useTabs";
import { useNavigate } from "react-router-dom";

export const useTax = ({
  isList,
  isTaxesId,
  isTaxesEdit,
  isTaxGroupId,
  isTaxGroupEdit,
}) => {
  const navigate = useNavigate();
  const { appState } = useContext(AppContext);
  const { setCurentTab } = useTabs();
  const { taxGroupsAndTaxes: currentTab } = appState.currentTabs;
  const [state, setState] = useImmer({
    taxGroup: {
      listData: [],
      isLoading: true,
      deleteModalOpen: false,
      selectedId: null,
      updateData: {
        name: "",
      },
      isSaveButton: false,
      details: null,
      editLoading: false,
    },
    taxGroupDetails: {
      isLoading: true,
      isOpen: false,
      isSaveButton: false,
      filteredList: [],
      taxesList: [],
      selectedtaxes: [],
      deleteModalOpen: false,
      deleteDetailsModalOpen: false,
      selectedId: null,
      searchText: null,
    },
    taxes: {
      listData: [],
      isLoading: true,
      deleteModalOpen: false,
      selectedId: null,
      updateData: {
        name: "",
        tax_rate: "",
      },
      isSaveButton: false,
      details: null,
      editLoading: false,
    },
    httpStatusCodes: {
      details: "",
    },
  });
  useEffect(() => {
    if (isList && currentTab === "1" && state.taxGroup.listData.length === 0) {
      HandleTaxGroupList();
    }
    if (isList && currentTab === "2" && state.taxes.listData.length === 0) {
      HandleTaxesList();
    }
  }, [currentTab]);

  //tax-group

  const HandleTaxGroupList = async () => {
    try {
      const res = await getTaxGroupList();

      setState((draft) => {
        draft.taxGroup.listData = res.data;
        draft.taxGroup.isLoading = false;
      });
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };

  const handleDeleteTaxGroup = async () => {
    try {
      const res = await deleteTaxGroupById(state.taxGroup.selectedId);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.taxGroup.listData = draft.taxGroup.listData.filter(
            (item) => item.id !== state.taxGroup.selectedId
          );
        });
        if (isTaxGroupId) {
          handleModal("TG-detail-delete", false, null);
          navigate("/tax-groups-and-taxes");
        } else {
          handleModal("TG-list-delete", false, null);
        }
      }else{
        handleModal("TG-detail-delete", false, null);
        handleModal("TG-list-delete", false, null);
        errorMessage(res.message)       
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleTaxGroupInputChange = (event) => {
    const value = event.target.value;
    setState((draft) => {
      draft.taxGroup.updateData.name = value;
    });
  };

  const onSaveTaxGroup = async () => {
    try {
      setState((draft) => {
        draft.taxGroup.isSaveButton = true;
      });
      const res = await createTaxGroup(state.taxGroup.updateData);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.taxGroup.isSaveButton = false;
        });
        navigate(-1);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.taxGroup.isSaveButton = false;
      });
    }
  };

  const HandleTaxGroupDetails = async () => {
    setState((draft) => {
      draft.taxGroup.editLoading = true;
    });
    try {
      const res = await getTaxGroupDetails(isTaxGroupId);
      if (res.success) {
        setState((draft) => {
          draft.taxGroup.details = res.data;
          draft.taxGroup.updateData.name = res.data.name;
          draft.taxGroupDetails.taxesList = res.data.Taxes;
          draft.taxGroup.editLoading = false;
          draft.taxGroupDetails.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.taxGroupDetails.isLoading = false;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };
  const onUpdateTaxGroup = async (id) => {
    try {
      setState((draft) => {
        draft.taxGroup.isSaveButton = true;
      });
      const res = await updateTaxGroup(state.taxGroup.updateData, id);
      if (res.success) {
        successMessage("Updated Successfully");
        setState((draft) => {
          draft.taxGroup.isSaveButton = false;
        });
        navigate(-1);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.taxGroup.isSaveButton = false;
      });
    }
  };

  //tax-group details
  const HandleFilterTaxes = (type, text) => {
    const alreadyTaxesList = state.taxGroupDetails.taxesList.map((val) => {
      return val.id;
    });
    const filteredTaxList = state.taxes.listData.filter(
      (val) => !alreadyTaxesList.includes(val.id)
    );
    if (type === "initial") {
      setState((draft) => {
        draft.taxGroupDetails.searchText = "";
        draft.taxGroupDetails.filteredList = filteredTaxList;
        return draft;
      });
    } else {
      text = text.toLowerCase();
      setState((draft) => {
        draft.taxGroupDetails.searchText = text;
        draft.taxGroupDetails.filteredList = filteredTaxList.filter((val) => {
          return val.name.toString().toLowerCase().indexOf(text) > -1;
        });
        return draft;
      });
    }
  };

  const handleTaxesSelect = (id) => {
    const isSelcted = state.taxGroupDetails.selectedtaxes.includes(id);
    if (isSelcted) {
      setState((draft) => {
        draft.taxGroupDetails.selectedtaxes =
          draft.taxGroupDetails.selectedtaxes.filter((val) => val !== id);
      });
    } else {
      setState((draft) => {
        draft.taxGroupDetails.selectedtaxes = [
          ...draft.taxGroupDetails.selectedtaxes,
          id,
        ];
        return draft;
      });
    }
  };

  const handleAssignTaxToTaxGroup = async () => {
    const data = {
      tax_id: state.taxGroupDetails.selectedtaxes,
    };
    setState((draft) => {
      draft.taxGroupDetails.isSaveButton = true
    });
    try {
      const res = await assignTaxToTaxGroup(data, isTaxGroupId);
      if (res.success === true) {
        successMessage(res.message);
        setState((draft) => {
          draft.taxGroupDetails.taxesList = [
            ...draft.taxGroupDetails.taxesList,
            ...draft.taxes.listData.filter((val) =>
              draft.taxGroupDetails.selectedtaxes.includes(val.id)
            ),
          ];
          draft.taxGroupDetails.selectedtaxes = [];
          draft.taxGroupDetails.isSaveButton = false
          return draft;
        });
        handleModal("tax-add", false);
        HandleFilterTaxes("initial");
      }
    } catch (error) {
      setState((draft) => {
        draft.taxGroupDetails.isSaveButton = false
      });
      errorMessage(error.response.data.message);
    }
  };

  const handleDeleteTaxFromTG = async () => {
    try {
      const res = await deleteTaxFromTaxGroup(
        isTaxGroupId,
        state.taxGroupDetails.selectedId
      );
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.taxGroupDetails.taxesList =
            draft.taxGroupDetails.taxesList.filter(
              (item) => item.id !== state.taxGroupDetails.selectedId
            );
        });
        handleModal("tax-delete-from-TG", false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  useEffect(() => {
    HandleFilterTaxes("initial");
  }, [state.taxes.listData, state.taxGroupDetails.taxesList]);

  //taxes

  const HandleTaxesList = async () => {
    if (state.taxes.listData.length === 0) {
      try {
        const res = await getTaxesList();
        setState((draft) => {
          draft.taxes.listData = res.data;
          draft.taxes.isLoading = false;
        });
      } catch (error) {
        errorMessage(error.response.data.message);
      }
    } else {
      HandleFilterTaxes("initial");
    }
  };

  const handleDeleteTaxes = async () => {
    try {
      const res = await deleteTaxesById(state.taxes.selectedId);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.taxes.listData = draft.taxes.listData.filter(
            (item) => item.id !== state.taxes.selectedId
          );
        });
        handleModal("Taxes-list-delete", false, null);
        HandleTaxGroupList();
      }else{
        errorMessage(res.message);
        handleModal("Taxes-list-delete", false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      console.log(err);
    }
  };

  const handleTaxesInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setState((draft) => {
      draft.taxes.updateData = {
        ...draft.taxes.updateData,
        [name]: value,
      };
    });
  };
  const onSaveTaxes = async () => {
    try {
      setState((draft) => {
        draft.taxes.isSaveButton = true;
      });
      const res = await createTaxes(state.taxes.updateData);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.taxes.isSaveButton = false;
        });
        navigate(-1);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.taxes.isSaveButton = false;
      });
    }
  };

  const HandleTaxesDetails = async () => {
    setState((draft) => {
      draft.taxes.editLoading = true;
    });
    try {
      const res = await getTaxDetails(isTaxesId);
      if (res.success) {
        setState((draft) => {
          draft.taxes.details = res.data;
          draft.taxes.updateData.name = res.data.name;
          draft.taxes.updateData.tax_rate = res.data.tax_rate;
          draft.taxes.editLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const onUpdateTaxes = async (id) => {
    try {
      setState((draft) => {
        draft.taxes.isSaveButton = true;
      });
      const res = await updateTaxes(state.taxes.updateData, id);
      if (res.success) {
        successMessage("Updated Successfully");
        setState((draft) => {
          draft.taxes.isSaveButton = false;
        });
        navigate(-1);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.taxes.isSaveButton = false;
      });
    }
  };
  const handleModal = (type, state, data) => {
    switch (type) {
      case "TG-list-delete":
        setState((draft) => {
          draft.taxGroup.deleteModalOpen = state;
          draft.taxGroup.selectedId = data;
        });
        break;
      case "Taxes-list-delete":
        setState((draft) => {
          draft.taxes.deleteModalOpen = state;
          draft.taxes.selectedId = data;
        });
        break;
      case "tax-add":
        if (!state) {
          setState((draft) => {
            draft.taxGroupDetails.selectedtaxes = [];
            draft.taxGroupDetails.filteredList = [];
            draft.taxGroupDetails.isOpen = state;
          });
        } else {
          HandleTaxesList();
          setState((draft) => {
            draft.taxGroupDetails.isOpen = state;
          });
        }
        break;
      case "tax-delete-from-TG":
        setState((draft) => {
          draft.taxGroupDetails.deleteModalOpen = state;
          draft.taxGroupDetails.selectedId = data;
        });
        break;
      case "TG-detail-delete":
        setState((draft) => {
          draft.taxGroupDetails.deleteDetailsModalOpen = state;
          draft.taxGroup.selectedId = data;
        });
        break;
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurentTab("taxGroupsAndTaxes", newValue);
  };

  useEffect(() => {
    if (isTaxesEdit && state.taxes.details === null) {
      HandleTaxesDetails();
    }
    if (isTaxGroupEdit && state.taxGroup.details === null) {
      HandleTaxGroupDetails();
    }
  }, [isTaxesEdit, isTaxGroupEdit]);

  return {
    state,
    currentTab,
    handleTabChange,
    handleModal,
    handleDeleteTaxGroup,
    handleDeleteTaxes,
    handleTaxesInputChange,
    onSaveTaxes,
    onUpdateTaxes,
    handleTaxGroupInputChange,
    onSaveTaxGroup,
    onUpdateTaxGroup,
    HandleFilterTaxes,
    handleTaxesSelect,
    handleAssignTaxToTaxGroup,
    handleDeleteTaxFromTG,
  };
};
