import { ContentLayout, DeleteModal } from "../../../shared";
import * as React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CustomTable } from "../../../shared";
import { useNavigate, useParams } from "react-router-dom";
import { useConditions } from "../hooks/useConditions";
import { sortItems } from "../../../utils";

export const ConditionsList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    state,
    handleModal,
    handleDeleteDiscount,
    isCreateVisible,
    isDeletable,
    isEditable,
  } = useConditions({
    isConditions: true,
  });
  const ActionButtons = ({ id, editAction, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
        {isEditable && (
          <img
            alt="details"
            onClick={editAction}
            src="/icons/arrow_forward.svg"
          />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },

      {
        Header: "Action",
        id: "action",
        accessor: "id",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return (
            <ActionButtons
              id={value}
              editAction={() => navigate(`edit/${value}`)}
              deleteAction={() => {
                handleModal(value);
              }}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <ContentLayout
        title="Coupon Rules"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("create")}
              >
                Add Rules
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={state.conditions}
          tableId="condition_list"
          isLoading={state?.isBusy}
          onRowLink={(e) => {
            return `edit/${e.id}`;
          }}
        />
      </ContentLayout>
      <DeleteModal
        open={state.isOpen}
        handleClose={() => handleModal()}
        onConfirm={() => handleDeleteDiscount()}
      />
    </>
  );
};
