import {
  ContentLayout,
  DeleteModal,
  CustomeDialog,
  CheckBoxListDialog,
  ServerPaginatedTable,
} from "../../../shared";
import * as React from "react";
import {
  Button,
  Chip,
  createFilterOptions,
  Checkbox,
  TextField,
  Stack,
  Avatar,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { arraySort, sortItems, sortNestedItems } from "../../../utils";
import { Box } from "@mui/system";
import { useProductsList } from "../hooks";

export const SuperProductsList = ({ data }) => {
  const navigate = useNavigate();
  const {
    state,
    globalData,
    IsEditable,
    isCreate,
    categoriesList,
    setCurentTab,
    handleApplyFilter,
    handleFilter,
    handleFilterClear,
    handleSearch,
    onSeachClear,
    onCloneSubmit,
    handleSelectAllCopyOptions,
    onCloneOptionsSelect,
    productCloneOptions,
    onProductCopyClick,
    handleSort,
    setPageSize,
    gotoPage,
  } = useProductsList({
    isList: true,
    isProduct: true,
  });
  const {
    filterApplied,
    BumpScreenTags,
    copyProduct: { isOpen, productName, copyOptions, isBusy },
    pagination,
    disableApplyButton,
  } = state;
  const productTypes = globalData?.product?.type
    ? [...globalData?.product?.type, { label: "Gift Card", value: "gift_card" }]
    : [];
  const { isLoading, listData } = state.productsList;
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });

  const ActionButtons = ({ editAction, row }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isCreate && (
          <img
            onClick={() => {
              !["gift_card", "delivery"].includes(row?.product_type) &&
                onProductCopyClick(row.name, row.id);
            }}
            alt="details"
            src="/icons/ic_copy.svg"
            className={` ${
              ["gift_card", "delivery"].includes(row?.product_type) &&
              "disabled-img"
            }`}
          />
        )}
        {IsEditable && (
          <img
            alt="details"
            onClick={editAction}
            src="/icons/arrow_forward.svg"
          />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        id: "name",
        accessor: (row, index) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <Avatar
                src={row.image}
                variant="rounded"
                sx={{ width: 44, height: 44 }}
              />
              <span className="link-text">{row.name}</span>
            </Stack>
          );
        },
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ value }) => {
          return value?.name;
        },
        sortType: (prev, curr, columnId) => {
          return sortNestedItems(prev, curr, columnId, "name");
        },
      },
      {
        Header: "Type",
        accessor: "product_type",
        Cell: ({ value }) => {
          return (
            <Typography variant="body2">
              {productTypes.find((item) => item.value === value)?.label}
            </Typography>
          );
        },
      },
      {
        Header: "Bump Screen Tags",
        accessor: "bump_screen_tags",
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return (
            <Box>
              {value?.slice(0, 4).map((data) => {
                return (
                  <Chip
                    sx={{ mr: 1, borderRadius: "8px" }}
                    label={data}
                    variant="filled"
                    size="small"
                  />
                );
              })}
              {value?.length > 4 && (
                <Chip
                  sx={{ borderRadius: "8px" }}
                  label={`+${value?.length - value?.slice(0, 4)?.length}`}
                  variant="filled"
                  size="small"
                />
              )}
            </Box>
          );
        },
      },

      {
        Header: "Status",
        accessor: "status",
        sortInverted: true,
        Cell: ({ value }) => {
          return value === 1 ? (
            <Chip
              label="Active"
              color="success"
              variant="outlined"
              size="small"
            />
          ) : (
            <Chip
              label="Inactive"
              color="success"
              variant="outlined"
              size="small"
              disabled
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              editAction={() => {
                setCurentTab("productDetails", "1");
                navigate(`details/${row.id}`);
              }}
              row={row}
            />
          );
        },
      },
    ],
    [productTypes]
  );
  const categoryOptions = categoriesList?.map((v) => {
    return { value: v.id, label: v.name };
  });
  const BumpScreenOption = BumpScreenTags?.map((item) => ({
    value: item,
    label: item,
  }));

  return (
    <>
      <ContentLayout
        title="Products"
        rightContent={
          <>
            {isCreate && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("create")}
              >
                Add Products
              </Button>
            )}
          </>
        }
      >
        <ServerPaginatedTable
          columns={columns}
          data={listData}
          isLoading={isLoading}
          filterApplied={filterApplied.all}
          onFilterClear={(e) => handleFilterClear("single", e)}
          onAllFilterClear={(e) => handleFilterClear("all")}
          defaultSearch={false}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
          pagination={state.pagination}
          sortByGlobal={state.sortBy}
          handleSort={handleSort}
          filterContent={
            <InputControl
              type="table-search"
              value={filterApplied.searchText}
              onChange={(e) => handleSearch(e.target.value)}
              onClear={() => handleSearch("")}
            />
          }
          filterSection={
            <>
              <Stack
                direction="row"
                gap="16px"
                alignItems="center"
                flexWrap="wrap"
              >
                <InputControl
                  type="select"
                  name="Status"
                  label="Status"
                  value={
                    filterApplied?.status?.value
                      ? filterApplied?.status?.value
                      : ""
                  }
                  optionValue={globalData?.status || []}
                  width="150px"
                  onChange={(e, v) => handleFilter(e, v, "status")}
                />
                <InputControl
                  type="dropdown"
                  name="Category"
                  label="Category"
                  multiple={true}
                  options={arraySort(categoryOptions || [], "label")}
                  value={filterApplied.categories}
                  disableCloseOnSelect
                  disableClearable={true}
                  onChange={(e, v) => handleFilter(e, v, "categories")}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category"
                      placeholder="Category"
                    />
                  )}
                />
                <InputControl
                  type="dropdown"
                  name="product_types"
                  label="Type"
                  multiple={true}
                  options={arraySort(productTypes || [], "label")}
                  value={filterApplied.product_types}
                  disableCloseOnSelect
                  disableClearable={true}
                  onChange={(e, v) => handleFilter(e, v, "Type")}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Type" placeholder="Type" />
                  )}
                />
                <InputControl
                  type="dropdown"
                  name="bump_screen_tags"
                  label="Bump Screen Tag"
                  multiple={true}
                  options={arraySort(BumpScreenOption || [], "label")}
                  value={filterApplied.bump_screen_tags}
                  disableCloseOnSelect
                  disableClearable={true}
                  onChange={(e, v) => handleFilter(e, v, "Bump Screen Tag")}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Bump Screen Tag"
                      placeholder="Bump Screen Tag"
                    />
                  )}
                />
              </Stack>
              <Button
                type="button"
                size="medium"
                variant="text"
                color="info"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                disabled={disableApplyButton}
                onClick={() => handleApplyFilter()}
              >
                Apply
              </Button>
            </>
          }
          onRowLink={(e) => {
            if (IsEditable) {
              return `details/${e.id}`;
            }
          }}
          onRowAction={() => {
            if (IsEditable) {
              setCurentTab("productDetails", "1");
            }
          }}
        />
      </ContentLayout>
      <CustomeDialog
        open={isOpen}
        handleClose={() => onProductCopyClick()}
        PaperProps={{
          sx: { width: "423px", height: "408px", p: "16px" },
        }}
        title="Copy Product"
        content={
          <CheckBoxListDialog
            Items={productCloneOptions || []}
            onSelect={onCloneOptionsSelect}
            onSubmit={onCloneSubmit}
            isSelected={(id) => copyOptions.includes(id)}
            searchBar={false}
            subHeader={`Creating a copy of product "${productName}"`}
            hasSelectAll={true}
            onAllSelect={handleSelectAllCopyOptions}
            isSelectAllChecked={productCloneOptions?.every((item) =>
              copyOptions.includes(item.id)
            )}
            selectAllText="Include All"
            loading={isBusy}
          />
        }
      />
    </>
  );
};
