import { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import {
  getRoleDetailsList,
  getPermissionList,
  getUsersList,
  assignUserToRole,
  deleteRoleById,
  deleteMemberFromRole,
  getRolesList,
} from "..";
import { useParams, useNavigate } from "react-router-dom";
import { successMessage, errorMessage } from "../../../utils";
import { AppContext } from "../../../store/AppScopeProvider";
import { useTabs } from "../../../shared/hooks/useTabs";

export const useRoleDetailsList = () => {
  const { appState } = useContext(AppContext);
  const { setCurentTab } = useTabs();
  const { rolesAndPermission: currentTab } = appState.currentTabs;
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useImmer({
    isLoading: true,
    data: {
      name: "",
      permission_id: [],
      type: "",
    },
    filterStatus: "",
    allPermissionsList: [],
    membersList: [],
    filteredMembersList: [],
    roleDetails: {},
    allUsers: [],
    filteredUsers: [],
    userSearchText: null,
    selectedUsers: [],
    open: false,
    roleDeleteModal: false,
    memberDeleteModal: false,
    selectedMember: null,
    isSearching: false,
    httpStatusCodes: {
      details: "",
    },
  });
  const HandleInitialPermission = async () => {
    try {
      const res = await getPermissionList();
      if (res.data) {
        setState((draft) => {
          draft.allPermissionsList = res.data;
          return draft;
        });
        HandleRolesDetailsList();
      }
    } catch (error) {}
  };

  const HandleRolesDetailsList = async () => {
    try {
      const res = await getRoleDetailsList(id);
      if (res.data) {
        setState((draft) => {
          draft.roleDetails = res.data;
          draft.data.name = res.data.display_name;
          draft.data.type = res.data.type;
          draft.data.permission_id = res.data.permissions.map((val) => val.id);
          draft.membersList = res.data.members;
          draft.filteredMembersList = res.data.members;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isLoading = false;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };
  useEffect(() => {
    HandleInitialPermission();
  }, [id]);

  const handleChecked = (depth, id, subRows) => {
    if (depth) {
      const check = state.data.permission_id.some((e) => e === id);
      return check;
    } else {
      const subArray = subRows?.map((e, i) => {
        return e.id;
      });
      return subArray.every((val) => state.data.permission_id.includes(val));
    }
  };

  const handleCheckindeterminate = (row) => {
    if (row.depth === 0) {
      const subArray = row.originalSubRows.map((e, i) => {
        return e.id;
      });

      const commonIDs = state.data.permission_id.filter((val) =>
        subArray.includes(val)
      );

      const notAllIDs = subArray.every((val) =>
        state.data.permission_id.includes(val)
      );

      return commonIDs.length > 0 && !notAllIDs;
    }
  };

  //memberList

  const HandleUserList = async () => {
    try {
      const res = await getUsersList();
      if (res.data) {
        setState((draft) => {
          draft.allUsers = res.data;
          return draft;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    HandleUserList();
  }, [id]);

  const HandleFilterUsers = (type, text) => {
    const alreadyMembers = [];
    state.membersList.map((val) => {
      alreadyMembers.push(val.id);
    });
    const filteredUsersList = state.allUsers.filter(
      (val) => !alreadyMembers.includes(val.id)
    );
    if (type === "initial") {
      setState((draft) => {
        draft.filteredUsers = filteredUsersList;
        return draft;
      });
    } else {
      text = text.toLowerCase();
      setState((draft) => {
        draft.userSearchText = text;
        draft.filteredUsers = filteredUsersList.filter(function (o) {
          return ["name", "email"].some(function (k) {
            return o[k].toString().toLowerCase().indexOf(text) !== -1;
          });
        });
        return draft;
      });
    }
  };
  useEffect(() => {
      HandleFilterUsers("initial");
  }, [state.allUsers, state.membersList]);

  const handleUsersSelect = (id) => {
    const isSelcted = state.selectedUsers.includes(id);
    if (isSelcted) {
      setState((draft) => {
        draft.selectedUsers = draft.selectedUsers.filter((val) => val !== id);
      });
    } else {
      setState((draft) => {
        draft.selectedUsers = [...draft.selectedUsers, id];
        return draft;
      });
    }
  };
  const isSelectedUser = (id) => {
    return state.selectedUsers.includes(id);
  };

  const handleAssignUser = async () => {
    const data = {
      role_id: [id],
      user_id: state.selectedUsers,
    };

    try {
      const res = await assignUserToRole(data, id);
      if (res.success === true) {
        handleClose();
        successMessage(res.message);
        setState((draft) => {
          draft.selectedUsers = [];
          draft.isLoading = true;
          return draft;
        });
        HandleRolesDetailsList();
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };

  const HandleStatusFilter = (status) => {
    setState((draft) => {
      draft.filterStatus = status;
    });
    if (status === 1) {
      setState((draft) => {
        draft.filteredMembersList = state.membersList.filter(
          (val) => val.status === 1
        );
        return draft;
      });
    } else if (status === 0) {
      setState((draft) => {
        draft.filteredMembersList = state.membersList.filter(
          (val) => val.status === 0
        );
        return draft;
      });
    } else {
      setState((draft) => {
        draft.filteredMembersList = draft.membersList;
        return draft;
      });
    }
  };

  //dialog Controt

  const handleClickOpen = () => {
    setState((draft) => {
      draft.open = true;
      return draft;
    });
  };

  const handleClose = () => {
    setState((draft) => {
      draft.open = false;
      draft.selectedUsers = [];
      draft.userSearchText = "";
      return draft;
    });
    HandleFilterUsers("initial");
  };

  const handleModal = (data) => {
    setState((draft) => {
      draft.roleDeleteModal = data;
    });
  };

  const handleDeleteRole = async (type = "list") => {
    try {
      const res = await deleteRoleById(id);
      if (res.success) {
        successMessage("Deleted Successfully");
        if (type === "details") {
          navigate("/roles-and-permissions");
        }

        handleModal(false);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      console.log(err);
    }
  };
  const handleMemberModal = (data, id) => {
    setState((draft) => {
      draft.memberDeleteModal = data;
      draft.selectedMember = id;
    });
  };

  const handleDeleteMember = async () => {
    const data = {
      role_id: id,
      user_id: [state.selectedMember],
    };
    try {
      const res = await deleteMemberFromRole(data, id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.membersList = draft.membersList.filter(
            (item) => item.id !== state.selectedMember
          );
          draft.filteredMembersList = draft.filteredMembersList.filter(
            (item) => item.id !== state.selectedMember
          );
        });
        handleMemberModal(false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      console.log(err);
    }
  };
  const resetFilters = () => {
    if (toString(state.filterStatus)) {
      HandleRolesDetailsList();
      setState((draft) => {
        draft.filterStatus = "";
        draft.isSearching = true;
      });
    }
  };
  return {
    state,
    handleChecked,
    handleCheckindeterminate,
    HandleFilterUsers,
    handleUsersSelect,
    isSelectedUser,
    handleAssignUser,
    handleClickOpen,
    handleClose,
    HandleStatusFilter,
    handleModal,
    handleDeleteRole,
    handleMemberModal,
    handleDeleteMember,
    setCurentTab,
    currentTab,
    resetFilters,
  };
};
