import React from "react";
import { AddOrEditSurCharges, SurchargesList } from "./containers";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../shared";

export const Surcharges = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<SurchargesList />} />

        <Route
          element={<ProtectedRoute allowedRoute={["surcharge-create"]} />}
        >
          <Route path="/create" element={<AddOrEditSurCharges />} />
        </Route>

        <Route
          element={<ProtectedRoute allowedRoute={["surcharge-modify"]} />}
        >
          <Route path="/edit/:id" element={<AddOrEditSurCharges />} />
        </Route>

      </Routes>
    </>
  );
};
