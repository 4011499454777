import { API } from "../../../utils";

export const getDoordashList = async () => {
  const res = await API.get(`doordash/stores`);
  return res.data;
};
export const uploadAllMenuDoordash = async () => {
  const res = await API.get(`push_menu_doordash`);
  return res;
};
export const uploadPushMenuDoordash = async (id) => {
  const res = await API.get(`push_menu_doordash/${id}`);
  return res;
};
export const editDoordash = async (payload) => {
  const res = await API.put(`doordash/stores`, payload);
  return res.data;
};
export const onboardSetUp = async (id, payload) => {
  const res = await API.post(`doordash/stores/${id}/onboard`, payload);
  return res.data;
};

export const onActOrDeact = async (id) => {
  const res = await API.get(`doordash/menu_activate_deactivate/${id}`);
  return res.data;
};
