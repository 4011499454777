import React from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  Stack,
  Card,
  Typography,
  Divider,
  Button,
  TextField,
  Checkbox,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InputControl from "../../../shared/components/InputControl";
import { CustomTable, Loader, usePermission } from "../../../shared";
import parse from "html-react-parser";
import SimpleReactValidator from "simple-react-validator";

const filter = createFilterOptions();

export const ProductDetailInfo = (props) => {
  const isEditable = usePermission("product-modify");
  const {
    data,
    globalData,
    HandleDetailsInfoChange,
    onsubmitDetailsInfo,
    handleNestedInput,
  } = props;
  const { isEdit } = data;
  const {
    description,
    ingredients,
    nutritional_information,
    nutritional_description,
    allergenes,
  } = data.data;
  const [, forceUpdate] = React.useState();

  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const onSubmit = () => {
    if (validator.current.allValid()) {
      onsubmitDetailsInfo();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const unitOptions = [
    ...new Set(
      globalData?.nutritional_information?.map((data) => {
        return data.value;
      })
    ),
  ];
  const columns = React.useMemo(
    () => [
      {
        Header: "Nutrition Name",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "Average Qty per Serve",
        accessor: (row) => {
          return `${row?.serving_quantity + " " + row?.unit}`;
        },
        disableSortBy: true,
      },
      {
        Header: "Average Qty per 100g",
        accessor: (row) => {
          return `${row?.quantity + " " + row?.unit}`;
        },
        disableSortBy: true,
      },
    ],
    []
  );

  const columns2 = React.useMemo(
    () => [
      {
        Header: "Nutrition Name",
        accessor: (row, index) => {
          return (
            <InputControl
              name="name"
              value={row.name}
              onChange={(e) => handleNestedInput("onChange", e, index)}
              error={validator.current.message(
                `Nutrition Name ${` `.repeat(index)}`,
                row.name,
                "required"
              )}
              helperText={validator.current.message(
                `Nutrition Name ${` `.repeat(index)}`,
                row.name,
                "required"
              )}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Average Qty per Serve",
        accessor: (row, index) => {
          return (
            <InputControl
              name="serving_quantity"
              type="number"
              value={row.serving_quantity}
              onChange={(e) => handleNestedInput("onChange", e, index)}
              error={validator.current.message(
                `Average qty per serve ${` `.repeat(index)}`,
                row.serving_quantity,
                "required"
              )}
              helperText={validator.current.message(
                `Average qty per serve ${` `.repeat(index)}`,
                row.serving_quantity,
                "required"
              )}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Average Qty per 100g",
        accessor: (row, index) => {
          return (
            <InputControl
              name="quantity"
              type="number"
              value={row.quantity}
              onChange={(e) => handleNestedInput("onChange", e, index)}
              error={validator.current.message(
                `avg qty per 100g ${` `.repeat(index)}`,
                row.quantity,
                "required"
              )}
              helperText={validator.current.message(
                `avg qty per 100g ${` `.repeat(index)}`,
                row.quantity,
                "required"
              )}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Unit",
        accessor: (row, index) => {
          return (
            <InputControl
              id="autocomplete"
              value={row.unit || ""}
              options={unitOptions ?? []}
              type="dropdown"
              className="tags-input"
              onChange={(event, newValue) => {
                handleNestedInput(
                  "onChange",
                  {
                    target: {
                      name: "unit",
                      value: newValue,
                    },
                  },
                  index
                );
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push(inputValue);
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              autoSelect
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "180px" }}
                  error={validator.current.message(
                    `unit ${` `.repeat(index)}`,
                    row.unit,
                    "required"
                  )}
                  helperText={validator.current.message(
                    `unit ${` `.repeat(index)}`,
                    row.unit,
                    "required"
                  )}
                />
              )}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Action",
        accessor: (row, index) => {
          return (
            <div className="action-buttons">
              <img
                alt="delete"
                onClick={() => handleNestedInput("delete", null, index)}
                src="/icons/ic_delete.svg"
              />
            </div>
          );
        },
        disableSortBy: true,
      },
    ],
    [unitOptions]
  );

  return (
    <>
      {validator.current.purgeFields()}
      {data.isLoading ? (
        <Loader />
      ) : (
        <Card sx={{ mb: "24px" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height="83px"
            p="32px 24px"
          >
            <Typography
              variant="subtitle1"
              fontWeight={600}
              lineHeight="19px"
              minWidth="160px"
            >
              Details
            </Typography>
            {isEditable && (
              <Button
                size="medium"
                variant="contained"
                disabled={data.isSaveButton}
                onClick={onSubmit}
              >
                {isEdit ? "Save" : "Edit "}
              </Button>
            )}
          </Stack>

          <Divider />
          {isEdit ? (
            <>
              <Stack sx={{ p: "32px 24px 24px", gap: "24px" }}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  lineHeight="19px"
                  minWidth="160px"
                >
                  Description
                </Typography>
                <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={(e) => HandleDetailsInfoChange("description", e)}
                />
              </Stack>
              <Divider />
              <Stack sx={{ p: "32px 24px 24px", gap: "24px" }}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  lineHeight="19px"
                  minWidth="160px"
                >
                  Nutritional Information
                </Typography>
                <ReactQuill
                  theme="snow"
                  value={nutritional_description}
                  onChange={(e) =>
                    HandleDetailsInfoChange("nutritional_description", e)
                  }
                />
                <CustomTable
                  columns={columns2}
                  data={nutritional_information}
                  pagination={false}
                  searchBar={false}
                  footerContent={
                    <>
                      <Button
                        variant="outlined"
                        sx={{
                          width: "fit-content",
                          border: "none",
                          "&:hover": {
                            border: "none",
                          },
                        }}
                        onClick={() => {
                          handleNestedInput("add");
                        }}
                      >
                        Add Nutrition
                      </Button>
                    </>
                  }
                />
              </Stack>

              <Divider />
              <Stack sx={{ p: "32px 24px 24px", gap: "24px" }}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  lineHeight="19px"
                  minWidth="160px"
                >
                  Ingredients
                </Typography>
                <ReactQuill
                  theme="snow"
                  value={ingredients}
                  onChange={(e) => HandleDetailsInfoChange("ingredients", e)}
                />
              </Stack>

              <Divider />
              <Stack sx={{ p: "32px 24px 24px", gap: "24px" }}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  lineHeight="19px"
                  minWidth="160px"
                >
                  Allergen
                </Typography>
                <ReactQuill
                  theme="snow"
                  value={allergenes}
                  onChange={(e) => HandleDetailsInfoChange("allergenes", e)}
                  readOnly={!isEdit}
                />
              </Stack>
            </>
          ) : (
            <>
              <Stack sx={{ p: "32px 24px 24px", gap: "16px" }}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  lineHeight="19px"
                  minWidth="160px"
                >
                  Description
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={description ? "#171717" : "#636363"}
                >
                  {description && description.replace(/<[^>]+>/g, "")
                    ? parse(description)
                    : " No Description added yet."}
                </Typography>
              </Stack>
              <Divider />
              <Stack sx={{ p: "32px 24px 24px", gap: "16px" }}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  lineHeight="19px"
                  minWidth="160px"
                >
                  Nutritional Information
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={nutritional_description ? "#171717" : "#636363"}
                >
                  {nutritional_description &&
                  nutritional_description.replace(/<[^>]+>/g, "")
                    ? parse(nutritional_description)
                    : " No Nutritional Information added yet."}
                </Typography>
                {nutritional_information.length > 0 && (
                  <CustomTable
                    columns={columns}
                    data={nutritional_information}
                    searchBar={false}
                    rowsSelection={false}
                    pagination={false}
                  />
                )}
              </Stack>

              <Divider />

              <Stack sx={{ p: "32px 24px 24px", gap: "16px" }}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  lineHeight="19px"
                  minWidth="160px"
                >
                  Ingredients
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={ingredients ? "#171717" : "#636363"}
                >
                  {ingredients && ingredients.replace(/<[^>]+>/g, "")
                    ? parse(ingredients)
                    : " No Ingredients added yet."}
                </Typography>
              </Stack>
              <Divider />
              <Stack sx={{ p: "32px 24px 24px", gap: "16px" }}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  lineHeight="19px"
                  minWidth="160px"
                >
                  Allergen
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={allergenes ? "#171717" : "#636363"}
                >
                  {allergenes && allergenes.replace(/<[^>]+>/g, "")
                    ? parse(allergenes)
                    : " No Allergen added yet."}
                </Typography>
              </Stack>
            </>
          )}
        </Card>
      )}
    </>
  );
};
