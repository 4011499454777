import { Box, Grid } from "@mui/material";

import { useDrop } from "react-dnd";
import { Product } from "./Product";

export const Square = ({
  x,
  y,
  products,
  moveProduct,
  grid_view,
  onChangeColor,
}) => {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: "prd",
      drop: (item) => moveProduct(x, y, item, products),
      canDrop: (item) => {
        const hasProduct = products.find((item) => {
          const positionX = item.position[0];
          const positionY = item.position[1];
          return positionX === x && positionY === y;
        });
        if (hasProduct) {
          return false;
        }
        return true;
      },
      collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    }),
    [products]
  );
  const hasItem = products.find(
    (prd) => prd.position[0] === x && prd.position[1] === y
  );
  return (
    <Grid item xs={1.5}>
      <Box
        ref={drop}
        sx={{
          backgroundColor: hasItem ? "" : "#F2F2F2",
          padding: grid_view === "image" && "6px",
          display: "flex",
          justifyContent: "center",
          height: "130px",
          boxShadow: hasItem ? "0px 1px 2px 0px rgba(0, 0, 0, 0.10)" : "",
        }}
      >
        {products.map((item) => {
          const positionX = item.position[0];
          const positionY = item.position[1];
          if (positionX === x && positionY === y) {
            return (
              <Product
                key={item.id}
                product={item}
                grid_view={grid_view}
                onChangeColor={onChangeColor}
              />
            );
          }
        })}
      </Box>
    </Grid>
  );
};
