import { API } from "../../../utils";

export const getOrder = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `orders?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};
export const getOrderList = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `orders?status[]=${1}&page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};
export const getOrderDetailsById = async (id) => {
  const res = await API.get(`orders/${id}`);
  return res.data;
};

export const SendInvoiceById = async (id) => {
  const res = await API.post(`orders/${id}/send-invoice`);
  return res.data;
};

export const DownloadInvoiceeById = async (id) => {
  const res = await API.get(`orders/${id}/download-invoice`, {
    responseType: "blob",
  });
  return res;
};

export const DownloadOrdersList = async (query) => {
  const res = await API.get(`/export/orders`, query);
  return res.data;
};

export const confirmOrder = async (id) => {
  const res = await API.post(`orders/${id}/confirm`);
  return res.data;
};

export const rejectOrder = async (id, payload) => {
  const res = await API.post(`orders/${id}/cancel`, payload);
  return res.data;
};

export const refundOrder = async (id, payload) => {
  const res = await API.post(`orders/${id}/refund`, payload);
  return res.data;
};
