import React from "react";
import { AppContext } from "../../store";

export const useContentLayout = ({ tabProps }) => {
  const {
    appState: { currentTabs },
  } = React.useContext(AppContext);

  // Find current tab value
  const currentTabValue =
    tabProps.list !== null ? currentTabs[tabProps.label] : "";

  // Find current tab details by using currentTabValue
  const currentTab =
    currentTabValue !== ""
      ? tabProps.list.find((tab) => tab.value === currentTabValue)
      : null;

  return { currentTabValue, currentTab };
};
