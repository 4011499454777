import * as React from "react";
import { Button, Stack } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";

export const AddOrEditMenuTemplate = ({
  state,
  onChangeMenuTemplate,
  onSaveMenuTemplate,
  onUpdateMenuTemplate,
}) => {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const onSubmit = () => {
    if (validator.current.allValid()) {
      state.selectedId ? onUpdateMenuTemplate() : onSaveMenuTemplate();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  return (
    <Stack
      justifyContent="space-between"
      height="100%"
      gap="44px"
      maxHeight={"calc(100% - 24px)"}
      mt="32px"
    >
      <InputControl
        name="name"
        label="Group Name"
        onChange={onChangeMenuTemplate}
        value={state.data.name}
        required
        sx={{
          width: "100%",
          bgcolor: "#fff",
        }}
        error={validator.current.message(
          "Group Name",
          state.data.name,
          "required"
        )}
        helperText={validator.current.message(
          "Group Name",
          state.data.name,
          "required"
        )}
      />
      <Button
        sx={{ height: "40px", width: "33px", alignSelf: "end" }}
        variant="contained"
        onClick={onSubmit}
        disabled={state.isSaveButtonBusy}
      >
        {state.selectedId ? "Update" : "Save"}
      </Button>
    </Stack>
  );
};
