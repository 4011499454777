import { API } from "../../../utils";

export const getConditions = async () => {
  const res = await API.get("rule-sets");
  return res.data;
};

export const deleteConditionById = async (id) => {
  const res = await API.delete(`rule-sets/${id}`);
  return res.data;
};
export const createConditions = async (payload) => {
  const res = await API.post(`rule-sets`, payload);
  return res.data;
};
export const getConditionDetails = async (id) => {
  const res = await API.get(`rule-sets/${id}`);
  return res.data;
};

export const editConditions = async (id, payload) => {
  const res = await API.put(`rule-sets/${id}`, payload);
  return res.data;
};

export const getCategoryWiseProductVariants = async (query) => {
  const res = await API.get("variant_product/list", query);
  return res.data;
};
