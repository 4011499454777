import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage, infoMessage } from "../../../utils";
import {
  editUbereats,
  getUbereatsList,
  uploadAllMenu,
  uploadPushMenu,
} from "../api/ubereatsApi";
import { useActivityLog, usePermission, useTabs } from "../../../shared";
import { AppContext } from "../../../store";
import { useMenuTemplate } from "../../MenuTemplate";
import { createTemplate, showChannel } from "../../Menulog/api/menuLogApi";

export const useUbereats = ({ isList }) => {
  const isEditable = usePermission("ubereats-modify");
  const { addActivityLog } = useActivityLog();
  const { appState } = useContext(AppContext);
  const { ubereats: currentTab } = appState.currentTabs;
  const { setCurentTab } = useTabs();
  const { state: stateMenuTempalte } = useMenuTemplate({ isList: true });
  const optionsMenuTemplate = stateMenuTempalte?.menuTemplates?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));
  const [state, setState] = useImmer({
    isBusy: false,
    isOpen: false,
    isAllSaveButton: false,
    isSaveButton: false,
    isSaveButtonBusy: false,
    ubereatsData: [],
    store_id: null,
    data: {
      store_id: "",
      ubereats_store_id: "",
      ubereats: 0,
    },
    isErrorOpen: false,
    errorMessages: null,
    dataMenu: {
      channel_id: 102,
      menu_id: "",
    },
  });
  useEffect(() => {
    if (isList) {
      getUbereatsStore();
    }
  }, [isList]);
  const getUbereatsStore = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getUbereatsList();
      if (res.success) {
        setState((draft) => {
          draft.ubereatsData = res.data;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  const handleAllMenu = async () => {
    try {
      setState((draft) => {
        draft.isAllSaveButton = true;
      });
      const res = await uploadAllMenu();
      if (res.status === 200) {
        setState((draft) => {
          draft.isAllSaveButton = false;
        });
        infoMessage(res.data.message);
      } else {
        setState((draft) => {
          draft.isAllSaveButton = false;
        });
        errorMessage("Error Occured!");
      }
    } catch (err) {
      setState((draft) => {
        draft.isAllSaveButton = false;
      });
      errorMessage("Error Occured!");
    }
  };
  const activityLog = (id, data) => {
    const filteredNewData = data?.find((item) => item.store_id === id);

    addActivityLog({
      templateId: 5,
      parameters: { StoreName: filteredNewData?.store_name },
      uniqueParameter: id,
      data: { newLogData: filteredNewData, oldLogData: {} },
    });
  };

  const handlePushMenu = async (id, data) => {
    try {
      setState((draft) => {
        draft.isSaveButton = true;
        draft.store_id = id;
      });
      const res = await uploadPushMenu(id);
      activityLog(id);
      infoMessage(res.data.message);
      getUbereatsStore();
      if (res.status === 200) {
        setState((draft) => {
          draft.isSaveButton = false;
        });
      } else {
        setState((draft) => {
          draft.isSaveButton = false;
        });
        errorMessage("Error Occured!");
      }
    } catch (err) {
      setState((draft) => {
        draft.isSaveButton = false;
      });
      errorMessage("Error Occured!");
    }
  };
  const toggleAction = (type, e, value) => {
    setState((draft) => {
      draft.isOpen = type;
      draft.data.store_id = value?.store_id;
      draft.data.ubereats = e ? 1 : 0;
      draft.data.ubereats_store_id = "";
    });
  };
  const onChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data.ubereats_store_id = value;
    });
  };
  const handleUpdate = async (value, e, type) => {
    let payload = {
      store_id: value?.store_id,
      ubereats_store_id: value?.ubereats_store_id,
      ubereats: e ? 1 : 0,
    };
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await editUbereats(type === "active" ? payload : state.data);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        getUbereatsStore();
        toggleAction(false);
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const isErrorModal = (v, text) => {
    setState((draft) => {
      draft.isErrorOpen = v;
      draft.errorMessages = text;
    });
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((draft) => {
      draft.dataMenu[name] = value;
    });
  };
  const handleSubmit = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await createTemplate(state.dataMenu);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const getTemplatesDetails = async (channel_id) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await showChannel(channel_id);
      if (res.data) {
        setState((draft) => {
          draft.dataMenu.menu_id = res?.data?.id;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (currentTab === "2") {
      getTemplatesDetails(state?.dataMenu?.channel_id);
    }
  }, [currentTab]);
  return {
    state,
    handleAllMenu,
    toggleAction,
    handlePushMenu,
    onChange,
    handleUpdate,
    isEditable,
    isErrorModal,
    currentTab,
    setCurentTab,
    optionsMenuTemplate,
    handleInputChange,
    handleSubmit,
  };
};
