import {
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React from "react";

export const CashDetailsTable = ({ data }) => {

    const coinsDataList = data.cashes.filter((data) => data.denomination_id <= 6)
    const cashDataList = data.cashes.filter((data) => data.denomination_id > 6)
    const coinTotal = coinsDataList.map((data) => Number(data.total.replace(/\,/g, ''))).reduce((pv, cv) => pv + cv, 0)
    const cashTotal = cashDataList.map((data) => Number(data.total.replace(/\,/g, ''))).reduce((pv, cv) => pv + cv, 0)

    return (
        <Stack sx={{ boxShadow: "none", flexDirection: "row" }}>
            <TableContainer sx={{
                borderRight: "1px solid #E6E6E6"
            }}>
                <Table aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" sx={{ p: "10px" }}>Coins</TableCell>
                            <TableCell align="left" sx={{ p: "10px" }}>Count</TableCell>
                            <TableCell align="left" sx={{ p: "10px" }}>Amount</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {coinsDataList?.map((item, index) => {
                            return (
                                <>
                                    <TableRow key={index} >
                                        <TableCell
                                            sx={{
                                                verticalAlign: "baseline",
                                                p: "10px"
                                            }}
                                            key={0}
                                        >
                                            {item.denomination_label}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                verticalAlign: "baseline",
                                                p: "10px"
                                            }}
                                            key={1}
                                        >
                                            {item.count}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                verticalAlign: "baseline",
                                                p: "10px"
                                            }}
                                            key={2}
                                        >
                                            ${item.total}
                                        </TableCell>
                                    </TableRow>
                                </>
                            );
                        })}


                        <TableRow sx={{ outline: "1px solid #E6E6E6" }}>
                            <TableCell
                                colSpan={2}
                                sx={{
                                    fontSize: "16px",
                                    borderBottom: "1px solid #E6E6E6"
                                }}
                                align="left"
                            >
                                Total Coin
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    borderBottom: "1px solid #E6E6E6",
                                }}
                                align="right"
                            >
                                ${coinTotal.toFixed(2)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer>
                <Table aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" sx={{ p: "10px" }}>Cash</TableCell>
                            <TableCell align="left" sx={{ p: "10px" }}>Count</TableCell>
                            <TableCell align="left" sx={{ p: "10px" }}>Amount</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {cashDataList?.map((item, index) => {
                            return (
                                <>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                verticalAlign: "baseline",
                                                p: "10px"
                                            }}
                                            key={0}
                                        >
                                            {item.denomination_label}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                verticalAlign: "baseline",
                                                p: "10px"
                                            }}
                                            key={1}
                                        >
                                            {item.count}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                verticalAlign: "baseline",
                                                p: "10px"
                                            }}
                                            key={2}
                                        >
                                            ${item.total}
                                        </TableCell>
                                    </TableRow>
                                </>
                            );
                        })}
                        <TableRow sx={{
                            height: "36.53px"
                        }} >

                        </TableRow>

                        <TableRow sx={{ outline: "1px solid #E6E6E6" }}>
                            <TableCell
                                colSpan={2}
                                sx={{
                                    fontSize: "16px",
                                }}
                                align="left"
                            >
                                Total Cash
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: 600,
                                }}
                                align="right"
                            >
                                ${cashTotal.toFixed(2)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
};
