import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { errorMessage } from "../../../utils";
import {
  getDashboardCards,
  getDashboardSalesGraph,
  getDashboardStoreSales,
} from "../api";
import { addDays } from "date-fns";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { TableStateContext } from "../../../store";

export const useDashboard = () => {
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const {
    filterState: {
      params: { dashboardDateRange },
    },
    filterStateDispatch,
  } = useContext(TableStateContext);

  const [state, setState] = useImmer({
    isLoad1: true,
    isLoad2: true,
    isLoad3: true,
    isOpen: false,
    date: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    showDate: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    salesGraph: {
      [`x-axis`]: [],
      [`y-axis`]: [],
    },
    storeSales: {
      [`x-axis`]: [],
      [`y-axis`]: [],
    },
    cardsData: [],
    dataList: [],
  });

  useEffect(() => {
    if (Object.keys(dashboardDateRange).length) {
      const date = [
        {
          endDate: new Date(dashboardDateRange.endDate),
          startDate: new Date(dashboardDateRange.startDate),
          key: "selection",
        },
      ];
      setState((draft) => {
        draft.date = date;
        draft.showDate = date;
      });
      invokeApis(date);
    } else {
      invokeApis(state.date);
    }
  }, []);

  const invokeApis = (date) => {
    HandleDashboardSalesGraph(date);
    HandleDashboardCards(date);
    if (!isStoreAdmin) {
      HandleDashboardStoreSales(date);
    }
  };

  const HandleDashboardCards = async (date) => {
    try {
      setState((draft) => {
        draft.isLoad1 = true;
      });
      const res = await getDashboardCards(
        moment(date[0].startDate).format("yyyy-MM-DD"),
        moment(date[0].endDate).format("yyyy-MM-DD")
      );
      if (res.success) {
        setState((draft) => {
          draft.cardsData = res.data;
          draft.isLoad1 = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isLoad1 = false;
      });
    }
  };

  const HandleDashboardSalesGraph = async (date) => {
    try {
      setState((draft) => {
        draft.isLoad2 = true;
      });
      const res = await getDashboardSalesGraph(
        moment(date[0].startDate).format("yyyy-MM-DD"),
        moment(date[0].endDate).format("yyyy-MM-DD")
      );
      if (res.success) {
        setState((draft) => {
          draft.salesGraph = res.data;
          draft.isLoad2 = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isLoad2 = false;
      });
    }
  };

  const HandleDashboardStoreSales = async (date) => {
    try {
      setState((draft) => {
        draft.isLoad3 = true;
      });
      const res = await getDashboardStoreSales(
        moment(date[0].startDate).format("yyyy-MM-DD"),
        moment(date[0].endDate).format("yyyy-MM-DD")
      );

      if (res.success) {
        setState((draft) => {
          draft.storeSales = res.data;
          draft.isLoad3 = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isLoad3 = false;
      });
    }
  };

  const onChangeDate = (e) => {
    setState((draft) => {
      draft.date = e;
    });
  };

  const handleIsOpen = (e) => {
    setState((draft) => {
      draft.isOpen = e;
    });
  };

  const handleDateChangeApply = () => {
    setState((draft) => {
      draft.showDate = state.date;
      draft.isOpen = false;
    });
    invokeApis(state.date);
    filterStateDispatch({
      type: "SET_DATA",
      page: "dashboardDateRange",
      data: state.date[0],
    });
  };

  useEffect(() => {
    if (!state.isOpen) {
      setState((draft) => {
        draft.date = state.showDate;
      });
    }
  }, [state.isOpen, state.showDate]);

  return {
    state,
    isStoreAdmin,
    onChangeDate,
    handleIsOpen,
    handleDateChangeApply,
  };
};
