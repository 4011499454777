import * as React from "react";
import { Button, Stack, Link, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { CustomTable, DeleteModal, Loader } from "../../../shared";
import { sortItems } from "../../../utils";

export const ClientAccount = (prop) => {
  const navigate = useNavigate();
  const {
    state,
    id,
    handleModal,
    handleDeleteClientAccount,
    isEditableCA,
    isCreateVisibleCA,
  } = prop;

  const { isLoading, dataList, isDeleteOpen } = state.clientAccount;
  const ActionButtons = ({ c_id, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isEditableCA && (
          <Link>
            <img
              onClick={() =>
                navigate(`/customers/details/${id}/clientaccountId/${c_id}`)
              }
              alt="details"
              src="/icons/arrow_forward.svg"
            />
          </Link>
        )}
      </div>
    );
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Store",
        accessor: "store_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Credit Limit",
        accessor: "credit_limit",
        Cell: ({ value }) => {
          return value?.toFixed(2);
        },
      },
      {
        Header: "Due Amount",
        accessor: "due_amount",
        Cell: ({ value }) => {
          return value?.toFixed(2);
        },
      },

      {
        Header: "Account Status",
        accessor: "status",
        sortInverted: true,
        Cell: ({ value }) => {
          return (
            <Typography
              color="#636363"
              fontSize="14px"
              fontStyle="normal"
              mt={1}
            >
              {value === 1 ? "Active" : "Inactive"}
            </Typography>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        accessor: "id",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              c_id={row.id}
              deleteAction={() => handleModal("client-ac-delete", true, row.id)}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <CustomTable
          columns={columns}
          data={dataList}
          searchBar={false}
          tableTitle="Stores"
          rowsSelection={false}
          tableId="customer_clientAccount"
          headerRightContent={
            <>
              {isCreateVisibleCA && (
                <Button
                  type="button"
                  size="medium"
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    navigate(`/customers/details/${id}/client-account/create`, {
                      state: { name: state?.data?.name, id: state?.data?.id },
                    });
                  }}
                >
                  Add New
                </Button>
              )}
            </>
          }
          onRowLink={(e) => {
            return `/customers/details/${id}/clientaccountId/${e.id}`;
          }}
        />
      )}
      <DeleteModal
        open={isDeleteOpen}
        handleClose={() => handleModal("client-ac-delete", false, null)}
        onConfirm={() => handleDeleteClientAccount()}
      />
    </>
  );
};
