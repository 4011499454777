import React from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Card, Typography } from "@mui/material";
import { API, errorMessage } from "../../../utils";
import { useContext } from "react";
import { AuthContext } from "../../../store/AuthProvider";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const SuccessPage = () => {
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const onLogout = async () => {
    const res = await API.post(`logout`);
    return res.data;
  };

  const handleLogout = async () => {
    if (auth.data.id) {
      try {
        setLoading(true);
        const res = await onLogout();
        if (res.success) {
          localStorage.clear();
          sessionStorage.clear()
          window.location.pathname = "/authentication/login";
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorMessage(err.response.data.message);
      }
    } else {
      navigate("/authentication/login");
    }
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "125px",
        }}
      >
        <Card align="center" sx={{ width: "424px", minHeight: "266px" }}>
          <Box
            sx={{
              minHeight: "269px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "24px",
            }}
          >
            <img height={80} width={80} src="/icons/Ellipse.png" />
            <Typography fontWeight={400} fontSize="32px" lineHeight="42px">
              Success
            </Typography>
            <Typography
              mt={1}
              fontWeight={400}
              fontSize="14px"
              lineHeight="18px"
            >
              Invitation accepted successfully.
            </Typography>
            <LoadingButton
              color="primary"
              fullWidth
              variant="contained"
              sx={{ height: "48px", mt: 4 }}
              onClick={handleLogout}
              loading={loading}
            >
              Continue To Login
            </LoadingButton>
          </Box>
        </Card>
      </Box>
    </>
  );
};
