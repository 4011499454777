// src/components/SalesTable.js
import { Hidden, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useTable } from 'react-table';

const SalesTable = ({ data, columns }) => {


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        footerGroups,
    } = useTable({ columns, data });

    return (
        <table {...getTableProps()} style={{
            border: "1px solid #E6E6E6",
            boxShadow: "0px 2px 8px 1px #0000000F",
            borderSpacing: 0,
            borderRadius: "12px",
            overflow: "hidden",
        }}>
            <TableHead>
                {headerGroups.map(headerGroup => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <TableCell
                                {...column.getHeaderProps()}
                                sx={{
                                    borderBottom: '1px solid #E6E6E6',
                                    background: '#f0f0f0',
                                    color: 'black',
                                    fontWeight: 'bold',
                                    minWidth: "94px",
                                    padding: "11px 10px",
                                    fontSize: "14px !important",
                                    fontWeight: "500 !important",
                                    lineHeight: "16.94px",
                                    textAlign: "left",
                                    color: "#42454A !important",
                                    borderRight: '1px solid #E6E6E6',
                                    "&:last-child": {
                                        borderRight: "0",
                                    },
                                }}
                            >
                                {column.render('Header')}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <TableRow {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <TableCell
                                    {...cell.getCellProps()}
                                    sx={{
                                        padding: '10px',
                                        borderBottom: '1px solid #E6E6E6',
                                        background: '#fff',
                                        borderRight: '1px solid #E6E6E6',
                                        fontSize: "14px !important",
                                        "&:first-child": {
                                            paddingleft: "8px",
                                        },
                                        "&:last-child": {
                                            borderRight: "0",
                                        },
                                    }}
                                >
                                    {cell.render('Cell')}
                                </TableCell>
                            ))}
                        </TableRow>
                    );
                })}
            </TableBody>
            <TableFooter sx={{ backgroundColor: "#5E736038" }}>
                {footerGroups.map((group) => (
                    <TableRow {...group.getFooterGroupProps()}>
                        {group.headers.map((cell, i) => (
                            <TableCell
                                key={i}
                                sx={{
                                    borderRight: "1px solid #00000014",
                                    borderBottom: "0",
                                    padding: "10px",
                                    "&:last-child": {
                                        borderRight: "0",
                                    },
                                }}
                                {...cell.getFooterProps()}
                            >
                                <Typography
                                    color="#3E5240"
                                    fontWeight={500}
                                    fontSize="16px"
                                    sx={{ lineHeight: "19px", }}
                                >
                                    {cell.render("Footer")}
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableFooter>
        </table >
    );
};

export default SalesTable;
