import { API } from "../../../utils";

/**
 * Fetches a list of QR codes based on provided parameters.
 * @param {Object} params - The parameters for querying QR codes.
 * @param {string} [params.search] - The search term for filtering QR codes.
 * @param {number} [params.store_id] - The ID of the store to filter QR codes.
 * @returns {Promise<Object>} The list of QR codes.
 */
export const getQrCodeListApi = async (params) => {
  const response = await API.get("qrcode", { params });
  return response.data;
};

/**
 * Fetches details of a specific QR code by its ID.
 * @param {string} qrCodeId - The ID of the QR code to fetch.
 * @returns {Promise<Object>} The details of the QR code.
 */
export const getQrCodeDetailsApi = async (qrCodeId) => {
  const response = await API.get(`qrcode/${qrCodeId}`);
  return response.data;
};

/**
 * Creates a new QR code with the provided parameters.
 * @param {Object} params - The parameters for creating a QR code.
 * @param {string} params.remarks - Remarks associated with the QR code.
 * @param {string} params.location - The location for the QR code.
 * @param {number} params.store_id - The ID of the store associated with the QR code.
 * @param {number} params.type - The type of the QR code.
 * @returns {Promise<Object>} The created QR code data.
 */
export const createQrCodeApi = async (params) => {
  const response = await API.post(`qrcode`, params);
  return response.data;
};

/**
 * Updates the details of a specific QR code.
 * @param {Object} options - The options for updating the QR code.
 * @param {number} options.qrCodeId - The ID of the QR code to update.
 * @param {Object} options.params - The parameters for updating the QR code.
 * @param {string} options.params.remarks - Remarks associated with the QR code.
 * @param {string} options.params.location - The location for the QR code.
 * @param {number} options.params.store_id - The ID of the store associated with the QR code.
 * @param {number} options.params.type - The type of the QR code.
 * @returns {Promise<Object>} The updated QR code data.
 */
export const updateQrCodeDetailsApi = async ({ qrCodeId, params }) => {
  const response = await API.put(`qrcode/${qrCodeId}`, params);
  return response.data;
};

/**
 * Deletes a specific QR code by its ID.
 * @param {string} qrCodeId - The ID of the QR code to delete.
 * @returns {Promise<Object>} The response data from the delete operation.
 */
export const deleteQrCodeDetailsApi = async (qrCodeId) => {
  const response = await API.delete(`qrcode/${qrCodeId}`);
  return response.data;
};
