import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import {
  createPushNotification,
  deletePushNotificationById,
  getPushNotification,
  getPushNotificationDetails,
  updatePushNotification,
} from "../..";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import moment from "moment";

export const usePushNotification = ({ isList, isAdd, isEdit }) => {
  const { appState } = useContext(AppContext);
  const { globalData } = appState;
  const [state, setState] = useImmer({
    isLoading: true,
    isButtonDisable: false,
    dataList: [],
    couponList: [],
    deleteModalOpen: false,
    selectedID: null,
    isOpen: false,
    details: {
      id: 1,
      name: "",
      title: "",
      description: "",
      analytics_label: "",
      type: null,
      subject: null,
      subject_type: "",
      subject_id: null,
      schedule: "",
      scheduled_date: null,
      scheduled_time: null,
      scheduled_at: "",
      store_wise_timezone: 0,
      timezone: "",
    },
    title: "",
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (isList) {
      handleGetPushNotificationList();
    }
  }, [isList]);

  useEffect(() => {
    if (isEdit) {
      handleGetPushNotificationDetails(isEdit);
    }
  }, [isEdit]);

  const handleGetPushNotificationList = async () => {
    try {
      setState((draft) => {
        draft.isLoading = true;
      });
      const res = await getPushNotification();
      if (res.success) {
        setState((draft) => {
          draft.isLoading = false;
          draft.dataList = res.data;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const handleGetPushNotificationDetails = async (id) => {
    try {
      setState((draft) => {
        draft.isLoading = true;
      });
      const res = await getPushNotificationDetails(id);
      if (res.success) {
        setState((draft) => {
          draft.isLoading = false;
          draft.details = {
            ...res.data,
            scheduled_date: res.data.scheduled_at,
            scheduled_time: moment(
              res.data.scheduled_at,
              "YYYY-MM-DD, hh:mm A"
            ).format("HH:mm:ss"),
          };
          draft.title = res.data.title;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const onCreatePushNotification = async (status = 1) => {
    try {
      setState((draft) => {
        draft.isLoading = true;
        draft.isButtonDisable = true;
      });

      let formData = new FormData();
      const data = {
        ...state.details,
        store_wise_timezone: state.details.store_wise_timezone ? 1 : 0,
        scheduled_date: moment(state.details.scheduled_date).format(
          "YYYY-MM-DD"
        ),
        subject: state.details.subject.id,
        scheduled_time: state.details.scheduled_time,
        status: status,
      };
      if (data.schedule === 0) {
        delete data.scheduled_date;
        delete data.scheduled_time;
        delete data.timezone;
        delete data.store_wise_timezone;
      }

      Object.keys(data).map((key) => {
        formData.append(key, data[key]);
      });
      const res = await createPushNotification(formData);
      if (res.success) {
        successMessage(res.message);

        setState((draft) => {
          draft.isLoading = false;
          draft.isButtonDisable = false;
          draft.isOpen = false;
        });
        navigate("/push-notification");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
        draft.isButtonDisable = false;
      });
    }
  };

  const onUpdatePushNotification = async (id, status) => {
    try {
      setState((draft) => {
        draft.isLoading = true;
        draft.isButtonDisable = true;
      });

      let formData = new FormData();
      const data = {
        ...state.details,
        store_wise_timezone: state.details.store_wise_timezone ? 1 : 0,
        scheduled_date: moment(state.details.scheduled_date).format(
          "YYYY-MM-DD"
        ),
        subject: state.details.subject.id,
        scheduled_time: state.details.scheduled_time,
        status: status,
      };
      if (data.schedule === 0) {
        delete data.scheduled_date;
        delete data.scheduled_time;
        delete data.timezone;
        delete data.store_wise_timezone;
      }

      Object.keys(data).map((key) => {
        formData.append(key, data[key]);
      });
      formData.append("_method", "PUT");
      const res = await updatePushNotification(id, formData);
      if (res.success) {
        successMessage(res.message);

        setState((draft) => {
          draft.isLoading = false;
          draft.isButtonDisable = false;
          draft.isOpen = false;
        });
        navigate("/push-notification");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
        draft.isButtonDisable = false;
      });
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.details[name] = value;
    });
  };

  const handleReviewModal = (e) => {
    setState((draft) => {
      draft.isOpen = e;
    });
  };

  const handleModal = (v, id = null) => {
    setState((draft) => {
      draft.deleteModalOpen = v;
      draft.selectedID = id;
    });
  };

  const handleDelete = async () => {
    try {
      const res = await deletePushNotificationById(state.selectedID);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.dataList = draft.dataList.filter(
            (item) => item.id !== state.selectedID
          );
        });
        handleModal(false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleChangeCoupons = (e) => {
    setState((draft) => {
      draft.details.subject = { ...e, id: e.value };
    });
  };
  return {
    state,
    globalData,
    onChange,
    onUpdatePushNotification,
    onCreatePushNotification,
    handleReviewModal,
    handleModal,
    handleDelete,
    handleChangeCoupons,
  };
};
