import { Button, Chip, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomTable, Loader } from "../../../shared";
import { useLoyalty } from "../../LoyaltyPoints/hooks";

export const CustomersLoyaltyCards = () => {
  const { id } = useParams();
  const { state, onBlurTab } = useLoyalty({ isCusID: id });

  const { isLoading, dataList } = state.customers;

  const ActionButtons = ({ id }) => {
    return (
      <a
        className="external-link "
        href={`/loyalty-cards/details/${id}`}
        target="_blank"
      >
        <img alt="link" src="/icons/ic_external_link.svg" />
        View Details
      </a>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Card Type",
        accessor: "loyalty_card_type",
        disableGlobalFilter: true,
      },
      {
        Header: "Barcode",
        accessor: "barcode",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Points",
        accessor: "points",
        disableGlobalFilter: true,
      },
      {
        Header: "Status",
        accessor: "status",
        disableGlobalFilter: true,
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <Chip
              label={value === 1 ? "Active" : "Inactive"}
              color="success"
              variant="outlined"
              size="small"
              disabled={value === 1 ? false : "filled"}
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        accessor: "id",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons id={row.id} />;
        },
      },
    ],
    []
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <CustomTable
          columns={columns}
          data={dataList}
          searchBar={false}
          tableTitle="Loyalty Cards"
          rowsSelection={false}
          pagination={false}
          rowClickNewTab={true}
          tableId="customer_loyaltyCards"
          onRowLink={(e) => {
            return `/loyalty-cards/details/${e.id}`;
          }}
          onRowAction={(e) => {
            onBlurTab();
          }}
        />
      )}
    </>
  );
};
