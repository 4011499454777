import React from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
} from "react-table";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableHead,
  TableContainer,
  TableSortLabel,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Box,
  Paper,
  Stack,
  Divider,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

export const TransactionDialogTable = (props) => {
  const {
    columns,
    data,
    searchBar = true,
    initialPage,
    minWidth = 800,
    filterContent = null,
    pagination = true,
    bulkDelete = false,
    onBulkDelete = {},
    defaultSearch = true,
    hiddenColumns = [],
    emptyMessage = "",
    customGotoPage = false,
    customSetPageSize = false,
    toggleViewModal,
    item,
  } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    preGlobalFilteredRows,
    globalFilteredRows,
    setGlobalFilter,
    state,
    state: { pageIndex, pageSize },
    selectedFlatRows,
    rows,
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: initialPage ?? 10,
        sortBy: [],
        hiddenColumns,
      },
    },

    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
    // ,
  );
  const tableData = pagination ? page : rows;
  return (
    <>
      <Stack overflow="auto" mt={2} minHeight={"470px"}>
        <Paper
          sx={{
            p: "0",
            boxShadow: "none",
            height: "100%",
            width: "100%",
          }}
        >
          <TableContainer
            sx={{ minWidth: minWidth, minHeight: "90%", overflow: "auto" }}
          >
            {searchBar && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                filterContent={filterContent}
                defaultSearch={defaultSearch}
              />
            )}

            <Table
              {...getTableProps()}
              sx={{
                borderCollapse: "inherit",
                overflow: "hidden",
              }}
            >
              <TableHead>
                {headerGroups.map((headerGroup, i) => (
                  <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          p: !column.padding && "10px",
                        }}
                        align={column?.align}
                        padding={column.padding || "normal"}
                        {...column.getHeaderProps(
                          bulkDelete && selectedFlatRows.length > 0
                            ? ""
                            : column.getSortByToggleProps()
                        )}
                      >
                        {bulkDelete &&
                        selectedFlatRows.length > 0 &&
                        index >= 1 ? (
                          <>
                            {index > 1 ? (
                              <></>
                            ) : (
                              <Typography
                                variant="subtitle2"
                                color="#1560D4"
                                lineHeight="18px"
                                onClick={() => {
                                  onBulkDelete(selectedFlatRows);
                                }}
                              >
                                Delete
                              </Typography>
                            )}
                          </>
                        ) : (
                          <>
                            {column.canSort ? (
                              <TableSortLabel
                                active={column.isSorted}
                                direction={column.isSortedDesc ? "dsc" : "asc"}
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    height: "15px",
                                    width: "15px",
                                  },
                                }}
                              >
                                {column.render("Header")}
                              </TableSortLabel>
                            ) : (
                              <>{column.render("Header")}</>
                            )}
                          </>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              {preGlobalFilteredRows.length > 0 &&
              globalFilteredRows.length > 0 ? (
                <>
                  <TableBody {...getTableBodyProps()}>
                    {tableData.map((row, i) => {
                      prepareRow(row);
                      return (
                        <TableRow>
                          {row.cells.map((cell, i) => {
                            return (
                              <TableCell
                                key={i}
                                align={cell.column?.align}
                                padding={cell.column.padding || "normal"}
                                sx={{
                                  borderBottom: "1px solid #E6E6E6",
                                  p: !cell.column.padding && "8px",
                                }}
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={20}
                      sx={{ py: 2, backgroundColor: "#F7F7F7" }}
                    >
                      <Typography
                        align="center"
                        variant="body2"
                        color={"#666666"}
                      >
                        {emptyMessage ? (
                          <Stack
                            direction="row"
                            gap={1}
                            justifyContent="center"
                          >
                            <img alt="edit" src="/icons/icon_not.svg" />
                            {emptyMessage}
                          </Stack>
                        ) : (
                          "No matches found"
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <Box
            sx={{
              background: "white",
              position: "sticky",
              bottom: -1,
            }}
          >
            {pagination && (
              <TablePagination
                rowsPerPageOptions={[8, 10, 20, 30, 40, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                onPageChange={(e, page) => {
                  customGotoPage && customGotoPage(page);
                  gotoPage(page);
                }}
                onRowsPerPageChange={(e) => {
                  customSetPageSize && customSetPageSize(e.target.value);
                  setPageSize(e.target.value);
                }}
              />
            )}
            <Divider />
            <Box
              sx={{
                height: "80px",
                background: "white",
                position: "sticky",
              }}
            >
              <Stack
                justifyContent="space-between"
                direction="row"
                alignContent="center"
                alignItems="center"
              >
                <Stack direction="row">
                  <Typography ml={3} mt={3}>
                    Selected Amount:
                  </Typography>
                  <Typography
                    ml={3}
                    mt={3}
                    color="#C07302"
                    fontWeight={600}
                    fontSize="17px"
                  >
                    ${item?.purchaseAmount?.toFixed(2)}
                  </Typography>
                </Stack>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ width: "80px", mr: 3, mt: 3 }}
                  onClick={() => toggleViewModal(false)}
                >
                  Submit
                </Button>
              </Stack>
            </Box>
          </Box>
        </Paper>
      </Stack>
    </>
  );
};
function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  filterContent,
  defaultSearch,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        py: "20px",
        px: "16px",
        gap: "16px",
        borderBottom: "1px solid #E6E6E6",
        position: "sticky",
        left: 0,
      }}
    >
      {defaultSearch && (
        <OutlinedInput
          sx={{
            height: "48px",
          }}
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder="Search"
          size="small"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon size="large" />
            </InputAdornment>
          }
          endAdornment={
            value && (
              <InputAdornment
                sx={{ cursor: "pointer" }}
                onClick={(e) => {
                  setValue("");
                  onChange("");
                }}
                position="end"
              >
                <CloseIcon size="large" />
              </InputAdornment>
            )
          }
          fullWidth
        />
      )}
      {filterContent}
    </Stack>
  );
}
export default GlobalFilter;
