import React from "react";
import { Route, Routes } from "react-router-dom";
import { AddOrEditProducts } from "./components/AddOrEditProducts";
import {
  PriceLevelList,
  CreateOrEditPriceLevels,
  PriceLevelProducts,
} from "./conatiners";
import { ProtectedRoute } from "../../shared";

export const PriceLevels = () => {
  return (
    <Routes>
      <Route path="/" element={<PriceLevelList />} />
      <Route element={<ProtectedRoute allowedRoute={["price-level-create"]} />}>
        <Route path="/create" element={<CreateOrEditPriceLevels />} />
      </Route>
      <Route element={<ProtectedRoute allowedRoute={["price-level-modify"]} />}>
        <Route path="/edit/:id" element={<CreateOrEditPriceLevels />} />
      </Route>

      <Route element={<ProtectedRoute allowedRoute={["price-level-view"]} />}>
        <Route path="/:id" element={<PriceLevelProducts />} />
      </Route>

      <Route element={<ProtectedRoute allowedRoute={["price-level-create"]} />}>
        <Route path="/:id/products/add" element={<AddOrEditProducts />} />
      </Route>

      <Route element={<ProtectedRoute allowedRoute={["price-level-modify"]} />}>
        <Route
          path="/:id/products/:productId/edit"
          element={<AddOrEditProducts />}
        />
      </Route>
    </Routes>
  );
};
