import * as React from "react";
import {
  Button,
  Grid,
  Stack,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";
import { AsyncPaginate } from "react-select-async-paginate";
import { useState } from "react";
import { useRef } from "react";

export const AddMemberDialog = (props) => {
  const {
    onChangeAddmember,
    data,
    handleGetCustomerList,
    onAddMember,
    isMemberSaveButtonBusy,
    members,
  } = props;
  const {
    barcode,
    customer_id,
    customer_name,
    isGuest,
    verification_code,
    member_number,
  } = data;
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const onSubmit = () => {
    if (validator.current.allValid()) {
      onAddMember();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <Stack
        ref={innerRef}
        {...innerProps}
        sx={{
          p: "7px 10px",
          gap: "2px",
        }}
      >
        <Typography variant="subtitle2" fontWeight={400} lineHeight="17px">
          {data.label}
        </Typography>
        <Typography color="#666666" variant="caption" lineHeight="15px">
          {data.email}, {data.phone}
        </Typography>
      </Stack>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: "2px solid #E6E8F0",
      height: "48px",
    }),
    option: (provided, state) => ({
      ...provided,
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
  };

  return (
    <>
      <Stack
        direction="column"
        sx={{
          mt: "30px",
        }}
        gap="10px"
      >
        {validator.current.purgeFields()}
        <Stack direction="column" justifyContent="space-between" gap="14px">
          <InputControl
            label="Barcode"
            name="barcode"
            onChange={onChangeAddmember}
            value={barcode}
            type="outlinedInput"
            inputType="text"
            inputLabel="Barcode"
            placeholder="Barcode"
            endAdornment={
              <InputAdornment position="start">
                <Tooltip title="If left empty,it will be auto generated">
                  <img alt="details" src="/icons/ic_info.svg" />
                </Tooltip>
              </InputAdornment>
            }
          />
          <InputControl
            type="outlinedInput"
            inputType="text"
            inputLabel="Member Number"
            label="Member Number"
            placeholder="Member Number"
            name="member_number"
            size="small"
            onChange={onChangeAddmember}
            value={member_number}
            endAdornment={
              <InputAdornment position="start">
                <Tooltip title="If left empty,it will be auto generated">
                  <img alt="details" src="/icons/ic_info.svg" />
                </Tooltip>
              </InputAdornment>
            }
          />

          <InputControl
            type="outlinedInput"
            inputType="text"
            inputLabel="Verification Code"
            label="Verification Code"
            placeholder="Verification Code"
            name="verification_code"
            size="small"
            onChange={onChangeAddmember}
            value={verification_code}
            error={validator.current.message(
              "Verification Code",
              `${verification_code}`,
              "size:6"
            )}
            helperText={validator.current.message(
              "Verification Code",
              `${verification_code}`,
              "size:6"
            )}
            endAdornment={
              <InputAdornment position="start">
                <Tooltip title="If left empty,it will be auto generated">
                  <img alt="details" src="/icons/ic_info.svg" />
                </Tooltip>
              </InputAdornment>
            }
          />
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{ ml: "4px" }}
            onChange={(e, v) =>
              onChangeAddmember({ target: { value: v, name: "isGuest" } })
            }
            defaultValue={"1"}
          >
            <FormControlLabel value={1} control={<Radio />} label="Customer" />
            <FormControlLabel value={2} control={<Radio />} label="Guest" />
          </RadioGroup>
          {isGuest === "1" ? (
            <Stack>
              <AsyncPaginate
                debounceTimeout={1000}
                value={customer_id}
                menuPosition="fixed"
                menuPlacement="top"
                placeholder="Member Name*"
                className="AsyncPaginate-dropdown"
                classNamePrefix="react-select"
                components={{ Option: CustomOption }}
                loadOptions={async (e, p, { page }) => {
                  const customerData = await handleGetCustomerList({
                    search: e,
                    page,
                  });
                  const filteredCustomerData = {
                    ...customerData,
                    options: customerData.options.filter((cus) => {
                      return !members.find(
                        (item) => item.customer_id === cus.value
                      );
                    }),
                  };

                  return filteredCustomerData;
                }}
                onChange={(e) => {
                  onChangeAddmember({
                    target: {
                      name: "customer_id",
                      value: e,
                    },
                  });
                }}
                additional={{
                  page: 1,
                }}
                styles={customStyles}
              />
              <FormHelperText sx={{ color: "#C60808" }}>
                {validator.current.message(
                  "Customer Name",
                  customer_id,
                  "required"
                )}
              </FormHelperText>
            </Stack>
          ) : (
            <InputControl
              label="Member Name"
              name="customer_name"
              value={customer_name}
              onChange={onChangeAddmember}
            />
          )}
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{ height: "40px", width: "119px" }}
            variant="contained"
            fullWidth
            disabled={isMemberSaveButtonBusy}
            onClick={() => onSubmit()}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
