import { API } from "../../../utils";

export const editDisplayMode = async (query) => {
  const res = await API.put("pos-settings/display-mode", query);
  return res.data;
};
export const editDisplayPosition = async (query) => {
  const res = await API.put("pos-settings/display-position", query);
  return res.data;
};
export const getPosSetting = async () => {
  const res = await API.get(`pos-settings`);
  return res.data;
};
export const editTemplate = async (query) => {
  const res = await API.put("pos-settings/keypad-template", query);
  return res.data;
};
