import {
  ContentLayout,
  HeaderBreadcrumbs,
  Loader,
  usePermission,
} from "../../../shared";
import * as React from "react";
import {
  Button,
  Card,
  Chip,
  FormHelperText,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useEmailSettings } from "../hooks";
import { AsyncPaginate } from "react-select-async-paginate";
import AddIcon from "@mui/icons-material/Add";
import SimpleReactValidator from "simple-react-validator";

export const EmailSettingsDetails = () => {
  const { id } = useParams();

  const {
    state,
    handleGetUserList,
    handleInputChange,
    handleAddEmail,
    handleDeleteEmail,
  } = useEmailSettings({ isDetails: id });
  const [input, setInput] = React.useState();

  const { isLoading, details, updateData } = state;
  const navigate = useNavigate();
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const onSubmit = () => {
    if (validator.current.allValid()) {
      handleAddEmail(input);
      setInput();
      validator.current.hideMessages();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const onInputChange = (inputValue, { action }) => {
    if (action === "input-change") {
      setInput(inputValue);
    }
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps, isSelected } = props;
    return (
      <>
        {data.custom ? (
          <Stack
            ref={innerRef}
            {...innerProps}
            sx={{
              flexDirection: "row",
              p: "7px 8px",
              gap: "2px",
              alignItems: "center",
            }}
          >
            <AddIcon
              sx={{
                color: "#1560D4",
              }}
            />

            <Link
              target="_blank"
              sx={{ textDecoration: "none" }}
              onClick={() => navigate("/client-account/customer/create")}
            >
              Add new User
            </Link>
          </Stack>
        ) : (
          <Stack
            ref={innerRef}
            {...innerProps}
            sx={{
              flexDirection: "row",

              p: "7px 8px",

              gap: "2px",
            }}
          >
            <Stack
              sx={{
                gap: "2px",
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight={400}
                lineHeight="17px"
              >
                {data.name}
              </Typography>
              <Typography color="#666666" variant="caption" lineHeight="15px">
                {data.email}
              </Typography>
            </Stack>
          </Stack>
        )}
      </>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: "2px solid #E6E8F0",
      height: "48px",
    }),
    option: (provided, state) => ({
      ...provided,
      maxHeight: "239px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
  };

  return (
    <>
      <ContentLayout
        title={details?.group || ""}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                {
                  name: "Email Settings",
                  href: "/email-settings",
                },
                { name: details?.group || "" },
              ]}
            />
          </>
        }
      >
        {isLoading ? (
          <Loader />
        ) : (
          <Card sx={{ p: "32px 24px", mb: "24px", overflow: "inherit" }}>
            <Stack direction="row" gap="32px">
              <Stack direction="row" gap="4px">
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  width="136px"
                  lineHeight="19px"
                >
                  {details?.name}
                </Typography>
                {/* <img style={{ height: "24px" }} alt="details" src="/icons/ic_info.svg" /> */}
              </Stack>

              <Stack
                direction={"row"}
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Stack width="100%" gap="16px">
                  <Stack direction={"row"} gap="20px">
                    <Stack width="100%">
                      <AsyncPaginate
                        debounceTimeout={1000}
                        placeholder="Add Mail address"
                        className="AsyncPaginate-dropdown"
                        classNamePrefix="react-select"
                        isMulti
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        inputValue={input}
                        onInputChange={onInputChange}
                        value={updateData}
                        key={JSON.stringify(updateData)}
                        components={{ Option: CustomOption }}
                        loadOptions={(e) => handleGetUserList({ search: e })}
                        onChange={(e, v) => {
                          if (v.action === "clear") {
                            setInput("");
                            validator.current.hideMessages();
                          } else if (v.action === "pop-value") {
                          } else {
                            handleInputChange(e);
                            validator.current.hideMessages();
                          }
                        }}
                        controlShouldRenderValue={false}
                        additional={{
                          page: 1,
                        }}
                        styles={customStyles}
                      />
                      <FormHelperText sx={{ color: "#C60808" }}>
                        {validator.current.message(
                          "Email",
                          input,
                          "required|email"
                        )}
                      </FormHelperText>
                    </Stack>
                    <Button
                      sx={{
                        height: "44px !important",
                      }}
                      onClick={onSubmit}
                      disabled={!input}
                    >
                      Add
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "16px",
                      rowGap: "8px",
                    }}
                  >
                    {updateData.map((data, index) => (
                      <Chip
                        key={index}
                        label={
                          <Typography variant="subtitle2">
                            {data.name || data.email}
                          </Typography>
                        }
                        onDelete={() => {
                          handleDeleteEmail(data);
                        }}
                        sx={{
                          backgroundColor: "#F2F2F2",
                          height: "30px",
                          "& .MuiChip-deleteIcon": {
                            color: "#CCCCCC",
                          },
                        }}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        )}
      </ContentLayout>
    </>
  );
};
