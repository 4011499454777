import { Button, Card, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import { OrdersTable } from "../components";
import { useParams, useLocation } from "react-router-dom";
import { useWastage } from "../hooks/useWastage";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import SimpleReactValidator from "simple-react-validator";


export const WastageDetails = () => {
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const printRef = useRef();
  const { id } = useParams();
  const { state, onChangeQty, handleEditWastage, handleEditBtn } = useWastage({ wastageId: id });

  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const onSubmit = () => {
    if (state.isEdit) {
      if (validator.current.allValid()) {
        handleEditWastage()
      } else {
        validator.current.showMessages();
        forceUpdate(1);
      }
    }
    else {
      handleEditBtn()
    }
  };

  const customerData = [
    {
      head: "Date & Time",
      name: state.data.wastage_date,
    },
    {
      head: "Store Name",
      name: state.data.store_name,
    },
    {
      head: "Staff Name",
      date: state.data.user_name,
    },
    {
      head: "Wastage Type",
      date: state.data.wastage_type,
    },
  ];
  return (
    <>
      <ContentLayout
        title={state.wastage_number}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: isStoreAdmin ? "/store-admin" : "/" },
              {
                name: "Wastage",
                href: isStoreAdmin ? "/store-admin/wastage" : "/wastage",
              },
              { name: state.wastage_number || "" },
            ]}
          />
        }
        rightContent={
          <>
            <ReactToPrint
              trigger={() => (
                <Button
                  sx={{ width: "40px" }}
                  type="button"
                  size="small"
                  variant="text"
                >
                  <img alt="tools" src="/icons/ic_print.svg" />
                </Button>
              )}
              content={() => printRef.current}
            />
            {!state.is72HourPassed && (<Button
              variant="text"
              onClick={onSubmit}
              disabled={state.isLoadingSave}
            >
              {state.isEdit ? "Save" : "Edit"}
            </Button>
            )}
          </>
        }
      >
        {state.isBusy ? (
          <Loader />
        ) : (
          <>
            <Card sx={{ marginBottom: "20px" }} ref={printRef}>
              <Grid
                container
                sx={{
                  height: "83px",
                  padding: "32px 24px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    fontSize={16}
                    sx={{
                      lineHeight: "19px",
                    }}
                  >
                    Wastage Summary
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid
                container
                sx={{
                  padding: "24px 16px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {customerData.map((item) => {
                  return (
                    <Grid item>
                      <Box sx={{ padding: "10px", width: "212px" }}>
                        <Typography
                          fontWeight={500}
                          fontSize={13}
                          sx={{
                            lineHeight: "15px",
                            marginBottom: "10px",
                          }}
                        >
                          {item.head}
                        </Typography>
                        {Object.keys(item).map((key) => {
                          if (key !== "head") {
                            return (
                              <Typography
                                fontWeight={400}
                                fontSize={13}
                                sx={{
                                  lineHeight: "15px",
                                  marginBottom: "6px",
                                }}
                              >
                                {item[key]}
                              </Typography>
                            );
                          }
                        })}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              <Divider />

              <OrdersTable state={state} onChangeQty={onChangeQty} validator={validator} disabled={!state.isEdit} />
              <Divider />
              <Grid
                sx={{
                  height: "126px",
                  padding: "32px 16px",
                }}
                container
              >
                <Grid sx={{ padding: "10px" }} item>
                  <Typography
                    fontWeight={500}
                    fontSize={13}
                    sx={{
                      lineHeight: "15px",
                      marginBottom: "10px",
                    }}
                  >
                    Remark
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={13}
                    sx={{
                      lineHeight: "15px",
                    }}
                  >
                    {state.data.notes}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </ContentLayout>
    </>
  );
};
