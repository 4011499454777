import { API } from "../../../utils";

//taxGroup
export const getTaxGroupList = async () => {
  const res = await API.get("taxgroups");
  return res.data;
};

export const deleteTaxGroupById = async (id) => {
  const res = await API.delete(`taxgroups/${id}`);
  return res.data;
};

export const createTaxGroup = async (payload) => {
  const res = await API.post(`taxgroups`, payload);
  return res.data;
};

export const updateTaxGroup = async (payload, id) => {
  const res = await API.put(`taxgroups/${id}`, payload);
  return res.data;
};

export const getTaxGroupDetails = async (id) => {
  const res = await API.get(`taxgroups/${id}`);
  return res.data;
};

export const assignTaxToTaxGroup = async (payload, id) => {
  const res = await API.put(`taxgroups/${id}/tax`, payload);
  return res.data;
};

export const deleteTaxFromTaxGroup = async (userId, taxId) => {
  const res = await API.delete(`taxgroups/${userId}/tax/${taxId}`);
  return res.data;
};

//taxes
export const getTaxesList = async () => {
  const res = await API.get("taxes");
  return res.data;
};

export const deleteTaxesById = async (id) => {
  const res = await API.delete(`taxes/${id}`);
  return res.data;
};

export const getTaxDetails = async (id) => {
  const res = await API.get(`taxes/${id}`);
  return res.data;
};

export const createTaxes = async (payload) => {
  const res = await API.post(`taxes`, payload);
  return res.data;
};

export const updateTaxes = async (payload, id) => {
  const res = await API.put(`taxes/${id}`, payload);
  return res.data;
};
