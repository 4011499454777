import {
  Box,
  Button,
  Checkbox,
  ListItem,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";
import { DashBoardDateRangePicker } from "../..";
import InputControl from "../../../shared/components/InputControl";

export const PaymentMediaReportFilter = ({
  filter,
  storeList,
  terminalList,
  salesTypeList,
  salesChannelList,
  orderStatusList,
  paymentMediaList,
  handleOpenDateRangeSelection,
  handleDateChangeApply,
  handleFilterChange,
  handleApplyFilter,
  isFilterApplied,
}) => {
  const getOptionIdentifier = (option) => option.id || option.value;
  const renderTags = (value) =>
    value.map((_, index) => <React.Fragment key={index}></React.Fragment>);
  const getOptionLabel = (option) => option.name || option.label;
  const getIsOptionEqualToValue = (option, value) =>
    getOptionIdentifier(option) === getOptionIdentifier(value);
  const renderOption = (props, option, { selected }) => {
    const label = option.label || option.name;
    const key = getOptionIdentifier(option);
    return (
      <ListItem {...props} key={key}>
        <Checkbox style={{ marginRight: 8 }} checked={selected} />
        {label}
      </ListItem>
    );
  };

  const onChange = (name, value) => {
    handleFilterChange({ name, value });
  };

  return (
    <Stack direction="row" gap="12px" flexWrap="wrap" width="100%">
      {handleOpenDateRangeSelection && handleDateChangeApply && (
        <DashBoardDateRangePicker
          isList={true}
          range={filter.date.value}
          showRange={filter.date.showDate}
          isOpen={filter.date.isCalenderOpen}
          handleIsOpen={handleOpenDateRangeSelection}
          handleDateChangeApply={handleDateChangeApply}
          position="left"
          onChangeDate={(event) => {
            handleFilterChange({ value: event, name: "order_date" });
          }}
        />
      )}

      {orderStatusList && (
        <InputControl
          type="dropdown"
          name="order_status"
          label="Order Status"
          multiple={true}
          options={orderStatusList}
          value={filter.list.order_status}
          disableCloseOnSelect
          disableClearable={true}
          onChange={(_, value) => onChange("order_status", value)}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={getIsOptionEqualToValue}
          renderTags={renderTags}
          renderOption={renderOption}
          sx={{ minWidth: "190px", flex: 1 }}
          componentsProps={{
            paper: {
              sx: {
                width: "fit-content",
                minWidth: 190,
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Order Status"
              placeholder="Order Status"
            />
          )}
        />
      )}

      {storeList && (
        <InputControl
          type="dropdown"
          name="store"
          label="Store"
          multiple={true}
          options={storeList}
          value={filter.list.store}
          disableCloseOnSelect
          disableClearable={true}
          renderTags={renderTags}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={getIsOptionEqualToValue}
          onChange={(_, value) => onChange("store", value)}
          renderOption={renderOption}
          sx={{ minWidth: "190px", flex: 1 }}
          componentsProps={{
            paper: {
              sx: {
                width: "fit-content",
                minWidth: 190,
              },
            },
          }}
          renderInput={(params) => (
            <TextField {...params} label="store" placeholder="Store" />
          )}
        />
      )}

      {salesTypeList && (
        <InputControl
          type="dropdown"
          name="sales_type"
          label="Sales Type"
          multiple={true}
          options={salesTypeList}
          value={filter.list.sales_type}
          disableCloseOnSelect
          disableClearable={true}
          onChange={(_, value) => onChange("sales_type", value)}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={getIsOptionEqualToValue}
          renderTags={renderTags}
          renderOption={renderOption}
          sx={{ minWidth: "190px", flex: 1 }}
          componentsProps={{
            paper: {
              sx: {
                width: "fit-content",
                minWidth: 190,
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sales Type"
              placeholder="Sales Type"
            />
          )}
        />
      )}

      {paymentMediaList && (
        <InputControl
          type="dropdown"
          name="payment_media"
          label="Payment Media"
          multiple={true}
          options={paymentMediaList}
          value={filter.list.payment_media}
          disableCloseOnSelect
          disableClearable={true}
          onChange={(_, value) => onChange("payment_media", value)}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={getIsOptionEqualToValue}
          renderTags={renderTags}
          renderOption={renderOption}
          sx={{ minWidth: "190px", flex: 1 }}
          componentsProps={{
            paper: {
              sx: {
                width: "fit-content",
                minWidth: 190,
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Payment Media"
              placeholder="Payment Media"
            />
          )}
        />
      )}

      {terminalList && (
        <InputControl
          type="dropdown"
          name="terminal_name"
          label="Terminal Name"
          multiple={true}
          options={terminalList}
          value={filter.list.terminal_name}
          disableCloseOnSelect
          disableClearable={true}
          renderTags={renderTags}
          getOptionLabel={getOptionLabel}
          onChange={(_, value) => onChange("terminal_name", value)}
          isOptionEqualToValue={getIsOptionEqualToValue}
          renderOption={renderOption}
          sx={{ minWidth: "190px", flex: 1 }}
          componentsProps={{
            paper: {
              sx: {
                width: "fit-content",
                minWidth: 190,
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Terminal Name"
              placeholder="Terminal Name"
            />
          )}
        />
      )}

      {salesChannelList && (
        <InputControl
          type="dropdown"
          name="sales_channel"
          label="Sales Channel"
          multiple={true}
          options={salesChannelList}
          value={filter.list.sales_channel}
          disableCloseOnSelect
          disableClearable={true}
          onChange={(_, value) => onChange("sales_channel", value)}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={getIsOptionEqualToValue}
          renderTags={renderTags}
          renderOption={renderOption}
          sx={{ minWidth: "190px", flex: 1 }}
          componentsProps={{
            paper: {
              sx: {
                width: "fit-content",
                minWidth: 190,
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sales Channel"
              placeholder="Sales Channel"
            />
          )}
        />
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          flex: 1,
        }}
        onClick={handleApplyFilter}
      >
        <Button variant="contained" size="small" disabled={isFilterApplied}>
          Apply
        </Button>
      </Box>
    </Stack>
  );
};
