import { API } from "../../../utils";

export const getBundleGroupsList = async (search, control_type) => {
  let url = "bundle-groups?";
  if (search) {
    url += `&search=${search}`;
  }
  if (control_type && control_type !== "all") {
    url += `&selection_type=${control_type}`;
  }

  const res = await API.get(url);
  return res.data;
};

export const deleteBundleGroupById = async (id) => {
  const res = await API.delete(`bundle-groups/${id}`);
  return res.data;
};

export const getBundleGroupDetailsById = async (id) => {
  const res = await API.get(`bundle-groups/${id}`);
  return res.data;
};

export const createBundleGroup = async (payload) => {
  const res = await API.post(`bundle-groups`, payload);
  return res.data;
};

export const updateBundleGroup = async (id, payload) => {
  const res = await API.put(`bundle-groups/${id}`, payload);
  return res.data;
};

export const updateBundleProductExtraPrice = async (id, proId, payload) => {
  const res = await API.put(`bundle-groups/${id}/products/${proId}/extra-price`, payload);
  return res.data;
};

export const assignProductToBundle = async (id, payload) => {
  const res = await API.post(`bundle-groups/${id}/products`, payload);
  return res.data;
};

export const deleteBundleProductById = async (id, proId) => {
  const res = await API.delete(`bundle-groups/${id}/products/${proId}`);
  return res.data;
};