import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import { usePermission, useTabs } from "../../../shared";
import { AppContext } from "../../../store/AppScopeProvider";
import { errorMessage, successMessage } from "../../../utils";
import {
  getCustomersList,
  getCustomerDetailsById,
  createCustomer,
  editCustomer,
  deleteCustomerById,
  sendCustomerEmailVerification,
  sendCustomerPhoneVerification,
  resetCustomerPasswordById,
  getCustomerPurchasedProduct,
  getCustomerTransaction,
  DownloadCustomersList,
  getCustomerClientAccount,
  EnableOrDisableClientAccount,
  deleteClientAccountById,
  assignStoresToClinetAccount,
  getClientAccountDetailsById,
  updateClientAccount,
  verifyCustomerEmailorPhone,
  forceVerifyCustomer,
  getDeletedCustomersList,
  getCustomerAddressById,
  getCustomerFavouriteProduct,
  editClientAccountCustomers,
  cancelDeleteRequest,
} from "../api";
import { useDebouncedCallback } from "use-debounce";
import moment from "moment";
import Swal from "sweetalert2";
import qs from "qs";
import { getStateList, getSuburbList } from "../../StateAndSuburb";
import { TableStateContext } from "../../../store";
var fileDownload = require("js-file-download");

export const useCustomers = ({
  load,
  cuid,
  id,
  transactionId,
  purchasedId,
  ClientAccountID,
  tId,
  clid,
  customerId,
}) => {
  const { appState } = useContext(AppContext);
  const location = useLocation();
  const { pathname } = location;
  const { setCurentTab } = useTabs();
  const isStoreAdmin = pathname.includes("store-admin");
  const isEditable = usePermission("customer-modify");
  const isDeletable = usePermission("customer-delete");
  const isCreateVisible = usePermission("customer-create");
  const hasResetPassword = usePermission("customer-reset-password");
  const isEditableCA = usePermission("client-account-modify");
  const isDeletableCA = usePermission("client-account-delete");
  const isCreateVisibleCA = usePermission("client-account-create");
  // reviews view permission
  const isReviewsViewable = usePermission("review-rating-view");

  const isClient = pathname.includes("client-account");
  const isCustomer = pathname.includes("customers");
  const PAGE_NAME = "customers";
  const {
    globalData,
    currentTabs: {
      customers: currentTab,
      clientAccDetails: clientAccDetailsTab,
    },
  } = appState;
  const navigate = useNavigate();
  const [value, setValue] = React.useState(2);

  const {
    filterState: {
      params: { customers },
      params: {
        customers: { columnOptions },
      },
    },
    initialState,
    filterStateDispatch,
  } = useContext(TableStateContext);
  const [state, setState] = useImmer({
    details: {},
    isOpenModal: false,
    initialLoad: true,
    isBusy: false,
    isDetailsFetching: false,
    isSaveButtonBusy: false,
    isOpen: false,
    isOpenDetails: false,
    id: null,
    customerName: "",
    deletedCustomers: 0,
    typeValue: "",
    pagination: {
      pageIndex: 1,
      pageSize: 50,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    customersFavoriteProducts: [],
    customersAddress: [],
    customers: [],
    sortBy: [],
    transactionsDetails: {
      data: [],
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
      searchText: "",
    },
    purchasedProductDetails: {
      data: [],
      pagination: {
        pageIndex: 1,
        pageSize: 50,
        pageCount: 0,
        total: 0,
        hasMorePages: true,
        lastPage: 1,
      },
      sortBy: [],
      searchText: "",
    },
    httpStatusCodes: {
      details: "",
    },
    verification: {
      isOpen: false,
      isSmsLoading: false,
      isEmailLoading: false,
      isVerify: 1,
      isError: null,
      sms: false,
      email: false,
      forceDisable: false,
      otpData: {
        type: null,
        otp: "",
      },
    },
    filters: {
      searchText: "",
      gender: "",
      status: "",
    },
    data: {
      have_a_card: 0,
      member_number: "",
      verification_code: "",
      business_address: {},
      first_name: "",
      last_name: "",
      email: "",
      country_code: 61,
      phone: "",
      date_of_birth: null,
      gender: null,
      status: 1,
      is_business_user: 0,
      opt_in: 1,
      business_name: "",
      abn_number: "",
    },
    isBussiness: false,
    validation: { isValidated: false, date_of_birth: null },
    resetPassword: {
      isOpen: false,
      slide: false,
      disabled: false,
      email: "",
      temporaryPassword: "",
    },
    clientAccount: {
      isLoading: true,
      initialLoad: true,
      dialogLoading: true,
      dataList: [],
      isOpen: false,
      isDeleteOpen: false,
      id: null,
      allDataList: [],
      filteredList: [],
      selectedItems: [],
      deleteModalOpen: false,
      selectedId: null,
      searchText: null,
    },
    clientAccountDetails: {
      isSaveButtonBusy: false,
      isLoading: true,
      transaction: {},
      details: {
        transactions: [],
      },
      updateData: {
        credit_limit: null,
        restrict_usage: 1,
        bill_duration: null,
        bill_period: null,
        bill_day: null,
        bill_date: null,
        bill_date2: null,
        status: 0,
        carry_forward: 0,
        due_amount: 0,
      },
    },
    suburbList: [],
    isSuburbBusy: false,
  });
  const debounced = useDebouncedCallback((value) => {
    const { gender, status } = state.filters;
    const { pagination, sortBy, deletedCustomers } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filters },
    });
    getCustomerList({
      search: value,
      gender,
      status,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
      type: deletedCustomers,
    });
  }, 1000);

  const debouncedOrders = useDebouncedCallback((value) => {
    const { sortBy, pagination } = state.purchasedProductDetails;
    getCustomerPurchasedProducts({
      purchasedId,
      search: value,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  }, 1000);
  const debouncedTransaction = useDebouncedCallback((value) => {
    const { sortBy, pagination } = state.transactionsDetails;
    getCustomerTransactions({
      transactionId,
      search: value,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  }, 1000);
  useEffect(() => {
    if (value === 1) {
      setState((draft) => {
        draft.clientAccountDetails.updateData.bill_date2 = "";
        draft.clientAccountDetails.updateData.bill_day = "";
      });
    }
    if (value === 2) {
      setState((draft) => {
        draft.clientAccountDetails.updateData.bill_date = "";
      });
    }
  }, [value]);

  useEffect(() => {
    if (load) {
      const { gender, status, searchText } = customers.filterApplied;
      const { pagination, sortBy, type } = customers;
      setState((draft) => {
        draft.filters = customers.filterApplied;
        draft.pagination = pagination;
        draft.sortBy = sortBy;
        draft.deletedCustomers = type;
      });
      getCustomerList({
        search: searchText,
        gender,
        status,
        pagination: {
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
        },
        sortBy,
        type,
      });
    }
  }, [load]);

  useEffect(() => {
    if (id) {
      getCustomerDetails(id);
      if (currentTab === "6") {
        HandleCustomerClientAccount(id);
      }
    }
  }, [id, currentTab]);

  useEffect(() => {
    if (
      (ClientAccountID && state.clientAccountDetails.isLoading) ||
      state.isOpenModal
    ) {
      HandleClientAccountDetails(ClientAccountID);
    }
  }, [
    ClientAccountID,
    state.clientAccountDetails.isLoading,
    state.isOpenModal,
  ]);

  const handleModal = (type, state, data) => {
    switch (type) {
      case "client-ac-delete":
        setState((draft) => {
          draft.clientAccount.isDeleteOpen = state;
          draft.clientAccount.id = data;
        });
        break;
      case "verify":
        setState((draft) => {
          draft.verification.isOpen = state;
          draft.verification.otpData.otp = "";
          draft.verification.isError = null;
        });
        break;
    }
  };

  const getCustomerList = async ({
    search = null,
    status = "",
    gender = null,
    pagination,
    sortBy = [],
    type = "",
  }) => {
    let params = {};
    if (search) {
      params = { ...params, search };
    }
    if (status !== "") {
      params = { ...params, status };
    }
    if (gender) {
      params = { ...params, gender };
    }
    if (sortBy.length > 0) {
      const sortByParams = sortBy.reduce(
        (obj, item) =>
          Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
        {}
      );
      params = {
        ...params,
        sort: sortByParams,
      };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = false;
      });
      if (type === 1) {
        const res = await getDeletedCustomersList(query, pagination);
        if (res.success) {
          setState((draft) => {
            draft.isBusy = false;
            draft.customers = res.data.customers;
            draft.pagination = res.data.pagination;
          });
        }
      } else {
        const res = await getCustomersList(query, pagination);
        if (res.success) {
          setState((draft) => {
            draft.isBusy = false;
            draft.customers = res.data.customers;
            draft.pagination = res.data.pagination;
          });
        }
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const getCustomerDetails = async (customerId) => {
    try {
      setState((draft) => {
        draft.isDetailsFetching = true;
      });
      const res = await getCustomerDetailsById(customerId);
      if (res.success) {
        setState((draft) => {
          draft.data = {
            ...res.data,
            date_of_birth: res.data.date_of_birth,
            business_address: res.data.business_address
              ? res.data.business_address
              : {
                unit_number: "",
                street_number: "",
                street_name: "",
                suburb: "",
                postcode: "",
                state: "",
                country: "",
              },
          };
          draft.customerName = res.data.name;
          draft.isDetailsFetching = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isDetailsFetching = false;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };

  const toggleModal = (id = null, type = "list") => {
    if (type === "list") {
      setState((draft) => {
        draft.id = id;
        draft.isOpen = !draft.isOpen;
      });
    } else {
      setState((draft) => {
        draft.id = id;
        draft.isOpenDetails = !draft.isOpenDetails;
      });
    }
  };

  const handleDeleteCustomer = async (type = "list") => {
    try {
      const res = await deleteCustomerById(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.customers = draft.customers.filter(
            (item) => item.id !== state.id
          );
        });
        toggleModal();
        if (type === "details") {
          navigate("/customers");
        }
      } else {
        errorMessage(res.message);
        if (type === "details") {
          toggleModal(null, "details");
        } else {
          toggleModal();
        }
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onChangeFilter = (e) => {
    const { name, value } = e.target;
    const { searchText, gender, status } = state.filters;
    const { pagination, sortBy, deletedCustomers } = state;
    if (name === "gender") {
      getCustomerList({
        search: searchText,
        gender: value,
        status,
        pagination: {
          pageIndex: 1,
          pageSize: pagination.pageSize,
        },
        sortBy,
        type: deletedCustomers,
      });
    } else if (name === "status") {
      getCustomerList({
        search: searchText,
        gender,
        status: value,
        pagination: {
          pageIndex: 1,
          pageSize: pagination.pageSize,
        },
        sortBy,
        type: deletedCustomers,
      });
    } else if (name === "searchText") {
      debounced(value);
    }
    setState((draft) => {
      draft.filters[name] = value;
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: { ...state.filters, [name]: value } },
    });
  };

  const resetFilters = () => {
    if (
      toString(state.filters.status) ||
      state.filters.gender ||
      state.filters.searchText
    ) {
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initialState.params.customers.filterApplied,
      });
      getCustomerList({
        pagination: {
          pageIndex: 1,
          pageSize: state.pagination.pageSize,
        },
        type: state.deletedCustomers,
      });
      setState((draft) => {
        draft.filters.status = "";
        draft.filters.gender = "";
        draft.filters.searchText = "";
      });
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
    if (name === "have_a_card") {
      setState((draft) => {
        draft.data.member_number = "";
        draft.data.verification_code = "";
      });
    }
  };
  const onChangeBusinessUser = (e, data) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data.business_address[name] = value;
    });
    if (name === "suburb") {
      setState((draft) => {
        draft.data.business_address.postcode = data.postcode;
        draft.data.business_address.state = data.state;
      });
    }
  };
  const onSaveCustomer = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const payload = {
        ...state.data,
        date_of_birth: state.data.date_of_birth
          ? moment(state.data.date_of_birth).format("YYYY-MM-DD")
          : "",
        business_address: {
          unit_number: state.data.business_address.unit_number ?? "",
          street_number: state.data.business_address.street_number,
          street_name: state.data.business_address.street_name,
          suburb: state.data.business_address.suburb,
          postcode: state.data.business_address.postcode,
          state: state.data.business_address.state,
        },
      };
      if (!payload.have_a_card) {
        delete payload.member_number;
        delete payload.verification_code;
      }
      if (!payload.is_business_user) {
        delete payload.business_name;
        delete payload.abn_number;
        delete payload.postcode;
        delete payload.street_name;
        delete payload.street_number;
        delete payload.suburb;
        delete payload.unit_number;
        delete payload.state;
        delete payload.country;
      }
      const res = await createCustomer(payload);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        setCurentTab("customers", "1");
        navigate(
          isStoreAdmin
            ? isClient
              ? "/store-admin/client-account/create"
              : `/store-admin/customers/details/${res.data.id}`
            : isClient
              ? "/client-account/create"
              : `/customers/details/${res.data.id}`
        );
      } else {
        errorMessage(res?.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onUpdateCustomer = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const payload = {
        ...state.data,
        date_of_birth: state.data.date_of_birth
          ? moment(state.data.date_of_birth).format("YYYY-MM-DD")
          : "",
        business_address: {
          unit_number: state.data.business_address.unit_number ?? "",
          street_number: state.data.business_address.street_number,
          street_name: state.data.business_address.street_name,
          suburb: state.data.business_address.suburb,
          postcode: state.data.business_address.postcode,
          state: state.data.business_address.state,
        },
      };
      if (!payload.have_a_card) {
        delete payload.member_number;
        delete payload.verification_code;
      }
      const res = await editCustomer(id, payload);
      if (res.success) {
        successMessage("Updated Successfully");
        navigate(-1);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      } else {
        errorMessage(res?.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const sendEmailVerification = async (id) => {
    try {
      setState((draft) => {
        draft.verification.isEmailLoading = true;
      });
      const res = await sendCustomerEmailVerification(id);
      if (res.success) {
        setState((draft) => {
          draft.verification.isOpen = true;
          draft.verification.otpData.type = "email";
          draft.verification.isVerify = 1;
          draft.verification.isEmailLoading = false;
          draft.verification.email = true;
        });
      } else {
        Swal.fire("Verify Email Address", "Verification has Failed", "error");
        setState((draft) => {
          draft.verification.isEmailLoading = false;
        });
      }
    } catch {
      Swal.fire("Verify Email Address", "Verification has Failed", "error");
      setState((draft) => {
        draft.verification.isEmailLoading = false;
      });
    }
  };

  const sendPhoneVerification = async (id) => {
    try {
      setState((draft) => {
        draft.verification.isSmsLoading = true;
      });
      const res = await sendCustomerPhoneVerification(id);
      if (res.success) {
        setState((draft) => {
          draft.verification.isOpen = true;
          draft.verification.otpData.type = "phone";
          draft.verification.isVerify = 1;
          draft.verification.isSmsLoading = false;
          draft.verification.sms = true;
        });
      } else {
        Swal.fire("Verify Phone Number", "Verification has Failed", "error");
        setState((draft) => {
          draft.verification.isSmsLoading = false;
        });
      }
    } catch {
      Swal.fire("Verify Phone Number", "Verification has Failed", "error");
      setState((draft) => {
        draft.verification.isSmsLoading = false;
      });
    }
  };

  const handleOtpInput = (e) => {
    setState((draft) => {
      draft.verification.otpData.otp = e.target.value;
    });
  };

  const handleVerifyEmailOrPhone = async () => {
    try {
      setState((draft) => {
        draft.verification.isVerify = 2;
        draft.verification.isError = null;
      });
      const res = await verifyCustomerEmailorPhone(
        id,
        state.verification.otpData
      );
      if (res.success) {
        setState((draft) => {
          draft.verification.isVerify = 3;
        });
        if (state.verification.otpData.type === "email") {
          setState((draft) => {
            draft.data = {
              ...draft.data,
              email_verified_at: true,
            };
          });
        } else {
          setState((draft) => {
            draft.data = {
              ...draft.data,
              phone_verified_at: true,
            };
          });
        }
      } else {
        setState((draft) => {
          draft.verification.isVerify = 1;
          draft.verification.isError = res.message;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.verification.isVerify = 1;
        draft.verification.isError = err.response.data.message;
      });
    }
  };

  const handleForceVerify = async () => {
    try {
      setState((draft) => {
        draft.verification.forceDisable = true;
      });
      const res = await forceVerifyCustomer(id);

      if (res.success) {
        setState((draft) => {
          draft.verification.forceDisable = false;
          draft.verification.isOpen = false;
        });
        successMessage(res.message);
        getCustomerDetails(id);
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.verification.forceDisable = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.verification.forceDisable = false;
      });
    }
  };

  const handleResetModal = (open, data) => {
    setState((draft) => {
      draft.resetPassword.isOpen = open;
      draft.resetPassword.slide = data;
      draft.resetPassword.email = state.data.email;
    });
  };

  const handleResetPassword = async (type, value) => {
    if (type === "email") {
      setState((draft) => {
        draft.resetPassword.email = value;
      });
    } else if (type === "disable") {
      setState((draft) => {
        draft.resetPassword.isDisabled = value;
      });
    } else {
      try {
        const res = await resetCustomerPasswordById(
          state.resetPassword.isDisabled
            ? { email: state.resetPassword.email }
            : {},
          id
        );
        if (res.success) {
          setState((draft) => {
            draft.resetPassword.temporaryPassword = res.data.temporary_password;
            draft.resetPassword.slide = 1;
            draft.resetPassword.isDisabled = false;
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
      }
    }
  };

  const handlePasswordCopy = () => {
    navigator.clipboard.writeText(state.resetPassword.temporaryPassword);
    successMessage("The temporary password is copied to the clipboard");
  };

  const onDateChange = (value) => {
    setState((draft) => {
      draft.data.date_of_birth = value || null;
    });
  };

  const onSeachClear = () => {
    const { status, gender } = state.filters;
    const { pagination, sortBy, deletedCustomers } = state;
    getCustomerList({
      search: null,
      gender,
      status,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
      type: deletedCustomers,
    });
    setState((draft) => {
      draft.filters.searchText = "";
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: { ...state.filters, searchText: "" } },
    });
  };

  const gotoPage = (page) => {
    const { searchText, gender, status } = state.filters;
    const { sortBy, deletedCustomers } = state;
    getCustomerList({
      search: searchText,
      gender,
      status,
      pagination: {
        pageIndex: page,
        pageSize: state.pagination.pageSize,
      },
      sortBy,
      type: deletedCustomers,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };

  const setPageSize = (e) => {
    const { searchText, gender, status } = state.filters;
    const { sortBy, deletedCustomers } = state;
    getCustomerList({
      search: searchText,
      gender,
      status,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
      type: deletedCustomers,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };
  useEffect(() => {
    if (transactionId) {
      getCustomerTransactions({
        transactionId,
        pagination: state.transactionsDetails.pagination,
      });
    }
  }, [transactionId]);
  const getCustomerTransactions = async ({
    transactionId,
    search,
    pagination,
    sortBy = [],
  }) => {
    try {
      let params = {};
      if (search) {
        params = { ...params, search };
      }
      if (sortBy.length > 0) {
        const sortByParams = sortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }
      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getCustomerTransaction(
        query,
        pagination,
        transactionId
      );
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.transactionsDetails.data = res?.data?.transactions;
          draft.transactionsDetails.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const gotoPageTransaction = (page) => {
    const { searchText, sortBy, pagination } = state.transactionsDetails;
    getCustomerTransactions({
      transactionId,
      search: searchText,
      pagination: {
        pageIndex: page,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.transactionsDetails.pagination.pageIndex = page;
    });
  };
  const setPageSizeTransaction = (e) => {
    const { searchText, sortBy, pagination } = state.transactionsDetails;
    getCustomerTransactions({
      transactionId,
      search: searchText,
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    setState((draft) => {
      draft.transactionsDetails.pagination.pageSize = +e.target.value;
    });
  };
  const handleSortTransaction = (sortBy) => {
    const { searchText, pagination } = state.transactionsDetails;
    getCustomerTransactions({
      transactionId,
      search: searchText,
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.transactionsDetails.sortBy = sortBy;
    });
  };
  const onChangeTransactionSearch = (e) => {
    debouncedTransaction(e.target.value);
    setState((draft) => {
      draft.transactionsDetails.searchText = e.target.value;
    });
  };
  const onSeachClearTransaction = () => {
    const { sortBy, pagination } = state.transactionsDetails;
    getCustomerTransactions({
      transactionId,
      search: null,
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.transactionsDetails.searchText = "";
    });
  };

  // ------------------------------------------------------------------------------------

  useEffect(() => {
    if (purchasedId) {
      getCustomerPurchasedProducts({
        purchasedId,
        pagination: state.purchasedProductDetails.pagination,
      });
    }
  }, [purchasedId]);

  const getCustomerPurchasedProducts = async ({
    purchasedId,
    search,
    pagination,
    sortBy = [],
  }) => {
    try {
      let params = {};
      if (search) {
        params = { ...params, search };
      }
      if (sortBy.length > 0) {
        const sortByParams = sortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }
      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getCustomerPurchasedProduct(
        query,
        pagination,
        purchasedId
      );
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.purchasedProductDetails.data = res.data.orders;
          draft.purchasedProductDetails.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const handleDownloadCustomersList = async () => {
    try {
      const { searchText, gender, status } = state.filters;
      const { sortBy } = state;
      let params = {};
      if (searchText) {
        params = { ...params, search: searchText };
      }
      if (status !== "") {
        params = { ...params, status };
      }
      if (gender) {
        params = { ...params, gender };
      }
      if (sortBy.length > 0) {
        const sortByParams = sortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }
      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };
      const res = await DownloadCustomersList(query);
      successMessage(res?.message);
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  //Client Account

  const HandleCustomerClientAccount = async (id) => {
    setState((draft) => {
      draft.clientAccount.initialLoad = false;
    });
    if (state.clientAccount.isLoading) {
      try {
        const res = await getCustomerClientAccount(id);
        if (res.success) {
          setState((draft) => {
            draft.clientAccount.dataList = res.data;
            draft.clientAccount.isLoading = false;
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
        setState((draft) => {
          draft.clientAccount.isLoading = false;
        });
      }
    }
  };

  const HandleClientAccountStatus = async (id) => {
    try {
      const res = await EnableOrDisableClientAccount(id);
      if (res.success) {
        setState((draft) => {
          draft.clientAccountDetails.details.status =
            state?.clientAccountDetails?.details?.status === 1 ? 0 : 1;
        });
        successMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleDeleteClientAccount = async () => {
    try {
      const res = await deleteClientAccountById(state.clientAccount.id);
      if (res.success) {
        successMessage("Client account deleted successfully");
        setState((draft) => {
          draft.clientAccount.dataList = draft.clientAccount.dataList.filter(
            (item) => item.id !== state.clientAccount.id
          );
        });
        handleModal("client-ac-delete", false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleClientAccountStorsSelect = (id) => {
    const isSelcted = state.clientAccount.selectedItems.includes(id);
    if (isSelcted) {
      setState((draft) => {
        draft.clientAccount.selectedItems =
          draft.clientAccount.selectedItems.filter((val) => val !== id);
      });
    } else {
      setState((draft) => {
        draft.clientAccount.selectedItems = [
          ...draft.clientAccount.selectedItems,
          id,
        ];
        return draft;
      });
    }
  };

  const handleAssignStorestoClientAccount = async () => {
    const data = {
      store_id: state.clientAccount.selectedItems,
    };
    try {
      const res = await assignStoresToClinetAccount(id, data);
      if (true) {
        successMessage(res.message);
        setState((draft) => {
          draft.clientAccount.isLoading = true;
          return draft;
        });
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };
  useEffect(() => {
    if (
      (isStoreAdmin &&
        state.clientAccount.isLoading &&
        !state.clientAccount.initialLoad) ||
      clid
    ) {
      HandleCustomerClientAccount(clid);
    }
  }, [state.clientAccount.isLoading, clid]);
  const HandleClientAccountDetails = async (id) => {
    try {
      const res = await getClientAccountDetailsById(id);

      if (res.success) {
        const name = tId
          ? res?.data?.transactions?.find((item) => item?.id === Number(tId))
          : {};

        name.customer_name = res.data.customer_name;
        setState((draft) => {
          draft.clientAccountDetails.details = {
            ...res.data,
            transactions: res?.data?.transactions?.reverse(),
          };
          draft.clientAccountDetails.transaction = tId ? name : "";
          draft.clientAccountDetails.updateData = {
            credit_limit: res?.data?.credit_limit,
            restrict_usage: res?.data?.restrict_usage,
            bill_duration: res?.data?.bill_duration,
            bill_period: res?.data?.bill_period,
            bill_day: res?.data?.bill_day,
            bill_date: res?.data?.bill_day ? null : res?.data?.bill_date,
            bill_date2: res?.data?.bill_day ? res?.data?.bill_date : null,
            status: res?.data?.status,
            carry_forward: res?.data?.carry_forward,
            due_amount: res?.data?.due_amount,
          };
          draft.clientAccountDetails.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.clientAccountDetails.isLoading = false;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "status" || name === "restrict_usage") {
      setState((draft) => {
        draft.clientAccountDetails.updateData = {
          ...draft.clientAccountDetails.updateData,
          [name]: event.target.checked ? 1 : 0,
        };
      });
    } else if (name === "bill_period") {
      setState((draft) => {
        draft.typeValue = value;
        draft.clientAccountDetails.updateData = {
          ...draft.clientAccountDetails.updateData,
          [name]: value,
        };
      });
    } else {
      setState((draft) => {
        draft.clientAccountDetails.updateData = {
          ...draft.clientAccountDetails.updateData,
          [name]: value,
        };
      });
    }
  };

  const onUpdateClientAccount = async (id, type) => {
    let data = {
      status: state.clientAccountDetails.updateData.status,
      restrict_usage: state.clientAccountDetails.updateData.restrict_usage,
      due_amount: state.clientAccountDetails.updateData.due_amount,
      credit_limit: state.clientAccountDetails.updateData.credit_limit,
      carry_forward: state.clientAccountDetails.updateData.carry_forward,
      bill_duration: state.clientAccountDetails.updateData.bill_duration,
      bill_period: state.clientAccountDetails.updateData.bill_period,
    };

    if (state.clientAccountDetails.updateData.bill_period === 1) {
      data = {
        ...data,
        bill_date: null,
        bill_day: state.clientAccountDetails.updateData.bill_day,
      };
    } else if (state.clientAccountDetails.updateData.bill_period === 3) {
      data = {
        ...data,
        bill_period: state.clientAccountDetails.updateData.bill_period,
        bill_duration: state.clientAccountDetails.updateData.bill_duration,
        bill_date: null,
        bill_day: null,
      };
    } else if (state.clientAccountDetails.updateData.bill_period === 2) {
      if (value === 1) {
        data = {
          ...data,
          bill_date: state.clientAccountDetails.updateData.bill_date,
          bill_date2: null,
          bill_day: null,
        };
      } else {
        data = {
          ...data,
          bill_date: state.clientAccountDetails.updateData.bill_date2,
          bill_date2: null,
          bill_day: state.clientAccountDetails.updateData.bill_day,
        };
      }
    }
    // delete data
    try {
      setState((draft) => {
        draft.clientAccountDetails.isSaveButtonBusy = true;
      });
      const res = await updateClientAccount(id, data);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.clientAccountDetails.isSaveButtonBusy = false;
        });
        isCustomer
          ? navigate(
            isStoreAdmin
              ? navigate(
                `/store-admin/customers/details${cuid}/clientaccountId/${ClientAccountID}`
              )
              : `/customers/details/${cuid}/clientaccountId/${ClientAccountID}`
          )
          : navigate(
            isStoreAdmin
              ? `/store-admin/client-account/details/${id}`
              : `/client-account/details/${id}`
          );
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.clientAccountDetails.isSaveButtonBusy = false;
      });
    }
  };

  const handleAllSelect = (checked) => {
    if (checked) {
      setState((draft) => {
        draft.clientAccount.selectedItems = [
          ...state.clientAccount.filteredList.map((item) => item.id),
        ];
      });
    } else {
      setState((draft) => {
        draft.clientAccount.selectedItems = [];
      });
    }
  };

  const onDayPicker = (value) => {
    if (state.clientAccountDetails.updateData.bill_day === value) {
      setState((draft) => {
        draft.clientAccountDetails.updateData.bill_day = null;
      });
    } else {
      setState((draft) => {
        draft.clientAccountDetails.updateData.bill_day = value;
      });
    }
  };

  const handleSort = (sortBy) => {
    const { searchText, gender, status } = state.filters;
    const { pagination, deletedCustomers } = state;
    getCustomerList({
      search: searchText,
      gender,
      status,
      pagination,
      sortBy,
      type: deletedCustomers,
    });
    filterStateDispatch({
      type: "UPDATE_SORT",
      page: PAGE_NAME,
      value: sortBy,
    });
    setState((draft) => {
      draft.sortBy = sortBy;
    });
  };

  const validateDate = (reason, name) => {
    if (reason === "disableFuture") {
      setState((draft) => {
        draft.validation[name] = "Please select a past date";
      });
    } else if (reason === "invalidDate") {
      setState((draft) => {
        draft.validation[name] = "Selected Date is not a valid date";
      });
    } else if (reason === "minDate") {
      setState((draft) => {
        draft.validation[name] = "Selected Date is not a valid date";
      });
    } else {
      setState((draft) => {
        draft.validation[name] = null;
      });
    }
  };

  const toggleCreateOrEditModal = async (details, data) => {
    setState((draft) => {
      draft.isOpenModal = data;
      draft.details = details;
    });
  };

  useEffect(() => {
    const { is_business_user, abn_number, business_name } = state.data;
    if (id) {
      if (abn_number || business_name) {
        setState((draft) => {
          draft.isBussiness = true;
        });
      } else {
        setState((draft) => {
          draft.isBussiness = is_business_user;
        });
      }
    } else {
      setState((draft) => {
        draft.isBussiness = is_business_user;
      });
    }
  }, [id, state.data.is_business_user, state.data.abn_number]);

  const handleSuburbList = async ({ search = null, page, type }) => {
    let params = {};
    let pagination = {
      pageIndex: page,
      pageSize: 10,
    };
    if (search) {
      params = { ...params, search };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      const res =
        type === "suburb"
          ? await getSuburbList(query, pagination)
          : await getStateList(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.suburbList = res.data.data.map((data) => {
            return { value: data.name, label: data.name };
          });
          draft.isSuburbBusy = false;
        });
        const options = res.data.data.map((data) => {
          return {
            value: data.name,
            label: data.name,
            postcode: data.postcode,
            state: data.state,
          };
        });
        return {
          options: options,
          hasMore: res.data.pagination.hasMorePages,
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (err) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const toggleHiddenColumns = (column) => {
    filterStateDispatch({
      type: "UPDATE_COLUMNS",
      page: PAGE_NAME,
      value: column,
    });
  };
  const HandleDeletedCustomersRequest = async (type) => {
    const { searchText, gender, status } = state.filters;
    const { pagination, sortBy } = state;
    getCustomerList({
      search: searchText,
      gender,
      status,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
      type: type,
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { type },
    });
    setState((draft) => {
      draft.deletedCustomers = type;
    });
  };
  const handleCustomerDeleteRequest = async (id, type) => {
    const { pagination, sortBy, deletedCustomers } = state;
    const { searchText, gender, status } = state.filters;
    try {
      let fn = type === "confirm" ? deleteCustomerById : cancelDeleteRequest;
      const res = await fn(id);
      if (res.success) {
        successMessage(res?.message);
        getCustomerList({
          search: searchText,
          gender,
          status,
          pagination: {
            pageIndex: 1,
            pageSize: pagination.pageSize,
          },
          sortBy,
          type: deletedCustomers,
        });
      } else {
        errorMessage(res?.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const getCustomerAddress = async (id) => {
    try {
      setState((draft) => {
        draft.isDetailsFetching = true;
      });
      const res = await getCustomerAddressById(id);
      if (res.success) {
        setState((draft) => {
          draft.customersAddress = res.data;
          draft.isDetailsFetching = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isDetailsFetching = false;
      });
    }
  };
  const getCustomerFavProducts = async (id) => {
    try {
      setState((draft) => {
        draft.isDetailsFetching = true;
      });
      const res = await getCustomerFavouriteProduct(id);
      if (res.success) {
        setState((draft) => {
          draft.customersFavoriteProducts = res.data;
          draft.isDetailsFetching = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isDetailsFetching = false;
      });
    }
  };
  useEffect(() => {
    if (id) {
      if (currentTab === "2") {
        getCustomerAddress(id);
      } else if (currentTab === "4") {
        getCustomerFavProducts(id);
      }
    }
  }, [id, currentTab]);
  const onUpdateClientAccountCustomers = async () => {
    const payload = {
      is_business_user: state.data.is_business_user,
      business_name: state.data.business_name,
      first_name: state.data.first_name,
      last_name: state.data.last_name,
      email: state.data.email,
      abn_number: state.data.abn_number,
      date_of_birth: state.data.date_of_birth
        ? moment(state.data.date_of_birth).format("YYYY-MM-DD")
        : "",
      business_address: {
        unit_number: state.data.business_address.unit_number,
        street_number: state.data.business_address.street_number,
        street_name: state.data.business_address.street_name,
        suburb: state.data.business_address.suburb,
        postcode: state.data.business_address.postcode,
        state: state.data.business_address.state,
      },
    };

    if (state.data.is_business_user === 0) {
      delete payload.business_name;
      delete payload.abn_number;
      delete payload.business_address;
      delete payload.street_name;
      delete payload.street_number;
      delete payload.suburb;
      delete payload.unit_number;
      delete payload.state;
      delete payload.country;
    }

    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await editClientAccountCustomers(id, payload);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate(`/store-admin/client-account/details/${customerId}`);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const gotoPageOrders = (page) => {
    const { searchText, sortBy, pagination } = state.purchasedProductDetails;
    getCustomerPurchasedProducts({
      purchasedId,
      search: searchText,
      pagination: {
        pageIndex: page,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.purchasedProductDetails.pagination.pageIndex = page;
    });
  };
  const setPageSizeOrders = (e) => {
    const { searchText, sortBy, pagination } = state.purchasedProductDetails;
    getCustomerPurchasedProducts({
      purchasedId,
      search: searchText,
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    setState((draft) => {
      draft.purchasedProductDetails.pagination.pageSize = +e.target.value;
    });
  };
  const handleSortOrders = (sortBy) => {
    const { searchText, pagination } = state.purchasedProductDetails;
    getCustomerPurchasedProducts({
      purchasedId,
      search: searchText,
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.purchasedProductDetails.sortBy = sortBy;
    });
  };
  const onChangeOrderSearch = (e) => {
    debouncedOrders(e.target.value);
    setState((draft) => {
      draft.purchasedProductDetails.searchText = e.target.value;
    });
  };
  const onSeachClearOrders = () => {
    const { sortBy, pagination } = state.purchasedProductDetails;
    getCustomerPurchasedProducts({
      purchasedId,
      search: null,
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.purchasedProductDetails.searchText = "";
    });
  };

  // Reset the default tab from location
  React.useLayoutEffect(() => {
    if (location.search.includes("?product-reviews")) {
      setCurentTab("customers", "8");
    }
  }, [location.search]);

  return {
    setCurentTab,
    state,
    isStoreAdmin,
    toggleModal,
    handleDeleteCustomer,
    onChangeFilter,
    resetFilters,
    onChange,
    onSaveCustomer,
    onUpdateCustomer,
    sendEmailVerification,
    sendPhoneVerification,
    handleResetModal,
    handleResetPassword,
    handlePasswordCopy,
    isEditable,
    isDeletable,
    isCreateVisible,
    isEditableCA,
    isDeletableCA,
    isCreateVisibleCA,
    isReviewsViewable,
    hasResetPassword,
    globalData,
    onDateChange,
    onSeachClear,
    handleModal,
    gotoPage,
    setPageSize,
    currentTab,
    clientAccDetailsTab,
    handleDownloadCustomersList,
    handleSort,
    HandleClientAccountStatus,
    handleDeleteClientAccount,
    handleClientAccountStorsSelect,
    handleAssignStorestoClientAccount,
    handleInputChange,
    onUpdateClientAccount,
    handleAllSelect,
    onDayPicker,
    validateDate,
    setState,
    value,
    setValue,
    handleOtpInput,
    handleVerifyEmailOrPhone,
    toggleCreateOrEditModal,
    handleForceVerify,
    onChangeBusinessUser,
    handleSuburbList,
    columnOptions,
    toggleHiddenColumns,
    HandleDeletedCustomersRequest,
    handleCustomerDeleteRequest,
    onUpdateClientAccountCustomers,
    gotoPageOrders,
    setPageSizeOrders,
    handleSortOrders,
    onChangeOrderSearch,
    onSeachClearOrders,
    handleSortTransaction,
    gotoPageTransaction,
    setPageSizeTransaction,
    onChangeTransactionSearch,
    onSeachClearTransaction,
  };
};
