import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const tableCellStyle = {
  borderBottom: "1px solid #E6E6E6",
};
export const RefundListTable = ({ isRefund = false, state }) => {
  return (
    <Paper sx={{ boxShadow: "none" }}>
      <TableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell align="left">Item</TableCell>
              <TableCell align="left">Product Price</TableCell>
              <TableCell align="left">Quantity</TableCell>
              <TableCell align="left">Sale Price</TableCell>
              <TableCell align="right">Total Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.data.products.map((item, index) => {
              return (
                <>
                  <TableRow>
                    <TableCell
                      sx={{ ...tableCellStyle, verticalAlign: "baseline" }}
                      key={0}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell sx={tableCellStyle} key={1}>
                      {item.has_tax ? "*" : ""}{item.product_name}
                      <Typography variant="body2" color="#636363" mt="4px">
                        {item?.child_products?.length > 0
                          ? item.child_products.map((data, i) => (
                            <div style={{ marginTop: "2px" }}>
                              {data.has_tax ? "*" : ""}{data.product_name}
                              {data?.child_products?.length > 0 &&
                                data.child_products.map((item) => (
                                  <div
                                    style={{
                                      marginLeft: "8px",
                                      marginTop: "2px",
                                    }}
                                  >
                                    {item.has_tax ? "*" : ""}{item.product_name}
                                  </div>
                                ))}
                            </div>
                          ))
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell sx={tableCellStyle} key={2}>
                      ${item?.mrp?.toFixed(2)}
                      <Typography variant="body2" color="#636363" mt="4px">
                        {item?.child_products?.length > 0
                          ? item.child_products.map((data, i) => (
                            <div style={{ marginTop: "2px" }}>
                              ${data.mrp?.toFixed(2)}
                              {data?.child_products?.length > 0
                                ? data.child_products.map((ch, i) => (
                                  <div style={{ marginTop: "2px" }}>
                                    ${ch?.mrp?.toFixed(2)}
                                  </div>
                                ))
                                : ""}
                            </div>
                          ))
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell sx={tableCellStyle} key={3}>
                      {item.quantity}
                      <Typography variant="body2" color="#636363" mt="4px">
                        {item?.child_products?.length > 0
                          ? item.child_products.map((data, i) => (
                            <div style={{ marginTop: "2px" }}>
                              {data.quantity}
                              {data?.child_products?.length > 0
                                ? data.child_products.map((ch, i) => (
                                  <div style={{ marginTop: "2px" }}>
                                    {ch.quantity}
                                  </div>
                                ))
                                : ""}
                            </div>
                          ))
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell sx={tableCellStyle} key={4}>
                      ${item?.price?.toFixed(2)}
                      <Typography variant="body2" color="#636363" mt="4px">
                        {item?.child_products?.length > 0
                          ? item.child_products.map((data, i) => (
                            <div style={{ marginTop: "2px" }}>
                              ${data?.price?.toFixed(2)}
                              {data?.child_products?.length > 0
                                ? data.child_products.map((ch, i) => (
                                  <div style={{ marginTop: "2px" }}>
                                    ${ch?.price?.toFixed(2)}
                                  </div>
                                ))
                                : ""}
                            </div>
                          ))
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ ...tableCellStyle, verticalAlign: "bottom" }}
                      align="right"
                      key={5}
                    >
                      ${item?.total?.toFixed(2)}
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
            <TableRow>
              <TableCell colSpan={4}></TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  borderLeft: "1px solid #E6E6E6",
                }}
                align="left"
              >
                Sub Total (In GST)
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  fontWeight: "500",
                }}
                align="right"
              >
                $
                {(state?.data?.sub_total_inc_tax &&
                  state?.data?.sub_total_inc_tax?.toFixed(2)) ||
                  ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4}></TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  borderLeft: "1px solid #E6E6E6",
                }}
              >
                Deduction
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  color: "#636363",
                }}
                align="right"
              >
                ${state?.data?.deduction?.toFixed(2)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4}></TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  borderLeft: "1px solid #E6E6E6",
                }}
              >
                Rounding
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  color: "#636363",
                }}
                align="right"
              >
                ${state.data?.round_off?.toFixed(2)}
              </TableCell>
            </TableRow>
            {!isRefund && (
              <>
                <TableRow>
                  <TableCell colSpan={4}></TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid #E6E6E6",
                      borderLeft: "1px solid #E6E6E6",
                      color: "#636363",
                      fontWeight: 700,
                    }}
                  >
                    Total (In GST)
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid #E6E6E6",
                      fontWeight: 700,
                    }}
                    align="right"
                  >
                    $
                    {state.data?.grand_total &&
                      state.data?.grand_total?.toFixed(2)}
                  </TableCell>
                </TableRow>
                {state?.data?.is_it_client_account_customer === true && (
                  <TableRow>
                    <TableCell colSpan={4}></TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        borderLeft: "1px solid #E6E6E6",
                        color: "#636363",
                        fontWeight: 700,
                      }}
                    >
                      Total Due (In GST)
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        fontWeight: 700,
                      }}
                      align="right"
                    >
                      $
                      {state.data?.client_account_total === null
                        ? "0.00"
                        : state.data?.client_account_total?.toFixed(2)}
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}

            <TableRow>
              <TableCell colSpan={4}></TableCell>
              <TableCell
                sx={{
                  borderLeft: "1px solid #E6E6E6",
                  fontWeight: "400",
                }}
              >
                Total Includes GST of
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "400",
                }}
                align="right"
              >
                ${state.data?.tax_amount}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
