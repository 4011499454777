import * as React from "react";
import { Stack, Typography, Button, Box } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -100%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "16px",
  outline: "none",
};

export function RejectOrderDialog(props) {
  const { handleClose, onSubmit, isButton, onChange, data, text } = props;

  return (
    <Stack sx={{ padding: "20px", gap: "10px", pt: 0 }}>
      <Typography>Why do you want to {text} the Order?</Typography>
      <InputControl
        type="textarea"
        label="Reason"
        placeholder="Describe..."
        name="reason"
        onChange={onChange}
        value={data.reason}
        sx={{
          mb: "10px",
        }}
      />
      <Stack direction="row" gap="12px" justifyContent="flex-end">
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={isButton} onClick={onSubmit} variant="contained">
          Submit
        </Button>
      </Stack>
    </Stack>
  );
}
