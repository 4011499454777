import { ContentLayout, HeaderBreadcrumbs, Loader } from "../../../shared";
import * as React from "react";
import {
  Button,
  Stack,
  Card,
  Typography,
  Divider,
  Box,
  Tab,
} from "@mui/material";
import { useParams } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { LoyaltyCardsProductsTable } from "./LoyaltyCardsProductsTable";
import { useLoyalty } from "../hooks";
import SimpleReactValidator from "simple-react-validator";
import { LedgerAccountsList } from "../../Configurations/components";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export const LoyaltyCardsDetails = () => {
  const { id } = useParams();
  const {
    state,
    handleInputChange,
    onUpdateLoyaltyCards,
    setState,
    handleMoveIncludeOrExclude,
    handleProductsSearch,
    onProductsSeachClear,
    loyaltyCardTypeDetails,
    setCurentTab,
  } = useLoyalty({ IsType: id });
  const { isLoading, details, updateData, isSave, searchText, isSaveButton } =
    state.loyaltyCards;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const onSubmit = () => {
    if (isSave) {
      if (validator.current.allValid()) {
        onUpdateLoyaltyCards(id);
      } else {
        validator.current.showMessages();
        forceUpdate(1);
      }
    } else {
      setState((draft) => {
        draft.loyaltyCards.isSave = true;
      });
    }
  };

  const handleChange = (event, newValue) => {
    setCurentTab("loyaltyCardTypeDetails", newValue);
  };

  return (
    <>
      <ContentLayout
        title={details?.card_type || ""}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Card Types", href: "/loyalty-cards" },
              {
                name: details?.card_type || "",
              },
            ]}
          />
        }
      >
        <TabContext value={loyaltyCardTypeDetails}>
          <Box
            className="sticky-Tab"
            sx={{ borderBottom: 1, borderColor: "divider", top: "104px" }}
          >
            <TabList onChange={handleChange}>
              <Tab label="Details" value="1" />
              <Tab label="Accounts" value="2" />
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            <>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <Card sx={{ mb: "24px" }}>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      height="83px"
                      p="32px 24px 16px"
                    >
                      <Button
                        type="button"
                        size="medium"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={isSaveButton}
                      >
                        {!isSave ? "Edit" : "Save"}
                      </Button>
                    </Stack>

                    <Divider />
                    <Stack
                      sx={{
                        flexDirection: "row",
                        gap: "20px",
                        p: "20px 16px",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        lineHeight="19px"
                        minWidth="160px"
                      >
                        Point Allocation
                      </Typography>
                      <InputControl
                        label="Purchase Point Percentage (%)"
                        type="number"
                        name="purchase_percentage"
                        value={updateData.purchase_percentage}
                        onChange={handleInputChange}
                        disabled={!isSave}
                        error={validator.current.message(
                          "Purchase Point Percentage",
                          updateData.purchase_percentage,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Purchase Point Percentage",
                          updateData.purchase_percentage,
                          "required"
                        )}
                      />
                    </Stack>
                    <Stack p="16px">
                      <LoyaltyCardsProductsTable
                        IsEdit={isSave}
                        data={details}
                        searchText={searchText}
                        handleMoveIncludeOrExclude={handleMoveIncludeOrExclude}
                        handleProductsSearch={handleProductsSearch}
                        onProductsSeachClear={onProductsSeachClear}
                      />
                    </Stack>
                  </Card>
                </>
              )}
            </>
          </TabPanel>

          <TabPanel sx={{ px: "0" }} value="2">
            <LedgerAccountsList type="loyalty" />
          </TabPanel>
        </TabContext>
      </ContentLayout>
    </>
  );
};
