import { Button, Typography, Stack, Box } from "@mui/material";
import React from "react";
import {
  ContentLayout,
  CustomTable,
  DeleteModal,
  HeaderBreadcrumbs,
  Loader,
} from "../../../shared";

import AddIcon from "@mui/icons-material/Add";
import { useParams, useNavigate } from "react-router-dom";
import { usePriceLevel } from "../hooks";
import moment from "moment";
import { sortItems } from "../../../utils";

export const PriceLevelProducts = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    state,
    handleDeleteModal,
    handleDeleteProductFromPriceLevel,
    isEditable,
    isCreateVisible,
    isDeletable,
    resetCurrentTabs,
  } = usePriceLevel({
    isPriceLevel: false,
    priceLevelProductId: id,
  });

  const ActionButtons = ({ id, editAction, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        <Typography
          sx={{ cursor: "pointer" }}
          fontWeight={500}
          fontSize={"14px"}
          color="#1560D4"
          onClick={editAction}
          className="LinkBtn"
        >
          Price Level
        </Typography>
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
      </div>
    );
  };
  const onNext = () => {
    navigate(`/price-levels/${id}/products/add`);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "product_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Price",
        accessor: "price",
        disableSortBy: true,
      },
      {
        Header: "Old Price",
        accessor: "old_price",
        disableSortBy: true,
      },
      {
        Header: "Cost",
        accessor: "cost",
        disableSortBy: true,
      },
      {
        Header: "Special Price (Start Date - End Date )",
        accessor: "special_price",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              <Typography fontWeight={400} fontSize="14px">
                {row.original.special_price}
              </Typography>
              {row.original.special_price_start_date && (
                <Typography fontWeight={400} fontSize="12px" color={"#636363"}>
                  Start:
                  {moment(row.original.special_price_start_date)
                    .utc()
                    .format("YYYY-MM-DD")}
                </Typography>
              )}
              {row.original.special_price_end_date && (
                <Typography fontWeight={400} fontSize="12px" color={"#636363"}>
                  End:
                  {moment(row.original.special_price_end_date)
                    .utc()
                    .format("YYYY-MM-DD")}
                </Typography>
              )}
            </>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.product_id}
              deleteAction={() => {
                handleDeleteModal(row.product_id);
              }}
              editAction={() => {
                navigate(`/price-levels/${id}/products/${row.product_id}/edit`);
              }}
            />
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <ContentLayout
        title={state.priceLevelName}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Price Levels", href: "/price-levels" },
                {
                  name: state.priceLevelName + "-Products",
                  href: `/price-levels/${id}`,
                },
              ]}
            />
          </>
        }
      >
        {state.isBusy ? (
          <Loader />
        ) : (
          <CustomTable
            columns={columns}
            data={state.productData}
            searchBar={false}
            tableTitle="Products"
            rowsSelection={false}
            headerRightContent={
              <>
                {isCreateVisible && (
                  <Button
                    type="button"
                    size="medium"
                    variant="text"
                    className="delte"
                    startIcon={<AddIcon />}
                    onClick={() => onNext()}
                  >
                    Add Product
                  </Button>
                )}
              </>
            }
            onRowLink={(e) => {
              return `/products/details/${e.product_id}`;
            }}
            onRowAction={(e) => {
              resetCurrentTabs();
            }}
          />
        )}
      </ContentLayout>

      <DeleteModal
        open={state.open}
        handleClose={() => handleDeleteModal()}
        onConfirm={() => handleDeleteProductFromPriceLevel()}
      />
    </>
  );
};
