import * as React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  CustomTable,
  DeleteModal,
  CheckBoxListDialog,
  CustomeDialog,
} from "../../../shared";
import { arraySort, sortItems } from "../../../utils";

export const DiscountStoresDetails = (prop) => {
  const {
    state,
    HandleFilterStores,
    handleStoreSelect,
    handleAssignStoretoDiscount,
    handleModal,
    DeleteStoreModal,
    handleDeleteStores,
    setCurentTab,
    handleSelectAll,
  } = prop;

  const ActionButtons = ({ deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
      </div>
    );
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Store Name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
        accessor: "name",
      },
      {
        Header: "Action",
        id: "action",
        accessor: "id",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => {
                DeleteStoreModal(true, row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <CustomTable
        columns={columns}
        data={arraySort(state?.storeDetails?.storeList || [], "name")}
        searchBar={false}
        tableTitle="Stores"
        emptyMessage={
          " If no stores added, discount is applicable to all stores."
        }
        headerRightContent={
          <Button
            type="button"
            size="medium"
            variant="text"
            className="delte"
            startIcon={<AddIcon />}
            onClick={() => handleModal(true)}
            sx={{
              minWidth: "40px",
              p: "8px",
              border: "1px solid rgba(21, 96, 212, 0.12)",
            }}
          >
            Add Store
          </Button>
        }
        onRowLink={(e) => {
          return `/stores/details/${e.id}`;
        }}
        onRowAction={() => {
          setCurentTab("storeDetails", "1");
        }}
      />

      <CustomeDialog
        open={state.storeDetails.isOpen}
        handleClose={() => handleModal(false)}
        PaperProps={{
          sx: { width: "423px", height: "535px", p: "16px" },
        }}
        title="Stores"
        content={
          <CheckBoxListDialog
            Items={state.storeDetails.filteredStores}
            onSearch={HandleFilterStores}
            onSelect={handleStoreSelect}
            onSubmit={handleAssignStoretoDiscount}
            disabled={
              !state.storeDetails.selectedStores.length ||
              state.storeDetails.isSaveButton
            }
            isSelected={(id) => state.storeDetails.selectedStores.includes(id)}
            hasSelectAll={true}
            isSelectAllChecked={state.storeDetails.filteredStores.every(
              (item) => state.storeDetails.selectedStores.includes(item.id)
            )}
            onAllSelect={handleSelectAll}
          />
        }
      />
      <DeleteModal
        open={state.storeDetails.isDelete}
        handleClose={() => DeleteStoreModal(false, null)}
        onConfirm={handleDeleteStores}
      />
    </>
  );
};
